import _ from 'lodash';
import { useCallback, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

const ACTIONS = {
    PAGE_CHANGE: 'pageChange',
    SORT: 'sort',
    ROW_CLICK: 'rowClick',
    ROW_SELECT: 'rowSelect',
    SELECT_ALL: 'selectAll',
    UNSELECT_ALL: 'unselectAll',
    SET: 'set',
    LOADING: 'loading',
    RATIO: 'ratio',
    CHECKBOX: 'checkbox',
    TOGGLE_SETTINGS: 'toggleSettings',
    TOGGLE_ACTION: 'toggleAction',
    CLICK_ACTION_OPTION: 'clickActionOption',
    RESET: 'reset',
    SET_PAGINATION_LIMIT: 'setPaginationLimit',
    SET_SEARCH: 'setSearch'
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_SEARCH: {
            state.search = action.payload;
            return {
                ...state
            };
        }
        case ACTIONS.SET_PAGINATION_LIMIT: {
            state.pageSize = action.payload;
            return {
                ...state
            };
        }
        case ACTIONS.RESET: {
            return { ...action.payload };
        }
        case ACTIONS.SET:
            return { ...action.payload };
        case ACTIONS.PAGE_CHANGE:
            state.currentPage = action.payload.page;
            return {
                ...state
            };
        case ACTIONS.SORT: {
            if (state.sortColumn.path === action.payload.path)
                state.sortColumn.order =
                    state.sortColumn.order === 'asc' ? 'desc' : 'asc';
            else {
                state.sortColumn.path = action.payload.path;
                state.sortColumn.order = 'asc';
            }
            const columnsIndex = _.findIndex(state.columns, (col) => {
                return col.path === action.payload.path;
            });
            let sortedData;
            if (
                columnsIndex !== -1 &&
                _.has(state.columns[columnsIndex], 'customSort')
            ) {
                sortedData = _.orderBy(
                    state.data,
                    state.columns[columnsIndex]['customSort'],
                    [state.sortColumn.order]
                );
            } else {
                sortedData = _.orderBy(
                    state.data,
                    [state.sortColumn.path],
                    [state.sortColumn.order]
                );
            }
            return {
                ...state,
                sortedData: sortedData
            };
        }
        case ACTIONS.ROW_CLICK:
            return {
                ...state
            };
        case ACTIONS.ROW_SELECT:
            // state.data.map((el2) => {
            // 	if (el2.id === action.payload.value.id) {
            // 		if (el2.selectBox === '') {
            // 			el2.selectBox = 'selected';
            // 			state.settings.selected.total += 1;
            // 		} else {
            // 			el2.selectBox = '';
            // 			state.settings.selected.total -= 1;
            // 		}
            // 	}
            // 	return el2;
            // });
            // state.columns[0].selected = true;
            // state.data.map((el2) => {
            // 	if (el2.selectBox === '') {
            // 		state.columns[0].selected = false;
            // 	}
            // 	return el2;
            // });
            return {
                ...state
            };
        case ACTIONS.SELECT_ALL:
            state.columns[0].selected = true;
            state.data.map((el) => {
                el.selectBox = 'selected';
                return el;
            });
            state.settings.selected.total = state.data.length;
            return {
                ...state
            };
        case ACTIONS.UNSELECT_ALL:
            state.columns[0].selected = false;
            state.data.map((el) => {
                el.selectBox = '';
                return el;
            });
            state.settings.selected.total = 0;
            return {
                ...state
            };
        case ACTIONS.TOGGLE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    open:
                        typeof action.payload === 'boolean'
                            ? action.payload
                            : !state.settings.open
                }
            };
        case ACTIONS.LOADING:
            return {
                ...state,
                loading:
                    typeof action.payload === 'boolean'
                        ? action.payload
                        : !state.loading
            };
        case ACTIONS.RATIO:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    viewOptions: {
                        ...state.settings.viewOptions,
                        ratioOptions: {
                            ...state.settings.viewOptions.ratioOptions,
                            selectedOption: action.payload
                        }
                    }
                }
            };
        case ACTIONS.CHECKBOX:
            if (
                state.settings.viewOptions.checkboxOptions.selectedOptions.includes(
                    action.payload
                )
            ) {
                const index = _.findIndex(
                    state.settings.viewOptions.checkboxOptions.selectedOptions,
                    (el) => {
                        return el === action.payload;
                    }
                );
                if (index !== -1) {
                    return {
                        ...state,
                        settings: {
                            ...state.settings,
                            viewOptions: {
                                ...state.settings.viewOptions,
                                checkboxOptions: {
                                    ...state.settings.viewOptions
                                        .checkboxOptions,
                                    selectedOptions: [
                                        ...state.settings.viewOptions.checkboxOptions.selectedOptions.slice(
                                            0,
                                            index
                                        ),
                                        ...state.settings.viewOptions.checkboxOptions.selectedOptions.slice(
                                            index + 1,
                                            state.settings.viewOptions
                                                .checkboxOptions.selectedOptions
                                                .length
                                        )
                                    ]
                                }
                            }
                        }
                    };
                }
            } else {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        viewOptions: {
                            ...state.settings.viewOptions,
                            checkboxOptions: {
                                ...state.settings.viewOptions.checkboxOptions,
                                selectedOptions: [
                                    ...state.settings.viewOptions
                                        .checkboxOptions.selectedOptions,
                                    action.payload
                                ]
                            }
                        }
                    }
                };
            }
            break;
        case ACTIONS.TOGGLE_ACTION: {
            const newState = { ...state };
            const index = _.findIndex(newState.data, (el) => {
                return el.id === action.payload;
            });
            if (index !== -1) {
                if (newState.data[index].action.open) {
                    newState.data[index].action.open = false;
                } else {
                    newState.data[index].action.open = true;
                }
            }
            newState.data.map((el) => {
                if (el.id !== action.payload) {
                    el.action.open = false;
                }
                return el;
            });
            return {
                ...newState
            };
        }
        case ACTIONS.CLICK_ACTION_OPTION:
            return { ...state };
    }
}

export function useTable(defaultValue, modal, type) {
    const navigate = useNavigate();
    const [value, dispatch] = useReducer(reducer, defaultValue);

    function pageChange(page) {
        dispatch({ type: 'pageChange', payload: { page: page } });
    }

    function sort(path) {
        dispatch({ type: 'sort', payload: { path: path } });
    }

    function rowSelect(value) {
        dispatch({ type: 'rowClick', payload: { value: value } });
    }

    const rowClick = useCallback(
        (value) => {
            const params = new URLSearchParams();
            if (type === 'client') {
                params.set('client-id', value.clientId.toString());
                navigate({
                    pathname: '/client-details',
                    search: params.toString()
                });
            }
            dispatch({
                type: 'rowClick'
            });
        },
        [type, navigate]
    );

    function selectAll() {
        dispatch({ type: 'selectAll' });
    }

    function unselectAll() {
        dispatch({ type: 'unselectAll' });
    }

    const setLoading = useCallback((value) => {
        dispatch({ type: 'loading', payload: value });
    }, []);

    const setValue = useCallback((value) => {
        dispatch({ type: 'set', payload: value });
    }, []);

    const toggleComponentSettings = useCallback((value) => {
        dispatch({ type: 'toggleSettings', payload: value });
    }, []);
    function clickRatioOption(option) {
        dispatch({ type: 'ratio', payload: option });
    }

    function clickCheckboxOption(option) {
        dispatch({ type: 'checkbox', payload: option });
    }

    const clickActionIcon = (attr, id) => {
        dispatch({ type: 'toggleAction', payload: id });
    };

    const setPaginationLimit = useCallback((value) => {
        dispatch({ type: 'setPaginationLimit', payload: value });
    }, []);

    const setSearch = useCallback((value) => {
        dispatch({ type: 'setSearch', payload: value });
    }, []);

    const resetTable = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue });
    }, [defaultValue]);

    return {
        value,
        toggleComponentSettings,
        pageChange,
        sort,
        rowSelect,
        rowClick,
        selectAll,
        unselectAll,
        setLoading,
        setValue,
        clickRatioOption,
        clickCheckboxOption,
        clickActionIcon,
        resetTable,
        setPaginationLimit,
        setSearch
    };
}
