import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './numerical-segment-filter-tag.module.scss'
import { FilterTag } from '../../../filter-tag/filter-tag'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const NumericalSegmentFilterTag = React.memo(
    ({ name, operator, value, index }) => {
        const { t } = useCustomTranslation()
        return (
            <FilterTag index={index}>
                <span
                    className={`${styles['numerical-segment-filter-tag-container__title']} me-2`}
                >
                    {t(`layout:auxbar.filters.${name}`)}
                </span>
                <span
                    className={`${styles['numerical-segment-filter-tag-container__title']} me-2`}
                >
                    {operator}
                </span>
                <span
                    className={`${styles['numerical-segment-filter-tag-container__value']}`}
                >
                    {value}
                </span>
            </FilterTag>
        )
    }
)
