import React, { useCallback, useState } from 'react';
import styles from './ManageTeamMembersModal.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';
import { Modal } from '../modal/modal';
import { AddUserInput } from '../../inputs/add-user-input/AddUserInput';
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import icoUserExample from '../../../assets/images/icoUserExample.png';
import { ToggleUserInput } from '../../inputs/toggle-user-input/ToggleUserInput';
import icoDone from '../../../assets/images/icoDone.svg';

export const ManageTeamMembersModal = ({ onToggleModal }) => {
    const { t } = useCustomTranslation();
    const [currentStep, setCurrentStep] = React.useState(0);
    const teamName = useTextInputAlternate({
        name: 'teamName'
    });
    const [usersToAdd, setUsersToAdd] = useState([
        {
            name: 'User 1',
            email: 'Email 1',
            avatar: icoUserExample,
            isTeamLeader: true
        }
    ]);

    const [usersAdded, setUsersAdded] = useState([]);

    const [usersToToggle, setUsersToToggle] = useState([
        {
            name: 'User 1',
            email: 'Email 1',
            avatar: icoUserExample,
            added: false,
            isTeamLeader: true
        },
        {
            name: 'User 2',
            email: 'Email 2',
            avatar: icoUserExample,
            added: false
        },
        {
            name: 'User 3',
            email: 'Email 3',
            avatar: icoUserExample,

            added: false
        }
    ]);

    const handleAddUser = (user) => {
        setUsersAdded([...usersAdded, user]);
    };

    const handleRemoveUser = (user) => {
        setUsersAdded(usersAdded.filter((u) => u.email !== user.email));
    };

    const handleToggleUser = (user) => {
        setUsersToToggle(
            usersToToggle.map((u) => {
                if (u.email === user.email) {
                    return {
                        ...u,
                        added: !u.added
                    };
                }
                return u;
            })
        );
    };

    return (
        <Modal onToggleModal={onToggleModal}>
            <div
                className="position-relative d-flex flex-column justify-content-between align-items-center"
                style={{ width: '96rem', height: '50rem' }}
            >
                {currentStep === 0 && (
                    <>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-center">
                                <span
                                    className={
                                        styles[
                                            'manage-team-members-modal-container__title'
                                        ]
                                    }
                                >
                                    {t(`modals:manageTeamMembersModal.title`)}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-center">
                                <span
                                    className={
                                        styles[
                                            'manage-team-members-modal-container__subTitle'
                                        ]
                                    }
                                >
                                    {t(
                                        `modals:manageTeamMembersModal.subTitle`
                                    )}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 flex-grow-1"
                            style={{ minHeight: 0, marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <AddUserInput
                                    usersToAddTitle={t(
                                        'modals:manageTeamMembersModal.usersToAdd'
                                    )}
                                    usersToAddDescription={t(
                                        'modals:manageTeamMembersModal.usersToAddDescription'
                                    )}
                                    usersAddedTitle={t(
                                        'modals:manageTeamMembersModal.usersAdded'
                                    )}
                                    onAddUser={handleAddUser}
                                    onRemoveUser={handleRemoveUser}
                                    usersToAdd={usersToAdd}
                                    usersAdded={usersAdded}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-end">
                            <div
                                className="col-auto px-0"
                                style={{ marginRight: '2rem' }}
                            >
                                <TerciaryButton
                                    text={t(`buttons:back`)}
                                    onClick={onToggleModal}
                                />
                            </div>
                            <div className="col-auto px-0">
                                <PrimaryButton
                                    text={t(`buttons:next`)}
                                    onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
                {currentStep === 1 && (
                    <>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-center">
                                <span
                                    className={
                                        styles[
                                            'manage-team-members-modal-container__title'
                                        ]
                                    }
                                >
                                    {t(`modals:manageTeamMembersModal.title`)}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 flex-grow-1"
                            style={{
                                minHeight: 0,
                                marginTop: '1rem',
                                marginBottom: '2rem'
                            }}
                        >
                            <div className="col px-0">
                                <ToggleUserInput
                                    title={t(
                                        'modals:createTeamModal.usersToToggle'
                                    )}
                                    optionalTitle={t(
                                        'modals:createTeamModal.optionalTitle'
                                    )}
                                    subTitle={t(
                                        'modals:createTeamModal.usersAdded'
                                    )}
                                    onToggleUser={handleToggleUser}
                                    usersToToggle={usersToToggle}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-end">
                            <div
                                className="col-auto px-0"
                                style={{ marginRight: '2rem' }}
                            >
                                <TerciaryButton
                                    text={t(`buttons:back`)}
                                    onClick={() =>
                                        setCurrentStep(currentStep - 1)
                                    }
                                />
                            </div>
                            <div className="col-auto px-0">
                                <PrimaryButton
                                    text={t(`buttons:save`)}
                                    onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
                {currentStep === 2 && (
                    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                        <div className="col-auto px-0">
                                            <img
                                                src={icoDone}
                                                alt="done"
                                                style={{
                                                    width: '8rem',
                                                    height: '8rem'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center"
                                        style={{
                                            marginTop: '2rem'
                                        }}
                                    >
                                        <div className="col-auto px-0">
                                            <span
                                                className={
                                                    styles[
                                                        'manage-team-members-modal-container__done-1'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    `modals:manageTeamMembersModal.done1`
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center"
                                        style={{
                                            marginTop: '2rem'
                                        }}
                                    >
                                        <div className="col-auto px-0">
                                            <span
                                                className={
                                                    styles[
                                                        'manage-team-members-modal-container__done-2'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    `modals:manageTeamMembersModal.done2`
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center"
                            style={{
                                marginTop: '2rem'
                            }}
                        >
                            <div className="col-auto px-0">
                                <PrimaryButton
                                    text={t(`buttons:done`)}
                                    onClick={onToggleModal}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
