import { createContext, useContext, useMemo } from 'react';

const TableFilterContext = createContext({
    filters: ''
});

export const TableFilterProvider = ({ value, children }) => {
    const valueMemo = useMemo(() => {
        return value;
    }, [value]);

    return (
        <TableFilterContext.Provider value={valueMemo}>
            {children}
        </TableFilterContext.Provider>
    );
};

export function useTableFilterContext() {
    return useContext(TableFilterContext);
}
