import { CSS } from '@dnd-kit/utilities'
import React, { useMemo } from 'react'
import { useSortable } from '@dnd-kit/sortable'

export const Sortable = ({ children, id }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    }

    return (
        <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
            {children}
        </div>
    )
}
