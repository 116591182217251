import Joi from 'joi';
import _ from 'lodash';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import icoDelete from '../../../assets/images/ico-delete.svg';
import icoLayout from '../../../assets/images/ico-page-8.svg';
import icoSend from '../../../assets/images/ico-page-11.svg';
import icoToggle from '../../../assets/images/ico-page-20.svg';
import icoDownload from '../../../assets/images/ico-download.svg';

import icoEdit from '../../../assets/images/ico-edit.svg';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useTable } from '../../../hooks/useTable';
import { useTextInput } from '../../../hooks/useTextInput';
import { useToggle } from '../../../hooks/useToggle';
import {
    ReportService,
    ProfileService,
    TeamsService
} from '../../../temp/test';
import { reportsListMock } from './mock';
import {
    translateFromCronString,
    translateToCronString
} from '../../../utils/date';
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput';
import { verifyComponent } from '../../../utils/component';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { DeleteButton } from '../../buttons/delete-button/delete-button';
import { ReturnButton } from '../../buttons/return-button/return-button';
import { notification } from '../../notifications/notification/notification';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { Modal } from '../../modals/modal/modal';
import { SelectInput } from '../../inputs/select-input/select-input';
import { TypeInput } from '../../inputs/type-input/type-input';
import { SelectMultipleInput } from '../../inputs/select-multiple-input/select-multiple-input';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { fromBinary } from '../../../utils/encoding';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays logins in a table
 */

export const ReportsList = React.memo(({ onReportClick, onReportView }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const [selectedReportId, setSelectedReportId] = useState('');
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(reportsListMock, modal, '');
    const {
        value: cronString,
        toggleDropdown: cronStringToggleDropdown,
        selectOption: cronStringSelectOption,
        keyPress: cronStringKeyPress,
        keyDown: cronStringKeyDown,
        setValue: cronStringSetValue,
        setError: cronStringSetError,
        reset: cronStringReset
    } = useSelectInput({
        name: 'cronString',
        showOptions: true,
        options: [
            t('settings:never'),
            t('settings:daily'),
            t('settings:weekly'),
            t('settings:biWeekly'),
            t('settings:monthly')
        ],
        optionsAux: ['never', 'daily', 'weekly', 'biWeekly', 'monthly'],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const {
        value: title,
        reset: titleReset,
        setValue: titleSetValue,
        setError: titleSetError,
        replaceValue: titleReplaceValue
    } = useTextInput({
        name: 'title',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: teamIds,
        toggleDropdown: teamIdsToggleDropdown,
        selectOption: teamIdsSelectOption,
        unselectOption: teamIdsUnselectOption,
        keyPress: teamIdsKeyPress,
        keyDown: teamIdsKeyDown,
        setValue: teamIdsSetValue,
        replaceAll: teamIdsReplaceAll
    } = useSelectMultipleInput({
        name: 'teamIds',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOptions: [],
        selectedOptionsAux: [],
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const {
        value: userIds,
        toggleDropdown: userIdsToggleDropdown,
        selectOption: userIdsSelectOption,
        unselectOption: userIdsUnselectOption,
        keyPress: userIdsKeyPress,
        keyDown: userIdsKeyDown,
        setValue: userIdsSetValue,
        replaceAll: userIdsReplaceAll
    } = useSelectMultipleInput({
        name: 'userIds',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOptions: [],
        selectedOptionsAux: [],
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const schema = Joi.object({
        title: Joi.string().required(),
        cronString: Joi.string().required(),
        teamIds: Joi.array().items(Joi.string()),
        userIds: Joi.array().items(Joi.string())
    });

    const getReports = useCallback(async () => {
        const dataClone = _.cloneDeep(reportsListMock);
        try {
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            if (isMounted.current) setValue(dataClone);
            if (isMounted.current) setLoading(true);
            const reportFilterSchema = {
                created_by: undefined,
                email_after_generate: undefined,
                id: undefined,
                report_type: undefined,
                scheduled: undefined,
                tag: undefined,
                teams: undefined,
                users: undefined
            };
            const reportDataBack =
                await ReportService.getApiBackofficeReporting({
                    reportFilterSchema: reportFilterSchema
                });
            reportDataBack.map((el) => {
                const options = [
                    {
                        name: 'testSendReport',
                        label: t('settings:testSendReport'),
                        icon: icoSend
                    },
                    {
                        name: 'editReportDetails',
                        label: t('settings:editReportDetails'),
                        icon: icoEdit
                    },
                    {
                        name: 'editReportLayout',
                        label: t('settings:editReportLayout'),
                        icon: icoLayout
                    },
                    {
                        name: 'downloadReport',
                        label: t('settings:downloadReport'),
                        icon: icoDownload
                    },
                    {
                        name: 'removeReport',
                        label: t('settings:removeReport'),
                        icon: icoDelete
                    }
                ];
                if (
                    translateFromCronString(el.schedule_cron_string) !== 'never'
                ) {
                    options.push({
                        name: 'toggleReportSchedule',
                        label: t('settings:toggleReportSchedule'),
                        icon: icoToggle
                    });
                }
                let users = '';
                el.users.map((el2, index) => {
                    users += el2.name;
                    if (index !== el.users.length - 1) {
                        users += ', ';
                    }
                    return el;
                });
                const newEl = {
                    id: el.id,
                    createdBy: el.created_user.name,
                    reportType: el.report_type,
                    periodicity: el.schedule_cron_string
                        ? translateFromCronString(el.schedule_cron_string)
                        : '-',
                    tag: el.tag,
                    status: {
                        value: el.scheduled
                            ? t(`columns:active`)
                            : t(`columns:inactive`),
                        color: el.scheduled ? '#34b53a' : '#d0021b'
                    },
                    users: users,
                    teams: '-',
                    action: {
                        label: t(`columns:action`),
                        id: el.id,
                        open: false,
                        options: [...options],
                        reference: React.createRef()
                    }
                };
                dataClone.data.push(newEl);
                return el;
            });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            verifyComponent(dataClone);
            if (isMounted.current) setValue(dataClone);
            setLoading(false);
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue, t]);

    const handleDeleteReport = async () => {
        try {
            await ReportService.deleteApiBackofficeReporting({
                reportId: selectedReportId
            });

            if (isMounted.current) {
                toggleModal(false);
                setLoading(false);
            }
            getReports();
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleEditReport = async () => {
        try {
            const result = schema.validate(
                {
                    cronString: cronString.selectedOption,
                    title: title.value
                },
                { abortEarly: false }
            );
            cronStringSetError();
            titleSetError();
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'cronString') {
                        cronStringSetError(el.type);
                    } else if (el.path[0] === 'title') {
                        titleSetError(el.type);
                    }
                    return null;
                });
                return;
            }

            const reportToEdit = await ReportService.getApiBackofficeReporting1(
                {
                    reportId: selectedReportId
                }
            );
            const cronValue = translateToCronString(
                cronString.selectedOptionAux
            );
            const reportSchema = {
                created_by: reportToEdit.created_by,
                email_after_generate: reportToEdit.email_after_generate,
                report_type: reportToEdit.report_type,
                schedule_cron_string: cronValue,
                tag: reportToEdit.tag,
                template: reportToEdit.template
            };
            console.log('fix: check readonly properties');
            await ReportService.putApiBackofficeReporting({
                reportId: selectedReportId,
                requestBody: reportSchema
            });
            await ReportService.putApiBackofficeReportingUsers({
                reportId: selectedReportId,
                requestBody: [...userIds.selectedOptionsAux]
            });
            await ReportService.putApiBackofficeReportingTeams({
                reportId: selectedReportId,
                requestBody: [...teamIds.selectedOptionsAux]
            });
            handleCloseModal();
            getReports();
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleToggleSchedule = useCallback(
        async (reportId) => {
            await ReportService.getApiBackofficeReportingActivateScheduling({
                reportId: reportId
            });
            getReports();
        },
        [getReports]
    );

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false);
            setModalType('');
            setSelectedReportId('');
        }
    };

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedReportId(id);
                clickActionIcon(attr, id);
            }
            if (option === 'editReportDetails') {
                if (isMounted.current) {
                    cronStringReset();
                    titleReset();
                    console.log('fix: check names for endpoints ending with 1');
                    const result =
                        await ReportService.getApiBackofficeReporting1({
                            reportId: id
                        });
                    titleReplaceValue(result.tag);
                    cronStringSelectOption(
                        t(
                            `settings:${
                                result.schedule_cron_string
                                    ? translateFromCronString(
                                          result.schedule_cron_string
                                      )
                                    : 'never'
                            }`
                        ),
                        translateFromCronString(result.schedule_cron_string)
                    );
                    const newUserIds = {
                        name: 'userIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    const newTeamIds = {
                        name: 'teamIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    const userBackData =
                        await ProfileService.getApiProfileListAccounts();
                    userBackData.map((el) => {
                        if (
                            _.findIndex(result.users, (el2) => {
                                return el2.uuid === el.uuid;
                            }) !== -1
                        ) {
                            newUserIds.selectedOptions.push(el.username);
                            newUserIds.selectedOptionsAux.push(el.uuid);
                        } else {
                            newUserIds.options.push(el.username);
                            newUserIds.optionsAux.push(el.uuid);
                        }
                        return el;
                    });

                    const teamBackData = await TeamsService.getApiTeams({
                        params: {},
                        leaderEmpNo: undefined
                    });
                    teamBackData.map((el) => {
                        newTeamIds.options.push(el.name);
                        newTeamIds.optionsAux.push(el.id);
                        return el;
                    });
                    userIdsReplaceAll(newUserIds);
                    teamIdsReplaceAll(newTeamIds);
                }
            } else if (option === 'editReportLayout') {
                onReportClick(id);
            } else if (option === 'toggleReportSchedule') {
                handleToggleSchedule(id);
            } else if (option === 'downloadReport') {
                try {
                    //     const reportDataBack =
                    //     await ReportService.getApiBackofficeReportingDownload({
                    //         reportId: id,
                    //     })

                    // console.log(reportDataBack)

                    // const document2 = new File(
                    //     [new Blob([atob(reportDataBack)], 'application/pdf')],
                    //     'asd.pdf'
                    // )
                    // saveAs(document2)
                    const reportDataBack =
                        await ReportService.getApiBackofficeReportingDownload({
                            reportId: id,
                            encodeB64: true
                        });
                    const blob = new Blob([fromBinary(reportDataBack)], {
                        type: 'application/pdf'
                    });
                    // Create an anchor tag and trigger the download
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'report.pdf';
                    document.body.appendChild(link); // Append link to body
                    link.click(); // Simulate click to start download
                    document.body.removeChild(link); // Remove the link when done
                } catch (err) {
                    errorHandler(err);
                }
            }
            if (isMounted.current) {
                toggleComponentSettings();
                toggleModal(true);
                setModalType(option);
            }
        },
        [
            onReportClick,
            handleToggleSchedule,
            cronStringReset,
            cronStringSelectOption,
            isMounted,
            t,
            titleReplaceValue,
            titleReset,
            toggleComponentSettings,
            toggleModal,
            teamIdsReplaceAll,
            userIdsReplaceAll
        ]
    );

    const handleSimulateSendReport = async () => {
        try {
            handleCloseModal();
            notification({
                statusText: undefined,
                text: `${t('notifications:reportSent')} `,
                date: undefined
            });
            console.log('fix: should property encode_64 be here?');
            await ReportService.getApiBackofficeReportingGenerate({
                reportId: selectedReportId
            });
            // let myImage = new Image();
            // let imgHeight;
            // let imgWidth;
            // myImage.onload = async function () {
            // 	imgHeight = this.height;
            // 	imgWidth = this.width;
            // 	let ratio = imgHeight / imgWidth;
            // 	let newHeight = 255 * ratio > 255 ? 255 * ratio : 255;
            // 	const doc = new jsPDF('p', 'mm', [255, newHeight]);
            // 	doc.addImage(
            // 		'data:image/jpg;base64,' + newPng,
            // 		'jpg',
            // 		0,
            // 		0,
            // 		255,
            // 		255 * ratio,
            // 		undefined,
            // 		'FAST'
            // 	);
            // 	let blob = doc.output('blob');
            // 	const document2 = new File([blob], 'asd.pdf');
            // 	saveAs(document2);
            // 	await ReportService.patchApiBackofficeReporting({
            // 		reportId: selectedReportId,
            // 		formData: { file: document2 },
            // 	});
            // 	return true;
            // };
            // myImage.src = 'data:image/jpg;base64,' + newPng;
        } catch (err) {
            errorHandler(err);
        }
    };

    useEffect(() => {
        getReports();
    }, [getReports]);

    return (
        <ContentContainer
            attr={data.attr}
            hidePinIcon
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onCloseSettings={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <React.Fragment>
                    {modal && modalType === 'editReportDetails' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('modals:editUser')}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <TypeInput
                                        onChangeInput={titleSetValue}
                                        {...title}
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <SelectInput
                                        onChangeInput={cronStringSetValue}
                                        onSelectInput={cronStringSelectOption}
                                        onKeyPress={cronStringKeyPress}
                                        onKeyDown={cronStringKeyDown}
                                        onToggleDropdown={
                                            cronStringToggleDropdown
                                        }
                                        {...cronString}
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <SelectMultipleInput
                                        hideOptionsAux
                                        disabled={teamIds.disabled}
                                        onChangeInput={teamIdsSetValue}
                                        onSelectMultiple={teamIdsSelectOption}
                                        onSelectedOptionClick={
                                            teamIdsUnselectOption
                                        }
                                        onKeyPress={teamIdsKeyPress}
                                        onKeyDown={teamIdsKeyDown}
                                        onToggleDropdown={teamIdsToggleDropdown}
                                        {...teamIds}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row ">
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <SelectMultipleInput
                                        hideOptionsAux
                                        disabled={userIds.disabled}
                                        onChangeInput={userIdsSetValue}
                                        onSelectMultiple={userIdsSelectOption}
                                        onSelectedOptionClick={
                                            userIdsUnselectOption
                                        }
                                        onKeyPress={userIdsKeyPress}
                                        onKeyDown={userIdsKeyDown}
                                        onToggleDropdown={userIdsToggleDropdown}
                                        {...userIds}
                                    />
                                </div>
                            </div>
                            <hr
                                className="my-4"
                                style={{
                                    color: 'rgba(0,0,0,0.1)',
                                    opacity: '1'
                                }}
                            />
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-auto px-0">
                                    <SecondaryButton
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t('modals:save')}
                                        onClick={handleEditReport}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    {modal && modalType === 'removeReport' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('pages:managerProfile.areyousure')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-auto px-0">
                                    <SecondaryButton
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        type={'delete'}
                                        text={t('modals:remove')}
                                        onClick={handleDeleteReport}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    {modal && modalType === 'testSendReport' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('pages:managerProfile.areyousure')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-auto px-0">
                                    <SecondaryButton
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t('modals:sendReport')}
                                        onClick={handleSimulateSendReport}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    <Table
                        actions={data.actions}
                        // onClickActionIcon={onClickActionIcon}
                        // onClickActionOption={onClickActionOption}
                        // onCloseActions={onCloseActions}
                        attr={data.attr}
                        type={data.type}
                        titleCounter={data.titleCounter}
                        titleText={t(`components:${data.attr}.title`)}
                        placeholderText={t(`components:${data.attr}.nodata`)}
                        columns={data.columns}
                        data={data.sortedData}
                        sortColumn={data.sortColumn}
                        onSort={dataSort}
                        currentPage={data.currentPage}
                        pageSize={data.pageSize}
                        onPageChange={dataPageChange}
                        onRowClick={
                            !_.find(data.data, (el) => {
                                return el.action.open;
                            })
                                ? onReportView
                                : undefined
                        }
                        onRowSelect={undefined}
                        onSelectAll={dataSelectAll}
                        onUnselectAll={dataUnselectAll}
                        paginationDisabled={data.paginationDisabled}
                        disabled={data.disabled}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                    ></Table>
                </React.Fragment>
            )}
        </ContentContainer>
    );
});

ReportsList.propTypes = {};
