import React from 'react';
import { GeneralTable } from '../../../../../components/dashboard-components/general-table/general-table';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { pendingInvitationsListMock } from './mock';
import { usePlatformFormat } from '../../../../../providers/platform-format-context';

export const PendingInvitationsList = () => {
    const { t } = useCustomTranslation();
    const { format } = usePlatformFormat();
    return (
        <GeneralTable
            title={t(`components:${pendingInvitationsListMock.attr}.title`)}
            attr={pendingInvitationsListMock.attr}
            columns={
                format === 'enterprise'
                    ? pendingInvitationsListMock.enterpriseColumns
                    : pendingInvitationsListMock.organizationColumns
            }
            filterColumns={pendingInvitationsListMock.filterColumns}
            mockData={pendingInvitationsListMock.data}
            withSearch
            hideTitle
            noDataText={t(
                `components:${pendingInvitationsListMock.attr}.nodata`
            )}
        />
    );
};
