import _, { over } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDashboardSettings } from '../../providers/dashboard-settings-context'
import { useAuxBar } from '../../providers/aux-bar-context'
import { useToggle } from '../../hooks/useToggle'
import { useModalPopup } from '../../providers/modal-popup-context'
import { useHoveredComponentsAndFilters } from '../../providers/hovered-components-and-filters-context'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useDynamicDashboard } from './useDynamicDashboard'
import { useRatioInput } from '../../hooks/useRatioInput'
import { useCheckboxInput } from '../../hooks/useCheckboxInput'
import componentConfig from '../../configs/componentLibraryConf.json'
import { useArray } from '../../hooks/useArray'
import { DTablesService, PagesService } from '../../temp/test'
import customDashboardLayout from '../../mock/dashboards/custom-dashboard-layout.json'
import { errorHandler } from '../../utils/api'
import { DragComponentProvider } from '../../providers/drag-component-context'
import { ComponentFilterProvider } from '../../providers/component-filter-context'
import { FilterInputsProvider } from '../../providers/filter-inputs-context'
import { Scrollable } from '../../components'
import { pageMap } from '../../constants/pageMap'
import { PageDetailsProvider } from '../../providers/page-details-context'
import { useSub } from '../../hooks/usePubSub'
import Auxbar from '../../layout/auxbar/auxbar'
import { useHighlightedChart } from '../../providers/highlighted-chart-context'
import Navbar from '../../layout/navbar/navbar'
import { isJsonString } from '../../utils/json'
import { componentsFit, fitComponents } from '../../utils/componentGrid'
import { ModalProvider } from '../../providers/modal-context'
import dynamicComponentConfig from '../../configs/dynamicInfoComponents.json'

/**
 * Provides functionality for managing dynamic dashboards
 */

const getComponentsLegacy = (page) => {
    if (_.has(page, 'notAvailableCharts') && page.notAvailableCharts && page.notAvailableCharts.length > 0) {
        return page.notAvailableCharts
    } else if (_.has(page, 'availableCharts' && page.availableCharts && page.availableCharts.length > 0)) {
        return page.availableCharts
    } else return []
}

const DynamicDashboard = ({ routes, component, onUpdateRoute, onPublishRoute, onRemoveRoute, onAddRoute, routeUrl }) => {
    const [isReady, setIsReady] = useState(false)
    const [loading, toggleLoading] = useToggle(true)
    const { setChartFilters } = useAuxBar()
    const { highlightedChart } = useHighlightedChart()
    const { steps, selectedTutorialStep, currentStep, setCurrentStep } = useModalPopup()
    const { isCustom } = useDashboardSettings()
    const { hoveredComponents, hoveredFilter, setHoveredComponents, setHoveredFilter } = useHoveredComponentsAndFilters()
    const location = useLocation()
    const isMounted = useIsMounted()
    const {
        value: dynamicInfo,
        setValue,
        setFilterInputs,
        setNotAvailableCharts,
        setNotAvailableChartValue,
        setNotAvailableChartSize,
        setLayout,
        setResizableGrid,
        setAvailableCharts,
    } = useDynamicDashboard({
        dashboardTitle: '',
        isHome: '',
        notAvailableCharts: [],
        availableCharts: [],
        filterInputs: [],
        resizableGrid: {
            compactType: 'vertical',
            verticalCompact: undefined,
            rowHeight: 40,
            maxRows: undefined,
            isEditable: true,
            isDroppable: true,
            isBounded: false,
            autoSize: true,
            draggableHandle: '.drag-handle-ref',
            containerPadding: [14, 14],
            margin: [14, 14],
            layout: [],
            droppingElement: {},
            gridMounted: false,
            reference: useRef(),
        },
    })
    const { array: dropdownList, setArray: setDropdownList } = useArray([])
    const [nextLayout, setNextLayout] = useState('')
    const [nextLayoutItem, setNextLayoutItem] = useState('')

    const [componentFilter, setComponentFilter] = useState('')

    const {
        value: componentInfo,
        addOption: componentInfoAddOption,
        reset: componentInfoReset,
        clickRatioOption: componentInfoClickRatioOption,
    } = useRatioInput({
        options: [],
        selectedOption: '',
    })

    const {
        value: componentAdditionalInfo,
        addOption: componentAdditionalInfoAddOption,
        setCheckboxLimit: componentAdditionalInfoSetLimit,
        reset: componentAdditionalInfoReset,
    } = useCheckboxInput({
        options: [],
        selectedOptions: [],
    })

    const handlePageLayoutChange = useCallback(
        (page, layout) => {
            const resizableGridClone = _.cloneDeep(dynamicInfo.resizableGrid)
            const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
            resizableGridClone.layout = layout
            notAvailableChartsClone.map((el) => {
                resizableGridClone.layout.map((el2) => {
                    if (el.dataGrid.i === el2.i) {
                        el.dataGrid.w = el2.w
                        el.dataGrid.h = el2.h
                        el.dataGrid.x = el2.x
                        el.dataGrid.y = el2.y
                    }
                    return el2
                })
                return el
            })
            if (isMounted.current) {
                setNotAvailableCharts(notAvailableChartsClone)
                setResizableGrid(resizableGridClone)
            }
        },
        [isMounted, dynamicInfo, setNotAvailableCharts, setResizableGrid]
    )

    const handleOpenChartConfig = useCallback(
        async (a, type) => {
            if (isMounted.current) {
                componentInfoReset()
                componentAdditionalInfoReset()
            }
            if (type.split('-')[0] === 'dTableInfoCard') {
                if (componentConfig[type.split('-')[0]].dTableInfoType === 'replace') {
                    const dTableBack = await DTablesService.getApiBackofficeAdminDtables({
                        tableType: [],
                    })
                    dTableBack.map((el) => {
                        if (el.table_type === 'pos_id' && componentConfig[type.split('-')[0]].dTableInfo === 'clientId') {
                            el.columns.map((el2) => {
                                if (isMounted.current && el2.variable_name !== el.table_type)
                                    componentInfoAddOption({
                                        name: el2.variable_name,
                                        label: el2.variable_name,
                                        color: null,
                                    })
                                return el2
                            })
                        } else if (el.table_type === 'client_id' && componentConfig[type.split('-')[0]].dTableInfo === 'clientId') {
                            el.columns.map((el2) => {
                                if (isMounted.current && el2.variable_name !== el.table_type)
                                    componentInfoAddOption({
                                        name: el2.variable_name,
                                        label: el2.variable_name,
                                        color: null,
                                    })
                                return el2
                            })
                        }
                        if (el.table_type === 'id' && componentConfig[type.split('-')[0]].dTableInfo === 'productId') {
                            el.columns.map((el2) => {
                                if (isMounted.current && el2.variable_name !== el.table_type)
                                    componentInfoAddOption({
                                        name: el2.variable_name,
                                        label: el2.variable_name,
                                        color: null,
                                    })
                                return el2
                            })
                        }
                        return el
                    })
                    if (isMounted.current) componentInfoClickRatioOption('', '', dTableBack[0].columns[0].variable_name)
                }
                if (componentConfig[type.split('-')[0]].dTableInfoType === 'complement') {
                    const dTableBack = await DTablesService.getApiBackofficeAdminDtables({
                        tableType: [],
                    })
                    dTableBack.map((el) => {
                        if (el.table_type === 'pos_id' && componentConfig[type.split('-')[0]].dTableInfo === 'clientId') {
                            el.columns.map((el2) => {
                                if (isMounted.current)
                                    componentAdditionalInfoAddOption({
                                        name: el2.variable_name,
                                        label: el2.variable_name,
                                        color: null,
                                    })
                                return el2
                            })
                        } else if (el.table_type === 'client_id' && componentConfig[type.split('-')[0]].dTableInfo === 'clientId') {
                            el.columns.map((el2) => {
                                if (isMounted.current)
                                    componentAdditionalInfoAddOption({
                                        name: el2.variable_name,
                                        label: el2.variable_name,
                                        color: null,
                                    })
                                return el2
                            })
                        }
                        if (el.table_type === 'id' && componentConfig[type.split('-')[0]].dTableInfo === 'productId') {
                            el.columns.map((el2) => {
                                if (isMounted.current)
                                    componentAdditionalInfoAddOption({
                                        name: el2.variable_name,
                                        label: el2.variable_name,
                                        color: null,
                                    })
                                return el2
                            })
                        }
                        return el
                    })

                    componentAdditionalInfoSetLimit(componentConfig[type.split('-')[0]].dTableInfoLimit)
                }
            }
        },
        [
            componentAdditionalInfoReset,
            componentAdditionalInfoAddOption,
            componentAdditionalInfoSetLimit,
            componentInfoAddOption,
            componentInfoClickRatioOption,
            componentInfoReset,
            isMounted,
        ]
    )

    const handlePageDropChart = useCallback(
        (page, layout, layoutItem) => {
            const resizableGridClone = _.cloneDeep(dynamicInfo.resizableGrid)
            if (resizableGridClone.droppingElement && !_.isEmpty(resizableGridClone.droppingElement)) {
                if (resizableGridClone.droppingElement.type.split('-')[0] === 'dTableInfoCard') {
                    const availableChartsClone = _.cloneDeep(dynamicInfo.availableCharts)
                    const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
                    setNextLayout(layout)
                    setNextLayoutItem(layoutItem)
                    handleOpenChartConfig('', resizableGridClone.droppingElement.type.split('-')[0], '', true)
                    const index = availableChartsClone.findIndex((el) => {
                        return el.type === resizableGridClone.droppingElement.type.split('-')[0]
                    })
                    if (index !== -1) {
                        const newEl = _.cloneDeep(availableChartsClone[index])
                        notAvailableChartsClone.unshift({
                            ...newEl,
                            dataGrid: {
                                ...newEl.dataGrid,
                                x: layoutItem.x,
                                y: layoutItem.y,
                            },
                            config: {
                                substituteInfo: '',
                                additionalInfo: [],
                                info: newEl.type.split('-')[0],
                                time: '',
                                filter: _.has(componentConfig[newEl.type.split('-')[0]].configList, 'startingFilter')
                                    ? [...componentConfig[newEl.type.split('-')[0]].configList.startingFilter]
                                    : [...componentConfig[newEl.type.split('-')[0]].configList.filter],
                                filterRequired: false,
                            },
                        })
                        resizableGridClone.droppingElement = {}
                        if (isMounted.current) {
                            setNotAvailableCharts(notAvailableChartsClone)
                            setResizableGrid(resizableGridClone)
                        }
                    }
                } else {
                    const availableChartsClone = _.cloneDeep(dynamicInfo.availableCharts)
                    const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
                    const index = availableChartsClone.findIndex((el) => {
                        return el.type === resizableGridClone.droppingElement.type.split('-')[0]
                    })
                    const { isDynamicComponent } = resizableGridClone.droppingElement
                    if (index !== -1) {
                        resizableGridClone.layout = layout
                        notAvailableChartsClone.map((el) => {
                            resizableGridClone.layout.map((el2) => {
                                if (el.dataGrid.i === el2.i) {
                                    el.dataGrid.w = el2.w
                                    el.dataGrid.h = el2.h
                                    el.dataGrid.x = el2.x
                                    el.dataGrid.y = el2.y
                                }
                            })
                        })
                        const newEl = _.cloneDeep(availableChartsClone[index])
                        notAvailableChartsClone.unshift({
                            ...newEl,
                            dataGrid: {
                                ...resizableGridClone.droppingElement,
                                x: layoutItem.x,
                                y: layoutItem.y,
                            },
                            config: {
                                substituteInfo: '',
                                additionalInfo: [],
                                info: newEl.type,
                                time: '',
                                filter: _.has(componentConfig[newEl.type.split('-')[0]].configList, 'startingFilter')
                                    ? [...componentConfig[newEl.type.split('-')[0]].configList.startingFilter]
                                    : [...componentConfig[newEl.type.split('-')[0]].configList.filter],
                                filterRequired: false,
                            },
                        })
                        resizableGridClone.droppingElement = {}
                        if (isMounted.current) {
                            setNotAvailableCharts(notAvailableChartsClone)
                            setResizableGrid(resizableGridClone)
                        }
                    } else if (isDynamicComponent) {
                        const newEl = _.cloneDeep(resizableGridClone.droppingElement)
                        notAvailableChartsClone.unshift({
                            ...newEl,
                            dataGrid: {
                                ...resizableGridClone.droppingElement.dataGrid,
                                x: layoutItem.x,
                                y: layoutItem.y,
                            },
                            config: {
                                substituteInfo: '',
                                additionalInfo: [],
                                info: newEl.type,
                                time: '',
                                filter: _.has(dynamicComponentConfig[newEl.type.split('-')[0]].configList, 'startingFilter')
                                    ? [...dynamicComponentConfig[newEl.type.split('-')[0]].configList.startingFilter]
                                    : [...dynamicComponentConfig[newEl.type.split('-')[0]].configList.filter],

                                filterRequired: false,
                            },
                        })
                        resizableGridClone.droppingElement = {}
                        resizableGridClone.layout = layout
                        if (isMounted.current) {
                            setNotAvailableCharts(notAvailableChartsClone)
                            setResizableGrid(resizableGridClone)
                        }
                    }
                }
            }
        },
        [handleOpenChartConfig, isMounted, dynamicInfo, setNotAvailableCharts, setResizableGrid]
    )

    const handleDragComponentStart = useCallback(
        async (e, chartName) => {
            const availableChartsClone = _.cloneDeep(dynamicInfo.availableCharts)
            const resizableGridClone = _.cloneDeep(dynamicInfo.resizableGrid)
            e.dataTransfer.setData('text/plain', '')
            let droppingElementIndex = _.findIndex(availableChartsClone, (el) => {
                return el.type.split('-')[0] === chartName.split('-')[0]
            })
            const minIndexComponent = _.maxBy(dynamicInfo.notAvailableCharts, (el2) => {
                return parseInt(el2.dataGrid.i.split('-')[1])
            })
            const minIndexComponentNextIndex = minIndexComponent ? parseInt(minIndexComponent.dataGrid.i.split('-')[1]) + 1 : 0
            availableChartsClone[droppingElementIndex].type = chartName.split('-')[0]
            availableChartsClone[droppingElementIndex].dataGrid = {
                ...availableChartsClone[droppingElementIndex].dataGrid,
                name: chartName.split('-')[0],
                i: chartName.split('-')[0] + '-' + minIndexComponentNextIndex,
            }
            if (droppingElementIndex !== -1) {
                resizableGridClone.droppingElement = {
                    ...availableChartsClone[droppingElementIndex].dataGrid,
                }
                resizableGridClone.droppingElement.type = chartName.split('-')[0] + '-' + minIndexComponentNextIndex
            }
            if (isMounted.current) {
                setResizableGrid(resizableGridClone)
            }
        },
        [dynamicInfo, isMounted, setResizableGrid]
    )

    const handleDragDynamicInfoComponentStart = useCallback(
        async (e, chart) => {
            const resizableGridClone = _.cloneDeep(dynamicInfo.resizableGrid)
            e.dataTransfer.setData('text/plain', '')
            // check if there are already charts in the grid to find the next index
            const minIndexComponent = _.maxBy(dynamicInfo.notAvailableCharts, (el2) => {
                return parseInt(el2.dataGrid.i.split('-')[1])
            })
            const minIndexComponentNextIndex = minIndexComponent ? parseInt(minIndexComponent.dataGrid.i.split('-')[1]) + 1 : 0

            resizableGridClone.droppingElement = {
                ...chart,
                dataGrid: {
                    ...chart.dataGrid,
                    i: chart.type.split('-')[0] + '-' + minIndexComponentNextIndex,
                },
                i: chart.type.split('-')[0] + '-' + minIndexComponentNextIndex,
            }
            setResizableGrid(resizableGridClone)
        },
        [dynamicInfo, setResizableGrid]
    )

    const handleDeletePageChart = useCallback(
        (page, chartName) => {
            let index
            const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
            index = notAvailableChartsClone.findIndex((el) => el.type === chartName)
            if (index !== -1) {
                notAvailableChartsClone.splice(index, 1)
                if (isMounted.current) {
                    setNotAvailableCharts(notAvailableChartsClone)
                }
            }
        },
        [dynamicInfo, isMounted, setNotAvailableCharts]
    )

    const handleDropdownCheck = useCallback((charts) => {
        const newFilterInputs = []
        charts.map((el) => {
            if (el.config.filter && el.config.filter.length > 0) {
                el.config.filter.map((el2) => {
                    if (!newFilterInputs.includes(el2)) {
                        newFilterInputs.push(el2)
                    }
                    return el2
                })
            }
            return el
        })
        return newFilterInputs
    }, [])

    const handleCustomDashboard = useCallback(async () => {
        toggleLoading(true)

        const initializeDynamicInfo = () => ({
            dashboardTitle: '',
            isHome: '',
            notAvailableCharts: [],
            availableCharts: [],
            filterInputs: [],
            resizableGrid: {
                compactType: 'vertical',
                verticalCompact: undefined,
                rowHeight: 40,
                maxRows: undefined,
                isEditable: true,
                isDroppable: true,
                isBounded: false,
                autoSize: true,
                draggableHandle: '.drag-handle-ref',
                containerPadding: [14, 14],
                margin: [14, 14],
                layout: [],
                droppingElement: {},
                gridMounted: false,
                reference: React.createRef(),
            },
        })

        const updateStepIfNeeded = () => {
            if (
                steps &&
                selectedTutorialStep !== '' &&
                steps[selectedTutorialStep]['steps'][currentStep]['waitUntilTrigger'] &&
                steps[selectedTutorialStep]['steps'][currentStep]['triggerPoint'] === 'navigateToHome' &&
                routeUrl === '/dashboard'
            ) {
                setCurrentStep(currentStep + 1)
            }
        }

        const updateDynamicInfo = async (getPageData) => {
            const content = JSON.parse(getPageData.homepage.content)
            newDynamicInfo.dashboardTitle = content.dashboardTitle
            newDynamicInfo.availableCharts = content.availableCharts
            newDynamicInfo.notAvailableCharts = content.notAvailableCharts
            newDynamicInfo.filterInputs = handleDropdownCheck(content.notAvailableCharts)
        }

        const createNewHomepageContent = async () => {
            const layoutKey = routeUrl.replaceAll('/', '')
            const layout = customDashboardLayout[layoutKey]
            const jsonToSend = {
                dashboardTitle: layout.dashboardTitle,
                dropdownList: layout.dropdownList,
                availableCharts: layout.availableCharts,
                notAvailableCharts: layout.notAvailableCharts,
            }
            await PagesService.putApiPagesHomepage({
                requestBody: { content: JSON.stringify(jsonToSend) },
            })
            newDynamicInfo.dashboardTitle = layout.dashboardTitle
            newDynamicInfo.availableCharts = layout.availableCharts
            newDynamicInfo.notAvailableCharts = layout.notAvailableCharts
            newDynamicInfo.filterInputs = handleDropdownCheck(layout.notAvailableCharts)
        }

        const handleDashboardRoute = async () => {
            const getPageData = await PagesService.getApiPagesMyPages()
            newDynamicInfo.isHome = true
            if (!_.isEmpty(getPageData.homepage.content)) {
                await updateDynamicInfo(getPageData)
            } else {
                await createNewHomepageContent()
            }
        }

        const handleCustomRoute = async () => {
            newDynamicInfo.isHome = false
            const getPageData = await PagesService.getApiPages1({
                url: routeUrl.replace('/', ''),
            })
            let toUpdate = false
            let filterInputs = []
            if (!isJsonString(getPageData.content)) {
                newDynamicInfo.dashboardTitle = location.pathname.split('/')[1]
                filterInputs = handleDropdownCheck([])
                newDynamicInfo.availableCharts = Object.values(componentConfig)
                toUpdate = true
            } else {
                const content = JSON.parse(getPageData.content)
                newDynamicInfo.dashboardTitle = content.dashboardTitle
                newDynamicInfo.notAvailableCharts = getComponentsLegacy(content).map((el) => {
                    el.config.filter = el.config.filter.filter((el2) => el2 !== 'none')
                    el.dataGrid = {
                        ...el.dataGrid,
                        w: _.has(componentConfig, el.type.split('-')[0])
                            ? Math.max(el.dataGrid.w, componentConfig[el.type.split('-')[0]].dataGrid.minW)
                            : Math.max(el.dataGrid.w, dynamicComponentConfig[el.type.split('-')[0]].dataGrid.minW),
                        h: _.has(componentConfig, el.type.split('-')[0])
                            ? Math.max(el.dataGrid.h, componentConfig[el.type.split('-')[0]].dataGrid.minH)
                            : Math.max(el.dataGrid.h, dynamicComponentConfig[el.type.split('-')[0]].dataGrid.minH),
                        minW: _.has(componentConfig, el.type.split('-')[0])
                            ? componentConfig[el.type.split('-')[0]].dataGrid.minW
                            : dynamicComponentConfig[el.type.split('-')[0]].dataGrid.minW,
                        minH: _.has(componentConfig, el.type.split('-')[0])
                            ? componentConfig[el.type.split('-')[0]].dataGrid.minH
                            : dynamicComponentConfig[el.type.split('-')[0]].dataGrid.minH,
                        maxW: _.has(componentConfig, el.type.split('-')[0])
                            ? componentConfig[el.type.split('-')[0]].dataGrid.maxW
                            : dynamicComponentConfig[el.type.split('-')[0]].dataGrid.maxW,
                        maxH: _.has(componentConfig, el.type.split('-')[0])
                            ? componentConfig[el.type.split('-')[0]].dataGrid.maxH
                            : dynamicComponentConfig[el.type.split('-')[0]].dataGrid.maxH,
                    }
                    return el
                })
                filterInputs = handleDropdownCheck(newDynamicInfo.notAvailableCharts)
                toUpdate = _.has(content, 'availableCharts') && content.availableCharts.length > 0
                newDynamicInfo.availableCharts = Object.values(componentConfig)
            }

            newDynamicInfo.filterInputs = filterInputs
            if (!componentsFit(newDynamicInfo.notAvailableCharts)) {
                toUpdate = true
                fitComponents(newDynamicInfo.notAvailableCharts)
            }
            if (toUpdate) onUpdateRoute(newDynamicInfo.dashboardTitle, [], newDynamicInfo.notAvailableCharts)
        }

        const handleDefaultRoute = () => {
            newDynamicInfo.isHome = false
            const layoutKey = routeUrl.replaceAll('/', '')
            if (isMounted.current && layoutKey in customDashboardLayout) {
                const layout = customDashboardLayout[layoutKey]
                newDynamicInfo.dashboardTitle = layout.dashboardTitle
                newDynamicInfo.availableCharts = Object.values(componentConfig)
                newDynamicInfo.notAvailableCharts = layout.notAvailableCharts
                newDynamicInfo.filterInputs = handleDropdownCheck(layout.notAvailableCharts)
            } else {
                newDynamicInfo.filterInputs = []
            }
        }
        const newDynamicInfo = initializeDynamicInfo()
        updateStepIfNeeded()
        try {
            if (routeUrl === '/dashboard') {
                await handleDashboardRoute()
            } else if (isCustom) {
                await handleCustomRoute()
            } else {
                handleDefaultRoute()
            }
            setIsReady(true)
            setValue(newDynamicInfo)
        } catch (err) {
            errorHandler(err)
        } finally {
            toggleLoading(false)
        }
    }, [
        onUpdateRoute,
        toggleLoading,
        isCustom,
        isMounted,
        setValue,
        routeUrl,
        handleDropdownCheck,
        currentStep,
        selectedTutorialStep,
        setCurrentStep,
        steps,
    ])

    const handleResetGrid = useCallback(() => {
        handleCustomDashboard()
    }, [handleCustomDashboard])

    const handleSetLayout = useCallback(
        async (layout) => {
            const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
            let changed = false
            const updateLayoutAndCheckChange = (chart, layoutItem) => {
                if (
                    chart.dataGrid.w !== layoutItem.w ||
                    chart.dataGrid.x !== layoutItem.x ||
                    chart.dataGrid.y !== layoutItem.y ||
                    chart.dataGrid.h !== layoutItem.h
                ) {
                    changed = true
                }
                layoutItem.w = chart.dataGrid.w
                layoutItem.h = chart.dataGrid.h
                layoutItem.x = chart.dataGrid.x
                layoutItem.y = chart.dataGrid.y
            }

            const processLayouts = () => {
                notAvailableChartsClone.forEach((chart) => {
                    layout.forEach((layoutItem) => {
                        if (chart.dataGrid.i === layoutItem.i) {
                            updateLayoutAndCheckChange(chart, layoutItem)
                        }
                    })
                })
            }

            const shouldUpdateRoute = () =>
                changed &&
                dynamicInfo.dashboardTitle === window.location.pathname.split('/')[1] &&
                (window.location.pathname.split('/')[1] === 'dashboard' || isCustom)

            processLayouts()

            if (shouldUpdateRoute()) {
                await onUpdateRoute(dynamicInfo.dashboardTitle, dynamicInfo.availableCharts, notAvailableChartsClone)

                if (isMounted.current) {
                    const filterInputs = handleDropdownCheck(notAvailableChartsClone)
                    if (filterInputs.length > 0) {
                        setFilterInputs(filterInputs)
                    }
                    setLayout(layout)
                }
            }
        },
        [setFilterInputs, isMounted, onUpdateRoute, setLayout, isCustom, dynamicInfo, handleDropdownCheck]
    )

    useEffect(() => {
        handleCustomDashboard()
    }, [handleCustomDashboard])

    useSub(['dashboard'], () => {
        handleCustomDashboard()
    })

    const handleMouseEnterComponent = useCallback(
        (attr) => {
            setHoveredComponents([attr])
            dynamicInfo.notAvailableCharts.map((el) => {
                //FIXME o hovered por enquanto só funciona com o primeiro filtro
                if (el.type === attr) setHoveredFilter(el.config.filter[0])
                return el
            })
        },
        [dynamicInfo, setHoveredComponents, setHoveredFilter]
    )

    const handleMouseLeaveComponent = useCallback(() => {
        setHoveredComponents([])
        setHoveredFilter('')
    }, [setHoveredComponents, setHoveredFilter])

    const handleCancelChartConfig = () => {
        const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
        setNotAvailableCharts(notAvailableChartsClone)
        setNextLayout('')
        setNextLayoutItem('')
    }

    const handleSaveChartConfig = (chart) => {
        const updateChartConfig = (chartType, chartConfig, isDynamicComponent) => {
            chartConfig.filter = [...componentFilter]
            if (!isDynamicComponent) {
                const configType = componentConfig[chartType].dTableInfoType
                if (configType === 'replace') {
                    chartConfig.info = componentInfo
                } else if (configType === 'complement') {
                    chartConfig.additionalInfo = [...componentAdditionalInfo.selectedOptions]
                }
            }
        }

        const processCharts = (charts, chart, isDynamicComponent) => {
            charts.forEach((el) => {
                if (el.dataGrid.i === chart.dataGrid.i) {
                    updateChartConfig(el.type.split('-')[0], el.config, isDynamicComponent)
                }
            })
        }

        const cloneAndProcessCharts = (chart) => {
            const notAvailableChartsClone = _.cloneDeep(dynamicInfo.notAvailableCharts)
            processCharts(notAvailableChartsClone, chart, chart.isDynamicComponent)
            return notAvailableChartsClone
        }

        let updatedCharts
        if (nextLayout && nextLayoutItem) {
            updatedCharts = cloneAndProcessCharts(chart)
        } else {
            updatedCharts = cloneAndProcessCharts(chart)
        }

        if (isMounted.current) {
            setNotAvailableCharts(updatedCharts)
        }
        setNextLayout('')
        setNextLayoutItem('')
    }

    const handleFillChartLayout = useCallback(
        (charts, withFilterInputs) => {
            const getDefaultFilters = (elType) => {
                const configList = componentConfig[elType].configList
                return _.has(configList, 'startingFilter') ? [...configList.startingFilter] : [...configList.filter]
            }

            const configureChart = (chart, withFilterInputs) => {
                const elType = chart.type.split('-')[0]
                const configList = componentConfig[elType].configList
                chart.dataGrid = {
                    ...chart.dataGrid,
                    maxW: componentConfig[elType].dataGrid.maxW,
                    minW: componentConfig[elType].dataGrid.minW,
                    maxH: componentConfig[elType].dataGrid.maxH,
                    minH: componentConfig[elType].dataGrid.minH,
                    name: chart.type,
                    i: chart.type,
                }
                chart.configList = configList
                chart.config = {
                    substituteInfo: '',
                    additionalInfo: [],
                    info: chart.type,
                    time: '',
                    filter: withFilterInputs
                        ? []
                        : _.has(chart, 'config') && _.has(chart.config, 'filter')
                        ? [...chart.config.filter]
                        : getDefaultFilters(elType),
                    filterRequired: false,
                }
                return chart
            }

            const newFilterInputs = charts.reduce((acc, chart) => {
                const elType = chart.type.split('-')[0]
                if (Object.keys(componentConfig).includes(elType)) {
                    configureChart(chart, withFilterInputs)
                    if (withFilterInputs) {
                        acc.push(componentConfig[elType].configList.filter[0])
                    }
                }
                return acc
            }, [])

            setFilterInputs(newFilterInputs)
            setNotAvailableCharts(charts)
        },
        [setNotAvailableCharts, setFilterInputs]
    )

    const filtersMemo = useMemo(() => {
        return JSON.stringify(
            dynamicInfo.notAvailableCharts.map((el) => {
                return el.config.filter
            })
        )
    }, [dynamicInfo.notAvailableCharts])

    useEffect(() => {
        if (filtersMemo) {
            const chartFilters = JSON.parse(filtersMemo).map((el) => {
                return el
            })
            setChartFilters(chartFilters)
        }
    }, [setChartFilters, filtersMemo])

    const availableChartsMemo = useMemo(() => dynamicInfo.availableCharts, [dynamicInfo.availableCharts])

    const filterInputsMemo = useMemo(() => dynamicInfo.filterInputs, [dynamicInfo.filterInputs])

    const MemoizedPage = useMemo(() => {
        const page = pageMap[component]
        return page
    }, [component])

    const memoizedPageProps = useMemo(() => {
        return {
            onResetGrid: handleResetGrid,
            dashboardTitle: dynamicInfo.dashboardTitle,
            notAvailableCharts: dynamicInfo.notAvailableCharts,
            availableCharts: availableChartsMemo,
            resizableGrid: dynamicInfo.resizableGrid,
            onSetLayout: handleSetLayout,
            onMouseEnterComponent: handleMouseEnterComponent,
            onMouseLeaveComponent: handleMouseLeaveComponent,
            hoveredFilter: hoveredFilter,
            hoveredComponents: hoveredComponents,
            isCustom: isCustom,
            dropdownList: dropdownList,
            onPageLayoutChange: handlePageLayoutChange,
            onPageDropChart: handlePageDropChart,
            onDeletePageChart: handleDeletePageChart,
            setDropdownList: setDropdownList,
            onOpenChartConfig: handleOpenChartConfig,
            onUpdateRoute: onUpdateRoute,
            onPublishRoute: onPublishRoute,
            onRemoveRoute: onRemoveRoute,
            onAddRoute: onAddRoute,
            filterInputs: filterInputsMemo,
            setFilterInputs: setFilterInputs,
            setAvailableCharts: setAvailableCharts,
            setNotAvailableCharts: setNotAvailableCharts,
            setNotAvailableChartValue: setNotAvailableChartValue,
            setNotAvailableChartSize: setNotAvailableChartSize,
            onFillChartLayout: handleFillChartLayout,
            isHome: dynamicInfo.isHome,
            onDropdownCheck: handleDropdownCheck,
            isReady: isReady && !_.isEmpty(dynamicInfo.dashboardTitle),
        }
    }, [
        handleResetGrid,
        dynamicInfo.dashboardTitle,
        dynamicInfo.notAvailableCharts,
        availableChartsMemo,
        dynamicInfo.resizableGrid,
        handleSetLayout,
        handleMouseEnterComponent,
        handleMouseLeaveComponent,
        hoveredFilter,
        hoveredComponents,
        isCustom,
        dropdownList,
        handlePageLayoutChange,
        handlePageDropChart,
        handleDeletePageChart,
        setDropdownList,
        handleOpenChartConfig,
        onUpdateRoute,
        onPublishRoute,
        onRemoveRoute,
        onAddRoute,
        filterInputsMemo,
        setFilterInputs,
        setAvailableCharts,
        setNotAvailableCharts,
        setNotAvailableChartValue,
        setNotAvailableChartSize,
        handleFillChartLayout,
        dynamicInfo.isHome,
        handleDropdownCheck,
        isReady,
    ])

    if (MemoizedPage) {
        return (
            <DragComponentProvider
                value={{
                    handleDragComponentStart: handleDragComponentStart,
                    handleDragDynamicInfoComponentStart: handleDragDynamicInfoComponentStart,
                }}
            >
                <ComponentFilterProvider
                    value={{
                        componentFilter: componentFilter,
                        setComponentFilter: setComponentFilter,
                        handleSaveChartConfig: handleSaveChartConfig,
                        handleCancelChartConfig: handleCancelChartConfig,
                    }}
                >
                    <FilterInputsProvider
                        value={{
                            filterInputs: filterInputsMemo,
                            setFilterInputs: setFilterInputs,
                        }}
                    >
                        <PageDetailsProvider value={{ isHome: dynamicInfo.isHome }}>
                            <div
                                className="w-100"
                                style={{
                                    // minWidth: '720px',
                                    overflow: 'hidden auto',
                                }}
                            >
                                <ModalProvider>
                                    <Scrollable isMainScroll>
                                        <div
                                            className="w-100 h-100"
                                            style={{
                                                paddingTop: '10rem',
                                            }}
                                        >
                                            <div className="row mx-0 w-100 h-100">
                                                <div
                                                    className="col px-0"
                                                    style={{
                                                        overflow: 'hidden',
                                                        zIndex: highlightedChart ? 1000 : 10,
                                                        pointerEvents: highlightedChart ? 'none' : '',
                                                    }}
                                                >
                                                    <div
                                                        className="w-100 h-100"
                                                        style={{
                                                            minWidth: '720px',
                                                        }}
                                                    >
                                                        <MemoizedPage {...memoizedPageProps} />
                                                    </div>
                                                </div>

                                                <div className="col-auto px-0">
                                                    <Auxbar />
                                                </div>
                                            </div>
                                        </div>
                                    </Scrollable>
                                </ModalProvider>
                            </div>
                        </PageDetailsProvider>
                    </FilterInputsProvider>
                </ComponentFilterProvider>
            </DragComponentProvider>
        )
    } else return null
}

export default DynamicDashboard

DynamicDashboard.propTypes = {}
