import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { segmentClientsToAddListMock } from './mock';
import { verifyComponent } from '../../../utils/component.js';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { WithTableHeader } from '../../../hocs/table-header/table-header';
import { Table } from '../../tables/table/table';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Lists all the users in a table
 */

export const SegmentClientsToAddList = React.memo(
    ({ onAddClientToSegment, clients }) => {
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [modal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            pageChange: dataPageChange,
            sort: dataSort,
            selectAll: dataSelectAll,
            unselectAll: dataUnselectAll,
            setLoading,
            setValue,
            clickActionIcon
        } = useTable(segmentClientsToAddListMock, modal, '');
        const getSegmentClientsToAdd = useCallback(async () => {
            const dataClone = _.cloneDeep(segmentClientsToAddListMock);
            try {
                dataClone.data.length = 0;
                dataClone.sortedData.length = 0;
                console.log('fix: change url');
                // const dataBack =
                //     await SegmentationService.getApiSegmentationSegmentationClients()

                dataClone.sortedData = _.sortBy(
                    dataClone.data,
                    dataClone.sortColumn.path,
                    dataClone.sortColumn.order
                );
                dataClone.titleCounter = dataClone.sortedData.length;
                dataClone.loading = false;
                dataClone.disabled = true;

                if (
                    dataClone.settings.view.options.length === 0 &&
                    dataClone.settings.options.options.length === 0
                )
                    dataClone.disabled = true;
                else dataClone.disabled = false;
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [isMounted, setLoading, setValue]);

        useEffect(() => {
            getSegmentClientsToAdd();
        }, [getSegmentClientsToAdd]);

        return (
            <>
                <ContentContainer
                    hidePinIcon
                    attr={data.attr}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    hideSettingsIcon
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <WithTableHeader count={data.titleCounter}>
                            <Table
                                actions={data.actions}
                                // onClickActionIcon={onClickActionIcon}
                                // onClickActionOption={onClickActionOption}
                                // onCloseActions={onCloseActions}
                                attr={data.attr}
                                type={data.type}
                                placeholderText={t(
                                    `components:${data.attr}.nodata`
                                )}
                                columns={data.columns}
                                data={clients}
                                sortColumn={data.sortColumn}
                                onSort={dataSort}
                                currentPage={data.currentPage}
                                pageSize={data.pageSize}
                                onPageChange={dataPageChange}
                                onRowClick={undefined}
                                onRowSelect={undefined}
                                onSelectAll={dataSelectAll}
                                onUnselectAll={dataUnselectAll}
                                paginationDisabled={data.paginationDisabled}
                                disabled={data.disabled}
                                onClickActionIcon={clickActionIcon}
                                onCloseActions={clickActionIcon}
                                onAdd={onAddClientToSegment}
                                addButtonText={t(
                                    `components:segmentClientsToAddTable.add`
                                )}
                            ></Table>
                        </WithTableHeader>
                    )}
                </ContentContainer>
            </>
        );
    }
);

SegmentClientsToAddList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func
};
