import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { productSalesByCategoryMock } from './mock';
import { useFilter } from '../../../providers/filter-context';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { SalesService } from '../../../temp/test';
import { useChartReady } from '../../../providers/chart-ready-context';
import {
    ContentContainer,
    DynamicBarChartContainer,
    Loader,
    Spinner
} from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays comparison of sales between products according to the selected category
 */

export const ProductSalesByCategory = React.memo(
    ({
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
        onDownloadComponent
    }) => {
        const { charts } = useChartReady();
        const isMounted = useIsMounted();
        const { i18n } = useTranslation();
        const { t } = useCustomTranslation();
        const { filter } = useFilter();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(productSalesByCategoryMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(productSalesByCategoryMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        verifyComponent(dataClone);
                        const result =
                            await SalesService.getApiSalesTotalRevenueSegment({
                                minDate: undefined
                            });
                        dataClone.data = Object.entries(result).map((el) => {
                            return {
                                categoryName: el[0],
                                categorySales: el[1]
                            };
                        });
                        dataClone.barStackedAttr = [
                            {
                                dataKey: 'categorySales',
                                gradientColors: ['199788', '26b5a4'],
                                stackId: 'a',
                                minPointSize: 70,
                                radius: [0, 100, 100, 0]
                            }
                        ];
                        dataClone.categoryAxis = 'categoryName';
                        dataClone.categoryLabel = '';
                        dataClone.unitsOrValues = 'values';
                        dataClone.loading = false;
                        dataClone.ready = true;

                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        dataClone.legend = [
                            {
                                name: 'productSalesByCategory',
                                color: '#199788'
                            }
                        ];
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            type
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <DynamicBarChartContainer
                            translate={t}
                            barStackedAttr={data.barStackedAttr}
                            categoryAxis={data.categoryAxis}
                            categoryLabel={data.categoryLabel}
                            orientation={data.orientation}
                            data={data.data}
                            margin={data.margin}
                            tooltipType={data.tooltipType}
                            unitsOrValues={data.unitsOrValues}
                            barWidth={data.barWidth}
                            gradient
                        />
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ProductSalesByCategory.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
