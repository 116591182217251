import styles from './swot-analysis.module.scss';

import _ from 'lodash';
import React, { useEffect, useCallback, useState } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { swotAnalysisMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { ContentContainer, Loader, Spinner } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { RichTextInput } from '../../inputs/rich-text-input/rich-text-input';
import { useToggle } from '../../../hooks/useToggle';
import { ClientAccountService } from '../../../temp/test';
import { usePreviewMode } from '../../dashboard-creation/library-component-factory/preview-mode-provider';

/**
 * Displays general information about a client
 */

export const SwotAnalysis = React.memo(
    ({
        onDownloadComponent,
        config,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const { isExample } = usePreviewMode();
        const [isEditing, toggleIsEditing] = useToggle(false);
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(swotAnalysisMock);

        const [insights, setInsights] = useState(undefined);

        const [objectives, setObjectives] = useState(undefined);

        const [opportunities, setOpportunities] = useState(undefined);

        const [threats, setThreats] = useState(undefined);

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(swotAnalysisMock);
            if (!isExample) {
                setLoading(true);

                try {
                    const dataBack2 =
                        await ClientAccountService.getApiClientAccountSwotGetByParams(
                            {
                                clientId: filter.hospitalId,
                                productId: filter.productId
                            }
                        );
                    dataClone.lastUpdated = dataBack2.updated_at;
                    setInsights(dataBack2.insights);
                    setObjectives(dataBack2.goals);
                    setOpportunities(dataBack2.opportunities);
                    setThreats(dataBack2.threats);
                    setValue(dataClone);

                    setLoading(false);
                } catch (err) {
                    dataClone.loading = false;
                    setInsights(undefined);
                    setObjectives(undefined);
                    setOpportunities(undefined);
                    setThreats(undefined);
                    setValue(dataClone);
                }
                setLoading(false);
            } else {
                setValue(dataClone);
                setLoading(false);
            }
        }, [filter, isExample, setValue, setLoading]);

        useEffect(() => {
            getInformation();
        }, [getInformation]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        const getClientAccountId = useCallback(async () => {
            try {
                const backData = await ClientAccountService.getApiClientAccount(
                    {}
                );
                const filteredData = backData.filter((e) => {
                    return (
                        e.product_id === parseInt(filter.productId) &&
                        e.client_id === parseInt(filter.hospitalId)
                    );
                });
                if (filteredData.length > 0) {
                    return filteredData[0].id;
                } else if (filteredData.length === 0) {
                    const result =
                        await ClientAccountService.postApiClientAccountUpsert({
                            requestBody: {
                                product_id: filter.productId,
                                client_id: filter.hospitalId
                            }
                        });
                    return result.id;
                }
            } catch (err) {
                console.log(err);
            }
        }, [filter]);

        const getId = useCallback(async () => {
            try {
                const dataBack2 =
                    await ClientAccountService.getApiClientAccountSwotGetByParams(
                        {
                            clientId: filter.hospitalId,
                            productId: filter.productId
                        }
                    );
                return dataBack2.id;
            } catch (err) {
                console.log(err);
            }
        }, [filter.hospitalId, filter.productId]);

        const handleSave = useCallback(async () => {
            const clientAccountId = await getClientAccountId();
            const id = await getId();
            try {
                await ClientAccountService.postApiClientAccountSwotUpsert({
                    requestBody: {
                        id: id,
                        client_account_id: clientAccountId,
                        insights: insights,
                        goals: objectives,
                        opportunities: opportunities,
                        threats: threats
                    }
                });
                toggleIsEditing();
            } catch (err) {
                errorHandler(err);
            }
        }, [
            getId,
            insights,
            objectives,
            opportunities,
            threats,
            toggleIsEditing,
            getClientAccountId
        ]);

        const handleCancel = useCallback(() => {
            getInformation();
            toggleIsEditing();
        }, [getInformation, toggleIsEditing]);

        return (
            <div
                className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    lastUpdated={data.lastUpdated}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    isEditing={isEditing}
                    onCancelEditMode={handleCancel}
                    onSaveEditMode={handleSave}
                    onOpenEditMode={toggleIsEditing}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div className={`${styles['swot-analysis-container']}`}>
                            <div
                                className="row mx-0 w-100"
                                style={{ height: '4rem', marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <div
                                        className={`${styles['swot-analysis-container__description']}`}
                                    >
                                        {t(
                                            `components:${data.attr}.description`
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: 'calc(100% - 6rem)'
                                }}
                            >
                                <div
                                    className="col-auto px-0 d-flex flex-column h-100"
                                    style={{
                                        width: 'calc((100% - 12rem) / 4)'
                                    }}
                                >
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem',
                                            height: '4rem'
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['swot-analysis-container__title']}`}
                                            >
                                                Insights
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ height: 'calc(100% - 6rem)' }}
                                    >
                                        <div className="col px-0 h-100">
                                            <RichTextInput
                                                isEditing={isEditing}
                                                text={insights}
                                                onSetText={(text) => {
                                                    setInsights(text);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-auto px-0 h-100 d-flex justify-content-center align-items-center"
                                    style={{ width: '4rem' }}
                                >
                                    <div
                                        className={`${styles['swot-analysis-container__vertical-line']}`}
                                    ></div>
                                </div>
                                <div
                                    className="col-auto px-0 d-flex flex-column h-100"
                                    style={{
                                        width: 'calc((100% - 12rem) / 4)'
                                    }}
                                >
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem',
                                            height: '4rem'
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['swot-analysis-container__title']}`}
                                            >
                                                Objectives
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ height: 'calc(100% - 6rem)' }}
                                    >
                                        <div className="col px-0 h-100">
                                            <RichTextInput
                                                isEditing={isEditing}
                                                text={objectives}
                                                onSetText={(text) => {
                                                    setObjectives(text);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="col-auto px-0 h-100 d-flex justify-content-center align-items-center"
                                    style={{ width: '4rem' }}
                                >
                                    <div
                                        className={`${styles['swot-analysis-container__vertical-line']}`}
                                    ></div>
                                </div>
                                <div
                                    className="col-auto px-0 d-flex flex-column h-100"
                                    style={{
                                        width: 'calc((100% - 12rem) / 4)'
                                    }}
                                >
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem',
                                            height: '4rem'
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['swot-analysis-container__title']}`}
                                            >
                                                Opportunities
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ height: 'calc(100% - 6rem)' }}
                                    >
                                        <div className="col px-0 h-100">
                                            <RichTextInput
                                                isEditing={isEditing}
                                                text={opportunities}
                                                onSetText={(text) => {
                                                    setOpportunities(text);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="col-auto px-0 h-100 d-flex justify-content-center align-items-center"
                                    style={{ width: '4rem' }}
                                >
                                    <div
                                        className={`${styles['swot-analysis-container__vertical-line']}`}
                                    ></div>
                                </div>
                                <div
                                    className="col-auto px-0 d-flex flex-column h-100"
                                    style={{
                                        width: 'calc((100% - 12rem) / 4)'
                                    }}
                                >
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem',
                                            height: '4rem'
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['swot-analysis-container__title']}`}
                                            >
                                                Threats
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ height: 'calc(100% - 6rem)' }}
                                    >
                                        <div className="col px-0 h-100">
                                            <RichTextInput
                                                isEditing={isEditing}
                                                text={threats}
                                                onSetText={(text) => {
                                                    setThreats(text);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);
