import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//import icoEmail from '../../../assets/images/ico-email.svg';
import icoEmail from '../../../assets/images/ico-email.svg';
import icoText from '../../../assets/images/ico-text.svg';
import icoMedia from '../../../assets/images/ico-media.svg';
import icoCalls from '../../../assets/images/ico-calls.svg';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { sentimentTopicDetailsMock } from './mock';
import { errorHandler } from '../../../utils/api.js';
import styles from './sentiment-topic-details.module.scss';
import _ from 'lodash';
import { verifyComponent } from '../../../utils/component';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useChartReady } from '../../../providers/chart-ready-context';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { SelectInput } from '../../inputs/select-input/select-input';
import { SentimentRing } from '../../charts/sentiment-ring/sentiment-ring';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays the evolution of revenue sales over time without forecast prediction
 */

export const SentimentTopicDetails = React.memo(({ analysisId }) => {
    const { charts } = useChartReady();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        value: data,
        setValue,
        setLoading,
        toggleComponentSettings
    } = useSimpleComponent(sentimentTopicDetailsMock);
    const {
        value: topic,
        toggleDropdown: topicToggleDropdown,
        selectOption: topicSelectOption,
        keyPress: topicKeyPress,
        keyDown: topicKeyDown,
        setValue: topicSetValue
    } = useSelectInput({
        name: 'topic',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: 'ESG',
        selectedOptionAux: 'ESG',
        state: 'normal',
        value: 'ESG',
        focused: -1,
        reference: useRef()
    });

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(sentimentTopicDetailsMock);
            try {
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [isMounted, setValue, analysisId, setLoading]);

    return (
        <ContentContainer
            hidePinIcon
            attr={data.attr}
            titleText={t(`components:${data.attr}.title`)}
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onCloseSettings={toggleComponentSettings}
        >
            <div className={`${styles['sentiment-topic-details-container']}`}>
                <div
                    className="row mx-0 d-flex justify-content-center align-items-start "
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0">
                        <span
                            className={`${styles['sentiment-topic-details-container__title']}`}
                        >
                            {t('components:sentimentTopicDetails.esg')}
                        </span>
                    </div>
                    <div className="col-4 px-0">
                        <SelectInput
                            onChangeInput={topicSetValue}
                            onSelectInput={topicSelectOption}
                            onKeyPress={topicKeyPress}
                            onKeyDown={topicKeyDown}
                            onToggleDropdown={topicToggleDropdown}
                            {...topic}
                        />
                    </div>
                </div>
                <div
                    className="row mx-0 d-flex justify-content-start align-items-start "
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col-auto px-0 me-3">
                        <SentimentRing
                            radius={45}
                            strokeWidth={5}
                            percentage={75}
                            sentiment={'positive'}
                            label={t(
                                'components:sentimentTopicDetails.positive'
                            )}
                            value={7.5}
                            invert={true}
                        />
                    </div>
                    <div className="col px-0 h-100">
                        <div className="row mx-0 h-100">
                            <div
                                className={`col ${styles['sentiment-topic-details-container__card']} me-2`}
                            >
                                <div className="row mx-0">
                                    <div className="col-auto px-0 pe-2">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            {t(
                                                'components:sentimentTopicDetails.texts'
                                            )}
                                        </span>
                                    </div>
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            <img
                                                loading="lazy"
                                                src={icoText}
                                                alt=""
                                                className={`${styles['sentiment-topic-details-container__card__icon']}`}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__text']}`}
                                        >
                                            1
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col ${styles['sentiment-topic-details-container__card']} me-2`}
                            >
                                <div className="row mx-0">
                                    <div className="col-auto px-0 pe-2">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            {t(
                                                'components:sentimentTopicDetails.emails'
                                            )}
                                        </span>
                                    </div>
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            <img
                                                loading="lazy"
                                                src={icoEmail}
                                                alt=""
                                                className={`${styles['sentiment-topic-details-container__card__icon']}`}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__text']}`}
                                        >
                                            3
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col ${styles['sentiment-topic-details-container__card']} me-2`}
                            >
                                <div className="row mx-0">
                                    <div className="col-auto px-0 pe-2">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            {t(
                                                'components:sentimentTopicDetails.calls'
                                            )}
                                        </span>
                                    </div>
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            <img
                                                loading="lazy"
                                                src={icoCalls}
                                                alt=""
                                                className={`${styles['sentiment-topic-details-container__card__icon']}`}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__text']}`}
                                        >
                                            -
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`col ${styles['sentiment-topic-details-container__card']}`}
                            >
                                <div className="row mx-0">
                                    <div className="col-auto px-0 pe-2">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            {t(
                                                'components:sentimentTopicDetails.media'
                                            )}
                                        </span>
                                    </div>
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__title']}`}
                                        >
                                            <img
                                                loading="lazy"
                                                src={icoMedia}
                                                alt=""
                                                className={`${styles['sentiment-topic-details-container__card__icon']}`}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <span
                                            className={` ${styles['sentiment-topic-details-container__card__text']}`}
                                        >
                                            2
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 d-flex justify-content-center align-items-start flex-grow-1">
                    <div
                        className={`col ${styles['sentiment-topic-details-container__card']}`}
                    >
                        {/*FIXME: Missing chart*/}
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
});

SentimentTopicDetails.propTypes = {};
