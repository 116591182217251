import styles from './profile-configuration.module.scss';

import React, { useEffect, useState } from 'react';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useArray } from '../../../hooks/useArray';
import {
    LoaderForeground,
    NavigationMenu,
    ProfileConfigurationProfile,
    Spinner
} from '../../../components';

const ProfileConfiguration = React.memo(() => {
    const isMounted = useIsMounted();
    const [loading, toggleLoading] = useToggle(true);

    const { array: subNavs } = useArray([
        'profile',
        'account',
        'notifications',
        'display'
    ]);
    const [currentSubNav, setCurrentSubNav] = useState('profile');

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleChangeSubNav = (option) => {
        if (isMounted.current) setCurrentSubNav(option);
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div className={`${styles['profile-configuration-container']}`}>
                    <div
                        className="row mx-0 w-100 "
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <div className={`row mx-0 w-100 h-100`}>
                                <NavigationMenu
                                    isPageMenu
                                    options={subNavs}
                                    selectedOption={currentSubNav}
                                    onChangeOption={handleChangeSubNav}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        {currentSubNav === 'profile' && (
                            <ProfileConfigurationProfile />
                        )}
                    </div>
                </div>
            )}
        </>
    );
});

export default ProfileConfiguration;

ProfileConfiguration.propTypes = {};
