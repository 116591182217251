import styles from './error-fallback.module.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'

import icoFallback from '../../assets/images/ico-fallback.svg'
import { useCustomTranslation } from '../../hooks/useCustomTranslation'

export const ErrorFallback = React.memo(() => {
    const { t } = useCustomTranslation()
    return (
        <div role="alert" className={`${styles['error-fallback-wrapper']}`}>
            <div className="row mx-0 w-75">
                <div className="col px-0">
                    <div className="row mx-0 w-100">
                        <div
                            className={`col px-0 ${styles['error-fallback-wrapper__error-wrapper']}`}
                        >
                            <img
                                loading="lazy"
                                src={icoFallback}
                                className={`${styles['error-fallback-wrapper__error-wrapper__image']}`}
                                alt="An error occurred"
                            />
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div
                            className={`col px-0 ${styles['error-fallback-wrapper__error-wrapper']}`}
                        >
                            <span
                                className={`${styles['error-fallback-wrapper__error-wrapper__text']}`}
                            >
                                {t('errors:fallback.title')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div
                            className={`col px-0 ${styles['error-fallback-wrapper__error-wrapper']}`}
                        >
                            <span
                                className={`${styles['error-fallback-wrapper__error-wrapper__text']}`}
                            >
                                {t('errors:fallback.description')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

ErrorFallback.propTypes = {}
