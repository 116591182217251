import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import arrowDown from '../../../assets/images/dropdown-arrow.svg'
import styles from './nav-option.module.scss'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { iconMap } from '../../../constants/iconMap'
import { useNavBar } from '../../../providers/nav-bar-context'
import { useAuxBar } from '../../../providers/aux-bar-context'
import { useModalPopup } from '../../../providers/modal-popup-context'
import { StarkdataService } from '../../../temp/test'
import { FullTextPopup, GlobalModalPopupNavbarCurrent } from '../../'
import { getFromLS, saveToLS } from '../../../utils/localStorage'
import { getNavWidth } from '../../../utils/width'
import { useModuleSubscription } from '../../../providers/module-subscription-context'

const countOptChildrenRecursively = (opt) => {
    let count = 0
    if (opt.children) {
        count += opt.children.length
        opt.children.forEach((child) => {
            count += countOptChildrenRecursively(child)
        })
    }
    return count
}

export const NavOption = React.memo(
    ({
        name,
        isFolderChild,
        disabled,
        icon,
        url,
        collapsed,
        isDraft,
        draftLabel,
        isFolder,
        isCategory,
        isCustom,
        isOpen,
        children,
        openNavDropdown,
        currentApp,
        localStorage,
        showIconAlways,
    }) => {
        const { steps, selectedTutorialStep, currentStep, setCurrentStep, toggleIsOpen } = useModalPopup()
        const { t } = useTranslation()
        const { toggleNavBar } = useNavBar()

        const { moduleSubscription } = useModuleSubscription()

        const isDisabled = useMemo(() => moduleSubscription[localStorage], [localStorage, moduleSubscription])

        return (
            <div
                className={`${styles['nav-option-container']}`}
                style={{
                    flexDirection: isCategory || isFolder ? 'column' : undefined,
                    alignItems: isCategory || isFolder ? 'flex-start' : undefined,
                    position: isCategory || isFolder ? 'relative' : undefined,
                    background: isFolder && isOpen && undefined,
                    paddingLeft: isFolderChild ? '5rem' : undefined,
                    borderRadius: isFolder && isOpen ? '0.8rem' : undefined,
                    overflow: isFolder && isOpen ? 'hidden' : undefined,
                    opacity: isDisabled === false ? '0.3' : 1,
                    pointerEvents: isDisabled === false ? 'none' : 'auto',
                }}
            >
                {steps && selectedTutorialStep !== '' && (
                    <GlobalModalPopupNavbarCurrent
                        opt={{ name: name }}
                        steps={steps}
                        toggleIsOpen={toggleIsOpen}
                        selectedTutorialStep={selectedTutorialStep}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                    />
                )}
                <NavLink
                    key={name}
                    className={({ isActive }) => {
                        let categoryStyle = isCategory ? `${styles['is-category']}` : ''
                        let folderStyle = isFolder ? `${styles['is-folder']}` : ''
                        let collapsedStyle = collapsed ? `${styles['collapsed']}` : ''
                        let activeStyle = isActive ? `${styles['is-active']}` : ''
                        let disabledStyle = disabled ? `${styles['disabled']}` : ''

                        return (
                            activeStyle +
                            ' ' +
                            disabledStyle +
                            ' ' +
                            collapsedStyle +
                            ' ' +
                            categoryStyle +
                            ' ' +
                            folderStyle +
                            ' ' +
                            `${styles['nav-option-container__link']}`
                        )
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: collapsed ? 'column' : 'row',
                    }}
                    onClick={
                        isFolder || isCategory
                            ? (e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  if (openNavDropdown) {
                                      openNavDropdown(name, currentApp)
                                      if (name === 'myanalytics') {
                                          toggleNavBar(true)
                                      }
                                  }
                              }
                            : undefined
                    }
                    to={isFolder || isCategory ? undefined : `/${url}`}
                >
                    {isFolder && isOpen && children && children.length > 0 && (
                        <div
                            className={`${styles['nav-option-container__link__verticalLine']}`}
                            style={{
                                height: `calc(100% +  (${countOptChildrenRecursively({
                                    children,
                                })} * 4rem)) `,
                            }}
                        ></div>
                    )}
                    {icon && (((!isCategory || collapsed) && !isFolder) || showIconAlways) && (
                        <span
                            className={`${styles['nav-option-container__link__icon']}`}
                            style={{
                                marginRight: collapsed ? '0' : '1.5rem',
                                marginBottom: collapsed ? '1rem' : '0',
                            }}
                        >
                            <img
                                loading="lazy"
                                src={iconMap[icon.image]}
                                style={{
                                    width: '2.4rem',
                                    height: '2.4rem',
                                }}
                            />
                        </span>
                    )}
                    <div
                        className={`${styles['nav-option-container__link__content']}`}
                        style={{
                            justifyContent: collapsed ? 'center' : 'space-between',
                        }}
                    >
                        <FullTextPopup
                            text={
                                (!isCustom && !isFolder && t(`layout:navbar.${name}`)) ||
                                (isCustom && name) ||
                                (isFolder && !isCategory && name) ||
                                (isFolder && isCategory && t(`layout:navbar.${name}`))
                            }
                        >
                            <span
                                className={`${styles['nav-option-container__link__content__text']}`}
                                style={{
                                    textAlign: collapsed ? 'center' : undefined,
                                    fontSize: collapsed ? '1rem' : undefined,
                                }}
                            >
                                {!isCustom && !isFolder && t(`layout:navbar.${name}`)}
                                {isCustom && name}
                                {isFolder && !isCategory && name}
                                {isFolder && isCategory && t(`layout:navbar.${name}`)}
                            </span>
                        </FullTextPopup>

                        {isDraft && !collapsed && (
                            <div className={`${styles['nav-option-container__link__content__draft-label']}`}>
                                <span className={`${styles['nav-option-container__link__content__draft-label__text']}`}>{draftLabel}</span>
                            </div>
                        )}
                        {(isFolder || isCategory) && !collapsed && (
                            <img
                                loading="lazy"
                                src={arrowDown}
                                alt="user dropdown"
                                className={
                                    isOpen
                                        ? `${styles['nav-option-container__link__content__arrow-icon']} ${styles['nav-option-container__link__content__arrow-icon--up']}`
                                        : `${styles['nav-option-container__link__content__arrow-icon']}`
                                }
                            />
                        )}
                    </div>
                </NavLink>
                {isOpen && children && children.length > 0 && (
                    <ul style={{ width: '100%', paddingLeft: '0' }}>
                        {children.map((child) => {
                            return (
                                <li key={child.name}>
                                    <NavOption
                                        {...child}
                                        collapsed={collapsed}
                                        isFolderChild={isFolder && isOpen}
                                        key={child.name}
                                        draftLabel={t('layout:navbar.navOption.draft')}
                                        openNavDropdown={openNavDropdown}
                                        currentApp={currentApp}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                )}
                {isOpen && children && children.length === 0 && !collapsed && (
                    <span className={`${styles['nav-option-container__no-children-text']}`}>{t('layout:navbar.noChildren') /* No children */}</span>
                )}
            </div>
        )
    }
)
NavOption.propTypes = {}
