import { createContext, useContext } from 'react'

const ComponentTabsContext = createContext({
    tabs: [],
    currentTab: '',
    setCurrentTab: () => {},
})

export function ComponentTabsProvider({ value, children }) {
    return <ComponentTabsContext.Provider value={value}>{children}</ComponentTabsContext.Provider>
}

export function useComponentTabs() {
    return useContext(ComponentTabsContext)
}
