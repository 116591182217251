/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChannelForecastDefaultSchema } from '../models/ChannelForecastDefaultSchema';
import type { GlobalForecastDefaultSchema } from '../models/GlobalForecastDefaultSchema';
import type { ProductForecastDefaultSchema } from '../models/ProductForecastDefaultSchema';
import type { RegionForecastDefaultSchema } from '../models/RegionForecastDefaultSchema';
import type { TeamForecastDefaultSchema } from '../models/TeamForecastDefaultSchema';
import type { TimeSeriesForecastSchema } from '../models/TimeSeriesForecastSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ForecastService {
    /**
     * Returns the forecast for the interval specified.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiForecast({
        interval,
        productIds,
        teamIds,
        regionIds,
        channelIds,
        sumBy,
    }: {
        /**
         * time interval
         */
        interval: 'year' | 'month' | 'quarter' | 'week' | 'day',
        /**
         * list of product ids.
         */
        productIds?: Array<number>,
        /**
         * list of team ids.
         */
        teamIds?: Array<number>,
        /**
         * list of region ids.
         */
        regionIds?: Array<number>,
        /**
         * list of channel ids.
         */
        channelIds?: Array<number>,
        /**
         * what property to aggregate sum
         */
        sumBy?: 'billing' | 'units',
    }): CancelablePromise<{
        forecast?: TimeSeriesForecastSchema;
        timeseries?: Record<string, number>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/forecast',
            query: {
                'interval': interval,
                'product_ids': productIds,
                'team_ids': teamIds,
                'region_ids': regionIds,
                'channel_ids': channelIds,
                'sum_by': sumBy,
            },
        });
    }
    /**
     * Returns the historical forecast. gets most recent forecast (calculation_date) per forecasted date in DB.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiForecastHistorical({
        interval,
        productIds,
        teamIds,
        regionIds,
        channelIds,
        sumBy,
    }: {
        /**
         * time interval
         */
        interval: 'year' | 'month' | 'quarter' | 'week' | 'day',
        /**
         * list of product ids.
         */
        productIds?: Array<number>,
        /**
         * list of team ids.
         */
        teamIds?: Array<number>,
        /**
         * list of region ids.
         */
        regionIds?: Array<number>,
        /**
         * list of channel ids.
         */
        channelIds?: Array<number>,
        /**
         * what property to aggregate sum
         */
        sumBy?: 'billing' | 'units',
    }): CancelablePromise<(GlobalForecastDefaultSchema | ProductForecastDefaultSchema | TeamForecastDefaultSchema | RegionForecastDefaultSchema | ChannelForecastDefaultSchema)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/forecast/historical',
            query: {
                'interval': interval,
                'product_ids': productIds,
                'team_ids': teamIds,
                'region_ids': regionIds,
                'channel_ids': channelIds,
                'sum_by': sumBy,
            },
        });
    }
}
