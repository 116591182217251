import React, { useMemo } from 'react'
import styles from './segment-preview.module.scss'
import icoToggleNavbar from '../../../../../assets/images/icoToggleNavbar.svg'
import { PieChartNew } from '../../../../../components/charts/pie-chart-new/pie-chart-new'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { formatCurrency } from '../../../../../utils/math'
import { MiniAreaChartNewContainer } from '../../../../../components/charts/mini-area-chart-new-container/mini-area-chart-new-container'
import { BarChartNewContainer } from '../../../../../components/charts/bar-chart-new-container/bar-chart-new-container'
import { RadarChartNewContainer } from '../../../../../components/charts/radar-chart-new-container/radar-chart-new-container'
import { BarChartRadialContainer } from '../../../../../components/charts/bar-chart-radial-container/bar-chart-radial-container'

const mockData = {
    data: {
        data1: [
            {
                key1: 'Products Market Share',
                value: 100,
                isGradient: true,
                color: '#FD9126',
                name: 'Products Market Share',
                percentage: 0.75,
            },
            {
                key1: 'Others',
                value: 1000,
                color: '#FDDCB2',
                name: 'Others',
                percentage: 0.25,
            },
        ],
    },
    attr: 'segmentUsersIncluded',
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1',
        },
    ],
}

const revenueData = {
    attr: 'segmentRevenueOverTime',
    data: [
        {
            date: '2020-01-01',
            month: 'Jan 2020',
            value: 129.38553836342703,
        },
        {
            date: '2020-02-01',
            month: 'Feb 2020',
            value: 261.9778364863332,
        },
        {
            date: '2020-03-01',
            month: 'Mar 2020',
            value: 525.0275364092522,
        },
        {
            date: '2020-04-01',
            month: 'Apr 2020',
            value: 429,
        },
    ],
    areaAttr: [
        {
            color: '#6AD0DE',
            dataKey: 'value',
            name: 'Value',
        },
    ],
}

const unitsSoldData = {
    attr: 'segmentUnitsSoldOverTime',
    data: [
        {
            date: '2020-01-01',
            month: 'Jan 2020',
            value: 230.38553836342703,
        },
        {
            date: '2020-02-01',
            month: 'Feb 2020',
            value: 261.9778364863332,
        },
        {
            date: '2020-03-01',
            month: 'Mar 2020',
            value: 102.0275364092522,
        },
        {
            date: '2020-04-01',
            month: 'Apr 2020',
            value: 429,
        },
    ],
    areaAttr: [
        {
            color: '#A26DE4',
            dataKey: 'value',
            name: 'Value',
        },
    ],
}

const mostSoldProductsData = {
    barAttr: [
        {
            dataKey: 'value',
            color: '#6AD0DE',
            isMain: true,
        },
        {
            dataKey: 'remaining',
            color: '#6AD0DE',
            isGradient: true,
        },
    ],
    categoryAxis: 'name',
    attr: 'mostSoldProducts',
    data: [
        {
            name: 'Product 1',
            value: 1000,
            remaining: 0,
        },
        {
            name: 'Product 2',
            value: 200,
            remaining: 800,
        },
        {
            name: 'Product 3',
            value: 300,
            remaining: 700,
        },
        {
            name: 'Product 4',
            value: 400,
            remaining: 600,
        },
        {
            name: 'Product 5',
            value: 500,
            remaining: 500,
        },
    ],
}

const ageData = {
    barAttr: [
        {
            dataKey: 'value',
            color: '#6AD0DE',
            isMain: true,
        },
        {
            dataKey: 'remaining',
            color: '#6AD0DE',
            isGradient: true,
        },
    ],
    categoryAxis: 'name',
    attr: 'age',
    data: [
        {
            name: '18 - 24',
            value: 1000,
            remaining: 0,
        },
        {
            name: '25 - 34',
            value: 200,
            remaining: 800,
        },
        {
            name: '35 - 44',
            value: 300,
            remaining: 700,
        },
        {
            name: '45 - 54',
            value: 400,
            remaining: 600,
        },
        {
            name: '55 - 64',
            value: 500,
            remaining: 500,
        },
        {
            name: '65+',
            value: 600,
            remaining: 400,
        },
    ],
}

const genderData = {
    barAttr: [
        {
            dataKey: 'value',
            color: '#6AD0DE',
            isMain: true,
        },
        {
            dataKey: 'remaining',
            color: '#6AD0DE',
            isGradient: true,
        },
    ],
    categoryAxis: 'name',
    attr: 'gender',
    data: [
        {
            name: 'Male',
            value: 1000,
            remaining: 200,
        },
        {
            name: 'Female',
            value: 200,
            remaining: 1000,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
    ],
}

const maritalStatusData = {
    barAttr: [
        {
            dataKey: 'value',
            color: '#6AD0DE',
            isMain: true,
        },
    ],
    categoryAxis: 'name',
    attr: 'maritalStatus',
    data: [
        {
            name: 'married',
            value: 1000,
        },
        {
            name: 'single',
            value: 200,
        },
        {
            name: 'divorced',
            value: 300,
        },
    ],
}

const mostUsedChannelsData = {
    barAttr: [
        {
            dataKey: 'value',
            color: '#6AD0DE',
            isMain: true,
        },
        {
            dataKey: 'remaining',
            color: '#6AD0DE',
            isGradient: true,
        },
    ],
    attr: 'mostUsedChannels',
    categoryAxis: 'name',
    data: [
        {
            name: 'Online',
            value: 800,
            remaining: 200,
        },
        {
            name: 'Retail',
            value: 200,
            remaining: 800,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
        {
            name: '',
            value: 0,
            remaining: 0,
        },
    ],
}

const RFMRadar = {
    attr: 'segmentRFM',
    data: [
        {
            subject: 'Recency',
            bottom: 0,
            left: 80,
            right: 80,
            fullMark: 150,
        },
        {
            subject: 'Frequency',
            bottom: 40,
            left: 0,
            right: 40,
            fullMark: 150,
        },
        {
            subject: 'Monetary',
            bottom: 120,
            left: 120,
            right: 0,
            fullMark: 150,
        },
    ],
    tickMinsAndMaxsMock: {
        Recency: { min: 0, max: 150 },
        Frequency: { min: 0, max: 150 },
        Monetary: { min: 0, max: 150 },
    },
}

export const SegmentPreview = ({ type = 'demographic', isCollapsed }) => {
    const { t } = useCustomTranslation()
    const mostUsedChannelsData = useMemo(() => {
        return {
            barAttr: [
                {
                    dataKey: 'value',
                    color: '#6AD0DE',
                    isMain: true,
                },
                {
                    dataKey: 'remaining',
                    color: '#6AD0DE',
                    isGradient: true,
                },
            ],
            attr: 'mostUsedChannels',
            categoryAxis: 'name',
            data: isCollapsed
                ? [
                      {
                          name: 'Online',
                          value: 800,
                          remaining: 200,
                      },
                      {
                          name: 'Retail',
                          value: 200,
                          remaining: 800,
                      },
                  ]
                : [
                      {
                          name: 'Online',
                          value: 800,
                          remaining: 200,
                      },
                      {
                          name: 'Retail',
                          value: 200,
                          remaining: 800,
                      },
                      {
                          name: '',
                          value: 0,
                          remaining: 0,
                      },
                      {
                          name: '',
                          value: 0,
                          remaining: 0,
                      },
                      {
                          name: '',
                          value: 0,
                          remaining: 0,
                      },
                  ],
        }
    }, [isCollapsed])
    return (
        <div className={styles['segment-preview-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '4rem' }}>
                <div className="col px-0 d-flex justify-content-start align-items-centers">
                    <div className={styles['segment-preview-container__toggle-icon-container']}>
                        <img src={icoToggleNavbar} className={styles['segment-preview-container__toggle-icon-container__icon']} />
                    </div>
                    <span className={styles['segment-preview-container__title']}>{'Live Segment Preview'}</span>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    marginBottom: isCollapsed ? '2rem' : '4rem',
                }}
            >
                <div
                    className={`${isCollapsed ? 'col-12' : 'col-6'}`}
                    style={{ padding: '0 10rem', minWidth: 0, marginBottom: isCollapsed ? '2rem' : 0 }}
                >
                    <PieChartNew addSpacingBetween {...mockData} fillInside />
                </div>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 d-flex flex-column justify-content-center`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col-auto px-0">
                            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <span className={styles['segment-preview-container__info-title']}>
                                        {t('components:segmentPreview.customersIncluded')}
                                    </span>
                                    <span className={styles['segment-preview-container__info-content']}>500</span>
                                    <span className={styles['segment-preview-container__info-content-aux']}>(20%)</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <span className={styles['segment-preview-container__info-title']}>
                                        {t('components:segmentPreview.revenueYTD')}
                                    </span>
                                    <span className={styles['segment-preview-container__info-content']}>{formatCurrency(105201.32, 'EUR')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col px-0"></div>
                        <div className="col-auto px-0">
                            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <span className={styles['segment-preview-container__info-title']}>
                                        {t('components:segmentPreview.customersExcluded')}
                                    </span>
                                    <span className={styles['segment-preview-container__info-content']}>2500</span>
                                    <span className={styles['segment-preview-container__info-content-aux']}>(80%)</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <span className={styles['segment-preview-container__info-title']}>
                                        {t('components:segmentPreview.unitsSoldYTD')}
                                    </span>
                                    <span className={styles['segment-preview-container__info-content']}>204</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />
            <div className="row mx-0 w-100" style={{ marginBottom: '4rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.revenueOverTime')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '15rem' }}>
                        <div className="col px-0">
                            <MiniAreaChartNewContainer {...revenueData} />
                        </div>
                    </div>
                </div>
                {isCollapsed && <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />}
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.unitsSoldOverTime')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '15rem' }}>
                        <div className="col px-0">
                            <MiniAreaChartNewContainer {...unitsSoldData} />
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />
            {type === 'demographic' && RenderDemographicInformation(isCollapsed)}
            {type === 'behavioural' && RenderBehaviouralInformation(isCollapsed)}
            {type === 'mix' && RenderMixInformation(isCollapsed)}
        </div>
    )
}

const RenderDemographicInformation = (isCollapsed) => {
    const { t } = useCustomTranslation()
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.mostSoldProducts')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...mostSoldProductsData} />
                        </div>
                    </div>
                </div>
                {isCollapsed && <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />}
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.mostUsedChannels')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: isCollapsed ? '10rem' : '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...mostUsedChannelsData} />
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />
            <div className="row mx-0 w-100" style={{ height: isCollapsed ? undefined : '30rem', marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <RadarChartNewContainer {...RFMRadar} />
                </div>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column justify-content-center`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className={styles['segment-preview-container__info-title']}>{t('components:segmentPreview.avgRecency')}</span>
                            <span className={styles['segment-preview-container__info-content']}>86.2</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className={styles['segment-preview-container__info-title']}>{t('components:segmentPreview.avgFrequency')}</span>
                            <span className={styles['segment-preview-container__info-content']}>6.62</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className={styles['segment-preview-container__info-title']}>{t('components:segmentPreview.avgMonetary')}</span>
                            <span className={styles['segment-preview-container__info-content']}>€ 3.12</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const RenderBehaviouralInformation = (isCollapsed) => {
    const { t } = useCustomTranslation()
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.gender')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...genderData} />
                        </div>
                    </div>
                </div>
                {isCollapsed && <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />}
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.age')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: isCollapsed ? '10rem' : '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...ageData} />
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.maritalStatus')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '30rem' }}>
                        <div className="col px-0">
                            <BarChartRadialContainer {...maritalStatusData} />
                        </div>
                    </div>
                </div>
                {isCollapsed && <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />}
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.mostSoldProducts')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: isCollapsed ? '10rem' : '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...mostSoldProductsData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const RenderMixInformation = (isCollapsed) => {
    const { t } = useCustomTranslation()
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.mostSoldProducts')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...mostSoldProductsData} />
                        </div>
                    </div>
                </div>
                {isCollapsed && <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />}
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.mostUsedChannels')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: isCollapsed ? '10rem' : '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...mostUsedChannelsData} />
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />
            <div className="row mx-0 w-100" style={{ height: isCollapsed ? undefined : '30rem', marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <RadarChartNewContainer {...RFMRadar} />
                </div>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column justify-content-center`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className={styles['segment-preview-container__info-title']}>{t('components:segmentPreview.avgRecency')}</span>
                            <span className={styles['segment-preview-container__info-content']}>86.2</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className={styles['segment-preview-container__info-title']}>{t('components:segmentPreview.avgFrequency')}</span>
                            <span className={styles['segment-preview-container__info-content']}>6.62</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className={styles['segment-preview-container__info-title']}>{t('components:segmentPreview.avgMonetary')}</span>
                            <span className={styles['segment-preview-container__info-content']}>€ 3.12</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.gender')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...genderData} />
                        </div>
                    </div>
                </div>
                {isCollapsed && <hr style={{ margin: '2rem 0', borderColor: '#c7c7c7', borderWidth: '2px' }} />}
                <div className={`${isCollapsed ? 'col-12' : 'col-6'} px-0 h-100 d-flex flex-column`} style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={`${styles['segment-preview-container__sub-title']}`} style={{ marginBottom: '2rem', display: 'block' }}>
                                {t('components:segmentPreview.age')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ minHeight: isCollapsed ? '10rem' : '20rem' }}>
                        <div className="col px-0">
                            <BarChartNewContainer {...ageData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
