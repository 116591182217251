import _ from 'lodash';
import React from 'react';
import icoCsv from '../../../assets/images/csv-ico.svg';
import icoGmail from '../../../assets/images/gmail-ico.svg';
import icoFileLoaded from '../../../assets/images/ico-file-loaded.svg';
import icoOutlook from '../../../assets/images/ico-outlook.svg';
import styles from './file-list.module.scss';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const FileList = React.memo(
    ({ files, onFileRemove, selectedFile, selectedFileLoading }) => {
        const { t } = useCustomTranslation();
        return (
            <div className={`${styles['file-list-container']}`}>
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-start align-items-center">
                        <div
                            className={`${styles['file-list-container__count']}`}
                        >
                            {_.countBy(files, (el) => {
                                return el.ready;
                            }).true || '0'}
                        </div>
                        <span
                            className={`${styles['file-list-container__title']}`}
                        >
                            {t(
                                `components:sentiment-import-data.uploadedfiles`
                            )}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 w-100 flex-grow-1 overflow-auto">
                    <div className="col px-0">
                        {files.map((el, index) => {
                            if (el.ready) {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            selectedFile && !selectedFileLoading
                                                ? `row mx-0 ${styles['file-list-container__upload-files-wrapper__file-wrapper']} ${styles['loaded']}`
                                                : selectedFileLoading
                                                ? `row mx-0 ${styles['file-list-container__upload-files-wrapper__file-wrapper']}`
                                                : `row mx-0 ${styles['file-list-container__upload-files-wrapper__file-wrapper']} ${styles['hidden']}`
                                        }
                                        onClick={() => {
                                            onFileRemove(el.id);
                                        }}
                                    >
                                        {selectedFile && (
                                            <React.Fragment>
                                                <div className="col-auto px-4 d-flex justify-content-start align-items-center">
                                                    {selectedFile &&
                                                        selectedFile &&
                                                        el.type === 'csv' && (
                                                            <img
                                                                src={icoCsv}
                                                                className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__icon']}`}
                                                            />
                                                        )}
                                                    {selectedFile &&
                                                        selectedFile &&
                                                        el.type === 'mbox' && (
                                                            <img
                                                                src={icoGmail}
                                                                className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__icon']}`}
                                                            />
                                                        )}
                                                    {selectedFile &&
                                                        selectedFile &&
                                                        el.type === 'pst' && (
                                                            <img
                                                                src={icoOutlook}
                                                                className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__icon']}`}
                                                            />
                                                        )}
                                                </div>
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <span
                                                        className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__title']}`}
                                                    >
                                                        {selectedFile &&
                                                            el.selectedFileName}
                                                    </span>
                                                </div>
                                                <div className="col px-0 d-flex justify-content-end align-items-center">
                                                    <div
                                                        className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__status']}`}
                                                    >
                                                        {selectedFile &&
                                                            selectedFileLoading && (
                                                                <Spinner />
                                                            )}
                                                        {selectedFile &&
                                                            !selectedFileLoading && (
                                                                <img
                                                                    src={
                                                                        icoFileLoaded
                                                                    }
                                                                    className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__icon']}`}
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {!selectedFile && (
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={`${styles['file-list-container__upload-files-wrapper__file-wrapper__title']}`}
                                                >
                                                    {t(
                                                        `pages:sentimentAnalysis.newAnalysis.noData`
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
);
