import styles from './hospital-account-information.module.scss';

import _ from 'lodash';
import React, { useCallback } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { hospitalAccountInformationMock } from './mock';
import { errorHandler } from '../../../utils/api';
import {
    ContentContainer,
    FullTextPopup,
    Loader,
    MarkerMap,
    SelectInput,
    Spinner,
    TypeInput
} from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useToggle } from '../../../hooks/useToggle';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import { ClientAccountService } from '../../../temp/test';
import { usePreviewMode } from '../../dashboard-creation/library-component-factory/preview-mode-provider';

/**
 * Displays general information about a client
 */

export const HospitalAccountInformation = React.memo(
    ({
        onDownloadComponent,
        config,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const [clientAccountId, setClientAccountId] = React.useState(undefined);
        const { isExample } = usePreviewMode();
        const { filter } = useFilter();

        const translateStatusFromBackend = (value) => {
            if (value === 'EXCEEDS') {
                return 'Exceeds';
            } else if (value === 'IN_LINE') {
                return 'In Line';
            } else if (value === 'BELOW') {
                return 'Below';
            } else {
                return '';
            }
        };

        const {
            value,
            setValue: setValueInputs,
            replaceAll,
            selectOption,
            keyPress,
            keyDown,
            unselectOption,
            toggleDropdown,
            clearSelectedOptions,
            clickRadioOption,
            clickCheckboxOption
        } = useMultipleInputs({
            status: {
                type: 'selectSingle',
                path: 'statuss',
                name: 'status',
                showOptions: true,
                options: [
                    translateStatusFromBackend('EXCEEDS'),
                    translateStatusFromBackend('IN_LINE'),
                    translateStatusFromBackend('BELOW')
                ],
                optionsAux: ['EXCEEDS', 'IN_LINE', 'BELOW'],
                selectedOption: '',
                selectedOptionAux: '',
                state: 'normal',
                value: '',
                focused: -1,
                hideOptionsAux: true,
                reference: React.createRef()
            },
            medicalLead: {
                name: 'medicalLead',
                path: 'medicalLead',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            salesLead: {
                name: 'saleslead',
                path: 'salesLead',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            accessLead: {
                name: 'accessLead',
                path: 'accessLead',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },

            percGMS: {
                name: 'percGMS',
                path: 'percGMS',
                type: 'number',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },

            mdmId: {
                name: 'mdmId',
                path: 'mdmId',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },

            influenceArea: {
                name: 'influenceArea',
                path: 'influenceArea',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            influenceAreaPop: {
                name: 'influenceAreaPop',
                path: 'influenceAreaPop',
                type: 'number',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            nrUrg: {
                name: 'nrUrg',
                path: 'nrUrg',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            nbrx: {
                name: 'nbrx',
                path: 'nbrx',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            potVsContr: {
                name: 'potVsContr',
                path: 'potVsContr',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            totalProductPatients: {
                name: 'totalProductPatients',
                path: 'totalProductPatients',
                type: 'number',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },

            classification: {
                type: 'selectSingle',
                path: 'classification',
                name: 'classification',
                showOptions: true,
                options: ['A', 'B', 'C', 'D'],
                optionsAux: ['A', 'B', 'C', 'D'],
                selectedOption: '',
                selectedOptionAux: '',
                state: 'normal',
                value: '',
                focused: -1,
                hideOptionsAux: true,
                reference: React.createRef()
            }
        });
        const isMounted = useIsMounted();
        const [isEditing, toggleIsEditing] = useToggle(false);
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(hospitalAccountInformationMock);

        const getId = useCallback(async () => {
            try {
                const backData2 =
                    await ClientAccountService.getApiClientAccount1({
                        id: clientAccountId
                    });
                return backData2.id;
            } catch (err) {
                console.log(err);
            }
        }, [clientAccountId]);

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(hospitalAccountInformationMock);
            if (!isExample) {
                setLoading(true);
                try {
                    let clientAccountId = undefined;
                    const backData =
                        await ClientAccountService.getApiClientAccount({});
                    const filteredData = backData.filter((e) => {
                        return (
                            e.product_id === parseInt(filter.productId) &&
                            e.client_id === parseInt(filter.hospitalId)
                        );
                    });
                    if (filteredData.length > 0) {
                        clientAccountId = filteredData[0].id;
                        setClientAccountId(filteredData[0].id);
                    } else if (filteredData.length === 0) {
                        const result =
                            await ClientAccountService.postApiClientAccountUpsert(
                                {
                                    requestBody: {
                                        product_id: filter.productId,
                                        client_id: filter.hospitalId
                                    }
                                }
                            );
                        clientAccountId = result.id;
                        setClientAccountId(result.id);
                    }
                    const backData2 =
                        await ClientAccountService.getApiClientAccount1({
                            id: clientAccountId
                        });
                    const smallFeatures = [
                        {
                            type: 'Feature',
                            properties: {
                                color: '#2c3c8d',
                                productImage: backData2.product.image_url,
                                productName: backData2.product.title
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [
                                    backData2.hospital.lon,
                                    backData2.hospital.lat
                                ]
                            }
                        }
                    ];
                    dataClone.dataList = [];
                    dataClone.startingPosition = {
                        lat: backData2.hospital.lat,
                        lon: backData2.hospital.lon,
                        zoom: 10
                    };
                    dataClone.dataList.push({
                        id: 'small',
                        data: {
                            type: 'FeatureCollection',
                            features: [...smallFeatures]
                        },
                        styleSmall: {
                            id: 'small-style-small',
                            type: 'circle',
                            innerRadius: 0.4,
                            paint: {
                                'circle-radius': 5,
                                'circle-color': '#FFB2B2',
                                'circle-opacity': 0.6
                            }
                        },
                        styleLarge: {
                            id: 'small-style-large',
                            type: 'circle',
                            outerRadius: 1.2,
                            paint: {
                                'circle-radius': 20,
                                'circle-color': '#FFB2B2',
                                'circle-opacity': 0.1
                            }
                        }
                    });
                    dataClone.productImage = backData2.product.image_url;
                    dataClone.percGMS = backData2.gms_perc;
                    dataClone.hospitalName = backData2.hospital.name;
                    dataClone.productName = backData2.product.title;
                    dataClone.status = translateStatusFromBackend(
                        backData2.status
                    );
                    dataClone.medicalLead = backData2.medical_resp;
                    dataClone.salesLead = backData2.sales_resp;
                    dataClone.accessLead = backData2.access_lead;
                    dataClone.mdmId = backData2.mdm_id;
                    dataClone.lastUpdated = backData2.updated_at;
                    dataClone.classification = backData2.rating;
                    dataClone.influenceArea = backData2.influence_area;
                    dataClone.influenceAreaPop = backData2.influence_area_pop;
                    dataClone.nrUrg = backData2.er_patients_number;
                    dataClone.nbrx = backData2.prescriptions;
                    dataClone.potVsContr = backData2.potential_vs_contractual;
                    dataClone.totalProductPatients = backData2.product_users;
                    dataClone.loading = false;
                    const inputsToReplace = {
                        status: {
                            type: 'selectSingle',
                            path: 'statuss',
                            name: 'status',
                            showOptions: true,
                            options: [
                                translateStatusFromBackend('EXCEEDS'),
                                translateStatusFromBackend('IN_LINE'),
                                translateStatusFromBackend('BELOW')
                            ],
                            optionsAux: ['EXCEEDS', 'IN_LINE', 'BELOW'],
                            selectedOption: translateStatusFromBackend(
                                backData2.status
                            ),
                            selectedOptionAux: backData2.status,
                            state: 'normal',
                            value: translateStatusFromBackend(backData2.status),
                            focused: -1,
                            hideOptionsAux: true,
                            reference: React.createRef()
                        },
                        medicalLead: {
                            name: 'medicalLead',
                            path: 'medicalLead',
                            type: 'type',
                            value: backData2.medical_resp,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        salesLead: {
                            name: 'salesLead',
                            path: 'salesLead',
                            type: 'type',
                            value: backData2.sales_resp,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        accessLead: {
                            name: 'accessLead',
                            path: 'accessLead',
                            type: 'type',
                            value: backData2.access_lead,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },

                        percGMS: {
                            name: 'percGMS',
                            path: 'percGMS',
                            type: 'number',
                            value: backData2.gms_perc,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },

                        mdmId: {
                            name: 'mdmId',
                            path: 'mdmId',
                            type: 'type',
                            value: backData2.mdm_id,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        influenceArea: {
                            name: 'influenceArea',
                            path: 'influenceArea',
                            type: 'type',
                            value: backData2.influence_area,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        influenceAreaPop: {
                            name: 'influenceAreaPop',
                            path: 'influenceAreaPop',
                            type: 'number',
                            value: backData2.influence_area_pop,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        nrUrg: {
                            name: 'nrUrg',
                            path: 'nrUrg',
                            type: 'type',
                            value: backData2.er_patients_number,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        nbrx: {
                            name: 'nbrx',
                            path: 'nbrx',
                            type: 'number',
                            value: backData2.prescriptions,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        potVsContr: {
                            name: 'potVsContr',
                            path: 'potVsContr',
                            type: 'type',
                            value: backData2.potential_vs_contractual,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        totalProductPatients: {
                            name: 'totalProductPatients',
                            path: 'totalProductPatients',
                            type: 'number',
                            value: backData2.product_users,
                            error: '',
                            hidden: false,
                            state: 'normal'
                        },
                        classification: {
                            type: 'selectSingle',
                            path: 'classification',
                            name: 'classification',
                            showOptions: true,
                            options: ['A', 'B', 'C', 'D'],
                            optionsAux: ['A', 'B', 'C', 'D'],
                            selectedOption: backData2.rating,
                            selectedOptionAux: backData2.rating,
                            state: 'normal',
                            value: backData2.rating,
                            focused: -1,
                            hideOptionsAux: true,
                            reference: React.createRef()
                        }
                    };
                    replaceAll(inputsToReplace);
                    setValue(dataClone);
                } catch (err) {
                    console.log(err);
                }
                try {
                    const backData =
                        await ClientAccountService.getApiClientAccountStakeholders(
                            {
                                params: {
                                    is_top_prescriber: true
                                }
                            }
                        );
                    dataClone.topPrescribers = [...backData];
                } catch (err) {
                    console.log(err);
                }
                setLoading(false);
            } else {
                dataClone.loading = false;
                setValue(dataClone);
            }
        }, [
            setLoading,
            filter.hospitalId,
            filter.productId,
            setValue,
            replaceAll,
            isExample
        ]);

        React.useEffect(() => {
            getInformation();
        }, [getInformation]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        const handleSave = useCallback(async () => {
            try {
                const id = await getId();
                const result =
                    await ClientAccountService.postApiClientAccountUpsert({
                        requestBody: {
                            id: id,
                            mdm_id: value.mdmId.value,
                            client_id: filter.hospitalId,
                            product_id: filter.productId,
                            status: value.status.selectedOptionAux
                                ? value.status.selectedOptionAux
                                : null,
                            medical_resp: value.medicalLead.value,
                            sales_resp: value.salesLead.value,
                            access_lead: value.accessLead.value,
                            gms_perc: parseFloat(value.percGMS.value) || 0,
                            influence_area: value.influenceArea.value,
                            influence_area_pop:
                                parseInt(value.influenceAreaPop.value) || 0,
                            er_patients_number: value.nrUrg.value,
                            prescriptions: parseInt(value.nbrx.value) || 0,
                            potential_vs_contractual: value.potVsContr.value,
                            product_users:
                                parseInt(value.totalProductPatients.value) || 0,
                            rating: value.classification.selectedOption
                                ? value.classification.selectedOption
                                : null
                        }
                    });
                getInformation();
                toggleIsEditing();
            } catch (err) {
                errorHandler(err);
            }
        }, [
            getId,
            getInformation,
            value.mdmId.value,
            filter.hospitalId,
            filter.productId,
            toggleIsEditing,
            value.accessLead.value,
            value.medicalLead.value,
            value.percGMS.value,
            value.salesLead.value,
            value.status.selectedOptionAux,
            value.influenceArea.value,
            value.influenceAreaPop.value,
            value.nrUrg.value,
            value.nbrx.value,
            value.potVsContr.value,
            value.totalProductPatients.value,
            value.classification.selectedOption
        ]);

        return (
            <div
                className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    lastUpdated={data.lastUpdated}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    isEditing={isEditing}
                    onCancelEditMode={toggleIsEditing}
                    onSaveEditMode={handleSave}
                    onOpenEditMode={toggleIsEditing}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`${styles['hospital-account-information-container']}`}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: '40rem',
                                    marginBottom: '2rem'
                                }}
                            >
                                <div
                                    className="col-auto px-0 h-100"
                                    style={{
                                        width: 'calc((100% - 2rem)/2)',
                                        borderRadius: '0.6rem'
                                    }}
                                >
                                    <MarkerMap
                                        translate={t}
                                        dataList={data.dataList || []}
                                        startingPosition={
                                            data.startingPosition || {
                                                lat: 38.736946,
                                                lon: -9.142685,
                                                zoom: 10
                                            }
                                        }
                                        tooltipType={data.attr}
                                        draggingDisabled
                                        zoomDisabled
                                        customScale={10}
                                        isRound
                                    />
                                </div>
                                <div
                                    className="col-auto h-100 px-0 h-100"
                                    style={{ width: '2rem' }}
                                ></div>
                                <div
                                    className="col-auto px-0 h-100"
                                    style={{
                                        width: 'calc((100% - 2rem)/2)'
                                    }}
                                >
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '4rem'
                                        }}
                                    >
                                        <div className="col px-0">
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['hospital-account-information-container__title']}`}
                                                    >
                                                        {data.hospitalName ||
                                                            '-'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['hospital-account-information-container__subtitle']}`}
                                                    >
                                                        {data.productName ||
                                                            '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ height: 'calc(100% - 8rem)' }}
                                    >
                                        {/* <div
                                            className="col-auto px-0 h-100"
                                            style={{ width: '20rem' }}
                                        >
                                            {data.productImage && (
                                                <img
                                                    src={data.productImage}
                                                    className={`${styles['hospital-account-information-container__product-image']}`}
                                                />
                                            )}
                                            {!data.productImage && (
                                                <div
                                                    className={`${styles['hospital-account-information-container__product-image']}`}
                                                >
                                                    <span
                                                        className={`${styles['hospital-account-information-container__product-image__no-image']}`}
                                                    >
                                                        No image available
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className="col-auto h-100 px-0"
                                            style={{ width: '2rem' }}
                                        ></div> */}
                                        <div className="col px-0">
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__status-text__title']}`}
                                                            >
                                                                MDM ID.
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    {...value.mdmId}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.mdmId
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.mdmId ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-auto h-100 px-0"
                                                    style={{ width: '2rem' }}
                                                ></div>
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__status-text__title']}`}
                                                            >
                                                                Status
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <SelectInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    onSelectInput={
                                                                        selectOption
                                                                    }
                                                                    onKeyPress={
                                                                        keyPress
                                                                    }
                                                                    onKeyDown={
                                                                        keyDown
                                                                    }
                                                                    onToggleDropdown={
                                                                        toggleDropdown
                                                                    }
                                                                    {...value.status}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.status
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.status ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__status-text__title']}`}
                                                                    >
                                                                        % GMS
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    {isEditing && (
                                                                        <TypeInput
                                                                            hideLabel
                                                                            onChangeInput={
                                                                                setValueInputs
                                                                            }
                                                                            isNumber
                                                                            {...value.percGMS}
                                                                        />
                                                                    )}
                                                                    {!isEditing && (
                                                                        <FullTextPopup
                                                                            text={
                                                                                data.percGMS
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                            >
                                                                                {data.percGMS ||
                                                                                    '-'}
                                                                            </span>
                                                                        </FullTextPopup>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-auto h-100 px-0"
                                                            style={{
                                                                width: '2rem'
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                                    >
                                                                        XFTeam
                                                                        Medical
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    {isEditing && (
                                                                        <TypeInput
                                                                            hideLabel
                                                                            onChangeInput={
                                                                                setValueInputs
                                                                            }
                                                                            {...value.medicalLead}
                                                                        />
                                                                    )}
                                                                    {!isEditing && (
                                                                        <FullTextPopup
                                                                            text={
                                                                                data.medicalLead
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                            >
                                                                                {data.medicalLead ||
                                                                                    '-'}
                                                                            </span>
                                                                        </FullTextPopup>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                                    >
                                                                        XFTeam
                                                                        Sales
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    {isEditing && (
                                                                        <TypeInput
                                                                            hideLabel
                                                                            onChangeInput={
                                                                                setValueInputs
                                                                            }
                                                                            {...value.salesLead}
                                                                        />
                                                                    )}
                                                                    {!isEditing && (
                                                                        <FullTextPopup
                                                                            text={
                                                                                data.salesLead
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                            >
                                                                                {data.salesLead ||
                                                                                    '-'}
                                                                            </span>
                                                                        </FullTextPopup>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-auto h-100 px-0"
                                                            style={{
                                                                width: '2rem'
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                                    >
                                                                        XFTeam
                                                                        Access
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    {isEditing && (
                                                                        <TypeInput
                                                                            hideLabel
                                                                            onChangeInput={
                                                                                setValueInputs
                                                                            }
                                                                            {...value.accessLead}
                                                                        />
                                                                    )}
                                                                    {!isEditing && (
                                                                        <FullTextPopup
                                                                            text={
                                                                                data.accessLead
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                            >
                                                                                {data.accessLead ||
                                                                                    '-'}
                                                                            </span>
                                                                        </FullTextPopup>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ height: 'calc((100% - 30rem)/2)' }}
                            >
                                <div className="col px-0 h-100">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ height: 'calc(100%)' }}
                                    >
                                        <div className="col px-0">
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                Influence Area
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    {...value.influenceArea}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.influenceArea
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.influenceArea ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                Influence Area
                                                                Population
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    isNumber
                                                                    {...value.influenceAreaPop}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.influenceAreaPop
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.influenceAreaPop ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                Urgency Patients
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    {...value.nrUrg}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.nrUrg
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.nrUrg ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-auto h-100 px-0"
                                            style={{ width: '2rem' }}
                                        ></div>
                                        <div className="col px-0">
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                NBRX
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    isNumber
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    {...value.nbrx}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.nbrx
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.nbrx ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                Pot Vs. Contr
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    {...value.potVsContr}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.potVsContr
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.potVsContr ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                Total Product
                                                                Patients
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    isNumber
                                                                    onChangeInput={
                                                                        setValueInputs
                                                                    }
                                                                    {...value.totalProductPatients}
                                                                />
                                                            )}
                                                            {!isEditing && (
                                                                <FullTextPopup
                                                                    text={
                                                                        data.totalProductPatients
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                    >
                                                                        {data.totalProductPatients ||
                                                                            '-'}
                                                                    </span>
                                                                </FullTextPopup>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-auto h-100 px-0"
                                            style={{ width: '2rem' }}
                                        ></div>
                                        <div className="col px-0">
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span
                                                                className={`${styles['hospital-account-information-container__team-text__subtitle']}`}
                                                            >
                                                                Top Prescribers
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {(!data.topPrescribers ||
                                                        data.topPrescribers
                                                            .length === 0) && (
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                >
                                                                    -
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data.topPrescribers &&
                                                        data.topPrescribers.map(
                                                            (e, i) => {
                                                                return (
                                                                    <div
                                                                        className="row mx-0 w-100"
                                                                        key={i}
                                                                    >
                                                                        <div className="col px-0">
                                                                            <span
                                                                                className={`${styles['hospital-account-information-container__team-text__content']}`}
                                                                            >
                                                                                {e ||
                                                                                    '-'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-auto h-100 px-0"
                                            style={{ width: '2rem' }}
                                        ></div>
                                        <div className="col px-0">
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    {isEditing && (
                                                        <SelectInput
                                                            onChangeInput={
                                                                setValueInputs
                                                            }
                                                            onSelectInput={
                                                                selectOption
                                                            }
                                                            onKeyPress={
                                                                keyPress
                                                            }
                                                            onKeyDown={keyDown}
                                                            onToggleDropdown={
                                                                toggleDropdown
                                                            }
                                                            {...value.classification}
                                                        />
                                                    )}
                                                    {!isEditing && (
                                                        <>
                                                            <div className="row mx-0 w-100 d-flex justify-content-center">
                                                                <div className="col-auto h-100 px-0">
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__classification-text']}`}
                                                                    >
                                                                        {data.classification ||
                                                                            '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 w-100 d-flex justify-content-center">
                                                                <div className="col-auto h-100 px-0">
                                                                    <span
                                                                        className={`${styles['hospital-account-information-container__classification-title']}`}
                                                                    >
                                                                        Classification
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

HospitalAccountInformation.propTypes = {};
