import { createContext, useContext } from 'react'

const SegmentFiltersContext = createContext({
    segmentFilters: [],
    pushSegmentFilter: () => {},
    removeSegmentFilter: () => {},
    handleOpenModal: () => {},
})

export function SegmentFiltersProvider({ value, children }) {
    return (
        <SegmentFiltersContext.Provider value={value}>
            {children}
        </SegmentFiltersContext.Provider>
    )
}

export function useSegmentFilters() {
    return useContext(SegmentFiltersContext)
}
