import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import styles from './donut-chart-with-circle.module.scss'
import { TooltipFactory } from '../..'
import useCursorTracking from '../../../hooks/useCursorTracking'

export const DonutChartWithCircle = React.memo(({ donutAttr, data, attr, insideTitle, insideTitleColor, isInsideTitleBig }) => {
    const { position } = useCursorTracking()
    const [animationActive, setAnimationActive] = React.useState(true)
    const [hoveredDonutPiece, setHoveredDonutPiece] = React.useState(undefined)

    return (
        <div className="row mx-0 w-100 h-100">
            <div className="col px-0 d-flex flex-column position-relative h-100">
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 h-100" style={{ zIndex: '1' }}>
                        <ResponsiveContainer width={'100%'}>
                            <PieChart width={'100%'}>
                                <Tooltip
                                    cursor={false}
                                    content={
                                        <TooltipFactory
                                            item={{
                                                type: attr,
                                                data: data,
                                                index: hoveredDonutPiece,
                                            }}
                                        />
                                    }
                                    wrapperStyle={{
                                        outline: 'none',
                                    }}
                                />

                                {donutAttr.map((el) => {
                                    return (
                                        <Pie
                                            key={el.dataKey + '2'}
                                            data={data[el.dataKey]}
                                            dataKey={el.valueKey}
                                            nameKey={el.nameKey}
                                            cx="50%"
                                            cy={'50%'}
                                            innerRadius={'80%'}
                                            blendStroke={true}
                                            isAnimationActive={animationActive}
                                            onAnimationEnd={() => setAnimationActive(false)}
                                            outerRadius={'100%'}
                                            startAngle={0}
                                            labelLine={true}
                                            label={undefined}
                                        >
                                            {data[el.dataKey].map((entry, index2) => {
                                                return (
                                                    <Cell
                                                        onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                        onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                        strokeWidth={0}
                                                        style={{
                                                            outline: 'none',
                                                        }}
                                                        key={`cell-${index2}`}
                                                        fill={entry['isRounded'] ? 'transparent' : entry.color}
                                                    />
                                                )
                                            })}
                                        </Pie>
                                    )
                                })}
                                {donutAttr.map((el) => {
                                    return (
                                        <Pie
                                            key={el.dataKey}
                                            data={data[el.dataKey]}
                                            dataKey={el.valueKey}
                                            nameKey={el.nameKey}
                                            cx="50%"
                                            cy={'50%'}
                                            innerRadius={'80%'}
                                            blendStroke={false}
                                            isAnimationActive={animationActive}
                                            onAnimationEnd={() => setAnimationActive(false)}
                                            outerRadius={'100%'}
                                            fill="#1576BF"
                                            startAngle={0}
                                            endAngle={360}
                                            labelLine={true}
                                            label={undefined}
                                            cornerRadius={20}
                                        >
                                            {data[el.dataKey].map((entry, index2) => {
                                                return (
                                                    <Cell
                                                        onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                        onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                        strokeWidth={0}
                                                        style={{
                                                            outline: 'none',
                                                            opacity: entry['isRounded'] ? 1 : 0,
                                                        }}
                                                        key={`cell-${index2}`}
                                                        fill={entry['isRounded'] ? entry.color : 'transparent'}
                                                    />
                                                )
                                            })}
                                        </Pie>
                                    )
                                })}
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                {insideTitle && (
                    <div
                        className={`${styles['donut-chart-with-circle-container__inside-text']}`}
                        style={{
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isInsideTitleBig ? '6rem' : '4rem',
                        }}
                    >
                        {insideTitle && (
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center">
                                    <span
                                        className={`${styles['donut-chart-with-circle-container__inside-text__title']}`}
                                        style={{
                                            fontSize: isInsideTitleBig ? '2rem' : '1.4rem',
                                            backgroundColor: insideTitleColor,
                                        }}
                                    >
                                        {insideTitle}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
})
