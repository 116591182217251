import { useCallback, useMemo, useReducer } from 'react';
import { weakPasswordOptions } from '../schemas/fieldSchemas';
import _ from 'lodash';
import { i18n } from '../configs/i18nextConf';

const ACTIONS = {
    CHANGE_VALUE: 'value',
    REPLACE_VALUE: 'replace',
    CHANGE_ERROR: 'error',
    RESET: 'reset'
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.CHANGE_VALUE:
            let newState = 'normal';
            let error = undefined;
            if (_.has(state, 'schema') && state.schema) {
                const result = state.schema.validate(action.payload, {
                    abortEarly: false
                });
                if (result.error) {
                    let resultError = '';
                    let errorConfig = {};
                    if (result.error.details[0].message.startsWith('custom:')) {
                        resultError =
                            result.error.details[0].message.split(':')[1];
                    } else if (
                        result.error.details[0].type ===
                        'passwordComplexity.tooShort'
                    ) {
                        resultError = `${result.error.details[0].type}.${weakPasswordOptions.min}`;
                    } else if (
                        result.error.details[0].type ===
                        'passwordComplexity.tooLong'
                    ) {
                        resultError = `${result.error.details[0].type}.${weakPasswordOptions.max}`;
                    } else if (
                        result.error.details[0].type ===
                        'passwordComplexity.lowercase'
                    ) {
                        resultError = `${result.error.details[0].type}.${weakPasswordOptions.lowerCase}`;
                    } else if (
                        result.error.details[0].type ===
                        'passwordComplexity.uppercase'
                    ) {
                        resultError = `${result.error.details[0].type}.${weakPasswordOptions.upperCase}`;
                    } else if (result.error.details[0].type === 'string.min') {
                        resultError = `${result.error.details[0].type}`;
                        errorConfig = {
                            limit: result.error.details[0].context.limit
                        };
                    } else if (result.error.details[0].type === 'string.max') {
                        resultError = `${result.error.details[0].type}`;
                        errorConfig = {
                            limit: result.error.details[0].context.limit
                        };
                    } else if (result.error.details[0].type === 'number.min') {
                        resultError = `${result.error.details[0].type}`;
                        errorConfig = {
                            limit: result.error.details[0].context.limit
                        };
                    } else if (result.error.details[0].type === 'number.max') {
                        resultError = `${result.error.details[0].type}`;
                        errorConfig = {
                            limit: result.error.details[0].context.limit
                        };
                    } else if (
                        result.error.details[0].type === 'number.greater'
                    ) {
                        resultError = `${result.error.details[0].type}`;
                        errorConfig = {
                            limit: result.error.details[0].context.limit
                        };
                    } else {
                        resultError = result.error.details[0].type;
                    }
                    newState = 'error';
                    error = i18n.t(resultError, errorConfig);
                }
            }
            return {
                ...state,
                value: action.payload,
                state: newState,
                error
            };
        case ACTIONS.CHANGE_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    state: 'error',
                    error: action.payload
                };
            } else {
                return {
                    ...state,
                    state: 'normal',
                    error: ''
                };
            }
        case ACTIONS.RESET:
            return {
                ...action.payload
            };
        case ACTIONS.REPLACE_VALUE:
            return {
                ...state,
                state: 'normal',
                value: action.payload
            };
    }
}

export function useTextInput(defaultValue) {
    const [value, dispatch] = useReducer(reducer, defaultValue);

    const setValue = useCallback((e) => {
        dispatch({ type: 'value', payload: e.target.value });
    }, []);

    const replaceValue = useCallback((value) => {
        dispatch({ type: 'value', payload: value });
    }, []);

    const setError = useCallback((value) => {
        dispatch({ type: 'error', payload: value });
    }, []);

    const reset = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue });
    }, [defaultValue]);

    const actions = useMemo(() => {
        return {
            onChangeInput: setValue,
            onSetError: setError,
            onReset: reset,
            onReplaceValue: replaceValue
        };
    }, [setValue, setError, reset, replaceValue]);

    return { value, actions, reset, setValue, setError, replaceValue };
}
