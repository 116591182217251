import _ from 'lodash';
import React from 'react';
import { ReactComponent as IcoMessage } from './assets/images/ico-message.svg';
import styles from './notification-list.module.scss';
import { ListWrapper } from '../..';

export const NotificationList = React.memo(
    ({
        title,
        notificationList = [],
        placeholder,
        successLabel,
        failedLabel,
        viewText,
        onViewMore,
        readText,
        unreadText,
        onHandleClick
    }) => {
        return (
            <div className={`${styles['notification-list']}`}>
                <div
                    className={`row mx-0 w-100 ${styles['notification-list__title-wrapper']}`}
                >
                    <div className="col px-0 d-flex justify-content-start align-items-center">
                        <span
                            className={`${styles['notification-list__title-wrapper__title']}`}
                        >
                            {title}
                        </span>
                    </div>
                    <div
                        className="col-auto px-0 d-flex justify-content-start align-items-center notification-list__title-wrapper__clickable-text-container"
                        onClick={onViewMore}
                    >
                        <span
                            className={`${styles['notification-list__title-wrapper__clickable-text-container__clickable-text']}`}
                        >
                            {viewText}
                        </span>
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100 ${styles['notification-list__list-wrapper']}`}
                >
                    <div
                        className={`col px-0 ${styles['notification-list__list-wrapper__item-wrapper']}`}
                    >
                        <ListWrapper>
                            <div className="w-100 h-100 d-flex flex-column align-items-center">
                                {notificationList.map((el, index) => {
                                    return (
                                        <div
                                            className="w-100"
                                            key={index}
                                            onClick={() => onHandleClick(el)}
                                        >
                                            {index !== 0 && (
                                                <div
                                                    className={`row mx-0 ${styles['notification-list__list-wrapper__item-wrapper__separator']}`}
                                                ></div>
                                            )}
                                            <div
                                                className={
                                                    !el.read
                                                        ? `row w-100 mx-0 ${styles['notification-list__list-wrapper__item-wrapper__item']}`
                                                        : `row w-100 mx-0 ${styles['notification-list__list-wrapper__item-wrapper__item']} ${styles['read']}`
                                                }
                                            >
                                                <div className="col-2 px-0 flex-grow-1 d-flex justify-content-center align-items-center">
                                                    <IcoMessage
                                                        alt=""
                                                        className={`${styles['notification-list__list-wrapper__item-wrapper__item__image']}`}
                                                    />
                                                </div>
                                                <div className="col-8 flex-grow-1 px-2 d-flex flex-column justify-content-between align-items-start py-2">
                                                    {el.statusText && (
                                                        <div className="row mx-0">
                                                            <span
                                                                className={`${styles['notification-list__list-wrapper__item-wrapper__item__status-text']}`}
                                                            >
                                                                {el.statusText}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {el.text && (
                                                        <div className="row mx-0">
                                                            <span
                                                                className={`${styles['notification-list__list-wrapper__item-wrapper__item__text']}`}
                                                            >
                                                                {el.text}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="row mx-0">
                                                        <span
                                                            className={
                                                                el.success
                                                                    ? `${styles['notification-list__list-wrapper__item-wrapper__item__text']} ${styles['success']}`
                                                                    : `${styles['notification-list__list-wrapper__item-wrapper__item__text']} ${styles['fail']}`
                                                            }
                                                        >
                                                            {el.success
                                                                ? successLabel
                                                                : failedLabel}
                                                        </span>
                                                    </div>
                                                    {el.date && (
                                                        <div className="row mx-0">
                                                            <span
                                                                className={`${styles['notification-list__list-wrapper__item-wrapper__item__date-text']}`}
                                                            >
                                                                {el.date}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-2 px-0 d-flex justify-content-center align-items-center">
                                                    {!el.read && (
                                                        <div
                                                            className={`notification-list__list-wrapper__item-wrapper__item__unread`}
                                                        ></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {notificationList.length === 0 && (
                                    <div
                                        className={`row w-100 h-100 mx-0 ${styles['notification-list__list-wrapper__item-wrapper__no-data']}`}
                                    >
                                        <div className="col h-100 px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['notification-list__list-wrapper__item-wrapper__no-data__text']}`}
                                            >
                                                {placeholder}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </ListWrapper>
                    </div>
                </div>
            </div>
        );
    }
);
