import React from 'react';
import { Dot } from 'recharts';

export const CustomDot = React.memo((props) => {
    const {
        cx,
        cy,
        stroke,
        index,
        currentIndex,
        currentDataKey,
        dataKey,
        handleMouseOverAreaDot,
        interactionDisabled,
        hideDot,
        isReverseColor
    } = props;
    if (!hideDot) {
        return (
            <svg
                key={index}
                onMouseOver={
                    !interactionDisabled
                        ? () =>
                              handleMouseOverAreaDot(
                                  currentIndex,
                                  isReverseColor ? dataKey : currentDataKey,
                                  cx,
                                  cy,
                                  index
                              )
                        : undefined
                }
            >
                <Dot cx={cx} cy={cy} r={4} fill={stroke} />
                <Dot
                    cx={cx}
                    cy={cy}
                    r={isReverseColor ? 2.5 : 1}
                    fill="#ffffff"
                />
            </svg>
        );
    } else {
        return null;
    }
});
