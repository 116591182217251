import React from 'react'
import icoReportOverflowWarning from '../../../assets/images/icoReportOverflowWarning.svg'
import useCursorTracking from '../../../hooks/useCursorTracking'
import styles from './component-warning.module.scss'

export const ComponentWarning = ({ message }) => {
    const [isHovered, setIsHovered] = React.useState(false)
    const { position } = useCursorTracking()
    return (
        <div>
            <img
                src={icoReportOverflowWarning}
                alt="Report overflow warning"
                style={{
                    width: '1.6rem',
                    height: '1.6rem',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            {isHovered && (
                <div
                    className={`${styles['component-warning-container__tooltip']}`}
                    style={{
                        top: position.y,
                        left: `calc(${position.x}px - 32rem)`,
                        display: position.x === 0 && position.y === 0 ? 'none' : 'block',
                    }}
                >
                    <span className={`${styles['component-warning-container__text']}`}>{message}</span>
                </div>
            )}
        </div>
    )
}
