//------------------------------------------------------------------------------------
// Styles and Packages
//------------------------------------------------------------------------------------

import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

//------------------------------------------------------------------------------------
// Images
//------------------------------------------------------------------------------------

import icoAiSegments from '../../../../../assets/images/icoAiSegments.svg'
import icoDemographicSegments from '../../../../../assets/images/icoDemographicSegments.svg'
import icoBehaviouralSegments from '../../../../../assets/images/icoBehaviouralSegments.svg'
import icoMixSegments from '../../../../../assets/images/icoMixSegments.svg'
import icoHandPickSegments from '../../../../../assets/images/icoHandPickSegments.svg'
import icoPlus from '../../../../../assets/images/ico-plus.svg'
import icoDragDots from '../../../../../assets/images/icoDragDots.svg'

//------------------------------------------------------------------------------------
// Providers and Hooks
//------------------------------------------------------------------------------------

import { ComponentTabsProvider } from '../../../../../providers/component-tabs-context'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useTable } from '../../../../../hooks/useTable'

//------------------------------------------------------------------------------------
// Components
//------------------------------------------------------------------------------------

import { EmptyList } from '../../../../../components/empty-states/empty-list/empty-list'
import { CreateSegmentOptionsModal } from '../../../../../components/modals/create-segment-options-modal/create-segment-options-modal'
import { SegmentList } from '../../../../../components/dashboard-components/segment-list/segment-list'
import { PrimaryButton } from '../../../../../components'
import { CreateAISegmentModal } from '../../../../../components/modals/create-ai-segment-modal/create-ai-segment-modal'

const segmentListMock = {
    attr: 'segmentList',
    type: 'type-1',
    data: [
        {
            name: 'Young adults in Portugal',
            usersIncluded: 500,
            revenueYTD: 5000,
            avgRecency: 10,
            avgFrequency: 20,
            avgMonetary: 30,
        },
    ],
    columns: [
        {
            path: 'segmentName',
            label: '',
            sortable: true,
        },
        {
            path: 'segmentCreatedAt',
            show: false,
            label: '',
            sortable: true,
        },
        {
            path: 'usersIncluded',
            label: '',
            sortable: true,
        },
        {
            path: 'revenueYTD',
            label: '',
            sortable: true,
        },
        {
            path: 'avgRecency',
            label: '',
            sortable: true,
        },
        {
            path: 'avgFrequency',
            label: '',
            sortable: true,
        },
        {
            path: 'avgMonetary',
            label: '',
            sortable: true,
        },
    ],
}

export const TabSegments = () => {
    const { t } = useCustomTranslation()
    const [currentTab, setCurrentTab] = useState('all')
    const navigate = useNavigate()
    const location = useLocation()
    const [createSegmentOptionModal, setCreateSegmentOptionModal] = useState(location.pathname === '/segment-analysis/segments/new-segment')
    const [createAISegment, createAISegmentSetValue] = useState(false)
    const { value, setLoading, setValue: setData, pageChange: dataPageChange, clickActionIcon } = useTable(segmentListMock)
    const tabs = useMemo(() => {
        return [
            {
                label: t('pages:segmentAnalysis.listTabs.all'),
                value: 'all',
                icon: undefined,
            },
            {
                label: t('pages:segmentAnalysis.listTabs.demographic'),
                value: 'demographic',
                icon: icoDemographicSegments,
            },
            {
                label: t('pages:segmentAnalysis.listTabs.behavioural'),
                value: 'behavioural',
                icon: icoBehaviouralSegments,
            },
            {
                label: t('pages:segmentAnalysis.listTabs.mix'),
                value: 'mix',
                icon: icoMixSegments,
            },
            {
                label: t('pages:segmentAnalysis.listTabs.handPick'),
                value: 'handPick',
                icon: icoHandPickSegments,
            },
            {
                label: t('pages:segmentAnalysis.listTabs.ai'),
                value: 'ai',
                icon: icoAiSegments,
            },
        ]
    }, [t])

    const handleSetSegmentOptionModal = (value) => {
        if (value) {
            navigate('/segment-analysis/segments/new-segment')
        } else {
            navigate('/segment-analysis/segments')
        }
        setCreateSegmentOptionModal(value)
    }

    const handleOption = (value) => {
        if (value === 'demographic') {
            navigate('/segment-analysis/segments/new-segment/demographic')
        } else if (value === 'behavioural') {
            navigate('/segment-analysis/segments/new-segment/behavioural')
        } else if (value === 'mix') {
            navigate('/segment-analysis/segments/new-segment/mix')
        } else if (value === 'handPick') {
            navigate('/segment-analysis/segments/new-segment/hand-picked')
        } else if (value === 'ai') {
            createAISegmentSetValue(true)
            setCreateSegmentOptionModal(false)
        }
    }

    const handleSetAIResult = useCallback(
        (value) => {
            navigate('/segment-analysis/segments/new-segment/ai', { state: { data: value } })
        },
        [navigate]
    )

    const handleReturnFromAISegmentCreation = () => {
        setCreateSegmentOptionModal(true)
        createAISegmentSetValue(false)
    }

    return (
        <>
            {createSegmentOptionModal && (
                <CreateSegmentOptionsModal onCloseModal={() => handleSetSegmentOptionModal(false)} onClickOption={handleOption} />
            )}
            {createAISegment && (
                <CreateAISegmentModal
                    onCloseModal={() => createAISegmentSetValue(false)}
                    onBack={handleReturnFromAISegmentCreation}
                    onSetAIResult={handleSetAIResult}
                />
            )}
            {value.data.length !== 0 && (
                <>
                    <div className="row mx-0 w-100 d-flex justify-content-end align-items-center" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <span className="dashboard-sub-title">{t('pages:segmentAnalysis.description')}</span>
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton text={t('buttons:createSegment')} icon={icoPlus} onClick={() => handleSetSegmentOptionModal(true)} />
                        </div>
                    </div>

                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <ComponentTabsProvider
                                value={{
                                    tabs: tabs,
                                    currentTab: currentTab,
                                    setCurrentTab: setCurrentTab,
                                }}
                            >
                                <SegmentList {...value} withTabs />
                            </ComponentTabsProvider>
                        </div>
                    </div>
                </>
            )}
            {value.data.length === 0 && <EmptyList text={t('pages:segmentAnalysis.nodata')} onClick={() => handleSetSegmentOptionModal(true)} />}
        </>
    )
}
