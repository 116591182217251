import React from 'react'
import styles from './assign-to-table-column.module.scss'

export const AssignToTableColumn = React.memo(({ el, column }) => {
    return (
        <div className={`${styles['assign-to-table-column-container']}`}>
            {el[column.path] && el[column.path].length !== 0 && (
                <div className="d-flex justify-content-start align-items-end">
                    {el[column.path].slice(0, 4).map((el2, index3) => {
                        return (
                            <img
                                key={index3}
                                src={el2.image}
                                alt="user"
                                className={`${styles['assign-to-table-column-container__table-image']}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                                loading="lazy"
                            />
                        )
                    })}
                    {el[column.path].length > 4 ? '...' : ''}
                </div>
            )}
        </div>
    )
})
