import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const PrecipitationTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.id !== '') {
        return (
            <TooltipContainer color={props.color}>
                <TooltipHeaderRow
                    title={t('tooltips:header.precipitation')}
                    value={props.payload[props.id].payload.month}
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.avg')}
                    value={`${parseFloat(
                        props.payload[props.id].payload.avgP
                    ).toFixed(2)} 
                     mm`}
                    color={props.color}
                />
            </TooltipContainer>
        )
    } else return null
})
