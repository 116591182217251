// Importing required libraries and components
import React from 'react';
import PropTypes from 'prop-types';
import { EditHeader } from './components/edit-header/edit-header';
import { DraftHeader } from './components/draft-header/draft-header';
import { PreviewHeader } from './components/preview-header/preview-header';
import { DefaultHeader } from './components/default-header/default-header';
import { PreviewReportHeader } from './components/preview-report-header/preview-report-header';
import { FilterConfigHeader } from './components/filter-config-header/filter-config-header';
import { PreviewReportEditHeader } from './components/preview-report-edit-header/preview-report-edit-header';
import { EditConfigHeader } from './components/edit-config-header/edit-config-header';
import { PreviewHeaderFinal } from './components/preview-header-final/preview-header-final';
import { PreviewGenerationHeader } from './components/preview-generation-header/preview-generation-header';

/**
 * DashboardHeaderFactory returns the appropriate header component based on the type and isEdit properties.
 */
export const DashboardHeaderFactory = React.memo((props) => {
    const { type, isEdit } = props;

    const headers = {
        draft: isEdit ? EditHeader : DraftHeader,
        preview: PreviewHeader,
        previewFinal: PreviewHeaderFinal,
        previewReport: PreviewReportHeader,
        previewGeneration: PreviewGenerationHeader,
        previewReportEdit: PreviewReportEditHeader,
        filterConfig: FilterConfigHeader,
        editConfig: EditConfigHeader
    };

    const HeaderComponent = headers[type] || DefaultHeader;

    return <HeaderComponent {...props} />;
});

// Defining prop types for DashboardHeaderFactory
DashboardHeaderFactory.propTypes = {
    type: PropTypes.string,
    isEdit: PropTypes.bool,
    lastState: PropTypes.string,
    selectedOption: PropTypes.string,
    dashboardTitle: PropTypes.string,
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    onCreatePage: PropTypes.func,
    onNavigate: PropTypes.func,
    onUpdateRoute: PropTypes.func,
    onPublishRoute: PropTypes.func
};
