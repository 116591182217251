export const getValueInString = (value, type) => {
    if (type === 'units') {
        return value
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else if (type === 'values') {
        return (
            '€' +
            value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        )
    } else if (type === 'percentage') {
        return (
            value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'
        )
    } else {
        return value
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
}
