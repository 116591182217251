import _ from 'lodash'
import React from 'react'
import styles from './modal-popup.module.scss'

export const ModalPopup = React.memo((props) => {
    const newStyles = {}
    if (_.has(props, 'top')) newStyles['top'] = props['top']
    if (_.has(props, 'bottom')) newStyles['bottom'] = props['bottom']
    if (_.has(props, 'left')) newStyles['left'] = props['left']
    if (_.has(props, 'right')) newStyles['right'] = props['right']
    return (
        <React.Fragment>
            <div className={`${styles['modal-popup-container']}`}></div>
            <div
                className={
                    _.has(props, 'direction')
                        ? `${styles['modal-popup-container__content']} ${
                              styles[
                                  `modal-popup-container__content--${props.direction}`
                              ]
                          }`
                        : `${styles['modal-popup-container__content']}`
                }
                style={newStyles}
            >
                {_.has(props, 'direction') && (
                    <div
                        className={`${
                            styles['modal-popup-container__content__arrow']
                        } ${
                            styles[
                                `modal-popup-container__content__arrow--${props.direction}`
                            ]
                        }`}
                    ></div>
                )}
                <div
                    className={`${styles['modal-popup-container__content__wrapper']}`}
                >
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
})

ModalPopup.propTypes = {}
