import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { CLUSTER_COLORS } from '../../../constants/color';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useChartReady } from '../../../providers/chart-ready-context';
import { useFilter } from '../../../providers/filter-context';
import { SegmentationService } from '../../../temp/test';
import { segmentComparisonMock } from './mock';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { RadarChartContainer } from '../../charts/radar-chart-container/radar-chart-container';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

export const SegmentComparison = React.memo(
    ({ segmentId, clientsAdded, segmentFilters }) => {
        const { charts } = useChartReady();
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(segmentComparisonMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(segmentComparisonMock);
                try {
                    const segmentClientsList = [];
                    const segmentComparisonData = {};
                    const allClientsList = [];
                    if (segmentId) {
                        setLoading(true);
                        dataClone.legend = [];
                        segmentComparisonData[`segment-${segmentId}`] = {
                            id: segmentId,
                            clientList: [],
                            color: ''
                        };
                        const segmentResults =
                            await SegmentationService.getApiSegmentation1({
                                id: segmentId
                            });
                        segmentComparisonData[`segment-${segmentId}`]['name'] =
                            segmentResults.name;
                        const results =
                            await SegmentationService.getApiSegmentationClients(
                                { id: segmentId }
                            );
                        results.map(async (el) => {
                            segmentComparisonData[`segment-${segmentId}`][
                                'clientList'
                            ].push({
                                segmentId: segmentId,
                                id: el.client_id ? el.client_id : '-',
                                name: el.name ? el.name : '-',
                                revenue: el.total_revenue,
                                sentiment: el.avg_sentiment
                                    ? el.avg_sentiment
                                    : 0,
                                risk: el.risk
                            });
                            return el;
                        });
                        if (segmentResults.children_count > 0) {
                            const segmentChildrenResults =
                                await SegmentationService.getApiSegmentationChildren(
                                    {
                                        id: segmentId
                                    }
                                );
                            segmentChildrenResults.map((el2, index2) => {
                                if (
                                    parseInt(el2['id']) === parseInt(segmentId)
                                ) {
                                    segmentComparisonData[
                                        `segment-${segmentId}`
                                    ]['color'] = `${
                                        CLUSTER_COLORS[index2 + 1]
                                    }`;
                                }
                                return el2;
                            });
                        }
                        const childrenResults =
                            await SegmentationService.getApiSegmentationChildren(
                                {
                                    id: segmentId
                                }
                            );
                        childrenResults.map(async (el) => {
                            segmentComparisonData[`segment-${el.id}`] = {
                                id: el.id,
                                name: el.name,
                                clientList: []
                            };
                            const clientResults =
                                await SegmentationService.getApiSegmentationClients(
                                    {
                                        id: el.id
                                    }
                                );
                            clientResults.map((el2) => {
                                segmentComparisonData[`segment-${el.id}`][
                                    'clientList'
                                ].push({
                                    segmentId: el.id,
                                    id: el2.client_id ? el2.client_id : '-',
                                    name: el2.name ? el2.name : '-',
                                    revenue: el2.total_revenue,
                                    risk: el2.risk,
                                    sentiment: el.avg_sentiment
                                        ? el.avg_sentiment
                                        : 0
                                });
                                return el2;
                            });
                            return el;
                        });
                        dataClone.legend = {
                            name: segmentComparisonData[`segment-${segmentId}`]
                                .name,
                            color:
                                segmentComparisonData[`segment-${segmentId}`]
                                    .color || `${CLUSTER_COLORS[segmentId]}`,
                            translated: true
                        };
                    }
                    let segmentClientsData = [];
                    const dataBack =
                        await SegmentationService.getApiSegmentationSegmentationClients();
                    if (segmentFilters && segmentFilters.length > 0) {
                        const previewRules = [];
                        segmentFilters.map((el) => {
                            let pointOfSaleRulesGroupRulesSchema = {
                                entity: el.entity,
                                group_func: el.grouper,
                                operator: el.operator,
                                param: el.name,
                                value: el.value
                            };
                            previewRules.push(pointOfSaleRulesGroupRulesSchema);
                            return el;
                        });
                        segmentClientsData =
                            await SegmentationService.postApiSegmentationRulesPreview(
                                {
                                    requestBody: previewRules
                                }
                            );
                    }
                    dataBack.map((el) => {
                        if (
                            clientsAdded &&
                            clientsAdded.length > 0 &&
                            clientsAdded.includes(el.id)
                        ) {
                            segmentClientsList.push({
                                id: el.id ? el.id : '-',
                                name: el.name ? el.name : '-',
                                revenue: el.total_revenue,
                                risk: el.risk,
                                sentiment: el.avg_sentiment
                                    ? el.avg_sentiment
                                    : 0
                            });
                        } else if (
                            segmentClientsData &&
                            segmentClientsData.length > 0
                        ) {
                            segmentClientsList.push({
                                id: el.id ? el.id : '-',
                                name: el.name ? el.name : '-',
                                revenue: el.total_revenue,
                                risk: el.risk,
                                sentiment: el.avg_sentiment
                                    ? el.avg_sentiment
                                    : 0
                            });
                        }
                        allClientsList.push({
                            id: el.id ? el.id : '-',
                            name: el.name ? el.name : '-',
                            revenue: el.total_revenue,
                            risk: el.risk,
                            sentiment: el.avg_sentiment ? el.avg_sentiment : 0
                        });
                    });
                    if (Object.entries(segmentComparisonData).length > 0) {
                        dataClone.radarAttr = [];
                        dataClone.data = [];
                        dataClone.legend = [];
                        Object.entries(segmentComparisonData).map(
                            (el, index) => {
                                const billingMax = _.maxBy(
                                    allClientsList,
                                    (el2) => {
                                        return el2.revenue;
                                    }
                                ).revenue;
                                const billingMean = _.meanBy(
                                    el[1]['clientList'],
                                    (el2) => {
                                        return el2.revenue;
                                    }
                                );
                                const billingMin = _.minBy(
                                    allClientsList,
                                    (el2) => {
                                        return el2.revenue;
                                    }
                                ).revenue;
                                const riskMax = _.maxBy(
                                    allClientsList,
                                    (el2) => {
                                        return el2.risk;
                                    }
                                ).risk;
                                const riskMean = _.meanBy(
                                    el[1]['clientList'],
                                    (el2) => {
                                        return el2.risk;
                                    }
                                );
                                const riskMin = _.minBy(
                                    allClientsList,
                                    (el2) => {
                                        return el2.risk;
                                    }
                                ).risk;
                                const sentimentMax = _.maxBy(
                                    allClientsList,
                                    (el2) => {
                                        return el2.sentiment;
                                    }
                                ).sentiment;
                                const sentimentMean = _.meanBy(
                                    el[1]['clientList'],
                                    (el2) => {
                                        return el2.sentiment;
                                    }
                                );
                                const sentimentMin = _.minBy(
                                    allClientsList,
                                    (el2) => {
                                        return el2.sentiment;
                                    }
                                ).sentiment;
                                dataClone.varMap = {
                                    Revenue: {
                                        min: billingMin,
                                        max: billingMax
                                    },
                                    Risk: { min: riskMin, max: riskMax },
                                    Sentiment: {
                                        min: sentimentMin,
                                        max: sentimentMax
                                    }
                                };
                                const selectedSegment = `segment-${el[1].id}`;
                                dataClone.radarAttr.push({
                                    name: selectedSegment,
                                    dataKey: selectedSegment,
                                    color:
                                        el[1].color ||
                                        `${CLUSTER_COLORS[index]}`
                                });
                                if (dataClone.data.length === 0) {
                                    dataClone.data.push(
                                        {
                                            subject: 'Revenue',
                                            [selectedSegment]:
                                                (billingMean - billingMin) /
                                                (billingMax - billingMin),
                                            fullMark:
                                                dataClone.varMap['Revenue'].max,
                                            [`${selectedSegment}-min`]:
                                                billingMin,
                                            [`${selectedSegment}-max`]:
                                                billingMax
                                        },
                                        {
                                            subject: 'Risk',
                                            [selectedSegment]:
                                                (riskMean - riskMin) /
                                                (riskMax - riskMin),
                                            fullMark:
                                                dataClone.varMap['Risk'].max,
                                            [`${selectedSegment}-min`]: riskMin,
                                            [`${selectedSegment}-max`]: riskMax
                                        },
                                        {
                                            subject: 'Sentiment',
                                            [selectedSegment]:
                                                (sentimentMean - sentimentMin) /
                                                (sentimentMax - sentimentMin),
                                            fullMark:
                                                dataClone.varMap['Sentiment']
                                                    .max,
                                            [`${selectedSegment}-min`]:
                                                sentimentMin,
                                            [`${selectedSegment}-max`]:
                                                sentimentMax
                                        }
                                    );
                                } else {
                                    dataClone.data.map((el2) => {
                                        if (el2.subject === 'Revenue') {
                                            el2[selectedSegment] =
                                                (billingMean - billingMin) /
                                                (billingMax - billingMin);
                                            el2[`${selectedSegment}-min`] =
                                                billingMin;
                                            el2[`${selectedSegment}-max`] =
                                                billingMax;
                                        } else if (el2.subject === 'Risk') {
                                            el2[selectedSegment] =
                                                (riskMean - riskMin) /
                                                (riskMax - riskMin);
                                            el2[`${selectedSegment}-min`] =
                                                riskMin;
                                            el2[`${selectedSegment}-max`] =
                                                riskMax;
                                        } else if (
                                            el2.subject === 'Sentiment'
                                        ) {
                                            el2[selectedSegment] =
                                                (sentimentMean - sentimentMin) /
                                                (sentimentMax - sentimentMin);
                                            el2[`${selectedSegment}-min`] =
                                                sentimentMin;
                                            el2[`${selectedSegment}-max`] =
                                                sentimentMax;
                                        }
                                        return el2;
                                    });
                                }
                                dataClone.legend.push({
                                    name: el[1].name,
                                    color:
                                        el[1].color ||
                                        `${CLUSTER_COLORS[index]}`,
                                    translated: true
                                });
                                return el;
                            }
                        );
                    }
                    if (segmentClientsList.length > 0) {
                        const billingMax = _.maxBy(allClientsList, (el) => {
                            return el.revenue;
                        }).revenue;
                        const billingMean = _.meanBy(
                            segmentClientsList,
                            (el) => {
                                return el.revenue;
                            }
                        );
                        const billingMin = _.minBy(allClientsList, (el) => {
                            return el.revenue;
                        }).revenue;
                        const riskMax = _.maxBy(allClientsList, (el) => {
                            return el.risk;
                        }).risk;
                        const riskMean = _.meanBy(segmentClientsList, (el) => {
                            return el.risk;
                        });
                        const riskMin = _.minBy(allClientsList, (el) => {
                            return el.risk;
                        }).risk;
                        const sentimentMax = _.maxBy(allClientsList, (el) => {
                            return el.sentiment;
                        }).sentiment;
                        const sentimentMean = _.meanBy(
                            segmentClientsList,
                            (el) => {
                                return el.sentiment;
                            }
                        );
                        const sentimentMin = _.minBy(allClientsList, (el) => {
                            return el.sentiment;
                        }).sentiment;
                        dataClone.varMap = {
                            Billing: { min: billingMin, max: billingMax },
                            Risk: { min: riskMin, max: riskMax },
                            Sentiment: { min: sentimentMin, max: sentimentMax }
                        };
                        const selectedSegment = `segment`;
                        dataClone.radarAttr = [
                            {
                                name: 'segment',
                                dataKey: selectedSegment,
                                color: '#7a5ea5'
                            }
                        ];
                        dataClone.data = [
                            {
                                subject: 'Billing',
                                segment:
                                    (billingMean - billingMin) /
                                    (billingMax - billingMin),
                                fullMark: dataClone.varMap['Billing'].max
                            },
                            {
                                subject: 'Risk',
                                segment:
                                    (riskMean - riskMin) / (riskMax - riskMin),
                                fullMark: dataClone.varMap['Risk'].max
                            },
                            {
                                subject: 'Sentiment',
                                segment:
                                    (sentimentMean - sentimentMin) /
                                    (sentimentMax - sentimentMin),
                                fullMark: dataClone.varMap['Sentiment'].max
                            }
                        ];
                    }
                    if (clientsAdded && clientsAdded.length > 0) {
                        dataClone.legend = undefined;
                    }

                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            filter,
            isMounted,
            setLoading,
            setValue,
            t,
            clientsAdded,
            segmentId,
            segmentFilters
        ]);

        const handleSelectSettingsOption = useCallback(() => {
            if (isMounted.current) toggleComponentSettings();
        }, [isMounted, toggleComponentSettings]);

        return (
            <ContentContainer
                attr={data.attr}
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
                onClickSettingsView={handleSelectSettingsOption}
                onCLickSettintsSelected={handleSelectSettingsOption}
                onCloseSettings={toggleComponentSettings}
                // // hovered={hovered}
                // onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                // onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                // hideInfoIcon={true}
                // hidePinIcon={isExample || isPreview || isReportExample}
                // hideSettingsIcon={isExample || isPreview || isReportExample}
                // pinned={pinned}
                // onPinComponent={onPinComponent}
                // onUnpinComponent={onUnpinComponent}
                // interval={'1M'}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <RadarChartContainer
                        angle={data.angle}
                        radarAttr={data.radarAttr}
                        data={data.data}
                        dataInterval={data.dataInterval}
                        categoryAttr={data.categoryAttr}
                        tooltipType={data.tooltipType}
                        varMap={data.varMap}
                        unitsOrValues={data.unitsOrValues}
                        translate={t}
                    />
                )}
            </ContentContainer>
        );
    }
);
