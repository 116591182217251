import styles from './config-client.module.scss';

import Joi from 'joi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTextInput } from '../../../hooks/useTextInput';
import { useToggle } from '../../../hooks/useToggle';
import { DefinitionsService } from '../../../temp/test';
import {
    LoaderForeground,
    PrimaryButton,
    Spinner,
    TerciaryButton,
    TypeInput
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { success } from '../../../components/notifications/notification/notification';

/**
 * Provides information about client configuration
 */

const ConfigClient = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();

    const [loading, toggleLoading] = useToggle(true);
    const {
        value: timeActiveClient,
        setValue: setTimeActiveClientValue,
        setError: setTimeActiveClientError,
        replaceValue: timeActiveClientReplace
    } = useTextInput({
        name: 'timeActiveClient',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });
    const {
        value: timeDormedClient,
        setValue: setTimeDormedClientValue,
        setError: setTimeDormedClientError,
        replaceValue: timeDormedClientReplace
    } = useTextInput({
        name: 'timeDormedClient',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });
    const {
        value: timeLostClient,
        setValue: setTimeLostClientValue,
        setError: setTimeLostClientError,
        replaceValue: timeLostClientReplace
    } = useTextInput({
        name: 'timeLostClient',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const schema = Joi.object({
        timeActiveClient: Joi.string().required(),
        timeDormedClient: Joi.string().required(),
        timeLostClient: Joi.string().required()
    });

    useEffect(() => {
        async function fill() {
            try {
                const timeActiveClient =
                    await DefinitionsService.getApiBackofficeSettingsTimeActiveClient();
                const timeDormedClient =
                    await DefinitionsService.getApiBackofficeSettingsTimeDormedClient();
                const timeLostClient =
                    await DefinitionsService.getApiBackofficeSettingsTimeLostClient();
                if (isMounted.current) {
                    timeActiveClientReplace(timeActiveClient.result.toString());
                    timeDormedClientReplace(timeDormedClient.result.toString());
                    timeLostClientReplace(timeLostClient.result.toString());
                    toggleLoading(false);
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [
        isMounted,
        timeActiveClientReplace,
        timeDormedClientReplace,
        timeLostClientReplace,
        toggleLoading
    ]);

    const handleSaveDefinitions = async () => {
        try {
            const result = schema.validate(
                {
                    timeActiveClient: timeActiveClient.value,
                    timeDormedClient: timeDormedClient.value,
                    timeLostClient: timeLostClient.value
                },
                { abortEarly: false }
            );
            if (isMounted.current) {
                setTimeActiveClientError();
                setTimeDormedClientError();
                setTimeLostClientError();
            }
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'timeActiveClient') {
                        if (isMounted.current)
                            setTimeActiveClientError(el.type);
                    } else if (el.path[0] === 'timeDormedClient') {
                        if (isMounted.current)
                            setTimeDormedClientError(el.type);
                    } else if (el.path[0] === 'timeLostClient') {
                        if (isMounted.current) setTimeLostClientError(el.type);
                    }
                });
                return;
            }
            await DefinitionsService.postApiBackofficeSettingsTimeActiveClient({
                requestBody: {
                    time_active_client: parseInt(timeActiveClient.value)
                }
            });
            await DefinitionsService.postApiBackofficeSettingsTimeDormedClient({
                requestBody: {
                    time_dormed_client: parseInt(timeDormedClient.value)
                }
            });
            await DefinitionsService.postApiBackofficeSettingsTimeLostClient({
                requestBody: {
                    time_lost_client: parseInt(timeLostClient.value)
                }
            });
            success({ text: t('notifications:saved') });
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['back-office-config-client-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row ">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.back-office.title')}
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div
                                className={`col p-0 h-auto d-flex flex-column justify-content-start align-items-center ${styles['back-office-config-client-container__content-wrapper']}`}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col ${styles['back-office-config-client-container__content']}`}
                                            >
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-client-container__content-wrapper__title-container']}`}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['back-office-config-client-container__content-wrapper__title-container__title']}`}
                                                        >
                                                            {t(
                                                                'pages:administration.back-office.sections.clients'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="col-2 px-0 d-flex justify-content-end align-items-center">
                                                        <TerciaryButton
                                                            text={t(
                                                                'buttons:save'
                                                            )}
                                                            onClick={
                                                                handleSaveDefinitions
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <hr
                                                    className={`${styles['back-office-config-client-container__separator']}`}
                                                />
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-client-container__definitions-container']} flex-grow-1`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-client-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0 d-flex justify-content-between align-items-center">
                                                                <span
                                                                    className={`${styles['back-office-config-client-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        `inputs:${timeActiveClient.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    onChangeInput={
                                                                        setTimeActiveClientValue
                                                                    }
                                                                    {...timeActiveClient}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-client-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0 d-flex justify-content-between align-items-center">
                                                                <span
                                                                    className={`${styles['back-office-config-client-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        `inputs:${timeDormedClient.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0 ">
                                                                <TypeInput
                                                                    onChangeInput={
                                                                        setTimeDormedClientValue
                                                                    }
                                                                    {...timeDormedClient}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-client-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0 d-flex justify-content-between align-items-center">
                                                                <span
                                                                    className={`${styles['back-office-config-client-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        `inputs:${timeLostClient.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0 ">
                                                                <TypeInput
                                                                    onChangeInput={
                                                                        setTimeLostClientValue
                                                                    }
                                                                    {...timeLostClient}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default ConfigClient;

ConfigClient.propTypes = {};
