import React, { useMemo } from 'react'
import styles from './drag-elements-input.module.scss'
import icoDelete from '../../../assets/images/icoDeleteComponent.svg'
import icoDragDots from '../../../assets/images/icoDragDots.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { SearchInputAlternative } from '../search-input-alternative/SearchInputAlternative'
import { Draggable } from '../../dnd/Draggable/Draggable'
import { ComponentWarning } from '../../dashboard-component-configuration/component-warning/component-warning'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, useDroppable, DragOverlay } from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { Sortable } from '../../dnd/Sortable/Sortable'
import _ from 'lodash'
import { Droppable } from '../../dnd/Droppable/Droppable'
import { error } from '../../notifications/notification/notification'

export const DragElementsInputs = ({
    elementsToAddTitle,
    elementsToAddDescription,
    elementsAddedTitle,
    elementsAddedDescription,
    elementsToAdd,
    elementsAdded,
    warningText,
    onSetElementsToAdd,
    onSetElementsAdded,
    elementsLimit,
}) => {
    const [activeElement, setActiveElement] = React.useState(undefined)
    const { t } = useCustomTranslation()
    const [searchValue, setSearchValue] = React.useState('')
    const elementsToAddSortedIds = useMemo(() => elementsToAdd.map((element) => element.id), [elementsToAdd])
    const elementsAddedSortedIds = useMemo(() => elementsAdded.map((element) => element.id), [elementsAdded])
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    function handleDragOver({ active, over }) {
        if (!over) return
        const activeContainer = active.data.current.sortable.containerId
        const overContainer = over.data.current?.sortable.containerId
        if (!overContainer) {
            if (over.id === 'elementsAdded') {
                if (elementsToAddSortedIds.includes(active.id)) {
                    onSetElementsToAdd((items) => {
                        return items.filter((item) => item.id !== active.id)
                    })
                    const element = elementsToAdd.find((item) => item.id === active.id)
                    onSetElementsAdded((items) => {
                        return [...items, element]
                    })
                }
            } else if (over.id === 'elementsToAdd') {
                if (elementsAddedSortedIds.includes(active.id)) {
                    onSetElementsAdded((items) => {
                        return items.filter((item) => item.id !== active.id)
                    })
                    const element = elementsAdded.find((item) => item.id === active.id)
                    onSetElementsToAdd((items) => {
                        return [...items, element]
                    })
                }
            }
            return
        }

        if (activeContainer !== overContainer) {
            const overIndex = over.data.current?.sortable.index || 0

            if (elementsToAddSortedIds.includes(active.id)) {
                onSetElementsToAdd((items) => {
                    return items.filter((item) => item.id !== active.id)
                })
                const element = elementsToAdd.find((item) => item.id === active.id)
                onSetElementsAdded((items) => {
                    return [...items.slice(0, overIndex), element, ...items.slice(overIndex)]
                })
            } else if (elementsAddedSortedIds.includes(active.id)) {
                onSetElementsAdded((items) => {
                    return items.filter((item) => item.id !== active.id)
                })
                const element = elementsAdded.find((item) => item.id === active.id)
                onSetElementsToAdd((items) => {
                    return [...items.slice(0, overIndex), element, ...items.slice(overIndex)]
                })
            }
        }
    }

    function handleDragStart({ active }) {
        setActiveElement({
            id: active.id,
            type: active.data.current.sortable.containerId,
            text: elementsToAdd.find((item) => item.id === active.id)?.text || elementsAdded.find((item) => item.id === active.id)?.text,
        })
    }

    function checkLimit() {
        if (elementsLimit && elementsAdded.length > elementsLimit) {
            error({
                text: t('notifications:limitColumns'),
            })
            return false
        }
        return true
    }

    function handleDragEnd({ active, over }) {
        if (!over) return
        const activeContainer = active.data.current.sortable.containerId
        const overContainer = over.data.current?.sortable.containerId
        if (activeContainer === overContainer) {
            if (activeContainer === 'elementsAdded' && !checkLimit()) {
                if (elementsAddedSortedIds.includes(active.id)) {
                    onSetElementsAdded((items) => {
                        return items.filter((item) => item.id !== active.id)
                    })
                    const element = elementsAdded.find((item) => item.id === active.id)
                    onSetElementsToAdd((items) => {
                        return [...items, element]
                    })
                }
            } else if (active.id !== over.id) {
                if (over.id === 'elementsAdded') {
                    if (elementsToAddSortedIds.includes(active.id) && checkLimit()) {
                        onSetElementsToAdd((items) => {
                            return items.filter((item) => item.id !== active.id)
                        })
                        const element = elementsToAdd.find((item) => item.id === active.id)
                        onSetElementsAdded((items) => {
                            return [...items, element]
                        })
                    }
                } else if (over.id === 'elementsToAdd') {
                    if (elementsAddedSortedIds.includes(active.id)) {
                        onSetElementsAdded((items) => {
                            return items.filter((item) => item.id !== active.id)
                        })
                        const element = elementsAdded.find((item) => item.id === active.id)
                        onSetElementsToAdd((items) => {
                            return [...items, element]
                        })
                    }
                } else if (active.data.current.sortable.containerId === over.data.current.sortable.containerId) {
                    if (elementsToAddSortedIds.includes(active.id)) {
                        onSetElementsToAdd((items) => {
                            const oldIndex = _.findIndex(elementsToAddSortedIds, (id) => id === active.id)
                            const newIndex = _.findIndex(elementsToAddSortedIds, (id) => id === over.id)
                            return arrayMove(items, oldIndex, newIndex)
                        })
                    } else if (elementsAddedSortedIds.includes(active.id)) {
                        onSetElementsAdded((items) => {
                            const oldIndex = _.findIndex(elementsAddedSortedIds, (id) => id === active.id)
                            const newIndex = _.findIndex(elementsAddedSortedIds, (id) => id === over.id)
                            return arrayMove(items, oldIndex, newIndex)
                        })
                    }
                } else {
                    if (elementsToAddSortedIds.includes(active.id)) {
                        onSetElementsAdded((items) => {
                            const oldIndex = _.findIndex(elementsToAddSortedIds, (id) => id === active.id)
                            const element = elementsToAdd[oldIndex]
                            return [...items, element]
                        })
                        onSetElementsToAdd((items) => {
                            const oldIndex = _.findIndex(elementsToAddSortedIds, (id) => id === active.id)
                            return items.filter((_, index) => index !== oldIndex)
                        })
                    } else if (elementsAddedSortedIds.includes(active.id) && checkLimit()) {
                        onSetElementsToAdd((items) => {
                            const oldIndex = _.findIndex(elementsAddedSortedIds, (id) => id === active.id)
                            const element = elementsAdded[oldIndex]
                            return [...items, element]
                        })
                        onSetElementsAdded((items) => {
                            const oldIndex = _.findIndex(elementsAddedSortedIds, (id) => id === active.id)
                            return items.filter((_, index) => index !== oldIndex)
                        })
                    }
                }
            }
        }
        setActiveElement(undefined)
    }

    const handleRemoveSelectedElement = (elementId) => {
        onSetElementsAdded((items) => {
            return items.filter((item) => item.id !== elementId)
        })
        const element = elementsAdded.find((item) => item.id === elementId)
        onSetElementsToAdd((items) => {
            return [...items, element]
        })
    }

    const handleRemoveAllElements = () => {
        onSetElementsAdded([])
        onSetElementsToAdd([...elementsToAdd, ...elementsAdded])
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <div className={styles['drag-elements-input-container']}>
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 d-flex flex-column">
                        <div className={styles['drag-elements-input-container__left-list']}>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <span className={styles['drag-elements-input-container__left-list__title']}>{elementsToAddTitle}</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <span className={styles['drag-elements-input-container__left-list__sub-title']}>{elementsToAddDescription}</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end" style={{ marginBottom: '2rem' }}>
                                <div className="col-7 px-0">
                                    <SearchInputAlternative
                                        value={searchValue}
                                        placeholder={t('inputs:searchUser')}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                            </div>
                            <SortableContext id="elementsToAdd" items={elementsToAdd} strategy={verticalListSortingStrategy}>
                                <div className="row mx-0 w-100 flex-grow-1">
                                    <div className="col px-0 h-100">
                                        <Droppable id={'elementsToAdd'}>
                                            {elementsToAdd.map((element) => {
                                                return (
                                                    <Sortable id={element.id} key={element.id}>
                                                        <div className={styles['drag-elements-input-container__left-list__element']}>
                                                            <div className="row mx-0 w-100">
                                                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                                    <img
                                                                        src={icoDragDots}
                                                                        alt="drag"
                                                                        className={styles['drag-elements-input-container__left-list__element__icon']}
                                                                    />
                                                                    <span
                                                                        className={styles['drag-elements-input-container__left-list__element__text']}
                                                                    >
                                                                        {t(`headers:${element.text}`)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Sortable>
                                                )
                                            })}
                                        </Droppable>
                                    </div>
                                </div>
                            </SortableContext>
                        </div>
                    </div>
                    <div className="col-auto px-0 position-relative">
                        <div className={styles['drag-elements-input-container__separator']}></div>
                    </div>
                    <div className="col px-0">
                        <div className={styles['drag-elements-input-container__right-list']}>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <div className={styles['drag-elements-input-container__right-list__title-container']}>
                                        <span className={styles['drag-elements-input-container__right-list__title-container__text']}>
                                            {elementsAddedTitle}
                                        </span>
                                        {warningText && <ComponentWarning message={warningText} />}
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 h-100 d-flex flex-column">
                                    <div className="row mx-0 w-100" style={{ padding: '2rem 2rem 0 2rem' }}>
                                        <div className="col px-0">
                                            <span className={styles['drag-elements-input-container__right-list__sub-title']}>
                                                {elementsAddedDescription}
                                                <b>{elementsAdded.length}</b>
                                            </span>{' '}
                                        </div>
                                        <div
                                            className="col-auto px-0 d-flex justify-content-center align-items-center"
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleRemoveAllElements}
                                        >
                                            <img
                                                className={styles['drag-elements-input-container__right-list__button-icon']}
                                                src={icoDelete}
                                                alt="drag"
                                            />
                                            <span className={styles['drag-elements-input-container__right-list__button-text']}>
                                                {t('common:removeAll')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SortableContext id="elementsAdded" items={elementsAdded} strategy={verticalListSortingStrategy}>
                                <div className="row mx-0 w-100 flex-grow-1">
                                    <div className="col px-0">
                                        <div className={styles['drag-elements-input-container__right-list__content-container']}>
                                            <Droppable id={'elementsAdded'}>
                                                {elementsAdded.map((element, index) => {
                                                    return (
                                                        <Sortable id={element.id} key={index}>
                                                            <div className={styles['drag-elements-input-container__right-list__element']}>
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                                        <img
                                                                            src={icoDragDots}
                                                                            alt="drag"
                                                                            className={
                                                                                styles['drag-elements-input-container__right-list__element__icon']
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col px-0" style={{ minWidth: 0 }}>
                                                                        <span
                                                                            className={
                                                                                styles['drag-elements-input-container__right-list__element__text']
                                                                            }
                                                                        >
                                                                            {t(`headers:${element.text}`)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-auto px-0">
                                                                        <img
                                                                            src={icoDelete}
                                                                            alt="delete"
                                                                            className={
                                                                                styles['drag-elements-input-container__right-list__element__icon']
                                                                            }
                                                                            style={{
                                                                                zIndex: 1,
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                e.preventDefault()
                                                                                handleRemoveSelectedElement(element.id)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Sortable>
                                                    )
                                                })}
                                            </Droppable>
                                        </div>
                                    </div>
                                </div>
                            </SortableContext>
                        </div>
                    </div>
                </div>
            </div>
            {activeElement !== undefined && (
                <DragOverlay>
                    <div className={styles['drag-elements-input-container__drag-element']}>
                        <div className="row mx-0 w-100 d-flex">
                            <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                <img src={icoDragDots} alt="drag" className={styles['drag-elements-input-container__drag-element__icon']} />
                            </div>
                            <div className="col px-0">
                                <span className={styles['drag-elements-input-container__drag-element__text']}>
                                    {t(`headers:${activeElement.text}`)}
                                </span>
                            </div>
                        </div>
                    </div>
                </DragOverlay>
            )}
        </DndContext>
    )
}
