/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DynamicTableSchema } from '../models/DynamicTableSchema';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DTablesService {
    /**
     * Returns list of tables
     * @returns DynamicTableSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtables({
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: table_type, table_format, timeseries_format.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'table_type' | 'table_format' | 'timeseries_format',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<DynamicTableSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables',
            query: {
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * adds a dynamic table
     * @returns DynamicTableSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeAdminDtables({
        requestBody,
    }: {
        /**
         * Optional description in *Markdown*
         */
        requestBody: DynamicTableSchema,
    }): CancelablePromise<DynamicTableSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/admin/dtables',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns dtable data for each id
     * @returns string call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtablesColumns({
        columns,
        ids,
    }: {
        /**
         * list of column data to retrieve
         */
        columns: Array<string>,
        /**
         * ids to filter
         */
        ids?: Array<number>,
    }): CancelablePromise<Array<Record<string, string>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables/columns',
            query: {
                'columns': columns,
                'ids': ids,
            },
        });
    }
    /**
     * Returns aggregated dtable data
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtablesColumns1({
        columnName,
        ids,
    }: {
        /**
         * list of column data to retrieve
         */
        columnName: Array<string>,
        /**
         * ids to filter
         */
        ids?: Array<number>,
    }): CancelablePromise<{
        result_type?: string;
        result_value?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables/columns/{column_name}',
            path: {
                'column_name': columnName,
            },
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * returns availabe table_types
     * @returns string call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtablesTableTypes(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables/table_types',
        });
    }
    /**
     * returns availabe var_types
     * @returns string call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtablesVarTypes(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables/var_types',
        });
    }
    /**
     * Deletes a dynamic table
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static deleteApiBackofficeAdminDtables({
        tableName,
    }: {
        /**
         * table name.
         */
        tableName: string,
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/admin/dtables/{table_name}',
            path: {
                'table_name': tableName,
            },
        });
    }
    /**
     * get one DynamicTable by table_name
     * @returns DynamicTableSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtables1({
        tableName,
    }: {
        /**
         * table_name
         */
        tableName: string,
    }): CancelablePromise<DynamicTableSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables/{table_name}',
            path: {
                'table_name': tableName,
            },
        });
    }
    /**
     * deletes row into a dtable
     * @returns void
     * @throws ApiError
     */
    public static deleteApiBackofficeAdminDtablesData({
        tableName,
        ids,
    }: {
        /**
         * table name.
         */
        tableName: string,
        /**
         * list of ids
         */
        ids?: Array<number>,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/admin/dtables/{table_name}/data',
            path: {
                'table_name': tableName,
            },
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * inserts row into a dtable
     * @returns string call successful
     * @throws ApiError
     */
    public static postApiBackofficeAdminDtablesData({
        tableName,
        requestBody,
    }: {
        /**
         * table name.
         */
        tableName: string,
        requestBody: Record<string, string>,
    }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/admin/dtables/{table_name}/data',
            path: {
                'table_name': tableName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns a dtable data
     * @returns string call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminDtablesData({
        tablename,
        ids,
        page,
        itemsPerPage,
    }: {
        /**
         * table name.
         */
        tablename: string,
        /**
         * list of ids
         */
        ids?: Array<number>,
        /**
         * pagination page
         */
        page?: number,
        /**
         * rows per page
         */
        itemsPerPage?: number,
    }): CancelablePromise<Array<Record<string, string>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/dtables/{tablename}/data',
            path: {
                'tablename': tablename,
            },
            query: {
                'ids': ids,
                'page': page,
                'items_per_page': itemsPerPage,
            },
        });
    }
}
