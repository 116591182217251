import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { TasksService } from '../../../temp/test';
//import icoUser from '../../../assets/images/user.svg';
import { myTasksListMock } from './mock';
// import { formatDate } from '../../../helpers/date';
// import {
// 	getPriorityColor,
// 	getStatusColor,
// } from '../../../helpers/color';
import styles from './my-tasks-list.module.scss';
import { ContentContainer, Loader, Spinner, Table } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays logins in a table
 */

export const MyTasksList = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal] = useToggle(false);
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue
    } = useTable(myTasksListMock, modal, '');

    const getTasks = useCallback(async () => {
        const dataClone = _.cloneDeep(myTasksListMock);
        try {
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(true);
            }
            console.log('fix: uuid is static');
            await TasksService.getApiTasksAssigned({ uuid: 9 });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            dataClone.disabled = true;
            verifyComponent(dataClone);
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
        }
    }, [isMounted, setLoading, setValue]);

    useEffect(() => {
        getTasks();
    }, [getTasks]);

    return (
        <div className={`${styles['my-tasks-list-container']}`}>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <Table
                        actions={data.actions}
                        // onClickActionIcon={onClickActionIcon}
                        // onClickActionOption={onClickActionOption}
                        // onCloseActions={onCloseActions}
                        attr={data.attr}
                        type={data.type}
                        titleCounter={data.titleCounter}
                        titleText={t(`components:${data.attr}.title`)}
                        placeholderText={t(`components:${data.attr}.nodata`)}
                        columns={data.columns}
                        data={data.sortedData}
                        sortColumn={data.sortColumn}
                        onSort={dataSort}
                        currentPage={data.currentPage}
                        pageSize={data.pageSize}
                        onPageChange={dataPageChange}
                        onRowClick={undefined}
                        onRowSelect={undefined}
                        onSelectAll={dataSelectAll}
                        onUnselectAll={dataUnselectAll}
                        paginationDisabled={data.paginationDisabled}
                        disabled={data.disabled}
                    ></Table>
                )}
            </ContentContainer>
        </div>
    );
});

MyTasksList.propTypes = {};
