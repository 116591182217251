import React, { useRef, useEffect, useMemo } from 'react';
import styles from './custom-timeline.module.scss';
import icoChangeYearArrow from '../../../assets/images/icoChangeYearArrow.svg';
import { TooltipWrapper } from '../../formatting/TooltipWrapper/TooltipWrapper';

const goalsMock = [
    {
        name: 'Goal 1',
        startDate: '2024-01-01',
        endDate: '2024-03-01',
        year: 2024,
        progress: 0.8
    },
    {
        name: 'Goal 2',
        startDate: '2024-02-01',
        endDate: '2024-04-01',
        year: 2024,
        progress: 1
    },
    {
        name: 'Goal 3',
        startDate: '2024-03-01',
        endDate: '2024-06-01',
        year: 2024,
        progress: 0.6
    },
    {
        name: 'Goal 4',
        startDate: '2024-07-01',
        endDate: '2024-12-01',
        year: 2024,
        progress: 0
    }
];

export const CustomTimeline = ({ onSelectGoal, goals = goalsMock }) => {
    const timelineRef = useRef(null);

    useEffect(() => {
        // Scroll to the current date
        if (timelineRef.current) {
            const today = new Date();
            const startOfYear = new Date(today.getFullYear(), 0, 1);
            const totalDaysInYear =
                (new Date(today.getFullYear(), 11, 31) - startOfYear) /
                    (1000 * 60 * 60 * 24) +
                1;
            const daysSinceStartOfYear =
                (today - startOfYear) / (1000 * 60 * 60 * 24);

            const scrollPosition =
                (daysSinceStartOfYear / totalDaysInYear) *
                timelineRef.current.scrollWidth;
            timelineRef.current.scrollLeft =
                scrollPosition - timelineRef.current.clientWidth / 2;
        }
    }, []);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const daysInYear = (year) => {
        return new Date(year, 1, 29).getMonth() === 1 ? 366 : 365;
    };

    const getDaysDifference = (start, end) => {
        const oneDay = 24 * 60 * 60 * 1000;
        return Math.round((end - start) / oneDay);
    };

    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const totalDaysInYear = daysInYear(today.getFullYear());

    const yearOptions = useMemo(() => {
        return [...goals.map((goal) => parseInt(goal.year))];
    }, [goals]);

    const [year, setYear] = React.useState(2024);
    const [hoveredGoal, setHoveredGoal] = React.useState(null);

    return (
        <div className="w-100 h-100 d-flex flex-column">
            {hoveredGoal && (
                <TooltipWrapper
                    attr={'goalTimeline'}
                    startDate={hoveredGoal.startDate}
                    endDate={hoveredGoal.endDate}
                    name={hoveredGoal.name}
                />
            )}
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <img
                        src={icoChangeYearArrow}
                        alt="icon"
                        className={
                            yearOptions.includes(year - 1)
                                ? styles['custom-timeline-container__arrow']
                                : styles['custom-timeline-container__arrow'] +
                                  ' ' +
                                  styles[
                                      'custom-timeline-container__arrow--disabled'
                                  ]
                        }
                        onClick={() => {
                            setYear(year - 1);
                        }}
                    />
                    <span className={styles['custom-timeline-container__year']}>
                        {year}
                    </span>

                    <img
                        src={icoChangeYearArrow}
                        alt="icon"
                        className={
                            yearOptions.includes(year + 1)
                                ? styles['custom-timeline-container__arrow']
                                : styles['custom-timeline-container__arrow'] +
                                  ' ' +
                                  styles[
                                      'custom-timeline-container__arrow--disabled'
                                  ]
                        }
                        style={{
                            transform: 'rotate(180deg)'
                        }}
                        onClick={() => {
                            setYear(year + 1);
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100 flex-grow-1">
                <div className="col px-0">
                    <div
                        className={styles['custom-timeline-container']}
                        ref={timelineRef}
                    >
                        <div
                            className={
                                styles['custom-timeline-container__months']
                            }
                            style={{ marginBottom: '2rem' }}
                        >
                            {months.map((month, index) => (
                                <div
                                    key={index}
                                    className={
                                        styles[
                                            'custom-timeline-container__months__month'
                                        ]
                                    }
                                >
                                    {month}
                                </div>
                            ))}
                        </div>
                        <div
                            className={
                                styles['custom-timeline-container__goals']
                            }
                        >
                            {goals.map((goal, index) => {
                                const startDate = new Date(goal.startDate);
                                const endDate = new Date(goal.endDate);
                                const startDays = getDaysDifference(
                                    startOfYear,
                                    startDate
                                );
                                const endDays = getDaysDifference(
                                    startDate,
                                    endDate
                                );

                                const startOffset =
                                    (startDays / totalDaysInYear) * 250;
                                const width = (endDays / totalDaysInYear) * 250;

                                let backgroundColor;
                                if (endDate < today) {
                                    backgroundColor =
                                        goal.progress === 1
                                            ? `#98d2c5`
                                            : `linear-gradient(to right, #e3a29e ${
                                                  goal.progress * 100
                                              }%,
                                               transparent ${
                                                   goal.progress * 100
                                               }%)`;
                                } else if (
                                    startDate <= today &&
                                    today <= endDate
                                ) {
                                    backgroundColor =
                                        goal.progress === 1
                                            ? `linear-gradient(
                                        to right,
                                            #98d2c5 0%,
                                            #98d2c5 ${
                                                goal.progress * 100 * 0.5
                                            }%,
                                            #98d2c5 ${
                                                goal.progress * 100 * 0.75
                                            }%,
                                            #98d2c5 ${goal.progress * 100}%,
                                            transparent ${goal.progress * 100}%
                                        )`
                                            : `linear-gradient(
                                        to right,
                                            #e3a29e 0%,
                                            #e3a29e ${
                                                goal.progress * 100 * 0.5
                                            }%,
                                            #e3a29e ${
                                                goal.progress * 100 * 0.75
                                            }%,
                                            #e3a29e ${goal.progress * 100}%,
                                            transparent ${goal.progress * 100}%
                                        )`;
                                } else {
                                    backgroundColor = `#e9ebf2`;
                                }

                                return (
                                    <div
                                        key={index}
                                        className={
                                            styles[
                                                'custom-timeline-container__goals__goal'
                                            ]
                                        }
                                        onMouseEnter={() => {
                                            setHoveredGoal(goal);
                                        }}
                                        onMouseLeave={() => {
                                            setHoveredGoal(null);
                                        }}
                                        style={{
                                            top: `${index * 5}rem`, // Each goal in a different row
                                            left: `${startOffset}%`,
                                            width: `${width}%`,
                                            '--background-gradient':
                                                backgroundColor,
                                            border: 'none',
                                            // borderColor:
                                            //     endDate < today
                                            //         ? goal.progress === 1
                                            //             ? '#98d2c5'
                                            //             : '#e3a29e'
                                            //         : startDate < today
                                            //         ? '#2c3c8d'
                                            //         : '#e9ebf2',
                                            cursor: onSelectGoal
                                                ? 'pointer'
                                                : 'default'
                                        }}
                                        onClick={
                                            onSelectGoal
                                                ? () => onSelectGoal(goal)
                                                : undefined
                                        }
                                    ></div>
                                );
                            })}
                        </div>
                        <div
                            className={
                                styles['custom-timeline-container__today']
                            }
                            style={{
                                bottom: `${goals.length * 50}px`,
                                left: `${
                                    (getDaysDifference(startOfYear, today) /
                                        totalDaysInYear) *
                                    250
                                }%`
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
