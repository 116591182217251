import React, { useState } from 'react';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import icoRejectGoal from '../../../assets/images/icoRejectGoal.svg';
import { RemoveModal } from '../remove-modal/remove-modal';
import { errorHandler } from '../../../utils/api';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';

export const RejectGoalModal = ({ id, onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation();
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { value: inputs, setValue: onChangeInput } = useMultipleInputs({
        motive: {
            name: 'motive',
            type: 'type',
            inputType: 'container'
        }
    });

    const handleDelete = async () => {
        try {
            setLoading(true);
            // await TeamsService.deleteApiTeams({ ids: [teamId] });
            if (onEnd) onEnd();
            setLoading(false);
            setIsSuccess(true);
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <RemoveModal
            icon={icoRejectGoal}
            inputs={Object.values(inputs)}
            onChangeInput={onChangeInput}
            title={t('modals:rejectGoal.title')}
            titleColor={'#DB6162'}
            description={t('modals:rejectGoal.description')}
            successMessage={t('modals:rejectGoal.success')}
            isLoading={loading}
            isSuccess={isSuccess}
            onDelete={handleDelete}
            onCloseModal={onCloseModal}
        />
    );
};
