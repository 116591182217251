import React from 'react';
import icoSearch from '../../../assets/images/icoSearch.svg';
import styles from './SearchInput.module.scss';

// Props are destructured to directly receive value and onChange from the parent component
export const SearchInput = ({ value, onChange, placeholder }) => {
    const [isFocused, setIsFocused] = React.useState(false);

    return (
        <div
            className={
                isFocused
                    ? `${styles['search-input-container']} ${styles.focused} ${styles.container}`
                    : `${styles['search-input-container']} ${styles.container}`
            }
        >
            <img
                src={icoSearch}
                alt="search"
                className={styles['search-input-container__icon']}
            />
            <input
                type="text"
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder={placeholder || 'Search...'}
                value={value} // Controlled input using value from props
                onChange={onChange} // Pass onChange event handler from props
                className={`${styles['search-input-container__input']}`}
            />
        </div>
    );
};
