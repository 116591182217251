import React from 'react';
import _ from 'lodash';
import { SelectBoxHeader } from './components/select-box-header/select-box-header';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const TableHeaderFactory = React.memo((props) => {
    const { t } = useCustomTranslation();
    switch (props.item.type) {
        case 'selectBox':
            return <SelectBoxHeader {...props} {...props.item} />;
        default:
            if (
                _.has(props.item.column, 'hideLabel') &&
                props.item.column.hideLabel
            )
                return null;
            else if (props.item.column.path === 'action') {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {t(`headers:${props.item.column.path}`)}
                    </div>
                );
            }
            return (
                <React.Fragment>
                    {props.item.column.label ||
                        t(`headers:${props.item.column.path}`)}{' '}
                    {props.item.column.sortable &&
                        props.item.renderSortIcon(props.item.column)}
                </React.Fragment>
            );
    }
});
