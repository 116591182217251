/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationSchema } from '../models/NotificationSchema';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationsService {
    /**
     * All notifications for current user. Most recent is first.
     * @returns NotificationSchema call successful
     * @throws ApiError
     */
    public static getApiNotifications({
        page,
        itemsPerPage,
    }: {
        page?: number,
        itemsPerPage?: number,
    }): CancelablePromise<Array<NotificationSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notifications/',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
            },
        });
    }
    /**
     * mark all selected notifications as read
     * @returns number call successful
     * @throws ApiError
     */
    public static postApiNotificationsMarkAsRead({
        requestBody,
    }: {
        requestBody: Array<number>,
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notifications/mark-as-read',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * send hello push notification to all users. They should be subscribed to own topic
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static getApiNotificationsNotifyAllUsers(): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notifications/notify-all-users',
        });
    }
    /**
     * marks notifications as read
     * @returns NotificationSchema call successful
     * @throws ApiError
     */
    public static getApiNotificationsRead({
        id,
    }: {
        /**
         * unique id of notification
         */
        id: number,
    }): CancelablePromise<NotificationSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notifications/{id}/read',
            path: {
                'id': id,
            },
        });
    }
}
