import { useState, useEffect, useCallback } from 'react'

const useCursorTracking = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [reversedHorizontal, setReversedHorizontal] = useState(false)
    const [reversedVertical, setReversedVertical] = useState(false)
    const [lastUpdate, setLastUpdate] = useState(Date.now())
    useEffect(() => {
        if (position.x > window.innerWidth / 2) {
            setReversedHorizontal(true)
        } else {
            setReversedHorizontal(false)
        }
        if (position.y > window.innerHeight / 2) {
            setReversedVertical(true)
        } else {
            setReversedVertical(false)
        }
    }, [position.x, position.y])

    const hasMovedEnough = useCallback(
        (newX, newY) => {
            const deltaX = Math.abs(newX - position.x)
            const deltaY = Math.abs(newY - position.y)
            return deltaX >= 10 || deltaY >= 10
        },
        [position.x, position.y]
    )

    const handleMouseMove = useCallback(
        (e) => {
            if (hasMovedEnough(e.clientX, e.clientY)) {
                const now = Date.now()
                if (now - lastUpdate >= 200) {
                    setPosition({ x: e.clientX + 10, y: e.clientY + 10 })
                    setLastUpdate(now)
                }
            }
        },
        [hasMovedEnough, lastUpdate]
    )

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove)
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [hasMovedEnough, handleMouseMove, lastUpdate]) // Add position as a dependency

    return {
        position,
        reversedHorizontal,
        reversedVertical,
    }
}

export default useCursorTracking
