import _, { unset } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import icoEdit from '../../../assets/images/ico-edit.svg'
import icoSave from '../../../assets/images/ico-save-blue.svg'
import 'bootstrap/dist/css/bootstrap.css'
import styles from './component-wrapper.module.scss'
import { Scrollable, Spinner } from '../..'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { Pin } from '../pin/pin'
import { formatDateTime } from '../../../utils/date'
import icoTimeseriesDataManager from '../../../assets/images/icoUserForecast.svg'
import { useToggle } from '../../../hooks/useToggle'
import { TimeseriesDataManagerPopup } from '../../popups/timeseries-data-manager-popup/TimeseriesDataManagerPopup'
import icoFilterBlue from '../../../assets/images/icoFilterBlue.svg'
import { ComponentFilterPopup } from '../../popups/time-interval-popup/ComponentFilterPopup'
import icoForecastStars from '../../../assets/images/icoForecastStars.svg'
import { SearchInputAlternative } from '../../inputs/search-input-alternative/SearchInputAlternative'
import { SearchProvider } from '../../../hooks/search-provider'
import { EditTableColumnsModal } from '../../modals/edit-table-columns-modal/edit-table-columns-modal'
import { ComponentTabs } from '../component-tabs/component-tabs'
import { useUserForecastFilter } from '../../../providers/user-forecast-filter-context'

export const ComponentWrapper = React.memo(
    ({
        attr,
        titleText,
        isForecast,
        editorMode,
        legend,
        onMouseEnterContainer,
        onClickContainer,
        children,
        hovered,
        darken,
        noBorder,
        complementaryInfo,
        interval,
        onOpenEditMode,
        onSaveEditMode,
        onCancelEditMode,
        isEditing,
        onClickLegend,
        loading,
        isPreview,
        showAllInfo,
        noMargin,
        isPinActive,
        component,
        onNewElementClick,
        onNewElementText,
        lastUpdated,
        withTimeseriesDataManager,
        withTimeFilter,
        withGeneralTimeFilter,
        withChannelFilter,
        withForecastFilter,
        withUserForecastInput,
        isDraggable,
        withSearch,
        withEditColumns,
        newUI,
        withTabs,
        config,
    }) => {
        const { t } = useCustomTranslation()
        const [searchValue, setSearchValue] = React.useState('')
        const hoveredStyle = hovered ? 'hovered' : ''
        const darkStyle = darken ? 'dark' : ''
        const borderStyle = noBorder ? 'no-border' : ''
        const timeseriesDataManagerRef = React.useRef()
        const componentFilterRef = React.useRef(null)
        const [componentFilterOpen, setComponentFilterOpen] = useToggle(false)
        const [startedLoading, setStartedLoading] = React.useState(false)
        const [timeseriesDataPopupOpen, setTimeseriesDataPopupOpen] = useToggle(false)
        const { userForecastEnabled } = useUserForecastFilter()
        const userForecastsRef = React.useRef(null)
        const [userForecastsPopupOpen, setUserForecastsPopupOpen] = useToggle(false)
        const [editTableColumnsModal, editTableColumnsModalSet] = useToggle(false)

        const componentWrapperStyle = useMemo(() => {
            if (isForecast && editorMode) {
                return `${styles['component-wrapper-container']} ${styles[`${darkStyle}`]} ${styles[`${borderStyle}`]} ${
                    styles[`component-wrapper-container--forecast`]
                } ${styles[`component-wrapper-container--disabled`]}`
            } else if (isForecast) {
                return `${styles['component-wrapper-container']} ${styles[`${darkStyle}`]} ${styles[`${borderStyle}`]} ${
                    styles[`component-wrapper-container--forecast`]
                } ${styles[`${hoveredStyle}`]}`
            } else if (editorMode) {
                return `${styles['component-wrapper-container']} ${styles[`${darkStyle}`]} ${styles[`${borderStyle}`]} ${
                    styles[`component-wrapper-container--disabled`]
                }`
            } else {
                return `${styles['component-wrapper-container']} ${styles[`${darkStyle}`]} ${styles[`${borderStyle}`]} ${styles[`${hoveredStyle}`]}`
            }
        }, [isForecast, editorMode, darkStyle, borderStyle, hoveredStyle])

        const firstRowStyle = useMemo(() => {
            const legendStyle = legend ? '' : `${styles['component-wrapper-container__first-row--no-legend']}`
            return `row w-100 m-0 ${styles['component-wrapper-container__first-row']} ${legendStyle} d-flex justify-content-start align-items-start`
        }, [legend])

        const secondRowStyle = useMemo(() => {
            if (!legend) {
                return `row w-100 mx-0 ${styles['component-wrapper-container__second-row']} ${styles['component-wrapper-container__second-row--no-legend']}`
            } else {
                return `row w-100 mx-0 ${styles['component-wrapper-container__second-row']}`
            }
        }, [legend])

        const thirdRowStyle = useMemo(() => {
            return `row w-100 m-0 ${styles['component-wrapper-container__third-row']}`
        }, [])

        useEffect(() => {
            if (!startedLoading) {
                setStartedLoading(true)
            }
        }, [startedLoading])

        const hasFilters = useMemo(() => {
            return withTimeFilter || withChannelFilter || withForecastFilter || withGeneralTimeFilter
        }, [withTimeFilter, withChannelFilter, withForecastFilter, withGeneralTimeFilter])

        return (
            <>
                {withTabs && <ComponentTabs />}
                <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                    <div
                        className={componentWrapperStyle}
                        style={{
                            background: newUI ? 'rgba(255,255,255,0.7)' : '#fff',
                            borderRadius: withTabs ? '0 2rem 2rem 2rem' : '2rem',
                        }}
                        onMouseEnter={() => {
                            if (onMouseEnterContainer) {
                                onMouseEnterContainer(attr)
                            }
                        }}
                        onMouseLeave={() => {
                            if (onMouseEnterContainer) {
                                onMouseEnterContainer(attr)
                            }
                        }}
                        onClick={onClickContainer ? () => onClickContainer(attr) : () => {}}
                    >
                        <div
                            className={firstRowStyle}
                            style={{
                                background: newUI ? '#fff' : 'transparent',
                                borderRadius: '2rem 2rem 0 0',
                            }}
                        >
                            <div className="col-auto title p-0 d-flex justify-content-start align-items-center">
                                {isForecast && (
                                    <img
                                        loading="lazy"
                                        src={icoForecastStars}
                                        className="me-2"
                                        style={{
                                            width: '2.4rem',
                                            height: '2.4rem',
                                        }}
                                    />
                                )}
                                <span className={`${styles['component-wrapper-container__font']} me-2`}>
                                    {titleText || t(`components:${attr}.title`) || '-'}
                                </span>
                                {lastUpdated && (
                                    <span className={`${styles['component-wrapper-container__font']} ${styles['last-updated']}`}>
                                        {t('common:lastUpdated')} {formatDateTime(lastUpdated)}
                                    </span>
                                )}
                                <span className={`${styles['component-wrapper-container__font']} ${styles['complementary']}`}>{interval}</span>
                                {complementaryInfo && (
                                    <span className={`${styles['component-wrapper-container__font']} ${styles['complementary']}`}>
                                        <span>&nbsp;</span>
                                        {complementaryInfo}
                                    </span>
                                )}
                            </div>
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <div
                                    style={{
                                        zIndex: 100,
                                        // visibility: isContainerHovered
                                        //     ? 'visible'
                                        //     : 'hidden',
                                        display: 'flex',
                                        marginLeft: '0.5rem',
                                    }}
                                >
                                    {isPinActive && <Pin component={component} />}
                                </div>
                                {hasFilters && (
                                    <div className="position-relative" ref={componentFilterRef}>
                                        <img
                                            loading="lazy"
                                            src={icoFilterBlue}
                                            alt="filter"
                                            style={{
                                                marginBottom: '0.25rem',
                                                cursor: 'pointer',
                                            }}
                                            className={
                                                componentFilterOpen
                                                    ? `${styles['component-wrapper-container__filter-icon']} ${styles['selected']}`
                                                    : `${styles['component-wrapper-container__filter-icon']}`
                                            }
                                            onClick={() => setComponentFilterOpen()}
                                        />
                                        {componentFilterOpen && (
                                            <ComponentFilterPopup
                                                onClosePopup={() => {
                                                    setComponentFilterOpen(false)
                                                }}
                                                withTimeFilter={withTimeFilter}
                                                withGeneralTimeFilter={withGeneralTimeFilter}
                                                withChannelFilter={withChannelFilter}
                                                withForecastFilter={withForecastFilter}
                                                componentRef={componentFilterRef}
                                            />
                                        )}
                                    </div>
                                )}
                                {withTimeseriesDataManager && (
                                    <div className="position-relative" ref={timeseriesDataManagerRef}>
                                        <img
                                            loading="lazy"
                                            src={icoTimeseriesDataManager}
                                            alt="user-forecast"
                                            className={
                                                timeseriesDataPopupOpen
                                                    ? `${styles['component-wrapper-container__user-forecast-icon']} ${styles['selected']}`
                                                    : `${styles['component-wrapper-container__user-forecast-icon']}`
                                            }
                                            onClick={() => setTimeseriesDataPopupOpen()}
                                        />
                                        {timeseriesDataPopupOpen && (
                                            <TimeseriesDataManagerPopup
                                                onClosePopup={() => {
                                                    setTimeseriesDataPopupOpen(false)
                                                }}
                                                componentRef={timeseriesDataManagerRef}
                                            />
                                        )}
                                    </div>
                                )}
                                {withUserForecastInput && userForecastEnabled && (
                                    <div className="position-relative" ref={userForecastsRef}>
                                        <img
                                            loading="lazy"
                                            src={icoTimeseriesDataManager}
                                            alt="user-forecast"
                                            className={
                                                userForecastsPopupOpen
                                                    ? `${styles['component-wrapper-container__user-forecast-icon']} ${styles['selected']}`
                                                    : `${styles['component-wrapper-container__user-forecast-icon']}`
                                            }
                                            onClick={() => setUserForecastsPopupOpen()}
                                        />
                                        {userForecastsPopupOpen && (
                                            <TimeseriesDataManagerPopup
                                                onClosePopup={() => {
                                                    setUserForecastsPopupOpen(false)
                                                }}
                                                type="userForecasts"
                                                componentRef={userForecastsRef}
                                            />
                                        )}
                                    </div>
                                )}
                                {withEditColumns && (
                                    <div className="position-relative">
                                        <img
                                            loading="lazy"
                                            src={icoEdit}
                                            alt="user-forecast"
                                            className={
                                                editTableColumnsModal
                                                    ? `${styles['component-wrapper-container__user-forecast-icon']} ${styles['selected']}`
                                                    : `${styles['component-wrapper-container__user-forecast-icon']}`
                                            }
                                            onClick={() => editTableColumnsModalSet(true)}
                                        />
                                        {editTableColumnsModal && (
                                            <EditTableColumnsModal
                                                onCloseModal={() => {
                                                    editTableColumnsModalSet(false)
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            {isEditing && onSaveEditMode && onCancelEditMode && (
                                <div
                                    className="col-auto px-0 d-flex justify-content-center align-items-center"
                                    style={{
                                        cursor: 'pointer',
                                        position: 'relative',
                                    }}
                                >
                                    <span className={styles['component-wrapper-container__cancel-text']} onClick={() => onCancelEditMode()}>
                                        {t('common:cancel')}
                                    </span>
                                    <img
                                        loading="lazy"
                                        src={icoSave}
                                        alt="save"
                                        className={styles['component-wrapper-container__save-icon']}
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            marginRight: '0.5rem',
                                        }}
                                    />
                                    <span className={styles['component-wrapper-container__save-text']} onClick={() => onSaveEditMode()}>
                                        {t('common:save')}
                                    </span>
                                </div>
                            )}
                            {!isEditing && onOpenEditMode && (
                                <div
                                    className="col-auto px-0 d-flex justify-content-center align-items-center"
                                    style={{
                                        cursor: 'pointer',
                                        position: 'relative',
                                    }}
                                    onClick={() => onOpenEditMode()}
                                >
                                    <img
                                        loading="lazy"
                                        src={icoEdit}
                                        alt="edit"
                                        className={styles['component-wrapper-container__edit-icon']}
                                        style={{
                                            marginRight: '0.5rem',
                                        }}
                                    />
                                    <span className={styles['component-wrapper-container__edit-text']}>{t('common:edit')}</span>
                                </div>
                            )}
                            {onNewElementClick && onNewElementText && (
                                <div className="col px-0 d-flex justify-content-end align-items-center">
                                    {/* <img
                                    loading="lazy"
                                    src={icoAddUser}
                                    className={`${styles['component-wrapper-container__new-element-icon']}`}
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={() => onNewElementClick()}
                                /> */}
                                    <span
                                        className={`${styles['component-wrapper-container__new-element__text']}`}
                                        onClick={() => onNewElementClick()}
                                    >
                                        {onNewElementText}
                                    </span>
                                </div>
                            )}
                        </div>

                        {withSearch && (
                            <div
                                className="row mx-0 w-100 mx-0"
                                style={{
                                    background: newUI ? 'transparent' : '#fff',
                                    padding: '0 2rem',
                                    margin: '1rem 0',
                                }}
                            >
                                <div className="col-auto px-0 d-block">
                                    <SearchInputAlternative
                                        value={searchValue}
                                        placeholder={t('inputs:searchUser')}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                        <>
                            <div
                                className={secondRowStyle}
                                style={{
                                    background: newUI ? 'transparent' : '#fff',
                                    margin: noMargin ? '0' : undefined,
                                }}
                            >
                                <div className="col p-0 h-100 w-100 position-relative" style={{ overflow: isDraggable ? 'visible' : unset }}>
                                    {loading && <Spinner />}
                                    {!loading &&
                                        ((
                                            <SearchProvider
                                                value={{
                                                    search: searchValue,
                                                }}
                                            >
                                                {children}
                                            </SearchProvider>
                                        ) ||
                                            undefined)}
                                </div>
                            </div>
                            {legend && legend.length > 0 && (
                                <div className={thirdRowStyle}>
                                    <div className="col p-0 d-flex justify-content-start align-items-center">
                                        <Scrollable direction="horizontal" addBottomSpacing>
                                            <div className="w-100 h-100 d-flex">
                                                {legend &&
                                                    legend.map((item, index) => {
                                                        let existsActive = legend.some((item2) => _.has(item2, 'active') && item2.active)
                                                        let isActive = _.has(item, 'active') && item.active
                                                        return (
                                                            <div
                                                                key={index}
                                                                onClick={onClickLegend ? () => onClickLegend(item) : undefined}
                                                                className="d-flex justify-content-start align-items-center"
                                                                style={{
                                                                    opacity: existsActive && isActive ? 1 : existsActive ? 0.3 : 1,
                                                                    cursor: onClickLegend ? 'pointer' : 'default',
                                                                }}
                                                            >
                                                                <span
                                                                    className={`${styles['component-wrapper-container__third-row__legend-circle']}`}
                                                                    style={{
                                                                        borderColor: item.color,
                                                                        display: 'inline-block',
                                                                    }}
                                                                />
                                                                <span
                                                                    className={`${styles['component-wrapper-container__third-row__legend-text']}`}
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                    }}
                                                                >
                                                                    {_.has(item, 'translated') && item.translated && item.name}
                                                                    {_.has(item, 'translated') && !item.translated && t(`legend:${item.name}`)}
                                                                    {!_.has(item, 'translated') && t(`legend:${item.name}`)}
                                                                </span>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </Scrollable>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </>
        )
    }
)
ComponentWrapper.propTypes = {}
