export const productInformationMock = {
    hoverStyle: '',
    desc: '',
    noData: false,
    loading: true,
    disabled: true,
    customInfo: [],
    attr: 'productDetailsCard',
    data: {
        categories: [
            {
                name: 'CVRM',
                id: 1
            }
        ],
        image: 'https://pillintrip.com/style/images/medicines/brilique/brilique1.jpg',
        name: 'Brilique',
        brand: 'Chocolate Sandwich Cookies',
        format: '',
        productId: 1,
        segment: '',
        category: '-',
        rrp: '€5',
        description: 'asdasd',
        presentations: [
            {
                name: '213',
                id: 174
            },
            {
                name: 'asda',
                id: 175
            }
        ],
        targetSegments: [
            {
                name: 'Sindromes Coronárias Agudas',
                id: 1
            },
            {
                name: 'Leucemia Linfocítica Crónia não tratados previamente',
                id: 3
            },
            {
                name: 'Diabetes tipo 2',
                id: 2
            }
        ],
        price: '€8',
        associatedTeams: [
            {
                name: 'cvx',
                id: 12
            },
            {
                name: 'new1',
                id: 9
            }
        ],
        externalId: '2',
        producer: 'My Products'
    },
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: 'VIEW',
            options: []
        },
        options: {
            label: 'OPTIONS',
            options: []
        }
    }
};
