import styles from './auxbar-component-filter-information.module.scss'
import React, { useEffect, useRef } from 'react'
import icoFilterAux from '../../../assets/images/ico-filter-aux.svg'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'
import { useComponentFilter } from '../../../providers/component-filter-context'
import { PrimaryButton, SelectInput, SelectMultipleInput, TerciaryButton } from '../../'
import { info } from '../../notifications/notification/notification'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useHighlightedChart } from '../../../providers/highlighted-chart-context'
import { useAuxBar } from '../../../providers/aux-bar-context'
import componentConfig from '../../../configs/componentLibraryConf.json'

export const AuxbarComponentFilterInformation = React.memo(({ componentName, component }) => {
    const { t } = useCustomTranslation()
    const { setComponentFilter, handleSaveChartConfig, handleCancelChartConfig } = useComponentFilter()
    const { setSelectedAvailableOption } = useAuxBar()
    const {
        value: inputs,
        replaceAll,
        setValue,
        selectOption,
        unselectOption,
        keyPress,
        keyDown,
        toggleDropdown,
        clearSelectedOptions,
    } = useMultipleInputs({})
    const containerRef = useRef(null)

    useEffect(() => {
        const newInputs = {
            filter: {
                type: 'selectMultiple',
                name: 'filter',
                showOptions: true,
                options: [],
                optionsAux: [],
                selectedOptions: [],
                selectedOptionsAux: [],
                state: 'normal',
                value: '',
                focused: -1,
                reference: React.createRef(),
            },
        }

        componentConfig[component.type].configList.filter.map((el) => {
            if (component.config.filter && component.config.filter.includes(el)) {
                return el
            }
            if (newInputs.filter) {
                newInputs.filter = {
                    ...newInputs.filter,
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    options: [...newInputs.filter.options, t(`inputs:${el}`)],
                    optionsAux: [...newInputs.filter.optionsAux, el],
                }
            } else {
                newInputs.filter = {
                    type: 'selectMultiple',
                    name: 'filter',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    state: 'normal',
                    value: '',
                    focused: -1,
                    reference: React.createRef(),
                }
            }
            return el
        })
        component.config.filter.map((el) => {
            if (newInputs.filter) {
                newInputs.filter = {
                    ...newInputs.filter,
                    selectedOptions: [...newInputs.filter.selectedOptions, t(`inputs:${el}`)],
                    selectedOptionsAux: [...newInputs.filter.selectedOptionsAux, el],
                }
            } else {
                newInputs.filter = {
                    type: 'selectMultiple',
                    name: 'filter',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    state: 'normal',
                    value: '',
                    focused: -1,
                    reference: React.createRef(),
                }
            }
            return el
        })
        replaceAll(newInputs)
    }, [componentName, replaceAll, t, component])

    useEffect(() => {
        if (inputs.filter) {
            setComponentFilter(inputs.filter.selectedOptionsAux)
        }
    })

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleCancelChartConfig()
                setSelectedAvailableOption('store')
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [handleCancelChartConfig, setSelectedAvailableOption])

    return (
        <div className={`${styles['auxbar-component-filter-information-container']}`} ref={containerRef}>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col-auto px-0 d-flex justify-content-center align-items-center" style={{ marginRight: '1rem' }}>
                    <img loading="lazy" src={icoFilterAux} alt="filter" />
                </div>
                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                    <span className={`${styles['auxbar-component-filter-information-container__title']}`}>{t('layout:auxbar.component.title')}</span>
                </div>
                <div className="col px-0"></div>
                <div className="col-auto px-0">
                    <TerciaryButton
                        text={t('buttons:clear')}
                        onClick={() => {
                            Object.keys(inputs).map((el) => {
                                clearSelectedOptions(el)
                                return el
                            })
                        }}
                    />
                </div>
            </div>
            {Object.values(inputs).map((el, index) => {
                return (
                    <div key={index} className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            {el.type === 'selectMultiple' && (
                                <SelectMultipleInput
                                    hideOptionsAux
                                    disabled={el.disabled}
                                    onChangeInput={setValue}
                                    onSelectMultiple={selectOption}
                                    onSelectedOptionClick={unselectOption}
                                    onKeyPress={keyPress}
                                    onKeyDown={keyDown}
                                    onToggleDropdown={toggleDropdown}
                                    {...el}
                                />
                            )}
                            {el.type === 'selectSingle' && (
                                <SelectInput
                                    hideOptionsAux
                                    onChangeInput={setValue}
                                    onSelectInput={selectOption}
                                    onKeyPress={keyPress}
                                    onKeyDown={keyDown}
                                    onToggleDropdown={toggleDropdown}
                                    {...el}
                                />
                            )}
                        </div>
                    </div>
                )
            })}
            <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                <div className="col-auto px-0">
                    <TerciaryButton
                        text={t('buttons:cancel')}
                        onClick={() => {
                            handleCancelChartConfig()
                            setSelectedAvailableOption('store')
                        }}
                    />
                </div>

                <div className="col-auto px-0">
                    <PrimaryButton
                        text={t('buttons:save')}
                        onClick={() => {
                            info({
                                text: t('notifications:filterSaved'),
                            })
                            handleSaveChartConfig(component)
                            setSelectedAvailableOption('')
                        }}
                    />
                </div>
            </div>
        </div>
    )
})

AuxbarComponentFilterInformation.propTypes = {}
