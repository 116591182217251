import React from 'react';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { useTranslation } from 'react-i18next';
import modalSuccessIcon from '../../../assets/images/modalSuccessIcon.svg';
import { Modal } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const SuccessModal = ({ text, onCloseModal }) => {
    const { t } = useCustomTranslation();
    return (
        <Modal onToggleModal={onCloseModal}>
            <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <img
                        src={modalSuccessIcon}
                        alt="success"
                        style={{
                            width: '8rem',
                            height: '8rem'
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span className="modal-title">{text}</span>
                </div>
            </div>
            <hr
                className="my-4"
                style={{
                    color: 'rgba(0,0,0,0.1)',
                    opacity: '1'
                }}
            />
            <div className="row mx-0 d-flex justify-content-center align-items-center">
                <div className="col px-0">
                    <PrimaryButton
                        text={t('buttons:close')}
                        onClick={onCloseModal}
                    />
                </div>
            </div>
        </Modal>
    );
};
