import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const MostUsedChannelsTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.active && props.payload && props.payload.length > 0) {
        return (
            <TooltipContainer color={'#B7BBE5'}>
                <TooltipHeaderRow title={props.label} />
                <Separator />
                <TooltipContentRow title={t(`tooltips:content.unitsSold`)} value={props.payload[0].value} />
            </TooltipContainer>
        )
    }
})
