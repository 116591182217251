import 'bootstrap/dist/css/bootstrap.css';
import styles from './team-information.module.scss';

import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { teamInformationMock } from './mock';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { RegionsService, TeamsService } from '../../../temp/test';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useToggle } from '../../../hooks/useToggle';
import { useTextInput } from '../../../hooks/useTextInput';
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useSub } from '../../../hooks/usePubSub';
import { success } from '../../notifications/notification/notification';
import { Spinner } from '../../loading/spinner/spinner';
import { TypeInput } from '../../inputs/type-input/type-input';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { SelectMultipleInput } from '../../inputs/select-multiple-input/select-multiple-input';
import { SelectInput } from '../../inputs/select-input/select-input';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays general information about a selected manager
 */

export const TeamInformation = React.memo(
    ({
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview
    }) => {
        const isMounted = useIsMounted();
        const location = useLocation();
        const { t } = useCustomTranslation();
        const [isEditing, toggleIsEditing] = useToggle(false);

        const {
            value: teamName,
            setValue: teamNameSetValue,
            replaceValue: teamNameReplaceValue
        } = useTextInput({
            name: 'teamName',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: responsibleMembers,
            toggleDropdown: responsibleMembersToggleDropdown,
            selectOption: responsibleMembersSelectOption,
            unselectOption: responsibleMembersUnselectOption,
            keyPress: responsibleMembersKeyPress,
            keyDown: responsibleMembersKeyDown,
            setValue: responsibleMembersSetValue,
            replaceAll: responsibleMembersReplaceAll
        } = useSelectMultipleInput({
            name: 'responsibleMembers',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: region,
            toggleDropdown: regionToggleDropdown,
            selectOption: regionSelectOption,
            keyPress: regionKeyPress,
            keyDown: regionKeyDown,
            setValue: regionSetValue,
            replaceAll: regionReplaceAll
        } = useSelectInput({
            name: 'region',
            showOptions: true,
            options: [],
            optionsAux: [],
            state: 'normal',
            focused: -1,
            reference: useRef()
        });

        const {
            value: data,
            setLoading,
            setValue
        } = useSimpleComponent(teamInformationMock);

        const getTeamInformation = useCallback(async () => {
            if (isMounted.current) setLoading(true);
            const dataClone = _.cloneDeep(teamInformationMock);
            try {
                const teamId = qs.parse(location.search)['team-id'];
                const dataBack = await TeamsService.getApiTeams1({
                    id: teamId
                });
                dataClone.teamName = dataBack.name ? dataBack.name : '';
                dataClone.responsibleMembers = dataBack.leaders
                    ? dataBack.leaders.map((el) => {
                          return {
                              name: el.employee.last_name
                                  ? el.employee.first_name +
                                    ' ' +
                                    el.employee.last_name
                                  : el.employee.first_name,
                              id: el.id
                          };
                      })
                    : [];
                dataClone.regions = dataBack.region
                    ? [{ name: dataBack.region.name, id: dataBack.region.id }]
                    : [];
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [isMounted, setValue, setLoading, location.search]);

        useSub('team', () => {
            getTeamInformation();
        });

        const handleEditTeam = useCallback(async () => {
            try {
                const teamId = qs.parse(location.search)['team-id'];
                const teamSchema = {
                    name: teamName.value,
                    leaders: responsibleMembers.selectedOptionsAux.map((el) => {
                        return {
                            id: parseInt(el)
                        };
                    }),
                    region_id: region.selectedOptionAux
                };
                await TeamsService.putApiTeams({
                    teamId: teamId,
                    requestBody: teamSchema
                });
                success({ text: t('components:teamInformation.teamEdited') });
                if (isMounted.current) {
                    toggleIsEditing(false);
                    setLoading(false);
                }
                getTeamInformation();
            } catch (err) {
                errorHandler(err);
            }
        }, [
            toggleIsEditing,
            location.search,
            getTeamInformation,
            isMounted,
            region.selectedOptionAux,
            responsibleMembers.selectedOptionsAux,
            setLoading,
            t,
            teamName.value
        ]);

        const handleOpenEditMode = useCallback(async () => {
            if (isMounted.current) {
                toggleIsEditing(true);
                teamNameReplaceValue(data.teamName);
                const newResponsibleMembers = {
                    name: 'responsibleMembers',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    state: 'normal',
                    focused: -1,
                    value: '',
                    reference: React.createRef()
                };
                const newRegion = {
                    name: 'region',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    state: 'normal',
                    focused: -1,
                    value: '',
                    reference: React.createRef()
                };
                const dataBackMembers = await TeamsService.getApiTeamsMembers({
                    teamId: qs.parse(location.search)['team-id'],
                    leaders: undefined
                });
                const dataBackRegions = await RegionsService.getApiRegions({});
                newResponsibleMembers.options = dataBackMembers
                    .filter(
                        (el) =>
                            !data.responsibleMembers.some(
                                (el2) => el2.id === el.id
                            )
                    )
                    .map((el) => {
                        if (el.employee) {
                            if (el.employee.last_name) {
                                return (
                                    el.employee.first_name +
                                    ' ' +
                                    el.employee.last_name
                                );
                            } else {
                                return el.employee.first_name;
                            }
                        }
                    });
                newResponsibleMembers.optionsAux = dataBackMembers
                    .filter(
                        (el) =>
                            !data.responsibleMembers.some(
                                (el2) => el2.id === el.id
                            )
                    )
                    .map((el) => {
                        return el.id;
                    });
                newResponsibleMembers.selectedOptions =
                    data.responsibleMembers.map((el) => {
                        return el.name;
                    });
                newResponsibleMembers.selectedOptionsAux =
                    data.responsibleMembers.map((el) => {
                        return el.id;
                    });
                responsibleMembersReplaceAll(newResponsibleMembers);
                if (data.regions.length > 0) {
                    newRegion.value = data.regions[0].name;
                    newRegion.selectOption = data.regions[0].name;
                    newRegion.selectedOptionAux = data.regions[0].id;
                }
                newRegion.options = dataBackRegions.map((el) => {
                    return el.name;
                });
                newRegion.optionsAux = dataBackRegions.map((el) => {
                    return el.id;
                });
                regionReplaceAll(newRegion);
            }
        }, [
            location.search,
            responsibleMembersReplaceAll,
            regionReplaceAll,
            toggleIsEditing,
            data.teamName,
            data.responsibleMembers,
            data.regions,
            isMounted,
            teamNameReplaceValue
        ]);

        useEffect(() => {
            getTeamInformation();
        }, [getTeamInformation]);

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    onOpenEditMode={!data.noData && handleOpenEditMode}
                    isEditing={isEditing}
                    onSaveEditMode={handleEditTeam}
                    onCancelEditMode={toggleIsEditing}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <React.Fragment>
                            <div
                                className={`${styles['team-information-container']}`}
                            >
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100 d-flex justify-content-between">
                                            <div className="col px-0">
                                                <span
                                                    className={
                                                        styles[
                                                            'team-information-container__title'
                                                        ]
                                                    }
                                                    style={{
                                                        height: '4.5rem',
                                                        display: 'block'
                                                    }}
                                                >
                                                    {isEditing && (
                                                        <TypeInput
                                                            hideLabel
                                                            onChangeInput={
                                                                teamNameSetValue
                                                            }
                                                            {...teamName}
                                                        />
                                                    )}
                                                    {!isEditing &&
                                                        data.teamName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <div className="col px-0">
                                                <span
                                                    className={
                                                        styles[
                                                            'team-information-container__content-legend'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'components:teamInformation.responsibleMembers'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                {!isEditing && (
                                                    <div
                                                        style={{
                                                            height: '4.5rem',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        {data.responsibleMembers
                                                            .length > 0 &&
                                                            data.responsibleMembers.map(
                                                                (el, index) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={`text-label`}
                                                                        >
                                                                            {
                                                                                el.name
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        {data.responsibleMembers
                                                            .length === 0 &&
                                                            '-'}
                                                    </div>
                                                )}
                                                {isEditing && (
                                                    <SelectMultipleInput
                                                        hideOptionsAux
                                                        hideLabel
                                                        disabled={
                                                            responsibleMembers.disabled
                                                        }
                                                        onChangeInput={
                                                            responsibleMembersSetValue
                                                        }
                                                        onSelectMultiple={
                                                            responsibleMembersSelectOption
                                                        }
                                                        onSelectedOptionClick={
                                                            responsibleMembersUnselectOption
                                                        }
                                                        onKeyPress={
                                                            responsibleMembersKeyPress
                                                        }
                                                        onKeyDown={
                                                            responsibleMembersKeyDown
                                                        }
                                                        onToggleDropdown={
                                                            responsibleMembersToggleDropdown
                                                        }
                                                        {...responsibleMembers}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <div className="col px-0">
                                                <span
                                                    className={
                                                        styles[
                                                            'team-information-container__content-legend'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'components:teamInformation.regions'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                {!isEditing && (
                                                    <div
                                                        style={{
                                                            height: '4.5rem',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        {data.regions.length >
                                                            0 &&
                                                            data.regions.map(
                                                                (el, index) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={`text-label`}
                                                                        >
                                                                            {
                                                                                el.name
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        {data.regions.length ===
                                                            0 && '-'}
                                                    </div>
                                                )}
                                                {isEditing && (
                                                    <SelectInput
                                                        hideLabel
                                                        onChangeInput={
                                                            regionSetValue
                                                        }
                                                        onSelectInput={
                                                            regionSelectOption
                                                        }
                                                        hideOptionsAux
                                                        onSelectIconInput={
                                                            regionSelectOption
                                                        }
                                                        onKeyPress={
                                                            regionKeyPress
                                                        }
                                                        onKeyDown={
                                                            regionKeyDown
                                                        }
                                                        onToggleDropdown={
                                                            regionToggleDropdown
                                                        }
                                                        {...region}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

TeamInformation.propTypes = {};
