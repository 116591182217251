import React from 'react';
import styles from './tooltip-header-row.module.scss';

export const TooltipHeaderRow = React.memo(
    ({ titleImage, title, value, color }) => {
        return (
            <div className={`${styles['tooltip-header-row']} mb-0`}>
                <div className="row w-100 mx-0 d-flex justify-content-between">
                    {!titleImage && (
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['tooltip-header-row__font']} ${styles['tooltip-header-row__font--title']} me-3 mb-0`}
                            >
                                {title || '-'}
                            </span>
                        </div>
                    )}
                    {titleImage && (
                        <div
                            className="col px-0 d-flex justify-content-center align-items-center"
                            style={{ width: 'calc((100% - 2rem)/2)' }}
                        >
                            <img
                                src={titleImage}
                                alt="titleImage"
                                style={{
                                    width: '100%',
                                    maxWidth: '5rem',
                                    height: 'auto'
                                }}
                            />
                        </div>
                    )}
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                        <span
                            className={`${styles['tooltip-header-row__font']} mb-0`}
                            style={{
                                color: '#4c4c4c',
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}
                        >
                            {value || ''}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
);
