import {
    getDayFromDateFormat,
    getMonthFromDateFormat,
    getQuarterFromDateFormat,
    getTimeFromDateFormat,
    getWeekFromDateFormat,
    getYearFromDateFormat,
    isSameYearSameMonth,
    isSameYearSameMonthSameDay
} from './date';

export const fromBackTimeToFront = (
    backData,
    frontData,
    keyMap,
    timeInterval,
    timeKey
) => {
    backData.map((el) => {
        const keysMapped = keyMap.reduce((acc, curr) => {
            const { isArray } = curr;
            if (isArray) {
                acc[curr.front] = [el[curr.back[0]], el[curr.back[1]]];
            } else {
                acc[curr.front] = el[curr.back];
            }
            return acc;
        }, {});
        const newEl = {
            ...keysMapped,
            time: getTimeFromDateFormat(el[timeKey]),
            day: getDayFromDateFormat(el[timeKey]),
            week: getWeekFromDateFormat(el[timeKey]),
            month: getMonthFromDateFormat(el[timeKey]),
            quarter: getQuarterFromDateFormat(el[timeKey]),
            year: getYearFromDateFormat(el[timeKey])
        };
        let idx;
        if (timeInterval === 'day' || timeInterval === 'week') {
            idx = frontData.findIndex((v) => {
                return isSameYearSameMonthSameDay(v.time, newEl.time);
            });
        } else {
            idx = frontData.findIndex((v) => {
                return isSameYearSameMonth(v.time, newEl.time);
            });
        }
        if (idx !== -1) {
            keyMap.map((key) => {
                if (Array.isArray(newEl[key.front])) {
                    frontData[idx][key.front] = newEl[key.front];
                } else {
                    frontData[idx][key.front] = newEl[key.front];
                }
                return null;
            });
        } else {
            frontData.push(newEl);
        }
        return null;
    });
};
