import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import icoUp from '../../../assets/images/ico-up.svg';
import icoDown from '../../../assets/images/ico-down.svg';
import styles from './total-sales.module.scss';
import { totalSalesMock } from './mock';
import { SalesService } from '../../../temp/test';
import { getRoundedValue } from '../../../utils/math';
import { ContentContainer, Loader, Spinner } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays the count and evolution of new clients compared to the previous month
 */

export const TotalSales = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(totalSalesMock);
        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(totalSalesMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        if (type) dataClone.attr = type;
                        const dataBack = await SalesService.getApiSalesSum({});
                        dataClone.content = getRoundedValue(dataBack.sum);
                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            type,
            t
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );
        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    // interval={'1M'}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <>
                            <div className="d-flex justify-content-start align-items-center">
                                <span
                                    className={`${styles['total-sales-container__card__percentage-text']}`}
                                    style={{
                                        color:
                                            data.contentAux > 0
                                                ? '#34b53a'
                                                : data.contentAux < 0
                                                ? '#df0000'
                                                : '#4c4c4c'
                                    }}
                                >
                                    {data.contentAux > 0 && '+'}

                                    {data.contentAux || '-'}
                                </span>
                                {data.contentAux > 0 && (
                                    <img loading="lazy" src={icoUp} alt="" />
                                )}
                                {data.contentAux < 0 && (
                                    <img loading="lazy" src={icoDown} alt="" />
                                )}
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                <span
                                    className={`${styles['total-sales-container__card__text']}`}
                                    style={{
                                        color: '#4c4c4c'
                                    }}
                                >
                                    {data.content}
                                </span>
                            </div>
                        </>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

TotalSales.propTypes = {};
