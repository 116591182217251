import React, { useState } from 'react'
import styles from './edit-role-modal.module.scss'
import { errorHandler } from '../../../utils/api'
import { Modal, PrimaryButton, SecondaryButton, SuccessModal, TypeInput } from '../..'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useTextInput } from '../../../hooks/useTextInput'
import { CheckboxInputNew } from '../../inputs/checkbox-input-new/checkbox-input-new'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'

export const EditRoleModal = ({ onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation()
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const { value: roleName, setValue: roleNameSetValue } = useTextInput({
        name: 'roleName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const { value: roleDescription, setValue: roleDescriptionSetValue } = useTextInput({
        name: 'roleDescription',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const { value: checkboxInputs, clickCheckboxOption } = useMultipleInputs({
        dashboardManagement: {
            type: 'checkbox',
            name: 'dashboardManagement',
            options: [
                {
                    name: 'dashboardManagement',
                },
            ],
            selectedOptions: [],
        },
    })

    const handleEditRole = async () => {
        try {
            setLoading(true)
            //await TeamsService.patchApiTeamsMe
            setIsSuccess(true)
            setLoading(false)
            onEnd()
        } catch (err) {
            setLoading(false)
            errorHandler(err)
        }
    }

    if (!isSuccess) {
        return (
            <Modal onToggleModal={() => onCloseModal(false)} isLoading={isLoading}>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">{t('modals:editRole.title')}</span>
                    </div>
                </div>
                <div className={`row mx-0 w-100`} style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <TypeInput newLabelType onChangeInput={roleNameSetValue} {...roleName} />
                    </div>
                </div>
                <div className={`row mx-0 w-100`} style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <TypeInput newLabelType onChangeInput={roleDescriptionSetValue} {...roleDescription} />
                    </div>
                </div>
                <div className={`row mx-0 w-100`} style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-start align-items-center">
                        <span className={`${styles['edit-role-modal-container__permissions-title']}`}>{t('common:permissions')}</span>
                    </div>
                </div>
                <div className={`row mx-0 w-100`} style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <CheckboxInputNew {...checkboxInputs.dashboardManagement} hideLabel onClickCheckboxOption={clickCheckboxOption} />
                    </div>
                </div>

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1',
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton
                            text={t('buttons:cancel')}
                            onClick={() => {
                                onCloseModal(false)
                            }}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('buttons:save')}
                            disabled={roleName.value === ''}
                            onClick={() => {
                                handleEditRole()
                            }}
                        />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return <SuccessModal text={t('modals:editRole.success')} onCloseModal={() => onCloseModal(false)} />
    }
}
