import { useState } from 'react';

export function useCurrentAppHook(logged) {
    const [value, setValue] = useState(() => {
        if (logged && window.location.pathname.split('/')[1] === 'back-office')
            return 'back-office';
        else if (
            logged &&
            window.location.pathname.split('/')[1] === 'administration'
        )
            return 'administration';
        else return 'pages';
    });

    return [value, setValue];
}
