import { createContext, useContext } from 'react';

const ChartReadyContext = createContext({
	charts: undefined,
	setChartReady: () => {},
});

export function ChartReadyProvider({ value, children }) {
	return (
		<ChartReadyContext.Provider value={value}>
			{children}
		</ChartReadyContext.Provider>
	);
}

export function useChartReady() {
	return useContext(ChartReadyContext);
}
