import React from 'react';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { GeneralTable } from '../../dashboard-components/general-table/general-table';

function generateOrganizationMembersMockData() {
    const names = [
        'Ava Smith',
        'Noah Johnson',
        'Emma Williams',
        'Liam Brown',
        'Sophia Jones',
        'Mason Miller',
        'Isabella Davis',
        'Lucas Garcia',
        'Mia Rodriguez',
        'Ethan Martinez',
        'Olivia Perez',
        'Aiden Wilson',
        'Lily Anderson',
        'Jacob Taylor',
        'Madison Thomas'
    ];

    const roles = [
        'Developer',
        'Manager',
        'Analyst',
        'Designer',
        'Administrator'
    ];

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    function createEmail(name) {
        return `${name.toLowerCase().replace(/ /g, '.')}@example.com`;
    }

    const data = names.map((name, index) => ({
        id: index + 1,
        name: name,
        email: createEmail(name),
        role: roles[getRandomInt(roles.length)],
        action: {
            id: index + 1,
            options: [
                { name: 'edit', icon: 'ico-edit.svg' },
                { name: 'delete', icon: 'ico-delete.svg' }
            ]
        }
    }));

    return {
        titleCounter: data.length.toString(),
        data,
        columns: [
            { path: 'id', label: 'ID', sortable: false },
            { path: 'name', label: 'Name', sortable: true },
            { path: 'email', label: 'Email', sortable: true },
            { path: 'role', label: 'Role', sortable: true },
            { path: 'action', label: 'Actions', sortable: false }
        ],
        currentPage: 1,
        pageSize: 10,
        type: 'type-1',
        attr: 'organizationMembersList',
        disabled: false,
        loading: false
    };
}

// Example usage:
const organizationMembersListMock = generateOrganizationMembersMockData();

export const OrganizationMembersList = () => {
    const { t } = useCustomTranslation();

    return (
        <GeneralTable
            withSearch
            title={t(`components:${organizationMembersListMock.attr}.title`)}
            attr={organizationMembersListMock.attr}
            columns={organizationMembersListMock.columns}
            mockData={organizationMembersListMock.data}
            updateEndpoint={() => {}}
            updateBodyInputs={{
                name: {
                    name: 'name',
                    path: 'name',
                    backendPath: 'name',
                    type: 'type',
                    show: true
                },
                email: {
                    name: 'email',
                    path: 'email',
                    backendPath: 'email',
                    type: 'type',
                    show: true
                },
                role: {
                    type: 'selectSingle',
                    path: 'role',
                    backendPath: 'role',
                    name: 'role',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    hideOptionsAux: true,
                    reference: React.createRef()
                }
            }}
            deleteEndpoint={() => {}}
            updateTitle={t(
                `components:${organizationMembersListMock.attr}.updateTitle`
            )}
            updateSuccessText={t(
                `components:${organizationMembersListMock.attr}.updateSuccessText`
            )}
            deleteTitle={t(
                `components:${organizationMembersListMock.attr}.deleteTitle`
            )}
            deleteDescription={t(
                `components:${organizationMembersListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${organizationMembersListMock.attr}.deleteSuccessText`
            )}
        />
    );
};
