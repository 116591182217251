export const bubbleGeoMock = {
    hoverStyle: '',
    desc: '',
    total: 147,
    revenue: 30000,
    percentage: 47,
    percentageGrowth: 0.6,
    tooltipType: 'bubbleGeo',
    dataList: [],
    titleText: '',
    attr: 'bubbleGeoChart',
    loading: true,
    legend: [
        {
            name: 'Team 1',
            color: '#ff6363',
            translated: true,
        },
        {
            name: 'Team 2',
            color: '#90d974',
            translated: true,
        },
        {
            name: 'Team 3',
            color: '#84cad9',
            translated: true,
        },
    ],
    type: 'normal',
    update: 1,
    disabled: true,
    startingPosition: {
        lat: 38.769688599999995,
        lon: -9.240822000000001,
        zoom: 7,
    },
    language: 'pt',
    minScale: 2,
    scale: 2,
    minZoom: 9,
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 38.736946,
        longitude: -9.142685,
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
    },
    interactiondisabled: true,
    showPopup: false,
    selectedEl: {},
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
        viewOptions: {
            ratioOptions: {
                options: [],
                selectedOption: '',
            },
            checkboxOptions: {
                options: [],
                selectedOptions: [],
            },
        },
    },
    ready: true,
}
