import { createContext, useContext } from 'react';

const HistoryGoalContext = createContext({
    historyGoalId: undefined,
    setHistoryGoalId: () => {}
});

export function HistoryGoalProvider({ value, children }) {
    return (
        <HistoryGoalContext.Provider value={value}>
            {children}
        </HistoryGoalContext.Provider>
    );
}

export function useHistoryGoal() {
    return useContext(HistoryGoalContext);
}
