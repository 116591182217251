import React, { useEffect, useState } from 'react';
import { SuccessModal } from '../success-modal/success-modal';
import { Modal, PrimaryButton, SecondaryButton } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import { errorHandler } from '../../../utils/api';
import { DTablesService, ProductsService } from '../../../temp/test';
import { InputFactory } from '../../inputs/input-factory/input-factory';
import { toIsoDate, toIsoDateTime, toIsoTime } from '../../../utils/format';

export const AddDynamicInfoModal = ({ dtableName, onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const {
        value: inputs,
        clickRadioOption: inputsClickRadioOption,
        setValue: inputsSetValue,
        toggleDropdown: inputsToggleDropdown,
        selectOption: inputsSelectOption,
        setOptions: inputsSetOptions,
        keyPress: inputsKeyPress,
        keyDown: inputsKeyDown,
        replaceAll: inputsReplaceAll
    } = useMultipleInputs({});

    useEffect(() => {
        async function fill() {
            setLoading(true);
            const tableBack =
                await DTablesService.getApiBackofficeAdminDtables1({
                    tableName: dtableName
                });

            const newInputs = {};
            if (tableBack.table_type === 'product_id') {
                const productBackData = await ProductsService.getApiProducts(
                    {}
                );
                const newFilter = {
                    name: tableBack.table_type,
                    showOptions: true,
                    type: 'selectSingle',
                    options: [],
                    optionsAux: [],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    reference: React.createRef()
                };
                productBackData.map((el) => {
                    newFilter.options.push(el.title);
                    newFilter.optionsAux.push(el.id.toString());
                    return el;
                });
                newInputs[tableBack.table_type] = { ...newFilter };
            }
            tableBack.columns.map((el) => {
                if (el.variable_name !== tableBack.table_type) {
                    if (
                        el.variable_type === 'string' ||
                        el.variable_type === 'int' ||
                        el.variable_type === 'float'
                    ) {
                        newInputs[el.variable_name] = {
                            name: el.variable_name,
                            type: 'type',
                            label: el.variable_name,
                            value: ''
                        };
                    } else if (el.variable_type === 'datetime') {
                        newInputs[el.variable_name] = {
                            name: el.variable_name,
                            type: 'datenew',
                            label: el.variable_name,
                            value: ''
                        };
                    }
                }
            });
            inputsReplaceAll(newInputs);
            setLoading(false);
        }
        fill();
    }, [dtableName, inputsReplaceAll]);

    const handleAddDynamicInfo = async () => {
        try {
            const body = Object.values(inputs).reduce((acc, el) => {
                if (el.type === 'datenew') {
                    acc[el.name] = toIsoDateTime(
                        toIsoDate(el.value),
                        '00:00:00'
                    );
                } else if (el.type === 'selectSingle' || el.type === 'radio') {
                    acc[el.name] = el.selectedOptionAux;
                } else if (
                    el.type === 'selectMultiple' ||
                    el.type === 'checkbox'
                ) {
                    acc[el.name] = el.selectedOptionsAux;
                } else {
                    acc[el.name] = el.value;
                }
                return acc;
            }, {});
            const result =
                await DTablesService.postApiBackofficeAdminDtablesData({
                    tableName: dtableName,
                    requestBody: {
                        ...body
                    }
                });
            if (onEnd) onEnd();
            setIsSuccess(true);
        } catch (err) {
            errorHandler(err);
        }
    };

    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">
                            {t('modals:addDynamicInfo.title')}
                        </span>
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col px-0">
                        {Object.values(inputs) &&
                            Object.values(inputs).map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem'
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-end align-items-center">
                                            <InputFactory
                                                {...el}
                                                clickRadioOption={
                                                    inputsClickRadioOption
                                                }
                                                onChangeInput={inputsSetValue}
                                                onToggleDropdown={
                                                    inputsToggleDropdown
                                                }
                                                onSelectInput={
                                                    inputsSelectOption
                                                }
                                                setOptions={inputsSetOptions}
                                                keyPress={inputsKeyPress}
                                                keyDown={inputsKeyDown}
                                                replaceAll={inputsReplaceAll}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton
                            type={'cancel'}
                            text={t('modals:cancel')}
                            onClick={onCloseModal}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('modals:save')}
                            onClick={handleAddDynamicInfo}
                        />
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:addDynamicInfo.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
