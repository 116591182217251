import { createContext, useContext, useState } from 'react'
import { AddNewTimeseriesEntryModal } from '../components/modals/add-new-timeseries-entry-modal/add-new-timeseries-entry-modal'

const ModalStatusContext = createContext({
    isOpen: '',
    setIsOpen: () => {},
})

export function ModalStatusProvider({ value, children }) {
    return (
        <>
            <ModalStatusContext.Provider value={value}>{children}</ModalStatusContext.Provider>
        </>
    )
}

export function useModalStatus() {
    return useContext(ModalStatusContext)
}
