import styles from './login.module.scss'

import React, { useEffect } from 'react'

import { Navigate, Route, useNavigate } from 'react-router-dom'
import icoStarkSmall from '../../../assets/images/logo-small.jpg'
import { errorHandler } from '../../../utils/api'
import { useCurrentUser } from '../../../providers/current-user-context'
import { loginWithJwt } from '../../../utils/authService'
import { AuthService } from '../../../temp/test'
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate'
import { ContactFooter, PrimaryButton, TypeInputAlternate } from '../../../components'

import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { AuthBackgroundWrapper } from '../../../components/backgrounds/AuthBackgroundWrapper/AuthBackgroundWrapper'

/**
 * Dashboard allowing user to log into the app
 */

const Login = React.memo(({ onFinishLogin }) => {
    const navigate = useNavigate()
    const { t } = useCustomTranslation()
    const { currentUser } = useCurrentUser()

    const username = useTextInputAlternate({
        name: 'username',
        // schema: loginUsernameSchema,
    })
    const password = useTextInputAlternate({
        name: 'password',
        // schema: weakPasswordSchema,
        isPassword: true,
    })

    useEffect(() => {
        if (currentUser) {
            return <Route path="*" element={<Navigate to="/dashboard" replace />} />
        }
    }, [currentUser])

    const handleLogin = async () => {
        // const usernameResult = username.validate()
        // const passwordResult = password.validate()
        // if (usernameResult.error || passwordResult.error) {
        //     return
        // }
        try {
            const { token, refresh_token } = await AuthService.postApiAuthLogin({
                requestBody: {
                    username: username.value,
                    password: password.value,
                },
            })
            loginWithJwt(token, refresh_token)
            onFinishLogin()
        } catch (err) {
            handleLoginError(err)
        }
    }

    const handleLoginError = (err) => {
        if (err.status === 300) {
            navigate('/login/code-verification', {
                state: { email: username.value, password: password.value },
            })
            handleSendMethod()
        } else {
            errorHandler(err)
        }
    }

    const handleSendMethod = async () => {
        try {
            await AuthService.postApiAuthLoginChallenge({
                requestBody: {
                    method: 'email',
                    password: password.value,
                    username: username.value,
                },
            })
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            handleLogin()
        }
    }

    return (
        <AuthBackgroundWrapper
            title={t('pages:login.welcome')}
            content={
                <>
                    <div className="row mx-0">
                        <div className="col px-0">
                            <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <TypeInputAlternate input={username} onKeyPress={handleKeyPress} />
                                </div>
                            </div>
                            <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <TypeInputAlternate input={password} onKeyPress={handleKeyPress} />
                                </div>
                            </div>
                            <div className="row mx-0" style={{ marginBottom: '5rem' }}>
                                <div className="col px-0 d-flex justify-content-end">
                                    <span
                                        className={styles.LoginContainer_link}
                                        onClick={() => {
                                            navigate('/forgot-password')
                                        }}
                                    >
                                        {t('pages:login.forgot')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row mx-0 d-flex justify-content-end align-items-center"
                        style={{
                            marginBottom: '2rem',
                        }}
                    >
                        <div className="col px-0">
                            <PrimaryButton text={t('pages:login.signin')} onClick={handleLogin} />
                        </div>
                    </div>
                    <hr
                        style={{
                            margin: '2rem 0',
                            background: '#e6e6e6',
                        }}
                    />
                    <div className="row mx-0 w-100 d-flex justify-content-center" style={{ marginBottom: '3rem' }}>
                        <div className="col-auto px-0">
                            <ContactFooter />
                        </div>
                    </div>
                </>
            }
        ></AuthBackgroundWrapper>
    )
})

export default Login

Login.propTypes = {}
