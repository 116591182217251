import React from 'react'
import icoArrowDown from '../../../assets/images/icoCasseteArrow.svg'
import styles from './cassete.module.scss'

export const Cassete = ({ counter, title, startOpen = false, alwaysOpen = false, children }) => {
    const [isOpen, setIsOpen] = React.useState(startOpen || alwaysOpen)
    return (
        <div className={styles['cassete-container']}>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div
                        className={styles['cassete-container__header']}
                        style={{
                            borderRadius: isOpen ? '2rem 2rem 0 0' : '2rem',
                        }}
                    >
                        <div className="row mx-0 w-100">
                            {counter && (
                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                    <span className={styles['cassete-container__header__counter']}>{counter}</span>
                                </div>
                            )}
                            {title && (
                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                    <span className={styles['cassete-container__header__title']}>{title}</span>
                                </div>
                            )}
                            <div className="col px-0"></div>
                            {!alwaysOpen && (
                                <div className="col-auto px-0">
                                    <img
                                        src={icoArrowDown}
                                        alt="arrow-down"
                                        style={{
                                            transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                                        }}
                                        className={styles['cassete-container__header__arrow']}
                                        onClick={() => setIsOpen(!isOpen)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="row mx-0 w-100">
                    <div className="col px-0">
                        <div className={styles['cassete-container__content']}>{children}</div>
                    </div>
                </div>
            )}
        </div>
    )
}
