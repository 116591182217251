import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './select-multiple-input.module.scss';
//import { ReactComponent as IcoError } from './assets/images/ico-error.svg'
import { ReactComponent as ArrowDown } from './assets/images/dropdown-arrow.svg';
import { ReactComponent as IcoClose } from './assets/images/ico-close.svg';
import { useTranslation } from 'react-i18next';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import FullTextPopup from '../../popups/FullTextPopup';

export const SelectMultipleInput = React.memo(
    ({
        onToggleDropdown,
        onSelectMultiple,
        onSelectedOptionClick,
        reference,
        disabled,
        name,
        options,
        optionsAux,
        showOptions,
        selectedOptions = [],
        selectedOptionsAux = [],
        hideOptionsAux,
        label,
        state,
        value,
        onChangeInput,
        loading,
        onKeyDown,
        focused,
        openDropdown,
        loadingText,
        noOptionsText,
        error,
        hovered,
        hideLabel
    }) => {
        const { t } = useCustomTranslation();
        const handleOptionClick = (e, name, option, optionAux) => {
            e.preventDefault();
            e.stopPropagation();
            onToggleDropdown(false, name);
            onSelectMultiple(option, optionAux, name);
        };

        const handleSelectedOptionClick = (e, name, option, optionAux) => {
            e.stopPropagation();
            onSelectedOptionClick(option, optionAux, name);
        };

        const [selectInputLeft, setSelectInputLeft] = useState(0);

        const selectInputRef = useRef(null);

        const handleDropdownPosition = useCallback(() => {
            if (selectInputRef.current) {
                const rect = selectInputRef.current.getBoundingClientRect();
                setSelectInputLeft(rect.left);
            }
        }, [selectInputRef]);
        useEffect(() => {
            if (openDropdown) {
                handleDropdownPosition();
            } else {
                setSelectInputLeft(undefined);
            }
        }, [openDropdown, handleDropdownPosition]);

        const handleOutsideClick = useCallback(
            (event) => {
                if (
                    reference.current &&
                    !reference.current.contains(event.target)
                ) {
                    event.preventDefault();
                    event.stopPropagation();
                    onToggleDropdown(false, name);
                }
            },
            [reference, onToggleDropdown, name]
        );

        useEffect(() => {
            if (openDropdown && reference.current) {
                document.addEventListener('mousedown', handleOutsideClick);
            } else if (!openDropdown && reference.current) {
                document.removeEventListener('mousedown', handleOutsideClick);
            }
            return function cleanup() {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [handleOutsideClick, openDropdown, reference]);

        let containerStyle;
        const hoveredStyle = hovered ? 'hovered' : '';
        if (openDropdown && disabled) {
            containerStyle = `${styles['select-multiple-container']} ${styles['selected']} ${styles['disabled']}`;
        } else if (
            (selectedOptions.length !== 0 ||
                selectedOptionsAux.length !== 0 ||
                value !== '') &&
            disabled
        ) {
            containerStyle = `${styles['select-multiple-container']} ${styles['valued']} ${styles['disabled']}`;
        } else if (disabled) {
            containerStyle = `${styles['select-multiple-container']} ${styles['disabled']}`;
        } else if (openDropdown) {
            containerStyle = `${styles['select-multiple-container']} ${styles['selected']} ${hoveredStyle}`;
        } else if (
            selectedOptions.length !== 0 ||
            selectedOptionsAux.length !== 0 ||
            value !== ''
        ) {
            containerStyle = `${styles['select-multiple-container']} ${styles['valued']} ${hoveredStyle}`;
        } else {
            containerStyle = `${styles['select-multiple-container']} ${hoveredStyle}`;
        }
        let newOptions = [...options];
        let newOptionsAux;
        if (optionsAux) newOptionsAux = [...optionsAux];
        if (value) {
            const valueReplaced = value.replace(
                new RegExp('\\\\', 'g'),
                '\\\\'
            );
            const regex = new RegExp(`${valueReplaced}`, 'i');
            newOptions = options.filter((p, index) => {
                if (p !== '') {
                    const result = p.toString().match(regex);
                    if (result) {
                        return result;
                    } else if (
                        optionsAux.length >= index + 1 &&
                        optionsAux[index] !== ''
                    ) {
                        return optionsAux[index].toString().match(regex);
                    }
                } else if (
                    optionsAux.length >= index + 1 &&
                    optionsAux[index] !== ''
                ) {
                    return optionsAux[index].toString().match(regex);
                }
            });
            newOptionsAux = optionsAux.filter((p, index) => {
                if (options.length >= index + 1 && options[index] !== '') {
                    const result = options[index].toString().match(regex);
                    if (result) {
                        return result;
                    } else if (p !== '') {
                        return p.toString().match(regex);
                    }
                } else if (p !== '') {
                    return p.toString().match(regex);
                }
            });
        }

        return (
            <React.Fragment>
                <div
                    className={containerStyle}
                    ref={reference}
                    onMouseDown={
                        !disabled
                            ? (e) => {
                                  e.stopPropagation();
                                  onToggleDropdown(undefined, name);
                              }
                            : undefined
                    }
                >
                    <div
                        className={`${styles['select-multiple-container__input-container']} ${state}`}
                        ref={selectInputRef}
                    >
                        {selectedOptions.length !== 0 && (
                            <div
                                className={`${styles['select-multiple-container__values']}`}
                            >
                                <div
                                    className={`${styles['select-multiple-container__values__container']}`}
                                >
                                    {selectedOptions.map((selOption, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`${styles['select-multiple-container__values__container__value']}`}
                                                onMouseDown={
                                                    !disabled
                                                        ? (e) =>
                                                              handleSelectedOptionClick(
                                                                  e,
                                                                  name,
                                                                  selectedOptions[
                                                                      index
                                                                  ],
                                                                  selectedOptionsAux[
                                                                      index
                                                                  ]
                                                              )
                                                        : undefined
                                                }
                                            >
                                                <FullTextPopup
                                                    text={
                                                        selectedOptions[index]
                                                    }
                                                >
                                                    <span
                                                        className={`${styles['select-multiple-container__values__container__value__main']}`}
                                                    >
                                                        {selectedOptions[index]}
                                                    </span>
                                                </FullTextPopup>
                                                {!hideOptionsAux &&
                                                    selectedOptionsAux[
                                                        index
                                                    ] !== '' && (
                                                        <span
                                                            className={`${styles['select-multiple-container__values__container__value__secondary']}`}
                                                        >
                                                            {
                                                                selectedOptionsAux[
                                                                    index
                                                                ]
                                                            }
                                                        </span>
                                                    )}
                                                {/* <IcoClose /> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <div
                            className={`${styles['select-multiple-container__input-container-container']} w-100`}
                        >
                            {(!selectedOptions ||
                                (selectedOptions &&
                                    selectedOptions.length === 0)) && (
                                <input
                                    autoComplete="off"
                                    name={name}
                                    value={value}
                                    size=""
                                    placeholder=""
                                    type="text"
                                    className={
                                        value
                                            ? `${styles['select-multiple-container__input-container__input']}`
                                            : `${styles['select-multiple-container__input-container__input']} ${styles['placeholder']}`
                                    }
                                    onChange={(e) => onChangeInput(e, name)}
                                    // onKeyPress={(e) => {
                                    //     e.persist()
                                    //     onKeyPress(e)
                                    // }}
                                />
                            )}
                            {!hideLabel && (
                                <label
                                    htmlFor={name}
                                    className={
                                        state === 'error'
                                            ? `${styles['select-multiple-container__label']} ${styles['select-multiple-container__label--error']}`
                                            : `${styles['select-multiple-container__label']}`
                                    }
                                >
                                    {label || t(`inputs:${name}`)}
                                </label>
                            )}
                        </div>
                        <div
                            className={`${styles['select-multiple-container__input-container__image-container']}`}
                            onMouseDown={
                                onToggleDropdown && !disabled
                                    ? (e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          onToggleDropdown(undefined, name);
                                      }
                                    : undefined
                            }
                        >
                            <ArrowDown
                                htmlFor={name}
                                className={
                                    state === 'error'
                                        ? `${styles['select-multiple-container__input-container__image-container__image']} ${styles['select-multiple-container__input-container__image-container__image--error']}`
                                        : `${styles['select-multiple-container__input-container__image-container__image']}`
                                }
                            />
                        </div>
                    </div>

                    {openDropdown && selectInputLeft && (
                        <div
                            className={`row mx-0 ${styles['select-multiple-container__dropdown']}`}
                            id={`${name}-dropdown`}
                            style={{
                                marginTop: '1rem',
                                left: selectInputLeft,
                                width: selectInputRef.current
                                    ? selectInputRef.current.offsetWidth
                                    : 'auto'
                            }}
                        >
                            <div
                                className={`col  p-0 ${styles['select-multiple-container__dropdown__element']}`}
                            >
                                {newOptions.length === 0 && !loading && (
                                    <div
                                        className={`row mx-0`}
                                        style={{
                                            height: '5rem',
                                            padding: '2rem'
                                        }}
                                    >
                                        <div
                                            className={`col px-0 d-flex justify-content-center align-items-center`}
                                        >
                                            <span
                                                style={{
                                                    fontFamily:
                                                        'OpenSansRegular',
                                                    fontSize: '1.4rem',
                                                    color: '#9b9b9b'
                                                }}
                                            >
                                                {t(`inputs:noOptions`)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {newOptions.length === 0 && loading && (
                                    <div
                                        className={`row m-0 ${styles['select-multiple-container__dropdown__container-loader']}`}
                                    >
                                        <div
                                            className={`col p-0 ${styles['select-multiple-container__dropdown__loader-value']}`}
                                        >
                                            {loadingText}
                                        </div>
                                    </div>
                                )}
                                {newOptions &&
                                    newOptions.length !== 0 &&
                                    !loading &&
                                    showOptions !== false &&
                                    newOptions.map((option, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    index == focused
                                                        ? `row ${styles['select-multiple-container__dropdown__container']} ${styles['select-multiple-container__dropdown__container--focused']}`
                                                        : `row ${styles['select-multiple-container__dropdown__container']}`
                                                }
                                                onMouseDown={
                                                    !disabled
                                                        ? (e) =>
                                                              handleOptionClick(
                                                                  e,
                                                                  name,
                                                                  newOptions[
                                                                      index
                                                                  ],
                                                                  newOptionsAux[
                                                                      index
                                                                  ]
                                                              )
                                                        : undefined
                                                }
                                            >
                                                <div
                                                    className={`w-100 p-0 ${styles['select-multiple-container__dropdown__value']}`}
                                                >
                                                    <FullTextPopup
                                                        text={option || '-'}
                                                    >
                                                        <span
                                                            className={`${styles['select-multiple-container__dropdown__value__main']}`}
                                                        >
                                                            {option || '-'}
                                                        </span>
                                                    </FullTextPopup>
                                                    {!hideOptionsAux &&
                                                        newOptionsAux[index] !==
                                                            '' && (
                                                            <span
                                                                className={`${styles['select-multiple-container__dropdown__value__secondary']}`}
                                                            >
                                                                {
                                                                    newOptionsAux[
                                                                        index
                                                                    ]
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                {newOptions.length === 0 && !loading && (
                                    <div
                                        className={`row m-0 ${styles['select-multiple-container__dropdown__container-loader']}`}
                                    >
                                        <div
                                            className={`col p-0 ${styles['select-multiple-container__dropdown__loader-value']}`}
                                        >
                                            {noOptionsText}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {/* {state === 'error' && (
            <IcoError className='select-multiple-container__error-icon' />
          )} */}
                </div>
                {error && (
                    <div
                        className={`${styles['select-multiple-container-error']}`}
                    >
                        {t(`errors:${error}`)}
                    </div>
                )}
            </React.Fragment>
        );
    }
);

SelectMultipleInput.propTypes = {
    /**
     * Boolean to disable input if true
     */
    disabled: PropTypes.bool,
    /**
     * Boolean to disable input if true
     */
    loading: PropTypes.bool,
    /**
     * Reference to the input
     */
    reference: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    /**
     * String to display input title
     */
    label: PropTypes.string,
    /**
     * String to change language
     */
    language: PropTypes.string,
    /**
     * index to focus option
     */
    focused: PropTypes.number,
    /**
     * String to display input value
     */
    value: PropTypes.string.isRequired,
    /**
     * String to match variable containing all the other attributes
     */
    name: PropTypes.string.isRequired,
    /**
     * Array of strings where each string is an option
     */
    options: PropTypes.array.isRequired,
    /**
     * Array of complementary strings where each string is an option
     */
    optionsAux: PropTypes.array,
    /**
     * Array of strings containing selected options
     */
    selectedOptions: PropTypes.array.isRequired,
    /**
     * Array of strings containing auxiliary selected options
     */
    selectedOptionsAux: PropTypes.array,
    /**
     * String that defines type of input to display : 'normal' | 'valid' | 'error'
     */
    state: PropTypes.string.isRequired,
    /**
     * String to display placeholder when no option is selected
     */
    /**
     * Function to handle selection of option from dropdown
     */
    onSelectMultiple: PropTypes.func.isRequired,
    /**
     * Function to handle selection of option from dropdown
     */
    onChangeInput: PropTypes.func.isRequired,
    /**
     * Function to handle selection of option from dropdown
     */
    onSelectedOptionClick: PropTypes.func.isRequired,
    /**
     * Function to handle clicking the arrow icon
     */
    onClickArrow: PropTypes.func,
    /**
     * Function to handle press of key
     */
    onKeyDown: PropTypes.func,
    /**
     * Function to handle press of key
     */
    onKeyPress: PropTypes.func
};
