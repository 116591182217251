import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import icoPin from '../../../assets/images/icoPin.svg';
import icoUnpin from '../../../assets/images/icoUnpin.svg';
import icoEdit from '../../../assets/images/ico-edit-blue.svg';
import icoSave from '../../../assets/images/ico-save-blue.svg';
import 'bootstrap/dist/css/bootstrap.css';
import styles from './content-container.module.scss';
import { ChartOptions, Scrollable } from '../..';
import icoFilterBlue from '../../../assets/images/icoFilterBlue.svg';

import forecastVector from '../../../assets/images/forecast-vector.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { formatDateTime } from '../../../utils/date';
import { CursorTrackingProvider } from '../../../providers/cursor-tracking-context';
import useCursorTracking from '../../../hooks/useCursorTracking';
import { TimeIntervalProvider } from '../../../providers/time-interval-context';
import { ComponentFilterPopup } from '../../popups/time-interval-popup/ComponentFilterPopup';
import { useToggle } from '../../../hooks/useToggle';
import icoUserForecast from '../../../assets/images/icoUserForecast.svg';
import { UserForecastsPopup } from '../../popups/user-forecasts-popup/UserForecastsPopup';

export const ContentContainer = React.memo(
    ({
        attr,
        titleText,
        isForecast,
        editorMode,
        legend,
        showChartOptions,
        chartOptions,
        selectedChartOption,
        onClickChartOption,
        onMouseEnterContainer,
        onClickContainer,
        settings,
        children,
        hovered,
        darken,
        noBorder,
        pinned,
        onPinComponent,
        onUnpinComponent,
        hidePinIcon,
        complementaryInfo,
        interval,
        onOpenEditMode,
        onSaveEditMode,
        onCancelEditMode,
        isEditing,
        onClickLegend,
        lastUpdated,
        icon,
        isCardTitle,
        withTimeFilter,
        withChannelFilter,
        withForecastFilter,
        withUserForecastInput,
        timeInterval
    }) => {
        const { t } = useCustomTranslation();
        const hoveredStyle = hovered ? 'hovered' : '';
        const darkStyle = darken ? 'dark' : '';
        const borderStyle = noBorder ? 'no-border' : '';
        const [isContainerHovered, setIsContainerHovered] =
            React.useState(false);
        const position = useCursorTracking();
        const componentFilterRef = React.useRef(null);
        const [componentFilterOpen, setComponentFilterOpen] = useToggle(false);
        const userForecastsRef = React.useRef(null);
        const [userForecastsPopupOpen, setUserForecastsPopupOpen] =
            useToggle(false);

        // get item.color as hex and return as rgba with 0.6 opacity

        const hexToRgbA = (hex, opacity) => {
            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('');
                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = `0x${c.join('')}`;
                return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
                    ','
                )},${opacity})`;
            }
            throw new Error('Bad Hex');
        };

        const hasFilters = useMemo(() => {
            return withTimeFilter || withChannelFilter || withForecastFilter;
        }, [withTimeFilter, withChannelFilter, withForecastFilter]);

        return (
            <TimeIntervalProvider timeInterval={timeInterval}>
                <div
                    className={
                        isForecast && editorMode
                            ? `${styles['content-container']} ${
                                  styles[`${darkStyle}`]
                              } ${styles[`${borderStyle}`]} ${
                                  styles[`content-container--forecast`]
                              } ${styles['content-container--disabled']}`
                            : isForecast
                            ? `${styles['content-container']} ${
                                  styles[`${darkStyle}`]
                              } ${styles[`${borderStyle}`]} ${
                                  styles[`content-container--forecast`]
                              } ${styles[`${hoveredStyle}`]}`
                            : editorMode
                            ? `${styles['content-container']} ${
                                  styles[`${darkStyle}`]
                              } ${styles[`${borderStyle}`]} ${
                                  styles[`content-container--disabled`]
                              }`
                            : `${styles['content-container']} ${
                                  styles[`${darkStyle}`]
                              } ${styles[`${borderStyle}`]} ${
                                  styles[`${hoveredStyle}`]
                              }`
                    }
                    onMouseEnter={() => {
                        if (onMouseEnterContainer) {
                            onMouseEnterContainer(attr);
                        }
                        setIsContainerHovered(true);
                    }}
                    onMouseLeave={() => {
                        if (onMouseEnterContainer) {
                            onMouseEnterContainer(attr);
                        }
                        setIsContainerHovered(false);
                    }}
                    onClick={
                        onClickContainer
                            ? () => onClickContainer(attr)
                            : () => {}
                    }
                >
                    {titleText && (
                        <div
                            className={
                                !legend
                                    ? `row w-100 m-0 ${styles['content-container__first-row']} ${styles['content-container__first-row--no-legend']} d-flex justify-content-start align-items-start`
                                    : `row w-100 m-0 ${styles['content-container__first-row']} d-flex justify-content-start align-items-start`
                            }
                        >
                            <div className="col title p-0 d-flex justify-content-start align-items-center">
                                {isForecast && (
                                    <img
                                        loading="lazy"
                                        src={forecastVector}
                                        className="me-2"
                                        style={{
                                            width: '2.4rem',
                                            height: '2.4rem'
                                        }}
                                    />
                                )}
                                <span
                                    className={`${styles['content-container__font']} me-2`}
                                >
                                    {titleText}
                                </span>
                                {lastUpdated && (
                                    <span
                                        className={`${styles['content-container__font']} ${styles['last-updated']}`}
                                    >
                                        {t('common:lastUpdated')}{' '}
                                        {formatDateTime(lastUpdated)}
                                    </span>
                                )}
                                <span
                                    className={`${styles['content-container__font']} ${styles['complementary']}`}
                                >
                                    {interval}
                                </span>
                                {complementaryInfo && (
                                    <span
                                        className={`${styles['content-container__font']} ${styles['complementary']}`}
                                    >
                                        <span>&nbsp;</span>
                                        {complementaryInfo}
                                    </span>
                                )}
                                {hasFilters && (
                                    <div
                                        className="position-relative"
                                        ref={componentFilterRef}
                                    >
                                        <img
                                            loading="lazy"
                                            src={icoFilterBlue}
                                            alt="filter"
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            className={
                                                componentFilterOpen
                                                    ? `${styles['content-container__filter-icon']} ${styles['selected']}`
                                                    : `${styles['content-container__filter-icon']}`
                                            }
                                            onClick={() =>
                                                setComponentFilterOpen()
                                            }
                                        />
                                        {componentFilterOpen && (
                                            <ComponentFilterPopup
                                                onClosePopup={() => {
                                                    setComponentFilterOpen(
                                                        false
                                                    );
                                                }}
                                                withTimeFilter={withTimeFilter}
                                                withChannelFilter={
                                                    withChannelFilter
                                                }
                                                withForecastFilter={
                                                    withForecastFilter
                                                }
                                                withUserForecastInput={
                                                    withUserForecastInput
                                                }
                                                componentRef={
                                                    componentFilterRef
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                                {withUserForecastInput && (
                                    <div
                                        className="position-relative"
                                        ref={userForecastsRef}
                                    >
                                        <img
                                            loading="lazy"
                                            src={icoUserForecast}
                                            alt="user-forecast"
                                            className={
                                                userForecastsPopupOpen
                                                    ? `${styles['content-container__user-forecast-icon']} ${styles['selected']}`
                                                    : `${styles['content-container__user-forecast-icon']}`
                                            }
                                            onClick={() =>
                                                setUserForecastsPopupOpen()
                                            }
                                        />
                                        {userForecastsPopupOpen && (
                                            <UserForecastsPopup
                                                onClosePopup={() => {
                                                    setUserForecastsPopupOpen(
                                                        false
                                                    );
                                                }}
                                                componentRef={userForecastsRef}
                                            />
                                        )}
                                    </div>
                                )}
                                {settings && titleText && (
                                    <div
                                        className={`${styles['content-container__first-row__options-container']}`}
                                        style={{
                                            zIndex: 100,
                                            visibility: isContainerHovered
                                                ? 'visible'
                                                : 'hidden',
                                            display: isContainerHovered
                                                ? ''
                                                : 'none'
                                        }}
                                    >
                                        {!hidePinIcon &&
                                            onUnpinComponent &&
                                            onPinComponent && (
                                                <div className="d-flex align-items-center justify-content-center component-icon pe-2">
                                                    <img
                                                        loading="lazy"
                                                        src={
                                                            pinned
                                                                ? icoUnpin
                                                                : icoPin
                                                        }
                                                        alt=""
                                                        onClick={
                                                            pinned &&
                                                            onUnpinComponent
                                                                ? () =>
                                                                      onUnpinComponent(
                                                                          attr
                                                                      )
                                                                : !pinned &&
                                                                  onPinComponent
                                                                ? () =>
                                                                      onPinComponent(
                                                                          attr
                                                                      )
                                                                : undefined
                                                        }
                                                        className={`${styles['content-container__ico-pin']}`}
                                                    />
                                                </div>
                                            )}
                                        {/* {!hideSettingsIcon && settings && (
                                        <div className="component-icon">
                                            <SettingsButton
                                                settings={settings}
                                                onClickSettingsView={
                                                    onClickSettingsView
                                                }
                                                onClickSettingsOptions={
                                                    onClickSettingsOptions
                                                }
                                                onClickSettingsSelected={
                                                    onClickSettingsSelected
                                                }
                                                onClickSettingsIcon={
                                                    onClickSettingsIcon
                                                }
                                                onCloseSettings={
                                                    onCloseSettings
                                                }
                                                disabled={disabled}
                                            />
                                        </div>
                                    )} */}
                                    </div>
                                )}
                            </div>
                            {isEditing &&
                                onSaveEditMode &&
                                onCancelEditMode && (
                                    <div
                                        className="col-auto px-0 d-flex justify-content-center align-items-center"
                                        style={{
                                            cursor: 'pointer',
                                            position: 'relative'
                                        }}
                                    >
                                        <span
                                            className={
                                                styles[
                                                    'content-container__cancel-text'
                                                ]
                                            }
                                            onClick={() => onCancelEditMode()}
                                        >
                                            {t('common:cancel')}
                                        </span>
                                        <div
                                            className={`d-flex justify-content-center align-items-center`}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}
                                            onClick={() => onSaveEditMode()}
                                        >
                                            <img
                                                loading="lazy"
                                                src={icoSave}
                                                alt="save"
                                                className={
                                                    styles[
                                                        'content-container__save-icon'
                                                    ]
                                                }
                                                style={{
                                                    width: '2rem',
                                                    height: '2rem',
                                                    marginRight: '0.5rem'
                                                }}
                                            />
                                            <span
                                                className={
                                                    styles[
                                                        'content-container__save-text'
                                                    ]
                                                }
                                            >
                                                {t('common:save')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            {!isEditing && onOpenEditMode && (
                                <div
                                    className="col-auto px-0 d-flex justify-content-center align-items-center"
                                    style={{
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={() => onOpenEditMode()}
                                >
                                    <img
                                        loading="lazy"
                                        src={icoEdit}
                                        alt="edit"
                                        className={
                                            styles[
                                                'content-container__edit-icon'
                                            ]
                                        }
                                        style={{
                                            marginRight: '0.5rem'
                                        }}
                                    />
                                    <span
                                        className={
                                            styles[
                                                'content-container__edit-text'
                                            ]
                                        }
                                    >
                                        {t('common:edit')}
                                    </span>
                                </div>
                            )}

                            {showChartOptions && (
                                <ChartOptions
                                    chartOptions={chartOptions}
                                    selectedChartOption={selectedChartOption}
                                    onClickChartOption={onClickChartOption}
                                />
                            )}
                        </div>
                    )}

                    <div
                        className={
                            !legend && !titleText
                                ? `row w-100 m-0 ${styles['content-container__second-row']} ${styles['content-container__second-row--no-legend-no-title']}`
                                : !legend
                                ? `row w-100 m-0 ${styles['content-container__second-row']} ${styles['content-container__second-row--no-legend']}`
                                : !titleText
                                ? `row w-100 m-0 ${styles['content-container__second-row']} ${styles['content-container__second-row--no-title']}`
                                : `row w-100 m-0 ${styles['content-container__second-row']}`
                        }
                    >
                        <div className="col p-0 h-100 w-100 position-relative">
                            <CursorTrackingProvider
                                value={{
                                    position: {
                                        x: position.x,
                                        y: position.y
                                    }
                                }}
                            >
                                {children || undefined}
                            </CursorTrackingProvider>
                        </div>
                    </div>
                    {legend && legend.length > 0 && (
                        <div
                            className={`row w-100 m-0 ${styles['content-container__third-row']}`}
                        >
                            <div className="col p-0 d-flex justify-content-start align-items-center">
                                <Scrollable
                                    direction="horizontal"
                                    addBottomSpacing
                                >
                                    <div className="w-100 h-100 d-flex">
                                        {legend.map((item, index) => {
                                            let existsActive = legend.some(
                                                (item2) =>
                                                    _.has(item2, 'active') &&
                                                    item2.active
                                            );
                                            let isActive =
                                                _.has(item, 'active') &&
                                                item.active;
                                            const { isForecast } = item;
                                            const rgbaColor = hexToRgbA(
                                                item.color,
                                                0.2
                                            );
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={
                                                        onClickLegend
                                                            ? () =>
                                                                  onClickLegend(
                                                                      item
                                                                  )
                                                            : undefined
                                                    }
                                                    className="d-flex justify-content-start align-items-center"
                                                    style={{
                                                        opacity:
                                                            existsActive &&
                                                            isActive
                                                                ? 1
                                                                : existsActive
                                                                ? 0.3
                                                                : 1,
                                                        cursor: onClickLegend
                                                            ? 'pointer'
                                                            : 'default'
                                                    }}
                                                >
                                                    <span
                                                        className={`${styles['content-container__third-row__legend-circle']}`}
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            // if its isForecast, then the border should be dashed
                                                            border: isForecast
                                                                ? `1px dashed ${item.color}`
                                                                : '4px solid',
                                                            borderColor:
                                                                item.color,
                                                            background:
                                                                isForecast
                                                                    ? rgbaColor
                                                                    : undefined
                                                        }}
                                                    />
                                                    <span
                                                        className={`${styles['content-container__third-row__legend-text']}`}
                                                        style={{
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    >
                                                        {_.has(
                                                            item,
                                                            'translated'
                                                        ) &&
                                                            item.translated &&
                                                            item.name}
                                                        {_.has(
                                                            item,
                                                            'translated'
                                                        ) &&
                                                            !item.translated &&
                                                            t(
                                                                `legend:${item.name}`
                                                            )}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Scrollable>
                            </div>
                        </div>
                    )}
                </div>
            </TimeIntervalProvider>
        );
    }
);
ContentContainer.propTypes = {
    /**
     * Bool to use or not forecast icon and style on the container
     * */
    isForecast: PropTypes.bool,
    /**
     * String to display title of chart container
     * */
    titleText: PropTypes.string,
    /**
     * String to access data attribute
     * */
    attr: PropTypes.string,
    /**
     * Bool to decide if settings button should be disabled
     */
    disabled: PropTypes.bool,
    /**
     * Bool to disable all pointer events
     */
    editorMode: PropTypes.bool,
    /**
     * Array containing objects
     * Each object has the following attributes:
     * name: string to display label of legend element
     * color: hex color of legend element
     * */
    legend: PropTypes.array,
    /**
     * Bool to display chart options
     * */
    showChartOptions: PropTypes.bool,
    /**
     * Array of objects
     * Each object contains the following attributes:
     * name: String to differenciate attribute
     * label: String to display attribute label
     */
    chartOptions: PropTypes.array,
    /**
     * String to choose which chart option is selected
     */
    selectedChartOption: PropTypes.string,
    /**
     * Object containing two attributtes: view | options.
     * Each attribute is an array of objects containing four attributes:
     * name: String to display setting label
     * icon: String to choose which icon to use
     * iconDefault: Image to show when icon === 'default'
     * iconSelected: Image to show when icon === 'selected'
     */
    settings: PropTypes.object,
    /**
     * Function to handle click on chart option
     * */
    onClickChartOption: PropTypes.func,
    /**
     * Function to handle click on settings view option
     * */
    onClickSettingsView: PropTypes.func,
    /**
     * Function to handle click on settings normal option
     * */
    onClickSettingsOptions: PropTypes.func,
    /**
     * Function to handle mouse entering the component
     */
    onMouseEnterContainer: PropTypes.func,
    /**
     * Function to handle mouse leaving the component
     */
    onMouseLeaveContainer: PropTypes.func,
    /**
     * Function to handle mouse leaving the component
     */
    onClickContainer: PropTypes.func
};
