import React from 'react';
import styles from './Billing.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { BillingDetails } from './components/billing-details/BillingDetails';
import { PaymentMethod } from './components/payment-method/PaymentMethod';
import { BillingCycle } from './components/billing-cycle/BillingCycle';
import { InvoiceHistory } from './components/invoice-history/InvoiceHistory';

export const Billing = () => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['billing-container']}>
            <div className="row w-100 mx-0 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-between align-items-center">
                    {t('pages:billing.title')}
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <BillingDetails />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <PaymentMethod />
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <BillingCycle />
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    height: '40rem',
                    marginBottom: '2rem'
                }}
            >
                <div className="col px-0 h-100">
                    <InvoiceHistory />
                </div>
            </div>
        </div>
    );
};
