import { createContext, useContext, useState } from 'react'
import { AddNewTimeseriesEntryModal } from '../components/modals/add-new-timeseries-entry-modal/add-new-timeseries-entry-modal'

const ModalContext = createContext({
    modal: '',
    setModal: () => {},
})

export function ModalProvider({ value, children }) {
    const [modal, setModal] = useState('')

    return (
        <>
            {modal === 'timeseriesEntry' && <AddNewTimeseriesEntryModal onCloseModal={() => setModal('')} />}
            <ModalContext.Provider
                value={{
                    modal,
                    setModal,
                }}
            >
                {children}
            </ModalContext.Provider>
        </>
    )
}

export function useModal() {
    return useContext(ModalContext)
}
