/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeptManagerSchema } from '../models/DeptManagerSchema';
import type { EmployeeSchema } from '../models/EmployeeSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmployeesService {
    /**
     * list all Employee types
     * @returns EmployeeSchema call successful
     * @throws ApiError
     */
    public static getApiEmployees({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: emp_no, birth_date, first_name, last_name, gender, location, hire_date, role, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'emp_no' | 'birth_date' | 'first_name' | 'last_name' | 'gender' | 'location' | 'hire_date' | 'role' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<EmployeeSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates Employee
     * @returns EmployeeSchema resource created
     * @throws ApiError
     */
    public static postApiEmployees({
        requestBody,
    }: {
        requestBody: EmployeeSchema,
    }): CancelablePromise<EmployeeSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/employees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * list all DeptManager types
     * @returns DeptManagerSchema call successful
     * @throws ApiError
     */
    public static getApiEmployeesManagers({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: emp_no, from_date, to_date.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'emp_no' | 'from_date' | 'to_date',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<DeptManagerSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/employees/managers',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
}
