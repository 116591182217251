import React from 'react';

export const productsDonutMock = {
    hoverStyle: '',
    desc: '',
    loading: false,
    title: '',
    titleColor: '#606060',
    content: '5',
    contentType: '',
    contentColor: '#06a4e7',
    contentFractionTotal: '',
    contentAux: '',
    contentAuxType: '',
    contentAuxColor: '#06a4e7',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaAttr: [
        {
            dataKey: 'exampleAttr1',
            color: '#06a4e7',
            strokeWidth: 3
        }
    ],
    categoryAxis: 'categoryAttr',
    data: {
        data1: [
            {
                key1: 'METFORMINA DCI',
                value: 5835231.58,
                color: '#FF6363',
                name: 'METFORMINA DCI',
                percentage: 0.25048971199135367
            },
            {
                key1: 'AC ACETILSA DCI',
                value: 2900907.17,
                color: '#14897C',
                name: 'AC ACETILSA DCI',
                percentage: 0.1245276029862302
            },
            {
                key1: 'CLOPIDOGREL DCI',
                value: 2396264.1,
                color: '#46D38C',
                name: 'CLOPIDOGREL DCI',
                percentage: 0.1028647271380822
            },
            {
                key1: 'TROMALYT',
                value: 2376686.16,
                color: '#FFBD77',
                name: 'TROMALYT',
                percentage: 0.10202430247202568
            },
            {
                key1: 'MONTELUCASTE DCI',
                value: 2240345.72,
                color: '#FF8900',
                name: 'MONTELUCASTE DCI',
                percentage: 0.09617159944213592
            },
            {
                key1: 'Others',
                value: 7545859.65,
                color: '#C85396',
                percentage: 0.32392205597017226,
                name: 'Others'
            }
        ]
    },
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    attr: 'salesByProductCard',
    prefix: 'salesByProductCard',
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    },
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1'
        }
    ]
};
