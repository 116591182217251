import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, Tooltip, LabelList, ResponsiveContainer, Cell, Text } from 'recharts'
import { ReactComponent as IcoForecasts } from '../../../assets/images/ico-side-2.svg'
import styles from './bar-chart-new-container.module.scss'
import { CustomBarChartLabel, TooltipFactory } from '../..'
import useCursorTracking from '../../../hooks/useCursorTracking'
import { max } from 'lodash'
import { hexToRGBA } from '../../../utils/color'
import { getRoundedValue } from '../../../utils/math'

const CustomTick = (props) => {
    let valueToShow = props.payload.value
    return (
        <Text {...props} className={styles['bar-chart-new-container__custom-tick']}>
            {valueToShow}
        </Text>
    )
}

export const BarChartNewContainer = React.memo(
    ({ attr, barAttr, categoryAxis, data, onClickBar, tooltipType, barWidth, noDataLabel, unitsOrValues, translate }) => {
        let height = data.length > 8 ? data.length * 40 : '100%'
        let barSize
        if (data.length < 4) {
            barSize = 50
        } else if (data.length < 8) {
            barSize = 30
        } else {
            barSize = 20
        }

        const maxValue = useMemo(() => {
            return data.reduce((acc, curr) => {
                return Math.max(acc, ...barAttr.map((attr) => curr[attr.dataKey]))
            }, 0)
        }, [data, barAttr])

        if (data.length !== 0) {
            return (
                <ResponsiveContainer height={height}>
                    <BarChart
                        className={`${styles['bar-chart-alternative-container']}`}
                        data={data}
                        barCategoryGap={5}
                        layout={'vertical'}
                        maxBarSize={20}
                    >
                        <defs>
                            <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stopColor="#2cd9c54d" />
                                <stop offset="41%" stopColor="#2e5bff87" />
                                <stop offset="98%" stopColor="#0ea6fa" />
                            </linearGradient>
                        </defs>
                        <Tooltip
                            content={
                                <TooltipFactory
                                    item={{
                                        type: attr || '',
                                        unitsOrValues: unitsOrValues || undefined,
                                        translate: translate || undefined,
                                        id: 0,
                                    }}
                                />
                            }
                            wrapperStyle={{
                                zIndex: 1000,
                                outline: 'none',
                            }}
                            cursor={false}
                        />
                        <XAxis type="number" hide />
                        <YAxis type="category" dataKey={categoryAxis} tick={<CustomTick />} />
                        {barAttr.map((attr, index) => {
                            return (
                                <Bar key={index} dataKey={attr.dataKey} stackId={1}>
                                    {data.map((entry, index) => {
                                        return (
                                            <Cell
                                                key={index}
                                                radius={
                                                    attr.isMain && entry['remaining'] === 0
                                                        ? [100, 100, 100, 100]
                                                        : attr.isMain
                                                        ? [100, 0, 0, 100]
                                                        : [0, 100, 100, 0]
                                                }
                                                style={{
                                                    filter: `drop-shadow(0px 0px 2px ${hexToRGBA('#4c4c4c', 0.3)})`,
                                                }}
                                                fill={
                                                    entry[attr.dataKey] === maxValue && attr.isMain
                                                        ? 'url(#gradient)'
                                                        : attr.isMain
                                                        ? '#B6B6B6'
                                                        : '#fff'
                                                }
                                            />
                                        )
                                    })}
                                </Bar>
                            )
                        })}
                    </BarChart>
                </ResponsiveContainer>
            )
        } else {
            return (
                <div className={`${styles['bar-chart-graph-container']} ${styles['bar-chart-graph-container--no-data']}`}>
                    <IcoForecasts />
                    <span className="my-4">{noDataLabel}</span>
                </div>
            )
        }
    }
)

BarChartNewContainer.propTypes = {}
