import React, { useCallback, useMemo } from 'react'
import icoEdit from '../../../assets/images/ico-edit.svg'
import icoDelete from '../../../assets/images/ico-delete.svg'
import icoManageMembers from '../../../assets/images/icoManageMembers.svg'
import icoTeamCard from '../../../assets/images/icoTeamCard.svg'
import { FullTextPopup, Scrollable, TypeInput } from '../../../components'
import styles from './YourTeamsSelection.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'
import { BreadCrumbs } from '../../../components/formatting/breadcrumbs/BreadCrumbs'
import { DetailsEditButton } from '../../../components/buttons/details-edit-button/DetailsEditButton'
import { ActionsButtonNew } from '../../../components/buttons/actions-button-new/actions-button-new'
import { ManageTeamMembersModal } from '../../../components/modals/manage-team-members-modal/ManageTeamMembersModal'
import { RemoveOrgTeamModal } from '../../../components/modals/remove-org-team-modal/remove-org-team-modal'
import { TeamDetailsMember } from '../../../components/teams/team-details-member/TeamDetailsMember'
import { useLocation, useParams } from 'react-router-dom'
import { TeamMemberList } from '../../../components/dashboard-components/team-member-list'

const mockTeamData = [
    {
        id: 1,
        title: 'Team 1',
        members: [
            {
                id: 1,
                name: 'Member 1',
            },
            {
                id: 2,
                name: 'Member 2',
            },
            {
                id: 3,
                name: 'Member 3',
            },
            {
                id: 4,
                name: 'Member 4',
            },
            {
                id: 5,
                name: 'Member 5',
            },
        ],
        action: {
            id: 1,
            open: false,
            options: [
                {
                    name: 'edit',
                    icon: icoEdit,
                },
                {
                    name: 'manageMembers',
                    icon: icoManageMembers,
                },
                {
                    name: 'remove',
                    icon: icoDelete,
                    isRed: true,
                },
            ],
            reference: React.createRef(),
        },
    },
]

const AddressBlock = ({ title, content, isEdit, input, onChangeInput }) => (
    <div className="col-auto px-0" style={{ marginRight: '2rem' }}>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span className={styles['selected-team-details-container__subTitle']}>{title}</span>
            </div>
        </div>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span className={styles['selected-team-details-container__content']}>
                    {isEdit && <TypeInput hideLabel onChangeInput={onChangeInput} isNumber {...input} />}
                    {!isEdit && (
                        <FullTextPopup text={content}>
                            <span className={`${styles['selected-team-details-container__team-text__content']}`}>{content || '-'}</span>
                        </FullTextPopup>
                    )}
                </span>
            </div>
        </div>
    </div>
)

export const YourTeamsSelection = ({ onToggleModal }) => {
    const location = useLocation()
    const { isEdit } = location.state || {}
    const { teamSelected } = location.state || {}
    const title = teamSelected.teamName
    const teamMembers = teamSelected.members
    const action = teamSelected.action
    const { t } = useCustomTranslation()
    const [isEditMode, setIsEditMode] = React.useState(isEdit)
    const [manageMembersModal, setManageMembersModal] = React.useState(false)
    const [deleteTeamModal, setDeleteTeamModal] = React.useState(false)
    const {
        value,
        setValue: setValueInputs,
        replaceAll,
    } = useMultipleInputs({
        address: {
            name: 'address',
            path: 'address',
            type: 'type',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        },
    })

    const crumbsMemo = useMemo(() => {
        return [
            {
                url: '/teams',
                label: t('pages:yourTeams.title'),
            },
            {
                isTitle: true,
                label: title,
            },
        ]
    }, [t, title])

    const handleClickOption = useCallback((option, teamId) => {
        if (option === 'edit') {
            setIsEditMode(true)
        } else if (option === 'remove') {
            setDeleteTeamModal(true)
        } else if (option === 'manageMembers') {
            setManageMembersModal(true)
        }
    }, [])

    const handleCancelEditMode = useCallback(() => {
        setIsEditMode(false)
    }, [])

    const handleSaveEditMode = useCallback(() => {
        setIsEditMode(false)
    }, [])

    return (
        <div className="w-100 h-100" style={{ padding: '0 2rem 2rem 2rem' }}>
            {manageMembersModal && (
                <ManageTeamMembersModal
                    onToggleModal={() => {
                        setManageMembersModal(false)
                    }}
                />
            )}
            {deleteTeamModal && (
                <RemoveOrgTeamModal
                    teamId={1}
                    onCloseModal={() => {
                        setDeleteTeamModal(false)
                    }}
                />
            )}
            <BreadCrumbs crumbs={crumbsMemo} />
            <div className="row w-100 mx-0 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-between align-items-center">{t('pages:yourTeams.title')}</div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    marginBottom: '2rem',
                }}
            >
                <div className="col px-0">
                    <span className={styles['selected-team-details-container__sub-title']}>{t('pages:yourTeams.sub-title')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div className={styles['selected-team-details-container']}>
                        <div className="row mx-0 w-100 d-flex justify-content-between align-items-center" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                <img src={icoTeamCard} alt="Team Card" className={styles['selected-team-details-container__icon']} />
                                <span className={styles['selected-team-details-container__title']}>{title}</span>
                            </div>
                            {isEditMode && (
                                <div className="col-auto px-0" style={{ marginRight: '2rem' }}>
                                    <DetailsEditButton onCancelEditMode={handleCancelEditMode} onSaveEditMode={handleSaveEditMode} />
                                </div>
                            )}
                            {!isEditMode && (
                                <div className="col-auto px-0">
                                    <ActionsButtonNew actions={action} onClickOption={(args) => handleClickOption(args, 1)} />
                                </div>
                            )}
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <div className="row mx-0 w-100">
                                    <AddressBlock
                                        title="Manager"
                                        content={teamSelected.teamManager}
                                        isEdit={isEditMode}
                                        input={value.address}
                                        onChangeInput={setValueInputs}
                                    />
                                    <AddressBlock
                                        title="Members"
                                        content={teamSelected.teamMembers}
                                        isEdit={isEditMode}
                                        input={value.address}
                                        onChangeInput={setValueInputs}
                                    />
                                    <AddressBlock
                                        title="Revenue"
                                        content={teamSelected.revenue}
                                        isEdit={isEditMode}
                                        input={value.address}
                                        onChangeInput={setValueInputs}
                                    />
                                    <AddressBlock
                                        title="Customers"
                                        content={teamSelected.customers}
                                        isEdit={isEditMode}
                                        input={value.address}
                                        onChangeInput={setValueInputs}
                                    />
                                </div>
                            </div>
                            <div className="col-auto px-0" style={{ width: '10rem' }}></div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <span className={styles['selected-team-details-container__title']}>Team Members</span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 flex-grow-1"
                            style={{
                                minHeight: 0,
                                height: '60rem',
                            }}
                        >
                            <div className="col px-0 h-100">
                                <TeamMemberList members={teamMembers} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
