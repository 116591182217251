import { createContext, useContext } from 'react'

const FilterInputsContext = createContext({
    filterInputs: '',
    setFilterInputs: () => {},
})

export function FilterInputsProvider({ value, children }) {
    return (
        <FilterInputsContext.Provider value={value}>
            {children}
        </FilterInputsContext.Provider>
    )
}

export function useFilterInputs() {
    return useContext(FilterInputsContext)
}
