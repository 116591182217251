import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { regionGeoMarketShareMock } from './mock'
import { useFilter } from '../../../providers/filter-context'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useGeo } from '../../../hooks/useGeo'
import { ContentContainer, Loader, MarkerMap, Spinner, Table } from '../..'
import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component'
import { RegionsService } from '../../../temp/test'
import styles from './region-geo-market-share.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { DynamicSizeMap } from '../../charts/dynamic-size-map/dynamic-size-map'

export const RegionGeoMarketShare = React.memo(({ config, isExample }) => {
    const { filter } = useFilter()
    const isMounted = useIsMounted()
    const { i18n } = useTranslation()
    const { t } = useCustomTranslation()

    const { value: data, toggleComponentSettings, setLoading, setValue } = useGeo(regionGeoMarketShareMock)

    // Helper function to interpolate between two colors
    function interpolateColor(color1, color2, ratio) {
        const r1 = parseInt(color1.substring(1, 3), 16)
        const g1 = parseInt(color1.substring(3, 5), 16)
        const b1 = parseInt(color1.substring(5, 7), 16)

        const r2 = parseInt(color2.substring(1, 3), 16)
        const g2 = parseInt(color2.substring(3, 5), 16)
        const b2 = parseInt(color2.substring(5, 7), 16)

        const r = Math.round(r1 + (r2 - r1) * ratio)
        const g = Math.round(g1 + (g2 - g1) * ratio)
        const b = Math.round(b1 + (b2 - b1) * ratio)

        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
    }

    // Function to find the interpolated color
    const getInterpolatedColor = useCallback((value, valueColorIntervals) => {
        // Find two closest intervals
        let lowerInterval = valueColorIntervals[0]
        let upperInterval = valueColorIntervals[valueColorIntervals.length - 1]

        for (let i = 0; i < valueColorIntervals.length - 1; i++) {
            const current = valueColorIntervals[i]
            const next = valueColorIntervals[i + 1]

            if (value >= current.value && value <= next.value) {
                lowerInterval = current
                upperInterval = next
                break
            }
        }

        // Calculate the ratio for interpolation
        const ratio = (value - lowerInterval.value) / (upperInterval.value - lowerInterval.value)

        // Interpolate the color
        return interpolateColor(lowerInterval.color, upperInterval.color, ratio)
    }, [])

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(regionGeoMarketShareMock)
            if (!isExample) {
                try {
                    if (isMounted.current) {
                        setLoading(true)
                    }
                    const dataBackIndexes = await RegionsService.getApiRegionsIndexesAll({
                        level: 1,
                    })
                    dataClone.dataList = []
                    const minMarketShare = Math.min(...dataBackIndexes.map((region) => region.market_share))
                    const maxMarketShare = Math.max(...dataBackIndexes.map((region) => region.market_share))
                    const valueColorIntervals = [
                        {
                            value: minMarketShare,
                            color: '#006BA6',
                        },
                        {
                            value: minMarketShare + (maxMarketShare - minMarketShare) / 4,
                            color: '#0496FF',
                        },
                        {
                            value: minMarketShare + (maxMarketShare - minMarketShare) / 2,
                            color: '#FFBC42',
                        },

                        {
                            value: minMarketShare + ((maxMarketShare - minMarketShare) * 3) / 4,
                            color: '#D81159',
                        },
                        {
                            value: maxMarketShare,
                            color: '#8F2D56',
                        },
                    ]
                    const PointLayer1 = {
                        id: 'point-inner',
                        type: 'circle',
                        source: 'earthquakes', // Replace with your actual source
                        paint: {
                            // Dynamic color based on the market_share attribute
                            'circle-color': [
                                'interpolate',
                                ['linear'],
                                ['get', 'market_share'], // Use the market_share attribute
                                valueColorIntervals[0].value,
                                valueColorIntervals[0].color, // Color for minimum market_share
                                valueColorIntervals[1].value,
                                valueColorIntervals[1].color, // Color for maximum market_share
                                valueColorIntervals[2].value,
                                valueColorIntervals[2].color, // Color for maximum market_share
                                valueColorIntervals[3].value,
                                valueColorIntervals[3].color, // Color for maximum market_share
                                valueColorIntervals[4].value,
                                valueColorIntervals[4].color, // Color for maximum market_share
                            ],
                            // Dynamic size for the inner circle
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'], // Use the zoom level to adjust size dynamically
                                0,
                                [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'market_share'],
                                    minMarketShare,
                                    1, // Small size at minimum market_share
                                    maxMarketShare,
                                    4, // Larger size at maximum market_share
                                ],
                                12,
                                [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'market_share'],
                                    minMarketShare,
                                    2, // Medium size at minimum market_share
                                    maxMarketShare,
                                    5, // Larger size at maximum market_share
                                ],
                                22,
                                [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'market_share'],
                                    minMarketShare,
                                    3, // Larger size at minimum market_share
                                    maxMarketShare,
                                    6, // Maximum size at maximum market_share
                                ],
                            ],
                            'circle-opacity': [
                                'interpolate',
                                ['linear'],
                                ['get', 'market_share'],
                                minMarketShare,
                                0.3, // Lower opacity at minimum market_share
                                maxMarketShare,
                                1, // Full opacity at maximum market_share
                            ],
                            'circle-stroke-color': '#fff',
                        },
                    }

                    dataClone.legend = [
                        {
                            name: `0 - ${Math.round(valueColorIntervals[0].value)}%`,
                            color: valueColorIntervals[0].color,
                            translated: true,
                        },
                        {
                            name: `${Math.round(valueColorIntervals[0].value)} - ${Math.round(valueColorIntervals[1].value)}%`,
                            color: valueColorIntervals[1].color,
                            translated: true,
                        },
                        {
                            name: `${Math.round(valueColorIntervals[1].value)} - ${Math.round(valueColorIntervals[2].value)}%`,
                            color: valueColorIntervals[2].color,
                            translated: true,
                        },
                        {
                            name: `${Math.round(valueColorIntervals[2].value)} - ${Math.round(valueColorIntervals[3].value)}%`,
                            color: valueColorIntervals[3].color,
                            translated: true,
                        },
                        {
                            name: `${Math.round(valueColorIntervals[3].value)} - ${Math.round(valueColorIntervals[4].value)}%`,
                            color: valueColorIntervals[4].color,
                            translated: true,
                        },
                    ]
                    verifyComponent(dataClone)
                    dataClone.data = {
                        type: 'FeatureCollection',
                        features: dataBackIndexes.map((region) => {
                            return {
                                type: 'Feature',
                                properties: {
                                    color: getInterpolatedColor(region.market_share, valueColorIntervals),
                                    name: region.region.name,
                                    market_share: region.market_share,
                                    lat: region.region.lat,
                                    lon: region.region.lon,
                                },
                                geometry: {
                                    type: 'Point',
                                    coordinates: [region.region.lon, region.region.lat],
                                },
                            }
                        }),
                    }
                    const PointLayer2 = {
                        id: 'point-outer',
                        type: 'circle',
                        source: 'earthquakes', // Replace with your actual source
                        paint: {
                            // Dynamic color based on the market_share attribute
                            'circle-color': [
                                'interpolate',
                                ['linear'],
                                ['get', 'market_share'], // Use the market_share attribute
                                valueColorIntervals[0].value,
                                valueColorIntervals[0].color, // Color for minimum market_share
                                valueColorIntervals[1].value,
                                valueColorIntervals[1].color, // Color for maximum market_share
                                valueColorIntervals[2].value,
                                valueColorIntervals[2].color, // Color for maximum market_share
                                valueColorIntervals[3].value,
                                valueColorIntervals[3].color, // Color for maximum market_share
                                valueColorIntervals[4].value,
                                valueColorIntervals[4].color, // Color for maximum market_share
                            ],
                            // Dynamic size for the outer circle
                            'circle-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'], // Use the zoom level to adjust size dynamically
                                0,
                                [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'market_share'],
                                    minMarketShare,
                                    4, // Larger size at minimum market_share (outer circle larger than inner)
                                    maxMarketShare,
                                    15, // Even larger size at maximum market_share
                                ],
                                12,
                                [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'market_share'],
                                    minMarketShare,
                                    5, // Medium size at minimum market_share (outer circle larger than inner)
                                    maxMarketShare,
                                    25, // Even larger size at maximum market_share
                                ],
                                22,
                                [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'market_share'],
                                    minMarketShare,
                                    6, // Larger size at minimum market_share
                                    maxMarketShare,
                                    40, // Maximum size at maximum market_share (outer circle larger than inner)
                                ],
                            ],
                            'circle-opacity': [
                                'interpolate',
                                ['linear'],
                                ['get', 'market_share'],
                                minMarketShare,
                                0, // Lower opacity at minimum market_share
                                maxMarketShare,
                                0.3, // Full opacity at maximum market_share
                            ],
                        },
                    }

                    dataClone.layersProp = [PointLayer1, PointLayer2]
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            } else {
                dataClone.dataList = []
                dataClone.legend = [
                    {
                        name: '0 - 20%',
                        color: '#0080FF',
                        translated: true,
                    },
                    {
                        name: '20 - 40%',
                        color: '#E57E7E',
                        translated: true,
                    },
                    {
                        name: '40 - 60%',
                        color: '#CC5151',
                        translated: true,
                    },
                    {
                        name: '60 - 80%',
                        color: '#B22C2C',
                        translated: true,
                    },
                    {
                        name: '80 - 100%',
                        color: '#FF7F00',
                        translated: true,
                    },
                ]

                setValue(dataClone)
            }
        }
        fill()
    }, [filter, isMounted, setLoading, setValue, t, config, isExample, getInterpolatedColor])

    const handleSelectSettingsOption = useCallback(() => {
        if (isMounted.current) toggleComponentSettings()
    }, [isMounted, toggleComponentSettings])

    return (
        <ContentContainer
            attr={data.attr}
            titleText={t(`components:${data.attr}.title`)}
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
            onClickSettingsView={handleSelectSettingsOption}
            onCLickSettintsSelected={handleSelectSettingsOption}
            onCloseSettings={toggleComponentSettings}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <div className={`${styles['region-geo-market-share-container']}`}>
                    <div className="row mx-0 w-100 h-100">
                        <div className="col px-0">
                            <div className={`${styles['region-geo-market-share-container__map']}`}>
                                <DynamicSizeMap {...data} customScale={10} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ContentContainer>
    )
})
