import styles from './edit-options.module.scss'

import React from 'react'
import PropTypes from 'prop-types'
import icoSettingsComponent from '../../../assets/images/ico-settings-component.svg'
import { DragHandle } from '../drag-handle/drag-handle'
import { useAuxBar } from '../../../providers/aux-bar-context'
import { useNavBar } from '../../../providers/nav-bar-context'
import { useLocation } from 'react-router-dom'
import { useHighlightedChart } from '../../../providers/highlighted-chart-context'
import { ActionBar } from '../../actions/action-bar/action-bar'
import { useTranslation } from 'react-i18next'
import icoFilterComponent from '../../../assets/images/icoFilterComponent.svg'
import icoDeleteComponent from '../../../assets/images/icoDeleteComponent.svg'

/**
 * Set of icons containing the management of single library components
 */

export const EditOptions = React.memo(({ type, config, onDeleteChart, onHighlightChart }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { selectedAvailableOption, setSelectedAvailableOption } = useAuxBar()
    const { toggleAuxBar } = useNavBar()
    const { highlightedChart, setHighlightedChart } = useHighlightedChart()
    const [isHovered, setIsHovered] = React.useState(false)
    return (
        <div className={styles['edit-options']} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className={styles['actions-container']}>
                {isHovered && location.pathname !== '/dashboard' && (
                    <ActionBar
                        actions={
                            [
                                {
                                    text: t('buttons:filter'),
                                    icon: icoFilterComponent,
                                    onClick: () => {
                                        if (onHighlightChart) {
                                            onHighlightChart()
                                        }
                                        setSelectedAvailableOption(`componentFilter_${type}`)
                                        toggleAuxBar(true)
                                    },
                                },
                                {
                                    text: t('buttons:delete'),
                                    icon: icoDeleteComponent,
                                    onClick: () => {
                                        if (selectedAvailableOption === 'componentFilter_' + type) {
                                            setSelectedAvailableOption('')
                                            toggleAuxBar(false)
                                        }
                                        if (highlightedChart === type) {
                                            setHighlightedChart(undefined)
                                        }
                                        onDeleteChart('', type.split('-')[0], config.additionalInfo)
                                    },
                                },
                            ]

                            // : [
                            //       {
                            //           text: t('buttons:filter'),
                            //           icon: icoSettingsComponent,
                            //           onClick: () => {
                            //               if (onHighlightChart) {
                            //                   onHighlightChart();
                            //               }
                            //               setSelectedAvailableOption(
                            //                   `componentFilter_${type}`
                            //               );
                            //               toggleAuxBar(true);
                            //           }
                            //       }
                            //   ]
                        }
                    />
                )}
            </div>
            <DragHandle />
        </div>
    )
})

EditOptions.propTypes = {
    /**
     * Labels the component
     */
    name: PropTypes.string,
    /**
     * Decides what image to use
     */
    page: PropTypes.string,
    /**
     * Propagates selection of template
     */
    onChooseTemplate: PropTypes.func,
    /**
     * Propagates preview of template
     */
    onPreviewTemplate: PropTypes.func,
}
