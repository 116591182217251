import React from 'react';
import { TypeInput } from '../type-input/type-input';
import { SelectInput } from '../select-input/select-input';
import { SelectMultipleInput } from '../select-multiple-input/select-multiple-input';
import { CheckboxInput } from '../checkbox-input/checkbox-input';
import { RadioInput } from '../radio-input/radio-input';
import { DateInput } from '../date-input/date-input';
import { CheckboxInputNew } from '../checkbox-input-new/checkbox-input-new';
import { DateInputNew } from '../date-input-new/date-input-new';
import { SelectLabelInput } from '../select-label-input/select-label-input';

export const InputFactory = React.memo((props) => {
    switch (props.type) {
        case 'type':
            return <TypeInput {...props} />;
        case 'number':
            return <TypeInput {...props} isNumber />;
        case 'selectLabel':
            return <SelectLabelInput {...props} />;
        case 'selectSingle':
            return <SelectInput {...props} />;
        case 'selectMultiple':
            return <SelectMultipleInput {...props} />;
        case 'radio':
            return <RadioInput {...props} />;
        case 'checkbox':
            return <CheckboxInputNew {...props} />;
        case 'date':
            return <DateInput {...props} />;
        case 'datenew':
            return <DateInputNew {...props} />;
    }
    return null;
});
