import styles from './client-information.module.scss';

import _ from 'lodash';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { DTablesService, PointOfSaleService } from '../../../temp/test';
import componentConfig from '../../../configs/componentLibraryConf.json';
import { clientInformationMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { ContentContainer, Loader, Spinner } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays general information about a client
 */

export const ClientInformation = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(clientInformationMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(clientInformationMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        if (type) dataClone.attr = type;
                        let photoResult;
                        if (
                            filter &&
                            filter.clientId.constructor === String &&
                            filter.clientId !== '' &&
                            config.filter.includes('clientId')
                        ) {
                            photoResult =
                                await PointOfSaleService.getApiPosPhoto({
                                    posId: filter.clientId
                                });
                        }
                        if (photoResult) {
                            dataClone.image = photoResult.photo_url;
                            dataClone.loading = false;
                            dataClone.noData = false;
                        } else {
                            dataClone.loading = false;
                            dataClone.noData = true;
                        }
                        let clientOverviewData;
                        if (
                            filter &&
                            filter.clientId.constructor === String &&
                            filter.clientId !== '' &&
                            config.filter.includes('clientId')
                        ) {
                            clientOverviewData =
                                await PointOfSaleService.getApiPos1({
                                    posId: filter.clientId
                                });
                        }
                        if (clientOverviewData) {
                            dataClone.name = clientOverviewData.name
                                ? clientOverviewData.name
                                : '-';
                            dataClone.comercialName = '-';
                            let subTitle = '';
                            if (clientOverviewData.addr_street)
                                subTitle += clientOverviewData.addr_street;
                            if (clientOverviewData.addr_door)
                                subTitle +=
                                    ' , ' + clientOverviewData.addr_door;
                            if (clientOverviewData.addr_zip_code)
                                subTitle +=
                                    ' , ' + clientOverviewData.addr_zip_code;
                            if (subTitle === '') subTitle = '-';
                            dataClone.subTitle = subTitle;
                            dataClone.clientId = clientOverviewData.client_id
                                ? clientOverviewData.client_id
                                : '-';
                            dataClone.vendorId = clientOverviewData.vendor_id
                                ? clientOverviewData.vendor_id
                                : '-';
                            dataClone.location = clientOverviewData.addr_city
                                ? clientOverviewData.addr_city
                                : '-';
                            let contactName = '';
                            if (clientOverviewData.client.first_name)
                                contactName +=
                                    clientOverviewData.client.first_name;
                            if (clientOverviewData.client.last_name)
                                contactName +=
                                    ' ' + clientOverviewData.client.last_name;
                            if (contactName === '') contactName = '-';
                            dataClone.contactName = contactName;
                            dataClone.phone = clientOverviewData.client
                                .mobile_phone
                                ? clientOverviewData.client.mobile_phone
                                : '-';
                            dataClone.email = clientOverviewData.client.email
                                ? clientOverviewData.client.email
                                : '-';
                            dataClone.open = true;
                            dataClone.loading = false;
                            dataClone.noData = false;
                        } else {
                            dataClone.loading = false;
                            dataClone.noData = true;
                        }
                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) setLoading(false);
                        let additionalData = [];
                        if (
                            config.additionalInfo &&
                            config.additionalInfo.length !== 0
                        ) {
                            additionalData =
                                await DTablesService.getApiBackofficeAdminDtablesColumns(
                                    {
                                        columns: config.additionalInfo,
                                        ids:
                                            filter &&
                                            filter.clientId.constructor ===
                                                String &&
                                            filter.clientId !== '' &&
                                            config.filter.includes('clientId')
                                                ? [filter.clientId]
                                                : []
                                    }
                                );
                        }
                        if (additionalData.length !== 0) {
                            config.additionalInfo.map((el2) => {
                                dataClone.customInfo.push(el2);
                                dataClone[el2] = additionalData[0][el2];
                                return el2;
                            });
                        }
                        if (isMounted.current) setValue(dataClone);
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setValue,
            setLoading,
            type,
            t
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`col-12 px-0 h-inherit ${styles['client-information-card-container']}`}
                        >
                            <div className="row mx-0 w-100 h-100">
                                <div className="col px-0 h-100 justify-content-center align-items-center">
                                    <div className="row mx-0 h-100 w-100">
                                        {data.image && !data.noData && (
                                            <a
                                                href={data.image}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                referrerPolicy="no-referrer"
                                                className={`col-4 px-0 ${styles['client-information-card-container__image-container']}`}
                                            >
                                                <img
                                                    loading="lazy"
                                                    src={data.image}
                                                    alt="client"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </a>
                                        )}
                                        {data.noData && (
                                            <>
                                                <div
                                                    className={`col px-0 h-100 d-flex justify-content-center align-items-center`}
                                                >
                                                    <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                                        <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                                            <span
                                                                className={`${styles['no-data-text']}`}
                                                            >
                                                                {t(
                                                                    `common:noData`
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {!data.noData && (
                                            <div
                                                className={`col px-0 h-100 ms-3 ${styles['client-information-card-container__desc-container']} d-flex flex-column justify-content-between`}
                                            >
                                                <div
                                                    className="row mx-0 w-100 "
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__title']}`}
                                                        >
                                                            {data.name
                                                                ? data.name
                                                                : ''}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {data.subTitle
                                                                ? data.subTitle
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 "
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.clientId`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                        >
                                                            {data.clientId
                                                                ? data.clientId
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.vendorId`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                        >
                                                            {data.vendorId
                                                                ? data.vendorId
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.location`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                        >
                                                            {data.location
                                                                ? data.location
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 "
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.contactName`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                        >
                                                            {data.contactName
                                                                ? data.contactName
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.phone`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                        >
                                                            {data.phone
                                                                ? data.phone
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0 mx-2 overflow-hidden h-100">
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.email`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                        >
                                                            {data.email
                                                                ? data.email
                                                                : ''}
                                                        </h1>
                                                    </div>
                                                </div>
                                                {data.customInfo.length !==
                                                    0 && (
                                                    <div
                                                        className="row mx-0 w-100 "
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        {data.customInfo.map(
                                                            (el, index) => {
                                                                if (
                                                                    index <=
                                                                    componentConfig[
                                                                        data
                                                                            .attr
                                                                    ]
                                                                        .dTableInfoLimit -
                                                                        1
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="col px-0 mx-2 overflow-hidden h-100"
                                                                        >
                                                                            <h1
                                                                                className={`${styles['client-information-card-container__desc-container__sub-title']}`}
                                                                            >
                                                                                {
                                                                                    el
                                                                                }
                                                                            </h1>
                                                                            <h1
                                                                                className={`${styles['client-information-card-container__desc-container__sub-sub-content']}`}
                                                                            >
                                                                                {data[
                                                                                    el
                                                                                ]
                                                                                    ? data[
                                                                                          el
                                                                                      ]
                                                                                    : ''}
                                                                            </h1>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ClientInformation.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
