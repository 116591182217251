import React from 'react'
import styles from './filter-tag.module.scss'
import { useSegmentFilters } from '../../../providers/segment-filters-context'

export const FilterTag = React.memo(({ index, children }) => {
    const { removeSegmentFilter } = useSegmentFilters()
    return (
        <div
            className={`${styles['filter-tag-container']}`}
            onClick={
                index !== undefined
                    ? () => removeSegmentFilter(index)
                    : undefined
            }
        >
            {children}
        </div>
    )
})
