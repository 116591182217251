/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserLoginSchema } from '../models/UserLoginSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LoginsService {
    /**
     * return login logs
     * @returns UserLoginSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeLogins({
        userUuid,
    }: {
        /**
         * user identifier.
         */
        userUuid?: string,
    }): CancelablePromise<Array<UserLoginSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/logins',
            query: {
                'user_uuid': userUuid,
            },
        });
    }
    /**
     * Returns a logins by day.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiBackofficeLoginsDaily({
        userUuid,
        minDate,
    }: {
        /**
         * user account
         */
        userUuid?: string,
        /**
         * minimum date to filter results
         */
        minDate?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/logins/daily',
            query: {
                'user_uuid': userUuid,
                'min_date': minDate,
            },
        });
    }
    /**
     * return login count per day, typeseries format
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiBackofficeLoginsMonthly({
        userUuid,
    }: {
        /**
         * user identifier.
         */
        userUuid?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/logins/monthly',
            query: {
                'user_uuid': userUuid,
            },
        });
    }
}
