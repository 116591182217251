import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../../providers/current-user-context'
import { removeFromLS } from '../../../utils/localStorage'
import { logout } from '../../../utils/authService'

/**
 * Dashboard allowing users to log out of the app
 */

const Logout = React.memo(() => {
    const { setCurrentUser } = useCurrentUser()
    const navigate = useNavigate()

    useEffect(() => {
        logout()
        removeFromLS('theme')
        removeFromLS('extra-nav-options')
        removeFromLS('extra-back-office-options')
        removeFromLS('extra-administration-options')
        setCurrentUser('')
        navigate('/')
    }, [navigate, setCurrentUser])

    return null
})

export default Logout

Logout.propTypes = {}
