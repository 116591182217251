import React from 'react';
import PropTypes from 'prop-types';
import styles from './pagination.module.scss';
import { ReactComponent as IcoArrowPagination } from '../../../assets/images/icoPaginationArrow.svg';

export const Pagination = React.memo(
    ({ itemsCount, pageSize, currentPage, onPageChange }) => {
        const pageCount = Math.ceil(itemsCount / pageSize);
        if (pageCount === 1) return null;

        const rangeStart = Math.max(currentPage - 2, 1);
        const rangeEnd = Math.min(currentPage + 2, pageCount);
        const pages = Array.from(
            { length: rangeEnd - rangeStart + 1 },
            (_, i) => rangeStart + i
        );

        const handlePrevPageClick = () => {
            if (currentPage > 1) onPageChange(currentPage - 1);
        };

        const handleNextPageClick = () => {
            if (currentPage < pageCount) onPageChange(currentPage + 1);
        };

        return (
            <div className={styles['pagination-container']}>
                <IcoArrowPagination
                    className={`${
                        styles['pagination-container__page--arrow']
                    } ${styles['pagination-container__page--arrow--left']} ${
                        currentPage === 1 ? styles['disabled'] : ''
                    }`}
                    onClick={handlePrevPageClick}
                />
                {pages.map((page) => (
                    <div
                        key={page}
                        className={`${styles['pagination-container__page']} ${
                            currentPage === page ? styles['current'] : ''
                        }`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </div>
                ))}
                <IcoArrowPagination
                    className={`${
                        styles['pagination-container__page--arrow']
                    } ${styles['pagination-container__page--arrow--right']} ${
                        currentPage === pageCount ? styles['disabled'] : ''
                    }`}
                    onClick={handleNextPageClick}
                />
            </div>
        );
    }
);

Pagination.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
};
