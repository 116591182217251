import React, { useCallback } from 'react';
import styles from './file-import.module.scss';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

export const FileImport = React.memo(
    ({ onHandleCsvFile, onHandleMboxFile, onHandlePstFile }) => {
        const { t } = useCustomTranslation();

        const onDrop = useCallback(
            (files) => {
                // for getting only extension
                var fileName = files[0].name;
                var fileExtension = fileName.split('.').pop();
                if (fileExtension === 'csv') {
                    onHandleCsvFile(files[0]);
                } else if (fileExtension === 'mbox') {
                    onHandleMboxFile(files[0]);
                } else if (fileExtension === 'pst') {
                    onHandlePstFile(files[0]);
                }
            },
            [onHandleCsvFile, onHandleMboxFile, onHandlePstFile]
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop,
            noClick: true
        });
        const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
            useDropzone({ onDrop, noDrag: true });
        return (
            <div
                className={`${styles['file-import-container']}`}
                {...getRootProps()}
                style={
                    isDragActive
                        ? {
                              backgroundImage: `url(${creationOptionBackground})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              border: '1px solid #2c3c8d'
                          }
                        : {
                              backgroundImage: `url(${creationOptionBackground})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              border: '1px solid transparent'
                          }
                }
            >
                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                    <div className="col px-0">
                        <span
                            className={`${styles['file-import-container__text']}`}
                        >
                            {t(`components:sentiment-import-data.draganddrop`)}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-center align-items-center">
                        <div
                            className={`${styles['file-import-container__separator']}`}
                        ></div>
                        <span
                            className={`${styles['file-import-container__text-light']}`}
                        >
                            {t(`components:sentiment-import-data.or`)}
                        </span>
                        <div
                            className={`${styles['file-import-container__separator']}`}
                        ></div>
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col px-0">
                        <label
                            {...getRootProps2()}
                            //htmlFor={`file-${addedChannelId}`}
                            className={`${styles['file-import-container__button']}`}
                        >
                            <span
                                className={`${styles['file-import-container__button__text']}`}
                            >
                                {t(`buttons:clicktobrowse`)}
                            </span>
                            <input {...getInputProps2()}></input>
                        </label>
                        <input {...getInputProps()}></input>
                    </div>
                </div>
            </div>
        );
    }
);
