import React from 'react';
import { getValueInString } from '../../../../../utils/unit';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const LoginsHistoryTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    if (props.id !== '') {
        return (
            <TooltipContainer {...props} color={props.payload[props.id].color}>
                <TooltipHeaderRow
                    title={t('tooltips:header.logins')}
                    value={
                        <span>
                            {
                                props.payload[props.id].payload[
                                    props.categoryAxis
                                ]
                            }
                        </span>
                    }
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.count')}
                    value={`${getValueInString(
                        parseFloat(
                            props.payload[props.id].payload[props.dataKey]
                        ),
                        props.unitsOrValues
                    )}`}
                    color={props.payload[props.id].color}
                />
            </TooltipContainer>
        );
    } else return null;
});
