import React from 'react';
import styles from './spinner.module.scss';

export const Spinner = React.memo(() => {
    return (
        <div className={`${styles['spinner-container']}`}>
            <div className={`${styles['spinner-container__container']}`}>
                <div
                    className={`${styles['spinner-container__container__spinner']}`}
                ></div>
            </div>
        </div>
    );
});
