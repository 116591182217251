import React from 'react'
import styles from './priority-table-column.module.scss'

export const PriorityTableColumn = React.memo(({ el, column }) => {
    return (
        <div className={`${styles['priority-table-column-container']}`}>
            <div
                className={`${styles['priority-table-column-container__priority-container']}`}
                style={{
                    backgroundColor: el[column.path].color,
                }}
            >
                <span
                    className={`${styles['priority-table-column-container__priority-container__content']}`}
                >
                    {el[column.path].value}
                </span>
            </div>
        </div>
    )
})
