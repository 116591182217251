import styles from './user-listing.module.scss';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import imagePlaceholder from '../../../assets/images/user-img.png';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import {
    LoaderForeground,
    LoginHistory,
    LoginList,
    ReturnButton,
    SecondaryButton,
    Spinner,
    UserInformation,
    UserList
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Dashboard containing information about users
 */

const AdministrationUserListing = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);

    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleReturn = () => {
        if (isMounted.current) setSelectedUser({});
    };

    const handleSelectUser = (el) => {
        if (isMounted.current)
            setSelectedUser({ ...el, image: imagePlaceholder });
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && _.isEmpty(selectedUser) && (
                <div
                    className={`row mx-0 w-100 ${styles['administration-user-listing-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.user-listing.title')}
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0 h-100">
                                <UserList onSelectUser={handleSelectUser} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!loading && !_.isEmpty(selectedUser) && (
                <div
                    className={`row mx-0 w-100 ${styles['administration-user-listing-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.user-listing.title')}
                            </div>
                            <SecondaryButton
                                type={'return'}
                                text={t('buttons:return')}
                                onClick={handleReturn}
                            />
                        </div>
                        <div className="row mx-0 w-100 d-flex">
                            <div className="col-4 px-0 pe-2 h-100">
                                <div
                                    className="row mx-0 w-100"
                                    style={{ height: '45rem' }}
                                >
                                    <div className="col px-0 h-100">
                                        <UserInformation
                                            usernameLabel={t(
                                                'components:userOverview.username'
                                            )}
                                            employeeIdLabel={t(
                                                'components:userOverview.employeeId'
                                            )}
                                            emailLabel={t(
                                                'components:userOverview.email'
                                            )}
                                            phoneLabel={t(
                                                'components:userOverview.phone'
                                            )}
                                            rolesLabel={t(
                                                'components:userOverview.roles'
                                            )}
                                            data={selectedUser}
                                            t={t}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-auto px-0"
                                style={{ width: '2rem' }}
                            ></div>
                            <div className="col px-0">
                                <div
                                    className={`row mx-0 ${styles['administration-user-listing-container__login-chart-container']}`}
                                    style={{ height: '45rem' }}
                                >
                                    <div className="col px-0 h-100">
                                        <LoginHistory
                                            userId={selectedUser.id}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ height: '2rem' }}
                        >
                            <div className="col-auto px-0"></div>
                        </div>
                        <div
                            className={`row mx-0 ${styles['administration-user-listing-container__login-table-container']}`}
                            style={{ height: '45rem' }}
                        >
                            <div className="col px-0 h-100">
                                <LoginList userId={selectedUser.id} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default AdministrationUserListing;

AdministrationUserListing.propTypes = {};
