/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductCategoryDefaultSchema } from '../models/ProductCategoryDefaultSchema';
import type { ProductDefaultSchema } from '../models/ProductDefaultSchema';
import type { ProductForecastDefaultSchema } from '../models/ProductForecastDefaultSchema';
import type { ProductRecommendationSchema } from '../models/ProductRecommendationSchema';
import type { StockSchema } from '../models/StockSchema';
import type { TargetSegmentDefaultSchema } from '../models/TargetSegmentDefaultSchema';
import type { TeamSchema } from '../models/TeamSchema';
import type { TimeSeriesForecastSchema } from '../models/TimeSeriesForecastSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductsService {
    /**
     * list all Product types
     * @returns ProductDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProducts({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, ext_id, title, description, price, status, image_url, producer, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'ext_id' | 'title' | 'description' | 'price' | 'status' | 'image_url' | 'producer' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ProductDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates Product
     * @returns ProductDefaultSchema resource created
     * @throws ApiError
     */
    public static postApiProducts({
        requestBody,
    }: {
        requestBody: ProductDefaultSchema,
    }): CancelablePromise<ProductDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * list all ProductCategory types
     * @returns ProductCategoryDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProductsCategories({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, parent_id, level, code, description, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'parent_id' | 'level' | 'code' | 'description' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ProductCategoryDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/categories',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * get one ProductCategory by id
     * @returns ProductCategoryDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProductsCategories1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ProductCategoryDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/categories/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * counts Product entities by filters
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiProductsCount({
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, ext_id, title, description, price, status, image_url, producer, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'ext_id' | 'title' | 'description' | 'price' | 'status' | 'image_url' | 'producer' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/count',
            query: {
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * Returns list of products with names
     * @returns ProductDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProductsFrequentlyBoughtTogether({
        productId,
    }: {
        /**
         * Product ID.
         */
        productId: number,
    }): CancelablePromise<Array<ProductDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/frequently_bought_together/{product_id}',
            path: {
                'product_id': productId,
            },
        });
    }
    /**
     * get page of Product types
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiProductsPage({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, ext_id, title, description, price, status, image_url, producer, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'ext_id' | 'title' | 'description' | 'price' | 'status' | 'image_url' | 'producer' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<{
        has_next?: boolean;
        has_prev?: boolean;
        items?: Array<ProductDefaultSchema>;
        page?: number;
        total_items?: number;
        total_pages?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/page',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * Returns the current stock for a given product
     * Returns the stock for a given product
     * @returns StockSchema successful operation
     * @throws ApiError
     */
    public static getApiProductsStocks({
        productIds,
    }: {
        /**
         * List of product ids.
         */
        productIds?: string,
    }): CancelablePromise<Array<StockSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/stocks',
            query: {
                'product_ids': productIds,
            },
        });
    }
    /**
     * Returns the stock for a given product
     * Returns the stock for a given product
     * @returns number successful operation
     * @throws ApiError
     */
    public static getApiProductsStocksOverTime({
        productIds,
    }: {
        /**
         * List of product ids.
         */
        productIds?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/stocks/over-time',
            query: {
                'product_ids': productIds,
            },
        });
    }
    /**
     * list all TargetSegment types
     * @returns TargetSegmentDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProductsTargetSegments({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, name, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'name' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<TargetSegmentDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/target-segments',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * get one TargetSegment by id
     * @returns TargetSegmentDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProductsTargetSegments1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<TargetSegmentDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/target-segments/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * deletes Product
     * @returns void
     * @throws ApiError
     */
    public static deleteApiProducts({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/products/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one Product by id
     * @returns ProductDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiProducts1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ProductDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * partially updates Product by id
     * @returns ProductDefaultSchema call successful
     * @throws ApiError
     */
    public static patchApiProducts({
        id,
        requestBody,
    }: {
        /**
         * id
         */
        id: number,
        requestBody: ProductDefaultSchema,
    }): CancelablePromise<ProductDefaultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns the N best recommendations for one pos_id
     * @returns ProductRecommendationSchema call successful
     * @throws ApiError
     */
    public static getApiProductsRecommended({
        posId,
    }: {
        posId: number,
    }): CancelablePromise<Array<ProductRecommendationSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{pos_id}/recommended',
            path: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns list of teams that have this product
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiProductsEvolMonth({
        productId,
        productCategoryId,
        startDate,
        endDate,
        channels,
        unitsOrBilling,
    }: {
        /**
         * Product ID.
         */
        productId: number,
        /**
         * Product category id.
         */
        productCategoryId: number,
        /**
         * Start date.
         */
        startDate?: string,
        /**
         * End date.
         */
        endDate?: string,
        /**
         * List of channel ids.
         */
        channels?: any[],
        /**
         * units or billing
         */
        unitsOrBilling?: string,
    }): CancelablePromise<{
        forecast?: TimeSeriesForecastSchema;
        timeseries?: Record<string, number>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{product_id}/evol/month',
            path: {
                'product_id': productId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'product_category_id': productCategoryId,
                'channels': channels,
                'units_or_billing': unitsOrBilling,
            },
        });
    }
    /**
     * Returns the forecast for a given product
     * Returns the forecast for a given product
     * @returns ProductForecastDefaultSchema successful operation
     * @throws ApiError
     */
    public static getApiProductsForecast({
        productId,
    }: {
        /**
         * Product ID.
         */
        productId: string,
    }): CancelablePromise<ProductForecastDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{product_id}/forecast',
            path: {
                'product_id': productId,
            },
        });
    }
    /**
     * Returns list of teams that have this product
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiProductsMarketShare({
        productId,
        startDate,
        endDate,
        productCategoryId,
        unitsOrBilling,
    }: {
        /**
         * Product ID.
         */
        productId: number,
        /**
         * Start date.
         */
        startDate?: string,
        /**
         * End date.
         */
        endDate?: string,
        /**
         * Product category id.
         */
        productCategoryId?: number,
        /**
         * units or billing
         */
        unitsOrBilling?: string,
    }): CancelablePromise<Record<string, {
        all_sales?: number;
        product_sales?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{product_id}/market-share',
            path: {
                'product_id': productId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'product_category_id': productCategoryId,
                'units_or_billing': unitsOrBilling,
            },
        });
    }
    /**
     * Returns list of teams that have this product
     * @returns TeamSchema call successful
     * @throws ApiError
     */
    public static getApiProductsTeams({
        productId,
    }: {
        /**
         * Product ID.
         */
        productId: number,
    }): CancelablePromise<Array<TeamSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{product_id}/teams',
            path: {
                'product_id': productId,
            },
        });
    }
}
