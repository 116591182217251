import _ from 'lodash';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ACTIONS = {
    SORT: 'handleSort',
    UPDATE_DATA: 'updateData'
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SORT: {
            if (state.sortColumn.path === action.payload.path)
                state.sortColumn.order =
                    state.sortColumn.order === 'asc' ? 'desc' : 'asc';
            else {
                state.sortColumn.path = action.payload.path;
                state.sortColumn.order = 'asc';
            }
            const columnsIndex = _.findIndex(state.columns, (col) => {
                return col.path === action.payload.path;
            });
            let sortedData;
            if (
                columnsIndex !== -1 &&
                _.has(state.columns[columnsIndex], 'customSort')
            ) {
                sortedData = _.orderBy(
                    state.data,
                    state.columns[columnsIndex]['customSort'],
                    [state.sortColumn.order]
                );
            } else {
                sortedData = _.orderBy(
                    state.data,
                    [state.sortColumn.path],
                    [state.sortColumn.order]
                );
            }
            return {
                ...state,
                sortedData: sortedData
            };
        }
        case ACTIONS.UPDATE_DATA:
            // Logic to update sortedData when data changes
            return {
                ...state,
                data: action.payload.data,
                sortedData: _.orderBy(
                    action.payload.data,
                    [state.sortColumn.path],
                    [state.sortColumn.order]
                )
            };
    }
}

export function useCustomSort(defaultValue) {
    const [value, dispatch] = useReducer(reducer, {
        sortedData: defaultValue.data,
        sortColumn: { path: defaultValue.path, order: defaultValue.order }
    });

    const handleSort = useCallback((path) => {
        dispatch({ type: 'handleSort', payload: { path: path } });
    }, []);

    // useEffect to watch for data changes and update sortedData
    useEffect(() => {
        dispatch({
            type: ACTIONS.UPDATE_DATA,
            payload: { data: defaultValue.data }
        });
    }, [defaultValue.data]); // Dependency array includes data

    return {
        sortedData: value.sortedData,
        sortedColumn: value.sortColumn,
        handleSort
    };
}
