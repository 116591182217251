import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import {
    getDayFromDateFormat,
    getMonthFromDateFormat,
    getTimeFromDateFormat,
    getYearFromDateFormat,
    isSameYearSameMonthSameDay
} from '../../../utils/date';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { LoginsService } from '../../../temp/test';
import { loginHistoryMock } from './mock';
import { verifyComponent } from '../../../utils/component.js';
import { useChartReady } from '../../../providers/chart-ready-context';
import {
    ContentContainer,
    DynamicComposedChartContainer,
    Loader,
    Spinner
} from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush';

/**
 * Displays evolution of logins over time
 */

const Chart = withCustomBrush(DynamicComposedChartContainer, 'day');

export const LoginHistory = React.memo(({ userId, isPreview }) => {
    const { charts } = useChartReady();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(loginHistoryMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(loginHistoryMock);
            try {
                const loginsDailyBack =
                    await LoginsService.getApiBackofficeLoginsDaily({
                        userUuid: userId
                    });
                dataClone.data.length = 0;
                Object.entries(loginsDailyBack).map((el) => {
                    let newEl = {
                        loginsCounter: el[1].toFixed(1),
                        loginsCounterAux: el[1].toFixed(1),
                        minLoginsCounterAux: el[1].toFixed(1),
                        time: getTimeFromDateFormat(el[0]),
                        day: getDayFromDateFormat(el[0], t),
                        month: getMonthFromDateFormat(el[0], t),
                        year: getYearFromDateFormat(el[0])
                    };
                    let idx = dataClone.data.findIndex((v) => {
                        return isSameYearSameMonthSameDay(v.time, newEl.time);
                    });
                    if (idx !== -1) {
                        newEl.loginsCounter = dataClone.data[idx].loginsCounter;
                        dataClone.data[idx] = newEl;
                    } else {
                        dataClone.data.push(newEl);
                    }
                    return null;
                });
                dataClone.interactionDisabled = false;
                dataClone.tooltipType = 'loginsHistory-daily';
                dataClone.loading = false;
                dataClone.ready = true;
                dataClone.areaAttr = [
                    {
                        dataKey: 'minLoginsCounterAux',
                        color: '#8c54ff',
                        dashArray: '3 3',
                        strokeWidth: 1
                    },
                    {
                        dataKey: 'loginsCounterAux',
                        color: '#513091',
                        strokeWidth: 2
                    },
                    {
                        dataKey: 'loginsCounter',
                        color: '#8c54ff',
                        strokeWidth: 2
                    }
                ];
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [userId, isMounted, setLoading, setValue, t, isPreview]);

    return (
        <div
            className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                timeInterval={'day'}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <Chart
                        translate={t}
                        brushStroke={'#8c54ff'}
                        areaAttr={data.areaAttr}
                        barAttr={data.barAttr}
                        categoryAxis={data.categoryAxis}
                        data={data.data}
                        dateGrouper={data.dateGrouper}
                        timeAttr={data.timeAttr}
                        lineType={data.lineType}
                        showExternalOptions={data.showExternalOption}
                        externalOptions={data.externalOptions}
                        selectedExternalOption={data.selectedExternalOptions}
                        gradient={data.gradient}
                        tooltipType={data.tooltipType}
                        margin={data.margin}
                        unitsOrValues={data.unitsOrValues}
                        barFilter={data.barFilter}
                    />
                )}
            </ContentContainer>
        </div>
    );
});

LoginHistory.propTypes = {
    /**
     * Selected user to filter request for data to backend
     */
    userId: PropTypes.string
};
