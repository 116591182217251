import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TeamsService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { RemoveModal } from '../..'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const RemoveTeamMemberModal = ({
    teamId,
    teamMemberId,
    onCloseModal,
    onEnd,
}) => {
    const { t } = useCustomTranslation()
    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleDeleteTeamMember = async () => {
        try {
            setLoading(true)
            await TeamsService.deleteApiTeamsMembers({
                teamId: teamId,
                memberId: teamMemberId,
            })
            if (onEnd) onEnd()
            setLoading(false)
            setIsSuccess(true)
        } catch (err) {
            errorHandler(err)
        }
    }

    return (
        <RemoveModal
            title={t('modals:removeTeamMember.title')}
            description={t('modals:removeTeamMember.description')}
            successMessage={t('modals:removeTeamMember.success')}
            isLoading={loading}
            isSuccess={isSuccess}
            onDelete={handleDeleteTeamMember}
            onCloseModal={onCloseModal}
        />
    )
}
