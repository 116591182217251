import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as IcoCloseModal } from './assets/images/ico-close-modal.svg'
import styles from './modal.module.scss'
import { LoaderForeground, Scrollable } from '../..'
import { useModalStatus } from '../../../providers/modal-status-context'

export const Modal = React.memo(({ title, description, onToggleModal, children, isLoading, noScrollingElements }) => {
    const reference = React.createRef()
    const { setIsOpen } = useModalStatus()
    const handleOutsideClick = useCallback(
        (event) => {
            if (reference.current && !reference.current.contains(event.target)) {
                event.preventDefault()
                event.stopPropagation()
                onToggleModal()
            }
        },
        [onToggleModal, reference]
    )

    useEffect(() => {
        setIsOpen(true)
        return function cleanup() {
            setIsOpen(false)
        }
    }, [setIsOpen])

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return function cleanup() {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [handleOutsideClick])

    return (
        <div className={`${styles['modal-container']}`}>
            <div className={`${styles['modal-container__content']}`}>
                {onToggleModal && (
                    <div className={`${styles['modal-container__content__icon-container']}`}>
                        <IcoCloseModal
                            onClick={onToggleModal}
                            style={{
                                width: '3rem',
                                height: '3rem',
                            }}
                        />
                    </div>
                )}
                <div className={`${styles['modal-container__content__wrapper']}`} ref={reference}>
                    {isLoading && (
                        <div className="row mx-0">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <LoaderForeground maxSize={18} speed={4} />
                            </div>
                        </div>
                    )}
                    <div
                        className="w-100"
                        style={{
                            height: noScrollingElements ? 'calc(100% - 6rem)' : '100%',
                            maxHeight: noScrollingElements ? 'calc(100vh - 18rem)' : 'calc(100vh - 24rem)',
                        }}
                    >
                        {title && (
                            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <span className={`${styles['modal-container__content__title']}`}>{title}</span>
                                </div>
                            </div>
                        )}
                        {description && (
                            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <span className={`${styles['modal-container__content__description']}`}>{description}</span>
                                </div>
                            </div>
                        )}

                        <Scrollable addRightSpacing>{children}</Scrollable>
                    </div>
                    {noScrollingElements && (
                        <div
                            className="w-100"
                            style={{
                                height: '6rem',
                            }}
                        >
                            {noScrollingElements}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
})

Modal.propTypes = {
    /**
     * Function to toggle modal
     * */
    onToggleModal: PropTypes.func,
}
