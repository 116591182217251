/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskSchema } from '../models/TaskSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * returns task object
     * @returns TaskSchema call successful
     * @throws ApiError
     */
    public static getApiTasks({
        taskId,
    }: {
        /**
         * task id.
         */
        taskId: number,
    }): CancelablePromise<TaskSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
        });
    }
    /**
     * Returns all vendor_id with names
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiVendors(): CancelablePromise<Array<{
        emp_no?: number;
        id?: number;
        name?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors',
        });
    }
}
