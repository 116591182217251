/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelAliasDefaultSchema } from '../models/ModelAliasDefaultSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ModelAliasService {
    /**
     * list all ModelAlias types
     * @returns ModelAliasDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiModelAlias({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, model_name, level, alias, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'model_name' | 'level' | 'alias' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ModelAliasDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/model-alias',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates ModelAlias
     * @returns ModelAliasDefaultSchema resource created
     * @throws ApiError
     */
    public static postApiModelAlias({
        requestBody,
    }: {
        requestBody: ModelAliasDefaultSchema,
    }): CancelablePromise<ModelAliasDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/model-alias',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ModelAlias
     * @returns void
     * @throws ApiError
     */
    public static deleteApiModelAlias({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/model-alias/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get ModelAlias by model_name
     * @returns string Get ModelAlias by model_name
     * @throws ApiError
     */
    public static getApiModelAlias1({
        modelName,
        level,
    }: {
        /**
         * Model name
         */
        modelName: string,
        /**
         * Level of entity
         */
        level?: number,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/model-alias/{model_name}',
            path: {
                'model_name': modelName,
            },
            query: {
                'level': level,
            },
        });
    }
    /**
     * update ModelAlias alias by model_name
     * @returns string Get ModelAlias by model_name
     * @throws ApiError
     */
    public static patchApiModelAlias({
        modelName,
        level,
        requestBody,
    }: {
        /**
         * Model name
         */
        modelName: string,
        /**
         * Level of entity
         */
        level?: number,
        requestBody?: {
            /**
             * New value
             */
            alias?: string;
        },
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/model-alias/{model_name}',
            path: {
                'model_name': modelName,
            },
            query: {
                'level': level,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
