import { useReducer } from 'react'

function valuesOpenedReducer(state, action) {
    switch (action.type) {
        case 'TOGGLE_OPEN':
            return {
                ...state,
                [action.payload]: !state[action.payload],
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export function useValuesOpened(initialState) {
    const [state, dispatch] = useReducer(valuesOpenedReducer, initialState)

    const toggleOpen = (name) => {
        dispatch({ type: 'TOGGLE_OPEN', payload: name })
    }

    return [state, toggleOpen]
}
