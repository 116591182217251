import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IcoButtonBackDefault } from './assets/images/ico-button-back-default.svg'
import { ReactComponent as IcoButtonBackDisabled } from './assets/images/ico-button-back-disabled.svg'
import { ReactComponent as IcoButtonBackHover } from './assets/images/ico-button-back-hover.svg'
import { ReactComponent as IcoButtonForwardDefault } from './assets/images/ico-button-forward-default.svg'
import { ReactComponent as IcoButtonForwardDisabled } from './assets/images/ico-button-forward-disabled.svg'
import { ReactComponent as IcoButtonForwardHover } from './assets/images/ico-button-forward-hover.svg'
import styles from './sentiment-convo-details.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const SentimentConvoDetails = React.memo(({ el }) => {
    const { t } = useCustomTranslation()
    const [attr] = useState('sentimentConvoDetails')
    const [selectedOption, setSelectedOption] = useState('positive')
    const [sentenceIndex, setSentenceIndex] = useState(0)
    const [backButtonHover, toggleBackButtonHover] = useState(false)
    const [forwardButtonHover, toggleForwardButtonHover] = useState(false)

    return (
        <div className={`row mx-0 w-100 ${styles['sentiment-convo-details']}`}>
            <div
                className={`col h-100 d-flex flex-column justify-content-between align-items-center ${styles['sentiment-convo-details-container']}`}
            >
                <div className="row mx-0 w-100 flex-grow-1">
                    <div
                        className={`col h-100 ${styles['sentiment-convo-details-container__item-wrapper']} d-flex flex-column`}
                    >
                        <div
                            className="row mx-0 w-100 d-flex justify-content-between align-items-center "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col-4 px-0">
                                <span
                                    className={`${styles['sentiment-convo-details-container__item-wrapper__sub-sub-title']}`}
                                >
                                    {t(`components:${attr}.dataextraction`)}
                                </span>
                            </div>
                            <div className="col ms-4 px-0">
                                <span
                                    className={`${styles['sentiment-convo-details-container__item-wrapper__sub-sub-title']}`}
                                >
                                    {t(`components:${attr}.sentenceanalysis`)}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-between align-items-center flex-grow-1">
                            <div className="col px-0 h-100">
                                <div className="row mx-0 w-100 h-100">
                                    <div
                                        className={`col-4 h-inherit ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper']}`}
                                    >
                                        <div
                                            className={`row mx-0 w-100 ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header']} mb-3`}
                                        >
                                            <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        selectedOption ===
                                                        'positive'
                                                            ? `${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header__title']} ${styles['selected']}`
                                                            : `${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header__title']}`
                                                    }
                                                    onClick={() =>
                                                        setSelectedOption(
                                                            'positive'
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        `components:${attr}.positivewords`
                                                    )}
                                                </span>
                                            </div>
                                            <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        selectedOption ===
                                                        'neutral'
                                                            ? `${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header__title']} ${styles['selected']}`
                                                            : `${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header__title']}`
                                                    }
                                                    onClick={() =>
                                                        setSelectedOption(
                                                            'neutral'
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        `components:${attr}.neutralwords`
                                                    )}
                                                </span>
                                            </div>
                                            <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        selectedOption ===
                                                        'negative'
                                                            ? `${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header__title']} ${styles['selected']}`
                                                            : `${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__header__title']}`
                                                    }
                                                    onClick={() =>
                                                        setSelectedOption(
                                                            'negative'
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        `components:${attr}.negativewords`
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col px-0 h-100 ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__content']}`}
                                            >
                                                <div className="row mx-0 w-100">
                                                    {selectedOption ===
                                                        'positive' &&
                                                        el.positiveWords.map(
                                                            (el2, index) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`col-auto ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__content__word-wrapper']} ${styles['positive']}`}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                el2
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                    {selectedOption ===
                                                        'neutral' &&
                                                        el.neutralWords.map(
                                                            (el2, index) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`col-auto ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__content__word-wrapper']} ${styles['neutral']}`}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                el2
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                    {selectedOption ===
                                                        'negative' &&
                                                        el.negativeWords.map(
                                                            (el2, index) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`col-auto ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper__content__word-wrapper']} ${styles['negative']}`}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                el2
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`col ms-4 h-inherit ${styles['sentiment-convo-details-container__item-wrapper__content-wrapper']} d-flex flex-column justify-content-between align-items-center`}
                                    >
                                        <div
                                            className="row mx-0 w-100 "
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0 h-100">
                                                <div className="row mx-0 w-100 h-100">
                                                    {el.sentences &&
                                                        el.sentences[
                                                            sentenceIndex
                                                        ] &&
                                                        el.sentences[
                                                            sentenceIndex
                                                        ].map((el2, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="col-auto px-0 my-2"
                                                                >
                                                                    <span
                                                                        className={`${
                                                                            styles[
                                                                                'sentiment-convo-details-container__item-wrapper__sentence-wrapper__text'
                                                                            ]
                                                                        } ${
                                                                            styles[
                                                                                `${el2.sentiment}`
                                                                            ]
                                                                        }`}
                                                                    >
                                                                        {
                                                                            el2.text
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                                            <div className="col-auto px-0 h-100">
                                                {el.sentences &&
                                                    el.sentences[
                                                        sentenceIndex
                                                    ] &&
                                                    sentenceIndex > 0 &&
                                                    backButtonHover && (
                                                        <IcoButtonBackHover
                                                            onMouseLeave={() =>
                                                                toggleBackButtonHover(
                                                                    !backButtonHover
                                                                )
                                                            }
                                                            onClick={() =>
                                                                setSentenceIndex(
                                                                    sentenceIndex -
                                                                        1
                                                                )
                                                            }
                                                            className={`${styles['sentiment-convo-details-container__item-wrapper__sentence-wrapper__button']}`}
                                                        />
                                                    )}
                                                {el.sentences &&
                                                    el.sentences[
                                                        sentenceIndex
                                                    ] &&
                                                    sentenceIndex > 0 &&
                                                    !backButtonHover && (
                                                        <IcoButtonBackDefault
                                                            onMouseEnter={() =>
                                                                toggleBackButtonHover(
                                                                    !backButtonHover
                                                                )
                                                            }
                                                            onClick={() =>
                                                                setSentenceIndex(
                                                                    sentenceIndex -
                                                                        1
                                                                )
                                                            }
                                                            className={`${styles['sentiment-convo-details-container__item-wrapper__sentence-wrapper__button']}`}
                                                        />
                                                    )}
                                                {el.sentences &&
                                                    el.sentences[
                                                        sentenceIndex
                                                    ] &&
                                                    sentenceIndex < 0 && (
                                                        <IcoButtonBackDisabled
                                                            className={`${styles['sentiment-convo-details-container__item-wrapper__sentence-wrapper__button disabled']}`}
                                                        />
                                                    )}
                                            </div>
                                            <div className="col-auto px-0 h-100">
                                                {el.sentences &&
                                                    el.sentences[
                                                        sentenceIndex
                                                    ] &&
                                                    sentenceIndex <
                                                        el.sentences.length -
                                                            1 &&
                                                    forwardButtonHover && (
                                                        <IcoButtonForwardHover
                                                            onMouseLeave={() =>
                                                                toggleForwardButtonHover(
                                                                    !forwardButtonHover
                                                                )
                                                            }
                                                            onClick={() =>
                                                                setSentenceIndex(
                                                                    sentenceIndex +
                                                                        1
                                                                )
                                                            }
                                                            className={`${styles['sentiment-convo-details-container__item-wrapper__sentence-wrapper__button']}`}
                                                        />
                                                    )}
                                                {el.sentences &&
                                                    el.sentences[
                                                        sentenceIndex
                                                    ] &&
                                                    sentenceIndex <
                                                        el.sentences.length -
                                                            1 &&
                                                    !forwardButtonHover && (
                                                        <IcoButtonForwardDefault
                                                            onMouseEnter={() =>
                                                                toggleForwardButtonHover(
                                                                    !forwardButtonHover
                                                                )
                                                            }
                                                            onClick={() =>
                                                                setSentenceIndex(
                                                                    sentenceIndex +
                                                                        1
                                                                )
                                                            }
                                                            className={`${styles['sentiment-convo-details-container__item-wrapper__sentence-wrapper__button']}`}
                                                        />
                                                    )}
                                                {el.sentences &&
                                                    el.sentences[
                                                        sentenceIndex
                                                    ] &&
                                                    sentenceIndex >=
                                                        el.sentences.length -
                                                            1 && (
                                                        <IcoButtonForwardDisabled
                                                            className={`${styles['sentiment-convo-details-container__item-wrapper__sentence-wrapper__button disabled']}`}
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
