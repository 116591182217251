import React from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { GeneralTable } from '../../../../../components/dashboard-components/general-table/general-table';
import { invoiceListMock } from './mock';

export const InvoiceHistory = () => {
    const { t } = useCustomTranslation();
    return (
        <GeneralTable
            title={t(`components:${invoiceListMock.attr}.title`)}
            withSelection
            attr={invoiceListMock.attr}
            columns={invoiceListMock.columns}
            mockData={invoiceListMock.data}
            deleteEndpoint={() => {}}
            deleteTitle={t(`components:${invoiceListMock.attr}.deleteTitle`)}
            deleteDescription={t(
                `components:${invoiceListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${invoiceListMock.attr}.deleteSuccessText`
            )}
        />
    );
};
