export const unitSalesMock = {
    attr: 'unitSalesChart',
    tooltipType: 'salesForecast-monthly',
    areaAttr: [
        {
            dataKey: 'unitsSold',
            color: '#8c54ff',
            strokeWidth: 2,
            isGradient: true,
            fillOpacity: 0.3
        }
    ],
    unitsOrValues: 'units',
    categoryAxis: 'month',
    data: [
        {
            unitsSold: '11793683.2',
            time: '2022-02-01',
            day: '01 Fev 2022',
            month: 'Feb 2022',
            year: '2022'
        },
        {
            unitsSold: '12872866.0',
            time: '2022-03-01',
            day: '01 Mar 2022',
            month: 'Mar 2022',
            year: '2022'
        },
        {
            unitsSold: '12094173.5',
            time: '2022-04-01',
            day: '01 Abr 2022',
            month: 'Apr 2022',
            year: '2022'
        },
        {
            unitsSold: '14628413.7',
            time: '2022-05-01',
            day: '01 Maio 2022',
            month: 'May 2022',
            year: '2022'
        },
        {
            unitsSold: '14136018.5',
            time: '2022-06-01',
            day: '01 Jun 2022',
            month: 'Jun 2022',
            year: '2022'
        },
        {
            unitsSold: '14042790.8',
            time: '2022-07-01',
            day: '01 Jul 2022',
            month: 'Jul 2022',
            year: '2022'
        },
        {
            unitsSold: '14356603.7',
            time: '2022-08-01',
            day: '01 Ago 2022',
            month: 'Aug 2022',
            year: '2022'
        },
        {
            unitsSold: '14309239.8',
            time: '2022-09-01',
            day: '01 Set 2022',
            month: 'Sep 2022',
            year: '2022'
        },
        {
            unitsSold: '15610575.7',
            time: '2022-10-01',
            day: '01 Out 2022',
            month: 'Oct 2022',
            year: '2022'
        },
        {
            unitsSold: '15897955.2',
            time: '2022-11-01',
            day: '01 Nov 2022',
            month: 'Nov 2022',
            year: '2022'
        },
        {
            unitsSold: '15604644.4',
            time: '2022-12-01',
            day: '01 Dez 2022',
            month: 'Dec 2022',
            year: '2022'
        },
        {
            unitsSold: '15611322.1',
            time: '2023-01-01',
            day: '01 Jan 2023',
            month: 'Jan 2023',
            year: '2023'
        },
        {
            unitsSold: '15855914.2',
            time: '2023-02-01',
            day: '01 Fev 2023',
            month: 'Feb 2023',
            year: '2023'
        },
        {
            unitsSold: '17770306.7',
            time: '2023-03-01',
            day: '01 Mar 2023',
            month: 'Mar 2023',
            year: '2023'
        },
        {
            unitsSold: '15644668.1',
            time: '2023-04-01',
            day: '01 Abr 2023',
            month: 'Apr 2023',
            year: '2023'
        },
        {
            unitsSold: '18061481.4',
            time: '2023-05-01',
            day: '01 Maio 2023',
            month: 'May 2023',
            year: '2023'
        },
        {
            unitsSold: '17484477.3',
            time: '2023-06-01',
            day: '01 Jun 2023',
            month: 'Jun 2023',
            year: '2023'
        }
    ]
};
