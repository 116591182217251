import imagePlaceholder from '../../../assets/images/user-img.png';
import styles from './profile-configuration-profile.module.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useTextInput } from '../../../hooks/useTextInput';
import { errorHandler } from '../../../utils/api';
import { ProfileService } from '../../../temp/test';
import { useCurrentUser } from '../../../providers/current-user-context';
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput';
import {
    LoaderForeground,
    SelectMultipleInput,
    Spinner,
    TypeInput
} from '../../';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const ProfileConfigurationProfile = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const inputFile = useRef(null);
    const { currentUser } = useCurrentUser();
    const [loading, toggleLoading] = useToggle(true);

    const {
        value: username,
        setValue: setUsernameValue,
        replaceValue: usernameReplaceValue
    } = useTextInput({
        name: 'username',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });
    const {
        value: name,
        setValue: setNameValue,
        replaceValue: nameReplaceValue
    } = useTextInput({
        name: 'name',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: email,
        setValue: setEmailValue,
        replaceValue: emailReplaceValue
    } = useTextInput({
        name: 'email',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: roles,
        toggleDropdown: rolesToggleDropdown,
        selectOption: rolesSelectOption,
        unselectOption: rolesUnselectOption,
        keyPress: rolesKeyPress,
        keyDown: rolesKeyDown,
        setValue: rolesSetValue,
        replaceSelectedOptions: rolesReplaceSelectedOptions
    } = useSelectMultipleInput({
        name: 'roles',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOptions: [],
        selectedOptionsAux: [],
        state: 'normal',
        value: '',
        focused: -1,
        reference: React.createRef()
    });
    const {
        value: department,
        setValue: setDepartmentValue,
        replaceValue: departmentReplaceValue
    } = useTextInput({
        name: 'department',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: phone,
        setValue: setPhoneValue,
        replaceValue: phoneReplaceValue
    } = useTextInput({
        name: 'phone',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const onPhotoUploadStart = () => {
        inputFile.current.click();
    };

    const onPhotoUploadEnd = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        async function fill() {
            try {
                const results = await ProfileService.getApiProfileUser({
                    uuid: currentUser.uuid
                });
                if (results.department) {
                    departmentReplaceValue(results.department);
                }
                if (results.username) {
                    usernameReplaceValue(results.username);
                }
                if (results.mobile_phone) {
                    phoneReplaceValue(results.mobile_phone);
                }
                if (results.name) {
                    nameReplaceValue(results.name);
                }
                if (results.email) {
                    emailReplaceValue(results.email);
                }
                const newRoles = [];
                results.roles.map((el) => {
                    newRoles.push(el.name);
                });
                if (results.roles) {
                    rolesReplaceSelectedOptions(newRoles, []);
                }
            } catch (e) {
                errorHandler(e);
            }
        }
        fill();
    }, [
        currentUser.uuid,
        departmentReplaceValue,
        usernameReplaceValue,
        phoneReplaceValue,
        nameReplaceValue,
        emailReplaceValue,
        rolesReplaceSelectedOptions
    ]);

    useEffect(() => {
        async function fill() {
            try {
                const results = await ProfileService.getApiProfileUser({
                    uuid: currentUser.uuid
                });

                if (results.department) {
                    departmentReplaceValue(results.department);
                }
                if (results.username) {
                    usernameReplaceValue(results.username);
                }
                if (results.mobile_phone) {
                    phoneReplaceValue(results.mobile_phone);
                }
                if (results.name) {
                    nameReplaceValue(results.name);
                }
                if (results.email) {
                    emailReplaceValue(results.email);
                }
                const newRoles = [];
                results.roles.map((el) => {
                    newRoles.push(el.name);
                });
                if (results.roles) {
                    rolesReplaceSelectedOptions(newRoles);
                }
            } catch (e) {
                errorHandler(e);
            }
        }
        fill();
    }, [
        currentUser.uuid,
        departmentReplaceValue,
        usernameReplaceValue,
        phoneReplaceValue,
        nameReplaceValue,
        emailReplaceValue,
        rolesReplaceSelectedOptions
    ]);

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`${styles['profile-configuration-profile-container']}`}
                >
                    <div className="row w-100 h-100 mx-0">
                        <div className="col px-0 h-100 d-flex flex-column align-items-center">
                            <div
                                className="row w-100 "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                    <span className="modal-title">
                                        {t('pages:profile-configuration.title')}
                                    </span>
                                </div>
                            </div>
                            {/* <hr
								className={`${styles['profile-configuration-profile-container__separator']}`}
							/> */}
                            <div className="row w-100 mx-0">
                                <div
                                    className={`col-auto px-0 pe-3 h-100 ${styles['profile-configuration-profile-container__image-container']}`}
                                >
                                    <div className="row mx-0 w-100 mb-4">
                                        <div className="col p-0 h-100">
                                            <div
                                                className={`${styles['profile-configuration-profile-container__image-container__image']}`}
                                            >
                                                <img
                                                    loading="lazy"
                                                    src={imagePlaceholder}
                                                    alt="user"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col p-0 h-100">
                                            <input
                                                type="file"
                                                id="photoUpload"
                                                ref={inputFile}
                                                style={{ display: 'none' }}
                                                onChange={onPhotoUploadEnd}
                                            />
                                            <PrimaryButton
                                                text={t('buttons:editPhoto')}
                                                onClick={onPhotoUploadStart}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`col h-100 d-flex flex-column justify-content-between ${styles['profile-configuration-profile-container__inputs-container']}`}
                                >
                                    <div className="row w-100 mx-0">
                                        <div className="col px-0 pe-3 h-100">
                                            <div
                                                className="row mx-0 w-100 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0 h-100">
                                                    <span
                                                        className={`${styles['profile-configuration-profile-container__inputs-container__title']}`}
                                                    >
                                                        {username.label}
                                                    </span>
                                                    <TypeInput
                                                        onChangeInput={
                                                            setUsernameValue
                                                        }
                                                        {...username}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0 h-100">
                                                    <span
                                                        className={`${styles['profile-configuration-profile-container__title']}}`}
                                                    >
                                                        {name.label}
                                                    </span>
                                                    <TypeInput
                                                        onChangeInput={
                                                            setNameValue
                                                        }
                                                        {...name}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0 h-100">
                                                    <span
                                                        className={`${styles['profile-configuration-profile-container__inputs-container__title']}`}
                                                    >
                                                        {roles.label}
                                                    </span>
                                                    <SelectMultipleInput
                                                        hideOptionsAux
                                                        disabled={
                                                            roles.disabled
                                                        }
                                                        onChangeInput={
                                                            rolesSetValue
                                                        }
                                                        onSelectMultiple={
                                                            rolesSelectOption
                                                        }
                                                        onSelectedOptionClick={
                                                            rolesUnselectOption
                                                        }
                                                        onKeyPress={
                                                            rolesKeyPress
                                                        }
                                                        onKeyDown={rolesKeyDown}
                                                        onToggleDropdown={
                                                            rolesToggleDropdown
                                                        }
                                                        {...roles}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-0 h-100">
                                            <div
                                                className="row mx-0 w-100 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0 h-100">
                                                    <span
                                                        className={`${styles['profile-configuration-profile-container__inputs-container__title']}`}
                                                    >
                                                        {email.label}
                                                    </span>
                                                    <TypeInput
                                                        onChangeInput={
                                                            setEmailValue
                                                        }
                                                        {...email}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0 h-100">
                                                    <span
                                                        className={`${styles['profile-configuration-profile-container__inputs-container__title']}`}
                                                    >
                                                        {phone.label}
                                                    </span>
                                                    <TypeInput
                                                        onChangeInput={
                                                            setPhoneValue
                                                        }
                                                        {...phone}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0 h-100">
                                                    <span
                                                        className={`${styles['profile-configuration-profile-container__inputs-container__title']}`}
                                                    >
                                                        {department.label}
                                                    </span>
                                                    <TypeInput
                                                        onChangeInput={
                                                            setDepartmentValue
                                                        }
                                                        {...department}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col p-0 button-container d-flex justify-content-end align-items-center">
                                            <PrimaryButton
                                                text={t('buttons:save')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

ProfileConfigurationProfile.propTypes = {};
