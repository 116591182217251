import styles from './navbar.module.scss'

import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import icoPlus from '../../assets/images/ico-plus.svg'
import { getNavWidth } from '../../utils/width'
import { useCurrentApp } from '../../providers/current-app-context'
import { useCurrentUser } from '../../providers/current-user-context'
import { useNavBar } from '../../providers/nav-bar-context'
import { useModalPopup } from '../../providers/modal-popup-context'
import { GlobalModalPopupNavbarAll, NavOption, Scrollable } from '../../components'
import icoToggleNavbar from '../../assets/images/icoToggleNavbar.svg'
import { StarkdataService } from '../../temp/test'
import { getFromLS, saveToLS } from '../../utils/localStorage'
import { ModuleSubscriptionProvider } from '../../providers/module-subscription-context'

/**
 * Navigation bar containin the list of dashboards to which the user can traverse
 */

const Navbar = React.memo(({ routes, actions, openDropdown }) => {
    const { currentUser } = useCurrentUser()
    const { currentApp } = useCurrentApp()
    const { steps, selectedTutorialStep, currentStep, setCurrentStep, toggleIsOpen } = useModalPopup()
    const [isModuleSentimentAnalysisDisabled, setIsModuleSentimentAnalysisDisabled] = useState(
        getFromLS('moduleSubscription') ? !getFromLS('moduleSubscription').sentimentAnalysis : false
    )
    const [isModuleSegmentAnalysisDisabled, setIsModuleSegmentAnalysisDisabled] = useState(
        getFromLS('moduleSubscription') ? !getFromLS('moduleSubscription').segmentAnalysis : false
    )
    const [isModuleTeamManagementDisabled, setIsModuleTeamManagementDisabled] = useState(
        getFromLS('moduleSubscription') ? !getFromLS('moduleSubscription').teamManagement : false
    )
    const [isModuleGoalManagementDisabled, setIsModuleGoalManagementDisabled] = useState(
        getFromLS('moduleSubscription') ? !getFromLS('moduleSubscription').goalManagement : false
    )

    const { navOpen, toggleNavBar } = useNavBar()
    const { t } = useTranslation()
    const style = getNavWidth(navOpen)

    const handleCloseNavBar = useCallback(() => {
        if (navOpen) {
            actions.closeAllOptions()
        }
        toggleNavBar()
    }, [navOpen, toggleNavBar, actions])

    useEffect(() => {
        async function fill() {
            const result = await StarkdataService.getApiBackofficeAdminModulesSubscription()
            setIsModuleSentimentAnalysisDisabled(!result.module_sentiment_analysis)
            setIsModuleSegmentAnalysisDisabled(!result.module_segmentation)
            setIsModuleTeamManagementDisabled(!result.module_team_management)
            setIsModuleGoalManagementDisabled(true)
            saveToLS('moduleSubscription', {
                sentimentAnalysis: result.module_sentiment_analysis || false,
                segmentAnalysis: result.module_segmentation || false,
                teamManagement: result.module_team_management || false,
                goalManagement: false,
            })
        }
        fill()
    }, [])

    const moduleSubscriptionValue = useMemo(() => {
        const isAuthDisabled = process.env.REACT_APP_DISABLE_TOKEN_AUTH === 'true'
        return {
            sentimentAnalysis: isAuthDisabled || !isModuleSentimentAnalysisDisabled,
            segmentAnalysis: isAuthDisabled || !isModuleSegmentAnalysisDisabled,
            teamManagement: isAuthDisabled || !isModuleTeamManagementDisabled,
            goalManagement: isAuthDisabled || !isModuleGoalManagementDisabled,
            yourEnterprise: false,
        }
    }, [isModuleSentimentAnalysisDisabled, isModuleSegmentAnalysisDisabled, isModuleTeamManagementDisabled, isModuleGoalManagementDisabled])
    //------------------------------------------------------------------------------------//
    //------------------------------------ Callbacks ------------------------------------//
    //------------------------------------------------------------------------------------//

    /**
     * Handles the opening of the navigation dropdown
     * @param {string} option - The option to open
     * @returns {void}
     */

    const handleOpenNavDropdown = useCallback(
        (option) => {
            routes.map((el) => {
                if (el.name === 'myanalytics') {
                    el.children.map((el2) => {
                        if (el2.name === option) {
                            openDropdown(el2.name)
                        }
                        return el2
                    })
                }
                if (el.name === option) {
                    openDropdown(el.name)
                }
                return el
            })
        },
        [openDropdown, routes]
    )

    /**
     * Handles the click of an icon
     * @param {string} option - The option to open
     * @returns {void}
     */

    return (
        <React.Fragment>
            <div className={`${styles['navbar-container']} ${styles[style]} ${styles[currentApp]}`} style={{ background: 'none' }}>
                <div
                    className={`${styles['navbar-container']} ${styles[style]} ${styles[currentApp]}`}
                    style={{
                        position: 'fixed',
                    }}
                >
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex justify-content-end" style={{ paddingRight: '2rem' }}>
                                <img
                                    src={icoToggleNavbar}
                                    alt="toggle navbar"
                                    className={`${styles['navbar-container__toggle-navbar']}`}
                                    style={{
                                        transform: !navOpen ? 'rotate(180deg)' : '',
                                        width: '2rem',
                                        height: '2rem',
                                    }}
                                    onClick={handleCloseNavBar}
                                />
                            </div>
                        </div>
                    </div>
                    {steps && selectedTutorialStep !== '' && (
                        <GlobalModalPopupNavbarAll
                            steps={steps}
                            toggleIsOpen={toggleIsOpen}
                            selectedTutorialStep={selectedTutorialStep}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                        />
                    )}
                    {((currentUser && currentUser.roles.includes('admin')) || (currentUser && currentUser.roles.includes('starkdata'))) &&
                        currentApp !== 'back-office' &&
                        currentApp !== 'administration' && (
                            <div
                                style={
                                    style === 'collapsed'
                                        ? {
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                          }
                                        : {
                                              width: '100%',
                                          }
                                }
                            >
                                <NavLink
                                    className={({ isActive }) => {
                                        return isActive
                                            ? `nav-link ${styles['is-active']} ${styles['navbar-container__new-page-container']}  ${styles[style]} ${styles['disabled']}`
                                            : `nav-link ${styles['navbar-container__new-page-container']}  ${styles[style]}`
                                    }}
                                    to={'/create-dashboard'}
                                >
                                    <span className={`${styles['navbar-container__new-page-container__image-container']}`}>
                                        <img
                                            loading="lazy"
                                            src={icoPlus}
                                            alt="add new page"
                                            style={{
                                                width: '2.4rem',
                                                height: '2.4rem',
                                            }}
                                        />
                                    </span>
                                    <span className={`${styles['navbar-container__new-page-container__text']}`}>{t('layout:navbar.newpage')}</span>
                                </NavLink>
                            </div>
                        )}

                    <ul
                        className={`${styles['navbar-container__navbar-menu']} h-100 d-flex align-items-start flex-column`}
                        style={
                            steps &&
                            selectedTutorialStep !== '' &&
                            steps[selectedTutorialStep]['steps'][currentStep]['popupDetails'] &&
                            steps[selectedTutorialStep]['steps'][currentStep]['popupDetails']['type'] === 'navigation'
                                ? { overflow: 'visible' }
                                : {}
                        }
                    >
                        <Scrollable>
                            <ModuleSubscriptionProvider
                                value={{
                                    moduleSubscription: moduleSubscriptionValue,
                                }}
                            >
                                {routes &&
                                    routes.map((opt, index) => {
                                        if (opt.show) {
                                            if (
                                                (currentApp === 'administration' && _.has(opt, 'isAdministration') && opt.isAdministration) ||
                                                (currentApp === 'back-office' && _.has(opt, 'isBackOffice') && opt.isBackOffice) ||
                                                (currentApp !== 'administration' &&
                                                    currentApp !== 'back-office' &&
                                                    (!_.has(opt, 'isAdministration') || !opt.isAdministration) &&
                                                    (!_.has(opt, 'isBackOffice') || !opt.isBackOffice))
                                            ) {
                                                return (
                                                    <div key={index} className="w-100">
                                                        <li className="w-100">
                                                            <NavOption
                                                                {...opt}
                                                                key={opt.name}
                                                                collapsed={style === 'collapsed'}
                                                                draftLabel={t('layout:navbar.navOption.draft')}
                                                                openNavDropdown={handleOpenNavDropdown}
                                                                currentApp={currentApp}
                                                                isFolder={opt['isCategory'] && opt['isOpen']}
                                                            />
                                                        </li>
                                                        {index !== routes.length - 1 && (
                                                            <div className={`${styles['navbar-container__separator']}`}></div>
                                                        )}
                                                    </div>
                                                )
                                            } else return null
                                        }
                                        return null
                                    })}
                            </ModuleSubscriptionProvider>
                        </Scrollable>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
})
export default Navbar

Navbar.propTypes = {
    /**
     * List of routes to populate the navigation bar
     */
    routes: PropTypes.arrayOf(PropTypes.object),
}
