import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import _ from 'lodash';

export const HighImpactGoalsTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    const value = _.cloneDeep(props.item);
    if (
        props.item &&
        props.item.data &&
        props.item.data.data1 &&
        props.item.index !== undefined
    ) {
        return (
            <TooltipContainer color={value.data.data1[props.item.index].color}>
                <TooltipHeaderRow
                    title={t('tooltips:header.highImpactGoals')}
                    value={
                        value.data.data1[0].value + value.data.data1[1].value
                    }
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.onTrack')}
                    value={value.data.data1[0].value}
                    color={value.data.data1[0].color}
                />
                <TooltipContentRow
                    title={t('tooltips:content.behindSchedule')}
                    value={value.data.data1[1].value}
                    color={value.data.data1[1].color}
                />
            </TooltipContainer>
        );
    }
    return null;
});
