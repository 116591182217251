//------------------------------------------------------------------------------------//
//------------------------------------  Style and Packages ------------------------------------//
//------------------------------------------------------------------------------------//

import styles from './dashboard-layout.module.scss'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

//------------------------------------------------------------------------------------//
//----------------------------------- Providers and Hooks ----------------------------//
//------------------------------------------------------------------------------------//

import { AuxBarProvider } from '../../providers/aux-bar-context'
import { useModalPopup } from '../../providers/modal-popup-context'
import { useIsMounted } from '../../hooks/useIsMounted'
import { NavBarProvider } from '../../providers/nav-bar-context'
import { HighlightedChartProvider } from '../../providers/highlighted-chart-context'
import { useCurrentUser } from '../../providers/current-user-context'
import { DashboardSettingsProvider } from '../../providers/dashboard-settings-context'
import { useToggle } from '../../hooks/useToggle'
import { FilterProvider } from '../../providers/filter-context'
import { RoutesProvider } from '../../providers/routes-context'
import { HistoryGoalProvider } from '../../providers/history-goal-context'
import { MainScrollProvider } from '../../hooks/main-scroll-context'

//------------------------------------------------------------------------------------//
//----------------------------------- Utility Functions ------------------------------//
//------------------------------------------------------------------------------------//

//------------------------------------------------------------------------------------//
//------------------------------------- Components -----------------------------------//
//------------------------------------------------------------------------------------//

import DynamicDashboard from '../../hocs/dynamic-dashboard/dynamic-dashboard'

export const DashboardLayout = React.memo(
    ({
        routes,
        routeUrl,
        profileRoutes,
        openDropdown,
        component,
        name,
        onUpdateRoute,
        onPublishRoute,
        onRemoveRoute,
        onAddRoute,
        isCustom,
        isDraft,
        closeAllOptions,
        selectedAvailableOption,
        setSelectedAvailableOption,
    }) => {
        //------------------------------------------------------------------------------------//
        //------------------------------------ State ------------------------------------//
        //------------------------------------------------------------------------------------//

        const location = useLocation()
        const isMounted = useIsMounted()
        const { currentUser } = useCurrentUser()
        const [isEdit, toggleIsEdit] = useToggle(false)
        const [availableOptions, setAvailableOptions] = useState([])

        const [historyGoalId, setHistoryGoalId] = useState('')
        const [chartFilters, setChartFilters] = useState([])
        const [isBuilder, setIsBuilder] = useState(false)
        const [dashboardComponent, setDashboardComponent] = useState(undefined)
        const { steps, selectedTutorialStep, currentStep, setCurrentStep, toggleIsOpen } = useModalPopup()

        const [highlightedChart, setHighlightedChart] = useState('')

        //------------------------------------------------------------------------------------//
        //------------------------------------ Memoized ------------------------------------//
        //------------------------------------------------------------------------------------//

        const auxBarProviderProps = useMemo(
            () => ({
                availableOptions: availableOptions,
                setAvailableOptions: setAvailableOptions,
                selectedAvailableOption: selectedAvailableOption,
                setSelectedAvailableOption: setSelectedAvailableOption,
                chartFilters: chartFilters,
                setChartFilters: setChartFilters,
                component: dashboardComponent,
                setComponent: setDashboardComponent,
                isBuilder: isBuilder,
                setIsBuilder: setIsBuilder,
            }),
            [
                availableOptions,
                setAvailableOptions,
                selectedAvailableOption,
                setSelectedAvailableOption,
                chartFilters,
                setChartFilters,
                dashboardComponent,
                setDashboardComponent,
                isBuilder,
                setIsBuilder,
            ]
        )

        const filterProviderProps = useMemo(() => ({}), [])

        const historyGoalProviderProps = useMemo(
            () => ({
                historyGoalId: historyGoalId,
                setHistoryGoalId: setHistoryGoalId,
            }),
            [historyGoalId, setHistoryGoalId]
        )

        const highlightedChartProviderProps = useMemo(
            () => ({
                highlightedChart: highlightedChart,
                setHighlightedChart: setHighlightedChart,
            }),
            [highlightedChart, setHighlightedChart]
        )

        const dashboardSettingsProviderProps = useMemo(
            () => ({
                isEdit: isEdit,
                isDraft: isDraft,
                isCustom: isCustom,
                toggleIsEdit: toggleIsEdit,
                showEditDashboard:
                    isCustom &&
                    !isDraft &&
                    ((currentUser && (currentUser.roles.includes('admin') || currentUser.roles.includes('starkdata'))) ||
                        process.env.REACT_APP_DISABLE_TOKEN_AUTH === 'true'),
            }),
            [isEdit, isDraft, isCustom, toggleIsEdit, currentUser]
        )

        //------------------------------------------------------------------------------------//
        //------------------------------------Lifecycle---------------------------------------//
        //------------------------------------------------------------------------------------//

        useEffect(() => {
            toggleIsEdit(false)
        }, [toggleIsEdit, location.pathname])

        useEffect(() => {
            if (!(highlightedChart !== '')) {
                setSelectedAvailableOption('')
            }
        }, [highlightedChart])

        //------------------------------------------------------------------------------------//
        //------------------------------------------Render------------------------------------//
        //------------------------------------------------------------------------------------//

        return (
            <AuxBarProvider value={auxBarProviderProps}>
                <FilterProvider value={filterProviderProps}>
                    <HistoryGoalProvider value={historyGoalProviderProps}>
                        <HighlightedChartProvider value={highlightedChartProviderProps}>
                            <DashboardSettingsProvider value={dashboardSettingsProviderProps}>
                                <div className={`w-100 h-100`}>
                                    <div className={`${styles['background']}`} />
                                    <div className={`${styles['container-wrapper']}`} style={highlightedChart ? { zIndex: 1000 } : {}}>
                                        <div className={`${styles['container-wrapper__shadow']}`} />
                                        <RoutesProvider
                                            value={{
                                                onUpdateRoute: onUpdateRoute,
                                            }}
                                        >
                                            <DynamicDashboard
                                                routes={routes}
                                                routeUrl={routeUrl}
                                                openDropdown={openDropdown}
                                                profileRoutes={profileRoutes}
                                                onUpdateRoute={onUpdateRoute}
                                                onPublishRoute={onPublishRoute}
                                                onRemoveRoute={onRemoveRoute}
                                                onAddRoute={onAddRoute}
                                                component={isEdit && name && name !== 'createdashboard' ? 'DraftDashboard' : component}
                                            />
                                        </RoutesProvider>
                                    </div>
                                </div>
                            </DashboardSettingsProvider>
                        </HighlightedChartProvider>
                    </HistoryGoalProvider>
                </FilterProvider>
            </AuxBarProvider>
        )
    }
)
DashboardLayout.propTypes = {}
