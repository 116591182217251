import React from 'react';
import styles from './LoaderForeground.module.scss';
import { Loader } from '../Loader/Loader';

export const LoaderForeground = React.memo(() => {
    return (
        <div className={`${styles['loader-foreground-container']}`}>
            <div
                className={`${styles['loader-foreground-container__background']}`}
            ></div>
            <div className="w-100 h-100">
                <Loader maxSize={18} speed={4} />
            </div>
        </div>
    );
});
