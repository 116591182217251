import styles from './auxbar.module.scss'

import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import icoAuxSearch from '../../assets/images/ico-aux-search.svg'
import icoAuxTasks from '../../assets/images/ico-aux-tasks.svg'
import icoAuxFilters from '../../assets/images/ico-filter.svg'
import { useModalPopup } from '../../providers/modal-popup-context'
import { useHighlightedChart } from '../../providers/highlighted-chart-context'
import { useNavBar } from '../../providers/nav-bar-context'
import { useAuxBar } from '../../providers/aux-bar-context'
import icoAssets from '../../assets/images/icoAssets.svg'
import {
    AuxbarBookmarkInformation,
    AuxbarComponentFilterInformation,
    AuxbarFilterInformation,
    AuxbarSearchInformation,
    AuxbarStoreInformation,
    FullTextPopup,
} from '../../components'
import { saveToLS } from '../../utils/localStorage'
import { useCustomTranslation } from '../../hooks/useCustomTranslation'
import { usePageDetails } from '../../providers/page-details-context'
import { TechnicalSupportModal } from '../../components/modals/technical-support-modal/technical-support-modal'
import { useToggle } from '../../hooks/useToggle'
import icoTechnicalSupport from '../../assets/images/icoTechnicalSupport.svg'
import componentConfig from '../../configs/componentLibraryConf.json'
import { AuxbarApprovalHistory } from '../../components/navigation/auxbar-approval-history/auxbar-approval-history'

/**
 * Provides the layout and functionality for an auxiliary bar
 */

const Auxbar = React.memo(({ hoveredFilter, customFilterInputs }) => {
    const availableCharts = useMemo(() => {
        return Object.values(componentConfig)
    }, [])

    const [technicalSupportModalOpen, toggleTechnicalSupportModal] = useToggle()
    const { availableOptions, setAvailableOptions, selectedAvailableOption, setSelectedAvailableOption, isBuilder, chartFilters } = useAuxBar()
    const { isHome } = usePageDetails()
    const { auxOpen, toggleAuxBar, handleClickIcon } = useNavBar()
    const { highlightedChart, setHighlightedChart } = useHighlightedChart()
    const { steps, selectedTutorialStep, currentStep } = useModalPopup()
    const { t } = useCustomTranslation()
    const auxOptions = useMemo(() => {
        return ['search', 'store', 'tasks', 'filters']
    }, [])

    useEffect(() => {
        const newAvailableOptions = []
        if (isBuilder && !isHome) {
            newAvailableOptions.push('store')
        }
        if (chartFilters && chartFilters.length > 0 && !isBuilder) {
            chartFilters.map((el) => {
                if (
                    /* {isDraft} */

                    el &&
                    el.length > 0 &&
                    newAvailableOptions.includes('search') === false
                ) {
                    newAvailableOptions.push('search')
                }
            })
        }
        if (customFilterInputs && customFilterInputs.length > 0 && customFilterInputs.filter((el) => el !== 'none').length > 0) {
            newAvailableOptions.push('search')
        }

        if (newAvailableOptions && newAvailableOptions.includes('search') && !isBuilder) {
            setSelectedAvailableOption('search')
            toggleAuxBar(true)
        } else if (newAvailableOptions && newAvailableOptions.includes('store')) {
            setSelectedAvailableOption('store')
            toggleAuxBar(true)
        } else {
            toggleAuxBar(false)
            setSelectedAvailableOption('')
        }
        setAvailableOptions(newAvailableOptions)
        saveToLS('availableOptions', newAvailableOptions)
    }, [setSelectedAvailableOption, toggleAuxBar, isHome, isBuilder, chartFilters, setAvailableOptions, customFilterInputs])

    const uniqueChartFilters = useMemo(() => {
        const uniqueFilters = []
        if (chartFilters && chartFilters.length > 0) {
            chartFilters.map((el) => {
                if (el && el.length > 0) {
                    el.map((el2) => {
                        if (uniqueFilters.includes(el2) === false) {
                            uniqueFilters.push(el2)
                        }
                    })
                }
            })
        }
        return uniqueFilters
    }, [chartFilters])

    useEffect(() => {
        if (!selectedAvailableOption.startsWith('componentFilter')) {
            setHighlightedChart(undefined)
        }
    }, [selectedAvailableOption, setHighlightedChart])

    return (
        <div
            className={
                (auxOpen && !_.isEmpty(availableOptions) && availableOptions.includes(selectedAvailableOption)) ||
                selectedAvailableOption.startsWith('componentFilter') ||
                selectedAvailableOption.startsWith('approvalHistory')
                    ? `${styles['auxbar-container']}`
                    : `${styles['auxbar-container']} ${styles['collapsed']}`
            }
            style={{
                zIndex: highlightedChart || technicalSupportModalOpen ? 1000 : undefined,
            }}
        >
            <div
                className={`${styles['auxbar-container__content']}`}
                style={{
                    visibility:
                        (auxOpen && !_.isEmpty(availableOptions) && availableOptions.includes(selectedAvailableOption)) ||
                        selectedAvailableOption.startsWith('componentFilter') ||
                        selectedAvailableOption.startsWith('approvalHistory')
                            ? 'visible'
                            : 'hidden',
                }}
            >
                <div className="position-relative w-100 h-100 d-flex flex-column">
                    <div
                        className="row w-100 h-100 category px-0 mx-0"
                        style={{
                            display: selectedAvailableOption === 'search' ? 'block' : 'none',
                        }}
                    >
                        <div className="col h-100">
                            <AuxbarSearchInformation filterInputs={uniqueChartFilters} hoveredFilter={hoveredFilter} />
                        </div>
                    </div>

                    {selectedAvailableOption === 'bookmark' && <AuxbarBookmarkInformation />}
                    <div
                        className="row w-100 h-100 category px-0 mx-0"
                        style={{
                            display: selectedAvailableOption === 'store' ? 'block' : 'none',
                        }}
                    >
                        <div className="col h-100 d-flex flex-column">
                            <AuxbarStoreInformation availableCharts={availableCharts} />
                        </div>
                    </div>
                    {selectedAvailableOption.startsWith('componentFilter') && highlightedChart && (
                        <AuxbarComponentFilterInformation component={highlightedChart} componentName={selectedAvailableOption.split('_')[1]} />
                    )}
                    {/* {selectedAvailableOption === 'tasks' && (
                                    <AuxbarTaskInformation />
                                )} */}
                    {selectedAvailableOption === 'filters' && <AuxbarFilterInformation />}
                    {selectedAvailableOption === 'approvalHistory' && <AuxbarApprovalHistory />}
                    {selectedAvailableOption === '' && (
                        <div className="row w-100 h-100 category px-0 mx-0">
                            <div className="col h-100"></div>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={
                    (auxOpen && !_.isEmpty(availableOptions) && availableOptions.includes(selectedAvailableOption)) ||
                    selectedAvailableOption.startsWith('componentFilter') ||
                    selectedAvailableOption.startsWith('approvalHistory')
                        ? `${styles['auxbar-container__aux']} ${styles['isOpen']}`
                        : `${styles['auxbar-container__aux']}`
                }
            >
                {auxOptions.map((el, index) => {
                    if (availableOptions && availableOptions.includes(el)) {
                        return (
                            <div
                                key={index}
                                className={
                                    selectedAvailableOption === el &&
                                    auxOpen &&
                                    !_.isEmpty(availableOptions) &&
                                    availableOptions.includes(selectedAvailableOption)
                                        ? `col ${styles['auxbar-container__aux__icon']} d-flex justify-content-center align-items-center ${styles['selected']}`
                                        : `col  ${styles['auxbar-container__aux__icon']} d-flex justify-content-center align-items-center`
                                }
                                onClick={() => {
                                    setHighlightedChart(undefined)
                                    handleClickIcon(el)
                                }}
                            >
                                <div className={`${styles['auxbar-container__aux__icon__img-container']}`}>
                                    <img
                                        loading="lazy"
                                        src={
                                            el === 'search'
                                                ? icoAuxSearch
                                                : el === 'tasks'
                                                ? icoAuxTasks
                                                : el === 'filters'
                                                ? icoAuxFilters
                                                : el === 'store'
                                                ? icoAssets
                                                : null
                                        }
                                        alt={el}
                                    />
                                </div>
                                <span className={`${styles['auxbar-container__aux__icon__text']}`}>{t('layout:auxbar.icons.' + el)}</span>
                            </div>
                        )
                    }
                    return null
                })}
                {technicalSupportModalOpen && (
                    <div className={`${styles['auxbar-container__aux__technical-support__modal']}`}>
                        <TechnicalSupportModal onCloseModal={toggleTechnicalSupportModal} />
                    </div>
                )}
                <FullTextPopup text={t('settings:help&Feedback')}>
                    <div className={`${styles['auxbar-container__aux__technical-support']}`}>
                        <img src={icoTechnicalSupport} alt="support" onClick={toggleTechnicalSupportModal} />
                    </div>
                </FullTextPopup>
            </div>
        </div>
    )
})

export default Auxbar

Auxbar.propTypes = {}
