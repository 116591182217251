import React from 'react'
import { dateSort } from '../../../constants/sort'

export const segmentListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'id', label: '', sortable: true },
        { path: 'name', label: '', sortable: true },
        { path: 'createdBy', label: '', sortable: true },
        {
            path: 'createdAt',
            label: '',
            sortable: true,
            customSort: (props) => dateSort(props, 'isoDate'),
        },
        { path: 'parentSegment', label: '', sortable: true },
        { path: 'automated', label: '', sortable: true },
        { path: 'action', label: '', sortable: false },
    ],
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'segmentTable',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
        reference: React.createRef(),
    },
}
