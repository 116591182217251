import React from 'react'

export const productSalesByCategoryMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    titleText: '',
    legend: undefined,
    type: 'Map',
    barStackedAttr: [
        {
            dataKey: 'categorySales',
            gradientColors: ['20998b', '2cd9c5'],
            stackId: 'a',
            minPointSize: 70,
            radius: [0, 100, 100, 0],
        },
    ],
    categoryAxis: 'categoryName',
    barWidth: 20,
    data: [
        {
            categoryName: "Cut Russet Potatoes Steam N' Mash",
            categorySales: 14658,
        },
        {
            categoryName: 'Overnight Diapers Size 6',

            categorySales: 14046,
        },
        {
            categoryName: 'Saline Nasal Mist',

            categorySales: 13275,
        },
        {
            categoryName: 'Green Chile Anytime Sauce',

            categorySales: 12805,
        },
        {
            categoryName: 'Sparkling Orange Juice & Prickly Pear Beverage',

            categorySales: 12333,
        },
        {
            categoryName: 'Pure Coconut Water With Orange',

            categorySales: 12149,
        },
        {
            categoryName: 'Chocolate Fudge Layer Cake',

            categorySales: 12035,
        },
        {
            categoryName: 'Fresh Scent Dishwasher Cleaner',

            categorySales: 11906,
        },
        {
            categoryName:
                'Smart Ones Classic Favorites Mini Rigatoni With Vodka Cream Sauce',

            categorySales: 11817,
        },
        {
            categoryName: 'Peach Mango Juice',

            categorySales: 11698,
        },
    ],
    orientation: 'vertical',
    tooltipType: 'productCategorySales',
    unitsOrValues: 'values',
    loading: true,
    categoryLabel: '',
    margin: {
        top: 5,
        left: 0,
        right: 20,
        bottom: 5,
    },
    attr: 'productSalesByCategoryChart',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
