import icoClientAi from '../assets/images/ico-client-ai.svg'
import icoClientInformation from '../assets/images/ico-client-information.svg'
import icoClientList from '../assets/images/ico-client-list.svg'
import icoGeographicDistribution from '../assets/images/ico-geographic-dist.svg'
import icoHomologueSales from '../assets/images/ico-homologue-sales.svg'
import icoProductInformation from '../assets/images/ico-product-information.svg'
import icoRevenueAtRisk from '../assets/images/ico-revenue-at-risk.svg'
import icoTimelineForecast from '../assets/images/ico-timeline-forecast.svg'
import icoTopCard from '../assets/images/ico-top-card.svg'

export default {
    bubbleGeoChart: icoGeographicDistribution,
    textBox: icoTopCard,
    revenueSalesForecastChart: icoTimelineForecast,
    revenueSalesForecastComparisonChart: icoTimelineForecast,
    evolByProductForecastChart: icoTimelineForecast,
    unitSalesForecastChart: icoTimelineForecast,
    unitSalesForecastComparisonChart: icoTimelineForecast,
    revenueSalesChart: icoTimelineForecast,
    unitSalesChart: icoTimelineForecast,
    geoChart: icoGeographicDistribution,
    extendedGeoChart: icoGeographicDistribution,
    clientGeoChart: icoGeographicDistribution,
    prospectGeoChart: icoGeographicDistribution,
    salesGeoChart: icoGeographicDistribution,
    clientOverviewCard: icoClientInformation,
    clientDetailsCard: icoClientAi,
    clientRiskChart: icoRevenueAtRisk,
    extendedClientRiskChart: icoRevenueAtRisk,
    totalClientsCard: icoTopCard,
    activeClientsCard: icoTopCard,
    inactiveClientsCard: icoTopCard,
    newClientsCard: icoTopCard,
    clientsAtRiskCard: icoTopCard,
    totalInvestmentCard: icoTopCard,
    meanDeviationCard: icoTopCard,
    meanRiskCard: icoTopCard,
    homologueCard: icoTopCard,
    marketDensityCard: icoTopCard,
    dTableInfoCard: icoTopCard,
    productSalesByRegionChart: icoGeographicDistribution,
    productSalesChart: icoHomologueSales,
    productStockVsSalesChart: icoHomologueSales,
    productStockChart: icoHomologueSales,
    productSalesByCategoryChart: icoHomologueSales,
    clientTable: icoClientList,
    competitorDetailsCard: icoProductInformation,
    productDetailsCard: icoProductInformation,
    productFrequentlyBoughtTogether: icoProductInformation,
    associatedTeamsTable: icoProductInformation,
    productsAssociatedTable: icoProductInformation,
    teamsAssociatedTable: icoProductInformation,
    productTable: icoProductInformation,
    salesHomologueChart: icoHomologueSales,
    productDistrictSalesChart: icoGeographicDistribution,
    productYearSalesChart: icoHomologueSales,
    productGeoSalesChart: icoGeographicDistribution,
    productAtc3DonutCard: icoProductInformation,
    productAtc4DonutCard: icoProductInformation,
    productMarketShareForecastChart: icoTimelineForecast,
    productMarketShareUnitsForecastChart: icoTimelineForecast,
    regionGeoSales: icoGeographicDistribution,
    regionGeoMarketShare: icoGeographicDistribution,
    regionGeoMarketIndex: icoGeographicDistribution,
    regionInformation: icoGeographicDistribution,
    productList: icoProductInformation,
    salesByProductCard: icoProductInformation,
    producerDonutSales: icoProductInformation,
    marketShareGlobalDonut: icoProductInformation,
    hospitalAccountInformation: icoProductInformation,
    patientJourney: icoProductInformation,
    swotAnalysis: icoProductInformation,
    accessList: icoClientList,
    dmuList: icoClientList,
    stakeholderList: icoClientList,
    targetCustomerList: icoClientList,
    tacticalPlanList: icoClientList,
    endConsumerInformation: icoClientList,
    dynamicInfoTable: icoProductInformation,
    dynamicInfoTimeseries: icoTimelineForecast,
    financeTableCard: icoTopCard,
}
