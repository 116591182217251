import { createContext, useContext } from 'react';

const NavBarContext = createContext({
    auxOpen: '',
    navOpen: '',
    handleClickIcon: () => {},
    toggleAuxBar: () => {},
    toggleNavBar: () => {},
    openNavDropdown: () => {},
    closeAllOptions: () => {},
    routes: [],
    profileRoutes: []
});

export function NavBarProvider({ value, children }) {
    return (
        <NavBarContext.Provider value={value}>
            {children}
        </NavBarContext.Provider>
    );
}

export function useNavBar() {
    return useContext(NavBarContext);
}
