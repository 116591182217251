export const competitorInformationMock = {
    hoverStyle: '',
    desc: '',
    open: true,
    openNow: '',
    customInfo: [],
    image: 'https://lh3.googleusercontent.com/places/AAcXr8piN5VT53HOxTKVc3I0PAAW6BwdwiL7cjqZiAlvYn-DsSzHKDedrx1G2dZ1kH2QV9C6rhZsLWpM-YkePigwNUl124OIXdsSLKM=s1600-w884-h960',
    name: 'Restaurante O Suiço',
    nameAux: 'o suiço',
    address: 'R. Casa Branca, Lisboa, Portugal',
    zipCode: 0,
    reviewCounter: '',
    reviewRate: '',
    segment: 'Restaurant',
    regionId: 108399210,
    location: '-',
    phone: '-',
    website: '-',
    openingHours: '',
    closed: '-',
    avgCostForTwo: '-',
    cuisine: 'Portuguese',
    peopleCapacity: '',
    terrace: false,
    smokingArea: false,
    takeAway: false,
    homeDelivery: false,
    keywords: [],
    zomatoUrl: '',
    disabled: true,
    zomatoUrlDisabled: true,
    loading: true,
    noData: true,
    attr: 'competitorDetailsCard',
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
    timings: '-',
}
