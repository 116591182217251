import Joi from 'joi';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useToggle } from '../../../hooks/useToggle';
import { TeamsService } from '../../../temp/test';
import { managerTeamListMock } from './mock';

import styles from './manager-team-listing.module.scss';
import { verifyComponent } from '../../../utils/component.js';
import { useModalPopup } from '../../../providers/modal-popup-context.js';
import {
    GlobalModalPopupManagerTeamListing,
    Loader,
    Modal,
    SelectInput,
    Spinner
} from '../../';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays all teams of a selected manager
 */

export const ManagerTeamListing = React.memo(
    ({ selectedTeamId, onSelectTeam, trigger, toggleTrigger, viewOnly }) => {
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [modal, toggleModal] = useToggle(false);
        const [modalType, setModalType] = useState('');
        const [selectedTeam] = useState('');
        const {
            steps,
            selectedTutorialStep,
            currentStep,
            setCurrentStep,
            toggleIsOpen
        } = useModalPopup();
        const { value: data, setValue } =
            useSimpleComponent(managerTeamListMock);
        const {
            value: employeeId,
            toggleDropdown: employeeIdToggleDropdown,
            selectOption: employeeIdSelectOption,
            keyPress: employeeIdKeyPress,
            keyDown: employeeIdKeyDown,
            setValue: employeeIdSetValue,
            setError: employeeIdSetError
        } = useSelectInput({
            name: 'employeeId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const schema = Joi.object({
            employeeId: Joi.number().required()
        });

        const getTeams = useCallback(async () => {
            const dataClone = _.cloneDeep(managerTeamListMock);
            try {
                dataClone.teams.length = 0;
                if (isMounted.current) setValue(dataClone);
                const teamBackData = await TeamsService.getApiTeams({
                    params: undefined,
                    leaderEmpNo: undefined
                });
                if (teamBackData && teamBackData.length !== 0) {
                    teamBackData.map((el) => {
                        const newMember = {
                            id: el.id,
                            deptName: el.name,
                            deptId: el.dept_no,
                            members: el.members,
                            memberCount: el.members.length
                        };
                        dataClone.teams.push(newMember);
                        return el;
                    });
                }
                dataClone.loading = false;
                verifyComponent(dataClone);
                //translateSections(dataClone, t);
                if (isMounted.current) setValue(dataClone);
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [isMounted, setValue]);
        useEffect(() => {
            getTeams();
        }, [getTeams, trigger]);

        const handleCloseModal = () => {
            if (isMounted.current) {
                toggleModal(false);
                setModalType('');
            }
        };

        // const handleSelectSettingsOption = async (attr, id, option) => {
        //     if (isMounted.current) {
        //         toggleComponentSettings(false)
        //         toggleModal(true)
        //         setModalType(option)
        //     }
        //     if (option === 'addMember') {
        //         employeeIdReset()
        //         try {
        //             const vendorBackData = await DefaultService.getApiVendors()
        //             const teamBackData = await TeamsService.getApiTeams({
        //                 params: {[id]},
        //                 leaderEmpNo: undefined,
        //             })
        //             vendorBackData.map((el) => {
        //                 if (
        //                     _.findIndex(teamBackData[0].members, (el2) => {
        //                         return el2.emp_no === el.emp_no
        //                     }) === -1
        //                 ) {
        //                     employeeIdAddOption(el.name, el.emp_no)
        //                 }
        //                 return el
        //             })
        //             setSelectedTeam(id)
        //         } catch (err) {
        //             errorHandler(err)
        //         }
        //     }
        // }

        const handleAddMemberToTeam = async () => {
            try {
                const result = schema.validate(
                    {
                        employeeId: employeeId.selectedOptionAux
                    },
                    { abortEarly: false }
                );
                employeeIdSetError();
                if (result.error) {
                    result.error.details.map((el) => {
                        if (el.path[0] === 'employeeId') {
                            employeeIdSetError(el.type);
                        }
                        return null;
                    });
                    return;
                }
                const teamMemberSchema = {
                    emp_no: parseInt(employeeId.selectedOptionAux),
                    is_leader: undefined
                };
                await TeamsService.postApiTeamsMembers({
                    teamId: selectedTeam,
                    requestBody: [teamMemberSchema]
                });
            } catch (err) {
                errorHandler(err);
            }
            getTeams();
            toggleTrigger();
            if (isMounted.current) toggleModal(false);
        };
        return (
            <>
                {steps && selectedTutorialStep !== '' && (
                    <GlobalModalPopupManagerTeamListing
                        steps={steps}
                        toggleIsOpen={toggleIsOpen}
                        selectedTutorialStep={selectedTutorialStep}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                    />
                )}
                {modal && modalType === 'addMember' && (
                    <Modal onToggleModal={handleCloseModal}>
                        <div className="row mx-0 mb-4">
                            <div className="col px-0">
                                <span className="modal-title">
                                    {t(
                                        'pages:managerProfile.addnewmembertoteam'
                                    )}
                                </span>
                            </div>
                        </div>

                        <div
                            className="row mx-0 "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <SelectInput
                                    onChangeInput={employeeIdSetValue}
                                    onSelectInput={employeeIdSelectOption}
                                    onKeyPress={employeeIdKeyPress}
                                    onKeyDown={employeeIdKeyDown}
                                    onToggleDropdown={employeeIdToggleDropdown}
                                    {...employeeId}
                                />
                            </div>
                        </div>
                        {/* <div className='row mx-0 mb-3'>
									<div className='col px-0'>
										<CheckboxInput
											attr='isLeader'
											options={checkboxInputs.isLeader.options}
											selectedOptions={checkboxInputs.isLeader.selectedOptions}
											onClickCheckboxOption={this.handleClickCheckboxOption}
										/>
									</div>
								</div> */}
                        <div className="row mx-0 d-flex justify-content-between align-items-center">
                            <div className="col-5 px-0">
                                <SecondaryButton
                                    text={t('modals:cancel')}
                                    onClick={handleCloseModal}
                                />
                            </div>
                            <div className="col-5 px-0">
                                <PrimaryButton
                                    text={t('modals:save')}
                                    onClick={handleAddMemberToTeam}
                                />
                            </div>
                        </div>
                    </Modal>
                )}
                <div className={`${styles['manager-team-container']}`}>
                    <div className="row mx-0 h-auto">
                        <div className="col px-0 mb-3 ">
                            <span
                                className={`${styles['manager-team-container__box']} me-2`}
                            >
                                {data.teams.length}
                            </span>
                            <span
                                className={`${styles['manager-team-container__box-aux']}`}
                            >
                                {t(`components:managerTeamContainer.title`)}
                            </span>
                        </div>
                        <div className="col-2 px-0 d-flex justify-content-end align-items-start">
                            {/* <SettingsButton
								attr={data.attr}
								settings={data.settings}
								disabled={data.disabled}
								onClickSettingsView={() => {}}
								onClickSettingsOptions={() => {}}
							/> */}
                        </div>
                    </div>
                    <div
                        className="row mx-0 overflow-auto py-2"
                        style={{ borderRadius: '12px' }}
                    >
                        {data.loading && (
                            <div
                                className={`col px-0 ${styles['manager-team-container__no-data-container']}`}
                            >
                                <Spinner />
                            </div>
                        )}
                        {!data.loading &&
                            data.teams &&
                            data.teams.length === 0 && (
                                <div
                                    className={`col px-0 ${styles['manager-team-container__no-data-container']}`}
                                >
                                    <span
                                        className={`${styles['manager-team-container__no-data-container__text']}`}
                                    >
                                        {data.placeholderText}
                                    </span>
                                </div>
                            )}
                        {!data.loading &&
                            data.teams &&
                            data.teams.length !== 0 && (
                                <div
                                    className={`${styles['manager-team-container__list-container']}`}
                                >
                                    {data.teams.map((el, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    el.id === selectedTeamId
                                                        ? `row mx-0 w-100 ${styles['manager-team-container__list']} ${styles['selected']} mb-2`
                                                        : `row mx-0 w-100 ${styles['manager-team-container__list']} mb-2`
                                                }
                                                onClick={
                                                    !viewOnly
                                                        ? (e) => {
                                                              onSelectTeam(
                                                                  el.id
                                                              );
                                                              e.stopPropagation();
                                                          }
                                                        : undefined
                                                }
                                                style={
                                                    viewOnly
                                                        ? {
                                                              pointerEvents:
                                                                  'none'
                                                          }
                                                        : {}
                                                }
                                            >
                                                <div className="col px-0 d-flex justify-content-start align-items-center ps-3">
                                                    <span
                                                        className={`${styles['manager-team-container__list__title']}`}
                                                    >
                                                        {el.deptName}
                                                    </span>
                                                </div>
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    {/* {_.range(
                                                        1,
                                                        Math.min(
                                                            el.memberCount + 1,
                                                            5
                                                        )
                                                    ).map((el2, index2) => {
                                                        return (
                                                            <img
                                                                key={index2}
                                                                src={el.image}
                                                                className={`${styles['manager-team-container__list__image']}`}
                                                            />
                                                        )
                                                    })} */}
                                                    {/* <span
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'flex-end',
                                                            height: '2em',
                                                        }}
                                                    >
                                                        {Math.max(
                                                            el.memberCount,
                                                            4
                                                        ) > 4
                                                            ? '...'
                                                            : ''}
                                                    </span>
                                                    {!viewOnly && (
                                                        <div
                                                            className={`${styles['manager-team-container__list__image-add']}`}
                                                            onClick={(e) => {
                                                                handleSelectSettingsOption(
                                                                    data.attr,
                                                                    el.id,
                                                                    'addMember'
                                                                )
                                                                e.stopPropagation()
                                                            }}
                                                        >
                                                            <IcoPlus
                                                                alt="add new member to team"
                                                                className={`${styles['manager-team-container__list__image-add__icon']}`}
                                                            />
                                                        </div>
                                                    )} */}
                                                </div>
                                                <div className="col px-0 d-flex justify-content-end align-items-center pe-3">
                                                    <span
                                                        className={`${styles['manager-team-container__list__sub-title']}`}
                                                    >
                                                        {el.memberCount}{' '}
                                                        {t(
                                                            `components:${data.attr}.members`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                    </div>
                </div>
            </>
        );
    }
);

ManagerTeamListing.propTypes = {
    /**
     * Manager Team selected
     */
    selectedTeamId: PropTypes.number,
    /**
     * Propagates selection of a manager team
     */
    onSelectTeam: PropTypes.func,
    /**
     * Propagation of change from parent element
     */
    trigger: PropTypes.bool,
    /**
     * Propagates the changes to the parent element
     */
    toggleTrigger: PropTypes.func
};
