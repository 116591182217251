import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBuilder from '../../../pages/general/report-builder/report-builder';
import styles from './report-layout.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const ReportLayout = React.memo(({ reportId }) => {
    const { t } = useCustomTranslation();
    const [attr] = useState('reportLayout');

    return (
        <div className={`${styles['report-layout-container']}`}>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div
                    className={`col px-0 ${styles['report-layout-container__title-container']}`}
                >
                    <span
                        className={`${styles['report-layout-container__title-container__title']}`}
                    >
                        {t(`components:${attr}.title`)}
                    </span>
                </div>
            </div>
            <div className="row mx-0">
                <div className="col px-0">
                    <ReportBuilder reportId={reportId} />
                </div>
            </div>
        </div>
    );
});
