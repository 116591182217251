import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import icoPin from '../../../assets/images/icoPin.svg';
import icoUnpin from '../../../assets/images/icoUnpin.svg';
import { PagesService } from '../../../temp/test';
import { useRoutes } from '../../../providers/routes-context';
import styles from './pin.module.scss';
import { errorHandler } from '../../../utils/api';
import { usePageDetails } from '../../../providers/page-details-context';
import { usePub } from '../../../hooks/usePubSub';

export const Pin = React.memo(({ component }) => {
    const [pinned, setPinned] = React.useState(false);
    const { onUpdateRoute } = useRoutes();
    const { isHome } = usePageDetails();
    const publish = usePub();
    const handlePinComponent = useCallback(async () => {
        const getPageData = await PagesService.getApiPagesMyPages();
        const notAvailableChartsClone = _.cloneDeep(
            JSON.parse(getPageData.homepage.content).notAvailableCharts
        );
        let maxYH = 0;
        notAvailableChartsClone.map((el) => {
            if (el.dataGrid.y + el.dataGrid.h > maxYH) {
                maxYH = el.dataGrid.y + el.dataGrid.h;
            }
            return el;
        });
        const matrix = Array(maxYH)
            .fill()
            .map(() => Array(12).fill());

        notAvailableChartsClone.map((el) => {
            _.range(el.dataGrid.y, el.dataGrid.y + el.dataGrid.h).map((el2) => {
                _.range(el.dataGrid.x, el.dataGrid.x + el.dataGrid.w).map(
                    (el3) => {
                        matrix[el2][el3] = 1;
                        return el3;
                    }
                );
                return el2;
            });
            return el;
        });
        let componentX = _.range(0, component.dataGrid.w);
        let componentY = _.range(0, component.dataGrid.h);
        let initialX = 0;
        let initialY = maxYH;
        let canPlace = false;
        for (let el2 of _.range(0, maxYH)) {
            for (let el3 of _.range(0, 12)) {
                canPlace = true;
                for (let el4 of componentY) {
                    for (let el5 of componentX) {
                        if (
                            el2 + el4 > maxYH - 1 ||
                            el3 + el5 > 12 - 1 ||
                            matrix[el2 + el4][el3 + el5] === 1
                        ) {
                            canPlace = false;
                            break;
                        }
                    }
                }
                if (canPlace) {
                    initialX = el3;
                    initialY = el2;
                    break;
                }
            }
            if (canPlace) {
                break;
            }
        }
        const newComponent = _.cloneDeep(component);
        newComponent.dataGrid.x = initialX;
        newComponent.dataGrid.y = initialY;
        notAvailableChartsClone.push(newComponent);
        await onUpdateRoute(
            JSON.parse(getPageData.homepage.content).dashboardTitle,
            JSON.parse(getPageData.homepage.content).availableCharts,
            notAvailableChartsClone
        );
        setPinned(true);
        return;
    }, [onUpdateRoute, component]);

    const handleUnpinComponent = useCallback(async () => {
        const getPageData = await PagesService.getApiPagesMyPages();
        const pageContent = JSON.parse(getPageData.homepage.content);
        const notAvailableChartsClone = _.cloneDeep(
            pageContent.notAvailableCharts
        );

        const notAvailableChartsIndex = _.findIndex(
            notAvailableChartsClone,
            (el) => el.type === component.type
        );

        if (notAvailableChartsIndex !== -1) {
            // Remove the unpinned component
            notAvailableChartsClone.splice(notAvailableChartsIndex, 1);

            // Assuming grid width is 12 units, adjust if different
            const gridWidth = 12;
            // Initialize an array to keep track of the y position for each column
            let columnsHeight = new Array(gridWidth).fill(0);

            // Function to find the next available position for the chart
            const findNextAvailablePosition = (chart) => {
                let positionFound = false;
                let x = 0,
                    y = 0;

                while (!positionFound) {
                    // Find column with minimum height
                    let minHeight = Math.min(...columnsHeight);
                    x = columnsHeight.indexOf(minHeight);

                    // Check if the chart fits in the current columns
                    let fits = true;
                    for (
                        let i = x;
                        i < x + chart.dataGrid.w && i < gridWidth;
                        i++
                    ) {
                        if (columnsHeight[i] > minHeight) {
                            fits = false;
                            break;
                        }
                    }

                    if (fits && x + chart.dataGrid.w <= gridWidth) {
                        positionFound = true;
                        y = minHeight;
                    } else {
                        // Increase the minimum column height to force the search to move to the next possible position
                        columnsHeight[x] += 1;
                    }
                }

                // Update columnsHeight for the width of the chart
                for (let i = x; i < x + chart.dataGrid.w; i++) {
                    columnsHeight[i] = y + chart.dataGrid.h;
                }

                return { x, y };
            };

            // Calculate new positions
            notAvailableChartsClone.forEach((chart) => {
                const { x, y } = findNextAvailablePosition(chart);
                chart.dataGrid.x = x;
                chart.dataGrid.y = y;
            });

            // Update the page content with the new layout
            await onUpdateRoute(
                pageContent.dashboardTitle,
                pageContent.availableCharts,
                notAvailableChartsClone
            );

            setPinned(false); // Assuming this updates the state to reflect the unpinned status
        }
    }, [onUpdateRoute, component.type, setPinned]);

    useEffect(() => {
        async function fill() {
            try {
                const getPageData = await PagesService.getApiPagesMyPages();
                JSON.parse(getPageData.homepage.content).notAvailableCharts.map(
                    (el) => {
                        if (el.type === component.type) {
                            setPinned(true);
                        }
                        return el;
                    }
                );
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [component.type]);

    return (
        <div className="d-flex align-items-center justify-content-center component-icon">
            <img
                loading="lazy"
                src={pinned ? icoUnpin : icoPin}
                alt=""
                onClick={async () => {
                    if (pinned) {
                        await handleUnpinComponent(component.type);
                    } else {
                        await handlePinComponent(component.type);
                    }
                    if (isHome) {
                        publish('dashboard');
                    }
                }}
                className={`${styles['pin-container__ico-pin']}`}
            />
        </div>
    );
});
