import styles from './draft-dashboard.module.scss'

import _ from 'lodash'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { WidthProvider, Responsive } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import CustomDashboard from '../custom-dashboard/custom-dashboard'
import { useAuxBar } from '../../../providers/aux-bar-context'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useDashboardSettings } from '../../../providers/dashboard-settings-context'
import { useModalPopup } from '../../../providers/modal-popup-context'
import { errorHandler } from '../../../utils/api'
import { PagesService } from '../../../temp/test'
import { maxComponentCount } from '../../../constants/component'
import { useHighlightedChart } from '../../../providers/highlighted-chart-context'
import { useLocation } from 'react-router-dom'
import componentConfig from '../../../configs/componentLibraryConf.json'
import {
    DeleteButton,
    EditOptions,
    LibraryComponentFactory,
    LoaderForeground,
    MyResizeHandle,
    PrimaryButton,
    ReturnButton,
    SecondaryButton,
    Spinner,
    TerciaryButton,
} from '../../../components'
import { error, success } from '../../../components/notifications/notification/notification'
import { ExampleBackground } from '../../../layout/example-background/example-background'
import Auxbar from '../../../layout/auxbar/auxbar'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { ActionBar } from '../../../components/actions/action-bar/action-bar'
import icoSaveCurrentDashboard from '../../../assets/images/icoSaveCurrentDashboard.svg'
import icoDeleteCurrentDashboard from '../../../assets/images/icoDeleteComponent.svg'
import icoPreviewCurrentDashboard from '../../../assets/images/icoPreviewCurrentDashboard.svg'
import icoPublishCurrentDashboard from '../../../assets/images/icoPublishCurrentDashboard.svg'
import icoArrowBackAction from '../../../assets/images/icoArrowBackAction.svg'
import { usePageDetails } from '../../../providers/page-details-context'

const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }

const ResponsiveGridLayout = WidthProvider(Responsive)

/**
 * Provides a page layout that allows users to edit dashboards
 */

const DraftDashboard = React.memo(
    ({
        dashboardTitle,
        url,
        dropdownList,
        notAvailableCharts,
        setNotAvailableCharts,
        availableCharts,
        resizableGrid,
        onPageLayoutChange,
        onPageDropChart,
        loading,
        onUpdateRoute,
        onPublishRoute,
        selectedOption,
        onDeletePageChart,
        onOpenChartConfig,
        onSetLayout,
        setFilterInputs,
        setDropdownList,
        onRemoveRoute,
        onResetGrid,
    }) => {
        const { isHome } = usePageDetails()
        const { t } = useCustomTranslation()
        const { setAvailableOptions, setIsBuilder } = useAuxBar()
        const { highlightedChart, setHighlightedChart } = useHighlightedChart()
        const { setSelectedAvailableOption } = useAuxBar()

        const isMounted = useIsMounted()
        const { isEdit, toggleIsEdit } = useDashboardSettings()
        const [editPageState, setEditPageState] = useState('type')
        const [lastState, setLastState] = useState('')
        const { steps, selectedTutorialStep, currentStep, setCurrentStep } = useModalPopup()
        const [currentWidthSectionMultiplier, setCurrentWidthSectionMultiplier] = useState(1)

        const newSize = useCallback((width) => {
            if (width < 600) {
                setCurrentWidthSectionMultiplier(0.6)
            } else if (width < 800) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1200) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1400) {
                setCurrentWidthSectionMultiplier(0.8)
            } else {
                setCurrentWidthSectionMultiplier(1)
            }
            return
        }, [])

        useEffect(() => {
            // Create a ResizeObserver instance
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    // Access the new width and height
                    const { width } = entry.contentRect
                    newSize(width)
                }
            })

            resizeObserver.observe(document.documentElement)
            return () => {
                resizeObserver.disconnect()
            }
        }, [newSize])

        const handlePublishPage = (...args) => {
            let newDropdownList = []
            notAvailableCharts.map((el) => {
                if (el.config.filter) {
                    el.config.filter.map((el) => {
                        if (!newDropdownList.includes(el)) {
                            newDropdownList.push(el)
                        }
                        return el
                    })
                }
                return el
            })
            setFilterInputs(newDropdownList)
            onPublishRoute(...args)
        }

        const handleNavigate = async (currentPage, page, direction, to) => {
            if (direction === 'front') {
                if (to === 'components') {
                    if (isMounted.current) setAvailableOptions(['store'])
                    else {
                        if (isMounted.current) setEditPageState(to)
                    }
                    setFilterInputs([])
                } else {
                    let newDropdownList = []
                    notAvailableCharts.map((el) => {
                        if (el.config.filter) {
                            el.config.filter.map((el) => {
                                if (!newDropdownList.includes(el)) {
                                    newDropdownList.push(el)
                                }
                                return el
                            })
                        }
                        return el
                    })
                    if (isMounted.current) {
                        if (newDropdownList.length > 1 || (newDropdownList.length === 1 && !newDropdownList.includes('none'))) {
                            setAvailableOptions(['search'])
                            setSelectedAvailableOption('search')
                        } else {
                            setAvailableOptions([])
                            setSelectedAvailableOption('')
                        }
                        setFilterInputs(newDropdownList)
                        setDropdownList(newDropdownList)
                        setEditPageState(to)
                    }
                }
            } else if (direction === 'back') {
                if (currentPage === 'components') {
                    if (isMounted.current) setEditPageState(to)
                } else {
                    if (isMounted.current) setEditPageState(lastState)
                }
                if (to === 'components') {
                    if (isMounted.current) setAvailableOptions(['store'])
                    setFilterInputs([])
                } else {
                    if (isMounted.current) setAvailableOptions([])
                }
            }

            if (isMounted.current) setLastState(currentPage)
        }

        const handleDeletePage = async () => {
            let url = dashboardTitle.toLowerCase().replaceAll(' ', '-')
            try {
                await PagesService.deleteApiPages({ url: url })
                onRemoveRoute(url)
            } catch (err) {
                errorHandler(err)
            }
        }

        useEffect(() => {
            if (editPageState !== 'type') {
                setHighlightedChart('')
            }
        }, [editPageState, setHighlightedChart])

        useEffect(() => {
            setFilterInputs([])
        }, [setFilterInputs])

        const handleCheckFilterInputs = () => {
            let newDropdownList = []
            notAvailableCharts.map((el) => {
                if (el.config.filter) {
                    el.config.filter.map((el) => {
                        if (!newDropdownList.includes(el)) {
                            newDropdownList.push(el)
                        }
                        return el
                    })
                }
                return el
            })
            if (isMounted.current) {
                if (newDropdownList.length > 1 || (newDropdownList.length === 1 && !newDropdownList.includes('none'))) {
                    setAvailableOptions(['search'])
                    setSelectedAvailableOption('search')
                } else {
                    setAvailableOptions([])
                    setSelectedAvailableOption('')
                }
                setFilterInputs(newDropdownList)
                setDropdownList(newDropdownList)
            }
        }

        useEffect(() => {
            return () => {
                setIsBuilder(false)
            }
        }, [setIsBuilder])

        useEffect(() => {
            if (editPageState !== 'preview') {
                setIsBuilder(true)
            } else {
                setIsBuilder(false)
            }
        }, [editPageState, setIsBuilder])

        useEffect(() => {
            if (editPageState === 'creationOptions') {
                setNotAvailableCharts([])
            }
        }, [editPageState, setNotAvailableCharts])

        const droppingItemMemo = useMemo(() => {
            if (!_.isEmpty(resizableGrid.droppingElement)) {
                const { isDynamicComponent } = resizableGrid.droppingElement
                return {
                    i: resizableGrid.droppingElement.i,
                    w: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.w
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.w,
                    h: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.h
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.h,
                    maxW: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.maxW
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.maxW,
                    maxH: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.maxH
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.maxH,
                    minW: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.minW
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.minW,
                    minH: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.minH
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.minH,
                    isDynamicComponent: isDynamicComponent,
                    name: resizableGrid.droppingElement.name,
                    type: resizableGrid.droppingElement.type,
                }
            } else return undefined
        }, [resizableGrid.droppingElement])

        if (editPageState !== 'preview') {
            return (
                <>
                    {editPageState === 'initial' && !loading && (
                        <div className="row mx-0 w-100 h-100">
                            <div className="col px-0 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 pb-1">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <img
                                            loading="lazy"
                                            src={undefined}
                                            alt="icon"
                                            className={`${styles['custom-dashboard-container__no-selection-icon']}`}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 pt-1">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <span className={`${styles['custom-dashboard-container__no-selection-text']}`}>
                                            {t(`pages:customDashboard.noSelectionText.${dashboardTitle}`)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {loading && <LoaderForeground />}
                    {!loading && (
                        <div className="row mx-0 w-100 h-100">
                            <div className={`col-12 ${styles['draft-dashboard-container']}`}>
                                <div className={`row mx-0 flex-grow-1 ${styles['draft-dashboard-container__layout-container']}`}>
                                    <div className="col px-0">
                                        {!isEdit && (
                                            <div className={`row mx-0 w-100 dashboard-header-row mb-4`}>
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <span className={`${styles['draft-dashboard-container__title-row__title']} me-4`}>
                                                        {t('pages:page-management.draft-dashboard.title')}
                                                    </span>
                                                </div>
                                                <div className="col-auto px-0 me-4 d-flex justify-content-end align-items-center">
                                                    <ActionBar
                                                        actions={[
                                                            {
                                                                text: t('buttons:saveasdraft'),
                                                                icon: icoSaveCurrentDashboard,
                                                                onClick: async () => {
                                                                    await onUpdateRoute(dashboardTitle, availableCharts, notAvailableCharts)
                                                                    success({
                                                                        statusText: undefined,
                                                                        text: `${t('notifications:savednewdraft')}`,
                                                                        date: undefined,
                                                                    })
                                                                },
                                                            },
                                                            {
                                                                text: t('buttons:delete'),
                                                                icon: icoDeleteCurrentDashboard,
                                                                onClick: () => {
                                                                    handleDeletePage()
                                                                },
                                                            },
                                                            {
                                                                text: t('buttons:preview'),
                                                                icon: icoPreviewCurrentDashboard,
                                                                onClick: () => {
                                                                    handleNavigate('components', selectedOption, 'front', 'preview')
                                                                },
                                                            },
                                                            {
                                                                text: t('buttons:publish'),
                                                                icon: icoPublishCurrentDashboard,
                                                                onClick: () => {
                                                                    if (
                                                                        steps &&
                                                                        selectedTutorialStep !== '' &&
                                                                        steps[selectedTutorialStep]['steps'][currentStep]['waitUntilTrigger'] &&
                                                                        steps[selectedTutorialStep]['steps'][currentStep]['triggerPoint'] ===
                                                                            'createDashboard'
                                                                    ) {
                                                                        setCurrentStep(currentStep + 1)
                                                                    }
                                                                    handlePublishPage(dashboardTitle, availableCharts, notAvailableCharts)
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {isEdit && (
                                            <div className={`row mx-0 w-100 dashboard-header-row mb-4 d-flex justify-content-between`}>
                                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                                    <span className={`${styles['draft-dashboard-container__title-row__title']} me-4`}>
                                                        {t('pages:page-management.draft-dashboard.title')}
                                                    </span>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <ActionBar
                                                        actions={
                                                            isHome
                                                                ? [
                                                                      {
                                                                          text: t('buttons:cancel'),
                                                                          icon: icoArrowBackAction,
                                                                          onClick: () => {
                                                                              onResetGrid()
                                                                              toggleIsEdit(false)
                                                                          },
                                                                      },

                                                                      {
                                                                          text: t('buttons:preview'),
                                                                          icon: icoPreviewCurrentDashboard,
                                                                          onClick: () => {
                                                                              handleNavigate('components', selectedOption, 'front', 'preview')
                                                                          },
                                                                      },
                                                                      {
                                                                          text: t('buttons:save'),
                                                                          icon: icoSaveCurrentDashboard,
                                                                          onClick: () => {
                                                                              handleCheckFilterInputs()
                                                                              onUpdateRoute(dashboardTitle, availableCharts, notAvailableCharts)

                                                                              toggleIsEdit(false)
                                                                          },
                                                                      },
                                                                  ]
                                                                : [
                                                                      {
                                                                          text: t('buttons:cancel'),
                                                                          icon: icoArrowBackAction,
                                                                          onClick: () => {
                                                                              onResetGrid()
                                                                              toggleIsEdit(false)
                                                                          },
                                                                      },
                                                                      {
                                                                          text: t('buttons:delete'),
                                                                          icon: icoDeleteCurrentDashboard,
                                                                          onClick: () => {
                                                                              handleDeletePage()
                                                                          },
                                                                      },
                                                                      {
                                                                          text: t('buttons:preview'),
                                                                          icon: icoPreviewCurrentDashboard,
                                                                          onClick: () => {
                                                                              handleNavigate('components', selectedOption, 'front', 'preview')
                                                                          },
                                                                      },
                                                                      {
                                                                          text: t('buttons:save'),
                                                                          icon: icoSaveCurrentDashboard,
                                                                          onClick: () => {
                                                                              handleCheckFilterInputs()
                                                                              onUpdateRoute(dashboardTitle, availableCharts, notAvailableCharts)

                                                                              toggleIsEdit(false)
                                                                          },
                                                                      },
                                                                  ]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className={`row mx-0 w-100 ${styles['draft-dashboard-container__section']}`}>
                                            <div className={`col ${styles['draft-dashboard-container__layout-container__options-container']}`}>
                                                <div
                                                    className={`row mx-0 flex-grow-1 ${styles['draft-dashboard-container__dashboard-template-container']}`}
                                                >
                                                    <div
                                                        className={`col px-0 d-flex flex-column ${styles['draft-dashboard-container__dashboard-template-container__invisible']}`}
                                                    >
                                                        <div className="row mx-0 w-100 h-100">
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`col px-0 d-flex flex-column ${styles['draft-dashboard-container__dashboard-template-container__background']}`}
                                                    >
                                                        <div className="row mx-0 w-100 h-100">
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']} d-flex justify-content-center align-items-center`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                            <div
                                                                className={`col h-100 ${styles['draft-dashboard-container__dashboard-template-container__background__column']}`}
                                                            ></div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="col px-0 d-flex flex-column"
                                                        style={{
                                                            minHeight: '100%',
                                                        }}
                                                    >
                                                        <ResponsiveGridLayout
                                                            className={
                                                                resizableGrid.gridMounted ? 'layout mx-0 animated w-100' : 'layout w-100 mx-0 '
                                                            }
                                                            cols={cols}
                                                            rowHeight={currentWidthSectionMultiplier * 30}
                                                            maxRows={resizableGrid.maxRows}
                                                            compactType={resizableGrid.compactType}
                                                            verticalCompact={resizableGrid.verticalCompact}
                                                            layout={resizableGrid.layout}
                                                            onLayoutChange={
                                                                !_.isEmpty(resizableGrid.droppingElement)
                                                                    ? () => {}
                                                                    : (layout) => onPageLayoutChange(selectedOption, layout)
                                                            }
                                                            isDraggable={resizableGrid.isEditable}
                                                            isResizable={resizableGrid.isEditable}
                                                            isDroppable={true}
                                                            onDrop={(layout, layoutItem, _event) => {
                                                                if (notAvailableCharts.length > maxComponentCount) {
                                                                    error({
                                                                        text: t('pages:page-management.create-dashboard.maxComponentsError'),
                                                                    })
                                                                } else if (layoutItem) {
                                                                    onPageDropChart(selectedOption, layout, layoutItem, _event)
                                                                }
                                                                _event.preventDefault()
                                                            }}
                                                            droppingItem={droppingItemMemo}
                                                            preventCollision={false}
                                                            draggableHandle={resizableGrid.draggableHandle}
                                                            margin={resizableGrid.margin}
                                                            containerPadding={resizableGrid.containerPadding}
                                                            resizeHandle={resizableGrid.isEditable ? <MyResizeHandle /> : ''}
                                                            autoSize={resizableGrid.autoSize}
                                                            isBounded={resizableGrid.isBounded}
                                                            useCSSTransforms={false}
                                                            measureBeforeMount={false}
                                                        >
                                                            {notAvailableCharts.map((el) => {
                                                                return (
                                                                    <div
                                                                        data-grid={el.dataGrid}
                                                                        key={el.dataGrid.i}
                                                                        className={`${styles['draft-dashboard-container__dashboard-template-container__chart-container']}`}
                                                                        style={
                                                                            highlightedChart && el.dataGrid.i === highlightedChart.dataGrid.i
                                                                                ? {
                                                                                      zIndex: 1000,
                                                                                  }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        <LibraryComponentFactory
                                                                            hoveredFilter={''}
                                                                            hoveredComponents={[]}
                                                                            component={el}
                                                                            onPinComponent={() => {}}
                                                                            onUnpinComponent={() => {}}
                                                                            pinnedComponents={[]}
                                                                            onMouseEnterInfoIcon={() => {}}
                                                                            onMouseLeaveInfoIcon={() => {}}
                                                                            type={el.type}
                                                                            salesDataTimeWindow={'month'}
                                                                            isExample
                                                                            showAllInfo
                                                                        />
                                                                        <ExampleBackground />
                                                                        <EditOptions
                                                                            type={el.dataGrid.i}
                                                                            config={el.config}
                                                                            onHighlightChart={() => {
                                                                                setHighlightedChart(el)
                                                                            }}
                                                                            onOpenChartConfig={onOpenChartConfig}
                                                                            onDeleteChart={onDeletePageChart}
                                                                        />
                                                                    </div>
                                                                )
                                                            })}
                                                        </ResponsiveGridLayout>
                                                        {highlightedChart && (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    position: 'fixed',
                                                                    backgroundColor: '#000',
                                                                    opacity: '0.3',
                                                                    top: 0,
                                                                    left: 0,
                                                                    zIndex: 100,
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-auto px-0">
                                <Auxbar
                                    isBuilder
                                    includeStore={
                                        location.pathname !== '/dashboard'
                                    }
                                    availableCharts={availableCharts}
                                />
                            </div> */}
                        </div>
                    )}
                </>
            )
        } else if (editPageState === 'preview') {
            return (
                <CustomDashboard
                    type="draft"
                    initialDashboardTitle={dashboardTitle}
                    initialUrl={url}
                    initialNotAvailableCharts={notAvailableCharts}
                    initialAvailableCharts={availableCharts}
                    initialDropdownList={dropdownList}
                    resizableGrid={resizableGrid}
                    onUpdateRoute={onUpdateRoute}
                    onPublishRoute={onPublishRoute}
                    onNavigate={handleNavigate}
                    lastState={lastState}
                    onSetLayout={onSetLayout}
                    isPreview
                />
            )
        } else return null
    }
)

export default DraftDashboard

DraftDashboard.propTypes = {
    /** Labels the dashboar */
    dashboardTitle: PropTypes.string,
    /**
     * Set of filters to fill preview state of layout
     */
    dropdownList: PropTypes.arrayOf(PropTypes.string),
    /**
     * Charts to populate dashboard layout
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Charts to populate library
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Object containing grid management
     */
    resizableGrid: PropTypes.object,
    /**
     * Handles changes to the layout
     */
    onPageLayoutChange: PropTypes.func,
    /**
     * Handles dropping a chart into the layout
     */
    onPageDropChart: PropTypes.func,
    /**
     * Provides manual management of the loading state
     */
    loading: PropTypes.bool,
    /**
     * Allows the header to contain buttons for updating the dashboard
     */
    onUpdateRoute: PropTypes.func,
    /**
     * Allows the header to contain buttons for publishing the dashboard
     */
    onPublishRoute: PropTypes.func,
    /**
     * Allows to return to the selected template
     */
    selectedOption: PropTypes.string,
    /**
     * Allows removal of charts back into the library store
     */
    onDeletePageChart: PropTypes.func,
    /**
     * Provides individual management for each chart
     */
    onOpenChartConfig: PropTypes.func,
    /**
     * Contains the step of the workflow
     */
    editPageState: PropTypes.string,
    /**
     * Allows user to return to different steps when on the preview step
     */
    lastState: PropTypes.string,
    /**
     * Handles changes in dashboard layout
     */
    onSetLayout: PropTypes.func,
}
