import React, { useEffect, useRef, useState } from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { errorHandler } from '../../../../../utils/api';
import {
    Modal,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
    SuccessModal,
    TypeInput
} from '../../../../../components';
import { useTextInput } from '../../../../../hooks/useTextInput';
import { useSelectInput } from '../../../../../hooks/useSelectInput';
import { MemberCard } from '../member-card/MemberCard';

const mockCard = {
    id: 1,
    name: 'Member 1',
    email: 'member1@example.com',
    contact: '1234560001',
    type: 'enterprise',
    role: 'admin',
    teams: ['Team 1'],
    action: {
        id: 1,
        open: false,
        options: [
            {
                name: 'edit',
                icon: '/static/media/ico-edit.94a244b4eab75da5e970d07406f7272a.svg'
            },
            {
                name: 'transferMember',
                icon: '/static/media/icoTransferMember.e43d99e66887bbf1f1f31f07fd683929.svg'
            },
            {
                name: 'remove',
                icon: '/static/media/ico-delete.27daa500e241fb9569115ef732833b2c.svg',
                isRed: true
            }
        ],
        reference: {
            current: null
        }
    }
};

export const EditMemberModal = ({ memberId, onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        async function fill() {
            try {
                // const dataBack = await TeamsService.getApiTeamsMembers1({
                //     teamId: teamId,
                //     memberId: teamMemberId
                // });
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, []);

    const handleeditMember = async () => {
        try {
            setLoading(true);
            // await TeamsService.patchApiTeamsMembers({
            //     teamId: teamId,
            //     memberId: teamMemberId,
            //     requestBody: {
            //         employee: {
            //             first_name: firstName.value,
            //             last_name: lastName.value
            //         },
            //         is_leader:
            //             isResponsible.selectedOption === 'yes' ? true : false
            //     }
            // });
            setIsSuccess(true);
            setLoading(false);
            onEnd();
        } catch (err) {
            setLoading(false);
            errorHandler(err);
        }
    };

    if (!isSuccess) {
        return (
            <Modal
                onToggleModal={() => onCloseModal(false)}
                isLoading={isLoading}
            >
                <MemberCard {...mockCard} isEdit />
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:editMember.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
