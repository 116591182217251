import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useModalPopup } from '../../../../../providers/modal-popup-context'
import { PrimaryButton, SecondaryButton } from '../../../../'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { ActionBar } from '../../../../actions/action-bar/action-bar'
import icoArrowBackAction from '../../../../../assets/images/icoArrowBackAction.svg'
import icoChooseTemplateAction from '../../../../../assets/images/icoChooseTemplateAction.svg'

export const PreviewHeader = React.memo(
    ({
        templateName,
        onChooseTemplate,
        onReturn,
        templateOption,
        notAvailableCharts,
    }) => {
        const { t } = useCustomTranslation()
        const { steps, selectedTutorialStep, currentStep, setCurrentStep } =
            useModalPopup()
        return (
            <div className="row w-100 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <span className="header-title me-4">
                        {t(`components:previews.${templateName}.title`)}
                    </span>
                </div>
                <div className="col-auto px-0">
                    <ActionBar
                        actions={[
                            {
                                text: t('buttons:cancel'),
                                icon: icoArrowBackAction,
                                onClick: () => {
                                    if (
                                        steps &&
                                        selectedTutorialStep !== '' &&
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ]['waitUntilTrigger'] &&
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ]['triggerPoint'] === 'createDashboard'
                                    ) {
                                        setCurrentStep(currentStep + 1)
                                    }
                                    onReturn()
                                },
                            },
                            {
                                text: t('buttons:chooseTemplate'),
                                icon: icoChooseTemplateAction,
                                onClick: () => {
                                    if (
                                        steps &&
                                        selectedTutorialStep !== '' &&
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ]['waitUntilTrigger'] &&
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ]['triggerPoint'] === 'createDashboard'
                                    ) {
                                        setCurrentStep(currentStep + 1)
                                    }
                                    onChooseTemplate(templateOption)
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
)

PreviewHeader.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
    /**
     * Allows the user to return to the correct template if on the workflow of creating a new dashboard
     */
    selectedOption: PropTypes.string,
    /**
     * Contains the list of library charts to populate the store in case the user updates the page layout
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Contains the list of charts when updating the layout
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Function allowing the user to 'go back'
     */
    onNavigate: PropTypes.func,
    /**
     * Fuction allowing the user to either save a new layout of a published page or creating a new page
     */
    onCreatePage: PropTypes.func,
}
