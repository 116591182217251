import { createContext, useContext, useMemo, useState } from 'react';
import config from '../configs/apiConfig';

const PlatformFormatContext = createContext({
    format: undefined,
    setFormat: () => {}
});

export function PlatformFormatProvider({ children }) {
    const [format, setFormat] = useState(config.platformFormat || '');
    const valueMemo = useMemo(() => {
        return {
            format: format,
            setFormat: setFormat
        };
    }, [format]);
    return (
        <PlatformFormatContext.Provider value={valueMemo}>
            {children}
        </PlatformFormatContext.Provider>
    );
}

export function usePlatformFormat() {
    return useContext(PlatformFormatContext);
}
