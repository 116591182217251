import React, { useState } from 'react';
import styles from './time-period-input.module.scss';
import icoCalendarColor from '../../../assets/images/icoCalendarColor.svg';
import icoArrow from '../../../assets/images/dropdown-arrow.svg';
import { DateInputAlternative } from '../date-input-alternative/date-input-alternative';
import { DatePickerAlternative } from '../date-picker-alternative/date-picker-alternative';
import { fromIsoDate, toIsoDateFromDate } from '../../../utils/format';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const TimePeriodInput = ({ startDate, endDate, onChangeInput }) => {
    const [selectedOption, setSelectedOption] = useState(undefined);
    const { t } = useCustomTranslation();
    const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const handleChangeInput = (...args) => {
        onChangeInput(...args);
    };

    const handleSelectCustomDate = (option) => {
        if (option === selectedOption) {
            onChangeInput('', 'startDate', true);
            onChangeInput('', 'endDate', true);
            setSelectedOption(undefined);
            return;
        }
        let startDate;
        let endDate;
        if (option === 'fy') {
            startDate = new Date(currentYear, 0, 1);
            endDate = new Date(currentYear, 11, 31);
        } else if (option === 'h1') {
            startDate = new Date(currentYear, 0, 1);
            endDate = new Date(currentYear, 5, 30);
        } else if (option === 'h2') {
            startDate = new Date(currentYear, 6, 1);
            endDate = new Date(currentYear, 11, 31);
        } else if (option === 'q1') {
            startDate = new Date(currentYear, 0, 1);
            endDate = new Date(currentYear, 2, 31);
        } else if (option === 'q2') {
            startDate = new Date(currentYear, 3, 1);
            endDate = new Date(currentYear, 5, 30);
        } else if (option === 'q3') {
            startDate = new Date(currentYear, 6, 1);
            endDate = new Date(currentYear, 8, 30);
        } else if (option === 'q4') {
            startDate = new Date(currentYear, 9, 1);
            endDate = new Date(currentYear, 11, 31);
        }
        onChangeInput(
            fromIsoDate(toIsoDateFromDate(startDate)),
            'startDate',
            true
        );
        onChangeInput(fromIsoDate(toIsoDateFromDate(endDate)), 'endDate', true);
        setSelectedOption(option);
    };

    const handleChangeCurrentYear = (year) => {
        if (!selectedOption) {
            setCurrentYear(year);
            return;
        }
        const startDate = new Date(year, 0, 1);
        const endDate = new Date(year, 11, 31);
        onChangeInput(
            fromIsoDate(toIsoDateFromDate(startDate)),
            'startDate',
            true
        );
        onChangeInput(fromIsoDate(toIsoDateFromDate(endDate)), 'endDate', true);
        setCurrentYear(year);
    };

    return (
        <div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                    <img
                        src={icoArrow}
                        alt="arrow"
                        style={{
                            cursor: 'pointer',
                            transform: 'rotate(90deg)',
                            marginRight: '2rem'
                        }}
                        onClick={() => {
                            handleChangeCurrentYear(currentYear - 1);
                        }}
                    />

                    <span
                        className={styles['time-period-input-container__year']}
                    >
                        {currentYear}
                    </span>
                    <img
                        src={icoArrow}
                        alt="arrow"
                        style={{
                            cursor: 'pointer',
                            transform: 'rotate(-90deg)',
                            marginLeft: '2rem'
                        }}
                        onClick={() => {
                            handleChangeCurrentYear(currentYear + 1);
                        }}
                    />
                </div>
            </div>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col-auto px-0" style={{ width: '40rem' }}>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'fy'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('fy')}
                            >
                                <span>FY</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'h1'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('h1')}
                            >
                                <span>H1</span>
                            </div>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{ width: '1rem' }}
                        ></div>
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'h2'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('h2')}
                            >
                                <span>H2</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'q1'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('q1')}
                            >
                                <span>Q1</span>
                            </div>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{ width: '1rem' }}
                        ></div>
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'q2'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('q2')}
                            >
                                <span>Q2</span>
                            </div>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{ width: '1rem' }}
                        ></div>
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'q3'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('q3')}
                            >
                                <span>Q3</span>
                            </div>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{ width: '1rem' }}
                        ></div>
                        <div className="col px-0">
                            <div
                                className={
                                    selectedOption === 'q4'
                                        ? `${styles['time-period-input-container__button']} ${styles['selected']}`
                                        : styles[
                                              'time-period-input-container__button'
                                          ]
                                }
                                onClick={() => handleSelectCustomDate('q4')}
                            >
                                <span>Q4</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col-auto px-0">
                    <DateInputAlternative
                        {...startDate}
                        onChangeInput={onChangeInput}
                    />
                </div>
                <div
                    className="col-auto px-0 d-flex justify-content-center align-items-center"
                    style={{ width: '3rem', marginTop: '1rem' }}
                >
                    <div
                        style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: '#D6D6D6',
                            borderRadius: '50%'
                        }}
                    ></div>
                </div>
                <div className="col-auto px-0 position-relative">
                    <DateInputAlternative
                        {...endDate}
                        onChangeInput={handleChangeInput}
                    />
                    <div
                        className={
                            styles['time-period-input-container__custom-date']
                        }
                        onClick={() => {
                            setIsCustomDateOpen(!isCustomDateOpen);
                        }}
                    >
                        <img
                            src={icoCalendarColor}
                            alt="calendar"
                            style={{ marginRight: '1rem' }}
                        />
                        <span>{t('inputs:customDate')}</span>
                    </div>
                </div>
            </div>
            {isCustomDateOpen && (
                <div
                    className="row mx-0 w-100 d-flex justify-content-center"
                    style={{ marginTop: '2rem' }}
                >
                    <div className="col-auto px-0">
                        <div
                            className={
                                styles[
                                    'time-period-input-container__custom-date__dropdown'
                                ]
                            }
                        >
                            <div
                                className={
                                    styles[
                                        'time-period-input-container__custom-date__dropdown__triangle'
                                    ]
                                }
                            ></div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center align-items-start">
                                    <DatePickerAlternative
                                        name={'startDate'}
                                        value={startDate.value}
                                        onChangeInput={handleChangeInput}
                                    />
                                </div>
                                <div
                                    className="col-auto px-0"
                                    style={{ width: '5rem' }}
                                ></div>
                                <div className="col px-0 d-flex justify-content-center align-items-start">
                                    <DatePickerAlternative
                                        name={'endDate'}
                                        value={endDate.value}
                                        onChangeInput={handleChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
