import React, { useMemo } from 'react'
import styles from './segment-ai.module.scss'
import icoGenerateAgain from '../../../../../assets/images/icoGenerateAgain.svg'
import icoBack from '../../../../../assets/images/icoArrowBackAction.svg'
import icoSave from '../../../../../assets/images/icoSaveCurrentDashboard.svg'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { BreadCrumbs } from '../../../../../components/formatting/breadcrumbs/BreadCrumbs'
import { ActionBar } from '../../../../../components/actions/action-bar/action-bar'
import { Cassete } from '../../../../../components/formatting/cassete/cassete'
import { PieChartNew } from '../../../../../components/charts/pie-chart-new/pie-chart-new'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { SegmentPreview } from '../../components/segment-preview/segment-preview'
import { RenderCheckboxBox } from '../../../../../components/inputs/checkbox-input-new/checkbox-input-new'

const mockData = {
    data: {
        data1: [
            {
                key1: 'Products Market Share',
                value: 100,
                isGradient: true,
                color: '#FD9126',
                name: 'Products Market Share',
                percentage: 0.75,
            },
            {
                key1: 'Others',
                value: 1000,
                color: '#FDDCB2',
                name: 'Others',
                percentage: 0.25,
            },
        ],
    },
    attr: 'segmentUsersIncluded',
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1',
        },
    ],
}

export const SegmentAi = () => {
    const { t } = useCustomTranslation()
    const location = useLocation()
    const resultData = useMemo(() => {
        return location.state.data
    }, [location.state])
    const crumbsMemo = useMemo(() => {
        return [
            {
                url: '/segment-analysis/overview',
                label: t('breadcrumbs:segmentAnalysis.overview'),
            },
            {
                url: '/segment-analysis/segments/new-segment',
                label: t('breadcrumbs:segmentAnalysis.newSegment'),
            },
            {
                url: '/segment-analysis/segments/new-segment/behavioural',
                label: t('breadcrumbs:segmentAnalysis.behavioural'),
                isTitle: true,
            },
        ]
    }, [t])

    return (
        <div className={`row mx-0 w-100 ${styles['segment-ai-container']}`}>
            <div className="col px-0 d-flex flex-column">
                <BreadCrumbs crumbs={crumbsMemo} />
                <div className="row w-100 mx-0">
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-title">{t(`pages:segmentAi.title`)}</span>
                    </div>
                </div>
                <div className="row w-100 mx-0" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-description">{t(`pages:segmentAi.description`)}</span>
                    </div>
                    <div className="col-auto px-0">
                        <ActionBar
                            actions={[
                                {
                                    text: t('buttons:back'),
                                    icon: icoBack,
                                    onClick: () => {
                                        window.history.back()
                                    },
                                },
                                {
                                    text: t('buttons:generateAgain'),
                                    icon: icoGenerateAgain,
                                    onClick: () => {
                                        console.log('Generate again')
                                    },
                                },
                                {
                                    text: t('buttons:save'),
                                    icon: icoSave,
                                    onClick: () => {
                                        console.log('Save')
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mx-0 w-100">
                    <div className="col-8 px-0" style={{ minWidth: 0 }}>
                        <Cassete counter={3} title={`${t('common:basicInputs')}`} alwaysOpen>
                            {_.chunk(resultData, 3).map((chunk, index) => {
                                return (
                                    <div key={index} className="row mx-0 w-100" style={{ marginTop: index !== 0 ? '2rem' : 0 }}>
                                        {chunk.map((item, index2) => {
                                            return (
                                                <div
                                                    key={index2}
                                                    className={`col`}
                                                    style={{
                                                        padding: index2 === 0 ? '0 1rem 0 0' : index2 === chunk.length - 1 ? '0 0 0 1rem' : '0 1rem',
                                                        minWidth: 0,
                                                    }}
                                                >
                                                    {RenderSegmentCard({})}
                                                </div>
                                            )
                                        })}
                                        {chunk.length < 3
                                            ? _.range(3 - chunk.length).map((item, index3) => <div key={index3} className="col"></div>)
                                            : null}
                                    </div>
                                )
                            })}
                        </Cassete>
                    </div>
                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                    <div className="col px-0" style={{ minWidth: 0 }}>
                        <SegmentPreview type="ai" isCollapsed={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const RenderSegmentCard = ({ title, content }) => {
    return (
        <div
            className={`${styles['segment-ai-container__card']}`}
            style={{
                minWidth: 0,
            }}
        >
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={`${styles['segment-ai-container__card__title']}`}>High Churn</span>
                </div>
                <div className="col-auto px-0">{RenderCheckboxBox({ iconStyle: 'selected' })}</div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0" style={{ minWidth: 0 }}>
                    <PieChartNew addSpacingBetween {...mockData} fillInside />
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col-auto px-0 d-flex flex-column justify-content-around">
                    <div style={{ marginBottom: '1rem' }}>
                        {RenderInfoBlock({
                            title: 'Users included',
                            content: '500',
                            auxContent: '(20%)',
                        })}
                    </div>
                    <div>
                        {RenderInfoBlock({
                            title: 'Users excluded',
                            content: '2000',
                            auxContent: '(80%)',
                        })}
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">{RenderInfoBlock({ title: 'Revenue (YTD)', content: '€105 210' })}</div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col px-0">{RenderInfoBlock({ title: 'Units Sold (YTD)', content: '2000' })}</div>
            </div>
        </div>
    )
}

const RenderInfoBlock = ({ title, content, auxContent }) => {
    return (
        <>
            <span className={`${styles['segment-ai-container__card__info-title']}`}>{title}</span>
            <span className={`${styles['segment-ai-container__card__info-content']}`}>{content}</span>
            <span className={`${styles['segment-ai-container__card__info-content-aux']}`}>{auxContent}</span>
        </>
    )
}
