import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from './date-picker.module.scss';
import _ from 'lodash';
import { useToggle } from '../../../hooks/useToggle';
import icoArrow from '../../../assets/images/dropdown-arrow.svg';
import {
    fromIsoDate,
    toIsoDate,
    toIsoDateFromDate
} from '../../../utils/format';
import Scrollable from '../../scrolling/scrollable';

export const DatePicker = ({
    name,
    value,
    onChangeInput,
    position,
    reference,
    parentRef,
    onCloseTimePicker,
    datePickerHorizontalPosition,
    initialDate,
    finalDate
}) => {
    const monthTranslator = useMemo(() => {
        return {
            1: 'Janeiro',
            2: 'Fevereiro',
            3: 'Março',
            4: 'Abril',
            5: 'Maio',
            6: 'Junho',
            7: 'Julho',
            8: 'Agosto',
            9: 'Setembro',
            10: 'Outubro',
            11: 'Novembro',
            12: 'Dezembro'
        };
    }, []);
    const [isMonthSelectionOpen, toggleIsMonthSelectionOpen] = useToggle(false);
    const [isYearSelectionOpen, toggleIsYearSelectionOpen] = useToggle(false);
    const [days, setDays] = useState(null);
    const [datePickerDay, setDatePickerDay] = useState(null);
    const [datePickerMonth, setDatePickerMonth] = useState(null);
    const [datePickerYear, setDatePickerYear] = useState(null);

    const getDays = useCallback((currentDate) => {
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const firstDayCurrentMonth = new Date(currentYear, currentMonth, 1);
        const firstDayNextMonth = new Date(currentYear, currentMonth + 1, 1);
        currentDate.setMonth(currentDate.getMonth() - 1);
        const lastDayCurrentMonth = new Date(currentYear, currentMonth + 1, 0);
        const lastDayLastMonth = new Date(currentYear, currentMonth, 0);
        const days = [];
        _.range(
            lastDayLastMonth.getDate() - lastDayLastMonth.getDay(),
            lastDayLastMonth.getDate() + 1
        ).map((el) => {
            days.push({ month: 'last', day: el });
            return null;
        });

        _.range(
            firstDayCurrentMonth.getDate(),
            lastDayCurrentMonth.getDate() + 1
        ).map((el) => {
            days.push({ month: 'current', day: el });
            return null;
        });

        _.range(
            firstDayNextMonth.getDate(),
            firstDayNextMonth.getDate() + (7 - lastDayCurrentMonth.getDay() - 1)
        ).map((el) => {
            days.push({ month: 'next', day: el });
            return null;
        });
        setDays(days);
    }, []);

    useEffect(() => {
        let day =
            (value && value.split('/')[0] && parseInt(value.split('/')[0])) ||
            (initialDate &&
                fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[0] &&
                parseInt(
                    fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[0]
                )) ||
            new Date().getDate();
        const month =
            (value && value.split('/')[1] && parseInt(value.split('/')[1])) ||
            (initialDate &&
                fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[1] &&
                parseInt(
                    fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[1]
                )) ||
            new Date().getMonth() + 1;
        const year =
            (value && value.split('/')[2]) ||
            (initialDate &&
                fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[2]) ||
            new Date().getFullYear();
        const currentDate =
            (value && new Date(toIsoDate(value))) ||
            (initialDate && new Date(initialDate)) ||
            new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const firstDayCurrentMonth = new Date(currentYear, currentMonth, 1);
        const firstDayNextMonth = new Date(currentYear, currentMonth + 1, 1);
        currentDate.setMonth(currentDate.getMonth() - 1);
        const lastDayCurrentMonth = new Date(currentYear, currentMonth + 1, 0);
        const lastDayLastMonth = new Date(currentYear, currentMonth, 0);
        const days = [];
        _.range(
            lastDayLastMonth.getDate() - lastDayLastMonth.getDay(),
            lastDayLastMonth.getDate() + 1
        ).map((el) => {
            days.push({ month: 'last', day: el });
            return null;
        });

        _.range(
            firstDayCurrentMonth.getDate(),
            lastDayCurrentMonth.getDate() + 1
        ).map((el) => {
            days.push({ month: 'current', day: el });
            return null;
        });

        _.range(
            firstDayNextMonth.getDate(),
            firstDayNextMonth.getDate() + (7 - lastDayCurrentMonth.getDay() - 1)
        ).map((el) => {
            days.push({ month: 'next', day: el });
            return null;
        });

        setDays(days);
        setDatePickerDay(day);
        setDatePickerMonth(month);
        setDatePickerYear(year);

        // if (!value && dateIsValid(day, month, year)) {
        //   onChangeInput(name, day + '/' + month + '/' + year, indexList);
        // }
    }, [value, initialDate]);

    const handleSaveNewDate = () => {
        onChangeInput(
            datePickerDay + '/' + datePickerMonth + '/' + datePickerYear,
            name,
            'date'
        );
        onCloseTimePicker();
    };

    const handleSaveNewDateWithDay = (day) => {
        onChangeInput(
            day + '/' + datePickerMonth + '/' + datePickerYear,
            name,
            'date'
        );
    };

    const handleOutsideClick = useCallback(
        (event) => {
            if (
                parentRef.current &&
                !parentRef.current.contains(event.target)
            ) {
                onCloseTimePicker();
            }
        },
        [onCloseTimePicker, parentRef]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return function cleanup() {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const handleChangeToPreviousMonth = () => {
        const newDate = new Date(
            datePickerYear,
            datePickerMonth - 1,
            datePickerDay
        );
        newDate.setMonth(newDate.getMonth() - 1);
        setDatePickerDay(newDate.getDate());
        setDatePickerMonth(newDate.getMonth() + 1);
        setDatePickerYear(newDate.getFullYear());
        getDays(newDate);
    };

    const handleChangeToSelectedMonth = (month) => {
        const newDate = new Date(datePickerYear, month, datePickerDay);
        newDate.setMonth(newDate.getMonth() - 1);
        setDatePickerDay(newDate.getDate());
        setDatePickerMonth(newDate.getMonth() + 1);
        setDatePickerYear(newDate.getFullYear());
        getDays(newDate);
    };

    const handleChangeToNextMonth = () => {
        const newDate = new Date(
            datePickerYear,
            datePickerMonth - 1,
            datePickerDay
        );
        newDate.setMonth(newDate.getMonth() + 1);
        setDatePickerDay(newDate.getDate());
        setDatePickerMonth(newDate.getMonth() + 1);
        setDatePickerYear(newDate.getFullYear());
        getDays(newDate);
    };

    const handleChangeToPreviousYear = () => {
        const newDate = new Date(
            datePickerYear,
            datePickerMonth - 1,
            datePickerDay
        );
        newDate.setFullYear(newDate.getFullYear() - 1);
        setDatePickerDay(newDate.getDate());
        setDatePickerMonth(newDate.getMonth() + 1);
        setDatePickerYear(newDate.getFullYear());
        getDays(newDate);
    };

    const handleChangeToSelectedYear = (year) => {
        const newDate = new Date(year, datePickerMonth - 1, datePickerDay);
        newDate.setFullYear(newDate.getFullYear() - 1);
        setDatePickerDay(newDate.getDate());
        setDatePickerMonth(newDate.getMonth() + 1);
        setDatePickerYear(newDate.getFullYear());
        getDays(newDate);
    };

    const handleChangeToNextYear = () => {
        const newDate = new Date(
            datePickerYear,
            datePickerMonth - 1,
            datePickerDay
        );
        newDate.setFullYear(newDate.getFullYear() + 1);
        setDatePickerDay(newDate.getDate());
        setDatePickerMonth(newDate.getMonth() + 1);
        setDatePickerYear(newDate.getFullYear());
        getDays(newDate);
    };
    function compareDates(date1, date2) {
        const year1 = date1.getFullYear();
        const month1 = date1.getMonth();
        const day1 = date1.getDate();

        const year2 = date2.getFullYear();
        const month2 = date2.getMonth();
        const day2 = date2.getDate();

        if (year1 === year2 && month1 === month2 && day1 === day2) {
            return 0; // Dates are equal
        } else if (
            year1 < year2 ||
            (year1 === year2 && month1 < month2) ||
            (year1 === year2 && month1 === month2 && day1 < day2)
        ) {
            return -1; // date1 is earlier than date2
        } else {
            return 1; // date1 is later than date2
        }
    }

    const checkForInitialAndFinalDateAux = (el2) => {
        let initialGood = false;
        let finalGood = false;
        if (!initialDate) {
            initialGood = false;
        } else if (
            initialDate &&
            compareDates(
                new Date(
                    datePickerYear,
                    datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                    el2.day
                ),
                initialDate
            ) === -1
        ) {
            initialGood = true;
        }
        if (!finalDate) {
            finalGood = false;
        } else if (
            finalDate &&
            compareDates(
                new Date(
                    datePickerYear,
                    datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                    el2.day
                ),
                finalDate
            ) === 1
        ) {
            finalGood = true;
        }
        if (initialGood || finalGood) {
            return true;
        }
        return false;
    };

    const checkForInitialAndFinalDate = (el2) => {
        let initialGood = false;
        let finalGood = false;
        if (
            (initialDate &&
                (compareDates(
                    new Date(
                        datePickerYear,
                        datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                        el2.day
                    ),
                    initialDate
                ) === 1 ||
                    compareDates(
                        new Date(
                            datePickerYear,
                            datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                            el2.day
                        ),
                        initialDate
                    ) === 0) &&
                el2.month === 'current') ||
            (!initialDate && el2.month === 'current')
        ) {
            initialGood = true;
        }
        if (
            (finalDate &&
                (compareDates(
                    new Date(
                        datePickerYear,
                        datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                        el2.day
                    ),
                    finalDate
                ) === -1 ||
                    compareDates(
                        new Date(
                            datePickerYear,
                            datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                            el2.day
                        ),
                        finalDate
                    ) === 0) &&
                el2.month === 'current') ||
            (!finalDate && el2.month === 'current')
        ) {
            finalGood = true;
        }
        if (initialGood && finalGood) {
            return true;
        }
        return false;
    };
    if (datePickerMonth && datePickerYear) {
        return (
            <div
                className={`${styles['date-picker-container']}`}
                ref={reference}
                style={
                    position === 'top'
                        ? datePickerHorizontalPosition === 'left'
                            ? {
                                  bottom: 'calc(100% + 1rem)',
                                  top: 'unset',
                                  right: '0',
                                  left: 'unset'
                              }
                            : {
                                  bottom: 'calc(100% + 1rem)',
                                  top: 'unset',
                                  left: 0
                              }
                        : datePickerHorizontalPosition === 'left'
                        ? { right: '0', left: 'unset' }
                        : { left: 0 }
                }
            >
                {isMonthSelectionOpen && (
                    <>
                        <div
                            className={`${styles['date-picker-container__background']}`}
                            onClick={() => {
                                toggleIsMonthSelectionOpen(false);
                            }}
                        ></div>
                        <div
                            className={`${styles['date-picker-container__month-container']}`}
                        >
                            <div className="row mx-0 w-100 h-100">
                                <div className="col px-0 d-flex flex-column h-100">
                                    <div
                                        className={`row mx-0 w-100 d-flex justify-content-between align-items-center ${styles['date-picker-container__month-container__top-row']}`}
                                    >
                                        <div
                                            className="col-2 px-0 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                handleChangeToPreviousMonth();
                                            }}
                                        >
                                            <img
                                                src={icoArrow}
                                                alt=""
                                                style={{
                                                    transform: 'rotate(90deg)'
                                                }}
                                                className={`${styles['date-picker-container__icon-arrow']}`}
                                            />
                                        </div>
                                        <div
                                            className={`col px-0 ${styles['date-picker-container__month-title']}`}
                                            onClick={() => {
                                                toggleIsMonthSelectionOpen(
                                                    false
                                                );
                                            }}
                                        >
                                            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                                <div className="col-auto px-0 pe-2">
                                                    <span
                                                        className={`${styles['date-picker-container__title']}`}
                                                    >
                                                        {
                                                            monthTranslator[
                                                                datePickerMonth
                                                            ]
                                                        }
                                                    </span>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <img
                                                        src={icoArrow}
                                                        alt=""
                                                        className={`${styles['date-picker-container__icon']}`}
                                                        style={{
                                                            transform:
                                                                'rotate(180deg)'
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-2 px-0 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleChangeToNextMonth}
                                        >
                                            <img
                                                src={icoArrow}
                                                alt=""
                                                className={`${styles['date-picker-container__icon-arrow']}`}
                                                style={{
                                                    transform: 'rotate(-90deg)'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <div
                                                className={`${styles['date-picker-container__month-container__separator']}`}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100 flex-grow-1 overflow-hidden">
                                        <div className="col px-0 h-100">
                                            <Scrollable>
                                                {Object.values(
                                                    monthTranslator
                                                ).map((el, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                index + 1 ===
                                                                datePickerMonth
                                                                    ? `row mx-0 w-100 ${styles['date-picker-container__month-container__month-row']} ${styles['active']}`
                                                                    : `row mx-0 w-100 ${styles['date-picker-container__month-container__month-row']}`
                                                            }
                                                            onClick={() => {
                                                                handleChangeToSelectedMonth(
                                                                    index + 1
                                                                );
                                                                toggleIsMonthSelectionOpen(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            <div className="col-auto px-0">
                                                                <span
                                                                    className={`${styles['date-picker-container__month-container__month-row__text']}`}
                                                                >
                                                                    {el}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Scrollable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {isYearSelectionOpen && (
                    <>
                        <div
                            className={`${styles['date-picker-container__background']}`}
                            onClick={() => {
                                toggleIsYearSelectionOpen(false);
                            }}
                        ></div>
                        <div
                            className={`${styles['date-picker-container__year-container']}`}
                        >
                            <div className="row mx-0 w-100 h-100">
                                <div className="col px-0 d-flex flex-column h-100">
                                    <div
                                        className={`row mx-0 w-100 d-flex justify-content-between align-items-center ${styles['date-picker-container__year-container__top-row']}`}
                                    >
                                        <div
                                            className="col-2 px-0 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleChangeToPreviousYear}
                                        >
                                            <img
                                                src={icoArrow}
                                                alt=""
                                                style={{
                                                    transform: 'rotate(90deg)'
                                                }}
                                                className={`${styles['date-picker-container__icon-arrow']}`}
                                            />
                                        </div>
                                        <div
                                            className={`col px-0 ${styles['date-picker-container__year-title']}`}
                                            onClick={() => {
                                                toggleIsYearSelectionOpen(
                                                    false
                                                );
                                            }}
                                        >
                                            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                                <div className="col-auto px-0 pe-2">
                                                    <span
                                                        className={`${styles['date-picker-container__title']}`}
                                                    >
                                                        {datePickerYear}
                                                    </span>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <img
                                                        src={icoArrow}
                                                        alt=""
                                                        className={`${styles['date-picker-container__icon']}`}
                                                        style={{
                                                            transform:
                                                                'rotate(180deg)'
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-2 px-0 d-flex justify-content-center align-items-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleChangeToNextYear}
                                        >
                                            <img
                                                src={icoArrow}
                                                alt=""
                                                className={`${styles['date-picker-container__icon-arrow']}`}
                                                style={{
                                                    transform: 'rotate(-90deg)'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <div
                                                className={`${styles['date-picker-container__year-container__separator']}`}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100 flex-grow-1 overflow-hidden">
                                        <div className="col px-0 h-100">
                                            <Scrollable>
                                                {_.reverse(
                                                    _.range(
                                                        1900,
                                                        new Date().getFullYear() +
                                                            1
                                                    )
                                                ).map((el, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                el ===
                                                                datePickerYear
                                                                    ? `row mx-0 w-100 ${styles['date-picker-container__year-container__year-row']} ${styles['active']}`
                                                                    : `row mx-0 w-100 ${styles['date-picker-container__year-container__year-row']}`
                                                            }
                                                            onClick={() => {
                                                                handleChangeToSelectedYear(
                                                                    el + 1
                                                                );
                                                                toggleIsYearSelectionOpen(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            <div className="col-auto px-0">
                                                                <span
                                                                    className={`${styles['date-picker-container__year-container__year-row__text']}`}
                                                                >
                                                                    {el}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Scrollable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="row mx-0 w-100">
                    <div className="col px-0">
                        <div
                            className={`row mx-0 w-100 d-flex justify-content-between align-items-center ${styles['date-picker-container__month-container__top-row']}`}
                            style={{ borderRight: '1px solid transparent' }}
                        >
                            <div
                                className="col-2 px-0 d-flex justify-content-center align-items-center"
                                style={{ cursor: 'pointer' }}
                                onClick={handleChangeToPreviousMonth}
                            >
                                <img
                                    src={icoArrow}
                                    alt=""
                                    style={{ transform: 'rotate(90deg)' }}
                                    className={`${styles['date-picker-container__icon-arrow']}`}
                                />
                            </div>
                            <div
                                className={`col px-0 ${styles['date-picker-container__month-title']}`}
                                onClick={() => {
                                    toggleIsMonthSelectionOpen(true);
                                }}
                            >
                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                    <div className="col-auto px-0 pe-2">
                                        <span
                                            className={`${styles['date-picker-container__title']}`}
                                        >
                                            {monthTranslator[datePickerMonth]}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <img
                                            src={icoArrow}
                                            alt=""
                                            className={`${styles['date-picker-container__icon']}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-2 px-0 d-flex justify-content-center align-items-center"
                                style={{ cursor: 'pointer' }}
                                onClick={handleChangeToNextMonth}
                            >
                                <img
                                    src={icoArrow}
                                    alt=""
                                    className={`${styles['date-picker-container__icon-arrow']}`}
                                    style={{ transform: 'rotate(-90deg)' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col px-0">
                        <div
                            className={`row mx-0 w-100 d-flex justify-content-between align-items-center ${styles['date-picker-container__year-container__top-row']}`}
                            style={{ borderLeft: '1px solid transparent' }}
                        >
                            <div
                                className="col-2 px-0 d-flex justify-content-center align-items-center"
                                style={{ cursor: 'pointer' }}
                                onClick={handleChangeToPreviousYear}
                            >
                                <img
                                    src={icoArrow}
                                    alt=""
                                    style={{ transform: 'rotate(90deg)' }}
                                    className={`${styles['date-picker-container__icon-arrow']}`}
                                />
                            </div>
                            <div
                                className={`col px-0 ${styles['date-picker-container__year-title']}`}
                                onClick={() => {
                                    toggleIsYearSelectionOpen(true);
                                }}
                            >
                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                    <div className="col-auto px-0 pe-2">
                                        <span
                                            className={`${styles['date-picker-container__title']}`}
                                        >
                                            {datePickerYear}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <img
                                            src={icoArrow}
                                            alt=""
                                            className={`${styles['date-picker-container__icon']}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-2 px-0 d-flex justify-content-center align-items-center"
                                style={{ cursor: 'pointer' }}
                                onClick={handleChangeToNextYear}
                            >
                                <img
                                    src={icoArrow}
                                    alt=""
                                    className={`${styles['date-picker-container__icon-arrow']}`}
                                    style={{ transform: 'rotate(-90deg)' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100 ${styles['date-picker-container__row-container']}`}
                >
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            D
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            S
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            T
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            Q
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            Q
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            S
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span
                            className={`${styles['date-picker-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                        >
                            S
                        </span>
                    </div>
                </div>
                {_.chunk(days, 7).map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`row mx-0 w-100 ${styles['date-picker-container__row-container']}`}
                        >
                            {el.map((el2, index2) => {
                                return (
                                    <div
                                        key={index2}
                                        className={
                                            checkForInitialAndFinalDate(el2)
                                                ? datePickerDay === el2.day
                                                    ? `col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['active']}`
                                                    : `col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']}`
                                                : `col d-flex justify-content-center align-items-center ${styles['date-picker-container__row-container__col-container']} ${styles['disabled-text']}`
                                        }
                                        onClick={
                                            el2.month === 'current'
                                                ? () => {
                                                      handleSaveNewDateWithDay(
                                                          el2.day
                                                      );
                                                      setDatePickerDay(el2.day);
                                                  }
                                                : undefined
                                        }
                                    >
                                        <span
                                            className={
                                                checkForInitialAndFinalDateAux(
                                                    el2
                                                ) || el2.month !== 'current'
                                                    ? `${styles['date-picker-container__row-container__col-container__text']} ${styles['disabled-text']}`
                                                    : `${styles['date-picker-container__row-container__col-container__text']}`
                                            }
                                        >
                                            {el2.day}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <div
                    className="row mx-0 w-100 d-flex justify-content-end align-items-center"
                    style={{ padding: '1.5rem' }}
                >
                    <div
                        className="col-auto px-0"
                        style={{ marginRight: '1.5rem' }}
                    >
                        <span
                            onClick={onCloseTimePicker}
                            className={`${styles['date-picker-container__row-container__col-container__cancel-text']}`}
                        >
                            Cancelar
                        </span>
                    </div>
                    <div className="col-auto px-0">
                        <span
                            onClick={handleSaveNewDate}
                            style={
                                !datePickerDay
                                    ? { pointerEvents: 'none', opacity: '0.5' }
                                    : undefined
                            }
                            className={`${styles['date-picker-container__row-container__col-container__ok-text']}`}
                        >
                            OK
                        </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
