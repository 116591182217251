import React from 'react';
import styles from './goal-time-left-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { formatCurrency } from '../../../../../utils/math';
import { getDayFromDateFormat } from '../../../../../utils/date';

export const getTimeLeft = (targetDate) => {
    const now = new Date();
    const target = new Date(targetDate);

    if (target < now) {
        return 'The target date has already passed.';
    }

    // Calculate the number of full months and remaining days between now and the target date
    let monthsLeft = 0;
    let current = new Date(now);

    while (current < target) {
        const nextMonth = new Date(current);
        nextMonth.setMonth(current.getMonth() + 1);
        if (nextMonth > target) {
            break;
        }
        monthsLeft += 1;
        current = nextMonth;
    }

    // Calculate the remaining days
    const remainingDays = Math.ceil((target - current) / (1000 * 60 * 60 * 24));

    if (monthsLeft < 2) {
        return `${monthsLeft * 30 + remainingDays} days`;
    } else {
        return `${monthsLeft} months`;
    }
};

export const GoalTimeLeftTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['goal-time-left-table-column-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '0.5rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['goal-time-left-table-column-container__label']}`}
                    >
                        {getTimeLeft(el[type])}
                    </span>{' '}
                    <span
                        className={`${styles['goal-time-left-table-column-container__text']}`}
                    >
                        ({getDayFromDateFormat(el[type])})
                    </span>
                </div>
            </div>
        </div>
    );
});
