import React, { useCallback, useEffect, useState } from 'react'
import styles from './TeamCarousel.module.scss'
import { TeamDetailsModal } from '../team-details-modal/TeamDetailsModal'
import icoEdit from '../../../assets/images/ico-edit.svg'
import icoDelete from '../../../assets/images/ico-delete.svg'
import icoManageMembers from '../../../assets/images/icoManageMembers.svg'
import icoArrow from '../../../assets/images/dropdown-arrow.svg'
import { RemoveOrgTeamModal } from '../../modals/remove-org-team-modal/remove-org-team-modal'
import { ManageTeamMembersModal } from '../../modals/manage-team-members-modal/ManageTeamMembersModal'
import icoTeamCard from '../../../assets/images/icoTeamCard.svg'
import { ActionsButtonNew } from '../../buttons/actions-button-new/actions-button-new'
import avatarTeamMember from '../../../assets/images/avatarTeamMember.png'
import icoUser1 from '../../../assets/images/user1.svg'
import icoUser2 from '../../../assets/images/user2.svg'
import icoUser3 from '../../../assets/images/user3.svg'
import icoUser4 from '../../../assets/images/user4.svg'
import icoUser5 from '../../../assets/images/user5.svg'
import icoUser6 from '../../../assets/images/user6.svg'
import icoUser7 from '../../../assets/images/user7.svg'
import icoUser8 from '../../../assets/images/user8.svg'
import icoUser9 from '../../../assets/images/user9.svg'
import icoUser10 from '../../../assets/images/user10.svg'

const fillTeams = (teams) => {
    const imagesUsed = []
    return teams.map((team) => {
        return {
            ...team,
            members: Array.from({ length: 5 }, (_, memberIndex) => {
                let newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
                    Math.floor(Math.random() * 10)
                ]
                if (imagesUsed.length !== 0) {
                    do {
                        newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
                            Math.floor(Math.random() * 10)
                        ]
                    } while (imagesUsed.includes(newImage))
                }

                imagesUsed.push(newImage)
                const firstName = 'John'
                const lastName = 'Doe'
                return {
                    teamMemberName: `${firstName} ${lastName}`,
                    teamMemberCustomers: Math.floor(Math.random() * 100 + 10),
                    teamMemberCustomersLast: Math.floor(Math.random() * 100 + 10),
                    teamMemberRevenue: `${(Math.random() * 10000 + 10).toFixed(0)}`,
                    teamMemberRevenueLast: `${(Math.random() * 11000 + 10).toFixed(0)}`,
                    image: newImage,
                }
            }),
            action: {
                id: team.id,
                open: false,
                options: [
                    {
                        name: 'edit',
                        icon: icoEdit,
                    },
                    {
                        name: 'manageMembers',
                        icon: icoManageMembers,
                    },
                    {
                        name: 'remove',
                        icon: icoDelete,
                        isRed: true,
                    },
                ],
                reference: React.createRef(),
            },
        }
    })
}

export const TeamCarousel = React.memo(({ teams }) => {
    const pageSize = 3
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedTeamId, setSelectedTeamId] = useState(null)
    const [teamCards, setTeamCards] = useState(null)
    const initialCardIndex = (currentPage - 1) * pageSize
    const finalCardIndex = initialCardIndex + pageSize
    const [editTeamModal, setEditTeamModal] = useState(false)
    const [deleteTeamModal, setDeleteTeamModal] = useState(false)
    const [manageMembersModal, setManageMembersModal] = useState(false)
    const handleClickOption = useCallback((option, teamId) => {
        setSelectedTeamId(teamId)
        if (option === 'edit') {
            setEditTeamModal(true)
        } else if (option === 'remove') {
            setDeleteTeamModal(true)
        } else if (option === 'manageMembers') {
            setManageMembersModal(true)
        }
    }, [])

    useEffect(() => {
        setTeamCards(fillTeams(teams))
    }, [teams])

    const handleUpdate = useCallback(() => {
        setTeamCards(fillTeams(teams))
    }, [teams])

    return (
        <>
            {selectedTeamId && manageMembersModal && (
                <ManageTeamMembersModal
                    onToggleModal={() => {
                        setManageMembersModal(false)
                        setSelectedTeamId(null)
                    }}
                />
            )}
            {selectedTeamId && deleteTeamModal && (
                <RemoveOrgTeamModal
                    teamId={selectedTeamId}
                    onCloseModal={() => {
                        setSelectedTeamId(null)
                        setDeleteTeamModal(false)
                    }}
                    onEnd={handleUpdate}
                />
            )}
            {selectedTeamId && editTeamModal && (
                <TeamDetailsModal
                    team={selectedTeamId}
                    onToggleModal={() => setEditTeamModal(false)}
                    isEdit
                    onCancelEditMode={() => {
                        setEditTeamModal(false)
                        setSelectedTeamId(null)
                    }}
                    onSaveEditMode={() => {
                        setEditTeamModal(false)
                        setSelectedTeamId(null)
                    }}
                />
            )}
            {selectedTeamId && !editTeamModal && !deleteTeamModal && !manageMembersModal && (
                <TeamDetailsModal team={selectedTeamId} onToggleModal={() => setSelectedTeamId(null)} />
            )}
            <div className={`row mx-0 ${styles['team-carousel-container']}`}>
                <div className="col px-0">
                    <div className="row mx-0 d-flex justify-content-end align-items-center" style={{ paddingBottom: '1rem' }}>
                        <div className="col-auto px-0">
                            <img
                                loading="lazy"
                                src={icoArrow}
                                alt="arrow"
                                style={{
                                    cursor: 'pointer',
                                    transform: 'rotate(90deg)',
                                    opacity: currentPage === 1 ? '0.5' : '1',
                                    pointerEvents: currentPage === 1 ? 'none' : '',
                                }}
                                onClick={() => {
                                    setCurrentPage(currentPage - 1)
                                }}
                            />
                            <img
                                loading="lazy"
                                src={icoArrow}
                                alt="arrow"
                                style={{
                                    cursor: 'pointer',
                                    transform: 'rotate(-90deg)',
                                    opacity: teamCards && finalCardIndex >= teamCards.length ? '0.5' : '1',
                                    pointerEvents: teamCards && finalCardIndex >= teamCards.length ? 'none' : '',
                                }}
                                onClick={() => {
                                    setCurrentPage(currentPage + 1)
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                        {teamCards &&
                            teamCards.slice(initialCardIndex, finalCardIndex).map((team, index) => (
                                <div
                                    className={`col-4 h-100`}
                                    style={{
                                        paddingRight: index === pageSize - 1 ? '0px' : '0.5rem',
                                        paddingLeft: index === 0 ? '0px' : '0.5rem',
                                    }}
                                    key={initialCardIndex + index} // Unique key based on card's position in the array
                                >
                                    <div
                                        className={styles['team-carousel-container__card']}
                                        onClick={() => {
                                            setSelectedTeamId(team)
                                        }}
                                    >
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-between align-items-center"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start">
                                                <img
                                                    src={icoTeamCard}
                                                    alt="Team Card"
                                                    className={styles['team-carousel-container__card__icon']}
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                />
                                                <span className={styles['team-carousel-container__card__title']}>{team.teamName}</span>
                                            </div>
                                            <div className="col-auto px-0">
                                                <ActionsButtonNew actions={team.action} onClickOption={(args) => handleClickOption(args, team.id)} />
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    marginRight: '2rem',
                                                }}
                                            >
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__content']}>Manager</span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__subTitle']}>{team.teamManager}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    marginRight: '2rem',
                                                }}
                                            >
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__content']}>Members</span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__subTitle']}>{team.teamMembers}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    marginRight: '2rem',
                                                }}
                                            >
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__content']}>Revenue</span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__subTitle']}>{team.revenue}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    marginRight: '2rem',
                                                }}
                                            >
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__content']}>Customers</span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={styles['team-carousel-container__card__subTitle']}>{team.customers}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0"></div>
                                        </div>
                                        <div className={styles['team-carousel-container__card__members-container']}>
                                            <div className="d-flex">
                                                {
                                                    team.members.slice(0, 3).map((member, index) => (
                                                        <img
                                                            key={index}
                                                            src={member.image}
                                                            className={styles['team-carousel-container__card__members-container__avatar']}
                                                        />
                                                    )) // Unique key based on member's position in the array
                                                }
                                                {team.members.length > 3 && (
                                                    <div className={styles['team-carousel-container__card__members-container__avatar-plus']}>
                                                        <span
                                                            className={styles['team-carousel-container__card__members-container__avatar-plus__text']}
                                                        >
                                                            +{team.members.length - 3}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
})
