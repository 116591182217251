import React from 'react';
import icoResizeComponent from '../../../assets/images/ico-resize-component.svg';
import styles from './resize-handle.module.scss';

export const MyResizeHandle = React.forwardRef((props, ref) => {
    const { handleAxis, ...restProps } = props;
    return (
        <div
            ref={ref}
            className={`handle-${handleAxis} resize-handle ${styles['resize-handle']}`}
            {...restProps}
        >
            <img
                loading="lazy"
                src={icoResizeComponent}
                alt="resize element with cursor"
                className={`${styles['resize-handle__icon']}`}
            />
        </div>
    );
});
