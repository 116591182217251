import { useCallback, useReducer } from 'react';

const ACTIONS = {
    RESET: 'reset',
    SET: 'set',
    RATIO: 'ratio',
    ADD_OPTION: 'addOption'
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET:
            return { ...action.payload };
        case ACTIONS.RESET:
            return { ...action.payload };
        case ACTIONS.ADD_OPTION:
            return {
                ...state,
                options: [...state.options, action.payload]
            };
        case ACTIONS.RATIO:
            return {
                ...state,
                selectedOption: action.payload
            };
    }
}

export function useRatioInput(defaultValue) {
    const [value, dispatch] = useReducer(reducer, defaultValue);

    function setValue(value) {
        dispatch({ type: 'set', payload: value });
    }

    const reset = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue });
    }, [defaultValue]);

    const clickRatioOption = useCallback((a, b, option) => {
        dispatch({ type: 'ratio', payload: option });
    }, []);

    function addOption(option) {
        dispatch({ type: 'addOption', payload: option });
    }

    return { value, setValue, addOption, reset, clickRatioOption };
}
