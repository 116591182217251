import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { formatDate } from '../../../utils/date';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { SentimentService } from '../../../temp/test';
import { SentimentChannelAnalysisFactory } from '../sentiment-channel-analysis-factory/sentiment-channel-analysis-factory';
import { sentimentChannelAnalysisMock } from './mock';
import styles from './sentiment-channel-analysis.module.scss';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

export const SentimentChannelAnalysis = React.memo(({ analysisId }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [selectedChannel, setSelectedChannel] = useState('texts');
    const [emails, setEmails] = useState([]);
    const [texts, setTexts] = useState([
        { conversations: [] },
        { conversations: [] }
    ]);
    const [calls, setCalls] = useState([]);
    const [media, setMedia] = useState([]);
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(sentimentChannelAnalysisMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(sentimentChannelAnalysisMock);
            try {
                const result = await SentimentService.getApiNlpAnalysis({
                    sessionId: analysisId
                });
                const newTextResults = [];
                const newEmailResults = [];
                const newCallResults = [];
                const newMediaResults = [];
                result.map((el) => {
                    let positiveWordsCounter = 0,
                        negativeWordsCounter = 0,
                        neutralWordsCounter = 0;
                    let positiveWords = [],
                        negativeWords = [],
                        neutralWords = [];
                    let sentences = [];
                    let text = el.text;
                    let majorSentences = [];
                    JSON.parse(el.keywords).map((el2) => {
                        if (sentences.length === 0) {
                            let newSentences = text.split(el2.text);
                            if (
                                typeof newSentences === 'object' &&
                                newSentences.length > 1
                            ) {
                                sentences.push(
                                    { text: newSentences[0], sentiment: '' },
                                    {
                                        text: el2.text,
                                        sentiment: el2['sentiment']['label']
                                    },
                                    { text: newSentences[1], sentiment: '' }
                                );
                            }
                        } else if (sentences.length !== 0) {
                            sentences.map((el3, index3) => {
                                if (el3.sentiment === '') {
                                    let newSentences = el3.text.split(el2.text);
                                    if (
                                        typeof newSentences === 'object' &&
                                        newSentences.length > 1
                                    ) {
                                        sentences.splice(index3 + 1, 0, {
                                            text: newSentences[1],
                                            sentiment: ''
                                        });
                                        sentences.splice(index3 + 1, 0, {
                                            text: el2.text,
                                            sentiment: el2['sentiment']['label']
                                        });
                                        sentences.splice(index3, 0, {
                                            text: newSentences[0],
                                            sentiment: ''
                                        });
                                        sentences.splice(index3 + 1, 1);
                                    }
                                }
                                return el3;
                            });
                        }
                        if (el2['sentiment']['label'] === 'positive') {
                            positiveWordsCounter += 1;
                            positiveWords.push(el2.text);
                        } else if (el2['sentiment']['label'] === 'negative') {
                            negativeWordsCounter += 1;
                            negativeWords.push(el2.text);
                        } else if (el2['sentiment']['label'] === 'neutral') {
                            neutralWordsCounter += 1;
                            neutralWords.push(el2.text);
                        }
                        return el2;
                    });
                    majorSentences.push(sentences);
                    const newEl = {
                        id: el.text_id,
                        subject: el.title,
                        text: el.text,
                        date: formatDate(el.classification_date),
                        sentiment: {
                            label: t(
                                `pages:sentimentAnalysis.${el.sentiment_label}`
                            ),
                            positive:
                                el.sentiment_label === 'positive'
                                    ? Math.abs(
                                          el.sentiment_score.toFixed(2) * 100
                                      )
                                    : 0,
                            negative:
                                el.sentiment_label === 'negative'
                                    ? Math.abs(
                                          el.sentiment_score.toFixed(2) * 100
                                      )
                                    : 0,
                            neutral:
                                el.sentiment_label === 'neutral'
                                    ? Math.abs(
                                          el.sentiment_score.toFixed(2) * 100
                                      )
                                    : 0
                        },
                        firstCorrespondent: '',
                        secondCorrespondent: '',
                        emailCounter: 1,
                        positiveWordCounter: positiveWordsCounter,
                        negativeWordCounter: negativeWordsCounter,
                        neutralWordCounter: neutralWordsCounter,
                        positiveWords: positiveWords,
                        neutralWords: neutralWords,
                        negativeWords: negativeWords,
                        sentences: majorSentences,
                        sourceType: el.source_type
                    };
                    if (newEl.sourceType === 'text_sentiment') {
                        newTextResults.push(newEl);
                    } else if (newEl.sourceType === 'email_sentiment') {
                        newEmailResults.push(newEl);
                    } else if (newEl.sourceType === 'call_sentiment') {
                        newCallResults.push(newEl);
                    } else if (newEl.sourceType === 'media_sentiment') {
                        newMediaResults.push(newEl);
                    }
                    return el;
                });
                setTexts(newTextResults);
                setEmails(newEmailResults);
                setCalls(newCallResults);
                setMedia(newMediaResults);
                verifyComponent(dataClone);
                setValue(dataClone);
                setLoading(false);
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [setTexts, analysisId, setLoading, setValue, t, isMounted]);

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={undefined}
            >
                <div
                    className={`${styles['sentiment-channel-analysis-container']}`}
                    style={{ paddingBottom: '2rem' }}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <React.Fragment>
                            <div
                                className="row mx-0 w-100 d-flex justify-content-start align-items-center "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col-1 px-0 me-2">
                                    {selectedChannel === 'texts' && (
                                        <PrimaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.texts'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('texts')
                                            }
                                        />
                                    )}
                                    {selectedChannel !== 'texts' && (
                                        <SecondaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.texts'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('texts')
                                            }
                                        />
                                    )}
                                </div>
                                <div className="col-1 px-0 me-2">
                                    {selectedChannel === 'emails' && (
                                        <PrimaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.emails'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('emails')
                                            }
                                        />
                                    )}
                                    {selectedChannel !== 'emails' && (
                                        <SecondaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.emails'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('emails')
                                            }
                                        />
                                    )}
                                </div>
                                <div className="col-1 px-0 me-2">
                                    {selectedChannel === 'calls' && (
                                        <PrimaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.calls'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('calls')
                                            }
                                        />
                                    )}
                                    {selectedChannel !== 'calls' && (
                                        <SecondaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.calls'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('calls')
                                            }
                                        />
                                    )}
                                </div>
                                <div className="col-1 px-0 me-2">
                                    {selectedChannel === 'media' && (
                                        <PrimaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.media'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('media')
                                            }
                                        />
                                    )}
                                    {selectedChannel !== 'media' && (
                                        <SecondaryButton
                                            text={t(
                                                'components:sentimentChannelAnalysis.media'
                                            )}
                                            onClick={() =>
                                                setSelectedChannel('media')
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{ flexBasis: 0, minHeight: 0 }}
                            >
                                <div className="col px-0 h-100">
                                    <SentimentChannelAnalysisFactory
                                        item={{
                                            type: selectedChannel,
                                            emails: emails,
                                            texts: texts,
                                            calls: calls,
                                            media: media
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </ContentContainer>
        </>
    );
});
