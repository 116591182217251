import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import IcoEye from '../../../assets/images/ico-hidden.svg'
import IcoEyeHidden from '../../../assets/images/icon-eye-hidden.svg'
import { ReactComponent as IcoEdit } from './assets/images/ico-edit.svg'
import { ReactComponent as IcoValid } from './assets/images/ico-valid.svg'
import icoAI from '../../../assets/images/icoAI.svg'
import icoSubmitAI from '../../../assets/images/icoSubmitAI.svg'
import icoDiscardAI from '../../../assets/images/icoDiscardAI.svg'
import icoGenerate from '../../../assets/images/icoGenerate.svg'
import styles from './type-input.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { InputLabel } from '../input-label/input-label'

export const TypeInput = React.memo(
    ({
        label,
        hidden,
        disabled,
        edit,
        name,
        value,
        error,
        onChangeInput,
        onClickHiddenIcon,
        onKeyPress,
        state,
        password,
        inputType,
        hovered,
        hideLabel,
        isNumber,
        newLabelType,
        isRequired,
        isOptional,
        isAI,
        onSubmit,
        onDiscard,
        onFocus,
        onBlur,
        placeholder = '',
    }) => {
        const { t } = useCustomTranslation()
        const [selected, toggleSelected] = useState(false)
        const [showPassword, toggleShowPassword] = useState(false)

        const textAreaStyle = inputType === 'container' ? styles['textarea'] : ''
        const containerStyle = [
            styles['type-input-container'],
            inputType && styles[inputType],
            hovered && styles['hovered'],
            disabled && styles['disabled'],
            selected && styles['selected'],
            value && styles['valued'],
            error && styles['error'],
            inputType === 'container' && styles['textarea'],
            isAI && styles['isAI'],
            textAreaStyle,
        ]
            .filter(Boolean)
            .join(' ')

        const inputClassName = [styles['form-control'], styles['type-input-container__input'], value ? '' : styles['placeholder'], error]
            .filter(Boolean)
            .join(' ')

        const passwordIconClassName = [
            styles['type-input-container__password-icon'],
            state === 'error' && styles['type-input-container__password-icon--error'],
        ]
            .filter(Boolean)
            .join(' ')

        const editIconClassName = [styles['type-input-container__edit-icon'], state === 'error' && styles['type-input-container__edit-icon--error']]
            .filter(Boolean)
            .join(' ')

        // Utility function to check if the string is numeric
        const isNumeric = (str) => {
            return typeof str === 'string' && (str.trim() === '' || (!isNaN(str) && !isNaN(parseFloat(str))))
        }

        // Updated onChange handler
        const handleInputChange = useCallback(
            (e, name) => {
                onChangeInput(e, name)
            },
            [onChangeInput]
        )

        return (
            <>
                <div className={containerStyle} onFocus={() => toggleSelected(true)} onBlur={() => toggleSelected(false)}>
                    {!hideLabel && !isAI && (
                        <>
                            <InputLabel
                                text={label}
                                name={name}
                                isError={state === 'error' || error}
                                isValued={value}
                                isSelected={selected}
                                isRequired={isRequired}
                                isOptional={isOptional}
                                type={newLabelType ? 'top' : undefined}
                            />
                        </>
                    )}
                    {inputType === 'container' || isAI ? (
                        <textarea
                            autoComplete="false"
                            name={name}
                            value={value}
                            id={name}
                            placeholder={placeholder}
                            type={hidden ? 'password' : 'text'}
                            className={`${inputClassName} ${textAreaStyle}`}
                            onKeyDown={onKeyPress ? onKeyPress : () => {}}
                            style={{ resize: 'none' }}
                            onChange={(e) => handleInputChange(e, name)}
                            onFocus={() => {
                                if (onFocus) onFocus()
                                toggleSelected(true)
                            }}
                            onBlur={() => {
                                if (onBlur) onBlur()
                                toggleSelected(false)
                            }}
                        />
                    ) : (
                        <div className={styles['type-input-container__input-wrapper']} style={{ height: '100%' }}>
                            <input
                                autoComplete="false"
                                name={name}
                                value={value || ''}
                                id={name}
                                placeholder={placeholder}
                                type={hidden ? 'password' : 'text'}
                                className={inputClassName}
                                onChange={(e) => handleInputChange(e, name)}
                                onFocus={() => toggleSelected(true)}
                                onBlur={() => toggleSelected(false)}
                            />
                        </div>
                    )}
                    {password && (
                        <img
                            loading="lazy"
                            src={showPassword ? IcoEyeHidden : IcoEye}
                            style={{
                                right: '1rem',
                                width: '2.4rem',
                                height: '2.4rem',
                            }}
                            className={passwordIconClassName}
                            onClick={() => {
                                toggleShowPassword(!showPassword)
                                onClickHiddenIcon(name)
                            }}
                        />
                    )}
                    {/* {isAI && (
                        <img
                            src={icoAI}
                            style={{
                                position: 'absolute',
                                top: '1rem',
                                right: '1rem',
                                width: '2.4rem',
                                height: '2.4rem'
                            }}
                        />
                    )} */}
                    {isAI && onSubmit && (
                        <img
                            src={icoGenerate}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                bottom: '1rem',
                                right: '1rem',
                                pointerEvents: value ? 'auto' : 'none',
                                opacity: value ? 1 : 0.5,
                            }}
                            onClick={() => onSubmit()}
                        />
                    )}
                    {/* {isAI && onDiscard && (
                        <img
                            src={icoDiscardAI}
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                bottom: '1rem',
                                right: '1rem',
                                width: '2.4rem',
                                height: '2.4rem',
                                borderRadius: '0.6rem'
                            }}
                            onClick={() => onDiscard()}
                        />
                    )} */}
                    {edit && <IcoEdit className={editIconClassName} />}
                    {state === 'valid' && <IcoValid className={styles['type-input-container__valid-icon']} />}
                </div>
                {error && <div className={styles['type-input-error']}>{t(`errors:${error}`)}</div>}
            </>
        )
    }
)

TypeInput.propTypes = {}
