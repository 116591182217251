import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGeo } from '../../../hooks/useGeo';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useFilter } from '../../../providers/filter-context';
import { ProspectsService } from '../../../temp/test';
import { prospectGeoMock } from './mock';
import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import { ContentContainer, Loader, MapContainerNew, Spinner } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays the geographical location of all competitors
 */

export const ProspectGeo = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter, setCompetitorId } = useFilter();
        const firstRender = useRef(true);
        const isMounted = useIsMounted();
        const prospectMapRef = useRef(null);
        const { i18n } = useTranslation();
        const { t } = useCustomTranslation();
        const [modal] = useToggle();
        const {
            value: data,
            toggleComponentSettings,
            mouseOverMap,
            setLoading,
            setValue
        } = useGeo(prospectGeoMock, modal);

        const handleMouseClickProspectMap = useCallback(
            async (loc, type) => {
                try {
                    if (type && type.toString() === loc.regionId.toString()) {
                        setCompetitorId(loc.regionId.toString());
                    }
                } catch (err) {
                    errorHandler(err);
                }
            },
            [setCompetitorId]
        );

        const handleMouseClickMapSecondary = (ref, loc) => {
            if (loc && ref.current) {
                ref.current.flyTo({
                    center: [loc.lon, loc.lat],
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                });
            }
        };

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(prospectGeoMock);
                try {
                    if (type) dataClone.attr = type;
                    if (!isExample) {
                        if (isMounted.current && firstRender.current) {
                            setLoading(true);
                        }
                        const competitorGeoData =
                            await ProspectsService.getApiCompetitors();
                        let avgLat = 0;
                        let avgLon = 0;
                        dataClone.originalCompetitionData = {
                            type: 'FeatureCollection',
                            features: []
                        };
                        dataClone.competitionData = {
                            type: 'FeatureCollection',
                            features: []
                        };
                        dataClone.selectedData = {
                            type: 'FeatureCollection',
                            features: []
                        };
                        let selectedCompetitor;
                        competitorGeoData.map((el) => {
                            if (
                                filter &&
                                el.id.toString() === filter.competitorId
                            ) {
                                selectedCompetitor = {
                                    regionId: el.id,
                                    innerColor: '#4c4c4c',
                                    defaultStatus: 'competitors',
                                    ...el
                                };
                                dataClone.selectedData.features.push({
                                    type: 'Feature',
                                    properties: {
                                        regionId: el.id,
                                        innerColor: '#4c4c4c',
                                        defaultStatus: 'competitors',
                                        ...el
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [el.lon, el.lat]
                                    }
                                });
                                return el;
                            } else {
                                dataClone.competitionData.features.push({
                                    type: 'Feature',
                                    properties: {
                                        regionId: el.id,
                                        innerColor: '#34b1eb',
                                        defaultStatus: 'competitors',
                                        ...el
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [el.lon, el.lat]
                                    }
                                });
                                avgLat += el.lat;
                                avgLon += el.lon;
                                return el;
                            }
                        });
                        dataClone.startingPosition = {
                            lat: undefined,
                            lon: undefined,
                            zoom: 14
                        };
                        if (avgLat !== 0 && avgLon !== 0) {
                            dataClone.startingPosition.lat =
                                avgLat /
                                dataClone.competitionData.features.length;
                            dataClone.startingPosition.lon =
                                avgLon /
                                dataClone.competitionData.features.length;
                        } else {
                            dataClone.startingPosition.lat = 38.736946;
                            dataClone.startingPosition.lon = -9.142685;
                        }
                        dataClone.loading = false;
                        dataClone.noData = false;
                        dataClone.ready = true;
                        dataClone.disabled = true;
                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                        firstRender.current = false;
                        if (prospectMapRef.current) {
                            handleMouseClickMapSecondary(
                                prospectMapRef,
                                selectedCompetitor
                            );
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            handleMouseClickProspectMap,
            type
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <MapContainerNew
                            translate={t}
                            reference={prospectMapRef}
                            risk1Data={data.risk1Data}
                            risk2Data={data.risk2Data}
                            risk3Data={data.risk3Data}
                            risk4Data={data.risk4Data}
                            competitionData={data.competitionData}
                            selectedData={data.selectedData}
                            startingPosition={data.startingPosition}
                            tooltipType={data.tooltipType}
                            interactionDisabled={data.interactionDisabled}
                            onMouseOverMap={mouseOverMap}
                            onMouseClickMap={handleMouseClickProspectMap}
                            selectedEl={data.selectedEl}
                            showPopup={data.showPopup}
                            scale={data.scale}
                            minScale={data.minScale}
                            minZoom={data.minZoom}
                            flyEnabled={data.flyEnabled}
                        />
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ProspectGeo.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
