import _ from 'lodash';
import icoSatisfactionHappy from '../../../assets/images/icoSatisfactionHappy.svg';
import icoSatisfactionSad from '../../../assets/images/icoSatisfactionSad.svg';
import styles from './goal-contribution.module.scss';
import React, { useEffect } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted.js';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent.js';
import { useFilter } from '../../../providers/filter-context.js';
import { errorHandler } from '../../../utils/api.js';
import {
    ComponentWrapper,
    DynamicComposedChartContainer
} from '../../index.jsx';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation.js';
import { useTimeInterval } from '../../../providers/time-interval-context.js';
import { useSalesChannel } from '../../../providers/sales-channel-context.js';
import { goalContributionMock } from './mock.jsx';
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush.jsx';

const Chart = withCustomBrush(DynamicComposedChartContainer, 'month');

export const GoalContribution = React.memo(
    ({ component, isPreview, isExample, config, ...rest }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { timeInterval } = useTimeInterval();
        const { channels } = useSalesChannel();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue
        } = useSimpleComponent(goalContributionMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(goalContributionMock);
                try {
                    if (!isExample) {
                        setLoading(true);
                        dataClone.loading = false;
                        setValue(dataClone);
                    } else {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            timeInterval,
            isPreview,
            channels
        ]);

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ComponentWrapper component={component} {...data} {...rest}>
                    <div className="row mx-0 w-100 h-100">
                        <div className="col-9 px-0">
                            <Chart
                                hasCircleTip
                                {...data}
                                {...rest}
                                startsAtZero
                            />
                        </div>
                        <div className="col-3 px-0">
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                    <img
                                        src={icoSatisfactionHappy}
                                        alt="happy"
                                        className={styles['satisfaction-icon']}
                                    />
                                    <span className={styles['ranking-title']}>
                                        Most Contribution
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '1rem' }}
                            >
                                <div className="col px-0">
                                    <span className={styles['ranking-text']}>
                                        1. Team 5
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '1rem' }}
                            >
                                <div className="col px-0">
                                    <span className={styles['ranking-text']}>
                                        2. Team 10
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '1rem' }}
                            >
                                <div className="col px-0">
                                    <span className={styles['ranking-text']}>
                                        3. Team 3
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ComponentWrapper>
            </div>
        );
    }
);

GoalContribution.propTypes = {};
