import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getMonthFromDateFormat, getYearFromDateFormat } from '../../../utils/date'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { ProductsService, SalesService } from '../../../temp/test'
import { stocksVsSalesMock } from './mock'
import PropTypes from 'prop-types'
import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component'
import { useChartReady } from '../../../providers/chart-ready-context'
import { ContentContainer, DynamicBarChartContainer, Loader, Spinner } from '../../'
import { getFilterParam } from '../../../utils/params'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { useSalesChannel } from '../../../providers/sales-channel-context'

/**
 * Displays the comparison and evolution of product sales and stock for each product
 */

export const ProductStocksVsSales = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
    }) => {
        const { charts } = useChartReady()
        const { filter } = useFilter()
        const { timeInterval } = useTimeInterval()
        const { channels } = useSalesChannel()
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()

        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(stocksVsSalesMock)

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(stocksVsSalesMock)
                try {
                    if (!isExample) {
                        setLoading(true)
                        dataClone.data.length = 0
                        const dataBack = await ProductsService.getApiProductsStocks({
                            productIds: getFilterParam(filter, config, ['productId', 'productIds']),
                        })
                        dataClone.data = dataBack.map((el) => {
                            return {
                                month: getMonthFromDateFormat(el.date, t),
                                productName: el.id,
                                productStock: el.quantity,
                                productSales: 0,
                                year: getYearFromDateFormat(el.date),
                            }
                        })
                        dataClone.loading = true
                        const dataBackSales = await SalesService.getApiSales({
                            producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                            interval: timeInterval,
                            clientId: getFilterParam(filter, config, ['clientId', 'clientIds']),
                            vendorId: getFilterParam(filter, config, ['vendorId', 'vendorIds']),
                            locationId: [],
                            productId: getFilterParam(filter, config, ['productId', 'productIds']),
                            sumBy: 'units',
                            channelId: JSON.parse(channels),
                        })
                        Object.entries(dataBackSales).map((el) => {
                            dataClone.data.map((el2) => {
                                if (el2.year === getYearFromDateFormat(el[0]) && el2.month === getMonthFromDateFormat(el[0], t)) {
                                    el2.productSales = el[1]
                                }
                            })
                        })
                        dataClone.barStackedAttr = [
                            {
                                dataKey: 'productSales',
                                color: '#2cd9c5',
                                stackId: 'a',
                                minPointSize: 70,
                                radius: [1000, 100, 0, 0],
                            },
                            {
                                dataKey: 'productStock',
                                color: '#14897c',
                                stackId: 'a',
                                minPointSize: 70,
                                radius: [1000, 100, 0, 0],
                            },
                        ]
                        dataClone.legend = [
                            { name: 'stock', color: '#14897c' },
                            { name: 'sales', color: '#2cd9c5' },
                        ]
                        dataClone.categoryAxis = 'productName'
                        dataClone.categoryLabel = ''
                        dataClone.unitsOrValues = 'units'
                        dataClone.loading = false
                        dataClone.ready = true
                        dataClone.disabled = false
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                    } else {
                        if (type) dataClone.attr = type
                        verifyComponent(dataClone)
                        setValue(dataClone)
                        setLoading(false)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            }
            fill()
        }, [isMounted, setLoading, setValue, t, type, config, filter, isExample, channels, timeInterval])
        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings()
                if (option === 'pin') {
                    onPinComponent(data.attr)
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr)
                } else if (option === 'download') {
                    onDownloadComponent()
                }
            },
            [data.attr, isMounted, onDownloadComponent, onPinComponent, onUnpinComponent, toggleComponentSettings]
        )

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <DynamicBarChartContainer
                            translate={t}
                            barStackedAttr={data.barStackedAttr}
                            categoryAxis={data.categoryAxis}
                            margin={data.margin}
                            hideXTicks={data.hideXTicks}
                            hideXTickLine
                            hideYTicks={data.hideYTicks}
                            hideYTickLine
                            hideCursor={data.hideCursor}
                            barWidth={data.barWidth}
                            data={data.data}
                            orientation={data.orientation}
                            unitsOrValues={'units'}
                            type={data.type}
                            tooltipType={data.tooltipType}
                            interactionDisabled={data.interactionDisabled}
                            labelDisabled={data.labelDisabled}
                        />
                    )}
                </ContentContainer>
            </div>
        )
    }
)

ProductStocksVsSales.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
