import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import styles from './settings-button.module.scss';
import icoCogDefault from './assets/images/ico-cog-default.svg';
import { OptionList } from '../../';
import { useToggle } from '../../../hooks/useToggle';

export const SettingsButton = React.memo(({ settings, disabled }) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    const handleOutsideClick = useCallback(
        (event) => {
            if (settings && settings.open) {
                if (
                    settings.reference.current &&
                    !settings.reference.current.contains(event.target)
                ) {
                    toggleIsOpen();
                }
            }
        },
        [toggleIsOpen, settings]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return function cleanup() {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const iconStyle = useMemo(() => {
        let disabledStyle = disabled ? styles['disabled'] : '';
        let openStyle = isOpen ? styles['open'] : '';
        return `${styles['settings-container__icon']} ${disabledStyle} ${openStyle}`;
    }, [disabled, isOpen]);

    const handleClickSettings = () => {};

    return (
        <div className={`${styles['settings-container']}`}>
            <img
                loading="lazy"
                src={icoCogDefault}
                className={iconStyle}
                onClick={toggleIsOpen}
            />
            {isOpen && (
                <OptionList
                    options={settings.options}
                    onClickOption={handleClickSettings}
                />
            )}
        </div>
    );
});

SettingsButton.propTypes = {
    /**
     * String to choose state attribute where the data is located
     */
    attr: PropTypes.string,
    /**
     * Object containing two attributtes: view | options.
     * Each attribute is an array of objects containing four attributes:
     * name: String to display setting label
     * icon: String to choose which icon to use
     * iconDefault: Image to show when icon === 'default'
     * iconSelected: Image to show when icon === 'selected'
     */
    settings: PropTypes.object,
    /**
     * Boolean to disable button if set to true
     */
    disabled: PropTypes.bool,
    /**
     * Function to handle clicking on a view settings
     */
    onClickSettingsView: PropTypes.func,
    /**
     * Function to handle clicking on an option settings
     */
    onClickSettings: PropTypes.func
};
