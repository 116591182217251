import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import styles from './mini-area-chart-new-container.module.scss'
import { getMonthFromDateFormat } from '../../../utils/date'
import { TooltipFactory } from '../../chart-configuration/tooltip-factory/tooltip-factory'

export const MiniAreaChartNewContainer = React.memo(
    ({ areaAttr, categoryAxis, data, lineType = 'monotone', minValue, maxValue, type, prefix, attr = '' }) => {
        const [animationActive, setAnimationActive] = React.useState(true)

        const minDate = useMemo(() => {
            return getMonthFromDateFormat(
                data.reduce((acc, curr) => {
                    return acc.date < curr.date ? acc : curr
                })['date']
            )
        }, [data])

        const maxDate = useMemo(() => {
            return getMonthFromDateFormat(
                data.reduce((acc, curr) => {
                    return acc.date > curr.date ? acc : curr
                })['date']
            )
        }, [data])

        return (
            <div className="w-100 h-100 d-flex flex-column">
                <div className="row mx-0 w-100 flex-grow-1" style={{ minHeight: 0 }}>
                    <div className="col px-0">
                        <ResponsiveContainer key={data.length}>
                            <AreaChart
                                className={`${styles['area-chart-container']}`}
                                data={data}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <Tooltip
                                    content={
                                        <TooltipFactory
                                            item={{
                                                type: attr || '',
                                            }}
                                        />
                                    }
                                    wrapperStyle={{
                                        zIndex: 1000,
                                        outline: 'none',
                                    }}
                                    cursor={false}
                                />
                                <defs>
                                    <filter id="glow">
                                        <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
                                        <feMerge>
                                            <feMergeNode in="coloredBlur" />
                                            <feMergeNode in="SourceGraphic" />
                                        </feMerge>
                                    </filter>
                                </defs>
                                <XAxis type="category" dataKey={categoryAxis} hide />
                                <YAxis type="number" allowDataOverflow hide />
                                {areaAttr.map((attr, index) => {
                                    const stackId = index

                                    return (
                                        <Area
                                            isAnimationActive={animationActive}
                                            onAnimationEnd={() => setAnimationActive(false)}
                                            key={index}
                                            type={lineType}
                                            dataKey={attr.dataKey}
                                            stackId={stackId}
                                            stroke={attr.color}
                                            strokeWidth={attr.strokeWidth}
                                            height={100}
                                            fill="none"
                                            filter='url("#glow")'
                                            fillOpacity={type === 'risk' ? 0 : 1}
                                        />
                                    )
                                })}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="row mx-0 w-100 d-flex justify-content-between">
                    <div className="col-auto px-0">
                        <span className={styles['mini-area-chart-new-container__date']}>{minDate}</span>
                    </div>
                    <div className="col-auto px-0">
                        <span className={styles['mini-area-chart-new-container__date']}>{maxDate}</span>
                    </div>
                </div>
            </div>
        )
    }
)

MiniAreaChartNewContainer.propTypes = {
    areaAttr: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string,
            color: PropTypes.string,
            strokeWidth: PropTypes.number,
        })
    ),
    categoryAxis: PropTypes.string,
    data: PropTypes.array,
    lineType: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    type: PropTypes.string,
    prefix: PropTypes.string,
}
