import React from 'react';
import styles from './EnterpriseInformation.module.scss';
import icoEnterpriseLocation from '../../../../../assets/images/icoEnterpriseLocation.svg';

export const EnterpriseInformation = () => {
    return (
        <div className={styles['enterprise-information-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <img
                        src={icoEnterpriseLocation}
                        alt="enterprise"
                        className={
                            styles['enterprise-information-container__icon']
                        }
                    />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={
                            styles['enterprise-information-container__title']
                        }
                    >
                        GlobalTech
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={
                            styles[
                                'enterprise-information-container__content-text'
                            ]
                        }
                    >
                        Main Street 123
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={
                            styles[
                                'enterprise-information-container__content-text'
                            ]
                        }
                    >
                        12345, Main City
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={
                            styles[
                                'enterprise-information-container__sub-title'
                            ]
                        }
                    >
                        Iceland
                    </span>
                </div>
            </div>
        </div>
    );
};
