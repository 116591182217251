import React, { useEffect } from 'react'
import { useToggle } from '../../../hooks/useToggle'
import styles from './edit-profile.module.scss'
import { useTextInput } from '../../../hooks/useTextInput'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emailSchema } from '../../../schemas/fieldSchemas'
import { errorHandler } from '../../../utils/api'
import { ProfileService } from '../../../temp/test'
import { useCurrentUser } from '../../../providers/current-user-context'
import { LoaderForeground, PrimaryButton, SecondaryButton, Spinner, TerciaryButton, TypeInput } from '../../../components'
import { success } from '../../../components/notifications/notification/notification'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { ActionBar } from '../../../components/actions/action-bar/action-bar'
import icoArrowBackAction from '../../../assets/images/icoArrowBackAction.svg'
import icoSaveCurrentDashboard from '../../../assets/images/icoSaveCurrentDashboard.svg'

/**
 * Provides a page layout that allows managers to view their profile details
 */

const EditProfile = React.memo(() => {
    const { currentUser } = useCurrentUser()
    const [loading] = useToggle(false)
    const { t } = useCustomTranslation()
    const navigate = useNavigate()
    const [teams] = React.useState([
        {
            name: 'Team 1',
        },
        {
            name: 'Team 2',
        },
    ])
    const {
        value: username,
        setValue: usernameSetValue,
        replaceValue: usernameReplaceValue,
    } = useTextInput({
        name: 'username',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: firstName,
        setValue: firstNameSetValue,
        replaceValue: firstNameReplaceValue,
    } = useTextInput({
        name: 'firstName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: lastName,
        setValue: lastNameSetValue,
        replaceValue: lastNameReplaceValue,
    } = useTextInput({
        name: 'lastName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: email,
        setValue: emailSetValue,
        replaceValue: emailReplaceValue,
    } = useTextInput({
        name: 'email',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: phone,
        setValue: phoneSetValue,
        replaceValue: phoneReplaceValue,
    } = useTextInput({
        name: 'phone',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const { value: emailToSend, setValue: emailToSendSetValue } = useTextInput({
        name: 'emailToSend',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
        schema: emailSchema,
    })

    useEffect(() => {
        async function fill() {
            try {
                const result = await ProfileService.getApiProfileUser({
                    uuid: currentUser.uuid,
                })
                usernameReplaceValue(result.username)
                firstNameReplaceValue(result.name.split(' ')[0])
                lastNameReplaceValue(result.name.split(' ')[1])
                emailReplaceValue(result.email)
                phoneReplaceValue(result.mobile_phone)
            } catch (err) {
                errorHandler(err)
            }
        }
        fill()
    }, [currentUser.uuid, usernameReplaceValue, firstNameReplaceValue, lastNameReplaceValue, emailReplaceValue, phoneReplaceValue])

    const handleSendPasswordReset = async () => {
        try {
            await ProfileService.postApiAuthForgotPassword({
                requestBody: {
                    email: emailToSend.value,
                    redirect_url: window.location.origin + '/reset-password',
                },
            })
            success({ text: t('pages:forgotPassword.sentEmail') })
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleEditProfile = async () => {
        try {
            await ProfileService.patchApiProfileUserEdit({
                uuid: currentUser.uuid,
                requestBody: {
                    username: username.value,
                    name: firstName.value + ' ' + lastName.value,
                    email: email.value,
                    mobile_phone: phone.value,
                },
            })
            success({ text: t('pages:editProfile.saved') })
            navigate('/profile/view')
        } catch (err) {
            errorHandler(err)
        }
    }

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div className={`row mx-0 w-100`} style={{ padding: '0 2rem 2rem 2rem' }}>
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">{t('pages:editProfile.title')}</div>
                                    <div className="col-auto px-0">
                                        <ActionBar
                                            actions={[
                                                {
                                                    text: t('buttons:cancel'),
                                                    icon: icoArrowBackAction,
                                                    onClick: () => {
                                                        navigate('/profile/view')
                                                    },
                                                },
                                                {
                                                    text: t('buttons:save'),
                                                    icon: icoSaveCurrentDashboard,
                                                    onClick: () => {
                                                        handleEditProfile()
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`row mx-0 w-100 ${styles['edit-profile-container']}`}>
                            <div className="col px-0">
                                <div className={`row mx-0 w-100 ${styles['edit-profile-container__row-container']}`} style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <span className={`${styles['edit-profile-container__row-container__title']}`}>
                                                {t('pages:editProfile.accountInformation')}
                                            </span>
                                        </div>
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <div
                                                className="col-auto px-0 d-flex justify-content-center align-items-center"
                                                style={{ marginRight: '1rem' }}
                                            >
                                                <TypeInput onChangeInput={usernameSetValue} {...username} />
                                            </div>
                                            <div
                                                className="col-auto px-0 d-flex justify-content-center align-items-center"
                                                style={{ marginRight: '1rem' }}
                                            >
                                                <TypeInput onChangeInput={firstNameSetValue} {...firstName} />
                                            </div>
                                            <div
                                                className="col-auto px-0 d-flex justify-content-center align-items-center"
                                                style={{ marginRight: '1rem' }}
                                            >
                                                <TypeInput onChangeInput={lastNameSetValue} {...lastName} />
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <div
                                                className="col-auto px-0 d-flex justify-content-center align-items-center"
                                                style={{ marginRight: '1rem' }}
                                            >
                                                <TypeInput disabled onChangeInput={emailSetValue} {...email} />
                                            </div>
                                            <div
                                                className="col-auto px-0 d-flex justify-content-center align-items-center"
                                                style={{ marginRight: '1rem' }}
                                            >
                                                <TypeInput onChangeInput={phoneSetValue} {...phone} />
                                            </div>
                                            <div className="col-auto px-0" style={{ marginRight: '1rem' }}>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={`${styles['edit-profile-container__legend']}`}>
                                                            {t('pages:editProfile.teams')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        {teams.map((team, index) => (
                                                            <div key={index} className="text-label">
                                                                {team.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row mx-0 w-100 ${styles['edit-profile-container__row-container']}`}>
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                                            <span className={`${styles['edit-profile-container__row-container__title']}`}>
                                                {t('pages:editProfile.changePassword')}
                                            </span>
                                        </div>
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <span className={`${styles['edit-profile-container__row-container__sub-title']}`}>
                                                {t('pages:editProfile.changePasswordDesc')}
                                            </span>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div
                                                className="col-auto px-0 d-flex justify-content-center align-items-center"
                                                style={{
                                                    marginRight: '1rem',
                                                    width: '20rem',
                                                }}
                                            >
                                                <TypeInput onChangeInput={emailToSendSetValue} {...emailToSend} />
                                            </div>{' '}
                                            <div className="col-auto px-0" style={{ width: '10rem' }}>
                                                <TerciaryButton
                                                    text={t('buttons:send')}
                                                    disabled={!emailToSend.value || emailToSend.error}
                                                    onClick={() => {
                                                        handleSendPasswordReset()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

export default EditProfile

EditProfile.propTypes = {}
