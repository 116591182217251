import React, { useMemo, useState } from 'react';
import styles from './AuthenticationAndSecurity.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { NavigationMenu } from '../../../components';
import { SingleSignOn } from './components/single-sign-on/SingleSignOn';
import { TwoFactor } from './components/two-factor/TwoFactor';

export const AuthenticationAndSecurity = () => {
    const { t } = useCustomTranslation();
    const navOptions = useMemo(() => {
        return ['singleSignOn', '2FA'];
    }, []);

    const [selectedOption, setSelectedOption] = useState('singleSignOn');
    return (
        <div className={styles['authentication-and-security-container']}>
            <div className="row mx-0 w-100 dashboard-header-row">
                <div className="col px-0">
                    {t('pages:authenticationAndSecurity.title')}
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <NavigationMenu
                        isPageMenu
                        options={navOptions}
                        selectedOption={selectedOption}
                        onChangeOption={(option) => setSelectedOption(option)}
                    />
                </div>
            </div>
            <div
                className="row mx-0 w-100 flex-grow-1"
                style={{ minHeight: 0 }}
            >
                <div className="col px-0">
                    {selectedOption === 'singleSignOn' && <SingleSignOn />}
                    {selectedOption === '2FA' && <TwoFactor />}
                </div>
            </div>
        </div>
    );
};
