/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalBillingInfoSchema } from '../models/GlobalBillingInfoSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BillingService {
    /**
     * Returns Current billing
     * @returns GlobalBillingInfoSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeBilling(): CancelablePromise<GlobalBillingInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/billing',
        });
    }
    /**
     * Returns Current billing
     * @returns GlobalBillingInfoSchema call successful
     * @throws ApiError
     */
    public static patchApiBackofficeBilling({
        requestBody,
    }: {
        requestBody: GlobalBillingInfoSchema,
    }): CancelablePromise<GlobalBillingInfoSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/backoffice/billing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns billing timeseries in euros
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiBackofficeBillingSentiment({
        minDate,
        maxDate,
    }: {
        /**
         * minimum date to filter results.
         */
        minDate?: string,
        /**
         * max date to filter results.
         */
        maxDate?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/billing/sentiment',
            query: {
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
}
