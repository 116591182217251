import React from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { TerciaryButton } from '../../../../buttons/terciary-button/terciary-button';

export const GoalDetailsTableColumn = ({ setGoalDetailsModal }) => {
    const { t } = useCustomTranslation();
    return (
        <TerciaryButton
            text={t('inputs:details')}
            onClick={() => setGoalDetailsModal(true)}
        />
    );
};
