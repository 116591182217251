import React from 'react'
import PropTypes from 'prop-types'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import styles from './mini-area-chart-container.module.scss'

export const MiniAreaChartContainer = React.memo(({ areaAttr, categoryAxis, data, lineType = 'monotone', minValue, maxValue, type, prefix }) => {
    const [animationActive, setAnimationActive] = React.useState(true)
    const getGradientColors = (maxValue, minValue) => {
        const gradientColors = []

        for (let i = minValue; i <= maxValue; i++) {
            // Define your color logic based on the value i
            let color
            if (i === 1) {
                color = '#6fdb7d'
            } else if (i === 2) {
                color = '#dbdb6f'
            } else if (i === 3) {
                color = '#ffbd77'
            } else if (i === 4) {
                color = '#ff6363'
            } else {
                // Handle other values as needed
                color = '#000000'
            }

            gradientColors.push(color)
        }

        return gradientColors
    }

    const gradientColors = getGradientColors(maxValue, minValue)

    return (
        <ResponsiveContainer key={data.length}>
            <AreaChart
                className={`${styles['area-chart-container']}`}
                data={data}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                {type === 'risk' && (
                    <defs>
                        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                            {gradientColors.map((color, index) => (
                                <stop offset={(index / maxValue).toFixed(2)} stopColor={color} key={index} />
                            ))}
                        </linearGradient>
                    </defs>
                )}
                {type !== 'risk' && (
                    <defs>
                        {areaAttr.map((attr, index) => {
                            const newPerc = (100 * (maxValue - minValue)) / maxValue
                            const gradientId = prefix ? `${prefix}-${attr.dataKey}` : attr.dataKey
                            return (
                                <linearGradient key={gradientId} id={gradientId} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={attr.color} stopOpacity={0.9} />
                                    <stop offset="25%" stopColor={attr.color} stopOpacity={0.7} />
                                    <stop offset={`50%`} stopColor={attr.color} stopOpacity={0.3} />
                                    <stop offset={`70%`} stopColor={attr.color} stopOpacity={0.1} />
                                    <stop offset={`100%`} stopColor={attr.color} stopOpacity={0} />
                                </linearGradient>
                            )
                        })}
                    </defs>
                )}
                <XAxis type="category" dataKey={categoryAxis} hide />
                <YAxis type="number" allowDataOverflow hide />
                {areaAttr.map((attr, index) => {
                    const stackId = index
                    const gradientFill = prefix ? `url(#${prefix}-${attr.dataKey})` : `url(#${attr.dataKey})`

                    return (
                        <Area
                            isAnimationActive={animationActive}
                            onAnimationEnd={() => setAnimationActive(false)}
                            key={index}
                            type={lineType}
                            dataKey={attr.dataKey}
                            stackId={stackId}
                            stroke={type === 'risk' ? 'url(#splitColor)' : attr.color}
                            strokeWidth={attr.strokeWidth}
                            height={100}
                            fill={gradientFill}
                            fillOpacity={type === 'risk' ? 0 : 1}
                        />
                    )
                })}
            </AreaChart>
        </ResponsiveContainer>
    )
})

MiniAreaChartContainer.propTypes = {
    areaAttr: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string,
            color: PropTypes.string,
            strokeWidth: PropTypes.number,
        })
    ),
    categoryAxis: PropTypes.string,
    data: PropTypes.array,
    lineType: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    type: PropTypes.string,
    prefix: PropTypes.string,
}
