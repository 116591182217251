import React from 'react';
import styles from './segment-overview.module.scss';
import { SegmentOverviewCard } from '../segment-overview-card';
import { SegmentList } from '../segment-list';
//import { SentimentProportions } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-proportions/sentiment-proportions';
//import { SentimentOverTime } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-over-time/sentiment-over-time';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SegmentOverview = React.memo(({ onSelectSegment }) => {
    return (
        <div className={`row mx-0 w-100`}>
            <div className="col px-0">
                <div
                    className="row mx-0"
                    style={{ height: '30rem', marginBottom: '2rem' }}
                >
                    <div className="col px-0 h-100">
                        <SegmentOverviewCard />
                    </div>
                </div>
                <div
                    className={`row mx-0 ${styles['segment-overview-container__last-row']}`}
                    style={{ height: '40rem' }}
                >
                    <div className="col px-0">
                        <SegmentList onSelectSegment={onSelectSegment} />
                    </div>
                </div>
            </div>
        </div>
    );
});

SegmentOverview.propTypes = {};
