import Joi from 'joi'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import icoDelete from '../../../assets/images/ico-delete.svg'
import icoEdit from '../../../assets/images/ico-edit.svg'
import { userListMock } from './mock'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useToggle } from '../../../hooks/useToggle'
import { useTable } from '../../../hooks/useTable'
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput'
import { useTextInput } from '../../../hooks/useTextInput'
import { ProfileService, RolesService } from '../../../temp/test'
import { verifyComponent } from '../../../utils/component'
import { errorHandler } from '../../../utils/api'
import { ContentContainer, DeleteButton, Loader, Modal, PrimaryButton, SecondaryButton, SelectMultipleInput, Spinner, Table, TypeInput } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { success } from '../../notifications/notification/notification'

/**
 * Lists all the users in a table
 */

export const UserList = React.memo(({ onSelectUser }) => {
    const isMounted = useIsMounted()
    const { t } = useCustomTranslation()
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = useState('')
    const [selectedUserId, setSelectedUserId] = useState('')
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon,
    } = useTable(userListMock, modal, '')
    const {
        value: role,
        addOption: roleAddOption,
        toggleDropdown: roleToggleDropdown,
        selectOption: roleSelectOption,
        unselectOption: roleUnselectOption,
        keyPress: roleKeyPress,
        keyDown: roleKeyDown,
        setValue: roleSetValue,
        reset: roleReset,
        replaceSelectedOptions: roleReplaceSelectedOptions,
        setError: roleSetError,
    } = useSelectMultipleInput({
        name: 'role',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOptions: [],
        selectedOptionsAux: [],
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef(),
    })
    const {
        value: department,
        reset: resetDepartment,
        setValue: setDepartmentValue,
        setError: setDepartmentError,
        replaceValue: replaceDepartment,
    } = useTextInput({
        name: 'department',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: email,
        reset: resetEmail,
        setValue: setEmailValue,
        setError: setEmailError,
        replaceValue: replaceEmail,
    } = useTextInput({
        name: 'email',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: empNo,
        reset: resetEmpNo,
        setValue: setEmpNoValue,
        setError: setEmpNoError,
        replaceValue: replaceEmpNo,
    } = useTextInput({
        name: 'empNo',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: phone,
        reset: resetPhone,
        setValue: setPhoneValue,
        setError: setPhoneError,
        replaceValue: replacePhone,
    } = useTextInput({
        name: 'phone',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: name,
        reset: resetName,
        setValue: setNameValue,
        setError: setNameError,
        replaceValue: replaceName,
    } = useTextInput({
        name: 'name',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: username,
        reset: resetUsername,
        setValue: setUsernameValue,
        setError: setUsernameError,
        replaceValue: replaceUsername,
    } = useTextInput({
        name: 'username',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const schema = Joi.object({
        role: Joi.array().items(Joi.string().required()),
        department: Joi.required(),
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .required(),
        // empNo: Joi.required(),
        phone: Joi.required(),
        name: Joi.required(),
        username: Joi.required(),
    })

    const getUsers = useCallback(async () => {
        const dataClone = _.cloneDeep(userListMock)
        try {
            dataClone.data.length = 0
            dataClone.sortedData.length = 0
            if (isMounted.current) {
                setValue(dataClone)
                setLoading(true)
            }
            const userBackData = await ProfileService.getApiProfileListAccounts()
            userBackData.map((el) => {
                let newElRoles = ''
                el.roles.map((el2, index2) => {
                    if (index2 !== 0) {
                        newElRoles += ', '
                    }
                    newElRoles += el2.name
                    return el2
                })
                const newEl = {
                    id: el.uuid,
                    empNo: el.emp_no ? el.emp_no : '',
                    name: el.name ? el.name : '',
                    username: el.username ? el.username : '',
                    email: el.email ? el.email : '',
                    department: el.department ? el.department : '',
                    role: newElRoles ? newElRoles : '',
                    phone: el.mobile_phone ? el.mobile_phone : '',
                    action: {
                        label: t(`columns:action`),
                        id: el.uuid,
                        open: false,
                        options: [
                            {
                                name: 'removeUser',
                                label: t('settings:removeUser'),
                                icon: icoDelete,
                            },
                            {
                                name: 'editUser',
                                label: t('settings:editUser'),
                                icon: icoEdit,
                            },
                        ],
                        reference: React.createRef(),
                    },
                }
                dataClone.data.push(newEl)
                return el
            })
            dataClone.sortedData = _.orderBy(dataClone.data, [dataClone.sortColumn.path], [dataClone.sortColumn.order])
            verifyComponent(dataClone)
            if (isMounted.current) {
                setValue(dataClone)
                setLoading(false)
            }
        } catch (err) {
            errorHandler(err)
            dataClone.loading = false
            if (isMounted.current) {
                setValue(dataClone)
            }
        }
    }, [isMounted, setLoading, setValue, t])

    useEffect(() => {
        getUsers()
    }, [getUsers])

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false)
            setModalType('')
            setSelectedUserId('')
        }
    }

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedUserId(id)
            }
            if (option === 'addUser') {
                if (isMounted.current) {
                    roleReset()
                    resetDepartment()
                    resetEmail()
                    // resetEmpNo();
                    resetPhone()
                    resetName()
                    resetUsername()
                }
                try {
                    const roleBackData = await RolesService.getApiBackofficeRoles()
                    roleBackData.map((el, index) => {
                        if (isMounted.current) roleAddOption(el.name.trim(), index)
                        return el
                    })
                } catch (err) {
                    errorHandler(err)
                }
            } else if (option === 'editUser') {
                if (isMounted.current) {
                    roleReset()
                    resetDepartment()
                    resetEmail()
                    // resetEmpNo();
                    resetPhone()
                    resetName()
                    resetUsername()
                }
                const selectedOptions = []
                try {
                    const roleBackData = await RolesService.getApiBackofficeRoles()
                    const userBackData = await ProfileService.getApiProfileListAccounts()
                    const index = _.findIndex(data.data, (el) => {
                        return el.id === id
                    })
                    if (index !== -1) {
                        roleBackData.map((el) => {
                            userBackData.map((el2) => {
                                if (el2.uuid === id) {
                                    if (_.findIndex(el2.roles, (el3) => el3.name === el.name) === -1) {
                                        if (isMounted.current) roleAddOption(el.name, el.name)
                                    }
                                }
                                return el2
                            })
                            return el
                        })
                        if (isMounted.current) {
                            replaceName(data.data[index].name)
                            replaceUsername(data.data[index].username)
                            replaceEmail(data.data[index].email)
                            replaceDepartment(data.data[index].department)
                            // replaceEmpNo(data.data[index].empNo);
                            replacePhone(data.data[index].phone)
                        }
                        data.data[index].role.split(',').map((el) => {
                            selectedOptions.push(el.trim())
                            return el
                        })
                        if (isMounted.current) roleReplaceSelectedOptions(selectedOptions, selectedOptions)
                    }
                } catch (err) {
                    errorHandler(err)
                }
            }
            if (isMounted.current) {
                toggleComponentSettings(false)
                toggleModal(true)
                setModalType(option)
            }
        },
        [
            data.data,
            isMounted,
            replaceDepartment,
            replaceEmail,
            replaceName,
            replacePhone,
            replaceUsername,
            resetDepartment,
            resetEmail,
            resetName,
            resetPhone,
            resetUsername,
            roleAddOption,
            roleReplaceSelectedOptions,
            roleReset,
            toggleComponentSettings,
            toggleModal,
        ]
    )

    const handleCreateUser = async () => {
        try {
            const result = schema.validate(
                {
                    role: role.selectedOptions,
                    department: department.value,
                    email: email.value,
                    phone: phone.value,
                    name: name.value,
                    username: username.value,
                },
                { abortEarly: false }
            )
            if (isMounted.current) {
                roleSetError()
                setDepartmentError()
                setEmailError()
                setPhoneError()
                setNameError()
                setNameError()
                setUsernameError()
            }
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'role') {
                        if (isMounted.current) roleSetError(el.type)
                    } else if (el.path[0] === 'department') {
                        if (isMounted.current) setDepartmentError(el.type)
                    } else if (el.path[0] === 'email') {
                        if (isMounted.current) setEmailError(el.type)
                    } else if (el.path[0] === 'phone') {
                        if (isMounted.current) setPhoneError(`${el.type}.phone`)
                    } else if (el.path[0] === 'name') {
                        if (isMounted.current) setNameError(el.type)
                    } else if (el.path[0] === 'username') {
                        if (isMounted.current) setUsernameError(el.type)
                    }
                    return null
                })
                return
            }
            const roles = []
            role.selectedOptions.map((el) => {
                const newRole = {
                    name: el,
                }
                newRole.name = el
                roles.push(newRole)
                return el
            })
            const user = {
                completed_tutorial: undefined,
                department: department.value,
                email: email.value,
                emp_no: undefined,
                image_url: undefined,
                mobile_phone: phone.value,
                name: name.value,
                roles: roles,
                username: username.value,
            }
            setLoading(true)
            await ProfileService.postApiProfileUser({ requestBody: user })
            if (isMounted.current) {
                toggleModal(false)
                setLoading(false)
            }
            success({ text: t('notifications:userCreated') })
            getUsers()
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleDeleteUser = async () => {
        try {
            await ProfileService.deleteApiProfileUser({ uuid: selectedUserId })
            if (isMounted.current) {
                toggleModal(false)
                setLoading(false)
            }
            success({ text: t('notifications:userDeleted') })
            getUsers()
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleEditUser = async () => {
        try {
            const result = schema.validate(
                {
                    role: role.selectedOptions,
                    department: department.value,
                    email: email.value,
                    phone: phone.value,
                    name: name.value,
                    username: username.value,
                },
                { abortEarly: false }
            )
            roleSetError()
            setDepartmentError()
            setEmailError()
            // setEmpNoError();
            setPhoneError()
            setNameError()
            setNameError()
            setUsernameError()
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'role') {
                        roleSetError(el.type)
                    } else if (el.path[0] === 'department') {
                        setDepartmentError(el.type)
                    } else if (el.path[0] === 'email') {
                        setEmailError(el.type)
                    } else if (el.path[0] === 'phone') {
                        setPhoneError(`${el.type}.phone`)
                    } else if (el.path[0] === 'name') {
                        setNameError(el.type)
                    } else if (el.path[0] === 'username') {
                        setUsernameError(el.type)
                    }
                    return null
                })
                return
            }
            const roles = []
            role.selectedOptions.map((el) => {
                const newRole = {
                    name: el,
                }
                newRole.name = el
                roles.push(newRole)
                return el
            })
            const user = {
                completed_tutorial: undefined,
                department: department.value,
                email: email.value,
                emp_no: undefined,
                image_url: undefined,
                mobile_phone: phone.value,
                name: name.value,
                roles: roles,
                username: username.value,
            }
            setLoading(true)
            await ProfileService.patchApiProfileUserEdit({
                uuid: selectedUserId,
                requestBody: user,
            })
            if (isMounted.current) {
                toggleModal(false)
                setLoading(false)
            }
            success({ text: t('notifications:userEdited') })
            getUsers()
        } catch (err) {
            errorHandler(err)
        }
    }

    return (
        <>
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <React.Fragment>
                        {modal && modalType === 'addUser' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('modals:createuser')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setDepartmentValue} {...department} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setEmailValue} {...email} />
                                    </div>
                                </div>
                                {/* <div
                                    className="row mx-0 "
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInput
                                            onChangeInput={setEmpNoValue}
                                            {...empNo}
                                        />
                                    </div>
                                </div> */}
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setPhoneValue} {...phone} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setNameValue} {...name} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setUsernameValue} {...username} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <SelectMultipleInput
                                            hideOptionsAux
                                            onChangeInput={roleSetValue}
                                            onSelectMultiple={roleSelectOption}
                                            onSelectedOptionClick={roleUnselectOption}
                                            onKeyPress={roleKeyPress}
                                            onKeyDown={roleKeyDown}
                                            onToggleDropdown={roleToggleDropdown}
                                            {...role}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton text={t('modals:save')} onClick={handleCreateUser} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'editUser' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('modals:editUser')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setDepartmentValue} {...department} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setEmailValue} {...email} />
                                    </div>
                                </div>
                                {/* <div
                                    className="row mx-0 "
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInput
                                            onChangeInput={setEmpNoValue}
                                            {...empNo}
                                        />
                                    </div>
                                </div> */}
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setPhoneValue} {...phone} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setNameValue} {...name} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setUsernameValue} {...username} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <SelectMultipleInput
                                            hideOptionsAux
                                            disabled={role.disabled}
                                            onChangeInput={roleSetValue}
                                            onSelectMultiple={roleSelectOption}
                                            onSelectedOptionClick={roleUnselectOption}
                                            onKeyPress={roleKeyPress}
                                            onKeyDown={roleKeyDown}
                                            onToggleDropdown={roleToggleDropdown}
                                            {...role}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton text={t('modals:save')} onClick={handleEditUser} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'removeUser' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('pages:managerProfile.areyousure')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton text={t('modals:remove')} onClick={handleDeleteUser} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        <Table
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(`components:${data.attr}.nodata`)}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={
                                !_.find(data.data, (el) => {
                                    return el.action.open
                                })
                                    ? onSelectUser
                                    : undefined
                            }
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                            onNewElementClick={() => {
                                handleSelectSettingsOption(data.attr, undefined, 'addUser')
                            }}
                            onNewElementText={t('buttons:add')}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </>
    )
})

UserList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func,
}
