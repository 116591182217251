import _ from 'lodash';
import { useCallback, useReducer } from 'react';

const ACTIONS = {
  SET_COLUMNS: 'setColumns',
  CLEAR: 'clear',
  CLEAR_COLUMN: 'clearColumn',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_COLUMNS:
      return [...action.payload];
    case ACTIONS.CLEAR_COLUMN:{
      const idx = _.findIndex(state, (el) => {
        return el.value === action.payload;
      });
      if (idx !== -1) {
        state[idx].mapped = false;
        return [...state];
      }
    }
    break
    case ACTIONS.CLEAR:
      return [...action.payload];
  }
}

export function useColumns(defaultValue) {
  const [columns, dispatch] = useReducer(reducer, defaultValue);

  const setColumns = useCallback((value) => {
    dispatch({ type: 'setColumns', payload: value });
  }, []);

  const clearColumn = useCallback((name) => {
    dispatch({ type: 'clearColumn', payload: name });
  }, []);

  const clear = useCallback(() => {
    dispatch({ type: 'clear', payload: defaultValue });
  }, [defaultValue]);

  return {
    columns,
    setColumns,
    clear,
    clearColumn,
  };
}
