import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { CustomBrush } from '../../components';
import { useCustomTranslation } from '../../hooks/useCustomTranslation';
import { useTimeInterval } from '../../providers/time-interval-context';

// HOC that adds state management to a component

export function withCustomBrush(WrappedComponent) {
    return function ({ data = [], ...rest }) {
        const { t } = useCustomTranslation();
        const { timeInterval } = useTimeInterval();
        const [initialMinDate, setInitialMinDate] = useState(undefined);
        const [initialMaxDate, setInitialMaxDate] = useState(undefined);
        const [minDate, setMinDate] = useState(undefined);
        const [maxDate, setMaxDate] = useState(undefined);

        const filteredData = React.useMemo(() => {
            if (minDate === undefined || maxDate === undefined) return data;
            data.sort((a, b) => a.time.localeCompare(b.time));
            return data.filter((el) => {
                return (
                    new Date(el.time) >= new Date(minDate) &&
                    new Date(el.time) <= new Date(maxDate)
                );
            });
        }, [maxDate, minDate, data]);

        useEffect(() => {
            if (data && data.length > 0 && _.every(data, 'time')) {
                let minDate = new Date(
                    data && data.length > 0 && _.every(data, 'time')
                        ? Math.max(
                              Math.min(...data.map((el) => new Date(el.time))),
                              new Date('2022-01-01')
                          )
                        : '2022-01-01'
                );
                let maxDate = new Date(
                    data && data.length > 0 && _.every(data, 'time')
                        ? Math.max(...data.map((el) => new Date(el.time)))
                        : '2023-01-01'
                );
                if (timeInterval === 'day') {
                    // check if initialMinDate is less than 365 days before maxDate
                    const thirtyDaysBeforeMinDate = new Date(maxDate);
                    thirtyDaysBeforeMinDate.setDate(maxDate.getDate() - 30);

                    // check if minDate is less than 7 days before maxDate
                    const sevenDaysBeforeMax = new Date(maxDate);
                    sevenDaysBeforeMax.setDate(maxDate.getDate() - 7);
                    if (minDate < sevenDaysBeforeMax) {
                        setMinDate(
                            sevenDaysBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setMinDate(minDate.toISOString().split('T')[0]);
                    }

                    if (minDate < thirtyDaysBeforeMinDate) {
                        setInitialMinDate(
                            thirtyDaysBeforeMinDate.toISOString().split('T')[0]
                        );
                    } else {
                        setInitialMinDate(minDate.toISOString().split('T')[0]);
                    }

                    setMaxDate(maxDate.toISOString().split('T')[0]);

                    setInitialMaxDate(maxDate.toISOString().split('T')[0]);
                } else if (timeInterval === 'week') {
                    // Check if initialMinDate is less than 52 weeks before maxDate
                    const fiftyTwoWeeksBeforeMax = new Date(maxDate);
                    fiftyTwoWeeksBeforeMax.setDate(maxDate.getDate() - 52 * 7);

                    // Check if minDate is less than 12 weeks before maxDate
                    const twelveWeeksBeforeMax = new Date(maxDate);
                    twelveWeeksBeforeMax.setDate(maxDate.getDate() - 12 * 7);

                    if (minDate < twelveWeeksBeforeMax) {
                        setMinDate(
                            twelveWeeksBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setMinDate(minDate.toISOString().split('T')[0]);
                    }

                    if (minDate < fiftyTwoWeeksBeforeMax) {
                        setInitialMinDate(
                            fiftyTwoWeeksBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setInitialMinDate(minDate.toISOString().split('T')[0]);
                    }

                    setMaxDate(maxDate.toISOString().split('T')[0]);

                    setInitialMaxDate(maxDate.toISOString().split('T')[0]);
                } else if (timeInterval === 'month') {
                    // Check if initialMinDate is less than 60 months before maxDate
                    const sixtyMonthsBeforeMax = new Date(maxDate);
                    sixtyMonthsBeforeMax.setMonth(maxDate.getMonth() - 60);

                    // Check if minDate is less than 6 months before maxDate
                    const elevenMonthsBeforeMax = new Date(maxDate);
                    elevenMonthsBeforeMax.setMonth(maxDate.getMonth() - 11);

                    if (minDate < elevenMonthsBeforeMax) {
                        setMinDate(
                            elevenMonthsBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setMinDate(minDate.toISOString().split('T')[0]);
                    }

                    if (minDate < sixtyMonthsBeforeMax) {
                        setInitialMinDate(
                            sixtyMonthsBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setInitialMinDate(minDate.toISOString().split('T')[0]);
                    }

                    setMaxDate(maxDate.toISOString().split('T')[0]);

                    setInitialMaxDate(maxDate.toISOString().split('T')[0]);
                } else if (timeInterval === 'year') {
                    // Check if initialMinDate is less than 5 years before maxDate
                    const fiveYearsBeforeMax = new Date(maxDate);
                    fiveYearsBeforeMax.setFullYear(maxDate.getFullYear() - 5);

                    // Check if minDate is less than 1 year before maxDate
                    const oneYearBeforeMax = new Date(maxDate);
                    oneYearBeforeMax.setFullYear(maxDate.getFullYear() - 1);

                    if (minDate < oneYearBeforeMax) {
                        setMinDate(
                            oneYearBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setMinDate(minDate.toISOString().split('T')[0]);
                    }

                    if (minDate < fiveYearsBeforeMax) {
                        setInitialMinDate(
                            fiveYearsBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setInitialMinDate(minDate.toISOString().split('T')[0]);
                    }

                    setMaxDate(maxDate.toISOString().split('T')[0]);

                    setInitialMaxDate(maxDate.toISOString().split('T')[0]);
                } else if (timeInterval === 'quarter') {
                    // Check if initialMinDate is less than 20 quarters before maxDate
                    const twentyQuartersBeforeMax = new Date(maxDate);
                    twentyQuartersBeforeMax.setMonth(maxDate.getMonth() - 20);

                    // Check if minDate is less than 2 quarters before maxDate
                    const twoQuartersBeforeMax = new Date(maxDate);
                    twoQuartersBeforeMax.setMonth(maxDate.getMonth() - 2);

                    if (minDate < twoQuartersBeforeMax) {
                        setMinDate(
                            twoQuartersBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setMinDate(minDate.toISOString().split('T')[0]);
                    }

                    if (minDate < twentyQuartersBeforeMax) {
                        setInitialMinDate(
                            twentyQuartersBeforeMax.toISOString().split('T')[0]
                        );
                    } else {
                        setInitialMinDate(minDate.toISOString().split('T')[0]);
                    }

                    setMaxDate(maxDate.toISOString().split('T')[0]);

                    setInitialMaxDate(maxDate.toISOString().split('T')[0]);
                }
            }
        }, [data, initialMaxDate, initialMinDate, timeInterval]);

        // Pass the state and the function to change it to the WrappedComponent

        const shouldContainBrush = useMemo(() => {
            return minDate && maxDate && data && data.length > 1;
        }, [minDate, maxDate, data]);

        return (
            <>
                {filteredData.length === 0 && (
                    <div className="row mx-0 w-100 h-100">
                        <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                            <span className="noDataText">
                                {t('common:noData')}
                            </span>
                        </div>
                    </div>
                )}
                {filteredData.length > 0 && (
                    <>
                        <div
                            className="row mx-0 w-100"
                            style={{
                                height: shouldContainBrush
                                    ? 'calc(100% - 2rem)'
                                    : '100%'
                            }}
                        >
                            <div className="col px-0 h-100">
                                <WrappedComponent
                                    {...rest}
                                    data={filteredData}
                                />
                            </div>
                        </div>
                        {shouldContainBrush && (
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: '2rem',
                                    padding: '0 2rem'
                                }}
                            >
                                <div className="col px-0">
                                    <CustomBrush
                                        timeInterval={timeInterval || 'month'}
                                        initialMinDate={initialMinDate}
                                        initialMaxDate={initialMaxDate}
                                        currentMaxDate={maxDate}
                                        currentMinDate={minDate}
                                        onSetCurrentMaxDate={setMaxDate}
                                        onSetCurrentMinDate={setMinDate}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };
}
