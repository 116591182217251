import Joi from 'joi';
import _ from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import icoDelete from '../../../assets/images/ico-delete.svg';
import icoEdit from '../../../assets/images/ico-edit.svg';
import { teamMemberListMock } from './mock';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useTable } from '../../../hooks/useTable';
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput';
import { useTextInput } from '../../../hooks/useTextInput';
import { ProfileService, RolesService } from '../../../temp/test';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import {
    ContentContainer,
    DeleteButton,
    Loader,
    Modal,
    PrimaryButton,
    SecondaryButton,
    SelectMultipleInput,
    Spinner,
    Table,
    TypeInput
} from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { TableImprovement } from '../../tables/table-improvement/table-improvement';

/**
 * Lists all the users in a table
 */

export const TeamMemberList = React.memo(({ members }) => {
    const {
        value: data,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll
    } = useTable(
        {
            titleCounter: '0',
            sortedData: members || [],
            data: members || [],
            columns: [
                { path: 'teamMemberName', label: '', sortable: true },
                { path: 'teamMemberRevenue', label: '', sortable: true },
                { path: 'teamMemberCustomers', label: '', sortable: true }
            ],
            sortColumn: { path: 'teamMemberRevenue', order: 'asc' },
            currentPage: 1,
            pageSize: 10,
            type: 'type-1',
            attr: 'userTable',
            disabled: true,
            loading: true,
            settings: {
                open: false,
                selected: {
                    label: '',
                    total: 0,
                    options: []
                },
                view: {
                    options: []
                },
                options: {
                    label: '',
                    options: []
                },
                reference: React.createRef()
            }
        },
        undefined,
        ''
    );

    return (
        <TableImprovement
            {...data}
            noPadding
            loading={false}
            onPageChange={dataPageChange}
        ></TableImprovement>
    );
});

TeamMemberList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func
};
