import React, { useCallback, useMemo } from 'react'
import styles from './segment-hand-picked.module.scss'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { BreadCrumbs } from '../../../../../components/formatting/breadcrumbs/BreadCrumbs'
import { Cassete } from '../../../../../components/formatting/cassete/cassete'
import { useTextInputv2 } from '../../../../../hooks/useTextInputv2'
import { TypeInput } from '../../../../../components'
import icoBack from '../../../../../assets/images/icoArrowBackAction.svg'
import icoSave from '../../../../../assets/images/icoSaveCurrentDashboard.svg'
import { SegmentPreview } from '../../components/segment-preview/segment-preview'
import { SegmentSelectedCustomers } from '../../../../../components/dashboard-components/segment-selected-customers/segment-selected-customers'
import { SegmentCustomersToSelect } from '../../../../../components/dashboard-components/segment-customers-to-select/segment-customers-to-select'
import { useArray } from '../../../../../hooks/useArray'
import { DndContext } from '@dnd-kit/core'
import { Droppable } from '../../../../../components/dnd/Droppable/Droppable'
import { SelectionProvider } from '../../../../../providers/selection-context'
import { forEach } from 'lodash'
import { DeleteProvider } from '../../../../../providers/delete-context'
import { ActionBar } from '../../../../../components/actions/action-bar/action-bar'

export const SegmentHandPicked = () => {
    const { t } = useCustomTranslation()
    const { value: name, actions: nameActions } = useTextInputv2('title')
    const { value: description, actions: descriptionActions } = useTextInputv2('description')
    const [dragInfo, setDragInfo] = React.useState('')
    const {
        array: customersToSelect,
        push: customersToSelectPush,
        removeMultiple: customersToSelectRemove,
    } = useArray([
        {
            id: 1,
            name: 'John Doe',
            age: 25,
            gender: 'Male',
            education: 'High School',
            maritalStatus: 'Single',
            country: 'North America',
        },
        {
            id: 2,
            name: 'Jane Doe',
            age: 30,
            gender: 'Male',
            education: 'High School',
            maritalStatus: 'Single',
            country: 'North America',
        },
    ])
    const { array: customersToSelectChecked, setArray: setCustomersToSelectChecked } = useArray([])
    const { array: selectedCustomersChecked, setArray: setSelectedCustomersChecked } = useArray([])
    const { array: selectedCustomers, push: selectedCustomersPush, removeMultiple: selectedCustomersRemove } = useArray([])

    const crumbsMemo = useMemo(() => {
        return [
            {
                url: '/segment-analysis/overview',
                label: t('breadcrumbs:segmentAnalysis.overview'),
            },
            {
                url: '/segment-analysis/segments/new-segment',
                label: t('breadcrumbs:segmentAnalysis.newSegment'),
            },
            {
                url: '/segment-analysis/segments/new-segment/hand-picked',
                label: t('breadcrumbs:segmentAnalysis.handPicked'),
                isTitle: true,
            },
        ]
    }, [t])

    function handleDragStart({ active }) {
        const attr = active.id.split('-')[0]
        setDragInfo(attr)
    }

    function handleDragEnd({ over, active }) {
        const attr = active.id.split('-')[0]
        const indexes = active.id.split('-').slice(1)
        if (active && attr === 'segmentCustomersToSelect' && over) {
            forEach(indexes, (index) => {
                const element = customersToSelect[index]
                selectedCustomersPush(element)
            })
            customersToSelectRemove(indexes)
            setSelectedCustomersChecked([])
            setCustomersToSelectChecked([])
        } else if (active && attr === 'segmentSelectedCustomers' && over) {
            forEach(indexes, (index) => {
                const element = selectedCustomers[index]
                customersToSelectPush(element)
            })
            selectedCustomersRemove(indexes)
            setSelectedCustomersChecked([])
            setCustomersToSelectChecked([])
        }
        setDragInfo('')
    }

    const handleDeleteFromSelectedCustomers = useCallback(
        (element) => {
            if (element) {
                const index = selectedCustomers.findIndex((el) => el.id === element.id)
                selectedCustomersRemove([index])
                customersToSelectPush(element)
            } else {
                forEach(selectedCustomersChecked, (index) => {
                    const element = selectedCustomers[index]
                    customersToSelectPush(element)
                })
                selectedCustomersRemove(selectedCustomersChecked)
                setSelectedCustomersChecked([])
            }
        },
        [selectedCustomers, selectedCustomersChecked, selectedCustomersRemove, customersToSelectPush, setSelectedCustomersChecked]
    )

    return (
        <div className={`row mx-0 w-100 ${styles['segment-hand-picked-container']}`}>
            <div className="col px-0 d-flex flex-column">
                <BreadCrumbs crumbs={crumbsMemo} />
                <div className="row w-100 mx-0">
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-title">{t(`pages:segmentHandPicked.title`)}</span>
                    </div>
                </div>
                <div className="row w-100 mx-0" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-description">{t(`pages:segmentHandPicked.description`)}</span>
                    </div>
                    <div className="col-auto px-0">
                        <ActionBar
                            actions={[
                                {
                                    text: t('buttons:back'),
                                    icon: icoBack,
                                    onClick: () => {
                                        window.history.back()
                                    },
                                },
                                {
                                    text: t('buttons:save'),
                                    icon: icoSave,
                                    onClick: () => {
                                        console.log('Save')
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mx-0 w-100">
                    <div className="col px-0" style={{ minWidth: 0 }}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <Cassete title={t('common:basicInputs')} startOpen>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            maxWidth: '40rem',
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <div className="col px-0">
                                            <TypeInput {...name} {...nameActions} newLabelType />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ height: '15rem', marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <TypeInput {...description} {...descriptionActions} newLabelType inputType={'container'} />
                                        </div>
                                    </div>
                                </Cassete>
                            </div>
                        </div>

                        <DndContext
                            onDragEnd={
                                handleDragEnd
                                // (data) => {
                                // const element = customersToSelect[data.active.id.split('-')[1]]
                                // selectedCustomersPush(element)
                                // customersToSelectRemove(data.active.id.split('-')[1])
                            }
                            onDragStart={handleDragStart}
                        >
                            <div className="row mx-0 w-100" style={{ height: '45rem', marginBottom: '2rem' }}>
                                <div className="col px-0 position-relative">
                                    {dragInfo && dragInfo === 'segmentSelectedCustomers' && (
                                        <div className={styles['segment-hand-picked-container__dropzone']}>
                                            <Droppable id={'segmentSelectedCustomers'}>
                                                <span className={styles['segment-hand-picked-container__dropzone__title']}>
                                                    Drop customers here to remove them from the segment
                                                </span>
                                            </Droppable>
                                        </div>
                                    )}

                                    <SelectionProvider
                                        value={{
                                            selected: selectedCustomersChecked,
                                            setSelected: setSelectedCustomersChecked,
                                        }}
                                    >
                                        <SegmentCustomersToSelect data={customersToSelect} isDraggable withSearch />
                                    </SelectionProvider>
                                </div>
                            </div>
                            <div className="row mx-0 w-100" style={{ height: '45rem' }}>
                                <div className="col px-0 position-relative">
                                    {dragInfo && dragInfo === 'segmentCustomersToSelect' && (
                                        <div className={styles['segment-hand-picked-container__dropzone']}>
                                            <Droppable id={'segmentCustomersToSelect'}>
                                                <span className={styles['segment-hand-picked-container__dropzone__title']}>
                                                    Drop selected customers here to add them to the segment
                                                </span>
                                            </Droppable>
                                        </div>
                                    )}

                                    <SelectionProvider
                                        value={{
                                            selected: customersToSelectChecked,
                                            setSelected: setCustomersToSelectChecked,
                                        }}
                                    >
                                        <DeleteProvider value={{ handleDelete: handleDeleteFromSelectedCustomers }}>
                                            <SegmentSelectedCustomers data={selectedCustomers} isDraggable withSearch />
                                        </DeleteProvider>
                                    </SelectionProvider>
                                </div>
                            </div>
                        </DndContext>
                    </div>
                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                    <div className="col-3 px-0">
                        <SegmentPreview isCollapsed />
                    </div>
                </div>
            </div>
        </div>
    )
}
