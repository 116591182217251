import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { PointOfSaleService } from '../../../temp/test'
import { riskDistributionMock } from './mock'
import { errorHandler } from '../../../utils/api.js'
import styles from './risk-distribution.module.scss'
import { verifyComponent } from '../../../utils/component.js'
import { useChartReady } from '../../../providers/chart-ready-context'
import { ContentContainer, DynamicBarChartContainer, Loader, Spinner } from '../../'
import { getFilterParam } from '../../../utils/params'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

/**
 * Displays how many clients are at each risk, allowing for comparison of distributions
 */

export const RiskDistribution = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
    }) => {
        const { filter } = useFilter()
        const { charts } = useChartReady()
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()
        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(riskDistributionMock)

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(riskDistributionMock)
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true)
                        }
                        if (type) dataClone.attr = type
                        const dataBack = await PointOfSaleService.getApiPos({
                            clientId: getFilterParam(filter, config, ['clientId', 'clientIds']),
                            mapInfo: 'true',
                            page: undefined,
                            vendorId: getFilterParam(filter, config, ['vendorId', 'vendorIds']),
                        })
                        let risk1Count = 0
                        let risk2Count = 0
                        let risk3Count = 0
                        let risk4Count = 0
                        dataBack.map((el) => {
                            if (el.risk === 1) {
                                risk1Count += 1
                            } else if (el.risk === 2) {
                                risk2Count += 1
                            } else if (el.risk === 3) {
                                risk3Count += 1
                            } else if (el.risk === 4) {
                                risk4Count += 1
                            }
                            return el
                        })
                        dataClone.data = [
                            {
                                1: risk1Count,
                                2: risk2Count,
                                3: risk3Count,
                                4: risk4Count,
                                categoryAttr: 'riskCategory',
                            },
                        ]
                        dataClone.tooltipType = 'clientRisk'
                        dataClone.loading = false
                        dataClone.ready = true

                        if (dataClone.settings.view.options.length === 0 && dataClone.settings.options.options.length === 0) dataClone.disabled = true
                        else dataClone.disabled = false
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                    } else {
                        if (type) dataClone.attr = type
                        verifyComponent(dataClone)
                        setValue(dataClone)
                        setLoading(false)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            }
            fill()
        }, [config, filter, isExample, isMounted, setLoading, setValue, t, type])

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings()
                if (option === 'pin') {
                    onPinComponent(data.attr)
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr)
                } else if (option === 'download') {
                    onDownloadComponent()
                }
            },
            [data.attr, isMounted, onDownloadComponent, onPinComponent, onUnpinComponent, toggleComponentSettings]
        )

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{
                    zIndex: 'inherit',
                }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div className={`${styles['risk-distribution-container']}`}>
                            {data.data[0]['1'] === 0 && data.data[0]['2'] === 0 && data.data[0]['3'] === 0 && data.data[0]['4'] === 0 && (
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <span className="noDataText">{t('common:noData')}</span>
                                    </div>
                                </div>
                            )}
                            {!(data.data[0]['1'] === 0 && data.data[0]['2'] === 0 && data.data[0]['3'] === 0 && data.data[0]['4'] === 0) && (
                                <div className="row mx-0 w-100 h-100">
                                    <div className={`col px-0 h-100 ${styles['risk-distribution-container__stacked-bars']}`}>
                                        <DynamicBarChartContainer
                                            translate={t}
                                            barStackedAttr={data.barStackedAttr}
                                            categoryAxis={data.categoryAxis}
                                            margin={data.margin}
                                            quantitativeLabel={data.quantitativeLabel}
                                            hideXValues={data.hideXValues}
                                            hideXTicks={data.hideXTicks}
                                            hideYTicks={data.hideYTicks}
                                            hideCursor={data.hideCursor}
                                            barWidth={data.barWidth}
                                            categoryLabel={data.categoryLabel}
                                            data={data.data}
                                            orientation={'horizontal'}
                                            tooltipType={data.tooltipType}
                                            type={'stacked'}
                                            interactionDisabled={data.interactionDisabled}
                                            labelDisabled={data.labelDisabled}
                                            unitsOrValues={data.unitsOrValues}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </ContentContainer>
            </div>
        )
    }
)

RiskDistribution.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
