import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { SegmentationService } from '../../../temp/test';
import { segmentRulesMock } from './mock';
import styles from './segment-rules.module.scss';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

export const SegmentRules = React.memo(({ segmentId }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [rules, setRules] = useState([]);
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(segmentRulesMock);

    React.useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(segmentRulesMock);
            try {
                const results =
                    await SegmentationService.getApiSegmentationRules({
                        id: segmentId
                    });
                setRules(results);
                if (
                    dataClone.settings.view.options.length === 0 &&
                    dataClone.settings.options.options.length === 0
                )
                    dataClone.disabled = true;
                else dataClone.disabled = false;
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [segmentId, setValue, isMounted, setLoading]);

    const handleSelectSettingsOption = useCallback(() => {
        if (isMounted.current) toggleComponentSettings();
    }, [isMounted, toggleComponentSettings]);

    return (
        <ContentContainer
            attr={data.attr}
            titleText={t(`components:${data.attr}.title`)}
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
            onClickSettingsView={handleSelectSettingsOption}
            onCLickSettintsSelected={handleSelectSettingsOption}
            onCloseSettings={toggleComponentSettings}
            // // hovered={hovered}
            // onMouseEnterInfoIcon={onMouseEnterInfoIcon}
            // onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
            // hideInfoIcon={true}
            // hidePinIcon={isExample || isPreview || isReportExample}
            // hideSettingsIcon={isExample || isPreview || isReportExample}
            // pinned={pinned}
            // onPinComponent={onPinComponent}
            // onUnpinComponent={onUnpinComponent}
            // interval={'1M'}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <div
                    className={`row mx-0 w-100 ${styles['segment-rules-container']}`}
                >
                    <div className="col px-0">
                        {rules.map((el, index) => {
                            return (
                                <div key={index} className="row mx-0 w-100">
                                    <div className="col-auto px-0">
                                        <div
                                            className={`${styles['segment-rules-container__rule-container']}`}
                                        >
                                            <span
                                                className={`${styles['segment-rules-container__rule-container__text']}`}
                                            >
                                                {el.param}
                                            </span>{' '}
                                            <span
                                                className={`${styles['segment-rules-container__rule-container__text']}`}
                                            >
                                                {el.operator}
                                            </span>{' '}
                                            <span
                                                className={`${styles['segment-rules-container__rule-container__text']}`}
                                            >
                                                {el.value}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </ContentContainer>
    );
});
