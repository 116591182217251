import React from 'react';

import styles from './sentiment-results.module.scss';
import { SentimentGeneralDetails } from '../../sentiment/sentiment-general-details';
import { SentimentWordFrequency } from '../../sentiment/sentiment-word-frequency';
import { SentimentChannelOverview } from '../../sentiment/sentiment-channel-overview';
import { SentimentTopicDetails } from '../../sentiment/sentiment-topic-details';
import { SentimentKeywordAnalysis } from '../../sentiment/sentiment-keyword-analysis';
import { SentimentChannelAnalysis } from '../../sentiment/sentiment-channel-analysis';
//import { SentimentProportions } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-proportions/sentiment-proportions';
//import { SentimentOverTime } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-over-time/sentiment-over-time';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SentimentResults = React.memo(({ analysisId }) => {
    return (
        <div
            className={`row mx-0 w-100 ${styles['sentiment-results-container']}`}
        >
            <div className="col px-0 h-100 d-flex flex-column align-items-center">
                <div
                    className={`row w-100 ${styles['sentiment-results-container__content-row']}`}
                    style={{ marginBottom: '2rem', height: '35rem' }}
                >
                    <div className="col-12 px-0 h-100">
                        <SentimentGeneralDetails analysisId={analysisId} />
                    </div>
                </div>
                <div
                    className={`row w-100 ${styles['sentiment-results-container__analysis-row']}`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col-6 px-0 pe-2 h-100">
                        <SentimentChannelOverview analysisId={analysisId} />
                    </div>
                    <div className="col-6 px-0 ps-2 h-100">
                        <SentimentWordFrequency analysisId={analysisId} />
                    </div>
                </div>
                {/* <div
                    className={`row w-100 ${styles['sentiment-results-container__analysis-row']}`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className={`col-12 px-0`}>
                        <SentimentTopicDetails analysisId={analysisId} />
                    </div>
                </div> */}
                <div
                    className={`row w-100 ${styles['sentiment-results-container__analysis-row']}`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className={`col-12 px-0 d-flex flex-column h-100`}>
                        <SentimentKeywordAnalysis analysisId={analysisId} />
                    </div>
                </div>
                <div
                    className={`row w-100 ${styles['sentiment-results-container__analysis-row']}`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className={`col-12 px-0 d-flex flex-column`}>
                        <SentimentChannelAnalysis analysisId={analysisId} />
                    </div>
                </div>
            </div>
        </div>
    );
});

SentimentResults.propTypes = {};
