import { createContext, useContext, useMemo } from 'react';

const ThemeContext = createContext({
    theme: 'light',
    setTheme: () => {}
});

export function ThemeProvider({ value, children }) {
    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    );
}

export function useTheme() {
    return useContext(ThemeContext);
}
