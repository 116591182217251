import React from 'react'
import { getDots } from '../../../../../utils/string'
import styles from './sentiment-channel-table-column.module.scss'

export const SentimentChannelTableColumn = React.memo(({ el, column }) => {
    return (
        <div
            className={`${styles['sentiment-channel-table-column-container']}`}
        >
            <span
                className={`${styles['sentiment-channel-table-column-container__content-icon']}`}
            >
                <img loading="lazy" src={el[column.path].icon} alt={''} />
            </span>
            <span
                className={`${styles['sentiment-channel-table-column-container__content']}`}
            >
                {el[column.path].label.toString().substring(0, 100) +
                    getDots(el[column.path].label.toString())}
            </span>
        </div>
    )
})
