import React from 'react';
//import { ReactComponent as IcoError } from './assets/images/ico-error.svg'
import { ReactComponent as IcoClose } from './assets/images/ico-close.svg';
import PropTypes from 'prop-types';
import styles from './tag-multiple-input.module.scss';
import { useTranslation } from 'react-i18next';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const TagMultipleInput = React.memo(
    ({
        onToggleDropdown,
        onSelectedOptionClick,
        reference,
        disabled,
        name,
        selectedOptions,
        selectedOptionsAux,
        hideOptionsAux,
        label,
        state,
        value,
        onChangeInput,
        onKeyPress,
        onKeyDown,
        openDropdown,
        error,
        hovered,
        hideLabel
    }) => {
        const { t } = useCustomTranslation();
        const handleSelectedOptionClick = (e, name, option, optionAux) => {
            e.stopPropagation();
            onSelectedOptionClick(option, optionAux);
        };

        let containerStyle;
        const hoveredStyle = hovered ? 'hovered' : '';
        if (openDropdown && disabled) {
            containerStyle = `${styles['tag-multiple-container']} ${styles['selected']} ${styles['disabled']}`;
        } else if (
            (selectedOptions.length !== 0 ||
                selectedOptionsAux.length !== 0 ||
                value !== '') &&
            disabled
        ) {
            containerStyle = `${styles['tag-multiple-container']} ${styles['valued']} ${styles['disabled']}`;
        } else if (disabled) {
            containerStyle = `${styles['tag-multiple-container']} ${styles['disabled']}`;
        } else if (openDropdown) {
            containerStyle = `${styles['tag-multiple-container']} ${styles['selected']} ${hoveredStyle}`;
        } else if (
            selectedOptions.length !== 0 ||
            selectedOptionsAux.length !== 0 ||
            value !== ''
        ) {
            containerStyle = `${styles['tag-multiple-container']} ${styles['valued']} ${hoveredStyle}`;
        } else {
            containerStyle = `${styles['tag-multiple-container']} ${hoveredStyle}`;
        }
        return (
            <React.Fragment>
                <div
                    className={containerStyle}
                    ref={reference}
                    onFocus={
                        !disabled
                            ? (e) => {
                                  e.stopPropagation();
                                  onToggleDropdown(true);
                              }
                            : undefined
                    }
                    onBlur={
                        !disabled
                            ? (e) => {
                                  e.stopPropagation();
                                  onToggleDropdown(false);
                              }
                            : undefined
                    }
                >
                    <div
                        className={`${styles['tag-multiple-container__input-container']} ${state}`}
                    >
                        <div
                            className={`${styles['tag-multiple-container__values']}`}
                        >
                            <div
                                className={`${styles['tag-multiple-container__values__container']}`}
                            >
                                {selectedOptions.map((selOption, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${styles['tag-multiple-container__values__container__value']}`}
                                            onClick={
                                                !disabled
                                                    ? (e) =>
                                                          handleSelectedOptionClick(
                                                              e,
                                                              name,
                                                              selectedOptions[
                                                                  index
                                                              ],
                                                              selectedOptionsAux[
                                                                  index
                                                              ]
                                                          )
                                                    : undefined
                                            }
                                        >
                                            <span
                                                className={`${styles['tag-multiple-container__values__container__value__main']}`}
                                            >
                                                {selectedOptions[index]}
                                            </span>
                                            {!hideOptionsAux &&
                                                selectedOptionsAux[index] !==
                                                    '' && (
                                                    <span
                                                        className={`${styles['tag-multiple-container__values__container__value__secondary']}`}
                                                    >
                                                        {
                                                            selectedOptionsAux[
                                                                index
                                                            ]
                                                        }
                                                    </span>
                                                )}
                                            <IcoClose />
                                        </div>
                                    );
                                })}
                                <div
                                    className={`${styles['tag-multiple-container__input-container-container']}`}
                                >
                                    <textarea
                                        autoComplete="false"
                                        name={name}
                                        value={value}
                                        size=""
                                        style={{ resize: 'none' }}
                                        placeholder=""
                                        type="text"
                                        className={
                                            value
                                                ? `${styles['tag-multiple-container__input-container__input']}`
                                                : `${styles['tag-multiple-container__input-container__input']} ${styles['placeholder']}`
                                        }
                                        onChange={onChangeInput}
                                        onKeyDown={(e) => {
                                            if (e && e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                            if (onKeyPress) onKeyPress(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {state !== 'error' && !hideLabel && (
                            <label
                                htmlFor={name}
                                className={`${styles['tag-multiple-container__label']}`}
                            >
                                {label || t(`inputs:${name}`)}
                            </label>
                        )}
                        {state === 'error' && !hideLabel && (
                            <label
                                htmlFor={name}
                                className={`${styles['tag-multiple-container__label--error']}`}
                            >
                                {label || t(`inputs:${name}`)}
                            </label>
                        )}
                    </div>

                    {/* {state === 'error' && (
            <IcoError className='tag-multiple-container__error-icon' />
          )} */}
                </div>
                {error && (
                    <div
                        className={`${styles['tag-multiple-container-error']}`}
                    >
                        {error}
                    </div>
                )}
            </React.Fragment>
        );
    }
);

TagMultipleInput.propTypes = {};
