import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import icoPage1 from '../../../assets/images/ico-page-1.svg'
import icoPage2 from '../../../assets/images/ico-page-2.svg'
import icoPage3 from '../../../assets/images/ico-page-3.svg'
import icoPage4 from '../../../assets/images/ico-page-4.svg'
import icoPage5 from '../../../assets/images/ico-page-5.svg'
import icoPage6 from '../../../assets/images/ico-page-6.svg'
import icoPage7 from '../../../assets/images/ico-page-7.svg'
import icoPage8 from '../../../assets/images/ico-page-8.svg'
import icoPage9 from '../../../assets/images/ico-page-9.svg'
import icoPage11 from '../../../assets/images/ico-page-11.svg'
import icoPage12 from '../../../assets/images/ico-page-12.svg'
import icoPage13 from '../../../assets/images/ico-page-13.svg'
import icoPage14 from '../../../assets/images/ico-page-14.svg'
import icoPage15 from '../../../assets/images/ico-page-15.svg'
import icoPage16 from '../../../assets/images/ico-page-16.svg'
import icoPage17 from '../../../assets/images/ico-page-17.svg'
import icoPage18 from '../../../assets/images/ico-page-18.svg'
import icoPage19 from '../../../assets/images/ico-page-19.svg'
import icoPage20 from '../../../assets/images/ico-page-20.svg'
import icoPage21 from '../../../assets/images/ico-page-21.svg'
import icoPage22 from '../../../assets/images/ico-page-22.svg'
import icoPage23 from '../../../assets/images/ico-page-23.svg'
import icoPage24 from '../../../assets/images/ico-page-24.svg'
import icoChooseTemplate from '../../../assets/images/icoChooseTemplate.svg'
import icoCreateNewDashboard from '../../../assets/images/icoCreateNewDashboard.svg'
import customDashboardContainer from '../../../mock/dashboards/custom-dashboard-layout.json'
import createDashboardContainer from '../../../mock/dashboards/create-dashboard-layout.json'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import CustomDashboard from '../custom-dashboard/custom-dashboard'
import styles from './create-dashboard.module.scss'
import { useToggle } from '../../../hooks/useToggle'
import { useTextInput } from '../../../hooks/useTextInput'
import { useSelectIconInput } from '../../../hooks/useSelectIconInput'
import Joi, { custom } from 'joi'
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useRatioInput } from '../../../hooks/useRatioInput'
import { useAuxBar } from '../../../providers/aux-bar-context'
import { useModalPopup } from '../../../providers/modal-popup-context'
import { iconMap } from '../../../constants/iconMap'
import { maxComponentCount } from '../../../constants/component'
import { useSelectInput } from '../../../hooks/useSelectInput'
import { errorHandler } from '../../../utils/api'
import { PagesService, RolesService } from '../../../temp/test'
import { useHighlightedChart } from '../../../providers/highlighted-chart-context'
import componentConfig from '../../../configs/componentLibraryConf.json'
import icoArrowBackAction from '../../../assets/images/icoArrowBackAction.svg'
import {
    CardCarousel,
    DashboardPreviewCard,
    EditOptions,
    LibraryComponentFactory,
    LoaderForeground,
    Modal,
    MyResizeHandle,
    PrimaryButton,
    RadioInput,
    SecondaryButton,
    SelectInput,
    SelectMultipleInput,
    TerciaryButton,
    TypeInput,
} from '../../../components'
import { error } from '../../../components/notifications/notification/notification'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png'
import { ActionBar } from '../../../components/actions/action-bar/action-bar'
import icoEditCurrentDashboard from '../../../assets/images/icoEditCurrentDashboard.svg'
import icoSaveCurrentDashboard from '../../../assets/images/icoSaveCurrentDashboard.svg'
import icoPublishCurrentDashboard from '../../../assets/images/icoPublishCurrentDashboard.svg'
import icoPreviewCurrentDashboard from '../../../assets/images/icoPreviewCurrentDashboard.svg'
import { PreviewModeProvider } from '../../../components/dashboard-creation/library-component-factory/preview-mode-provider'
import AnimateHeight from 'react-animate-height'
import LoadingBar from '../../../components/loading/LoadingBar/LoadingBar'
import dynamicComponents from '../../../configs/dynamicInfoComponents.json'

const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }

const ResponsiveGridLayout = WidthProvider(Responsive)

/**
 * Provides a page layout that allows users to create new dashboards
 */

const CreateDashboard = React.memo(
    ({
        dropdownList,
        notAvailableCharts,
        setNotAvailableCharts,
        availableCharts,
        resizableGrid,
        onUpdateRoute,
        onPublishRoute,
        selectedOption,
        onPageLayoutChange,
        onPageDropChart,
        onOpenChartConfig,
        onDeletePageChart,
        onSetLayout,
        onFillChartLayout,
        setAvailableCharts,
        setFilterInputs,
        setDropdownList,
        onAddRoute,
    }) => {
        const [currentTemplateName, setCurrentTemplateName] = useState('')
        const [savedDashboardName, setSavedDashboardName] = useState('')
        const gridRef = useRef()
        const [dashboardGenerationState, setDashboardGenerationState] = useState(undefined)
        const [dashboardCreationOptions, setDashboardCreationOptions] = useState([
            {
                label: 'createDashboard',
                image: icoCreateNewDashboard,
                to: 'type',
                strokeType: 'dashed',
            },
            {
                label: 'chooseTemplate',
                image: icoChooseTemplate,
                to: 'template',
                strokeType: 'solid',
            },
        ])
        const [selectedCreationOption, setSelectedCreationOption] = useState('')
        const [transformScaleCustom, setTransformScaleCustom] = useState(1)
        const { steps, selectedTutorialStep, currentStep, setCurrentStep } = useModalPopup()
        const { t } = useCustomTranslation()
        const { highlightedChart, setHighlightedChart } = useHighlightedChart()
        const isMounted = useIsMounted()
        const { setAvailableOptions, setIsBuilder } = useAuxBar()
        const [loading, toggleLoading] = useToggle(true)
        const [createPageState, setCreatePageState] = useState('creationOptions')
        const [lastState, setLastState] = useState('')
        const [modal, toggleModal] = useToggle(false)
        const [modalType, setModalType] = useState('')
        const { setSelectedAvailableOption } = useAuxBar()
        const [previewName, setPreviewName] = useState('')
        const [templateOption, setTemplateOption] = useState('')
        const {
            value: dashboardName,
            reset: resetDashboardName,
            setValue: setDashboardNameValue,
            setError: setDashboardNameError,
        } = useTextInput({
            name: 'dashboardName',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        })
        const {
            value: dashboardAIDescription,
            reset: resetDashboardAIDescription,
            setValue: setDashboardAIDescriptionValue,
            replaceValue: replaceDashboardAIDescription,
            setError: setDashboardAIDescriptionError,
        } = useTextInput({
            name: 'dashboardAIDescription',
            value: '',
            error: '',
            placeholder: t('inputs:dashboardAIDescription'),
            hidden: false,
            state: 'normal',
        })
        const {
            value: icons,
            toggleDropdown: iconsToggleDropdown,
            selectOption: iconsSelectOption,
            keyPress: iconsKeyPress,
            keyDown: iconsKeyDown,
            setValue: iconsSetValue,
        } = useSelectIconInput({
            name: 'icons',
            showOptions: true,
            options: [],
            optionsAux: [],
            iconList: [
                { name: 'icoPage1', icon: icoPage1 },
                { name: 'icoPage2', icon: icoPage2 },
                { name: 'icoPage3', icon: icoPage3 },
                { name: 'icoPage4', icon: icoPage4 },
                { name: 'icoPage5', icon: icoPage5 },
                { name: 'icoPage6', icon: icoPage6 },
                { name: 'icoPage7', icon: icoPage7 },
                { name: 'icoPage8', icon: icoPage8 },
                { name: 'icoPage9', icon: icoPage9 },
                { name: 'icoPage11', icon: icoPage11 },
                { name: 'icoPage12', icon: icoPage12 },
                { name: 'icoPage13', icon: icoPage13 },
                { name: 'icoPage14', icon: icoPage14 },
                { name: 'icoPage15', icon: icoPage15 },
                { name: 'icoPage16', icon: icoPage16 },
                { name: 'icoPage17', icon: icoPage17 },
                { name: 'icoPage18', icon: icoPage18 },
                { name: 'icoPage19', icon: icoPage19 },
                { name: 'icoPage20', icon: icoPage20 },
                { name: 'icoPage21', icon: icoPage21 },
                { name: 'icoPage22', icon: icoPage22 },
                { name: 'icoPage23', icon: icoPage23 },
                { name: 'icoPage24', icon: icoPage24 },
            ],
            selectedIcon: 'icoPage1',
            state: 'normal',
            value: '',
            loading: false,
            language: 'pt',
            focused: -1,
            reference: React.createRef(),
        })

        const [currentWidthSectionMultiplier, setCurrentWidthSectionMultiplier] = useState(1)

        const mapTo01 = useCallback(
            (value, min, max) => {
                let multiplier =
                    resizableGrid && resizableGrid.droppingElement && resizableGrid.droppingElement.w
                        ? 0.2 * resizableGrid.droppingElement.w + 0.15
                        : 0.3
                return (multiplier * (value - min)) / (max - min)
            },
            [resizableGrid]
        )

        const newSize = useCallback((width) => {
            if (width < 600) {
                setCurrentWidthSectionMultiplier(0.6)
            } else if (width < 800) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1200) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1400) {
                setCurrentWidthSectionMultiplier(0.8)
            } else {
                setCurrentWidthSectionMultiplier(1)
            }
            return
        }, [])

        useEffect(() => {
            // Create a ResizeObserver instance
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    // Access the new width and height
                    const { width } = entry.contentRect
                    newSize(width)
                }
            })

            resizeObserver.observe(document.documentElement)
            return () => {
                resizeObserver.disconnect()
            }
        }, [newSize])

        const handleCheckMousePositionInsideGrid = useCallback(
            (e) => {
                if (gridRef && gridRef.current) {
                    const gridRect = gridRef.current.getBoundingClientRect()
                    if (
                        e.clientX > gridRect.left &&
                        e.clientX < gridRect.right &&
                        e.clientY > gridRect.top &&
                        e.clientY < gridRect.bottom &&
                        mapTo01(e.clientX, gridRect.right, gridRect.left)
                    ) {
                        setTransformScaleCustom(1 + mapTo01(e.clientX, gridRect.right, gridRect.left))
                    }
                }
            },
            [mapTo01]
        )

        useEffect(() => {
            if (gridRef && gridRef.current) {
                let ref = gridRef.current
                ref.addEventListener('dragover', (e) => {
                    handleCheckMousePositionInsideGrid(e)
                })
                return () => {
                    ref.removeEventListener('dragover', (e) => {
                        handleCheckMousePositionInsideGrid(e)
                    })
                }
            }
        }, [handleCheckMousePositionInsideGrid])

        const { value: includeFolder, clickRatioOption: includeFolderClickRadioOption } = useRatioInput({
            name: 'includeFolder',
            options: [
                {
                    name: 'yes',
                    label: t(`inputs:yes`),
                    disabled: false,
                    blocked: false,
                },
                {
                    name: 'no',
                    label: t(`inputs:no`),
                    disabled: false,
                    blocked: false,
                },
            ],
            selectedOption: '',
        })

        const { value: folderType, clickRatioOption: folderTypeClickRadioOption } = useRatioInput({
            name: 'folderType',
            options: [
                {
                    name: 'newFolder',
                    label: t(`inputs:newFolder`),
                    disabled: false,
                    blocked: false,
                },
                {
                    name: 'existingFolder',
                    label: t(`inputs:existingFolder`),
                    disabled: false,
                    blocked: false,
                },
            ],
        })
        const { value: folderName, setValue: folderNameSetValue } = useTextInput({
            name: 'folderName',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        })
        const {
            value: roles,
            toggleDropdown: rolesToggleDropdown,
            selectOption: rolesSelectOption,
            unselectOption: rolesUnselectOption,
            keyPress: rolesKeyPress,
            keyDown: rolesKeyDown,
            setValue: rolesSetValue,
            setError: rolesSetError,
            replaceAll: rolesReplaceAll,
        } = useSelectMultipleInput({
            name: 'roles',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: React.createRef(),
        })
        const {
            value: folder,
            toggleDropdown: folderToggleDropdown,
            selectOption: folderSelectOption,
            keyPress: folderKeyPress,
            keyDown: folderKeyDown,
            setValue: folderSetValue,
            replaceAll: folderReplaceAll,
        } = useSelectInput({
            name: 'folder',
            showOptions: true,
            options: [],
            optionsAux: [],
            state: 'normal',
            focused: -1,
            reference: useRef(),
        })

        const handleCreatePage = async (availableCharts, notAvailableCharts, isDraft) => {
            let dashboardTitle = savedDashboardName
            let dashboardIconName = icons.selectedIcon
            let isInsideFolder = includeFolder.selectedOption === 'yes'
            await onAddRoute(
                dashboardTitle,
                dashboardIconName,
                availableCharts,
                notAvailableCharts,
                isDraft,
                [...roles.selectedOptions].map((el) => {
                    return {
                        name: el,
                    }
                }),
                isInsideFolder,
                folderType.selectedOption === 'existingFolder',
                folder.selectedOptionAux,
                folderType.selectedOption === 'newFolder' ? folderName.value : folder.selectOption
            )
        }

        useEffect(() => {
            return () => {
                setIsBuilder(false)
            }
        }, [setIsBuilder])

        useEffect(() => {
            if (createPageState === 'components') {
                setIsBuilder(true)
            } else {
                setIsBuilder(false)
            }
        }, [createPageState, setIsBuilder])

        useEffect(() => {
            if (createPageState === 'creationOptions') {
                setNotAvailableCharts([])
            }
        }, [createPageState, setNotAvailableCharts])

        useEffect(() => {
            async function checkExistingFolders() {
                if (folderType.selectedOption === 'existingFolder') {
                    const getPageData = await PagesService.getApiPagesMyPages()
                    if (getPageData.pages && getPageData.pages.length > 0) {
                        const newFolderList = []
                        getPageData.pages.map((el) => {
                            if (_.has(el, 'folder') && el.folder) {
                                if (
                                    _.findIndex(newFolderList, (folder) => {
                                        return folder.id === el.folder.id
                                    }) === -1
                                ) {
                                    newFolderList.push(el.folder)
                                }
                            }
                            return el
                        })
                        if (isMounted.current) {
                            folderReplaceAll({
                                name: 'folder',
                                showOptions: true,
                                state: 'normal',
                                focused: -1,
                                reference: React.createRef(),
                                options: newFolderList.map((el) => {
                                    return el.name
                                }),
                                optionsAux: newFolderList.map((el) => {
                                    return el.id
                                }),
                            })
                        }
                    }
                }
            }
            checkExistingFolders()
        }, [folderReplaceAll, folderType.selectedOption, isMounted])

        const schema = Joi.object({
            dashboardName: Joi.string().required(),
            roles: Joi.array().items(Joi.string().required()),
        })

        const handleChooseTemplateFromPreview = () => {
            if (isMounted.current) {
                toggleModal(true)
                setModalType('create')
            }
        }

        useEffect(() => {
            if (createPageState === 'type') {
                setNotAvailableCharts([])
            }
        }, [createPageState, setNotAvailableCharts])

        const handleChooseTemplate = (name) => {
            if (isMounted.current) {
                toggleModal(true)
                setModalType('create')
                setCurrentTemplateName(name)
            }
        }

        const handleEditTemplate = () => {
            if (isMounted.current) {
                toggleModal(true)
                setModalType('create')
            }
        }

        const handlePreviewTemplate = (page, name) => {
            setCreatePageState('preview')
            setPreviewName(name)
            setTemplateOption(page)
            setCurrentTemplateName(page)
            if (isMounted.current) {
                setAvailableCharts(customDashboardContainer['availableCharts'])
                const charts = _.cloneDeep(
                    createDashboardContainer[page]['notAvailableCharts'].filter((el) => {
                        return Object.keys(componentConfig).includes(el.type) || Object.keys(dynamicComponents).includes(el.type)
                    })
                )
                onFillChartLayout(charts, true)
            }
        }

        const handleCreationNavigation = async (currentPage, page, direction, to) => {
            if (direction === 'front') {
                if (to === 'components') {
                    setFilterInputs([])
                    setAvailableCharts(Object.values(componentConfig))
                    const charts = _.cloneDeep(
                        createDashboardContainer[currentTemplateName]['notAvailableCharts'].filter((el) => {
                            return Object.keys(componentConfig).includes(el.type) || Object.keys(dynamicComponents).includes(el.type)
                        })
                    )
                    onFillChartLayout(charts, false)
                    const result = schema.validate(
                        {
                            dashboardName: dashboardName.value,
                            roles: roles.selectedOptions,
                        },
                        { abortEarly: false }
                    )
                    if (isMounted.current) {
                        setDashboardNameError()
                        rolesSetError()
                    }
                    if (result.error) {
                        result.error.details.map((el) => {
                            if (el.path[0] === 'dashboardName') {
                                if (isMounted.current) setDashboardNameError(el.type)
                            } else if (el.path[0] === 'roles') {
                                if (isMounted.current) rolesSetError(el.type)
                            }
                        })
                        return
                    }
                    if (isMounted.current) setAvailableOptions(['store'])
                    if (modalType === 'preview') {
                        let newDropdownList = []
                        notAvailableCharts.map((el) => {
                            if (el.config.filter) {
                                el.config.filter.map((filter) => {
                                    if (!newDropdownList.includes(filter)) {
                                        newDropdownList.push(filter)
                                    }
                                    return filter
                                })
                            }
                            return el
                        })
                        if (isMounted.current) {
                            if (newDropdownList.length > 1 || (newDropdownList.length === 1 && !newDropdownList.includes('none'))) {
                                setAvailableOptions(['search'])
                            } else {
                                setAvailableOptions([])
                            }
                            setFilterInputs(newDropdownList)
                            setDropdownList(newDropdownList)
                            setCreatePageState('preview')
                        }
                    } else {
                        if (isMounted.current) {
                            setCreatePageState(to)
                        }
                    }
                } else {
                    let newDropdownList = []
                    notAvailableCharts.map((el) => {
                        if (el.config.filter) {
                            el.config.filter.map((filter) => {
                                if (!newDropdownList.includes(filter)) {
                                    newDropdownList.push(filter)
                                }
                                return filter
                            })
                        }
                        return el
                    })
                    if (isMounted.current) {
                        if (newDropdownList.length > 1 || (newDropdownList.length === 1 && !newDropdownList.includes('none'))) {
                            setAvailableOptions(['search'])
                            setSelectedAvailableOption('search')
                        } else {
                            setAvailableOptions([])
                            setSelectedAvailableOption('')
                        }
                        setFilterInputs(newDropdownList)
                        setDropdownList(newDropdownList)
                        setCreatePageState(to)
                    }
                }
            } else if (direction === 'back') {
                if (currentPage === 'type') {
                    if (isMounted.current) {
                        resetDashboardName()
                        iconsSelectOption('icoPage1')
                    }
                }
                if (currentPage === 'components') {
                    if (isMounted.current) {
                        resetDashboardName()
                        iconsSelectOption('icoPage1')
                        setCreatePageState(to)
                    }
                } else {
                    if (isMounted.current) setCreatePageState(lastState)
                }
                if (to === 'components') {
                    if (isMounted.current) setAvailableOptions(['store'])
                    setFilterInputs([])
                } else {
                    if (isMounted.current) setAvailableOptions([])
                }
            }
            if (isMounted.current) {
                setLastState(currentPage)
                handleCloseModal()
            }
        }

        const handleCloseModal = () => {
            if (isMounted.current) {
                toggleModal(false)
                setModalType('')
            }
        }

        const handleReturnFromPreview = () => {
            if (isMounted.current) {
                setCreatePageState('creationOptions')
                setSelectedCreationOption('')
            }
        }

        useEffect(() => {
            async function fill() {
                try {
                    const roleBackData = await RolesService.getApiBackofficeRoles()
                    const newRoles = {
                        name: 'roles',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef(),
                    }
                    roleBackData.map((el, index) => {
                        newRoles.options.push(el.name)
                        newRoles.optionsAux.push(index)
                    })
                    rolesReplaceAll(newRoles)
                } catch (err) {
                    errorHandler(err)
                }
                if (isMounted.current) toggleLoading(false)
            }
            fill()
        }, [isMounted, toggleLoading, rolesReplaceAll])

        const droppingItemMemo = useMemo(() => {
            if (!_.isEmpty(resizableGrid.droppingElement)) {
                const { isDynamicComponent } = resizableGrid.droppingElement
                return {
                    i: resizableGrid.droppingElement.i,
                    w: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.w
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.w,
                    h: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.h
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.h,
                    maxW: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.maxW
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.maxW,
                    maxH: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.maxH
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.maxH,
                    minW: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.minW
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.minW,
                    minH: isDynamicComponent
                        ? resizableGrid.droppingElement.dataGrid.minH
                        : componentConfig[resizableGrid.droppingElement.name.split('-')[0]].dataGrid.minH,
                    isDynamicComponent: isDynamicComponent,
                    name: resizableGrid.droppingElement.name,
                    type: resizableGrid.droppingElement.type,
                }
            } else return undefined
        }, [resizableGrid.droppingElement])

        function fitComponents(components) {
            const GRID_WIDTH = 12 // Define the grid width
            let grid = [] // Initialize the grid

            // Function to find the first suitable position for a component
            function findPosition(minW, maxW, minH, maxH) {
                for (let y = 0; ; y++) {
                    for (let x = 0; x <= GRID_WIDTH - minW; x++) {
                        // Attempt to fit the component within the constraints
                        let validWidth = maxW
                        for (let testW = minW; testW <= maxW; testW++) {
                            if (canFit(x, y, testW, minH)) {
                                validWidth = testW
                                break // Found a suitable width that fits
                            }
                        }

                        if (validWidth >= minW && canFit(x, y, validWidth, minH)) {
                            return { x, y, w: validWidth, h: minH } // Return the position and size
                        }
                    }
                }
            }

            // Check if the component can fit in the specified location
            function canFit(x, y, w, h) {
                for (let dy = 0; dy < h; dy++) {
                    for (let dx = 0; dx < w; dx++) {
                        if ((grid[y + dy] && grid[y + dy][x + dx]) || x + dx >= GRID_WIDTH) {
                            return false // Space is already occupied or exceeds grid width
                        }
                    }
                }
                return true // The space is available
            }

            // Place the component on the grid
            function placeComponent(x, y, w, h) {
                for (let dy = 0; dy < h; dy++) {
                    if (!grid[y + dy]) {
                        grid[y + dy] = []
                    }
                    for (let dx = 0; dx < w; dx++) {
                        grid[y + dy][x + dx] = true // Mark the grid space as occupied
                    }
                }
            }

            // Process each component to fit into the grid
            components.forEach((component) => {
                let { minW, maxW, minH, maxH } = component.dataGrid
                let pos = findPosition(minW, maxW, minH, maxH)
                component.dataGrid.x = pos.x
                component.dataGrid.y = pos.y
                component.dataGrid.w = pos.w
                component.dataGrid.h = pos.h
                placeComponent(pos.x, pos.y, pos.w, pos.h)
            })

            return components
        }

        const handlePreviewAIGeneration = useCallback(async () => {
            const chartsResult = await PagesService.postApiPagesNlGeneratePage({
                requestBody: {
                    query: dashboardAIDescription.value,
                },
            })
            setDashboardGenerationState('success')
            setCreatePageState('previewGeneration')
            // sort by minW and minH first to fit the largest components first
            const componentConfigSorted = Object.values(componentConfig).sort((a, b) => {
                return b.dataGrid.minH - a.dataGrid.minH
            })

            const chartLayout = fitComponents(
                Object.values(componentConfigSorted).filter((el) => {
                    return chartsResult.includes(el.type) && !componentConfigSorted['disallowGeneration']
                })
            )
            onFillChartLayout(chartLayout, true)
        }, [onFillChartLayout, dashboardAIDescription.value])

        const handleSubmitAIDescription = () => {
            setCreatePageState('creationOptions')
            setDashboardGenerationState('loading')
            handlePreviewAIGeneration()
        }

        const handleDiscardAIDescription = () => {
            setCreatePageState('creationOptions')
            replaceDashboardAIDescription('')
            setDashboardGenerationState(undefined)
        }

        const handleKeySubmitAIDescription = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                handleSubmitAIDescription()
            }
        }

        const handleFocusAIDescription = () => {
            setDashboardGenerationState('hovered')
        }

        const handleEnterAIDescription = useCallback(() => {
            if (dashboardAIDescription.value === '' && !dashboardGenerationState) {
                handleFocusAIDescription()
            }
        }, [dashboardAIDescription, dashboardGenerationState])

        const handleLeaveAIDescription = useCallback(() => {
            if (dashboardAIDescription.value === '' && dashboardGenerationState === 'hovered') {
                setDashboardGenerationState(undefined)
            }
        }, [dashboardAIDescription, dashboardGenerationState])

        return (
            <>
                {modal && modalType === 'create' && (
                    <Modal onToggleModal={handleCloseModal}>
                        <div className="row mx-0 d-flex justify-content-start align-items-center" style={{ marginBottom: '2rem' }}>
                            <span className="modal-title">{t('modals:createDashboard')}</span>
                        </div>
                        <div className="w-100 h-100 d-flex-justify-content-center align-items-center create-dashboard-inputs">
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col-auto px-0 h-100 d-flex justify-content-start align-items-start" style={{ width: '9rem' }}>
                                    <SelectInput
                                        onChangeInput={iconsSetValue}
                                        onSelectInput={iconsSelectOption}
                                        onSelectIconInput={iconsSelectOption}
                                        onKeyPress={iconsKeyPress}
                                        onKeyDown={iconsKeyDown}
                                        onToggleDropdown={iconsToggleDropdown}
                                        {...icons}
                                        inputType={'icon'}
                                        selectedIcon={iconMap[icons.selectedIcon]}
                                    />
                                </div>
                                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                                <div className="col px-0 d-flex h-100 flex-column justify-content-start align-items-start">
                                    <TypeInput onChangeInput={setDashboardNameValue} {...dashboardName} />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 " style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <SelectMultipleInput
                                        hideOptionsAux
                                        onChangeInput={rolesSetValue}
                                        onSelectMultiple={rolesSelectOption}
                                        onSelectedOptionClick={rolesUnselectOption}
                                        onKeyPress={rolesKeyPress}
                                        onKeyDown={rolesKeyDown}
                                        onToggleDropdown={rolesToggleDropdown}
                                        {...roles}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 " style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <RadioInput {...includeFolder} onClickRatioOption={includeFolderClickRadioOption} />
                                </div>
                            </div>
                            {includeFolder && includeFolder.selectedOption === 'yes' && (
                                <div
                                    className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 "
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <RadioInput {...folderType} onClickRatioOption={folderTypeClickRadioOption} />
                                    </div>
                                </div>
                            )}

                            {includeFolder && includeFolder.selectedOption === 'yes' && folderType && folderType.selectedOption === 'newFolder' && (
                                <div
                                    className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 "
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={folderNameSetValue} {...folderName} />
                                    </div>
                                </div>
                            )}
                            {includeFolder &&
                                includeFolder.selectedOption === 'yes' &&
                                folderType &&
                                folderType.selectedOption === 'existingFolder' && (
                                    <div
                                        className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 "
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <SelectInput
                                                onChangeInput={folderSetValue}
                                                onSelectInput={folderSelectOption}
                                                hideOptionsAux
                                                onSelectIconInput={folderSelectOption}
                                                onKeyPress={folderKeyPress}
                                                onKeyDown={folderKeyDown}
                                                onToggleDropdown={folderToggleDropdown}
                                                {...folder}
                                            />
                                        </div>
                                    </div>
                                )}

                            <hr
                                className="my-4"
                                style={{
                                    color: 'rgba(0,0,0,0.1)',
                                    opacity: 1,
                                }}
                            />
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <TerciaryButton text={t('buttons:cancel')} onClick={handleCloseModal} />
                                </div>
                                <div className="col-5 px-0">
                                    <PrimaryButton
                                        text={t('buttons:save')}
                                        disabled={
                                            !dashboardName.value ||
                                            roles.selectedOptions.length === 0 ||
                                            !includeFolder.selectedOption ||
                                            (includeFolder.selectedOption === 'yes' && !folderType.selectedOption) ||
                                            (includeFolder.selectedOption === 'yes' &&
                                                folderType.selectedOption === 'newFolder' &&
                                                !folderName.value) ||
                                            (includeFolder.selectedOption === 'yes' &&
                                                folderType.selectedOption === 'existingFolder' &&
                                                !folder.selectedOption)
                                        }
                                        onClick={() => {
                                            handleCreationNavigation('type', undefined, 'front', 'components')
                                            setSavedDashboardName(dashboardName.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                {loading && <LoaderForeground />}
                {(createPageState === 'creationOptions' || (dashboardGenerationState && createPageState === 'previewGeneration')) && !loading && (
                    <React.Fragment>
                        <div className={`${styles['create-dashboard-container']}`}>
                            <div
                                className={`row mx-0 flex-grow-1 ${styles['create-dashboard-container__layout-container']}`}
                                style={
                                    steps &&
                                    selectedTutorialStep !== '' &&
                                    steps[selectedTutorialStep]['steps'][currentStep]['popupDetails'] &&
                                    steps[selectedTutorialStep]['steps'][currentStep]['popupDetails']['type'] === 'dashboardPreviewCard'
                                        ? {
                                              zIndex: 10,
                                              overflow: 'visible',
                                          }
                                        : {}
                                }
                            >
                                <div className="col px-0">
                                    <>
                                        <AnimateHeight
                                            id="example-panel"
                                            duration={500}
                                            delay={!dashboardGenerationState ? 0 : 300}
                                            height={dashboardGenerationState === undefined ? 'auto' : 0} // see props documentation below
                                        >
                                            <div
                                                className="row mx-0 w-100"
                                                style={{
                                                    marginBottom: '5rem',
                                                }}
                                            >
                                                <div className="col px-0">
                                                    <div
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem',
                                                        }}
                                                    >
                                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                                            <span className={`${styles['create-dashboard-container__sub-title']}`}>
                                                                {t('pages:page-management.create-dashboard.subTitle')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            marginBottom: '2rem',
                                                        }}
                                                    >
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                padding: '0 2rem',
                                                                maxWidth: '128rem',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '1rem',
                                                                }}
                                                            >
                                                                <div
                                                                    className="col px-0 d-flex justify-content-end align-items-end"
                                                                    style={{
                                                                        height: '3rem',
                                                                    }}
                                                                >
                                                                    <span className={`${styles['create-dashboard-container__description-title']}`}>
                                                                        {t('pages:page-management.create-dashboard.description1Title')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '1rem',
                                                                }}
                                                            >
                                                                <div
                                                                    className="col px-0 d-flex justify-content-end align-items-end"
                                                                    style={{
                                                                        height: '3rem',
                                                                    }}
                                                                >
                                                                    <span className={`${styles['create-dashboard-container__description-title']}`}>
                                                                        {t('pages:page-management.create-dashboard.description2Title')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {process.env.REACT_APP_PROMPT_GENERATE_DASHBOARD === 'true' && (
                                                                <div
                                                                    className="row mx-0 w-100"
                                                                    style={{
                                                                        marginBottom: '1rem',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col px-0 d-flex justify-content-end align-items-end"
                                                                        style={{
                                                                            height: '3rem',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={`${styles['create-dashboard-container__description-title']}`}
                                                                        >
                                                                            {t('pages:page-management.create-dashboard.description3Title')}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                width: '0.5rem',
                                                            }}
                                                        ></div>
                                                        <div className="col-auto px-0">
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '1rem',
                                                                }}
                                                            >
                                                                <div
                                                                    className="col px-0 d-flex justify-content-start align-items-end"
                                                                    style={{
                                                                        height: '3rem',
                                                                    }}
                                                                >
                                                                    <span className={`${styles['create-dashboard-container__description-content']}`}>
                                                                        {t('pages:page-management.create-dashboard.description1Content')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '1rem',
                                                                }}
                                                            >
                                                                <div
                                                                    className="col px-0 d-flex justify-content-start align-items-end"
                                                                    style={{
                                                                        height: '3rem',
                                                                    }}
                                                                >
                                                                    <span className={`${styles['create-dashboard-container__description-content']}`}>
                                                                        {t('pages:page-management.create-dashboard.description2Content')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {process.env.REACT_APP_PROMPT_GENERATE_DASHBOARD === 'true' && (
                                                                <div
                                                                    className="row mx-0 w-100"
                                                                    style={{
                                                                        marginBottom: '1rem',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col px-0 d-flex justify-content-start align-items-end"
                                                                        style={{
                                                                            height: '3rem',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={`${styles['create-dashboard-container__description-content']}`}
                                                                        >
                                                                            {t('pages:page-management.create-dashboard.description3Content')}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0 d-flex justify-content-center">
                                                            <span className={`${styles['create-dashboard-container__title-AI']}`}>
                                                                {t('pages:page-management.create-dashboard.callToAction')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AnimateHeight>
                                    </>
                                    {process.env.REACT_APP_PROMPT_GENERATE_DASHBOARD === 'true' && (
                                        <>
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                style={{
                                                    marginBottom: '4rem',
                                                }}
                                            >
                                                <div
                                                    className="col-auto px-0"
                                                    style={{
                                                        width: dashboardGenerationState ? '128rem' : '75rem',
                                                        transition: !dashboardGenerationState ? 'all 0.3s ease-in 0.3s' : 'all 0.3s ease-in',
                                                    }}
                                                >
                                                    <TypeInput
                                                        onChangeInput={setDashboardAIDescriptionValue}
                                                        onFocus={handleEnterAIDescription}
                                                        onBlur={handleLeaveAIDescription}
                                                        isAI
                                                        onSubmit={
                                                            dashboardGenerationState !== 'loading'
                                                                ? () => {
                                                                      handleSubmitAIDescription()
                                                                  }
                                                                : undefined
                                                        }
                                                        onDiscard={
                                                            dashboardGenerationState === 'loading'
                                                                ? () => {
                                                                      handleDiscardAIDescription()
                                                                  }
                                                                : undefined
                                                        }
                                                        onKeyPress={dashboardGenerationState === 'loading' ? undefined : handleKeySubmitAIDescription}
                                                        {...dashboardAIDescription}
                                                    />
                                                </div>
                                            </div>
                                            {dashboardGenerationState && (
                                                <>
                                                    {dashboardGenerationState === 'loading' && (
                                                        <>
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '2rem',
                                                                }}
                                                            >
                                                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                                                    <span className={`${styles['create-dashboard-container__generating-title']}`}>
                                                                        {t('pages:page-management.create-dashboard.generatingDashboard')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 w-100 d-flex justify-content-center">
                                                                <div
                                                                    className="col-auto px-0"
                                                                    style={{
                                                                        width: '50rem',
                                                                    }}
                                                                >
                                                                    <LoadingBar />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {dashboardGenerationState === 'error' && (
                                                        <>
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '2rem',
                                                                }}
                                                            >
                                                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                                                    <span
                                                                        className={`${styles['create-dashboard-container__sub-title']}`}
                                                                        style={{
                                                                            color: '#dc6e6a',
                                                                        }}
                                                                    >
                                                                        {t('pages:page-management.create-dashboard.errorGeneratingDashboard')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {dashboardGenerationState === 'success' && createPageState === 'previewGeneration' && (
                                                        <CustomDashboard
                                                            type="previewGeneration"
                                                            templateName={previewName}
                                                            templateOption={templateOption}
                                                            onChooseTemplate={handleChooseTemplateFromPreview}
                                                            onReturn={handleDiscardAIDescription}
                                                            initialDashboardTitle={dashboardName.value}
                                                            initialNotAvailableCharts={notAvailableCharts}
                                                            initialAvailableCharts={availableCharts}
                                                            initialDropdownList={dropdownList}
                                                            resizableGrid={resizableGrid}
                                                            onSetLayout={onSetLayout}
                                                            onUpdateRoute={onUpdateRoute}
                                                            onPublishRoute={onPublishRoute}
                                                            onNavigate={handleCreationNavigation}
                                                            onCreatePage={handleCreatePage}
                                                            pageState={createPageState}
                                                            lastState={lastState}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                style={{
                                                    marginBottom: '5rem',
                                                    opacity: dashboardGenerationState ? 0 : 1,
                                                    pointerEvents: dashboardGenerationState ? 'none' : 'auto',
                                                    transition: 'all 0.3s ease-in',
                                                }}
                                            >
                                                <div className="col-auto px-0">
                                                    <span className={`${styles['create-dashboard-container__sub-title']}`}>
                                                        {t('pages:page-management.create-dashboard.or')}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center"
                                        style={{
                                            opacity: dashboardGenerationState ? 0 : 1,
                                            pointerEvents: dashboardGenerationState ? 'none' : 'auto',
                                            transition: 'all 0.3s ease-in',
                                        }}
                                    >
                                        {dashboardCreationOptions.map((el, index) => {
                                            return (
                                                <div key={index} className="col-auto px-0">
                                                    <div
                                                        className={
                                                            el.strokeType === 'dashed'
                                                                ? styles['create-dashboard-container__creation-option'] +
                                                                  ' ' +
                                                                  styles['create-dashboard-container__creation-option--dashed']
                                                                : styles['create-dashboard-container__creation-option']
                                                        }
                                                        style={
                                                            el.label === selectedCreationOption
                                                                ? {
                                                                      backgroundImage: `url(${creationOptionBackground})`,
                                                                      backgroundSize: 'cover',
                                                                      backgroundPosition: 'center',
                                                                  }
                                                                : {}
                                                        }
                                                        onClick={() => {
                                                            if (el.label === 'createDashboard') {
                                                                handleChooseTemplate('blankReport')
                                                            } else {
                                                                setSelectedCreationOption(el.label)
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src={el.image}
                                                            alt="icon"
                                                            className={styles['create-dashboard-container__creation-option__image']}
                                                        />
                                                        <span className={styles['create-dashboard-container__creation-option__text']}>
                                                            {t(`pages:page-management.create-dashboard.creationOptions.${el.label}`)}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {selectedCreationOption === 'chooseTemplate' && (
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginTop: '4rem',
                                                marginBottom: '2rem',
                                                opacity: dashboardGenerationState ? 0 : 1,
                                                pointerEvents: dashboardGenerationState ? 'none' : 'auto',
                                                transition: 'all 0.3s ease-in',
                                            }}
                                        >
                                            <div className={`col ${styles['create-dashboard-container__layout-container__options-container']}`}>
                                                <CardCarousel
                                                    cards={[
                                                        <DashboardPreviewCard
                                                            key={'salesReport'}
                                                            name={'salesReport'}
                                                            page={'generalPage'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                        <DashboardPreviewCard
                                                            key={'clientsGeneral'}
                                                            name={'clientsGeneral'}
                                                            page={'clientsGeneralPage'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                        <DashboardPreviewCard
                                                            key={'clientsDetailed'}
                                                            name={'clientsDetailed'}
                                                            page={'clientsDetailedPage'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                        <DashboardPreviewCard
                                                            key={'products'}
                                                            name={'products'}
                                                            page={'productsPage'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                        <DashboardPreviewCard
                                                            key={'global'}
                                                            name={'global'}
                                                            page={'global'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                        <DashboardPreviewCard
                                                            key={'productOverview'}
                                                            name={'productOverview'}
                                                            page={'productOverview'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                        <DashboardPreviewCard
                                                            key={'regionOverview'}
                                                            name={'regionOverview'}
                                                            page={'regionOverview'}
                                                            selectedOption={selectedOption}
                                                            onChooseTemplate={handleChooseTemplate}
                                                            onPreviewTemplate={handlePreviewTemplate}
                                                            t={t}
                                                        />,
                                                    ]}
                                                    selectedOption={selectedOption}
                                                    onChooseTemplate={handleChooseTemplate}
                                                    onPreviewTemplate={handlePreviewTemplate}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <Auxbar isBuilder /> */}
                    </React.Fragment>
                )}
                {createPageState === 'type' && !loading && (
                    <React.Fragment>
                        <div className={`${styles['create-dashboard-container']}`}>
                            <div
                                className={`row mx-0 flex-grow-1 ${styles['create-dashboard-container__layout-container']}`}
                                style={
                                    steps &&
                                    selectedTutorialStep !== '' &&
                                    steps[selectedTutorialStep]['steps'][currentStep]['popupDetails'] &&
                                    steps[selectedTutorialStep]['steps'][currentStep]['popupDetails']['type'] === 'dashboardPreviewCard'
                                        ? {
                                              zIndex: 10,
                                              overflow: 'visible',
                                          }
                                        : {}
                                }
                            >
                                <div className="col px-0">
                                    <div className={`row w-100 dashboard-header-row`}>
                                        <div className="col px-0 d-flex justify-content-start align-items-center">
                                            <span className={`title`}>{t('pages:page-management.create-dashboard.dashboardGallery')}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '5rem',
                                        }}
                                    >
                                        <div className="col px-0">
                                            <div
                                                className="row mx-0 w-100"
                                                style={{
                                                    marginBottom: '2rem',
                                                }}
                                            >
                                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                                    <span className={`${styles['create-dashboard-container__sub-title']}`}>
                                                        {t('pages:page-management.create-dashboard.subTitle')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                        style={{
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <div className="col-auto px-0">
                                            <SecondaryButton text={t('buttons:create-dashboard')} onClick={() => handleChooseTemplate('blankPage')} />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                        style={{
                                            marginBottom: '5rem',
                                        }}
                                    >
                                        <div className="col-auto px-0">
                                            <span className={`${styles['create-dashboard-container__sub-title']}`}>
                                                {t('pages:page-management.create-dashboard.or')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Auxbar isBuilder /> */}
                    </React.Fragment>
                )}
                {createPageState === 'components' && (
                    <div className="row mx-0 w-100 h-100">
                        <div className={`col-12 ${styles['create-dashboard-container']}`}>
                            <div className={`row mx-0 flex-grow-1 ${styles['create-dashboard-container__layout-container']}`}>
                                <div className="col px-0">
                                    <div className={`row mx-0 w-100 dashboard-header-row mb-4 d-flex justify-content-end`}>
                                        <div className="col-auto px-0">
                                            <ActionBar
                                                actions={[
                                                    {
                                                        text: t('buttons:cancel'),
                                                        icon: icoArrowBackAction,
                                                        onClick: () => {
                                                            if (
                                                                steps &&
                                                                selectedTutorialStep !== '' &&
                                                                steps[selectedTutorialStep]['steps'][currentStep]['waitUntilTrigger'] &&
                                                                steps[selectedTutorialStep]['steps'][currentStep]['triggerPoint'] ===
                                                                    'dashboardCreated'
                                                            ) {
                                                                setCurrentStep(currentStep + 1)
                                                            }
                                                            handleCreationNavigation('components', selectedOption, 'back', 'creationOptions')
                                                        },
                                                    },
                                                    {
                                                        text: savedDashboardName,
                                                        icon: icoEditCurrentDashboard,
                                                        onClick: () => {
                                                            handleEditTemplate(templateOption)
                                                        },
                                                    },
                                                    {
                                                        text: t('buttons:saveasdraft'),
                                                        icon: icoSaveCurrentDashboard,
                                                        onClick: () =>
                                                            handleCreatePage(
                                                                availableCharts,

                                                                notAvailableCharts,
                                                                true
                                                            ),
                                                    },
                                                    {
                                                        text: t('buttons:preview'),
                                                        icon: icoPreviewCurrentDashboard,
                                                        onClick: () => {
                                                            handleCreationNavigation('components', selectedOption, 'front', 'previewFinal')
                                                        },
                                                    },
                                                    {
                                                        text: t('buttons:publish'),
                                                        icon: icoPublishCurrentDashboard,
                                                        onClick: () => {
                                                            if (
                                                                steps &&
                                                                selectedTutorialStep !== '' &&
                                                                steps[selectedTutorialStep]['steps'][currentStep]['waitUntilTrigger'] &&
                                                                steps[selectedTutorialStep]['steps'][currentStep]['triggerPoint'] ===
                                                                    'dashboardCreated'
                                                            ) {
                                                                setCurrentStep(currentStep + 1)
                                                            }

                                                            handleCreatePage(
                                                                availableCharts,

                                                                notAvailableCharts,
                                                                false
                                                            )
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className={`row mx-0 w-100 ${styles['create-dashboard-container__section']}`}>
                                        <div className={`col ${styles['create-dashboard-container__layout-container__options-container']}`}>
                                            <div
                                                className={`row mx-0 flex-grow-1 ${styles['create-dashboard-container__dashboard-template-container']}`}
                                            >
                                                <div
                                                    className={`col px-0 d-flex flex-column ${styles['create-dashboard-container__dashboard-template-container__invisible']}`}
                                                >
                                                    <div className="row mx-0 w-100 h-100">
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__invisible__column']}`}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`col px-0 d-flex flex-column ${styles['create-dashboard-container__dashboard-template-container__background']}`}
                                                >
                                                    <div className="row mx-0 w-100 h-100">
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']} d-flex justify-content-center align-items-center']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                        <div
                                                            className={`col h-100 ${styles['create-dashboard-container__dashboard-template-container__background__column']}`}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col px-0 d-flex flex-column"
                                                    style={{
                                                        minHeight: 'inherit',
                                                    }}
                                                    ref={gridRef}
                                                >
                                                    <ResponsiveGridLayout
                                                        style={{
                                                            alignItems: 'start',
                                                        }}
                                                        cols={cols}
                                                        className={resizableGrid.gridMounted ? 'layout w-100 mx-0 animated' : 'layout w-100 mx-0'}
                                                        rowHeight={currentWidthSectionMultiplier * 30}
                                                        maxRows={resizableGrid.maxRows}
                                                        compactType={resizableGrid.compactType}
                                                        draggableCancel={'.resize-handle'}
                                                        verticalCompact={resizableGrid.verticalCompact}
                                                        layout={resizableGrid.layout}
                                                        onLayoutChange={
                                                            !_.isEmpty(resizableGrid.droppingElement)
                                                                ? () => {}
                                                                : (layout) => onPageLayoutChange(undefined, layout)
                                                        }
                                                        isDraggable={resizableGrid.isEditable}
                                                        isResizable={resizableGrid.isEditable}
                                                        isDroppable={true}
                                                        // transformScale={!_.isEmpty(resizableGrid.droppingElement) ? transformScaleCustom : undefined}
                                                        onDrop={(layout, layoutItem, _event) => {
                                                            if (notAvailableCharts.length > maxComponentCount) {
                                                                error({
                                                                    text: t('pages:page-management.create-dashboard.maxComponentsError'),
                                                                })
                                                            } else if (layoutItem) {
                                                                onPageDropChart(selectedOption, layout, layoutItem, _event)
                                                            }
                                                            _event.preventDefault()
                                                        }}
                                                        droppingItem={droppingItemMemo}
                                                        preventCollision={false}
                                                        draggableHandle={resizableGrid.draggableHandle}
                                                        margin={resizableGrid.margin}
                                                        containerPadding={resizableGrid.containerPadding}
                                                        resizeHandle={resizableGrid.isEditable ? <MyResizeHandle /> : ''}
                                                        autoSize={resizableGrid.autoSize}
                                                        isBounded={resizableGrid.isBounded}
                                                        useCSSTransforms={false}
                                                        measureBeforeMount={false}
                                                    >
                                                        {notAvailableCharts.map((el) => {
                                                            return (
                                                                <div
                                                                    data-grid={el.dataGrid}
                                                                    key={el.dataGrid.i}
                                                                    className={`${styles['create-dashboard-container__dashboard-template-container__chart-container']}`}
                                                                    style={
                                                                        highlightedChart && el.dataGrid.i === highlightedChart.dataGrid.i
                                                                            ? {
                                                                                  zIndex: 1000,
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    <LibraryComponentFactory
                                                                        hoveredFilter={''}
                                                                        hoveredComponents={[]}
                                                                        component={el}
                                                                        onPinComponent={() => {}}
                                                                        onUnpinComponent={() => {}}
                                                                        pinnedComponents={[]}
                                                                        onMouseEnterInfoIcon={() => {}}
                                                                        onMouseLeaveInfoIcon={() => {}}
                                                                        type={el.type}
                                                                        salesDataTimeWindow={'month'}
                                                                        isExample
                                                                        showAllInfo
                                                                    />
                                                                    {/* <ExampleBackground /> */}
                                                                    <EditOptions
                                                                        type={el.dataGrid.i}
                                                                        config={el.config}
                                                                        onHighlightChart={() => {
                                                                            setHighlightedChart(el)
                                                                        }}
                                                                        onOpenChartConfig={onOpenChartConfig}
                                                                        onDeleteChart={onDeletePageChart}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </ResponsiveGridLayout>
                                                    {highlightedChart && (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                position: 'fixed',
                                                                backgroundColor: '#000',
                                                                opacity: '0.3',
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 100,
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-auto px-0">
                            <Auxbar
                                isBuilder
                                includeStore
                                availableCharts={availableCharts}
                                notAvailableCharts={notAvailableCharts}
                            />
                        </div> */}
                    </div>
                )}
                <PreviewModeProvider value={{ isExample: true }}>
                    {createPageState === 'preview' && (
                        <>
                            <CustomDashboard
                                type="preview"
                                templateName={previewName}
                                templateOption={templateOption}
                                onChooseTemplate={handleChooseTemplateFromPreview}
                                onReturn={handleReturnFromPreview}
                                initialDashboardTitle={savedDashboardName}
                                initialNotAvailableCharts={notAvailableCharts}
                                initialAvailableCharts={availableCharts}
                                initialDropdownList={dropdownList}
                                resizableGrid={resizableGrid}
                                onSetLayout={onSetLayout}
                                onUpdateRoute={onUpdateRoute}
                                onPublishRoute={onPublishRoute}
                                onNavigate={handleCreationNavigation}
                                onCreatePage={handleCreatePage}
                                pageState={createPageState}
                                lastState={lastState}
                                isPreview
                            />
                        </>
                    )}
                    {createPageState === 'previewFinal' && (
                        <>
                            <CustomDashboard
                                type="previewFinal"
                                templateName={previewName}
                                templateOption={templateOption}
                                onChooseTemplate={handleChooseTemplateFromPreview}
                                onReturn={handleReturnFromPreview}
                                initialDashboardTitle={savedDashboardName}
                                initialNotAvailableCharts={notAvailableCharts}
                                initialAvailableCharts={availableCharts}
                                initialDropdownList={dropdownList}
                                resizableGrid={resizableGrid}
                                onSetLayout={onSetLayout}
                                onUpdateRoute={onUpdateRoute}
                                onPublishRoute={onPublishRoute}
                                onNavigate={handleCreationNavigation}
                                onCreatePage={handleCreatePage}
                                pageState={createPageState}
                                lastState={lastState}
                                isPreview
                            />
                        </>
                    )}
                </PreviewModeProvider>
            </>
        )
    }
)

export default CreateDashboard

CreateDashboard.propTypes = {
    /**
     * List if filters to show in the preview step of the page creation workflow
     */
    dropdownList: PropTypes.arrayOf(PropTypes.string),
    /**
     * Charts to populate layout once the dashboard was published or drafted
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Charts to populate store once the dashboard was published or drafted
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Object containing layout details regarding each chart individually
     */
    resizableGrid: PropTypes.object,
    /**
     * Provides manual control of loading page state
     */
    loading: PropTypes.bool,
    /**
     * Allows users to update dashboard being created
     */
    onUpdateRoute: PropTypes.func,
    /**
     * Allows users to publish dashboard being created
     */
    onPublishRoute: PropTypes.func,
    /**
     * Allows user to return back to the chosen template on the workflow
     */
    selectedOption: PropTypes.func,
    /**
     * Allows user to navigate between dashboard creation steps
     */
    onNavigate: PropTypes.func,
    /**
     * Contains the dashboard creation step
     */
    pageState: PropTypes.string,
    /**
     * Provides the user with the ability to return back to the initial step or the second step depending on where
     * the user chose to preview the dashboard layout
     */
    lastState: PropTypes.string,
    /**
     * Allows users to change layout of components
     */
    onPageLayoutChange: PropTypes.func,
    /**
     * Allows users to drop new charts into the layout
     */
    onPageDropChart: PropTypes.func,
    /**
     * To provide individual management of each chart
     */
    onOpenChartConfig: PropTypes.func,
    /**
     * Users can remove a chart back into the library
     */
    onDeletePageChart: PropTypes.func,
    /**
     * Labels the new dashboard
     */
    dashboardName: PropTypes.string,
    /**
     * Changes the layout of the grid
     */
    onSetLayout: PropTypes.func,
}
