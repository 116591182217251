import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './global-modal-popup-original.module.scss'
import { ModalPopup } from '../modal-popup/modal-popup'
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const GlobalModalPopupOriginal = React.memo(
    ({
        direction,
        visible,
        toggleIsOpen,
        steps,
        selectedTutorialStep,
        currentStep,
        setCurrentStep,
    }) => {
        const { t } = useCustomTranslation()

        return (
            <div
                className={`${styles['global-modal-popup-original-container']}`}
                style={
                    visible
                        ? { opacity: 1 }
                        : {
                              opacity: 0,
                              pointerEvents: 'none',
                          }
                }
            >
                <div
                    className={`w-100 h-100 ${styles['global-modal-popup-original-container__highlight-container']}`}
                    style={
                        visible
                            ? { opacity: 1 }
                            : {
                                  opacity: 0,
                                  pointerEvents: 'none',
                              }
                    }
                ></div>
                <div
                    className={`${styles['global-modal-popup-original-container__popup-container']}`}
                    style={
                        visible &&
                        (direction === 'left' ||
                            direction === 'right' ||
                            direction === 'up' ||
                            direction === 'down')
                            ? { opacity: 1, transform: 'translate(0,0)' }
                            : !visible &&
                              (direction === 'left' || direction === 'right')
                            ? {
                                  opacity: 0,
                                  pointerEvents: 'none',
                                  transform: 'translate(-1rem,0)',
                              }
                            : visible
                            ? { opacity: 1, transform: 'translate(0,0)' }
                            : {
                                  opacity: 0,
                                  pointerEvents: 'none',
                                  transform: 'translate(0,-1rem)',
                              }
                    }
                >
                    <ModalPopup
                        direction={direction ? direction : undefined}
                        top={
                            _.has(
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['popupDetails'],
                                'top'
                            )
                                ? steps[selectedTutorialStep]['steps'][
                                      currentStep
                                  ]['popupDetails']['top']
                                : undefined
                        }
                        bottom={
                            _.has(
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['popupDetails'],
                                'bottom'
                            )
                                ? steps[selectedTutorialStep]['steps'][
                                      currentStep
                                  ]['popupDetails']['bottom']
                                : undefined
                        }
                        left={
                            _.has(
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['popupDetails'],
                                'left'
                            )
                                ? steps[selectedTutorialStep]['steps'][
                                      currentStep
                                  ]['popupDetails']['left']
                                : undefined
                        }
                        right={
                            _.has(
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['popupDetails'],
                                'right'
                            )
                                ? steps[selectedTutorialStep]['steps'][
                                      currentStep
                                  ]['popupDetails']['right']
                                : undefined
                        }
                    >
                        <div className="row mx-0 d-flex justify-content-start align-items-center mb-1">
                            <div className="col px-0">
                                <span
                                    className={`${styles['global-modal-popup-original-container__title']}`}
                                >
                                    {t(
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ].title
                                    )}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 d-flex justify-content-start align-items-center "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                {steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ].text.map((el, index) => {
                                    return (
                                        <span
                                            key={index}
                                            className={
                                                el.bold
                                                    ? `${styles['global-modal-popup-original-container__text']} ${styles['global-modal-popup-original-container__text--bold']}`
                                                    : `${styles['global-modal-popup-original-container__text']}`
                                            }
                                        >
                                            {t(el.text)}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                        {steps[selectedTutorialStep]['steps'][currentStep]
                            .isStart &&
                            currentStep + 1 !==
                                steps[selectedTutorialStep]['steps'].length && (
                                <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton
                                            text={t('buttons:gotIt')}
                                            onClick={() => {
                                                if (
                                                    !steps[
                                                        selectedTutorialStep
                                                    ]['steps'][currentStep + 1]
                                                        .isPopup
                                                ) {
                                                    toggleIsOpen(true)
                                                }
                                                setCurrentStep(currentStep + 1)
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                    </ModalPopup>
                </div>
            </div>
        )
    }
)
