import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { authMap } from '../../constants/authMap'
import PropTypes from 'prop-types'
import { useArray } from '../../hooks/useArray'

import authNavOptions from './logged-out-nav-options.json'

import styles from './logged-out-routes.module.scss'
import { FilterProvider } from '../../providers/filter-context'
import { Notification } from '../../components'
import { useCustomTranslation } from '../../hooks/useCustomTranslation'
import SetPassword from '../../pages/auth/set-password/set-password'

// removeFromLS('extra-nav-options');

/**
 * Handles Routes for authentication-purposed pages
 */

const LoggedOutRoutes = React.memo(({ onFinishLogin }) => {
    const { t } = useCustomTranslation()
    const { array: routes } = useArray(authNavOptions)
    const location = useLocation()

    return (
        <div
            className={`${styles['auth-container']}`}
            style={
                location.pathname.split('/')[1] === 'invisible'
                    ? { height: 'auto' }
                    : {}
            }
        >
            <FilterProvider>
                <Notification
                    successLabel={t('notifications:success')}
                    failedLabel={t('notifications:failed')}
                    onStartNotification={() => {}}
                    onFinishNotification={() => {}}
                />
                {routes && (
                    <Routes>
                        {routes.map((el, index) => {
                            if (el.component) {
                                const Page = authMap[el.component]
                                return (
                                    <Route
                                        key={index}
                                        path={`/${el.url}`}
                                        element={
                                            <Page
                                                onFinishLogin={onFinishLogin}
                                            />
                                        }
                                    ></Route>
                                )
                            }
                        })}
                        <Route
                            path="/set-password/:uuid"
                            element={<SetPassword />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={'/login'} replace />}
                        />
                    </Routes>
                )}
            </FilterProvider>
        </div>
    )
})

export default LoggedOutRoutes

LoggedOutRoutes.propTypes = {
    /**
     * Propagates ending of login process
     */
    onFinishLogin: PropTypes.func,
}
