import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '../../../hooks/useToggle';
import icoCheckCircle from '../../../assets/images/icoCheckCircle.svg';

import styles from './contact-support.module.scss';
import { useTranslation } from 'react-i18next';
import {
    descriptionSchema,
    emailSchema,
    nameSchema,
    topicSchema
} from '../../../schemas/fieldSchemas';
import { useTextInput } from '../../../hooks/useTextInput';
import { errorHandler } from '../../../utils/api';
import icoStarkSmall from '../../../assets/images/logo-small.jpg';
import { SupportService } from '../../../temp/test';
import {
    PrimaryButton,
    TerciaryButton,
    TypeInput,
    TypeInputAlternate
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { AuthBackgroundWrapper } from '../../../components/backgrounds/AuthBackgroundWrapper/AuthBackgroundWrapper';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';

const ContactSupport = React.memo(() => {
    const { t } = useCustomTranslation();
    const navigate = useNavigate();
    const [isFormSubmitted, toggleIsFormSubmitted] = useToggle(false);

    const name = useTextInputAlternate({
        name: 'name',
        schema: nameSchema
    });

    const email = useTextInputAlternate({
        name: 'email',
        schema: emailSchema
    });

    const topic = useTextInputAlternate({
        name: 'topic',
        schema: topicSchema
    });

    const description = useTextInputAlternate({
        name: 'description',
        schema: descriptionSchema
    });

    const handleSubmit = async () => {
        try {
            toggleIsFormSubmitted(true);
            await SupportService.postApiSupport({
                requestBody: {
                    name: name.value,
                    email: email.value,
                    reason: topic.value,
                    message: description.value
                }
            });
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleKeyPress = (e) => {
        if (
            e.which === 13 &&
            !(
                !name.value ||
                !email.value ||
                !topic.value ||
                email.error ||
                name.error ||
                topic.error
            )
        ) {
            handleSubmit();
        }
    };

    return (
        <AuthBackgroundWrapper
            title={t('pages:login.contactForm')}
            description={t('pages:login.contactFormDesc')}
            content={
                <>
                    {!isFormSubmitted && (
                        <div className="row mx-0">
                            <div className="col px-0">
                                <div
                                    className="row mx-0"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInputAlternate
                                            input={name}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row mx-0"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInputAlternate
                                            input={email}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row mx-0"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInputAlternate
                                            input={topic}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row mx-0"
                                    style={{
                                        height: '20rem',
                                        marginBottom: '2rem'
                                    }}
                                >
                                    <div className="col px-0 h-100">
                                        <TypeInputAlternate
                                            input={description}
                                            onKeyPress={handleKeyPress}
                                            inputType={'container'}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-between align-items-center"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <PrimaryButton
                                            disabled={
                                                !name.value ||
                                                !email.value ||
                                                !topic.value ||
                                                email.error ||
                                                name.error ||
                                                topic.error
                                            }
                                            text={t('pages:login.submit')}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                                    <div className="col px-0">
                                        <TerciaryButton
                                            text={t('pages:login.back')}
                                            onClick={() => {
                                                navigate('/login');
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isFormSubmitted && (
                        <React.Fragment>
                            <div className="row mx-0">
                                <div className="col px-0">
                                    <div
                                        className="row mx-0"
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <img
                                                loading="lazy"
                                                src={icoCheckCircle}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0"
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['contact-support-container__desc-success']}`}
                                            >
                                                {t(
                                                    'pages:contactSupport.formSent'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={`${styles['contact-support-container__desc2']}`}
                                            >
                                                {t(
                                                    'pages:contactSupport.formSentDesc'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 d-flex justify-content-start align-items-center"
                                        style={{ marginTop: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <PrimaryButton
                                                text={t(
                                                    'pages:contactSupport.goBack'
                                                )}
                                                onClick={() => {
                                                    navigate('/login');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </>
            }
        ></AuthBackgroundWrapper>
    );
});

export default ContactSupport;
