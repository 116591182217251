export function componentsFit(components) {
    let fits = true;
    const GRID_WIDTH = 12; // Define the grid width
    let grid = []; // Initialize the grid
    // Function to check if a component can fit in the specified location
    function canFit(x, y, w, h) {
        for (let dy = 0; dy < h; dy++) {
            for (let dx = 0; dx < w; dx++) {
                if (grid[y + dy][x + dx]) {
                    return false; // Space is already occupied or exceeds grid dimensions
                } else {
                    grid[y + dy][x + dx] = true; // Mark the grid space as occupied
                }
            }
        }
        return true; // The space is available
    }

    // Place the component on the grid
    function placeComponent(x, y, w, h) {
        for (let dy = 0; dy < h; dy++) {
            if (!grid[y + dy]) {
                grid[y + dy] = [];
            }
            for (let dx = 0; dx < w; dx++) {
                grid[y + dy][x + dx] = false; // Mark the grid space as occupied
            }
        }
    }

    //place components first
    for (let component of components) {
        let { x, y, w, h } = component.dataGrid;
        placeComponent(x, y, w, h);
    }

    // fill in the gaps with false
    for (let i = 0; i < grid.length; i++) {
        if (!grid[i]) {
            grid[i] = [];
        }
        for (let j = 0; j < GRID_WIDTH; j++) {
            if (!grid[i][j]) {
                grid[i][j] = false;
            }
        }
    }

    // Iterate through each component and place it on the grid
    for (let component of components) {
        let { x, y, w, h } = component.dataGrid;
        if (!canFit(x, y, w, h)) {
            fits = false;
        }
    }

    return fits; // If all components fit, return true
}

export function fitComponents(components) {
    const GRID_WIDTH = 12; // Define the grid width
    let grid = []; // Initialize the grid

    // Function to find the first suitable position for a component
    function findPosition(minW, maxW, minH, maxH) {
        for (let y = 0; ; y++) {
            for (let x = 0; x <= GRID_WIDTH - minW; x++) {
                // Attempt to fit the component within the constraints
                let validWidth = maxW;
                for (let testW = minW; testW <= maxW; testW++) {
                    if (canFit(x, y, testW, minH)) {
                        validWidth = testW;
                        break; // Found a suitable width that fits
                    }
                }

                if (validWidth >= minW && canFit(x, y, validWidth, minH)) {
                    return { x, y, w: validWidth, h: minH }; // Return the position and size
                }
            }
        }
    }

    // Check if the component can fit in the specified location
    function canFit(x, y, w, h) {
        for (let dy = 0; dy < h; dy++) {
            for (let dx = 0; dx < w; dx++) {
                if (
                    (grid[y + dy] && grid[y + dy][x + dx]) ||
                    x + dx >= GRID_WIDTH
                ) {
                    return false; // Space is already occupied or exceeds grid width
                }
            }
        }
        return true; // The space is available
    }

    // Place the component on the grid
    function placeComponent(x, y, w, h) {
        for (let dy = 0; dy < h; dy++) {
            if (!grid[y + dy]) {
                grid[y + dy] = [];
            }
            for (let dx = 0; dx < w; dx++) {
                grid[y + dy][x + dx] = true; // Mark the grid space as occupied
            }
        }
    }

    // Process each component to fit into the grid
    components.forEach((component) => {
        let { minW, maxW, minH, maxH } = component.dataGrid;
        let pos = findPosition(minW, maxW, minH, maxH);
        component.dataGrid.x = pos.x;
        component.dataGrid.y = pos.y;
        component.dataGrid.w = pos.w;
        component.dataGrid.h = pos.h;
        placeComponent(pos.x, pos.y, pos.w, pos.h);
    });

    return components;
}
