export const regionGeoSalesMock = {
    hoverStyle: '',
    desc: '',
    total: 147,
    revenue: 30000,
    percentage: 47,
    percentageGrowth: 0.6,
    tooltipType: 'regionGeoSales',
    dataList: [],
    titleText: '',
    attr: 'regionGeoSales',
    loading: false,
    legend: [
        {
            name: '0 - €1.6K',
            color: '#006BA6',
            translated: true,
        },
        {
            name: '1.6K - €94.6K',
            color: '#0496FF',
            translated: true,
        },
        {
            name: '94.6K - €187.5K',
            color: '#FFBC42',
            translated: true,
        },
        {
            name: '187.5K - €280.5K',
            color: '#D81159',
            translated: true,
        },
        {
            name: '280.5K - €373.4K',
            color: '#8F2D56',
            translated: true,
        },
    ],
    type: 'normal',
    update: 1,
    disabled: true,
    startingPosition: {
        lat: 38.769688599999995,
        lon: -9.240822000000001,
        zoom: 7,
    },
    language: 'pt',
    minScale: 2,
    scale: 2,
    minZoom: 9,
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 38.736946,
        longitude: -9.142685,
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
    },
    interactiondisabled: true,
    showPopup: false,
    selectedEl: {},
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
        viewOptions: {
            ratioOptions: {
                options: [],
                selectedOption: '',
            },
            checkboxOptions: {
                options: [],
                selectedOptions: [],
            },
        },
    },
    ready: true,
    data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    color: '#D51259',
                    name: 'AC001 S Miguel, S Maria, Terceira, Graciosa, S Jorge',
                    value: 284830.93,
                    lat: 38.7216415,
                    lon: -27.2205771,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-27.2205771, 38.7216415],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0280D1',
                    name: 'AC002 Faial,Pico,Flores,Corvo',
                    value: 46125.04,
                    lat: 38.5912552,
                    lon: -28.6964752,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-28.6964752, 38.5912552],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D6',
                    name: 'AV001 Espinho',
                    value: 52190.15,
                    lat: 41.0071914,
                    lon: -8.641033199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.641033199999999, 41.0071914],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038DEC',
                    name: 'AV002 Feira 1 (Feira, Souto)',
                    value: 74579.33,
                    lat: 39.5757716,
                    lon: -8.2374798,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2374798, 39.5757716],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCC',
                    name: 'AV003 Feira 3 (Argoncilhe, Fiaes, Lobao)',
                    value: 41635.34,
                    lat: 41.0191312,
                    lon: -8.544635999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.544635999999999, 41.0191312],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B7',
                    name: 'AV004 Castelo de Paiva',
                    value: 19045.35,
                    lat: 41.0409444,
                    lon: -8.2714736,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2714736, 41.0409444],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B3',
                    name: 'AV005 Oliveira de Azemeis 1(Santiago de Riba-Ul, Sao Martinho da Gandara, Vila de Cucujaes)',
                    value: 14953.55,
                    lat: 40.8527028,
                    lon: -8.489734499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.489734499999999, 40.8527028],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017AC5',
                    name: 'AV006 S Joao da Madeira',
                    value: 34144.11,
                    lat: 40.90052379999999,
                    lon: -8.490671599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.490671599999999, 40.90052379999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'AV007 Ovar 1(Arada, Cortegaca, Esmoriz, Maceda)',
                    value: 25870.87,
                    lat: 40.945082,
                    lon: -8.622563,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.622563, 40.945082],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'AV008 Ovar 2 (Ovar)',
                    value: 27420.45,
                    lat: 40.8596399,
                    lon: -8.6253313,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6253313, 40.8596399],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0280D2',
                    name: 'AV009 Murtosa, Estarreja',
                    value: 48080.19,
                    lat: 40.7528385,
                    lon: -8.5709856,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5709856, 40.7528385],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038AE5',
                    name: 'AV010 Vale de Cambra, Sever do Vouga, OAzemeis 2(Cesar, Nog Cravo, Carregosa, Pindelo, S Roque)',
                    value: 67982.98,
                    lat: 40.83985699999999,
                    lon: -8.481836999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.481836999999999, 40.83985699999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'AV011 Agueda 1 (Agueda)',
                    value: 21834.25,
                    lat: 40.5754246,
                    lon: -8.446436799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.446436799999999, 40.5754246],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038CEB',
                    name: 'AV012 Agueda 2, Obairro (Aguada de Cima, Macinhata do Vouga, Oliveira do Bairro)',
                    value: 73489.44,
                    lat: 40.577358,
                    lon: -8.448443,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.448443, 40.577358],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0494FB',
                    name: 'AV013 Aveiro 1 (Vera Cruz, Gloria)',
                    value: 90797.19,
                    lat: 40.6408386,
                    lon: -8.648259399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.648259399999999, 40.6408386],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCB',
                    name: 'AV014 Ilhavo (Gafanha, Salvador)',
                    value: 40092.25,
                    lat: 40.6363737,
                    lon: -8.7004614,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7004614, 40.6363737],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'AV015 Vagos (Vagos, Calvao)',
                    value: 29542.9,
                    lat: 40.4836591,
                    lon: -8.7095789,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7095789, 40.4836591],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B8',
                    name: 'AV016 Anadia 1 (Tamengo, Avelas de Caminho, Avelas de Cima, Paredes do Bairro)',
                    value: 20011.27,
                    lat: 40.4836974,
                    lon: -8.4170685,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4170685, 40.4836974],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B4',
                    name: 'AV017 Anadia 2 (Arcos)',
                    value: 16441.15,
                    lat: 40.44783959999999,
                    lon: -8.4409028,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4409028, 40.44783959999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C2',
                    name: 'AV018 Mealhada (Mealhada, Barcouco)',
                    value: 30488.62,
                    lat: 40.3807921,
                    lon: -8.449581400000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.449581400000001, 40.3807921],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'AV019 Aveiro 2(Oliverinha, Aradas, Santa Joana, Sao Bernardo)',
                    value: 32370.38,
                    lat: 40.6188083,
                    lon: -8.624348,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.624348, 40.6188083],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017AC5',
                    name: 'AV020 Feira 2 (Lourosa, P Brandao, SM Lamas, Nogueira da Regedoura, Sao Paio de Oleiros, Mozelos)',
                    value: 33556.72,
                    lat: 40.9780231,
                    lon: -8.5725509,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5725509, 40.9780231],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCB',
                    name: 'AV021 Feira 4(Escapaes, Rio Meao, Sao Joao de Ver)',
                    value: 40606.39,
                    lat: 40.9557367,
                    lon: -8.588585799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.588585799999999, 40.9557367],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'AV022 Arouca',
                    value: 25714.59,
                    lat: 40.9312766,
                    lon: -8.237704899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.237704899999999, 40.9312766],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D6',
                    name: 'AV023 Oliveira de Azemeis 3(O Azemeis, P Bemposta, Loureiro, Ossela)',
                    value: 51480.51,
                    lat: 40.8505259,
                    lon: -8.434573199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.434573199999999, 40.8505259],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C0',
                    name: 'AV024 Albergaria-a-Velha',
                    value: 29000.62,
                    lat: 40.6894236,
                    lon: -8.4796655,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4796655, 40.6894236],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B5',
                    name: 'AV025 Ovar 3(Valega, Sao Vicente de Pereira Jusa, Sao Joao)',
                    value: 17238.6,
                    lat: 40.8656705,
                    lon: -8.5364829,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5364829, 40.8656705],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BE',
                    name: 'AV026 Aveiro 3(Cacia, Esgueira, Eixo)',
                    value: 27055.88,
                    lat: 40.6405055,
                    lon: -8.6537539,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6537539, 40.6405055],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D6',
                    name: 'BG001 Macedo Cavaleiros, Miranda do Douro, Vimioso, Vinhais',
                    value: 51360.19,
                    lat: 41.5844451,
                    lon: -6.529121099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-6.529121099999999, 41.5844451],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038CEB',
                    name: 'BG002 Braganca',
                    value: 73662.12,
                    lat: 41.8061131,
                    lon: -6.756737999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-6.756737999999999, 41.8061131],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECD',
                    name: 'BG003 Mirandela',
                    value: 42615.38,
                    lat: 41.4853545,
                    lon: -7.180871100000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.180871100000001, 41.4853545],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BC',
                    name: 'BG004 Carrazeda Ansiaes, Vila Flor, Alfandega da Fe',
                    value: 25116.97,
                    lat: 41.344363,
                    lon: -6.9601807,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-6.9601807, 41.344363],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'BG005 Mogadouro, Freixo de Espada Cinta, Torre de Moncorvo',
                    value: 25945.88,
                    lat: 41.1856059,
                    lon: -7.0498442,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.0498442, 41.1856059],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B6',
                    name: 'BJ001 Alvito, Cuba, Vidigueira, Moura01(Santo Agostinho, Sao Joao Baptista)',
                    value: 18009.49,
                    lat: 38.2118842,
                    lon: -7.8005203,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.8005203, 38.2118842],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BC',
                    name: 'BJ002 Serpa',
                    value: 24856.12,
                    lat: 37.9469642,
                    lon: -7.602181900000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.602181900000001, 37.9469642],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D7',
                    name: 'BJ003 Beja (Beringel, Salvador, Santa Maria da Feira)',
                    value: 52495.14,
                    lat: 38.018056,
                    lon: -7.859999999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.859999999999999, 38.018056],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAD',
                    name: 'BJ004 Ferreira do Alentejo',
                    value: 9384.11,
                    lat: 38.0593873,
                    lon: -8.114071299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.114071299999999, 38.0593873],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027BC8',
                    name: 'BJ005 Odemira (Vila Nova de Milfontes, Colos), Ourique, Castro Verde',
                    value: 37091.43,
                    lat: 37.7239951,
                    lon: -8.7817132,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7817132, 37.7239951],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAB',
                    name: 'BJ006  Moura 2, Barrancos',
                    value: 7092.87,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0070B0',
                    name: 'BJ007  Almodovar, Mertola',
                    value: 12624.87,
                    lat: 37.6415148,
                    lon: -7.6606665,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6606665, 37.6415148],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAC',
                    name: 'BJ008 Aljustrel',
                    value: 8318.47,
                    lat: 37.8760582,
                    lon: -8.1649833,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1649833, 37.8760582],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCC',
                    name: 'BR001 Esposende (Esposende, Marinhas »)',
                    value: 41260.88,
                    lat: 41.5323363,
                    lon: -8.783007099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.783007099999999, 41.5323363],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#1498F3',
                    name: 'BR002 Barcelos 1 (Alheira, Arcozelo, Barcelinhos, Macieira de Rates »)',
                    value: 100695.58,
                    lat: 41.5410747,
                    lon: -8.6124835,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6124835, 41.5410747],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C0',
                    name: 'BR003 Barcelos 2 (Vila Cova, Barqueiros, Vila Seca, Carapecos, Perelhal)',
                    value: 29246.37,
                    lat: 41.5318724,
                    lon: -8.6939738,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6939738, 41.5318724],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DBB75D',
                    name: 'BR004 Amares, Braga 1 (Sao Victor, Lamacaes), Vila Verde 1 (Vila Verde »)',
                    value: 174318.63,
                    lat: 41.5457315,
                    lon: -8.3950816,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.3950816, 41.5457315],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BE',
                    name: 'BR005 Vila Verde 2 (Cervaes, Prado)',
                    value: 27246.51,
                    lat: 41.5986139,
                    lon: -8.5226706,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5226706, 41.5986139],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0284D9',
                    name: 'BR006 Povoa de Lanhoso, Terras de Bouro, Vieira do Minho',
                    value: 54723.14,
                    lat: 41.7167409,
                    lon: -8.306244399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.306244399999999, 41.7167409],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCB',
                    name: 'BR007 Braga 2 (Celeiros, Maximinos, Real »)',
                    value: 40148.95,
                    lat: 41.5117597,
                    lon: -8.4508236,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4508236, 41.5117597],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC7',
                    name: 'BR008 Braga 3 (Se, Mire de Tibaes, Palmeira, Sao Pedro Merelim,Sao Jose de Sao Lazaro, Sao Vicente)',
                    value: 36201.42,
                    lat: 41.5653733,
                    lon: -8.4778465,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4778465, 41.5653733],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'BR009 Famalicao 1(Santa Maria Oliveira, Bairro, Delaes, Riba de Ave)',
                    value: 31643.55,
                    lat: 41.40588959999999,
                    lon: -8.3967334,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.3967334, 41.40588959999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D1B565',
                    name: 'BR010 Famalicao 2 (Joane,Nine),Guimaraes1(Azurem,Brito,Caldelas,SPaio,STorcato,OCastelo,Serzedo »)',
                    value: 170475.59,
                    lat: 41.4515701,
                    lon: -8.2861917,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2861917, 41.4515701],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#76A7A9',
                    name: 'BR011 Guimaraes 2 (Creixomil, Moreira de Conegos), Vizela (Vizela)',
                    value: 137007.07,
                    lat: 41.4377038,
                    lon: -8.302363999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.302363999999999, 41.4377038],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0386DE',
                    name: 'BR012 Fafe',
                    value: 59867.17,
                    lat: 41.4508217,
                    lon: -8.1728619,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1728619, 41.4508217],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D7',
                    name: 'BR013 Barcelos 3 (Barcelos)',
                    value: 52617.55,
                    lat: 41.5335052,
                    lon: -8.619289199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.619289199999999, 41.5335052],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0797FC',
                    name: 'BR014 Braga 4 (Sao Jose de Sao Lazaro, Sao Joao do Souto)',
                    value: 95898.44,
                    lat: 41.5441376,
                    lon: -8.4211639,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4211639, 41.5441376],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#359DDA',
                    name: 'BR015 Famalicao 3(Ribeir,Fradel,Lousad,Calend,Caval,Gondif,Louro,Antas,Gav,VNFamal,Land,Verm,Ruiva)',
                    value: 112914.76,
                    lat: 41.4116818,
                    lon: -8.5224051,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5224051, 41.4116818],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B6',
                    name: 'BR016 Cabeceiras de Basto',
                    value: 18715.46,
                    lat: 41.5116939,
                    lon: -7.9979753,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9979753, 41.5116939],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'BR017 Celorico de Basto',
                    value: 25369.2,
                    lat: 41.3889737,
                    lon: -7.999703599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.999703599999999, 41.3889737],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038AE7',
                    name: 'CB001 Covilha 1 (Casegas, Sao Martinho, Sao Pedro »)',
                    value: 69285.04,
                    lat: 40.177914,
                    lon: -7.6985738,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6985738, 40.177914],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCC',
                    name: 'CB002 Covilha 2 (Ferro, Teixoso, Tortosendo, Vila do Carvalho), Belmonte',
                    value: 41485.74,
                    lat: 40.315611,
                    lon: -7.4976536,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4976536, 40.315611],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CCA',
                    name: 'CB003 CBranco 1 (Alcains, Lardosa, Castelo Branco - N »), VVRodao  (Cebolais de Cima »)',
                    value: 38883.24,
                    lat: 39.9163551,
                    lon: -7.4489531,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4489531, 39.9163551],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0285DB',
                    name: 'CB004 CBranco 2 (Castelo Branco - S), Idanha-a-Nova, Penamacor',
                    value: 56977.16,
                    lat: 39.924752,
                    lon: -7.2415895,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.2415895, 39.924752],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027FCF',
                    name: 'CB005 Oleiros, Proenca-a-Nova, Serta, Vila de Rei',
                    value: 44190.36,
                    lat: 39.7492692,
                    lon: -7.924331400000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.924331400000001, 39.7492692],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CC9',
                    name: 'CB006 Fundao',
                    value: 38258.21,
                    lat: 40.137963,
                    lon: -7.501077299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.501077299999999, 40.137963],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAC',
                    name: 'CO001 Cantanhede 1 (Sepins, Vilamar, Murtede, Febres, Covoes)',
                    value: 7568.97,
                    lat: 40.3880011,
                    lon: -8.500711599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.500711599999999, 40.3880011],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0281D4',
                    name: 'CO002 Cantanhede 2 (Anca, Cadima, Tocha, Cantanhede)',
                    value: 50152.49,
                    lat: 40.3257161,
                    lon: -8.6407177,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6407177, 40.3257161],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B7',
                    name: 'CO003 Penacova',
                    value: 19020.88,
                    lat: 40.2704883,
                    lon: -8.28054,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.28054, 40.2704883],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0386DE',
                    name: 'CO004 Tabua, Arganil, Oliveira do Hospital',
                    value: 59876.6,
                    lat: 40.218499,
                    lon: -8.0537909,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.0537909, 40.218499],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B5B179',
                    name: 'CO006 Coimbra 1 (Eiras, Santa Cruz, Santo Antonio dos Olivais-N)',
                    value: 160318.04,
                    lat: 40.2172968,
                    lon: -8.4023333,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4023333, 40.2172968],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC7',
                    name: 'CO007 Coimbra 2 (Ceira, Santo Antonio dos Olivais - S)',
                    value: 35900.83,
                    lat: 40.2172968,
                    lon: -8.4023333,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4023333, 40.2172968],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C0',
                    name: 'CO008 Coimbra 3 (Santa Clara, Se Nova »)',
                    value: 29051.24,
                    lat: 40.2069771,
                    lon: -8.415379399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.415379399999999, 40.2069771],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038EEE',
                    name: 'CO009 Coimbra 4 (São Martinho do Bispo, Trouxemil »)',
                    value: 76444.26,
                    lat: 40.2107568,
                    lon: -8.4564966,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4564966, 40.2107568],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B7',
                    name: 'CO010 Coimbra 5 (Eiras »)',
                    value: 19905.81,
                    lat: 41.1337843,
                    lon: -8.4223704,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4223704, 41.1337843],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'CO011 Coimbra 6 (Santa Cruz, Sao Bartolomeu, Se Nova)',
                    value: 24000.06,
                    lat: 40.21481259999999,
                    lon: -8.4342267,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4342267, 40.21481259999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027BC8',
                    name: 'CO012 Mont Velho (Montemor-o-Velho, Arazede »)',
                    value: 36714.99,
                    lat: 40.2817719,
                    lon: -8.6522166,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6522166, 40.2817719],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D7',
                    name: 'CO013 FFoz 1 (Alhadas, Alqueidao, Buarcos »)',
                    value: 52636.42,
                    lat: 40.1682124,
                    lon: -8.8816732,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8816732, 40.1682124],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D8',
                    name: 'CO014 FFoz 2 (Marinha das Ondas, Sao Juliao (Figueira da Foz) »)',
                    value: 54242.9,
                    lat: 40.15084359999999,
                    lon: -8.8555736,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8555736, 40.15084359999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C2',
                    name: 'CO015 Soure (Soure, Vila da Rainha »)',
                    value: 31230.53,
                    lat: 40.0629162,
                    lon: -8.7270068,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7270068, 40.0629162],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'CO016 Condeixa-a-Nova',
                    value: 25479.18,
                    lat: 40.1141535,
                    lon: -8.498116399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.498116399999999, 40.1141535],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECD',
                    name: 'CO017 Miranda do Corvo, Penela, Lousa, VN de Poiares',
                    value: 42668.63,
                    lat: 40.2107667,
                    lon: -8.25907,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.25907, 40.2107667],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAF',
                    name: 'CO018 Pampilhosa da Serra, Gois',
                    value: 11011.73,
                    lat: 40.0460146,
                    lon: -7.951996099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.951996099999999, 40.0460146],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B5',
                    name: 'CO019 Mira',
                    value: 17132.24,
                    lat: 40.4287977,
                    lon: -8.738126099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.738126099999999, 40.4287977],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0070B1',
                    name: 'EV001 Mora, Arraiolos',
                    value: 13088.09,
                    lat: 38.723479,
                    lon: -7.9863851,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9863851, 38.723479],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027BC8',
                    name: 'EV002 Estremoz, Borba, Vila Vicosa, Alandroal, Redondo',
                    value: 36875.17,
                    lat: 38.7021331,
                    lon: -7.4036488,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4036488, 38.7021331],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D5',
                    name: 'EV003 Evora 1 (Santo Antao, Sao Mamede, Evora (Se e Sao Pedro), Senhora da Saude »)',
                    value: 51111.18,
                    lat: 38.6351836,
                    lon: -7.855835700000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.855835700000001, 38.6351836],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CC9',
                    name: 'EV004 Montemor-o-Novo, Vendas Novas',
                    value: 37860.19,
                    lat: 38.64708,
                    lon: -8.2178641,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2178641, 38.64708],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'EV005 Viana do Alentejo, Portel, Reng Monsaraz, Mourao',
                    value: 28178.06,
                    lat: 38.4518533,
                    lon: -7.3840729,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.3840729, 38.4518533],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D8',
                    name: 'EV006 Evora 2 (Malagueira, Santo Antao, Sao Mamede,Se e Sao Pedro, Santo Antao)',
                    value: 53722.14,
                    lat: 38.5702579,
                    lon: -7.9221795,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9221795, 38.5702579],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAD',
                    name: 'FR001 Aljezur, Monchique',
                    value: 8508.79,
                    lat: 37.3123601,
                    lon: -8.6713298,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6713298, 37.3123601],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'FR002 Lagos, Vila do Bispo',
                    value: 23586.64,
                    lat: 37.0829204,
                    lon: -8.9121095,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9121095, 37.0829204],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0495FC',
                    name: 'FR003 Portimao (Portimao, Alvor, Mexilhoeira Grande)',
                    value: 91854.31,
                    lat: 37.130505,
                    lon: -8.5935063,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5935063, 37.130505],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027FD0',
                    name: 'FR004 Lagoa, Silves (Silves,Armacao Pera,Alcantarilha,Algoz,S Bartolomeu Messines,S Marcos Serra,»)',
                    value: 45728.22,
                    lat: 37.3592972,
                    lon: -8.376360199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.376360199999999, 37.3592972],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038AE5',
                    name: 'FR005 Loule',
                    value: 67921.32,
                    lat: 37.1379187,
                    lon: -8.020216,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.020216, 37.1379187],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC7',
                    name: 'FR006 Albufeira',
                    value: 35624.05,
                    lat: 37.0890719,
                    lon: -8.2478796,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2478796, 37.0890719],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0281D4',
                    name: 'FR007 Faro 1(Conceicao, Montenegro, Sao Pedro, Se)',
                    value: 49894.88,
                    lat: 37.0193548,
                    lon: -7.9304397,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9304397, 37.0193548],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D6',
                    name: 'FR008 Olhao',
                    value: 51440.66,
                    lat: 37.0260416,
                    lon: -7.8423509,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.8423509, 37.0260416],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC7',
                    name: 'FR009 Faro 2(Estoi,S Bras Alportel,Se),Tavira 1(Sta Luzia,Conceicao,Luz,Santa Catarina Fonte Bispo)',
                    value: 36092.13,
                    lat: 37.1335906,
                    lon: -7.643001899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.643001899999999, 37.1335906],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'FR010 VR St Antonio, Alcoutim, Castro Marim',
                    value: 29816.72,
                    lat: 37.2179422,
                    lon: -7.4423339,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4423339, 37.2179422],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0387E1',
                    name: 'FR011 Faro 3(Sao Pedro, Se)',
                    value: 62898.89,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAD',
                    name: 'FR012 Tavira 2(Santa Maria Tavira, Santiago Tavira)',
                    value: 9125.41,
                    lat: 37.1248493,
                    lon: -7.652046399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.652046399999999, 37.1248493],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B8',
                    name: 'GR001 Meda, Trancoso, VN Foz Coa',
                    value: 20514.36,
                    lat: 41.0822466,
                    lon: -7.1403598,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.1403598, 41.0822466],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B4',
                    name: 'GR002 Figueira Castelo Rodrigo, Pinhel',
                    value: 16331.36,
                    lat: 40.7732736,
                    lon: -7.0685935,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.0685935, 40.7732736],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B7',
                    name: 'GR003 Aguiar da Beira, Fornos de Algodres, Celorico da Beira',
                    value: 19778.78,
                    lat: 40.6335005,
                    lon: -7.3927217,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.3927217, 40.6335005],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038BE8',
                    name: 'GR004 Guarda (Goncalo, Sao Vicente, Trinta, Se)',
                    value: 70163.44,
                    lat: 40.5408397,
                    lon: -7.2560056,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.2560056, 40.5408397],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B3',
                    name: 'GR005 Gouveia, Manteigas',
                    value: 15643.64,
                    lat: 40.4022206,
                    lon: -7.538339300000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.538339300000001, 40.4022206],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'GR006 Seia (Seia, Vide, Loriga, Sao Romao, Torrozelo)',
                    value: 31794.72,
                    lat: 40.4148619,
                    lon: -7.707845000000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.707845000000001, 40.4148619],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B8',
                    name: 'GR007 Sabugal, Almeida',
                    value: 20964.42,
                    lat: 40.3516608,
                    lon: -7.089524099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.089524099999999, 40.3516608],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038EEF',
                    name: 'LR001 Pombal 1 (Guia, Almagreira, Pombal »)',
                    value: 78381.65,
                    lat: 39.9918999,
                    lon: -8.6620705,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6620705, 39.9918999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'LR002 Pombal 2 (Albergaria dos Doze, Carnide »)',
                    value: 23761.53,
                    lat: 39.7991856,
                    lon: -8.5820059,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5820059, 39.7991856],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0284DA',
                    name: 'LR003 Ansiao, Alvaiazere, Figueiro dos Vinhos, Castanheira Pera, Pedrogao Grande)',
                    value: 56411.42,
                    lat: 39.917835,
                    lon: -8.1458099,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1458099, 39.917835],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#47A0CC',
                    name: 'LR004 Leiria 1 (Monte Real, Sta Catarina da Serra, Barreira, Memoria »)',
                    value: 119544.94,
                    lat: 39.8412644,
                    lon: -8.8748765,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8748765, 39.8412644],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027BC8',
                    name: 'LR005 Leiria 2 (Marrazes)',
                    value: 37338.61,
                    lat: 39.763454,
                    lon: -8.805053899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.805053899999999, 39.763454],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECC',
                    name: 'LR006 Leiria 3 (Leiria, Maceira)',
                    value: 41826.17,
                    lat: 39.6823024,
                    lon: -8.8786256,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8786256, 39.6823024],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'LR007 Leiria 4 (Leiria, Barosa, Amor)',
                    value: 28164.12,
                    lat: 39.74953310000001,
                    lon: -8.807682999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.807682999999999, 39.74953310000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCA',
                    name: 'LR008 Marinha Grande',
                    value: 39505.11,
                    lat: 39.7503802,
                    lon: -8.931805899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.931805899999999, 39.7503802],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'LR009 Batalha, Porto de Mos 1(Mira de Aire, Alqueidao da Serra)',
                    value: 21690.44,
                    lat: 39.5411755,
                    lon: -8.715083100000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.715083100000001, 39.5411755],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'LR010 Nazare',
                    value: 24090.53,
                    lat: 39.6012147,
                    lon: -9.0700991,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0700991, 39.6012147],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0285DD',
                    name: 'LR011 Alcobaca 1 (Aljubarrota (Prazeres), Barrio, Pataias, Alcobaca)',
                    value: 58687.67,
                    lat: 39.5489981,
                    lon: -8.9767266,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9767266, 39.5489981],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BC',
                    name: 'LR012 Alcobaca 2 (Benedita, Turquel, Alfeizerao, S Martinho Porto, Vimeiro)',
                    value: 24521.04,
                    lat: 39.1782666,
                    lon: -9.3169929,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3169929, 39.1782666],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#45A0CE',
                    name: 'LR013 Caldas Rainha (Vidais, A dos Francos, Foz do Arelho, Sto Onofre, N Sra Populo »)',
                    value: 118547.06,
                    lat: 39.4030187,
                    lon: -9.152151,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.152151, 39.4030187],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'LR015 Obidos, Bombarral',
                    value: 23757.14,
                    lat: 39.2680991,
                    lon: -9.1585113,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1585113, 39.2680991],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C0',
                    name: 'LR016 Peniche',
                    value: 28844.34,
                    lat: 39.3603291,
                    lon: -9.387622,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.387622, 39.3603291],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017AC4',
                    name: 'LR017 Porto de Mos 2(Calvaria de Cima, Juncal, Sao Pedro, Serro Ventoso)',
                    value: 33320.71,
                    lat: 39.6018396,
                    lon: -8.900817199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.900817199999999, 39.6018396],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'LX001 Lourinha',
                    value: 27547.97,
                    lat: 39.2414853,
                    lon: -9.3129511,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3129511, 39.2414853],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'LX002 Cadaval, Azambuja',
                    value: 31973.99,
                    lat: 39.2433402,
                    lon: -9.1027805,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1027805, 39.2433402],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027FCF',
                    name: 'LX003 Alenquer (Abrigada, Aldeia Galega da Merceana, Carregado »)',
                    value: 44046.24,
                    lat: 39.0820899,
                    lon: -9.1125693,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1125693, 39.0820899],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0070B1',
                    name: 'LX004 T Vedras 1 (Silveira, A dos Cunhados, Ponte do Rol, Santa Maria e Sao Miguel)',
                    value: 12694.58,
                    lat: 39.0979879,
                    lon: -9.3173746,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3173746, 39.0979879],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#269BE5',
                    name: 'LX005 T Vedras 2 (Sao Pedro e Sao Tiago, Campelos, Maxial »)',
                    value: 107198.83,
                    lat: 39.0936028,
                    lon: -9.2572667,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2572667, 39.0936028],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCC',
                    name: 'LX006 Mafra 1(Igreja Nova, Mafra, Sobral da Abelheira, Encarnacao, »), Ericeira',
                    value: 41341.85,
                    lat: 38.9664933,
                    lon: -9.4176169,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.4176169, 38.9664933],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0387E0',
                    name: 'LX007 VF Xira 1 (Castanheira do Ribatejo, VF Xira, Alhandra)',
                    value: 62335.87,
                    lat: 38.9273718,
                    lon: -9.009137,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.009137, 38.9273718],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0387E0',
                    name: 'LX008 VF Xira 2 (Alverca do Ribatejo, Povoa de Sta Iria, Vialonga)',
                    value: 62339.28,
                    lat: 38.8615606,
                    lon: -9.0633125,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0633125, 38.8615606],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'LX009 Loures 1 (Portela, Moscavide)',
                    value: 25311.53,
                    lat: 38.7819922,
                    lon: -9.1082647,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1082647, 38.7819922],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D7',
                    name: 'LX010 Loures 2 (Prior Velho, Sacavem)',
                    value: 52370.6,
                    lat: 38.7957214,
                    lon: -9.1151801,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1151801, 38.7957214],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0284DA',
                    name: 'LX011 Loures 3 (Camarate, Santo Antonio de Cavaleiros, Santa Iria de Azoia »)',
                    value: 55646.03,
                    lat: 38.8137476,
                    lon: -9.1640362,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1640362, 38.8137476],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0491F6',
                    name: 'LX012 Loures 4 (Lousa, Bucelas, Fanhoes, Sto Antonio do Tojal, Loures)',
                    value: 84857.13,
                    lat: 38.8615627,
                    lon: -9.1506447,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1506447, 38.8615627],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006CA8',
                    name: 'LX013 Odivelas 1 (Pontinha)',
                    value: 3928.45,
                    lat: 38.7779317,
                    lon: -9.2092897,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2092897, 38.7779317],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038BE8',
                    name: 'LX014 Odivelas 2 (Olival Basto, Povoa de Santo Adriao, Odivelas)',
                    value: 70660.39,
                    lat: 38.7912926,
                    lon: -9.1633678,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1633678, 38.7912926],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038FF1',
                    name: 'LX015 Amadora 1 (Alfragide, Buraca, Damaia, Reboleira, Venteira, Falagueira)',
                    value: 79640.43,
                    lat: 38.7471779,
                    lon: -9.2197802,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2197802, 38.7471779],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'LX016 Amadora 2 (Brandoa)',
                    value: 21100.8,
                    lat: 38.7683384,
                    lon: -9.2173458,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2173458, 38.7683384],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#389ED8',
                    name: 'LX017 Amadora 3 (Venteira, Mina)',
                    value: 113776.26,
                    lat: 38.758785,
                    lon: -9.2437861,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2437861, 38.758785],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177C0',
                    name: 'LX018 Sintra 1 (Belas, Casal de Cambra,  Monte Abraao)',
                    value: 28403.11,
                    lat: 38.8026373,
                    lon: -9.2278328,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2278328, 38.8026373],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#72A7AC',
                    name: 'LX019 Sintra 2 (Colares,SJLampas,Terrug,AlmBispo,Montelav,PPinheiro,SPPenafer,SMiguel,AlgMMartins»)',
                    value: 135340.62,
                    lat: 38.8031844,
                    lon: -9.449783,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.449783, 38.8031844],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B4',
                    name: 'LX020 Sintra 3 (Queluz)',
                    value: 16501.28,
                    lat: 38.7573981,
                    lon: -9.2587453,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2587453, 38.7573981],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#3B9ED5',
                    name: 'LX021 Sintra 4 (Rio de Mouro, Cacem, Sao Marcos, Agualva, Mira-Sintra, Massama)',
                    value: 115068.84,
                    lat: 38.784473,
                    lon: -9.321642,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.321642, 38.784473],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BBB275',
                    name: 'LX022 Cascais 1 (Alcabideche, Cascais, Estoril)',
                    value: 162438.19,
                    lat: 38.7133152,
                    lon: -9.3936558,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3936558, 38.7133152],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CC9',
                    name: 'LX023 Cascais 2 (Sao Domingos de Rana)',
                    value: 38301.88,
                    lat: 38.7123876,
                    lon: -9.3389678,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3389678, 38.7123876],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAF',
                    name: 'LX024 Cascais 3 (Carcavelos)',
                    value: 10628.47,
                    lat: 38.6879164,
                    lon: -9.3378114,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3378114, 38.6879164],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177C0',
                    name: 'LX025 Oeiras 1 (Oeiras-W e Sao Juliao Barra)',
                    value: 28369.97,
                    lat: 38.6920034,
                    lon: -9.3186852,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3186852, 38.6920034],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177C0',
                    name: 'LX026 Oeiras 2 (Barcarena, Paco de Arcos, Porto Salvo)',
                    value: 28491.25,
                    lat: 38.696797,
                    lon: -9.2915896,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2915896, 38.696797],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0285DC',
                    name: 'LX027 Oeiras 3 (Carnaxide, Queijas)',
                    value: 58430.75,
                    lat: 38.7238227,
                    lon: -9.23699,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.23699, 38.7238227],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'LX028 Oeiras 4 (Linda a Velha)',
                    value: 21470.87,
                    lat: 38.7149733,
                    lon: -9.2416491,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2416491, 38.7149733],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0280D2',
                    name: 'LX029 Lisboa 1 (Sta Maria de Belem, S Francisco de Xavier)',
                    value: 47287.1,
                    lat: 38.6991685,
                    lon: -9.2203687,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2203687, 38.6991685],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0284D9',
                    name: 'LX030 Lisboa 2 (Ajuda, Alcantara, Sta Maria de Belem)',
                    value: 55449.25,
                    lat: 38.703621,
                    lon: -9.1956117,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1956117, 38.703621],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAC',
                    name: 'LX031 Lisboa 3 (Alcantara-N)',
                    value: 7755.96,
                    lat: 38.7059957,
                    lon: -9.1827535,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1827535, 38.7059957],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0285DB',
                    name: 'LX032 Lisboa 4 (Lapa, Prazeres, Santos-o-Velho, Sao Paulo, Prazeres, Lapa)',
                    value: 57290.6,
                    lat: 38.709909,
                    lon: -9.1610726,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1610726, 38.709909],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038BE8',
                    name: 'LX033 Lisboa 5 (Sta Isabel, Coracao de Jesus, S Mamede, S Sebastiao da Pedreira)',
                    value: 70347.58,
                    lat: 38.7244377,
                    lon: -9.146999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.146999, 38.7244377],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'LX034 Lisboa 6 (Merces, Sao Mamede, Coracao de Jesus »)',
                    value: 23354.72,
                    lat: 38.7188082,
                    lon: -9.152534,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.152534, 38.7188082],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0070B1',
                    name: 'LX035 Lisboa 7 (Anjos, Pena)',
                    value: 13238.95,
                    lat: 38.7263148,
                    lon: -9.1349164,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1349164, 38.7263148],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B8',
                    name: 'LX036 Lisboa 8 (Sao Jorge de Arroios, Penha de Franca, Sao Joao)',
                    value: 20509.66,
                    lat: 38.7259582,
                    lon: -9.1276295,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1276295, 38.7259582],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006CA8',
                    name: 'LX037 Lisboa 9 (Madalena, Sao Nicolau, Sta Justa)',
                    value: 4075.01,
                    lat: 38.7222524,
                    lon: -9.1393366,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1393366, 38.7222524],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017AC5',
                    name: 'LX038 Lisboa 10 (Sta Justa, Socorro, Santiago, Sto Estevao, S Miguel »)',
                    value: 34441.21,
                    lat: 38.7124614,
                    lon: -9.1379767,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1379767, 38.7124614],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECC',
                    name: 'LX039 Lisboa 11 (Campolide, Campolide, Sao Domingos de Benfica)',
                    value: 41815.6,
                    lat: 38.7258789,
                    lon: -9.1627085,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1627085, 38.7258789],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0494FA',
                    name: 'LX040 Lisboa 12 (Sao Sebastiao da Pedreia, Sao Jorge de Arroios)',
                    value: 89504.43,
                    lat: 38.7363912,
                    lon: -9.1451223,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1451223, 38.7363912],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAE',
                    name: 'LX041 Lisboa 13 (SSPedr-NE)',
                    value: 9860.36,
                    lat: 38.7142511,
                    lon: -9.1359734,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1359734, 38.7142511],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0070B0',
                    name: 'LX042 Lisboa 14 (Nossa Senhora de Fatima, S Joao de Deus, S Jorge de Arroios)',
                    value: 11650.56,
                    lat: 39.6172063,
                    lon: -8.6521422,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6521422, 39.6172063],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006BA7',
                    name: 'LX043 Lisboa 15 (Sao Joao de Deus, Alvalade)',
                    value: 2679.92,
                    lat: 38.745489,
                    lon: -9.1345755,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1345755, 38.745489],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0390F3',
                    name: 'LX044 Lisboa 16 (Campo Grande, Lumiar, Ameixoeira, Charneca)',
                    value: 81579.91,
                    lat: 38.7584429,
                    lon: -9.1583507,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1583507, 38.7584429],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAE',
                    name: 'LX045 Lisboa 17 (Sao Joao de Brito, Campo Grande)',
                    value: 9543.85,
                    lat: 38.7541381,
                    lon: -9.1532229,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1532229, 38.7541381],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F3BA4B',
                    name: 'LX046 Lisboa 18 (Benfica, Sao Domingues de Benfica, Carnide)',
                    value: 183098.82,
                    lat: 38.7641133,
                    lon: -9.1871364,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1871364, 38.7641133],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B2',
                    name: 'LX047 Lisboa 19 (Carnide, Lumiar)',
                    value: 14144.38,
                    lat: 38.7641133,
                    lon: -9.1871364,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1871364, 38.7641133],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAF',
                    name: 'LX048 Lisboa 20 (Benfica)',
                    value: 11236.55,
                    lat: 38.7510519,
                    lon: -9.2026693,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2026693, 38.7510519],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0281D3',
                    name: 'LX049 Lisboa 21 (Sao Joao, Sao Jorge de Arroios, Alto do Pina)',
                    value: 48278.83,
                    lat: 38.741539,
                    lon: -9.1276295,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1276295, 38.741539],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'LX050 Lisboa 22 (Marvila, Beato)',
                    value: 23963.77,
                    lat: 38.7324804,
                    lon: -9.1097188,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1097188, 38.7324804],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0496FF',
                    name: 'LX051 Lisboa 23 (Marvila, Olivais-SE)',
                    value: 94160.34,
                    lat: 38.7513851,
                    lon: -9.1179465,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1179465, 38.7513851],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006DAA',
                    name: 'LX052 Lisboa 24 (Sta Maria dos Olivais)',
                    value: 5764.68,
                    lat: 38.7712833,
                    lon: -9.129012800000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.129012800000002, 38.7712833],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006DAA',
                    name: 'LX053 T Vedras 3 (Freiria, Ventosa, Turcifal, Sao Pedro da Cadeira)',
                    value: 5993.02,
                    lat: 39.02567090000001,
                    lon: -9.3173746,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3173746, 39.02567090000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CC9',
                    name: 'LX054 Mafra 2(Malveira, Vila Franca do Rosario, Gradil), Sobral Monte Agraco, Arruda dos Vinhos',
                    value: 38331.82,
                    lat: 38.9835516,
                    lon: -9.0774591,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0774591, 38.9835516],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'LX055 Odivelas 3 (Ramada, Canecas)',
                    value: 32386.93,
                    lat: 38.8161817,
                    lon: -9.2175988,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2175988, 38.8161817],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAD',
                    name: 'LX056 Amadora 4 (Sao Bras, Falagueira)',
                    value: 8537.55,
                    lat: 38.7813644,
                    lon: -9.2314492,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2314492, 38.7813644],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017AC5',
                    name: 'LX057 Cascais 4 (Parede)',
                    value: 33956.96,
                    lat: 38.69291399999999,
                    lon: -9.3553319,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3553319, 38.69291399999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'LX058 Oeiras 5 (Oeiras-E e Sao Juliao Barra)',
                    value: 25412.94,
                    lat: 38.6919934,
                    lon: -9.3118418,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3118418, 38.6919934],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'LX059 Oeiras 6 (Alges, Cruz Quebrada)',
                    value: 23288.49,
                    lat: 38.7022246,
                    lon: -9.2296032,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2296032, 38.7022246],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0070B0',
                    name: 'LX061 Lisboa 25 (Santo Condestavel)',
                    value: 12554.89,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAE',
                    name: 'LX063 Lisboa 27(Sao Paulo, Merces, St Catatina, Sacramento, Encarnacao, Martires)',
                    value: 9784.55,
                    lat: 38.7222524,
                    lon: -9.1393366,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1393366, 38.7222524],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0281D4',
                    name: 'LX064 Lisboa 28 (Sao Domingos de Benfica)',
                    value: 50175.34,
                    lat: 38.7500101,
                    lon: -9.1685614,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1685614, 38.7500101],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DCB75D',
                    name: 'LX065 Lisboa 28 (Nossa Senhora de Fatima, Alvalade)',
                    value: 174432.82,
                    lat: 38.7477561,
                    lon: -9.139388900000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.139388900000002, 38.7477561],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'LX066 Lisboa 29 (Olivais-S, W)',
                    value: 20995.65,
                    lat: 38.7712833,
                    lon: -9.129012800000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.129012800000002, 38.7712833],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#8F2D56',
                    name: 'MD001 Madeira, Porto Santo',
                    value: 373472.45,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006DAA',
                    name: 'PG001 Nisa',
                    value: 5710.05,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C2',
                    name: 'PG002 Portalegre (Alagoa, Fortios, S Lourenco, Alegrete, Se)',
                    value: 30728.02,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'PG003 Ponte de Sor, Avis, Sousel, Alter Chao',
                    value: 29827.12,
                    lat: 39.28602840000001,
                    lon: -7.9701846,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9701846, 39.28602840000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCC',
                    name: 'PG004 Fronteira, Monforte, Arronches, Campo Maior, Elvas',
                    value: 41574.34,
                    lat: 39.0207079,
                    lon: -7.0642042,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.0642042, 39.0207079],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B3',
                    name: 'PG005 Gaviao, Castelo de Vide, Marvao, Crato',
                    value: 15388.15,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0281D4',
                    name: 'PO001 P Varzim 1(Balazar, Estela, Rates, A Ver-o-Mar, Amorim, Agucadoura)',
                    value: 49370.82,
                    lat: 41.3828815,
                    lon: -8.759989299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.759989299999999, 41.3828815],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038BE9',
                    name: 'PO002 Vila do Conde 1 (Macieira da Maia, Junqueira, Azurara, Vila do Conde)',
                    value: 71848.67,
                    lat: 41.347023,
                    lon: -8.7370252,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7370252, 41.347023],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'PO003 Vila do Conde 2(Guilhabreu, Labruge, Mindelo »)',
                    value: 27757.71,
                    lat: 41.2781543,
                    lon: -8.718171,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.718171, 41.2781543],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038CE9',
                    name: 'PO004 Sto Tirso 1 (Areias, Santo Tirso)',
                    value: 72072.87,
                    lat: 41.3633525,
                    lon: -8.4760011,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4760011, 41.3633525],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECD',
                    name: 'PO005 Sto Tirso 2 (S Tome de Negrelos, Vilarinho, S Martinho Campos »)',
                    value: 41911.41,
                    lat: 41.348127,
                    lon: -8.4003255,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4003255, 41.348127],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0389E5',
                    name: 'PO006 Sto Tirso 3 (Agrela »), P Ferreira (Pacos de Ferriea, Freamunde »)',
                    value: 67031.32,
                    lat: 41.2865608,
                    lon: -8.338962,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.338962, 41.2865608],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D8',
                    name: 'PO007 Lousada (Aveleda, Lustosa »), Felgueiras 1 (Idaes, Regilde »)',
                    value: 53475.49,
                    lat: 41.2774793,
                    lon: -8.2837376,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2837376, 41.2774793],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0390F4',
                    name: 'PO008 Felgueiras 2 (Margaride Sta Eulalia, Lixa, Moure, Santao »)',
                    value: 82721.73,
                    lat: 41.3654043,
                    lon: -8.198755499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.198755499999999, 41.3654043],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'PO009 Baiao',
                    value: 25454.39,
                    lat: 41.16177150000001,
                    lon: -8.035514,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.035514, 41.16177150000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0493F9',
                    name: 'PO010 Amarante',
                    value: 88365.44,
                    lat: 41.26963569999999,
                    lon: -8.0826488,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.0826488, 41.26963569999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EA5E4F',
                    name: 'PO011 MCanaveses, Penafiel',
                    value: 238507.28,
                    lat: 41.2050452,
                    lon: -8.2875175,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2875175, 41.2050452],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#209AEA',
                    name: 'PO012 Paredes (Madalena, Baltar, Lordelo, Rebordosa »)',
                    value: 105171.44,
                    lat: 41.2053076,
                    lon: -8.3304861,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.3304861, 41.2053076],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038FF1',
                    name: 'PO013 Valongo 1(Campo, Sobrado, Valongo, Alfena)',
                    value: 80488.58,
                    lat: 41.1937533,
                    lon: -8.5017966,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5017966, 41.1937533],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BE',
                    name: 'PO014 Maia 1 (Aguas Santas, Pedroucos)',
                    value: 27150.78,
                    lat: 41.1888,
                    lon: -8.5874796,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5874796, 41.1888],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C2',
                    name: 'PO015 Maia 2 (Avioso (Santa Maria), Maia)',
                    value: 31031.23,
                    lat: 41.267274,
                    lon: -8.6062677,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6062677, 41.267274],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027DCA',
                    name: 'PO016 Valongo 2(Ermesinde)',
                    value: 39744.77,
                    lat: 41.219536,
                    lon: -8.5522002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5522002, 41.219536],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B3',
                    name: 'PO017 Maia 4 (Moreira, Vila Nova da Telha)',
                    value: 15174,
                    lat: 41.2531913,
                    lon: -8.668614,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.668614, 41.2531913],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D5',
                    name: 'PO018 Matosinhos 2 (Matosinhos)',
                    value: 50648.83,
                    lat: 41.1844362,
                    lon: -8.696277499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.696277499999999, 41.1844362],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B4',
                    name: 'PO019 Matosinhos 3 (Custoias, Guifoes, Sta Cruz do Bispo)',
                    value: 15833.36,
                    lat: 41.1971825,
                    lon: -8.6682332,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6682332, 41.1971825],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B4B17B',
                    name: 'PO020 Matosinhos 4 (Senhora da Hora, Leca do Balio, S Mamede de Infesta)',
                    value: 159738.91,
                    lat: 41.183583,
                    lon: -8.6518083,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6518083, 41.183583],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0281D4',
                    name: 'PO021 Porto 1 (Nevogilde, Foz do Douro, Lordelo do Ouro, Aldoar)',
                    value: 50186.52,
                    lat: 41.1517951,
                    lon: -8.674636099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.674636099999999, 41.1517951],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B7',
                    name: 'PO022 Porto 2 (Foz do Douro, Lordelo do Ouro, Massarelos)',
                    value: 19461.15,
                    lat: 41.1517951,
                    lon: -8.674636099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.674636099999999, 41.1517951],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0492F6',
                    name: 'PO023 Porto 3 (Cedofeita, Ramalde)',
                    value: 85115.27,
                    lat: 41.1490117,
                    lon: -8.6163972,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6163972, 41.1490117],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0286DD',
                    name: 'PO024 Porto 4 (Ramalde)',
                    value: 59461.14,
                    lat: 41.1663721,
                    lon: -8.6498773,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6498773, 41.1663721],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E9B953',
                    name: 'PO025 Porto 5 (Paranhos, Ramalde)',
                    value: 179398.34,
                    lat: 41.1663721,
                    lon: -8.6498773,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6498773, 41.1663721],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'PO026 Porto 6 (Cedofeita, Paranhos)',
                    value: 30172.91,
                    lat: 41.14894719999999,
                    lon: -8.6162177,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6162177, 41.14894719999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'PO027 Porto 7 (Bonfim, Paranhos)',
                    value: 27742.9,
                    lat: 41.1753841,
                    lon: -8.607517,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.607517, 41.1753841],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAE',
                    name: 'PO028 Porto 8 (Bonfim, Campanha)',
                    value: 10289.01,
                    lat: 41.1540226,
                    lon: -8.5919779,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5919779, 41.1540226],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAC',
                    name: 'PO029 Porto 9 (Bonfim, Cedofeita, Sto Ildefonso)',
                    value: 8168.53,
                    lat: 41.1464714,
                    lon: -8.604028,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.604028, 41.1464714],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C4',
                    name: 'PO030 Porto 10 (Bonfim, Sto Ildefonso)',
                    value: 32751.09,
                    lat: 41.1579438,
                    lon: -8.629105299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.629105299999999, 41.1579438],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAE',
                    name: 'PO031 Porto 11 (Sto Ildefonso, Se)',
                    value: 10164.58,
                    lat: 41.1433171,
                    lon: -8.609144100000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.609144100000002, 41.1433171],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#4AA1CB',
                    name: 'PO032 Porto 12 (Sao Nicolau, Vitoria, Se, Cedofeita, Miragaia)',
                    value: 120413.62,
                    lat: 41.14069500000001,
                    lon: -8.6148768,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6148768, 41.14069500000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0492F6',
                    name: 'PO033 Gondomar 1 (Valbom, Sao Cosme), VN Gaia 1 (Oliveira do Douro)',
                    value: 85416.92,
                    lat: 41.12982299999999,
                    lon: -8.5619497,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5619497, 41.12982299999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0491F4',
                    name: 'PO034 Gondomar 2 (Rio Tinto, Baguim do Monte)',
                    value: 83247.46,
                    lat: 41.1918929,
                    lon: -8.541168400000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.541168400000002, 41.1918929],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D6',
                    name: 'PO035 Gondomar 3 (Fanzeres, S Pedro da Cova, Foz do Sousa »)',
                    value: 51668.22,
                    lat: 41.1521455,
                    lon: -8.510187499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.510187499999999, 41.1521455],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#58A3C0',
                    name: 'PO036 VN Gaia 2 (Canidelo, Santa Marinha, Sao Pedro da Afurada, Canidelo)',
                    value: 125839.01,
                    lat: 41.1233052,
                    lon: -8.6461724,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6461724, 41.1233052],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006BA6',
                    name: 'PO037 VN Gaia 3 (Madalena, Vilar do Paraiso)',
                    value: 1672.62,
                    lat: 41.0891434,
                    lon: -8.612393899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.612393899999999, 41.0891434],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038DEC',
                    name: 'PO038 VN Gaia 4 (Valadares, Vilar do Paraiso, Arcozelo, Gulpilhares)',
                    value: 74800.44,
                    lat: 41.0517695,
                    lon: -8.6435589,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6435589, 41.0517695],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BC',
                    name: 'PO039 VN Gaia 5 (Serzedo, S Felix da Marinha)',
                    value: 24965.11,
                    lat: 41.0498582,
                    lon: -8.6150022,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6150022, 41.0498582],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'PO040 VN Gaia 6 (Crestuma, Lever, Grijo, Perosinho, Seixezelo »)',
                    value: 29830.7,
                    lat: 41.0728337,
                    lon: -8.4866271,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4866271, 41.0728337],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D1B565',
                    name: 'PO041 VN Gaia 7 (Avintes, Vilar de Andorinho, Pedroso, Mafamude, Sta Marinha)',
                    value: 170571,
                    lat: 41.1252976,
                    lon: -8.601839499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.601839499999999, 41.1252976],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038BE9',
                    name: 'PO042 P Varzim 2 (Povoa de Varzim)',
                    value: 71715.71,
                    lat: 41.3828815,
                    lon: -8.759989299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.759989299999999, 41.3828815],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECC',
                    name: 'PO043 Maia 3 (Gueifaes, Vermoim)',
                    value: 41767.7,
                    lat: 41.4229179,
                    lon: -8.4485846,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4485846, 41.4229179],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0286DD',
                    name: 'PO044 Trofa (Muro, Sao Mamede Coronado, Sao Romao Coronado, Alvarelhos, Bougado)',
                    value: 59310.54,
                    lat: 41.282184,
                    lon: -8.56904,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.56904, 41.282184],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAD',
                    name: 'PO045 Maia 5 (Sao Pedro Fins, Silva Escura, Milheiros, Nogueira)',
                    value: 9495.32,
                    lat: 41.2571491,
                    lon: -8.581362799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.581362799999999, 41.2571491],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0387E0',
                    name: 'PO046 Matosinhos 1 (Leca da Palmeira, Lavra, Perafita)',
                    value: 61985.11,
                    lat: 41.2014865,
                    lon: -8.6962733,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6962733, 41.2014865],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CCA',
                    name: 'PO047 Porto13 (Campanha)',
                    value: 39290.35,
                    lat: 41.16136789999999,
                    lon: -8.5763932,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5763932, 41.16136789999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D7',
                    name: 'PO048 VN Gaia 8 (Mafamude)',
                    value: 52361.65,
                    lat: 41.1252976,
                    lon: -8.601839499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.601839499999999, 41.1252976],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D5',
                    name: 'SR001 Ourem (Fatima, Alburitel, Nossa Sra da Piedade, Caxarias, Urq »)',
                    value: 51151.12,
                    lat: 39.642683,
                    lon: -8.5226706,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5226706, 39.642683],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D5',
                    name: 'SR002 Tomar, Ferreira do Zezere',
                    value: 51257,
                    lat: 39.6932144,
                    lon: -8.2905257,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2905257, 39.6932144],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038AE6',
                    name: 'SR003 Torres Novas (Sta Maria, Santiago, S Pedro, Olaia, Paco, Riachos »)',
                    value: 68165.19,
                    lat: 39.4790062,
                    lon: -8.540103,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.540103, 39.4790062],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CC9',
                    name: 'SR004 Entroncamento, Vila Nova da Barquinha, Golega',
                    value: 38148.62,
                    lat: 39.4611239,
                    lon: -8.478759,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.478759, 39.4611239],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B2',
                    name: 'SR005 Macao, Sardoal',
                    value: 14375.69,
                    lat: 39.5375181,
                    lon: -8.1612773,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1612773, 39.5375181],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0E97F8',
                    name: 'SR006 Santarem 1 (Sao Salvador, Sao Nicolau)',
                    value: 98284.35,
                    lat: 39.2848804,
                    lon: -8.704075,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.704075, 39.2848804],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B4',
                    name: 'SR007 Alcanena',
                    value: 15980.65,
                    lat: 39.4595628,
                    lon: -8.666868899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.666868899999999, 39.4595628],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BA',
                    name: 'SR008 Santarem 2 (Achete, Alcanhoes, Pernes, Vale de Figueira), Alpiarca',
                    value: 22914.82,
                    lat: 39.2929167,
                    lon: -8.665559199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.665559199999999, 39.2929167],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B2',
                    name: 'SR009 Cartaxo 1 (Ereira, Pontevel, Valada, Vale da Pedra, Vila Cha de Ourique)',
                    value: 13969.22,
                    lat: 39.1178806,
                    lon: -8.7976937,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7976937, 39.1178806],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B8',
                    name: 'SR010 Salvaterra de Magos',
                    value: 20478.78,
                    lat: 39.0272019,
                    lon: -8.794182,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.794182, 39.0272019],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0282D5',
                    name: 'SR011 Constancia, Coruche, Chamusca',
                    value: 50496.84,
                    lat: 39.1687671,
                    lon: -8.2757081,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2757081, 39.1687671],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D8',
                    name: 'SR012 Abrantes',
                    value: 53404.83,
                    lat: 39.4630563,
                    lon: -8.1995808,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1995808, 39.4630563],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECD',
                    name: 'SR013 Santarem 3 (Alcanede,AmBaixo,Tremes,Abitureiras,Almoster,P Isenta,Vale Sant,Varzea),Rio Maior',
                    value: 42789.95,
                    lat: 39.3148294,
                    lon: -8.7821951,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7821951, 39.3148294],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006DAA',
                    name: 'SR014 Santarem 4 (Marvila, Sao Nicolau, S Salvador)',
                    value: 6243.37,
                    lat: 39.2848804,
                    lon: -8.704075,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.704075, 39.2848804],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C2',
                    name: 'SR015 Alpiarca, Alameirim',
                    value: 31271.8,
                    lat: 39.2119734,
                    lon: -8.6254394,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6254394, 39.2119734],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'SR016 Cartaxo 2 (Cartaxo)',
                    value: 21699.85,
                    lat: 39.16181660000001,
                    lon: -8.7888254,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7888254, 39.16181660000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'SR017 Benavente',
                    value: 27335.55,
                    lat: 38.9817395,
                    lon: -8.8095549,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8095549, 38.9817395],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038CEA',
                    name: 'ST001 Almada 1 (Costa da Caparica, Trafaria)',
                    value: 72681.22,
                    lat: 38.670973,
                    lon: -9.2323084,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2323084, 38.670973],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'ST002 Almada 2 (Charneca da Caparica, Sobreda)',
                    value: 31684.48,
                    lat: 38.6279559,
                    lon: -9.1973177,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1973177, 38.6279559],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C2',
                    name: 'ST003 Almada 3 (Feijo, Laranjeiro)',
                    value: 31093.27,
                    lat: 38.65467,
                    lon: -9.1634774,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1634774, 38.65467],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CC9',
                    name: 'ST004 Almada 4 (Cova da Piedade)',
                    value: 37768.74,
                    lat: 38.6703794,
                    lon: -9.1595477,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1595477, 38.6703794],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038DEC',
                    name: 'ST005 Almada 5 (Almada, Cacilhas, Pragal)',
                    value: 74480.42,
                    lat: 38.6861358,
                    lon: -9.1492754,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1492754, 38.6861358],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'ST006 Seixal 1 (Arrentela, Fernao Ferro)',
                    value: 31724.47,
                    lat: 38.626648,
                    lon: -9.1038399,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1038399, 38.626648],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BE',
                    name: 'ST007 Seixal 2 (Seixal, Aldeia de Paio Pires)',
                    value: 26814.94,
                    lat: 38.6410676,
                    lon: -9.0975,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0975, 38.6410676],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0389E5',
                    name: 'ST008 Seixal 3 (Corroios, Amora-N)',
                    value: 67180.79,
                    lat: 38.6275503,
                    lon: -9.1456487,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1456487, 38.6275503],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006DAB',
                    name: 'ST009 Seixal 4 (Amora -S)',
                    value: 6799.08,
                    lat: 38.6201484,
                    lon: -9.1345466,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1345466, 38.6201484],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'ST010 Barreiro 1 (Barreiro, Lavradio)',
                    value: 31514.67,
                    lat: 38.6691492,
                    lon: -9.0527681,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0527681, 38.6691492],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'ST011 Barreiro 2 (Coina, Sto Andre, Sto Antonio da Charneca)',
                    value: 25550.35,
                    lat: 38.6254803,
                    lon: -9.0324825,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0324825, 38.6254803],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0172B4',
                    name: 'ST012 Barreiro 3 (Alto do Seixalinho, Verderena)',
                    value: 15788.62,
                    lat: 38.6544481,
                    lon: -9.0714266,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0714266, 38.6544481],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'ST013 Moita 1 (Vale da Amoreira, Baixa da Banheira)',
                    value: 24029.95,
                    lat: 38.6462216,
                    lon: -9.0382454,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0382454, 38.6462216],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B3',
                    name: 'ST014 Montijo 1 (Afonsoeiro, Atalaia, Sarilhos Grandes, Canha, Sto Isidro de Pegoes)',
                    value: 15269.4,
                    lat: 38.6793102,
                    lon: -8.967957799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.967957799999999, 38.6793102],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'ST015 Montijo 2 (Montijo)',
                    value: 23567.28,
                    lat: 38.7055652,
                    lon: -8.974118299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.974118299999999, 38.7055652],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006FAE',
                    name: 'ST016 Alcochette',
                    value: 9550.03,
                    lat: 38.7561726,
                    lon: -8.9598931,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9598931, 38.7561726],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BB',
                    name: 'ST017 Palmela 2 (Palmela, Quinta do Anjo)',
                    value: 23878.89,
                    lat: 38.5611235,
                    lon: -8.9423976,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9423976, 38.5611235],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0174B9',
                    name: 'ST018 Setubal 1 (Santa Maria da Graca, Sao Juliao)',
                    value: 21677.67,
                    lat: 38.5244132,
                    lon: -8.888171,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.888171, 38.5244132],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038FF1',
                    name: 'ST019 Setubal 2 (Sao Sebastiao, Santa Maria da Graca)',
                    value: 80517.68,
                    lat: 38.5167627,
                    lon: -8.8315681,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8315681, 38.5167627],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0179C3',
                    name: 'ST020 Alcacer do Sal, Grandola',
                    value: 31685.82,
                    lat: 38.3725756,
                    lon: -8.5110695,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5110695, 38.3725756],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0389E4',
                    name: 'ST021 Santiago Cacem 2 (Santiago do Cacem, Sto Andre), Sines',
                    value: 66645.64,
                    lat: 38.0551238,
                    lon: -8.7861353,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7861353, 38.0551238],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'ST022 Barreiro 4 (Alto do Seixalinho)',
                    value: 27515.85,
                    lat: 38.66001809999999,
                    lon: -9.0615356,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0615356, 38.66001809999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'ST023 Moita 2 (Alhos Vedros, Moita)',
                    value: 29723.88,
                    lat: 38.6407724,
                    lon: -9.0058867,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0058867, 38.6407724],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BA',
                    name: 'ST024 Palmela 1 (Pinhal Novo, Poceirao, Marateca)',
                    value: 22976.95,
                    lat: 38.6318721,
                    lon: -8.915093299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.915093299999999, 38.6318721],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECE',
                    name: 'ST025 Sesimbra',
                    value: 43124.04,
                    lat: 38.44421699999999,
                    lon: -9.1014903,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1014903, 38.44421699999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC7',
                    name: 'ST026 Setubal 3 (Sao Lourenco, Sao Simao, Nossa Senhora da Anunciada)',
                    value: 35877.53,
                    lat: 38.5203456,
                    lon: -9.0190036,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0190036, 38.5203456],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0283D8',
                    name: 'ST027 Setubal 4 (Sado, Sao Sebastiao)',
                    value: 53674.85,
                    lat: 38.5045895,
                    lon: -8.808715099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.808715099999999, 38.5045895],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006EAC',
                    name: 'ST028 Santiago Cacem 1 (Alvalade, Cercal, Ermidas-Sado, Abela)',
                    value: 7573.36,
                    lat: 38.7477561,
                    lon: -9.139388900000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.139388900000002, 38.7477561],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017AC5',
                    name: 'VC001 Valenca, Vila Nova Cerveira',
                    value: 34274.57,
                    lat: 41.9383326,
                    lon: -8.744077299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.744077299999999, 41.9383326],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'VC002 Moncao',
                    value: 28094.53,
                    lat: 42.0771855,
                    lon: -8.4819441,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4819441, 42.0771855],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0286DD',
                    name: 'VC003 Arcos de Valdevez, Melgaco, Ponte da Barca',
                    value: 59576.17,
                    lat: 41.8055334,
                    lon: -8.417051599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.417051599999999, 41.8055334],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0388E2',
                    name: 'VC004 Paredes de Coura, Ponte de Lima',
                    value: 64585.03,
                    lat: 41.7647333,
                    lon: -8.5827407,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5827407, 41.7647333],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#44A0CF',
                    name: 'VC005 Viana do Castelo 1 (Afife,Carreco,Lanheses,Meadela,Portuzelo,Monserrate,Santa Maria Maior)',
                    value: 118385.78,
                    lat: 41.7021226,
                    lon: -8.822494100000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.822494100000002, 41.7021226],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'VC006 Caminha',
                    value: 30037.11,
                    lat: 41.8732082,
                    lon: -8.837845399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.837845399999999, 41.8732082],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BD',
                    name: 'VC007 Viana do Castelo 2 (Barroselas, Balugaes »)',
                    value: 25934.21,
                    lat: 41.6427053,
                    lon: -8.6432965,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6432965, 41.6427053],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177BF',
                    name: 'VC008 Viana do Castelo 3 (Vila Franca, Castelo do Neiva, Vila Nova de Anha, Darque, Neiva)',
                    value: 28164.1,
                    lat: 41.6634866,
                    lon: -8.7938678,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7938678, 41.6634866],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027BC8',
                    name: 'VR001 Boticas, Montalegre, Mondim Basto, Ribeira de Pena',
                    value: 37113.04,
                    lat: 41.6984124,
                    lon: -7.6710889,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6710889, 41.6984124],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0496FF',
                    name: 'VR002 Chaves, Vila Pouca de Aguiar',
                    value: 94381.61,
                    lat: 41.5002387,
                    lon: -7.6432318,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6432318, 41.5002387],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC6',
                    name: 'VR003 Alijo, Murca, Sabrosa',
                    value: 35535.14,
                    lat: 41.4072842,
                    lon: -7.453387899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.453387899999999, 41.4072842],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0175BA',
                    name: 'VR004 Valpacos',
                    value: 22215.09,
                    lat: 41.6069681,
                    lon: -7.3104483,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.3104483, 41.6069681],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECD',
                    name: 'VR005 Peso da Regua, SM Penaguiao, Mesao Frio',
                    value: 42875.07,
                    lat: 41.1598648,
                    lon: -7.889922799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.889922799999999, 41.1598648],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0285DD',
                    name: 'VR006 Vila Real 1 (Mateus, Nossa Senhora da Conceicao, Sao Pedro, Campea, Lordelo)',
                    value: 58738.39,
                    lat: 41.2379674,
                    lon: -8.4239055,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4239055, 41.2379674],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2A9CE2',
                    name: 'VR007 Vila Real 2 (Sao Diniz, Sao Pedro, Nossa Senhora da Conceicao)',
                    value: 108715.51,
                    lat: 41.2988174,
                    lon: -7.7439337,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.7439337, 41.2988174],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027ECD',
                    name: 'VS001 Cinfaes (Cinfaes, Fornelos »), Resende',
                    value: 41896.59,
                    lat: 41.0717872,
                    lon: -8.0902881,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.0902881, 41.0717872],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0389E4',
                    name: 'VS002 Lamego,  SJ Pesq, Tabuaco, Armamar',
                    value: 66756.44,
                    lat: 41.1162713,
                    lon: -7.5674579,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.5674579, 41.1162713],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0177C0',
                    name: 'VS003 Tondela 1 (Canas, Lajeosa, Besteiros, »)',
                    value: 28421.44,
                    lat: 40.540379,
                    lon: -7.979455,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.979455, 40.540379],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#419FD1',
                    name: 'VS004 Penedono,Sernance,Moim Beira,Tarouca,Satao,VN Paiva, Castro Daire,SP Sul (Varzea»),Oli Frades',
                    value: 117190.11,
                    lat: 40.8998417,
                    lon: -7.4900188,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4900188, 40.8998417],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0178C1',
                    name: 'VS005 Vouzela, Viseu 1 ( S Salvador, Torredeita, Lordosa, Abraveses, Bodiosa)',
                    value: 29998.51,
                    lat: 40.6791759,
                    lon: -7.9224147,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9224147, 40.6791759],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B7',
                    name: 'VS006 Tondela 2 (Tondela)',
                    value: 19343.72,
                    lat: 40.5168388,
                    lon: -8.080047100000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.080047100000002, 40.5168388],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0492F7',
                    name: 'VS007 Viseu 2 (Santa Maria, SJose, Cepoes, SJ Lourosa, Silgueiros)',
                    value: 86534.65,
                    lat: 40.5578422,
                    lon: -7.9583908,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9583908, 40.5578422],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#017BC7',
                    name: 'VS008 Mangualde, Penalva do Castelo',
                    value: 36189.9,
                    lat: 40.6862295,
                    lon: -7.633112999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.633112999999999, 40.6862295],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0173B6',
                    name: 'VS009 Nelas',
                    value: 18214.24,
                    lat: 40.537787,
                    lon: -7.8498679,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.8498679, 40.537787],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0171B2',
                    name: 'VS010 Carregal do Sal',
                    value: 14321.09,
                    lat: 40.4347784,
                    lon: -7.9980002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9980002, 40.4347784],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#1D9AEC',
                    name: 'VS011 Viseu 3 (Coracao de Jesus, Sao Jose)',
                    value: 103754.09,
                    lat: 40.67024,
                    lon: -7.9075423,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9075423, 40.67024],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027BC8',
                    name: 'VS012 S Comba Dao, Mortagua',
                    value: 37066.48,
                    lat: 40.3971448,
                    lon: -8.2309134,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2309134, 40.3971448],
                },
            },
        ],
    },
    layersProp: [
        {
            id: 'point-inner',
            type: 'circle',
            source: 'earthquakes',
            paint: {
                'circle-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'value'],
                    1672.62,
                    '#006BA6',
                    94622.5775,
                    '#0496FF',
                    187572.535,
                    '#FFBC42',
                    280522.4925,
                    '#D81159',
                    373472.45,
                    '#8F2D56',
                ],
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    ['interpolate', ['linear'], ['get', 'value'], 1672.62, 1, 373472.45, 4],
                    12,
                    ['interpolate', ['linear'], ['get', 'value'], 1672.62, 2, 373472.45, 5],
                    22,
                    ['interpolate', ['linear'], ['get', 'value'], 1672.62, 3, 373472.45, 6],
                ],
                'circle-opacity': ['interpolate', ['linear'], ['get', 'value'], 1672.62, 0.3, 373472.45, 1],
                'circle-stroke-color': '#fff',
            },
        },
        {
            id: 'point-outer',
            type: 'circle',
            source: 'earthquakes',
            paint: {
                'circle-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'value'],
                    1672.62,
                    '#006BA6',
                    94622.5775,
                    '#0496FF',
                    187572.535,
                    '#FFBC42',
                    280522.4925,
                    '#D81159',
                    373472.45,
                    '#8F2D56',
                ],
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    ['interpolate', ['linear'], ['get', 'value'], 1672.62, 4, 373472.45, 15],
                    12,
                    ['interpolate', ['linear'], ['get', 'value'], 1672.62, 5, 373472.45, 25],
                    22,
                    ['interpolate', ['linear'], ['get', 'value'], 1672.62, 6, 373472.45, 40],
                ],
                'circle-opacity': ['interpolate', ['linear'], ['get', 'value'], 1672.62, 0, 373472.45, 0.3],
            },
        },
    ],
}
