import React from 'react'
import icoPlus from '../../../assets/images/ico-plus.svg'

export const reportUserListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'id', label: '', sortable: true },
        { path: 'empNo', label: '', sortable: true },
        { path: 'name', label: '', sortable: true },
        { path: 'username', label: '', sortable: true },
        { path: 'email', label: '', sortable: true },
        { path: 'department', label: '', sortable: true },
        { path: 'phone', label: '', sortable: true },
        { path: 'action', label: 'ACTION', sortable: false },
    ],
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'reportUserList',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [{ name: 'addUser', label: '', icon: icoPlus }],
        },
        reference: React.createRef(),
    },
}
