import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { PointOfSaleService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component.js';
import { totalClientsMock } from './mock';
import icoClientsCard from '../../../assets/images/icoClientsCard.svg';
import styles from './total-clients.module.scss';
import { ContentContainer, Loader, Spinner } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { GeneralCard } from '../general-card/general-card';

/**
 * Displays the count and evolution of total clients compared to the previous month
 */
export const TotalClients = React.memo((props) => {
    const {
        onDownloadComponent,
        config,
        isExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
        isReportExample
    } = props;

    const { filter } = useFilter();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(totalClientsMock);

    const determineVendorIds = useCallback(() => {
        if (
            filter?.vendorId.constructor === String &&
            config.filter.includes('vendorId')
        ) {
            return [filter.vendorId];
        }
        if (
            filter?.vendorIds.constructor === Array &&
            filter.vendorIds.length &&
            config.filter.includes('vendorIds')
        ) {
            return filter.vendorIds;
        }
        return [];
    }, [config.filter, filter?.vendorId, filter?.vendorIds]);

    useEffect(() => {
        async function fetchTotalClients() {
            const dataClone = _.cloneDeep(totalClientsMock);

            try {
                if (!isExample) {
                    if (isMounted.current) setLoading(true);
                    if (type) dataClone.attr = type;

                    const dataBack = await PointOfSaleService.getApiPosAllCards(
                        { vendorId: determineVendorIds() }
                    );
                    dataClone.content = dataBack.count.count.toString();
                    dataClone.contentType = 'units';
                    dataClone.contentAuxType = 'percentageRisk';
                    if (dataBack.count.variation !== '')
                        dataClone.contentAux = dataBack.count.variation;
                    dataClone.loading = false;

                    const noOptions = (options) => options.length === 0;
                    dataClone.disabled =
                        noOptions(dataClone.settings.view.options) &&
                        noOptions(dataClone.settings.options.options);

                    verifyComponent(dataClone);

                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } else {
                    if (type) dataClone.attr = type;
                    verifyComponent(dataClone);
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }

        fetchTotalClients();
    }, [
        config,
        filter,
        isExample,
        isMounted,
        setLoading,
        setValue,
        type,
        t,
        determineVendorIds
    ]);

    const handleSelectSettingsOption = useCallback(
        (attr, id, option) => {
            if (isMounted.current) toggleComponentSettings();
            if (option === 'pin') {
                onPinComponent(data.attr);
            } else if (option === 'unpin') {
                onUnpinComponent(data.attr);
            } else if (option === 'download') {
                onDownloadComponent();
            }
        },
        [
            data.attr,
            isMounted,
            onDownloadComponent,
            onPinComponent,
            onUnpinComponent,
            toggleComponentSettings
        ]
    );

    return (
        <div
            className={`${isPreview ? 'pe-none' : ''} w-100 h-100 ${
                styles['total-clients-container']
            }`}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <div className="row mx-0 w-100 h-100">
                    <div
                        className={`col px-0 ${styles['total-clients-container__card']}`}
                    >
                        <ContentContainer
                            attr={data.attr}
                            icon={icoClientsCard}
                            isCardTitle
                            titleText={t(`components:${data.attr}.title`)}
                            disabled={data.disabled}
                            isForecast={data.isForecast}
                            legend={data.legend}
                            settings={data.settings}
                            onClickSettingsIcon={toggleComponentSettings}
                            onClickSettingsOptions={handleSelectSettingsOption}
                            onClickSettingsView={handleSelectSettingsOption}
                            onCLickSettintsSelected={handleSelectSettingsOption}
                            onCloseSettings={toggleComponentSettings}
                            // hovered={hovered}
                            onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                            onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                            hideInfoIcon={true}
                            hidePinIcon={
                                isExample || isPreview || isReportExample
                            }
                            hideSettingsIcon={
                                isExample || isPreview || isReportExample
                            }
                            pinned={pinned}
                            onPinComponent={onPinComponent}
                            onUnpinComponent={onUnpinComponent}
                        >
                            <GeneralCard {...data} />
                        </ContentContainer>
                    </div>
                </div>
            )}
        </div>
    );
});

TotalClients.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
