import React, { useCallback, useEffect, useMemo } from 'react'
import styles from './FinanceTable.module.scss'
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper'
import { financeTableMock } from './mock'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { ProductsService, SalesService } from '../../../temp/test'
import { getFilterParam } from '../../../utils/params'
import { useFilter } from '../../../providers/filter-context'
import _ from 'lodash'
import { getValueInString } from '../../../utils/unit'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { useSalesChannel } from '../../../providers/sales-channel-context'
import Scrollable from '../../scrolling/scrollable'

const transformInfo = (data) => {
    const dataSalesQuarter = Object.entries(data).reduce((acc, [key, value]) => {
        const date = new Date(key)
        const quarter = Math.floor(date.getMonth() / 3) + 1
        const year = date.getFullYear()
        const quarterKey = `${year}-Q${quarter}`
        if (!acc[quarterKey]) {
            // Initialize with sum for sales values and an object to count months
            acc[quarterKey] = {
                sum: 0,
                year: year,
                quarter: quarter,
                monthCount: {},
            }
        }
        acc[quarterKey].sum += value
        // Use the month as a key in monthCount; increment if exists, or initialize to 1
        const month = date.getMonth()
        acc[quarterKey].monthCount[month] = (acc[quarterKey].monthCount[month] || 0) + 1
        return acc
    }, {})

    const filteredQuarters = Object.entries(dataSalesQuarter).reduce((filtered, [quarterKey, { sum, year, quarter, monthCount }]) => {
        // Check if the number of keys (unique months) in monthCount is 3
        if (Object.keys(monthCount).length === 3) {
            filtered[quarterKey] = {
                sum: Math.round(sum),
                year,
                quarter,
            }
        }
        return filtered
    }, {})

    // for every item in the filteredQuarters object, calculate the difference between the current and the previous quarter and store it in attribute 'pp' and also the same value but in percentage and store it in 'growth'
    const quartersKeys = Object.keys(filteredQuarters)
    for (let i = 0; i < quartersKeys.length; i++) {
        const currentQuarter = filteredQuarters[quartersKeys[i]]
        const previousQuarter = filteredQuarters[quartersKeys[i - 1]]
        if (previousQuarter) {
            currentQuarter.pp = Math.round((currentQuarter.sum - previousQuarter.sum) * 10) / 10
            currentQuarter.growth = Math.round(((currentQuarter.sum - previousQuarter.sum) / previousQuarter.sum) * 10) / 10 + '%'
        }
    }

    // now do the same but get the difference between the current quarter and the same quarter of the previous year
    for (let i = 0; i < quartersKeys.length; i++) {
        const currentQuarter = filteredQuarters[quartersKeys[i]]
        const previousYearQuarter = filteredQuarters[quartersKeys[i - 4]]
        if (previousYearQuarter) {
            currentQuarter.growthPYG = Math.round(((currentQuarter.sum - previousYearQuarter.sum) / previousYearQuarter.sum) * 10) / 10 + '%'
        }
    }

    // Get the keys for the last 6 quarters
    const last6QuartersKeys = Object.keys(filteredQuarters).slice(-6)

    // Construct a new object with only the last 6 quarters
    const last6Quarters = last6QuartersKeys.reduce((obj, key) => {
        obj[key] = filteredQuarters[key]
        return obj
    }, {})
    return last6Quarters
}

const RowContent = React.memo(({ type, year, quarter, kUnits, pp, growth, growthPYG, hideDate }) => {
    return (
        <>
            <div
                className="row mx-0 w-100"
                style={{
                    height: '4rem',
                    marginBottom: '1rem',
                    paddingTop: type === 'product' ? '1.5rem' : 0,
                }}
            >
                {!hideDate && (
                    <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                        <span className={`${styles['finance-table-container__column-title']}`}>{year}</span>
                        <span className={`${styles['finance-table-container__column-sub-title']}`}>{'Q' + quarter}</span>
                    </div>
                )}
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    height: type === 'product' ? 'calc(100% - 6.5rem - 5rem)' : 'calc(100% - 5rem)',
                    padding: '1rem',
                    paddingTop: type === 'product' ? '2rem' : '1rem',
                }}
            >
                <div className="col px-0 d-flex flex-column justify-content-between">
                    <div
                        className="row mx-0 w-100"
                        style={{
                            paddingTop: type === 'product' ? '0.5rem' : 0,
                            marginBottom: '1rem',
                        }}
                    >
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span className={`${styles['finance-table-container__row-content']}`}>{kUnits || '-'}</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span className={`${styles['finance-table-container__row-content']}`} style={{ color: '#969696' }}>
                                {pp || '-'}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span className={`${styles['finance-table-container__row-content']}`} style={{ color: '#969696' }}>
                                {growth || '-'}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span className={`${styles['finance-table-container__row-content']}`} style={{ color: '#969696' }}>
                                {growthPYG || '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {type === 'product' && (
                <div
                    className="row mx-0 w-100"
                    style={{
                        height: '6rem',
                        paddingTop: '1.5rem',
                    }}
                >
                    <div className="col px-0 d-flex flex-column justify-content-center">
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span className={`${styles['finance-table-container__row-content']}`}>112</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span className={`${styles['finance-table-container__row-content']}`} style={{ color: '#969696' }}>
                                    -
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

const Header = React.memo(({ type, title }) => {
    return (
        <>
            <div className="row mx-0 w-100" style={{ height: '4rem', marginBottom: '1rem' }}>
                <div className="col px-0">
                    <div className={`${styles[`finance-table-container__${type}-header`]}`}>
                        <span className={`${styles[`finance-table-container__${type}-header__text`]}`}>{title || 'Market'}</span>
                    </div>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{
                    height: type === 'product' ? 'calc(100% - 6.5rem - 5rem)' : 'calc(100% - 5rem)',
                    paddingTop: type === 'product' ? '1.5rem' : 0,
                }}
            >
                <div className={`${styles[`finance-table-container__${type}-border`]}`}></div>
                <div className="col px-0">
                    <div className={`${styles[`finance-table-container__${type}-content`]}`}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <span className={`${styles['finance-table-container__row-text']}`}>K units</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <span className={`${styles['finance-table-container__row-text']}`}>Var. PP</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <span className={`${styles['finance-table-container__row-text']}`}>Growth (PP)</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <span className={`${styles['finance-table-container__row-text']}`}>Growth (PYG)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {type === 'product' && (
                <div className="row mx-0 w-100" style={{ height: '6rem', paddingTop: '1.5rem' }}>
                    <div
                        className="col-auto px-0 d-flex justify-content-end align-items-center"
                        style={{
                            width: '20rem',
                        }}
                    >
                        <div style={{ padding: 0, paddingRight: '1rem' }}>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    marginBottom: '1rem',
                                }}
                            >
                                <div className="col px-0 d-flex justify-content-end align-items-center">
                                    <span className={`${styles['finance-table-container__row-text']}`}>%MS</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-end align-items-center">
                                    <span className={`${styles['finance-table-container__row-text']}`}>Var %MS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

export const FinanceTable = React.memo(({ config, isExample, ...rest }) => {
    const { value: data, setValue, setLoading } = useSimpleComponent(financeTableMock)
    const { timeInterval } = useTimeInterval()
    const { channels } = useSalesChannel()
    const { filter } = useFilter()

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(financeTableMock)
            if (!isExample) {
                setLoading(true)
                let dataSales = await SalesService.getApiSales({
                    producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                    interval: timeInterval,
                    productId: getFilterParam(filter, config, ['productId', 'productIds']),
                    sumBy: 'units',
                    channelId: JSON.parse(channels),
                })

                const forbiddenCategories = ['V&I', 'OBU', 'R&I', 'CVRM']
                const productResult = await ProductsService.getApiProducts1({
                    id: getFilterParam(filter, config, ['productId', 'productIds']),
                })
                let categorySales = {}
                await Promise.all(
                    productResult.categories.map(async (category, categoryIndex) => {
                        if (!forbiddenCategories.includes(category.code) && category.code.length === 5) {
                            categorySales = await SalesService.getApiSales({
                                producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                                interval: timeInterval,
                                productCategories: [category.code],
                                sumBy: 'units',
                                channelId: JSON.parse(channels),
                            })
                        }
                    })
                )
                // ensure that both timeseries start at the first common date
                const commonDates = Object.keys(dataSales).filter((date) => date in categorySales)
                categorySales = _.pick(categorySales, commonDates)
                dataSales = _.pick(dataSales, commonDates)
                dataClone.marketData = transformInfo(categorySales)
                dataClone.productData = transformInfo(dataSales)
                dataClone.productName = productResult.title
                dataClone.loading = false
                setValue(dataClone)
            } else {
                setValue(dataClone)
                setLoading(false)
            }
        }
        fill()
    }, [config, filter, setValue, isExample, setLoading, timeInterval, channels])

    const renderVerticalLine = useCallback((data, index, type) => {
        if (
            Object.entries(data)[index] &&
            Object.entries(data)[index - 1] &&
            Object.entries(data)[index][1].year !== Object.entries(data)[index - 1][1].year
        ) {
            return (
                <div className="col-auto px-0 h-100">
                    <div
                        className={
                            type === 'product'
                                ? `${styles['finance-table-container__vertical-product-line']}`
                                : `${styles['finance-table-container__vertical-line']}`
                        }
                    ></div>
                </div>
            )
        }
    }, [])

    return (
        <ComponentWrapper {...data} {...rest}>
            <div className={`${styles['finance-table-container']}`}>
                <Scrollable>
                    <div
                        className="row mx-0 w-100 position-relative"
                        style={{
                            zIndex: 2,
                            flexGrow: 1,
                            minHeight: '20rem',
                        }}
                    >
                        <div className="col-auto px-0" style={{ width: '20rem' }}>
                            <Header type="market" />
                        </div>
                        <div className="col px-0">
                            <div className="row mx-0 w-100 h-100 d-flex justify-content-between align-items-center">
                                {data.marketData &&
                                    Object.entries(data.marketData).length > 0 &&
                                    Object.entries(data.marketData).map((item, index) => {
                                        return (
                                            <div key={index} className="col-auto px-0 h-100">
                                                <div className="row mx-0 w-100 h-100">
                                                    {renderVerticalLine(data.productData, index)}
                                                    <div
                                                        key={index}
                                                        className="col-auto px-0 h-100"
                                                        style={{
                                                            width: '10rem',
                                                        }}
                                                    >
                                                        <RowContent
                                                            type={'market'}
                                                            year={item[1].year}
                                                            quarter={item[1].quarter}
                                                            kUnits={item[1].sum}
                                                            pp={item[1].pp}
                                                            growth={item[1].growth}
                                                            growthPYG={item[1].growthPYG}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 position-relative" style={{ zIndex: 2, flexGrow: 1, minHeight: '30rem' }}>
                        <div className={`${styles['finance-table-container__product-last-row']}`} style={{ padding: 0, paddingRight: '1rem' }}></div>
                        <div className="col-auto px-0" style={{ width: '20rem' }}>
                            <Header type="product" title={data.productName} />
                        </div>
                        <div className="col px-0">
                            <div className="row mx-0 w-100 d-flex justify-content-between align-items-center h-100">
                                {data.productData &&
                                    Object.entries(data.productData).length > 0 &&
                                    Object.entries(data.productData).map((item, index) => {
                                        return (
                                            <div key={index} className="col-auto px-0 h-100">
                                                <div className="row mx-0 w-100 h-100">
                                                    {renderVerticalLine(data.productData, index, 'product')}
                                                    <div
                                                        key={index}
                                                        className="col-auto px-0 h-100"
                                                        style={{
                                                            width: '10rem',
                                                        }}
                                                    >
                                                        <RowContent
                                                            hideDate
                                                            type={'product'}
                                                            year={item[1].year}
                                                            quarter={item[1].quarter}
                                                            kUnits={item[1].sum}
                                                            pp={item[1].pp}
                                                            growth={item[1].growth}
                                                            growthPYG={item[1].growthPYG}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className={styles['finance-table-container__last-column-background']}></div>
                </Scrollable>
            </div>
        </ComponentWrapper>
    )
})
