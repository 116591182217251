import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const HospitalAccountInformationTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    return (
        <div
            style={{
                position: 'fixed',
                top: props.loc.top,
                left: props.loc.left,
                transition: 'all 0.3s ease'
            }}
        >
            <TooltipContainer color={props.loc.color}>
                <TooltipHeaderRow
                    titleImage={props.loc.productImage}
                    value={props.loc.productName}
                />
            </TooltipContainer>
        </div>
    );
});
