import React from 'react'
import PropTypes from 'prop-types'
import './sub-goal-progress-tooltip.scss'
import { useTranslation } from 'react-i18next'
import { useCursorTrackingProvider } from '../../../../../providers/cursor-tracking-context'
import { TooltipHeaderRow } from '../../../tooltip-header-row/tooltip-header-row'
import { TooltipContentRow } from '../../../tooltip-content-row/tooltip-content-row'
import { Separator } from '../../../../formatting/separator/separator'
import { getValueInString } from '../../../../../utils/unit'

export const SubGoalProgressTooltip = React.memo((props) => {
    const { position } = useCursorTrackingProvider()
    const { i18n } = useTranslation()
    return (
        <div
            className="sub-goal-progress-tooltip"
            style={{
                top: position.y,
                left: position.x,
                borderColor: props.item.color,
                transition: 'all 0.3s ease-in-out',
                display: position.x === 0 && position.y === 0 ? 'none' : 'block',
            }}
        >
            <TooltipHeaderRow title={'Sub-Goal'} value={props.item.name} />
            <Separator />
            <TooltipContentRow title={'Progress'} value={props.item.progress + '%'} color={props.item.color} />
        </div>
    )
})
