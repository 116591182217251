import React from 'react';
import { ApprovalRequestList } from '../ApprovalRequestList/ApprovalRequestList';

export const GoalApprovals = () => {
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className="dashboard-sub-title">
                        Here you will find the goals created that need your
                        approval.
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <ApprovalRequestList />
                </div>
            </div>
        </>
    );
};
