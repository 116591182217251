import React from 'react';
import { has } from 'lodash';
import 'bootstrap/dist/css/bootstrap.css';

import styles from './option-list-old.module.scss';

export const OptionListOld = React.memo(
    ({ attr, id, label, type, options, onClickOption, position, total }) => {
        return (
            <div
                className={
                    position === 'up'
                        ? `${styles['option-list-old-container']} ${styles['option-list-old-container--up']}`
                        : `${styles['option-list-old-container']} ${styles['option-list-old-container--down']}`
                }
            >
                <div className="row m-0 mb-2">
                    <div
                        className={`col p-0 d-flex justify-content-start align-items-center ${styles['option-list-old-container__title-container']}`}
                    >
                        <span
                            className={`${styles['option-list-old-container__title']}`}
                        >
                            {total ? total + ' ' + label : label}
                        </span>
                    </div>
                </div>
                {options.map((option, index) => {
                    if (type === 'checkbox') {
                        return (
                            <div
                                key={index}
                                className={`row mx-0 ${styles['option-list-old-container__option']}`}
                                onClick={
                                    id !== undefined
                                        ? (e) => {
                                              onClickOption(
                                                  attr,
                                                  id,
                                                  option.name
                                              );
                                              e.stopPropagation();
                                          }
                                        : (e) => {
                                              onClickOption(
                                                  attr,
                                                  undefined,
                                                  option.name
                                              );
                                              e.stopPropagation();
                                          }
                                }
                            >
                                <div className="col-auto d-flex justify-content-between align-items-end p-0">
                                    {has(option, 'icon') &&
                                        option.icon === 'default' && (
                                            <img
                                                src={option.iconDefault}
                                                alt="option"
                                                className={`${styles['option-list-old-container__option__icon']} me-2`}
                                            />
                                        )}
                                    {has(option, 'icon') &&
                                        option.icon !== 'default' && (
                                            <img
                                                loading="lazy"
                                                src={option.iconSelected}
                                                alt="option"
                                                className={`${styles['option-list-old-container__option__icon']} me-2`}
                                            />
                                        )}
                                    <span
                                        className={`${styles['option-list-old-container__option__text']}`}
                                    >
                                        {option.label
                                            ? option.label
                                            : option.name}
                                    </span>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div
                                key={index}
                                className={`row mx-0 ${styles['option-list-old-container__option']}`}
                                onClick={
                                    id !== undefined
                                        ? (e) => {
                                              onClickOption(
                                                  attr,
                                                  id,
                                                  option.name
                                              );
                                              e.stopPropagation();
                                          }
                                        : (e) => {
                                              onClickOption(
                                                  attr,
                                                  undefined,
                                                  option.name
                                              );
                                              e.stopPropagation();
                                          }
                                }
                            >
                                <div className="col-auto d-flex justify-content-between align-items-center p-0">
                                    {has(option, 'icon') && (
                                        <img
                                            loading="lazy"
                                            src={option.icon}
                                            alt="option"
                                            className={`${styles['option-list-old-container__option__icon']} me-2`}
                                        />
                                    )}
                                    <span
                                        className={`${styles['option-list-old-container__option__text']}`}
                                        style={{
                                            color:
                                                has(option, 'color') &&
                                                option.color
                                        }}
                                    >
                                        {option.label
                                            ? option.label
                                            : option.name}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }
);
