import React from 'react'
import { getValueInString } from '../../../../../utils/unit'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import _ from 'lodash'
import { formatCurrency } from '../../../../../utils/math'

export const SegmentRevenueOverTimeTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.active && props.payload && props.payload.length > 0) {
        return (
            <TooltipContainer color={props.payload[0].color}>
                <>
                    <TooltipHeaderRow title={props.payload[0].payload['month']} />
                    <Separator />
                    <TooltipContentRow
                        title={t('tooltips:content.revenue')}
                        value={formatCurrency(props.payload[0].payload['value'], 'EUR')}
                        color={props.payload[0].color}
                    />
                </>
            </TooltipContainer>
        )
    } else return null
})
