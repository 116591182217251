import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styles from './custom-entry-table.module.scss';
import Scrollable from '../../scrolling/scrollable';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ToggleButton } from '../../inputs/toggle-button/toggle-button';
import { useToggle } from '../../../hooks/useToggle';
import icoArrow from '../../../assets/images/dropdown-arrow.svg';
import icoPlus from '../../../assets/images/icoPlus.svg';
import icoMinus from '../../../assets/images/icoMinus.svg';

export const CustomEntryTable = React.memo(
    ({
        isEditing,
        matrix,
        onSetMatrix,
        columnWidths,
        onSetColumnWidths,
        hasHeaderRow,
        onSetHasHeaderRow,
        hasHeaderColumn,
        onSetHasHeaderColumn
    }) => {
        const tableRef = useRef(null);
        const [tableHeight, setTableHeight] = useState(0);
        const optionsRef = useRef(null);
        const [width, height] = useWindowSize();
        const [tableLeft, setTableLeft] = useState(0);
        const [tableTop, setTableTop] = useState(0);
        const [isOptionsOpen, setIsOptionsOpen] = useToggle(false);
        const [isInputFocusd, setIsInputFocused] = useState(false);

        const updateColumnWidth = (columnIndex, newWidth) => {
            // Create a new array to avoid directly mutating the state
            const newWidths = [...columnWidths];
            newWidths[columnIndex] = newWidth;
            onSetColumnWidths(newWidths);
        };

        const handleCalculateTablePosition = useCallback(() => {
            if (tableRef.current && isEditing) {
                const tableRect = tableRef.current.getBoundingClientRect();
                const parentRect =
                    tableRef.current.parentElement.getBoundingClientRect();

                // Check if the table overflows on the right side
                if (tableRect.right > parentRect.right) {
                    setTableLeft(parentRect.left + parentRect.width);
                } else {
                    setTableLeft(tableRect.left + tableRect.width);
                }

                setTableTop(parentRect.bottom - parentRect.height);
            }
        }, [width, height, isEditing]);

        const addRow = useCallback(async () => {
            await onSetMatrix((prevMatrix) => [
                ...prevMatrix,
                new Array(prevMatrix[0].length).fill('')
            ]);
            handleCalculateTablePosition();
            if (tableRef.current)
                setTableHeight(tableRef.current.getBoundingClientRect().height);
        }, [handleCalculateTablePosition, onSetMatrix]);

        const adjustAllRowHeights = useCallback(() => {
            const rows = tableRef.current.querySelectorAll('tr');
            rows.forEach(adjustRowHeight);
        }, []);

        useEffect(() => {
            adjustAllRowHeights();
            if (tableRef.current)
                setTableHeight(tableRef.current.getBoundingClientRect().height);
        }, [adjustAllRowHeights]);

        const addColumn = useCallback(async () => {
            await onSetMatrix((prevMatrix) =>
                prevMatrix.map((row) => [...row, ''])
            );
            setTimeout(adjustAllRowHeights, 0); // Adjust heights after state update
            handleCalculateTablePosition();
            if (tableRef.current)
                setTableHeight(tableRef.current.getBoundingClientRect().height);
        }, [adjustAllRowHeights, handleCalculateTablePosition, onSetMatrix]);

        const updateCell = (rowIndex, colIndex, value) => {
            const updatedMatrix = matrix.map((row, rIndex) => {
                if (rIndex === rowIndex) {
                    return row.map((cell, cIndex) => {
                        if (cIndex === colIndex) {
                            return value;
                        }
                        return cell;
                    });
                }
                return row;
            });

            onSetMatrix(updatedMatrix);
        };

        const navigateInput = (rowIndex, colIndex, shiftKey, isTab) => {
            let newRowIndex = rowIndex;
            let newColIndex = colIndex;

            if (isTab) {
                if (shiftKey) {
                    if (colIndex > 0) {
                        newColIndex -= 1;
                    } else if (rowIndex > 0) {
                        newRowIndex -= 1;
                        newColIndex = matrix[0].length - 1;
                    }
                } else {
                    if (colIndex < matrix[0].length - 1) {
                        newColIndex += 1;
                    } else if (rowIndex < matrix.length - 1) {
                        newRowIndex += 1;
                        newColIndex = 0;
                    }
                }
            } else {
                if (shiftKey) {
                    newRowIndex = rowIndex > 0 ? rowIndex - 1 : 0;
                } else {
                    newRowIndex =
                        rowIndex < matrix.length - 1 ? rowIndex + 1 : rowIndex;
                }
            }

            const nextInput = document.querySelector(
                `#input-${newRowIndex}-${newColIndex}`
            );
            nextInput?.focus();
        };

        const handleKeyDown = (e, rowIndex, colIndex) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                navigateInput(rowIndex, colIndex, e.shiftKey, false);
            } else if (e.key === 'Tab') {
                e.preventDefault();
                navigateInput(rowIndex, colIndex, e.shiftKey, true);
            }
        };

        useEffect(() => {
            handleCalculateTablePosition();
        }, [handleCalculateTablePosition]);

        const handleOutsideClick = useCallback(
            (e) => {
                if (tableRef.current && !tableRef.current.contains(e.target)) {
                    setIsInputFocused(false);
                }
                if (
                    optionsRef.current &&
                    !optionsRef.current.contains(e.target)
                ) {
                    setIsOptionsOpen(false);
                }
            },
            [setIsInputFocused, setIsOptionsOpen]
        );

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick, false);

            return () => {
                document.removeEventListener(
                    'mousedown',
                    handleOutsideClick,
                    false
                );
            };
        }, [handleOutsideClick]);

        const adjustRowHeight = (row) => {
            let maxRowHeight = 0;
            row.querySelectorAll('textarea').forEach((textarea) => {
                // Reset height to ensure accurate scrollHeight calculation
                textarea.style.height = 'auto';
                maxRowHeight = Math.max(maxRowHeight, textarea.scrollHeight);
            });

            // Set all textareas in the row to the max height
            row.querySelectorAll('textarea').forEach((textarea) => {
                textarea.style.height = `${maxRowHeight}px`;
            });
        };

        const handleInputChange = (e, rowIndex, colIndex) => {
            updateCell(rowIndex, colIndex, e.target.value);

            // Adjust the height of all textareas in the same row
            const row = e.target.closest('tr');
            adjustRowHeight(row);
            if (tableRef.current)
                setTableHeight(tableRef.current.getBoundingClientRect().height);
        };

        useEffect(() => {
            // Adjust all textareas height after component mounts
            const textareas = tableRef.current.querySelectorAll('textarea');
            textareas.forEach((textarea) => {
                textarea.style.height = 'auto'; // Reset height to recalculate
                textarea.style.height = `${textarea.scrollHeight}px`;
            });
        }, []); // Run only once after mount

        const handleMouseDown = (columnIndex, event) => {
            const startX = event.clientX;
            const startWidth =
                tableRef.current.querySelectorAll('tr')[0].children[columnIndex]
                    .offsetWidth;

            const handleMouseMove = (moveEvent) => {
                const newWidth = startWidth + (moveEvent.clientX - startX);
                // Update the width of the column
                // Assuming you have a state or method to update the width of the column
                updateColumnWidth(columnIndex, newWidth);
            };

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        };

        const removeRow = useCallback(() => {
            if (matrix.length > 2) {
                const newMatrix = matrix.slice(0, -1);
                onSetMatrix(newMatrix);
                handleCalculateTablePosition();
            }
        }, [matrix, handleCalculateTablePosition, onSetMatrix]);

        const removeColumn = useCallback(() => {
            if (matrix[0].length > 2) {
                const newMatrix = matrix.map((row) => row.slice(0, -1));
                onSetMatrix(newMatrix);
                handleCalculateTablePosition();
            }
        }, [matrix, handleCalculateTablePosition, onSetMatrix]);

        return (
            <div
                className={`${styles['custom-entry-table-container']}`}
                style={{
                    overflow: 'auto'
                }}
            >
                <div className="row mx-0 w-100">
                    <div className="col px-0">
                        <div className="d-flex justify-content-start align-items-center">
                            <table
                                cellPadding={0}
                                cellSpacing={0}
                                ref={tableRef}
                            >
                                <tbody>
                                    {matrix.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {row.map((cell, colIndex) => (
                                                <td
                                                    key={colIndex}
                                                    style={{
                                                        position: 'relative',
                                                        overflow: 'visible',
                                                        background:
                                                            rowIndex === 0
                                                                ? '#f0f0f0'
                                                                : '#fff',
                                                        width:
                                                            columnWidths[
                                                                colIndex
                                                            ] + 'px' || 'auto',
                                                        borderRadius:
                                                            rowIndex === 0 &&
                                                            colIndex === 0
                                                                ? '0.5rem 0 0 0'
                                                                : rowIndex ===
                                                                      0 &&
                                                                  colIndex ===
                                                                      row.length -
                                                                          1
                                                                ? '0 0.5rem 0 0'
                                                                : rowIndex ===
                                                                      matrix.length -
                                                                          1 &&
                                                                  colIndex === 0
                                                                ? '0 0 0 0.5rem'
                                                                : rowIndex ===
                                                                      matrix.length -
                                                                          1 &&
                                                                  colIndex ===
                                                                      row.length -
                                                                          1
                                                                ? '0 0 0.5rem 0'
                                                                : '0'
                                                    }}
                                                >
                                                    {/* {rowIndex === 0 && isEditing && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: 0,
                                                    zIndex: 1,
                                                    bottom: 0,
                                                    width: '1rem',
                                                    height: `${tableHeight}px`,
                                                    background: '#2c3c8d',
                                                    cursor: 'pointer'
                                                }}
                                                onMouseDown={(e) =>
                                                    handleMouseDown(colIndex, e)
                                                }
                                            />
                                        )} */}
                                                    <textarea
                                                        rows={1}
                                                        id={`input-${rowIndex}-${colIndex}`}
                                                        value={cell}
                                                        className={`${styles['custom-entry-table-container__input']}`}
                                                        style={
                                                            hasHeaderColumn &&
                                                            colIndex === 0
                                                                ? {
                                                                      background:
                                                                          rowIndex ===
                                                                          0
                                                                              ? '#f0f0f0'
                                                                              : '#fff',
                                                                      overflow:
                                                                          'hidden',
                                                                      pointerEvents:
                                                                          isEditing
                                                                              ? 'all'
                                                                              : 'none'
                                                                  }
                                                                : hasHeaderRow &&
                                                                  rowIndex === 0
                                                                ? {
                                                                      background:
                                                                          rowIndex ===
                                                                          0
                                                                              ? '#f0f0f0'
                                                                              : '#fff',
                                                                      overflow:
                                                                          'hidden',
                                                                      pointerEvents:
                                                                          isEditing
                                                                              ? 'all'
                                                                              : 'none'
                                                                  }
                                                                : {
                                                                      background:
                                                                          rowIndex ===
                                                                          0
                                                                              ? '#f0f0f0'
                                                                              : '#fff',
                                                                      overflow:
                                                                          'hidden',
                                                                      pointerEvents:
                                                                          isEditing
                                                                              ? 'all'
                                                                              : 'none'
                                                                  }
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                rowIndex,
                                                                colIndex
                                                            )
                                                        }
                                                        onKeyDown={(e) =>
                                                            handleKeyDown(
                                                                e,
                                                                rowIndex,
                                                                colIndex
                                                            )
                                                        }
                                                        onFocus={() =>
                                                            setIsInputFocused(
                                                                true
                                                            )
                                                        }
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    {/* {isEditing && (
                                        <tr>
                                            <td
                                                colSpan={matrix[0].length}
                                                style={{
                                                    position: 'relative',
                                                    border: 'none'
                                                }}
                                            >
                                                <button
                                                    className={`${styles['custom-entry-table-container__add-row-button']}`}
                                                    onClick={addRow}
                                                >
                                                    +
                                                </button>
                                                {matrix.length > 2 && (
                                                    <button
                                                        className={`${styles['custom-entry-table-container__remove-row-button']}`}
                                                        onClick={removeRow}
                                                    >
                                                        -
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    )} */}
                                    <tr>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '100%',
                                                transform:
                                                    'translate(-50%, -0%)'
                                            }}
                                        >
                                            {isEditing && (
                                                <>
                                                    <img
                                                        className={`${styles['custom-entry-table-container__add-row-button']}`}
                                                        onClick={addRow}
                                                        src={icoPlus}
                                                        alt="plus"
                                                    />
                                                    {matrix.length > 2 && (
                                                        <img
                                                            className={`${styles['custom-entry-table-container__remove-row-button']}`}
                                                            onClick={removeRow}
                                                            src={icoMinus}
                                                            alt="minus"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </tr>
                                </tbody>
                                {/* {tableRef.current &&
                                    isEditing &&
                                    isInputFocusd && (
                                        <div
                                            className={`${styles['custom-entry-table-container__options']}`}
                                            ref={optionsRef}
                                            style={{
                                                position: 'fixed',
                                                transition:
                                                    'all 0.3s ease-in-out',
                                                left: tableLeft,
                                                top: tableTop,
                                                zIndex: 10,
                                                transform:
                                                    'translate(-100%, calc(-100% - 1rem))'
                                            }}
                                        >
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <div
                                                        className={`${styles['custom-entry-table-container__options__option']}`}
                                                        onClick={() =>
                                                            setIsOptionsOpen()
                                                        }
                                                    >
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={`${styles['custom-entry-table-container__options__option__title']}`}
                                                                >
                                                                    Options
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="col-auto px-0"
                                                                style={{
                                                                    width: '2rem'
                                                                }}
                                                            ></div>
                                                            <div className="col-auto px-0">
                                                                <img
                                                                    src={
                                                                        icoArrow
                                                                    }
                                                                    alt="arrow"
                                                                    className={`${styles['custom-entry-table-container__options__option__arrow']}`}
                                                                    style={{
                                                                        transform:
                                                                            isOptionsOpen
                                                                                ? 'rotate(180deg)'
                                                                                : 'rotate(0deg)'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {isOptionsOpen && (
                                                <div
                                                    className={`${styles['custom-entry-table-container__options__content']}`}
                                                >
                                                    <div
                                                        className="row mx-0 w-100 d-flex justify-content-between"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col-auto px-0 d-flex align-items-center">
                                                            <span
                                                                className={`${styles['custom-entry-table-container__options__subtitle']}`}
                                                            >
                                                                Header Row
                                                            </span>
                                                        </div>

                                                        <div className="col-auto px-0">
                                                            <ToggleButton
                                                                bool={
                                                                    hasHeaderRow
                                                                }
                                                                onClick={() =>
                                                                    onSetHasHeaderRow(
                                                                        (
                                                                            prev
                                                                        ) =>
                                                                            !prev
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100 d-flex justify-content-between">
                                                        <div className="col-auto px-0 d-flex align-items-center">
                                                            <span
                                                                className={`${styles['custom-entry-table-container__options__subtitle']}`}
                                                            >
                                                                Header Column
                                                            </span>
                                                        </div>

                                                        <div className="col-auto px-0">
                                                            <ToggleButton
                                                                bool={
                                                                    hasHeaderColumn
                                                                }
                                                                onClick={() =>
                                                                    onSetHasHeaderColumn(
                                                                        (
                                                                            prev
                                                                        ) =>
                                                                            !prev
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )} */}
                            </table>
                            {isEditing && (
                                <>
                                    <img
                                        className={`${styles['custom-entry-table-container__add-column-button']}`}
                                        onClick={addColumn}
                                        src={icoPlus}
                                        alt="plus"
                                    />
                                    {matrix[0].length > 2 && (
                                        <img
                                            className={`${styles['custom-entry-table-container__remove-column-button']}`}
                                            onClick={removeColumn}
                                            src={icoMinus}
                                            alt="minus"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
