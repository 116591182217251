import styles from './product-frequently-bought-together.module.scss'

import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { ProductsService } from '../../../temp/test'
import { productFrequentlyBoughtTogetherMock } from './mock'

import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component'
import { ContentContainer, Loader, Spinner } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

/**
 * Displays a list of products
 */

export const ProductFrequentlyBoughtTogether = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
    }) => {
        const { filter } = useFilter()
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()

        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(productFrequentlyBoughtTogetherMock)

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(productFrequentlyBoughtTogetherMock)
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true)
                        }
                        if (type) dataClone.attr = type
                        let dataBack
                        if (filter && filter.productId.constructor === String && filter.productId && config.filter.includes('productId')) {
                            dataBack = await ProductsService.getApiProductsFrequentlyBoughtTogether({
                                productId: filter.productId,
                            })
                        }
                        if (dataBack && dataBack.length !== 0) {
                            dataClone.itemList.length = 0
                            dataBack.map((el) => {
                                const newEl = {
                                    name: el.title ? el.title : '-',
                                    description: el.descripton ? el.descripton : '-',
                                    id: el.id ? el.id : '-',
                                    price: el.price ? '€' + el.price : '-',
                                    image: el.image_url,
                                }
                                dataClone.itemList.push(newEl)
                                return el
                            })
                            dataClone.loading = false
                            dataClone.noData = false
                        } else {
                            dataClone.itemList.length = 0
                            dataClone.loading = false
                            dataClone.noData = false
                        }

                        if (dataClone.settings.view.options.length === 0 && dataClone.settings.options.options.length === 0) dataClone.disabled = true
                        else dataClone.disabled = false
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                    } else {
                        if (type) dataClone.attr = type
                        verifyComponent(dataClone)
                        setValue(dataClone)
                        setLoading(false)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            }
            fill()
        }, [config, filter, isExample, isMounted, setLoading, setValue, t, type])
        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings()
                if (option === 'pin') {
                    onPinComponent(data.attr)
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr)
                } else if (option === 'download') {
                    onDownloadComponent()
                }
            },
            [data.attr, isMounted, onDownloadComponent, onPinComponent, onUnpinComponent, toggleComponentSettings]
        )

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    <div className={`${styles['list-container']}`}>
                        {data.loading && <Spinner />}
                        {data.itemList.length === 0 && (
                            <div className={`col px-0 h-100 d-flex justify-content-center align-items-center`}>
                                <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                    <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                        <span className={`${styles['no-data-text']}`}>{t(`common:noData`)}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!data.loading &&
                            data.itemList.length !== 0 &&
                            Object.entries(data.itemList).map((item, index) => (
                                <div key={index} className={`row w-100 m-0 ${styles['list-container__list-item']}`}>
                                    <div
                                        className={`col-2 me-2 px-0 ${styles['list-container__image-container']} d-flex justify-content-center align-items-center overflow-hidden`}
                                    >
                                        <img
                                            loading="lazy"
                                            src={item[1].image}
                                            alt="product"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                    <div className="col p-0 me-2">
                                        <div className="row m-0 h-50 pb-3">
                                            <div className="col p-0 d-flex justify-content-start align-items-start flex-column">
                                                <span className={`${styles['list-container__list-item__attr']}`}>
                                                    {t(`components:${data.attr}.name`)}
                                                </span>
                                                <span className={`${styles['list-container__list-item__value']}`}>
                                                    {item[1].name.length > 10 ? item[1].name.substring(0, 10) : item[1].name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row m-0 h-50">
                                            <div className="col p-0 d-flex justify-content-start align-items-start flex-column">
                                                <span className={`${styles['list-container__list-item__attr']}`}>
                                                    {t(`components:${data.attr}.description`)}
                                                </span>
                                                <span className={`${styles['list-container__list-item__value']}`}>
                                                    {item[1].description.length > 10
                                                        ? item[1].description.substring(0, 10) + '...'
                                                        : item[1].description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col p-0">
                                        <div className="row m-0 h-50 pb-3">
                                            <div className="col p-0 d-flex justify-content-start align-items-start flex-column">
                                                <span className={`${styles['list-container__list-item__attr']}`}>
                                                    {t(`components:${data.attr}.id`)}
                                                </span>
                                                <span className={`${styles['list-container__list-item__value']}`}>
                                                    {item[1].id.length > 10 ? item[1].id.substring(0, 10) + '...' : item[1].id}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row m-0 h-50">
                                            <div className="col p-0 d-flex justify-content-start align-items-start flex-column">
                                                <span className={`${styles['list-container__list-item__attr']}`}>
                                                    {t(`components:${data.attr}.price`)}
                                                </span>
                                                <span className={`${styles['list-container__list-item__value']}`}>
                                                    {item[1].price.length > 10 ? item[1].price.substring(0, 10) + '...' : item[1].price}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </ContentContainer>
            </div>
        )
    }
)

ProductFrequentlyBoughtTogether.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
