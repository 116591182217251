import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ReactComponent as IcoSort } from './assets/images/sort-icon.svg'
import styles from './table-improvement.module.scss'
import { TableHeaderFactory } from '../table-header-factory'
import { TableType2Text } from '../table-type-2-text/table-type-2-text'
import { TableColumnFactory } from '../table-column-factory'
import { Pagination } from '../pagination/pagination'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useCustomSort } from './useCustomSort'
import { useSearch } from '../../../hooks/search-provider'
import icoDelete from '../../../assets/images/icoDeleteComponent.svg'
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg'
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg'
import { useSelection } from '../../../providers/selection-context'
import { useTableFilterContext } from '../../../providers/table-filter-context'
import { Draggable } from '../../dnd/Draggable/Draggable'
import { TableDragHandle } from '../../dnd/TableDragHandle/TableDragHandle'
import { useDelete } from '../../../providers/delete-context'

export const TableImprovement = React.memo(
    ({
        attr,
        columns,
        data,
        currentPage,
        pageSize,
        onPageChange,
        onClickActionOption,
        onClickActionIcon,
        onRowClick,
        onRowSelect,
        onSelectAll,
        onUnselectAll,
        type,
        heightFill,
        paginationDisabled,
        onAdd,
        onRemove,
        loading,
        addButtonText,
        removeButtonText,
        noPadding,
        filterColumns,
        onClick,
        noDataText,
        isDraggable,
        newUI,
        ...rest
    }) => {
        const { t } = useCustomTranslation()
        const [hoveredRow, toggleHoveredRow] = useState(false)
        const { selected, setSelected } = useSelection()
        const { handleDelete } = useDelete()
        const { search } = useSearch()
        const [hoveredActions, toggleHoveredActions] = useState(false)
        const { filters } = useTableFilterContext()

        const { sortedData, sortedColumn, handleSort } = useCustomSort({
            path: columns && columns.length > 0 ? columns[0].path : '',
            order: 'asc',
            data: data,
        })

        // New state to track selected rows

        // Method to toggle row selection
        const toggleRowSelection = useCallback(
            (rowIndex) => {
                setSelected((prevSelectedRows) =>
                    prevSelectedRows.includes(rowIndex) ? prevSelectedRows.filter((index) => index !== rowIndex) : [...prevSelectedRows, rowIndex]
                )
            },
            [setSelected]
        )

        // Optionally handle select all rows
        const selectAllRows = useCallback(() => {
            const allRowIds = data.map((item, index) => index) // or any unique identifier from your data
            setSelected(allRowIds)
        }, [data, setSelected])

        const unselectAllRows = useCallback(() => {
            setSelected([])
        }, [setSelected])

        // Incorporate the selection checkbox into the table rows
        const renderRowSelectionCheckbox = useCallback(
            (rowIndex) => {
                return (
                    <img
                        src={selected.includes(rowIndex) ? icoCheckboxSelected : icoCheckboxEmpty}
                        alt="Select"
                        style={{ width: '2.4rem', height: '2.4rem', cursor: 'pointer', pointerEvents: 'all' }}
                        onClick={(e) => {
                            e.stopPropagation() // Prevent row click event
                            toggleRowSelection(rowIndex)
                        }}
                    />
                )
            },
            [selected, toggleRowSelection]
        )

        const renderSortIcon = useCallback(
            (column) => {
                if (sortedColumn && column.path !== sortedColumn.path) return null
                if (sortedColumn && column.path === sortedColumn.path && sortedColumn.order === 'asc')
                    return (
                        <IcoSort
                            className={`${styles['asc-arrow']}`}
                            style={{
                                width: '1rem',
                                height: '1rem',
                            }}
                        />
                    )
                return (
                    <IcoSort
                        style={{
                            width: '1rem',
                            height: '1rem',
                        }}
                    />
                )
            },
            [sortedColumn]
        )
        const count = sortedData ? sortedData.length : 0

        const paginatedData = useMemo(() => {
            if (!sortedData || !currentPage || !pageSize) return sortedData
            const startIndex = (currentPage - 1) * pageSize
            return _.slice(sortedData, startIndex, startIndex + pageSize)
        }, [sortedData, currentPage, pageSize])

        const filteredData = useMemo(() => {
            let filtered = sortedData

            // Apply filters
            if (filters) {
                filters.forEach((filter) => {
                    if (filter.value.length > 0) {
                        filtered = filtered.filter((item) => filter.value.includes(_.get(item, filter.path)))
                    }
                })
            }

            if (search) {
                const searchRegex = new RegExp(_.escapeRegExp(search), 'i')
                filtered = _.filter(filtered, (item) =>
                    _.some(columns, ({ path }) => {
                        const value = _.get(item, path)
                        return value && searchRegex.test(value.toString())
                    })
                )
            }

            return filtered
        }, [sortedData, search, columns, filters])

        const columnsToShow = useMemo(() => {
            return columns.filter(({ show }) => show === undefined || show)
        }, [columns])

        const RowDraggableCheck = useCallback(
            ({ children, index, ...rest }) => {
                return isDraggable ? (
                    <Draggable
                        element={'tr'}
                        {...rest}
                        id={selected && selected.length > 0 && selected.includes(index) ? `${attr}-${selected.join('-')}` : `${attr}-${index}`}
                        preventDrag
                    >
                        {children}
                    </Draggable>
                ) : (
                    <tr {...rest}>{children}</tr>
                )
            },
            [isDraggable, attr, selected]
        )

        return (
            <>
                <div
                    className={
                        heightFill && type
                            ? `${styles['table-improvement-container']} ${styles[`${type}`]}`
                            : type
                            ? `${styles['table-improvement-container']} ${styles[`${type}`]}`
                            : `${styles['table-improvement-container']}`
                    }
                    style={{
                        overflow: isDraggable ? 'visible' : 'hidden',
                        background: newUI ? 'transparent' : '#fff',
                    }}
                >
                    {count === 0 && !loading && (
                        <div
                            className={`row w-100 m-0 ${styles['table-improvement-container__content-row']}`}
                            style={{
                                overflow: isDraggable ? 'visible' : 'auto',
                                padding: noPadding ? '0' : '2rem',
                                paddingTop: '0',
                            }}
                        >
                            <div
                                className={`w-100`}
                                style={{
                                    overflowX: isDraggable ? 'visible' : 'auto',
                                    overflowY: isDraggable ? 'visible' : 'auto',
                                    maxHeight: '100%',
                                    padding: '0',
                                    boxSizing: 'content-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div className={`row mx-0`}>
                                    <div className={`col px-0`}>
                                        <table
                                            className={data && data.length === 0 ? `w-100 h-100 ${styles[`${type}`]}` : `w-100 ${styles[`${type}`]}`}
                                        >
                                            {(!type || (type && type !== 'type-2')) && (
                                                <thead
                                                    className={onRowClick ? `${styles['tr--clickable']}` : ''}
                                                    style={{
                                                        width: count === 0 && !loading ? 'auto' : undefined,
                                                        background: newUI ? 'transparent' : '#fff',
                                                    }}
                                                >
                                                    <tr
                                                        style={{
                                                            background: newUI ? 'transparent' : '#fff',
                                                        }}
                                                    >
                                                        {columnsToShow &&
                                                            columnsToShow.map((column) => {
                                                                return (
                                                                    <th key={column.path} onClick={() => handleSort(column.path, attr)}>
                                                                        <TableHeaderFactory
                                                                            item={{
                                                                                type: column.path,
                                                                                column: column,
                                                                                attr: attr,
                                                                                loading: loading,
                                                                                onSelectAll: onSelectAll,
                                                                                onUnselectAll: onUnselectAll,
                                                                                renderSortIcon: renderSortIcon,
                                                                            }}
                                                                        ></TableHeaderFactory>
                                                                    </th>
                                                                )
                                                            })}
                                                    </tr>
                                                </thead>
                                            )}
                                        </table>
                                    </div>
                                </div>

                                <div
                                    className={`${styles['no-table-improvement-container']} d-flex flex-column justify-content-center align-items-center flex-grow-1`}
                                >
                                    <div className="row mx-0 w-100 h-100">
                                        <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                            <span className="noDataText">{noDataText || t('common:noData')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {count !== 0 && !loading && (
                        <div
                            className={`row w-100 m-0 ${styles['table-improvement-container__content-row']}`}
                            style={{
                                overflow: isDraggable ? 'visible' : 'auto',
                                padding: noPadding ? '0' : '2rem',
                                paddingTop: '0',
                            }}
                        >
                            <div
                                className={`w-100`}
                                style={{
                                    overflowX: isDraggable ? 'visible' : 'auto',
                                    overflowY: isDraggable ? 'visible' : 'auto',
                                    maxHeight: '100%',
                                    padding: '0',
                                    boxSizing: 'content-box',
                                }}
                            >
                                <div className={`row mx-0`}>
                                    <div className={`col px-0`}>
                                        <table
                                            className={data && data.length === 0 ? `w-100 h-100 ${styles[`${type}`]}` : `w-100 ${styles[`${type}`]}`}
                                        >
                                            {(!type || (type && type !== 'type-2')) && (
                                                <thead
                                                    className={onRowClick ? `${styles['tr--clickable']}` : ''}
                                                    style={count === 0 && !loading ? { width: 'auto' } : {}}
                                                >
                                                    <tr
                                                        style={{
                                                            background: newUI ? 'transparent' : '#fff',
                                                        }}
                                                    >
                                                        {isDraggable && <th></th>}
                                                        {setSelected && selected && (
                                                            <th>
                                                                <img
                                                                    src={
                                                                        selected.length === filteredData.length
                                                                            ? icoCheckboxSelected
                                                                            : icoCheckboxEmpty
                                                                    }
                                                                    alt="Select"
                                                                    style={{
                                                                        width: '2.4rem',
                                                                        height: '2.4rem',
                                                                        cursor: 'pointer',
                                                                        pointerEvents: 'all',
                                                                    }}
                                                                    onClick={() =>
                                                                        selected.length === filteredData.length ? unselectAllRows() : selectAllRows()
                                                                    }
                                                                />
                                                            </th>
                                                        )}
                                                        {columnsToShow &&
                                                            columnsToShow.map((column, columnIndex) => {
                                                                return (
                                                                    <th key={column.path} onClick={() => handleSort(column.path, attr)}>
                                                                        <TableHeaderFactory
                                                                            item={{
                                                                                type: column.path,
                                                                                column: column,
                                                                                attr: attr,
                                                                                loading: loading,
                                                                                onSelectAll: onSelectAll,
                                                                                onUnselectAll: onUnselectAll,
                                                                                renderSortIcon: renderSortIcon,
                                                                                filterColumns: filterColumns,
                                                                            }}
                                                                        ></TableHeaderFactory>
                                                                    </th>
                                                                )
                                                            })}
                                                    </tr>
                                                </thead>
                                            )}
                                            <tbody>
                                                {filteredData &&
                                                    filteredData.map((el, index) => {
                                                        return (
                                                            <RowDraggableCheck
                                                                key={index}
                                                                index={index}
                                                                onClick={onRowClick ? () => onRowClick(el, attr) : () => {}}
                                                                onMouseOver={() => {
                                                                    toggleHoveredRow(true)
                                                                }}
                                                                onMouseOut={() => {
                                                                    toggleHoveredRow(false)
                                                                }}
                                                                className={
                                                                    el.forecast && onRowClick && hoveredRow && !hoveredActions
                                                                        ? `${styles['tr--clickable']} ${styles['forecast']}`
                                                                        : el.forecast && onRowSelect
                                                                        ? `${styles['tr--selectable']} ${styles['forecast']}`
                                                                        : el.forecast
                                                                        ? `${styles['forecast']}`
                                                                        : onRowClick && hoveredRow && !hoveredActions
                                                                        ? `${styles['tr--clickable']}`
                                                                        : onRowSelect
                                                                        ? `${styles['tr--selectable']}`
                                                                        : isDraggable
                                                                        ? `${styles['draggable']}`
                                                                        : ''
                                                                }
                                                                style={{
                                                                    position: 'relative',
                                                                    height: '4rem',
                                                                }}
                                                            >
                                                                {isDraggable && (
                                                                    <td width={10}>
                                                                        <TableDragHandle
                                                                            id={
                                                                                selected && selected.length > 0 && selected.includes(index)
                                                                                    ? `${attr}-${selected.join('-')}`
                                                                                    : `${attr}-${index}`
                                                                            }
                                                                        />
                                                                    </td>
                                                                )}

                                                                {selected && <td>{renderRowSelectionCheckbox(index)}</td>}
                                                                {columnsToShow &&
                                                                    columnsToShow.map((column, columnIndex) => {
                                                                        return (
                                                                            <td
                                                                                key={column.path}
                                                                                colSpan={
                                                                                    filterColumns && columnIndex === columnsToShow.length - 1 ? 2 : 1
                                                                                }
                                                                            >
                                                                                {type === 'type-2' && <TableType2Text column={column} />}
                                                                                <TableColumnFactory
                                                                                    item={{
                                                                                        type: column.path,
                                                                                        attr: attr,
                                                                                        index: index,
                                                                                        el: el,
                                                                                        column: column,
                                                                                        onClick,
                                                                                        onClickActionIcon,
                                                                                        onClickActionOption,
                                                                                        onCloseActions: onClickActionIcon,
                                                                                        filteredData: filteredData,
                                                                                        addButtonText: addButtonText,
                                                                                        removeButtonText: removeButtonText,
                                                                                        onAdd: onAdd,
                                                                                        onRemove: onRemove,
                                                                                        onRowSelect: onRowSelect,
                                                                                        onToggleHoveredActions: toggleHoveredActions,
                                                                                        ...rest,
                                                                                    }}
                                                                                ></TableColumnFactory>
                                                                            </td>
                                                                        )
                                                                    })}
                                                                {handleDelete && (
                                                                    <td>
                                                                        <img
                                                                            src={icoDelete}
                                                                            alt="Delete"
                                                                            style={{
                                                                                width: '2rem',
                                                                                height: '2rem',
                                                                                cursor: 'pointer',
                                                                                pointerEvents: 'all',
                                                                                filter: 'brightness(0) saturate(100%) invert(44%) sepia(26%) saturate(3151%) hue-rotate(327deg) brightness(118%) contrast(101%)',
                                                                            }}
                                                                            onClick={() => handleDelete(el)}
                                                                        />
                                                                    </td>
                                                                )}
                                                            </RowDraggableCheck>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {pageSize && count >= pageSize && !paginationDisabled && !loading && (
                        <div
                            className={`row w-100 m-0`}
                            style={{
                                padding: '0rem 2rem',
                                paddingBottom: '1rem',
                            }}
                        >
                            <div className={`col p-0 d-flex justify-content-end`}>
                                <Pagination
                                    itemsCount={data ? data.length : 0}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    onPageChange={onPageChange || (() => {})}
                                    attr={attr}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    }
)

TableImprovement.propTypes = {
    /**
     * String to display placeholder text
     */
    placeholderText: PropTypes.string,
    /**
     * String to display title text
     */
    titleText: PropTypes.string,
    /**
     * Number to display counter in the title
     */
    titleCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * String to find attribute in state where the value is located
     */
    attr: PropTypes.string,
    /**
     * Boolean to display time Options
     */
    showTimeOptions: PropTypes.bool,
    /**
     * Array of objects
     * Each object contains:
     * path: String of attribute in data array of objects
     * label: String to display in column
     * sortable: Bool to allow or block sorting for that column
     */
    columns: PropTypes.array,
    /**
     * Object containing:
     * path: String of sorted attribute
     * order: String of order. Can be 'asc' | 'desc'
     */
    sortColumn: PropTypes.object,
    /**
     * Array of objects
     * Each object contains:
     * [key, value] = [String of attribute, Value of attribute (Int | Float | Bool | String)]
     */
    data: PropTypes.array,
    /**
     * Function to handle clicking on a column header
     */
    onSort: PropTypes.func,
    /**
     * Function to handle clicking on a row
     */
    onRowClick: PropTypes.func,
    /**
     * Total number of items per page
     */
    pageSize: PropTypes.number,
    /**
     * Current pagination page. Initially set to 1 by parent component
     */
    currentPage: PropTypes.number,
    /**
     * Function to change currentPage attribute
     */
    onPageChange: PropTypes.func,
    /**
     * Bool to display chart options
     * */
    showChartOptions: PropTypes.bool,
    /**
     * Array of objects
     * Each object contains the following attributes:
     * name: String to differenciate attribute
     * label: String to display attribute label
     */
    chartOptions: PropTypes.array,
    /**
     * String to choose which chart option is selected
     */
    selectedChartOption: PropTypes.string,
    /**
     * Function to handle click on chart option
     * */
    onClickChartOption: PropTypes.func,
    /**
     * Object containing two attributtes: view | options.
     * Each attribute is an array of objects containing four attributes:
     * name: String to display setting label
     * icon: Image to show icon
     */
    settings: PropTypes.object,
    /**
     * Function to handle click on action icon
     * */
    onClickActionOption: PropTypes.func,
    /**
     * Function to handle click on settings view option
     * */
    onClickSettingsView: PropTypes.func,
    /**
     * Function to handle click on settings normal option
     * */
    onClickSettingsOptions: PropTypes.func,
    /**
     * Object containing two attributtes: view | options.
     * Each attribute is an array of objects containing four attributes:
     * name: String to display setting label
     * icon: Image to show icon
     */
    actions: PropTypes.object,
    /**
     * Bool to disable settings button
     */
    disabled: PropTypes.bool,
    /**
     * String to choose type of table to display
     */
    type: PropTypes.string,
}
