import icoBookmarks from '../assets/images/ico-bookmark.svg';
import icoClientDetail from '../assets/images/ico-client-detail.svg';
import icoClientOverview from '../assets/images/ico-client-overview.svg';
import icoDashboard from '../assets/images/ico-dashboard.svg';
import icoEditProfile from '../assets/images/ico-edit.svg';
import icoLogout from '../assets/images/ico-logout.svg';
import icoMachineLearning from '../assets/images/ico-machinelearning.svg';
import icoMagGlass from '../assets/images/ico-mag-glass.svg';
import icoPage1 from '../assets/images/ico-page-1.svg';
import icoPage2 from '../assets/images/ico-page-2.svg';
import icoPage3 from '../assets/images/ico-page-3.svg';
import icoPage4 from '../assets/images/ico-page-4.svg';
import icoPage5 from '../assets/images/ico-page-5.svg';
import icoPage6 from '../assets/images/ico-page-6.svg';
import icoPage7 from '../assets/images/ico-page-7.svg';
import icoPage8 from '../assets/images/ico-page-8.svg';
import icoPage9 from '../assets/images/ico-page-9.svg';
import icoPage11 from '../assets/images/ico-page-11.svg';
import icoPage12 from '../assets/images/ico-page-12.svg';
import icoPage13 from '../assets/images/ico-page-13.svg';
import icoPage14 from '../assets/images/ico-page-14.svg';
import icoPage15 from '../assets/images/ico-page-15.svg';
import icoPage16 from '../assets/images/ico-page-16.svg';
import icoPage17 from '../assets/images/ico-page-17.svg';
import icoPage18 from '../assets/images/ico-page-18.svg';
import icoPage19 from '../assets/images/ico-page-19.svg';
import icoPage20 from '../assets/images/ico-page-20.svg';
import icoPage21 from '../assets/images/ico-page-21.svg';
import icoPage22 from '../assets/images/ico-page-22.svg';
import icoPage23 from '../assets/images/ico-page-23.svg';
import icoPage24 from '../assets/images/ico-page-24.svg';
import icoProduct from '../assets/images/ico-product.svg';
import icoViewProfile from '../assets/images/ico-profile.svg';
import icoProspects from '../assets/images/ico-prospects.svg';
import icoSales from '../assets/images/ico-sales.svg';
import icoSegmentAnalysis from '../assets/images/ico-segment.svg';
import icoSentimentAnalysis from '../assets/images/ico-sentiment-analysis.svg';
import icoSettings from '../assets/images/ico-cog.svg';
import icoForecasts from '../assets/images/ico-side.svg';
import icoTask from '../assets/images/ico-assign-task.svg';
import icoNotification from '../assets/images/ico-bell.svg';
import icoAnalytics from '../assets/images/icoAnalytics.svg';
import icoUsers from '../assets/images/icoUsers.svg';
import icoConfiguration from '../assets/images/icoConfiguration.svg';
import icoAuthentication from '../assets/images/icoAuthentication.svg';
import icoUserProfile from '../assets/images/icoUserProfile.svg';
import icoUserNotifications from '../assets/images/icoUserNotifications.svg';
import icoUserSettings from '../assets/images/icoUserSettings.svg';
import icoUserLogout from '../assets/images/icoUserLogout.svg';
import icoAllUsers from '../assets/images/icoAllUsers.svg';
import icoRoleManagement from '../assets/images/icoRoleManagement.svg';
import icoCompetitors from '../assets/images/icoCompetitors.svg';
import icoPointsOfSale from '../assets/images/icoPointsOfSale.svg';
import icoClients from '../assets/images/icoClients.svg';
import icoUserActivity from '../assets/images/icoUserActivity.svg';
import icoYourOrganizations from '../assets/images/icoYourOrganizations.svg';
import icoYourTeams from '../assets/images/icoYourTeams.svg';
import icoAuthenticationAndSecurity from '../assets/images/icoAuthenticationAndSecurity.svg';
import icoPeopleAndPermissions from '../assets/images/icoPeopleAndPermissions.svg';
import icoBilling from '../assets/images/icoBilling.svg';
import icoGoalManagement from '../assets/images/icoGoalManagement.svg';

export const iconMap = {
    icoMachineLearning: icoMachineLearning,
    icoMagGlass: icoMagGlass,
    icoViewProfile: icoViewProfile,
    icoEditProfile: icoEditProfile,
    icoNotification: icoNotification,
    icoLogout: icoLogout,
    icoSegmentAnalysis: icoSegmentAnalysis,
    icoBookmarks: icoBookmarks,
    icoDashboard: icoDashboard,
    icoClientOverview: icoClientOverview,
    icoClientDetail: icoClientDetail,
    icoProduct: icoProduct,
    icoForecasts: icoForecasts,
    icoProspects: icoProspects,
    icoSentimentAnalysis: icoSentimentAnalysis,
    icoSettings: icoSettings,
    icoSales: icoSales,
    icoPage1: icoPage1,
    icoPage2: icoPage2,
    icoPage3: icoPage3,
    icoPage4: icoPage4,
    icoPage5: icoPage5,
    icoPage6: icoPage6,
    icoPage7: icoPage7,
    icoPage8: icoPage8,
    icoPage9: icoPage9,
    icoPage11: icoPage11,
    icoPage12: icoPage12,
    icoPage13: icoPage13,
    icoPage14: icoPage14,
    icoPage15: icoPage15,
    icoPage16: icoPage16,
    icoPage17: icoPage17,
    icoPage18: icoPage18,
    icoPage19: icoPage19,
    icoPage20: icoPage20,
    icoPage21: icoPage21,
    icoPage22: icoPage22,
    icoPage23: icoPage23,
    icoPage24: icoPage24,
    icoTask: icoTask,
    icoAnalytics: icoAnalytics,
    icoUsers: icoUsers,
    icoConfiguration: icoConfiguration,
    icoAuthentication: icoAuthentication,
    icoUserProfile: icoUserProfile,
    icoUserNotifications: icoUserNotifications,
    icoUserSettings: icoUserSettings,
    icoUserLogout: icoUserLogout,
    icoAllUsers: icoAllUsers,
    icoRoleManagement: icoRoleManagement,
    icoCompetitors: icoCompetitors,
    icoPointsOfSale: icoPointsOfSale,
    icoClients: icoClients,
    icoUserActivity: icoUserActivity,
    icoYourOrganizations: icoYourOrganizations,
    icoYourTeams: icoYourTeams,
    icoBilling: icoBilling,
    icoAuthenticationAndSecurity: icoAuthenticationAndSecurity,
    icoPeopleAndPermissions: icoPeopleAndPermissions,
    icoGoalManagement: icoGoalManagement
};
