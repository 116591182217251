import styles from './config-competitor.module.scss';

import Joi from 'joi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTextInput } from '../../../hooks/useTextInput';
import { useToggle } from '../../../hooks/useToggle';
import { DefinitionsService } from '../../../temp/test';
import {
    LoaderForeground,
    PrimaryButton,
    Spinner,
    TerciaryButton,
    TypeInput
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { success } from '../../../components/notifications/notification/notification';

/**
 * Provides information about competitor configuration
 */

const ConfigCompetitor = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();

    const [loading, toggleLoading] = useToggle(true);
    const {
        value: distanceComp,
        setValue: setDistanceCompValue,
        setError: setDistanceCompError,
        replaceValue: distanceCompReplace
    } = useTextInput({
        name: 'distanceComp',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const schema = Joi.object({
        distanceComp: Joi.string().required()
    });

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    useEffect(() => {
        async function fill() {
            try {
                const distanceComp =
                    await DefinitionsService.getApiBackofficeSettingsDistanceComp();
                if (isMounted.current) {
                    distanceCompReplace(distanceComp.result.toString());
                    toggleLoading(false);
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [distanceCompReplace, isMounted, toggleLoading]);

    const handleSaveDefinitions = async () => {
        try {
            const result = schema.validate(
                {
                    distanceComp: distanceComp.value
                },
                { abortEarly: false }
            );
            if (isMounted.current) setDistanceCompError();
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'distanceComp') {
                        if (isMounted.current) setDistanceCompError(el.type);
                    }
                });
                return;
            }
            const postDistanceComp = {};
            postDistanceComp.distance_comp = parseInt(distanceComp.value);
            await DefinitionsService.postApiBackofficeSettingsDistanceComp({
                requestBody: { distance_comp: postDistanceComp }
            });
            success({ text: t('notifications:saved') });
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['back-office-config-competitor-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.back-office.title')}
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div
                                className={`col p-0 h-auto d-flex flex-column justify-content-start align-items-center ${styles['back-office-config-competitor-container__content-wrapper']}`}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col ${styles['back-office-config-competitor-container__content']}`}
                                            >
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-competitor-container__content-wrapper__title-container']}`}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['back-office-config-competitor-container__content-wrapper__title-container__title']}`}
                                                        >
                                                            {t(
                                                                'pages:administration.back-office.sections.competitors'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="col-2 px-0 d-flex justify-content-end align-items-center">
                                                        <TerciaryButton
                                                            text={t(
                                                                'buttons:save'
                                                            )}
                                                            onClick={
                                                                handleSaveDefinitions
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <hr
                                                    className={`${styles['back-office-config-competitor-container__separator']}`}
                                                />
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-competitor-container__definitions-container']} flex-grow-1`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-competitor-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0 d-flex justify-content-between align-items-center">
                                                                <span
                                                                    className={`${styles['back-office-config-competitor-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        `inputs:${distanceComp.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    onChangeInput={
                                                                        setDistanceCompValue
                                                                    }
                                                                    {...distanceComp}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default ConfigCompetitor;

ConfigCompetitor.propTypes = {};
