import React, { useMemo, useState } from 'react'
import styles from './PeopleAndPermissions.module.scss'
import { NavigationMenu } from '../../../components'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { Invitations } from './components/invitations/Invitations'
import { PendingInvitations } from './components/pending-invitations/PendingInvitations'
import { Members } from './components/members/Members'
import { Roles } from './components/roles/Roles'

export const PeopleAndPermissions = () => {
    const { t } = useCustomTranslation()
    const navOptions = useMemo(() => {
        return ['members', 'invitation', 'pendingInvite', 'roles']
    }, [])

    const [selectedOption, setSelectedOption] = useState('members')

    return (
        <div className={styles['people-and-permissions-container']}>
            <div className="row w-100 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-between align-items-center">{t('pages:peopleAndPermissions.title')}</div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <NavigationMenu
                        isPageMenu
                        options={navOptions}
                        selectedOption={selectedOption}
                        onChangeOption={(option) => setSelectedOption(option)}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100 flex-grow-1" style={{ minHeight: 0 }}>
                <div className="col px-0">
                    {selectedOption === 'members' && <Members />}
                    {selectedOption === 'invitation' && <Invitations />}
                    {selectedOption === 'pendingInvite' && <PendingInvitations />}
                    {selectedOption === 'roles' && <Roles />}
                </div>
            </div>
        </div>
    )
}
