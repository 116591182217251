import React from 'react'
import styles from './permissions-tree.module.scss'
import { PermissionDetails } from './components/permission-details/permission-details'

export const PermissionTree = ({ category, image, permissions, shouldContainRoles = true }) => {
    return (
        <div className={`${styles['permission-tree']}`}>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <div className="d-flex align-items-center">
                        <img src={image} className={styles['permission-tree__category-image']} alt="permission-tree" />
                        <span className={styles['permission-tree__category-title']}>{category}</span>
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100 position-relative">
                <div
                    className="col-auto px-0"
                    style={{
                        width: '4.5rem',
                    }}
                ></div>
                <div className="col px-0">
                    {permissions &&
                        permissions.length > 0 &&
                        permissions.map((permission, index) => {
                            return (
                                <PermissionDetails
                                    key={index}
                                    {...permission}
                                    index={index}
                                    isLast={index === permissions.length - 1}
                                    shouldContainRoles={shouldContainRoles}
                                />
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
