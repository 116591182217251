import styles from './auxbar-filter-information.module.scss'

import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import arrowDown from '../../../assets/images/dropdown-arrow.svg'
import { useTextInput } from '../../../hooks/useTextInput'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSegmentFilters } from '../../../providers/segment-filters-context'
import { useArray } from '../../../hooks/useArray'
import { SegmentationService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { InfoButton } from '../../buttons/info-button/info-button'
import { ListWrapper, PrimaryButton, TypeInput } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const AuxbarFilterInformation = React.memo(() => {
    const { t } = useCustomTranslation()
    const { handleOpenModal } = useSegmentFilters()
    const isMounted = useIsMounted()
    const [searchedAssets] = useState('')

    const { value: filtersSearch, setValue: setFiltersSearchValue } =
        useTextInput({
            name: 'filtersSearch',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        })
    const [filtersSearchResult, setFiltersSearchResult] = useState('')

    const { array: filterCategories, setArray: setFilterCategories } = useArray(
        []
    )

    const { array: filterOperators, setArray: setFilterOperators } = useArray(
        []
    )

    const handleSearchAssets = () => {
        if (isMounted.current) setFiltersSearchResult(filtersSearch.value)
        const filterCategoriesClone = _.cloneDeep(filterCategories)
        if (filtersSearch.value) {
            const regex = new RegExp(`${filtersSearch.value}`, 'i')
            filterCategoriesClone.map((el) => {
                el.options.map((el2) => {
                    if (t(`layout:auxbar.filters.${el2.name}`).match(regex)) {
                        el.open = !el.open
                    }
                })
            })
        } else {
            filterCategoriesClone.map((el) => {
                el.open = false
            })
        }
        if (isMounted.current) setFilterCategories(filterCategoriesClone)
    }

    const handleOpenFilter = (name) => {
        const filterCategoriesClone = _.cloneDeep(filterCategories)
        filterCategoriesClone.map((el) => {
            if (el.name === name) {
                el.open = !el.open
            }
        })
        if (isMounted.current) setFilterCategories(filterCategoriesClone)
    }

    React.useEffect(() => {
        async function fill() {
            try {
                const newCategories = []
                const results =
                    await SegmentationService.getApiSegmentationRulesEntities()
                const newOperators = [...results.operators]
                results.rules_opts.map((el) => {
                    const newOptions = el.properties.map((el2) => {
                        return { name: el2 }
                    })
                    const newEl = {
                        name: el.entity,
                        grouper: el.grouper,
                        label: t(`layout:auxbar.categories.${el.entity}`),
                        open: false,
                        options: newOptions,
                    }
                    newCategories.push(newEl)
                    return el
                })
                setFilterCategories(newCategories)
                setFilterOperators(newOperators)
            } catch (err) {
                errorHandler(err)
            }
        }
        fill()
    }, [setFilterCategories, setFilterOperators, t])

    return (
        <React.Fragment>
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0 d-flex flex-column h-100">
                    <div
                        className={`row ${styles['auxbar-filter-information__search']}`}
                    >
                        <div className="col px-0">
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <TypeInput
                                        onChangeInput={setFiltersSearchValue}
                                        {...filtersSearch}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end align-items-center mb-4">
                                <div className="col-auto mt-2 px-0">
                                    <PrimaryButton
                                        text={t(`buttons:search`)}
                                        onClick={() => handleSearchAssets()}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                                <div className="col-auto px-0">
                                    {searchedAssets !== '' && (
                                        <span
                                            className={`${styles['auxbar-filter-information__search']}`}
                                        >
                                            {t(
                                                'layout:auxbar.assets.resultfor'
                                            )}{' '}
                                            <span
                                                className={`${styles['auxbar-filter-information__search']}`}
                                            >
                                                {searchedAssets}
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`row w-100 m-0 ${styles['auxbar-filter-information__filter']}`}
                    >
                        <ListWrapper>
                            {Object.entries(filterCategories).map(
                                (attr, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div
                                                className={
                                                    attr[1].open
                                                        ? `row mx-0 w-100 ${styles['auxbar-filter-information__filter__menu']} ${styles['auxbar-filter-information__filter__menu--open']}`
                                                        : `row mx-0 w-100 ${styles['auxbar-filter-information__filter__menu']}`
                                                }
                                                onClick={() =>
                                                    handleOpenFilter(
                                                        attr[1].name
                                                    )
                                                }
                                            >
                                                <div className="col px-0 ">
                                                    <span
                                                        className={`${styles['auxbar-filter-information__filter__menu__text']}`}
                                                    >
                                                        {attr[1].label}
                                                    </span>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <img
                                                        loading="lazy"
                                                        src={arrowDown}
                                                        alt="store open and close action"
                                                        className={
                                                            attr[1].open
                                                                ? `${styles['auxbar-filter-information__filter__menu__icon']} ${styles['open']}`
                                                                : `${styles['auxbar-filter-information__filter__menu__icon']}`
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {attr[1].open && (
                                                <div
                                                    className={`row mx-0 ${styles['auxbar-filter-information__filter__menu__component-row']}`}
                                                >
                                                    {attr[1].options.map(
                                                        (el, index) => {
                                                            const regex =
                                                                new RegExp(
                                                                    `${filtersSearchResult}`,
                                                                    'i'
                                                                )
                                                            if (
                                                                t(
                                                                    `layout:auxbar.filters.${el.name}`
                                                                ).match(regex)
                                                            ) {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`col-12 ${styles['auxbar-filter-information__filter__menu__component-row__component']} d-flex flex-column justify-content-center align-items-center`}
                                                                        onClick={() =>
                                                                            handleOpenModal(
                                                                                el.name,
                                                                                attr[1]
                                                                                    .name,
                                                                                filterOperators
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="row mx-0 w-100 ">
                                                                            <div className="col px-2 d-flex justify-content-start align-items-center">
                                                                                <span
                                                                                    className={`${styles['auxbar-filter-information__filter__menu__component-row__component__title']}`}
                                                                                >
                                                                                    {t(
                                                                                        `layout:auxbar.filters.${el.name}`
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )
                                }
                            )}
                        </ListWrapper>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

AuxbarFilterInformation.propTypes = {}
