import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import styles from './market-share-global-donut.module.scss'
import { marketShareGlobalDonutMock } from './mock'

import { ContentContainer, DonutChart, Loader, Spinner } from '../..'
import _ from 'lodash'
import { SalesService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { CustomPieChart } from '../../charts/custom-pie-chart/custom-pie-chart'
import { getFilterParam } from '../../../utils/params'

/**
 * Displays the count and evolution of new clients compared to the previous month
 */

export const MarketShareGlobalDonut = React.memo(
    ({ isExample, isReportExample, pinned, config, onPinComponent, onUnpinComponent, onMouseEnterInfoIcon, onMouseLeaveInfoIcon, isPreview }) => {
        const { filter } = useFilter()
        const { t } = useCustomTranslation()

        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(marketShareGlobalDonutMock)

        const getMarketShareInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(marketShareGlobalDonutMock)
            if (!isExample) {
                try {
                    setLoading(true)
                    const dataBackSelf = await SalesService.getApiSalesSum({
                        producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                        unitsOrBilling: 'units',
                    })
                    const dataBackGlobal = await SalesService.getApiSalesSum({
                        unitsOrBilling: 'units',
                    })

                    dataClone.donutAttr = [
                        {
                            dataKey: 'data1',
                            valueKey: 'value',
                            nameKey: 'key1',
                        },
                    ]
                    dataClone.legend = [
                        {
                            name: 'My Products',
                            color: '#FD9126',
                            translated: true,
                        },
                        {
                            name: 'Competitors',
                            color: '#FDDCB2',
                            translated: true,
                        },
                    ]
                    dataClone.data = {
                        data1: [
                            {
                                key1: 'My Products',
                                value: dataBackSelf.sum,
                                color: '#FD9126',
                                name: 'My Products',
                                percentage: dataBackSelf.sum / dataBackGlobal.sum,
                            },
                            {
                                key1: 'Competitors',
                                value: dataBackGlobal.sum - dataBackSelf.sum,
                                color: '#FDDCB2',
                                name: 'Competitors',
                                percentage: (dataBackGlobal.sum - dataBackSelf.sum) / dataBackGlobal.sum,
                            },
                        ],
                    }
                    setValue(dataClone)
                    setLoading(false)
                } catch (err) {
                    errorHandler(err)
                }
            } else {
                dataClone.legend = [
                    {
                        name: 'Products Market Share',
                        color: '#FD9126',
                        translated: true,
                    },
                    {
                        name: 'Others',
                        color: '#FDDCB2',
                        translated: true,
                    },
                ]
                setValue(dataClone)
            }
        }, [setLoading, setValue, isExample, config, filter])

        useEffect(() => {
            getMarketShareInformation()
        }, [getMarketShareInformation])

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    // interval={'1M'}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div className={`${styles['market-share-global-donut-container']}`}>
                            <CustomPieChart addSpacingBetween donutAttr={data.donutAttr} attr={data.attr} data={data.data} fillInside />
                        </div>
                    )}
                </ContentContainer>
            </div>
        )
    }
)

MarketShareGlobalDonut.propTypes = {}
