import { jwtDecode } from 'jwt-decode'

import { errorHandler } from './api'
// import ApiClient from '../temp/test/src/ApiClient';

const tokenKey = 'token'
const refreshTokenKey = 'refreshToken'
// export const apiClient = new ApiClient();

export function loginWithJwt(jwt, rjwt) {
    localStorage.setItem(tokenKey, jwt)
    localStorage.setItem(refreshTokenKey, rjwt)
}

export function logout() {
    localStorage.removeItem(tokenKey)
    localStorage.removeItem(refreshTokenKey)
}

export function unauthorizedAccess() {
    localStorage.removeItem(tokenKey)
    localStorage.removeItem(refreshTokenKey)
    window.location = '/'
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey)
        return jwt ? jwtDecode(jwt) : null
    } catch (err) {
        errorHandler(err)
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey)
}

export function getRjwt() {
    return localStorage.getItem(refreshTokenKey)
}

const auth = {
    loginWithJwt,
    logout,
    getCurrentUser,
    getJwt,
    unauthorizedAccess,
}

// Function to decode the base64 part of the token
function decodeToken(token) {
    // Split the token by '.' to get the payload part (second part)
    const tokenParts = token.split('.')
    if (tokenParts.length !== 3) {
        throw new Error('Invalid token format')
    }

    // Base64 decode the payload part
    const payload = tokenParts[1]
    const decodedPayload = atob(payload)

    try {
        // Parse the JSON payload
        const parsedPayload = JSON.parse(decodedPayload)
        return parsedPayload
    } catch (error) {
        throw new Error('Invalid token payload')
    }
}

export default auth
