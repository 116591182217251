/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalisysOverviewSchema } from '../models/AnalisysOverviewSchema';
import type { ClientSentimentSchema } from '../models/ClientSentimentSchema';
import type { MultiChannelOverviewParams } from '../models/MultiChannelOverviewParams';
import type { SentimentAnalysisSchema } from '../models/SentimentAnalysisSchema';
import type { SentimentDistributionParams } from '../models/SentimentDistributionParams';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { TextSentimentSchema } from '../models/TextSentimentSchema';
import type { UserSchema } from '../models/UserSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SentimentService {
    /**
     * creates session
     * @returns SentimentAnalysisSchema call successful
     * @throws ApiError
     */
    public static postApiNlpAnalysis({
        requestBody,
    }: {
        requestBody?: {
            name?: string;
        },
    }): CancelablePromise<SentimentAnalysisSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/analysis',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * updates session
     * @returns SentimentAnalysisSchema call successful
     * @throws ApiError
     */
    public static patchApiNlpAnalysis({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: {
            name?: string;
        },
    }): CancelablePromise<SentimentAnalysisSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/nlp/analysis/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns an overview for all channels of session
     * @returns MultiChannelOverviewParams call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisChannelOverview({
        id,
    }: {
        /**
         * id of analysis
         */
        id: number,
    }): CancelablePromise<MultiChannelOverviewParams> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis/{id}/channel-overview',
            path: {
                'id': id,
            },
        });
    }
    /**
     * returns an overview grouped by channel
     * @returns AnalisysOverviewSchema call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisOverview({
        id,
        channel,
    }: {
        /**
         * id of analysis
         */
        id: number,
        /**
         * string of channel name
         */
        channel?: string,
    }): CancelablePromise<AnalisysOverviewSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis/{id}/overview',
            path: {
                'id': id,
            },
            query: {
                'channel': channel,
            },
        });
    }
    /**
     * keyword wordcloud
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisWordcloud({
        id,
        includeNeutral = true,
    }: {
        id: number,
        includeNeutral?: boolean,
    }): CancelablePromise<{
        keyword_count?: number;
        keyword_distribution?: Record<string, SentimentDistributionParams>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis/{id}/wordcloud',
            path: {
                'id': id,
            },
            query: {
                'include_neutral': includeNeutral,
            },
        });
    }
    /**
     * returns the session by session_id
     * @returns TextSentimentSchema call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysis({
        sessionId,
    }: {
        /**
         * session id.
         */
        sessionId: number,
    }): CancelablePromise<Array<TextSentimentSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis/{session_id}',
            path: {
                'session_id': sessionId,
            },
        });
    }
    /**
     * returns the sentiment over time for certain_keywords
     * @returns SentimentDistributionParams call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisKeywordSentimentOverTime({
        sessionId,
        keywords,
    }: {
        /**
         * session id.
         */
        sessionId: number,
        /**
         * keyword in text
         */
        keywords?: Array<string>,
    }): CancelablePromise<Record<string, Record<string, SentimentDistributionParams>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis/{session_id}/keyword-sentiment-over-time',
            path: {
                'session_id': sessionId,
            },
            query: {
                'keywords': keywords,
            },
        });
    }
    /**
     * returns the sentiment over time for the session analisys (-1 to 1)
     * @returns SentimentDistributionParams call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisSentimentOverTime({
        sessionId,
    }: {
        /**
         * session id.
         */
        sessionId: number,
    }): CancelablePromise<Record<string, SentimentDistributionParams>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis/{session_id}/sentiment-over-time',
            path: {
                'session_id': sessionId,
            },
        });
    }
    /**
     * returns analysis information by session_id
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisInformation({
        sessionId,
        minDate,
        maxDate,
    }: {
        /**
         * session id.
         */
        sessionId: number,
        /**
         * minimum date to filter results.
         */
        minDate?: string,
        /**
         * max date to filter results.
         */
        maxDate?: string,
    }): CancelablePromise<{
        call_sentiment?: number;
        created_by?: UserSchema;
        date_of_creation?: string;
        email_sentiment?: number;
        max_date?: string;
        media_sentiment?: number;
        min_date?: string;
        session_id?: string;
        text_sentiment?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis_information/{session_id}',
            path: {
                'session_id': sessionId,
            },
            query: {
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
    /**
     * returns the session by session_id
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiNlpAnalysisSegmentation({
        sessionId,
        minDate,
        maxDate,
    }: {
        /**
         * session id.
         */
        sessionId: number,
        /**
         * minimum date to filter results.
         */
        minDate?: string,
        /**
         * max date to filter results.
         */
        maxDate?: string,
    }): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/analysis_segmentation/{session_id}',
            path: {
                'session_id': sessionId,
            },
            query: {
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
    /**
     * returns keywords for a document
     * @returns ClientSentimentSchema call successful
     * @throws ApiError
     */
    public static getApiNlpClient({
        clientId,
    }: {
        /**
         * client_id.
         */
        clientId: number,
    }): CancelablePromise<Array<ClientSentimentSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/client',
            query: {
                'client_id': clientId,
            },
        });
    }
    /**
     * post client sentiment
     * @returns ClientSentimentSchema call successful
     * @throws ApiError
     */
    public static postApiNlpClient({
        requestBody,
    }: {
        /**
         * business partner schema
         */
        requestBody?: {
            client_id?: number;
            sentiment?: string;
        },
    }): CancelablePromise<ClientSentimentSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * CREATE DESCRIPTION.
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static deleteApiNlpClient({
        clientId,
    }: {
        /**
         * client_id.
         */
        clientId: number,
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/nlp/client/{client_id}',
            path: {
                'client_id': clientId,
            },
        });
    }
    /**
     * Returns sentiment for a document. Set session_id to negative value for default
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiNlpDemo({
        requestBody,
    }: {
        /**
         * business partner schema
         */
        requestBody?: {
            document?: string;
            input_keywords?: Array<string>;
        },
    }): CancelablePromise<{
        keywords?: Array<{
            sentiment?: {
                label?: string;
                score?: number;
            };
            text?: string;
        }>;
        label?: string;
        score?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/demo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * uploads and parse mbox file. extract emails from 'from_email'
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiNlpExtractCsv({
        formData,
    }: {
        /**
         * mapping csv column names to internal names
         */
        formData?: {
            conversation_id?: string;
            csv?: Blob;
            date?: string;
            from?: string;
            input_keywords?: Array<string>;
            session_id?: number;
            subject?: string;
            text?: string;
            to?: string;
        },
    }): CancelablePromise<{
        sentiment?: Array<{
            TextSentiment?: TextSentimentSchema;
            keywords?: Array<{
                label?: string;
                score?: number;
                text?: string;
            }>;
        }>;
        session_id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/extract_csv',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Uploads and parse mbox file. extract emails from 'from_email'. Set session_id to negative value for default
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiNlpExtractMbox({
        formData,
    }: {
        /**
         * business partner schema
         */
        formData?: {
            client_id?: string;
            from_email?: string;
            input_keywords?: Array<string>;
            mbox?: Blob;
            session_id?: number;
        },
    }): CancelablePromise<{
        sentiment?: Array<{
            TextSentiment?: TextSentimentSchema;
            keywords?: Array<{
                label?: string;
                score?: number;
                text?: string;
            }>;
        }>;
        session_id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/extract_mbox',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * returns the session by session_id
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiNlpKeywordMessageDistribution({
        sessionId,
        minDate,
        maxDate,
    }: {
        /**
         * session id.
         */
        sessionId: number,
        /**
         * minimum date to filter results.
         */
        minDate?: string,
        /**
         * max date to filter results.
         */
        maxDate?: string,
    }): CancelablePromise<Array<{
        label?: string;
        negative?: number;
        neutral?: number;
        positive?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/keyword_message_distribution/{session_id}',
            path: {
                'session_id': sessionId,
            },
            query: {
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
    /**
     * returns keywords for a document.
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiNlpKeywords({
        requestBody,
    }: {
        /**
         * business partner schema
         */
        requestBody?: {
            document?: string;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/keywords',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns the last analysis
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiNlpLastAnalysis(): CancelablePromise<Array<{
        TextSentiment?: TextSentimentSchema;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/last_analysis',
        });
    }
    /**
     * returns a list with all analysis
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiNlpListAnalysis(): CancelablePromise<Array<{
        classification_date?: string;
        created_at?: string;
        created_by?: string;
        keyword_count?: number;
        max_date?: string;
        messages?: number;
        min_date?: string;
        name?: string;
        num_analysis?: number;
        num_distinct_channels?: number;
        num_distinct_clients?: number;
        session_id?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/list_analysis',
        });
    }
    /**
     * Returns sentiment for a document. Set session_id to negative value for default
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiNlpSentiment({
        requestBody,
    }: {
        /**
         * business partner schema
         */
        requestBody?: {
            document?: string;
            input_keywords?: Array<string>;
            session_id?: number;
        },
    }): CancelablePromise<{
        keywords?: Array<{
            label?: string;
            score?: number;
            text?: string;
        }>;
        label?: string;
        score?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/sentiment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns a list of suggested keywords
     * @returns string call successful
     * @throws ApiError
     */
    public static getApiNlpSuggestedKeywords(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nlp/suggested_keywords',
        });
    }
    /**
     * Uploads and parse voice file. Set session_id to negative value for default
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiNlpVoiceSentiment({
        formData,
    }: {
        formData?: {
            client_id?: string;
            from_call?: string;
            input_keywords?: Array<string>;
            session_id?: number;
            wav?: Blob;
        },
    }): CancelablePromise<Array<{
        TextSentiment?: TextSentimentSchema;
        keywords?: Array<{
            label?: string;
            score?: number;
            text?: string;
        }>;
    }>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nlp/voice/sentiment',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
