import React from 'react'
import { TooltipContainer, TooltipContentRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SegmentUsersIncludedTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.item && props.item.index !== undefined) {
        return (
            <TooltipContainer color={'#B7BBE5'}>
                <TooltipContentRow title={t(`tooltips:content.customersIncluded`)} value={props.item.data.data1[0].value} />
                <TooltipContentRow title={t(`tooltips:content.customersExcluded`)} value={props.item.data.data1[1].value} />
            </TooltipContainer>
        )
    }
})
