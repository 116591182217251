import _ from 'lodash'
import { useCallback, useEffect, useReducer } from 'react'

const ACTIONS = {
    LOADING: 'loading',
    RESET: 'reset',
    SET: 'set',
    TOGGLE_SETTINGS: 'toggleSettings',
    RATIO: 'ratio',
    CHECKBOX: 'checkbox',
    TOGGLE_VIEW_OPTION: 'toggleViewOption',
    CLICK_LEGEND: 'clickLegend',
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.CLICK_LEGEND: {
            const index = _.findIndex(state.legend, (el) => {
                return el.id === action.payload.id
            })
            if (index !== -1) {
                return {
                    ...state,
                    legend: [
                        ...state.legend.slice(0, index),
                        {
                            ...state.legend[index],
                            active: _.has(state.legend[index], 'active')
                                ? !state.legend[index].active
                                : true,
                        },
                        ...state.legend.slice(index + 1, state.legend.length),
                    ],
                }
            } else {
                return { ...state }
            }
        }
        case ACTIONS.TOGGLE_VIEW_OPTION: {
            const index = _.findIndex(state.settings.view.options, (el) => {
                return el.name === action.payload
            })
            if (index !== -1) {
                const newViewOptions = _.cloneDeep(state.settings.view.options)
                newViewOptions[index].icon =
                    newViewOptions[index].icon === 'default'
                        ? 'selected'
                        : 'default'
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        view: {
                            ...state.settings.view,
                            options: newViewOptions,
                        },
                    },
                }
            } else {
                return { ...state }
            }
        }
        case ACTIONS.LOADING:
            return {
                ...state,
                loading:
                    typeof action.payload === 'boolean'
                        ? action.payload
                        : !state.loading,
            }
        case ACTIONS.SET:
            return { ...action.payload }
        case ACTIONS.TOGGLE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    open:
                        typeof action.payload === 'boolean'
                            ? action.payload
                            : !state.settings.open,
                },
            }
        case ACTIONS.RESET:
            return { ...action.payload }
        case ACTIONS.RATIO:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    viewOptions: {
                        ...state.settings.viewOptions,
                        ratioOptions: {
                            ...state.settings.viewOptions.ratioOptions,
                            selectedOption: action.payload,
                        },
                    },
                },
            }
        case ACTIONS.CHECKBOX:
            if (
                state.settings.viewOptions.checkboxOptions.selectedOptions.includes(
                    action.payload
                )
            ) {
                const index = _.findIndex(
                    state.settings.viewOptions.checkboxOptions.selectedOptions,
                    (el) => {
                        return el === action.payload
                    }
                )
                if (index !== -1) {
                    return {
                        ...state,
                        settings: {
                            ...state.settings,
                            viewOptions: {
                                ...state.settings.viewOptions,
                                checkboxOptions: {
                                    ...state.settings.viewOptions
                                        .checkboxOptions,
                                    selectedOptions: [
                                        ...state.settings.viewOptions.checkboxOptions.selectedOptions.slice(
                                            0,
                                            index
                                        ),
                                        ...state.settings.viewOptions.checkboxOptions.selectedOptions.slice(
                                            index + 1,
                                            state.settings.viewOptions
                                                .checkboxOptions.selectedOptions
                                                .length
                                        ),
                                    ],
                                },
                            },
                        },
                    }
                }
            } else {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        viewOptions: {
                            ...state.settings.viewOptions,
                            checkboxOptions: {
                                ...state.settings.viewOptions.checkboxOptions,
                                selectedOptions: [
                                    ...state.settings.viewOptions
                                        .checkboxOptions.selectedOptions,
                                    action.payload,
                                ],
                            },
                        },
                    },
                }
            }
    }
}

export function useSimpleComponent(defaultValue, getInformation, isExample) {
    const [value, dispatch] = useReducer(reducer, defaultValue)

    const setLoading = useCallback((value) => {
        dispatch({ type: 'loading', payload: value })
    }, [])

    const toggleComponentSettings = useCallback((value) => {
        dispatch({ type: 'toggleSettings', payload: value })
    }, [])

    const setValue = useCallback((value) => {
        dispatch({ type: 'set', payload: value })
    }, [])

    const reset = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue })
    }, [defaultValue])

    function clickRatioOption(option) {
        dispatch({ type: 'ratio', payload: option })
    }

    function clickCheckboxOption(a, b, option) {
        dispatch({ type: 'checkbox', payload: option })
    }

    const toggleViewOption = useCallback((option) => {
        dispatch({ type: 'toggleViewOption', payload: option })
    }, [])

    const handleClickLegend = useCallback((legend) => {
        dispatch({ type: 'clickLegend', payload: legend })
    }, [])

    const handleGetInformation = useCallback(async () => {
        setLoading(true)
        const data = await getInformation()
        setLoading(false)
        setValue(data)
    }, [getInformation, setLoading, setValue])

    useEffect(() => {
        if (isExample) return
        if (getInformation) handleGetInformation()
    }, [handleGetInformation, isExample, getInformation])

    return {
        value,
        setLoading,
        setValue,
        reset,
        toggleComponentSettings,
        clickRatioOption,
        clickCheckboxOption,
        toggleViewOption,
        handleClickLegend,
        handleGetInformation,
    }
}
