import React from 'react';
import PropTypes from 'prop-types';
import { useModalPopup } from '../../../../../providers/modal-popup-context';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { PrimaryButton } from '../../../../buttons/primary-button/primary-button';
import { ReturnButton } from '../../../../buttons/return-button/return-button';
import { SecondaryButton } from '../../../../buttons/secondary-button/secondary-button';

export const PreviewGenerationHeader = React.memo(
    ({
        templateName,
        onChooseTemplate,
        onReturn,
        templateOption,
        notAvailableCharts
    }) => {
        const { t } = useCustomTranslation();
        const { steps, selectedTutorialStep, currentStep, setCurrentStep } =
            useModalPopup();
        return (
            <div
                className="row w-100 dashboard-header-row d-flex justify-content-center align-items-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col-auto px-0">
                    <SecondaryButton
                        text={t('buttons:discard')}
                        onClick={() => {
                            if (
                                steps &&
                                selectedTutorialStep !== '' &&
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['waitUntilTrigger'] &&
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['triggerPoint'] === 'discard'
                            ) {
                                setCurrentStep(currentStep + 1);
                            }
                            onReturn();
                        }}
                    />
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col-auto px-0">
                    <PrimaryButton
                        text={t('buttons:createDashboard')}
                        disabled={notAvailableCharts.length === 0}
                        onClick={() => {
                            if (
                                steps &&
                                selectedTutorialStep !== '' &&
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['waitUntilTrigger'] &&
                                steps[selectedTutorialStep]['steps'][
                                    currentStep
                                ]['triggerPoint'] === 'createDashboard'
                            ) {
                                setCurrentStep(currentStep + 1);
                            }
                            onChooseTemplate(templateOption);
                        }}
                    />
                </div>
            </div>
        );
    }
);

PreviewGenerationHeader.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
    /**
     * Allows the user to return to the correct template if on the workflow of creating a new dashboard
     */
    selectedOption: PropTypes.string,
    /**
     * Contains the list of library charts to populate the store in case the user updates the page layout
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Contains the list of charts when updating the layout
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Function allowing the user to 'go back'
     */
    onNavigate: PropTypes.func,
    /**
     * Fuction allowing the user to either save a new layout of a published page or creating a new page
     */
    onCreatePage: PropTypes.func
};
