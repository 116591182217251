import _ from 'lodash';
import { useCallback, useReducer } from 'react';

const ACTIONS = {
	SET: 'set',
	RESET: 'reset',
	CHECKBOX: 'checkbox',
    CHECKBOX_ALL: 'checkboxAll',
	ADD_OPTION: 'addOption',
	LIMIT: 'limit',
	MARK_ALL_SELECTED_AS_READ:'markAllSelectedAsRead'
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.MARK_ALL_SELECTED_AS_READ:
			state.options.map(el => {
				if (state.selectedOptions.includes(el.id)){
					el.read = true
				}
			})
			return {...state}
		case ACTIONS.SET:
			return { ...action.payload };

		case ACTIONS.RESET:
			return { ...action.payload };
		case ACTIONS.ADD_OPTION:
			return {
				...state,
				options: [...state.options, action.payload],
			};
        case ACTIONS.CHECKBOX_ALL:
			if (action.payload === true){
				const newSelectedOptions = []
				state.options.map(el => {
					newSelectedOptions.push(el.id)
				})
				return {
					...state,
					selectedOptions: newSelectedOptions
				}
			}
			else if (action.payload === false){
				return {
					...state,
					selectedOptions: []
				}
			}
			else {

				if (_.every(state.options, el=> {
					return state.selectedOptions.includes(el.id)
				})){
					return {
						...state,
						selectedOptions: []
					}
				}
				else{
					const newSelectedOptions = []
					state.options.map(el => {
						newSelectedOptions.push(el.id)
					})
					return {
						...state,
						selectedOptions: newSelectedOptions
					}
				}
			}
		case ACTIONS.CHECKBOX:
			if (state.selectedOptions.includes(action.payload)) {
				const index = _.findIndex(state.selectedOptions, (el) => {
					return el === action.payload;
				});
				if (index !== -1) {
					return {
						...state,
						selectedOptions: [
							...state.selectedOptions.slice(0, index),
							...state.selectedOptions.slice(
								index + 1,
								state.selectedOptions.length
							),
						],
					};
				}
			} else {
				return {
					...state,
					selectedOptions:
						state.limit && state.selectedOptions.length < state.limit
							? [...state.selectedOptions, action.payload]
							: !state.limit ?[...state.selectedOptions,action.payload] :[...state.selectedOptions],
				};
			}
			break
		case ACTIONS.LIMIT:
			return {
				...state,
				limit: action.payload,
			};
	}
}

export function useNotificationsCheckboxInput(defaultValue) {
	const [value, dispatch] = useReducer(reducer, defaultValue);

	const setValue = useCallback((value) => {
		dispatch({ type: 'set', payload: value });
	},[])

	const reset = useCallback(() => {
		dispatch({ type: 'reset', payload: defaultValue });
	}, [defaultValue]);

	function clickCheckboxOption(option) {
		dispatch({ type: 'checkbox', payload: option });
	}

	function addOption(option) {
		dispatch({ type: 'addOption', payload: option });
	}

	function setCheckboxLimit(limit) {
		dispatch({ type: 'limit', payload: limit });
	}

    const clickAllCheckboxOptions = useCallback((toggleValue) => {
        dispatch({ type: 'checkboxAll', payload:toggleValue });
    },[])

	const markAllSelectedAsRead = useCallback(() => {
		dispatch({ type: 'markAllSelectedAsRead' });
	},[])

	return {
		value,
		setValue,
		addOption,
		reset,
		clickCheckboxOption,
		setCheckboxLimit,
        clickAllCheckboxOptions,
		markAllSelectedAsRead
	};
}
