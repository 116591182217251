/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientAccountActionsDefaultSchema } from '../models/ClientAccountActionsDefaultSchema';
import type { ClientAccountCompetitorProductKPIDefaultSchema } from '../models/ClientAccountCompetitorProductKPIDefaultSchema';
import type { ClientAccountDefaultSchema } from '../models/ClientAccountDefaultSchema';
import type { ClientAccountDMUDefaultSchema } from '../models/ClientAccountDMUDefaultSchema';
import type { ClientAccountProductKPIDefaultSchema } from '../models/ClientAccountProductKPIDefaultSchema';
import type { ClientAccountSWOTDefaultSchema } from '../models/ClientAccountSWOTDefaultSchema';
import type { ClientAccountTacticalPlanDefaultSchema } from '../models/ClientAccountTacticalPlanDefaultSchema';
import type { ClientAccountTargetClientsDefaultSchema } from '../models/ClientAccountTargetClientsDefaultSchema';
import type { EndConsumerJourneyDefaultSchema } from '../models/EndConsumerJourneyDefaultSchema';
import type { StakeHolderDefaultSchema } from '../models/StakeHolderDefaultSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ClientAccountService {
    /**
     * list all ClientAccount types
     * @returns ClientAccountDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccount({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, client_id, product_id, mdm_id, medical_resp, sales_resp, access_lead, rating, gms_perc, status, influence_area, influence_area_pop, er_patients_number, product_users, prescriptions, potential_vs_contractual, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'client_id' | 'product_id' | 'mdm_id' | 'medical_resp' | 'sales_resp' | 'access_lead' | 'rating' | 'gms_perc' | 'status' | 'influence_area' | 'influence_area_pop' | 'er_patients_number' | 'product_users' | 'prescriptions' | 'potential_vs_contractual' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ClientAccountDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * gets list of ClientAccountActions by params
     * @returns ClientAccountActionsDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountActionsGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<Array<ClientAccountActionsDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/actions/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountActions
     * @returns ClientAccountActionsDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountActionsUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountActionsDefaultSchema,
    }): CancelablePromise<ClientAccountActionsDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/actions/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountActions
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountActions({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/actions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one ClientAccountActions by id
     * @returns ClientAccountActionsDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountActions({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ClientAccountActionsDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/actions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * list all ClientAccountCompetitorProductKPI types
     * @returns ClientAccountCompetitorProductKPIDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountCompetitorProductKpi({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, client_account_id, name, users, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'client_account_id' | 'name' | 'users' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ClientAccountCompetitorProductKPIDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/competitor-product-kpi',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * gets list of ClientAccountCompetitorProductKPI by params
     * @returns ClientAccountCompetitorProductKPIDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountCompetitorProductKpiGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<Array<ClientAccountCompetitorProductKPIDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/competitor-product-kpi/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountCompetitorProductKPI
     * @returns ClientAccountCompetitorProductKPIDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountCompetitorProductKpiUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountCompetitorProductKPIDefaultSchema,
    }): CancelablePromise<ClientAccountCompetitorProductKPIDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/competitor-product-kpi/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountCompetitorProductKPI
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountCompetitorProductKpi({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/competitor-product-kpi/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * gets list of ClientAccountDMU by params
     * @returns ClientAccountDMUDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountDmusGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<Array<ClientAccountDMUDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/dmus/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountDMU
     * @returns ClientAccountDMUDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountDmusUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountDMUDefaultSchema,
    }): CancelablePromise<ClientAccountDMUDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/dmus/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountDMU
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountDmus({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/dmus/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one ClientAccountDMU by id
     * @returns ClientAccountDMUDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountDmus({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ClientAccountDMUDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/dmus/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get page of ClientAccount types
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiClientAccountPage({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, client_id, product_id, mdm_id, medical_resp, sales_resp, access_lead, rating, gms_perc, status, influence_area, influence_area_pop, er_patients_number, product_users, prescriptions, potential_vs_contractual, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'client_id' | 'product_id' | 'mdm_id' | 'medical_resp' | 'sales_resp' | 'access_lead' | 'rating' | 'gms_perc' | 'status' | 'influence_area' | 'influence_area_pop' | 'er_patients_number' | 'product_users' | 'prescriptions' | 'potential_vs_contractual' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<{
        has_next?: boolean;
        has_prev?: boolean;
        items?: Array<ClientAccountDefaultSchema>;
        page?: number;
        total_items?: number;
        total_pages?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/page',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * gets one of EndConsumerJourney by params
     * @returns EndConsumerJourneyDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountPatientJourneyGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<EndConsumerJourneyDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/patient-journey/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of EndConsumerJourney
     * @returns EndConsumerJourneyDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountPatientJourneyUpsert({
        requestBody,
    }: {
        requestBody: EndConsumerJourneyDefaultSchema,
    }): CancelablePromise<EndConsumerJourneyDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/patient-journey/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deletes file.
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountPatientJourneyFile({
        accountPlanId,
    }: {
        /**
         * ClientAccount id
         */
        accountPlanId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/patient-journey/{account_plan_id}/file',
            path: {
                'account_plan_id': accountPlanId,
            },
            errors: {
                404: `File not found`,
            },
        });
    }
    /**
     * Gets file stream optionally encoded in b64.
     * @returns binary File
     * @throws ApiError
     */
    public static getApiClientAccountPatientJourneyFile({
        accountPlanId,
    }: {
        /**
         * ClientAccount id
         */
        accountPlanId: number,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/patient-journey/{account_plan_id}/file',
            path: {
                'account_plan_id': accountPlanId,
            },
            errors: {
                404: `File not found`,
            },
        });
    }
    /**
     * Uploads file.
     * @returns void
     * @throws ApiError
     */
    public static postApiClientAccountPatientJourneyFile({
        accountPlanId,
        formData,
    }: {
        /**
         * ClientAccount id
         */
        accountPlanId: number,
        /**
         * product_journey_file
         */
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/patient-journey/{account_plan_id}/file',
            path: {
                'account_plan_id': accountPlanId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Invalid file`,
            },
        });
    }
    /**
     * deletes EndConsumerJourney
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountPatientJourney({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/patient-journey/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one EndConsumerJourney by id
     * @returns EndConsumerJourneyDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountPatientJourney({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<EndConsumerJourneyDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/patient-journey/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * list all ClientAccountProductKPI types
     * @returns ClientAccountProductKPIDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountProductKpi({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, client_account_id, kpi_map, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'client_account_id' | 'kpi_map' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ClientAccountProductKPIDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/product-kpi',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * gets one of ClientAccountProductKPI by params
     * @returns ClientAccountProductKPIDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountProductKpiGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<ClientAccountProductKPIDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/product-kpi/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountProductKPI
     * @returns ClientAccountProductKPIDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountProductKpiUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountProductKPIDefaultSchema,
    }): CancelablePromise<ClientAccountProductKPIDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/product-kpi/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountProductKPI
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountProductKpi({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/product-kpi/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * list all StakeHolder types
     * @returns StakeHolderDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountStakeholders({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, client_account_id, mdm_id, name, spec, why, opportunity, is_speaker, is_top_prescriber, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'client_account_id' | 'mdm_id' | 'name' | 'spec' | 'why' | 'opportunity' | 'is_speaker' | 'is_top_prescriber' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<StakeHolderDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/stakeholders',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * gets list of StakeHolder by params
     * @returns StakeHolderDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountStakeholdersGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<Array<StakeHolderDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/stakeholders/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of StakeHolder
     * @returns StakeHolderDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountStakeholdersUpsert({
        requestBody,
    }: {
        requestBody: StakeHolderDefaultSchema,
    }): CancelablePromise<StakeHolderDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/stakeholders/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes StakeHolder
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountStakeholders({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/stakeholders/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one StakeHolder by id
     * @returns StakeHolderDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountStakeholders1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<StakeHolderDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/stakeholders/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * gets one of ClientAccountSWOT by params
     * @returns ClientAccountSWOTDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountSwotGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<ClientAccountSWOTDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/swot/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountSWOT
     * @returns ClientAccountSWOTDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountSwotUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountSWOTDefaultSchema,
    }): CancelablePromise<ClientAccountSWOTDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/swot/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountSWOT
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountSwot({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/swot/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one ClientAccountSWOT by id
     * @returns ClientAccountSWOTDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountSwot({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ClientAccountSWOTDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/swot/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * gets list of ClientAccountTacticalPlan by params
     * @returns ClientAccountTacticalPlanDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountTacticalPlanGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<Array<ClientAccountTacticalPlanDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/tactical-plan/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountTacticalPlan
     * @returns ClientAccountTacticalPlanDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountTacticalPlanUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountTacticalPlanDefaultSchema,
    }): CancelablePromise<ClientAccountTacticalPlanDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/tactical-plan/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountTacticalPlan
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountTacticalPlan({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/tactical-plan/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one ClientAccountTacticalPlan by id
     * @returns ClientAccountTacticalPlanDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountTacticalPlan({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ClientAccountTacticalPlanDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/tactical-plan/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * list all ClientAccountTargetClients types
     * @returns ClientAccountTargetClientsDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountTargetClients({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, client_account_id, spec, hcps, a, b, c, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'client_account_id' | 'spec' | 'hcps' | 'a' | 'b' | 'c' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ClientAccountTargetClientsDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/target-clients',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * gets list of ClientAccountTargetClients by params
     * @returns ClientAccountTargetClientsDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccountTargetClientsGetByParams({
        clientId,
        productId,
    }: {
        /**
         * params
         */
        clientId: string,
        productId: string,
    }): CancelablePromise<Array<ClientAccountTargetClientsDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/target-clients/get-by-params',
            query: {
                'client_id': clientId,
                'product_id': productId,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccountTargetClients
     * @returns ClientAccountTargetClientsDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountTargetClientsUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountTargetClientsDefaultSchema,
    }): CancelablePromise<ClientAccountTargetClientsDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/target-clients/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccountTargetClients
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccountTargetClients({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/target-clients/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * creates or updates partially an instance of ClientAccount
     * @returns ClientAccountDefaultSchema resource updated
     * @throws ApiError
     */
    public static postApiClientAccountUpsert({
        requestBody,
    }: {
        requestBody: ClientAccountDefaultSchema,
    }): CancelablePromise<ClientAccountDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client-account/upsert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ClientAccount
     * @returns void
     * @throws ApiError
     */
    public static deleteApiClientAccount({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/client-account/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one ClientAccount by id
     * @returns ClientAccountDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiClientAccount1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ClientAccountDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client-account/{id}',
            path: {
                'id': id,
            },
        });
    }
}
