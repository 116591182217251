import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeesService, TeamsService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { useTextInput } from '../../../hooks/useTextInput'
import { SuccessModal } from '../success-modal/success-modal'
import { useRatioInput } from '../../../hooks/useRatioInput'
import { useSelectInput } from '../../../hooks/useSelectInput'
import { Modal, PrimaryButton, RadioInput, SecondaryButton, SelectInput, TypeInput } from '../..'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const AddTeamMemberModal = ({ onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation()
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const { value: firstName, setValue: firstNameSetValue } = useTextInput({
        name: 'firstName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const { value: lastName, setValue: lastNameSetValue } = useTextInput({
        name: 'lastName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const {
        value: employeeId,
        toggleDropdown: employeeIdToggleDropdown,
        selectOption: employeeIdSelectOption,
        keyPress: employeeIdKeyPress,
        keyDown: employeeIdKeyDown,
        setValue: employeeIdSetValue,
        replaceOptions: employeeIdReplaceOptions,
    } = useSelectInput({
        name: 'employeeId',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef(),
    })

    const { value: isPlatformUser, clickRatioOption: isPlatformUserClickRadioOption } = useRatioInput({
        name: 'isPlatformUser',
        options: [
            {
                name: 'yes',
                label: t(`inputs:yes`),
                disabled: false,
                blocked: false,
            },
            {
                name: 'no',
                label: t(`inputs:no`),
                disabled: false,
                blocked: false,
            },
        ],
    })

    const { value: isResponsible, clickRatioOption: isResponsibleClickRadioOption } = useRatioInput({
        name: 'isResponsible',
        options: [
            {
                name: 'yes',
                label: t(`inputs:yes`),
                disabled: false,
                blocked: false,
            },
            {
                name: 'no',
                label: t(`inputs:no`),
                disabled: false,
                blocked: false,
            },
        ],
    })

    useEffect(() => {
        async function fill() {
            const dataBack = await EmployeesService.getApiEmployees({})
            const params = new URLSearchParams(location.search)
            const teamsBackData = await TeamsService.getApiTeamsMembers({
                teamId: parseInt(params.get('team-id')),
            })
            const dataBackWithoutTeamMembers = dataBack.filter((el) => {
                return !teamsBackData.some((el2) => {
                    return el2.emp_no === el.emp_no
                })
            })
            employeeIdReplaceOptions(
                dataBackWithoutTeamMembers.map((el) => {
                    return el.last_name ? el.first_name + ' ' + el.last_name : el.first_name
                }),
                dataBackWithoutTeamMembers.map((el) => {
                    return el.emp_no
                })
            )
        }
        fill()
    }, [employeeIdReplaceOptions])

    const handleAddExistingMemberToTeam = async () => {
        try {
            setLoading(true)
            const params = new URLSearchParams(location.search)
            await TeamsService.postApiTeamsMembers({
                teamId: parseInt(params.get('team-id')),
                requestBody: [
                    {
                        is_leader: isResponsible.selectedOption === 'yes' ? true : false,
                        emp_no: employeeId.selectedOptionAux,
                    },
                ],
            })
            if (onEnd) onEnd()
            setIsSuccess(true)
            setLoading(false)
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleAddNonExistingMemberToTeam = async () => {
        try {
            setLoading(true)
            const params = new URLSearchParams(location.search)
            await TeamsService.postApiTeamsMembers({
                teamId: parseInt(params.get('team-id')),
                requestBody: [
                    {
                        is_leader: isResponsible.selectedOption === 'yes' ? true : false,
                        employee: {
                            first_name: firstName.value,
                            last_name: lastName.value,
                        },
                    },
                ],
            })
            if (onEnd) onEnd()
            setIsSuccess(true)
            setLoading(false)
        } catch (err) {
            errorHandler(err)
        }
    }

    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">{t('pages:managerProfile.addnewmembertoteam')}</span>
                    </div>
                </div>
                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                    <div className="col px-0">
                        <RadioInput {...isPlatformUser} direction="horizontal" onClickRatioOption={isPlatformUserClickRadioOption} />
                    </div>
                </div>
                {isPlatformUser.selectedOption === 'no' && (
                    <>
                        <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <TypeInput onChangeInput={firstNameSetValue} {...firstName} />
                            </div>
                        </div>
                        <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <TypeInput onChangeInput={lastNameSetValue} {...lastName} />
                            </div>
                        </div>
                    </>
                )}
                {isPlatformUser.selectedOption === 'yes' && (
                    <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <SelectInput
                                onChangeInput={employeeIdSetValue}
                                onSelectInput={employeeIdSelectOption}
                                onKeyPress={employeeIdKeyPress}
                                onKeyDown={employeeIdKeyDown}
                                onToggleDropdown={employeeIdToggleDropdown}
                                {...employeeId}
                            />
                        </div>
                    </div>
                )}
                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                    <div className="col px-0">
                        <RadioInput {...isResponsible} direction="horizontal" onClickRatioOption={isResponsibleClickRadioOption} />
                    </div>
                </div>
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton type={'cancel'} text={t('modals:cancel')} onClick={onCloseModal} />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('modals:save')}
                            disabled={
                                !isPlatformUser.selectedOption ||
                                !isResponsible.selectedOption ||
                                (isPlatformUser.selectedOption === 'no' && (firstName.value === '' || lastName.value === '')) ||
                                (isPlatformUser.selectedOption === 'yes' && employeeId.value === '')
                            }
                            onClick={isPlatformUser.selectedOption === 'yes' ? handleAddExistingMemberToTeam : handleAddNonExistingMemberToTeam}
                        />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return <SuccessModal text={t('modals:addTeamMember.success')} onCloseModal={() => onCloseModal(false)} />
    }
}
