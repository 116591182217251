import React from 'react';
import styles from './goal-target-value-table-column.module.scss';
import { formatCurrency } from '../../../../../utils/math';

export const GoalTargetValueTableColumn = React.memo(({ el, type }) => {
    return (
        <div className={styles['goal-target-value-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    <div className="row mx-0 w-100">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '1rem' }}
                        >
                            <span
                                className={
                                    styles[
                                        'goal-target-value-table-column-container__text'
                                    ]
                                }
                            >
                                {el[type]
                                    ? formatCurrency(el[type], 'EUR')
                                    : ''}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
