import { useCallback, useEffect, useReducer } from 'react';

const ACTIONS = {
	LAYOUT: 'layout',
	SET_VALUE: 'set',
	FIX_LAYOUT: 'fixLayout'
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.LAYOUT:
			return {
				...state,
				layout: action.payload,
			};
		case ACTIONS.SET_VALUE:
			return {
				...action.payload,
			};
			case ACTIONS.FIX_LAYOUT:
				action.payload.map(el => {
					state.layout.map((el2) => {
						if (el.dataGrid.i === el2.i) {
							el2.w = el.dataGrid.w;
							el2.h = el.dataGrid.h;
							el2.x = el.dataGrid.x;
							el2.y = el.dataGrid.y;
						}
						return el2;
					});
					return el;
				})
				return {
					...state,
				};
	}
}

export function useResizableGrid(defaultValue) {
	const [grid, dispatch] = useReducer(reducer, defaultValue);

	useEffect(() => {
		window.dispatchEvent(new Event('resize'));
	}, [grid]);

	function setLayout(layout) {
		dispatch({ type: 'layout', payload: layout });
	}

	function setValue(value) {
		dispatch({ type: 'set', payload: value });
	}

	const fixLayout = useCallback((charts) => {
		dispatch({ type: 'fixLayout', payload: charts });
	},[])

	return [grid, setLayout, setValue, fixLayout];
}
