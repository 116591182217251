import React from 'react';

export const activeClientsMock = {
    hoverStyle: '',
    desc: '',
    loading: true,
    title: '',
    titleColor: '#606060',
    contentType: '',
    contentColor: '#ffb200',
    contentFractionTotal: '',
    content: '103',
    contentAux: '-13',
    timeInterval: 'monthly',
    contentAuxType: '',
    contentAuxColor: '#ffb200',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaData: [
        {
            date: '2020-01-01',
            value: 129.38553836342703
        },
        {
            date: '2020-02-01',
            value: 261.9778364863332
        },
        {
            date: '2020-03-01',
            value: 525.0275364092522
        },
        {
            date: '2020-04-01',
            value: 429
        }
    ],
    areaAttr: [
        {
            color: '#ce706d',
            stroke: '#ce706d',
            dataKey: 'value',
            name: 'Value'
        }
    ],
    categoryAxis: 'categoryAttr',
    data: [
        { exampleAttr1: 10, categoryAttr: 'yo1' },
        { exampleAttr1: 15, categoryAttr: 'yo2' },
        { exampleAttr1: 5, categoryAttr: 'yo2.1' },
        { exampleAttr1: 30, categoryAttr: 'yo2.2' },
        { exampleAttr1: 5, categoryAttr: 'yo3' }
    ],
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    prefix: 'activeClientsCard',
    attr: 'activeClientsCard',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    }
};
