import _ from 'lodash'
import icoUser1 from '../assets/images/user1.svg'
import icoUser2 from '../assets/images/user2.svg'
import icoUser3 from '../assets/images/user3.svg'
import icoUser4 from '../assets/images/user4.svg'
import icoUser5 from '../assets/images/user5.svg'
import icoUser6 from '../assets/images/user6.svg'
import icoUser7 from '../assets/images/user7.svg'
import icoUser8 from '../assets/images/user8.svg'
import icoUser9 from '../assets/images/user9.svg'
import icoUser10 from '../assets/images/user10.svg'

export function getFilterParam(filter, config, filterTypes) {
    let result = []

    if (!filter) {
        return result
    } else if (!config) {
        for (const type of filterTypes) {
            if (_.has(filter, type)) {
                if (filter[type].constructor === String && filter[type] !== '') {
                    result = [filter[type]]
                    break
                } else if (filter[type].constructor === Array && filter[type].length !== 0) {
                    result = filter[type]
                    break
                }
            }
        }
    } else {
        for (const type of filterTypes) {
            if (config.filter.includes(type)) {
                if (typeof filter[type] === 'string' && filter[type] !== '') {
                    result = [filter[type]]
                    break
                } else if (filter[type].length !== 0) {
                    result = filter[type]
                    break
                }
            }
        }
    }

    // if (config.info === 'unitSalesForecastComparisonChart' && filterTypes.includes('productId')) {
    //     console.log(filter, config, filterTypes, result)
    // }
    return result
}

export const getProfileImages = (count) => {
    const imagesUsed = []
    Array.from({ length: count }, () => {
        let newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
            Math.floor(Math.random() * 10)
        ]
        if (imagesUsed.length !== 0) {
            do {
                newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
                    Math.floor(Math.random() * 10)
                ]
            } while (imagesUsed.includes(newImage))
        }
        imagesUsed.push(newImage)
    })
    return imagesUsed
}
