import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styles from './MilestoneTimeline.module.scss';
import { t } from 'i18next';
import _ from 'lodash';
import { months } from '../../../utils/date';
import { MilestoneMarker } from '../MilestoneMarker/MilestoneMarker';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import { InputFactory } from '../../inputs/input-factory/input-factory';
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Draggable } from '../../dnd/Draggable/Draggable';

export const MilestoneTimeline = ({
    newMilestone,
    monthToDrop,
    dates,
    droppedDates,
    reference,
    onCancelMilestone,
    onSaveMilestone,
    isDraggable,
    minified,
    showCurrentDate
}) => {
    const auxReference = useRef(null);
    const [timelineWidth, setTimelineWidth] = useState(0);
    const [timelineOffsetLeft, setTimelineOffsetLeft] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [hoveredDroppedDate, setHoveredDroppedDate] = useState(null);
    const [timelineOffsetBottom, setTimelineOffsetBottom] = useState(0);

    // Function to update the left offset of the timeline
    const updateTimelineOffset = useCallback(() => {
        if (reference && reference.current) {
            const rect = reference.current.getBoundingClientRect();
            setTimelineOffsetLeft(rect.left);
            setTimelineOffsetBottom(rect.top);
            setTimelineWidth(rect.width);
        } else if (auxReference && auxReference.current) {
            const rect = auxReference.current.getBoundingClientRect();
            setTimelineOffsetLeft(rect.left);
            setTimelineOffsetBottom(rect.top);
            setTimelineWidth(rect.width);
        }
    }, [reference, auxReference]);

    useEffect(() => {
        updateTimelineOffset();
        window.addEventListener('resize', updateTimelineOffset);
        return () => window.removeEventListener('resize', updateTimelineOffset);
    }, [updateTimelineOffset]);

    const allMonths = useMemo(() => {
        // assume dropped dates is something like [{ date: '1/1', value: 0 }]
        return newMilestone
            ? droppedDates.map((el) => el.date).concat(newMilestone)
            : droppedDates.map((el) => el.date);
    }, [droppedDates, newMilestone]);

    const markerSize = useMemo(() => {
        if (minified) {
            return '3rem';
        } else {
            return '5rem';
        }
    }, [minified]);

    const currentDayPosition = useMemo(() => {
        if (!showCurrentDate) return null;
        const now = new Date();
        const dayOfYear =
            Math.floor(
                (now - new Date(now.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
            ) + 1;
        return dayOfYear / 365;
    }, [showCurrentDate]);

    return (
        <div
            className={`${styles['milestone-timeline-container']}`}
            style={{
                height: minified ? '2rem' : '4rem'
            }}
            ref={auxReference}
        >
            {showCurrentDate && currentDayPosition && (
                <div
                    className={styles['current-day-line']}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: `calc(calc(${currentDayPosition} * ${timelineWidth}px) - 1px)`,
                        height: '100%',
                        width: '2px',
                        backgroundColor: '#2c3c8d', // or any color you prefer
                        zIndex: 99
                    }}
                ></div>
            )}
            {dates.map((month, index) => {
                const foundMonths = [];
                if (allMonths && allMonths.length > 0) {
                    allMonths.forEach((el) => {
                        if (el.split('/')[1] === month.toString()) {
                            foundMonths.push(el);
                        }
                    });
                }
                return (
                    <div
                        key={index}
                        className={
                            monthToDrop && monthToDrop === month.toString()
                                ? `${styles['milestone-timeline-container__month']} ${styles['hovered']}`
                                : `${styles['milestone-timeline-container__month']}`
                        }
                    >
                        {foundMonths.map((el, index) => {
                            const [day, month] = el.split('/');
                            const date = new Date(
                                new Date().getFullYear(),
                                month,
                                day
                            );
                            const today = new Date();
                            const leftPosition =
                                (_.sum(
                                    dates
                                        .slice(
                                            0,
                                            dates.indexOf(parseInt(month))
                                        )
                                        .map((el) =>
                                            new Date(2021, el, 0).getDate()
                                        )
                                ) +
                                    parseInt(el.split('/')[0])) /
                                365;
                            return (
                                <div
                                    key={index}
                                    style={
                                        minified
                                            ? {
                                                  position: 'absolute',
                                                  zIndex: 100,
                                                  bottom: `calc(100%)`
                                              }
                                            : {
                                                  zIndex: 100,
                                                  position: 'fixed',
                                                  top: `calc(${timelineOffsetBottom}px - ${markerSize})`,
                                                  left: `calc(${timelineOffsetLeft}px + calc(${leftPosition}  * ${timelineWidth}px) - 5px)`
                                              }
                                    }
                                    onMouseOver={() => {
                                        if (
                                            el !== newMilestone &&
                                            !isDragging
                                        ) {
                                            setHoveredDroppedDate(
                                                droppedDates.find(
                                                    (date) => date.date === el
                                                )
                                            );
                                        }
                                    }}
                                    onMouseDown={() => {
                                        setIsDragging(true);
                                    }}
                                    onMouseUp={() => {
                                        setIsDragging(false);
                                    }}
                                    onMouseOut={() =>
                                        setHoveredDroppedDate(null)
                                    }
                                >
                                    {hoveredDroppedDate &&
                                        hoveredDroppedDate.date === el && (
                                            <MilestoneHoverPopup
                                                {...hoveredDroppedDate}
                                            />
                                        )}
                                    {newMilestone && newMilestone === el && (
                                        <MilestoneCreationPopup
                                            newMilestone={newMilestone}
                                            onCancelMilestone={
                                                onCancelMilestone
                                            }
                                            onSaveMilestone={onSaveMilestone}
                                        />
                                    )}
                                    {isDraggable && (
                                        <Draggable hoverText={'asd'} id={el}>
                                            <MilestoneMarker isSmall />
                                        </Draggable>
                                    )}
                                    {!isDraggable && (
                                        <MilestoneMarker
                                            isSmaller
                                            status={
                                                date < today
                                                    ? 'completed'
                                                    : 'todo'
                                            }
                                        />
                                    )}
                                </div>
                            );
                        })}
                        <span
                            className={`${styles['milestone-timeline-container__month__number']}`}
                            style={{
                                fontSize: minified ? '1rem' : '1.6rem'
                            }}
                        >
                            {t(`months:${months[month]}`)}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

const MilestoneCreationPopup = ({
    newMilestone,
    onCancelMilestone,
    onSaveMilestone
}) => {
    const { t } = useCustomTranslation();
    const creationPopupRef = useRef();
    const currentDate = useMemo(() => {
        const [day, month] = newMilestone.split('/');
        return `${day}/${month}/${new Date().getFullYear()}`;
    }, [newMilestone]);
    const { value: inputs, setValue: inputsSetValue } = useMultipleInputs({
        date: {
            type: 'datenew',
            name: 'date',
            value: currentDate
        },
        targetValue: {
            type: 'type',
            name: 'targetValue'
        }
    });
    const handleOutsideClick = useCallback(
        (e) => {
            if (
                creationPopupRef.current &&
                !creationPopupRef.current.contains(e.target)
            ) {
                onCancelMilestone(newMilestone);
            }
        },
        [onCancelMilestone, newMilestone]
    );

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [handleOutsideClick]);
    return (
        <div className="row mx-0 w-100" ref={creationPopupRef}>
            <div className="col px-0">
                <div
                    className={`${styles['milestone-timeline-container__creation-popup']}`}
                >
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            {Object.values(inputs) &&
                                Object.values(inputs).map((el, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem'
                                            }}
                                        >
                                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                                <InputFactory
                                                    {...el}
                                                    newLabelType
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                        <div className="col-auto px-0">
                            <TerciaryButton
                                text={t(`common:save`)}
                                disabled={
                                    !inputs.date.value ||
                                    !inputs.targetValue.value
                                }
                                onClick={() =>
                                    onSaveMilestone(
                                        inputs.date.value,
                                        inputs.targetValue.value
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MilestoneHoverPopup = ({ date, target }) => {
    const { t } = useCustomTranslation();
    return (
        <div
            className={`${styles['milestone-timeline-container__hover-popup']}`}
        >
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span>{date}</span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span>{target}</span>
                </div>
            </div>
        </div>
    );
};
