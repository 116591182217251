import React from 'react';
import { Text } from 'recharts';
import styles from './custom-text-tick.module.scss';

export const CustomTextTick = React.memo((props) => {
    const { payload, hideXValues, hidden } = props;
    const valueToShow = payload.value;
    if (!hideXValues || hidden) {
        return (
            <Text {...props} className={`${styles['custom-text-tick']}`}>
                {valueToShow}
            </Text>
        );
    } else {
        return null;
    }
});
