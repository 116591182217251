import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import styles from './producer-donut-sales.module.scss';
import { producerDonutSalesMock } from './mock';
import { ContentContainer, DonutChart, Loader, Spinner } from '../..';
import _ from 'lodash';
import { errorHandler } from '../../../utils/api';
import { SalesService } from '../../../temp/test';
import { getFilterParam } from '../../../utils/params';
import { DONUT_1_COLORS } from '../../../constants/color';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays the count and evolution of new clients compared to the previous month
 */

export const ProducerDonutSales = React.memo(
    ({
        isExample,
        isReportExample,
        pinned,
        config,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview
    }) => {
        const { filter } = useFilter();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(producerDonutSalesMock);

        const getProductsInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(producerDonutSalesMock);
            if (!isExample) {
                try {
                    setLoading(true);
                    const dataBack =
                        await SalesService.getApiSalesDistributionProducer({
                            regions: getFilterParam(filter, config, [
                                'regionId',
                                'regionIds'
                            ])
                        });
                    dataClone.donutAttr = [
                        {
                            dataKey: 'data1',
                            valueKey: 'value',
                            nameKey: 'key1'
                        }
                    ];
                    dataClone.data = {
                        data1: dataBack
                            .slice(0, 5)
                            .map((item, index) => ({
                                key1: item.product_producer,
                                value: item.value,
                                color: DONUT_1_COLORS[index],
                                name:
                                    item.product_producer === 'self'
                                        ? 'My Products'
                                        : item.product_producer,
                                percentage:
                                    item.value /
                                    dataBack
                                        .map((item) => item.value)
                                        .reduce((a, b) => a + b, 0)
                            }))
                            .concat(
                                dataBack.slice(5).length > 0
                                    ? {
                                          key1: 'Others',
                                          value: dataBack
                                              .slice(5)
                                              .map((item) => item.value)
                                              .reduce((a, b) => a + b, 0),
                                          color: DONUT_1_COLORS[5],
                                          percentage:
                                              dataBack
                                                  .slice(5)
                                                  .map((item) => item.value)
                                                  .reduce((a, b) => a + b, 0) /
                                              dataBack
                                                  .map((item) => item.value)
                                                  .reduce((a, b) => a + b, 0),

                                          name: t('common:others')
                                      }
                                    : []
                            )
                    };
                    setValue(dataClone);
                    setLoading(false);
                } catch (err) {
                    errorHandler(err);
                }
            } else {
                dataClone.donutAttr = [
                    {
                        dataKey: 'data1',
                        valueKey: 'value',
                        nameKey: 'key1'
                    }
                ];
                setValue(dataClone);
            }
        }, [setLoading, setValue, filter, config, t, isExample]);

        useEffect(() => {
            if (filter) {
                getProductsInformation();
            }
        }, [getProductsInformation, filter]);

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    // interval={'1M'}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <>
                            {data.data.data1.length === 0 && (
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <span className="noDataText">
                                            {t('common:noData')}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {data.data.data1.length > 0 && (
                                <div
                                    className={`${styles['producer-donut-sales-container']}`}
                                >
                                    <DonutChart
                                        donutAttr={data.donutAttr}
                                        attr={data.attr}
                                        data={data.data}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ProducerDonutSales.propTypes = {};
