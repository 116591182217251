import React from 'react'

export const productAtc4DonutMock = {
    hoverStyle: '',
    desc: '',
    loading: false,
    title: '',
    titleColor: '#606060',
    content: '5',
    contentType: '',
    contentColor: '#06a4e7',
    contentFractionTotal: '',
    contentAux: '',
    contentAuxType: '',
    contentAuxColor: '#06a4e7',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    legend: [],
    forecast: [],
    areaAttr: [
        {
            dataKey: 'exampleAttr1',
            color: '#06a4e7',
            strokeWidth: 3,
        },
    ],
    categoryAxis: 'categoryAttr',
    data: {
        data1: [
            {
                key1: 'Product',
                value: 17357051.54,
                color: '#41FF94',
                name: 'Product',
                percentage: 0.62272463213962,
            },
            {
                key1: 'Others',
                value: 3248928.99,
                color: '#E6E6E6',
                name: 'Others',
                percentage: 0.36770418756979956,
            },
        ],
    },
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    attr: 'productAtc4DonutCard',
    prefix: 'productAtc4DonutCard',
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: '',
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
    },
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1',
        },
    ],
}
