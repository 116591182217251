import React, { useState } from 'react';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { Modal, PrimaryButton } from '../..';
import icoStarkSmall from '../../../assets/images/logo-small.jpg';
import { SuccessModal } from '../success-modal/success-modal';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { errorHandler } from '../../../utils/api';
import { InputFactory } from '../../inputs/input-factory/input-factory';

export const GeneralAddModal = ({
    inputs,
    title,
    successMessage,
    onAdd,
    onCloseModal,
    ...rest
}) => {
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { t } = useCustomTranslation();

    const handleAddElement = async () => {
        try {
            setLoading(true);
            await onAdd();
            setLoading(false);
            setIsSuccess(true);
        } catch (err) {
            errorHandler(err);
        }
    };

    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                    <div className="col px-0 d-flex justify-content-center align-items-center">
                        <img
                            src={icoStarkSmall}
                            alt="Stark"
                            style={{
                                width: '8rem',
                                height: '8rem'
                            }}
                        />
                    </div>
                </div>
                <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-center align-items-center">
                        <span className="modal-subtitle">{title}</span>
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col px-0">
                        {Object.values(inputs) &&
                            Object.values(inputs).map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem'
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-end align-items-center">
                                            <InputFactory {...el} {...rest} />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1'
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton
                            text={t('modals:cancel')}
                            onClick={onCloseModal}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('modals:save')}
                            onClick={handleAddElement}
                        />
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal text={successMessage} onCloseModal={onCloseModal} />
        );
    }
};
