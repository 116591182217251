import _ from 'lodash'
import { useCallback, useMemo, useReducer } from 'react'

const ACTIONS = {
    SET: 'set',
    RESET: 'reset',
    CHECKBOX: 'checkbox',
    ADD_OPTION: 'addOption',
    LIMIT: 'limit',
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET:
            return { ...action.payload }
        case ACTIONS.RESET:
            return { ...action.payload }
        case ACTIONS.ADD_OPTION:
            return {
                ...state,
                options: [...state.options, action.payload],
            }
        case ACTIONS.CHECKBOX:
            if (state.selectedOptions.includes(action.payload)) {
                const index = _.findIndex(state.selectedOptions, (el) => {
                    return el === action.payload
                })
                if (index !== -1) {
                    return {
                        ...state,
                        selectedOptions: [
                            ...state.selectedOptions.slice(0, index),
                            ...state.selectedOptions.slice(index + 1, state.selectedOptions.length),
                        ],
                    }
                }
            } else {
                return {
                    ...state,
                    selectedOptions: [...state.selectedOptions, action.payload],
                }
            }
            break
        case ACTIONS.LIMIT:
            return {
                ...state,
                limit: action.payload,
            }
    }
}

export function useCheckboxInput({ name, options = [], selectedOptions = [] }) {
    const defaultValue = useMemo(() => {
        return {
            name: name,
            options: options,
            selectedOptions: selectedOptions,
            error: '',
            state: 'normal',
        }
    }, [name, options, selectedOptions])

    const [value, dispatch] = useReducer(reducer, defaultValue)

    function setValue(value) {
        dispatch({ type: 'set', payload: value })
    }

    const reset = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue })
    }, [defaultValue])

    function clickCheckboxOption(a, b, option) {
        dispatch({ type: 'checkbox', payload: option })
    }

    function addOption(option) {
        dispatch({ type: 'addOption', payload: option })
    }

    function setCheckboxLimit(limit) {
        dispatch({ type: 'limit', payload: limit })
    }

    return {
        value,
        setValue,
        addOption,
        reset,
        clickCheckboxOption,
        setCheckboxLimit,
    }
}
