import React from 'react'
import { Dot } from 'recharts'
import styles from './custom-active-dot.module.scss'

export const CustomActiveDot = React.memo((props) => {
    const {
        cx,
        cy,
        fill,
        id,
        dataKey,
        index,
        currentIndex,
        animationEnded,
        hoveredArea,
        handleMouseOverAreaDot,
        handleMouseLeave,
    } = props
    if (
        animationEnded &&
        id !== '' &&
        dataKey === hoveredArea.dataKey &&
        index === hoveredArea.index
    ) {
        return (
            <svg
                key={index}
                onMouseOver={() =>
                    handleMouseOverAreaDot(currentIndex, dataKey, cx, cy, index)
                }
                onMouseOut={handleMouseLeave}
            >
                <Dot cx={cx} cy={cy} r={6} fill={fill} />
                <Dot
                    cx={cx}
                    cy={cy}
                    r={5}
                    fill="#ffffff"
                    className={`${styles['custom-active-dot']}`}
                />
                <Dot cx={cx} cy={cy} r={2} fill={fill} />;
            </svg>
        )
    }
    return null
})
