import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import styles from './team-sales-vs-all.module.scss'
import { errorHandler } from '../../../utils/api'
import { TeamsService } from '../../../temp/test'
import _ from 'lodash'
import { teamSalesVsAllMock } from './mock'
import { useSub } from '../../../hooks/usePubSub'
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container'
import { DonutChart } from '../../charts/donut-chart/donut-chart'
import { Spinner } from '../../loading/spinner/spinner'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { Loader } from '../../loading/Loader/Loader'

/**
 * Displays the count and evolution of new clients compared to the previous month
 */

export const TeamSalesVsAll = React.memo(
    ({ isExample, isReportExample, pinned, onPinComponent, onUnpinComponent, onMouseEnterInfoIcon, onMouseLeaveInfoIcon, isPreview }) => {
        const { filter } = useFilter()
        const { t } = useCustomTranslation()

        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(teamSalesVsAllMock)

        const getInformation = useCallback(async () => {
            try {
                const dataClone = _.cloneDeep(teamSalesVsAllMock)
                const dataBack = await TeamsService.getApiTeamsSalesVsAll({
                    teamId: filter.teamId,
                })
                const dataBackTeamInfo = await TeamsService.getApiTeams1({
                    id: filter.teamId,
                })
                dataClone.donutAttr = [
                    {
                        dataKey: 'data1',
                        valueKey: 'value',
                        nameKey: 'key1',
                    },
                ]
                dataClone.data = {
                    data1: [
                        {
                            key1: 'Others',
                            name: t('common:others'),
                            value: dataBack.all - dataBack.team,
                            percentage: (dataBack.all - dataBack.team) / dataBack.all,
                            color: '#d6d6d6',
                        },
                        {
                            name: dataBackTeamInfo.name,
                            key1: 'Team',
                            value: dataBack.team,
                            percentage: dataBack.team / dataBack.all,
                            color: '#2CD9C5',
                        },
                    ],
                }
                dataClone.legend = [
                    {
                        name: dataBackTeamInfo.name,
                        color: '#2CD9C5',
                        translated: true,
                    },
                    {
                        name: t('common:others'),
                        color: '#d6d6d6',
                        translated: true,
                    },
                ]

                dataClone.insideText = dataBack.all === 0 ? '0%' : Math.round((dataBack.team / dataBack.all) * 100) + '%'
                setValue(dataClone)
                setLoading(false)
            } catch (err) {
                errorHandler(err)
            }
        }, [setLoading, filter, setValue, t])

        useSub('team', () => {
            getInformation()
        })

        useEffect(() => {
            if (filter) {
                getInformation()
            }
        }, [setLoading, filter, setValue, t, getInformation])

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div className={`${styles['team-sales-vs-all-container']}`}>
                            <DonutChart isHalfCircle insideText={data.insideText} donutAttr={data.donutAttr} attr={data.attr} data={data.data} />
                        </div>
                    )}
                </ContentContainer>
            </div>
        )
    }
)

TeamSalesVsAll.propTypes = {}
