import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { getValueInString } from '../../../../../utils/unit'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import useCursorTracking from '../../../../../hooks/useCursorTracking'
import { useCursorTrackingProvider } from '../../../../../providers/cursor-tracking-context'

export const BubbleTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    const { position } = useCursorTrackingProvider()
    return (
        <div
            style={{
                position: 'fixed',
                top: position.y,
                left: position.x,
                transition: 'all 0.3s ease',
                display: position.x === 0 && position.y === 0 ? 'none' : 'block',
            }}
        >
            <TooltipContainer color={props.loc.color}>
                <TooltipHeaderRow title={t('tooltips:header.team')} value={props.loc.name} />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.sales')}
                    value={getValueInString(parseFloat(props.loc.value || 0), 'values')}
                    color={props.loc.color}
                />
            </TooltipContainer>
        </div>
    )
})
