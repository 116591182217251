import React from 'react';
import styles from './goal-difference-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const GoalDifferenceTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['goal-difference-table-column-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '0.5rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'goal-difference-table-column-container__text-box'
                            ] +
                            ' ' +
                            (el[type] > 0
                                ? styles[
                                      'goal-difference-table-column-container__text-box--positive'
                                  ]
                                : el[type] < 0
                                ? styles[
                                      'goal-difference-table-column-container__text-box--negative'
                                  ]
                                : undefined)
                        }
                    >
                        {el[type] + '%'}
                    </span>
                </div>
            </div>
        </div>
    );
});
