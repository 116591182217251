import _ from 'lodash';
import { t } from 'i18next';

const toCron = {
    never: '0 5 31 2 *',
    daily: '0 0 * * *',
    weekly: '0 0 * * 1',
    biWeekly: '0 0 1-7,14-21 * 1',
    monthly: '0 0 1 * *'
};

const fromCron = {
    '0 5 31 2 *': 'never',
    '0 0 * * *': 'daily',
    '0 0 * * 1': 'weekly',
    '0 0 1-7,14-21 * 1': 'biWeekly',
    '0 0 1 * *': 'monthly'
};

export const translateFromCronString = (value) => {
    if (_.has(fromCron, value)) return fromCron[value];
    else return 'undefined';
};

export const translateToCronString = (value) => {
    if (_.has(toCron, value)) return toCron[value];
    else return 'undefined';
};

export const getTimeFromUnixTimeStamp = (timestamp) => {
    const timeFormat = new Date(timestamp / 1000000);
    return timeFormat.toISOString().split('T')[0];
};

export const getDayFromUnixTimeStamp = (timestamp) => {
    const timeFormat = new Date(timestamp / 1000000);
    return timeFormat.getDate();
};

export const getMonthFromUnixTimeStamp = (timestamp) => {
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Maio',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];
    const timeFormat = new Date(timestamp / 1000000);
    return months[timeFormat.getMonth()];
};

export const getYearFromUnixTimeStamp = (timestamp) => {
    const timeFormat = new Date(timestamp / 1000000);
    return timeFormat.getFullYear();
};

export const getIsoTimeDiference = (startIso, endIso) => {
    const start = new Date(startIso).getTime();
    const end = new Date(endIso).getTime();
    const milliseconds = Math.abs(end - start).toString();
    const seconds = parseInt(milliseconds / 1000);
    const minutes = parseInt(seconds / 60);
    const hours = parseInt(minutes / 60);
    const days = parseInt(hours / 24);
    const time =
        days + ':' + (hours % 24) + ':' + (minutes % 60) + ':' + (seconds % 60);
    return time;
};

export const formatDate = (date) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Maio',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];
    return (
        subParts[2] +
        ' ' +
        months[parseInt(subParts[1]) - 1] +
        ' ' +
        subParts[0]
    );
};

export const formatDateTime = (date) => {
    let parts = date.split('T');
    let subParts = parts[0].split('-');
    let subPartsTime = parts[1].split('.')[0];
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Maio',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];
    return (
        subParts[2] +
        ' ' +
        months[parseInt(subParts[1]) - 1] +
        ' ' +
        subParts[0] +
        ' ' +
        subPartsTime
    );
};

export const getDateParts = (date) => {
    let parts = date.split('T');
    return parts[0];
};

export const getFullMonthAndYear = (date, translate) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    const months = [
        'JanuaryFull',
        'FebruaryFull',
        'MarchFull',
        'AprilFull',
        'MayFull',
        'JuneFull',
        'JulyFull',
        'AugustFull',
        'SeptemberFull',
        'OctoberFull',
        'NovemberFull',
        'DecemberFull'
    ];
    return (
        translate(`months:${months[parseInt(subParts[1]) - 1]}`) +
        ' ' +
        subParts[0]
    );
};

export const getDayFromDateFormat = (date) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Maio',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];
    return (
        subParts[2] +
        ' ' +
        (t(`months:${months[parseInt(subParts[1]) - 1]}`) ||
            months[parseInt(subParts[1]) - 1]) +
        ' ' +
        subParts[0]
    );
};

export const getMonthFromDateFormat = (date) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    return t(`months:${months[parseInt(subParts[1]) - 1]}`) + ' ' + subParts[0];
};

export const getWeekFromDateFormat = (date) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Maio',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];
    return (
        subParts[2] +
        ' ' +
        (t(`months:${months[parseInt(subParts[1]) - 1]}`) ||
            months[parseInt(subParts[1]) - 1]) +
        ' ' +
        subParts[0]
    );
};

export const getQuarterFromDateFormat = (date) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    const quarters = [
        'Q1',
        'Q1',
        'Q1',
        'Q2',
        'Q2',
        'Q2',
        'Q3',
        'Q3',
        'Q3',
        'Q4',
        'Q4',
        'Q4'
    ];
    return quarters[parseInt(subParts[1]) - 1] + ' ' + subParts[0];
};

export const getYearFromDateFormat = (date) => {
    let parts;
    if (date.split('T').length > 1) {
        parts = date.split('T');
    } else parts = date.split(' ');
    let subParts = parts[0].split('-');
    return subParts[0];
};

export const getTimeFromDateFormat = (date) => {
    let parts = date.split('T');
    return parts[0];
};

export const isFirstThreeMonths = (date) => {
    return parseInt(date.split('T')[0].split('-')[1]) - 3 <= 0;
};

export const isFirstSixMonths = (date) => {
    return parseInt(date.split('T')[0].split('-')[1]) - 6 <= 0;
};

export const isFirstNineMonths = (date) => {
    return parseInt(date.split('T')[0].split('-')[1]) - 9 <= 0;
};

export const isLastMonth = (date) => {
    return parseInt(date.split('T')[0].split('-')[1]) === 12;
};

export const isSameYearSameMonthSameDay = (dateA, dateB) => {
    return (
        dateA.split('-')[0] === dateB.split('-')[0] &&
        dateA.split('-')[1] === dateB.split('-')[1] &&
        dateA.split('-')[2] === dateB.split('-')[2]
    );
};

export const isSameYearSameMonth = (dateA, dateB) => {
    return (
        dateA.split('-')[0] === dateB.split('-')[0] &&
        dateA.split('-')[1] === dateB.split('-')[1]
    );
};

export const isFirstXMonths = (x, date) => {
    return parseInt(date.split('T')[0].split('-')[1]) - x <= 0;
};

export const getLastYearMaxXMonthsBefore = (x, date) => {
    return (
        (parseInt(date.split('T')[0].split('-')[0]) - 1).toString() +
        `-${(
            12 -
            (x - parseInt(date.split('T')[0].split('-')[1]))
        ).toString()}-${date.split('T')[0].split('-')[2]}T00:00:00`
    );
};

export const getLastXMonths = (x, date) => {
    let month = (parseInt(date.split('T')[0].split('-')[1]) - x).toString();
    if (parseInt(month) < 10) month = '0' + month;
    return `${date.split('T')[0].split('-')[0]}-${month}-${
        date.split('T')[0].split('-')[2]
    }T00:00:00`;
};

export const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};
