import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './sentiment-media.module.scss';
import { SentimentMediaInput } from '../sentiment-media-input';
import { errorHandler } from '../../../utils/api';
import { ScrapperService } from '../../../temp/test';
import { formatDate } from '../../../utils/date';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays logins in a table
 */

export const SentimentMedia = React.memo(
    ({ onSetResults, onNavigateToResults }) => {
        const { t } = useCustomTranslation();

        const handleSearch = async (subject, keywords) => {
            try {
                onNavigateToResults();
                const result =
                    await ScrapperService.postApiBackofficeScrapperFolha({
                        requestBody: {
                            input_keywords: keywords,
                            q: subject,
                            session_id: undefined
                        }
                    });
                const newMediaResults = [];
                const newKeywordCounter = [];
                const newResults = { sessionId: undefined, data: [] };
                Object.entries(result.result).map((el) => {
                    newKeywordCounter.push({ name: el[0], count: el[1] });
                    return el;
                });
                result.data.map((el) => {
                    let positiveWordsCounter = 0,
                        negativeWordsCounter = 0,
                        neutralWordsCounter = 0;
                    let positiveWords = [],
                        negativeWords = [],
                        neutralWords = [];
                    let sentences = [];
                    let text = el.text;
                    let majorSentences = [];
                    JSON.parse(el.keywords).map((el2) => {
                        if (sentences.length === 0) {
                            let newSentences = text.split(el2.text);
                            if (
                                typeof newSentences === 'object' &&
                                newSentences.length > 1
                            ) {
                                sentences.push(
                                    { text: newSentences[0], sentiment: '' },
                                    {
                                        text: el2.text,
                                        sentiment: el2.sentiment.label
                                    },
                                    { text: newSentences[1], sentiment: '' }
                                );
                            }
                        } else if (sentences.length !== 0) {
                            sentences.map((el3, index3) => {
                                if (el3.sentiment === '') {
                                    let newSentences = el3.text.split(el2.text);
                                    if (
                                        typeof newSentences === 'object' &&
                                        newSentences.length > 1
                                    ) {
                                        sentences.splice(index3 + 1, 0, {
                                            text: newSentences[1],
                                            sentiment: ''
                                        });
                                        sentences.splice(index3 + 1, 0, {
                                            text: el2.text,
                                            sentiment: el2.sentiment.label
                                        });
                                        sentences.splice(index3, 0, {
                                            text: newSentences[0],
                                            sentiment: ''
                                        });
                                        sentences.splice(index3 + 1, 1);
                                    }
                                }
                                return el3;
                            });
                        }
                        if (el2.sentiment.label === 'positive') {
                            positiveWordsCounter += 1;
                            positiveWords.push(el2.text);
                        } else if (el2.sentiment.label === 'negative') {
                            negativeWordsCounter += 1;
                            negativeWords.push(el2.text);
                        } else if (el2.sentiment.label === 'neutral') {
                            neutralWordsCounter += 1;
                            neutralWords.push(el2.text);
                        }
                        return el2;
                    });
                    majorSentences.push(sentences);
                    const newEl = {
                        id: el.text_id,
                        subject: el.title,
                        text: el.text,
                        date: formatDate(el.classification_date),
                        sentiment: {
                            label: t(
                                `pages:sentimentAnalysis.${el.sentiment_label}`
                            ),
                            positive:
                                el.sentiment_label === 'positive'
                                    ? Math.abs(
                                          el.sentiment_score.toFixed(2) * 100
                                      )
                                    : 0,
                            negative:
                                el.sentiment_label === 'negative'
                                    ? Math.abs(
                                          el.sentiment_score.toFixed(2) * 100
                                      )
                                    : 0,
                            neutral:
                                el.sentiment_label === 'neutral'
                                    ? Math.abs(
                                          el.sentiment_score.toFixed(2) * 100
                                      )
                                    : 0
                        },
                        firstCorrespondent: '',
                        secondCorrespondent: '',
                        emailCounter: 1,
                        positiveWordCounter: positiveWordsCounter,
                        negativeWordCounter: negativeWordsCounter,
                        neutralWordCounter: neutralWordsCounter,
                        positiveWords: positiveWords,
                        neutralWords: neutralWords,
                        negativeWords: negativeWords,
                        sentences: majorSentences
                    };
                    newMediaResults.push(newEl);
                    return el;
                });
                newResults.sessionId = result.session_id;
                newResults.data.push({
                    input: 'media',
                    output: newMediaResults
                });
                onSetResults(newResults);
            } catch (err) {
                errorHandler(err);
            }
        };

        return (
            <>
                <div className={`${styles['sentiment-media-container']}`}>
                    <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <div
                                className={`${styles['sentiment-media-container__input-container']}`}
                            >
                                <SentimentMediaInput onSearch={handleSearch} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

SentimentMedia.propTypes = {};
