import React from 'react';
import { Rectangle } from 'recharts';

export const CustomShape = React.memo((props) => {
    const { x, y, width, height, fill } = props;
    return (
        <g>
            <Rectangle
                x={x - width}
                y={y}
                width={width}
                height={height}
                fill={fill}
                radius={[6, 6, 6, 6]}
                stroke={fill}
                strokeWidth={3}
                strokeOpacity={0.6}
            />
        </g>
    );
});
