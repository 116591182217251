import React from 'react'
import styles from './component-tabs.module.scss'

import icoDragDots from '../../../assets/images/icoDragDots.svg'
import { useComponentTabs } from '../../../providers/component-tabs-context'
import { has } from 'lodash'

export const ComponentTabs = () => {
    const { tabs, currentTab, setCurrentTab } = useComponentTabs()
    return (
        <div className={`${styles['component-tabs-container']}`}>
            <div className="row mx-0 w-100">
                {tabs.map((el, index) => {
                    return (
                        <div key={index} className="col-auto px-0 d-flex align-items-end">
                            <div
                                className={`${styles['component-tabs-container__tab']} ${currentTab === el.value ? styles['active'] : ''}`}
                                onClick={() => {
                                    setCurrentTab(el.value)
                                }}
                            >
                                {tabs.length !== 1 && (
                                    <img src={icoDragDots} alt="drag-dots" className={`${styles['component-tabs-container__tab__drag-dots']}`} />
                                )}
                                {has(el, 'icon') && el.icon && (
                                    <img src={el.icon} alt="icon" className={`${styles['component-tabs-container__tab__icon']}`} />
                                )}
                                <span className={`${styles['component-tabs-container__tab__text']}`}>{el.label}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
