import React from 'react'

export const productsAssociatedListMock = {
    titleCounter: '',
    sortedData: [],
    data: [],
    columns: [
        { path: 'name', label: '', sortable: true },
        { path: 'price', label: '', sortable: true },
        { path: 'description', label: '', sortable: true },
        { path: 'associated', label: '', sortable: true },
        { path: 'action', label: 'ACTION', sortable: false },
    ],
    sortColumn: { path: 'associated', order: 'desc' },
    currentPage: 1,
    pageSize: 10,
    loading: false,
    type: 'type-1',
    attr: 'productsAssociatedTable',
    paginationDisabled: false,
    disabled: true,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            options: [],
        },
        reference: React.createRef(),
    },
}
