import { createContext, useContext } from 'react'

const RoutesContext = createContext({
    onUpdateRoute: () => {},
})

export function RoutesProvider({ value, children }) {
    return (
        <RoutesContext.Provider value={value}>
            {children}
        </RoutesContext.Provider>
    )
}

export function useRoutes() {
    return useContext(RoutesContext)
}
