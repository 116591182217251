import React, { useCallback, useEffect, useMemo } from 'react'
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper'
import { Spinner } from '../../loading/spinner/spinner'
import { CustomPieChart } from '../../charts/custom-pie-chart/custom-pie-chart'
import { TableImprovement } from '../../tables/table-improvement/table-improvement'
import { useToggle } from '../../../hooks/useToggle'
import { useTable } from '../../../hooks/useTable'

const highImpactGoalsMock = {
    attr: 'highImpactGoals',
    type: 'type-1',
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1',
        },
    ],
    donutData: {
        data1: [
            {
                value: 7,
                key1: 7,
                color: '#ef9e9c',
            },
            {
                value: 9,
                key1: 9,
                color: '#6cd3c3',
            },
        ],
    },
    tableData: [
        {
            id: 1,
            name: 'Goal 1',
            targetValue: 50,
            targetVariable: 20,
            goalTimeLeft: '2024-12-31',
            goalCurrentValue: [17000, 26],
            goalExpectedValue: [18000, 36],
            goalDifference: 10,
        },
        {
            id: 2,
            name: 'Goal 2',
            targetValue: 50,
            targetVariable: 20,
            goalTimeLeft: '2024-12-31',
            goalCurrentValue: [17000, 26],
            goalExpectedValue: [18000, 36],
            goalDifference: 10,
        },
        {
            id: 3,
            name: 'Goal 3',
            targetValue: 50,
            targetVariable: 20,
            goalTimeLeft: '2024-12-31',
            goalCurrentValue: [17000, 26],
            goalExpectedValue: [18000, 36],
            goalDifference: 10,
        },
        {
            id: 4,
            name: 'Goal 4',
            targetValue: 50,
            targetVariable: 20,
            goalTimeLeft: '2024-12-31',
            goalCurrentValue: [17000, 26],
            goalExpectedValue: [18000, 36],
            goalDifference: 10,
        },
    ],
    columns: [
        {
            path: 'name',
            label: '',
            sortable: true,
        },
        {
            path: 'targetVariable',
            label: '',
            sortable: true,
        },
        {
            path: 'goalTimeLeft',
            label: '',
            sortable: true,
        },
        {
            path: 'targetValue',
            label: '',
            sortable: true,
        },
        {
            path: 'goalCurrentValue',
            label: '',
            sortable: true,
        },
        {
            path: 'goalExpectedValue',
            label: '',
            sortable: true,
        },
        {
            path: 'goalDifference',
            label: '',
            sortable: true,
        },
    ],
    legend: [
        {
            name: 'behindSchedule',
            color: '#ef9e9c',
        },
        {
            name: 'onTrack',
            color: '#6cd3c3',
        },
    ],
}

export const HighImpactGoals = ({ isPreview, ...rest }) => {
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = React.useState('')
    const [activeFilter, setActiveFilter] = React.useState(undefined)
    const { value, setLoading, setValue: setData, pageChange: dataPageChange, clickActionIcon } = useTable(highImpactGoalsMock)

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            clickActionIcon(attr, id)
            toggleModal(true)
            setModalType(option)
        },
        [toggleModal, clickActionIcon]
    )

    const handleFilterData = useCallback(
        (legendName) => {
            setActiveFilter(legendName)
        },
        [setActiveFilter]
    )

    const newLegend = useMemo(() => {
        return highImpactGoalsMock.legend.map((el, index) => {
            return {
                ...el,
                active: index === activeFilter,
            }
        })
    }, [activeFilter])

    const newData = useMemo(() => {
        if (activeFilter === 0) {
            return value.tableData.filter((el) => el.goalDifference < 0)
        } else if (activeFilter === 1) {
            return value.tableData.filter((el) => el.goalDifference >= 0)
        }
        return value.tableData
    }, [activeFilter, value.tableData])

    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper {...value} {...rest} legend={newLegend}>
                {value.loading && <Spinner />}
                {!value.loading && (
                    <>
                        <div
                            className="row mx-0 w-100 position-relative"
                            style={{
                                height: '50%',
                                marginBottom: '2rem',
                                zIndex: '10',
                            }}
                        >
                            <CustomPieChart {...value} data={value.donutData} onFilterData={handleFilterData} />
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0"></div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem', height: '50%' }}>
                            <TableImprovement
                                {...value}
                                {...rest}
                                data={newData}
                                onPageChange={dataPageChange}
                                onClickActionIcon={clickActionIcon}
                                onCloseActions={clickActionIcon}
                                onClickActionOption={handleSelectSettingsOption}
                            />
                        </div>
                    </>
                )}
            </ComponentWrapper>
        </div>
    )
}
