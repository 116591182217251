import React from 'react'
import styles from './general-card.module.scss'
import icoUp from '../../../assets/images/icoCardUp.svg'
import icoDown from '../../../assets/images/icoCardDown.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { MiniAreaChartContainer } from '../../charts/mini-area-chart-container/mini-area-chart-container'

export const GeneralCard = ({ content, contentAux, areaData, areaAttr, minValue, maxValue, timeInterval }) => {
    const { t } = useCustomTranslation()
    return (
        <div className="row mx-0 w-100 h-100 overflow-hidden">
            <div className="col-4 px-0 d-flex flex-column justify-content-end">
                <div className="row mx-0 w-100" style={{ marginBottom: '0.5rem' }}>
                    <div className="col px-0">
                        <span className={`${styles['general-card-container__text']}`}>{content || 0}</span>
                    </div>
                </div>
            </div>
            <div className="col px-0"></div>
            {areaData && (
                <div className="col-8 px-0 d-flex flex-column justify-content-end">
                    <div className="row mx-0 w-100" style={{ height: '3rem', marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <MiniAreaChartContainer
                                areaAttr={areaAttr}
                                minValue={minValue}
                                maxValue={maxValue}
                                categoryAxis="date"
                                lineType="monotone"
                                data={areaData}
                            />
                        </div>
                    </div>

                    {contentAux !== 0 && (
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                {contentAux > 0 && <img loading="lazy" src={icoUp} alt="" />}
                                {contentAux < 0 && <img loading="lazy" src={icoDown} alt="" />}
                                <span
                                    className={`${styles['general-card-container__percentage-text']}`}
                                    style={{
                                        color: contentAux > 0 ? '#34b53a' : contentAux < 0 ? '#df0000' : '#4c4c4c',
                                    }}
                                >
                                    {contentAux || '-'}%
                                </span>
                                {timeInterval === 'monthly' && (
                                    <span className={`${styles['general-card-container__time-text']}`}>{t(`common:thisMonth`)}</span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
