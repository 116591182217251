import React, { useCallback } from 'react';
import styles from './resend-button-table-column.module.scss';
import icoCheckGreen from '../../../../../assets/images/ico-check-green.svg';
import { SecondaryButton } from '../../../../buttons/secondary-button/secondary-button';
import { success } from '../../../../notifications/notification/notification';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const ResendButtonTableColumn = React.memo(({ onClick }) => {
    const [isClicked, setIsClicked] = React.useState(false);
    const { t } = useCustomTranslation();

    const handleResend = useCallback(() => {
        setIsClicked(true);
        success({
            text: 'Invitation resent successfully'
        });
        setTimeout(() => {
            setIsClicked(false);
        }, 5000);
    }, []);

    return (
        <div className={`${styles['resend-button-table-column-container']}`}>
            {!isClicked && (
                <span
                    className={
                        styles['resend-button-table-column-container__text']
                    }
                    onClick={() => {
                        handleResend();
                        if (onClick) onClick();
                    }}
                >
                    {t('buttons:resend')}
                </span>
            )}
            {isClicked && (
                <img
                    src={icoCheckGreen}
                    alt="icoCheckGreen"
                    className={
                        styles[
                            'resend-button-table-column-container__ico-check-green'
                        ]
                    }
                />
            )}
        </div>
    );
});
