import React from 'react'
import { ReactComponent as IcoRemove } from '../../../assets/images/icon-table-remove.svg'
import styles from './remove-button.module.scss'

export const RemoveButton = React.memo(({ index, onRemove, disabled }) => {
    return (
        <div className={`${styles['remove-button-container']}`}>
            <IcoRemove
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onRemove(index)
                }}
                className={
                    disabled
                        ? `${styles['remove-button-container__icon']} ${styles['disabled']}`
                        : `${styles['remove-button-container__icon']} `
                }
            ></IcoRemove>
        </div>
    )
})

RemoveButton.propTypes = {}
