import { useCallback, useState } from 'react'

export function useArray(defaultValue) {
    const [array, setArray] = useState(defaultValue)

    const push = useCallback((element) => {
        setArray((a) => [...a, element])
    }, [])

    function remove(index) {
        setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)])
    }

    const removeMultiple = useCallback((indices) => {
        setArray((a) => {
            const indexesToString = indices.map((index) => index.toString())
            return a.filter((_, index) => !indexesToString.includes(index.toString()))
        })
    }, [])

    const updateSingle = useCallback((index, attr, value) => {
        setArray((a) => [...a.slice(0, index), { ...a[index], [attr]: value }, ...a.slice(index + 1, a.length)])
    }, [])

    function update(index, newElement) {
        setArray((a) => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)])
    }

    const updateAll = useCallback((attr, value) => {
        setArray((a) => a.map((item) => ({ ...item, [attr]: value })))
    }, [])

    function filter(callback) {
        setArray((a) => a.filter(callback))
    }

    const clear = useCallback(() => {
        setArray([])
    }, [])

    const reset = useCallback(() => {
        setArray(defaultValue)
    }, [defaultValue])

    return {
        array,
        setArray,
        push,
        remove,
        removeMultiple,
        update,
        updateSingle,
        updateAll,
        filter,
        clear,
        reset,
    }
}
