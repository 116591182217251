import React, { useState } from 'react'
import styles from './OrganizationDetails.module.scss'
import { TypeInput } from '../../inputs/type-input/type-input'
import FullTextPopup from '../../popups/FullTextPopup'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'
import { CreateTeamModal } from '../../modals/create-team-modal/CreateTeamModal'
import { InviteMemberModal } from '../../../pages/general/people-and-permissions/components/invite-member-modal/invite-member-modal'
import icoOrganization from '../../../assets/images/icoOrganization.svg'
import { NoAdminWarning } from '../../formatting/no-admin-warning/no-admin-warning'
import { DetailsEditButton } from '../../buttons/details-edit-button/DetailsEditButton'
import { NavigationMenu } from '../../navigation/navigation-menu/navigation-menu'
import { PrimaryButton } from '../../buttons/primary-button/primary-button'
import icoPlus from '../../../assets/images/ico-plus.svg'
import icoCreateTeam from '../../../assets/images/icoCreateTeam.svg'
import { TeamCarousel } from '../../teams/team-carousel/TeamCarousel'
import { OrganizationMembersList } from '../organization-members-list/OrganizationMembersList'

/**
 * AddressBlock Component
 *
 * This is a reusable component that displays an address block.
 *
 * @param {Object} props - The properties that define the component's behavior and display.
 * @param {string} props.title - The title of the address block.
 * @param {string} props.content - The content of the address block.
 *
 * @returns {JSX.Element} The AddressBlock component.
 */
const AddressBlock = ({ title, content, isEdit, onChangeInput, input }) => (
    <div className="col-auto px-0" style={{ marginRight: '2rem' }}>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span className={styles['organization-details-container__subTitle']}>{title}</span>
            </div>
        </div>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span className={styles['organization-details-container__content']}>
                    {isEdit && input && <TypeInput hideLabel onChangeInput={onChangeInput} isNumber {...input} />}
                    {!isEdit && (
                        <FullTextPopup text={content}>
                            <span className={`${styles['hospital-account-information-container__team-text__content']}`}>{content || '-'}</span>
                        </FullTextPopup>
                    )}
                </span>
            </div>
        </div>
    </div>
)

/**
 * OrganizationDetails Component
 *
 * This is a component that displays the details of an organization.
 *
 * @param {Object} props - The properties that define the component's behavior and display.
 * @param {string} props.id - The ID of the organization.
 * @param {string} props.name - The name of the organization.
 * @param {Array} props.members - The members of the organization.
 * @param {Array} props.roles - The roles within the organization.
 * @param {Array} props.permissions - The permissions within the organization.
 * @param {Array} props.pendingInvitations - The pending invitations within the organization.
 *
 * @returns {JSX.Element} The OrganizationDetails component.
 */

export const OrganizationDetails = ({
    organizationName,
    type,
    continent,
    country,
    city,
    numberOfEmployees,
    keyContact,
    industry,
    admin,
    teams,
    teamList,
    revenue,
    status,
    isEdit,
    onCancelEditMode,
    onSaveEditMode,
}) => {
    const { t } = useCustomTranslation()
    const [menuOptions, setMenuOptions] = useState(['teams', 'members'])
    const [selectedOption, setSelectedOption] = useState('teams')
    const [createTeamModal, setCreateTeamModal] = useState(false)
    const [inviteMemberModal, setInviteMemberModal] = useState(false)
    const [noAdministration, setNoAdministration] = useState(true)
    const [teamCount, setTeamCount] = useState(3)

    const {
        value,
        setValue: setValueInputs,
        replaceAll,
    } = useMultipleInputs({
        address: {
            name: 'address',
            path: 'address',
            type: 'type',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        },
    })

    return (
        <>
            {createTeamModal && <CreateTeamModal onToggleModal={() => setCreateTeamModal(false)} />}
            {inviteMemberModal && <InviteMemberModal type="organization" orgId={1} onCloseModal={() => setInviteMemberModal(false)} />}
            <div className={styles['organization-details-container']}>
                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-start align-items-center">
                        <img
                            src={icoOrganization}
                            alt="Organization"
                            className={styles['organization-details-container__titleIcon']}
                            style={{ marginRight: '1rem' }}
                        />
                        <span className={styles['organization-details-container__titleText']}>{organizationName}</span>
                        {noAdministration && (
                            <div style={{ marginLeft: '1rem' }}>
                                <NoAdminWarning />
                            </div>
                        )}
                    </div>
                    <div className="col px-0"></div>
                    {isEdit && (
                        <div className="col-auto px-0">
                            <DetailsEditButton onCancelEditMode={onCancelEditMode} onSaveEditMode={onSaveEditMode} />
                        </div>
                    )}
                </div>
                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                    <div className="col-auto px-0">
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <AddressBlock
                                    title="Location"
                                    content={`${city}, ${country}`}
                                    isEdit={isEdit}
                                    input={value.address}
                                    onChangeInput={setValueInputs}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <AddressBlock
                                    title="Region"
                                    content={`${continent}`}
                                    isEdit={isEdit}
                                    input={value.address}
                                    onChangeInput={setValueInputs}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto px-0">
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <AddressBlock title="Admin" content={admin} isEdit={isEdit} input={value.address} onChangeInput={setValueInputs} />
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <AddressBlock title="Teams" content={teams} isEdit={isEdit} input={value.address} onChangeInput={setValueInputs} />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto px-0">
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <AddressBlock
                                    title="Revenue"
                                    content={revenue}
                                    isEdit={isEdit}
                                    input={value.address}
                                    onChangeInput={setValueInputs}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 w-100 flex-grow-1">
                    <div className="col px-0"></div>
                </div>
                <div className="row mx-0 w-100">
                    <div className="col px-0">
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <NavigationMenu
                                    options={menuOptions}
                                    selectedOption={selectedOption}
                                    onChangeOption={(option) => setSelectedOption(option)}
                                />
                            </div>
                            {teamCount > 0 && (
                                <div className="col-auto px-0">
                                    <PrimaryButton text={t('buttons:createTeam')} icon={icoPlus} onClick={() => setCreateTeamModal(true)} />
                                </div>
                            )}
                        </div>
                        {selectedOption === 'teams' && (
                            <>
                                <div className="row mx-0 w-100" style={{ marginBottom: '0rem' }}>
                                    <div className="col px-0">
                                        <span className={styles['organization-details-container__sectionDescription']}>
                                            {t('pages:yourOrganizations.teams')}
                                        </span>
                                    </div>
                                </div>

                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        {teamCount === 0 && (
                                            <div className={styles['organization-details-container__noTeams']}>
                                                <span
                                                    className={styles['organization-details-container__noTeams__text']}
                                                    style={{
                                                        marginBottom: '2rem',
                                                    }}
                                                >
                                                    {t('pages:yourOrganizations.noTeams')}
                                                </span>
                                                <PrimaryButton
                                                    text={t('buttons:createTeamNow')}
                                                    icon={icoCreateTeam}
                                                    onClick={() => setCreateTeamModal(true)}
                                                />
                                            </div>
                                        )}
                                        {teamCount > 0 && <TeamCarousel teams={teamList} />}
                                    </div>
                                </div>
                            </>
                        )}
                        {selectedOption === 'members' && (
                            <>
                                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <span className={styles['organization-details-container__sectionDescription']}>
                                            {t('pages:yourOrganizations.members')}
                                        </span>
                                    </div>
                                </div>

                                <div className="row mx-0 w-100" style={{ height: '50rem' }}>
                                    <div className="col px-0 h-100">
                                        <OrganizationMembersList />
                                    </div>
                                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                                    <div className="col-auto d-flex flex-column justify-content-center" style={{ padding: '0 10rem' }}>
                                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                            <div className="col-auto px-0">
                                                <span className={styles['organization-details-container__big-text']}>500</span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                            style={{
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col-auto px-0">
                                                <span className={styles['organization-details-container__medium-text']}>Members</span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                            <div className="col-auto px-0">
                                                <PrimaryButton
                                                    text={t('buttons:inviteMembers')}
                                                    onClick={() => {
                                                        setInviteMemberModal(true)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
