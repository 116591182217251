export const segmentGeoMock = {
    hoverStyle: '',
    desc: '',
    total: 147,
    revenue: 30000,
    percentage: 47,
    percentageGrowth: 0.6,
    dataList: [
        {
            id: 'data-selected',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            styleSmall: {
                id: 'data-selected-style-small',
                type: 'circle',
                innerRadius: 0.4,
                paint: {
                    'circle-radius': 0.3,
                    'circle-color': '#ff6363',
                    'circle-opacity': 1,
                },
            },
            styleLarge: {
                id: 'data-selected-style-large',
                type: 'circle',
                outerRadius: 1.2,
                paint: {
                    'circle-radius': 1,
                    'circle-color': '#ff6363',
                    'circle-opacity': 0.3,
                },
            },
            styleLargeOutline: {
                id: `data-selected-style-large-outline`,
                type: 'circle',
                outerRadius: 1,
                paint: {
                    'circle-opacity': 0,
                    'circle-radius': 10,
                    'circle-color': '#ff6363',
                    'circle-stroke-opacity': 1,
                    'circle-stroke-color': '#ff6363',
                    'circle-stroke-width': 2,
                },
            },
            styleInterval: {
                id: `data-selected-style--interval`,
                type: 'circle',
                innerRadius: 0.6,
                paint: {
                    'circle-radius': 0.3,
                    'circle-color': '#fff',
                    'circle-opacity': 1,
                },
            },
            styleSegment: {
                id: 'data-selected-style-segment',
                type: 'circle',
                outerRadius: 1,
                paint: {
                    'circle-opacity': 0,
                    'circle-radius': 10,
                    'circle-color': '#8c54ff',
                    'circle-stroke-opacity': 0.6,
                    'circle-stroke-color': '#8c54ff',
                    'circle-stroke-width': 2,
                },
            },
        },
        {
            id: 'data-selected-1',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            styleSmall: {
                id: 'data-selected-1-style-small',
                type: 'circle',
                innerRadius: 0.4,
                paint: {
                    'circle-radius': 0.3,
                    'circle-color': '#6fdb7d',
                    'circle-opacity': 1,
                },
            },
            styleLarge: {
                id: 'data-selected-1-style-large',
                type: 'circle',
                outerRadius: 1.2,
                paint: {
                    'circle-radius': 1,
                    'circle-color': '#6fdb7d',
                    'circle-opacity': 0.3,
                },
            },
            styleLargeOutline: {
                id: `data-selected-1-large-outline`,
                type: 'circle',
                outerRadius: 1,
                paint: {
                    'circle-opacity': 0,
                    'circle-radius': 10,
                    'circle-color': '#6fdb7d',
                    'circle-stroke-opacity': 1,
                    'circle-stroke-color': '#6fdb7d',
                    'circle-stroke-width': 2,
                },
            },
            styleInterval: {
                id: `data-selected-1-interval`,
                type: 'circle',
                innerRadius: 0.6,
                paint: {
                    'circle-radius': 0.3,
                    'circle-color': '#fff',
                    'circle-opacity': 1,
                },
            },
            styleSegment: {
                id: 'data-selected-1-style-segment',
                type: 'circle',
                outerRadius: 1,
                paint: {
                    'circle-opacity': 0,
                    'circle-radius': 10,
                    'circle-color': '#8c54ff',
                    'circle-stroke-opacity': 0.6,
                    'circle-stroke-color': '#8c54ff',
                    'circle-stroke-width': 2,
                },
            },
        },
        {
            id: 'data-selected-2',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            styleSmall: {
                id: 'data-selected-2-style-small',
                type: 'circle',
                innerRadius: 0.4,
                paint: {
                    'circle-radius': 0.3,
                    'circle-color': '#dbdb6f',
                    'circle-opacity': 1,
                },
            },
            styleLarge: {
                id: 'data-selected-2-style-large',
                type: 'circle',
                outerRadius: 1.2,
                paint: {
                    'circle-radius': 0.8,
                    'circle-color': '#dbdb6f',
                    'circle-opacity': 0.3,
                },
            },
            styleLargeOutline: {
                id: `data-selected-2-large-outline`,
                type: 'circle',
                outerRadius: 1,
                paint: {
                    'circle-opacity': 0,
                    'circle-radius': 10,
                    'circle-color': '#dbdb6f',
                    'circle-stroke-opacity': 1,
                    'circle-stroke-color': '#dbdb6f',
                    'circle-stroke-width': 2,
                },
            },
            styleInterval: {
                id: `data-selected-2-interval`,
                type: 'circle',
                innerRadius: 0.6,
                paint: {
                    'circle-radius': 0.3,
                    'circle-color': '#fff',
                    'circle-opacity': 1,
                },
            },
            styleSegment: {
                id: 'data-selected-2-style-segment',
                type: 'circle',
                outerRadius: 1,
                paint: {
                    'circle-opacity': 0,
                    'circle-radius': 10,
                    'circle-color': '#8c54ff',
                    'circle-stroke-opacity': 0.6,
                    'circle-stroke-color': '#8c54ff',
                    'circle-stroke-width': 2,
                },
            },
        },
    ],
    titleText: '',
    attr: 'segmentGeoChart',
    loading: true,
    legend: [
        {
            name: 'Segment 1',
            color: '#8c54ff',
            translated: true,
        },
    ],
    type: 'normal',
    tooltipType: 'clientGeo',
    update: 1,
    disabled: true,
    startingPosition: {
        lat: 38.769688599999995,
        lon: -9.240822000000001,
        zoom: 12,
    },
    language: 'pt',
    minScale: 2,
    scale: 2,
    minZoom: 9,
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 38.736946,
        longitude: -9.142685,
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
    },
    interactiondisabled: true,
    showPopup: false,
    selectedEl: {},
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [
                {
                    name: 'geoChartFilter',
                    icon: '/static/media/ico-view.31bb8949.svg',
                    label: 'Filter',
                    translated: true,
                },
            ],
        },
        viewOptions: {
            ratioOptions: {
                options: [
                    {
                        name: 'defaultStatus',
                        label: 'Status',
                        disabled: false,
                        blocked: false,
                        translated: true,
                    },
                    {
                        name: 'riskStatus',
                        label: 'Risk',
                        disabled: false,
                        blocked: false,
                        translated: true,
                    },
                ],
                selectedOption: 'riskStatus',
            },
            checkboxOptions: {
                options: [
                    {
                        name: 'risk1',
                        label: 'Risk 1',
                        color: '#8c54ff',
                        disabled: false,
                        blocked: false,
                        translated: true,
                    },
                    {
                        name: 'risk2',
                        label: 'Risk 2',
                        color: '#8c54ff',
                        disabled: false,
                        blocked: false,
                        translated: true,
                    },
                    {
                        name: 'risk3',
                        label: 'Risk 3',
                        color: '#8c54ff',
                        disabled: false,
                        blocked: false,
                        translated: true,
                    },
                    {
                        name: 'risk4',
                        label: 'Risk 4',
                        color: '#ff6363',
                        disabled: false,
                        blocked: false,
                        translated: true,
                    },
                ],
                selectedOptions: [
                    'activeClients',
                    'inactiveClients',
                    'risk1',
                    'risk2',
                    'risk3',
                    'risk4',
                    'risk5',
                ],
            },
        },
    },
    ready: true,
}
