import { getProfileImages } from '../../../../../../../../../../../utils/params'

const images = getProfileImages(5)

export const assignedToListMock = {
    titleCounter: '0',
    data: [
        {
            id: 1,
            roleAssignedMember: ['Element 1', images[0]],
            email: 'element1@example.com',
        },
        {
            id: 2,
            roleAssignedMember: ['Element 2', images[1]],
            email: 'element2@example.com',
        },
        {
            id: 3,
            roleAssignedMember: ['Element 3', images[2]],
            email: 'element3@example.com',
        },
        {
            id: 4,
            roleAssignedMember: ['Element 4', images[3]],
            email: 'element4@example.com',
        },
        {
            id: 5,
            roleAssignedMember: ['Element 5', images[4]],
            email: 'element5@example.com',
        },
    ],
    columns: [
        { path: 'id', label: 'ID', sortable: true, show: false },
        { path: 'roleAssignedMember', label: 'Name', sortable: true },
        { path: 'email', label: 'Email', sortable: true },
    ],
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'assignedToList',
    disabled: true,
    loading: true,
}
