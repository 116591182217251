import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import { useIsMounted } from '../../../hooks/useIsMounted.js';
import { useTable } from '../../../hooks/useTable.js';
import { useToggle } from '../../../hooks/useToggle.js';
import { jobListMock } from './mock.jsx';
import { ContentContainer, Loader, Spinner, Table } from '../..';
import { JobsService } from '../../../temp/test';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation.js';

export const JobList = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal] = useToggle(false);
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue
    } = useTable(jobListMock, modal, '');

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(jobListMock);
            try {
                if (isMounted.current) setLoading(true);
                dataClone.data.length = 0;
                dataClone.sortedData.length = 0;
                if (isMounted.current) setValue(dataClone);
                const jobsBack = await JobsService.getApiBackofficeJobs();
                jobsBack.map((el) => {
                    const newEl = {
                        id: el.id,
                        userId: el.user_id,
                        name: el.name,
                        description: el.description,
                        completed: el.complete,
                        success: el.success,
                        timeLeft: el.time_left
                    };
                    dataClone.data.push(newEl);
                    return el;
                });
                dataClone.sortedData = _.orderBy(
                    dataClone.data,
                    [dataClone.sortColumn.path],
                    [dataClone.sortColumn.order]
                );
                verifyComponent(dataClone);
                if (isMounted.current) setValue(dataClone);
                setLoading(false);
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [isMounted, setLoading, setValue, t]);

    return (
        <ContentContainer
            attr={data.attr}
            hidePinIcon
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onCloseSettings={toggleComponentSettings}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <Table
                    actions={data.actions}
                    // onClickActionIcon={onClickActionIcon}
                    // onClickActionOption={onClickActionOption}
                    // onCloseActions={onCloseActions}
                    attr={data.attr}
                    type={data.type}
                    titleCounter={data.titleCounter}
                    titleText={t(`components:${data.attr}.title`)}
                    placeholderText={t(`components:${data.attr}.nodata`)}
                    columns={data.columns}
                    data={data.sortedData}
                    sortColumn={data.sortColumn}
                    onSort={dataSort}
                    currentPage={data.currentPage}
                    pageSize={data.pageSize}
                    onPageChange={dataPageChange}
                    onRowClick={undefined}
                    onRowSelect={undefined}
                    onSelectAll={dataSelectAll}
                    onUnselectAll={dataUnselectAll}
                    paginationDisabled={data.paginationDisabled}
                    disabled={data.disabled}
                ></Table>
            )}
        </ContentContainer>
    );
});

JobList.propTypes = {};
