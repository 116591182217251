import React from 'react'
import { ReactComponent as IcoCheckboxSelected } from '../../assets/images/ico-checkbox-selected.svg'
import { ReactComponent as IcoCheckboxUnselected } from '../../assets/images/ico-checkbox-unselected.svg'
import styles from './select-box-header.module.scss'

export const SelectBoxHeader = React.memo(
    ({ column, loading, onSelectAll, onUnselectAll, attr }) => {
        return (
            <div className={`${styles['select-box-header-container']}`}>
                {!column.selected && !loading && (
                    <IcoCheckboxUnselected
                        onClick={
                            onSelectAll ? () => onSelectAll(attr) : undefined
                        }
                        style={{ cursor: 'pointer' }}
                    />
                )}
                {column.selected && (
                    <IcoCheckboxSelected
                        onClick={
                            onUnselectAll
                                ? () => onUnselectAll(attr)
                                : undefined
                        }
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </div>
        )
    }
)
