/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductCategoryDefaultSchema } from '../models/ProductCategoryDefaultSchema';
import type { RegionDefaultSchema } from '../models/RegionDefaultSchema';
import type { TimeSeriesForecastSchema } from '../models/TimeSeriesForecastSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SalesService {
    /**
     * Returns a timeseries with sales by time interval.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSales({
        interval,
        minDate,
        clientId,
        vendorId,
        locationId,
        productId,
        teamId,
        producers,
        productCategories,
        channelId,
        sumBy,
        regionId,
    }: {
        /**
         * time interval (year, month, quarter, week or day)
         */
        interval: string,
        /**
         * minimum date to filter results
         */
        minDate: string,
        /**
         * list of clients
         */
        clientId?: Array<number>,
        /**
         * list of vendors.
         */
        vendorId?: Array<number>,
        /**
         * list of locations.
         */
        locationId?: Array<string>,
        /**
         * list of products.
         */
        productId?: Array<number>,
        /**
         * list of teams.
         */
        teamId?: Array<number>,
        /**
         * list of producers.
         */
        producers?: Array<string>,
        /**
         * list of product categories.
         */
        productCategories?: Array<number>,
        /**
         * list of channel ids.
         */
        channelId?: Array<number>,
        /**
         * what property to aggregate sum
         */
        sumBy?: 'billing' | 'units',
        /**
         * list of regions.
         */
        regionId?: Array<number>,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales',
            query: {
                'interval': interval,
                'client_id': clientId,
                'vendor_id': vendorId,
                'location_id': locationId,
                'product_id': productId,
                'team_id': teamId,
                'producers': producers,
                'product_categories': productCategories,
                'channel_id': channelId,
                'min_date': minDate,
                'sum_by': sumBy,
                'region_id': regionId,
            },
        });
    }
    /**
     * Returns a dic with sales with all product categories.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesByProductCategory({
        minDate,
        maxDate,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
        /**
         * maximum date to filter results
         */
        maxDate?: string,
    }): CancelablePromise<Array<{
        category?: ProductCategoryDefaultSchema;
        value?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/by-product-category',
            query: {
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
    /**
     * Returns a dic with sales with all regions.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesByRegion({
        minDate,
        maxDate,
        productId,
        regionLevel,
        unitsOrBilling,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
        /**
         * maximum date to filter results
         */
        maxDate?: string,
        /**
         * list of products.
         */
        productId?: Array<number>,
        /**
         * level deep of regions.
         */
        regionLevel?: number,
        /**
         * what property to aggregate sum
         */
        unitsOrBilling?: 'billing' | 'units',
    }): CancelablePromise<Array<{
        region?: RegionDefaultSchema;
        value?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/by-region',
            query: {
                'min_date': minDate,
                'max_date': maxDate,
                'product_id': productId,
                'region_level': regionLevel,
                'units_or_billing': unitsOrBilling,
            },
        });
    }
    /**
     * Returns a timeseries with sales by day.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSalesDay({
        clientId,
        minDate,
        locationId,
        productId,
        teamId,
        producers,
        productCategories,
        channelId,
    }: {
        /**
         * list of clients
         */
        clientId: Array<number>,
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate: string,
        /**
         * list of locations.
         */
        locationId?: Array<string>,
        /**
         * list of products.
         */
        productId?: Array<number>,
        /**
         * list of teams.
         */
        teamId?: Array<number>,
        /**
         * list of producers.
         */
        producers?: Array<string>,
        /**
         * list of product categories.
         */
        productCategories?: Array<number>,
        /**
         * list of channel ids.
         */
        channelId?: Array<number>,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/day',
            query: {
                'client_id': clientId,
                'location_id': locationId,
                'product_id': productId,
                'team_id': teamId,
                'producers': producers,
                'product_categories': productCategories,
                'channel_id': channelId,
                'min_date': minDate,
            },
        });
    }
    /**
     * returns products sales percentage distribution from total.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesDistribution({
        clientId,
        vendorId,
        regions,
        minDate,
        maxDate,
    }: {
        /**
         * list of clients
         */
        clientId?: Array<number>,
        /**
         * vendors to filter
         */
        vendorId?: Array<number>,
        /**
         * region to filter
         */
        regions?: Array<number>,
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
        /**
         * maximum date to filter results
         */
        maxDate?: string,
    }): CancelablePromise<Array<{
        product_id?: number;
        product_name?: string;
        value?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/distribution',
            query: {
                'client_id': clientId,
                'vendor_id': vendorId,
                'regions': regions,
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
    /**
     * returns producer percentage distribution from total.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesDistributionProducer({
        clientId,
        vendorId,
        regions,
        minDate,
        maxDate,
    }: {
        /**
         * list of clients
         */
        clientId?: Array<number>,
        /**
         * vendors to filter
         */
        vendorId?: Array<number>,
        /**
         * region to filter
         */
        regions?: Array<number>,
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
        /**
         * maximum date to filter results
         */
        maxDate?: string,
    }): CancelablePromise<Array<{
        product_id?: number;
        product_name?: string;
        value?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/distribution/producer',
            query: {
                'client_id': clientId,
                'vendor_id': vendorId,
                'regions': regions,
                'min_date': minDate,
                'max_date': maxDate,
            },
        });
    }
    /**
     * Returns sales percentage for group of Clients, for the last three months versus one year ago.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesHomologue({
        clientId,
        vendorId,
        locationId,
        productId,
        teamId,
        producers,
        productCategories,
        channelId,
        unitsOrBilling,
    }: {
        /**
         * list of clients
         */
        clientId?: Array<number>,
        /**
         * vendors to filter
         */
        vendorId?: Array<number>,
        /**
         * list of locations.
         */
        locationId?: Array<string>,
        /**
         * list of products
         */
        productId?: Array<number>,
        /**
         * list of teams.
         */
        teamId?: Array<number>,
        /**
         * list of producers.
         */
        producers?: Array<string>,
        /**
         * list of product categories.
         */
        productCategories?: Array<number>,
        /**
         * list of channel ids.
         */
        channelId?: Array<number>,
        /**
         * what property to aggregate sum
         */
        unitsOrBilling?: 'units' | 'billing',
    }): CancelablePromise<{
        result?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/homologue',
            query: {
                'client_id': clientId,
                'vendor_id': vendorId,
                'location_id': locationId,
                'product_id': productId,
                'team_id': teamId,
                'producers': producers,
                'product_categories': productCategories,
                'channel_id': channelId,
                'units_or_billing': unitsOrBilling,
            },
        });
    }
    /**
     * Returns a timeseries with sales by month
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesMonth({
        minDate,
        clientId,
        vendorId,
        locationId,
        productId,
        teamId,
        sumBy,
        regionId,
        producers,
        productCategories,
        channelId,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate: string,
        /**
         * list of clients.
         */
        clientId?: Array<number>,
        /**
         * list of vendors.
         */
        vendorId?: Array<number>,
        /**
         * list of locations.
         */
        locationId?: Array<string>,
        /**
         * list of products.
         */
        productId?: Array<number>,
        /**
         * list of teams.
         */
        teamId?: Array<number>,
        /**
         * what property to aggregate sum
         */
        sumBy?: 'billing' | 'units',
        /**
         * list of regions.
         */
        regionId?: Array<number>,
        /**
         * list of producers.
         */
        producers?: Array<string>,
        /**
         * list of product categories.
         */
        productCategories?: Array<number>,
        /**
         * list of channel ids
         */
        channelId?: Array<number>,
    }): CancelablePromise<{
        forecast?: TimeSeriesForecastSchema;
        timeseries?: Record<string, number>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/month',
            query: {
                'client_id': clientId,
                'vendor_id': vendorId,
                'location_id': locationId,
                'product_id': productId,
                'team_id': teamId,
                'min_date': minDate,
                'sum_by': sumBy,
                'region_id': regionId,
                'producers': producers,
                'product_categories': productCategories,
                'channel_id': channelId,
            },
        });
    }
    /**
     * Returns a timeseries with sales by month for each team
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesMonthPerTeam({
        minDate,
        teamId,
        sumBy,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate: string,
        /**
         * list of teams.
         */
        teamId?: Array<number>,
        /**
         * what property to aggregate sum
         */
        sumBy?: 'billing' | 'units',
    }): CancelablePromise<Array<{
        forecast?: TimeSeriesForecastSchema;
        team?: string;
        team_id?: number;
        timeseries?: Record<string, number>;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/month/per-team',
            query: {
                'team_id': teamId,
                'min_date': minDate,
                'sum_by': sumBy,
            },
        });
    }
    /**
     * revenue at risk for groups of clients based on predicted risk.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesRevenueAtRisk({
        clientId,
    }: {
        /**
         * list of clients
         */
        clientId: Array<number>,
    }): CancelablePromise<Array<{
        percentage_from_total?: number;
        revenue_at_risk?: number;
        safe_revenue?: number;
        'x'?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/revenue_at_risk',
            query: {
                'client_id': clientId,
            },
        });
    }
    /**
     * Returns the sum of sales for a given query.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSalesSum({
        clientId,
        vendorId,
        locationId,
        productId,
        teamId,
        channelId,
        producers,
        productCategories,
        unitsOrBilling,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * list of clients.
         */
        clientId?: Array<number>,
        /**
         * list of vendors.
         */
        vendorId?: Array<number>,
        /**
         * list of locations.
         */
        locationId?: Array<string>,
        /**
         * list of products.
         */
        productId?: Array<number>,
        /**
         * list of teams.
         */
        teamId?: Array<number>,
        /**
         * list of channel ids.
         */
        channelId?: Array<number>,
        /**
         * list of producers.
         */
        producers?: Array<string>,
        /**
         * list of product categories.
         */
        productCategories?: Array<number>,
        /**
         * what property to aggregate sum
         */
        unitsOrBilling?: 'units' | 'billing',
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: date, billing, costs.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'date' | 'billing' | 'costs',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<{
        sum?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/sum',
            query: {
                'client_id': clientId,
                'vendor_id': vendorId,
                'location_id': locationId,
                'product_id': productId,
                'team_id': teamId,
                'channel_id': channelId,
                'producers': producers,
                'product_categories': productCategories,
                'units_or_billing': unitsOrBilling,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * Returns timeseries by segments.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSalesTimeseriesSegment({
        minDate,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
    }): CancelablePromise<Record<string, Record<string, number>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/timeseries/segment',
            query: {
                'min_date': minDate,
            },
        });
    }
    /**
     * Returns a dict with total revenue for all departments.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSalesTotalRevenueDepartment({
        minDate,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/total_revenue/department',
            query: {
                'min_date': minDate,
            },
        });
    }
    /**
     * Returns a dict with total revenue for all point of sale locations.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSalesTotalRevenuePosLocation({
        minDate,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/total_revenue/pos-location',
            query: {
                'min_date': minDate,
            },
        });
    }
    /**
     * Returns a dict with total revenue for all segments.
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSalesTotalRevenueSegment({
        minDate,
    }: {
        /**
         * minimum date to filter results (in the format 'YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ')
         */
        minDate?: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/total_revenue/segment',
            query: {
                'min_date': minDate,
            },
        });
    }
}
