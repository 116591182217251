import ContactSupport from '../pages/auth/contact-support/contact-support';
import ForgotPassword from '../pages/auth/forgot-password/forgot-password';
import Login from '../pages/auth/login/login';
import Logout from '../pages/auth/logout/logout';
import ResetPassword from '../pages/auth/reset-password/reset-password';
import SetPassword from '../pages/auth/set-password/set-password';
import VerificationCode from '../pages/auth/verification-code/verification-code';
import ReportBuilder from '../pages/general/report-builder/report-builder';

export const authMap = {
    Login: Login,
    ForgotPassword: ForgotPassword,
    ContactSupport: ContactSupport,
    ResetPassword: ResetPassword,
    SetPassword: SetPassword,
    Logout: Logout,
    VerificationCode: VerificationCode,
    ReportBuilder: ReportBuilder
};
