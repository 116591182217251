import React, { useCallback } from 'react'
import styles from './TableInput.module.scss'
import icoDelete from '../../../assets/images/ico-delete.svg'
import icoPlus from '../../../assets/images/ico-plus.svg'
import Scrollable from '../../scrolling/scrollable'
import { useTimeSeriesManager } from '../../../providers/time-series-manager-context'
import { getDayFromDateFormat, getMonthFromDateFormat, getQuarterFromDateFormat, getYearFromDateFormat } from '../../../utils/date'

export const TableInput = React.memo(({ title, onAdd, onRemove }) => {
    const { data, xAxis, xAxisFormat, yAxis } = useTimeSeriesManager()

    const xAxisFormatter = useCallback(
        (value) => {
            if (xAxisFormat === 'month') {
                return getMonthFromDateFormat(value)
            } else if (xAxisFormat === 'year') {
                return getYearFromDateFormat(value)
            } else if (xAxisFormat === 'quarter') {
                return getQuarterFromDateFormat(value)
            } else if (xAxisFormat === 'day') {
                return getDayFromDateFormat(value)
            }
            return value
        },
        [xAxisFormat]
    )

    return (
        <div className={`${styles['table-input-container']}`}>
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex ">
                    <span className={`${styles['table-input-container__title']}`}>{title}</span>
                    <img src={icoPlus} alt="Add" className={`${styles['table-input-container__add']}`} onClick={onAdd} />
                </div>
            </div>
            <div className="row mx-0 w-100 flex-grow-1">
                <div className="col px-0 h-100">
                    <Scrollable addRightSpacing>
                        {data &&
                            data.map((element, index) => {
                                return (
                                    <div className={`${styles['table-input-container__element']}`} key={index}>
                                        <div className="row mx-0 w-100">
                                            <div className="col-auto px-0">
                                                <span className={`${styles['table-input-container__element__text']}`} style={{ color: '#969696' }}>
                                                    {xAxisFormatter(element[xAxis])}
                                                </span>{' '}
                                                <span className={`${styles['table-input-container__element__text']}`}>{element[yAxis]}</span>
                                            </div>
                                            <div className="col px-0"></div>
                                            <div className="col-auto px-0">
                                                <img
                                                    src={icoDelete}
                                                    alt="Delete"
                                                    className={`${styles['table-input-container__element__delete']}`}
                                                    onClick={() => onRemove(element)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Scrollable>
                </div>
            </div>
        </div>
    )
})
