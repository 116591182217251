import React, { useCallback } from 'react'
import { GeneralTable } from '../general-table/general-table'
import { dmuListMock } from './mock'
import { useFilter } from '../../../providers/filter-context'
import { ClientAccountService } from '../../../temp/test'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const DmuList = () => {
    const { t } = useCustomTranslation()
    const [clientAccountId, setClientAccountId] = React.useState(undefined)
    const { filter } = useFilter()

    const getClientAccountId = useCallback(async () => {
        try {
            const backData = await ClientAccountService.getApiClientAccount({})
            const filteredData = backData.filter((e) => {
                return (
                    e.product_id === parseInt(filter.productId) &&
                    e.client_id === parseInt(filter.hospitalId)
                )
            })
            if (filteredData.length > 0) {
                return filteredData[0].id
            } else if (filteredData.length === 0) {
                const result =
                    await ClientAccountService.postApiClientAccountUpsert({
                        requestBody: {
                            product_id: filter.productId,
                            client_id: filter.hospitalId,
                        },
                    })
                return result.id
            }
        } catch (err) {
            console.log(err)
        }
    }, [filter])

    return (
        <GeneralTable
            title={t(`components:${dmuListMock.attr}.title`)}
            attr={dmuListMock.attr}
            columns={[
                {
                    path: 'id',
                    backendPath: 'id',
                    show: false,
                },
                {
                    path: 'name',
                    backendPath: 'name',
                    show: true,
                },
                {
                    path: 'function',
                    backendPath: 'job_title',
                    show: true,
                },
                {
                    path: 'raci',
                    backendPath: 'raci',
                    show: true,
                },
                {
                    path: 'influence',
                    backendPath: 'influence',
                    show: true,
                },
                {
                    path: 'engagementAZ',
                    backendPath: 'engagement',
                    show: true,
                },
                {
                    path: 'action',
                    show: true,
                },
            ]}
            getEndpoint={
                ClientAccountService.getApiClientAccountDmusGetByParams
            }
            getConfig={{
                clientId: filter.hospitalId,
                productId: filter.productId,
            }}
            addTitle={t(`components:${dmuListMock.attr}.addTitle`)}
            addSuccessText={t(`components:${dmuListMock.attr}.addSuccessText`)}
            addEndpoint={ClientAccountService.postApiClientAccountDmusUpsert}
            addBodyStaticInputs={[
                {
                    value: getClientAccountId,
                    backendPath: 'client_account_id',
                },
            ]}
            addBodyInputs={{
                name: {
                    name: 'name',
                    path: 'name',
                    backendPath: 'name',
                    type: 'type',
                    show: true,
                },
                function: {
                    name: 'function',
                    path: 'function',
                    backendPath: 'job_title',
                    type: 'type',
                    show: true,
                },
                raci: {
                    name: 'raci',
                    path: 'raci',
                    backendPath: 'raci',
                    type: 'type',
                    show: true,
                },
                influence: {
                    name: 'influence',
                    path: 'influence',
                    backendPath: 'influence',
                    type: 'type',
                    show: true,
                },
                engagementAZ: {
                    name: 'engagementAZ',
                    path: 'engagementAZ',
                    backendPath: 'engagement',
                    type: 'type',
                    show: true,
                },
            }}
            updateTitle={t(`components:${dmuListMock.attr}.updateTitle`)}
            updateSuccessText={t(
                `components:${dmuListMock.attr}.updateSuccessText`
            )}
            updateBodyStaticInputs={[
                {
                    path: 'id',
                    backendPath: 'id',
                },
            ]}
            updateEndpoint={ClientAccountService.postApiClientAccountDmusUpsert}
            updateBodyInputs={{
                name: {
                    name: 'name',
                    path: 'name',
                    backendPath: 'name',
                    type: 'type',
                    show: true,
                },
                function: {
                    name: 'function',
                    path: 'function',
                    backendPath: 'job_title',
                    type: 'type',
                    show: true,
                },
                raci: {
                    name: 'raci',
                    path: 'raci',
                    backendPath: 'raci',
                    type: 'type',
                    show: true,
                },
                influence: {
                    name: 'influence',
                    path: 'influence',
                    backendPath: 'influence',
                    type: 'type',
                    show: true,
                },
                engagementAZ: {
                    name: 'engagementAZ',
                    path: 'engagementAZ',
                    backendPath: 'engagement',
                    type: 'type',
                    show: true,
                },
            }}
            deleteIds={[
                {
                    path: 'id',
                    backendPath: 'id',
                },
            ]}
            deleteEndpoint={ClientAccountService.deleteApiClientAccountDmus}
            deleteTitle={t(`components:${dmuListMock.attr}.deleteTitle`)}
            deleteDescription={t(
                `components:${dmuListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${dmuListMock.attr}.deleteSuccessText`
            )}
        />
    )
}
