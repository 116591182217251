import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { totalInvestmentMock } from './mock';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import styles from './total-investment.module.scss';
import { PointOfSaleService } from '../../../temp/test';
import { getValueInString } from '../../../utils/unit';
import { getFilterParam } from '../../../utils/params';
import { ContentContainer, Loader, Spinner } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays the total investment
 */

export const TotalInvestment = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(totalInvestmentMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(totalInvestmentMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        if (type) dataClone.attr = type;
                        const dataBack =
                            await PointOfSaleService.getApiPosClientOverviewTableVendors(
                                {
                                    vendorId: getFilterParam(filter, config, [
                                        'vendorId',
                                        'vendorIds'
                                    ])
                                }
                            );
                        if (dataBack.investment) {
                            dataClone.headline = getValueInString(
                                dataBack.investment,
                                'values'
                            );
                            dataClone.headlineType = 'money';
                        } else {
                            dataClone.headline = '-';
                            dataClone.headlineType = '';
                        }
                        dataClone.loading = false;

                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            type
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={`${isPreview ? 'pe-none' : ''} w-100 h-100 ${
                    styles['total-investment-container']
                }`}
                style={{ zIndex: 'inherit' }}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <ContentContainer
                        attr={data.attr}
                        titleText={t(`components:${data.attr}.title`)}
                        disabled={data.disabled}
                        isForecast={data.isForecast}
                        legend={data.legend}
                        settings={data.settings}
                        onClickSettingsIcon={toggleComponentSettings}
                        onClickSettingsOptions={handleSelectSettingsOption}
                        onClickSettingsView={handleSelectSettingsOption}
                        onCLickSettintsSelected={handleSelectSettingsOption}
                        onCloseSettings={toggleComponentSettings}
                        // hovered={hovered}
                        onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                        onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                        hideInfoIcon={true}
                        hidePinIcon={isExample || isPreview || isReportExample}
                        hideSettingsIcon={
                            isExample || isPreview || isReportExample
                        }
                        pinned={pinned}
                        onPinComponent={onPinComponent}
                        onUnpinComponent={onUnpinComponent}
                    >
                        <>
                            <span
                                className={`${styles['total-investment-container__text']}`}
                            >
                                {data.headline || '€ 100.000,00'}
                            </span>
                        </>
                    </ContentContainer>
                )}
            </div>
        );
    }
);

TotalInvestment.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
