import { createContext, useContext } from 'react';

const PreviewModeContext = createContext({
    isExample: undefined
});

export function PreviewModeProvider({ value, children }) {
    return (
        <PreviewModeContext.Provider value={value}>
            {children}
        </PreviewModeContext.Provider>
    );
}

export function usePreviewMode() {
    return useContext(PreviewModeContext);
}
