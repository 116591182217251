import React from 'react'
import styles from './pie-chart-new.module.scss'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import { hexToRGBA } from '../../../utils/color'
import _ from 'lodash'
import { TooltipWrapper } from '../../formatting/TooltipWrapper/TooltipWrapper'

const ActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, onClick, setHoveredDonutPiece, index } =
        props

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                onMouseEnter={() => {
                    setHoveredDonutPiece(index)
                }}
                onMouseLeave={() => setHoveredDonutPiece(undefined)}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 5}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
                cursor={onClick ? 'pointer' : 'default'}
                onClick={onClick}
                style={{
                    filter: `drop-shadow(0px 0px 5px ${hexToRGBA(payload.color ? payload.color : '#4c4c4c', 0.6)})`,
                    cursor: onClick ? 'pointer' : 'default',
                }}
            />
        </g>
    )
}

export const PieChartNew = React.memo(({ isDonut, donutAttr, data, attr, onFilterData, isName }) => {
    const [animationActive, setAnimationActive] = React.useState(true)
    const [hoveredDonutPiece, setHoveredDonutPiece] = React.useState(undefined)
    const [selectedDonutPiece, setSelectedDonutPiece] = React.useState(undefined)
    const RADIAN = Math.PI / 180

    return (
        <div
            className="row mx-0 w-100 position-relative"
            style={{
                width: '100%',
                aspectRatio: '1/1',
            }}
        >
            <div className="col px-0 d-flex flex-column h-100">
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 h-100" style={{ zIndex: '1' }}>
                        <div className={styles['pie-chart-new-container__background-circle']}></div>
                        <div className={styles['pie-chart-new-container__gradient-band']}></div>
                        <TooltipWrapper attr={attr} data={data} hoveredDonutPiece={hoveredDonutPiece} />
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart width={'100%'} height={'100%'}>
                                <defs>
                                    <linearGradient id={`gradient`} x1="1" y1="0.5" x2="0" y2="0">
                                        <stop offset="0%" stopColor={'#2CD9C5'} stopOpacity={0.3} />
                                        <stop offset="41%" stopColor={'#2E5BFF'} stopOpacity={0.53} />
                                        <stop offset="98%" stopColor={'#0EA6FA'} stopOpacity={1} />
                                    </linearGradient>
                                </defs>
                                {donutAttr.map((el, index) => {
                                    return (
                                        <Pie
                                            activeIndex={selectedDonutPiece}
                                            activeShape={
                                                <ActiveShape
                                                    index={selectedDonutPiece}
                                                    setHoveredDonutPiece={setHoveredDonutPiece}
                                                    onClick={() => {
                                                        if (onFilterData) {
                                                            setSelectedDonutPiece(undefined)
                                                            onFilterData(undefined)
                                                        }
                                                    }}
                                                />
                                            }
                                            key={el.dataKey}
                                            data={data[el.dataKey]}
                                            dataKey={el.valueKey}
                                            nameKey={el.nameKey}
                                            labelLine={isDonut ? true : false}
                                            label={false}
                                            innerRadius={0}
                                            outerRadius={'100%'}
                                            paddingAngle={isDonut ? 2 : 0}
                                            cornerRadius={isDonut ? 10 : 0}
                                            isAnimationActive={animationActive}
                                            onAnimationEnd={() => setAnimationActive(false)}
                                        >
                                            {data[el.dataKey].map((entry, index2) => {
                                                return (
                                                    <Cell
                                                        key={`cell-${index2}`}
                                                        fill={_.has(entry, 'isGradient') && entry.isGradient ? `url(#gradient)` : '#fff'}
                                                        stroke="none"
                                                        style={{
                                                            visibility: _.has(entry, 'isGradient') && entry.isGradient ? 'visible' : 'hidden',
                                                            filter: !(_.has(entry, 'isGradient') && entry.isGradient)
                                                                ? `drop-shadow(0px 0px 5px ${hexToRGBA('#4c4c4c', 0.6)})`
                                                                : 'none',
                                                        }}
                                                        onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                        onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                        onClick={() => {
                                                            if (onFilterData) {
                                                                const value =
                                                                    selectedDonutPiece !== undefined && selectedDonutPiece === index2
                                                                        ? undefined
                                                                        : index2

                                                                setSelectedDonutPiece(value)
                                                                onFilterData(value)
                                                            }
                                                        }}
                                                    />
                                                )
                                            })}
                                        </Pie>
                                    )
                                })}
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
})
