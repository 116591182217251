import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { associatedTeamsMock } from './mock';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useTable } from '../../../hooks/useTable';
import { ProductsService } from '../../../temp/test';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import {
    AssociateTeamModal,
    ContentContainer,
    DisassociateTeamModal,
    Loader,
    Spinner,
    Table
} from '../../';
import { usePub } from '../../../hooks/usePubSub';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useFilter } from '../../../providers/filter-context';
import { usePreviewMode } from '../../dashboard-creation/library-component-factory/preview-mode-provider';

/**
 * Lists all the teams composed of employees in a table
 */

export const AssociatedTeams = React.memo(({ isReportBuilder }) => {
    const publish = usePub();
    const isMounted = useIsMounted();
    const { isExample } = usePreviewMode();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const { filter } = useFilter();
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(associatedTeamsMock, modal, '');

    const getTeams = useCallback(async () => {
        const dataClone = _.cloneDeep(associatedTeamsMock);
        try {
            if (!isExample) {
                if (isMounted.current) setLoading(true);
                dataClone.data.length = 0;
                dataClone.sortedData.length = 0;
                dataClone.currentPage = 1;
                if (isMounted.current) setValue(dataClone);
                const teamsBackData = await ProductsService.getApiProductsTeams(
                    {
                        productId: filter.productId
                            ? filter.productId
                            : undefined
                    }
                );
                teamsBackData.map((el) => {
                    const newEl = {
                        id: el.id,
                        name: el.name ? el.name : '',
                        responsibleMembers:
                            el.leaders.length > 0 ? el.leaders.length : '',
                        productsCount: el.products_count
                            ? el.products_count
                            : '',
                        action: {
                            label: t(`columns:action`),
                            id: el.id,
                            open: false,
                            options: [
                                {
                                    name: 'disassociateTeam',
                                    label: t(`settings:disassociateTeam`)
                                }
                            ],
                            reference: React.createRef()
                        }
                    };
                    dataClone.data.push(newEl);
                    return el;
                });
                dataClone.sortedData = _.orderBy(
                    dataClone.data,
                    [dataClone.sortColumn.path],
                    [dataClone.sortColumn.order]
                );
                verifyComponent(dataClone, t);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } else {
                verifyComponent(dataClone);
                setValue(dataClone);
                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue, t, filter, isExample]);

    useEffect(() => {
        getTeams();
    }, [getTeams]);

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false);
            setModalType('');
            setSelectedTeamId('');
        }
    };

    const handleAssociateTeamOpenModal = async () => {
        if (isMounted.current) {
            toggleModal(true);
            setModalType('associateTeam');
        }
    };

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedTeamId(id);
            }
            if (isMounted.current) {
                toggleComponentSettings(false);
                toggleModal(true);
                setModalType(option);
            }
        },
        [isMounted, toggleComponentSettings, toggleModal]
    );

    return (
        <>
            {modal && modalType === 'associateTeam' && (
                <AssociateTeamModal
                    onCloseModal={handleCloseModal}
                    onEnd={() => getTeams()}
                />
            )}
            {modal && modalType === 'disassociateTeam' && (
                <DisassociateTeamModal
                    teamId={selectedTeamId}
                    onCloseModal={handleCloseModal}
                    onEnd={() => getTeams()}
                />
            )}
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <React.Fragment>
                        <Table
                            isReportBuilder={isReportBuilder}
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.sortedData.length}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(
                                `components:${data.attr}.nodata`
                            )}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={undefined}
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                            onNewElementClick={handleAssociateTeamOpenModal}
                            onNewElementText={t('buttons:associateTeam')}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </>
    );
});

AssociatedTeams.propTypes = {};
