import React, { useMemo } from 'react';
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import styles from './goal-summary-card.module.scss';
import icoChangeYearArrow from '../../../assets/images/icoChangeYearArrow.svg';
import icoGoal from '../../../assets/images/icoGoal.svg';
import icoMilestone from '../../../assets/images/icoMilestone.svg';
import icoSubGoal from '../../../assets/images/icoSubGoal.svg';
import { Loader } from '../../loading/Loader/Loader';
import { DonutChartWithCircle } from '../../charts/donut-chart-with-circle/donut-chart-with-circle';
import {
    TimeIntervalProvider,
    useTimeInterval
} from '../../../providers/time-interval-context';
import { months } from '../../../utils/date';
import { Spinner } from '../../loading/spinner/spinner';

const goalSummaryCardMock = {
    attr: 'goalSummaryCard',
    cards: {
        2023: [
            {
                quarter: 1,
                semester: 1,
                month: 1,
                goalsTotal: 18,
                goalsReached: 15,
                milestonesTotal: 18,
                milestonesReached: 14,
                subGoalsTotal: 18,
                subGoalsReached: 12
            },
            {
                quarter: 1,
                semester: 1,
                month: 2,
                goalsTotal: 19,
                goalsReached: 16,
                milestonesTotal: 19,
                milestonesReached: 13,
                subGoalsTotal: 19,
                subGoalsReached: 11
            },
            {
                quarter: 1,
                semester: 1,
                month: 3,
                goalsTotal: 20,
                goalsReached: 18,
                milestonesTotal: 20,
                milestonesReached: 15,
                subGoalsTotal: 20,
                subGoalsReached: 14
            },
            {
                quarter: 2,
                semester: 1,
                month: 4,
                goalsTotal: 21,
                goalsReached: 19,
                milestonesTotal: 21,
                milestonesReached: 16,
                subGoalsTotal: 21,
                subGoalsReached: 13
            },
            {
                quarter: 2,
                semester: 1,
                month: 5,
                goalsTotal: 22,
                goalsReached: 17,
                milestonesTotal: 22,
                milestonesReached: 14,
                subGoalsTotal: 22,
                subGoalsReached: 12
            },
            {
                quarter: 2,
                semester: 1,
                month: 6,
                goalsTotal: 23,
                goalsReached: 20,
                milestonesTotal: 23,
                milestonesReached: 18,
                subGoalsTotal: 23,
                subGoalsReached: 16
            },
            {
                quarter: 3,
                semester: 2,
                month: 7,
                goalsTotal: 24,
                goalsReached: 21,
                milestonesTotal: 24,
                milestonesReached: 19,
                subGoalsTotal: 24,
                subGoalsReached: 18
            },
            {
                quarter: 3,
                semester: 2,
                month: 8,
                goalsTotal: 25,
                goalsReached: 22,
                milestonesTotal: 25,
                milestonesReached: 21,
                subGoalsTotal: 25,
                subGoalsReached: 20
            },
            {
                quarter: 3,
                semester: 2,
                month: 9,
                goalsTotal: 26,
                goalsReached: 23,
                milestonesTotal: 26,
                milestonesReached: 22,
                subGoalsTotal: 26,
                subGoalsReached: 21
            },
            {
                quarter: 4,
                semester: 2,
                month: 10,
                goalsTotal: 27,
                goalsReached: 24,
                milestonesTotal: 27,
                milestonesReached: 23,
                subGoalsTotal: 27,
                subGoalsReached: 22
            },
            {
                quarter: 4,
                semester: 2,
                month: 11,
                goalsTotal: 28,
                goalsReached: 25,
                milestonesTotal: 28,
                milestonesReached: 24,
                subGoalsTotal: 28,
                subGoalsReached: 23
            },
            {
                quarter: 4,
                semester: 2,
                month: 12,
                goalsTotal: 29,
                goalsReached: 26,
                milestonesTotal: 29,
                milestonesReached: 25,
                subGoalsTotal: 29,
                subGoalsReached: 24
            }
        ],
        2024: [
            {
                quarter: 1,
                semester: 1,
                month: 1,
                goalsTotal: 19,
                goalsReached: 16,
                milestonesTotal: 19,
                milestonesReached: 14,
                subGoalsTotal: 19,
                subGoalsReached: 13
            },
            {
                quarter: 1,
                semester: 1,
                month: 2,
                goalsTotal: 20,
                goalsReached: 17,
                milestonesTotal: 20,
                milestonesReached: 15,
                subGoalsTotal: 20,
                subGoalsReached: 12
            },
            {
                quarter: 1,
                semester: 1,
                month: 3,
                goalsTotal: 21,
                goalsReached: 18,
                milestonesTotal: 21,
                milestonesReached: 16,
                subGoalsTotal: 21,
                subGoalsReached: 14
            },
            {
                quarter: 2,
                semester: 1,
                month: 4,
                goalsTotal: 22,
                goalsReached: 19,
                milestonesTotal: 22,
                milestonesReached: 17,
                subGoalsTotal: 22,
                subGoalsReached: 15
            },
            {
                quarter: 2,
                semester: 1,
                month: 5,
                goalsTotal: 23,
                goalsReached: 20,
                milestonesTotal: 23,
                milestonesReached: 18,
                subGoalsTotal: 23,
                subGoalsReached: 16
            },
            {
                quarter: 2,
                semester: 1,
                month: 6,
                goalsTotal: 24,
                goalsReached: 21,
                milestonesTotal: 24,
                milestonesReached: 19,
                subGoalsTotal: 24,
                subGoalsReached: 17
            },
            {
                quarter: 3,
                semester: 2,
                month: 7,
                goalsTotal: 25,
                goalsReached: 22,
                milestonesTotal: 25,
                milestonesReached: 20,
                subGoalsTotal: 25,
                subGoalsReached: 18
            },
            {
                quarter: 3,
                semester: 2,
                month: 8,
                goalsTotal: 26,
                goalsReached: 23,
                milestonesTotal: 26,
                milestonesReached: 21,
                subGoalsTotal: 26,
                subGoalsReached: 19
            },
            {
                quarter: 3,
                semester: 2,
                month: 9,
                goalsTotal: 27,
                goalsReached: 24,
                milestonesTotal: 27,
                milestonesReached: 22,
                subGoalsTotal: 27,
                subGoalsReached: 20
            },
            {
                quarter: 4,
                semester: 2,
                month: 10,
                goalsTotal: 28,
                goalsReached: 25,
                milestonesTotal: 28,
                milestonesReached: 23,
                subGoalsTotal: 28,
                subGoalsReached: 21
            },
            {
                quarter: 4,
                semester: 2,
                month: 11,
                goalsTotal: 29,
                goalsReached: 26,
                milestonesTotal: 29,
                milestonesReached: 24,
                subGoalsTotal: 29,
                subGoalsReached: 22
            },
            {
                quarter: 4,
                semester: 2,
                month: 12,
                goalsTotal: 30,
                goalsReached: 27,
                milestonesTotal: 30,
                milestonesReached: 25,
                subGoalsTotal: 30,
                subGoalsReached: 23
            }
        ]
    }
};

const COLORS = {
    RED: '#ef9e9c',
    YELLOW: 'rgba(245, 164, 20, 1)',
    GREEN: 'rgba(44, 217, 197, 1)'
};

const SummaryRow = ({ title, icon, total, reached }) => {
    const donutInfo = {
        donutAttr: [
            {
                dataKey: 'data1',
                valueKey: 'value',
                nameKey: 'key1'
            }
        ],
        data: {
            data1: [
                {
                    value: 7,
                    key1: 7,
                    color:
                        reached / total === 1
                            ? COLORS.GREEN
                            : reached / total > 0.7
                            ? COLORS.YELLOW
                            : COLORS.RED,
                    isRounded: true
                },
                {
                    value: 9,
                    key1: 9,
                    color: '#f2f2f2'
                }
            ]
        },
        insideTitle: Math.round((reached / total) * 100) + '%',
        insideTitleColor:
            reached / total === 1
                ? COLORS.GREEN
                : reached / total > 0.7
                ? COLORS.YELLOW
                : COLORS.RED
    };
    return (
        <div className="row mx-0 w-100" style={{ marginTop: '2rem' }}>
            <div className="col-8 px-0">
                <div className="row mx-0 w-100">
                    <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                        <img
                            src={icon}
                            alt="icon"
                            className={styles['goal-summary-card__card__icon']}
                            style={{ marginRight: '1rem' }}
                        />
                    </div>
                    <div className="col px-0 d-flex justify-content-start align-items-center">
                        <span
                            className={
                                styles['goal-summary-card__card__content-title']
                            }
                        >
                            {title}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 w-100" style={{ marginTop: '1rem' }}>
                    <div className="col-auto px-0">
                        <span
                            className={
                                styles['goal-summary-card__card__content-text']
                            }
                        >
                            Target:
                        </span>
                    </div>
                    <div className="col-auto px-0">
                        <span
                            className={
                                styles[
                                    'goal-summary-card__card__content-text'
                                ] +
                                ' ' +
                                styles[
                                    'goal-summary-card__card__content-text--bold'
                                ]
                            }
                        >
                            {total}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 w-100" style={{ marginTop: '1rem' }}>
                    <div className="col-auto px-0">
                        <span
                            className={
                                styles['goal-summary-card__card__content-text']
                            }
                        >
                            Reached:
                        </span>
                    </div>
                    <div className="col-auto px-0">
                        <span
                            className={
                                styles[
                                    'goal-summary-card__card__content-text'
                                ] +
                                ' ' +
                                styles[
                                    'goal-summary-card__card__content-text--bold'
                                ]
                            }
                            style={{
                                color:
                                    reached / total === 1
                                        ? COLORS.GREEN
                                        : reached / total > 0.7
                                        ? COLORS.YELLOW
                                        : COLORS.RED
                            }}
                        >
                            {reached}
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-4 px-0">
                <DonutChartWithCircle {...donutInfo} />
            </div>
        </div>
    );
};

const GoalSummaryContent = ({ year, yearOptions, setYear, value }) => {
    const { timeInterval } = useTimeInterval();
    // timeInterval can be month, quarter or semester
    const valueGroupedByTimeInterval = useMemo(() => {
        return value.cards[year].reduce((acc, card) => {
            const timeIntervalValue = card[timeInterval];
            if (!acc[timeIntervalValue]) {
                acc[timeIntervalValue] = {
                    goalsTotal: 0,
                    goalsReached: 0,
                    milestonesTotal: 0,
                    milestonesReached: 0,
                    subGoalsTotal: 0,
                    subGoalsReached: 0
                };
            }
            acc[timeIntervalValue].goalsTotal += card.goalsTotal;
            acc[timeIntervalValue].goalsReached += card.goalsReached;
            acc[timeIntervalValue].milestonesTotal += card.milestonesTotal;
            acc[timeIntervalValue].milestonesReached += card.milestonesReached;
            acc[timeIntervalValue].subGoalsTotal += card.subGoalsTotal;
            acc[timeIntervalValue].subGoalsReached += card.subGoalsReached;
            return acc;
        }, {});
    }, [value.cards, year, timeInterval]);
    return (
        <div className={styles['goal-summary-card']}>
            <div
                className="row mx-0 w-100 d-flex justify-content-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                    <img
                        src={icoChangeYearArrow}
                        alt="icon"
                        className={
                            yearOptions.includes(year - 1)
                                ? styles['goal-summary-card__arrow']
                                : styles['goal-summary-card__arrow'] +
                                  ' ' +
                                  styles['goal-summary-card__arrow--disabled']
                        }
                        onClick={() => {
                            setYear(year - 1);
                        }}
                    />
                    <span className={styles['goal-summary-card__year']}>
                        {year}
                    </span>

                    <img
                        src={icoChangeYearArrow}
                        alt="icon"
                        className={
                            yearOptions.includes(year + 1)
                                ? styles['goal-summary-card__arrow']
                                : styles['goal-summary-card__arrow'] +
                                  ' ' +
                                  styles['goal-summary-card__arrow--disabled']
                        }
                        style={{
                            transform: 'rotate(180deg)'
                        }}
                        onClick={() => {
                            setYear(year + 1);
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100 flex-grow-1">
                <div className="col-12 px-0 d-flex">
                    {Object.entries(valueGroupedByTimeInterval).map(
                        (card, index) => (
                            <div
                                key={index}
                                style={{
                                    width:
                                        parseInt(
                                            (1 /
                                                Object.entries(
                                                    valueGroupedByTimeInterval
                                                ).length) *
                                                100
                                        ) + '%',
                                    minWidth: '30rem',
                                    marginRight:
                                        index <
                                        Object.entries(
                                            valueGroupedByTimeInterval
                                        ).length -
                                            1
                                            ? '2rem'
                                            : '0'
                                }}
                            >
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col px-0">
                                        <div
                                            className={
                                                styles[
                                                    'goal-summary-card__card'
                                                ]
                                            }
                                        >
                                            <div
                                                className="row mx-0 w-100"
                                                style={{
                                                    marginBottom: '2rem'
                                                }}
                                            >
                                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                                    <span
                                                        className={
                                                            styles[
                                                                'goal-summary-card__card__title'
                                                            ]
                                                        }
                                                    >
                                                        {timeInterval ===
                                                        'month'
                                                            ? months[card[0]]
                                                            : timeInterval ===
                                                              'quarter'
                                                            ? `Quarter ${card[0]}`
                                                            : `Semester ${card[0]}`}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* {SummaryRow({
                                                title: 'Goals',
                                                icon: icoGoal,
                                                total: card[1].goalsTotal,
                                                reached: card[1].goalsReached
                                            })} */}
                                            {SummaryRow({
                                                title: 'Milestones',
                                                icon: icoMilestone,
                                                total: card[1].milestonesTotal,
                                                reached:
                                                    card[1].milestonesReached
                                            })}
                                            {SummaryRow({
                                                title: 'Sub-Goals',
                                                icon: icoSubGoal,
                                                total: card[1].subGoalsTotal,
                                                reached: card[1].subGoalsReached
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export const GoalSummaryCard = ({ isPreview, ...rest }) => {
    const { value, setLoading, setValue } =
        useSimpleComponent(goalSummaryCardMock);
    const yearOptions = useMemo(() => {
        return Object.keys(value.cards).map((year) => parseInt(year));
    }, [value.cards]);
    const [year, setYear] = React.useState(2024);
    return (
        <div
            className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <TimeIntervalProvider>
                <ComponentWrapper {...value} {...rest} withGeneralTimeFilter>
                    {value.loading && <Spinner />}
                    {!value.loading && (
                        <GoalSummaryContent
                            year={year}
                            yearOptions={yearOptions}
                            setYear={setYear}
                            value={value}
                        />
                    )}
                </ComponentWrapper>
            </TimeIntervalProvider>
        </div>
    );
};
