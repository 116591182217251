import React from 'react'
import styles from './approval-state-table-column.module.scss'
import icoReject from '../../../../../assets/images/icoReject.svg'
import icoApprove from '../../../../../assets/images/icoApprove.svg'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const PendingApprovalStateTableColumn = ({
    setApproveApprovalModal,
    setRejectApprovalModal,
}) => {
    const { t } = useCustomTranslation()
    return (
        <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
            <div
                className="col-auto px-0 d-flex justify-content-start align-items-center"
                style={{
                    cursor: 'pointer',
                }}
                onClick={
                    setApproveApprovalModal
                        ? () => setApproveApprovalModal(true)
                        : null
                }
            >
                <img
                    src={icoApprove}
                    alt="Approve"
                    className={`${styles['approval-state-table-column-container__icon']}`}
                />
                <span
                    className={`${styles['approval-state-table-column-container__text']}`}
                >
                    {t('common:approve')}
                </span>
            </div>
            <div className="col-auto px-0" style={{ width: '2rem' }}></div>
            <div
                className="col-auto px-0 d-flex justify-content-start align-items-center"
                style={{
                    cursor: 'pointer',
                }}
                onClick={
                    setRejectApprovalModal
                        ? () => setRejectApprovalModal(true)
                        : null
                }
            >
                <img
                    src={icoReject}
                    alt="Reject"
                    className={`${styles['approval-state-table-column-container__icon']}`}
                />
                <span
                    className={`${styles['approval-state-table-column-container__text']}`}
                >
                    {t('common:reject')}
                </span>
            </div>
        </div>
    )
}

export const ApprovalStateTableColumn = ({
    el,
    setApproveApprovalModal,
    setRejectApprovalModal,
}) => {
    const { t } = useCustomTranslation()
    if (el.approvalState === 'pending') {
        return (
            <>
                <PendingApprovalStateTableColumn
                    setApproveApprovalModal={setApproveApprovalModal}
                    setRejectApprovalModal={setRejectApprovalModal}
                />
            </>
        )
    } else if (el.approvalState === 'approved') {
        return (
            <div className="row mx-0 w-100 d-flex justify-content-start">
                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                    <img
                        src={icoApprove}
                        alt="Approve"
                        className={`${styles['approval-state-table-column-container__icon']}`}
                    />
                    <span
                        className={`${styles['approval-state-table-column-container__text']}`}
                        style={{ color: '#8D9092' }}
                    >
                        {t('common:approved')}
                    </span>
                    <span
                        className={`${styles['approval-state-table-column-container__date']}`}
                    >
                        20 Feb 2024
                    </span>
                </div>
            </div>
        )
    } else if (el.approvalState === 'rejected') {
        return (
            <div className="row mx-0 w-100 d-flex justify-content-start">
                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                    <img
                        src={icoReject}
                        alt="Reject"
                        className={`${styles['approval-state-table-column-container__icon']}`}
                    />
                    <span
                        className={`${styles['approval-state-table-column-container__text']}`}
                        style={{ color: '#8D9092' }}
                    >
                        {t('common:rejected')}
                    </span>
                    <span
                        className={`${styles['approval-state-table-column-container__date']}`}
                    >
                        20 Feb 2024
                    </span>
                </div>
            </div>
        )
    }
}
