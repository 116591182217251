import React from 'react'
import { CategoricalSegmentFilterTag } from './components/categorical-segment-filter-tag/categorical-segment-filter-tag'
import { IntervalSegmentFilterTag } from './components/interval-segment-filter-tag/interval-segment-filter-tag'
import { NumericalSegmentFilterTag } from './components/numerical-segment-filter-tag/numerical-segment-filter-tag'

export const SegmentFilterTagFactory = React.memo(({ item }) => {
    if (item.type === 'categorical') {
        return <CategoricalSegmentFilterTag {...item} />
    } else if (item.type === 'interval') {
        return <IntervalSegmentFilterTag {...item} />
    } else if (item.type === 'numerical') {
        return <NumericalSegmentFilterTag {...item} />
    } else return null
})
