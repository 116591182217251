export const revenueSalesForecastComparisonMock = {
    attr: 'revenueSalesForecastComparisonChart',
    isForecast: true,
    categoryAxis: 'month',
    data: [
        {
            revenueRealForecast: 0.03849223684073877,
            time: '2021-04-01',
            day: '01 Abr 2021',
            week: '01 Abr 2021',
            month: 'Abr 2021',
            quarter: 'Q2 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 4804.0064056544525,
            time: '2021-05-01',
            day: '01 Maio 2021',
            week: '01 Maio 2021',
            month: 'Mai 2021',
            quarter: 'Q2 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 0.039501079463207134,
            time: '2021-06-01',
            day: '01 Jun 2021',
            week: '01 Jun 2021',
            month: 'Jun 2021',
            quarter: 'Q2 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 4358.267359198677,
            time: '2021-07-01',
            day: '01 Jul 2021',
            week: '01 Jul 2021',
            month: 'Jul 2021',
            quarter: 'Q3 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 4545.861370604207,
            time: '2021-08-01',
            day: '01 Ago 2021',
            week: '01 Ago 2021',
            month: 'Ago 2021',
            quarter: 'Q3 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 4428.283414119893,
            time: '2021-09-01',
            day: '01 Set 2021',
            week: '01 Set 2021',
            month: 'Set 2021',
            quarter: 'Q3 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 4831.353089407343,
            time: '2021-10-01',
            day: '01 Out 2021',
            week: '01 Out 2021',
            month: 'Out 2021',
            quarter: 'Q4 2021',
            year: '2021',
        },
        {
            revenueRealForecast: 4108.025288315299,
            time: '2021-11-01',
            day: '01 Nov 2021',
            week: '01 Nov 2021',
            month: 'Nov 2021',
            quarter: 'Q4 2021',
            year: '2021',
        },
        {
            revenue: 10080.47,
            revenueForecastValue: 10080.47,
            revenueForecastIntervalValue: [10080.47, 10080.47],
            time: '2021-12-01',
            day: '01 Dez 2021',
            week: '01 Dez 2021',
            month: 'Dez 2021',
            quarter: 'Q4 2021',
            year: '2021',
            revenueRealForecast: 4361.930964868961,
        },
        {
            revenue: 9778.06,
            revenueForecastValue: 9778.06,
            revenueForecastIntervalValue: [9778.06, 9778.06],
            time: '2022-01-01',
            day: '01 Jan 2022',
            week: '01 Jan 2022',
            month: 'Jan 2022',
            quarter: 'Q1 2022',
            year: '2022',
            revenueRealForecast: 5099.130464392414,
        },
        {
            revenue: 9276.27,
            revenueForecastValue: 9276.27,
            revenueForecastIntervalValue: [9276.27, 9276.27],
            time: '2022-02-01',
            day: '01 Fev 2022',
            week: '01 Fev 2022',
            month: 'Fev 2022',
            quarter: 'Q1 2022',
            year: '2022',
            revenueRealForecast: 9276.247208798946,
        },
        {
            revenue: 10191.83,
            revenueForecastValue: 10191.83,
            revenueForecastIntervalValue: [10191.83, 10191.83],
            time: '2022-03-01',
            day: '01 Mar 2022',
            week: '01 Mar 2022',
            month: 'Mar 2022',
            quarter: 'Q1 2022',
            year: '2022',
            revenueRealForecast: 4905.061865955975,
        },
        {
            revenue: 9538.15,
            revenueForecastValue: 9538.15,
            revenueForecastIntervalValue: [9538.15, 9538.15],
            time: '2022-04-01',
            day: '01 Abr 2022',
            week: '01 Abr 2022',
            month: 'Abr 2022',
            quarter: 'Q2 2022',
            year: '2022',
            revenueRealForecast: 4974.81754377287,
        },
        {
            revenue: 10277.72,
            revenueForecastValue: 10277.72,
            revenueForecastIntervalValue: [10277.72, 10277.72],
            time: '2022-05-01',
            day: '01 Maio 2022',
            week: '01 Maio 2022',
            month: 'Mai 2022',
            quarter: 'Q2 2022',
            year: '2022',
            revenueRealForecast: 5743.161590957083,
        },
        {
            revenue: 9859.59,
            revenueForecastValue: 9859.59,
            revenueForecastIntervalValue: [9859.59, 9859.59],
            time: '2022-06-01',
            day: '01 Jun 2022',
            week: '01 Jun 2022',
            month: 'Jun 2022',
            quarter: 'Q2 2022',
            year: '2022',
            revenueRealForecast: 7712.980009794694,
        },
        {
            revenue: 10052.22,
            revenueForecastValue: 10052.22,
            revenueForecastIntervalValue: [10052.22, 10052.22],
            time: '2022-07-01',
            day: '01 Jul 2022',
            week: '01 Jul 2022',
            month: 'Jul 2022',
            quarter: 'Q3 2022',
            year: '2022',
            revenueRealForecast: 5179.584210912484,
        },
        {
            revenue: 10238.55,
            revenueForecastValue: 10238.55,
            revenueForecastIntervalValue: [10238.55, 10238.55],
            time: '2022-08-01',
            day: '01 Ago 2022',
            week: '01 Ago 2022',
            month: 'Ago 2022',
            quarter: 'Q3 2022',
            year: '2022',
            revenueRealForecast: 4945.101373248253,
        },
        {
            revenue: 10094.55,
            revenueForecastValue: 10094.55,
            revenueForecastIntervalValue: [10094.55, 10094.55],
            time: '2022-09-01',
            day: '01 Set 2022',
            week: '01 Set 2022',
            month: 'Set 2022',
            quarter: 'Q3 2022',
            year: '2022',
            revenueRealForecast: 5319.095566546088,
        },
        {
            revenue: 10361.45,
            revenueForecastValue: 10361.45,
            revenueForecastIntervalValue: [10361.45, 10361.45],
            time: '2022-10-01',
            day: '01 Out 2022',
            week: '01 Out 2022',
            month: 'Out 2022',
            quarter: 'Q4 2022',
            year: '2022',
            revenueRealForecast: 5652.66994112115,
        },
        {
            revenue: 10158.91,
            revenueForecastValue: 10158.91,
            revenueForecastIntervalValue: [10158.91, 10158.91],
            time: '2022-11-01',
            day: '01 Nov 2022',
            week: '01 Nov 2022',
            month: 'Nov 2022',
            quarter: 'Q4 2022',
            year: '2022',
            revenueRealForecast: 4929.342140029106,
        },
        {
            revenue: 10448.17,
            revenueForecastValue: 10448.17,
            revenueForecastIntervalValue: [10448.17, 10448.17],
            time: '2022-12-01',
            day: '01 Dez 2022',
            week: '01 Dez 2022',
            month: 'Dez 2022',
            quarter: 'Q4 2022',
            year: '2022',
            revenueRealForecast: 5183.247816582771,
        },
        {
            revenue: 10413.3,
            revenueForecastValue: 10413.3,
            revenueForecastIntervalValue: [10413.3, 10413.3],
            time: '2023-01-01',
            day: '01 Jan 2023',
            week: '01 Jan 2023',
            month: 'Jan 2023',
            quarter: 'Q1 2023',
            year: '2023',
            revenueRealForecast: 6261.061733209153,
        },
        {
            revenue: 9422.95,
            revenueForecastValue: 9422.95,
            revenueForecastIntervalValue: [9422.95, 9422.95],
            time: '2023-02-01',
            day: '01 Fev 2023',
            week: '01 Fev 2023',
            month: 'Fev 2023',
            quarter: 'Q1 2023',
            year: '2023',
            revenueRealForecast: 5663.373589319117,
        },
        {
            revenue: 10582.94,
            revenueForecastValue: 10582.94,
            revenueForecastIntervalValue: [10582.94, 10582.94],
            time: '2023-03-01',
            day: '01 Mar 2023',
            week: '01 Mar 2023',
            month: 'Mar 2023',
            quarter: 'Q1 2023',
            year: '2023',
            revenueRealForecast: 5726.378717669782,
        },
        {
            revenue: 9792.48,
            revenueForecastValue: 9792.48,
            revenueForecastIntervalValue: [9792.48, 9792.48],
            time: '2023-04-01',
            day: '01 Abr 2023',
            week: '01 Abr 2023',
            month: 'Abr 2023',
            quarter: 'Q2 2023',
            year: '2023',
            revenueRealForecast: 5913.9727290755,
        },
        {
            revenue: 10812.7,
            revenueForecastValue: 10812.7,
            revenueForecastIntervalValue: [10812.7, 10812.7],
            time: '2023-05-01',
            day: '01 Maio 2023',
            week: '01 Maio 2023',
            month: 'Mai 2023',
            quarter: 'Q2 2023',
            year: '2023',
            revenueRealForecast: 5994.171047276248,
        },
        {
            revenue: 10340.74,
            revenueForecastValue: 10340.74,
            revenueForecastIntervalValue: [10340.74, 10340.74],
            time: '2023-06-01',
            day: '01 Jun 2023',
            week: '01 Jun 2023',
            month: 'Jun 2023',
            quarter: 'Q2 2023',
            year: '2023',
            revenueRealForecast: 5933.395567964744,
        },
        {
            revenue: 10693.34,
            revenueForecastValue: 10693.34,
            revenueForecastIntervalValue: [10693.34, 10693.34],
            time: '2023-07-01',
            day: '01 Jul 2023',
            week: '01 Jul 2023',
            month: 'Jul 2023',
            quarter: 'Q3 2023',
            year: '2023',
            revenueRealForecast: 5931.405761913906,
        },
        {
            revenue: 10460.68,
            revenueForecastValue: 10460.68,
            revenueForecastIntervalValue: [10460.68, 10460.68],
            time: '2023-08-01',
            day: '01 Ago 2023',
            week: '01 Ago 2023',
            month: 'Ago 2023',
            quarter: 'Q3 2023',
            year: '2023',
            revenueRealForecast: 6070.656740443001,
        },
        {
            revenue: 10071.67,
            revenueForecastValue: 10071.67,
            revenueForecastIntervalValue: [10071.67, 10071.67],
            time: '2023-09-01',
            day: '01 Set 2023',
            week: '01 Set 2023',
            month: 'Set 2023',
            quarter: 'Q3 2023',
            year: '2023',
            revenueRealForecast: 6140.412418259895,
        },
        {
            revenue: 10273.69,
            revenueForecastValue: 10273.69,
            revenueForecastIntervalValue: [10273.69, 10273.69],
            time: '2023-10-01',
            day: '01 Out 2023',
            week: '01 Out 2023',
            month: 'Out 2023',
            quarter: 'Q4 2023',
            year: '2023',
            revenueRealForecast: 6404.491492122572,
        },
        {
            revenue: 10177.88,
            revenueForecastValue: 10177.88,
            revenueForecastIntervalValue: [10177.88, 10177.88],
            time: '2023-11-01',
            day: '01 Nov 2023',
            week: '01 Nov 2023',
            month: 'Nov 2023',
            quarter: 'Q4 2023',
            year: '2023',
            revenueRealForecast: 5750.658991742914,
        },
        {
            revenueForecastValue: 6629.909523117141,
            revenueForecastIntervalValue: [1044.5322252980895, 12215.286820936193],
            time: '2024-01-01',
            day: '01 Jan 2024',
            week: '01 Jan 2024',
            month: 'Jan 2024',
            quarter: 'Q1 2024',
            year: '2024',
        },
        {
            revenueForecastValue: 6484.499851749279,
            revenueForecastIntervalValue: [899.1225539302268, 12069.87714956833],
            time: '2024-02-01',
            day: '01 Fev 2024',
            week: '01 Fev 2024',
            month: 'Fev 2024',
            quarter: 'Q1 2024',
            year: '2024',
        },
        {
            revenueForecastValue: 6549.576870054636,
            revenueForecastIntervalValue: [964.1995722355841, 12134.954167873688],
            time: '2024-03-01',
            day: '01 Mar 2024',
            week: '01 Mar 2024',
            month: 'Mar 2024',
            quarter: 'Q1 2024',
            year: '2024',
        },
        {
            revenueForecastValue: 5974.289026003826,
            revenueForecastIntervalValue: [388.9117281847739, 11559.666323822878],
            time: '2024-04-01',
            day: '01 Abr 2024',
            week: '01 Abr 2024',
            month: 'Abr 2024',
            quarter: 'Q2 2024',
            year: '2024',
        },
    ],
    tooltipType: 'salesForecast-monthly',
    unitsOrValues: 'values',
    areaAttr: [
        {
            dataKey: 'revenueForecastIntervalValue',
            color: '#2cd9c5',
            dashArray: '3 3',
            strokeWidth: 2,
            hideDot: true,
            forecastGradient: true,
            fillOpacity: 0.9,
            lineType: 'monotone',
        },
        {
            dataKey: 'revenueForecastValue',
            color: '#2cd9c5',
            isReverseColor: true,
            strokeWidth: 2,
            lineType: 'monotone',
        },
        {
            dataKey: 'revenue',
            color: '#2cd9c5',
            strokeWidth: 2,
            lineType: 'monotone',
        },
    ],

    barAttr: [
        {
            dataKey: 'revenueUserForecast',
            color: '#EE7979',
            isForecast: true,
        },
        {
            dataKey: 'revenueRealForecast',
            color: '#AAEFE7',
            isForecast: true,
        },
    ],
    legend: [
        {
            name: 'revenue',
            color: '#2cd9c5',
        },
        // {
        //     name: 'userForecast',
        //     isForecast: true,
        //     color: '#EE7979',
        // },
        {
            name: 'realForecast',
            isForecast: true,
            color: '#AAEFE7',
        },
    ],
}
