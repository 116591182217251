import React, { useMemo } from 'react';
import milestoneMarker from '../../../assets/images/milestoneMarker.svg';

export const MilestoneMarker = ({ isSmall, isSmaller, status }) => {
    const size = useMemo(() => {
        if (isSmaller) {
            return '3rem';
        } else if (isSmall) {
            return '5rem';
        }
        return undefined;
    }, [isSmaller, isSmall]);

    const cursor = useMemo(() => {
        if (isSmaller) {
            return 'default';
        }
        return 'grab';
    }, [isSmaller]);
    return (
        <img
            src={milestoneMarker}
            alt="Milestone Marker"
            style={{
                height: size,
                cursor: cursor,
                filter:
                    status === 'completed'
                        ? 'brightness(0) saturate(100%) invert(99%) sepia(53%) saturate(744%) hue-rotate(86deg) brightness(88%) contrast(84%)'
                        : 'none'
            }}
        />
    );
};
