import React from 'react'

export const productSalesByRegionMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    titleText: '',
    legend: undefined,
    type: 'Map',
    barStackedAttr: [
        {
            dataKey: 'regionSales',
            gradientColors: ['41824b', '8be297'],
            stackId: 'a',
            minPointSize: 70,
            radius: [0, 100, 100, 0],
        },
    ],
    barWidth: 20,
    categoryAxis: 'regionName',
    data: [
        {
            regionName: "Cut Russet Potatoes Steam N' Mash",

            regionSales: 14658,
        },
        {
            regionName: 'Overnight Diapers Size 6',

            regionSales: 14046,
        },
        {
            regionName: 'Saline Nasal Mist',

            regionSales: 13275,
        },
        {
            regionName: 'Green Chile Anytime Sauce',

            regionSales: 12805,
        },
        {
            regionName: 'Sparkling Orange Juice & Prickly Pear Beverage',

            regionSales: 12333,
        },
        {
            regionName: 'Pure Coconut Water With Orange',

            regionSales: 12149,
        },
        {
            regionName: 'Chocolate Fudge Layer Cake',

            regionSales: 12035,
        },
        {
            regionName: 'Fresh Scent Dishwasher Cleaner',

            regionSales: 11906,
        },
        {
            regionName:
                'Smart Ones Classic Favorites Mini Rigatoni With Vodka Cream Sauce',

            regionSales: 11817,
        },
        {
            regionName: 'Peach Mango Juice',

            regionSales: 11698,
        },
    ],
    orientation: 'vertical',
    tooltipType: 'productRegionSales',
    unitsOrValues: 'values',
    loading: true,
    categoryLabel: '',
    margin: {
        top: 5,
        left: 0,
        right: 20,
        bottom: 5,
    },
    attr: 'productSalesByRegionChart',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
