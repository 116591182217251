import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useCursorTrackingProvider } from '../../../../../providers/cursor-tracking-context'

export const RegionGeoMarketShareTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    const { position } = useCursorTrackingProvider()
    return (
        <div
            style={{
                transition: 'all 0.3s ease-in-out',
                position: 'fixed',
                left: position.x,
                top: position.y,
            }}
        >
            <TooltipContainer color={props.item.hoveredPiece.color}>
                <TooltipHeaderRow title={props.item.hoveredPiece.name} />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.market-share')}
                    value={parseFloat(props.item.hoveredPiece.market_share.toFixed(2) || 0) + '%'}
                />
            </TooltipContainer>
        </div>
    )
})
