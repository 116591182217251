import React from 'react'

export const jobListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'id', label: '', sortable: true },
        { path: 'userId', label: '', sortable: true },
        { path: 'name', label: '', sortable: true },
        { path: 'description', label: '', sortable: true },
        { path: 'completed', label: '', sortable: true },
        { path: 'success', label: '', sortable: true },
        { path: 'timeLeft', label: '', sortable: true },
    ],
    sortColumn: { path: '', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'jobTable',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
        reference: React.createRef(),
    },
}
