import React from 'react'
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.css'
import styles from './chart-options.module.scss'

export const ChartOptions = React.memo(
    ({
        chartOptions,
        selectedChartOption,
        onClickChartOption,
        disabled,
        outsideChart,
    }) => {
        return (
            <div
                className={
                    disabled && outsideChart
                        ? `col-auto mx-3 p-1 ${styles['chart-options-container']} ${styles['chart-options-container--disabled']}`
                        : disabled
                        ? `col-auto mx-3 p-1 ${styles['chart-options-container']} ${styles['chart-options-container--disabled']}`
                        : outsideChart
                        ? `col-auto p-1 ${styles['chart-options-container']}`
                        : `col-auto mx-3 p-1 ${styles['chart-options-container']}`
                }
            >
                <div className="row w-100 m-0">
                    {chartOptions.map((option, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    selectedChartOption === option.name
                                        ? `col ${styles['chart-options-container__option']} ${styles['selected']}`
                                        : `col ${styles['chart-options-container__option']}`
                                }
                                onClick={() => onClickChartOption(option.name)}
                            >
                                <span>{option.label}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
)

ChartOptions.propTypes = {
    /**
     * String to find attribute in state where the value is located
     */
    attr: PropTypes.string,
    /**
     * Boolean to disable component
     */
    disabled: PropTypes.bool,
    /**
     * Boolean to decide if options are outside of a chart or not
     */
    outsideChart: PropTypes.bool,
    /**
     * Array of objects
     * Each object contains the following attributes:
     * name: String to differenciate attribute
     * label: String to display attribute label
     */
    chartOptions: PropTypes.array.isRequired,
    /**
     * String to choose which chart option is selected
     */
    selectedChartOption: PropTypes.string.isRequired,
    /**
     * Function to handle click on chart option
     * */
    onClickChartOption: PropTypes.func.isRequired,
}
