import React, { useState } from 'react'
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button'
import { Modal, PrimaryButton, TypeInput } from '../..'
import { SuccessModal } from '../success-modal/success-modal'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { errorHandler } from '../../../utils/api'
import { useTextInput } from '../../../hooks/useTextInput'
import { DateInputNew } from '../../inputs/date-input-new/date-input-new'
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate'

export const AddNewForecastModal = ({ inputs, title, successMessage, onAdd, onCloseModal, ...rest }) => {
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const { t } = useCustomTranslation()

    const { value: newForecastDate, replaceValue: newForecastDateSetValue } = useTextInput({
        name: 'newForecastDate',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const { setValue: newForecastValueSetValue, ...newForecastValue } = useTextInputAlternate({
        name: 'newForecastValue',
        isNumber: true,
    })

    const handleAddElement = async () => {
        try {
            setLoading(true)
            await onAdd()
            setLoading(false)
            setIsSuccess(true)
        } catch (err) {
            errorHandler(err)
        }
    }

    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">{t('modals:add-new-forecast-modal.title')}</span>
                    </div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{
                        marginBottom: '2rem',
                    }}
                >
                    <div className="col px-0">
                        <DateInputNew {...newForecastDate} onChangeInput={newForecastDateSetValue} />
                    </div>
                </div>
                <div className="row mx-0 w-100">
                    <div
                        className="col px-0"
                        style={{
                            marginBottom: '2rem',
                        }}
                    >
                        <TypeInput {...newForecastValue} onChangeInput={newForecastValueSetValue} />
                    </div>
                </div>

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1',
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton text={t('modals:cancel')} onClick={onCloseModal} />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton text={t('modals:yes')} onClick={handleAddElement} />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return <SuccessModal text={successMessage} onCloseModal={onCloseModal} />
    }
}
