import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { SalesService, ForecastService, GoalsService } from '../../../temp/test'
import { unitSalesForecastComparisonMock } from './mock'
import { errorHandler } from '../../../utils/api.js'
import { useFilter } from '../../../providers/filter-context'
import { getFilterParam } from '../../../utils/params'
import { ComponentWrapper, DynamicComposedChartContainer } from '../..'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { fromBackTimeToFront } from '../../../utils/timeseries'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { useSalesChannel } from '../../../providers/sales-channel-context'
import { TimeSeriesManagerProvider } from '../../../providers/time-series-manager-context'
import { useUserForecastFilter } from '../../../providers/user-forecast-filter-context'

/**
 * Displays the evolution of unit sales over time with forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer)

export const UnitSalesForecastComparison = React.memo(({ component, isPreview, isExample, config, ...rest }) => {
    const { filter } = useFilter()
    const isMounted = useIsMounted()
    const { timeInterval } = useTimeInterval()
    const { userForecastEnabled } = useUserForecastFilter()
    const { channels } = useSalesChannel()
    const { t } = useCustomTranslation()
    const [userForecastData, setUserForecastData] = useState([])
    const [userForecastXAxis] = useState('date')
    const [userForecastYAxis] = useState('forecast')
    const [noBarData, setNoBarData] = useState(false)

    const { value: data, setLoading, setValue } = useSimpleComponent(unitSalesForecastComparisonMock)

    const isProductMonthlyUserForecast = useMemo(() => {
        return (config?.filter?.includes('productId') || config?.filter?.includes('productIds')) && timeInterval === 'month'
    }, [timeInterval, config])

    const isGlobalYearlyUserForecast = useMemo(() => {
        return timeInterval === 'year' && !config?.filter?.includes('productId') && !config?.filter?.includes('productIds')
    }, [timeInterval, config])

    const getUserForecastData = useCallback(async () => {
        try {
            let userForecastBackend = []
            if (isProductMonthlyUserForecast) {
                userForecastBackend = await GoalsService.getApiUserForecastProductMonth({
                    params: {
                        product_id: getFilterParam(filter, config, ['productId', 'productIds']),
                        sales_aggregation: 'units',
                    },
                })
                userForecastBackend = userForecastBackend.map((el) => {
                    const monthWithZero = el.month < 10 ? `0${el.month}` : el.month
                    return {
                        id: el.id,
                        date: `${el.year}-${monthWithZero}-01T00:00:00.000Z`,
                        forecast: el.forecast,
                    }
                })
            } else if (isGlobalYearlyUserForecast) {
                userForecastBackend = await GoalsService.getApiUserForecastGlobalYear({
                    params: {
                        sales_aggregation: 'units',
                    },
                })
                userForecastBackend = userForecastBackend.map((el) => {
                    return {
                        id: el.id,
                        date: `${el.year}-01-01T00:00:00.000Z`,
                        forecast: el.forecast,
                    }
                })
            }
            return userForecastBackend
        } catch (err) {
            errorHandler(err)
        }
    }, [config, filter, isProductMonthlyUserForecast, isGlobalYearlyUserForecast])

    const getData = useCallback(async () => {
        const dataClone = _.cloneDeep(unitSalesForecastComparisonMock)
        try {
            if (!isExample) {
                setLoading(true)
                dataClone.data = []
                dataClone.barAttr = []
                dataClone.legend = []
                let userForecastBackend = []
                if (userForecastEnabled) {
                    userForecastBackend = await getUserForecastData()
                    setUserForecastData(userForecastBackend)
                }
                const historicalForecastSales = await ForecastService.getApiForecastHistorical({
                    interval: timeInterval,
                    productIds: getFilterParam(filter, config, ['productId', 'productIds']),
                    teamIds: getFilterParam(filter, config, ['teamId', 'teamIds']),
                    regionIds: getFilterParam(filter, config, ['regionId', 'regionIds']),
                    channelIds: JSON.parse(channels),
                    sumBy: 'units',
                })
                const forecastSales = await ForecastService.getApiForecast({
                    interval: timeInterval,
                    productIds: getFilterParam(filter, config, ['productId', 'productIds']),
                    teamIds: getFilterParam(filter, config, ['teamId', 'teamIds']),
                    regionIds: getFilterParam(filter, config, ['regionId', 'regionIds']),
                    channelIds: JSON.parse(channels),
                    sumBy: 'units',
                })
                if (forecastSales.length === 0 && historicalForecastSales.length === 0) {
                    setNoBarData(true)
                }
                const dataSales = await SalesService.getApiSales({
                    producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                    interval: timeInterval,
                    clientId: getFilterParam(filter, config, ['clientId', 'clientIds']),
                    vendorId: getFilterParam(filter, config, ['vendorId', 'vendorIds']),
                    locationId: [],
                    productId: getFilterParam(filter, config, ['productId', 'productIds']),
                    teamId: getFilterParam(filter, config, ['teamId', 'teamIds']),
                    regionId: getFilterParam(filter, config, ['regionId', 'regionIds']),
                    sumBy: 'units',
                    channelId: JSON.parse(channels),
                })
                // get the max date value from dataSales and filter the historicalForecastSales until that date
                const maxDate = Object.keys(dataSales).reduce((a, b) => (a > b ? a : b))
                const historicalForecastSalesFiltered = historicalForecastSales.filter((el) => el.forecast_date <= maxDate)
                dataClone.categoryAxis = timeInterval

                if (userForecastEnabled) {
                    fromBackTimeToFront(
                        userForecastBackend,
                        dataClone.data,
                        [
                            {
                                back: 'forecast',
                                front: 'unitsSoldUserForecast',
                            },
                        ],
                        timeInterval,
                        'date'
                    )
                }
                fromBackTimeToFront(
                    historicalForecastSalesFiltered,
                    dataClone.data,
                    [
                        {
                            back: 'forecast',
                            front: 'unitsSoldRealForecast',
                        },
                    ],
                    timeInterval,
                    'forecast_date'
                )
                fromBackTimeToFront(Object.entries(dataSales), dataClone.data, [{ back: 1, front: 'unitsSold' }], timeInterval, '0')

                fromBackTimeToFront(
                    [Object.entries(dataSales)[Object.entries(dataSales).length - 1]],
                    dataClone.data,
                    [
                        {
                            back: [1, 1],
                            front: 'unitsSoldForecastIntervalValue',
                            isArray: true,
                        },
                        {
                            back: 1,
                            front: 'unitsSoldForecastValue',
                        },
                        {
                            back: 1,
                            front: 'forecastGradient',
                        },
                    ],
                    timeInterval,
                    '0'
                )
                forecastSales.map((item) => {
                    item.forecast_lower = item.forecast_lower + (item.forecast - item.forecast_lower) * 0.2
                    item.forecast_upper = item.forecast_upper - (item.forecast_upper - item.forecast) * 0.2
                })
                fromBackTimeToFront(
                    forecastSales,
                    dataClone.data,
                    [
                        {
                            back: 'forecast_lower',
                            front: 'forecastGradient',
                        },
                        {
                            back: 'forecast',
                            front: 'unitsSoldForecastValue',
                        },
                        {
                            back: ['forecast_lower', 'forecast_upper'],
                            front: 'unitsSoldForecastIntervalValue',
                            isArray: true,
                        },
                    ],
                    timeInterval,
                    'forecast_date'
                )
                dataClone.barAttr.push({
                    dataKey: 'unitsSoldUserForecast',
                    color: '#EE7979',
                    isForecast: true,
                })
                dataClone.barAttr.push({
                    dataKey: 'unitsSoldRealForecast',
                    color: '#B876FB',
                    isForecast: true,
                })
                dataClone.legend.push({
                    name: 'unitsSold',
                    color: '#8c54ff',
                })

                if (userForecastBackend.length > 0) {
                    dataClone.legend.push({
                        name: 'userForecast',
                        isForecast: true,
                        color: '#EE7979',
                    })
                }
                if (isProductMonthlyUserForecast || isGlobalYearlyUserForecast) {
                    dataClone.legend.push({
                        name: 'realForecast',
                        isForecast: true,
                        color: '#B876FB',
                    })
                }
                dataClone.loading = false
                setValue(dataClone)
            } else {
                setValue(dataClone)
                setLoading(false)
            }
        } catch (err) {
            errorHandler(err)
            dataClone.loading = false
            if (isMounted.current) {
                setValue(dataClone)
            }
        }
    }, [
        userForecastEnabled,
        getUserForecastData,
        config,
        filter,
        isExample,
        isMounted,
        setLoading,
        setValue,
        timeInterval,
        channels,
        isProductMonthlyUserForecast,
        isGlobalYearlyUserForecast,
    ])

    useEffect(() => {
        getData()
    }, [getData])

    const handleAdd = useCallback(
        async (...args) => {
            if (isProductMonthlyUserForecast) {
                try {
                    const body = {
                        forecast: args[0].forecast.value,
                        month: args[0].date.value.split(' ')[0],
                        product_id: getFilterParam(filter, config, ['productId', 'productIds'])[0],
                        year: args[0].date.value.split(' ')[1],
                        sales_aggregation: 'units',
                    }
                    await GoalsService.postApiUserForecastProductMonth({
                        requestBody: body,
                    })
                    await getData()
                } catch (err) {
                    errorHandler(err)
                }
            } else if (isGlobalYearlyUserForecast) {
                try {
                    const body = {
                        forecast: args[0].forecast.value,
                        year: args[0].date.value,
                        sales_aggregation: 'units',
                    }
                    await GoalsService.postApiUserForecastGlobalYear({
                        requestBody: body,
                    })
                    await getData()
                } catch (err) {
                    errorHandler(err)
                }
            }
        },
        [config, filter, isProductMonthlyUserForecast, isGlobalYearlyUserForecast, getData]
    )

    const handleRemove = useCallback(
        async (id) => {
            try {
                if (isProductMonthlyUserForecast) {
                    await GoalsService.deleteApiUserForecastProductMonth({
                        id: id,
                    })
                } else if (isGlobalYearlyUserForecast) {
                    await GoalsService.deleteApiUserForecastGlobalYear({
                        id: id,
                    })
                }
                await getData()
            } catch (err) {
                errorHandler(err)
            }
        },
        [isProductMonthlyUserForecast, isGlobalYearlyUserForecast, getData]
    )

    const userForecastsValue = useMemo(() => {
        return {
            data: userForecastData,
            xAxis: userForecastXAxis,
            xAxisFormat: isProductMonthlyUserForecast ? 'month' : isGlobalYearlyUserForecast ? 'year' : 'day',
            yAxis: userForecastYAxis,
            onAdd: handleAdd,
            onRemove: handleRemove,
        }
    }, [isGlobalYearlyUserForecast, isProductMonthlyUserForecast, handleAdd, handleRemove, userForecastData, userForecastXAxis, userForecastYAxis])

    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <TimeSeriesManagerProvider value={userForecastsValue}>
                <ComponentWrapper
                    withTimeFilter
                    withChannelFilter
                    withForecastFilter
                    isForecast
                    withUserForecastInput={isProductMonthlyUserForecast || isGlobalYearlyUserForecast}
                    component={component}
                    {...data}
                    {...rest}
                >
                    <Chart
                        {...data}
                        forecastValue="unitsSoldForecastValue"
                        forecastIntervalValue="unitsSoldForecastIntervalValue"
                        startsAtZero={!noBarData}
                    />
                </ComponentWrapper>
            </TimeSeriesManagerProvider>
        </div>
    )
})

UnitSalesForecastComparison.propTypes = {}
