import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { getClientColorByRisk } from '../../../utils/color';
import { verifyComponent } from '../../../utils/component';
import { useGeo } from '../../../hooks/useGeo';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useFilter } from '../../../providers/filter-context';
import { SegmentationService } from '../../../temp/test';
import { segmentGeoMock } from './mock';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { MapContainerNew } from '../../charts/map-container-new/map-container-new';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

export const SegmentGeo = React.memo(({ segmentId }) => {
    const { filter } = useFilter();
    const isMounted = useIsMounted();
    const { i18n } = useTranslation();
    const { t } = useCustomTranslation();

    const {
        value: data,
        toggleComponentSettings,
        mouseOverMap,
        clickMap,
        setLoading,
        setValue
    } = useGeo(segmentGeoMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(segmentGeoMock);
            try {
                if (isMounted.current) {
                    setLoading(true);
                }
                const results =
                    await SegmentationService.getApiSegmentationClients({
                        id: segmentId
                    });
                let avgLat = 0;
                let avgLon = 0;
                let count = 0;
                let riskColors = ['#6fdb7d', '#dbdb6f', '#ffbd77', '#ff6363'];
                dataClone.legend = [
                    {
                        name: 'risk1',
                        color: '#6fdb7d',
                        translated: false
                    },
                    {
                        name: 'risk2',
                        color: '#dbdb6f',
                        translated: false
                    },
                    {
                        name: 'risk3',
                        color: '#ffbd77',
                        translated: false
                    },
                    {
                        name: 'risk4',
                        color: '#ff6363',
                        translated: false
                    }
                ];
                dataClone.dataList = [
                    {
                        id: `data-${segmentId}-risk-1`,
                        data: {
                            type: 'FeatureCollection',
                            features: []
                        },
                        styleSmall: {
                            id: `data-${segmentId}-risk-1-small`,
                            type: 'circle',
                            innerRadius: 0.6,
                            paint: {
                                'circle-radius': 5,
                                'circle-color': '#6fdb7d',
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `data-${segmentId}-risk-1-large`,
                            type: 'circle',
                            outerRadius: 1,
                            paint: {
                                'circle-radius': 15,
                                'circle-color': '#6fdb7d',
                                'circle-opacity': 0.3
                            }
                        }
                    },
                    {
                        id: `data-${segmentId}-risk-2`,
                        data: {
                            type: 'FeatureCollection',
                            features: []
                        },
                        styleSmall: {
                            id: `data-${segmentId}-risk-2-small`,
                            type: 'circle',
                            innerRadius: 0.6,
                            paint: {
                                'circle-radius': 5,
                                'circle-color': '#dbdb6f',
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `data-${segmentId}-risk-2-large`,
                            type: 'circle',
                            outerRadius: 1,
                            paint: {
                                'circle-radius': 15,
                                'circle-color': '#dbdb6f',
                                'circle-opacity': 0.3
                            }
                        }
                    },
                    {
                        id: `data-${segmentId}-risk-3`,
                        data: {
                            type: 'FeatureCollection',
                            features: []
                        },
                        styleSmall: {
                            id: `data-${segmentId}-risk-3-small`,
                            type: 'circle',
                            innerRadius: 0.6,
                            paint: {
                                'circle-radius': 5,
                                'circle-color': '#ffbd77',
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `data-${segmentId}-risk-3-large`,
                            type: 'circle',
                            outerRadius: 1,
                            paint: {
                                'circle-radius': 15,
                                'circle-color': '#ffbd77',
                                'circle-opacity': 0.3
                            }
                        }
                    },
                    {
                        id: `data-${segmentId}-risk-4`,
                        data: {
                            type: 'FeatureCollection',
                            features: []
                        },
                        styleSmall: {
                            id: `data-${segmentId}-risk-4-small`,
                            type: 'circle',
                            innerRadius: 0.6,
                            paint: {
                                'circle-radius': 5,
                                'circle-color': '#ff6363',
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `data-${segmentId}-risk-4-large`,
                            type: 'circle',
                            outerRadius: 1,
                            paint: {
                                'circle-radius': 15,
                                'circle-color': '#ff6363',
                                'circle-opacity': 0.3
                            }
                        }
                    }
                ];
                results.map((el) => {
                    if (el.lon && el.lat) {
                        let initialDefaultStatus = 'inactiveClients';
                        let initialDefaultStatusValue = 'Inactive';
                        let initialDefaultStatusColor = '#a28c6a';
                        let innerColor = getClientColorByRisk(
                            riskColors,
                            el.risk
                        );
                        if (el.lost) {
                            initialDefaultStatus = 'lostClients';
                            initialDefaultStatusValue = 'Cliente Perdido';
                            initialDefaultStatusColor = '#606060';
                            innerColor = '#606060';
                        } else if (el.active) {
                            initialDefaultStatus = 'activeClients';
                            initialDefaultStatusValue = 'Cliente Ativo';
                            initialDefaultStatusColor = '#ffb200';
                        }
                        const newEl = {
                            lat: el.lat,
                            lon: el.lon,
                            regionId: el.client_id.toString(),
                            defaultStatus: initialDefaultStatus,
                            defaultStatusValue: initialDefaultStatusValue,
                            defaultStatusColor: initialDefaultStatusColor,
                            riskStatus: 'risk' + el.risk,
                            riskStatusValue: el.risk,
                            riskStatusColor: getClientColorByRisk(
                                riskColors,
                                el.risk
                            ),
                            innerColor: innerColor,
                            ...el
                        };
                        dataClone.dataList[el.risk - 1]['data'][
                            'features'
                        ].push({
                            type: 'Feature',
                            properties: {
                                ...newEl
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [el.lon, el.lat]
                            }
                        });
                        avgLat += el.lat;
                        avgLon += el.lon;
                        count++;
                    }
                    return el;
                });
                if (count) {
                    dataClone.startingPosition.lat = avgLat / count;
                    dataClone.startingPosition.lon = avgLon / count;
                }
                if (
                    dataClone.settings.view.options.length === 0 &&
                    dataClone.settings.options.options.length === 0
                )
                    dataClone.disabled = true;
                else dataClone.disabled = false;
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [filter, isMounted, setLoading, setValue, t, segmentId]);

    const handleSelectSettingsOption = useCallback(() => {
        if (isMounted.current) toggleComponentSettings();
    }, [isMounted, toggleComponentSettings]);

    return (
        <ContentContainer
            attr={data.attr}
            titleText={t(`components:${data.attr}.title`)}
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
            onClickSettingsView={handleSelectSettingsOption}
            onCLickSettintsSelected={handleSelectSettingsOption}
            onCloseSettings={toggleComponentSettings}
            // // hovered={hovered}
            // onMouseEnterInfoIcon={onMouseEnterInfoIcon}
            // onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
            // hideInfoIcon={true}
            // hidePinIcon={isExample || isPreview || isReportExample}
            // hideSettingsIcon={isExample || isPreview || isReportExample}
            // pinned={pinned}
            // onPinComponent={onPinComponent}
            // onUnpinComponent={onUnpinComponent}
            // interval={'1M'}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <MapContainerNew
                    translate={t}
                    risk1Data={data.risk1Data}
                    risk2Data={data.risk2Data}
                    risk3Data={data.risk3Data}
                    risk4Data={data.risk4Data}
                    dataList={data.dataList}
                    competitionData={data.competitionData}
                    selectedData={data.selectedData}
                    startingPosition={data.startingPosition}
                    tooltipType={data.tooltipType}
                    interactionDisabled={data.interactionDisabled}
                    onMouseOverMap={mouseOverMap}
                    onMouseClickMap={clickMap}
                    selectedEl={data.selectedEl}
                    showPopup={data.showPopup}
                    scale={data.scale}
                    minScale={data.minScale}
                    minZoom={data.minZoom}
                    flyEnabled={data.flyEnabled}
                />
            )}
        </ContentContainer>
    );
});
