import { useCallback, useReducer, useEffect } from 'react';
import _ from 'lodash';

const ACTIONS = {
  SET_VALUE: 'setValue',
  SET_DASHBOARD_TITLE: 'setDashboardTitle',
  SET_IS_HOME: 'setIsHome',
  SET_FILTER_INPUTS: 'setFilterInputs',
  SET_AVAILABLE_OPTIONS: 'setAvailableOptions',
  SET_SELECTED_AVAILABLE_OPTION: 'setSelectedAvailableOption',
  SET_AVAIlABLE_CHARTS: 'setAvailableCharts',
  SET_NOT_AVAIlABLE_CHARTS: 'setNotAvailableCharts',
  SET_NOT_AVAILABLE_CHART_VALUE: 'setNotAvailableChartValue',
  SET_NOT_AVAILABLE_CHART_SIZE: 'setNotAvailableChartSize',
  SET_LAYOUT: 'setLayout',
  SET_RESIZABLE_GRID: 'setResizableGrid',
  FIX_LAYOUT: 'fixLayout',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_LAYOUT:
      return {
        ...state,
        resizableGrid: {
          ...state.resizableGrid,
          layout: action.payload,
        },
      };
    case ACTIONS.SET_RESIZABLE_GRID:
      return {
        ...state,
        resizableGrid: {
          ...action.payload,
        },
      };
    case ACTIONS.FIX_LAYOUT:
      break;
    case ACTIONS.SET_VALUE:
      return {
        ...action.payload,
      };

    case ACTIONS.SET_DASHBOARD_TITLE:
      return {
        ...state,
        dashboardTitle: action.payload,
      };
    case ACTIONS.SET_AVAIlABLE_CHARTS:
      return {
        ...state,
        availableCharts: [...action.payload],
      };
    case ACTIONS.SET_NOT_AVAILABLE_CHART_SIZE: {
      let index = _.findIndex(state.notAvailableCharts, (el) => {
        return el.dataGrid.i === action.payload.identifier;
      });
      if (index !== -1) {
        state.notAvailableCharts[index]['dataGrid'].w = action.payload.width;
        state.notAvailableCharts[index]['dataGrid'].h = action.payload.height;
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    }
    case ACTIONS.SET_NOT_AVAILABLE_CHART_VALUE: {
      let index = _.findIndex(state.notAvailableCharts, (el) => {
        return el.dataGrid.i === action.payload.identifier;
      });
      if (index !== -1) {
        state.notAvailableCharts[index]['content'] = action.payload.value;
        return {
          ...state,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case ACTIONS.SET_NOT_AVAIlABLE_CHARTS:
      action.payload.map((el) => {
        state.resizableGrid.layout.map((el2) => {
          if (el.dataGrid.i === el2.i) {
            el2.w = el.dataGrid.w;
            el2.h = el.dataGrid.h;
            el2.x = el.dataGrid.x;
            el2.y = el.dataGrid.y;
          }
          return el2;
        });
        return el;
      });
      return {
        ...state,
        notAvailableCharts: [...action.payload],
      };
    case ACTIONS.SET_IS_HOME:
      return {
        ...state,
        isHome: action.payload,
      };

    case ACTIONS.SET_FILTER_INPUTS:
      return {
        ...state,
        filterInputs: [...action.payload],
      };
    case ACTIONS.SET_AVAILABLE_OPTIONS:
      return {
        ...state,
        availableOptions: [...action.payload],
      };
    case ACTIONS.SET_SELECTED_AVAILABLE_OPTION:
      return {
        ...state,
        selectedAvailableOption: action.payload,
      };
  }
}

export function useDynamicDashboard(defaultValue) {
  const [value, dispatch] = useReducer(reducer, defaultValue);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [value.resizableGrid]);

  const setValue = useCallback((value) => {
    dispatch({ type: 'setValue', payload: value });
  }, []);

  const setDashboardTitle = useCallback((value) => {
    dispatch({ type: 'setDashboardTitle', payload: value });
  }, []);

  const setIsHome = useCallback((value) => {
    dispatch({ type: 'setIsHome', payload: value });
  }, []);

  const setFilterInputs = useCallback((value) => {
    dispatch({ type: 'setFilterInputs', payload: value });
  }, []);

  const setAvailableOptions = useCallback((value) => {
    dispatch({ type: 'setAvailableOptions', payload: value });
  }, []);

  const setSelectedAvailableOption = useCallback((value) => {
    dispatch({ type: 'setSelectedAvailableOption', payload: value });
  }, []);

  const setNotAvailableCharts = useCallback((value) => {
    dispatch({ type: 'setNotAvailableCharts', payload: value });
  }, []);

  const setNotAvailableChartValue = useCallback((value, identifier) => {
    dispatch({
      type: 'setNotAvailableChartValue',
      payload: { value: value, identifier: identifier },
    });
  }, []);

  const setNotAvailableChartSize = useCallback((width, height, identifier) => {
    dispatch({
      type: 'setNotAvailableChartSize',
      payload: { width: width, height: height, identifier: identifier },
    });
  }, []);

  const setAvailableCharts = useCallback((value) => {
    dispatch({ type: 'setAvailableCharts', payload: value });
  }, []);

  const setLayout = useCallback((layout) => {
    dispatch({ type: 'setLayout', payload: layout });
  }, []);

  const setResizableGrid = useCallback((value) => {
    dispatch({ type: 'setResizableGrid', payload: value });
  }, []);

  return {
    value,
    setValue,
    setDashboardTitle,
    setIsHome,
    setFilterInputs,
    setAvailableOptions,
    setSelectedAvailableOption,
    setNotAvailableCharts,
    setNotAvailableChartValue,
    setNotAvailableChartSize,
    setAvailableCharts,
    setLayout,
    setResizableGrid,
  };
}
