import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SentimentConvoDetails } from '../../../sentiment-convo-details/sentiment-convo-details';

import styles from './sentiment-channel-analysis-text-file.module.scss';
import { SentimentConvoList } from '../../../sentiment-convo-list';
import { ReturnButton } from '../../../../buttons/return-button/return-button';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { SecondaryButton } from '../../../../buttons/secondary-button/secondary-button';

export const SentimentChannelAnalysisTextFile = React.memo(({ texts }) => {
    const { t } = useCustomTranslation();
    const [selectedConversation, setSelectedConversation] = useState('');
    return (
        <div
            className={`${styles['sentiment-channel-analysis-text-file-container']}`}
        >
            {selectedConversation !== '' && texts && (
                <React.Fragment>
                    <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                        <div className="col-auto px-0">
                            <SecondaryButton
                                text={t('buttons:return')}
                                onClick={() => setSelectedConversation('')}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
            {selectedConversation === '' && texts && (
                <React.Fragment>
                    {texts.length === 0 && (
                        <div className="row mx-0 w-100 h-100">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['sentiment-channel-analysis-text-file-container__no-data-text']}`}
                                >
                                    {t(
                                        'components:sentiment-channel-analysis-file-text.nodata'
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                    {texts.length !== 0 && (
                        <div
                            className="row mx-0"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['sentiment-channel-analysis-text-file-container__count']} me-3`}
                                >
                                    {texts.length}
                                </span>
                                <span
                                    className={`${styles['sentiment-channel-analysis-text-file-container__title']}`}
                                >
                                    {t(
                                        `components:sentiment-channel-analysis-file-text.results`
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
            <div
                className="row mx-0 w-100 d-flex justify-content-between align-items-center flex-grow-1"
                style={{ minHeight: 0 }}
            >
                <div className="col px-0 h-100">
                    {selectedConversation === '' && texts && (
                        <SentimentConvoList
                            conversations={texts}
                            onViewEmails={setSelectedConversation}
                            type={'2'}
                        />
                    )}
                    {selectedConversation !== '' && texts && (
                        <SentimentConvoDetails
                            el={texts[selectedConversation]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});
