import _ from 'lodash'
export const turnPortugalLayerIntoDistrictsLayers = (portugalLayer) => {
    const districtsLayers = portugalLayer.features.map((district, index) => {
        const districtLayer = {
            paintType: 'fill',
            id: index.toString(),
            data: {
                type: 'FeatureCollection',
                features: [district],
            },
            paint: {
                'fill-color': _.has(district.properties, 'color')
                    ? district.properties.color
                    : 'transparent',
                'fill-opacity': district.marketIndex !== 1 ? 0.3 : 0,
            },
        }

        return districtLayer
    })

    return districtsLayers
}
