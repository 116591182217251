export const productFrequentlyBoughtTogetherMock = {
    hoverStyle: '',
    desc: '',
    noData: false,
    disabled: true,
    loading: true,
    attr: 'productFrequentlyBoughtTogether',
    itemList: [
        {
            name: 'All-Seasons Salt',
            description: 'All-Seasons Salt',
            id: '2',
            price: '€10',
            image: 'https://pillintrip.com/style/images/medicines/brilique/brilique1.jpg',
        },
        {
            name: 'Robust Golden Unsweetened Oolong Tea',
            description: 'Robust Golden Unsweetened Oolong Tea',
            id: '3',
            price: '€6',
            image: 'https://pillintrip.com/style/images/medicines/brilique/brilique1.jpg',
        },
        {
            name: 'Smart Ones Classic Favorites Mini Rigatoni With Vodka Cream Sauce',
            description:
                'Smart Ones Classic Favorites Mini Rigatoni With Vodka Cream Sauce',
            id: '4',
            price: '€1',
            image: 'https://pillintrip.com/style/images/medicines/brilique/brilique1.jpg',
        },
    ],
    imgList: [],
    type: 'product',
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
