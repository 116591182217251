import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GeneralTable } from '../general-table/general-table'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { DTablesService, ProductsService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'

export const DynamicInfoTable = ({ component }) => {
    const name = useMemo(() => {
        return component.name
    }, [component])

    const type = useMemo(() => {
        return component.type
    }, [component])

    const { t } = useCustomTranslation()

    const [tableColumns, setTableColumns] = useState([])
    const [addInputs, setAddInputs] = useState([])
    const [mockData, setMockData] = useState([{}])

    const getData = useCallback(async () => {
        try {
            const tableBack = await DTablesService.getApiBackofficeAdminDtables1({
                tableName: name,
            })

            setTableColumns(
                tableBack.columns
                    .map((column) => {
                        return {
                            path: column.variable_name,
                            backendPath: column.variable_name,
                            show: true,
                            sortable: true,
                        }
                    })
                    .concat([
                        {
                            path: 'id',
                            backendPath: 'id',
                            sortable: false,
                            show: false,
                        },
                        {
                            path: 'action',
                            sortable: false,
                            show: true,
                        },
                    ])
            )
            // create 10 rows of mock data for the table
            const mockData = []
            for (let i = 0; i < 10; i++) {
                const mockRow = {}
                tableBack.columns.map((column) => {
                    mockRow[column.variable_name] = `mock ${column.variable_name} ${i}`
                    return column
                })
                mockRow.id = i
                mockData.push(mockRow)
            }
            setMockData(mockData)
            const newInputs = {}
            if (tableBack.table_type === 'product_id') {
                const productBackData = await ProductsService.getApiProducts({})
                const newFilter = {
                    name: tableBack.table_type,
                    showOptions: true,
                    type: 'selectSingle',
                    options: [],
                    optionsAux: [],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    reference: React.createRef(),
                    backendPath: tableBack.table_type,
                }
                productBackData.map((el) => {
                    newFilter.options.push(el.title)
                    newFilter.optionsAux.push(el.id.toString())
                    return el
                })
                newInputs[tableBack.table_type] = { ...newFilter }
            }
            tableBack.columns.map((el) => {
                if (el.variable_name !== tableBack.table_type) {
                    if (el.variable_type === 'string' || el.variable_type === 'int' || el.variable_type === 'float') {
                        newInputs[el.variable_name] = {
                            name: el.variable_name,
                            type: 'type',
                            label: el.variable_name,
                            value: '',
                            backendPath: el.variable_name,
                        }
                    } else if (el.variable_type === 'datetime') {
                        newInputs[el.variable_name] = {
                            name: el.variable_name,
                            type: 'datenew',
                            label: el.variable_name,
                            value: '',
                            backendPath: el.variable_name,
                        }
                    }
                }
            })
            setAddInputs(newInputs)
        } catch (err) {
            errorHandler(err)
        }
    }, [name])

    useEffect(() => {
        getData()
    }, [getData])

    return (
        <>
            <GeneralTable
                isExample={false}
                title={t(name).split('_')[1]}
                attr={type}
                mockData={mockData}
                columns={tableColumns}
                getEndpoint={DTablesService.getApiBackofficeAdminDtablesData}
                getConfig={{
                    tablename: name,
                }}
                addTitle={t(`components:${type}.addTitle`)}
                addSuccessText={t(`components:${type}.addSuccessText`)}
                addEndpoint={DTablesService.postApiBackofficeAdminDtablesData}
                addPath={{
                    backendPath: 'tableName',
                    value: name,
                }}
                addBodyInputs={addInputs || []}
                deleteEndpoint={DTablesService.deleteApiBackofficeAdminDtablesData}
                deleteTitle={t(`components:${type}.deleteTitle`)}
                deleteDescription={t(`components:${type}.deleteDescription`)}
                deleteSuccessText={t(`components:${type}.deleteSuccessText`)}
                deleteIds={[
                    {
                        backendPath: 'tableName',
                        value: name,
                        isArray: false,
                    },
                    {
                        path: 'id',
                        backendPath: 'ids',
                        isArray: true,
                    },
                ]}
            />
        </>
    )
}
