import React from 'react';
import styles from './card-carousel.module.scss';
import icoArrow from '../../../assets/images/dropdown-arrow.svg';

export const CardCarousel = React.memo(({ cards }) => {
    const [initialCard, setInitialCard] = React.useState(0);
    const [finalCard, setFinalCard] = React.useState(2);
    return (
        <div className={`row mx-0 ${styles['card-carousel-container']}`}>
            <div className="col px-0">
                <div
                    className="row mx-0 d-flex justify-content-end align-items-center"
                    style={{ paddingBottom: '1rem' }}
                >
                    <div className="col-auto px-0">
                        <img
                            loading="lazy"
                            src={icoArrow}
                            alt="arrow"
                            style={{
                                cursor: 'pointer',
                                transform: 'rotate(90deg)',
                                opacity: initialCard == 0 ? '0.5' : '1',
                                pointerEvents: initialCard == 0 ? 'none' : ''
                            }}
                            onClick={() => {
                                setInitialCard(initialCard - 1);
                                setFinalCard(finalCard - 1);
                            }}
                        />
                        <img
                            loading="lazy"
                            src={icoArrow}
                            alt="arrow"
                            style={{
                                cursor: 'pointer',
                                transform: 'rotate(-90deg)',
                                opacity:
                                    finalCard == cards.length - 1 ? '0.5' : '1',
                                pointerEvents:
                                    finalCard == cards.length - 1 ? 'none' : ''
                            }}
                            onClick={() => {
                                setInitialCard(initialCard + 1);
                                setFinalCard(finalCard + 1);
                            }}
                        />
                    </div>
                </div>
                <div className="row mx-0">
                    {cards &&
                        cards.length > 0 &&
                        cards.map((card, index) => {
                            if (index < initialCard || index > finalCard)
                                return null;
                            return (
                                <div
                                    className={`col-4 h-100 ${styles['card-carousel-container__card']}`}
                                    style={{
                                        paddingRight:
                                            index == finalCard
                                                ? '0px'
                                                : '0.5rem',
                                        paddingLeft:
                                            index == initialCard
                                                ? '0px'
                                                : '0.5rem'
                                    }}
                                    key={index}
                                >
                                    {card}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
});
