export const productListMock = {
    hoverStyle: '',
    desc: '',
    titleCounter: 10,
    titleText: '',
    placeholderText: '',
    sortedData: [
        {
            productId: 0,
            productName: 'Mar central pasteleiro central esplanada bar.',
            lastOrder: '11 Dez 2021',
            revenue: '2,612.00',
            risk: 3,
            avgOrders: '52.24',
            totalOrders: 50,
        },
        {
            productId: 1,
            productName: 'Pastel hotel pasteleiro esplanada café mar.',
            lastOrder: '01 Dez 2021',
            revenue: '2,156.00',
            risk: 1,
            avgOrders: '43.12',
            totalOrders: 50,
        },
        {
            productId: 2,
            productName: 'Central pasteleiro pastel pasteleiro pastel.',
            lastOrder: '25 Nov 2021',
            revenue: '2,680.00',
            risk: 1,
            avgOrders: '53.60',
            totalOrders: 50,
        },
        {
            productId: 3,
            productName: 'Nata pasteleiro pastel sol por.',
            lastOrder: '13 Dez 2021',
            revenue: '2,880.00',
            risk: 3,
            avgOrders: '57.60',
            totalOrders: 50,
        },
        {
            productId: 4,
            productName:
                'Sol pasteleiro café central por pastel central pastel.',
            lastOrder: '12 Dez 2021',
            revenue: '2,465.00',
            risk: 2,
            avgOrders: '49.30',
            totalOrders: 50,
        },
        {
            productId: 5,
            productName: 'Hotel esplanada bar mar sol nata pasteleiro.',
            lastOrder: '06 Dez 2021',
            revenue: '2,470.00',
            risk: 1,
            avgOrders: '49.40',
            totalOrders: 50,
        },
        {
            productId: 6,
            productName: 'Hotel sol nata esplanada por.',
            lastOrder: '15 Dez 2021',
            revenue: '2,323.00',
            risk: 1,
            avgOrders: '46.46',
            totalOrders: 50,
        },
        {
            productId: 7,
            productName: 'Esplanada pastel central central.',
            lastOrder: '11 Dez 2021',
            revenue: '2,330.00',
            risk: 3,
            avgOrders: '46.60',
            totalOrders: 50,
        },
        {
            productId: 8,
            productName: 'Pasteleiro café sol pastel café pasteleiro nata.',
            lastOrder: '02 Dez 2021',
            revenue: '2,575.00',
            risk: 3,
            avgOrders: '51.50',
            totalOrders: 50,
        },
        {
            productId: 9,
            productName: 'Pastel por do café.',
            lastOrder: '15 Nov 2021',
            revenue: '2,377.00',
            risk: 1,
            avgOrders: '47.54',
            totalOrders: 50,
        },
    ],
    data: [
        {
            productId: 0,
            productName: 'Mar central pasteleiro central esplanada bar.',
            lastOrder: '11 Dez 2021',
            revenue: '2,612.00',
            risk: 3,
            avgOrders: '52.24',
            totalOrders: 50,
        },
        {
            productId: 1,
            productName: 'Pastel hotel pasteleiro esplanada café mar.',
            lastOrder: '01 Dez 2021',
            revenue: '2,156.00',
            risk: 1,
            avgOrders: '43.12',
            totalOrders: 50,
        },
        {
            productId: 2,
            productName: 'Central pasteleiro pastel pasteleiro pastel.',
            lastOrder: '25 Nov 2021',
            revenue: '2,680.00',
            risk: 1,
            avgOrders: '53.60',
            totalOrders: 50,
        },
        {
            productId: 3,
            productName: 'Nata pasteleiro pastel sol por.',
            lastOrder: '13 Dez 2021',
            revenue: '2,880.00',
            risk: 3,
            avgOrders: '57.60',
            totalOrders: 50,
        },
        {
            productId: 4,
            productName:
                'Sol pasteleiro café central por pastel central pastel.',
            lastOrder: '12 Dez 2021',
            revenue: '2,465.00',
            risk: 2,
            avgOrders: '49.30',
            totalOrders: 50,
        },
        {
            productId: 5,
            productName: 'Hotel esplanada bar mar sol nata pasteleiro.',
            lastOrder: '06 Dez 2021',
            revenue: '2,470.00',
            risk: 1,
            avgOrders: '49.40',
            totalOrders: 50,
        },
        {
            productId: 6,
            productName: 'Hotel sol nata esplanada por.',
            lastOrder: '15 Dez 2021',
            revenue: '2,323.00',
            risk: 1,
            avgOrders: '46.46',
            totalOrders: 50,
        },
        {
            productId: 7,
            productName: 'Esplanada pastel central central.',
            lastOrder: '11 Dez 2021',
            revenue: '2,330.00',
            risk: 3,
            avgOrders: '46.60',
            totalOrders: 50,
        },
        {
            productId: 8,
            productName: 'Pasteleiro café sol pastel café pasteleiro nata.',
            lastOrder: '02 Dez 2021',
            revenue: '2,575.00',
            risk: 3,
            avgOrders: '51.50',
            totalOrders: 50,
        },
        {
            productId: 9,
            productName: 'Pastel por do café.',
            lastOrder: '15 Nov 2021',
            revenue: '2,377.00',
            risk: 1,
            avgOrders: '47.54',
            totalOrders: 50,
        },
    ],
    columns: [
        {
            path: 'productId',
            sortable: true,
            translated: false,
        },
        {
            path: 'productName',
            sortable: true,
            translated: false,
        },
        {
            path: 'avgOrders',
            sortable: true,
            translated: false,
        },
        {
            path: 'lastOrder',
            sortable: true,
            translated: false,
        },
        {
            path: 'revenue',
            sortable: true,
            translated: false,
        },
        {
            path: 'risk',
            sortable: true,
            translated: false,
        },
        {
            path: 'totalOrders',
            sortable: true,
            translated: false,
        },
    ],
    sortColumn: {
        path: 'risk',
        order: 'asc',
    },
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
    disabled: true,
    currentPage: 1,
    pageSize: 7,
    type: 'type-1',
    attr: 'productTable',
    actions: {},
    loading: false,
}
