import { createContext, useContext } from 'react';

const SearchContext = createContext({
    search: ''
});

export function SearchProvider({ value, children }) {
    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    );
}

export function useSearch() {
    return useContext(SearchContext);
}
