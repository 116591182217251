/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportFilterSchema } from '../models/ReportFilterSchema';
import type { ReportSchema } from '../models/ReportSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReportService {
    /**
     * get all reports
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeReporting({
        reportFilterSchema,
    }: {
        reportFilterSchema?: ReportFilterSchema,
    }): CancelablePromise<Array<ReportSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/reporting',
            query: {
                'ReportFilterSchema': reportFilterSchema,
            },
        });
    }
    /**
     * create report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeReporting({
        requestBody,
    }: {
        requestBody: ReportSchema,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/reporting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * gets reports authorized for user
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeReportingMyReports({
        tag,
        reportType,
        emailAfterGenerate,
        scheduled,
        createdBy,
        users,
        teams,
    }: {
        tag?: string,
        reportType?: string,
        emailAfterGenerate?: boolean,
        scheduled?: boolean,
        createdBy?: number,
        users?: number,
        teams?: number,
    }): CancelablePromise<Array<ReportSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/reporting/my-reports',
            query: {
                'tag': tag,
                'report_type': reportType,
                'email_after_generate': emailAfterGenerate,
                'scheduled': scheduled,
                'created_by': createdBy,
                'users': users,
                'teams': teams,
            },
        });
    }
    /**
     * deletes report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static deleteApiBackofficeReporting({
        reportId,
    }: {
        /**
         * id of report.
         */
        reportId: number,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/reporting/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * gets specific report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeReporting1({
        reportId,
    }: {
        /**
         * id of report.
         */
        reportId: number,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/reporting/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * service for frontend to upload and Update report file associated with the report in DB
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static patchApiBackofficeReporting({
        reportId,
        formData,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        /**
         * file
         */
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/backoffice/reporting/{report_id}',
            path: {
                'report_id': reportId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * updates report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static putApiBackofficeReporting({
        reportId,
        requestBody,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        requestBody: ReportSchema,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/reporting/{report_id}',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * activates/deactivates scheduling
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeReportingActivateScheduling({
        reportId,
    }: {
        /**
         * id of report.
         */
        reportId: number,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/reporting/{report_id}/activate-scheduling',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * downloads current version of report as an attachment
     * @returns binary call successful
     * @throws ApiError
     */
    public static getApiBackofficeReportingDownload({
        reportId,
        encodeB64 = false,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        encodeB64?: boolean,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/reporting/{report_id}/download',
            path: {
                'report_id': reportId,
            },
            query: {
                'encode_b64': encodeB64,
            },
        });
    }
    /**
     * asks frontend for report and sets file. it goes the same as a recurring job to generate reports.
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeReportingGenerate({
        reportId,
    }: {
        /**
         * id of report.
         */
        reportId: number,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/reporting/{report_id}/generate',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * adds team ids to receive report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeReportingTeams({
        reportId,
        requestBody,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        requestBody: Array<number>,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/reporting/{report_id}/teams',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * replaces team ids to receive report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static putApiBackofficeReportingTeams({
        reportId,
        requestBody,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        requestBody: Array<number>,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/reporting/{report_id}/teams',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * ads user uuids to receive report
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeReportingUsers({
        reportId,
        requestBody,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        requestBody: Array<string>,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/reporting/{report_id}/users',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * sets user uuids only to those in request
     * @returns ReportSchema call successful
     * @throws ApiError
     */
    public static putApiBackofficeReportingUsers({
        reportId,
        requestBody,
    }: {
        /**
         * id of report.
         */
        reportId: number,
        requestBody: Array<string>,
    }): CancelablePromise<ReportSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/reporting/{report_id}/users',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
