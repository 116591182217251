import React, { useEffect, useState, useRef } from 'react';
import styles from './FullTextPopup.module.scss';
import useCursorTracking from '../../../hooks/useCursorTracking';
import { useCursorTrackingProvider } from '../../../providers/cursor-tracking-context';

export const FullTextPopup = React.memo(({ text, children }) => {
    const [showPopup, setShowPopup] = useState(false);
    const { position, reversedHorizontal, reversedVertical } =
        useCursorTrackingProvider();
    const timeoutRef = useRef(null);

    function handleMouseEnter(event) {
        timeoutRef.current = setTimeout(() => {
            setShowPopup(true);
        }, 300);
    }

    function handleMouseOut() {
        clearTimeout(timeoutRef.current);
        setShowPopup(false);
    }

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <div
            className="d-flex justify-content-start align-items-center"
            style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseOut}
        >
            <span
                className={`${styles['full-text-popup-container__capitalization']}`}
            >
                {showPopup && text && text != 0 && (
                    <span
                        style={{
                            position: 'fixed',
                            top: position.y - 20,
                            left: position.x,
                            display:
                                position.x === 0 && position.y === 0
                                    ? 'none'
                                    : 'block',
                            transform: `translateX(${
                                reversedHorizontal ? '-100%' : '0'
                            }) translateY(${reversedVertical ? '-100%' : '0'})`
                        }}
                        className={`${styles['full-text-popup-container']}`}
                    >
                        {text}
                    </span>
                )}
            </span>
            {children}
        </div>
    );
});
