import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SegmentUnitsSoldOverTimeTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.active && props.payload && props.payload.length > 0) {
        return (
            <TooltipContainer color={props.payload[0].color}>
                <>
                    <TooltipHeaderRow title={props.payload[0].payload['month']} />
                    <Separator />
                    <TooltipContentRow
                        title={t('tooltips:content.unitsSold')}
                        value={Math.floor(props.payload[0].payload['value'])}
                        color={props.payload[0].color}
                    />
                </>
            </TooltipContainer>
        )
    } else return null
})
