import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { DTablesService } from '../../../temp/test';
import { formatDate } from '../../../utils/date';
import { selectedDynamicTablesListMock } from './mock';
import { verifyComponent } from '../../../utils/component.js';
import { ContentContainer, Loader, Spinner, Table } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { RemoveDynamicInfoModal } from '../../modals/remove-dynamic-info-modal /remove-dynamic-info-modal ';
import { AddDynamicInfoModal } from '../../modals/add-dynamic-info-modal/add-dynamic-info-modal';
import { EditDynamicInfoModal } from '../../modals/edit-dynamic-info-modal/edit-dynamic-info-modal';
import { TableImprovement } from '../../tables/table-improvement/table-improvement';
import icoDelete from '../../../assets/images/ico-delete.svg';

/**
 * Displays all columns of a selected dynamic table
 */

export const SelectedDynamicTablesList = React.memo(({ selectedTable }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [selectedInfo, setSelectedInfo] = useState(undefined);
    const [addInfoModal, toggleAddInfoModal] = useToggle(false);
    const [removeInfoModal, toggleRemoveInfoModal] = useToggle(false);
    const [editInfoModal, toggleEditInfoModal] = useToggle(false);
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(selectedDynamicTablesListMock);

    const getData = useCallback(async () => {
        const dataClone = _.cloneDeep(selectedDynamicTablesListMock);
        try {
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            const tableBack =
                await DTablesService.getApiBackofficeAdminDtables1({
                    tableName: selectedTable.tableName
                });

            dataClone.columns = tableBack.columns.map((column) => {
                return {
                    path: column.variable_name,
                    backendPath: column.variable_name,
                    show: true,
                    sortable: true
                };
            });
            dataClone.columns.push({
                path: 'action',
                sortable: false
            });
            const dTableBack =
                await DTablesService.getApiBackofficeAdminDtablesData({
                    tablename: selectedTable.tableName,
                    ids: undefined,
                    page: undefined,
                    itemsPerPage: undefined
                });
            for (let el of dTableBack) {
                dataClone.data.push({
                    ...el,
                    action: {
                        label: t(`columns:action`),
                        id: el.id,
                        open: false,
                        options: [
                            {
                                icon: icoDelete,
                                name: 'delete',
                                label: t(`settings:delete`),
                                color: '#4c4c4c'
                            }
                        ],
                        reference: React.createRef()
                    }
                });
            }
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            dataClone.loading = false;
            verifyComponent(dataClone);
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [selectedTable, setValue, setLoading, isMounted, t]);

    useEffect(() => {
        getData();
    }, [getData]);

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            clickActionIcon(attr, id);
            if (option === 'delete') {
                setSelectedInfo(id);
                toggleRemoveInfoModal();
            }
        },
        [clickActionIcon, toggleRemoveInfoModal]
    );

    return (
        <>
            {addInfoModal && (
                <AddDynamicInfoModal
                    dtableName={selectedTable.tableName}
                    onCloseModal={toggleAddInfoModal}
                    onEnd={() => {
                        getData();
                    }}
                />
            )}
            {removeInfoModal && (
                <RemoveDynamicInfoModal
                    dtableName={selectedTable.tableName}
                    dtableInfoId={selectedInfo}
                    onCloseModal={toggleRemoveInfoModal}
                    onEnd={() => {
                        getData();
                    }}
                />
            )}
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <Table
                        actions={data.actions}
                        // onClickActionIcon={onClickActionIcon}
                        // onClickActionOption={onClickActionOption}
                        // onCloseActions={onCloseActions}
                        useTitle
                        attr={data.attr}
                        type={data.type}
                        titleCounter={data.titleCounter}
                        titleText={selectedTable.tableName}
                        placeholderText={t(`components:${data.attr}.nodata`)}
                        columns={data.columns}
                        data={data.sortedData}
                        sortColumn={data.sortColumn}
                        onSort={dataSort}
                        currentPage={data.currentPage}
                        pageSize={data.pageSize}
                        onPageChange={dataPageChange}
                        onRowClick={undefined}
                        onRowSelect={undefined}
                        onSelectAll={dataSelectAll}
                        onUnselectAll={dataUnselectAll}
                        paginationDisabled={data.paginationDisabled}
                        disabled={data.disabled}
                        onNewElementClick={() => {
                            toggleAddInfoModal();
                        }}
                        onNewElementText={t('buttons:addDTableInfo')}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                    ></Table>
                )}
            </ContentContainer>
        </>
    );
});

SelectedDynamicTablesList.propTypes = {
    /**
     * Dynamic table as a filter for getting data from api
     */
    selectedTable: PropTypes.object
};
