import { createContext, useContext } from 'react';

const ModalPopupContext = createContext({
    isOpen: undefined,
    toggleIsOpen: () => {},
    steps: undefined,
    selectedTutorialStep: undefined,
    currentStep: undefined,
    setCurrentStep: () => {},
    completeTutorialStep: () => {}
});

export function ModalPopupProvider({ value, children }) {
    return (
        <ModalPopupContext.Provider value={value}>
            {children}
        </ModalPopupContext.Provider>
    );
}

export function useModalPopup() {
    return useContext(ModalPopupContext);
}
