import React, { useMemo } from 'react';
import styles from './image-input.module.scss';

export const ImageInput = ({ isEditing, image, onSetImage }) => {
    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            onSetImage(e.target.files[0]);
        }
    };

    // New function to handle file drop
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onSetImage(e.dataTransfer.files[0]);
        }
    };

    // Function to prevent default behavior for drag events
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const imageToShow = useMemo(() => {
        if (image) return URL.createObjectURL(image);
    }, [image]);

    return (
        <div
            className={styles['image-input-container']}
            style={!isEditing ? { border: 'none' } : {}}
            onDragOver={handleDrag}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
        >
            {!isEditing && !imageToShow && (
                <div className="row mx-0 w-100 h-100">
                    <div
                        className="col px-0 d-flex justify-content-center align-items-center
                    "
                    >
                        <span
                            className={
                                styles['image-input-container__no-image-text']
                            }
                        >
                            Please upload an image
                        </span>
                    </div>
                </div>
            )}
            {!isEditing && imageToShow && (
                <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                    <div
                        className="col h-100 px-0 d-flex justify-content-center align-items-center
                    "
                    >
                        <img
                            src={imageToShow}
                            alt="image"
                            className="img-fluid"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                borderRadius: '0.5rem',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                window.open(imageToShow, '_blank');
                            }}
                        />
                    </div>
                </div>
            )}
            {isEditing && imageToShow && (
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 d-flex flex-column justify-content-center align-items-center h-100">
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                            style={{
                                height: 'calc(100% - 6rem)',
                                marginBottom: '2rem'
                            }}
                        >
                            <div className="col-12 px-0 d-flex justify-content-center align-items-center h-100">
                                <img
                                    src={imageToShow}
                                    alt="image"
                                    className="img-fluid"
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                        maxHeight: '100%',
                                        borderRadius: '0.5rem',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        window.open(imageToShow, '_blank');
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                            <div className="col-auto px-0">
                                <div
                                    className={`${styles['image-input-container__button']}
                                    ${styles['image-input-container__button--remove']}
                                    `}
                                    onClick={() => {
                                        onSetImage(null);
                                    }}
                                >
                                    <span
                                        className={`${styles['image-input-container__button__text']}`}
                                    >
                                        Remove
                                    </span>
                                </div>
                            </div>
                            <div
                                className="col-auto px-0"
                                style={{ width: '2rem' }}
                            ></div>
                            <div className="col-auto px-0">
                                <div
                                    className={`${styles['image-input-container__button']}`}
                                    onClick={() => {
                                        document
                                            .querySelector('input[type=file]')
                                            .click();
                                    }}
                                >
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            handleImageChange(e);
                                        }}
                                    />
                                    <span
                                        className={`${styles['image-input-container__button__text']}`}
                                    >
                                        Browse files
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isEditing && !imageToShow && (
                <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                    <div className="col px-0" style={{ maxWidth: '40rem' }}>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                    <div className="col-auto px-0"></div>
                                </div>
                                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['image-input-container__text1']}`}
                                        >
                                            Drag and drop files here
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <div
                                    className={
                                        styles['image-input-container__line']
                                    }
                                ></div>
                            </div>
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['image-input-container__text2']}`}
                                >
                                    or{' '}
                                </span>
                            </div>
                            <div className="col px-0">
                                <div
                                    className={
                                        styles['image-input-container__line']
                                    }
                                ></div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                            <div className="col-auto px-0">
                                <div
                                    className={`${styles['image-input-container__button']}`}
                                    onClick={() => {
                                        document
                                            .querySelector('input[type=file]')
                                            .click();
                                    }}
                                >
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                    <span
                                        className={`${styles['image-input-container__button__text']}`}
                                    >
                                        Browse files
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
