import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { reportGeneralDetailsMock } from './mock'
import styles from './report-general-details.module.scss'
import { ContentContainer } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const ReportGeneralDetails = React.memo(({ reportId }) => {
    const isMounted = useIsMounted()
    const { t } = useCustomTranslation()

    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings,
    } = useSimpleComponent(reportGeneralDetailsMock)

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(reportGeneralDetailsMock)
            try {
                setLoading(true)
                verifyComponent(dataClone)
                if (isMounted.current) {
                    setValue(dataClone)
                    setLoading(false)
                }
            } catch (err) {
                errorHandler(err)
                dataClone.loading = false
                if (isMounted.current) {
                    setValue(dataClone)
                }
            }
        }
        fill()
    }, [isMounted, setValue, reportId, setLoading])

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={undefined}
            >
                <div
                    className={`${styles['report-general-details-container']}`}
                >
                    <div
                        className={`row mx-0 ${styles['report-general-details-container__content-container']}`}
                    >
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div
                                    className={`row mx-0 ${styles['report-general-details-container__content-container__details-container']}`}
                                >
                                    <div className="col px-0">
                                        <div className="row mx-0 h-100">
                                            <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                'components:reportGeneralDetails.tag'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__text']}`}
                                                        >
                                                            -
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                'components:reportGeneralDetails.createdBy'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__text']}`}
                                                        >
                                                            -
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                'components:reportGeneralDetails.reportType'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__text']}`}
                                                        >
                                                            -
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                'components:reportGeneralDetails.periodicity'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__text']}`}
                                                        >
                                                            -
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                'components:reportGeneralDetails.scheduled'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['report-general-details-container__content-container__details-container__text']}`}
                                                        >
                                                            -
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </>
    )
})

ReportGeneralDetails.propTypes = {}
