import { createContext, useContext } from 'react'

const HighlightedChartContext = createContext({
    highlightedChart: undefined,
    setHighlightedChart: () => {},
})

export function HighlightedChartProvider({ value, children }) {
    return (
        <HighlightedChartContext.Provider value={value}>
            {children}
        </HighlightedChartContext.Provider>
    )
}

export function useHighlightedChart() {
    return useContext(HighlightedChartContext)
}
