import React from 'react';

export const totalClientsMock = {
    hoverStyle: '',
    desc: '',
    loading: true,
    title: '',
    titleColor: '#606060',
    content: '80',
    contentAux: '13',
    timeInterval: 'monthly',
    contentType: '',
    contentColor: '#323136',
    contentFractionTotal: '',
    contentAuxType: '',
    contentAuxColor: '#323136',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaAttr: [
        {
            dataKey: 'minForecastValue-totalClients',
            color: '#323136',
            strokeWidth: 2,
            dashArray: '3 3'
        },
        {
            dataKey: 'unitsSold-totalClients',
            color: '#323136',
            strokeWidth: 2
        }
    ],
    categoryAxis: 'month',
    data: [
        {
            'unitsSold-totalClients': 10,
            'minForecastValue-totalClients': 10,
            month: 'Jan',
            time: 'Jan'
        },
        {
            'unitsSold-totalClients': 15,
            'minForecastValue-totalClients': 15,
            month: 'Feb',
            time: 'Feb'
        },
        {
            'unitsSold-totalClients': 10,
            'minForecastValue-totalClients': 10,
            month: 'Mar',
            time: 'Mar'
        },
        {
            'unitsSold-totalClients': 18,
            'minForecastValue-totalClients': 18,
            month: 'Apr',
            time: 'Apr'
        },
        {
            'unitsSold-totalClients': 20,
            'minForecastValue-totalClients': 20,
            month: 'May',
            time: 'May'
        }
    ],
    dateGrouper: '',
    gradientOn: true,
    lineType: 'monotone',
    maxValue: 25,
    minValue: 2,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'salesForecast-monthly',
    unitsOrValues: 'units',
    margin: {
        top: 20,
        right: 20,
        left: -30,
        bottom: -20
    },
    attr: 'totalClientsCard',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    }
};
