import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import styles from './donut-chart.module.scss'
import { FullTextPopup, TooltipFactory } from '../../'
import useCursorTracking from '../../../hooks/useCursorTracking'
import _ from 'lodash'

const limitTextWithEllipsisToXChars = (text, limit) => {
    if (text.length > limit) {
        return text.substring(0, limit) + '...'
    }
    return text
}

export const DonutChart = React.memo(
    ({ donutAttr, data, title, attr, subTitle, text, isHalfCircle, isFull, insideText, insideTitle, fillInside, addSpacingBetween }) => {
        const [animationActive, setAnimationActive] = React.useState(true)
        const { position } = useCursorTracking()

        const [hoveredDonutPiece, setHoveredDonutPiece] = React.useState(undefined)

        const [popup, setPopup] = React.useState({
            show: false,
            text: '',
            x: 0,
            y: 0,
        })

        const RADIAN = Math.PI / 180
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }) => {
            const radius = innerRadius + (outerRadius - innerRadius) + 30
            const x = cx + radius * Math.cos(-midAngle * RADIAN)
            const y = cy + radius * Math.sin(-midAngle * RADIAN)

            return (
                <text
                    x={x}
                    y={y}
                    fill={'#4c4c4c'}
                    fontSize={'1.2rem'}
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                    className="label-donut"
                    onMouseEnter={() =>
                        setPopup({
                            show: true,
                            text: payload.name,
                            x: position.x,
                            y: position.y,
                        })
                    }
                    onMouseLeave={() => setPopup({ show: false, text: '', x: 0, y: 0 })}
                >
                    {limitTextWithEllipsisToXChars(payload.name, 8)}
                </text>
            )
        }

        return (
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0 d-flex flex-column position-relative h-100">
                    <div className="row mx-0 w-100 h-100">
                        <div className="col px-0 h-100" style={{ zIndex: '1' }}>
                            <ResponsiveContainer width={'100%'} height={'100%'}>
                                <PieChart width={'100%'} height={'100%'}>
                                    <defs>
                                        {_.has(data, 'data1') &&
                                            data.data1 &&
                                            data.data1.map((el, index) => {
                                                return (
                                                    <linearGradient key={index} id={`gradientCursor${index}-${attr}`} x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor={el.color} stopOpacity={0.8} />
                                                        <stop offset="95%" stopColor={el.color} stopOpacity={0.4} />
                                                    </linearGradient>
                                                )
                                            })}
                                    </defs>
                                    <Tooltip
                                        cursor={false}
                                        content={
                                            <TooltipFactory
                                                item={{
                                                    type: attr,
                                                    data: data,
                                                    index: hoveredDonutPiece,
                                                }}
                                            />
                                        }
                                        wrapperStyle={{
                                            outline: 'none',
                                        }}
                                    />
                                    {donutAttr.map((el) => {
                                        return (
                                            <Pie
                                                key={el.dataKey}
                                                data={data[el.dataKey]}
                                                dataKey={el.valueKey}
                                                nameKey={el.nameKey}
                                                cx="50%"
                                                cy={isHalfCircle ? '75%' : '50%'}
                                                innerRadius={fillInside ? '0%' : !isHalfCircle && !isFull ? '50%' : isFull ? '75%' : '100%'}
                                                blendStroke={!addSpacingBetween ? true : false}
                                                isAnimationActive={animationActive}
                                                onAnimationEnd={() => setAnimationActive(false)}
                                                outerRadius={!isHalfCircle && !isFull ? '70%' : isFull ? '100%' : '120%'}
                                                fill="#1576BF"
                                                startAngle={0}
                                                endAngle={isHalfCircle ? 180 : 360}
                                                paddingAngle={5}
                                                labelLine={true}
                                                label={!isHalfCircle && !isFull ? renderCustomizedLabel : undefined}
                                                cornerRadius={5}
                                            >
                                                {data[el.dataKey].map((entry, index2) => {
                                                    return (
                                                        <Cell
                                                            onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                            onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                            strokeWidth={2}
                                                            style={{
                                                                outline: 'none',
                                                            }}
                                                            key={`cell-${index2}`}
                                                            fill={`url(#gradientCursor${index2}-${attr})`}
                                                        />
                                                    )
                                                })}
                                            </Pie>
                                        )
                                    })}
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    {(insideText || insideTitle) && (
                        <div
                            className={`${styles['donut-chart-container__inside-text']}`}
                            style={
                                isHalfCircle
                                    ? {
                                          top: '75%',
                                          transform: 'translate(-50%, calc(-50% - 2.5rem))',
                                      }
                                    : {
                                          top: '50%',
                                          transform: 'translate(-50%, -50%)',
                                      }
                            }
                        >
                            {insideTitle && (
                                <div className="row mx-0 w-100">
                                    <div className="col px-0 d-flex justify-content-center">
                                        <span className={`${styles['donut-chart-container__inside-text__title']}`}>{insideTitle}</span>
                                    </div>
                                </div>
                            )}
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center">
                                    <span
                                        className={`${styles['donut-chart-container__inside-text__text']}`}
                                        style={isHalfCircle ? { fontSize: '5rem' } : {}}
                                    >
                                        {insideText}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`${styles['donut-chart-container__content-container']}`}>
                        <span className={`${styles['donut-chart-container__content-container__title']}`}>{title}</span>
                        <span className={`${styles['donut-chart-container__content-container__sub-title']}`}>{subTitle}</span>
                        <span className={`${styles['donut-chart-container__content-container__text']}`}>{text}</span>
                    </div>
                </div>
                {popup.show && (
                    <div
                        style={{
                            transform: 'translateX(-50%) translateY(-100%)',
                            backgroundColor: 'white',
                            padding: '5px',
                            zIndex: 10,
                            width: 'fit-content',
                            borderRadius: '0.6rem',
                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                            pointerEvents: 'none',
                            transition: 'all 0.3s ease-in-out',
                        }}
                    >
                        {popup.text}
                    </div>
                )}
            </div>
        )
    }
)
