/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaSentimentSchema } from '../models/MediaSentimentSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ScrapperService {
    /**
     * Returns list of MediaSentiment
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiBackofficeScrapperFolha({
        requestBody,
    }: {
        /**
         * q schema
         */
        requestBody?: {
            input_keywords?: Array<string>;
            'q'?: string;
            session_id?: number;
        },
    }): CancelablePromise<{
        data?: Array<MediaSentimentSchema>;
        result?: Record<string, any>;
        session_id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/scrapper/folha',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
