import React, { useCallback, useMemo } from 'react';
import styles from './create-goal-modal.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { StepByStepModal } from '../step-by-step-modal/step-by-step-modal';
import { errorHandler } from '../../../utils/api';
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { SelectInput } from '../../inputs/select-input/select-input';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import { TypeInput } from '../../inputs/type-input/type-input';
import { RadioInput } from '../../inputs/radio-input/radio-input';
import { TimePeriodInput } from '../../inputs/time-period-input/time-period-input';
import { ToggleButton } from '../../inputs/toggle-button/toggle-button';
import { useToggle } from '../../../hooks/useToggle';
import icoHighImpactGoal from '../../../assets/images/icoHighImpactGoal.svg';
import icoHighImpactGoalWhite from '../../../assets/images/icoHighImpactGoalWhite.svg';
import { GoalSummaryModal } from '../goal-summary-modal/goal-summary-modal';
import { SelectLabelInput } from '../../inputs/select-label-input/select-label-input';
import icoPlusDimension from '../../../assets/images/icoPlusDimension.svg';
import { SelectMultipleInput } from '../../inputs/select-multiple-input/select-multiple-input';

export const CreateGoalModal = ({ onToggleModal }) => {
    const { t } = useCustomTranslation();
    const [currentStep, setCurrentStep] = React.useState(0);
    const [reminder, toggleReminder] = useToggle(false);
    const [highImpactGoal, toggleHighImpactGoal] = useToggle(false);
    const [summary, toggleSummary] = useToggle(false);
    const [businessDimensionCounter, setBusinessDimensionCounter] =
        React.useState(1);
    const {
        value: inputs,
        clickRadioOption: inputsClickRadioOption,
        setValue: inputsSetValue,
        toggleDropdown: inputsToggleDropdown,
        selectOption: inputsSelectOption,
        setOptions: inputsSetOptions,
        keyPress: inputsKeyPress,
        keyDown: inputsKeyDown,
        addInput: inputsAddInput,
        removeInput: inputsRemoveInput
    } = useMultipleInputs({
        goalTitle: {
            type: 'type',
            name: 'goalTitle'
        },
        goalDescription: {
            type: 'type',
            name: 'goalDescription'
        },
        goalOwner: {
            type: 'selectSingle',
            name: 'goalOwner',
            options: ['John Doe', 'Jane Doe'],
            optionsIcon: ['John Doe', 'Jane Doe'],
            reference: React.createRef()
        },
        goalType: {
            type: 'radio',
            name: 'goalType',
            direction: 'horizontal',
            options: [
                {
                    name: 'enterpriseGoal',
                    label: t(`common:enterpriseGoal`)
                },
                {
                    name: 'organizationGoal',
                    label: t(`common:organizationGoal`)
                },
                {
                    name: 'teamGoal',
                    label: t(`common:teamGoal`)
                }
            ],
            selectedOption: '',
            value: '',
            reference: React.createRef()
        },
        organization: {
            type: 'selectSingle',
            name: 'organization',
            options: [],
            reference: React.createRef()
        },
        enterprise: {
            type: 'selectSingle',
            name: 'enterprise',
            options: [],
            reference: React.createRef()
        },
        team: {
            type: 'selectSingle',
            name: 'team',
            options: [],
            reference: React.createRef()
        },
        visibility: {
            type: 'selectSingle',
            name: 'visibility',
            options: [],
            reference: React.createRef()
        },
        startDate: {
            name: 'startDate'
        },
        endDate: {
            name: 'endDate'
        },
        businessIndicator: {
            type: 'selectSingle',
            name: 'businessIndicator',
            options: ['Revenue', 'Profit'],
            reference: React.createRef()
        },
        targetCategory: {
            type: 'selectLabel',
            name: 'targetCategory',
            options: ['Efficiency', 'Quality', 'Safety', 'Cost', 'Delivery'],
            optionsAux: ['Efficiency', 'Quality', 'Safety', 'Cost', 'Delivery'],
            reference: React.createRef()
        },
        targetValue: {
            type: 'type',
            name: 'targetValue'
        },
        businessDimensionCategories1: {
            type: 'selectSingle',
            name: 'businessDimensionCategories1',
            options: [],
            reference: React.createRef()
        },
        valuesbusinessDimensionCategories1: {
            type: 'selectMultiple',
            name: 'valuesbusinessDimensionCategories1',
            options: [],
            reference: React.createRef()
        },

        approvalRequest: {
            type: 'radio',
            name: 'approvalRequest',
            direction: 'horizontal',
            options: [
                {
                    name: 'yes',
                    label: t(`inputs:yes`)
                },
                {
                    name: 'no',
                    label: t(`inputs:no`)
                }
            ],
            selectedOption: '',
            value: '',
            reference: React.createRef()
        },
        approvalRequestOwner: {
            type: 'selectSingle',
            name: 'approvalRequestOwner',
            options: [],
            reference: React.createRef()
        }
    });

    const handleCreateGoal = useCallback(async () => {
        try {
            // await createGoal
            onToggleModal();
        } catch (error) {
            errorHandler(error);
        }
    }, [onToggleModal]);

    const handleAddBusinessDimension = useCallback(() => {
        inputsAddInput(
            'businessDimensionCategories' + businessDimensionCounter + 1,
            'selectSingle',
            {},
            [],
            [],
            React.createRef()
        );
        inputsAddInput(
            'valuesbusinessDimensionCategories' + businessDimensionCounter + 1,
            'selectMultiple',
            {},
            [],
            [],
            React.createRef()
        );
        setBusinessDimensionCounter(businessDimensionCounter + 1);
    }, [businessDimensionCounter, inputsAddInput]);

    const handleRemoveBusinessDimension = useCallback(() => {
        inputsRemoveInput('businessDimension' + businessDimensionCounter);
        inputsRemoveInput(
            'businessDimension' + businessDimensionCounter + 'Values'
        );
        setBusinessDimensionCounter(businessDimensionCounter - 1);
    }, [businessDimensionCounter, inputsRemoveInput]);

    const dynamicProps = useMemo(() => {
        switch (inputs.goalType.selectedOption) {
            case 'enterpriseGoal':
                return { ...inputs.enterprise };
            case 'organizationGoal':
                return { ...inputs.organization };
            case 'teamGoal':
                return { ...inputs.team };
            default:
                return {};
        }
    }, [
        inputs.goalType.selectedOption,
        inputs.enterprise,
        inputs.organization,
        inputs.team
    ]);

    return (
        <>
            {!summary && (
                <StepByStepModal
                    steps={[
                        t('modals:createGoalModal.steps.0'),
                        t('modals:createGoalModal.steps.1'),
                        t('modals:createGoalModal.steps.2'),
                        t('modals:createGoalModal.steps.3'),
                        t('modals:createGoalModal.steps.4')
                    ]}
                    onToggleModal={onToggleModal}
                    currentStep={currentStep}
                >
                    {currentStep === 0 && (
                        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="row mx-0 w-100 flex-grow-1 d-flex justify-content-center align-items-center"
                                style={{
                                    margin: '2rem 0'
                                }}
                            >
                                <div className="col-auto px-0">
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '30rem' }}
                                        >
                                            <TypeInput
                                                onChangeInput={inputsSetValue}
                                                newLabelType
                                                {...inputs.goalTitle}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '30rem' }}
                                        >
                                            <TypeInput
                                                onChangeInput={inputsSetValue}
                                                newLabelType
                                                inputType="container"
                                                isOptional
                                                {...inputs.goalDescription}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '30rem' }}
                                        >
                                            <SelectInput
                                                onChangeInput={inputsSetValue}
                                                onSelectInput={
                                                    inputsSelectOption
                                                }
                                                onKeyPress={inputsKeyPress}
                                                onKeyDown={inputsKeyDown}
                                                onToggleDropdown={
                                                    inputsToggleDropdown
                                                }
                                                hideOptionsAux
                                                newLabelType
                                                {...inputs.goalOwner}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col-auto px-0">
                                            <RadioInput
                                                onClickRatioOption={
                                                    inputsClickRadioOption
                                                }
                                                {...inputs.goalType}
                                            />
                                        </div>
                                    </div>
                                    {inputs.goalType.selectedOption && (
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-start"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div
                                                className="col-auto px-0"
                                                style={{ width: '30rem' }}
                                            >
                                                <SelectInput
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                    onSelectInput={
                                                        inputsSelectOption
                                                    }
                                                    onKeyPress={inputsKeyPress}
                                                    onKeyDown={inputsKeyDown}
                                                    newLabelType
                                                    onToggleDropdown={
                                                        inputsToggleDropdown
                                                    }
                                                    hideOptionsAux
                                                    {...dynamicProps}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '30rem' }}
                                        >
                                            <SelectInput
                                                onChangeInput={inputsSetValue}
                                                onSelectInput={
                                                    inputsSelectOption
                                                }
                                                onKeyPress={inputsKeyPress}
                                                onKeyDown={inputsKeyDown}
                                                onToggleDropdown={
                                                    inputsToggleDropdown
                                                }
                                                hideOptionsAux
                                                newLabelType
                                                {...inputs.visibility}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end">
                                <div
                                    className="col-auto px-0"
                                    style={{ marginRight: '2rem' }}
                                >
                                    <TerciaryButton
                                        text={t(`buttons:cancel`)}
                                        onClick={onToggleModal}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t(`buttons:next`)}
                                        onClick={() => setCurrentStep(1)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {currentStep === 1 && (
                        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{
                                    margin: '5rem 0'
                                }}
                            >
                                <div className="col px-0">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={
                                                    styles[
                                                        'create-goal-modal-container__title'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'modals:createGoalModal.timePeriod'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <TimePeriodInput
                                                startDate={inputs.startDate}
                                                endDate={inputs.endDate}
                                                onChangeInput={inputsSetValue}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end">
                                <div
                                    className="col-auto px-0"
                                    style={{ marginRight: '2rem' }}
                                >
                                    <TerciaryButton
                                        text={t(`buttons:back`)}
                                        onClick={() => setCurrentStep(0)}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t(`buttons:next`)}
                                        onClick={() => setCurrentStep(2)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="row mx-0 w-100 flex-grow-1 d-flex justify-content-center align-items-center"
                                style={{
                                    margin: '5rem 0'
                                }}
                            >
                                <div className="col-auto px-0">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={
                                                    styles[
                                                        'create-goal-modal-container__title'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'modals:createGoalModal.measure'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <SelectLabelInput
                                                newLabelType
                                                {...inputs.targetCategory}
                                                onSelectInput={
                                                    inputsSelectOption
                                                }
                                                onChangeInput={inputsSetValue}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <SelectInput
                                                newLabelType
                                                {...inputs.businessIndicator}
                                                onChangeInput={inputsSetValue}
                                                onSelectInput={
                                                    inputsSelectOption
                                                }
                                                onToggleDropdown={
                                                    inputsToggleDropdown
                                                }
                                            />
                                        </div>
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '2rem' }}
                                        ></div>
                                        <div className="col px-0">
                                            <TypeInput
                                                newLabelType
                                                {...inputs.targetValue}
                                                onChangeInput={inputsSetValue}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col-auto px-0">
                                            <ToggleButton
                                                bool={reminder}
                                                onClick={toggleReminder}
                                            />
                                        </div>
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '1rem' }}
                                        ></div>
                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={
                                                    styles[
                                                        'create-goal-modal-container__reminder'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'modals:createGoalModal.reminder'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col-auto px-0">
                                            <ToggleButton
                                                bool={highImpactGoal}
                                                onClick={toggleHighImpactGoal}
                                            />
                                        </div>
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '1rem' }}
                                        ></div>
                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                            <img
                                                src={
                                                    highImpactGoal
                                                        ? icoHighImpactGoal
                                                        : icoHighImpactGoalWhite
                                                }
                                                alt="High Impact Goal"
                                                style={{
                                                    width: '2.4rem',
                                                    height: '2.4rem'
                                                }}
                                            />
                                            <span
                                                className={
                                                    styles[
                                                        'create-goal-modal-container__reminder'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'modals:createGoalModal.highImpactGoal'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end">
                                <div
                                    className="col-auto px-0"
                                    style={{ marginRight: '2rem' }}
                                >
                                    <TerciaryButton
                                        text={t(`buttons:back`)}
                                        onClick={() => setCurrentStep(1)}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t(`buttons:next`)}
                                        onClick={() => setCurrentStep(3)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {currentStep === 3 && (
                        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="row mx-0 w-100 flex-grow-1 d-flex justify-content-center align-items-center"
                                style={{
                                    margin: '5rem 0'
                                }}
                            >
                                <div className="col-auto px-0">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-start align-items-center">
                                            <span
                                                style={{
                                                    fontFamily:
                                                        'OpenSansSemiBold',
                                                    fontSize: '1.6rem',
                                                    color: '#4c4c4c',
                                                    marginRight: '1rem'
                                                }}
                                            >
                                                {t(
                                                    'modals:createGoalModal.businessDimension'
                                                )}
                                            </span>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        'OpenSansSemiBold',
                                                    fontSize: '1.6rem',
                                                    color: '#d6d6d6'
                                                }}
                                            >
                                                ({t('common:optional')})
                                            </span>
                                        </div>
                                    </div>
                                    {Object.keys(inputs).filter((key) =>
                                        key.startsWith(
                                            'businessDimensionCategories'
                                        )
                                    ).length > 0 &&
                                        Object.keys(inputs)
                                            .filter((key) =>
                                                key.startsWith(
                                                    'businessDimensionCategories'
                                                )
                                            )
                                            .map((key) => {
                                                return (
                                                    <div
                                                        key={key}
                                                        className="row mx-0 w-100"
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <SelectInput
                                                                hideLabel
                                                                {...inputs[key]}
                                                                onChangeInput={
                                                                    inputsSetValue
                                                                }
                                                                onSelectInput={
                                                                    inputsSelectOption
                                                                }
                                                                onToggleDropdown={
                                                                    inputsToggleDropdown
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-auto px-0"
                                                            style={{
                                                                width: '2rem'
                                                            }}
                                                        ></div>
                                                        <div className="col px-0">
                                                            <SelectMultipleInput
                                                                hideLabel
                                                                {...inputs[
                                                                    'values' +
                                                                        key
                                                                ]}
                                                                onChangeInput={
                                                                    inputsSetValue
                                                                }
                                                                onSelectInput={
                                                                    inputsSelectOption
                                                                }
                                                                onToggleDropdown={
                                                                    inputsToggleDropdown
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    <div className="row mx-0 w-100">
                                        <div
                                            className="col-auto px-0 d-flex justify-content-start align-items-center"
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleAddBusinessDimension}
                                        >
                                            <img
                                                src={icoPlusDimension}
                                                alt="Add Business Dimension"
                                                style={{
                                                    width: '2rem',
                                                    height: '2rem',
                                                    marginRight: '1rem'
                                                }}
                                            />
                                            <span
                                                style={{
                                                    fontFamily:
                                                        'OpenSansSemiBold',
                                                    fontSize: '1.4rem',
                                                    color: '#2c3c8d'
                                                }}
                                            >
                                                {t(
                                                    'modals:createGoalModal.addBusinessDimension'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end">
                                <div
                                    className="col-auto px-0"
                                    style={{ marginRight: '2rem' }}
                                >
                                    <TerciaryButton
                                        text={t(`buttons:back`)}
                                        onClick={() => setCurrentStep(2)}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t(`buttons:next`)}
                                        onClick={() => setCurrentStep(4)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {currentStep === 4 && (
                        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{
                                    margin: '5rem 0'
                                }}
                            >
                                <div className="col px-0">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={
                                                    styles[
                                                        'create-goal-modal-container__title'
                                                    ]
                                                }
                                            >
                                                {t(
                                                    'modals:createGoalModal.approvalRequest'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                        <div className="col-auto px-0">
                                            <RadioInput
                                                hideLabel
                                                onClickRatioOption={
                                                    inputsClickRadioOption
                                                }
                                                {...inputs.approvalRequest}
                                            />
                                        </div>
                                    </div>
                                    {inputs.approvalRequest
                                        .selectedOptionAux === 'yes' && (
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                            style={{ marginTop: '2rem' }}
                                        >
                                            <div className="col-auto px-0">
                                                <SelectInput
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                    onSelectInput={
                                                        inputsSelectOption
                                                    }
                                                    onKeyPress={inputsKeyPress}
                                                    onKeyDown={inputsKeyDown}
                                                    onToggleDropdown={
                                                        inputsToggleDropdown
                                                    }
                                                    hideOptionsAux
                                                    newLabelType
                                                    {...inputs.approvalRequestOwner}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-end">
                                <div
                                    className="col-auto px-0"
                                    style={{ marginRight: '2rem' }}
                                >
                                    <TerciaryButton
                                        text={t(`buttons:back`)}
                                        onClick={() => setCurrentStep(2)}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t(`buttons:next`)}
                                        onClick={() => toggleSummary()}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </StepByStepModal>
            )}

            {summary && (
                <GoalSummaryModal
                    onCloseModal={onToggleModal}
                    isCreatingGoal
                    goBackText={t(`buttons:back`)}
                    onSaveText={
                        inputs.approvalRequest.selectedOptionAux === 'yes'
                            ? t(`buttons:submitForApproval`)
                            : t(`buttons:publishGoal`)
                    }
                    goBackHandler={toggleSummary}
                    onSaveHandler={handleCreateGoal}
                />
            )}
        </>
    );
};
