import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import styles from './marker-map.module.scss';
import ReactMapGL, {
    Popup,
    Source,
    Layer,
    NavigationControl
} from 'react-map-gl'; //Marker,
import mapboxgl from 'mapbox-gl';
import { useToggle } from '../../../hooks/useToggle';
import { TooltipFactory } from '../../';
import { useTranslation } from 'react-i18next';
import useCursorTracking from '../../../hooks/useCursorTracking';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

mapboxgl.workerClass =
    // @ts-ignore
    // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const MarkerMap = React.memo(
    ({
        dataList,
        tooltipType,
        startingPosition,
        draggingDisabled,
        hoveringDisabled,
        zoomDisabled,
        reference,
        customScale,
        hideTooltip,
        isRound
    }) => {
        const { position } = useCursorTracking();
        const { t } = useCustomTranslation();
        const mapRef = useMemo(() => {
            if (reference) {
                return reference;
            } else {
                return React.createRef();
            }
        }, [reference]);
        const [showPopup, toggleShowPopup] = useToggle(false);
        const [selectedEl, setSelectedEl] = React.useState({});

        const layerList = [];
        if (dataList && dataList.length !== 0) {
            dataList.map((el) => {
                if (_.has(el, 'styleSmall')) {
                    layerList.push(el.styleSmall.id);
                }
                if (_.has(el, 'styleLarge')) {
                    layerList.push(el.styleLarge.id);
                } else layerList.push(el.id);
                return el;
            });
        }

        const getCursor = useCallback(() => {
            return !draggingDisabled
                ? 'grabbing'
                : !hoveringDisabled
                ? 'pointer'
                : 'default';
        }, [draggingDisabled, hoveringDisabled]);

        const handleLoad = useCallback(() => {
            if (mapRef && mapRef.current) {
                const layers = [];

                if (dataList && dataList.length !== 0) {
                    dataList.map((el) => {
                        if (_.has(el, 'styleSmall')) {
                            layers.push(el.styleSmall.id);
                        }
                        if (_.has(el, 'styleLarge')) {
                            layers.push(el.styleLarge.id);
                        } else layers.push(el.id);
                        return el;
                    });
                }
                // mapRef.current.on('click', (e) => {
                //     let features = mapRef.current.queryRenderedFeatures(
                //         e.point,
                //         {
                //             layers: layers,
                //         }
                //     )
                //     if (!features.length) {
                //         return
                //     }
                //     let feature = features[0]
                //     setSelectedEl(feature.properties)
                // })
                mapRef.current.on('mousemove', (e) => {
                    let features = mapRef.current.queryRenderedFeatures(
                        e.point,
                        {
                            layers: layers
                        }
                    );
                    if (!features.length) {
                        toggleShowPopup(false);
                        setSelectedEl({});
                        return;
                    }
                    let feature = features[0];
                    toggleShowPopup(true);
                    if (_.isEmpty(selectedEl)) {
                        setSelectedEl({
                            ...feature.properties
                        });
                    }
                });
                mapRef.current.on('mouseout', (e) => {
                    toggleShowPopup(false);
                    setSelectedEl({});
                    return;
                });
            }
        }, [dataList, toggleShowPopup, mapRef, selectedEl]);

        return (
            <div
                className={styles['marker-map-container']}
                data-testid={'hospital-map'}
                style={isRound ? { borderRadius: '0.6rem' } : {}}
            >
                <ReactMapGL
                    onRender={(event) => event.target.resize()}
                    dragPan={!draggingDisabled}
                    ref={mapRef}
                    minZoom={1}
                    interactiveLayerIds={layerList}
                    maxZoom={18}
                    attributionControl={false}
                    className={styles['marker-map-container__map']}
                    mapStyle="mapbox://styles/rvelhinho/cko63h5332slv17muc66hozni"
                    mapboxAccessToken={
                        process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
                    }
                    cursor={getCursor()}
                    scrollZoom={false}
                    doubleClickZoom={!zoomDisabled}
                    initialViewState={{
                        longitude: startingPosition
                            ? startingPosition.lon
                            : -9.140459,
                        latitude: startingPosition
                            ? startingPosition.lat
                            : 38.712458,
                        zoom: startingPosition ? startingPosition.zoom : 10
                    }}
                    onLoad={!hideTooltip ? handleLoad : undefined}
                >
                    {dataList &&
                        dataList.length !== 0 &&
                        dataList.map((el) => {
                            return (
                                <Source
                                    key={el.id}
                                    id={el.id}
                                    type="geojson"
                                    data={el.data}
                                >
                                    {_.has(el, 'styleSmall') &&
                                        _.has(el, 'styleLarge') && (
                                            <>
                                                <Layer
                                                    {...el.styleLarge}
                                                    source={el.id}
                                                    id={el.styleLarge.id}
                                                />
                                                <Layer
                                                    {...el.styleSmall}
                                                    source={el.id}
                                                    id={el.styleSmall.id}
                                                />
                                            </>
                                        )}

                                    {!_.has(el, 'styleSmall') &&
                                        !_.has(el, 'styleLarge') && (
                                            <Layer
                                                type={
                                                    _.has(el, 'paintType') &&
                                                    el.paintType
                                                        ? el.paintType
                                                        : 'fill'
                                                }
                                                id={el.id}
                                                paint={
                                                    _.has(el, 'paint') &&
                                                    el.paint
                                                        ? el.paint
                                                        : {}
                                                }
                                            ></Layer>
                                        )}
                                </Source>
                            );
                        })}
                    {!zoomDisabled && (
                        <NavigationControl
                            style={{ left: '10px', top: '10px' }}
                            showCompass={false}
                        />
                    )}
                    {showPopup &&
                        tooltipType &&
                        !hideTooltip &&
                        !_.isEmpty(selectedEl) &&
                        !hoveringDisabled &&
                        selectedEl.color &&
                        position && (
                            // <Popup
                            //     latitude={selectedEl.lat}
                            //     longitude={selectedEl.lon}
                            //     anchor="bottom"
                            //     closeButton={false}
                            //     maxWidth="none"
                            //     offset={30}
                            // >

                            <TooltipFactory
                                item={{
                                    type: tooltipType.split('-')[0] || '',
                                    interval: tooltipType.split('-')[1]
                                        ? tooltipType.split('-')[1]
                                        : 'monthly',

                                    unitsOrValues: undefined,
                                    translate: t || undefined,
                                    loc: {
                                        ...selectedEl
                                    },
                                    customScale: customScale,
                                    color: selectedEl.innerColor
                                }}
                            />
                            // </Popup>
                        )}
                </ReactMapGL>
            </div>
        );
    }
);
