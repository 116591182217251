import { useState, useCallback, useEffect, useRef } from 'react';

export const useDropdownKeyboardNavigation = (
    options,
    openDropdown,
    onSelect,
    dropdownRef
) => {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const optionRefs = useRef([]);

    const updateScrollPosition = (index) => {
        const focusedOption = optionRefs.current[index];
        if (focusedOption && dropdownRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const optionRect = focusedOption.getBoundingClientRect();

            if (optionRect.bottom > dropdownRect.bottom) {
                dropdownRef.current.scrollTop +=
                    optionRect.bottom - dropdownRect.bottom;
            } else if (optionRect.top < dropdownRect.top) {
                dropdownRef.current.scrollTop -=
                    dropdownRect.top - optionRect.top;
            }
        }
    };

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'ArrowDown') {
                setFocusedIndex((prevIndex) => {
                    const newIndex =
                        prevIndex < options.length - 1
                            ? prevIndex + 1
                            : prevIndex;
                    updateScrollPosition(newIndex);
                    return newIndex;
                });
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                setFocusedIndex((prevIndex) => {
                    const newIndex = prevIndex > 0 ? prevIndex - 1 : 0;
                    updateScrollPosition(newIndex);
                    return newIndex;
                });
                e.preventDefault();
            } else if (e.key === 'Enter' && focusedIndex >= 0) {
                onSelect(focusedIndex);
                setFocusedIndex(-1);
            }
        },
        [options.length, focusedIndex, onSelect, updateScrollPosition]
    );

    useEffect(() => {
        if (openDropdown) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
            setFocusedIndex(-1); // Reset the focus index when dropdown is closed
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [openDropdown, handleKeyDown]);

    // Clear and rebuild refs array on options change
    useEffect(() => {
        optionRefs.current = optionRefs.current.slice(0, options.length);
    }, [options.length]);

    return { focusedIndex, optionRefs };
};
