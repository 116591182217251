import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { sentimentChannelOverviewMock } from './mock';
import { useTable } from '../../../hooks/useTable';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import icoEmail from '../../../assets/images/ico-email.svg';
import icoText from '../../../assets/images/ico-text.svg';
import icoMedia from '../../../assets/images/ico-media.svg';
import icoCalls from '../../../assets/images/ico-calls.svg';
import _ from 'lodash';
import { errorHandler } from '../../../utils/api';
import { SentimentService } from '../../../temp/test';
import { verifyComponent } from '../../../utils/component';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SentimentChannelOverview = React.memo(({ analysisId }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal] = useToggle(false);
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue
    } = useTable(sentimentChannelOverviewMock, modal, 'client');

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(sentimentChannelOverviewMock);
            try {
                if (isMounted.current) setLoading(true);
                const result =
                    await SentimentService.getApiNlpAnalysisChannelOverview({
                        id: analysisId
                    });
                dataClone.data.length = 0;
                dataClone.sortedData.length = 0;
                Object.entries(result).map((el) => {
                    let totalSentiment = 0;
                    let sentimentCounter = {
                        positive: 0,
                        neutral: 0,
                        negative: 0
                    };
                    Object.entries(el[1].sentiment_distribution).map((el1) => {
                        sentimentCounter[el1[0]] = el1[1];
                        totalSentiment += el1[1];
                    });
                    let icon = null;
                    if (el[0] === 'text_sentiment') {
                        icon = icoText;
                    } else if (el[0] === 'email_sentiment') {
                        icon = icoEmail;
                    } else if (el[0] === 'call_sentiment') {
                        icon = icoCalls;
                    } else if (el[0] === 'media_sentiment') {
                        icon = icoMedia;
                    }

                    dataClone.data.push({
                        sentimentChannel: {
                            label: t(`components:${data.attr}.${el[0]}`),
                            icon: icon
                        },
                        total: el[1].count,
                        customers: el[1].unique_clients,
                        keywords: el[1].keyword_count,
                        sentimentPositive: (
                            (sentimentCounter.positive / totalSentiment) *
                            100
                        ).toFixed(0),
                        sentimentNeutral: (
                            (sentimentCounter.neutral / totalSentiment) *
                            100
                        ).toFixed(0),
                        sentimentNegative: (
                            (sentimentCounter.negative / totalSentiment) *
                            100
                        ).toFixed(0),
                        trend: (el[1].avg_score_change * 100).toFixed(1)
                    });
                });
                dataClone.sortedData = _.orderBy(
                    dataClone.data,
                    [dataClone.sortColumn.path],
                    [dataClone.sortColumn.order]
                );
                dataClone.loading = false;
                verifyComponent(dataClone);
                if (isMounted.current) setValue(dataClone);
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [isMounted, setLoading, setValue, t, analysisId, data.attr]);

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <Table
                        actions={data.actions}
                        // onClickActionIcon={onClickActionIcon}
                        // onClickActionOption={onClickActionOption}
                        // onCloseActions={onCloseActions}
                        attr={data.attr}
                        type={data.type}
                        titleCounter={data.titleCounter}
                        titleText={t(`components:${data.attr}.title`)}
                        placeholderText={t(`components:${data.attr}.nodata`)}
                        columns={data.columns}
                        data={data.sortedData}
                        sortColumn={data.sortColumn}
                        onSort={dataSort}
                        currentPage={data.currentPage}
                        pageSize={data.pageSize}
                        onPageChange={dataPageChange}
                        onRowClick={undefined}
                        onRowSelect={undefined}
                        onSelectAll={dataSelectAll}
                        onUnselectAll={dataUnselectAll}
                        paginationDisabled={data.paginationDisabled}
                        disabled={data.disabled}
                    ></Table>
                )}
            </ContentContainer>
        </>
    );
});

SentimentChannelOverview.propTypes = {};
