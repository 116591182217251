import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { TextAlign } from '@tiptap/extension-text-align';

// import icoHeader from '../../../../../assets/images/icon-header.svg';
// import icoClearEditor from '../../../../../assets/images/icon-clear-editor.svg';
// import icoAlignTextLeft from '../../../../../assets/images/icon-align-text-left.svg';
// import icoAlignTextRight from '../../../../../assets/images/icon-align-text-right.svg';
// import icoAlignTextCenter from '../../../../../assets/images/icon-align-text-center.svg';
// import icoAlignTextJustified from '../../../../../assets/images/icon-align-text-justified.svg';
// import icoArrowEditor from '../../../../../assets/images/ico-arrow-editor.svg';
// import icoItalic from '../../../../../assets/images/icon-italic.svg';
// import icoBold from '../../../../../assets/images/icon-bold.svg';

import React, { useCallback, useEffect, useRef } from 'react';

import styles from './report-editor.module.scss';

export const ReportEditor = React.memo(
  ({
    isExample,
    isFilterConfig,
    content,
    onChangeContent,
    identifier,
    width,
    height,
    onChangeSize,
  }) => {
    const ref = useRef(null);
    const editor = useEditor({
      extensions: [
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        StarterKit.configure({
          levels: [1, 3, 6],
        }),
      ],
      editorProps: {
        attributes: {
          class: `${styles['report-editor-container__input-container']}`,
        },
      },
      onUpdate({ editor }) {
        handleSetChangeContentTimeout(editor.getJSON());
      },
      content: content
        ? {
            ...content,
          }
        : {
            type: 'doc',
            content: [
              // …
            ],
          },
    });

    const handleSetChangeContentTimeout = useCallback(
      (json, width, height) => {
        onChangeContent(json, identifier);
        if (ref.current && width && height) {
          onChangeSize(
            (ref.current.offsetWidth * 12) / width,
            (ref.current.offsetHeight * 12) / height,
            identifier
          );
        }
      },
      [onChangeContent, identifier, onChangeSize]
    );

    useEffect(() => {
      if (editor) {
        editor.on('update', ({ editor }) => {
          handleSetChangeContentTimeout(editor.getJSON(), width, height);
        });
      }
    }, [editor, handleSetChangeContentTimeout, width, height]);

    if (editor) {
      return (
        <div className={`${styles['report-editor-container']}`} ref={ref}>
          {
            // <div
            //   className='row mx-0 w-100'
            //   style={!isFilterConfig ? { opacity: 0 } : {}}
            // >
            //   <div className='col px-0'>
            //     <button
            //       className={
            //         editor.isActive('undo')
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={() => {
            //         editor.chain().focus().undo().run();
            //       }}
            //     >
            //       <img
            //         src={icoArrowEditor}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       className={
            //         editor.isActive('redo')
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={() => {
            //         editor.chain().focus().redo().run();
            //       }}
            //     >
            //       <img
            //         src={icoArrowEditor}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']} ${styles['report-editor-container__icon-container__icon--reverse']}`}
            //       />
            //     </button>
            //     <button
            //       onClick={() => {
            //         editor.chain().focus().toggleBold().run();
            //       }}
            //       className={
            //         editor.isActive('bold')
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //     >
            //       <img
            //         src={icoBold}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       onClick={() => {
            //         editor.chain().focus().toggleItalic().run();
            //       }}
            //       className={
            //         editor.isActive('italic')
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //     >
            //       <img
            //         src={icoItalic}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       className={
            //         editor.isActive({ textAlign: 'left' })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={() => {
            //         editor.commands.setTextAlign('left');
            //       }}
            //     >
            //       <img
            //         src={icoAlignTextLeft}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       className={
            //         editor.isActive({ textAlign: 'center' })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={() => {
            //         editor.commands.setTextAlign('center');
            //       }}
            //     >
            //       <img
            //         src={icoAlignTextCenter}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       className={
            //         editor.isActive({ textAlign: 'right' })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={() => {
            //         editor.commands.setTextAlign('right');
            //       }}
            //     >
            //       <img
            //         src={icoAlignTextRight}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       className={
            //         editor.isActive({ textAlign: 'justify' })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={() => {
            //         editor.commands.setTextAlign('justify');
            //       }}
            //     >
            //       <img
            //         src={icoAlignTextJustified}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //     <button
            //       className={
            //         editor.isActive('heading', { level: 1 })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={
            //         editor.isActive('heading', { level: 1 })
            //           ? () => {
            //               editor.chain().focus().clearNodes().run();
            //             }
            //           : () => {
            //               editor.commands.setHeading({ level: 1 });
            //             }
            //       }
            //     >
            //       <img
            //         src={icoHeader}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //       <span
            //         className={`${styles['report-editor-container__icon-container__icon-secondary']}`}
            //       >
            //         1
            //       </span>
            //     </button>
            //     <button
            //       className={
            //         editor.isActive('heading', { level: 3 })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={
            //         editor.isActive('heading', { level: 3 })
            //           ? () => {
            //               editor.chain().focus().clearNodes().run();
            //             }
            //           : () => {
            //               editor.commands.setHeading({ level: 3 });
            //             }
            //       }
            //     >
            //       <img
            //         src={icoHeader}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //       <span
            //         className={`${styles['report-editor-container__icon-container__icon-secondary']}`}
            //       >
            //         2
            //       </span>
            //     </button>
            //     <button
            //       className={
            //         editor.isActive('heading', { level: 6 })
            //           ? `${styles['report-editor-container__icon-container']} ${styles['report-editor-container__icon-container--is-active']}`
            //           : `${styles['report-editor-container__icon-container']}`
            //       }
            //       onClick={
            //         editor.isActive('heading', { level: 6 })
            //           ? () => {
            //               editor.chain().focus().clearNodes().run();
            //             }
            //           : () => {
            //               editor.commands.setHeading({ level: 6 });
            //             }
            //       }
            //     >
            //       <img
            //         src={icoHeader}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //       <span
            //         className={`${styles['report-editor-container__icon-container__icon-secondary']}`}
            //       >
            //         3
            //       </span>
            //     </button>
            //     <button
            //       className={`${styles['report-editor-container__icon-container']}`}
            //       onClick={() => {
            //         editor.chain().focus().clearNodes().run();
            //       }}
            //     >
            //       <img
            //         src={icoClearEditor}
            //         alt=''
            //         className={`${styles['report-editor-container__icon-container__icon']}`}
            //       />
            //     </button>
            //   </div>
            // </div>
          }
          <div className='row mx-0 w-100 flex-grow-1'>
            <div className='col px-0'>
              <div
                className={
                  isExample || isFilterConfig
                    ? `${styles['report-editor-container__editor-container']} ${styles['customizable']}`
                    : `${styles['report-editor-container__editor-container']}`
                }
              >
                <EditorContent editor={editor} />
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
);
