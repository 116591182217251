import _ from 'lodash'
import React, { useEffect } from 'react'

import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { SalesService, ForecastService } from '../../../temp/test'
import { unitSalesForecastMock } from './mock'
import { errorHandler } from '../../../utils/api.js'
import { useFilter } from '../../../providers/filter-context'
import { getFilterParam } from '../../../utils/params'
import { ComponentWrapper, DynamicComposedChartContainer } from '../../'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { fromBackTimeToFront } from '../../../utils/timeseries'
import { useSalesChannel } from '../../../providers/sales-channel-context'

/**
 * Displays the evolution of unit sales over time with forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer)

export const UnitSalesForecast = React.memo(({ component, isPreview, isExample, config, ...rest }) => {
    const { filter } = useFilter()
    const isMounted = useIsMounted()
    const { timeInterval } = useTimeInterval()
    const { channels } = useSalesChannel()
    const { t } = useCustomTranslation()

    const { value: data, setLoading, setValue } = useSimpleComponent(unitSalesForecastMock)

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(unitSalesForecastMock)
            try {
                if (!isExample) {
                    setLoading(true)
                    dataClone.data.length = 0
                    const forecastSales = await ForecastService.getApiForecast({
                        interval: timeInterval,
                        productIds: getFilterParam(filter, config, ['productId', 'productIds']),
                        teamIds: getFilterParam(filter, config, ['teamId', 'teamIds']),
                        regionIds: getFilterParam(filter, config, ['regionId', 'regionIds']),
                        channelIds: JSON.parse(channels),
                        sumBy: 'units',
                    })
                    const dataSales = await SalesService.getApiSales({
                        producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                        interval: timeInterval,
                        clientId: getFilterParam(filter, config, ['clientId', 'clientIds']),
                        vendorId: getFilterParam(filter, config, ['vendorId', 'vendorIds']),
                        locationId: [],
                        productId: getFilterParam(filter, config, ['productId', 'productIds']),
                        teamId: getFilterParam(filter, config, ['teamId', 'teamIds']),
                        regionId: getFilterParam(filter, config, ['regionId', 'regionIds']),
                        channelId: JSON.parse(channels),
                        sumBy: 'units',
                    })
                    dataClone.categoryAxis = timeInterval
                    fromBackTimeToFront(Object.entries(dataSales), dataClone.data, [{ back: 1, front: 'unitsSold' }], timeInterval, '0')

                    fromBackTimeToFront(
                        [Object.entries(dataSales)[Object.entries(dataSales).length - 1]],
                        dataClone.data,
                        [
                            {
                                back: [1, 1],
                                front: 'unitsSoldForecastIntervalValue',
                                isArray: true,
                            },
                            {
                                back: 1,
                                front: 'unitsSoldForecastValue',
                            },
                            {
                                back: 1,
                                front: 'forecastGradient',
                            },
                        ],
                        timeInterval,
                        '0'
                    )
                    forecastSales.map((item) => {
                        item.forecast_lower = item.forecast_lower + (item.forecast - item.forecast_lower) * 0.2
                        item.forecast_upper = item.forecast_upper - (item.forecast_upper - item.forecast) * 0.2
                    })
                    fromBackTimeToFront(
                        forecastSales,
                        dataClone.data,
                        [
                            {
                                back: 'forecast_lower',
                                front: 'forecastGradient',
                            },
                            {
                                back: 'forecast',
                                front: 'unitsSoldForecastValue',
                            },
                            {
                                back: ['forecast_lower', 'forecast_upper'],
                                front: 'unitsSoldForecastIntervalValue',
                                isArray: true,
                            },
                        ],
                        timeInterval,
                        'forecast_date'
                    )
                    dataClone.legend = [{ name: 'unitsSold', color: '#8c54ff' }]
                    dataClone.loading = false
                    setValue(dataClone)
                } else {
                    setValue(dataClone)
                    setLoading(false)
                }
            } catch (err) {
                errorHandler(err)
                dataClone.loading = false
                if (isMounted.current) {
                    setValue(dataClone)
                }
            }
        }
        fill()
    }, [config, filter, isExample, isMounted, setLoading, setValue, t, timeInterval, isPreview, channels])

    return (
        <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper withTimeFilter withChannelFilter isForecast component={component} {...data} {...rest}>
                <Chart {...data} forecastValue="unitsSoldForecastValue" forecastIntervalValue="unitsSoldForecastIntervalValue" />
            </ComponentWrapper>
        </div>
    )
})

UnitSalesForecast.propTypes = {}
