import styles from './auxbar-bookmark-information.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import icoForecasts from '../../../assets/images/ico-side.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const AuxbarBookmarkInformation = React.memo(() => {
    const { t } = useCustomTranslation()
    return (
        <React.Fragment>
            <div className="row w-100 m-0 mb-4">
                <div className="col p-0">
                    <div
                        className={`row ${styles['auxbar-bookmark-information__nav']}`}
                    ></div>
                </div>
            </div>
            <div className="row w-100 m-0 h-100">
                <div
                    className={`col h-100 p-0 d-flex flex-column justify-content-center align-items-center ${styles['auxbar-bookmark-information__nav__content']}`}
                >
                    <img
                        loading="lazy"
                        src={icoForecasts}
                        alt="statistics icon"
                    />
                    <span>{t('layout:auxbar.nodatafound')}</span>
                </div>
            </div>
        </React.Fragment>
    )
})

AuxbarBookmarkInformation.propTypes = {}
