import styles from './competitor-information.module.scss';

import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { DTablesService, ProspectsService } from '../../../temp/test';
import componentConfig from '../../../configs/componentLibraryConf.json';
import { competitorInformationMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import {
    ContentContainer,
    DeleteButton,
    Loader,
    PrimaryButton,
    Spinner
} from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays general information about a competitor
 */

export const CompetitorInformation = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(competitorInformationMock);

        const handleClickZomatoButton = () => {
            window.open(data.zomatoUrl, '_blank');
            return;
        };

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(competitorInformationMock);
                try {
                    if (!isExample) {
                        if (type) dataClone.attr = type;
                        let prospectByIdData;
                        if (
                            filter &&
                            filter.competitorId.constructor === String &&
                            filter.competitorId !== '' &&
                            config.filter.includes('competitorId')
                        ) {
                            if (isMounted.current) {
                                setLoading(true);
                            }
                            prospectByIdData =
                                await ProspectsService.getApiCompetitors1({
                                    competitorId: filter.competitorId
                                });
                            if (prospectByIdData) {
                                dataClone.name = prospectByIdData.competitor
                                    .name
                                    ? prospectByIdData.competitor.name
                                    : '';
                                // dataClone.nameAux = prospectByIdData.competitor
                                //     .name
                                //     ? prospectByIdData.competitor.name
                                //     : '-'
                                dataClone.image = prospectByIdData.imgUrl
                                    ? prospectByIdData.imgUrl
                                    : '-';
                                dataClone.address = prospectByIdData.competitor
                                    .name
                                    ? prospectByIdData.competitor.name
                                    : '-';
                                dataClone.reviewCounter = prospectByIdData.zInfo
                                    .rating_votes
                                    ? prospectByIdData.zInfo.rating_votes
                                    : '';
                                dataClone.reviewRate = prospectByIdData.zInfo
                                    .rating
                                    ? parseFloat(prospectByIdData.zInfo.rating)
                                    : '';
                                // dataClone.segment = prospectByIdData.competitor.amenity
                                // 	? prospectByIdData.competitor.amenity
                                // 	: '-';
                                dataClone.regionId = prospectByIdData.competitor
                                    .id
                                    ? prospectByIdData.competitor.id
                                    : '-';
                                dataClone.location = prospectByIdData.competitor
                                    .addr_city
                                    ? prospectByIdData.competitor.addr_city
                                    : '-';
                                dataClone.phone = prospectByIdData.competitor
                                    .phone
                                    ? prospectByIdData.competitor.phone
                                    : '-';
                                dataClone.website = prospectByIdData.competitor
                                    .website
                                    ? prospectByIdData.competitor.website
                                    : '-';
                                dataClone.openingHours = prospectByIdData
                                    .competitor.opening_hours
                                    ? prospectByIdData.competitor.opening_hours
                                    : '';
                                dataClone.closed = '-';
                                // dataClone.cuisine = prospectByIdData.competitor.cuisine
                                // 	? prospectByIdData.competitor.cuisine
                                // 	: '-';
                                // dataClone.peopleCapacity = prospectByIdData.competitor.capacity
                                // 	? prospectByIdData.competitor.capacity
                                // 	: '-';
                                // dataClone.terrace = prospectByIdData.competitor.outdoor_seating
                                // 	? prospectByIdData.competitor.outdoor_seating
                                // 	: '-';
                                // dataClone.smokingArea = prospectByIdData.competitor.smoking
                                // 	? prospectByIdData.competitor.smoking
                                // 	: '-';
                                // dataClone.takeAway = prospectByIdData.competitor.takeaway
                                // 	? prospectByIdData.competitor.takeaway
                                // 	: '-';
                                dataClone.homeDelivery = false;
                                dataClone.openNow = prospectByIdData.zInfo
                                    .open_now
                                    ? prospectByIdData.zInfo.open_now
                                    : '';
                                dataClone.timings = prospectByIdData.zInfo
                                    .timings
                                    ? prospectByIdData.zInfo.timings
                                    : '-';
                                dataClone.avgCostForTwo = prospectByIdData.zInfo
                                    .average_cost_for_two
                                    ? '€' +
                                      prospectByIdData.zInfo
                                          .average_cost_for_two
                                    : '-';
                                dataClone.keywords = prospectByIdData.zInfo
                                    .highlights
                                    ? prospectByIdData.zInfo.highlights
                                    : [];
                                if (prospectByIdData.zInfo.zomato_url) {
                                    dataClone.zomatoUrl =
                                        prospectByIdData.zInfo.zomato_url;
                                    dataClone.zomatoUrlDisabled = false;
                                } else {
                                    dataClone.zomatoUrlDisabled = true;
                                }
                                dataClone.open = true;
                                dataClone.loading = false;
                                dataClone.noData = false;
                            } else {
                                dataClone.noData = true;
                                dataClone.loading = false;
                            }

                            if (
                                dataClone.settings.view.options.length === 0 &&
                                dataClone.settings.options.options.length === 0
                            )
                                dataClone.disabled = true;
                            else dataClone.disabled = false;
                            verifyComponent(dataClone);
                            if (isMounted.current) {
                                setValue(dataClone);
                                setLoading(false);
                            }
                            let additionalData = [];
                            if (
                                config.additionalInfo &&
                                config.additionalInfo.length !== 0
                            ) {
                                additionalData =
                                    await DTablesService.getApiBackofficeAdminDtablesColumns(
                                        {
                                            columns: config.additionalInfo,
                                            ids:
                                                filter &&
                                                filter.competitorId
                                                    .constructor === String &&
                                                filter.competitorId !== '' &&
                                                config.filter.includes(
                                                    'competitorId'
                                                )
                                                    ? [filter.competitorId]
                                                    : []
                                        }
                                    );
                            }
                            if (additionalData.length !== 0) {
                                config.additionalInfo.map((el2) => {
                                    dataClone.customInfo.push(el2);
                                    dataClone[el2] = additionalData[0][el2];
                                    return el2;
                                });
                            }
                            if (isMounted.current) setValue(dataClone);
                        } else {
                            dataClone.noData = true;
                            if (isMounted.current) setValue(dataClone);
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            type,
            t
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        let reviewHelperFull, halfFullCircle, reviewHelperEmpty;
        if (
            _.has(data, 'open') &&
            data.open &&
            _.has(data, 'reviewRate') &&
            data.reviewRate !== ''
        ) {
            reviewHelperFull = _.range(1, Math.floor(data.reviewRate) + 1);
            halfFullCircle =
                data.reviewRate - Math.floor(data.reviewRate) > 0
                    ? (data.reviewRate - Math.floor(data.reviewRate)) * 100
                    : false;
            reviewHelperEmpty = _.range(1, 5 - Math.floor(data.reviewRate) + 1);
        }

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`col ${styles['competitor-information-container']}`}
                        >
                            <div className="row mx-0 w-100 h-100">
                                {_.has(data, 'image') &&
                                    data.image &&
                                    !data.noData && (
                                        <a
                                            href={data.image}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            referrerPolicy="no-referrer"
                                            className={`col-2 px-0 ${styles['competitor-information-container__image-container']}`}
                                        >
                                            <img
                                                loading="lazy"
                                                src={data.image}
                                                alt="client"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </a>
                                    )}

                                {data.noData && (
                                    <div
                                        className={`col px-0 h-100 d-flex justify-content-center align-items-center`}
                                    >
                                        <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                            <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={`${styles['no-data-text']}`}
                                                >
                                                    {t(`common:noData`)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!data.noData && (
                                    <div className="col px-0 w-100 h-100">
                                        <div className="row mx-0 w-100 h-100">
                                            <div
                                                className={`col-4 ${styles['competitor-information-container__details-container']} h-100`}
                                            >
                                                <div className="row mx-0 mb-3 w-100 flex-grow-1">
                                                    <div className="col-8 px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__title']}`}
                                                        >
                                                            {_.has(data, 'name')
                                                                ? data.name
                                                                : _.has(
                                                                      data,
                                                                      'nameAux'
                                                                  )
                                                                ? data.nameAux
                                                                : undefined}
                                                        </h1>
                                                        {/* <h1
                                                            className={`${styles['competitor-information-container__details-container__content']} pe-2`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'address'
                                                            )
                                                                ? data.address
                                                                : undefined}
                                                        </h1> */}
                                                    </div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__sub-title']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'reviewCounter'
                                                            ) &&
                                                            data.reviewCounter
                                                                ? data.reviewCounter
                                                                : undefined}{' '}
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.reviews`
                                                            )}
                                                        </h1>
                                                        <div
                                                            className={`${styles['competitor-information-container__details-container__reviews']}`}
                                                        >
                                                            {typeof reviewHelperFull !==
                                                                'object' && (
                                                                <span>-</span>
                                                            )}
                                                            {typeof reviewHelperFull ===
                                                                'object' &&
                                                                reviewHelperFull.map(
                                                                    (
                                                                        el,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={`${styles['competitor-information-container__details-container__reviews__circle']} ${styles['competitor-information-container__details-container__reviews__circle--full']}`}
                                                                            ></div>
                                                                        );
                                                                    }
                                                                )}
                                                            {typeof reviewHelperFull ===
                                                                'boolean' &&
                                                                halfFullCircle && (
                                                                    <div
                                                                        className={`${styles['competitor-information-container__details-container__reviews__circle']} ${styles['competitor-information-container__details-container__reviews__circle--half']}`}
                                                                    >
                                                                        <div
                                                                            className={`${styles['competitor-information-container__details-container__reviews__circle--half__full']}`}
                                                                            style={{
                                                                                width: `${halfFullCircle}%`
                                                                            }}
                                                                        ></div>
                                                                        <div
                                                                            className={`{styles['competitor-information-container__details-container__reviews__circle--full__empty']}`}
                                                                        ></div>
                                                                    </div>
                                                                )}
                                                            {typeof reviewHelperFull ===
                                                                'object' &&
                                                                reviewHelperEmpty.map(
                                                                    (
                                                                        el,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={`${styles['competitor-information-container__details-container__reviews__circle']} ${styles['competitor-information-container__details-container__reviews__circle--empty']}`}
                                                                            ></div>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 mb-3 w-100 flex-grow-1">
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.segment`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'segment'
                                                            )
                                                                ? data.segment
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.regionId`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'regionId'
                                                            )
                                                                ? data.regionId
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.location`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__content']}`}
                                                        >
                                                            {data.location
                                                                ? data.location
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 mb-3 w-100 flex-grow-1">
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.phone`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__content']}`}
                                                        >
                                                            {data.phone
                                                                ? data.phone
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.website`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__content']}`}
                                                        >
                                                            {data.website
                                                                ? data.website
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2 d-flex justify-content-start align-items-start">
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '3rem'
                                                            }}
                                                        >
                                                            <PrimaryButton
                                                                disabled={
                                                                    _.has(
                                                                        data,
                                                                        'zomatoUrlDisabled'
                                                                    )
                                                                        ? data.zomatoUrlDisabled
                                                                        : false
                                                                }
                                                                text={t(
                                                                    `buttons:zomato`
                                                                )}
                                                                onClick={
                                                                    handleClickZomatoButton
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row w-100 mx-0">
                                                    <div className="col-auto px-0 pe-3">
                                                        <h1
                                                            className={`${styles['competitor-information-container__details-container__content']} ${styles['competitor-information-container__details-container__content--open']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'openNow'
                                                            )
                                                                ? data.openNow
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`col ${styles['competitor-information-container__side-details-container']} h-100`}
                                            >
                                                <div
                                                    className="row mx-0 "
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.openingHours`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'openingHours'
                                                            )
                                                                ? data.openingHours
                                                                : _.has(
                                                                      data,
                                                                      'timings'
                                                                  )
                                                                ? data.timings
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.avgCost2`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'avgCostForTwo'
                                                            )
                                                                ? data.avgCostForTwo
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.cuisine`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'cuisine'
                                                            )
                                                                ? data.cuisine
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.capacity`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'peopleCapacity'
                                                            )
                                                                ? data.peopleCapacity
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 "
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.terrace`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'terrace'
                                                            )
                                                                ? data.terrace
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.smokingArea`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'smokingArea'
                                                            )
                                                                ? data.smokingArea
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.takeAway`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'takeAway'
                                                            )
                                                                ? data.takeAway
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                    <div className="col px-2">
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                        >
                                                            {t(
                                                                `components:${
                                                                    _.has(
                                                                        data,
                                                                        'attr'
                                                                    )
                                                                        ? data.attr
                                                                        : 'competitorDetailsCard'
                                                                }.homeDelivery`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['competitor-information-container__side-details-container__content']}`}
                                                        >
                                                            {_.has(
                                                                data,
                                                                'homeDelivery'
                                                            )
                                                                ? data.homeDelivery
                                                                : undefined}
                                                        </h1>
                                                    </div>
                                                </div>
                                                {data.customInfo.length !==
                                                    0 && (
                                                    <div
                                                        className="row mx-0 w-100 "
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        {data.customInfo.map(
                                                            (el, index) => {
                                                                if (
                                                                    index <=
                                                                    componentConfig[
                                                                        data
                                                                            .attr
                                                                    ]
                                                                        .dTableInfoLimit -
                                                                        1
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="col px-0 h-100"
                                                                        >
                                                                            <h1
                                                                                className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                                            >
                                                                                {
                                                                                    el
                                                                                }
                                                                            </h1>
                                                                            <h1
                                                                                className={`${styles['competitor-information-container__side-details-container__subcontent']}`}
                                                                            >
                                                                                {data[
                                                                                    el
                                                                                ]
                                                                                    ? data[
                                                                                          el
                                                                                      ]
                                                                                    : ''}
                                                                            </h1>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                                <div className="row mx-0">
                                                    <div
                                                        className={`col px-0 ${styles['competitor-information-container__side-details-container__tag-container']}`}
                                                    >
                                                        {_.has(
                                                            data,
                                                            'keywords'
                                                        ) &&
                                                            data.keywords
                                                                .length !== 0 &&
                                                            data.keywords
                                                                .slice(0, 10)
                                                                .map(
                                                                    (
                                                                        el,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={`${styles['competitor-information-container__side-details-container__tag-container__tag']}`}
                                                                            >
                                                                                {
                                                                                    el
                                                                                }
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                        {_.has(
                                                            data,
                                                            'keywords'
                                                        ) &&
                                                            data.keywords
                                                                .length >
                                                                10 && (
                                                                <span>
                                                                    {' '}
                                                                    ...
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

CompetitorInformation.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
