import React, { useCallback, useMemo } from 'react';
import styles from './navigation-menu.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const NavigationMenu = React.memo(
    ({
        options,
        selectedOption,
        onChangeOption,
        isAlternativeColor,
        fillSpace,
        translated,
        isPageMenu
    }) => {
        const { t } = useCustomTranslation();
        const customClassName = useCallback(
            (option) => {
                const active =
                    selectedOption === option ? styles['active'] : '';
                const disabled = option === 'api' ? styles['disabled'] : '';
                const alternative = isAlternativeColor
                    ? styles['alternative']
                    : '';
                return `${
                    fillSpace ? 'col-auto' : 'col-auto'
                } px-2 h-auto d-flex justify-content-center align-items-center ${
                    styles['navigation-menu-container__nav-option']
                } ${active} ${disabled} ${alternative}`;
            },
            [isAlternativeColor, selectedOption, fillSpace]
        );
        return (
            <div
                className={
                    isPageMenu
                        ? styles['navigation-menu-container'] +
                          ' ' +
                          styles['page-menu']
                        : styles['navigation-menu-container']
                }
                style={
                    fillSpace
                        ? {
                              justifyContent: 'space-around'
                          }
                        : {}
                }
            >
                {options.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={customClassName(el)}
                            onClick={() => onChangeOption(el)}
                        >
                            <span
                                className={`${styles['navigation-menu-container__nav-option__title']}`}
                            >
                                {translated && el}
                                {!translated &&
                                    t(`components:navigation-menu.${el}.title`)}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }
);
