import _ from 'lodash';
import { useCallback, useReducer } from 'react';

const ACTIONS = {
    ADD_OPTION: 'add',
    TOGGLE_DROPDOWN: 'dropdown',
    CHANGE_ERROR: 'error',
    SELECT_OPTION: 'select',
    UNSELECT_OPTION: 'unselect',
    KEY_PRESS: 'keyPress',
    KEY_DOWN: 'keyDown',
    SET_VALUE: 'value',
    RESET: 'reset',
    REPLACE: 'replace',
    REPLACE_ALL: 'replace_all',
    REPLACE_OPTIONS: 'replaceOptions',
    REPLACE_SELECTED_OPTIONS: 'replaceSelectedOptions'
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.REPLACE_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: [...action.payload.selectedOptions],
                selectedOptionsAux: [...action.payload.selectedOptionsAux]
            };

        case ACTIONS.REPLACE_OPTIONS:
            return {
                ...state,
                options: [...action.payload.options],
                optionsAux: [...action.payload.optionsAux]
            };
        case ACTIONS.REPLACE_ALL:
            return {
                ...action.payload
            };
        case ACTIONS.CHANGE_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    state: 'error',
                    error: action.payload
                };
            } else {
                return {
                    ...state,
                    state: 'normal',
                    error: ''
                };
            }
        case ACTIONS.ADD_OPTION:
            return {
                ...state,
                options: [...state.options, action.payload.option],
                optionsAux: [...state.optionsAux, action.payload.optionAux]
            };
        case ACTIONS.TOGGLE_DROPDOWN:
            return {
                ...state,
                openDropdown: action.payload
            };
        case ACTIONS.SELECT_OPTION: {
            let selectOptionIndex = _.findIndex(state.optionsAux, (el) => {
                return el === action.payload.optionAux;
            });
            if (selectOptionIndex !== -1) {
                return {
                    ...state,
                    options: [
                        ...state.options.slice(0, selectOptionIndex),
                        ...state.options.slice(
                            selectOptionIndex + 1,
                            state.options.length
                        )
                    ],
                    optionsAux: [
                        ...state.optionsAux.slice(0, selectOptionIndex),
                        ...state.optionsAux.slice(
                            selectOptionIndex + 1,
                            state.optionsAux.length
                        )
                    ],
                    selectedOptions: [
                        ...state.selectedOptions,
                        action.payload.option
                    ],
                    selectedOptionsAux: [
                        ...state.selectedOptionsAux,
                        action.payload.optionAux
                    ],
                    value: ''
                };
            } else {
                return {
                    ...state,
                    selectedOptions: [
                        ...state.selectedOptions,
                        action.payload.option
                    ],
                    selectedOptionsAux: [
                        ...state.selectedOptionsAux,
                        action.payload.optionAux
                    ],
                    value: ''
                };
            }
        }
        case ACTIONS.UNSELECT_OPTION:
            {
                let selectedOptionIndex = _.findIndex(
                    state.selectedOptionsAux,
                    (el) => {
                        return el === action.payload.optionAux;
                    }
                );

                if (selectedOptionIndex !== -1) {
                    return {
                        ...state,
                        options: [action.payload.option, ...state.options],
                        optionsAux: [
                            action.payload.optionAux,
                            ...state.optionsAux
                        ],
                        selectedOptions: [
                            ...state.selectedOptions.slice(
                                0,
                                selectedOptionIndex
                            ),
                            ...state.selectedOptions.slice(
                                selectedOptionIndex + 1,
                                state.selectedOptions.length
                            )
                        ],
                        selectedOptionsAux: [
                            ...state.selectedOptionsAux.slice(
                                0,
                                selectedOptionIndex
                            ),
                            ...state.selectedOptionsAux.slice(
                                selectedOptionIndex + 1,
                                state.selectedOptionsAux.length
                            )
                        ],
                        value: ''
                    };
                }
            }
            break;
        case ACTIONS.KEY_PRESS: {
            let options = _.cloneDeep(state.options),
                optionsAux = _.cloneDeep(state.optionsAux),
                selectedOptions = _.cloneDeep(state.selectedOptions),
                selectedOptionsAux = _.cloneDeep(state.selectedOptionsAux);
            if (action.payload.which === 13) {
                action.payload.preventDefault();
                if (state.value && state.focused >= 0) {
                    let valueReplaced = state.value.replace(
                        new RegExp('\\\\', 'g'),
                        '\\\\'
                    );
                    const regex = new RegExp(`${valueReplaced}`, 'i');
                    let newOptions = state.options.filter((p, index) => {
                        return (
                            p.toString().match(regex) ||
                            state.optionsAux[index].toString().match(regex)
                        );
                    });
                    let newOptionsAux = state.optionsAux.filter((p, index) => {
                        return (
                            state.options[index].toString().match(regex) ||
                            p.toString().match(regex)
                        );
                    });

                    selectedOptions.push(newOptions[state.focused]);
                    selectedOptionsAux.push(newOptionsAux[state.focused]);

                    let indexAux;
                    indexAux = state.optionsAux.indexOf(
                        newOptionsAux[state.focused]
                    );
                    optionsAux.splice(indexAux, 1);
                    options.splice(indexAux, 1);
                } else if (state.focused >= 0) {
                    selectedOptions.push(state.options[state.focused]);
                    selectedOptionsAux.push(state.optionsAux[state.focused]);
                    options.splice(state.focused, 1);
                    optionsAux.splice(state.focused, 1);
                }
                state.focused = -1;
                const scrollDiv = document.getElementById(
                    `${state.name}-dropdown`
                );
                scrollDiv.scrollTop = 0;
                action.payload.currentTarget.blur();
                return {
                    ...state,
                    options,
                    optionsAux,
                    selectedOptions,
                    selectedOptionsAux,
                    focused: -1,
                    value: ''
                };
            } else {
                return {
                    ...state,
                    focused: -1
                };
            }
        }

        case ACTIONS.KEY_DOWN:
            if (action.payload.swhich === 38) {
                if (state.focused > 0) {
                    const scrollDiv = document.getElementById(
                        `${state.name}-dropdown`
                    );
                    const topElement = document.querySelector(
                        `#${state.name}-dropdown .select-multiple-container__dropdown__container--focused`
                    );
                    scrollDiv.scrollTop =
                        topElement.offsetTop - topElement.offsetHeight;
                    state.focused = state.focused - 1;
                }
            } else if (action.payload.swhich === 40) {
                if (state.value && state.focused !== -1) {
                    let valueReplaced = state.valuaction.payload.sreplace(
                        new RegExp('\\\\', 'g'),
                        '\\\\'
                    );
                    const regex = new RegExp(`${valueReplaced}`, 'i');
                    let newOptions = state.options.filter((p) => {
                        return p.toString().match(regex);
                    });
                    if (state.focused < newOptions.length - 1) {
                        const scrollDiv = document.getElementById(
                            `${state.name}-dropdown`
                        );
                        const topElement = document.querySelector(
                            `#${state.name}-dropdown .select-multiple-container__dropdown__container--focused`
                        );
                        scrollDiv.scrollTop =
                            topElement.offsetTop + topElement.offsetHeight;
                        return {
                            ...state,
                            focused: state.focused + 1
                        };
                    }
                } else if (
                    state.focused !== -1 &&
                    state.focused < state.options.length - 1
                ) {
                    const scrollDiv = document.getElementById(
                        `${state.name}-dropdown`
                    );
                    const topElement = document.querySelector(
                        `#${state.name}-dropdown .select-multiple-container__dropdown__container--focused`
                    );
                    scrollDiv.scrollTop =
                        topElement.offsetTop + topElement.offsetHeight;
                    return {
                        ...state,
                        focused: state.focused + 1
                    };
                } else if (state.focused === -1) {
                    return {
                        ...state,
                        focused: 0
                    };
                }
            } else if (action.payload.which === 9) {
                return {
                    ...state,
                    openDropdown: false
                };
            } else {
                return {
                    ...state
                };
            }
            break;
        case ACTIONS.SET_VALUE:
            return {
                ...state,
                value: action.payload
            };
        case ACTIONS.RESET:
            return {
                ...action.payload
            };
        case ACTIONS.REPLACE:
            return {
                ...state,
                selectedOptions: [...action.payload],
                selectedOptionsAux: [...action.payload]
            };
    }
}

export function useSelectMultipleInput(defaultValue) {
    const [value, dispatch] = useReducer(reducer, defaultValue);

    const addOption = useCallback((value, valueAux) => {
        dispatch({
            type: 'add',
            payload: { option: value, optionAux: valueAux }
        });
    }, []);

    const replaceOptions = useCallback((value, valueAux) => {
        dispatch({
            type: 'replaceOptions',
            payload: { options: value, optionsAux: valueAux }
        });
    }, []);

    function selectOption(value, valueAux) {
        dispatch({
            type: 'select',
            payload: { option: value, optionAux: valueAux }
        });
    }

    function unselectOption(value, valueAux) {
        dispatch({
            type: 'unselect',
            payload: { option: value, optionAux: valueAux }
        });
    }

    function keyPress(e) {
        dispatch({
            type: 'keyPress',
            payload: e
        });
    }

    function keyDown(e) {
        dispatch({
            type: 'keyDown',
            payload: e
        });
    }

    const toggleDropdown = useCallback(
        (v) => {
            dispatch({
                type: 'dropdown',
                payload: typeof v === 'boolean' ? v : !value.openDropdown
            });
        },
        [value]
    );

    const setValue = useCallback((e) => {
        dispatch({ type: 'value', payload: e.target.value });
    }, []);

    const reset = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue });
    }, [defaultValue]);

    const replaceSelectedOptions = useCallback(
        (selectedOptions, selectedOptionsAux) => {
            dispatch({
                type: 'replaceSelectedOptions',
                payload: {
                    selectedOptions: selectedOptions,
                    selectedOptionsAux: selectedOptionsAux
                }
            });
        },
        []
    );
    function setError(value) {
        dispatch({ type: 'error', payload: value });
    }

    const replaceAll = useCallback((value) => {
        dispatch({ type: 'replace_all', payload: value });
    }, []);

    return {
        value,
        addOption,
        toggleDropdown,
        selectOption,
        replaceOptions,
        unselectOption,
        keyPress,
        keyDown,
        setValue,
        reset,
        replaceSelectedOptions,
        setError,
        replaceAll
    };
}
