import React, { useState, useRef } from 'react'
import { ReactComponent as IcoInfo } from './assets/images/ico-info.svg'
import { ReactComponent as IcoSuccess } from '../../../assets/images/icoSuccessToast.svg'
import PropTypes from 'prop-types'
import { ReactComponent as IcoClose } from './assets/images/ico-close.svg'
import ee from 'event-emitter'
import styles from './notification.module.scss'

const emitter = new ee()

const info = (msg, time) => {
    emitter.emit('info', msg, time)
}

const error = (msg, time) => {
    emitter.emit('error', msg, time)
}

const notification = (msg, time) => {
    emitter.emit('notification', msg, time)
}

const warning = (msg, time) => {
    emitter.emit('warning', msg, time)
}

const success = (msg, time) => {
    emitter.emit('success', msg, time)
}

const Notification = React.memo(
    ({
        onStartNotification,
        onFinishNotification,
        successLabel,
        failedLabel,
    }) => {
        const [top, setTop] = useState(-100)
        const [notification, setNotification] = useState({
            statusText: '',
            success: false,
            text: '',
            date: '',
        })
        const [type, setType] = useState('info')
        let timeout = useRef(null)
        emitter.on('info', (notification, time) => {
            onShow(notification, time, 'info')
        })
        emitter.on('error', (notification, time) => {
            onShow(notification, time, 'error')
        })
        emitter.on('notification', (notification, time) => {
            onShow(notification, time, 'notification')
        })
        emitter.on('warning', (notification, time) => {
            onShow(notification, time, 'warning')
        })
        emitter.on('success', (notification, time) => {
            onShow(notification, time, 'success')
        })

        const onShow = (notification, time, type) => {
            onStartNotification()
            showNotification(notification, time, type)
        }
        const showNotification = (notification, time, type) => {
            const timeInterval = time ? time : 5000
            setTop('8rem')
            setNotification(notification)
            setType(type)
            timeout = setTimeout(() => {
                setTop(-100)
                onFinishNotification()
                clearTimeout(timeout)
                timeout = null
            }, timeInterval)
        }
        let backgroundColor
        if (type === 'info') {
            backgroundColor = '#ffffff'
        } else if (type === 'error') {
            backgroundColor = '#db5046'
        } else if (type === 'notification') {
            backgroundColor = '#2c3c8d'
        } else if (type === 'warning') {
            backgroundColor = '#dbcf46'
        } else if (type === 'success') {
            backgroundColor = '#4ddb46'
        }
        return (
            <div
                className={`${styles['notification-container']}`}
                style={{
                    top: top,
                    backgroundColor: '#fff',
                    borderColor: backgroundColor,
                }}
            >
                <div
                    className={`row w-100 mx-0 ${styles['notification-container__item-wrapper__item']}`}
                >
                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                        {type === 'info' && (
                            <IcoInfo
                                alt=""
                                className={`${styles['notification-container__item-wrapper__item__image']}`}
                                style={{
                                    filter: 'brightness(0) saturate(100%) invert(30%) sepia(1%) saturate(0%) hue-rotate(28deg) brightness(91%) contrast(88%)',
                                }}
                            />
                        )}
                        {type === 'error' && (
                            <IcoInfo
                                alt=""
                                className={`${styles['notification-container__item-wrapper__item__image']}`}
                                style={{
                                    filter: 'brightness(0) saturate(100%) invert(56%) sepia(50%) saturate(6767%) hue-rotate(335deg) brightness(92%) contrast(86%)',
                                }}
                            />
                        )}
                        {type === 'notification' && (
                            <IcoInfo
                                alt=""
                                className={`${styles['notification-container__item-wrapper__item__image']}`}
                                style={{
                                    filter: 'brightness(0) saturate(100%) invert(20%) sepia(15%) saturate(7481%) hue-rotate(215deg) brightness(97%) contrast(93%)',
                                }}
                            />
                        )}
                        {type === 'warning' && (
                            <IcoInfo
                                alt=""
                                className={`${styles['notification-container__item-wrapper__item__image']}`}
                                style={{
                                    filter: 'brightness(0) saturate(100%) invert(81%) sepia(52%) saturate(536%) hue-rotate(5deg) brightness(95%) contrast(89%)',
                                }}
                            />
                        )}
                        {type === 'success' && (
                            <IcoSuccess
                                alt=""
                                className={`${styles['notification-container__item-wrapper__item__image']}`}
                                style={{
                                    filter: 'brightness(0) saturate(100%) invert(89%) sepia(78%) saturate(4375%) hue-rotate(48deg) brightness(92%) contrast(84%)',
                                }}
                            />
                        )}
                    </div>
                    <div className="col px-2 d-flex flex-column justify-content-center align-items-start py-2">
                        <div className="row mx-0">
                            <span
                                className={`${styles['notification-container__item-wrapper__item__status-text']}`}
                            >
                                {notification.statusText}
                            </span>
                        </div>
                        {notification && notification.text && (
                            <div className="row mx-0">
                                <span
                                    className={`${styles['notification-container__item-wrapper__item__text']}`}
                                >
                                    {notification.text}
                                </span>
                            </div>
                        )}
                        {notification.hasOwnProperty('success') && (
                            <div className="row mx-0">
                                <span
                                    className={
                                        notification.success
                                            ? `${styles['notification-list__list-wrapper__item-wrapper__item__text']} ${styles['success']}`
                                            : `${styles['notification-list__list-wrapper__item-wrapper__item__text']} ${styles['fail']}`
                                    }
                                >
                                    {notification.success
                                        ? successLabel
                                        : failedLabel}
                                </span>
                            </div>
                        )}
                        {notification && notification.date && (
                            <div className="row mx-0">
                                <span
                                    className={`${styles['notification-container__item-wrapper__item__date-text']}`}
                                >
                                    {notification.date}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                        <IcoClose
                            onClick={() => {
                                clearTimeout(timeout)
                                timeout = null
                                setTop(-100)
                                onFinishNotification()
                            }}
                            alt=""
                            className={`${styles['notification-container__image']}`}
                        />
                    </div>
                </div>
            </div>
        )
    }
)

Notification.propTypes = {
    /**
     * Number to specify timeout interval
     * */
    time: PropTypes.number,
}

export { Notification, info, error, warning, success, notification }
