import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { segmentClientsListMock } from './mock';
import { verifyComponent } from '../../../utils/component.js';
import { getValueInString } from '../../../utils/unit';
import styles from './segment-clients-list.module.scss';
import { SegmentationService } from '../../../temp/test';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { WithTableHeader } from '../../../hocs/table-header/table-header';
import { Spinner } from '../../loading/spinner/spinner';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Lists all the users in a table
 */

export const SegmentClientsList = React.memo(
    ({ segmentId, segmentFilters }) => {
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [modal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            pageChange: dataPageChange,
            sort: dataSort,
            selectAll: dataSelectAll,
            unselectAll: dataUnselectAll,
            setLoading,
            setValue,
            clickActionIcon
        } = useTable(segmentClientsListMock, modal, '');

        const getSegmentClients = useCallback(
            async (search) => {
                const dataClone = _.cloneDeep(segmentClientsListMock);
                try {
                    dataClone.data.length = 0;
                    dataClone.sortedData.length = 0;
                    dataClone.columns = [
                        { path: 'id', label: '', sortable: true },
                        { path: 'name', label: '', sortable: true },
                        { path: 'risk', label: '', sortable: true },
                        { path: 'revenue', label: '', sortable: true }
                    ];

                    if (segmentId) {
                        const results =
                            await SegmentationService.getApiSegmentationClients(
                                { id: segmentId }
                            );
                        results.map((el) => {
                            dataClone.data.push({
                                id: el.client_id ? el.client_id : '-',
                                name: el.name ? el.name : '-',
                                revenue: getValueInString(
                                    el.total_revenue,
                                    'values'
                                ),
                                risk: el.risk ? el.risk : '-'
                            });
                            return el;
                        });
                    }
                    if (segmentFilters && segmentFilters.length > 0) {
                        const previewRules = [];
                        segmentFilters.map((el) => {
                            let pointOfSaleRulesGroupRulesSchema = {
                                entity: el.entity,
                                group_func: el.grouper,
                                operator: el.operator,
                                param: el.name,
                                value: el.value
                            };
                            previewRules.push(pointOfSaleRulesGroupRulesSchema);
                            return el;
                        });
                        const results =
                            await SegmentationService.postApiSegmentationRulesPreview(
                                {
                                    requestBody: previewRules
                                }
                            );
                        results.map((el) => {
                            dataClone.data.push({
                                id: el.client_id ? el.client_id : '-',
                                name: el.name ? el.name : '-',
                                revenue: getValueInString(
                                    el.total_revenue,
                                    'values'
                                ),
                                risk: el.risk ? el.risk : '-'
                            });
                            return el;
                        });
                    }
                    dataClone.sortedData = _.sortBy(
                        dataClone.data,
                        dataClone.sortColumn.path,
                        dataClone.sortColumn.order
                    );
                    dataClone.titleCounter = dataClone.sortedData.length;
                    dataClone.loading = false;
                    dataClone.disabled = true;

                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                    if (search) {
                        const searchRegex = new RegExp(
                            `${search.replace(
                                new RegExp('\\\\', 'g'),
                                '\\\\'
                            )}`,
                            'i'
                        );
                        dataClone.filteredData = _.filter(
                            dataClone.data,
                            (el) => {
                                return el.name.toString().match(searchRegex);
                            }
                        );
                    } else {
                        dataClone.filteredData = _.cloneDeep(dataClone.data);
                    }
                    dataClone.sortedData = _.orderBy(
                        dataClone.filteredData,
                        [dataClone.sortColumn.path],
                        [dataClone.sortColumn.order]
                    );
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            },
            [isMounted, setLoading, setValue, segmentId, segmentFilters]
        );

        useEffect(() => {
            getSegmentClients();
        }, [getSegmentClients]);

        return (
            <div className={`${styles['segment-clients-list-container']}`}>
                <ContentContainer
                    hidePinIcon
                    attr={data.attr}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    hideSettingsIcon
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <WithTableHeader count={data.titleCounter}>
                            <Table
                                actions={data.actions}
                                // onClickActionIcon={onClickActionIcon}
                                // onClickActionOption={onClickActionOption}
                                // onCloseActions={onCloseActions}
                                attr={data.attr}
                                type={data.type}
                                placeholderText={t(
                                    `components:${data.attr}.nodata`
                                )}
                                columns={data.columns}
                                data={data.sortedData}
                                sortColumn={data.sortColumn}
                                onSort={dataSort}
                                currentPage={data.currentPage}
                                pageSize={data.pageSize}
                                onPageChange={dataPageChange}
                                onRowClick={undefined}
                                onRowSelect={undefined}
                                onSelectAll={dataSelectAll}
                                onUnselectAll={dataUnselectAll}
                                paginationDisabled={data.paginationDisabled}
                                disabled={data.disabled}
                                onClickActionIcon={clickActionIcon}
                                onCloseActions={clickActionIcon}
                            ></Table>
                        </WithTableHeader>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

SegmentClientsList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func
};
