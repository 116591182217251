import React from 'react'
import { GeneralTable } from '../../../../../components/dashboard-components/general-table/general-table'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { approvalRequestListMock } from './mock'
import { usePlatformFormat } from '../../../../../providers/platform-format-context'
import { RejectGoalModal } from '../../../../../components/modals/reject-goal-modal/reject-goal-modal'
import { GoalSummaryModal } from '../../../../../components/modals/goal-summary-modal/goal-summary-modal'

export const ApprovalRequestList = () => {
    const { t } = useCustomTranslation()
    const { format } = usePlatformFormat()
    const [rejectApprovalModal, setRejectApprovalModal] = React.useState(false)
    const [approveApprovalModal, setApproveApprovalModal] =
        React.useState(false)
    const [goalDetailsModal, setGoalDetailsModal] = React.useState(false)

    return (
        <>
            {goalDetailsModal && (
                <GoalSummaryModal
                    onCloseModal={() => setGoalDetailsModal(false)}
                    setRejectApprovalModal={setRejectApprovalModal}
                    setApproveApprovalModal={setApproveApprovalModal}
                />
            )}
            {rejectApprovalModal && (
                <RejectGoalModal
                    onCloseModal={() => setRejectApprovalModal(false)}
                    onEnd={() => console.log('End')}
                />
            )}
            <GeneralTable
                title={t(`components:${approvalRequestListMock.attr}.title`)}
                attr={approvalRequestListMock.attr}
                columns={
                    format === 'enterprise'
                        ? approvalRequestListMock.enterpriseColumns
                        : approvalRequestListMock.organizationColumns
                }
                mockData={approvalRequestListMock.data}
                withSearch
                noDataText={t(
                    `components:${approvalRequestListMock.attr}.nodata`
                )}
                hideTitle
                withFilter
                setRejectApprovalModal={setRejectApprovalModal}
                setApproveApprovalModal={setApproveApprovalModal}
                setGoalDetailsModal={setGoalDetailsModal}
            />
        </>
    )
}
