export const segmentComparisonMock = {
    loading: true,
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'segmentComparisonChart',
    dataInterval: [0, 150],
    radarAttr: [
        {
            name: 'fds',
            dataKey: 'A',
            color: '#7a5ea5'
        }
    ],
    categoryAttr: 'subject',
    unitsOrValues: 'units',
    tooltipType: 'segmentComparison',
    varMap: {
        'Avg. Revenue': { min: 0, max: 1000 },
        'Avg. Risk': { min: 0, max: 500 },
        Recurrence: { min: 0, max: 250 }
    },
    data: [
        {
            subject: '',
            fullMark: 1
        },
        {
            subject: '',
            fullMark: 1
        },
        {
            subject: '',
            fullMark: 1
        }
    ],
    angle: 90,
    areaAttr: [
        {
            dataKey: 'fillLine-unitsSoldForecast',
            color: '#8c54ff',
            strokeWidth: 2
        },
        {
            dataKey: 'minUnitsSoldForecastValue',
            color: '#8c54ff',
            dashArray: '3 3',
            strokeWidth: 2
        },
        {
            dataKey: 'maxUnitsSoldForecastValue',
            color: '#8c54ff',
            dashArray: '3 3',
            strokeWidth: 2
        },
        {
            dataKey: 'minUnitsSoldForecastValueAux',
            color: '#8c54ff',
            dashArray: '3 3',
            strokeWidth: 2
        },
        {
            dataKey: 'unitsSoldForecastValue',
            color: '#8c54ff',
            strokeWidth: 2
        },
        {
            dataKey: 'unitsSoldAux',
            color: '#513091',
            strokeWidth: 2
        },
        {
            dataKey: 'unitsSold',
            color: '#8c54ff',
            strokeWidth: 2
        }
    ],
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: 'VIEW',
            options: []
        },
        options: {
            label: 'OPTIONS',
            options: []
        }
    },
    legend: undefined
};
