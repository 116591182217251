import React from 'react';
import styles from './tooltip-content-row.module.scss';

export const TooltipContentRow = React.memo(
    ({ title, value, color, hideColor }) => {
        return (
            <div className={`${styles['tooltip-content-row']}`}>
                <div className="row mx-0 w-100 d-flex justify-content-between">
                    <div className="col-auto px-0">
                        {!hideColor && color && (
                            <div
                                style={{
                                    position: 'relative',
                                    border: `0.3rem solid ${color}`,
                                    width: '1rem',
                                    height: '1rem',
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    marginRight: '0.5rem'
                                }}
                            ></div>
                        )}
                        <span
                            className={`${styles['tooltip-content-row__font']} ${styles['tooltip-content-row__font--content']} me-3`}
                        >
                            {title || '-'}
                        </span>
                    </div>
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                    <div className="col-auto px-0">
                        <span
                            className={`${styles['tooltip-content-row__font']} ${styles['tooltip-content-row__font--content']}`}
                            style={{ color: '#4c4c4c' }}
                        >
                            {value || '-'}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
);
