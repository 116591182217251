import { createContext, useContext, useMemo, useState } from 'react';

const SalesChannelContext = createContext({
    channels: [],
    setChannels: () => {}
});

export function SalesChannelProvider({ children }) {
    const [channels, setChannels] = useState([]);

    const salesChannelValue = useMemo(() => {
        return {
            channels: JSON.stringify(channels),
            setChannels
        };
    }, [channels]);

    return (
        <SalesChannelContext.Provider value={salesChannelValue}>
            {children}
        </SalesChannelContext.Provider>
    );
}

export function useSalesChannel() {
    return useContext(SalesChannelContext);
}
