import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './ComponentFilterPopup.module.scss'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { InputFactory } from '../../inputs/input-factory/input-factory'
import Scrollable from '../../scrolling/scrollable'
import { AddNewForecastModal } from '../../modals/add-new-forecast-modal/add-new-forecast-modal'
import { errorHandler } from '../../../utils/api'
import { SaleChannelsService } from '../../../temp/test'
import { useSalesChannel } from '../../../providers/sales-channel-context'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useUserForecastFilter } from '../../../providers/user-forecast-filter-context'

const timeIntervalInput = 'timeInterval'

export const ComponentFilterPopup = ({
    withTimeFilter,
    withGeneralTimeFilter,
    withChannelFilter,
    withForecastFilter,
    componentRef,
    onClosePopup,
}) => {
    const { t } = useCustomTranslation()
    const [isReverseX, setIsReverseX] = useState(undefined)
    const { timeInterval, setTimeInterval } = useTimeInterval(withGeneralTimeFilter ? 'quarter' : 'month')
    const { userForecastEnabled, setUserForecastEnabled } = useUserForecastFilter()
    const { channels, setChannels } = useSalesChannel()
    const [addNewForecastModal, setAddNewForecastModal] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const {
        value: inputs,
        setValue: onChangeInput,
        selectOption: onSelectInput,
        unselectOption: onUnselectInput,
        toggleDropdown: onToggleDropdown,
        clearSelectedOptions: onClearSelectedOptions,
        clickRadioOption: onClickRatioOption,
        clickCheckboxOption: onClickCheckboxOption,
        replaceAll: replaceAllInputs,
    } = useMultipleInputs({})

    // whenever the component is mounted, if it is over half the inner width of the window, reverse the x axis

    useEffect(() => {
        if (componentRef.current) {
            const rect = componentRef.current.getBoundingClientRect()
            if (rect.x + rect.width > window.innerWidth / 2) {
                setIsReverseX(true)
            } else {
                setIsReverseX(false)
            }
        }
    }, [componentRef])

    useEffect(() => {
        async function fill() {
            try {
                const result = await SaleChannelsService.getApiSaleChannels({})
                let newInputs = {}
                if (withTimeFilter) {
                    newInputs = {
                        ...newInputs,
                        timeInterval: {
                            type: 'radio',
                            name: 'timeInterval',
                            options: [
                                {
                                    name: 'day',
                                    label: t('inputs:day'),
                                },
                                {
                                    name: 'week',
                                    label: t('inputs:week'),
                                },
                                {
                                    name: 'month',
                                    label: t('inputs:month'),
                                },
                                {
                                    name: 'quarter',
                                    label: t('inputs:quarter'),
                                },
                                {
                                    name: 'year',
                                    label: t('inputs:year'),
                                },
                            ],
                            selectedOption: timeInterval || 'month',
                        },
                    }
                }
                if (withGeneralTimeFilter) {
                    newInputs = {
                        ...newInputs,
                        timeInterval: {
                            type: 'radio',
                            name: 'timeInterval',
                            options: [
                                {
                                    name: 'month',
                                    label: t('inputs:month'),
                                },
                                {
                                    name: 'quarter',
                                    label: t('inputs:quarter'),
                                },
                                {
                                    name: 'semester',
                                    label: t('inputs:semester'),
                                },
                            ],
                            selectedOption: timeInterval || 'quarter',
                        },
                    }
                }
                if (withForecastFilter) {
                    newInputs = {
                        ...newInputs,
                        forecast: {
                            type: 'radio',
                            name: 'forecast',
                            options: [
                                {
                                    name: 'yes',
                                    label: t('inputs:yes'),
                                },
                                {
                                    name: 'no',
                                    label: t('inputs:no'),
                                },
                            ],
                            selectedOption: userForecastEnabled ? 'yes' : 'no',
                        },
                    }
                }
                if (withChannelFilter) {
                    newInputs = {
                        ...newInputs,
                        channel: {
                            type: 'checkbox',
                            name: 'channel',
                            options: result.map((el) => {
                                return {
                                    name: el.id,
                                    label: el.name,
                                }
                            }),
                            selectedOptions: result
                                .filter((el) => {
                                    return channels.includes(el.id)
                                })
                                .map((el) => {
                                    return el.id
                                }),
                        },
                    }
                }
                replaceAllInputs(newInputs)
                setLoaded(true)
            } catch (err) {
                errorHandler(err)
            }
        }
        if (!loaded) {
            fill()
        }
    }, [
        loaded,
        channels,
        replaceAllInputs,
        withTimeFilter,
        withGeneralTimeFilter,
        withChannelFilter,
        withForecastFilter,
        t,
        timeInterval,
        userForecastEnabled,
    ])

    const inputActions = useMemo(() => {
        return {
            onChangeInput,
            onSelectInput,
            onUnselectInput,
            onToggleDropdown,
            onClearSelectedOptions,
            onClickRatioOption,
            onClickCheckboxOption,
        }
    }, [onChangeInput, onSelectInput, onUnselectInput, onToggleDropdown, onClearSelectedOptions, onClickRatioOption, onClickCheckboxOption])

    const handleOutsideClick = useCallback(
        (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target) && !addNewForecastModal) {
                event.preventDefault()
                event.stopPropagation()
                onClosePopup()
            }
        },
        [onClosePopup, componentRef, addNewForecastModal]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [handleOutsideClick])

    // Extract the specific piece of state you want to watch
    const selectedTimeInterval = useMemo(() => {
        return inputs[timeIntervalInput]?.selectedOptionAux
    }, [inputs])

    const selectedChannels = useMemo(() => {
        return inputs['channel']?.selectedOptions
    }, [inputs])

    const userForecast = useMemo(() => {
        return inputs['forecast']?.selectedOption
    }, [inputs])

    useEffect(() => {
        // Now this effect depends specifically on `selectedTimeInterval` and `withTimeFilter`
        if (withTimeFilter && selectedTimeInterval) {
            setTimeInterval(selectedTimeInterval)
        }
    }, [selectedTimeInterval, setTimeInterval, withTimeFilter])

    useEffect(() => {
        if (withGeneralTimeFilter && selectedTimeInterval) {
            setTimeInterval(selectedTimeInterval)
        }
    }, [selectedTimeInterval, setTimeInterval, withGeneralTimeFilter])

    useEffect(() => {
        // Now this effect depends specifically on `selectedChannels` and `withChannelFilter`
        // compare selectedChannels to channels before setting
        if (withChannelFilter && selectedChannels) {
            setChannels(selectedChannels)
        }
    }, [selectedChannels, withChannelFilter, setChannels])
    useEffect(() => {
        if (withForecastFilter && userForecast !== undefined) {
            setUserForecastEnabled(userForecast === 'yes' ? true : false)
        }
    }, [userForecast, setUserForecastEnabled, withForecastFilter])

    return (
        <>
            {addNewForecastModal && <AddNewForecastModal onCloseModal={() => setAddNewForecastModal(false)} />}
            <div
                className={`${styles['component-filter-popup-container']}`}
                style={{
                    left: isReverseX ? 'unset' : '0',
                    right: isReverseX ? '0' : 'unset',
                    visibility: isReverseX === undefined ? 'hidden' : 'visible',
                }}
            >
                <div className="row mx-0 w-100" style={{ height: '30rem' }}>
                    <div className="col px-0 h-100">
                        <Scrollable addRightSpacing>
                            {inputs &&
                                Object.values(inputs).map((input, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: index === Object.values(inputs).length - 1 ? '0' : '2rem',
                                            }}
                                        >
                                            <div className="col px-0">
                                                <InputFactory {...input} {...inputActions} />
                                            </div>
                                        </div>
                                    )
                                })}
                        </Scrollable>
                    </div>
                </div>
            </div>
        </>
    )
}
