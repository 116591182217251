import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { DTablesService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper'
import { Spinner } from '../../loading/spinner/spinner'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { DynamicComposedChartContainer } from '../../charts/dynamic-composed-chart-container/dynamic-composed-chart-container'
import { getMonthFromDateFormat, getTimeFromDateFormat } from '../../../utils/date'
import { toIsoDate, toIsoDateTime } from '../../../utils/format'
import { TimeSeriesManagerProvider } from '../../../providers/time-series-manager-context'
import { Loader } from '../../loading/Loader/Loader'

const Chart = withCustomBrush(DynamicComposedChartContainer, 'day')

export const DynamicInfoTimeseries = ({ component, isPreview, isExample, config, ...rest }) => {
    const name = useMemo(() => {
        return component.name
    }, [component])
    const [data, setData] = useState({})
    const [xAxis, setXAxis] = useState(undefined)
    const [yAxis, setYAxis] = useState(undefined)
    const [backendXAxis, setBackendXAxis] = useState(undefined)
    const { t } = useCustomTranslation()

    // function that receives data with datetimes like 2024-03-18T00:00:00 and should group them by month and year
    const groupByMonthAndYear = useCallback(
        (data, dateAttr, valueAttr) => {
            const groupedData = data.reduce((acc, curr) => {
                const month = getMonthFromDateFormat(curr[dateAttr], t)
                const key = `${month}`
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(curr)
                return acc
            }, {})
            return Object.keys(groupedData).map((key) => {
                return {
                    month: key,
                    data: groupedData[key],
                    [valueAttr]: groupedData[key].reduce((acc, curr) => {
                        return acc + curr[valueAttr]
                    }, 0),
                    time: getTimeFromDateFormat(groupedData[key][0][dateAttr]),
                }
            })
        },
        [t]
    )

    // function that groups by year
    const groupByYear = useCallback((data, dateAttr, valueAttr) => {
        const groupedData = data.reduce((acc, curr) => {
            const date = new Date(curr[dateAttr])
            const year = date.getFullYear()
            if (!acc[year]) {
                acc[year] = []
            }
            acc[year].push(curr)
            return acc
        }, {})
        return Object.keys(groupedData).map((key) => {
            return {
                year: key,
                data: groupedData[key],
                [valueAttr]: groupedData[key].reduce((acc, curr) => {
                    return acc + curr[valueAttr]
                }, 0),
                time: getTimeFromDateFormat(groupedData[key][0][dateAttr]),
            }
        })
    }, [])

    // function that groups by day
    const groupByDay = useCallback(
        (data, dateAttr, valueAttr) => {
            const groupedData = data.reduce((acc, curr) => {
                const date = new Date(curr[dateAttr])
                const day = date.getDate()
                const month = getMonthFromDateFormat(curr[dateAttr], t)
                const key = `${day} ${month}`
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(curr)
                return acc
            }, {})
            return Object.keys(groupedData).map((key) => {
                return {
                    id: groupedData[key][0].id,
                    day: key,
                    data: groupedData[key],
                    [valueAttr]: groupedData[key].reduce((acc, curr) => {
                        return acc + curr[valueAttr]
                    }, 0),
                    time: getTimeFromDateFormat(groupedData[key][0][dateAttr]),
                }
            })
        },
        [t]
    )

    const getData = useCallback(async () => {
        if (!isExample) {
            try {
                const tableData = await DTablesService.getApiBackofficeAdminDtables1({
                    tableName: name,
                })
                const result = await DTablesService.getApiBackofficeAdminDtablesData({
                    tablename: name,
                    ids: undefined,
                    page: undefined,
                    itemsPerPage: undefined,
                })
                const areaAttr = []
                areaAttr.push({
                    dataKey: tableData.timeseries_x,
                    color: '#8c54ff',
                    strokeWidth: 2,
                })
                result.sort((a, b) => {
                    return new Date(a[tableData.timeseries_x]) - new Date(b[tableData.timeseries_x])
                })
                setXAxis('day')
                setBackendXAxis(tableData.timeseries_x)
                setYAxis(tableData.timeseries_y)
                setData({
                    tooltipType: 'dynamincInfoTimeseries',
                    areaAttr: [
                        {
                            dataKey: tableData.timeseries_y,
                            color: '#8c54ff',
                            strokeWidth: 2,
                        },
                    ],
                    legend: [
                        {
                            name: tableData.timeseries_y,
                            color: '#8c54ff',
                            strokeWidth: 2,
                        },
                    ],
                    categoryAxis: 'day',
                    data: groupByDay(result, tableData.timeseries_x, tableData.timeseries_y),
                })
            } catch (err) {
                errorHandler(err)
            }
        } else {
            try {
                const tableData = await DTablesService.getApiBackofficeAdminDtables1({
                    tableName: name,
                })
                const result = []
                for (let i = 0; i < 9; i++) {
                    const mockRow = {}
                    const value = i + 1
                    mockRow[tableData.timeseries_x] = `2024-03-${'0' + value}T00:00:00`
                    mockRow[tableData.timeseries_y] = Math.floor(Math.random() * 100)
                    result.push(mockRow)
                }
                const areaAttr = []
                areaAttr.push({
                    dataKey: tableData.timeseries_x,
                    color: '#8c54ff',
                    strokeWidth: 2,
                })
                result.sort((a, b) => {
                    return new Date(a[tableData.timeseries_x]) - new Date(b[tableData.timeseries_x])
                })
                setXAxis('day')
                setBackendXAxis(tableData.timeseries_x)
                setYAxis(tableData.timeseries_y)
                setData({
                    tooltipType: 'dynamincInfoTimeseries',
                    areaAttr: [
                        {
                            dataKey: tableData.timeseries_y,
                            color: '#8c54ff',
                            strokeWidth: 2,
                        },
                    ],
                    legend: [
                        {
                            name: tableData.timeseries_y,
                            color: '#8c54ff',
                            strokeWidth: 2,
                        },
                    ],
                    categoryAxis: 'day',
                    data: groupByDay(result, tableData.timeseries_x, tableData.timeseries_y),
                })
            } catch (err) {
                errorHandler(err)
            }
        }
    }, [name, groupByDay, isExample])

    useEffect(() => {
        getData()
    }, [getData])

    const handleAdd = useCallback(
        async (inputs) => {
            try {
                const requestBody = {
                    [yAxis]: inputs[yAxis].value,
                    [backendXAxis]: toIsoDateTime(toIsoDate(inputs[xAxis].value), '00:00:00'),
                }
                await DTablesService.postApiBackofficeAdminDtablesData({
                    tableName: name,
                    requestBody: requestBody,
                })
            } catch (err) {
                errorHandler(err)
            }
            await getData()
        },
        [name, xAxis, yAxis, getData, backendXAxis]
    )

    const handleRemove = useCallback(
        async (ids) => {
            try {
                await DTablesService.deleteApiBackofficeAdminDtablesData({
                    tableName: name,
                    ids: ids,
                })
            } catch (err) {
                errorHandler(err)
            }
            await getData()
        },
        [name, getData]
    )

    const dynamicInfoProviderValue = useMemo(() => {
        return {
            data: data.data,
            xAxis,
            yAxis,
            onAdd: handleAdd,
            onRemove: handleRemove,
        }
    }, [data, handleAdd, handleRemove, xAxis, yAxis])

    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <TimeSeriesManagerProvider value={dynamicInfoProviderValue}>
                <ComponentWrapper titleText={t(name).split('_')[1]} component={component} withTimeseriesDataManager {...data} {...rest}>
                    {data.loading && <Spinner />}
                    {!data.loading && <Chart {...data} />}
                </ComponentWrapper>
            </TimeSeriesManagerProvider>
        </div>
    )
}
