import _ from 'lodash';
import React, { useEffect } from 'react';
import { CLUSTER_COLORS } from '../../../constants/color';
import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import {
    getDayFromDateFormat,
    getMonthFromDateFormat,
    getTimeFromDateFormat,
    getYearFromDateFormat,
    isSameYearSameMonth
} from '../../../utils/date';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useChartReady } from '../../../providers/chart-ready-context';
import { SegmentationService } from '../../../temp/test';
import { segmentRevenueSalesMock } from './mock';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { DynamicComposedChartContainer } from '../../charts/dynamic-composed-chart-container/dynamic-composed-chart-container';
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush';

/**
 * Displays evolution and comparison of revenue sales from one or multiple segments
 */

const Chart = withCustomBrush(DynamicComposedChartContainer);

export const SegmentRevenueSales = React.memo(({ isPreview, segmentId }) => {
    const { charts } = useChartReady();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(segmentRevenueSalesMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(segmentRevenueSalesMock);
            try {
                const time = new Date('2022-01-01');
                const timeISO = time.toISOString();
                const results =
                    await SegmentationService.getApiSegmentationMonthlySales({
                        id: segmentId,
                        minDate: timeISO
                    });
                dataClone.areaAttr = [];
                dataClone.legend = [
                    {
                        name: t('legend:revenue'),
                        color: '#2cd9c5',
                        translated: true
                    }
                ];
                Object.entries(results).map((el2) => {
                    let newEl = {};
                    newEl['time'] = getTimeFromDateFormat(el2[0]);
                    newEl['day'] = getDayFromDateFormat(el2[0]);
                    newEl['month'] = getMonthFromDateFormat(el2[0], t);
                    newEl['year'] = getYearFromDateFormat(el2[0]);
                    let idx;
                    idx = dataClone.data.findIndex((v) => {
                        return isSameYearSameMonth(v.time, newEl.time);
                    });
                    if (idx !== -1) {
                        dataClone.data[idx]['time'] = newEl['time'];
                        dataClone.data[idx]['day'] = newEl['day'];
                        dataClone.data[idx]['month'] = newEl['month'];
                        dataClone.data[idx]['year'] = newEl['year'];
                    } else {
                        Object.entries(results).map((el3, index3) => {
                            newEl[`revenue-${index3}`] = 0;
                            newEl[`revenueAux-${index3}`] = 0;
                            newEl[`forecastValue-${index3}`] = 0;
                            newEl[`maxForecastValue-${index3}`] = 0;
                            newEl[`minForecastValue-${index3}`] = 0;
                            newEl[`minForecastValueAux-${index3}`] = 0;
                            return el3;
                        });
                        dataClone.data.push(newEl);
                    }
                    return el2;
                });
                dataClone.areaAttr = [
                    {
                        dataKey: 'revenue',
                        color: '#2cd9c5',
                        isGradient: true,
                        strokeWidth: 2
                    },
                    {
                        dataKey: [`revenueAux`],
                        color: '#2cd9c5',
                        isGradient: true,
                        strokeWidth: 2
                    },
                    {
                        dataKey: [`revenue`],
                        color: '#2cd9c5',
                        isGradient: true,
                        strokeWidth: 2
                    }
                ];

                Object.entries(results).map((el2) => {
                    let idx = dataClone.data.findIndex((v) => {
                        return isSameYearSameMonth(
                            v.time,
                            getTimeFromDateFormat(el2[0])
                        );
                    });
                    if (idx !== -1) {
                        dataClone.data[idx][`revenue`] = el2[1].toFixed(1);
                        dataClone.data[idx][`revenueAux`] = el2[1].toFixed(1);
                        dataClone.data[idx][`forecastValue`] = 0;
                        dataClone.data[idx][`maxForecastValue`] = 0;
                        dataClone.data[idx][`minForecastValue`] =
                            el2[1].toFixed(1);
                        dataClone.data[idx][`minForecastValueAux`] =
                            el2[1].toFixed(1);
                    }
                    return el2;
                });

                dataClone.categoryAxis = 'month';
                if (!isPreview) dataClone.dateGrouper = 'month';
                dataClone.interactionDisabled = false;
                dataClone.tooltipType = 'salesHistory-monthly';
                dataClone.loading = false;
                dataClone.ready = true;
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [isMounted, setLoading, setValue, t, isPreview, segmentId]);

    return (
        <div
            className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
            >
                <Chart {...data} />
            </ContentContainer>
        </div>
    );
});

SegmentRevenueSales.propTypes = {};
