export const segmentRulesMock = {
    loading: true,
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'segmentRules',
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
    legend: undefined,
}
