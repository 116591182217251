import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLUSTER_COLORS } from '../../../constants/color';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

//import icoArrowUpPositive from '../../../../../../../assets/images/ico-arrow-up-positive.svg';

import styles from './segment-descriptor.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const SegmentDescriptor = React.memo(({ lastCreated }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={`${styles['segment-descriptor-container']}`}>
            <div
                className={`${styles['segment-descriptor-container__card-container']}`}
            >
                <div
                    className="row mx-0 w-100 d-flex justify-content-start align-items-center "
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col-auto px-0">
                        <span
                            className={`${styles['segment-descriptor-container__sub-title']}`}
                        >
                            {t('components:segmentDescriptorCard.title')}
                        </span>
                    </div>
                </div>
                {lastCreated.id && (
                    <React.Fragment>
                        <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['segment-descriptor-container__card-container__target-title']}`}
                                    style={{
                                        color: CLUSTER_COLORS[0]
                                    }}
                                >
                                    {t(
                                        'components:segmentDescriptorCard.segment'
                                    )}{' '}
                                    {lastCreated.id}
                                </span>
                            </div>
                            <div className="col-auto px-0">
                                <div>
                                    <span
                                        className={`${styles['segment-descriptor-container__card-container__content']}`}
                                    >
                                        {t(
                                            'components:segmentDescriptorCard.created'
                                        )}
                                    </span>
                                    <span> </span>
                                    <span
                                        className={`${styles['segment-descriptor-container__card-container__content-aux']}`}
                                    >
                                        {lastCreated.createdAt}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['segment-descriptor-container__card-container__description']}`}
                                >
                                    {lastCreated.name}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0 d-flex flex-column justify-content-end align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['segment-descriptor-container__card-container__value']}`}
                                            style={{
                                                color: CLUSTER_COLORS[0]
                                            }}
                                        >
                                            {
                                                lastCreated.segmentClientPercentage
                                            }
                                        </span>
                                        <span
                                            className={`${styles['segment-descriptor-container__card-container__value-aux']}`}
                                            style={{
                                                color: CLUSTER_COLORS[0]
                                            }}
                                        >
                                            %
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['segment-descriptor-container__card-container__value']}`}
                                            style={{
                                                color: CLUSTER_COLORS[0]
                                            }}
                                        >
                                            {lastCreated.segmentClientCount}
                                        </span>
                                        <span
                                            className={`${styles['segment-descriptor-container__card-container__value-aux']}`}
                                            style={{
                                                color: CLUSTER_COLORS[0]
                                            }}
                                        >
                                            /
                                        </span>
                                        <span
                                            className={`${styles['segment-descriptor-container__card-container__value-aux']}`}
                                        >
                                            {lastCreated.platformClientCount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row mx-0 w-100'>
							<div className='col-auto p-1'>
								<div
									className={`${styles['segment-descriptor-container__card-container__tag']} ${styles['positive']}`}
								>
									<span
										className={`${styles['segment-descriptor-container__card-container__tag__tag-text']} ${styles['positive']}`}
									>
										tag
									</span>
									<img
										className={`${styles['segment-descriptor-container__card-container__tag__tag-icon']}`}
										src={icoArrowUpPositive}
										alt=''
									/>
								</div>
							</div>
						</div> */}
                    </React.Fragment>
                )}
                {!lastCreated.id && (
                    <div className="row mx-0 w-100 d-flex justify-content-center align-items-center flex-grow-1">
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['segment-descriptor-container__no-data-title']}`}
                            >
                                {t(
                                    'components:segmentDescriptorCard.noSegmentsAvailable'
                                )}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
