import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useTranslation } from 'react-i18next'

export const KeywordSentimentTooltip = React.memo((props) => {
    const { i18n } = useTranslation()
    const { t } = useCustomTranslation()
    if (props.id !== '') {
        return (
            <TooltipContainer color={props.payload[props.id].fill}>
                <TooltipHeaderRow
                    title={t('tooltips:header.keyword')}
                    value={
                        props.payload[props.id].name === 'positive' &&
                        i18n.language === 'pt'
                            ? 'POSITIVO'
                            : props.payload[props.id].name === 'positive' &&
                              i18n.language === 'en'
                            ? 'POSITIVE'
                            : props.payload[props.id].name === 'neutral' &&
                              i18n.language === 'pt'
                            ? 'NEUTRO'
                            : props.payload[props.id].name === 'neutral' &&
                              i18n.language === 'en'
                            ? 'NEUTRAL'
                            : props.payload[props.id].name === 'negative' &&
                              i18n.language === 'pt'
                            ? 'NEGATIVO'
                            : props.payload[props.id].name === 'negative' &&
                              i18n.language === 'en'
                            ? 'NEGATIVE'
                            : '-'
                    }
                    color={props.payload[props.id].fill}
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.percentageMessages')}
                    value={`${props.payload[props.id].value}%`}
                    color={props.payload[props.id].fill}
                />
            </TooltipContainer>
        )
    }
})
