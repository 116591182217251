import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './report-builder.module.scss'
import { OpenAPI, ReportService } from '../../../temp/test'
import { useParams } from 'react-router-dom'
import ReactGridLayout, { WidthProvider } from 'react-grid-layout'
import { useReadyArray } from './useReadyArray'
import _ from 'lodash'
import config from '../../../configs/apiConfig'
import { getJwt } from '../../../utils/authService'
import { useFilter } from '../../../providers/filter-context'
import { useArray } from '../../../hooks/useArray'
import { useResizableGrid } from '../../../hooks/useResizableGrid'
import { ChartReadyProvider } from '../../../providers/chart-ready-context'
import { LibraryComponentFactory } from '../../../components'

const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }

const ResponsiveGridLayout = WidthProvider(ReactGridLayout)

let url = window.location
let params = new URLSearchParams(url.search)
let token = params.has('token') ? params.get('token') : ''
let serviceUrl = config.service.BASE_URL
OpenAPI.BASE = serviceUrl
OpenAPI.TOKEN = token ? token : getJwt()

export const ReportBuilder = React.memo(({ reportId }) => {
    const params = useParams()
    const { setFilter } = useFilter()
    const { array: charts, setArray: setCharts } = useArray([])
    const { array: chartsReady, setArray: setChartsReady, setReady } = useReadyArray([])

    const [resizableGrid, setLayout] = useResizableGrid({
        compactType: 'vertical',
        verticalCompact: undefined,
        rowHeight: 40,
        maxRows: undefined,
        isEditable: true,
        isDroppable: true,
        isBounded: false,
        autoSize: true,
        draggableHandle: '.drag-handle-ref',
        containerPadding: [7, 7],
        margin: [14, 14],
        layout: [],
        droppingElement: {},
        gridMounted: false,
        reference: useRef(),
    })

    useEffect(() => {
        const fill = async () => {
            const reportDataBack = await ReportService.getApiBackofficeReporting1({
                reportId: reportId ? reportId : params.id,
            })
            const newChartsReady = []
            setCharts(JSON.parse(reportDataBack.template).charts)
            JSON.parse(reportDataBack.template).charts.map((el) => {
                newChartsReady.push({ type: el.type, ready: true })
                return el
            })
            const filters = JSON.parse(reportDataBack.template).filters
            setFilter(filters)
            setChartsReady(newChartsReady)
        }
        fill()
    }, [params.id, setCharts, setFilter, reportId, setChartsReady])

    return (
        <div className={`${reportId ? styles['report-builder-normal-container'] : styles['report-builder-container']}`}>
            {chartsReady && chartsReady.length > 0 && _.every(chartsReady, (el) => el.ready) && <div id="doneId"></div>}
            <ChartReadyProvider
                value={{
                    charts: chartsReady,
                    setChartReady: setReady,
                }}
            >
                {charts && charts.length > 0 && (
                    <ResponsiveGridLayout
                        cols={cols}
                        className="layout w-100 mx-0"
                        rowHeight={30}
                        maxRows={resizableGrid.maxRows}
                        compactType={resizableGrid.compactType}
                        verticalCompact={resizableGrid.verticalCompact}
                        layout={resizableGrid.layout}
                        onLayoutChange={(layout) => {
                            setLayout(layout)
                        }}
                        isDraggable={false}
                        isResizable={false}
                        margin={resizableGrid.margin}
                        containerPadding={resizableGrid.containerPadding}
                        resizeHandle={false}
                        autoSize={resizableGrid.autoSize}
                        isBounded={resizableGrid.isBounded}
                        useCSSTransforms={false}
                    >
                        {charts.map((el) => {
                            return (
                                <div key={el.dataGrid.i} className={`${styles['report-builder-container__chart-container']}`} data-grid={el.dataGrid}>
                                    <LibraryComponentFactory
                                        hoveredFilter={''}
                                        hoveredComponents={[]}
                                        component={el}
                                        onPinComponent={() => {}}
                                        onUnpinComponent={() => {}}
                                        pinnedComponents={[]}
                                        onMouseEnterInfoIcon={() => {}}
                                        onMouseLeaveInfoIcon={() => {}}
                                        salesDataTimeWindow={'month'}
                                        type={'previewReport'}
                                    />
                                </div>
                            )
                        })}
                    </ResponsiveGridLayout>
                )}
            </ChartReadyProvider>
        </div>
    )
})

ReportBuilder.propTypes = {}

export default ReportBuilder
