import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGeo } from '../../../hooks/useGeo';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { salesGeoMock } from './mock';
import { useFilter } from '../../../providers/filter-context';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { ContentContainer, Loader, MapContainerNew, Spinner } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays the geographical location of where product sales occurred
 */

export const SalesGeo = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isPreview,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { i18n } = useTranslation();
        const { t } = useCustomTranslation();
        const [modal, toggleModal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            mouseOverMap,
            clickMap,
            setLoading,
            setValue
        } = useGeo(salesGeoMock, modal);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(salesGeoMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        dataClone.loading = false;
                        dataClone.ready = true;
                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            type
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'clientGeoChartFilter') {
                    if (isMounted.current) toggleModal(true);
                }
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings,
                toggleModal
            ]
        );

        return (
            <>
                <div
                    className={
                        isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'
                    }
                    style={{ zIndex: 'inherit' }}
                >
                    <ContentContainer
                        attr={data.attr}
                        titleText={t(`components:${data.attr}.title`)}
                        disabled={data.disabled}
                        isForecast={data.isForecast}
                        legend={data.legend}
                        settings={data.settings}
                        onClickSettingsIcon={toggleComponentSettings}
                        onClickSettingsOptions={handleSelectSettingsOption}
                        onClickSettingsView={handleSelectSettingsOption}
                        onCLickSettintsSelected={handleSelectSettingsOption}
                        onCloseSettings={toggleComponentSettings}
                        // hovered={hovered}
                        onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                        onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                        pinned={pinned}
                        onPinComponent={onPinComponent}
                        onUnpinComponent={onUnpinComponent}
                        hideInfoIcon={true}
                        hidePinIcon={isExample || isPreview || isReportExample}
                        hideSettingsIcon={isExample}
                    >
                        {data.loading && <Spinner />}
                        {!data.loading && (
                            <React.Fragment>
                                <MapContainerNew
                                    translate={t}
                                    risk1Data={data.risk1Data}
                                    risk2Data={data.risk2Data}
                                    risk3Data={data.risk3Data}
                                    risk4Data={data.risk4Data}
                                    competitionData={data.competitionData}
                                    selectedData={data.selectedData}
                                    startingPosition={data.startingPosition}
                                    tooltipType={data.tooltipType}
                                    interactionDisabled={
                                        data.interactionDisabled
                                    }
                                    onMouseOverMap={mouseOverMap}
                                    onMouseClickMap={clickMap}
                                    selectedEl={data.selectedEl}
                                    showPopup={data.showPopup}
                                    scale={data.scale}
                                    minScale={data.minScale}
                                    minZoom={data.minZoom}
                                    flyEnabled={data.flyEnabled}
                                />
                            </React.Fragment>
                        )}
                    </ContentContainer>
                </div>
            </>
        );
    }
);

SalesGeo.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
