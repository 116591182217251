import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    AddComponentButton,
    PrimaryButton,
    ReturnButton,
    SecondaryButton
} from '../../../../';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
export const PreviewReportHeader = React.memo(
    ({
        dashboardTitle,
        notAvailableCharts,
        availableCharts,
        onCreateReport,
        onNavigate
    }) => {
        const { t } = useCustomTranslation();
        return (
            <div className="row w-100 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-between align-items-center">
                    <span className="header-title me-4">{dashboardTitle}</span>
                    <div className="d-flex">
                        <div className="me-2">
                            <SecondaryButton
                                text={t('buttons:return')}
                                onClick={() =>
                                    onNavigate('back', 'filterConfig')
                                }
                            />
                        </div>
                        <PrimaryButton
                            text={t('buttons:createReport')}
                            onClick={() =>
                                onCreateReport(
                                    availableCharts,
                                    notAvailableCharts,
                                    false
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
);

PreviewReportHeader.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
    /**
     * Allows the user to return to the correct template if on the workflow of creating a new dashboard
     */
    selectedOption: PropTypes.string,
    /**
     * Contains the list of library charts to populate the store in case the user updates the page layout
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Contains the list of charts when updating the layout
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Function allowing the user to 'go back'
     */
    onNavigate: PropTypes.func,
    /**
     * Fuction allowing the user to either save a new layout of a published page or creating a new page
     */
    onCreatePage: PropTypes.func
};
