import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { bubbleGeoMock } from './mock';
import { useFilter } from '../../../providers/filter-context';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useGeo } from '../../../hooks/useGeo';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { ContentContainer, Loader, MarkerMap, Spinner } from '../../';
import { TeamsService } from '../../../temp/test';
import { TEAM_COMPARISON_COLORS } from '../../../constants/color';
import { LOCATIONS } from '../../../constants/coordinates';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const BubbleGeo = React.memo(() => {
    const { filter } = useFilter();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const { i18n } = useTranslation();

    const {
        value: data,
        toggleComponentSettings,
        setLoading,
        setValue
    } = useGeo(bubbleGeoMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(bubbleGeoMock);
            try {
                if (isMounted.current) {
                    setLoading(false);
                }
                const teams = await TeamsService.getApiTeams({});
                const newDataList = [];
                dataClone.legend = [];
                teams.forEach((team, index) => {
                    dataClone.legend.push({
                        name: `${team.name}`,
                        color: TEAM_COMPARISON_COLORS[index % 10],
                        translated: true
                    });
                    newDataList.push({
                        id: `team${team.id}Small`,
                        data: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    properties: {
                                        id: `team${team.id}-2`,
                                        name: `${team.name}`,
                                        value: Math.random() * 1000000,
                                        interval: 10,
                                        segment: 10,
                                        team: `team${team.id}`,
                                        color: TEAM_COMPARISON_COLORS[
                                            index % 10
                                        ],
                                        radius: 1,
                                        lat: LOCATIONS[(index * 3 + 1) % 30][0],
                                        lon: LOCATIONS[(index * 3 + 1) % 30][1]
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [
                                            LOCATIONS[(index * 3 + 1) % 30][1],
                                            LOCATIONS[(index * 3 + 1) % 30][0]
                                        ]
                                    }
                                }
                            ]
                        },
                        styleSmall: {
                            id: `team${team.id}-style-small`,
                            type: 'circle',
                            innerRadius: 0.4,
                            paint: {
                                'circle-radius': 5,
                                'circle-color':
                                    TEAM_COMPARISON_COLORS[index % 10],
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `team${team.id}-style-large`,
                            type: 'circle',
                            outerRadius: 1.2,
                            paint: {
                                'circle-radius': 20,
                                'circle-color':
                                    TEAM_COMPARISON_COLORS[index % 10],
                                'circle-opacity': 0.3
                            }
                        }
                    });
                    newDataList.push({
                        id: `team${team.id}Medium`,
                        data: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    properties: {
                                        id: `team${team.id}-3`,
                                        name: `${team.name}`,
                                        value: Math.random() * 1000000,
                                        interval: 10,
                                        segment: 10,
                                        team: `team${team.id}`,
                                        color: TEAM_COMPARISON_COLORS[
                                            index % 10
                                        ],
                                        radius: 1,
                                        lat: LOCATIONS[(index * 3 + 2) % 30][0],
                                        lon: LOCATIONS[(index * 3 + 2) % 30][1]
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [
                                            LOCATIONS[(index * 3 + 2) % 30][1],
                                            LOCATIONS[(index * 3 + 2) % 30][0]
                                        ]
                                    }
                                }
                            ]
                        },
                        styleSmall: {
                            id: `team${team.id}-medium-style-small`,
                            type: 'circle',
                            innerRadius: 0.4,
                            paint: {
                                'circle-radius': 8,
                                'circle-color':
                                    TEAM_COMPARISON_COLORS[index % 10],
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `team${team.id}-medium-style-large`,
                            type: 'circle',
                            outerRadius: 1.2,
                            paint: {
                                'circle-radius': 50,
                                'circle-color':
                                    TEAM_COMPARISON_COLORS[index % 10],
                                'circle-opacity': 0.3
                            }
                        }
                    });
                    newDataList.push({
                        id: `team${team.id}Large`,
                        data: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    properties: {
                                        id: `team${team.id}-1`,
                                        name: `${team.name}`,
                                        value: Math.random() * 1000000,
                                        interval: 10,
                                        segment: 10,
                                        team: `team${team.id}`,
                                        color: TEAM_COMPARISON_COLORS[
                                            index % 10
                                        ],
                                        radius: 1,
                                        lat: LOCATIONS[(index * 3 + 3) % 30][0],
                                        lon: LOCATIONS[(index * 3 + 3) % 30][1]
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [
                                            LOCATIONS[(index * 3 + 3) % 30][1],
                                            LOCATIONS[(index * 3 + 3) % 30][0]
                                        ]
                                    }
                                }
                            ]
                        },
                        styleSmall: {
                            id: `team${team.id}-large-style-small`,
                            type: 'circle',
                            innerRadius: 0.4,
                            paint: {
                                'circle-radius': 10,
                                'circle-color':
                                    TEAM_COMPARISON_COLORS[index % 10],
                                'circle-opacity': 1
                            }
                        },
                        styleLarge: {
                            id: `team${team.id}-large-style-large`,
                            type: 'circle',
                            outerRadius: 1.2,
                            paint: {
                                'circle-radius': 35,
                                'circle-color':
                                    TEAM_COMPARISON_COLORS[index % 10],
                                'circle-opacity': 0.3
                            }
                        }
                    });
                });
                dataClone.dataList = newDataList;
                verifyComponent(dataClone);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [filter, isMounted, setLoading, setValue, t]);

    const handleSelectSettingsOption = useCallback(() => {
        if (isMounted.current) toggleComponentSettings();
    }, [isMounted, toggleComponentSettings]);

    return (
        <ContentContainer
            attr={data.attr}
            titleText={t(`components:${data.attr}.title`)}
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
            onClickSettingsView={handleSelectSettingsOption}
            onCLickSettintsSelected={handleSelectSettingsOption}
            onCloseSettings={toggleComponentSettings}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <MarkerMap
                    translate={t}
                    dataList={data.dataList}
                    startingPosition={data.startingPosition}
                    tooltipType={data.tooltipType}
                    interactionDisabled={data.interactionDisabled}
                    selectedEl={data.selectedEl}
                    showPopup={data.showPopup}
                    scale={data.scale}
                    minScale={data.minScale}
                    minZoom={data.minZoom}
                    flyEnabled={data.flyEnabled}
                    customScale={10}
                />
            )}
        </ContentContainer>
    );
});
