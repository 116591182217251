import React from 'react'
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg'
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg'

export const revenueSalesForecastMock = {
    loading: true,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'revenueSalesForecastChart',
    isForecast: true,
    titleText: '',
    areaAttr: [
        {
            dataKey: 'revenueForecastIntervalValue',
            color: '#2cd9c5',
            dashArray: '3 3',
            strokeWidth: 2,
            hideDot: true,
            forecastGradient: true,
            fillOpacity: 0.9,
            lineType: 'monotone',
        },
        {
            dataKey: 'revenueForecastValue',
            color: '#2cd9c5',
            isReverseColor: true,
            strokeWidth: 2,
            lineType: 'monotone',
        },
        {
            dataKey: 'revenue',
            color: '#2cd9c5',
            strokeWidth: 2,
            lineType: 'monotone',
        },
    ],
    categoryAxis: 'month',
    data: [
        {
            revenue: 26630,
            time: '2020-01-01',
            day: '01 Jan 2020',
            week: '01 Jan 2020',
            month: 'Jan 2020',
            quarter: 'Q1 2020',
            year: '2020',
        },
        {
            revenue: 25299,
            time: '2020-02-01',
            day: '01 Fev 2020',
            week: '01 Fev 2020',
            month: 'Feb 2020',
            quarter: 'Q1 2020',
            year: '2020',
        },
        {
            revenue: 27947,
            time: '2020-03-01',
            day: '01 Mar 2020',
            week: '01 Mar 2020',
            month: 'Mar 2020',
            quarter: 'Q1 2020',
            year: '2020',
        },
        {
            revenue: 25732,
            time: '2020-04-01',
            day: '01 Abr 2020',
            week: '01 Abr 2020',
            month: 'Apr 2020',
            quarter: 'Q2 2020',
            year: '2020',
        },
        {
            revenue: 24579,
            time: '2020-05-01',
            day: '01 Maio 2020',
            week: '01 Maio 2020',
            month: 'May 2020',
            quarter: 'Q2 2020',
            year: '2020',
        },
        {
            revenue: 27426,
            time: '2020-06-01',
            day: '01 Jun 2020',
            week: '01 Jun 2020',
            month: 'Jun 2020',
            quarter: 'Q2 2020',
            year: '2020',
        },
        {
            revenue: 24128,
            time: '2020-07-01',
            day: '01 Jul 2020',
            week: '01 Jul 2020',
            month: 'Jul 2020',
            quarter: 'Q3 2020',
            year: '2020',
        },
        {
            revenue: 27457,
            time: '2020-08-01',
            day: '01 Ago 2020',
            week: '01 Ago 2020',
            month: 'Aug 2020',
            quarter: 'Q3 2020',
            year: '2020',
        },
        {
            revenue: 25844,
            time: '2020-09-01',
            day: '01 Set 2020',
            week: '01 Set 2020',
            month: 'Sep 2020',
            quarter: 'Q3 2020',
            year: '2020',
        },
        {
            revenue: 26781,
            time: '2020-10-01',
            day: '01 Out 2020',
            week: '01 Out 2020',
            month: 'Oct 2020',
            quarter: 'Q4 2020',
            year: '2020',
        },
        {
            revenue: 26193,
            time: '2020-11-01',
            day: '01 Nov 2020',
            week: '01 Nov 2020',
            month: 'Nov 2020',
            quarter: 'Q4 2020',
            year: '2020',
        },
        {
            revenue: 31314,
            time: '2020-12-01',
            day: '01 Dez 2020',
            week: '01 Dez 2020',
            month: 'Dec 2020',
            quarter: 'Q4 2020',
            year: '2020',
        },
        {
            revenue: 26982,
            time: '2021-01-01',
            day: '01 Jan 2021',
            week: '01 Jan 2021',
            month: 'Jan 2021',
            quarter: 'Q1 2021',
            year: '2021',
        },
        {
            revenue: 23583,
            time: '2021-02-01',
            day: '01 Fev 2021',
            week: '01 Fev 2021',
            month: 'Feb 2021',
            quarter: 'Q1 2021',
            year: '2021',
        },
        {
            revenue: 27019,
            time: '2021-03-01',
            day: '01 Mar 2021',
            week: '01 Mar 2021',
            month: 'Mar 2021',
            quarter: 'Q1 2021',
            year: '2021',
        },
        {
            revenue: 26648,
            time: '2021-04-01',
            day: '01 Abr 2021',
            week: '01 Abr 2021',
            month: 'Apr 2021',
            quarter: 'Q2 2021',
            year: '2021',
        },
        {
            revenue: 25927,
            time: '2021-05-01',
            day: '01 Maio 2021',
            week: '01 Maio 2021',
            month: 'May 2021',
            quarter: 'Q2 2021',
            year: '2021',
        },
        {
            revenue: 26443,
            time: '2021-06-01',
            day: '01 Jun 2021',
            week: '01 Jun 2021',
            month: 'Jun 2021',
            quarter: 'Q2 2021',
            year: '2021',
        },
        {
            revenue: 24787,
            time: '2021-07-01',
            day: '01 Jul 2021',
            week: '01 Jul 2021',
            month: 'Jul 2021',
            quarter: 'Q3 2021',
            year: '2021',
        },
        {
            revenue: 27031,
            time: '2021-08-01',
            day: '01 Ago 2021',
            week: '01 Ago 2021',
            month: 'Aug 2021',
            quarter: 'Q3 2021',
            year: '2021',
        },
        {
            revenue: 24196,
            time: '2021-09-01',
            day: '01 Set 2021',
            week: '01 Set 2021',
            month: 'Sep 2021',
            quarter: 'Q3 2021',
            year: '2021',
        },
        {
            revenue: 25793,
            time: '2021-10-01',
            day: '01 Out 2021',
            week: '01 Out 2021',
            month: 'Oct 2021',
            quarter: 'Q4 2021',
            year: '2021',
        },
        {
            revenue: 29188,
            time: '2021-11-01',
            day: '01 Nov 2021',
            week: '01 Nov 2021',
            month: 'Nov 2021',
            quarter: 'Q4 2021',
            year: '2021',
        },
        {
            revenue: 28279,
            time: '2021-12-01',
            day: '01 Dez 2021',
            week: '01 Dez 2021',
            month: 'Dec 2021',
            quarter: 'Q4 2021',
            year: '2021',
        },
        {
            revenue: 28312,
            time: '2022-01-01',
            day: '01 Jan 2022',
            week: '01 Jan 2022',
            month: 'Jan 2022',
            quarter: 'Q1 2022',
            year: '2022',
        },
        {
            revenue: 24325,
            time: '2022-02-01',
            day: '01 Fev 2022',
            week: '01 Fev 2022',
            month: 'Feb 2022',
            quarter: 'Q1 2022',
            year: '2022',
        },
        {
            revenue: 26770,
            time: '2022-03-01',
            day: '01 Mar 2022',
            week: '01 Mar 2022',
            month: 'Mar 2022',
            quarter: 'Q1 2022',
            year: '2022',
        },
        {
            revenue: 27240,
            time: '2022-04-01',
            day: '01 Abr 2022',
            week: '01 Abr 2022',
            month: 'Apr 2022',
            quarter: 'Q2 2022',
            year: '2022',
        },
        {
            revenue: 26212,
            time: '2022-05-01',
            day: '01 Maio 2022',
            week: '01 Maio 2022',
            month: 'May 2022',
            quarter: 'Q2 2022',
            year: '2022',
        },
        {
            revenue: 27062,
            time: '2022-06-01',
            day: '01 Jun 2022',
            week: '01 Jun 2022',
            month: 'Jun 2022',
            quarter: 'Q2 2022',
            year: '2022',
        },
        {
            revenue: 25569,
            time: '2022-07-01',
            day: '01 Jul 2022',
            week: '01 Jul 2022',
            month: 'Jul 2022',
            quarter: 'Q3 2022',
            year: '2022',
        },
        {
            revenue: 25085,
            time: '2022-08-01',
            day: '01 Ago 2022',
            week: '01 Ago 2022',
            month: 'Aug 2022',
            quarter: 'Q3 2022',
            year: '2022',
        },
        {
            revenue: 22610,
            time: '2022-09-01',
            day: '01 Set 2022',
            week: '01 Set 2022',
            month: 'Sep 2022',
            quarter: 'Q3 2022',
            year: '2022',
        },
        {
            revenue: 25652,
            time: '2022-10-01',
            day: '01 Out 2022',
            week: '01 Out 2022',
            month: 'Oct 2022',
            quarter: 'Q4 2022',
            year: '2022',
        },
        {
            revenue: 26033,
            time: '2022-11-01',
            day: '01 Nov 2022',
            week: '01 Nov 2022',
            month: 'Nov 2022',
            quarter: 'Q4 2022',
            year: '2022',
        },
        {
            revenue: 25130,
            time: '2022-12-01',
            day: '01 Dez 2022',
            week: '01 Dez 2022',
            month: 'Dec 2022',
            quarter: 'Q4 2022',
            year: '2022',
        },
        {
            revenue: 28300,
            time: '2023-01-01',
            day: '01 Jan 2023',
            week: '01 Jan 2023',
            month: 'Jan 2023',
            quarter: 'Q1 2023',
            year: '2023',
        },
        {
            revenue: 24620,
            time: '2023-02-01',
            day: '01 Fev 2023',
            week: '01 Fev 2023',
            month: 'Feb 2023',
            quarter: 'Q1 2023',
            year: '2023',
        },
        {
            revenue: 27558,
            time: '2023-03-01',
            day: '01 Mar 2023',
            week: '01 Mar 2023',
            month: 'Mar 2023',
            quarter: 'Q1 2023',
            year: '2023',
        },
        {
            revenue: 28502,
            time: '2023-04-01',
            day: '01 Abr 2023',
            week: '01 Abr 2023',
            month: 'Apr 2023',
            quarter: 'Q2 2023',
            year: '2023',
        },
        {
            revenue: 24171,
            time: '2023-05-01',
            day: '01 Maio 2023',
            week: '01 Maio 2023',
            month: 'May 2023',
            quarter: 'Q2 2023',
            year: '2023',
        },
        {
            revenue: 24764,
            time: '2023-06-01',
            day: '01 Jun 2023',
            week: '01 Jun 2023',
            month: 'Jun 2023',
            quarter: 'Q2 2023',
            year: '2023',
        },
        {
            revenue: 26970,
            time: '2023-07-01',
            day: '01 Jul 2023',
            week: '01 Jul 2023',
            month: 'Jul 2023',
            quarter: 'Q3 2023',
            year: '2023',
        },
        {
            revenue: 28179,
            time: '2023-08-01',
            day: '01 Ago 2023',
            week: '01 Ago 2023',
            month: 'Aug 2023',
            quarter: 'Q3 2023',
            year: '2023',
        },
        {
            revenue: 24865,
            time: '2023-09-01',
            day: '01 Set 2023',
            week: '01 Set 2023',
            month: 'Sep 2023',
            quarter: 'Q3 2023',
            year: '2023',
        },
        {
            revenue: 27082,
            time: '2023-10-01',
            day: '01 Out 2023',
            week: '01 Out 2023',
            month: 'Oct 2023',
            quarter: 'Q4 2023',
            year: '2023',
        },
        {
            revenue: 25392,
            time: '2023-11-01',
            day: '01 Nov 2023',
            week: '01 Nov 2023',
            month: 'Nov 2023',
            quarter: 'Q4 2023',
            year: '2023',
        },
        {
            revenue: 26399,
            time: '2023-12-01',
            day: '01 Dez 2023',
            week: '01 Dez 2023',
            month: 'Dec 2023',
            quarter: 'Q4 2023',
            year: '2023',
        },
        {
            revenue: 29152,
            time: '2024-01-01',
            day: '01 Jan 2024',
            week: '01 Jan 2024',
            month: 'Jan 2024',
            quarter: 'Q1 2024',
            year: '2024',
        },
        {
            revenue: 25161,
            time: '2024-02-01',
            day: '01 Fev 2024',
            week: '01 Fev 2024',
            month: 'Feb 2024',
            quarter: 'Q1 2024',
            year: '2024',
        },
        {
            revenue: 26373,
            time: '2024-03-01',
            day: '01 Mar 2024',
            week: '01 Mar 2024',
            month: 'Mar 2024',
            quarter: 'Q1 2024',
            year: '2024',
        },
        {
            revenue: 20402,
            time: '2024-04-01',
            day: '01 Abr 2024',
            week: '01 Abr 2024',
            month: 'Apr 2024',
            quarter: 'Q2 2024',
            year: '2024',
            revenueForecastIntervalValue: [20402, 20402],
            revenueForecastValue: 20402,
            forecastGradient: 20402,
        },
        {
            forecastGradient: 17000,
            revenueForecastValue: 22000,
            revenueForecastIntervalValue: [17000, 27000],
            time: '2024-05-01',
            day: '01 Maio 2024',
            week: '01 Maio 2024',
            month: 'May 2024',
            quarter: 'Q2 2024',
            year: '2024',
        },
        {
            forecastGradient: 15000,
            revenueForecastValue: 20000,
            revenueForecastIntervalValue: [15000, 26000],
            time: '2024-06-01',
            day: '01 Jun 2024',
            week: '01 Jun 2024',
            month: 'Jun 2024',
            quarter: 'Q2 2024',
            year: '2024',
        },
        {
            forecastGradient: 16000,
            revenueForecastValue: 21000,
            revenueForecastIntervalValue: [16000, 26000],
            time: '2024-07-01',
            day: '01 Jul 2024',
            week: '01 Jul 2024',
            month: 'Jul 2024',
            quarter: 'Q3 2024',
            year: '2024',
        },
    ],
    dateGrouper: '',
    gradient: 0.2,
    lineType: 'monotone',
    maxValue: 20000000,
    minValue: 0,

    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'salesForecast-monthly',
    unitsOrValues: 'values',
    margin: {
        top: 0,
        right: 40,
        left: 0,
        bottom: 0,
    },
    chartOptions: [
        { name: 'avgT', label: '' },
        { name: 'avgP', label: '' },
    ],
    interactionDisabled: true,
    suffix: 'revenueSalesForecastChartAuxbar',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: [
                {
                    name: 'showWeather',
                    label: '',
                    icon: 'default',
                    iconDefault: icoCheckboxEmpty,
                    iconSelected: icoCheckboxSelected,
                },
            ],
        },
        options: {
            label: '',
            options: [
                //
            ],
        },
    },
}
