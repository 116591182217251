import 'bootstrap/dist/css/bootstrap.css';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './user-information.module.scss';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { userInformationMock } from './mock';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { useModalPopup } from '../../../providers/modal-popup-context';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../../../temp/test';
import { useCurrentUser } from '../../../providers/current-user-context';
import {
    GlobalModalPopupManagerInformation,
    Loader,
    Spinner,
    TerciaryButton
} from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays general information about a selected manager
 */

export const UserInformation = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();
    const {
        steps,
        selectedTutorialStep,
        currentStep,
        setCurrentStep,
        toggleIsOpen
    } = useModalPopup();
    const {
        value: data,
        setLoading,
        setValue
    } = useSimpleComponent(userInformationMock);
    useEffect(() => {
        async function fill() {
            if (isMounted.current) setLoading(true);
            const dataClone = _.cloneDeep(userInformationMock);
            try {
                const result = await ProfileService.getApiProfileUser({
                    uuid: currentUser.uuid
                });
                dataClone.username = result.username;
                dataClone.firstName = result.name.split(' ')[0];
                dataClone.lastName = result.name.split(' ')[1];
                dataClone.name = result.name;
                dataClone.email = result.email;
                dataClone.phone = result.mobile_phone;
                dataClone.myTeams = result.teams
                    ? result.teams.map((team) => {
                          return team.name;
                      })
                    : [];
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [isMounted, setValue, setLoading, t, currentUser.uuid]);

    return (
        <>
            {data.loading && <Spinner />}
            {!data.loading && (
                <React.Fragment>
                    {steps && selectedTutorialStep !== '' && (
                        <GlobalModalPopupManagerInformation
                            steps={steps}
                            toggleIsOpen={toggleIsOpen}
                            selectedTutorialStep={selectedTutorialStep}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                        />
                    )}
                    <div className={`${styles['user-information-container']}`}>
                        <div className="row mx-0 w-100 h-100">
                            <div className="col px-0 h-100">
                                <div
                                    className="row mx-0"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <h1
                                            className={`${styles['user-information-container__name']}`}
                                        >
                                            {data.username ? data.username : ''}
                                        </h1>
                                        <h1
                                            className={`${styles['user-information-container__name-legend']}`}
                                        >
                                            {t(
                                                `components:managerInformation.username`
                                            )}
                                        </h1>
                                    </div>
                                    <div className="col-auto px-0">
                                        <TerciaryButton
                                            text={t('buttons:editProfile')}
                                            onClick={() => {
                                                navigate('/profile/edit');
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="row mx-0"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div
                                        className="col-auto px-0"
                                        style={{ marginRight: '2rem' }}
                                    >
                                        <h1
                                            className={`${styles['user-information-container__title']}`}
                                        >
                                            {t(
                                                `components:managerInformation.firstName`
                                            )}
                                        </h1>
                                        <h1
                                            className={`${styles['user-information-container__content']}`}
                                        >
                                            {data.firstName
                                                ? data.firstName
                                                : '-'}
                                        </h1>
                                    </div>
                                    <div
                                        className="col-auto px-0"
                                        style={{ marginRight: '2rem' }}
                                    >
                                        <h1
                                            className={`${styles['user-information-container__title']}`}
                                        >
                                            {t(
                                                `components:managerInformation.lastName`
                                            )}
                                        </h1>
                                        <h1
                                            className={`${styles['user-information-container__content']}`}
                                        >
                                            {data.lastName
                                                ? data.lastName
                                                : '-'}
                                        </h1>
                                    </div>
                                    <div
                                        className="col-auto px-0"
                                        style={{ marginRight: '2rem' }}
                                    >
                                        <h1
                                            className={`${styles['user-information-container__title']}`}
                                        >
                                            {t(
                                                `components:managerInformation.phone`
                                            )}
                                        </h1>
                                        <h1
                                            className={`${styles['user-information-container__content']}`}
                                        >
                                            {data.phone ? data.phone : '-'}
                                        </h1>
                                    </div>
                                    <div className="col px-0">
                                        <h1
                                            className={`${styles['user-information-container__title']}`}
                                        >
                                            {t(
                                                `components:managerInformation.email`
                                            )}
                                        </h1>
                                        <h1
                                            className={`${styles['user-information-container__content']}`}
                                        >
                                            {data.email ? data.email : '-'}
                                        </h1>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <div className="col px-0">
                                                <h1
                                                    className={`${styles['user-information-container__title']}`}
                                                >
                                                    {t(
                                                        `components:managerInformation.teams`
                                                    )}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                {data.myTeams.length &&
                                                data.myTeams.length > 0
                                                    ? data.myTeams.map(
                                                          (team, index) => {
                                                              return (
                                                                  <div
                                                                      key={
                                                                          index
                                                                      }
                                                                      className={`text-label`}
                                                                  >
                                                                      {
                                                                          team.name
                                                                      }
                                                                  </div>
                                                              );
                                                          }
                                                      )
                                                    : '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </>
    );
});

UserInformation.propTypes = {};
