import React from 'react'

export const productStockMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    titleText: '',
    legend: undefined,
    type: 'Map',
    barStackedAttr: [
        {
            dataKey: 'productStock',
            gradientColors: ['199788', '26b5a4'],
            stackId: 'a',
            minPointSize: 70,
            radius: [0, 100, 100, 0],
        },
    ],
    barWidth: 20,
    categoryAxis: 'productName',
    data: [
        {
            productName: 'Neurolynk',
            productStock: 2282,
        },
        {
            productName: 'Dancity',
            productStock: 2008,
        },
        {
            productName: 'Zillanet',
            productStock: 3437,
        },
        {
            productName: 'Zillatide',
            productStock: 2294,
        },
        {
            productName: 'Zathin',
            productStock: 2075,
        },
        {
            productName: 'Tres-Zap',
            productStock: 3748,
        },
    ],
    orientation: 'vertical',
    tooltipType: 'productStock',
    unitsOrValues: 'units',
    loading: true,
    categoryLabel: '',
    margin: {
        top: 5,
        left: 0,
        right: 20,
        bottom: 5,
    },
    attr: 'productStockChart',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
