import React from 'react';

export const newClientsMock = {
    hoverStyle: '',
    desc: '',
    loading: true,
    title: '',
    titleColor: '#606060',
    content: '80',
    contentAux: '13',
    timeInterval: 'monthly',
    contentType: '',
    contentColor: '#06a4e7',
    contentFractionTotal: '',
    contentAuxType: '',
    contentAuxColor: '#06a4e7',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaData: [
        {
            date: '2020-01-01',
            value3: 1.663294818529014
        },
        {
            date: '2020-02-01',
            value3: 1.568392717893731
        },
        {
            date: '2020-03-01',
            value3: 3
        },
        {
            date: '2020-04-01',
            value3: 3
        }
    ],
    areaAttr: [
        {
            color: '#6ad6c5',
            stroke: '#6ad6c5',
            dataKey: 'value3',
            name: 'Value'
        }
    ],
    categoryAxis: 'categoryAttr',
    data: [
        { exampleAttr1: 10, categoryAttr: 'yo1' },
        { exampleAttr1: 15, categoryAttr: 'yo2' },
        { exampleAttr1: 5, categoryAttr: 'yo2.1' },
        { exampleAttr1: 30, categoryAttr: 'yo2.2' },
        { exampleAttr1: 5, categoryAttr: 'yo3' }
    ],
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    attr: 'newClientsCard',
    prefix: 'newClientsCard',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    }
};
