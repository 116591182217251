import styles from './custom-dashboard.module.scss'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import PropTypes from 'prop-types'
import icoDashboard from '../../../assets/images/ico-dashboard.svg'
import icoMagGlass from '../../../assets/images/ico-mag-glass.svg'
import _ from 'lodash'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useAuxBar } from '../../../providers/aux-bar-context'
import { useModalPopup } from '../../../providers/modal-popup-context'
import { useDashboardSettings } from '../../../providers/dashboard-settings-context'
import { useArray } from '../../../hooks/useArray'
import { PagesService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { useFilter } from '../../../providers/filter-context'
import { DashboardHeaderFactory, GlobalModalPopupCustomDashboard, LibraryComponentFactory } from '../../../components'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

const ResponsiveGridLayout = WidthProvider(Responsive)

/**
 * Provides a page layout that allows users to view analytics
 */

const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }

const CustomDashboard = React.memo(
    ({
        templateName,
        templateOption,
        onChooseTemplate,
        onReturn,
        lastState,
        onUpdateRoute,
        onPublishRoute,
        selectedOption,
        onCreatePage,
        onCreateReport,
        onNavigate,
        initialDashboardTitle,
        initialUrl,
        initialNotAvailableCharts,
        initialAvailableCharts,
        dashboardTitle,
        availableCharts,
        notAvailableCharts,
        setNotAvailableCharts,
        onMouseEnterComponent,
        onMouseLeaveComponent,
        resizableGrid,
        onSetLayout,
        hoveredFilter,
        hoveredComponents,
        type = '',
        isHome,
        onDropdownCheck,
        filterInputs,
        setFilterInputs,
        onChangeContent,
        onChangeSize,
        isReady,
        isPreview,
    }) => {
        const ref = useRef()
        const { filter } = useFilter()
        const [currentWidthSectionMultiplier, setCurrentWidthSectionMultiplier] = useState(1)

        const newSize = useCallback((width) => {
            if (width < 600) {
                setCurrentWidthSectionMultiplier(0.6)
            } else if (width < 800) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1200) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1400) {
                setCurrentWidthSectionMultiplier(0.8)
            } else {
                setCurrentWidthSectionMultiplier(1)
            }
            return
        }, [])

        useEffect(() => {
            // Create a ResizeObserver instance
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    // Access the new width and height
                    const { width } = entry.contentRect
                    newSize(width)
                }
            })

            resizeObserver.observe(document.documentElement)
            return () => {
                resizeObserver.disconnect()
            }
        }, [newSize])

        const [width, setWidth] = useState(0)
        const [height, setHeight] = useState(0)
        const isMounted = useIsMounted()
        const { availableOptions, setAvailableOptions, setChartFilters } = useAuxBar()
        const { steps, selectedTutorialStep, currentStep, setCurrentStep, toggleIsOpen } = useModalPopup()
        const { t } = useCustomTranslation()
        const { isEdit } = useDashboardSettings()
        const { array: pinnedComponents, setArray: setPinnedComponents, push: addPinnedComponents, remove: removePinnedComponents } = useArray([])

        useEffect(() => {
            if (ref.current) {
                setWidth(ref.current.offsetWidth)
                setHeight(ref.current.offsetHeight)
            }
        }, [])

        const charts = initialNotAvailableCharts ? initialNotAvailableCharts : notAvailableCharts

        const handleUnpinComponent = useCallback(
            async (attr) => {
                const getPageData = await PagesService.getApiPagesMyPages()
                const pageContent = JSON.parse(getPageData.homepage.content)
                const notAvailableChartsClone = _.cloneDeep(pageContent.notAvailableCharts)

                const notAvailableChartsIndex = _.findIndex(notAvailableChartsClone, (el) => el.type === attr)

                if (notAvailableChartsIndex !== -1) {
                    // Remove the unpinned component
                    notAvailableChartsClone.splice(notAvailableChartsIndex, 1)

                    // Assuming grid width is 12 units, adjust if different
                    const gridWidth = 12
                    // Initialize an array to keep track of the y position for each column
                    let columnsHeight = new Array(gridWidth).fill(0)

                    // Function to find the next available position for the chart
                    const findNextAvailablePosition = (chart) => {
                        let positionFound = false
                        let x = 0,
                            y = 0

                        while (!positionFound) {
                            // Find column with minimum height
                            let minHeight = Math.min(...columnsHeight)
                            x = columnsHeight.indexOf(minHeight)

                            // Check if the chart fits in the current columns
                            let fits = true
                            for (let i = x; i < x + chart.dataGrid.w && i < gridWidth; i++) {
                                if (columnsHeight[i] > minHeight) {
                                    fits = false
                                    break
                                }
                            }

                            if (fits && x + chart.dataGrid.w <= gridWidth) {
                                positionFound = true
                                y = minHeight
                            } else {
                                // Increase the minimum column height to force the search to move to the next possible position
                                columnsHeight[x] += 1
                            }
                        }

                        // Update columnsHeight for the width of the chart
                        for (let i = x; i < x + chart.dataGrid.w; i++) {
                            columnsHeight[i] = y + chart.dataGrid.h
                        }

                        return { x, y }
                    }

                    // Calculate new positions
                    notAvailableChartsClone.forEach((chart) => {
                        const { x, y } = findNextAvailablePosition(chart)
                        chart.dataGrid.x = x
                        chart.dataGrid.y = y
                    })

                    // Update the page content with the new layout
                    await onUpdateRoute(pageContent.dashboardTitle, pageContent.availableCharts, notAvailableChartsClone)

                    const filterInputs = await onDropdownCheck(notAvailableChartsClone)
                    if (filterInputs.length > 0) {
                        setFilterInputs(filterInputs)
                        setAvailableOptions(['search'])
                    } else {
                        setAvailableOptions([])
                    }
                    if (isHome) setNotAvailableCharts(notAvailableChartsClone)
                    else {
                        const pinnedIndex = _.findIndex(pinnedComponents, (el) => {
                            return el === attr
                        })
                        if (pinnedIndex !== -1) {
                            if (isMounted.current) removePinnedComponents(pinnedIndex)
                        }
                    }
                }
            },
            [
                isMounted,
                removePinnedComponents,
                pinnedComponents,
                isHome,
                onUpdateRoute,
                onDropdownCheck,
                setAvailableOptions,
                setNotAvailableCharts,
                setFilterInputs,
            ]
        )

        const handlePinComponent = useCallback(
            async (attr) => {
                if (
                    steps &&
                    selectedTutorialStep !== '' &&
                    steps[selectedTutorialStep]['steps'][currentStep]['waitUntilTrigger'] &&
                    steps[selectedTutorialStep]['steps'][currentStep]['triggerPoint'] === 'componentPinned'
                ) {
                    setCurrentStep(currentStep + 1)
                }
                const getPageData = await PagesService.getApiPagesMyPages()
                const notAvailableChartsClone = _.cloneDeep(JSON.parse(getPageData.homepage.content).notAvailableCharts)
                const index = _.findIndex(charts, (el) => {
                    return el.type === attr
                })

                if (index !== -1) {
                    let maxYH = 0
                    notAvailableChartsClone.map((el) => {
                        if (el.dataGrid.y + el.dataGrid.h > maxYH) {
                            maxYH = el.dataGrid.y + el.dataGrid.h
                        }
                        return el
                    })
                    const matrix = Array(maxYH)
                        .fill()
                        .map(() => Array(12).fill())

                    notAvailableChartsClone.map((el) => {
                        _.range(el.dataGrid.y, el.dataGrid.y + el.dataGrid.h).map((el2) => {
                            _.range(el.dataGrid.x, el.dataGrid.x + el.dataGrid.w).map((el3) => {
                                matrix[el2][el3] = 1
                                return el3
                            })
                            return el2
                        })
                        return el
                    })
                    let componentX = _.range(0, charts[index].dataGrid.w)
                    let componentY = _.range(0, charts[index].dataGrid.h)
                    let initialX = 0
                    let initialY = maxYH
                    let canPlace = false
                    for (let el2 of _.range(0, maxYH)) {
                        for (let el3 of _.range(0, 12)) {
                            canPlace = true
                            for (let el4 of componentY) {
                                for (let el5 of componentX) {
                                    if (el2 + el4 > maxYH - 1 || el3 + el5 > 12 - 1 || matrix[el2 + el4][el3 + el5] === 1) {
                                        canPlace = false
                                        break
                                    }
                                }
                            }
                            if (canPlace) {
                                initialX = el3
                                initialY = el2
                                break
                            }
                        }
                        if (canPlace) {
                            break
                        }
                    }
                    const newComponent = _.cloneDeep(charts[index])
                    newComponent.dataGrid.x = initialX
                    newComponent.dataGrid.y = initialY
                    notAvailableChartsClone.push(newComponent)
                    await onUpdateRoute(
                        JSON.parse(getPageData.homepage.content).dashboardTitle,
                        JSON.parse(getPageData.homepage.content).availableCharts,
                        notAvailableChartsClone
                    )
                    if (isMounted.current) addPinnedComponents(attr)
                    return
                }
            },
            [addPinnedComponents, isMounted, charts, onUpdateRoute, currentStep, selectedTutorialStep, setCurrentStep, steps]
        )

        useEffect(() => {
            async function fill() {
                try {
                    const getPageData = await PagesService.getApiPagesMyPages()
                    setPinnedComponents([])
                    JSON.parse(getPageData.homepage.content).notAvailableCharts.map((el) => {
                        if (isMounted.current) addPinnedComponents(el.type)
                        return el
                    })
                    JSON.parse(getPageData.homepage.content).availableCharts.map((el) => {
                        if (isMounted.current) addPinnedComponents(el.type)
                        return el
                    })
                } catch (err) {
                    errorHandler(err)
                }
            }
            fill()
        }, [addPinnedComponents, isMounted, setPinnedComponents])

        const isDashboardFiltered = useMemo(() => {
            const dashboardFilters = []
            charts.map((el) => {
                if (el.config.filter && el.config.filter.length > 0) {
                    el.config.filter.map((el2) => {
                        if (!dashboardFilters.includes(el2)) {
                            dashboardFilters.push(el2)
                        }
                        return el2
                    })
                }
                return el
            })
            return _.some(Object.entries(filter), (el) => {
                if (!Array.isArray(el[1])) {
                    return dashboardFilters.includes(el[0]) && el[1] !== ''
                } else if (Array.isArray(el[1])) {
                    return dashboardFilters.includes(el[0]) && el[1].length > 0
                }
            })
        }, [charts, filter])

        return (
            <div className="row mx-0 w-100 h-100">
                <div className="col-12 w-100 d-flex flex-column" style={{ padding: '0 2rem 2rem 2rem' }}>
                    <DashboardHeaderFactory
                        type={type}
                        isPreview={isPreview}
                        templateOption={templateOption}
                        templateName={templateName}
                        onChooseTemplate={onChooseTemplate}
                        onReturn={onReturn}
                        isEdit={isEdit}
                        lastState={lastState}
                        selectedOption={selectedOption}
                        dashboardTitle={initialDashboardTitle ? initialDashboardTitle : dashboardTitle}
                        url={(initialUrl = initialUrl ? initialUrl : dashboardTitle)}
                        notAvailableCharts={charts}
                        availableCharts={initialAvailableCharts ? initialAvailableCharts : availableCharts}
                        onCreatePage={onCreatePage}
                        onCreateReport={onCreateReport}
                        onNavigate={onNavigate}
                        onUpdateRoute={onUpdateRoute}
                        onPublishRoute={onPublishRoute}
                    />
                    {charts &&
                        charts.length > 0 &&
                        availableOptions.includes('search') &&
                        !isDashboardFiltered &&
                        availableOptions &&
                        availableOptions.length > 0 && (
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{
                                    position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    pointerEvents: 'none',
                                }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="row mx-0 w-100 pb-1">
                                        <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                            <img
                                                loading="lazy"
                                                src={icoMagGlass}
                                                alt="icon"
                                                className={`${styles['custom-dashboard-container__no-selection-icon']}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100 pt-1">
                                        <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                            <span className={`${styles['custom-dashboard-container__no-selection-text']}`}>
                                                {t(`pages:customDashboard.noSelectionText.custom`)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    {charts && charts.length === 0 && isReady && (
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 pb-1">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <img
                                            loading="lazy"
                                            src={icoDashboard}
                                            alt="icon"
                                            className={`${styles['custom-dashboard-container__no-selection-icon']}`}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 pt-1">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <span className={`${styles['custom-dashboard-container__no-selection-text']}`}>
                                            {t(`pages:customDashboard.noData`)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(!availableOptions.includes('search') || (availableOptions.includes('search') && isDashboardFiltered)) &&
                        charts &&
                        charts.length !== 0 &&
                        ((availableOptions.length > 0 &&
                            !availableOptions.includes('none') &&
                            ((filterInputs && filterInputs.length > 0) || !filterInputs)) ||
                            (availableOptions.length === 0 &&
                                ((filterInputs && filterInputs.length === 0) ||
                                    (filterInputs && filterInputs.includes('none')) ||
                                    !filterInputs))) && (
                            <div className={`row w-100 mx-0 ${styles['custom-dashboard-container']}`} ref={ref}>
                                <div className="col px-0 d-flex flex-column align-items-center">
                                    {charts.length > 0 && (
                                        <ResponsiveGridLayout
                                            className="layout w-100 mx-0 mb-0"
                                            rowHeight={30 * currentWidthSectionMultiplier}
                                            maxRows={resizableGrid.maxRows}
                                            compactType={resizableGrid.compactType}
                                            verticalCompact={resizableGrid.verticalCompact}
                                            layout={resizableGrid.layout}
                                            cols={cols}
                                            onLayoutChange={() => {}}
                                            draggableCancel={'.react-draggable'}
                                            isDraggable={false}
                                            isResizable={false}
                                            resizeHandle={false}
                                            autoSize={resizableGrid.autoSize}
                                            isBounded={resizableGrid.isBounded}
                                            margin={resizableGrid.margin}
                                            containerPadding={resizableGrid.containerPadding}
                                            useCSSTransforms={false}
                                        >
                                            {charts.length > 0 &&
                                                charts.map((el, componentIndex) => {
                                                    return (
                                                        <div
                                                            key={el.dataGrid.i}
                                                            className={`${styles['custom-dashboard-container__chart-container']}`}
                                                            style={{
                                                                opacity: type === 'previewGeneration' ? 0 : 1,
                                                                animation:
                                                                    type === 'previewGeneration'
                                                                        ? `fadeIn 0.5s forwards ${componentIndex * 0.3}s`
                                                                        : undefined,
                                                                pointerEvents: type === 'previewGeneration' ? 'none' : 'auto',
                                                            }}
                                                            data-grid={{
                                                                ...el.dataGrid,
                                                            }}
                                                        >
                                                            {steps && selectedTutorialStep !== '' && (
                                                                <GlobalModalPopupCustomDashboard
                                                                    el={el}
                                                                    steps={steps}
                                                                    toggleIsOpen={toggleIsOpen}
                                                                    selectedTutorialStep={selectedTutorialStep}
                                                                    currentStep={currentStep}
                                                                    setCurrentStep={setCurrentStep}
                                                                />
                                                            )}
                                                            <LibraryComponentFactory
                                                                isPinActive
                                                                isExample={type === 'preview' || type === 'previewGeneration'}
                                                                hoveredFilter={hoveredFilter ? hoveredFilter : ''}
                                                                hoveredComponents={hoveredComponents ? hoveredComponents : []}
                                                                component={el}
                                                                onPinComponent={handlePinComponent}
                                                                onUnpinComponent={handleUnpinComponent}
                                                                pinnedComponents={pinnedComponents ? pinnedComponents : []}
                                                                onMouseEnterInfoIcon={onMouseEnterComponent ? onMouseEnterComponent : () => {}}
                                                                onMouseLeaveInfoIcon={onMouseLeaveComponent ? onMouseLeaveComponent : () => {}}
                                                                type={type}
                                                                width={width}
                                                                height={height}
                                                                onChangeContent={onChangeContent}
                                                                onChangeSize={onChangeSize}
                                                                salesDataTimeWindow={'month'}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                        </ResponsiveGridLayout>
                                    )}
                                </div>
                            </div>
                        )}
                </div>
                {/* {!isPreview && (
                    <div className="col-auto px-0">
                        <Auxbar
                            availableCharts={
                                initialAvailableCharts
                                    ? initialAvailableCharts
                                    : availableCharts
                            }
                            notAvailableCharts={charts}
                        />
                    </div>
                )} */}
            </div>
        )
    }
)

export default CustomDashboard

CustomDashboard.propTypes = {
    /**
     * Provides the user with the ability to return back to the initial step or the second step depending on where
     * the user chose to preview the dashboard layout
     */
    lastState: PropTypes.string,
    /**
     * Allows users to update dashboard being created
     */
    onUpdateRoute: PropTypes.func,
    /**
     * Allows users to publish dashboard being created
     */
    onPublishRoute: PropTypes.func,
    /**
     * Allows user to return back to the chosen template on the workflow
     */
    selectedOption: PropTypes.string,
    /**
     * Function to publish or draft new dashboard
     */
    onCreatePage: PropTypes.func,
    /**
     * Allows user to navigate between dashboard creation steps
     */
    onNavigate: PropTypes.func,
    /**
     * Labels the dashboard when coming from the preview path on page creation or editing
     */
    initialDashboardTitle: PropTypes.string,
    /**
     * Populates the layout when coming from the preview path on page creation or editing
     */
    initialNotAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Populates the library store when coming from the preview path on page creation or editing
     */
    initialAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Labels the dashboar
     */
    dashboardTitle: PropTypes.string,
    /**
     * Charts to populate store once the dashboard was published or drafted
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Charts to populate layout once the dashboard was published or drafted
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Propagates entering a component
     */
    onMouseEnterComponent: PropTypes.func,
    /**
     * Propagates leaving a component
     */
    onMouseLeaveComponent: PropTypes.func,
    /**
     * Provides a list of pinned components to populate the homepage
     */
    pinnedComponents: PropTypes.arrayOf(PropTypes.string),
    /**
     * Allows the user to pin a component
     */
    onPinComponent: PropTypes.func,
    /**
     * Allows the user to unpin a component
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Object containing layout details regarding each chart individually
     */
    resizableGrid: PropTypes.object,
    /**
     * Changes the layout of the grid
     */
    onSetLayout: PropTypes.func,
    /**
     * Provides manual control of loading page state
     */
    loading: PropTypes.bool,
    /**
     * Filter being hovered
     */
    hoveredFilter: PropTypes.string,
    /**
     * Components being hovered
     */
    hoveredComponents: PropTypes.arrayOf(PropTypes.string),
    /**
     * Step of current dashboard
     */
    pageState: PropTypes.string,
    /**
     * Type of dashboard header
     */
    type: PropTypes.string,
}
