export const invoiceListMock = {
    titleCounter: '0',
    data: [
        {
            invoiceId: '123456',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123457',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123458',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123459',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123460',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123461',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123462',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123463',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123464',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123465',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123466',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'paid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123467',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123468',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123469',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123470',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123471',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123472',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123473',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        },
        {
            invoiceId: '123474',
            billingDate: '2021-08-01',
            plan: 'Basic',
            Amount: '100',
            invoiceStatus: 'unpaid',
            action: {
                id: 1,
                options: []
            }
        }
    ],
    columns: [
        { path: 'invoiceId', label: '', sortable: true },
        { path: 'billingDate', label: '', sortable: true },
        { path: 'plan', label: '', sortable: true },
        { path: 'Amount', label: '', sortable: true },
        { path: 'invoiceStatus', label: '', sortable: true },
        { path: 'invoiceDownload', label: '', sortable: false }
    ],
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'invoiceList',
    disabled: true,
    loading: true
};
