import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import icoEmail from '../../../assets/images/ico-email.svg';
import icoText from '../../../assets/images/ico-text.svg';
import icoMedia from '../../../assets/images/ico-media.svg';
import icoCalls from '../../../assets/images/ico-calls.svg';
import icoUp from '../../../assets/images/ico-up.svg';
import icoDown from '../../../assets/images/ico-down.svg';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { sentimentGeneralDetailsMock } from './mock';
import styles from './sentiment-general-details.module.scss';
import { errorHandler } from '../../../utils/api';
import { SentimentService } from '../../../temp/test';
import _ from 'lodash';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { formatDate } from '../../../utils/date';
import { verifyComponent } from '../../../utils/component';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SentimentGeneralDetails = React.memo(({ analysisId }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(sentimentGeneralDetailsMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(sentimentGeneralDetailsMock);
            try {
                if (isMounted.current) {
                    setLoading(true);
                }
                const detailedResult =
                    await SentimentService.getApiNlpAnalysisOverview({
                        id: analysisId,
                        channel: undefined
                    });
                const channelResult =
                    await SentimentService.getApiNlpAnalysisChannelOverview({
                        id: analysisId
                    });
                dataClone.data = {
                    analysisName: detailedResult.name
                        ? detailedResult.name
                        : '-',
                    createdBy: detailedResult.created_by
                        ? detailedResult.created_by.name
                        : '-',
                    dateOfCreation: detailedResult.created_at
                        ? formatDate(detailedResult.created_at)
                        : '-',
                    beganAt: detailedResult.began_at
                        ? formatDate(detailedResult.began_at)
                        : '-',
                    endedAt: detailedResult.ended_at
                        ? formatDate(detailedResult.ended_at)
                        : '-',
                    keywordCount: detailedResult.keyword_count
                        ? detailedResult.keyword_count
                        : '-',
                    positiveKeywordCount: detailedResult.keyword_positive_count
                        ? detailedResult.keyword_positive_count
                        : '-',
                    neutralKeywordCount: detailedResult.keyword_neutral_count
                        ? detailedResult.keyword_neutral_count
                        : '-',
                    negativeKeywordCount: detailedResult.keyword_negative_count
                        ? detailedResult.keyword_negative_count
                        : '-',
                    texts: _.has(channelResult, 'text_sentiment')
                        ? channelResult.text_sentiment.count
                        : '-',
                    emails: _.has(channelResult, 'email_sentiment')
                        ? channelResult.email_sentiment.count
                        : '-',
                    calls: _.has(channelResult, 'call_sentiment')
                        ? channelResult.call_sentiment.count
                        : '-',
                    media: _.has(channelResult, 'media_sentiment')
                        ? channelResult.media_sentiment.count
                        : '-',
                    trend: _.has(detailedResult, 'avg_score_change')
                        ? detailedResult.avg_score_change.toFixed(1)
                        : '-'
                };
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [analysisId, isMounted, setLoading, setValue]);

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={undefined}
            >
                <div
                    className={`${styles['sentiment-general-details-container']}`}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`row mx-0 ${styles['sentiment-general-details-container__content-container']}`}
                        >
                            <div className="row mx-0 w-100 px-0">
                                <div className="col px-0 d-flex flex-column justify-content-between">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <div
                                                className={`row mx-0 ${styles['sentiment-general-details-container__content-container__details-container']}`}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0 h-100">
                                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                            <div
                                                                className="row w-100 mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.analysisName`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .analysisName
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                            <div
                                                                className="row w-100 mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.createdBy`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .createdBy
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                            <div
                                                                className="row w-100 mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.dateOfCreation`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .dateOfCreation
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                            <div
                                                                className="row w-100 mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.beganAt`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .beganAt
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                                            <div
                                                                className="row w-100 mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.endedAt`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__content-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .endedAt
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`row mx-0 ${styles['sentiment-general-details-container__content-container__details-container']}`}
                                            >
                                                <div className="col px-0">
                                                    <div className="row mx-0">
                                                        <div className="col px-0">
                                                            <div
                                                                className="row mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.keywordCount`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .keywordCount
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0">
                                                            <div
                                                                className="row mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.positiveKeywordCount`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                        style={{
                                                                            color: '#7dd081'
                                                                        }}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .positiveKeywordCount
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0">
                                                            <div
                                                                className="row mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.neutralKeywordCount`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                        style={{
                                                                            color: '#f0bb5f'
                                                                        }}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .neutralKeywordCount
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0">
                                                            <div
                                                                className="row mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.negativeKeywordCount`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                        style={{
                                                                            color: '#db6162'
                                                                        }}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .negativeKeywordCount
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col px-0">
                                                            <div
                                                                className="row mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.trend`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0">
                                                                <div className="col px-0">
                                                                    {data.data
                                                                        .trend >
                                                                        0 && (
                                                                        <span
                                                                            className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                            style={{
                                                                                color: '#7dd081'
                                                                            }}
                                                                        >
                                                                            +{' '}
                                                                        </span>
                                                                    )}
                                                                    {data.data
                                                                        .trend <
                                                                        0 && (
                                                                        <span
                                                                            className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                            style={{
                                                                                color: '#db6162'
                                                                            }}
                                                                        >
                                                                            -{' '}
                                                                        </span>
                                                                    )}
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                        style={
                                                                            data
                                                                                .data
                                                                                .trend >
                                                                            0
                                                                                ? {
                                                                                      color: '#34b53a'
                                                                                  }
                                                                                : data
                                                                                      .data
                                                                                      .trend <
                                                                                  0
                                                                                ? {
                                                                                      color: '#df0000'
                                                                                  }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        {Math.abs(
                                                                            data
                                                                                .data
                                                                                .trend
                                                                        )
                                                                            .toString()
                                                                            .substring(
                                                                                0,
                                                                                100
                                                                            )}
                                                                        %
                                                                    </span>
                                                                    {data.data
                                                                        .trend >
                                                                        0 && (
                                                                        <img
                                                                            loading="lazy"
                                                                            src={
                                                                                icoUp
                                                                            }
                                                                        ></img>
                                                                    )}
                                                                    {data.data
                                                                        .trend <
                                                                        0 && (
                                                                        <img
                                                                            loading="lazy"
                                                                            src={
                                                                                icoDown
                                                                            }
                                                                        ></img>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <div className={`row mx-0`}>
                                                <div className="col px-0">
                                                    <div className="row mx-0 h-100">
                                                        <div
                                                            className={`col me-2 ${styles['sentiment-general-details-container__card-container__details-container']}`}
                                                            style={{
                                                                backgroundImage: `url(${creationOptionBackground})`,
                                                                backgroundSize:
                                                                    'cover',
                                                                backgroundPosition:
                                                                    'center',
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div
                                                                className="row w-100 mx-0"
                                                                style={{
                                                                    marginBottom:
                                                                        '1rem'
                                                                }}
                                                            >
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.texts`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 d-flex justify-content-between">
                                                                <div className="col-auto px-0 pe-2">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoText
                                                                        }
                                                                        alt=""
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__icon']}`}
                                                                    />
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .texts
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`col me-2 ${styles['sentiment-general-details-container__card-container__details-container']}`}
                                                            style={{
                                                                backgroundImage: `url(${creationOptionBackground})`,
                                                                backgroundSize:
                                                                    'cover',
                                                                backgroundPosition:
                                                                    'center',
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.emails`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 d-flex justify-content-between">
                                                                <div className="col-auto px-0 pe-2">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoEmail
                                                                        }
                                                                        alt=""
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__icon']}`}
                                                                    />
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .emails
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`col me-2 ${styles['sentiment-general-details-container__card-container__details-container']}`}
                                                            style={{
                                                                backgroundImage: `url(${creationOptionBackground})`,
                                                                backgroundSize:
                                                                    'cover',
                                                                backgroundPosition:
                                                                    'center',
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.calls`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 d-flex justify-content-between">
                                                                <div className="col-auto px-0 pe-2">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoCalls
                                                                        }
                                                                        alt=""
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__icon']}`}
                                                                    />
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .calls
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`col ${styles['sentiment-general-details-container__card-container__details-container']}`}
                                                            style={{
                                                                backgroundImage: `url(${creationOptionBackground})`,
                                                                backgroundSize:
                                                                    'cover',
                                                                backgroundPosition:
                                                                    'center',
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="row w-100 mx-0">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__sub-title']}`}
                                                                    >
                                                                        {t(
                                                                            `components:${data.attr}.media`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mx-0 d-flex justify-content-between">
                                                                <div className="col-auto px-0 pe-2">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoMedia
                                                                        }
                                                                        alt=""
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__icon']}`}
                                                                    />
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <span
                                                                        className={`${styles['sentiment-general-details-container__card-container__details-container__text']}`}
                                                                    >
                                                                        {
                                                                            data
                                                                                .data
                                                                                .media
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ContentContainer>
        </>
    );
});

SentimentGeneralDetails.propTypes = {};
