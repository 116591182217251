import styles from './user-listing.module.scss';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import imagePlaceholder from '../../../assets/images/user-img.png';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import {
    LoaderForeground,
    LoginHistory,
    LoginList,
    ReturnButton,
    Spinner,
    UserInformation,
    UserList
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Provides information about users
 */

const BackOfficeUserListing = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);

    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleReturn = () => {
        if (isMounted.current) setSelectedUser({});
    };

    const handleSelectUser = (el) => {
        if (isMounted.current)
            setSelectedUser({ ...el, image: imagePlaceholder });
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && _.isEmpty(selectedUser) && (
                <div
                    className={`row mx-0 w-100 ${styles['back-office-user-listing-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:back-office.user-listing.title')}
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ height: '45rem' }}
                        >
                            <div className="col px-0 h-100">
                                <UserList />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default BackOfficeUserListing;

BackOfficeUserListing.propTypes = {};
