import _ from 'lodash';
import { useCallback, useReducer } from 'react';

const ACTIONS = {
  SET_COLUMN: 'set',
  SET_READY: 'ready',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_COLUMN:
      return [...action.payload];
    case ACTIONS.SET_READY:{
      const idx = _.findIndex(state, (el) => {
        return el.type === action.payload;
      });
      if (idx !== -1) {
        state[idx].ready = true;
        return [...state];
      } else {
        return [...state];
      }
    }
  }
}

export function useReadyArray(defaultValue) {
  const [array, dispatch] = useReducer(reducer, defaultValue);

  const setArray = useCallback((value) => {
    dispatch({ type: 'set', payload: value });
  }, []);

  const setReady = useCallback((value) => {
    dispatch({ type: 'ready', payload: value });
  }, []);

  return { array, setArray, setReady };
}
