import styles from './dynamic-tables.module.scss';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import {
    DynamicTablesList,
    LoaderForeground,
    ReturnButton,
    SecondaryButton,
    SelectedDynamicTablesList,
    Spinner
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Dashboard containing information about dynamic tables
 */

const AdministrationDynamicTables = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);
    const [selectedTable, setSelectedTable] = useState({});

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleSelectDynamicTable = (dTable, vtypes) => {
        if (isMounted.current)
            setSelectedTable({
                tableName: dTable.tableName,
                tableType: dTable.tableType,
                variables: dTable.variables,
                variableTypes: vtypes
            });
    };

    const handleReturn = () => {
        if (isMounted.current) setSelectedTable({});
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && _.isEmpty(selectedTable) && (
                <div
                    className={`row mx-0 w-100 ${styles['administration-dtables-container']}`}
                >
                    <div className="col px-0 h-100 align-items-center">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.dynamic-tables.title')}
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ height: '45rem' }}
                        >
                            <div className="col px-0 h-100">
                                <DynamicTablesList
                                    onSelectDynamicTable={
                                        handleSelectDynamicTable
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!loading && !_.isEmpty(selectedTable) && (
                <div
                    className={`row mx-0 w-100 ${styles['administration-dtables-container']}`}
                >
                    <div className="col px-0 h-100 align-items-center">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col-auto me-2 px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.dynamic-tables.title')}
                            </div>
                            <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                <SecondaryButton
                                    text={t('buttons:return')}
                                    onClick={handleReturn}
                                />
                            </div>
                        </div>
                        <div className="row w-100" style={{ height: '45rem' }}>
                            <div className="col px-0 h-100">
                                <SelectedDynamicTablesList
                                    selectedTable={selectedTable}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default AdministrationDynamicTables;

AdministrationDynamicTables.propTypes = {};
