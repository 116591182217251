import React, { useState } from 'react'
import styles from './radar-chart-container.module.scss'
import { Dot, PolarAngleAxis, PolarGrid, Radar, RadarChart, ResponsiveContainer, Text, Tooltip } from 'recharts'
import _ from 'lodash'
import { useDotHoveredList } from './useDotHoveredList'
import { TooltipFactory } from '../..'
import useCursorTracking from '../../../hooks/useCursorTracking'

const renderPolarAngleAxis = ({ payload, x, y, cx, cy, ...rest }) => {
    return (
        <Text {...rest} verticalAnchor="middle" y={y + (y - cy) / 20} x={x + (x - cx) / 20} fontSize={'1.4rem'} fontFamily="OpenSansRegular">
            {payload.value.toUpperCase()}
        </Text>
    )
}

export const RadarChartContainer = React.memo(({ varMap, radarAttr, data, categoryAttr, tooltipType, unitsOrValues, translate }) => {
    const { list, toggleActiveDot, toggleInactiveDot } = useDotHoveredList(
        _.range(0, radarAttr.length).map((el) => {
            return {
                attr: radarAttr[el]['dataKey'],
                dots: _.range(0, data.length).map((el2) => {
                    return { index: el2, active: false }
                }),
            }
        })
    )
    const [animationActive, setAnimationActive] = React.useState(true)
    const { position } = useCursorTracking()
    const RenderCustomDot = ({ dataKeyIndex, dataKeyColor, dataKey, index, payload, cx, cy }) => {
        return (
            <svg key={`${dataKeyIndex}-${index}`}>
                <g
                    r={6}
                    fill={'transparent'}
                    // onMouseEnter={() => {
                    //     toggleActiveDot(dataKeyIndex, index);
                    //     setTooltipActive({
                    //         segment: dataKey,
                    //         subject: payload.payload['subject'],
                    //         value: payload.payload[dataKey],
                    //         color: dataKeyColor,
                    //         min: varMap[payload.payload['subject']]['min'],
                    //         max: varMap[payload.payload['subject']]['max'],
                    //         fullMark: payload.payload['fullMark']
                    //     });
                    // }}
                    // onMouseLeave={() => {
                    //     toggleInactiveDot(dataKeyIndex, index);
                    //     setTooltipActive({});
                    // }}
                >
                    <g>
                        <Dot
                            cx={cx}
                            cy={cy}
                            r={4}
                            fill={dataKeyColor}
                            className={
                                //FIXME: Tratar de adicionar estes novos styles aos module.scss
                                list[dataKeyIndex].dots[index]['active']
                                    ? `${styles['radar-chart-container__custom-active-dot']} ${styles['radar-chart-container__custom-active-dot--active']}`
                                    : ''
                            }
                        />
                        <Dot
                            cx={cx}
                            cy={cy}
                            r={2.5}
                            fill="#ffffff"
                            className={
                                list[dataKeyIndex].dots[index]['active']
                                    ? `${styles['radar-chart-container__custom-active-dot']} ${styles['radar-chart-container__custom-active-dot--active']}`
                                    : ''
                            }
                        />
                    </g>
                </g>
            </svg>
        )
    }

    return (
        <React.Fragment>
            <div className={`${styles['radar-chart-container']}`}>
                <ResponsiveContainer height="100%" width="100%">
                    <RadarChart outerRadius={'80%'} width={'100%'} height={'100%'} data={data}>
                        {/* <Tooltip
                                position={{
                                    x: position.x,
                                    y: position.y
                                }}
                                content={
                                    <TooltipFactory
                                        item={{
                                            type:
                                                tooltipType.split('-')[0] || '',
                                            interval: tooltipType.split('-')[1]
                                                ? tooltipType.split('-')[1]
                                                : 'monthly',
                                            unitsOrValues:
                                                unitsOrValues || undefined,
                                            translate: translate || undefined,
                                            tooltipActive: tooltipActive,
                                            varMap: varMap
                                        }}
                                    />
                                }
                                wrapperStyle={{
                                    zIndex: 1000,
                                    position: 'fixed',
                                    outline: 'none'
                                }}
                            /> */}
                        <PolarGrid strokeDasharray={'5 5'} />
                        <PolarAngleAxis dataKey={categoryAttr} tick={(props) => renderPolarAngleAxis(props)} />

                        {radarAttr.map((el, idx) => {
                            return (
                                <Radar
                                    key={idx}
                                    name={el.name}
                                    dataKey={el.dataKey}
                                    stroke={el.color}
                                    fontFamily="OpenSansRegular"
                                    fontSize={'1.4rem'}
                                    fill={el.color}
                                    fillOpacity={0.2}
                                    isAnimationActive={animationActive}
                                    onAnimationEnd={() => setAnimationActive(false)}
                                    // isAnimationActive={!animationDisabled}
                                    animationDuration={300}
                                    animationEasing={'ease-in-out'}
                                    dot={<RenderCustomDot dataKeyIndex={idx} dataKeyColor={el.color} dataKey={el.dataKey} />}
                                />
                            )
                        })}
                    </RadarChart>
                </ResponsiveContainer>
            </div>
        </React.Fragment>
    )
})
