import React from 'react'
import icoDragDots from '../../../assets/images/icoDragDots.svg'
import { useDraggable } from '@dnd-kit/core'

export const TableDragHandle = ({ id }) => {
    const { attributes, listeners } = useDraggable({
        id,
    })
    return (
        <img
            {...attributes}
            {...listeners}
            src={icoDragDots}
            alt="Drag"
            style={{
                cursor: 'grab',
                marginRight: '1rem',
                marginLeft: '1rem',
            }}
        />
    )
}
