import React from 'react';
import styles from './invoice-status-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const InvoiceStatusTableColumn = React.memo(({ el }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['invoice-status-table-column-container']}>
            <span
                className={
                    el.invoiceStatus === 'paid'
                        ? `${styles['invoice-status-table-column-container__text']} ${styles['paid']}`
                        : `${styles['invoice-status-table-column-container__text']} ${styles['unpaid']}`
                }
            >
                {t(
                    el.invoiceStatus === 'paid'
                        ? 'columns:paid'
                        : 'columns:unpaid'
                )}
            </span>
        </div>
    );
});
