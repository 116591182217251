import styles from './verification-code.module.scss'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import icoStarkSmall from '../../../assets/images/logo-small.jpg'
import icoRuleInfo from '../../../assets/images/icoRuleInfo.svg'
import icoCheckCircle from '../../../assets/images/icoCheckCircle.svg'
import icoError from '../../../assets/images/ico-error.svg'
import { AuthService, OpenAPI } from '../../../temp/test'
import { getCurrentUser, getJwt, loginWithJwt } from '../../../utils/authService'
import { useCurrentUser } from '../../../providers/current-user-context'
import { useToggle } from '../../../hooks/useToggle'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useArray } from '../../../hooks/useArray'
import { joiErrorHandler } from '../../../utils/error'
import { verificationCodeSchema } from '../../../schemas/fieldSchemas'
import { errorHandler } from '../../../utils/api'
import { Loader } from '../../../components'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { AuthBackgroundWrapper } from '../../../components/backgrounds/AuthBackgroundWrapper/AuthBackgroundWrapper'

/**
 * Dashboard allowing user to log into the app
 */

const VerificationCode = React.memo(() => {
    const navigate = useNavigate()
    const { t } = useCustomTranslation()
    const { setCurrentUser } = useCurrentUser()
    const location = useLocation()
    const [email] = useState(location.state.email)
    const [password] = useState(location.state.password)
    const [isWaiting, setIsWaiting] = useState(undefined)
    const [isCodeVerified, toggleIsCodeVerified] = useToggle(false)
    const [isCodeResent, toggleIsCodeResent] = useToggle(false)
    const [error, setError] = useState(undefined)
    const isMounted = useIsMounted()
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const codeRefs = useMemo(() => {
        return [ref1, ref2, ref3, ref4, ref5, ref6]
    }, [])
    const { array: verificationCodeArray, setArray: verificationCodeSetArray, update: verificationCodeUpdate } = useArray(['', '', '', '', '', ''])

    const handleFinishLogin = useCallback(() => {
        OpenAPI.TOKEN = getJwt()
        if (getCurrentUser() && getCurrentUser().roles && getCurrentUser().roles.length > 0) {
            setCurrentUser(getCurrentUser())
            navigate('/')
        }
    }, [navigate, setCurrentUser])

    const handleChangeInput = useCallback(
        (index, e) => {
            const joiError = joiErrorHandler(verificationCodeSchema, e.target.value)
            if (joiError) {
                verificationCodeUpdate(index, '')
                Object.entries(joiError).map((el) => {
                    if (isMounted.current) setError(t(el[1]))
                    return el
                })
            } else {
                if (index === codeRefs.length - 1 && verificationCodeArray[index] !== '') {
                    return false
                } else {
                    setError(undefined)
                    toggleIsCodeResent(false)
                    if (index === codeRefs.length - 1 && verificationCodeArray[index] === '') {
                        verificationCodeUpdate(index, e.nativeEvent.data)
                        return false
                    } else if (e.target.value.length > 1 && index + 1 < codeRefs.length - 1) {
                        verificationCodeUpdate(index + 1, e.nativeEvent.data)
                        codeRefs[index + 2].current.focus()
                    } else if (e.target.value.length > 1 && index < codeRefs.length - 1) {
                        verificationCodeUpdate(index + 1, e.nativeEvent.data)
                        codeRefs[index + 1].current.focus()
                    } else {
                        verificationCodeUpdate(index, e.nativeEvent.data)
                        codeRefs[index + 1].current.focus()
                    }
                }
            }
        },
        [isMounted, t, verificationCodeUpdate, codeRefs, verificationCodeArray, setError, toggleIsCodeResent]
    )

    const handleSubmitCode = useCallback(async () => {
        try {
            const data = await AuthService.postApiAuthLoginVerify({
                requestBody: {
                    username: email,
                    password: password,
                    code: _.join(verificationCodeArray, ''),
                },
            })
            loginWithJwt(data.token, data.refresh_token)
            if (isMounted.current) toggleIsCodeVerified(true)
        } catch (err) {
            setError(t('errors:verificationCode.invalid'))
            toggleIsCodeResent(false)
            errorHandler(err)
        }
    }, [t, toggleIsCodeResent, isMounted, toggleIsCodeVerified, email, password, verificationCodeArray])

    const handleKeyDown = useCallback(
        (index, event) => {
            if (event.keyCode === 8 || event.charCode === 45) {
                setError(undefined)
                toggleIsCodeResent(false)
                if (verificationCodeArray[index] === '' && index > 0) {
                    verificationCodeUpdate(index - 1, '')
                } else {
                    verificationCodeUpdate(index, '')
                }
                verificationCodeUpdate(index, '')
                if (index > 0) {
                    codeRefs[index - 1].current.focus()
                }
                event.preventDefault()
                event.stopPropagation()
                return false
            }
        },
        [codeRefs, verificationCodeUpdate, setError, toggleIsCodeResent, verificationCodeArray]
    )

    const handleSendMethod = useCallback(async () => {
        try {
            await AuthService.postApiAuthLoginChallenge({
                requestBody: {
                    method: 'email',
                    password: password,
                    username: email,
                },
            })
        } catch (err) {
            errorHandler(err)
        }
    }, [email, password])

    const handleResendCode = useCallback(() => {
        handleSendMethod()
        toggleIsCodeResent(true)
        setError(undefined)
        verificationCodeSetArray(['', '', '', '', '', ''])
        let isWaiting = 5
        setIsWaiting(5)
        let interval = setInterval(() => {
            if (isWaiting === 1) {
                isWaiting--
                clearInterval(interval)
                setIsWaiting(undefined)
            }
            if (isWaiting > 1) {
                isWaiting--
                setIsWaiting(isWaiting)
            }
        }, 1000)
    }, [toggleIsCodeResent, setError, verificationCodeSetArray, handleSendMethod, setIsWaiting])

    useEffect(() => {
        if (verificationCodeArray.every((el) => el !== '')) {
            handleSubmitCode()
        }
    }, [verificationCodeArray, handleSubmitCode])

    useEffect(() => {
        let timeoutId

        if (isCodeVerified) {
            timeoutId = setTimeout(() => {
                handleFinishLogin()
            }, 5000)
        }
        return () => {
            clearTimeout(timeoutId)
        }
    }, [isCodeVerified, handleFinishLogin])

    const handlePaste = (index, event) => {
        event.preventDefault()
        const clipboardData = event.clipboardData || window.clipboardData
        const pastedText = clipboardData.getData('text')
        const filteredText = pastedText.replace(/\D/g, '')
        const filteredTextLength = filteredText.length
        _.range(index, index + filteredTextLength).map((el) => {
            if (el < codeRefs.length) {
                verificationCodeUpdate(el, filteredText[el - index])
            }
            return el
        })
    }

    return (
        <AuthBackgroundWrapper
            title={t('pages:verificationCode.verificationCode')}
            content={
                <>
                    {isCodeVerified && (
                        <div className="row mx-0">
                            <div className="col px-0">
                                <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <img loading="lazy" src={icoCheckCircle} alt="" />
                                            </div>
                                        </div>
                                        <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <span className={`${styles['verification-code-container__desc1']}`}>
                                                    {t('pages:verificationCode.success')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <span className={`${styles['verification-code-container__desc2']}`}>
                                                    {t('pages:verificationCode.success2')}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '15.4rem',
                                            }}
                                        >
                                            <div className="col px-0">{<Loader maxSize={18} speed={4} />}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isCodeVerified && (
                        <div className="row mx-0">
                            <div className="col px-0">
                                <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '5rem',
                                            }}
                                        >
                                            <div className="col px-0">
                                                {error && (
                                                    <div
                                                        className="row mx-0"
                                                        style={{
                                                            marginBottom: '2rem',
                                                        }}
                                                    >
                                                        <div className="col px-0">
                                                            <div className={`${styles['verification-code-container__error-container']}`}>
                                                                <img
                                                                    loading="lazy"
                                                                    src={icoError}
                                                                    alt=""
                                                                    className={`${styles['verification-code-container__error-container__icon']}`}
                                                                />
                                                                <span className={`${styles['verification-code-container__error-container__text']}`}>
                                                                    {`${error}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {!isCodeResent && (
                                                    <div className="row mx-0">
                                                        <div className="col px-0">
                                                            <div className={`${styles['verification-code-container__description-container']}`}>
                                                                <span className={`${styles['verification-code-container__desc2']}`}>
                                                                    {t('pages:verificationCode.codeSent')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {isCodeResent && (
                                                    <div className="row mx-0">
                                                        <div className="col px-0">
                                                            <div className={`${styles['verification-code-container__info-container']}`}>
                                                                <img
                                                                    loading="lazy"
                                                                    src={icoRuleInfo}
                                                                    alt=""
                                                                    className={`${styles['verification-code-container__info-container__icon']}`}
                                                                />
                                                                <span className={`${styles['verification-code-container__desc2']}`}>
                                                                    {t('pages:verificationCode.codeSent')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 d-flex justify-content-between"
                                            style={{
                                                marginBottom: '5rem',
                                                padding: '0 4.5rem',
                                            }}
                                        >
                                            {_.range(0, verificationCodeArray.length).map((el) => {
                                                return (
                                                    <div key={el} className="col-auto px-0">
                                                        <input
                                                            ref={codeRefs[el]}
                                                            data-testid={`${el}-code`}
                                                            className={
                                                                error
                                                                    ? `${styles['verification-code-container__digit']} ${styles['error']}`
                                                                    : `${styles['verification-code-container__digit']}`
                                                            }
                                                            onPaste={(e) => handlePaste(el, e)}
                                                            value={verificationCodeArray[el] || ''}
                                                            type={'text'}
                                                            onChange={(e) => handleChangeInput(el, e)}
                                                            onKeyDown={(e) => handleKeyDown(el, e)}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '0.7rem',
                                            }}
                                        >
                                            <div className="col px-0">
                                                <span className={`${styles['verification-code-container__text']}`}>
                                                    {t('pages:verificationCode.didNotReceive')}{' '}
                                                    <span
                                                        style={
                                                            isWaiting
                                                                ? {
                                                                      color: '#888',
                                                                      pointerEvents: 'none',
                                                                  }
                                                                : {}
                                                        }
                                                        className={`${styles['verification-code-container__link']}`}
                                                        onClick={handleResendCode}
                                                    >
                                                        {isWaiting !== undefined &&
                                                            `${t('pages:verificationCode.resendCode1')} ${isWaiting} ${t(
                                                                'pages:verificationCode.resendCode2'
                                                            )}`}
                                                        {isWaiting === undefined && `${t('pages:verificationCode.resendCode')}`}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            }
        />
    )
})

export default VerificationCode

VerificationCode.propTypes = {}
