import React from 'react'
import styles from './member-id-table-column.module.scss'

export const MemberIdTableColumn = React.memo(({ el, column }) => {
    return (
        <div className={`${styles['member-id-table-column-container']}`}>
            <div className="d-flex justify-content-start align-items-center">
                <span
                    className={`${styles['member-id-table-column-container__content w-auto']}`}
                >
                    {el[column.path].name.toString()}
                </span>
            </div>
        </div>
    )
})
