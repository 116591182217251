import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ReactComponent as IcoSort } from './assets/images/sort-icon.svg';
import styles from './table.module.scss';
import { TableHeaderFactory } from '../table-header-factory';
import { TableType2Text } from '../table-type-2-text/table-type-2-text';
import { TableColumnFactory } from '../table-column-factory';
import icoAddUser from '../../../assets/images/icoAddUser.svg';
import { Pagination } from '../pagination/pagination';
import { ChartOptions } from '../../chart-configuration/chart-options/chart-options';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { ReportOverflowWarning } from '../../dashboard-component-configuration/report-overflow-warning/report-overflow-warning';
import { useReportBuilder } from '../../dashboard-creation/library-component-factory/report-builder-provider';
import { useCustomSort } from '../table-improvement/useCustomSort';

export const Table = React.memo(
    ({
        search,
        titleCounter,
        attr,
        columns,
        data,
        onSort,
        currentPage,
        pageSize,
        onPageChange,
        showChartOptions,
        chartOptions,
        selectedChartOption,
        onClickActionOption,
        onClickActionIcon,
        onClickChartOption,
        sortColumn,
        onRowClick,
        onRowSelect,
        onSelectAll,
        onUnselectAll,
        type,
        heightFill,
        paginationDisabled,
        onAdd,
        onRemove,
        loading,
        addButtonText,
        removeButtonText,
        noPadding,
        onNewElementClick,
        onNewElementText,
        useTitle,
        titleText
    }) => {
        const { t } = useCustomTranslation();
        const { isReportBuilder } = useReportBuilder();
        const [hoveredRow, toggleHoveredRow] = useState(false);
        const [hoveredActions, toggleHoveredActions] = useState(false);
        const paginate = (items, pageNumber, pageSize) => {
            const startIndex = (pageNumber - 1) * pageSize;
            return _(items).slice(startIndex).take(pageSize).value();
        };

        const { sortedData, sortedColumn, handleSort } = useCustomSort({
            path: columns[0].path,
            order: 'asc',
            data: data
        });

        const renderSortIcon = useCallback(
            (column) => {
                if (sortedColumn && column.path !== sortedColumn.path)
                    return null;
                if (
                    sortedColumn &&
                    column.path === sortedColumn.path &&
                    sortedColumn.order === 'asc'
                )
                    return (
                        <IcoSort
                            className={`${styles['asc-arrow']}`}
                            style={{
                                width: '1rem',
                                height: '1rem'
                            }}
                        />
                    );
                return (
                    <IcoSort
                        style={{
                            width: '1rem',
                            height: '1rem'
                        }}
                    />
                );
            },
            [sortedColumn]
        );

        const count = sortedData ? sortedData.length : 0;

        const paginatedData =
            sortedData && currentPage && pageSize
                ? paginate(sortedData, currentPage, pageSize)
                : sortedData;

        let filteredData;
        if (search && _.find(columns, (el) => el.path === 'name')) {
            const searchRegex = new RegExp(
                `${search.replace(new RegExp('\\\\', 'g'), '\\\\')}`,
                'i'
            );
            filteredData = _.filter(paginatedData, (el) => {
                return el.name.toString().match(searchRegex);
            });
        } else {
            filteredData = _.cloneDeep(paginatedData);
        }

        const showReportWarning = useMemo(() => {
            return isReportBuilder ? true : false;
        }, [isReportBuilder]);
        return (
            <div
                className={
                    heightFill && type
                        ? `${styles['table-container']} ${styles[`${type}`]}`
                        : type
                        ? `${styles['table-container']} ${styles[`${type}`]}`
                        : `${styles['table-container']}`
                }
            >
                {attr && (
                    <div
                        className={`row w-100 m-0 ${styles['table-container__first-row']}`}
                        style={{ padding: noPadding ? '0' : '2rem' }}
                    >
                        <div
                            className={`col h-100 p-0 d-flex justify-content-start align-items-center`}
                        >
                            {typeof titleCounter === 'number' &&
                                titleCounter >= 0 && (
                                    <div
                                        className={`${styles['table-container__first-row__highlight-qty']} me-3`}
                                    >
                                        {titleCounter}
                                    </div>
                                )}
                            <span
                                className={`${styles['table-container__first-row__highlight-text']}`}
                            >
                                {useTitle && titleText}
                                {(!useTitle && t(`components:${attr}.title`)) ||
                                    ''}
                            </span>
                            <div style={{ marginLeft: '1rem' }}>
                                {showReportWarning && <ReportOverflowWarning />}
                            </div>
                        </div>
                        {onNewElementClick && onNewElementText && (
                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                <img
                                    loading="lazy"
                                    src={icoAddUser}
                                    className={`${styles['table-container__new-element-icon']}`}
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={() => onNewElementClick()}
                                />
                                <span
                                    className={`${styles['table-container__new-element-text']}`}
                                    onClick={() => onNewElementClick()}
                                >
                                    {onNewElementText}
                                </span>
                            </div>
                        )}
                        {showChartOptions && (
                            <ChartOptions
                                attr={attr}
                                chartOptions={chartOptions}
                                selectedChartOption={selectedChartOption}
                                onClickChartOption={onClickChartOption}
                            />
                        )}
                    </div>
                )}
                {count === 0 && !loading && (
                    <div
                        className={`row w-100 m-0 ${styles['table-container__content-row']}`}
                        style={{
                            padding: noPadding ? '0' : '2rem',
                            paddingTop: '0'
                        }}
                    >
                        <div
                            className={`w-100`}
                            style={{
                                overflowX: 'auto',
                                overflowY: 'auto',
                                maxHeight: '100%',
                                padding: '0',
                                boxSizing: 'content-box',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div className={`row mx-0`}>
                                <div className={`col px-0`}>
                                    <table
                                        className={
                                            data && data.length === 0
                                                ? `w-100 h-100 ${
                                                      styles[`${type}`]
                                                  }`
                                                : `w-100 ${styles[`${type}`]}`
                                        }
                                        // style={{ padding: '0 0.25rem', overflow: 'auto' }}
                                    >
                                        {(!type ||
                                            (type && type !== 'type-2')) && (
                                            <thead
                                                className={
                                                    onRowClick
                                                        ? `${styles['tr--clickable']}`
                                                        : ''
                                                }
                                                style={
                                                    count === 0 && !loading
                                                        ? { width: 'auto' }
                                                        : {}
                                                }
                                            >
                                                <tr>
                                                    {columns &&
                                                        columns.map(
                                                            (column) => {
                                                                return (
                                                                    <th
                                                                        key={
                                                                            column.path
                                                                        }
                                                                        className={
                                                                            column.sortable
                                                                                ? columns.length ===
                                                                                  1
                                                                                    ? column.path ===
                                                                                          'satisfaction' ||
                                                                                      column.path ===
                                                                                          'action' ||
                                                                                      column.path ===
                                                                                          'add' ||
                                                                                      column.path ===
                                                                                          'remove' ||
                                                                                      column.path ===
                                                                                          'risk'
                                                                                        ? `${styles['th--sortable']} ${styles['single']} ${styles['centered']}`
                                                                                        : `${styles['th--sortable']} ${styles['single']}`
                                                                                    : column.path ===
                                                                                          'satisfaction' ||
                                                                                      column.path ===
                                                                                          'action' ||
                                                                                      column.path ===
                                                                                          'add' ||
                                                                                      column.path ===
                                                                                          'remove' ||
                                                                                      column.path ===
                                                                                          'risk'
                                                                                    ? `${styles['th--sortable']} ${styles['centered']}`
                                                                                    : `${styles['th--sortable']}`
                                                                                : columns.length ===
                                                                                  1
                                                                                ? column.path ===
                                                                                      'satisfaction' ||
                                                                                  column.path ===
                                                                                      'action' ||
                                                                                  column.path ===
                                                                                      'add' ||
                                                                                  column.path ===
                                                                                      'remove' ||
                                                                                  column.path ===
                                                                                      'risk'
                                                                                    ? 'centered single'
                                                                                    : 'single '
                                                                                : column.path ===
                                                                                      'satisfaction' ||
                                                                                  column.path ===
                                                                                      'action' ||
                                                                                  column.path ===
                                                                                      'add' ||
                                                                                  column.path ===
                                                                                      'remove' ||
                                                                                  column.path ===
                                                                                      'risk'
                                                                                ? `${styles['centered']}`
                                                                                : ''
                                                                        }
                                                                        onClick={
                                                                            handleSort
                                                                                ? () =>
                                                                                      handleSort(
                                                                                          column.path,
                                                                                          attr
                                                                                      )
                                                                                : () => {}
                                                                        }
                                                                    >
                                                                        <TableHeaderFactory
                                                                            item={{
                                                                                type: column.path,
                                                                                column: column,
                                                                                attr: attr,
                                                                                loading:
                                                                                    loading,
                                                                                onSelectAll:
                                                                                    onSelectAll,
                                                                                onUnselectAll:
                                                                                    onUnselectAll,
                                                                                renderSortIcon:
                                                                                    renderSortIcon
                                                                            }}
                                                                        ></TableHeaderFactory>
                                                                    </th>
                                                                );
                                                            }
                                                        )}
                                                </tr>
                                            </thead>
                                        )}
                                    </table>
                                </div>
                            </div>

                            <div
                                className={`${styles['no-table-container']} d-flex flex-column justify-content-center align-items-center flex-grow-1`}
                            >
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                        <span className="noDataText">
                                            {t('common:noData')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {count !== 0 && !loading && (
                    <div
                        className={`row w-100 m-0 ${styles['table-container__content-row']}`}
                        style={{
                            padding: noPadding ? '0' : '2rem',
                            paddingTop: '0'
                        }}
                    >
                        <div
                            className={`w-100`}
                            style={{
                                overflowX: 'auto',
                                overflowY: 'auto',
                                maxHeight: '100%',
                                padding: '0',
                                boxSizing: 'content-box'
                            }}
                        >
                            <div className={`row mx-0`}>
                                <div className={`col px-0`}>
                                    <table
                                        className={
                                            data && data.length === 0
                                                ? `w-100 h-100 ${
                                                      styles[`${type}`]
                                                  }`
                                                : `w-100 ${styles[`${type}`]}`
                                        }
                                        // style={{ padding: '0 0.25rem', overflow: 'auto' }}
                                    >
                                        {(!type ||
                                            (type && type !== 'type-2')) && (
                                            <thead
                                                className={
                                                    onRowClick
                                                        ? `${styles['tr--clickable']}`
                                                        : ''
                                                }
                                                style={
                                                    count === 0 && !loading
                                                        ? { width: 'auto' }
                                                        : {}
                                                }
                                            >
                                                <tr>
                                                    {columns &&
                                                        columns.map(
                                                            (column) => {
                                                                return (
                                                                    <th
                                                                        key={
                                                                            column.path
                                                                        }
                                                                        className={
                                                                            column.sortable
                                                                                ? columns.length ===
                                                                                  1
                                                                                    ? column.path ===
                                                                                          'satisfaction' ||
                                                                                      column.path ===
                                                                                          'action' ||
                                                                                      column.path ===
                                                                                          'add' ||
                                                                                      column.path ===
                                                                                          'remove' ||
                                                                                      column.path ===
                                                                                          'risk'
                                                                                        ? `${styles['th--sortable']} ${styles['single']} ${styles['centered']}`
                                                                                        : `${styles['th--sortable']} ${styles['single']}`
                                                                                    : column.path ===
                                                                                          'satisfaction' ||
                                                                                      column.path ===
                                                                                          'action' ||
                                                                                      column.path ===
                                                                                          'add' ||
                                                                                      column.path ===
                                                                                          'remove' ||
                                                                                      column.path ===
                                                                                          'risk'
                                                                                    ? `${styles['th--sortable']} ${styles['centered']}`
                                                                                    : `${styles['th--sortable']}`
                                                                                : columns.length ===
                                                                                  1
                                                                                ? column.path ===
                                                                                      'satisfaction' ||
                                                                                  column.path ===
                                                                                      'action' ||
                                                                                  column.path ===
                                                                                      'add' ||
                                                                                  column.path ===
                                                                                      'remove' ||
                                                                                  column.path ===
                                                                                      'risk'
                                                                                    ? 'centered single'
                                                                                    : 'single '
                                                                                : column.path ===
                                                                                      'satisfaction' ||
                                                                                  column.path ===
                                                                                      'action' ||
                                                                                  column.path ===
                                                                                      'add' ||
                                                                                  column.path ===
                                                                                      'remove' ||
                                                                                  column.path ===
                                                                                      'risk'
                                                                                ? `${styles['centered']}`
                                                                                : ''
                                                                        }
                                                                        onClick={
                                                                            handleSort
                                                                                ? () =>
                                                                                      handleSort(
                                                                                          column.path,
                                                                                          attr
                                                                                      )
                                                                                : () => {}
                                                                        }
                                                                    >
                                                                        <TableHeaderFactory
                                                                            item={{
                                                                                type: column.path,
                                                                                column: column,
                                                                                attr: attr,
                                                                                loading:
                                                                                    loading,
                                                                                onSelectAll:
                                                                                    onSelectAll,
                                                                                onUnselectAll:
                                                                                    onUnselectAll,
                                                                                renderSortIcon:
                                                                                    renderSortIcon
                                                                            }}
                                                                        ></TableHeaderFactory>
                                                                    </th>
                                                                );
                                                            }
                                                        )}
                                                </tr>
                                            </thead>
                                        )}
                                        <tbody>
                                            {filteredData &&
                                                filteredData.map(
                                                    (el, index) => {
                                                        return (
                                                            <tr
                                                                key={index}
                                                                onClick={
                                                                    onRowClick
                                                                        ? () =>
                                                                              onRowClick(
                                                                                  el,
                                                                                  attr
                                                                              )
                                                                        : () => {}
                                                                }
                                                                onMouseOver={() => {
                                                                    toggleHoveredRow(
                                                                        true
                                                                    );
                                                                }}
                                                                onMouseOut={() => {
                                                                    toggleHoveredRow(
                                                                        false
                                                                    );
                                                                }}
                                                                className={
                                                                    el.forecast &&
                                                                    onRowClick &&
                                                                    hoveredRow &&
                                                                    !hoveredActions
                                                                        ? `${styles['tr--clickable']} ${styles['forecast']}`
                                                                        : el.forecast &&
                                                                          onRowSelect
                                                                        ? `${styles['tr--selectable']} ${styles['forecast']}`
                                                                        : el.forecast
                                                                        ? `${styles['forecast']}`
                                                                        : onRowClick &&
                                                                          hoveredRow &&
                                                                          !hoveredActions
                                                                        ? `${styles['tr--clickable']}`
                                                                        : onRowSelect
                                                                        ? `${styles['tr--selectable']}`
                                                                        : ''
                                                                }
                                                                style={{
                                                                    height: '6rem'
                                                                }}
                                                            >
                                                                {columns &&
                                                                    columns.map(
                                                                        (
                                                                            column
                                                                        ) => {
                                                                            return (
                                                                                <td
                                                                                    className={
                                                                                        columns.length ===
                                                                                        1
                                                                                            ? column.path ===
                                                                                                  'action' ||
                                                                                              column.path ===
                                                                                                  'riskDistribution' ||
                                                                                              column.path ===
                                                                                                  'add' ||
                                                                                              column.path ===
                                                                                                  'remove' ||
                                                                                              column.path ===
                                                                                                  'satisfaction' ||
                                                                                              column.path ===
                                                                                                  'risk'
                                                                                                ? 'centered single'
                                                                                                : 'single'
                                                                                            : column.path ===
                                                                                                  'action' ||
                                                                                              column.path ===
                                                                                                  'riskDistribution' ||
                                                                                              column.path ===
                                                                                                  'add' ||
                                                                                              column.path ===
                                                                                                  'remove' ||
                                                                                              column.path ===
                                                                                                  'satisfaction' ||
                                                                                              column.path ===
                                                                                                  'risk'
                                                                                            ? 'centered'
                                                                                            : ''
                                                                                    }
                                                                                    key={
                                                                                        column.path
                                                                                    }
                                                                                    style={
                                                                                        _.has(
                                                                                            column,
                                                                                            'isNumber'
                                                                                        ) &&
                                                                                        column.isNumber
                                                                                            ? {
                                                                                                  whiteSpace:
                                                                                                      'nowrap'
                                                                                              }
                                                                                            : {}
                                                                                    }
                                                                                >
                                                                                    {type ===
                                                                                        'type-2' && (
                                                                                        <TableType2Text
                                                                                            column={
                                                                                                column
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    <TableColumnFactory
                                                                                        item={{
                                                                                            type: column.path,
                                                                                            attr: attr,
                                                                                            index: index,
                                                                                            el: el,
                                                                                            column: column,
                                                                                            onClickActionIcon,
                                                                                            onClickActionOption,
                                                                                            onCloseActions:
                                                                                                onClickActionIcon,
                                                                                            filteredData:
                                                                                                filteredData,
                                                                                            addButtonText:
                                                                                                addButtonText,
                                                                                            removeButtonText:
                                                                                                removeButtonText,
                                                                                            onAdd: onAdd,
                                                                                            onRemove:
                                                                                                onRemove,
                                                                                            onRowSelect:
                                                                                                onRowSelect,
                                                                                            onToggleHoveredActions:
                                                                                                toggleHoveredActions
                                                                                        }}
                                                                                    ></TableColumnFactory>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {pageSize &&
                    count >= pageSize &&
                    !paginationDisabled &&
                    !loading && (
                        <div
                            className={`row m-0`}
                            style={{
                                padding: '0rem 2rem',
                                paddingBottom: '1rem'
                            }}
                        >
                            <div className={`col p-0`}>
                                <Pagination
                                    itemsCount={data ? data.length : 0}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    onPageChange={onPageChange || (() => {})}
                                    attr={attr}
                                />
                            </div>
                        </div>
                    )}
            </div>
        );
    }
);

Table.propTypes = {
    /**
     * String to display placeholder text
     */
    placeholderText: PropTypes.string,
    /**
     * String to display title text
     */
    titleText: PropTypes.string,
    /**
     * Number to display counter in the title
     */
    titleCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * String to find attribute in state where the value is located
     */
    attr: PropTypes.string,
    /**
     * Boolean to display time Options
     */
    showTimeOptions: PropTypes.bool,
    /**
     * Array of objects
     * Each object contains:
     * path: String of attribute in data array of objects
     * label: String to display in column
     * sortable: Bool to allow or block sorting for that column
     */
    columns: PropTypes.array,
    /**
     * Object containing:
     * path: String of sorted attribute
     * order: String of order. Can be 'asc' | 'desc'
     */
    sortColumn: PropTypes.object,
    /**
     * Array of objects
     * Each object contains:
     * [key, value] = [String of attribute, Value of attribute (Int | Float | Bool | String)]
     */
    data: PropTypes.array,
    /**
     * Function to handle clicking on a column header
     */
    onSort: PropTypes.func,
    /**
     * Function to handle clicking on a row
     */
    onRowClick: PropTypes.func,
    /**
     * Total number of items per page
     */
    pageSize: PropTypes.number,
    /**
     * Current pagination page. Initially set to 1 by parent component
     */
    currentPage: PropTypes.number,
    /**
     * Function to change currentPage attribute
     */
    onPageChange: PropTypes.func,
    /**
     * Bool to display chart options
     * */
    showChartOptions: PropTypes.bool,
    /**
     * Array of objects
     * Each object contains the following attributes:
     * name: String to differenciate attribute
     * label: String to display attribute label
     */
    chartOptions: PropTypes.array,
    /**
     * String to choose which chart option is selected
     */
    selectedChartOption: PropTypes.string,
    /**
     * Function to handle click on chart option
     * */
    onClickChartOption: PropTypes.func,
    /**
     * Object containing two attributtes: view | options.
     * Each attribute is an array of objects containing four attributes:
     * name: String to display setting label
     * icon: Image to show icon
     */
    settings: PropTypes.object,
    /**
     * Function to handle click on action icon
     * */
    onClickActionOption: PropTypes.func,
    /**
     * Function to handle click on settings view option
     * */
    onClickSettingsView: PropTypes.func,
    /**
     * Function to handle click on settings normal option
     * */
    onClickSettingsOptions: PropTypes.func,
    /**
     * Object containing two attributtes: view | options.
     * Each attribute is an array of objects containing four attributes:
     * name: String to display setting label
     * icon: Image to show icon
     */
    actions: PropTypes.object,
    /**
     * Bool to disable settings button
     */
    disabled: PropTypes.bool,
    /**
     * String to choose type of table to display
     */
    type: PropTypes.string
};
