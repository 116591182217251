/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompetitorsSchema } from '../models/CompetitorsSchema';
import type { zInfoSchema } from '../models/zInfoSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProspectsService {
    /**
     * returns all prospects
     * @returns CompetitorsSchema call successful
     * @throws ApiError
     */
    public static getApiCompetitors(): CancelablePromise<Array<CompetitorsSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitors',
        });
    }
    /**
     * prospect information
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiCompetitors1({
        competitorId,
    }: {
        /**
         * id of prospect.
         */
        competitorId: number,
    }): CancelablePromise<{
        competitor?: CompetitorsSchema;
        gInfo?: Record<string, any>;
        imgUrl?: string;
        zInfo?: zInfoSchema;
        zInfo_sim_score?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/competitors/{competitor_id}',
            path: {
                'competitor_id': competitorId,
            },
        });
    }
}
