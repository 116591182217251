/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalYearlyGoalDefaultSchema } from '../models/GlobalYearlyGoalDefaultSchema';
import type { ProductSalesMonthlyGoalDefaultSchema } from '../models/ProductSalesMonthlyGoalDefaultSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GoalsService {
    /**
     * list all GlobalYearlyGoal types
     * @returns GlobalYearlyGoalDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiUserForecastGlobalYear({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, year, sales_aggregation, forecast, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'year' | 'sales_aggregation' | 'forecast' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<GlobalYearlyGoalDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user-forecast/global/year',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates GlobalYearlyGoal
     * @returns GlobalYearlyGoalDefaultSchema resource created
     * @throws ApiError
     */
    public static postApiUserForecastGlobalYear({
        requestBody,
    }: {
        requestBody: GlobalYearlyGoalDefaultSchema,
    }): CancelablePromise<GlobalYearlyGoalDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user-forecast/global/year',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes GlobalYearlyGoal
     * @returns void
     * @throws ApiError
     */
    public static deleteApiUserForecastGlobalYear({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user-forecast/global/year/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one GlobalYearlyGoal by id
     * @returns GlobalYearlyGoalDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiUserForecastGlobalYear1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<GlobalYearlyGoalDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user-forecast/global/year/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * partially updates GlobalYearlyGoal by id
     * @returns GlobalYearlyGoalDefaultSchema call successful
     * @throws ApiError
     */
    public static patchApiUserForecastGlobalYear({
        id,
        requestBody,
    }: {
        /**
         * id
         */
        id: number,
        requestBody: GlobalYearlyGoalDefaultSchema,
    }): CancelablePromise<GlobalYearlyGoalDefaultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user-forecast/global/year/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * list all ProductSalesMonthlyGoal types
     * @returns ProductSalesMonthlyGoalDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiUserForecastProductMonth({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, product_id, year, month, forecast, sales_aggregation, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'product_id' | 'year' | 'month' | 'forecast' | 'sales_aggregation' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<ProductSalesMonthlyGoalDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user-forecast/product/month',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates ProductSalesMonthlyGoal
     * @returns ProductSalesMonthlyGoalDefaultSchema resource created
     * @throws ApiError
     */
    public static postApiUserForecastProductMonth({
        requestBody,
    }: {
        requestBody: ProductSalesMonthlyGoalDefaultSchema,
    }): CancelablePromise<ProductSalesMonthlyGoalDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user-forecast/product/month',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes ProductSalesMonthlyGoal
     * @returns void
     * @throws ApiError
     */
    public static deleteApiUserForecastProductMonth({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/user-forecast/product/month/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one ProductSalesMonthlyGoal by id
     * @returns ProductSalesMonthlyGoalDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiUserForecastProductMonth1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<ProductSalesMonthlyGoalDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user-forecast/product/month/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * partially updates ProductSalesMonthlyGoal by id
     * @returns ProductSalesMonthlyGoalDefaultSchema call successful
     * @throws ApiError
     */
    public static patchApiUserForecastProductMonth({
        id,
        requestBody,
    }: {
        /**
         * id
         */
        id: number,
        requestBody: ProductSalesMonthlyGoalDefaultSchema,
    }): CancelablePromise<ProductSalesMonthlyGoalDefaultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user-forecast/product/month/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
