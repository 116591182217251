import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useTranslation } from 'react-i18next'
import { getValueInString } from '../../../../../utils/unit'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SalesHomologueTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.payload && props.payload.length > 0) {
        return (
            <TooltipContainer color={props.payload[0].fill}>
                <TooltipHeaderRow title={t(`tooltips:header.sales-homologue`)} value={props.payload[0].payload.month} />
                <Separator />
                <TooltipContentRow
                    title={props.payload[0].payload.year}
                    value={getValueInString(parseFloat(props.payload[0].payload.lastYearSales || 0), props.unitsOrValues)}
                    color={'#6586ea'}
                />
                <TooltipContentRow
                    title={props.payload[0].payload.previousYear}
                    value={getValueInString(parseFloat(props.payload[0].payload.previousYearSales || 0), props.unitsOrValues)}
                    color={'#2cd1bd'}
                />
            </TooltipContainer>
        )
    } else return null
})
