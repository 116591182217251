import React from 'react'
import styles from './add-component-button.module.scss'
import { ReactComponent as IcoAdd } from '../../../assets/images/icon-table-add.svg'

export const AddComponentButton = React.memo(({ id, onAdd, disabled }) => {
    return (
        <div className="add-component-button-container">
            <IcoAdd
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onAdd(id)
                }}
                className={
                    disabled
                        ? `${styles['add-component-button-container__icon']} ${styles['disabled']}`
                        : `${styles['add-component-button-container__icon']}`
                }
            ></IcoAdd>
        </div>
    )
})

AddComponentButton.propTypes = {}
