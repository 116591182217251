import React from 'react';
import styles from './YourContractDetails.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { ContractInformation } from './components/contract-information/ContractInformation';
import { EnterpriseInformation } from './components/enterprise-information/EnterpriseInformation';
import { usePlatformFormat } from '../../../providers/platform-format-context';

export const YourContractDetails = () => {
    const { t } = useCustomTranslation();
    const { format } = usePlatformFormat();
    return (
        <div className={styles['your-contract-details-container']}>
            <div className="row w-100 mx-0 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-between align-items-center">
                    {format === 'enterprise' &&
                        t('pages:yourContractDetails.enterpriseTitle')}
                    {format === 'organization' &&
                        t('pages:yourContractDetails.organizationTitle')}
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0" style={{ width: '60%' }}>
                    <ContractInformation />
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div
                    className="col-auto px-0"
                    style={{ width: 'calc(40% - 2rem)' }}
                >
                    <EnterpriseInformation />
                </div>
            </div>
        </div>
    );
};
