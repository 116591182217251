import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { getValueInString } from '../../../../../utils/unit';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const GoalContributionTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    if (props.payload && props.payload.length > 0) {
        return (
            <TooltipContainer color={'#6ad6c5'}>
                <TooltipHeaderRow
                    title={t(`tooltips:header.goal-contribution`)}
                    value={props.payload[0].payload.name}
                />
                <Separator />
                <TooltipContentRow
                    title={t(`tooltips:content.contributor`)}
                    value={props.payload[0].payload.contribution + '%'}
                    color={'#6ad6c5'}
                />
            </TooltipContainer>
        );
    } else return null;
});
