import React from 'react'
import { ReactComponent as IcoRightArrow } from '../../../assets/images/icon-right-arrow.svg'
import styles from './right-double-arrow-button.module.scss'

export const RightDoubleArrowButton = React.memo(({ disabled }) => {
    return (
        <div
            className={`${styles['right-double-arrow-container']}`}
            style={
                disabled
                    ? { cursor: 'none', pointerEvents: 'none', opacity: 0.2 }
                    : {}
            }
        >
            <IcoRightArrow
                className={`${styles['right-double-arrow-container__icon']}`}
            />
            <IcoRightArrow
                className={`${styles['right-double-arrow-container__icon']}`}
            />
        </div>
    )
})
