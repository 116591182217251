import Joi from 'joi'
import PasswordComplexity from 'joi-password-complexity'

export const ageSchema = Joi.number().integer().greater(0)

export const emailSchema = Joi.string()
    .email({ tlds: { allow: false } })
    .empty()

export const verificationCodeSchema = Joi.number().integer()

export const weakPasswordOptions = {
    min: 8,
    max: 1000,
    lowerCase: 1,
    upperCase: 1,
}

export const weakPasswordSchema = PasswordComplexity({
    min: 8,
    max: 1000,
    lowerCase: 1,
})

export const mediumPasswordSchema = PasswordComplexity({
    min: 8,
    max: 1000,
    lowerCase: 1,
    upperCase: 1,
    numeric: 1,
})

export const mediumPasswordSchema2 = PasswordComplexity({
    min: 8,
    max: 1000,
    lowerCase: 1,
    upperCase: 1,
    symbol: 1,
})

export const strongPasswordSchema = PasswordComplexity({
    min: 8,
    max: 1000,
    lowerCase: 1,
    upperCase: 1,
    numeric: 1,
    symbol: 1,
})

export const loginEmailSchema = Joi.string().email({ tlds: { allow: false } })

export const loginUsernameSchema = Joi.string().required()

export const loginPasswordSchema = Joi.string().required()

export const nameSchema = Joi.string()
    .pattern(/^[a-zA-ZÀ-ÿ\- ]+$/)
    .empty()

export const topicSchema = Joi.string().required()

export const descriptionSchema = Joi.string()

export const bedNumberSchema = Joi.string().required()
