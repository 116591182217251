export const getRoundedValue = (value, unit) => {
    let str,
        suffix = '';
    let prefix = '';

    value = +value;
    let factor = Math.pow(10, 1);

    if (unit === 'euro') prefix = '€';
    else if (unit === 'dollar') prefix = '$';
    else unit = '';

    if (value >= 1000000000) {
        suffix = 'B';
        str = Math.floor(value / (1000000000 / factor)) / factor;
    } else if (value >= 1000000) {
        suffix = 'M';
        str = Math.floor(value / (1000000 / factor)) / factor;
    } else if (value >= 1000) {
        suffix = 'K';
        str = Math.floor(value / (1000 / factor)) / factor;
    } else if (value >= 0) {
        str = Math.floor(value).toString();
    }

    return prefix + str + suffix;
};

export function formatCurrency(value, currency) {
    // Create a new NumberFormat object for the 'en-EU' locale with the specified currency
    const formatter = new Intl.NumberFormat('en-EU', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    // Format the value using the formatter and return it
    return formatter.format(value);
}
