import _ from 'lodash';
import { useCallback, useReducer } from 'react';

const ACTIONS = {
  PUSH: 'push',
  REMOVE: 'remove',
  SET_COLUMN: 'setColumn',
  SET_READY: 'setReady',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.PUSH:
      return [...state, action.payload];
    case ACTIONS.REMOVE:{

      const removeIdx = _.findIndex(state, (el) => {
        return el.id === action.payload;
      });
      if (removeIdx !== -1) {
        return [
          ...state.slice(0, removeIdx),
          ...state.slice(removeIdx + 1, state.length),
        ];
      } else {
        return [...state];
      }
    }
    case ACTIONS.SET_COLUMN:{

      const idx = _.findIndex(state, (el) => {
        return el.id === action.payload.id;
      });
      const newState = _.cloneDeep(state);
      if (idx !== -1) {
        if (
          newState[idx].type === 'csv' &&
          action.payload.attr !== 'ready'
        ) {
          newState[idx]['csvMap'][action.payload.attr] =
            action.payload.value;
        } else if (newState[idx].type === 'media') {
          if (
            action.payload.value !== undefined &&
            action.payload.value !== ''
          ) {
            newState[idx].ready = true;
          } else {
            newState[idx].ready = false;
          }
          newState[idx][action.payload.attr] = action.payload.value;
        } else {
          newState[idx][action.payload.attr] = action.payload.value;
        }
      }
      return [...newState];
    }

    case ACTIONS.SET_READY:{

      const readyIdx = _.findIndex(state, (el) => {
        return el.id === action.payload.id;
      });
      if (readyIdx !== -1) {
        const newState = _.cloneDeep(state);
        newState[readyIdx].ready = action.payload.value;
        return [...newState];
      } else {
        return [...state];
      }
    }
  }
}

export function useChannels(defaultValue) {
  const [channels, dispatch] = useReducer(reducer, defaultValue);

  const push = useCallback((element) => {
    dispatch({ type: 'push', payload: element });
  }, []);

  const remove = useCallback((id) => {
    dispatch({ type: 'remove', payload: id });
  }, []);

  const setColumn = useCallback((id, attr, value) => {
    dispatch({ type: 'setColumn', payload: { id, attr, value } });
  }, []);

  const setReady = useCallback((id, value) => {
    dispatch({ type: 'setReady', payload: { id, value } });
  }, []);

  return { channels, push, remove, setColumn, setReady };
}
