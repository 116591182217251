import React from 'react'

export const stocksVsSalesMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    titleText: '',
    legend: [
        { name: 'stock', color: '#2cd9c5' },
        { name: 'sales', color: '#14897c' },
    ],
    barWidth: 20,
    barStackedAttr: [
        {
            dataKey: 'revenue2020',
            color: '#2cd9c5',
            stackId: 'a',
            radius: [1000, 100, 0, 0],
        },
        {
            dataKey: 'revenue2021',
            color: '#14897c',
            stackId: 'a',
            radius: [100, 100, 0, 0],
        },
    ],
    categoryAxis: 'categoryAttr',
    maxValue: 106690,
    minValue: 16740,
    data: [
        {
            revenue2020: 24000000,
            revenue2021: 23000000,
            categoryAttr: 'Abbott',
        },
        {
            revenue2020: 27000000,
            revenue2021: 28000000,
            categoryAttr: 'AbbVie',
        },
        {
            revenue2020: 21000000,
            revenue2021: 24000000,
            categoryAttr: 'Amgen',
        },
        {
            revenue2020: 17000000,
            revenue2021: 19000000,
            categoryAttr: 'Bayer',
        },
        {
            revenue2020: 19000000,
            revenue2021: 22000000,
            categoryAttr: 'GSK',
        },
        {
            revenue2020: 16000000,
            revenue2021: 15000000,
            categoryAttr: 'J&J',
        },
        {
            revenue2020: 24000000,
            revenue2021: 25000000,
            categoryAttr: 'Merck',
        },
        {
            revenue2020: 26000000,
            revenue2021: 21000000,
            categoryAttr: 'Pfizer',
        },
        {
            revenue2020: 20000000,
            revenue2021: 23000000,
            categoryAttr: 'Roche',
        },
        {
            revenue2020: 21000000,
            revenue2021: 22000000,
            categoryAttr: 'Sanofi',
        },
    ],
    orientation: 'vertical',
    tooltipType: 'salesVsStocks',
    unitsOrValues: 'values',
    loading: true,
    categoryLabel: '',
    margin: {
        top: 5,
        left: 0,
        right: 20,
        bottom: 5,
    },
    type: 'stacked',
    attr: 'productStockVsSalesChart',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
