import React from 'react';

import styles from './radio-input.module.scss';
import { useTranslation } from 'react-i18next';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const RadioInput = React.memo(
    ({
        modalAttr,
        attr,
        label,
        onClickRatioOption,
        options,
        selectedOption,
        name,
        direction, // new prop for direction,
        hideLabel
    }) => {
        const { t } = useCustomTranslation();
        const isHorizontal = direction === 'horizontal';
        return (
            <div
                className={`${styles['radio-input-container']} ${
                    isHorizontal ? styles['horizontal'] : ''
                }`}
            >
                {(label || name) && !hideLabel && (
                    <label
                        htmlFor={name}
                        className={`${styles['radio-input-container__label']}`}
                        style={{ marginBottom: '1rem' }}
                    >
                        {label || t(`inputs:${name}`)}
                    </label>
                )}
                <div className={isHorizontal ? 'row' : 'd-flex flex-column'}>
                    {options.map((el, index) => {
                        let iconStyle;
                        if (el.disabled) {
                            iconStyle = 'disabled';
                        } else if (el.blocked) {
                            iconStyle = 'blocked';
                        } else if (el.name === selectedOption) {
                            iconStyle = 'selected';
                        } else {
                            iconStyle = 'default';
                        }
                        return (
                            <div
                                key={index}
                                className={`mx-0 ${
                                    styles['radio-input-container__option']
                                } ${isHorizontal ? 'col-auto' : ''}`}
                                style={{
                                    marginBottom:
                                        index === options.length - 1
                                            ? 0
                                            : undefined
                                }}
                            >
                                <div
                                    className={`${styles['radio-input-container__option__content']}`}
                                    onClick={() =>
                                        onClickRatioOption(
                                            modalAttr,
                                            attr,
                                            el.name,
                                            name
                                        )
                                    }
                                >
                                    <div className="col-auto px-0 me-3 d-flex justify-content-center align-items-center">
                                        {(iconStyle === 'blocked' ||
                                            iconStyle === 'selected') && (
                                            <div
                                                className={`${
                                                    styles[
                                                        'radio-input-container__option__icon'
                                                    ]
                                                } ${
                                                    styles[
                                                        `radio-input-container__option__icon--${iconStyle}`
                                                    ]
                                                }`}
                                            >
                                                <div
                                                    className={`${
                                                        styles[
                                                            'radio-input-container__option__icon__sub-icon'
                                                        ]
                                                    } ${
                                                        styles[
                                                            `radio-input-container__option__icon__sub-icon--${iconStyle}`
                                                        ]
                                                    }`}
                                                ></div>
                                            </div>
                                        )}
                                        {iconStyle !== 'blocked' &&
                                            iconStyle !== 'selected' && (
                                                <div
                                                    className={`${
                                                        styles[
                                                            'radio-input-container__option__icon'
                                                        ]
                                                    } ${
                                                        styles[
                                                            `radio-input-container__option__icon--${iconStyle}`
                                                        ]
                                                    }`}
                                                ></div>
                                            )}
                                    </div>
                                    <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                        {el.color && (
                                            <div
                                                className={`${styles['radio-input-container__option__color']}`}
                                                style={{
                                                    borderColor: el.color
                                                }}
                                            ></div>
                                        )}
                                        <span
                                            className={`${styles['radio-input-container__option__text']}`}
                                        >
                                            {el.label || t(`common:${el.name}`)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
);

RadioInput.propTypes = {};
