import React from 'react'
import icoEmail from '../../../assets/images/ico-email.svg'

export const sentimentChannelOverviewMock = {
    titleCounter: '0',
    sortedData: [
        {
            sentimentChannel: {
                label: 'EMAILS',
                icon: icoEmail,
            },
            total: 10,
            customers: 86,
            keywords: 0,
            sentimentPositive: 0,
            sentimentNeutral: 70,
            sentimentNegative: 20,
            trend: 0.6,
        },
    ],
    data: [
        {
            sentimentChannel: {
                label: 'POSITIVE',
                icon: icoEmail,
            },
            total: 10,
            customers: 86,
            keywords: 0,
            sentimentPositive: 0,
            sentimentNeutral: 70,
            sentimentNegative: 20,
            trend: 0.6,
        },
    ],
    columns: [
        {
            path: 'sentimentChannel',
            sortable: true,
            translated: false,
        },
        {
            path: 'total',
            label: '',
            sortable: true,
            translated: false,
        },
        {
            path: 'customers',
            label: '',
            sortable: true,
            translated: false,
        },
        {
            path: 'keywords',
            label: '',
            sortable: true,
            translated: false,
        },
        {
            path: 'sentimentPositive',
            label: '',
            sortable: true,
            translated: false,
        },
        {
            path: 'sentimentNeutral',
            label: '',
            sortable: true,
            translated: false,
        },
        {
            path: 'sentimentNegative',
            label: '',
            sortable: true,
            translated: false,
        },
        {
            path: 'trend',
            label: '',
            sortable: false,
            translated: false,
        },
    ],
    sortColumn: { path: 'sentimentChannel', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'sentimentChannelOverview',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
        reference: React.createRef(),
    },
}
