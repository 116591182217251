export const CLUSTER_COLORS = [
    '#8c54ff',
    '#ff8900',
    '#06a4e7',
    '#d2ae21',
    '#21D2AE',
]

export const DONUT_1_COLORS = [
    '#06A4E7',
    '#5bbee7',
    '#8ac9e7',
    '#b9d4e7',
    '#e8eef7',
    '#9c9c9c',
]

export const DONUT_2_COLORS = [
    '#FF6363',
    '#14897C',
    '#46D38C',
    '#FFBD77',
    '#FF8900',
    '#C85396',
    '#FD9999',
]

export const TEAM_COMPARISON_COLORS = [
    '#2cd9c5',
    '#d96256',
    '#7fd94d',
    '#ff6363',
    '#8c54ff',
    '#ff8900',
    '#06a4e7',
    '#d2ae21',
    '#21D2AE',
    '#FF9441',
]
