import React, { useCallback, useEffect, useState } from 'react'
import styles from './UserForecastsPopup.module.scss'
import { TableInput } from '../../inputs/table-input/TableInput'
import { AddNewForecastModal } from '../../modals/add-new-forecast-modal/add-new-forecast-modal'
import { useModal } from '../../../providers/modal-context'

export const UserForecastsPopup = ({ componentRef, onClosePopup }) => {
    const [isReverseX, setIsReverseX] = useState(undefined)
    const { setModal } = useModal()
    const [addNewForecastModal, setAddNewForecastModal] = useState(false)

    const handleOutsideClick = useCallback(
        (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target) && !addNewForecastModal) {
                event.preventDefault()
                event.stopPropagation()
                onClosePopup()
            }
        },
        [onClosePopup, componentRef, addNewForecastModal]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [handleOutsideClick])

    useEffect(() => {
        if (componentRef.current) {
            const rect = componentRef.current.getBoundingClientRect()
            if (rect.x + rect.width > window.innerWidth / 2) {
                setIsReverseX(true)
            } else {
                setIsReverseX(false)
            }
        }
    }, [componentRef])

    return (
        <>
            <div
                className={`${styles['user-forecasts-popup-container']}`}
                style={{
                    left: isReverseX ? 'unset' : '0',
                    right: isReverseX ? '0' : 'unset',
                    visibility: isReverseX === undefined ? 'hidden' : 'visible',
                }}
            >
                <div className="row mx-0 w-100" style={{ height: '30rem' }}>
                    <div className="col px-0 h-100">
                        <TableInput title={'User Forecasts'} onAdd={() => setModal('userForecast')} onRemove={() => {}} />
                    </div>
                </div>
            </div>
        </>
    )
}
