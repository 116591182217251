export const mockTutorialSteps = [
    {
        completed: false,
        text: 'modals:tutorialHub.tourPlatform.title',
        steps: [
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part1`,
                        bold: false,
                    },
                    {
                        text: ` `,
                        bold: true,
                    },
                    {
                        text: `keywords:predictiveAnalysis`,
                        bold: true,
                    },
                    {
                        text: `, `,
                        bold: true,
                    },
                    {
                        text: `keywords:productRecommendation`,
                        bold: true,
                    },
                    {
                        text: `, `,
                        bold: true,
                    },
                    {
                        text: `keywords:sentimentAnalysis`,
                        bold: true,
                    },
                    {
                        text: ` `,
                        bold: true,
                    },
                    {
                        text: `keywords:and`,
                        bold: true,
                    },
                    {
                        text: ` `,
                        bold: true,
                    },
                    {
                        text: `keywords:customerSegmentation`,
                        bold: true,
                    },
                    {
                        text: `.`,
                        bold: true,
                    },
                ],
                url: 'global-dashboard',
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part2`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part3`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    direction: 'left',
                    type: 'navigation',
                    name: 'all',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part4`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    direction: 'left',
                    type: 'navigation',
                    name: 'sentimentanalysis',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part5`,
                        bold: false,
                    },
                    {
                        text: `keywords:predictiveAnalysis`,
                        bold: true,
                    },
                    {
                        text: `keywords:productRecommendation`,
                        bold: true,
                    },
                    {
                        text: `keywords:sentimentAnalysis`,
                        bold: true,
                    },
                    {
                        text: `keywords:and`,
                        bold: true,
                    },
                    {
                        text: `keywords:customerSegmentation`,
                        bold: true,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    type: 'component',
                    name: 'all',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part6`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    direction: 'up',
                    type: 'component',
                    name: 'totalClientsCard',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part7`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    direction: 'down',
                    type: 'component',
                    name: 'geoChart',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.tourPlatform.title',
                text: [
                    {
                        text: `modals:tutorialHub.tourPlatform.text.part8`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: true,
            },
        ],
    },
    {
        completed: false,
        text: 'modals:tutorialHub.exploreProfile.title',
        steps: [
            {
                text: [
                    {
                        text: `modals:tutorialHub.exploreProfile.text.part1`,
                        bold: false,
                    },
                ],
                title: 'modals:tutorialHub.exploreProfile.title',
                url: 'profile/view',
                isStart: false,
            },
            {
                title: 'modals:tutorialHub.exploreProfile.title',
                text: [
                    {
                        text: `modals:tutorialHub.exploreProfile.text.part2`,
                        bold: false,
                    },
                ],
                isStart: false,
            },
            {
                title: 'modals:tutorialHub.exploreProfile.title',
                text: [
                    {
                        text: `modals:tutorialHub.exploreProfile.text.part3`,
                        bold: false,
                    },
                ],
                isStart: false,
            },
            {
                title: 'modals:tutorialHub.exploreProfile.title',
                text: [
                    {
                        text: `modals:tutorialHub.exploreProfile.text.part4`,
                        bold: false,
                    },
                ],
                isStart: false,
            },
            {
                title: 'modals:tutorialHub.exploreProfile.title',
                text: [
                    {
                        text: `modals:tutorialHub.exploreProfile.text.part5`,
                        bold: false,
                    },
                ],

                isStart: false,
                isFinal: true,
            },
        ],
    },
    {
        completed: false,
        text: 'modals:tutorialHub.createDashboard.title',
        steps: [
            {
                title: 'modals:tutorialHub.createDashboard.title',
                text: [
                    {
                        text: `modals:tutorialHub.createDashboard.text.part1`,
                        bold: false,
                    },
                ],
                url: 'create-dashboard',
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.createDashboard.title',
                text: [
                    {
                        text: `modals:tutorialHub.createDashboard.text.part2`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.createDashboard.title',
                text: [
                    {
                        text: `modals:tutorialHub.createDashboard.text.part3`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    direction: 'right',
                    type: 'dashboardPreviewCard',
                    name: 'clientsDetailed',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.createDashboard.title',
                text: [
                    {
                        text: `modals:tutorialHub.createDashboard.text.part4`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: false,
                waitUntilTrigger: true,
                triggerPoint: 'dashboardCreated',
            },
            {
                title: 'modals:tutorialHub.createDashboard.title',
                text: [
                    {
                        text: `modals:tutorialHub.createDashboard.text.part5`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: true,
            },
        ],
    },
    {
        completed: false,
        text: 'modals:tutorialHub.customizeHomepage.title',
        steps: [
            {
                title: 'modals:tutorialHub.customizeHomepage.title',
                text: [
                    {
                        text: `modals:tutorialHub.customizeHomepage.text.part1`,
                        bold: false,
                    },
                ],
                url: 'global-dashboard',
            },
            {
                title: 'modals:tutorialHub.customizeHomepage.title',
                text: [
                    {
                        text: `modals:tutorialHub.customizeHomepage.text.part2`,
                        bold: false,
                    },
                ],
                waitUntilTrigger: true,
                triggerPoint: 'componentPinned',
            },
            {
                title: 'modals:tutorialHub.customizeHomepage.title',
                text: [
                    {
                        text: `modals:tutorialHub.customizeHomepage.text.part3`,
                        bold: false,
                    },
                ],
                waitUntilTrigger: true,
                triggerPoint: 'navigateToHome',
            },
            {
                title: 'modals:tutorialHub.customizeHomepage.title',
                text: [
                    {
                        text: `modals:tutorialHub.customizeHomepage.text.part4`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.customizeHomepage.title',
                text: [
                    {
                        text: `modals:tutorialHub.customizeHomepage.text.part5`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: true,
                popupDetails: {
                    direction: 'up',
                    type: 'top',
                    name: 'edit',
                },
                isStart: true,
                isFinal: false,
            },
            {
                title: 'modals:tutorialHub.customizeHomepage.title',
                text: [
                    {
                        text: `modals:tutorialHub.customizeHomepage.text.part6`,
                        bold: false,
                    },
                ],
                isNextStepADifferentUrl: false,
                isPopup: false,
                isStart: false,
                isFinal: true,
            },
        ],
    },
]
