import { createContext, useContext, useMemo } from 'react';
import { getCurrentUser } from '../utils/authService';
import { useCurrentAppHook } from '../useCurrentAppHook';

const CurrentAppContext = createContext({
    currentApp: undefined,
    setCurrentApp: () => {}
});

export function CurrentAppProvider({ children }) {
    const [currentApp, setCurrentApp] = useCurrentAppHook(getCurrentUser());
    return (
        <CurrentAppContext.Provider
            value={{
                currentApp: currentApp,
                setCurrentApp: setCurrentApp
            }}
        >
            {children}
        </CurrentAppContext.Provider>
    );
}

export function useCurrentApp() {
    return useContext(CurrentAppContext);
}
