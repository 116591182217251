import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import _ from 'lodash';
import styles from './sentiment-import-data.module.scss';
import Papa from 'papaparse';
import icoPlus from '../../../assets/images/ico-plus.svg';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useChannels } from './useChannels';
import { useTextInput } from '../../../hooks/useTextInput';
// import ScrapperApi from '../../../../../temp/test/services/ScrapperApi';
// import NewSentiment from '../../../../../temp/test/model/NewSentiment';
// import { formatDate } from '../../../../../helpers/date';
import { useTagMultipleInput } from '../../../hooks/useTagMultipleInput';
import { useColumns } from './useColumns';
import { useRows } from './useRows';
import { useRecommendedKeywords } from './useRecommendedKeywords';
import { ScrapperService, SentimentService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { formatDate } from '../../../utils/date';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { Modal } from '../../modals/modal/modal';
import { SelectInput } from '../../inputs/select-input/select-input';
import { ListWrapper } from '../../scrolling/list-wrapper/list-wrapper';
import { TypeInput } from '../../inputs/type-input/type-input';
import { FileImport } from '../file-import/file-import';
import { TagMultipleInput } from '../../inputs/tag-multiple-input/tag-multiple-input';
import { Spinner } from '../../loading/spinner/spinner';
import { FileList } from '../file-list/file-list';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import FullTextPopup from '../../popups/FullTextPopup';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SentimentImportData = React.memo(
    ({ onSetResults, onNavigateToResults }) => {
        const { t } = useCustomTranslation();
        const isMounted = useIsMounted();
        const [modal, toggleModal] = useToggle(false);
        const [modalType, setModalType] = useState('');
        const {
            channels: addedChannels,
            push: addedChannelsPush,
            setColumn: addedChannelsSetColumn,
            remove: addedChannelsRemove
        } = useChannels([]);

        const [addedChannelId, setAddedChannelId] = useState(0);
        const {
            keywords: recommendedKeywords,
            setKeywords: setRecommendedKeywords,
            unselectKeyword
        } = useRecommendedKeywords([]);
        const {
            value: keywords,
            toggleDropdown: keywordsToggleDropdown,
            selectOption: keywordsSelectOption,
            unselectOption: keywordsUnselectOption,
            keyPress: keywordsKeyPress,
            keyDown: keywordsKeyDown,
            setValue: keywordsSetValue,
            setError: keywordsSetError,
            addKeyword,
            removeKeyword
        } = useTagMultipleInput({
            name: 'keywords',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const { value: tag, setValue: tagSetValue } = useTextInput({
            name: 'tag',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            columns: columns,
            setColumns: setColumns,
            clear: clearColumns,
            clearColumn
        } = useColumns([]);
        const {
            rows: rows,
            setRows: setRows,
            clear: clearRows,
            clearRow
        } = useRows([]);

        const [selectedFile, setSelectedFile] = useState(null);
        const [selectedFileData, setSelectedFileData] = useState(null);
        const [selectedFileLoading, toggleSelectedFileLoading] =
            useToggle(false);

        const [selectedChannelId, setSelectedChannelId] = useState('');

        const {
            value: conversationId,
            addOption: conversationIdAddOption,
            toggleDropdown: conversationIdToggleDropdown,
            selectOption: conversationIdSelectOption,
            keyPress: conversationIdKeyPress,
            keyDown: conversationIdKeyDown,
            setValue: conversationIdSetValue,
            reset: conversationIdReset
        } = useSelectInput({
            name: 'conversationId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: from,
            addOption: fromAddOption,
            toggleDropdown: fromToggleDropdown,
            selectOption: fromSelectOption,
            keyPress: fromKeyPress,
            keyDown: fromKeyDown,
            setValue: fromSetValue,
            reset: fromReset
        } = useSelectInput({
            name: 'from',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: to,
            addOption: toAddOption,
            toggleDropdown: toToggleDropdown,
            selectOption: toSelectOption,
            keyPress: toKeyPress,
            keyDown: toKeyDown,
            setValue: toSetValue,
            reset: toReset
        } = useSelectInput({
            name: 'to',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: subject,
            addOption: subjectAddOption,
            toggleDropdown: subjectToggleDropdown,
            selectOption: subjectSelectOption,
            keyPress: subjectKeyPress,
            keyDown: subjectKeyDown,
            setValue: subjectSetValue,
            reset: subjectReset
        } = useSelectInput({
            name: 'subject',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: text,
            addOption: textAddOption,
            toggleDropdown: textToggleDropdown,
            selectOption: textSelectOption,
            keyPress: textKeyPress,
            keyDown: textKeyDown,
            setValue: textSetValue,
            reset: textReset
        } = useSelectInput({
            name: 'text',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: date,
            addOption: dateAddOption,
            toggleDropdown: dateToggleDropdown,
            selectOption: dateSelectOption,
            keyPress: dateKeyPress,
            keyDown: dateKeyDown,
            setValue: dateSetValue,
            reset: dateReset
        } = useSelectInput({
            name: 'date',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const [recommendedKeywordsLoading, setRecommendedKeywordsLoading] =
            useState(false);

        useEffect(() => {
            async function fill() {
                try {
                    setRecommendedKeywordsLoading(true);
                    const result =
                        await SentimentService.getApiNlpSuggestedKeywords();
                    const newKeywords = [];
                    result.map((el) => {
                        newKeywords.push({
                            value: el,
                            selected: false
                        });
                    });
                    setRecommendedKeywords(newKeywords);
                    setRecommendedKeywordsLoading(false);
                } catch (err) {
                    errorHandler(err);
                }
            }
            fill();
        }, [setRecommendedKeywords]);

        const handleConversationIdSetValue = (e) => {
            clearColumn(conversationId.name);
            clearRow(conversationId.name);
            addedChannelsSetColumn(
                selectedChannelId,
                conversationId.name,
                undefined
            );
            conversationIdSetValue(e);
        };

        const handleFromSetValue = (e) => {
            clearColumn(from.name);
            clearRow(from.name);
            addedChannelsSetColumn(selectedChannelId, from.name, undefined);
            fromSetValue(e);
        };

        const handleToSetValue = (e) => {
            clearColumn(to.name);
            clearRow(to.name);
            addedChannelsSetColumn(selectedChannelId, to.name, undefined);
            toSetValue(e);
        };

        const handleSubjectSetValue = (e) => {
            clearColumn(subject.name);
            clearRow(subject.name);
            addedChannelsSetColumn(selectedChannelId, subject.name, undefined);
            subjectSetValue(e);
        };

        const handleTextSetValue = (e) => {
            clearColumn(text.name);
            clearRow(text.name);
            addedChannelsSetColumn(selectedChannelId, text.name, undefined);
            textSetValue(e);
        };
        const handleDateSetValue = (e) => {
            clearColumn(date.name);
            clearRow(date.name);
            addedChannelsSetColumn(selectedChannelId, date.name, undefined);
            dateSetValue(e);
        };

        const handleAddColumnTable = useCallback(() => {
            clearColumns();
            conversationIdReset();

            fromReset();
            toReset();
            textReset();
            subjectReset();
            dateReset();
        }, [
            clearColumns,
            conversationIdReset,
            fromReset,
            toReset,
            textReset,
            subjectReset,
            dateReset
        ]);

        const handleOpenModal = useCallback(() => {
            if (isMounted.current) {
                toggleModal(true);
                setModalType('csvColumnMap');
            }
        }, [toggleModal, setModalType, isMounted]);

        const handleCloseCsvColumnMapModal = () => {
            const idx = _.findIndex(addedChannels, (el) => {
                return el.id === selectedChannelId;
            });
            if (idx !== -1) {
                addedChannelsRemove(selectedChannelId);
            }
            setSelectedChannelId('');
            handleCloseModal();
        };
        const handleCloseModal = () => {
            if (isMounted.current) {
                toggleModal(false);
                setModalType('');
            }
        };

        const handleSelectRecommendedKeyword = (index) => {
            const newRecommendedKeywords = _.cloneDeep(recommendedKeywords);
            if (newRecommendedKeywords[index].selected) {
                removeKeyword(newRecommendedKeywords[index].value);
                newRecommendedKeywords[index].selected = false;
            } else {
                if (
                    keywords.selectedOptions.includes(
                        newRecommendedKeywords[index].value
                    )
                ) {
                    keywordsSetError(t(`duplicateKeyword`));
                } else {
                    addKeyword(newRecommendedKeywords[index].value);
                    newRecommendedKeywords[index].selected = true;
                }
            }
            setRecommendedKeywords(newRecommendedKeywords);
        };

        const conversationIdCustomSelectOption = (
            value,
            valueAux,
            name,
            data,
            columns
        ) => {
            if (isMounted.current) conversationIdSelectOption(value, valueAux);
            addedChannelsSetColumn(selectedChannelId, 'conversationId', value);
            handleSelectColumn(
                'conversationId',
                value,
                valueAux,
                data,
                columns
            );
        };

        const fromCustomSelectOption = (
            value,
            valueAux,
            name,
            data,
            columns
        ) => {
            if (isMounted.current) fromSelectOption(value, valueAux);
            addedChannelsSetColumn(selectedChannelId, 'from', value);
            handleSelectColumn('from', value, valueAux, data, columns);
        };

        const toCustomSelectOption = (value, valueAux, name, data, columns) => {
            if (isMounted.current) toSelectOption(value, valueAux);
            addedChannelsSetColumn(selectedChannelId, 'to', value);
            handleSelectColumn('to', value, valueAux, data, columns);
        };

        const subjectCustomSelectOption = (
            value,
            valueAux,
            name,
            data,
            columns
        ) => {
            if (isMounted.current) subjectSelectOption(value, valueAux);
            addedChannelsSetColumn(selectedChannelId, 'subject', value);
            handleSelectColumn('subject', value, valueAux, data, columns);
        };

        const textCustomSelectOption = (
            value,
            valueAux,
            name,
            data,
            columns
        ) => {
            if (isMounted.current) textSelectOption(value, valueAux);
            addedChannelsSetColumn(selectedChannelId, 'text', value);
            handleSelectColumn('text', value, valueAux, data, columns);
        };

        const dateCustomSelectOption = (
            value,
            valueAux,
            name,
            data,
            columns
        ) => {
            if (isMounted.current) dateSelectOption(value, valueAux);
            addedChannelsSetColumn(selectedChannelId, 'date', value);
            handleSelectColumn('date', value, valueAux, data, columns);
        };

        const handleSelectColumn = (
            name,
            option,
            optionAux,
            data,
            columnsData
        ) => {
            const newColumns = columnsData ? columnsData : _.cloneDeep(columns);
            newColumns.map((el) => {
                el.mapped = false;
                return el;
            });
            if (isMounted.current) {
                setColumns(newColumns);
                clearRows();
            }
            const newRows = [];
            let newData = data ? data : selectedFileData;
            newData.slice(0, Math.min(newData.length - 1, 5)).map((el) => {
                const newEl = {};
                newColumns.map((el2) => {
                    let value = null;
                    if (el2.value === name) {
                        value = option;
                    } else if (el2.value === 'conversationId') {
                        value = conversationId.selectedOption;
                    } else if (el2.value === 'from') {
                        value = from.selectedOption;
                    } else if (el2.value === 'to') {
                        value = to.selectedOption;
                    } else if (el2.value === 'subject') {
                        value = subject.selectedOption;
                    } else if (el2.value === 'text') {
                        value = text.selectedOption;
                    } else if (el2.value === 'date') {
                        value = date.selectedOption;
                    }
                    newEl[el2.value] = '';
                    Object.entries(newData[0]).map((el3) => {
                        if (el3[0] === value) {
                            el2.mapped = true;
                            if (
                                typeof el[value] !== 'string' &&
                                selectedFile &&
                                selectedFile.type === 'mbox'
                            ) {
                                if (
                                    value === 'from' ||
                                    value === 'to' ||
                                    value === 'reply' ||
                                    value === 'sender' ||
                                    value === 'deliveredTo'
                                ) {
                                    if (el[value].length) {
                                        el[value].map((el4, index4) => {
                                            if (index4 === 0) {
                                                if (
                                                    typeof el4.address !==
                                                    'string'
                                                ) {
                                                    newEl[el2.value] +=
                                                        el4.address.toString();
                                                } else {
                                                    newEl[el2.value] +=
                                                        el4.address;
                                                }
                                            } else {
                                                if (
                                                    typeof el4.address !==
                                                    'string'
                                                ) {
                                                    newEl[el2.value] +=
                                                        ' ' +
                                                        el4.address.toString();
                                                } else {
                                                    newEl[el2.value] +=
                                                        ' ' + el4.address;
                                                }
                                            }
                                            return el4;
                                        });
                                    } else {
                                        if (
                                            typeof el[value].address !==
                                            'string'
                                        ) {
                                            newEl[el2.value] =
                                                el[value].address.toString();
                                        } else {
                                            newEl[el2.value] =
                                                el[value].address;
                                        }
                                    }
                                } else if (value === 'headers') {
                                    el[value].map((el4, index4) => {
                                        if (index4 === 0) {
                                            newEl[el2.value] +=
                                                el4.key + ' ' + el4.value;
                                        } else {
                                            newEl[el2.value] +=
                                                '; ' +
                                                el4.key +
                                                ' ' +
                                                el4.value;
                                        }
                                        return el4;
                                    });
                                } else {
                                    newEl[el2.value] = el[value].toString();
                                }
                            } else if (
                                typeof el[value] !== 'string' &&
                                selectedFile &&
                                selectedFile.type === 'text/csv'
                            ) {
                                newEl[el2.value] = el[value].toString();
                            } else {
                                newEl[el2.value] = el[value];
                            }
                            return false;
                        }
                        return el3;
                    });
                    return el2;
                });
                newRows.push(newEl);
                return el;
            });
            if (isMounted.current) setRows(newRows);
        };

        const handleCsvFile = useCallback(
            (file) => {
                const newChannel = {
                    id: addedChannelId,
                    type: 'csv',
                    selectedFile: file,
                    selectedFileName: file.name,
                    csvMap: {
                        conversationId: undefined,
                        from: undefined,
                        to: undefined,
                        subject: undefined,
                        text: undefined,
                        date: undefined
                    },
                    ready: false
                };
                if (isMounted.current) {
                    setSelectedFile(file);
                    toggleSelectedFileLoading(true);
                    clearColumns();
                    clearRows();
                    conversationIdReset();
                    handleAddColumnTable();
                    fromReset();
                    toReset();
                    textReset();
                    subjectReset();
                    dateReset();
                    setSelectedChannelId(addedChannelId);
                    setAddedChannelId(addedChannelId + 1);
                    handleOpenModal();
                }
                Papa.parse(file, {
                    complete: async (result) => {
                        const data = result.data;
                        const headers = [];
                        Object.entries(data[0]).map((el) => {
                            headers.push(el[0]);
                            return el;
                        });
                        if (isMounted.current) {
                            clearRows();
                            const newColumns = [
                                { value: 'conversationId', mapped: false },
                                { value: 'from', mapped: false },
                                { value: 'to', mapped: false },
                                { value: 'subject', mapped: false },
                                { value: 'text', mapped: false },
                                { value: 'date', mapped: false }
                            ];
                            setColumns(newColumns);
                            headers.map((el) => {
                                conversationIdAddOption(el, el);
                                fromAddOption(el, el);
                                toAddOption(el, el);
                                subjectAddOption(el, el);
                                textAddOption(el, el);
                                dateAddOption(el, el);
                            });
                            setSelectedFileData(_.cloneDeep(data));
                            newColumns.map((el) => {
                                el.mapped = false;
                                return el;
                            });
                            if (isMounted.current) {
                                setColumns(newColumns);
                                clearRows();
                            }
                            const newRows = [];
                            let newData = data ? data : selectedFileData;
                            newData
                                .slice(0, Math.min(newData.length - 1, 5))
                                .map((el) => {
                                    const newEl = {};
                                    newColumns.map((el2) => {
                                        newEl[el2.value] = '';
                                        Object.entries(el).map((el3) => {
                                            if (el3[0] === el2.value) {
                                                let value = null;
                                                if (
                                                    el2.value ===
                                                        'conversationId' ||
                                                    el2.value === 'number' ||
                                                    el2.value === 'identifier'
                                                ) {
                                                    value = conversationId.name;
                                                    newChannel.csvMap.conversationId =
                                                        value;
                                                    conversationIdSelectOption(
                                                        value,
                                                        value
                                                    );
                                                } else if (
                                                    el2.value === 'from'
                                                ) {
                                                    value = from.name;
                                                    newChannel.csvMap.from =
                                                        value;
                                                    fromSelectOption(
                                                        value,
                                                        value
                                                    );
                                                } else if (el2.value === 'to') {
                                                    value = to.name;
                                                    newChannel.csvMap.to =
                                                        value;
                                                    toSelectOption(
                                                        value,
                                                        value
                                                    );
                                                } else if (
                                                    el2.value === 'subject'
                                                ) {
                                                    value = subject.name;
                                                    newChannel.csvMap.subject =
                                                        value;
                                                    subjectSelectOption(
                                                        value,
                                                        value
                                                    );
                                                } else if (
                                                    el2.value === 'text' ||
                                                    el2.value === 'content'
                                                ) {
                                                    value = text.name;
                                                    newChannel.csvMap.text =
                                                        value;
                                                    textSelectOption(
                                                        value,
                                                        value
                                                    );
                                                } else if (
                                                    el2.value === 'date'
                                                ) {
                                                    value = date.name;
                                                    newChannel.csvMap.date =
                                                        value;
                                                    dateSelectOption(
                                                        value,
                                                        value
                                                    );
                                                }
                                                el2.mapped = true;
                                                if (
                                                    typeof el[value] !==
                                                        'string' &&
                                                    selectedFile &&
                                                    selectedFile.type === 'mbox'
                                                ) {
                                                    if (
                                                        value === 'from' ||
                                                        value === 'to' ||
                                                        value === 'reply' ||
                                                        value === 'sender' ||
                                                        value === 'deliveredTo'
                                                    ) {
                                                        if (el[value].length) {
                                                            el[value].map(
                                                                (
                                                                    el4,
                                                                    index4
                                                                ) => {
                                                                    if (
                                                                        index4 ===
                                                                        0
                                                                    ) {
                                                                        if (
                                                                            typeof el4.address !==
                                                                            'string'
                                                                        ) {
                                                                            newEl[
                                                                                el2.value
                                                                            ] +=
                                                                                el4.address.toString();
                                                                        } else {
                                                                            newEl[
                                                                                el2.value
                                                                            ] +=
                                                                                el4.address;
                                                                        }
                                                                    } else {
                                                                        if (
                                                                            typeof el4.address !==
                                                                            'string'
                                                                        ) {
                                                                            newEl[
                                                                                el2.value
                                                                            ] +=
                                                                                ' ' +
                                                                                el4.address.toString();
                                                                        } else {
                                                                            newEl[
                                                                                el2.value
                                                                            ] +=
                                                                                ' ' +
                                                                                el4.address;
                                                                        }
                                                                    }
                                                                    return el4;
                                                                }
                                                            );
                                                        } else {
                                                            if (
                                                                typeof el[value]
                                                                    .address !==
                                                                'string'
                                                            ) {
                                                                newEl[
                                                                    el2.value
                                                                ] =
                                                                    el[
                                                                        value
                                                                    ].address.toString();
                                                            } else {
                                                                newEl[
                                                                    el2.value
                                                                ] =
                                                                    el[
                                                                        value
                                                                    ].address;
                                                            }
                                                        }
                                                    } else if (
                                                        value === 'headers'
                                                    ) {
                                                        el[value].map(
                                                            (el4, index4) => {
                                                                if (
                                                                    index4 === 0
                                                                ) {
                                                                    newEl[
                                                                        el2.value
                                                                    ] +=
                                                                        el4.key +
                                                                        ' ' +
                                                                        el4.value;
                                                                } else {
                                                                    newEl[
                                                                        el2.value
                                                                    ] +=
                                                                        '; ' +
                                                                        el4.key +
                                                                        ' ' +
                                                                        el4.value;
                                                                }
                                                                return el4;
                                                            }
                                                        );
                                                    } else {
                                                        newEl[el2.value] =
                                                            el[
                                                                value
                                                            ].toString();
                                                    }
                                                } else if (
                                                    typeof el[value] !==
                                                        'string' &&
                                                    selectedFile &&
                                                    selectedFile.type ===
                                                        'text/csv'
                                                ) {
                                                    newEl[el2.value] =
                                                        el[value].toString();
                                                } else {
                                                    newEl[el2.value] =
                                                        el[value];
                                                }
                                                return false;
                                            }
                                            return el3;
                                        });
                                        return el2;
                                    });

                                    newRows.push(newEl);
                                    return el;
                                });
                            if (isMounted.current) {
                                setColumns(newColumns);
                                setRows(newRows);
                            }
                            toggleSelectedFileLoading(false);
                        }
                        addedChannelsPush(newChannel);
                    },
                    header: true
                });
            },
            [
                addedChannelId,
                addedChannelsPush,
                clearColumns,
                clearRows,
                conversationIdReset,
                dateReset,
                fromReset,
                handleAddColumnTable,
                handleOpenModal,
                subjectReset,
                textReset,
                toReset,
                toggleSelectedFileLoading,
                conversationId.name,
                conversationIdAddOption,
                conversationIdSelectOption,
                date.name,
                dateAddOption,
                dateSelectOption,
                from.name,
                fromAddOption,
                fromSelectOption,
                isMounted,
                selectedFile,
                selectedFileData,
                setColumns,
                setRows,
                subject.name,
                subjectAddOption,
                subjectSelectOption,
                text.name,
                textAddOption,
                textSelectOption,
                to.name,
                toAddOption,
                toSelectOption
            ]
        );

        const handleCsvFileSave = () => {
            addedChannelsSetColumn(selectedChannelId, 'ready', true);
            handleCloseModal();
        };

        const handleMboxFile = useCallback(
            async (file) => {
                if (isMounted.current) {
                    setSelectedFile(file);
                    toggleSelectedFileLoading(false);
                    clearColumns();
                    conversationIdReset();
                    fromReset();
                    toReset();
                    textReset();
                    subjectReset();
                    dateReset();
                    clearRows();
                    addedChannelsPush({
                        id: addedChannelId,
                        type: 'mbox',
                        selectedFile: file,
                        selectedFileName: file.name,
                        ready: true
                    });
                    setAddedChannelId(addedChannelId + 1);
                }
            },
            [
                addedChannelId,
                addedChannelsPush,
                clearColumns,
                clearRows,
                conversationIdReset,
                dateReset,
                fromReset,
                isMounted,
                subjectReset,
                textReset,
                toReset,
                toggleSelectedFileLoading
            ]
        );

        const handlePstFile = useCallback(
            async (file) => {
                if (isMounted.current) {
                    setSelectedFile(file);
                    toggleSelectedFileLoading(false);
                    clearColumns();
                    conversationIdReset();
                    fromReset();
                    toReset();
                    textReset();
                    subjectReset();
                    dateReset();
                    clearRows();
                    addedChannelsPush({
                        id: addedChannelId,
                        type: 'pst',
                        selectedFile: file,
                        selectedFileName: file.name,
                        ready: true
                    });
                    setAddedChannelId(addedChannelId + 1);
                }
            },
            [
                addedChannelId,
                addedChannelsPush,
                clearColumns,
                clearRows,
                conversationIdReset,
                dateReset,
                fromReset,
                isMounted,
                subjectReset,
                textReset,
                toReset,
                toggleSelectedFileLoading
            ]
        );

        const handleSentimentAnalysis = async () => {
            try {
                const newResults = { sessionId: undefined, data: [] };
                onNavigateToResults();
                const analysisBack = await SentimentService.postApiNlpAnalysis({
                    requestBody: { name: tag.value }
                });
                let jointSessionId = analysisBack.id;
                for (const el of addedChannels) {
                    let conversationsBackData;
                    if (el.type === 'csv') {
                        conversationsBackData =
                            await SentimentService.postApiNlpExtractCsv({
                                formData: {
                                    csv: el.selectedFile,
                                    conversation_id: el.csvMap.conversationId,
                                    from: el.csvMap.from,
                                    to: el.csvMap.to,
                                    subject: el.csvMap.subject,
                                    text: el.csvMap.text,
                                    date: el.csvMap.date,
                                    input_keywords: [],
                                    session_id: jointSessionId
                                }
                            });
                        newResults.sessionId = conversationsBackData.session_id;
                        newResults.data.push({
                            input: el,
                            output: conversationsBackData
                        });
                    } else if (el.type === 'mbox') {
                        conversationsBackData =
                            await SentimentService.postApiNlpExtractMbox({
                                formData: {
                                    client_id: 1,
                                    from_email: 'random@gmail.com',
                                    mbox: el.selectedFile,
                                    input_keywords: [],
                                    session_id: jointSessionId
                                }
                            });
                        newResults.sessionId = conversationsBackData.session_id;
                        newResults.data.push({
                            input: el,
                            output: conversationsBackData
                        });
                    } else if (el.type === 'pst') {
                        conversationsBackData =
                            await SentimentService.postApiNlpExtractMbox({
                                formData: {
                                    client_id: 1,
                                    from_email: 'random@gmail.com',
                                    mbox: el.selectedFile,
                                    input_keywords: [],
                                    session_id: jointSessionId
                                }
                            });
                        newResults.sessionId = conversationsBackData.session_id;
                        newResults.data.push({
                            input: el,
                            output: conversationsBackData
                        });
                    } else if (el.type === 'media') {
                        const newSentiment = {};
                        newSentiment.input_keywords = [];
                        newSentiment.q = el.search;
                        newSentiment.session_id = jointSessionId;
                        const result =
                            await ScrapperService.postApiBackofficeScrapperFolha(
                                {
                                    requestBody: {
                                        input_keywords: [],
                                        session_id: jointSessionId,
                                        q: el.search
                                    }
                                }
                            );
                        jointSessionId = result.session_id;
                        newResults.sessionId = result.session_id;
                        const newMediaResults = [];
                        const newKeywordCounter = [];
                        Object.entries(result.result).map((el) => {
                            newKeywordCounter.push({
                                name: el[0],
                                count: el[1]
                            });
                            return el;
                        });
                        result.data.map((el) => {
                            let positiveWordsCounter = 0,
                                negativeWordsCounter = 0,
                                neutralWordsCounter = 0;
                            let positiveWords = [],
                                negativeWords = [],
                                neutralWords = [];
                            let sentences = [];
                            let text = el.text;
                            let majorSentences = [];
                            JSON.parse(el.keywords).map((el2) => {
                                if (sentences.length === 0) {
                                    let newSentences = text.split(el2.text);
                                    if (
                                        typeof newSentences === 'object' &&
                                        newSentences.length > 1
                                    ) {
                                        sentences.push(
                                            {
                                                text: newSentences[0],
                                                sentiment: ''
                                            },
                                            {
                                                text: el2.text,
                                                sentiment: el2.sentiment.label
                                            },
                                            {
                                                text: newSentences[1],
                                                sentiment: ''
                                            }
                                        );
                                    }
                                } else if (sentences.length !== 0) {
                                    sentences.map((el3, index3) => {
                                        if (el3.sentiment === '') {
                                            let newSentences = el3.text.split(
                                                el2.text
                                            );
                                            if (
                                                typeof newSentences ===
                                                    'object' &&
                                                newSentences.length > 1
                                            ) {
                                                sentences.splice(
                                                    index3 + 1,
                                                    0,
                                                    {
                                                        text: newSentences[1],
                                                        sentiment: ''
                                                    }
                                                );
                                                sentences.splice(
                                                    index3 + 1,
                                                    0,
                                                    {
                                                        text: el2.text,
                                                        sentiment:
                                                            el2.sentiment.label
                                                    }
                                                );
                                                sentences.splice(index3, 0, {
                                                    text: newSentences[0],
                                                    sentiment: ''
                                                });
                                                sentences.splice(index3 + 1, 1);
                                            }
                                        }
                                        return el3;
                                    });
                                }
                                if (el2.sentiment.label === 'positive') {
                                    positiveWordsCounter += 1;
                                    positiveWords.push(el2.text);
                                } else if (el2.sentiment.label === 'negative') {
                                    negativeWordsCounter += 1;
                                    negativeWords.push(el2.text);
                                } else if (el2.sentiment.label === 'neutral') {
                                    neutralWordsCounter += 1;
                                    neutralWords.push(el2.text);
                                }
                                return el2;
                            });
                            majorSentences.push(sentences);
                            const newEl = {
                                id: el.text_id,
                                subject: el.title,
                                text: el.text,
                                date: formatDate(el.classification_date),
                                sentiment: {
                                    label: t(
                                        `pages:sentimentAnalysis.${el.sentiment_label}`
                                    ),
                                    positive:
                                        el.sentiment_label === 'positive'
                                            ? Math.abs(
                                                  el.sentiment_score.toFixed(
                                                      2
                                                  ) * 100
                                              )
                                            : 0,
                                    negative:
                                        el.sentiment_label === 'negative'
                                            ? Math.abs(
                                                  el.sentiment_score.toFixed(
                                                      2
                                                  ) * 100
                                              )
                                            : 0,
                                    neutral:
                                        el.sentiment_label === 'neutral'
                                            ? Math.abs(
                                                  el.sentiment_score.toFixed(
                                                      2
                                                  ) * 100
                                              )
                                            : 0
                                },
                                firstCorrespondent: '',
                                secondCorrespondent: '',
                                emailCounter: 1,
                                positiveWordCounter: positiveWordsCounter,
                                negativeWordCounter: negativeWordsCounter,
                                neutralWordCounter: neutralWordsCounter,
                                positiveWords: positiveWords,
                                neutralWords: neutralWords,
                                negativeWords: negativeWords,
                                sentences: majorSentences
                            };
                            newMediaResults.push(newEl);
                            return el;
                        });
                        newResults.data.push({
                            input: el,
                            output: newMediaResults
                        });
                    }
                }
                onSetResults(newResults);
            } catch (err) {
                errorHandler(err);
            }
            //onSetResults();
        };

        const handleKeywordsUnselectOption = useCallback(
            (value, valueAux) => {
                unselectKeyword(value);
                keywordsUnselectOption(value, valueAux);
            },
            [unselectKeyword, keywordsUnselectOption]
        );

        return (
            <>
                {modal && modalType === 'csvColumnMap' && (
                    <Modal onToggleModal={handleCloseCsvColumnMapModal}>
                        <div
                            className="row mx-0 d-flex justify-content-start align-items-center "
                            style={{ marginBottom: '2rem' }}
                        >
                            <span className="modal-title">
                                {t('modals:addsentimentchannel')}
                            </span>
                        </div>
                        <div
                            className={`row mx-0 flex-grow-1 ${styles['data-column-map']}`}
                        >
                            <div
                                className={`col d-flex flex-column justify-content-between align-items-center px-0 ${styles['data-column-map__data-column-map-container']}`}
                            >
                                <div
                                    className={`row mx-0 w-100 ${styles['data-column-map__data-column-map-container__header']} h-auto`}
                                >
                                    <div
                                        className={`col ${styles['data-column-map__data-column-map-container__title-container']}`}
                                    >
                                        <span
                                            className={` ${styles['data-column-map__data-column-map-container__title-container__title']}`}
                                        >
                                            {t(
                                                'pages:sentimentAnalysis.columnsMap.title'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 flex-grow-1">
                                    <div className="col px-0 h-100 d-flex flex-column justify-content-between">
                                        <div
                                            className={`row mx-0 w-100 h-auto ${styles['data-column-map__data-column-map-container__column-row']}`}
                                        >
                                            <div
                                                className={
                                                    'col-2 px-0 h-100 pe-2'
                                                }
                                            >
                                                <SelectInput
                                                    hideOptionsAux
                                                    onChangeInput={
                                                        handleConversationIdSetValue
                                                    }
                                                    onSelectInput={
                                                        conversationIdCustomSelectOption
                                                    }
                                                    onKeyPress={
                                                        conversationIdKeyPress
                                                    }
                                                    onKeyDown={
                                                        conversationIdKeyDown
                                                    }
                                                    onToggleDropdown={
                                                        conversationIdToggleDropdown
                                                    }
                                                    {...conversationId}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    'col-2 px-0 h-100 pe-2'
                                                }
                                            >
                                                <SelectInput
                                                    hideOptionsAux
                                                    onChangeInput={
                                                        handleFromSetValue
                                                    }
                                                    onSelectInput={
                                                        fromCustomSelectOption
                                                    }
                                                    onKeyPress={fromKeyPress}
                                                    onKeyDown={fromKeyDown}
                                                    onToggleDropdown={
                                                        fromToggleDropdown
                                                    }
                                                    {...from}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    'col-2 px-0 h-100 pe-2'
                                                }
                                            >
                                                <SelectInput
                                                    hideOptionsAux
                                                    onChangeInput={
                                                        handleToSetValue
                                                    }
                                                    onSelectInput={
                                                        toCustomSelectOption
                                                    }
                                                    onKeyPress={toKeyPress}
                                                    onKeyDown={toKeyDown}
                                                    onToggleDropdown={
                                                        toToggleDropdown
                                                    }
                                                    {...to}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    'col-2 px-0 px-0 h-100 pe-2'
                                                }
                                            >
                                                <SelectInput
                                                    hideOptionsAux
                                                    onChangeInput={
                                                        handleSubjectSetValue
                                                    }
                                                    onSelectInput={
                                                        subjectCustomSelectOption
                                                    }
                                                    onKeyPress={subjectKeyPress}
                                                    onKeyDown={subjectKeyDown}
                                                    onToggleDropdown={
                                                        subjectToggleDropdown
                                                    }
                                                    {...subject}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    'col-2 px-0 h-100 pe-2'
                                                }
                                            >
                                                <SelectInput
                                                    hideOptionsAux
                                                    onChangeInput={
                                                        handleTextSetValue
                                                    }
                                                    onSelectInput={
                                                        textCustomSelectOption
                                                    }
                                                    onKeyPress={textKeyPress}
                                                    onKeyDown={textKeyDown}
                                                    onToggleDropdown={
                                                        textToggleDropdown
                                                    }
                                                    {...text}
                                                />
                                            </div>
                                            <div className={'col-2 px-0 h-100'}>
                                                <SelectInput
                                                    hideOptionsAux
                                                    onChangeInput={
                                                        handleDateSetValue
                                                    }
                                                    onSelectInput={
                                                        dateCustomSelectOption
                                                    }
                                                    onKeyPress={dateKeyPress}
                                                    onKeyDown={dateKeyDown}
                                                    onToggleDropdown={
                                                        dateToggleDropdown
                                                    }
                                                    {...date}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 flex-grow-1 overflow-auto"
                                            style={{ maxHeight: '20rem' }}
                                        >
                                            <div
                                                className={`col px-0 h-100 ${styles['data-column-map__data-column-map-container__data-wrapper']}`}
                                            >
                                                {_.findIndex(rows, (el) => {
                                                    return !_.every(
                                                        el,
                                                        (v) => v === ''
                                                    );
                                                }) !== -1 && (
                                                    <React.Fragment>
                                                        <ListWrapper>
                                                            {rows.map(
                                                                (el, index) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={`row mx-0 w-100 ${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper']}`}
                                                                        >
                                                                            {columns.map(
                                                                                (
                                                                                    el2,
                                                                                    index2
                                                                                ) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={
                                                                                                index2
                                                                                            }
                                                                                            className={
                                                                                                index2 !==
                                                                                                columns.length -
                                                                                                    1
                                                                                                    ? `col-2 px-0 h-100 ${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper__text-wrapper']} pe-2`
                                                                                                    : `col-2 px-0 h-100 ${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper__text-wrapper']}`
                                                                                            }
                                                                                        >
                                                                                            <FullTextPopup
                                                                                                text={
                                                                                                    el[
                                                                                                        el2
                                                                                                            .value
                                                                                                    ]
                                                                                                }
                                                                                            >
                                                                                                <span
                                                                                                    className={`${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper__text-wrapper__text']}`}
                                                                                                >
                                                                                                    {
                                                                                                        el[
                                                                                                            el2
                                                                                                                .value
                                                                                                        ]
                                                                                                    }
                                                                                                </span>
                                                                                            </FullTextPopup>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </ListWrapper>
                                                        <hr
                                                            className="my-4"
                                                            style={{
                                                                color: 'rgba(0,0,0,0.1)',
                                                                opacity: 1
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mx-0 mt-3 d-flex justify-content-between align-items-center">
                                            <div className="col px-0">
                                                <SecondaryButton
                                                    text={t('modals:cancel')}
                                                    onClick={
                                                        handleCloseCsvColumnMapModal
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{ width: '2rem' }}
                                            ></div>
                                            <div className="col px-0">
                                                <PrimaryButton
                                                    disabled={
                                                        !conversationId.selectedOption ||
                                                        !text.selectedOption ||
                                                        !date.selectedOption
                                                    }
                                                    text={t('modals:save')}
                                                    onClick={handleCsvFileSave}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className="w-100 h-100 d-flex flex-column">
                    <div
                        className="row mx-0 w-100 d-flex justify-content-end align-items-center "
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col-auto px-0">
                            <PrimaryButton
                                disabled={
                                    addedChannels.length === 0 ||
                                    _.find(addedChannels, { ready: false })
                                }
                                text={t('buttons:save')}
                                onClick={handleSentimentAnalysis}
                            />
                        </div>
                    </div>
                    <div
                        className={`${styles['sentiment-import-data-container']}`}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="row mx-0 h-100">
                            <div className="col px-0">
                                <div
                                    className={`${styles['sentiment-import-data-container__data-container']}`}
                                >
                                    <div className={`row mx-0 w-100 mb-3`}>
                                        <div className="col px-0">
                                            <div
                                                className="row mx-0 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0 d-flex">
                                                    <span
                                                        className={`${styles['sentiment-import-data-container__title']}`}
                                                    >
                                                        {t(
                                                            `components:sentiment-import-data.information`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col px-0">
                                                    <TypeInput
                                                        onChangeInput={
                                                            tagSetValue
                                                        }
                                                        {...tag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 "
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <div
                                                className="row mx-0 "
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['sentiment-import-data-container__title']}`}
                                                    >
                                                        {t(
                                                            `components:sentiment-import-data.importdata`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col px-0">
                                                    <FileImport
                                                        onHandleCsvFile={
                                                            handleCsvFile
                                                        }
                                                        onHandleMboxFile={
                                                            handleMboxFile
                                                        }
                                                        onHandlePstFile={
                                                            handlePstFile
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0"
                                        style={{
                                            height: '10rem',
                                            marginBottom: '2rem'
                                        }}
                                    >
                                        <div className="col px-0 h-100">
                                            <div
                                                className={`row mx-0 w-100 h-100 ${styles['sentiment-import-data-container__content']}`}
                                            >
                                                <div className="col px-0 d-flex flex-column h-100">
                                                    <TagMultipleInput
                                                        hideOptionsAux
                                                        onChangeInput={
                                                            keywordsSetValue
                                                        }
                                                        onSelectMultiple={
                                                            keywordsSelectOption
                                                        }
                                                        onSelectedOptionClick={
                                                            handleKeywordsUnselectOption
                                                        }
                                                        onKeyPress={
                                                            keywordsKeyPress
                                                        }
                                                        onKeyDown={
                                                            keywordsKeyDown
                                                        }
                                                        onToggleDropdown={
                                                            keywordsToggleDropdown
                                                        }
                                                        {...keywords}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col px-0 d-flex flex-column">
                                            <div
                                                className="row mx-0"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['sentiment-import-data-container__title']}`}
                                                    >
                                                        {t(
                                                            `components:sentiment-import-data.suggestedkeywords`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 flex-grow-1"
                                                style={{ minHeight: '10rem' }}
                                            >
                                                <div className="col px-0 h-100">
                                                    {recommendedKeywordsLoading && (
                                                        <Spinner />
                                                    )}
                                                    {!recommendedKeywordsLoading &&
                                                        recommendedKeywords &&
                                                        recommendedKeywords.length >
                                                            0 && (
                                                            <React.Fragment>
                                                                <div className="row mx-0 w-100 mb-4">
                                                                    <div className="col px-0">
                                                                        {recommendedKeywords
                                                                            .slice(
                                                                                0,
                                                                                30
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    el,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className={
                                                                                                el.selected
                                                                                                    ? `${styles['sentiment-import-data-container__content__recommended-keyword']} ${styles['selected']}`
                                                                                                    : `${styles['sentiment-import-data-container__content__recommended-keyword']}`
                                                                                            }
                                                                                            onClick={() =>
                                                                                                handleSelectRecommendedKeyword(
                                                                                                    index,
                                                                                                    el.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                className={`${styles['sentiment-import-data-container__content__recommended-keyword__text']}`}
                                                                                            >
                                                                                                {
                                                                                                    el.value
                                                                                                }
                                                                                            </span>
                                                                                            <img
                                                                                                loading="lazy"
                                                                                                src={
                                                                                                    icoPlus
                                                                                                }
                                                                                                alt="add suggested keyword"
                                                                                                className={`${styles['sentiment-import-data-container__content__recommended-keyword__icon']}`}
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col px-0">
                                <FileList
                                    files={addedChannels}
                                    onFileRemove={addedChannelsRemove}
                                    selectedFile={selectedFile}
                                    selectedFileLoading={selectedFileLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

SentimentImportData.propTypes = {};
