import React, { useMemo } from 'react'
import styles from './segment-mix.module.scss'
import icoBack from '../../../../../assets/images/icoArrowBackAction.svg'
import icoSave from '../../../../../assets/images/icoSaveCurrentDashboard.svg'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { BreadCrumbs } from '../../../../../components/formatting/breadcrumbs/BreadCrumbs'
import { Cassete } from '../../../../../components/formatting/cassete/cassete'
import { useTextInputv2 } from '../../../../../hooks/useTextInputv2'
import { TypeInput } from '../../../../../components'
import { useCheckboxInput } from '../../../../../hooks/useCheckboxInput'
import { SegmentPreview } from '../../components/segment-preview/segment-preview'
import { ActionBar } from '../../../../../components/actions/action-bar/action-bar'
import { CheckboxInputNew } from '../../../../../components/inputs/checkbox-input-new/checkbox-input-new'

export const SegmentMix = () => {
    const { t } = useCustomTranslation()
    const { value: name, actions: nameActions } = useTextInputv2('title')
    const { value: description, actions: descriptionActions } = useTextInputv2('description')

    const demographicInputs = {
        age: useCheckboxInput({
            name: 'age',
            options: [`18-24`, `25-34`, `35-44`, `45-54`, `55-64`, `65+`].map((label) => ({ label })),
        }),
        gender: useCheckboxInput({
            name: 'gender',
            options: [`${t('common:male')}`, `${t('common:female')}`, `${t('common:other')}`].map((label) => ({ label })),
        }),
        ethnicity: useCheckboxInput({
            name: 'ethnicity',
            options: [`${t('common:white')}`, `${t('common:black')}`, `${t('common:asian')}`, `${t('common:other')}`].map((label) => ({ label })),
        }),
        education: useCheckboxInput({
            name: 'education',
            options: [`${t('common:highSchool')}`, `${t('common:university')}`, `${t('common:postGraduate')}`, `${t('common:other')}`].map(
                (label) => ({
                    label,
                })
            ),
        }),
        maritalStatus: useCheckboxInput({
            name: 'maritalStatus',
            options: [`${t('common:single')}`, `${t('common:married')}`, `${t('common:divorced')}`, `${t('common:other')}`].map((label) => ({
                label,
            })),
        }),
        familySituation: useCheckboxInput({
            name: 'familySituation',
            options: [`0`, `1`, `2`, `3+`].map((label) => ({ label })),
        }),
        income: useCheckboxInput({
            name: 'income',
            options: [`0-25k`, `25-50k`, `50-75k`, `75-100k`, `100k+`].map((label) => ({ label })),
        }),
    }
    const finantialInputs = {
        daysSinceLastPurchase: useCheckboxInput({
            name: 'daysSinceLastPurchase',
            options: [`${t('common:lessThan')} 1`, '1 - 3', '3 - 10', '10 - 30', `${t('common:moreThan')} 30`].map((label) => ({ label })),
        }),
        totalOfPurchases: useCheckboxInput({
            name: 'totalOfPurchases',
            options: [`${t('common:lessThan')} 10`, '10 - 20', '20 - 50', '50 - 100', `${t('common:moreThan')} 10`].map((label) => ({ label })),
        }),
        totalOfRevenue: useCheckboxInput({
            name: 'totalOfRevenue',
            options: [`${t('common:lessThan')} €100`, '€100 - €200', '€200 - €500', '€500 - €1000', `${t('common:moreThan')} €100`].map((label) => ({
                label,
            })),
        }),
        avgOrderRevenue: useCheckboxInput({
            name: 'avgOrderRevenue',
            options: [`${t('common:lessThan')} €10`, '€10 - €20', '€20 - €50', '€50 - €100', `${t('common:moreThan')} €10`].map((label) => ({
                label,
            })),
        }),
        highestPurchaseRevenue: useCheckboxInput({
            name: 'highestPurchaseRevenue',
            options: [`${t('common:lessThan')} €10`, '€10 - €20', '€20 - €50', '€50 - €100', `${t('common:moreThan')} €10`].map((label) => ({
                label,
            })),
        }),
    }

    const retentionAndLoyaltyInputs = {
        customerRisk: useCheckboxInput({
            name: 'customerRisk',
            options: ['Low', 'Medium', 'High'].map((label) => ({ label })),
        }),
        customerLifetimeDuration: useCheckboxInput({
            name: 'customerLifetimeDuration',
            options: [
                `${t('common:lessThan')} 1 week`,
                '1 week - 1 month',
                '1 month - 6 months',
                '6 months - 1 year',
                `${t('common:moreThan')} 1 year`,
            ].map((label) => ({
                label,
            })),
        }),
        uniqueProductsPurchased: useCheckboxInput({
            name: 'uniqueProductsPurchased',
            options: [`${t('common:lessThan')} 5`, '5 - 10', '10 - 20', '20 - 50', `${t('common:moreThan')} 50`].map((label) => ({ label })),
        }),
    }

    const engagementInputs = {
        websiteVisits: useCheckboxInput({
            name: 'websiteVisits',
            options: [`${t('common:lessThan')} 1`, '1 - 3', '3 - 10', '10 - 30', `${t('common:moreThan')} 30`].map((label) => ({ label })),
        }),
        salesChannels: useCheckboxInput({
            name: 'salesChannels',
            options: [`${t('common:online')}`, `${t('common:retail')}`].map((label) => ({ label })),
        }),
    }

    const satisfactionInputs = {
        netPromoterScore: useCheckboxInput({
            name: 'netPromoterScore',
            options: [`${t('common:detractor')} (0-6)`, `${t('common:passive')} (7-8)`, `${t('common:promoter')} (9-10)`].map((label) => ({ label })),
        }),
    }

    const [clientType, setClientType] = React.useState('b2c')

    const crumbsMemo = useMemo(() => {
        return [
            {
                url: '/segment-analysis/overview',
                label: t('breadcrumbs:segmentAnalysis.overview'),
            },
            {
                url: '/segment-analysis/segments/new-segment',
                label: t('breadcrumbs:segmentAnalysis.newSegment'),
            },
            {
                url: '/segment-analysis/segments/new-segment/mix',
                label: t('breadcrumbs:segmentAnalysis.mix'),
                isTitle: true,
            },
        ]
    }, [t])

    return (
        <div className={`row mx-0 w-100 ${styles['segment-mix-container']}`}>
            <div className="col px-0 d-flex flex-column">
                <BreadCrumbs crumbs={crumbsMemo} />
                <div className="row w-100 mx-0">
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-title">{t(`pages:segmentMix.title`)}</span>
                    </div>
                </div>
                <div className="row w-100 mx-0" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-description">{t(`pages:segmentMix.description`)}</span>
                    </div>
                    <div className="col-auto px-0">
                        <ActionBar
                            actions={[
                                {
                                    text: t('buttons:back'),
                                    icon: icoBack,
                                    onClick: () => {
                                        window.history.back()
                                    },
                                },
                                {
                                    text: t('buttons:save'),
                                    icon: icoSave,
                                    onClick: () => {
                                        console.log('Save')
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mx-0 w-100">
                    <div className="col px-0" style={{ minWidth: 0 }}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <Cassete title={t('common:basicInputs')} startOpen>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <TypeInput {...name} {...nameActions} newLabelType />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ height: '10rem', marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <TypeInput {...description} {...descriptionActions} newLabelType inputType={'container'} />
                                        </div>
                                    </div>
                                </Cassete>
                            </div>
                        </div>
                        {RenderCassete('demographicInputs', demographicInputs)}
                        {RenderCassete('finantialInputs', finantialInputs)}
                        {RenderCassete('retentionAndLoyaltyInputs', retentionAndLoyaltyInputs)}
                        {RenderCassete('engagementInputs', engagementInputs)}
                        {RenderCassete('satisfactionInputs', satisfactionInputs)}
                    </div>
                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                    <div className="col-8 px-0">
                        <SegmentPreview type={'mix'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const RenderCassete = (title, inputs) => {
    const { t } = useCustomTranslation()
    return (
        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
            <div className="col px-0">
                <Cassete title={t(`common:${title}`)} startOpen>
                    {Object.values(inputs).map((checkboxInput, index) => (
                        <div key={index} className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <CheckboxInputNew {...checkboxInput.value} onClickCheckboxOption={checkboxInput.clickCheckboxOption} />
                            </div>
                        </div>
                    ))}
                </Cassete>
            </div>
        </div>
    )
}
