import React, { useCallback, useMemo, useState } from 'react'
import styles from './add-milestones-modal.module.scss'
import { Modal } from '../modal/modal'
import _ from 'lodash'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { DndContext } from '@dnd-kit/core'
import { MilestoneTimeline } from '../../goals/MilestoneTimeline/MilestoneTimeline'
import { MilestoneMarker } from '../../goals/MilestoneMarker/MilestoneMarker'
import { Draggable } from '../../dnd/Draggable/Draggable'
import { Droppable } from '../../dnd/Droppable/Droppable'
import icoTrash from '../../../assets/images/icoDeleteRed.svg'
import { months } from '../../../utils/date'

export const AddMilestonesModal = ({ onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation()
    const timelineRef = React.useRef(null)
    const [isDropped] = useState(false)
    const [tempDate, setTempDate] = useState('')
    const [newMilestone, setNewMilestone] = useState('') // { date: '1/1', value: 0
    const [tempDateReal, setTempDateReal] = useState('')
    const [dates] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    const [droppedDates, setDroppedDates] = useState([]) // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    const [draggingCurrent, setDraggingCurrent] = useState('')
    const draggableMarkup = (
        <Draggable hoverText={tempDate} id={'draggable'} showOver>
            <MilestoneMarker />
        </Draggable>
    )

    const monthToDrop = useMemo(() => {
        return tempDateReal.split('/')[1]
    }, [tempDateReal])

    const handleDragMove = useCallback(
        (e) => {
            if ((!timelineRef.current || !e.over) && e.active.id !== 'draggable') {
                if (e.active.id) {
                    setDraggingCurrent(e.active)
                }
                return
            } else if (e.active.id === 'draggable') {
                const rect = timelineRef.current.getBoundingClientRect()
                const mouseX = rect.width / 2 + e.delta.x
                // Assume we are dealing with the current year for this example
                const currentYear = new Date().getFullYear()
                const daysInYear = isLeapYear(currentYear) ? 366 : 365
                const dayOfYear = Math.ceil((mouseX / rect.width) * daysInYear)

                // Calculate month and day from dayOfYear
                let month = 1
                let dayOfMonth = dayOfYear
                while (dayOfMonth > daysInMonth(month - 1, currentYear)) {
                    dayOfMonth -= daysInMonth(month - 1, currentYear)
                    month++
                }

                // Adjust month and day for display (month is 0-indexed, so add 1 for display)
                const displayMonth = month
                const newTempDateReal = `${dayOfMonth}/${displayMonth}`
                const newTempDate = `${dayOfMonth}/${t(`months:${months[displayMonth]}`)}`
                setTempDateReal(newTempDateReal)
                setTempDate(newTempDate)
            }
        },
        [t]
    )

    // Helper function to check if a year is a leap year
    function isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
    }

    // Helper function to get the number of days in a month
    function daysInMonth(month, year) {
        return new Date(year, month + 1, 0).getDate()
    }

    return (
        <Modal onToggleModal={onCloseModal}>
            <div className="w-100 h-100 overflow-hidden">
                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center" style={{ marginBottom: '1rem' }}>
                    <div className="col-auto px-0">
                        <span className={`${styles['add-milestones-modal__title']}`}>Track your success!</span>
                    </div>
                </div>
                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center" style={{ marginBottom: '1rem' }}>
                    <div
                        className="col-auto px-0 d-flex justify-content-center"
                        style={{
                            maxWidth: '50rem',
                        }}
                    >
                        <span className={`${styles['add-milestones-modal__description']}`}>
                            Here, you have the ability to input monthly dates and specify the value or percentage you wish to monitor within your set
                            goal.
                        </span>
                    </div>
                </div>
                <div className="row mx-0 w-100" style={{ marginTop: '2rem' }}>
                    <div className="col px-0 d-flex flex-column justify-content-center">
                        <DndContext onDragEnd={handleDragEnd} onDragMove={handleDragMove}>
                            <div className="row mx-0 w-100" ref={timelineRef} style={{ margin: '5rem 0', height: '4rem' }}>
                                <div className="col px-0">
                                    <Droppable id={'droppable'}>
                                        <MilestoneTimeline
                                            isDraggable
                                            newMilestone={newMilestone}
                                            dates={dates}
                                            monthToDrop={monthToDrop}
                                            droppedDates={droppedDates}
                                            reference={timelineRef}
                                            onSaveMilestone={handleSaveMilestone}
                                            onCancelMilestone={handleCancelMilestone}
                                        />
                                    </Droppable>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                style={{
                                    marginBottom: '2rem',
                                    height: '10rem',
                                }}
                            >
                                <div className="col-auto px-0">{draggableMarkup}</div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-center">
                                <div className="col-auto px-0">
                                    <span className={`${styles['add-milestones-modal__marker-description']}`}>
                                        Drag the marker on the timeline to define your goal milestones.
                                    </span>
                                </div>
                            </div>
                            {draggingCurrent && (
                                <div className={`${styles['add-milestones-modal__remove-area']}`}>
                                    <Droppable id={'remove'}>
                                        <div>
                                            <img
                                                src={icoTrash}
                                                alt="Remove"
                                                style={{
                                                    width: '2.4rem',
                                                    height: '2.4rem',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                }}
                                            />
                                        </div>
                                    </Droppable>
                                </div>
                            )}
                        </DndContext>
                    </div>
                </div>
            </div>
        </Modal>
    )

    function handleSaveMilestone(date, target) {
        setDroppedDates(droppedDates.filter((el) => el.date !== date))
        setNewMilestone('')
        setDroppedDates([...droppedDates, { date, target }])
    }

    function handleCancelMilestone(date) {
        setDroppedDates(droppedDates.filter((el) => el.date !== date))
        setNewMilestone('')
    }

    function handleDragEnd({ over, active }) {
        if (active && active.id !== 'draggable' && over) {
            setDroppedDates(droppedDates.filter((el) => el.date !== active.id))
            setNewMilestone('')
        } else if (over && active.id === 'draggable') {
            setNewMilestone(tempDateReal)
        } else {
            setNewMilestone('')
        }
        setDraggingCurrent('')
        setTempDate('')
        setTempDateReal('')
    }
}
