export const loginHistoryMock = {
    loading: true,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: false,
    attr: 'loginChart',
    isForecast: false,
    titleText: '',
    areaAttr: [
        {
            dataKey: 'minLoginsCounterAux',
            color: '#8c54ff',
            dashArray: '3 3',
            strokeWidth: 1
        },
        {
            dataKey: 'loginsCounterAux',
            color: '#513091',
            strokeWidth: 2
        },
        {
            dataKey: 'loginsCounter',
            color: '#8c54ff',
            strokeWidth: 2
        }
    ],
    categoryAxis: 'day',
    data: [
        {
            loginsCounter: 10,
            loginsCounterAux: 10,
            minLoginsCounterAux: 10,
            time: '2024-01-12',
            day: '12 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            loginsCounter: 0,
            loginsCounterAux: 0,
            minLoginsCounterAux: 0,
            time: '2024-01-13',
            day: '13 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            loginsCounter: 0,
            loginsCounterAux: 0,
            minLoginsCounterAux: 0,
            time: '2024-01-14',
            day: '14 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            loginsCounter: 4,
            loginsCounterAux: 4,
            minLoginsCounterAux: 4,
            time: '2024-01-15',
            day: '15 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        }
    ],
    dateGrouper: '',
    gradientOn: true,
    lineType: 'monotone',
    maxValue: 25,
    minValue: 2,
    noForecast: true,
    laguange: 'pt',
    tooltipType: 'loginsHistory-daily',
    unitsOrValues: 'units',
    margin: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
    },
    interactionDisabled: false,
    ready: true
};
