import React from 'react'
import styles from './satisfaction-table-column.module.scss'

export const SatisfactionTableColumn = React.memo(({ el, column }) => {
    return (
        <div className={`${styles['satisfaction-table-column-container']}`}>
            <img loading="lazy" src={el[column.path]} alt="satisfaction icon" />
        </div>
    )
})
