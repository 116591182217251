export const riskDistributionMock = {
    hoverStyle: '',
    desc: '',
    attr: 'clientRiskChart',
    ready: true,
    titleText: 'DISTRIBUIÇÃO DE CLIENTE P/ RISCO',
    disabled: true,
    language: 'pt',
    risk1Total: 104,
    risk1Revenue: 104000,
    risk2Total: 104,
    risk2Revenue: 104000,
    risk3Total: 104,
    risk3Revenue: 104000,
    risk4Total: 104,
    risk4Revenue: 104000,
    barStackedAttr: [
        {
            dataKey: '1',
            color: '#6ad6c5',
            opacity: 0.8,
            stackId: 'a',
            radius: [0, 0, 0, 0]
        },
        {
            dataKey: '2',
            color: '#dbdb6f',
            opacity: 0.8,
            stackId: 'a',
            radius: [0, 0, 0, 0]
        },
        {
            dataKey: '3',
            color: '#ffbd77',
            opacity: 0.8,
            stackId: 'a',
            radius: [0, 0, 0, 0]
        },
        {
            dataKey: '4',
            opacity: 0.8,
            color: '#ff6363',
            stackId: 'a',
            radius: [8, 8, 0, 0]
        }
    ],
    categoryAxis: 'categoryAttr',
    categoryLabel: '',
    data: [
        {
            1: 339,
            2: 78,
            3: 74,
            4: 46,
            categoryAttr: 'riskCategory'
        }
    ],
    orientation: 'horizontal',
    tooltipType: 'clientRisk',
    unitsOrValues: 'units',
    areaAttrRisk1: [
        {
            dataKey: 'minForecastValue-risk1',
            color: '#fd9999',
            strokeWidth: 2,
            dashArray: '3 3'
        },
        {
            dataKey: 'unitsSold-risk1',
            color: '#fd9999',
            strokeWidth: 2
        }
    ],
    categoryAxisRisk1: 'month',
    dataRisk1: [
        {
            'unitsSold-risk1': 10,

            'minForecastValue-risk1': 10,
            month: 'Jan',
            time: 'Jan'
        },
        {
            'unitsSold-risk1': 15,

            'minForecastValue-risk1': 15,
            month: 'Feb',
            time: 'Feb'
        },
        {
            'unitsSold-risk1': 10,

            'minForecastValue-risk1': 10,
            month: 'Mar',
            time: 'Mar'
        },
        {
            'unitsSold-risk1': 8,

            'minForecastValue-risk1': 8,
            month: 'Apr',
            time: 'Apr'
        },
        {
            'unitsSold-risk1': 9,
            'minForecastValue-risk1': 9,
            month: 'May',
            time: 'May'
        }
    ],
    dateGrouperRisk1: '',
    gradientOnRisk1: 0.6,
    lineTypeRisk1: 'monotone',
    maxValueRisk1: 25,
    minValueRisk1: 2,
    noForecastRisk1: false,
    timeAttrRisk1: 'month',
    laguangeRisk1: 'pt',
    tooltipTypeRisk1: 'salesForecast-monthly',
    unitsOrValuesRisk1: 'units',

    areaAttrRisk2: [
        {
            dataKey: 'minForecastValue-risk2',
            color: '#ff8a20',
            strokeWidth: 2,
            dashArray: '3 3'
        },

        {
            dataKey: 'unitsSold-risk2',
            color: '#ff8a20',
            strokeWidth: 2
        }
    ],
    categoryAxisRisk2: 'month',
    dataRisk2: [
        {
            'unitsSold-risk2': 10,

            'minForecastValue-risk2': 10,
            month: 'Jan',
            time: 'Jan'
        },
        {
            'unitsSold-risk2': 15,

            'minForecastValue-risk2': 15,
            month: 'Feb',
            time: 'Feb'
        },
        {
            'unitsSold-risk2': 10,

            'minForecastValue-risk2': 10,
            month: 'Mar',
            time: 'Mar'
        },
        {
            'unitsSold-risk2': 8,

            'minForecastValue-risk2': 8,
            month: 'Apr',
            time: 'Apr'
        },
        {
            'unitsSold-risk2': 9,
            'minForecastValue-risk2': 9,
            month: 'May',
            time: 'May'
        }
    ],
    dateGrouperRisk2: '',
    gradientOnRisk2: 0.6,
    lineTypeRisk2: 'monotone',
    maxValueRisk2: 25,
    minValueRisk2: 2,
    noForecastRisk2: false,
    timeAttrRisk2: 'month',
    laguangeRisk2: 'pt',
    tooltipTypeRisk2: 'salesForecast-monthly',
    unitsOrValuesRisk2: 'units',

    areaAttrRisk3: [
        {
            dataKey: 'minForecastValue-risk3',
            color: '#f8c100',
            strokeWidth: 2,
            dashArray: '3 3'
        },

        {
            dataKey: 'unitsSold-risk3',
            color: '#f8c100',
            strokeWidth: 2
        }
    ],
    categoryAxisRisk3: 'month',
    dataRisk3: [
        {
            'unitsSold-risk3': 10,

            'minForecastValue-risk3': 10,
            month: 'Jan',
            time: 'Jan'
        },
        {
            'unitsSold-risk3': 15,

            'minForecastValue-risk3': 15,
            month: 'Feb',
            time: 'Feb'
        },
        {
            'unitsSold-risk3': 10,

            'minForecastValue-risk3': 10,
            month: 'Mar',
            time: 'Mar'
        },
        {
            'unitsSold-risk3': 8,

            'minForecastValue-risk3': 8,
            month: 'Apr',
            time: 'Apr'
        },
        {
            'unitsSold-risk3': 9,
            'minForecastValue-risk3': 9,
            month: 'May',
            time: 'May'
        }
    ],
    dateGrouperRisk3: '',
    gradientOnRisk3: 0.6,
    lineTypeRisk3: 'monotone',
    maxValueRisk3: 25,
    minValueRisk3: 2,
    noForecastRisk3: false,
    timeAttrRisk3: 'month',
    laguangeRisk3: 'pt',
    tooltipTypeRisk3: 'salesForecast-monthly',
    unitsOrValuesRisk3: 'units',
    areaAttrRisk4: [
        {
            dataKey: 'minForecastValue-risk4',
            color: '#46d38c',
            strokeWidth: 2,
            dashArray: '3 3'
        },

        {
            dataKey: 'unitsSold-risk4',
            color: '#46d38c',
            strokeWidth: 2
        }
    ],
    categoryAxisRisk4: 'month',
    dataRisk4: [
        {
            'unitsSold-risk4': 10,

            'minForecastValue-risk4': 10,
            month: 'Jan',
            time: 'Jan'
        },
        {
            'unitsSold-risk4': 15,

            'minForecastValue-risk4': 15,
            month: 'Feb',
            time: 'Feb'
        },
        {
            'unitsSold-risk4': 10,

            'minForecastValue-risk4': 10,
            month: 'Mar',
            time: 'Mar'
        },
        {
            'unitsSold-risk4': 8,

            'minForecastValue-risk4': 8,
            month: 'Apr',
            time: 'Apr'
        },
        {
            'unitsSold-risk4': 9,
            'minForecastValue-risk4': 9,
            month: 'May',
            time: 'May'
        }
    ],
    dateGrouperRisk4: '',
    gradientOnRisk4: 0.6,
    lineTypeRisk4: 'monotone',
    maxValueRisk4: 25,
    minValueRisk4: 2,
    noForecastRisk4: false,
    timeAttrRisk4: 'month',
    laguangeRisk4: 'pt',
    tooltipTypeRisk4: 'salesForecast-monthly',
    unitsOrValuesRisk4: 'units',
    margin: {
        top: 20,
        right: 20,
        left: -20,
        bottom: -20
    },
    legend: [
        {
            name: 'risk1',
            color: '#6fdb7d',
            translated: false
        },
        {
            name: 'risk2',
            color: '#dbdb6f',
            translated: false
        },
        {
            name: 'risk3',
            color: '#ffbd77',
            translated: false
        },
        {
            name: 'risk4',
            color: '#ff6363',
            translated: false
        }
    ],
    quantitativeLabel: '',
    hideXTicks: true,
    hideYTicks: false,
    hideCursor: true,
    barWidth: 70,
    loading: true,
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: 'VIEW',
            options: []
        },
        options: {
            label: 'OPTIONS',
            options: []
        }
    }
};
