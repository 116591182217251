import React from 'react';
import styles from './BillingDetails.module.scss';

export const BillingDetails = () => {
    return (
        <div className={styles['billing-details-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={styles['billing-details-container__title']}
                    >
                        Billing Details
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '4rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles['billing-details-container__sub-title']
                        }
                    >
                        Next bill date
                    </span>
                    <span
                        className={
                            styles['billing-details-container__content-text']
                        }
                    >
                        Apr. 07, 2024
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <span
                        className={
                            styles['billing-details-container__sub-title']
                        }
                    >
                        Next price estimate
                    </span>
                    <span
                        className={
                            styles['billing-details-container__content-text']
                        }
                    >
                        $ 1,200.00
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <span
                        className={
                            styles['billing-details-container__sub-title']
                        }
                    >
                        Billing address
                    </span>
                    <span
                        className={
                            styles['billing-details-container__content-text']
                        }
                    >
                        1234 Main St. Suite 200
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <span
                        className={
                            styles['billing-details-container__sub-title']
                        }
                    >
                        Billing contact
                    </span>
                    <span
                        className={
                            styles['billing-details-container__content-text']
                        }
                    >
                        John Doe
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <span
                        className={
                            styles['billing-details-container__sub-title']
                        }
                    >
                        Usage
                    </span>
                    <span
                        className={
                            styles['billing-details-container__content-text']
                        }
                    >
                        4850 out of 5k monthly active members
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div
                        className={
                            styles['billing-details-container__progress-bar']
                        }
                    >
                        <div
                            className={
                                styles[
                                    'billing-details-container__progress-bar__progress'
                                ]
                            }
                            style={{ width: '97%' }}
                        ></div>
                        <div
                            className={
                                styles[
                                    'billing-details-container__progress-bar__progress-circle'
                                ]
                            }
                            style={{
                                left: 'calc(97% - 0.75rem)'
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
