import React, { useMemo } from 'react'
import styles from './segment-demographic.module.scss'
import icoAge from '../../../../../assets/images/icoAge.svg'
import icoGender from '../../../../../assets/images/icoGender.svg'
import icoEthnicity from '../../../../../assets/images/icoEthnicity.svg'
import icoEducation from '../../../../../assets/images/icoEducation.svg'
import icoMaritalStatus from '../../../../../assets/images/icoMaritalStatus.svg'
import icoIncome from '../../../../../assets/images/icoIncome.svg'
import icoFamilySituation from '../../../../../assets/images/icoFamilySituation.svg'
import icoBack from '../../../../../assets/images/icoArrowBackAction.svg'
import icoSave from '../../../../../assets/images/icoSaveCurrentDashboard.svg'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { BreadCrumbs } from '../../../../../components/formatting/breadcrumbs/BreadCrumbs'
import { Cassete } from '../../../../../components/formatting/cassete/cassete'
import { useTextInputv2 } from '../../../../../hooks/useTextInputv2'
import { PrimaryButton, TerciaryButton, TypeInput } from '../../../../../components'
import { useCheckboxInput } from '../../../../../hooks/useCheckboxInput'
import { CheckboxInputNew } from '../../../../../components/inputs/checkbox-input-new/checkbox-input-new'
import { SegmentPreview } from '../../components/segment-preview/segment-preview'
import { ActionBar } from '../../../../../components/actions/action-bar/action-bar'

export const SegmentDemographic = () => {
    const { t } = useCustomTranslation()
    const { value: name, actions: nameActions } = useTextInputv2('title')
    const { value: description, actions: descriptionActions } = useTextInputv2('description')
    const { value: age, clickCheckboxOption: ageClickCheckboxOption } = useCheckboxInput({
        name: 'age',
        options: [
            {
                name: '18-24',
            },
            {
                name: '25-34',
            },
            {
                name: '35-44',
            },
            {
                name: '45-54',
            },
            {
                name: '55-64',
            },
            {
                name: '65+',
            },
        ],
    })

    const { value: gender, clickCheckboxOption: genderClickCheckboxOption } = useCheckboxInput({
        name: 'gender',
        options: [
            {
                name: 'Male',
            },
            {
                name: 'Female',
            },
            {
                name: 'Other',
            },
        ],
    })

    const { value: ethnicity, clickCheckboxOption: ethnicityClickCheckboxOption } = useCheckboxInput({
        name: 'ethnicity',
        options: [
            {
                name: 'White',
            },
            {
                name: 'Black',
            },
            {
                name: 'Asian',
            },
            {
                name: 'Other',
            },
        ],
    })

    const { value: education, clickCheckboxOption: educationClickCheckboxOption } = useCheckboxInput({
        name: 'education',
        options: [
            {
                name: 'highSchool',
            },
            {
                name: 'University',
            },
            {
                name: 'Post Graduate',
            },
            {
                name: 'Other',
            },
        ],
    })

    const { value: maritalStatus, clickCheckboxOption: maritalStatusClickCheckboxOption } = useCheckboxInput({
        name: 'maritalStatus',
        options: [
            {
                name: 'Single',
            },
            {
                name: 'Married',
            },
            {
                name: 'Divorced',
            },
            {
                name: 'Other',
            },
        ],
    })

    const { value: familySituation, clickCheckboxOption: familySituationClickCheckboxOption } = useCheckboxInput({
        name: 'familySituation',
        options: [
            {
                name: '0',
            },
            {
                name: '1',
            },
            {
                name: '2',
            },
            {
                name: '3+',
            },
        ],
    })

    const { value: income, clickCheckboxOption: incomeClickCheckboxOption } = useCheckboxInput({
        name: 'income',
        options: [
            {
                name: '0-25k',
            },
            {
                name: '25-50k',
            },
            {
                name: '50-75k',
            },
            {
                name: '75-100k',
            },
            {
                name: '100k+',
            },
        ],
    })

    const [clientType, setClientType] = React.useState('b2c')

    const crumbsMemo = useMemo(() => {
        return [
            {
                url: '/segment-analysis/overview',
                label: t('breadcrumbs:segmentAnalysis.overview'),
            },
            {
                url: '/segment-analysis/segments/new-segment',
                label: t('breadcrumbs:segmentAnalysis.newSegment'),
            },
            {
                url: '/segment-analysis/segments/new-segment/demographic',
                label: t('breadcrumbs:segmentAnalysis.demographic'),
                isTitle: true,
            },
        ]
    }, [t])

    return (
        <div className={`row mx-0 w-100 ${styles['segment-demographic-container']}`}>
            <div className="col px-0 d-flex flex-column">
                <BreadCrumbs crumbs={crumbsMemo} />
                <div className="row w-100 mx-0">
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-title">{t(`pages:segmentDemographic.title`)}</span>
                    </div>
                </div>
                <div className="row w-100 mx-0" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="dashboard-description">{t(`pages:segmentDemographic.description`)}</span>
                    </div>
                    <div className="col-auto px-0">
                        <ActionBar
                            actions={[
                                {
                                    text: t('buttons:back'),
                                    icon: icoBack,
                                    onClick: () => {
                                        window.history.back()
                                    },
                                },
                                {
                                    text: t('buttons:save'),
                                    icon: icoSave,
                                    onClick: () => {
                                        console.log('Save')
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mx-0 w-100">
                    <div className="col px-0" style={{ minWidth: 0 }}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <Cassete title={t('common:basicInputs')} startOpen>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <TypeInput {...name} {...nameActions} newLabelType />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ height: '10rem', marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <TypeInput {...description} {...descriptionActions} newLabelType inputType={'container'} />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0" style={{ minWidth: 0 }}>
                                            {clientType === 'b2c' ? (
                                                <PrimaryButton text={t('buttons:b2c')} onClick={() => setClientType('b2c')} />
                                            ) : (
                                                <TerciaryButton text={t('buttons:b2c')} onClick={() => setClientType('b2c')} />
                                            )}
                                        </div>
                                        <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                                        <div className="col px-0" style={{ minWidth: 0 }}>
                                            {clientType === 'b2b' ? (
                                                <PrimaryButton text={t('buttons:b2b')} onClick={() => setClientType('b2b')} />
                                            ) : (
                                                <TerciaryButton text={t('buttons:b2b')} onClick={() => setClientType('b2b')} />
                                            )}
                                        </div>
                                    </div>
                                </Cassete>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <Cassete title={t('common:demographicInputs')} startOpen>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew {...age} icon={icoAge} onClickCheckboxOption={ageClickCheckboxOption} />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew {...gender} icon={icoGender} onClickCheckboxOption={genderClickCheckboxOption} />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew
                                                {...ethnicity}
                                                icon={icoEthnicity}
                                                onClickCheckboxOption={ethnicityClickCheckboxOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew
                                                {...education}
                                                icon={icoEducation}
                                                onClickCheckboxOption={educationClickCheckboxOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew
                                                {...maritalStatus}
                                                icon={icoMaritalStatus}
                                                onClickCheckboxOption={maritalStatusClickCheckboxOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew
                                                {...familySituation}
                                                icon={icoFamilySituation}
                                                onClickCheckboxOption={familySituationClickCheckboxOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                        <div className="col px-0">
                                            <CheckboxInputNew {...income} icon={icoIncome} onClickCheckboxOption={incomeClickCheckboxOption} />
                                        </div>
                                    </div>
                                </Cassete>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                    <div className="col-8 px-0">
                        <SegmentPreview />
                    </div>
                </div>
            </div>
        </div>
    )
}
