import { createContext, useContext } from 'react';

const ComponentFilterContext = createContext({
    componentFilter: undefined,
    setComponentFilter: () => {},
    handleSaveChartConfig: () => {},
    handleCancelChartConfig: () => {}
});

export function ComponentFilterProvider({ value, children }) {
    return (
        <ComponentFilterContext.Provider value={value}>
            {children}
        </ComponentFilterContext.Provider>
    );
}

export function useComponentFilter() {
    return useContext(ComponentFilterContext);
}
