import React from 'react'
import styles from './TeamSmallCard.module.scss'
import { ActionsButtonNew } from '../../buttons/actions-button-new/actions-button-new'
import icoTeamCard from '../../../assets/images/icoTeamCard.svg'
import FullTextPopup from '../../popups/FullTextPopup'

export const TeamSmallCard = ({ onSelectCard, team, onClickOption }) => {
    return (
        <div
            className={styles['team-small-card-container']}
            onClick={() => {
                onSelectCard(team)
            }}
        >
            <div className="row mx-0 w-100 d-flex justify-content-between align-items-center" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-start">
                    <img
                        src={icoTeamCard}
                        alt="Team Card"
                        className={styles['team-small-card-container__icon']}
                        style={{
                            marginRight: '1rem',
                        }}
                    />
                    <span className={styles['team-small-card-container__title']}>{team.teamName}</span>
                </div>
                <div className="col-auto px-0">
                    <ActionsButtonNew actions={team.action} onClickOption={(args) => onClickOption(args, team.id)} />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div
                    className="col-6 px-0"
                    style={{
                        paddingRight: '2rem',
                    }}
                >
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={styles['team-small-card-container__content']}>Manager</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <FullTextPopup text={team.teamManager}>
                                <span className={styles['team-small-card-container__subTitle']}>{team.teamManager}</span>
                            </FullTextPopup>
                        </div>
                    </div>
                </div>
                <div
                    className="col-6 px-0"
                    style={{
                        paddingRight: '2rem',
                    }}
                >
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={styles['team-small-card-container__content']}>Members</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <FullTextPopup text={team.teamMembers}>
                                <span className={styles['team-small-card-container__subTitle']}>{team.teamMembers}</span>
                            </FullTextPopup>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div
                    className="col-6 px-0"
                    style={{
                        paddingRight: '2rem',
                    }}
                >
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={styles['team-small-card-container__content']}>Revenue</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <FullTextPopup text={team.revenue}>
                                <span className={styles['team-small-card-container__subTitle']}>{team.revenue}</span>
                            </FullTextPopup>
                        </div>
                    </div>
                </div>
                <div
                    className="col-6 px-0"
                    style={{
                        paddingRight: '2rem',
                    }}
                >
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={styles['team-small-card-container__content']}>Customers</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <FullTextPopup text={team.customers}>
                                <span className={styles['team-small-card-container__subTitle']}>{team.customers}</span>
                            </FullTextPopup>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0"></div>
            </div>
            <div className={styles['team-small-card-container__members-container']}>
                <div className="d-flex">
                    {
                        team.members.slice(0, 3).map((member, index) => (
                            <img key={index} src={member.image} className={styles['team-small-card-container__members-container__avatar']} />
                        )) // Unique key based on member's position in the array
                    }
                    {team.members.length > 3 && (
                        <div className={styles['team-small-card-container__members-container__avatar-plus']}>
                            <span className={styles['team-small-card-container__members-container__avatar-plus__text']}>
                                +{team.members.length - 3}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
