import { useCallback, useReducer } from 'react';

const ACTIONS = {
	COMPLETE_TUTORIAL_STEP: 'completeTutorialStep',
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.COMPLETE_TUTORIAL_STEP:
            state[action.payload].completed = true
			return [ ...state ];
	}
}

export function useTutorialStep(defaultValue) {
	const [value, dispatch] = useReducer(reducer, defaultValue);

	const completeTutorialStep = useCallback((index) => {
		dispatch({ type: 'completeTutorialStep', payload: index });
	}, []);


	return { value, completeTutorialStep };
}
