import React from 'react';

export const associatedTeamsMock = {
    titleCounter: '0',
    titleText: '',
    placeholderText: '',
    sortedData: [
        {
            id: 9,
            name: 'new1',
            responsibleMembers: 3,
            productsCount: 1,
            action: {
                label: 'Actions',
                id: 9,
                open: false,
                options: [
                    {
                        name: 'disassociateTeam',
                        label: 'Disassociate Team',
                        color: '#DB6162'
                    }
                ],
                reference: {
                    current: null
                }
            }
        },
        {
            id: 6,
            name: 'Equipa Especialidade R&I INALADOS',
            responsibleMembers: '',
            productsCount: 4,
            action: {
                label: 'Actions',
                id: 6,
                open: false,
                options: [
                    {
                        name: 'disassociateTeam',
                        label: 'Disassociate Team',
                        color: '#DB6162'
                    }
                ],
                reference: {
                    current: null
                }
            }
        },
        {
            id: 5,
            name: 'Equipa Especialidade R&I Biológicos',
            responsibleMembers: 1,
            productsCount: 3,
            action: {
                label: 'Actions',
                id: 5,
                open: false,
                options: [
                    {
                        name: 'disassociateTeam',
                        label: 'Disassociate Team',
                        color: '#DB6162'
                    }
                ],
                reference: {
                    current: null
                }
            }
        }
    ],
    data: [
        {
            id: 9,
            name: 'new1',
            responsibleMembers: 3,
            productsCount: 1,
            action: {
                label: 'Actions',
                id: 9,
                open: false,
                options: [
                    {
                        name: 'disassociateTeam',
                        label: 'Disassociate Team',
                        color: '#DB6162'
                    }
                ],
                reference: {
                    current: null
                }
            }
        },
        {
            id: 6,
            name: 'Equipa Especialidade R&I INALADOS',
            responsibleMembers: '',
            productsCount: 4,
            action: {
                label: 'Actions',
                id: 6,
                open: false,
                options: [
                    {
                        name: 'disassociateTeam',
                        label: 'Disassociate Team',
                        color: '#DB6162'
                    }
                ],
                reference: {
                    current: null
                }
            }
        },
        {
            id: 5,
            name: 'Equipa Especialidade R&I Biológicos',
            responsibleMembers: 1,
            productsCount: 3,
            action: {
                label: 'Actions',
                id: 5,
                open: false,
                options: [
                    {
                        name: 'disassociateTeam',
                        label: 'Disassociate Team',
                        color: '#DB6162'
                    }
                ],
                reference: {
                    current: null
                }
            }
        }
    ],
    columns: [
        {
            path: 'name',
            label: '',
            sortable: true
        },
        {
            path: 'responsibleMembers',
            label: '',
            sortable: true
        },
        {
            path: 'productsCount',
            label: '',
            sortable: true
        },
        {
            path: 'action',
            label: '',
            sortable: false
        }
    ],
    sortColumn: {
        path: 'teamName',
        order: 'asc'
    },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'associatedTeamsTable',
    disabled: true,
    loading: false,
    settings: {
        open: false,
        selected: {
            label: 'SELECTED',
            total: 0,
            options: []
        },
        view: {
            options: [],
            label: 'VIEW'
        },
        options: {
            label: 'OPTIONS',
            options: []
        },
        reference: {
            current: null
        }
    }
};
