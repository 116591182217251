import 'bootstrap/dist/css/bootstrap.css';
import 'event-source-polyfill/src/eventsource.min.js';
import 'mapbox-gl/dist/mapbox-gl.css';
import { createRoot } from 'react-dom/client';
import 'react-grid-layout/css/styles.css';
import './sass/index.scss';
import './configs/i18nextConf.js';
import './index.scss';
import './assets/typography.scss';
import 'react-quill/dist/quill.snow.css';
import 'draft-js/dist/Draft.css';

import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import { LoaderForeground } from './components';

const root = createRoot(document.getElementById('root'));
root.render(
    <Suspense
        fallback={
            <div style={{ width: '100vw', height: '100vh' }}>
                <LoaderForeground />
            </div>
        }
    >
        <CookiesProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </CookiesProvider>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
