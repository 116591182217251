import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { useFilter } from '../../../providers/filter-context';
import { productListMock } from './mock';
import { useChartReady } from '../../../providers/chart-ready-context';
import { ContentContainer, Loader, Spinner, Table } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * List all clients in a table
 */

export const ProductList = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { setChartReady } = useChartReady();
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [modal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            pageChange: dataPageChange,
            sort: dataSort,
            selectAll: dataSelectAll,
            unselectAll: dataUnselectAll,
            setLoading,
            setValue
        } = useTable(productListMock, modal, 'client');

        useEffect(() => {
            async function fill() {
                //
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setValue,
            setLoading,
            t,
            type,
            setChartReady
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <Table
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(
                                `components:${data.attr}.nodata`
                            )}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                        ></Table>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ProductList.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
