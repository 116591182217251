import _ from 'lodash'
import React, { useEffect } from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { SalesService } from '../../../temp/test'
import { revenueSalesMock } from './mock'
import { errorHandler } from '../../../utils/api.js'
import { getFilterParam } from '../../../utils/params'
import { ComponentWrapper, DynamicComposedChartContainer } from '../../'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { useSalesChannel } from '../../../providers/sales-channel-context'
import { fromBackTimeToFront } from '../../../utils/timeseries'

/**
 * Displays the unit sales over time without forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer)

export const RevenueSales = React.memo(({ component, isPreview, isExample, config, ...rest }) => {
    const { filter } = useFilter()
    const isMounted = useIsMounted()
    const { timeInterval } = useTimeInterval()
    const { channels } = useSalesChannel()
    const { t } = useCustomTranslation()

    const { value: data, setLoading, setValue } = useSimpleComponent(revenueSalesMock)

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(revenueSalesMock)
            try {
                if (!isExample) {
                    setLoading(true)
                    dataClone.data.length = 0
                    const dataSales = await SalesService.getApiSales({
                        producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                        interval: timeInterval,
                        clientId: getFilterParam(filter, config, ['clientId', 'clientIds']),
                        vendorId: getFilterParam(filter, config, ['vendorId', 'vendorIds']),
                        locationId: [],
                        productId: getFilterParam(filter, config, ['productId', 'productIds']),
                        teamId: getFilterParam(filter, config, ['teamId', 'teamIds']),
                        regionId: getFilterParam(filter, config, ['regionId', 'regionIds']),
                        channelId: JSON.parse(channels),
                        sumBy: 'billing',
                    })
                    dataClone.categoryAxis = timeInterval
                    fromBackTimeToFront(Object.entries(dataSales), dataClone.data, [{ back: 1, front: 'revenue' }], timeInterval, '0')
                    dataClone.legend = [{ name: 'revenue', color: '#2cd9c5' }]
                    dataClone.loading = false
                    setValue(dataClone)
                } else {
                    setValue(dataClone)
                    setLoading(false)
                }
            } catch (err) {
                errorHandler(err)
                dataClone.loading = false
                if (isMounted.current) {
                    setValue(dataClone)
                }
            }
        }
        fill()
    }, [config, filter, isExample, isMounted, setLoading, setValue, t, timeInterval, isPreview, channels])

    return (
        <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper withTimeFilter withChannelFilter component={component} {...data} {...rest}>
                <Chart {...data} />
            </ComponentWrapper>
        </div>
    )
})

RevenueSales.propTypes = {}
