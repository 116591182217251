import React, { useCallback, useEffect } from 'react'
import styles from './product-atc4-donut.module.scss'
import { useTranslation } from 'react-i18next'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { errorHandler } from '../../../utils/api'
import _ from 'lodash'
import { useSub } from '../../../hooks/usePubSub'
import icoUpPositive from '../../../assets/images/ico-up-positive.svg'
import icoDownNegative from '../../../assets/images/ico-down-negative.svg'
import { ContentContainer, DonutChart, Loader, Spinner } from '../..'
import { productAtc4DonutMock } from './mock'
import { ProductsService, TeamsService } from '../../../temp/test'
import { getValueInString } from '../../../utils/unit'
import { getFilterParam } from '../../../utils/params'
import { Promise } from 'q'
import { getMonthFromDateFormat } from '../../../utils/date'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

/**
 * Displays the count and evolution of new clients compared to the previous month
 */

export const ProductAtc4Donut = React.memo(
    ({ isExample, isReportExample, pinned, onPinComponent, onUnpinComponent, onMouseEnterInfoIcon, onMouseLeaveInfoIcon, isPreview, config }) => {
        const { filter } = useFilter()
        const { t } = useCustomTranslation()
        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(productAtc4DonutMock)

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(productAtc4DonutMock)
            if (!isExample) {
                try {
                    setLoading(true)
                    const forbiddenCategories = ['V&I', 'OBU', 'R&I', 'CVRM']
                    const productResult = await ProductsService.getApiProducts1({
                        id: getFilterParam(filter, config, ['productId', 'productIds']),
                    })
                    await Promise.all(
                        productResult.categories.map(async (category, categoryIndex) => {
                            if (!forbiddenCategories.includes(category.code) && category.code.length === 5) {
                                const categoryResult = await ProductsService.getApiProductsMarketShare({
                                    productId: getFilterParam(filter, config, ['productId', 'productIds']),
                                    productCategoryId: category.id,
                                    unitsOrBilling: 'units',
                                })
                                const currentMonthData = Object.entries(categoryResult)[0]
                                const monthForecast1 = Object.entries(categoryResult)[1]
                                const monthForecast2 = Object.entries(categoryResult)[2]
                                const monthForecast3 = Object.entries(categoryResult)[3]
                                dataClone.currentMonth = getMonthFromDateFormat(currentMonthData[0], t)
                                dataClone.forecast = [
                                    {
                                        label: getMonthFromDateFormat(monthForecast1[0], t),
                                        growth: getValueInString(
                                            (monthForecast1[1].product_sales / monthForecast1[1].all_sales -
                                                currentMonthData[1].product_sales / currentMonthData[1].all_sales) *
                                                100
                                        ),
                                        percentage: ((monthForecast1[1].product_sales / monthForecast1[1].all_sales) * 100).toFixed(2) + '%',
                                        total: getValueInString(Math.round(monthForecast1[1].product_sales), 'units'),
                                    },
                                    {
                                        label: getMonthFromDateFormat(monthForecast2[0], t),
                                        growth: getValueInString(
                                            (monthForecast2[1].product_sales / monthForecast2[1].all_sales -
                                                monthForecast1[1].product_sales / monthForecast1[1].all_sales) *
                                                100
                                        ),
                                        percentage: ((monthForecast2[1].product_sales / monthForecast2[1].all_sales) * 100).toFixed(2) + '%',
                                        total: getValueInString(Math.round(monthForecast2[1].product_sales), 'units'),
                                    },
                                    {
                                        label: getMonthFromDateFormat(monthForecast3[0], t),
                                        growth: getValueInString(
                                            (monthForecast3[1].product_sales / monthForecast3[1].all_sales -
                                                monthForecast2[1].product_sales / monthForecast2[1].all_sales) *
                                                100
                                        ),
                                        percentage: ((monthForecast3[1].product_sales / monthForecast3[1].all_sales) * 100).toFixed(2) + '%',
                                        total: getValueInString(Math.round(monthForecast3[1].product_sales), 'units'),
                                    },
                                ]
                                dataClone.donutAttr = [
                                    {
                                        dataKey: 'data1',
                                        valueKey: 'value',
                                        nameKey: 'key1',
                                    },
                                ]
                                dataClone.data = {
                                    data1: [
                                        {
                                            key1: 'Others',
                                            name: t('common:others'),
                                            value: currentMonthData[1].all_sales - currentMonthData[1].product_sales,
                                            percentage:
                                                (currentMonthData[1].all_sales - currentMonthData[1].product_sales) / currentMonthData[1].all_sales,
                                            color: '#E6E6E6',
                                        },
                                        {
                                            name: t('common:product'),
                                            key1: 'Team',
                                            value: currentMonthData[1].product_sales,
                                            percentage: currentMonthData[1].product_sales / currentMonthData[1].all_sales,
                                            color: '#8C54FF',
                                        },
                                    ],
                                }
                                dataClone.legend = [
                                    {
                                        name: t('common:product'),
                                        color: '#8C54FF',
                                        translated: true,
                                    },
                                    {
                                        name: t('common:others'),
                                        color: '#E6E6E6',
                                        translated: true,
                                    },
                                ]

                                dataClone.insideTitle = ((currentMonthData[1].product_sales / currentMonthData[1].all_sales) * 100).toFixed(2) + '%'
                                dataClone.insideText = getValueInString(Math.round(currentMonthData[1].product_sales), 'units')
                            }
                        })
                    )
                    setValue(dataClone)
                    setLoading(false)
                } catch (err) {
                    errorHandler(err)
                }
            } else {
                dataClone.currentMonth = 'Nov 2023'
                dataClone.insideText = '€3,754'
                dataClone.insideTitle = '0.91%'
                dataClone.data = {
                    data1: [
                        {
                            key1: 'Others',
                            name: 'Others',
                            value: 389225.81,
                            percentage: 0.9904466154901992,
                            color: '#E6E6E6',
                        },
                        {
                            name: 'Product',
                            key1: 'Team',
                            value: 3754.29,
                            percentage: 0.009553384509800878,
                            color: '#8C54FF',
                        },
                    ],
                }
                dataClone.donutAttr = [
                    {
                        dataKey: 'data1',
                        valueKey: 'value',
                        nameKey: 'key1',
                    },
                ]
                dataClone.forecast = [
                    {
                        label: 'Nov 2023',
                        growth: '-0.34',
                        percentage: '0.91%',
                        total: '€3,742.00',
                    },
                    {
                        label: 'Dec 2023',
                        growth: '-5.47',
                        percentage: '0.90%',
                        total: '€3,547.00',
                    },
                    {
                        label: 'Jan 2024',
                        growth: '3.95',
                        percentage: '0.99%',
                        total: '€3,694.00',
                    },
                ]
                dataClone.legend = [
                    {
                        name: 'Product',
                        color: '#8C54FF',
                        translated: true,
                    },
                    {
                        name: 'Others',
                        color: '#E6E6E6',
                        translated: true,
                    },
                ]
                setValue(dataClone)
            }
        }, [setLoading, setValue, filter, t, config, isExample])

        useSub('team', () => {
            getInformation()
        })

        useEffect(() => {
            if (filter) {
                getInformation()
            }
        }, [setLoading, filter, setValue, t, getInformation])

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div className={`${styles['product-atc4-donut-container']}`}>
                            {data.insideText && (
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col-1 px-0 h-100">
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <div className="col px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span className={`${styles['product-atc4-donut-container__label']}`}>
                                                            {data.currentMonth}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div className="row mx-0 w-100">
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <span
                                                        className={`${styles['product-atc4-donut-container__negative-text']}`}
                                                    >
                                                        -7%
                                                    </span>
                                                    <img
                                                        src={icoDownNegative}
                                                        alt=""
                                                    />
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 px-4 h-100">
                                        <DonutChart
                                            isFull
                                            insideTitle={data.insideTitle}
                                            insideText={data.insideText}
                                            donutAttr={data.donutAttr}
                                            attr={data.attr}
                                            data={data.data}
                                        />
                                    </div>
                                    <div className="col-2 h-100">
                                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                            <div className="col px-0">
                                                <span className={`${styles['product-atc4-donut-container__label-title']}`}>
                                                    {t('common:forecast')}
                                                </span>
                                            </div>
                                        </div>
                                        {data.forecast.map((forecastMonth, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem',
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span className={`${styles['product-atc4-donut-container__label']}`}>
                                                                    {forecastMonth.label}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                                <span
                                                                    className={
                                                                        forecastMonth.growth > 0
                                                                            ? `${styles['product-atc4-donut-container__positive-text']}`
                                                                            : forecastMonth.growth < 0
                                                                            ? `${styles['product-atc4-donut-container__negative-text']}`
                                                                            : `${styles['product-atc4-donut-container__text']}`
                                                                    }
                                                                >
                                                                    {forecastMonth.growth + '%'}
                                                                </span>
                                                                <img src={forecastMonth.growth > 0 ? icoUpPositive : icoDownNegative} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0 d-flex">
                                                                <span
                                                                    className={`${styles['product-atc4-donut-container__text']}`}
                                                                    style={{
                                                                        marginRight: '0.5rem',
                                                                    }}
                                                                >
                                                                    {forecastMonth.percentage}
                                                                </span>
                                                                <span className={`${styles['product-atc4-donut-container__text-bold']}`}>
                                                                    {forecastMonth.total}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0">
                                                                <span className={`${styles['product-atc4-donut-container__label']}`}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                            {!data.insideText && (
                                <>
                                    <div className={`col px-0 h-100 d-flex justify-content-center align-items-center`}>
                                        <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                            <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                                <span className={`noDataText`}>{t(`common:noData`)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </ContentContainer>
            </div>
        )
    }
)

ProductAtc4Donut.propTypes = {}
