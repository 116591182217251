import React from 'react';
import styles from './ModalInputLabel.module.scss';

export const ModalInputLabel = ({ text, optionalText }) => {
    return (
        <div className={styles['modal-input-label-container']}>
            {text}
            {optionalText && (
                <span
                    className={styles['modal-input-label-container__optional']}
                >
                    {' '}
                    {optionalText}
                </span>
            )}
        </div>
    );
};
