import { createContext, useContext, useMemo, useState } from 'react'

const UserForecastFilterContext = createContext({
    userForecastEnabled: false,
    setUserForecastEnabled: () => {},
})

export function UserForecastFilterProvider({ children }) {
    const [userForecastEnabled, setUserForecastEnabled] = useState(true)

    const UserForecastFilterValue = useMemo(() => {
        return {
            userForecastEnabled,
            setUserForecastEnabled,
        }
    }, [userForecastEnabled])

    return <UserForecastFilterContext.Provider value={UserForecastFilterValue}>{children}</UserForecastFilterContext.Provider>
}

export function useUserForecastFilter() {
    return useContext(UserForecastFilterContext)
}
