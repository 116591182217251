import React, { useMemo, useState } from 'react';
import styles from './edit-goal-modal.module.scss';
import { errorHandler } from '../../../utils/api';
import {
    Modal,
    PrimaryButton,
    RadioInput,
    SecondaryButton,
    SelectInput,
    SuccessModal,
    TerciaryButton,
    ToggleButton,
    TypeInput
} from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { TabsModal } from '../tabs-modal/tabs-modal';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import { useToggle } from '../../../hooks/useToggle';
import icoVisibility from '../../../assets/images/icoVisibility.svg';
import icoGoalManagement from '../../../assets/images/icoGoalManagement.svg';
import { TimePeriodInput } from '../../inputs/time-period-input/time-period-input';
import { GoalSummaryModal } from '../goal-summary-modal/goal-summary-modal';

export const EditGoalModal = ({ onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [menuOptions, setMenuOptions] = useState([
        'generalInformation',
        'timePeriod',
        'target',
        'approval'
    ]);
    const [selectedOption, setSelectedOption] = useState('generalInformation');
    const [reminder, toggleReminder] = useToggle(false);
    const [summary, toggleSummary] = useToggle(false);

    const {
        value: inputs,
        clickRadioOption: inputsClickRadioOption,
        setValue: inputsSetValue,
        toggleDropdown: inputsToggleDropdown,
        selectOption: inputsSelectOption,
        setOptions: inputsSetOptions,
        keyPress: inputsKeyPress,
        keyDown: inputsKeyDown
    } = useMultipleInputs({
        goalTitle: {
            type: 'type',
            name: 'goalTitle'
        },
        goalDescription: {
            type: 'type',
            name: 'goalDescription'
        },
        goalOwner: {
            type: 'selectSingle',
            name: 'goalOwner',
            options: [],
            reference: React.createRef()
        },
        goalType: {
            type: 'radio',
            name: 'goalType',
            direction: 'horizontal',
            options: [
                {
                    name: 'enterpriseGoal',
                    label: t(`common:enterpriseGoal`)
                },
                {
                    name: 'organizationGoal',
                    label: t(`common:organizationGoal`)
                },
                {
                    name: 'teamGoal',
                    label: t(`common:teamGoal`)
                }
            ],
            selectedOption: '',
            value: '',
            reference: React.createRef()
        },
        organization: {
            type: 'selectSingle',
            name: 'organization',
            options: [],
            reference: React.createRef()
        },
        enterprise: {
            type: 'selectSingle',
            name: 'enterprise',
            options: [],
            reference: React.createRef()
        },
        team: {
            type: 'selectSingle',
            name: 'team',
            options: [],
            reference: React.createRef()
        },
        visibility: {
            type: 'selectSingle',
            name: 'visibility',
            options: [],
            reference: React.createRef()
        },
        startDate: {
            name: 'startDate'
        },
        endDate: {
            name: 'endDate'
        },
        businessIndicator: {
            type: 'selectSingle',
            name: 'businessIndicator',
            options: [],
            reference: React.createRef()
        },
        targetValue: {
            type: 'type',
            name: 'targetValue'
        },
        businessDimension: {
            type: 'selectSingle',
            name: 'businessDimension',
            options: [],
            reference: React.createRef()
        },
        approvalRequest: {
            type: 'radio',
            name: 'approvalRequest',
            direction: 'horizontal',
            options: [
                {
                    name: 'yes',
                    label: t(`inputs:yes`)
                },
                {
                    name: 'no',
                    label: t(`inputs:no`)
                }
            ],
            selectedOption: '',
            value: '',
            reference: React.createRef()
        },
        approvalRequestOwner: {
            type: 'selectSingle',
            name: 'approvalRequestOwner',
            options: [],
            reference: React.createRef()
        }
    });

    const handleEditGoalInfo = async () => {
        try {
            setLoading(true);
            //await TeamsService.patchApiTeamsMe
            setIsSuccess(true);
            setLoading(false);
            onEnd();
        } catch (err) {
            setLoading(false);
            errorHandler(err);
        }
    };

    const dynamicProps = useMemo(() => {
        switch (inputs.goalType.selectedOption) {
            case 'enterpriseGoal':
                return { ...inputs.enterprise };
            case 'organizationGoal':
                return { ...inputs.organization };
            case 'teamGoal':
                return { ...inputs.team };
            default:
                return {};
        }
    }, [
        inputs.goalType.selectedOption,
        inputs.enterprise,
        inputs.organization,
        inputs.team
    ]);

    if (!isSuccess) {
        return (
            <>
                {!summary && (
                    <TabsModal
                        tabs={menuOptions}
                        currentTab={selectedOption}
                        onChangeTab={(tab) => setSelectedOption(tab)}
                        onToggleModal={() => onCloseModal(false)}
                        isLoading={isLoading}
                    >
                        <div
                            className="row mx-0 w-100"
                            style={{ padding: '0 20rem' }}
                        >
                            <div className="col px-0">
                                {selectedOption === 'generalInformation' && (
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col-auto px-0">
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-start"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div
                                                    className="col-auto px-0"
                                                    style={{ width: '30rem' }}
                                                >
                                                    <TypeInput
                                                        onChangeInput={
                                                            inputsSetValue
                                                        }
                                                        newLabelType
                                                        {...inputs.goalTitle}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-start"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div
                                                    className="col-auto px-0"
                                                    style={{ width: '30rem' }}
                                                >
                                                    <TypeInput
                                                        onChangeInput={
                                                            inputsSetValue
                                                        }
                                                        newLabelType
                                                        inputType="container"
                                                        isOptional
                                                        {...inputs.goalDescription}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-start"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div
                                                    className="col-auto px-0"
                                                    style={{ width: '30rem' }}
                                                >
                                                    <SelectInput
                                                        onChangeInput={
                                                            inputsSetValue
                                                        }
                                                        onSelectInput={
                                                            inputsSelectOption
                                                        }
                                                        onKeyPress={
                                                            inputsKeyPress
                                                        }
                                                        onKeyDown={
                                                            inputsKeyDown
                                                        }
                                                        onToggleDropdown={
                                                            inputsToggleDropdown
                                                        }
                                                        hideOptionsAux
                                                        newLabelType
                                                        {...inputs.goalOwner}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-start"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col-auto px-0">
                                                    <RadioInput
                                                        onClickRatioOption={
                                                            inputsClickRadioOption
                                                        }
                                                        {...inputs.goalType}
                                                    />
                                                </div>
                                            </div>
                                            {inputs.goalType.selectedOption && (
                                                <div
                                                    className="row mx-0 w-100 d-flex justify-content-start"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '30rem'
                                                        }}
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                inputsSetValue
                                                            }
                                                            onSelectInput={
                                                                inputsSelectOption
                                                            }
                                                            onKeyPress={
                                                                inputsKeyPress
                                                            }
                                                            onKeyDown={
                                                                inputsKeyDown
                                                            }
                                                            newLabelType
                                                            onToggleDropdown={
                                                                inputsToggleDropdown
                                                            }
                                                            hideOptionsAux
                                                            {...dynamicProps}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-start"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div
                                                    className="col-auto px-0"
                                                    style={{ width: '30rem' }}
                                                >
                                                    <SelectInput
                                                        onChangeInput={
                                                            inputsSetValue
                                                        }
                                                        onSelectInput={
                                                            inputsSelectOption
                                                        }
                                                        onKeyPress={
                                                            inputsKeyPress
                                                        }
                                                        onKeyDown={
                                                            inputsKeyDown
                                                        }
                                                        onToggleDropdown={
                                                            inputsToggleDropdown
                                                        }
                                                        hideOptionsAux
                                                        newLabelType
                                                        {...inputs.visibility}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {selectedOption === 'timePeriod' && (
                                    <>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '5rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        styles[
                                                            'edit-goal-modal-container__title'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'modals:editGoalModal.timePeriod'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <TimePeriodInput
                                                    startDate={inputs.startDate}
                                                    endDate={inputs.endDate}
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {selectedOption === 'target' && (
                                    <>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '5rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        styles[
                                                            'edit-goal-modal-container__title'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'modals:editGoalModal.measure'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <TypeInput
                                                    newLabelType
                                                    {...inputs.businessIndicator}
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{ width: '2rem' }}
                                            ></div>
                                            <div className="col px-0">
                                                <TypeInput
                                                    newLabelType
                                                    {...inputs.targetValue}
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <SelectInput
                                                    onChangeInput={
                                                        inputsSetValue
                                                    }
                                                    onSelectInput={
                                                        inputsSelectOption
                                                    }
                                                    onKeyPress={inputsKeyPress}
                                                    onKeyDown={inputsKeyDown}
                                                    onToggleDropdown={
                                                        inputsToggleDropdown
                                                    }
                                                    hideOptionsAux
                                                    newLabelType
                                                    {...inputs.businessDimension}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mx-0 w-100">
                                            <div className="col-auto px-0">
                                                <ToggleButton
                                                    bool={reminder}
                                                    onClick={toggleReminder}
                                                />
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{ width: '2rem' }}
                                            ></div>
                                            <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        styles[
                                                            'edit-goal-modal-container__reminder'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'modals:editGoalModal.reminder'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {selectedOption === 'approval' && (
                                    <>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '5rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={
                                                        styles[
                                                            'edit-goal-modal-container__title'
                                                        ]
                                                    }
                                                >
                                                    {t(
                                                        'modals:editGoalModal.approvalRequest'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                            <div className="col-auto px-0">
                                                <RadioInput
                                                    hideLabel
                                                    onClickRatioOption={
                                                        inputsClickRadioOption
                                                    }
                                                    {...inputs.approvalRequest}
                                                />
                                            </div>
                                        </div>
                                        {inputs.approvalRequest
                                            .selectedOptionAux === 'yes' && (
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                style={{ marginTop: '2rem' }}
                                            >
                                                <div className="col-auto px-0">
                                                    <SelectInput
                                                        onChangeInput={
                                                            inputsSetValue
                                                        }
                                                        onSelectInput={
                                                            inputsSelectOption
                                                        }
                                                        onKeyPress={
                                                            inputsKeyPress
                                                        }
                                                        onKeyDown={
                                                            inputsKeyDown
                                                        }
                                                        onToggleDropdown={
                                                            inputsToggleDropdown
                                                        }
                                                        hideOptionsAux
                                                        newLabelType
                                                        {...inputs.approvalRequestOwner}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <div
                            className="row w-100 mx-0 d-flex justify-content-end align-items-center"
                            style={{ marginTop: '2rem' }}
                        >
                            <div className="col-auto px-0">
                                <TerciaryButton
                                    text={t('buttons:cancel')}
                                    onClick={() => {
                                        onCloseModal(false);
                                    }}
                                />
                            </div>
                            <div className="col-auto px-0">
                                <PrimaryButton
                                    text={t('buttons:save')}
                                    onClick={() => toggleSummary()}
                                />
                            </div>
                        </div>
                    </TabsModal>
                )}
                {summary && (
                    <GoalSummaryModal
                        onCloseModal={onCloseModal}
                        isCreatingGoal
                        goBackText={t(`buttons:back`)}
                        onSaveText={
                            inputs.approvalRequest.selectedOptionAux === 'yes'
                                ? t(`buttons:submitForApproval`)
                                : t(`buttons:publishGoal`)
                        }
                        goBackHandler={toggleSummary}
                        onSaveHandler={handleEditGoalInfo}
                    />
                )}
            </>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:editGoalInfo.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
