import styles from './config-poi.module.scss';

import Joi from 'joi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTextInput } from '../../../hooks/useTextInput';
import { useToggle } from '../../../hooks/useToggle';
import { DefinitionsService } from '../../../temp/test';
import {
    LoaderForeground,
    PrimaryButton,
    Spinner,
    TerciaryButton,
    TypeInput
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { success } from '../../../components/notifications/notification/notification';

/**
 * Provides information about point of sales configuration
 */

const ConfigPoi = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);
    const {
        value: distancePoi,
        setValue: setDistancePoiValue,
        setError: setDistancePoiError,
        replaceValue: distancePoiReplace
    } = useTextInput({
        name: 'distancePoi',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: tagsPoi,
        setValue: setTagsPoiValue,
        setError: setTagsPoiError,
        replaceValue: tagsPoiReplace
    } = useTextInput({
        name: 'tagsPoi',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const schema = Joi.object({
        distancePoi: Joi.string().required(),
        tagsPoi: Joi.string().required()
    });

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    useEffect(() => {
        async function fill() {
            try {
                const distancePoi =
                    await DefinitionsService.getApiBackofficeSettingsDistancePoi();
                if (isMounted.current)
                    distancePoiReplace(distancePoi.result.toString());
                const tagsPoi =
                    await DefinitionsService.getApiBackofficeSettingsPoiTags();
                if (isMounted.current) {
                    tagsPoiReplace(tagsPoi.result.toString());
                    toggleLoading(false);
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [distancePoiReplace, isMounted, toggleLoading, tagsPoiReplace]);

    const handleSaveDefinitions = async () => {
        try {
            const result = schema.validate(
                {
                    distancePoi: distancePoi.value,
                    tagsPoi: tagsPoi.value
                },
                { abortEarly: false }
            );
            if (isMounted.current) {
                setDistancePoiError();
                setTagsPoiError();
            }
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'distancePoi') {
                        if (isMounted.current) setDistancePoiError(el.type);
                    } else if (el.path[0] === 'tagsPoi') {
                        if (isMounted.current) setTagsPoiError(el.type);
                    }
                });
                return;
            }
            await DefinitionsService.postApiBackofficeSettingsDistancePoi({
                requestBody: { distance_poi: parseInt(distancePoi.value) }
            });
            await DefinitionsService.postApiBackofficeSettingsPoiTags({
                requestBody: { poi_tags: parseInt(tagsPoi.value) }
            });
            success({ text: t('notifications:saved') });
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['back-office-config-poi-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.back-office.title')}
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div
                                className={`col p-0 h-auto d-flex flex-column justify-content-start align-items-center ${styles['back-office-config-poi-container__content-wrapper']}`}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col ${styles['back-office-config-poi-container__content']}`}
                                            >
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-poi-container__content-wrapper__title-container']}`}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['back-office-config-poi-container__content-wrapper__title-container__title']}`}
                                                        >
                                                            {t(
                                                                'pages:administration.back-office.sections.poi'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="col-2 px-0 d-flex justify-content-end align-items-center">
                                                        <TerciaryButton
                                                            text={t(
                                                                'buttons:save'
                                                            )}
                                                            onClick={
                                                                handleSaveDefinitions
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <hr
                                                    className={`${styles['back-office-config-poi-container__separator']}`}
                                                />
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-poi-container__definitions-container']} flex-grow-1`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-poi-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0 d-flex justify-content-between align-items-center">
                                                                <span
                                                                    className={`${styles['back-office-config-poi-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        `inputs:${distancePoi.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    onChangeInput={
                                                                        setDistancePoiValue
                                                                    }
                                                                    {...distancePoi}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-poi-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0 d-flex justify-content-between align-items-center">
                                                                <span
                                                                    className={`${styles['back-office-config-poi-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        `inputs:${tagsPoi.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col px-0">
                                                                <TypeInput
                                                                    onChangeInput={
                                                                        setTagsPoiValue
                                                                    }
                                                                    {...tagsPoi}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default ConfigPoi;

ConfigPoi.propTypes = {};
