import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { PointOfSaleService } from '../../../temp/test'
import { clientsAtRiskMock } from './mock'
import { errorHandler } from '../../../utils/api.js'
import { verifyComponent } from '../../../utils/component.js'
import { ContentContainer, Loader, Spinner } from '../../'
import styles from './clients-at-risk.module.scss'
import { getFilterParam } from '../../../utils/params'
import icoClientsCard from '../../../assets/images/icoClientsCard.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { GeneralCard } from '../general-card/general-card'

/**
 * Displays the count and evolution of clients at risk compared to the previous month
 */

export const ClientsAtRisk = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
    }) => {
        const { filter } = useFilter()
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()
        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(clientsAtRiskMock)

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(clientsAtRiskMock)
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true)
                        }
                        if (type) dataClone.attr = type
                        const dataBack = await PointOfSaleService.getApiPosAllCards({
                            vendorId: getFilterParam(filter, config, ['vendorId', 'vendorIds']),
                        })
                        dataClone.content = dataBack.at_risk.count.toString()
                        dataClone.contentType = 'units'
                        dataClone.contentAuxType = 'percentageRisk'
                        if (dataBack.at_risk.variation !== '') dataClone.contentAux = dataBack.at_risk.variation
                        if (dataClone.settings.view.options.length === 0 && dataClone.settings.options.options.length === 0) dataClone.disabled = true
                        else dataClone.disabled = false
                        dataClone.areaData = [
                            {
                                date: '2020-01-01',
                                value4: ((dataBack.at_risk.count * 100) / (100 + dataBack.at_risk.variation)) * Math.random(),
                            },
                            {
                                date: '2020-02-01',
                                value4: ((dataBack.at_risk.count * 100) / (100 + dataBack.at_risk.variation)) * Math.random(),
                            },
                            {
                                date: '2020-03-01',
                                value4: (dataBack.at_risk.count * 100) / (100 + dataBack.at_risk.variation),
                            },
                            {
                                date: '2020-04-01',
                                value4: dataBack.at_risk.count,
                            },
                        ]
                        dataClone.areaAttr = [
                            {
                                color: dataClone['areaData'][2].value4 > dataClone['areaData'][3].value4 ? '#ce706d' : '#6ad6c5',
                                stroke: dataClone['areaData'][2].value4 > dataClone['areaData'][3].value4 ? '#ce706d' : '#6ad6c5',
                                dataKey: 'value4',
                                name: 'Value',
                            },
                        ]
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                    } else {
                        if (type) dataClone.attr = type
                        verifyComponent(dataClone)
                        setValue(dataClone)
                        setLoading(false)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            }
            fill()
        }, [config, filter, isExample, isMounted, setValue, setLoading, type, t])

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings()
                if (option === 'pin') {
                    onPinComponent(data.attr)
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr)
                } else if (option === 'download') {
                    onDownloadComponent()
                }
            },
            [data.attr, isMounted, onDownloadComponent, onPinComponent, onUnpinComponent, toggleComponentSettings]
        )
        return (
            <div className={`${isPreview ? 'pe-none' : ''} w-100 h-100  ${styles['clients-at-risk-container']}`} style={{ zIndex: 'inherit' }}>
                {data.loading && <Spinner />}
                {!data.loading && (
                    <ContentContainer
                        attr={data.attr}
                        icon={icoClientsCard}
                        isCardTitle
                        titleText={t(`components:${data.attr}.title`)}
                        disabled={data.disabled}
                        isForecast={data.isForecast}
                        legend={data.legend}
                        settings={data.settings}
                        onClickSettingsIcon={toggleComponentSettings}
                        onClickSettingsOptions={handleSelectSettingsOption}
                        onClickSettingsView={handleSelectSettingsOption}
                        onCLickSettintsSelected={handleSelectSettingsOption}
                        onCloseSettings={toggleComponentSettings}
                        // hovered={hovered}
                        onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                        onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                        hideInfoIcon={true}
                        hidePinIcon={isExample || isPreview || isReportExample}
                        hideSettingsIcon={isExample || isPreview || isReportExample}
                        pinned={pinned}
                        onPinComponent={onPinComponent}
                        onUnpinComponent={onUnpinComponent}
                        // interval={'1M'}
                    >
                        <GeneralCard {...data} />
                    </ContentContainer>
                )}
            </div>
        )
    }
)

ClientsAtRisk.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
