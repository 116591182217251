import React from 'react';

export const segmentRevenueSalesMock = {
    loading: true,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'segmentRevenueSales',
    isForecast: false,
    titleText: '',
    areaAttr: [],
    categoryAxis: 'month',
    data: [],
    dateGrouper: '',
    gradientOn: true,
    lineType: 'monotone',
    maxValue: 25,
    minValue: 2,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'salesHistory-monthly',
    unitsOrValues: 'values',
    margin: {
        top: 20,
        right: 40,
        left: 0,
        bottom: 10
    },
    interactionDisabled: true,
    suffix: 'segmentRevenueSalesAuxbar',
    settings: {
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        },
        reference: React.createRef()
    }
};
