import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { productMarketShareForecastMock } from './mock'
import { ComponentWrapper, DynamicComposedChartContainer } from '../..'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { ForecastService, ProductsService, SalesService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { getFilterParam } from '../../../utils/params'
import { Promise } from 'q'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { useSalesChannel } from '../../../providers/sales-channel-context'
import { fromBackTimeToFront } from '../../../utils/timeseries'
/**
 * Displays the evolution of unit sales over time with forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer)

export const ProductMarketShareUnitsForecast = React.memo(({ component, isPreview, isExample, config, ...rest }) => {
    const { filter } = useFilter()
    const isMounted = useIsMounted()
    const { timeInterval } = useTimeInterval()
    const { channels } = useSalesChannel()
    const { t } = useCustomTranslation()

    const { value: data, setLoading, setValue } = useSimpleComponent(productMarketShareForecastMock)

    const getInformation = useCallback(async () => {
        const dataClone = _.cloneDeep(productMarketShareForecastMock)
        try {
            if (!isExample) {
                setLoading(true)
                dataClone.data.length = 0
                const forbiddenCategories = ['V&I', 'OBU', 'R&I', 'CVRM']
                const productResult = await ProductsService.getApiProducts1({
                    id: getFilterParam(filter, config, ['productId', 'productIds']),
                })
                const forecastSales = await ForecastService.getApiForecast({
                    interval: timeInterval,
                    productIds: getFilterParam(filter, config, ['productId', 'productIds']),
                    teamIds: getFilterParam(filter, config, ['teamId', 'teamIds']),
                    regionIds: getFilterParam(filter, config, ['regionId', 'regionIds']),
                    sumBy: 'units',
                    channelIds: JSON.parse(channels),
                })
                await Promise.all(
                    productResult.categories.map(async (category, categoryIndex) => {
                        if (!forbiddenCategories.includes(category.code)) {
                            const categoryResult = await SalesService.getApiSales({
                                interval: timeInterval,
                                productCategories: [category.id],
                                channelId: JSON.parse(channels),
                                sumBy: 'units',
                            })
                            const categoryName = category.code.length === 4 ? 'atc3' : 'atc4'
                            fromBackTimeToFront(
                                Object.entries(categoryResult),
                                dataClone.data,
                                [
                                    {
                                        back: 1,
                                        front: `category-${categoryName}`,
                                    },
                                ],
                                timeInterval,
                                '0'
                            )
                            const categoryForecast = await ForecastService.getApiForecast({
                                interval: timeInterval,
                                productCategories: [category.id],
                                teamIds: getFilterParam(filter, config, ['teamId', 'teamIds']),
                                regionIds: getFilterParam(filter, config, ['regionId', 'regionIds']),
                                sumBy: 'units',
                                channelIds: JSON.parse(channels),
                            })
                            categoryForecast.map((item) => {
                                item.forecast_lower = item.forecast_lower + (item.forecast - item.forecast_lower) * 0.2
                                item.forecast_upper = item.forecast_upper - (item.forecast_upper - item.forecast) * 0.2
                            })
                            fromBackTimeToFront(
                                categoryForecast,
                                dataClone.data,
                                [
                                    {
                                        back: 'forecast',
                                        front: `category-${categoryName}ForecastValue`,
                                    },
                                    {
                                        back: ['forecast_lower', 'forecast_upper'],
                                        front: `category-${categoryName}ForecastIntervalValue`,
                                        isArray: true,
                                    },
                                ],
                                timeInterval,
                                'forecast_date'
                            )
                            dataClone.barAttr.push({
                                dataKey: `category-${categoryName}`,
                                color: categoryName === 'atc3' ? '#FF9441' : '#8C54FF',
                                stackId: 'b',
                            })
                            dataClone.barAttr.push({
                                dataKey: `category-${categoryName}ForecastValue`,
                                color: categoryName === 'atc3' ? '#FF9441' : '#8C54FF',
                                stackId: 'b',
                                isForecast: true,
                            })

                            dataClone.legend.push({
                                id: `category-${categoryName}`,
                                name: `category-${categoryName}`,
                                label: category.name,
                                translated: false,
                                color: categoryName === 'atc3' ? '#FF9441' : '#8C54FF',
                            })
                        }
                    })
                )
                const result = await SalesService.getApiSales({
                    producers: getFilterParam(filter, config, ['productId', 'productIds']).length > 0 ? undefined : ['self'],
                    interval: timeInterval,
                    productId: getFilterParam(filter, config, ['productId', 'productIds']),
                    channelId: JSON.parse(channels),
                    sumBy: 'units',
                })

                fromBackTimeToFront(Object.entries(result), dataClone.data, [{ back: 1, front: 'unitsSold' }], timeInterval, '0')
                fromBackTimeToFront(
                    [Object.entries(result)[Object.entries(result).length - 1]],
                    dataClone.data,
                    [
                        {
                            back: [1, 1],
                            front: 'unitsSoldForecastIntervalValue',
                            isArray: true,
                        },
                        {
                            back: 1,
                            front: 'unitsSoldForecastValue',
                        },
                        {
                            back: 1,
                            front: 'forecastGradient',
                        },
                    ],
                    timeInterval,
                    '0'
                )

                forecastSales.map((item) => {
                    item.forecast_lower = item.forecast_lower + (item.forecast - item.forecast_lower) * 0.2
                    item.forecast_upper = item.forecast_upper - (item.forecast_upper - item.forecast) * 0.2
                })
                fromBackTimeToFront(
                    forecastSales,
                    dataClone.data,
                    [
                        {
                            back: 'forecast',
                            front: 'unitsSoldForecastValue',
                        },
                        {
                            back: ['forecast_lower', 'forecast_upper'],
                            front: 'unitsSoldForecastIntervalValue',
                            isArray: true,
                        },
                    ],
                    timeInterval,
                    'forecast_date'
                )

                if (!isPreview) dataClone.dateGrouper = 'month'

                dataClone.legend.push({
                    id: 'unitsSold',
                    name: 'unitsSold',
                    label: 'Units Sold',
                    translated: false,
                    color: '#8c54ff',
                })
                if (isMounted.current) {
                    setLoading(false)
                    setValue(dataClone)
                }
            } else {
                dataClone.barAttr = [
                    {
                        dataKey: 'atc3Sales',
                        color: '#FF9441',
                        stackId: 'b',
                        minPointSize: 70,
                        radius: [8, 8, 0, 0],
                    },

                    {
                        dataKey: 'atc4Sales',
                        color: '#8C54FF',
                        stackId: 'a',
                        minPointSize: 70,
                        radius: [8, 8, 0, 0],
                    },
                ]
                dataClone.data = [
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-01-01',
                        day: '01 Jan 2022',
                        month: 'Jan 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-02-01',
                        day: '01 Feb 2022',
                        month: 'Feb 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-03-01',
                        day: '01 Mar 2022',
                        month: 'Mar 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-04-01',
                        day: '01 Apr 2022',
                        month: 'Apr 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-05-01',
                        day: '01 May 2022',
                        month: 'May 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-06-01',
                        day: '01 Jun 2022',
                        month: 'Jun 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-07-01',
                        day: '01 Jul 2022',
                        month: 'Jul 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-08-01',
                        day: '01 Aug 2022',
                        month: 'Aug 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-09-01',
                        day: '01 Sep 2022',
                        month: 'Sep 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                    {
                        atc3Sales: '118113.3',
                        atc4Sales: '58113.3',
                        time: '2022-10-01',
                        day: '01 Oct 2022',
                        month: 'Oct 2022',
                        year: '2022',
                        unitsSold: '13983.3',
                    },
                ]
                dataClone.areaAttr = [
                    {
                        dataKey: 'unitsSold',
                        color: '#8c54ff',
                        strokeWidth: 2,
                        isTransparent: true,
                    },
                    {
                        dataKey: 'unitsSoldForecastValue',
                        isReverseColor: true,
                        dashArray: '3 3',
                        isGradient: true,
                        isTransparent: true,
                        strokeWidth: 2,
                        color: '#8c54ff',
                        fillOpacity: 1,
                    },
                ]
                dataClone.legend = [
                    {
                        id: 'category-atc4',
                        name: 'category-atc4',
                        translated: false,
                        color: '#8C54FF',
                    },
                    {
                        id: 'category-atc3',
                        name: 'category-atc3',
                        translated: false,
                        color: '#FF9441',
                    },
                    {
                        id: 'unitsSold',
                        name: 'unitsSold',
                        label: 'unitsSold',
                        translated: false,
                        color: '#8c54ff',
                    },
                ]
                setValue(dataClone)
            }
        } catch (err) {
            errorHandler(err)
            dataClone.loading = false
            if (isMounted.current) {
                setValue(dataClone)
            }
        }
    }, [config, filter, isExample, isMounted, setLoading, setValue, timeInterval, isPreview, channels])

    useEffect(() => {
        getInformation()
    }, [getInformation])

    return (
        <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper withTimeFilter withChannelFilter component={component} {...data} {...rest}>
                <Chart {...data} forecastValue="unitsSoldForecastValue" forecastIntervalValue="unitsSoldForecastIntervalValue" startsAtZero />
            </ComponentWrapper>
        </div>
    )
})

ProductMarketShareUnitsForecast.propTypes = {}
