import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTagMultipleInput } from '../../../hooks/useTagMultipleInput';
import { useTextInput } from '../../../hooks/useTextInput';
import icoPlus from '../../../assets/images/ico-plus.svg';

import styles from './sentiment-media-input.module.scss';
import Joi from 'joi';
import { SentimentService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { useRecommendedKeywords } from '../sentiment-import-data/useRecommendedKeywords';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { TypeInput } from '../../inputs/type-input/type-input';
import { TagMultipleInput } from '../../inputs/tag-multiple-input/tag-multiple-input';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays logins in a table
 */

export const SentimentMediaInput = React.memo(({ onSearch }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        keywords: recommendedKeywords,
        setKeywords: setRecommendedKeywords,
        unselectKeyword
    } = useRecommendedKeywords([]);
    const {
        value: mainSubject,
        setValue: mainSubjectSetValue,
        setError: mainSubjectSetError
    } = useTextInput({
        name: 'mainSubject',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });
    const {
        value: keywords,
        toggleDropdown: keywordsToggleDropdown,
        selectOption: keywordsSelectOption,
        unselectOption: keywordsUnselectOption,
        keyPress: keywordsKeyPress,
        keyDown: keywordsKeyDown,
        setValue: keywordsSetValue,
        setError: keywordsSetError,
        addKeyword,
        removeKeyword
    } = useTagMultipleInput({
        name: 'keywords',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOptions: [],
        selectedOptionsAux: [],
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const [recommendedKeywordsLoading, setRecommendedKeywordsLoading] =
        useState(false);

    const schema = Joi.object({
        mainSubject: Joi.string().required()
    });

    useEffect(() => {
        async function fill() {
            try {
                setRecommendedKeywordsLoading(true);
                const result =
                    await SentimentService.getApiNlpSuggestedKeywords();
                const newKeywords = [];
                result.map((el) => {
                    newKeywords.push({
                        value: el,
                        selected: false
                    });
                });
                setRecommendedKeywords(newKeywords);
                setRecommendedKeywordsLoading(false);
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [setRecommendedKeywords]);

    const handleSelectRecommendedKeyword = (index) => {
        const newRecommendedKeywords = _.cloneDeep(recommendedKeywords);
        if (newRecommendedKeywords[index].selected) {
            removeKeyword(newRecommendedKeywords[index].value);
            newRecommendedKeywords[index].selected = false;
        } else {
            if (
                keywords.selectedOptions.includes(
                    newRecommendedKeywords[index].value
                )
            ) {
                keywordsSetError(t(`duplicateKeyword`));
            } else {
                addKeyword(newRecommendedKeywords[index].value);
                newRecommendedKeywords[index].selected = true;
            }
        }
        setRecommendedKeywords(newRecommendedKeywords);
    };

    const handleSearch = () => {
        const result = schema.validate(
            {
                mainSubject: mainSubject.value
            },
            { abortEarly: false }
        );
        if (isMounted.current) {
            mainSubjectSetError();
        }
        if (result.error) {
            result.error.details.map((el) => {
                if (el.path[0] === 'mainSubject') {
                    if (isMounted.current) mainSubjectSetError(el.type);
                }
            });
            return;
        }
        onSearch(mainSubject.value, keywords.selectedOptions);
    };

    const handleKeywordsUnselectOption = useCallback(
        (value, valueAux) => {
            unselectKeyword(value);
            keywordsUnselectOption(value, valueAux);
        },
        [unselectKeyword, keywordsUnselectOption]
    );

    return (
        <>
            <div className={`${styles['sentiment-media-input-container']}`}>
                <div
                    className="row mx-0 flex-grow-1"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className={`col px-0 pe-3 d-flex flex-column`}>
                        <div
                            className="row mx-0 "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0 d-flex">
                                <span
                                    className={`${styles['sentiment-media-input-container__title']}`}
                                >
                                    {t(
                                        `components:sentiment-media-input.search`
                                    )}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <TypeInput
                                    onChangeInput={mainSubjectSetValue}
                                    {...mainSubject}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 flex-grow-1">
                            <div className="col px-0">
                                <TagMultipleInput
                                    hideOptionsAux
                                    onChangeInput={keywordsSetValue}
                                    onSelectMultiple={keywordsSelectOption}
                                    onSelectedOptionClick={
                                        handleKeywordsUnselectOption
                                    }
                                    onKeyPress={keywordsKeyPress}
                                    onKeyDown={keywordsKeyDown}
                                    onToggleDropdown={keywordsToggleDropdown}
                                    {...keywords}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col px-0 d-flex flex-column">
                        <div
                            className="row mx-0"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0 d-flex">
                                <span
                                    className={`${styles['sentiment-media-input-container__title']}`}
                                >
                                    {t(
                                        `components:sentiment-media-input.suggestedKeywords`
                                    )}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 flex-grow-1"
                            style={{ minHeight: '10rem' }}
                        >
                            <div className="col px-0">
                                <div className="row mx-0 h-100">
                                    <div className="col px-0 h-100">
                                        {recommendedKeywordsLoading && (
                                            <Spinner />
                                        )}
                                        {!recommendedKeywordsLoading &&
                                            recommendedKeywords &&
                                            recommendedKeywords.length > 0 && (
                                                <React.Fragment>
                                                    <div className="row mx-0 w-100 mb-4">
                                                        <div className="col px-0">
                                                            {recommendedKeywords
                                                                .slice(0, 30)
                                                                .map(
                                                                    (
                                                                        el,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className={
                                                                                    el.selected
                                                                                        ? `${styles['sentiment-media-input-container__recommended-keyword']} ${styles['selected']}`
                                                                                        : `${styles['sentiment-media-input-container__recommended-keyword']}`
                                                                                }
                                                                                onClick={() =>
                                                                                    handleSelectRecommendedKeyword(
                                                                                        index,
                                                                                        el.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className={`${styles['sentiment-media-input-container__recommended-keyword__text']}`}
                                                                                >
                                                                                    {
                                                                                        el.value
                                                                                    }
                                                                                </span>
                                                                                <img
                                                                                    loading="lazy"
                                                                                    src={
                                                                                        icoPlus
                                                                                    }
                                                                                    alt="add suggested keyword"
                                                                                    className={`${styles['sentiment-media-input-container__recommended-keyword__icon']}`}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 d-flex justify-content-end align-items-end">
                    <div className="col-auto px-0">
                        <PrimaryButton
                            text={t('modals:search')}
                            onClick={() => handleSearch()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

SentimentMediaInput.propTypes = {};
