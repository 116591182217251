import React, { useCallback, useMemo, useRef } from 'react'
import _ from 'lodash'
import styles from './dynamic-size-map.module.scss'
import ReactMapGL, { Source, Layer, NavigationControl } from 'react-map-gl' //Marker,
import mapboxgl from 'mapbox-gl'
import { useToggle } from '../../../hooks/useToggle'
import useCursorTracking from '../../../hooks/useCursorTracking'
import { TooltipWrapper } from '../../formatting/TooltipWrapper/TooltipWrapper'

mapboxgl.workerClass =
    // @ts-ignore
    // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

export const DynamicSizeMap = React.memo(
    ({
        dataList,
        tooltipType,
        startingPosition,
        draggingDisabled,
        attr,
        hoveringDisabled,
        zoomDisabled,
        reference,
        customScale,
        hideTooltip,
        isRound,
        layersProp,
        data,
    }) => {
        const mapRef = useRef(null)
        const { position } = useCursorTracking()
        const [showPopup, toggleShowPopup] = useToggle(false)
        const [selectedEl, setSelectedEl] = React.useState({})

        const handleLoad = useCallback(() => {
            const layers = layersProp ? [...layersProp.map((layer) => layer.id)] : []

            // mapRef.current.on('click', (e) => {
            //     let features = mapRef.current.queryRenderedFeatures(e.point, {
            //         layers: layers,
            //     })
            //     if (!features.length) {
            //         return
            //     }
            //     let feature = features[0]
            //     setSelectedEl(feature.properties)
            //     // if (onMouseClickMap) {
            //     //     onMouseClickMap(
            //     //         feature.properties,
            //     //         feature.properties.regionId
            //     //     );
            //     // }
            // })

            mapRef.current.on('mousemove', (e) => {
                let features = mapRef.current.queryRenderedFeatures(e.point, {
                    layers: layers,
                })
                if (!features.length) {
                    toggleShowPopup(false)
                    setSelectedEl({})
                    return
                }
                let feature = features[0]
                setSelectedEl(feature.properties)
                toggleShowPopup(true)
                return
                // const regexRisk = new RegExp(`risk`, 'i')
                // const regexCompetitors = new RegExp(`competition`, 'i')
                // const regexSelected = new RegExp(`selected`, 'i')
                // if (
                //     feature.layer.id.match(regexRisk) ||
                //     feature.layer.id.match(regexCompetitors) ||
                //     feature.layer.id.match(regexSelected) ||
                //     customScale
                // ) {
                //     setSelectedEl(feature.properties)
                //     toggleShowPopup(true)
                // }
            })
            mapRef.current.on('mouseout', (e) => {
                toggleShowPopup(false)
                setSelectedEl({})
                return
            })
        }, [toggleShowPopup, mapRef, layersProp])

        const onClick = (event) => {
            if (event.features && event.features.length > 0) {
                const feature = event.features[0]
                const clusterId = feature.properties.cluster_id

                const mapboxSource = mapRef.current.getSource('earthquakes')

                mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) {
                        return
                    }

                    mapRef.current.easeTo({
                        center: feature.geometry.coordinates,
                        zoom,
                        duration: 500,
                    })
                })
            }
        }

        if (layersProp) {
            return (
                <div className={styles['dynamic-size-map-container']} data-testid={'hospital-map'} style={isRound ? { borderRadius: '0.6rem' } : {}}>
                    <ReactMapGL
                        interactiveLayerIds={[...layersProp.map((layer) => layer.id)]}
                        onClick={onClick}
                        ref={mapRef}
                        onRender={(event) => event.target.resize()}
                        dragPan={!draggingDisabled}
                        onLoad={handleLoad}
                        minZoom={1}
                        maxZoom={18}
                        attributionControl={false}
                        className={styles['dynamic-size-map-container__map']}
                        mapStyle="mapbox://styles/rvelhinhostark/clda6apkg003601oex2cw9xey"
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                        scrollZoom={false}
                        doubleClickZoom={!zoomDisabled}
                        initialViewState={{
                            longitude: startingPosition ? startingPosition.lon : -9.140459,
                            latitude: startingPosition ? startingPosition.lat : 38.712458,
                            zoom: startingPosition ? startingPosition.zoom : 8,
                        }}
                    >
                        <Source id="earthquakes" type="geojson" data={data}>
                            {layersProp.map((layer, index) => (
                                <Layer key={index} {...layer} />
                            ))}
                        </Source>
                        {!zoomDisabled && <NavigationControl style={{ left: '10px', top: '10px' }} showCompass={false} />}
                        {/* <div className={styles['dynamic-size-map-container__legend']}>
                         <div className="w-100 h-100 position-relative">
                             <div
                                 className={styles['dynamic-size-map-container__legend__circle']}
                                 style={{
                                     height: '12rem',
                                 }}
                             >
                                 <span
                                     className={styles['dynamic-size-map-container__legend__circle__text']}
                                     style={{
                                         position: 'absolute',
                                         top: '0rem',
                                         left: '50%',
                                         transform: 'translateX(-50%)',
                                     }}
                                 >
                                     {`> 2k`}
                                 </span>
                             </div>
                             <div
                                 className={styles['dynamic-size-map-container__legend__circle']}
                                 style={{
                                     height: '10rem',
                                 }}
                             ></div>
                             <div
                                 className={styles['dynamic-size-map-container__legend__circle']}
                                 style={{
                                     height: '8rem',
                                 }}
                             ></div>
                             <div
                                 className={styles['dynamic-size-map-container__legend__circle']}
                                 style={{
                                     height: '6rem',
                                 }}
                             ></div>
                             <div
                                 className={styles['dynamic-size-map-container__legend__circle']}
                                 style={{
                                     height: '4rem',
                                 }}
                             >
                                 <span
                                     className={styles['dynamic-size-map-container__legend__circle__text']}
                                     style={{
                                         position: 'absolute',
                                         whiteSpace: 'nowrap',
                                         top: '50%',
                                         left: '50%',
                                         transform: 'translate(-50%, -50%)',
                                     }}
                                 >
                                     {`< 10`}
                                 </span>
                             </div>
                         </div>
                     </div> */}
                        {showPopup && selectedEl && <TooltipWrapper attr={attr} hoveredPiece={selectedEl} />}
                    </ReactMapGL>
                </div>
            )
        }
        return null
    }
)
