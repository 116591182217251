import React from 'react';
import styles from './action-button-table-column.module.scss';
import { ActionsButton } from '../../../../buttons/actions-button/actions-button';

export const ActionButtonTableColumn = React.memo(
    ({
        index,
        el,
        filteredData,
        attr,
        column,
        onClickActionIcon,
        onClickActionOption,
        onCloseActions,
        onToggleHoveredActions
    }) => {
        return (
            <div
                className={`${styles['action-button-table-column-container']}`}
            >
                <ActionsButton
                    onMouseEnter={() => {
                        onToggleHoveredActions(true);
                    }}
                    onMouseLeave={() => {
                        onToggleHoveredActions(false);
                    }}
                    position={
                        index >= Math.floor(filteredData.length / 2) &&
                        index > 1
                            ? 'up'
                            : 'down'
                    }
                    attr={attr}
                    actions={el[column.path]}
                    onClickActionIcon={onClickActionIcon}
                    onClickActionOption={onClickActionOption}
                    onCloseActions={onCloseActions}
                />
            </div>
        );
    }
);
