import React, { useCallback, useEffect } from 'react'
import { ReactComponent as IcoError } from './assets/images/ico-error.svg'
import { ReactComponent as IcoValid } from './assets/images/ico-valid.svg'
import PropTypes from 'prop-types'

import styles from './pagination-input.module.scss'
import { ReactComponent as ArrowDown } from './assets/images/dropdown-arrow.svg'

export const PaginationInput = React.memo(
    ({
        state,
        name,
        options,
        optionsAux,
        selectedOption,
        selectedOptionAux,
        label,
        disabled,
        value,
        loading,
        error,
        onToggleDropdown,
        reference,
        focused,
        openDropdown,
        inputType,
        iconList,
        loadingText,
        hideOptionsAux,
        noOptionsText,
        hovered,
        onSelectInput,
        onSelectIconInput,
    }) => {
        const handleOutsideClick = useCallback(
            (event) => {
                if (openDropdown) {
                    if (
                        reference.current &&
                        !reference.current.contains(event.target)
                    ) {
                        event.preventDefault()
                        event.stopPropagation()
                        onToggleDropdown(false)
                    }
                }
            },
            [onToggleDropdown, openDropdown, reference]
        )

        useEffect(() => {
            document.addEventListener('click', handleOutsideClick)
            return function cleanup() {
                document.removeEventListener('click', handleOutsideClick)
            }
        }, [handleOutsideClick])

        const handleOptionClick = (e, name, option, optionAux) => {
            onToggleDropdown(false)
            onSelectInput(option, optionAux)
        }

        const handleOptionIconClick = (e, name, iconName) => {
            onToggleDropdown(false)
            onSelectIconInput(iconName)
        }

        const hoveredStyle = hovered ? 'hovered' : ''
        let containerStyle
        if (disabled) {
            containerStyle = `${styles['pagination-input-container']} ${styles[inputType]} ${styles['disabled']}`
        } else if (openDropdown) {
            containerStyle = `${styles['pagination-input-container']} ${styles[inputType]} ${styles['selected']} ${styles[hoveredStyle]}`
        } else if (
            selectedOption !== '' ||
            selectedOptionAux !== '' ||
            value !== ''
        ) {
            containerStyle = `${styles['pagination-input-container']} ${styles[inputType]} ${styles['valued']} ${styles[hoveredStyle]}`
        } else {
            containerStyle = `${styles['pagination-input-container']} ${styles[inputType]} ${styles[hoveredStyle]}`
        }
        let newOptions = [...options]
        let newOptionsAux
        if (optionsAux) newOptionsAux = [...optionsAux]
        return (
            <React.Fragment>
                <div
                    className={containerStyle}
                    ref={reference}
                    onFocus={() => onToggleDropdown(true)}
                    onBlur={() => onToggleDropdown(false)}
                >
                    {label && state !== 'error' && (
                        <label
                            htmlFor={name}
                            className={`${styles['pagination-input-container__label']}`}
                        >
                            {label}
                        </label>
                    )}
                    {label && state === 'error' && (
                        <label
                            htmlFor={name}
                            className={`${styles['pagination-input-container__label-error']}`}
                        >
                            {label}
                        </label>
                    )}
                    <div
                        className={`${styles['pagination-input-container__input-container']}`}
                        onMouseDown={
                            onToggleDropdown
                                ? () => onToggleDropdown()
                                : undefined
                        }
                    >
                        {value}
                        <div
                            className={`${styles['pagination-input-container__input-container__arrow-container']}`}
                        >
                            <ArrowDown
                                htmlFor={name}
                                className={
                                    state === 'error'
                                        ? `${styles['pagination-input-container__input-container__arrow-container__arrow']} ${styles['pagination-input-container__input-container__arrow-container__arrow--error']}`
                                        : `${styles['pagination-input-container__input-container__arrow-container__arrow']}`
                                }
                            />
                        </div>
                    </div>
                    {openDropdown && (
                        <div
                            className={`row m-0 ${styles['pagination-input-container__dropdown']}`}
                            id={`${name}-dropdown`}
                        >
                            <div
                                className={`col p-0 ${styles['pagination-input-container__dropdown__element']}`}
                            >
                                {inputType !== 'icon' && (
                                    <React.Fragment>
                                        {newOptions.length === 0 && loading && (
                                            <div
                                                className={`row m-0 ${styles['pagination-input-container__dropdown__container-loader']}`}
                                            >
                                                <div
                                                    className={`col p-0 ${styles['pagination-input-container__dropdown__loader-value']}`}
                                                >
                                                    {loadingText}
                                                </div>
                                            </div>
                                        )}
                                        {newOptions.length === 0 &&
                                            !loading && (
                                                <div
                                                    className={`row m-0 ${styles['pagination-input-container__dropdown__container-loader']}`}
                                                >
                                                    <div
                                                        className={`col p-0 ${styles['pagination-input-container__dropdown__loader-value']}`}
                                                    >
                                                        {noOptionsText}
                                                    </div>
                                                </div>
                                            )}
                                        {newOptions.length !== 0 &&
                                            !loading &&
                                            newOptions.map((option, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={
                                                            index == focused
                                                                ? `row ${styles['pagination-input-container__dropdown__container']} ${styles['pagination-input-container__dropdown__container--focused']}`
                                                                : `row ${styles['pagination-input-container__dropdown__container']}`
                                                        }
                                                        onMouseDown={(e) =>
                                                            handleOptionClick(
                                                                e,
                                                                name,
                                                                option,
                                                                newOptionsAux[
                                                                    index
                                                                ]
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`w-100 p-0 ${styles['pagination-input-container__dropdown__value']}`}
                                                        >
                                                            <span
                                                                className={`${styles['pagination-input-container__dropdown__value__main']}`}
                                                            >
                                                                {newOptions[
                                                                    index
                                                                ]
                                                                    ? newOptions[
                                                                          index
                                                                      ]
                                                                    : '-'}
                                                            </span>
                                                            {!hideOptionsAux &&
                                                                newOptionsAux[
                                                                    index
                                                                ] !== '' && (
                                                                    <span
                                                                        className={`${styles['pagination-input-container__dropdown__value__secondary']}`}
                                                                    >
                                                                        {
                                                                            newOptionsAux[
                                                                                index
                                                                            ]
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </React.Fragment>
                                )}
                                {inputType === 'icon' && (
                                    <React.Fragment>
                                        {iconList.length === 0 && loading && (
                                            <div
                                                className={`row m-0 ${styles['pagination-input-container__dropdown__container-loader']}`}
                                            >
                                                <div
                                                    className={`col p-0 ${styles['pagination-input-container__dropdown__loader-value']}`}
                                                >
                                                    {loadingText}
                                                </div>
                                            </div>
                                        )}
                                        {iconList.length === 0 && !loading && (
                                            <div
                                                className={`row m-0 ${styles['pagination-input-container__dropdown__container-loader']}`}
                                            >
                                                <div
                                                    className={`col p-0 ${styles['pagination-input-container__dropdown__loader-value']}`}
                                                >
                                                    {noOptionsText}
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ minHeight: '15rem' }}
                                        >
                                            {iconList.length !== 0 &&
                                                !loading &&
                                                iconList.map(
                                                    (option, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`col-auto rounded ${styles['pagination-input-container__dropdown__container']} d-flex justify-content-center align-items-center`}
                                                                onMouseDown={(
                                                                    e
                                                                ) =>
                                                                    handleOptionIconClick(
                                                                        e,
                                                                        name,
                                                                        option.name
                                                                    )
                                                                }
                                                                style={{
                                                                    width: '36px',
                                                                    height: '36px',
                                                                    padding: 0,
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    src={
                                                                        option.icon
                                                                    }
                                                                    className={`${styles['pagination-input-container__dropdown__value-icon']}`}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                )}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}
                    {state === 'valid' && (
                        <IcoValid
                            className={`${styles['pagination-input-container__valid-icon']}`}
                        />
                    )}
                    {state === 'error' && (
                        <IcoError
                            className={`${styles['pagination-input-container__error-icon']}`}
                        />
                    )}
                </div>
                {error && <div className="select-input-error">{error}</div>}
            </React.Fragment>
        )
    }
)

PaginationInput.propTypes = {
    /**
     * Boolean to disable input if true
     */
    disabled: PropTypes.bool,
    /**
     * Boolean to disable input if true
     */
    loading: PropTypes.bool,
    /**
     * String to display input title
     */
    label: PropTypes.string,
    /**
     * Reference to the input
     */
    reference: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    /**
     * String to change language
     */
    language: PropTypes.string,
    /**
     * index to focus option
     */
    focused: PropTypes.number,
    /**
     * String to display input value
     */
    value: PropTypes.string.isRequired,
    /**
     * String to match variable containing all the other attributes
     */
    name: PropTypes.string.isRequired,
    /**
     * Array of strings where each string is an option
     */
    options: PropTypes.array.isRequired,
    /**
     * Array of complementary strings where each string is an option
     */
    optionsAux: PropTypes.array,
    /**
     * String or number containing selected option
     */
    selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * String or number containing selected option
     */
    selectedOptionAux: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /**
     * String that defines type of input to display : 'normal' | 'valid' | 'error'
     */
    state: PropTypes.string.isRequired,
    /**
     * String to display placeholder when no option is selected
     */
    /**
     * Function to select option from dropdown
     */
    onSelectInput: PropTypes.func.isRequired,
    /**
     * Function to handle clicking the arrow icon
     */
    onClickArrow: PropTypes.func,
    /**
     * Function to handle press of key
     */
    onKeyDown: PropTypes.func,
    /**
     * Function to handle press of key
     */
    onKeyPress: PropTypes.func,
}
