import React from 'react';
import PropTypes from 'prop-types';
import './word-tooltip.scss';
import { useTranslation } from 'react-i18next';

export const WordTooltip = React.memo((props) => {
    const { top, left, word } = props;
    const { i18n } = useTranslation();
    return (
        <div
            className="word-tooltip"
            style={{
                top: top,
                left: left,
                borderColor:
                    word.sentiment === 'positive'
                        ? '#7dd081'
                        : word.sentiment === 'neutral'
                        ? '#f0bb5f'
                        : word.sentiment === 'negative'
                        ? '#db6162'
                        : '#606060'
            }}
        >
            <div className="row w-100 m-0">
                <div className="col p-0 mr-2 d-flex justify-content-start align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--title">
                        <span>
                            {i18n.language === 'pt'
                                ? 'CLASSIFICAÇÃO'
                                : 'RANKING'}
                        </span>
                    </p>
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col p-0 d-flex justify-content-end align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--title-bold">
                        <span>{word.rankingPosition}</span>
                        <span>/{word.rankingCount}</span>
                    </p>
                </div>
            </div>
            <hr className="separator" />

            <div className="row w-100 m-0">
                <div className="col-auto p-0 pr-3 d-flex justify-content-start align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--content">
                        {i18n.language === 'pt' ? 'Nome' : 'Name'}
                    </p>
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col p-0 d-flex justify-content-end align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--content font-weight-bold">
                        {word.value}
                    </p>
                </div>
            </div>
            <div className="row w-100 m-0">
                <div className="col-auto p-0 pr-3 d-flex justify-content-start align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--content">
                        {i18n.language === 'pt' ? 'Sentimento' : 'Sentiment'}
                    </p>
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col p-0 d-flex justify-content-end align-items-center">
                    <p
                        className="word-tooltip__font word-tooltip__font--content font-weight-bold"
                        style={{
                            color:
                                word.sentiment === 'positive'
                                    ? '#7dd081'
                                    : word.sentiment === 'neutral'
                                    ? '#f0bb5f'
                                    : word.sentiment === 'negative'
                                    ? '#db6162'
                                    : '#606060'
                        }}
                    >
                        {word.sentiment}
                    </p>
                </div>
            </div>
            <div className="row w-100 m-0">
                <div className="col-auto p-0 pr-3 d-flex justify-content-start align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--content">
                        {i18n.language === 'pt' ? 'Contador' : 'Count'}
                    </p>
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col p-0 d-flex justify-content-end align-items-center">
                    <p className="word-tooltip__font word-tooltip__font--content font-weight-bold">
                        {word.inputCount}
                    </p>
                </div>
            </div>
        </div>
    );
});

WordTooltip.propTypes = {
    /**
     * Bool to choose when render should happen
     */
    animationEnded: PropTypes.bool,
    /**
     * String to choose which dataKey is currently selected
     */
    dataKey: PropTypes.string,
    /**
     * String or number to choose which id is currently selected
     * */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * String to choose which type of tooltip to render
     */
    type: PropTypes.string
};
