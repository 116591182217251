/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductDefaultSchema } from '../models/ProductDefaultSchema';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { TeamForecastDefaultSchema } from '../models/TeamForecastDefaultSchema';
import type { TeamMemberSchema } from '../models/TeamMemberSchema';
import type { TeamSchema } from '../models/TeamSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TeamsService {
    /**
     * deletes Team entities by ids
     * @returns any delete successful
     * @throws ApiError
     */
    public static deleteApiTeams({
        ids,
    }: {
        /**
         * list of ids to delete
         */
        ids: Array<number>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/teams',
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * Returns list of teams accordinng to params
     * @returns TeamSchema call successful
     * @throws ApiError
     */
    public static getApiTeams({
        leaderEmpNo,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * get teams where emp_no is leader
         */
        leaderEmpNo?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, dept_no, name, last_update, region_id, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'dept_no' | 'name' | 'last_update' | 'region_id' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<TeamSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams',
            query: {
                'leader_emp_no': leaderEmpNo,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates Team
     * @returns TeamSchema resource created
     * @throws ApiError
     */
    public static postApiTeams({
        requestBody,
    }: {
        requestBody: TeamSchema,
    }): CancelablePromise<TeamSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/teams',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * counts Team entities by filters
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiTeamsCount({
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, dept_no, name, last_update, region_id, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'dept_no' | 'name' | 'last_update' | 'region_id' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/count',
            query: {
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * deletes Team
     * @returns void
     * @throws ApiError
     */
    public static deleteApiTeams1({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/teams/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one Team by id
     * @returns TeamSchema call successful
     * @throws ApiError
     */
    public static getApiTeams1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<TeamSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * updates team
     * @returns TeamSchema call successful
     * @throws ApiError
     */
    public static putApiTeams({
        teamId,
        requestBody,
    }: {
        teamId: number,
        requestBody: TeamSchema,
    }): CancelablePromise<TeamSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/teams/{team_id}',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns the sales forecast for a given team
     * Returns the sales forecast for a given team
     * @returns TeamForecastDefaultSchema successful operation
     * @throws ApiError
     */
    public static getApiTeamsForecast({
        teamId,
    }: {
        /**
         * team ID.
         */
        teamId: string,
    }): CancelablePromise<TeamForecastDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{team_id}/forecast',
            path: {
                'team_id': teamId,
            },
        });
    }
    /**
     * get team members
     * @returns TeamMemberSchema call successful
     * @throws ApiError
     */
    public static getApiTeamsMembers({
        teamId,
        leaders,
    }: {
        teamId: number,
        leaders?: boolean,
    }): CancelablePromise<Array<TeamMemberSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{team_id}/members',
            path: {
                'team_id': teamId,
            },
            query: {
                'leaders': leaders,
            },
            errors: {
                404: `team not found`,
            },
        });
    }
    /**
     * adds member to team
     * @returns TeamMemberSchema call successful
     * @throws ApiError
     */
    public static postApiTeamsMembers({
        teamId,
        requestBody,
    }: {
        teamId: number,
        requestBody?: Array<TeamMemberSchema>,
    }): CancelablePromise<Array<TeamMemberSchema>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/teams/{team_id}/members',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * delete team member
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static deleteApiTeamsMembers({
        teamId,
        memberId,
    }: {
        /**
         * team id.
         */
        teamId: number,
        /**
         * member_id
         */
        memberId: number,
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/teams/{team_id}/members/{member_id}',
            path: {
                'team_id': teamId,
                'member_id': memberId,
            },
        });
    }
    /**
     * get team members by id
     * @returns TeamMemberSchema call successful
     * @throws ApiError
     */
    public static getApiTeamsMembers1({
        teamId,
        memberId,
    }: {
        /**
         * team id.
         */
        teamId: number,
        /**
         * member_id
         */
        memberId: number,
    }): CancelablePromise<TeamMemberSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{team_id}/members/{member_id}',
            path: {
                'team_id': teamId,
                'member_id': memberId,
            },
        });
    }
    /**
     * delete team member
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static patchApiTeamsMembers({
        teamId,
        memberId,
        requestBody,
    }: {
        /**
         * team id.
         */
        teamId: number,
        /**
         * member_id
         */
        memberId: number,
        requestBody?: TeamMemberSchema,
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/teams/{team_id}/members/{member_id}',
            path: {
                'team_id': teamId,
                'member_id': memberId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Removes association between products and team
     * @returns void
     * @throws ApiError
     */
    public static deleteApiTeamsProducts({
        teamId,
        requestBody,
    }: {
        teamId: number,
        requestBody: Array<number>,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/teams/{team_id}/products',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns list of products and if they are associated with team
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiTeamsProducts({
        teamId,
        associatedOnly,
        params,
        sortBy,
        sortDir,
    }: {
        teamId: number,
        associatedOnly?: boolean,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, ext_id, title, description, price, status, image_url, producer, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'ext_id' | 'title' | 'description' | 'price' | 'status' | 'image_url' | 'producer' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<{
        associated?: boolean;
        product?: ProductDefaultSchema;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{team_id}/products',
            path: {
                'team_id': teamId,
            },
            query: {
                'associated_only': associatedOnly,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * Associates products with team
     * @returns void
     * @throws ApiError
     */
    public static patchApiTeamsProducts({
        teamId,
        requestBody,
    }: {
        teamId: number,
        requestBody: Array<number>,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/teams/{team_id}/products',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns sales by product for team
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiTeamsSalesByProduct({
        teamId,
        fromDate,
        toDate,
    }: {
        teamId: number,
        fromDate?: string,
        toDate?: string,
    }): CancelablePromise<Array<{
        product?: ProductDefaultSchema;
        sales?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{team_id}/sales-by-product',
            path: {
                'team_id': teamId,
            },
            query: {
                'from_date': fromDate,
                'to_date': toDate,
            },
        });
    }
    /**
     * Returns sales by product for team
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiTeamsSalesVsAll({
        teamId,
        fromDate,
        toDate,
    }: {
        teamId: number,
        fromDate?: string,
        toDate?: string,
    }): CancelablePromise<Array<{
        all?: number;
        team?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/teams/{team_id}/sales-vs-all',
            path: {
                'team_id': teamId,
            },
            query: {
                'from_date': fromDate,
                'to_date': toDate,
            },
        });
    }
}
