import React, { useCallback, useEffect, useRef } from 'react';
import styles from './TeamDetailsModal.module.scss';
import { TeamDetailsMember } from '../team-details-member/TeamDetailsMember';
import { TypeInput } from '../../inputs/type-input/type-input';
import FullTextPopup from '../../popups/FullTextPopup';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import icoTeamCard from '../../../assets/images/icoTeamCard.svg';
import icoCloseModal from '../../../assets/images/ico-close-modal.svg';
import { DetailsEditButton } from '../../buttons/details-edit-button/DetailsEditButton';
import Scrollable from '../../scrolling/scrollable';
import { TeamMemberList } from '../../dashboard-components/team-member-list';

const mockTeamData = [
    {
        id: 1,
        title: 'Team 1',
        members: [
            {
                id: 1,
                name: 'Member 1'
            },
            {
                id: 2,
                name: 'Member 2'
            },
            {
                id: 3,
                name: 'Member 3'
            },
            {
                id: 4,
                name: 'Member 4'
            },
            {
                id: 5,
                name: 'Member 5'
            }
        ]
    }
];

const AddressBlock = ({ title, content, isEdit, input, onChangeInput }) => (
    <div className="col-auto px-0" style={{ marginRight: '2rem' }}>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span
                    className={styles['team-details-modal-container__subTitle']}
                >
                    {title}
                </span>
            </div>
        </div>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span
                    className={styles['team-details-modal-container__content']}
                >
                    {isEdit && (
                        <TypeInput
                            hideLabel
                            onChangeInput={onChangeInput}
                            isNumber
                            {...input}
                        />
                    )}
                    {!isEdit && (
                        <FullTextPopup text={content}>
                            <span
                                className={`${styles['hospital-account-information-container__team-text__content']}`}
                            >
                                {content || '-'}
                            </span>
                        </FullTextPopup>
                    )}
                </span>
            </div>
        </div>
    </div>
);

export const TeamDetailsModal = ({
    team,
    onToggleModal,
    isEdit,
    onCancelEditMode,
    onSaveEditMode
}) => {
    const componentRef = useRef(null);
    const {
        value,
        setValue: setValueInputs,
        replaceAll
    } = useMultipleInputs({
        address: {
            name: 'address',
            path: 'address',
            type: 'type',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        }
    });

    const handleOutsideClick = useCallback(
        (event) => {
            if (!componentRef.current.contains(event.target)) {
                onToggleModal(null);
                if (onCancelEditMode) onCancelEditMode();
            }
        },
        [onToggleModal, onCancelEditMode]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const title = team.teamName;
    const teamMembers = team.members;

    return (
        <>
            <div
                className={styles['team-details-modal-container']}
                ref={componentRef}
            >
                <div
                    className="row mx-0 w-100 d-flex justify-content-between align-items-center"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-start align-items-center">
                        <img
                            src={icoTeamCard}
                            alt="Team Card"
                            className={
                                styles['team-details-modal-container__icon']
                            }
                        />
                        <span
                            className={
                                styles['team-details-modal-container__title']
                            }
                        >
                            {title}
                        </span>
                    </div>
                    {isEdit && (
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <DetailsEditButton
                                onCancelEditMode={onCancelEditMode}
                                onSaveEditMode={onSaveEditMode}
                            />
                        </div>
                    )}
                    <div className="col-auto px-0 d-flex justify-content-end align-items-center">
                        <img
                            src={icoCloseModal}
                            alt="Close Modal"
                            className={
                                styles[
                                    'team-details-modal-container__close-icon'
                                ]
                            }
                            onClick={onToggleModal}
                        />
                    </div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0">
                        <div className="row mx-0 w-100">
                            <AddressBlock
                                title="Manager"
                                content={team.teamManager}
                                input={value.address}
                                onChangeInput={setValueInputs}
                            />
                            <AddressBlock
                                title="Team Members"
                                content={team.teamMembers}
                                input={value.address}
                                onChangeInput={setValueInputs}
                            />
                            <AddressBlock
                                title="Revenue"
                                content={team.revenue}
                                input={value.address}
                                onChangeInput={setValueInputs}
                            />
                            <AddressBlock
                                title="Customers"
                                content={team.customers}
                                input={value.address}
                                onChangeInput={setValueInputs}
                            />
                        </div>
                    </div>
                    <div
                        className="col-auto px-0"
                        style={{ width: '10rem' }}
                    ></div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0">
                        <span
                            className={
                                styles['team-details-modal-container__title']
                            }
                        >
                            Team Members
                        </span>
                    </div>
                </div>
                <div
                    className="row mx-0 w-100 flex-grow-1"
                    style={{ minHeight: 0 }}
                >
                    <div className="col px-0 h-100">
                        <TeamMemberList members={teamMembers} />
                    </div>
                </div>
            </div>
            <div className={styles['blurred-background']}></div>
        </>
    );
};
