import { createContext, useContext } from 'react';

const DragComponentContext = createContext({
    handleDragComponentStart: () => {},
    handleDragDynamicInfoComponentStart: () => {}
});

export function DragComponentProvider({ value, children }) {
    return (
        <DragComponentContext.Provider value={value}>
            {children}
        </DragComponentContext.Provider>
    );
}

export function useDragComponent() {
    return useContext(DragComponentContext);
}
