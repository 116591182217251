import React from 'react';

export const producerDonutSalesMock = {
    hoverStyle: '',
    desc: '',
    loading: false,
    title: '',
    titleColor: '#606060',
    content: '5',
    contentType: '',
    contentColor: '#06a4e7',
    contentFractionTotal: '',
    contentAux: '',
    contentAuxType: '',
    contentAuxColor: '#06a4e7',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaAttr: [
        {
            dataKey: 'exampleAttr1',
            color: '#06a4e7',
            strokeWidth: 3
        }
    ],
    categoryAxis: 'categoryAttr',
    data: {
        data1: [
            {
                key1: 'ALTER',
                value: 16393605.5,
                color: '#06A4E7',
                name: 'ALTER',
                percentage: 0.3379341755024947
            },
            {
                key1: 'AZEVEDOS',
                value: 5955726.6,
                color: '#5bbee7',
                name: 'AZEVEDOS',
                percentage: 0.12277003726174063
            },
            {
                key1: 'self',
                value: 4231117.37,
                color: '#8ac9e7',
                name: 'My Products',
                percentage: 0.08721932218542369
            },
            {
                key1: 'BOEHRINGER INGELH',
                value: 4170534.61,
                color: '#b9d4e7',
                name: 'BOEHRINGER INGELH',
                percentage: 0.08597048250520414
            },
            {
                key1: 'BAYER PORTUGAL',
                value: 3854483.25,
                color: '#e8eef7',
                name: 'BAYER PORTUGAL',
                percentage: 0.07945546933387694
            },
            {
                key1: 'Others',
                value: 13905771.510000002,
                color: '#9c9c9c',
                percentage: 0.28665051321125984,
                name: 'Others'
            }
        ]
    },
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    attr: 'producerDonutSales',
    prefix: 'producerDonutSales',
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    },
    donutAttr: []
};
