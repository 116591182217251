import React, { useCallback } from 'react';
import { GeneralTable } from '../general-table/general-table';
import { targetCustomerListMock } from './mock';
import { useFilter } from '../../../providers/filter-context';
import { ClientAccountService } from '../../../temp/test';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const TargetCustomerList = () => {
    const { t } = useCustomTranslation();
    const [clientAccountId, setClientAccountId] = React.useState(undefined);
    const { filter } = useFilter();

    const getClientAccountId = useCallback(async () => {
        try {
            const backData = await ClientAccountService.getApiClientAccount({});
            const filteredData = backData.filter((e) => {
                return (
                    e.product_id === parseInt(filter.productId) &&
                    e.client_id === parseInt(filter.hospitalId)
                );
            });
            if (filteredData.length > 0) {
                return filteredData[0].id;
            } else if (filteredData.length === 0) {
                const result =
                    await ClientAccountService.postApiClientAccountUpsert({
                        requestBody: {
                            product_id: filter.productId,
                            client_id: filter.hospitalId
                        }
                    });
                return result.id;
            }
        } catch (err) {
            console.log(err);
        }
    }, [filter]);
    return (
        <GeneralTable
            title={t(`components:${targetCustomerListMock.attr}.title`)}
            attr={targetCustomerListMock.attr}
            columns={[
                {
                    path: 'id',
                    backendPath: 'id',
                    show: false
                },
                {
                    path: 'spec',
                    backendPath: 'spec',
                    show: true
                },
                {
                    path: 'a',
                    label: '#A',
                    backendPath: 'a',
                    show: true
                },
                {
                    path: 'b',
                    label: '#B',
                    backendPath: 'b',
                    show: true
                },
                {
                    path: 'c',
                    label: '#C',
                    backendPath: 'c',
                    show: true
                },
                {
                    path: 'hcps',
                    label: '#HCPs',
                    backendPath: 'hcps',
                    show: true
                },
                {
                    path: 'keyStakeholders',
                    label: 'Key Stakeholders',
                    backendPath: 'key_stakeholders',
                    show: true
                },
                {
                    path: 'speakers',
                    label: 'Speakers',
                    backendPath: 'speakers',
                    show: true
                },
                {
                    path: 'action',
                    show: true
                }
            ]}
            getEndpoint={
                ClientAccountService.getApiClientAccountTargetClientsGetByParams
            }
            getConfig={{
                clientId: filter.hospitalId,
                productId: filter.productId
            }}
            addTitle={t(`components:${targetCustomerListMock.attr}.addTitle`)}
            addSuccessText={t(
                `components:${targetCustomerListMock.attr}.addSuccessText`
            )}
            addEndpoint={
                ClientAccountService.postApiClientAccountTargetClientsUpsert
            }
            addBodyStaticInputs={[
                {
                    value: getClientAccountId,
                    backendPath: 'client_account_id'
                }
            ]}
            addBodyInputs={{
                spec: {
                    name: 'spec',
                    path: 'spec',
                    backendPath: 'spec',
                    type: 'type',
                    show: true
                },
                a: {
                    name: 'a',
                    path: 'a',
                    backendPath: 'a',
                    type: 'type',
                    show: true
                },
                b: {
                    name: 'b',
                    path: 'b',
                    backendPath: 'b',
                    type: 'type',
                    show: true
                },
                c: {
                    name: 'c',
                    path: 'c',
                    backendPath: 'c',
                    type: 'type',
                    show: true
                },
                hcps: {
                    name: 'hcps',
                    path: 'hcps',
                    backendPath: 'hcps',
                    type: 'number',
                    show: true
                }
            }}
            updateTitle={t(
                `components:${targetCustomerListMock.attr}.updateTitle`
            )}
            updateSuccessText={t(
                `components:${targetCustomerListMock.attr}.updateSuccessText`
            )}
            updateEndpoint={
                ClientAccountService.postApiClientAccountTargetClientsUpsert
            }
            updateBodyStaticInputs={[
                {
                    path: 'id',
                    backendPath: 'id'
                }
            ]}
            updateBodyInputs={{
                spec: {
                    name: 'spec',
                    path: 'spec',
                    backendPath: 'spec',
                    type: 'type',
                    show: true
                },
                a: {
                    name: 'a',
                    path: 'a',
                    backendPath: 'a',
                    type: 'type',
                    show: true
                },
                b: {
                    name: 'b',
                    path: 'b',
                    backendPath: 'b',
                    type: 'type',
                    show: true
                },
                c: {
                    name: 'c',
                    path: 'c',
                    backendPath: 'c',
                    type: 'type',
                    show: true
                },
                hcps: {
                    name: 'hcps',
                    path: 'hcps',
                    backendPath: 'hcps',
                    type: 'number',
                    show: true
                }
            }}
            deleteIds={[
                {
                    path: 'id',
                    backendPath: 'id'
                }
            ]}
            deleteEndpoint={
                ClientAccountService.deleteApiClientAccountTargetClients
            }
            deleteTitle={t(
                `components:${targetCustomerListMock.attr}.deleteTitle`
            )}
            deleteDescription={t(
                `components:${targetCustomerListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${targetCustomerListMock.attr}.deleteSuccessText`
            )}
        />
    );
};
