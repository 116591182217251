import React from 'react'
import styles from './risk-table-column.module.scss'

export const RiskTableColumn = React.memo(({ el, column }) => {
    let riskColor = 'transparent'
    if (el[column.path] == 1) {
        riskColor = '#6fdb7d'
    } else if (el[column.path] == 2) {
        riskColor = '#dbdb6f'
    } else if (el[column.path] == 3) {
        riskColor = '#ffbd77'
    } else if (el[column.path] == 4) {
        riskColor = '#ff6363'
    }
    return (
        <div className={`${styles['risk-table-column-container']}`}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    className="risk-icon me-2"
                    style={{
                        backgroundColor: riskColor,
                    }}
                ></div>
                <span
                    className={`${styles['risk-table-column-container__content']}`}
                >
                    {el[column.path]}
                </span>
            </div>
        </div>
    )
})
