import React, { useCallback, useMemo, useRef } from 'react';
import styles from './filter-input.module.scss';
import icoFilter from '../../../assets/images/ico-filter-aux.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useToggle } from '../../../hooks/useToggle';
import { useValuesOpened } from '../../../hooks/useValuesOpened';
import Scrollable from '../../scrolling/scrollable';
import icoArrow from '../../../assets/images/ico-arrow.svg';
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg';
import icoCheckboxChecked from '../../../assets/images/ico-checkbox-selected.svg';

export const FilterInput = React.memo(
    ({ inputs, filters, toggleFilterOption, clearAllFilters }) => {
        const { t } = useCustomTranslation();
        const [isOpen, toggleIsOpen] = useToggle(false);
        const filtersRef = useRef(null);

        // Preparing the values for display and selection handling

        // create value from inputs knowing that inptus is something like { name: 'type', options: [ 'Enterprise', 'Organization', ], }

        const value = useMemo(() => {
            const initialValue = inputs.reduce((acc, el) => {
                acc[el.path] = {
                    name: el.path,
                    options: el.values,
                    selectedOptions:
                        filters.find((f) => f.path === el.path)?.value || []
                };
                return acc;
            }, {});
            return initialValue;
        }, [inputs, filters]);

        const initialState = useMemo(() => {
            const state = {};
            for (const key in value) {
                state[key] = false;
            }
            return state;
        }, [value]);

        const [valuesOpened, toggleOpen] = useValuesOpened(initialState);

        // Handler for selecting and deselecting filter options
        const handleSelectOption = (filterName, option) => {
            toggleFilterOption(filterName, option);
        };

        const handleOutsideClick = useCallback(
            (e) => {
                if (
                    filtersRef.current &&
                    !filtersRef.current.contains(e.target)
                ) {
                    toggleIsOpen();
                }
            },
            [toggleIsOpen]
        );

        React.useEffect(() => {
            if (isOpen) {
                document.addEventListener('click', handleOutsideClick);
            } else {
                document.removeEventListener('click', handleOutsideClick);
            }
            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }, [isOpen, handleOutsideClick]);

        return (
            <div className={styles['filter-input-container']} ref={filtersRef}>
                <div
                    className={
                        isOpen
                            ? `${styles['filter-input-container__button']} ${styles['filter-input-container__button--open']}`
                            : styles['filter-input-container__button']
                    }
                    onClick={toggleIsOpen}
                >
                    <img
                        src={icoFilter}
                        alt=""
                        className={
                            styles['filter-input-container__filter-icon']
                        }
                    />
                    <span
                        className={
                            styles['filter-input-container__filter-text']
                        }
                    >
                        {t('common:filter')}
                    </span>
                </div>
                {isOpen && filtersRef && (
                    <div
                        className={
                            styles['filter-input-container__options-container']
                        }
                        style={{
                            position: 'absolute',
                            zIndex: 100,
                            top: '1rem',
                            right: '0%',
                            transform: 'translate(0, 5rem)'
                        }}
                    >
                        <div
                            className="row mx-0 w-100 d-flex justify-content-between"
                            style={{
                                marginBottom: '2rem',
                                padding: '0 1rem'
                            }}
                        >
                            <div className="col-auto px-0">
                                <span
                                    className={
                                        styles[
                                            'filter-input-container__options-container__title'
                                        ]
                                    }
                                >
                                    {`${t('common:filterBy')}`}
                                </span>
                            </div>
                            <div className="col-auto px-0">
                                <span
                                    className={
                                        styles[
                                            'filter-input-container__options-container__clear'
                                        ]
                                    }
                                    onClick={clearAllFilters}
                                >
                                    {`${t('common:clearFilters')}`}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 flex-grow-1"
                            style={{ minHeight: 0 }}
                        >
                            <div className="col px-0 h-100">
                                <Scrollable addRightSpacing>
                                    {Object.values(value).map((el, index) => (
                                        <div
                                            key={index}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <div
                                                        className={
                                                            valuesOpened[
                                                                el.name
                                                            ]
                                                                ? `${styles['filter-input-container__options-container__option']} ${styles['filter-input-container__options-container__option--open']}`
                                                                : styles[
                                                                      'filter-input-container__options-container__option'
                                                                  ]
                                                        }
                                                        onClick={() =>
                                                            toggleOpen(el.name)
                                                        }
                                                    >
                                                        <div className="row mx-0 w-100 d-flex justify-content-between">
                                                            <div className="col-auto px-0">
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'filter-input-container__options-container__option__name'
                                                                        ]
                                                                    }
                                                                >
                                                                    {t(
                                                                        `common:${el.name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col-auto px-0">
                                                                <img
                                                                    src={
                                                                        icoArrow
                                                                    }
                                                                    alt=""
                                                                    className={
                                                                        styles[
                                                                            'filter-input-container__options-container__option__arrow'
                                                                        ]
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {valuesOpened[el.name] &&
                                                el.options.map(
                                                    (option, optionIndex) => (
                                                        <div
                                                            key={optionIndex}
                                                            className="row mx-0 w-100"
                                                        >
                                                            <div className="col px-0">
                                                                <div
                                                                    className={`${
                                                                        styles[
                                                                            'filter-input-container__options-container__option__value'
                                                                        ]
                                                                    } ${
                                                                        el.selectedOptions.includes(
                                                                            option
                                                                        )
                                                                            ? styles[
                                                                                  'selected'
                                                                              ]
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        handleSelectOption(
                                                                            el.name,
                                                                            option
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            el.selectedOptions.includes(
                                                                                option
                                                                            )
                                                                                ? icoCheckboxChecked
                                                                                : icoCheckboxEmpty
                                                                        }
                                                                        alt=""
                                                                        className={
                                                                            styles[
                                                                                'filter-input-container__options-container__option__value-checkbox'
                                                                            ]
                                                                        }
                                                                    />
                                                                    {option}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    ))}
                                </Scrollable>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
