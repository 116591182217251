import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import icoDropdownArrow from '../../../assets/images/dropdown-arrow.svg';
import styles from './scrollable.module.scss';
import { useToggle } from '../../../hooks/useToggle';
import {
    MainScrollProvider,
    useMainScroll
} from '../../../hooks/main-scroll-context';

export const Scrollable = React.memo(
    ({
        children,
        isMainScroll,
        direction,
        addRightSpacing,
        addLeftSpacing,
        addBottomSpacing,
        hideScroll,
        initialScrollOffset,
        hideShadowUp,
        isSmooth
    }) => {
        const [isHovered, setIsHovered] = useState(false);
        const [listShadow, setListShadow] = useState('default');
        const location = useLocation();
        const myRef = useRef(null);
        const { setScrollTop: setMainScrollTop } = useMainScroll();
        const [isActive] = useToggle(true);
        const [scrollTop, setScrollTop] = useState(0);
        const [scrollLeft, setScrollLeft] = useState(0);
        const [scrollHeight, setScrollHeight] = useState(0);
        const [scrollWidth, setScrollWidth] = useState(0);
        const trackerRef = useRef(null);
        const handleGoBackInScroll = () => {
            if (myRef.current.scrollLeft - 300 > 0) {
                myRef.current.scrollLeft = myRef.current.scrollLeft - 300;
            } else if (scrollLeft !== 0) {
                myRef.current.scrollLeft = 0;
            }
        };

        const handleScrollbarMouseDown = () => {
            window.addEventListener('mousemove', handleScrollbarMouseMove);
            window.addEventListener('mouseup', handleScrollbarMouseUp);
        };

        const handleScrollbarMouseMove = useCallback((e) => {
            const { clientX } = e;
            const { left, width } = myRef.current.getBoundingClientRect();
            const scrollPosition = (clientX - left) / width;

            let newScrollLeft =
                (myRef.current.scrollWidth - myRef.current.clientWidth) *
                scrollPosition;

            // Ensure the new scroll position is within bounds
            newScrollLeft = Math.max(0, newScrollLeft);

            // Adjust the maximum scroll position to prevent overflow on the right side
            newScrollLeft = Math.min(
                myRef.current.scrollWidth - myRef.current.clientWidth - 1,
                newScrollLeft
            );

            myRef.current.scrollLeft = newScrollLeft;
        }, []);

        const handleScrollbarMouseUp = () => {
            window.removeEventListener('mousemove', handleScrollbarMouseMove);
            window.removeEventListener('mouseup', handleScrollbarMouseUp);
        };

        const handleGoForwardInScroll = () => {
            if (
                myRef.current.scrollWidth - myRef.current.scrollLeft + 300 >
                myRef.current.clientWidth
            ) {
                if (
                    myRef.current.scrollWidth - myRef.current.scrollLeft >
                    myRef.current.clientWidth
                ) {
                    myRef.current.scrollLeft = myRef.current.scrollLeft + 300;
                }
            } else if (
                myRef.current.scrollWidth - myRef.current.scrollLeft >
                myRef.current.clientWidth
            ) {
                if (
                    myRef.current.scrollWidth - myRef.current.scrollLeft >
                    myRef.current.clientWidth
                ) {
                    myRef.current.scrollLeft = myRef.current.clientWidth;
                }
            }
        };

        const checkForShadow = useCallback((event) => {
            const top =
                event.target.scrollTop === 0 &&
                event.target.scrollHeight !== event.target.clientHeight;
            const bottom =
                event.target.scrollHeight - event.target.scrollTop ===
                    event.target.clientHeight &&
                event.target.scrollHeight !== event.target.clientHeight;
            const middle =
                event.target.scrollTop !== 0 &&
                event.target.scrollHeight - event.target.scrollTop !==
                    event.target.clientHeight;
            if (top) {
                setListShadow('up');
            } else if (bottom) {
                setListShadow('down');
            } else if (middle) {
                setListShadow('middle');
            } else {
                setListShadow('default');
            }
        }, []);

        const handleScroll = useCallback(
            (event) => {
                const {
                    scrollHeight,
                    scrollWidth,
                    clientHeight,
                    clientWidth,
                    scrollTop,
                    scrollLeft
                } = event.target;
                const isScrollTop = scrollHeight !== clientHeight;
                const isScrollLeft = scrollWidth !== clientHeight;
                if (isScrollTop) {
                    setScrollTop((scrollTop / scrollHeight) * clientHeight);
                    if (isMainScroll)
                        setMainScrollTop(
                            (scrollTop / scrollHeight) * clientHeight
                        );
                    setScrollHeight((clientHeight / scrollHeight) * 100);
                }
                if (isScrollLeft) {
                    setScrollLeft((scrollLeft / scrollWidth) * clientWidth);
                    setScrollWidth((clientWidth / scrollWidth) * 100);
                }
                checkForShadow(event);
            },
            [checkForShadow, isMainScroll, setMainScrollTop]
        );

        useEffect(() => {
            myRef.current.scrollTop = 0;
            myRef.current.scrollLeft = 0;
        }, [location.pathname]);

        useEffect(() => {
            if (myRef.current) {
                const { height } = myRef.current.getBoundingClientRect();
                setScrollHeight((height / myRef.current.scrollHeight) * 100);
            }
        }, [children]);

        useEffect(() => {
            if (myRef.current && initialScrollOffset !== undefined) {
                myRef.current.scrollLeft = initialScrollOffset;
            }
        }, [initialScrollOffset]);

        return (
            <div
                className={`${styles['scrollable-container']}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {/* scrollable content */}
                {isSmooth && (
                    <>
                        <img
                            src={icoDropdownArrow}
                            alt=""
                            className={`${styles['scrollable-container__arrow']}`}
                            style={{ pointerEvents: 'all' }}
                            onClick={handleGoBackInScroll}
                        />
                        <img
                            src={icoDropdownArrow}
                            alt=""
                            className={`${styles['scrollable-container__arrow']} ${styles['scrollable-container__arrow--inverse']}`}
                            style={{ pointerEvents: 'all' }}
                            onClick={handleGoForwardInScroll}
                        />
                    </>
                )}
                <div
                    className={`${styles['scrollable-container__container']}`}
                    ref={myRef}
                    onScroll={handleScroll}
                    style={
                        direction === 'horizontal'
                            ? {
                                  overflowX: 'auto',
                                  overflowY: 'hidden',
                                  scrollBehavior: isSmooth ? 'smooth' : 'auto'
                              }
                            : {
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                  scrollBehavior: isSmooth ? 'smooth' : 'auto'
                              }
                    }
                    onMouseEnter={() => {
                        const event = new Event('scroll'); // Create a new scroll event
                        myRef.current.dispatchEvent(event);
                    }}
                >
                    {React.Children.map(children, (child) => child)}
                </div>
                {/* scrollbar */}
                {isActive &&
                    !isMainScroll &&
                    scrollWidth !== 0 &&
                    scrollWidth !== 100 &&
                    direction === 'horizontal' && (
                        <div
                            className={`${styles['scrollable-container__horizontal-scrollbar']}`}
                            style={
                                hideScroll
                                    ? {
                                          visibility: 'hidden',
                                          pointerEvents: 'none'
                                      }
                                    : addBottomSpacing
                                    ? { top: 'calc(100% + 0.5rem)' }
                                    : {}
                            }
                        >
                            <div
                                className={`${styles['scrollable-container__horizontal-scrollbar__tracker']}`}
                                style={{
                                    left: scrollLeft,
                                    width: `${scrollWidth}%`,
                                    cursor: 'grabbing'
                                }}
                                ref={trackerRef}
                                onMouseDown={handleScrollbarMouseDown}
                            />
                        </div>
                    )}
                {isActive &&
                    !isMainScroll &&
                    scrollHeight !== 0 &&
                    scrollHeight !== 100 &&
                    scrollHeight < 95 &&
                    direction !== 'horizontal' && (
                        <div
                            className={`${styles['scrollable-container__vertical-scrollbar']}`}
                            style={
                                hideScroll
                                    ? {
                                          visibility: 'hidden',
                                          pointerEvents: 'none'
                                      }
                                    : addRightSpacing
                                    ? {
                                          marginLeft: '1rem',
                                          visibility: isHovered
                                              ? 'visible'
                                              : 'hidden'
                                      }
                                    : addLeftSpacing
                                    ? {
                                          right: '0.5rem',
                                          left: 'unset',
                                          visibility: isHovered
                                              ? 'visible'
                                              : 'hidden'
                                      }
                                    : {
                                          visibility: isHovered
                                              ? 'visible'
                                              : 'hidden'
                                      }
                            }
                        >
                            <div
                                className={`${styles['scrollable-container__vertical-scrollbar__tracker']}`}
                                style={{
                                    top: scrollTop,
                                    height: `${scrollHeight}%`
                                }}
                            />
                        </div>
                    )}
                {isMainScroll && !hideShadowUp && (
                    <div
                        className={
                            isMainScroll
                                ? `${styles['scrollable-container__main-shadow']}`
                                : `${
                                      styles[
                                          `scrollable-container__list-shadow`
                                      ]
                                  } ${
                                      styles[
                                          `scrollable-container__list-shadow--${listShadow}`
                                      ]
                                  }`
                        }
                    ></div>
                )}
            </div>
        );
    }
);
