import React from 'react'

export const salesHomologueMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    titleText: '',
    legend: [
        {
            name: 'lastYearSales',
            color: '#6586ea',
        },
        {
            name: 'previousYearSales',
            color: '#2cd1bd',
        },
    ],
    type: 'Map',
    barStackedAttr: [
        {
            dataKey: 'previousYearSales',
            color: '#2cd1bd',
            stackId: 'b',
            minPointSize: 70,
            radius: [8, 8, 0, 0],
        },
        {
            dataKey: 'lastYearSales',
            color: '#2cd1bd',
            stackId: 'a',
            minPointSize: 70,
            radius: [8, 8, 0, 0],
            isForecastGradient: true,
        },
    ],
    categoryAxis: 'month',
    barWidth: 20,
    data: [
        {
            month: 'Dec 2022',
            lastYearSales: 2879927.34,
            previousYearSales: 2619270.56,
        },
        {
            month: 'Jan 2023',
            lastYearSales: 2751521.59,
            previousYearSales: 2827734.23,
        },
        {
            month: 'Feb 2023',
            lastYearSales: 3054169.47,
            previousYearSales: 2583738.23,
        },
        {
            month: 'Mar 2023',
            lastYearSales: 3604831.6,
            previousYearSales: 2714170.84,
        },
        {
            month: 'Apr 2023',
            lastYearSales: 3113952.73,
            previousYearSales: 2590965.14,
        },
        {
            month: 'May 2023',
            lastYearSales: 3839485.54,
            previousYearSales: 3423997.4,
        },
        {
            month: 'Jun 2023',
            lastYearSales: 3737048.71,
            previousYearSales: 3312284.64,
        },
        {
            month: 'Jul 2022',
            previousYearSales: 3377734.11,
            lastYearSales: 0,
        },
        {
            month: 'Aug 2022',
            previousYearSales: 3135367.01,
            lastYearSales: 0,
        },
        {
            month: 'Sep 2022',
            previousYearSales: 2895008.03,
            lastYearSales: 0,
        },
        {
            month: 'Oct 2022',
            previousYearSales: 3543343.78,
            lastYearSales: 0,
        },
        {
            month: 'Nov 2022',
            previousYearSales: 3160282.15,
            lastYearSales: 0,
        },
    ],
    orientation: 'horizontal',
    tooltipType: 'salesHomologue',
    unitsOrValues: 'units',
    loading: false,
    categoryLabel: '',
    margin: {
        top: 5,
        left: 0,
        right: 20,
        bottom: 5,
    },
    attr: 'salesHomologueChart',
}
