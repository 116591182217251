import styles from './segment-analysis-new.module.scss'

import React, { useState, useCallback, useEffect } from 'react'

import { useArray } from '../../../hooks/useArray'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationMenu } from '../../../components'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { TabOverview } from './tabs/tab-overview/tab-overview'
import { TabSegments } from './tabs/tab-segments/tab-segments'

/**
 * Provides a page layout that allows users to analyze segments
 */

const SegmentAnalysis = React.memo(() => {
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useCustomTranslation()
    const { array: subNavs } = useArray(['overview', 'segments'])
    const [selectedOption, setSelectedOption] = useState(
        location.pathname.includes('/segment-analysis/overview')
            ? 'overview'
            : location.pathname.includes('/segment-analysis/segments')
            ? 'segments'
            : null
    )

    const handleChangeSubNav = useCallback(
        (option) => {
            navigate(`/segment-analysis/${option}`)
            setSelectedOption(option)
        },
        [setSelectedOption, navigate]
    )

    useEffect(() => {
        if (location.pathname === '/segment-analysis') {
            navigate('/segment-analysis/overview')
        }
    }, [navigate])

    return (
        <>
            <div className={`row mx-0 w-100 ${styles['segment-analysis-new-container']}`}>
                <div className="col px-0 d-flex flex-column">
                    <div className="row w-100 dashboard-header-row">
                        <div className="col px-0 d-flex justify-content-between align-items-center">
                            <span>{t(`pages:segmentAnalysis.title`)}</span>
                        </div>
                    </div>

                    <div className="row mx-0 w-100 " style={{ marginBottom: '2rem' }}>
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <div className={`row mx-0 w-100 h-100`}>
                                <div className="col px-0">
                                    <NavigationMenu
                                        isPageMenu
                                        options={subNavs}
                                        selectedOption={selectedOption}
                                        onChangeOption={handleChangeSubNav}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedOption === 'overview' && <TabOverview />}
                    {selectedOption === 'segments' && <TabSegments />}
                </div>
            </div>
        </>
    )
})

export default SegmentAnalysis

SegmentAnalysis.propTypes = {}
