import React, { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { regionGeoMarketIndexMock } from './mock'
import { useFilter } from '../../../providers/filter-context'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useGeo } from '../../../hooks/useGeo'
import { ContentContainer, Loader, MarkerMap, Spinner } from '../..'
import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component'
import { RegionsService, SalesService } from '../../../temp/test'
import { getFilterParam } from '../../../utils/params'
import distritosGeoJson from '../../../mock/geojson/portugal-districts.json'
import { turnPortugalLayerIntoDistrictsLayers } from '../../../utils/geo'
import * as turf from '@turf/turf'
import mockDatalist from './mockDatalist.json'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const RegionGeoMarketIndex = React.memo(({ config, isExample }) => {
    const { filter } = useFilter()
    const isMounted = useIsMounted()
    const { i18n } = useTranslation()
    const { t } = useCustomTranslation()

    const { value: data, toggleComponentSettings, setLoading, setValue } = useGeo(regionGeoMarketIndexMock)

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(regionGeoMarketIndexMock)
            if (!isExample) {
                try {
                    if (isMounted.current) {
                        setLoading(true)
                    }
                    const dataBackIndexes = await RegionsService.getApiRegionsIndexesAll({
                        level: 1,
                    })
                    const districtsClone = _.cloneDeep(distritosGeoJson)
                    dataClone.legend = []
                    dataClone.dataList = []
                    for (let region of dataBackIndexes) {
                        let isInsideCoordinates = false
                        if (region.region.lat === null || region.region.lon === null) {
                            break
                        }
                        var point = turf.point([region.region.lon, region.region.lat])
                        for (let feature of districtsClone.features) {
                            // here first is lng and then lat
                            for (let coord of feature.geometry.coordinates) {
                                var polygon = turf.polygon(coord, {
                                    name: 'poly1',
                                })

                                // here first is lng and then lat
                                isInsideCoordinates = turf.inside(point, polygon)
                                if (isInsideCoordinates) {
                                    feature.properties.lat = region.region.lat
                                    feature.properties.lon = region.region.lon
                                }

                                if (isInsideCoordinates) {
                                    feature.marketIndex = region.market_index
                                    feature.properties.marketIndex = feature.marketIndex
                                    feature.properties.color = feature.marketIndex > 1 ? '#f0972d' : '#7ec3e5'
                                    break
                                }
                            }
                            if (isInsideCoordinates) {
                                break
                            }
                        }
                        if (isInsideCoordinates) {
                            continue
                        }
                    }
                    dataClone.legend = [
                        {
                            name: 'Market Index > 1',
                            color: '#f0972d',
                            translated: true,
                        },
                        {
                            name: 'Market Index < 1',
                            color: '#7ec3e5',
                            translated: true,
                        },
                    ]

                    const districtsLayers = turnPortugalLayerIntoDistrictsLayers(districtsClone)
                    dataClone.dataList = districtsLayers
                    verifyComponent(dataClone)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            } else {
                dataClone.dataList = mockDatalist
                dataClone.legend = [
                    {
                        name: 'Market Index > 1',
                        color: '#f0972d',
                        translated: true,
                    },
                    {
                        name: 'Market Index < 1',
                        color: '#7ec3e5',
                        translated: true,
                    },
                ]
                setValue(dataClone)
            }
        }
        fill()
    }, [filter, isMounted, setLoading, setValue, t, config, isExample])

    const handleSelectSettingsOption = useCallback(() => {
        if (isMounted.current) toggleComponentSettings()
    }, [isMounted, toggleComponentSettings])

    return (
        <ContentContainer
            attr={data.attr}
            titleText={t(`components:${data.attr}.title`)}
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
            onClickSettingsView={handleSelectSettingsOption}
            onCLickSettintsSelected={handleSelectSettingsOption}
            onCloseSettings={toggleComponentSettings}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <MarkerMap
                    translate={t}
                    dataList={data.dataList}
                    startingPosition={data.startingPosition}
                    tooltipType={data.tooltipType}
                    interactionDisabled={data.interactionDisabled}
                    selectedEl={data.selectedEl}
                    showPopup={data.showPopup}
                    scale={data.scale}
                    minScale={data.minScale}
                    minZoom={data.minZoom}
                    flyEnabled={data.flyEnabled}
                    customScale={10}
                />
            )}
        </ContentContainer>
    )
})
