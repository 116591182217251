import React from 'react';

import icoDelete from '../../../assets/images/ico-delete.svg';
import icoPlus from '../../../assets/images/ico-plus.svg';

export const roleListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'name', label: '', sortable: true },
        { path: 'action', label: '', sortable: false }
    ],
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'roleTable',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: [
                { name: 'addRole', label: '', icon: icoPlus },
                { name: 'removeRole', label: '', icon: icoDelete }
            ]
        },
        view: {
            options: []
        },
        options: {
            label: '',
            options: [{ name: 'addRole', label: '', icon: icoPlus }]
        },
        reference: React.createRef()
    }
};
