/**
 * ContactFooter.jsx
 *
 * This component is a contact footer that allows users to navigate to the contact support page.
 * It uses the useNavigate hook from react-router-dom to handle navigation.
 *
 * @author Ricardo Velhinho
 * @version 1.0
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './contact-footer.module.scss'

import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

/**
 * ContactFooter functional component
 * @returns {JSX.Element} - returns JSX element of contact footer
 */

export const ContactFooter = React.memo(() => {
    const { t } = useCustomTranslation()
    const navigate = useNavigate()
    return (
        <div
            className={`${styles['contact-footer-container']}`}
            data-testid={'contact-footer'}
        >
            <span className={`${styles['contact-footer-container__text']}`}>
                {t('pages:login.contactFooter')}
            </span>{' '}
            <span
                className={`${styles['contact-footer-container__link']}`}
                onClick={() => navigate('/contact-support')}
                data-testid={'contact-support-link'}
            >
                {t('pages:login.contactFooter2')}
            </span>
        </div>
    )
})
