import React, { useState } from 'react'
import icoExpandPermission from '../../../../../../../../../assets/images/icoExpandPermission.svg'
import icoCollapsePermission from '../../../../../../../../../assets/images/icoCollapsePermission.svg'
import icoCheck from '../../../../../../../../../assets/images/icoCheck.svg'
import icoUncheck from '../../../../../../../../../assets/images/icoUncheck.svg'
import styles from './permission-details.module.scss'

export const PermissionDetails = ({ title, index, rolesEnabled, rolesDisabled, isLast, shouldContainRoles }) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <>
            <div
                className="row mx-0 w-100 position-relative"
                style={{
                    height: '2.5rem',
                    marginBottom: '1rem',
                }}
            >
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <div
                        style={{
                            position: 'absolute',
                            borderBottom: '1px solid #E5E5E5',
                            borderLeft: '1px solid #E5E5E5',
                            borderBottomLeftRadius: '0.25rem',
                            width: '2.25rem',
                            height: index === 0 ? '2.5rem' : `4rem`,
                            bottom: '50%',
                            left: '-4rem',
                            transform: 'translate(50%, 0%)',
                        }}
                    ></div>
                    <span className={`${styles['permission-tree__permission-title']}`}>{title}</span>
                    {shouldContainRoles && (
                        <img
                            src={expanded ? icoCollapsePermission : icoExpandPermission}
                            className={styles['permission-tree__permission-icon']}
                            alt="expand"
                            onClick={() => setExpanded(!expanded)}
                        />
                    )}
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0 position-relative">
                    {expanded && !isLast && (
                        <div
                            style={{
                                position: 'absolute',
                                height: 'calc(100% + 3.5rem)',
                                width: '1px',
                                backgroundColor: '#E5E5E5',
                                left: '-3rem',
                                top: '0%',
                                transform: 'translate(50%, -50%)',
                            }}
                        ></div>
                    )}
                    {expanded &&
                        rolesEnabled &&
                        rolesEnabled.length > 0 &&
                        rolesEnabled.map((role, index) => {
                            return (
                                <div key={index} className="d-flex align-items-center" style={{ marginBottom: '1rem' }}>
                                    <img key={index} src={icoCheck} className={styles['permission-tree__permission-role-icon']} alt="check" />
                                    <span className={`${styles['permission-tree__permission-role-title']}`}>{role}</span>
                                </div>
                            )
                        })}
                    {expanded &&
                        rolesDisabled &&
                        rolesDisabled.length > 0 &&
                        rolesDisabled.map((role, index) => {
                            return (
                                <div key={index} className="d-flex align-items-center" style={{ marginBottom: '1rem' }}>
                                    <img key={index} src={icoUncheck} className={styles['permission-tree__permission-role-icon']} alt="uncheck" />
                                    <span className={`${styles['permission-tree__permission-role-title']}`}>{role}</span>
                                </div>
                            )
                        })}
                </div>
            </div>
        </>
    )
}
