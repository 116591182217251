import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { segmentClientsAddedListMock } from './mock';
import { verifyComponent } from '../../../utils/component.js';
import { WithTableHeader } from '../../../hocs/table-header/table-header';
import { Spinner } from '../../loading/spinner/spinner';
import { Table } from '../../tables/table/table';
import { ContentContainer, Loader } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Lists all the users in a table
 */

export const SegmentClientsAddedList = React.memo(
    ({ clients, onRemoveClientFromSegment }) => {
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [modal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            pageChange: dataPageChange,
            sort: dataSort,
            selectAll: dataSelectAll,
            unselectAll: dataUnselectAll,
            setLoading,
            setValue,
            clickActionIcon
        } = useTable(segmentClientsAddedListMock, modal, '');

        const getSegmentClientsAdded = useCallback(
            async (search) => {
                const dataClone = _.cloneDeep(segmentClientsAddedListMock);
                try {
                    dataClone.data.length = 0;
                    dataClone.sortedData.length = 0;
                    dataClone.filteredData.length = 0;

                    dataClone.columns = onRemoveClientFromSegment
                        ? [
                              { path: 'id', label: '', sortable: true },
                              { path: 'name', label: '', sortable: true },
                              { path: 'risk', label: '', sortable: true },
                              { path: 'revenue', label: '', sortable: true },
                              { path: 'sentiment', label: '', sortable: true },
                              { path: 'remove', label: '', sortable: false }
                          ]
                        : [
                              { path: 'id', label: '', sortable: true },
                              { path: 'name', label: '', sortable: true },
                              { path: 'risk', label: '', sortable: true },
                              { path: 'revenue', label: '', sortable: true },
                              { path: 'sentiment', label: '', sortable: true }
                          ];
                    // const dataBack =
                    //     await PointOfSaleService.getApiPosClientOverviewTableVendors(
                    //         {
                    //             vendorId: [],
                    //         }
                    //     )

                    if (search) {
                        const searchRegex = new RegExp(
                            `${search.replace(
                                new RegExp('\\\\', 'g'),
                                '\\\\'
                            )}`,
                            'i'
                        );
                        dataClone.filteredData = _.filter(
                            dataClone.data,
                            (el) => {
                                return el.name.toString().match(searchRegex);
                            }
                        );
                    } else {
                        dataClone.filteredData = _.cloneDeep(dataClone.data);
                    }
                    dataClone.sortedData = _.sortBy(
                        dataClone.filteredData,
                        dataClone.sortColumn.path,
                        dataClone.sortColumn.order
                    );
                    dataClone.sortedData.map((el, index) => {
                        el.idx = index;
                        return el;
                    });
                    dataClone.titleCounter = dataClone.sortedData.length;
                    dataClone.loading = false;
                    dataClone.disabled = true;

                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            },
            [isMounted, setLoading, setValue, onRemoveClientFromSegment]
        );

        useEffect(() => {
            getSegmentClientsAdded();
        }, [getSegmentClientsAdded]);

        return (
            <>
                <ContentContainer
                    hidePinIcon
                    attr={data.attr}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    hideSettingsIcon
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <WithTableHeader count={data.titleCounter}>
                            <Table
                                actions={data.actions}
                                // onClickActionIcon={onClickActionIcon}
                                // onClickActionOption={onClickActionOption}
                                // onCloseActions={onCloseActions}
                                attr={data.attr}
                                type={data.type}
                                placeholderText={t(
                                    `components:${data.attr}.nodata`
                                )}
                                columns={data.columns}
                                data={clients}
                                sortColumn={data.sortColumn}
                                onSort={dataSort}
                                currentPage={data.currentPage}
                                pageSize={data.pageSize}
                                onPageChange={dataPageChange}
                                onRowClick={undefined}
                                onRowSelect={undefined}
                                onSelectAll={dataSelectAll}
                                onUnselectAll={dataUnselectAll}
                                paginationDisabled={data.paginationDisabled}
                                disabled={data.disabled}
                                onClickActionIcon={clickActionIcon}
                                onCloseActions={clickActionIcon}
                                onRemove={onRemoveClientFromSegment}
                                removeButtonText={t(
                                    `components:segmentClientsAddedTable.remove`
                                )}
                            ></Table>
                        </WithTableHeader>
                    )}
                </ContentContainer>
            </>
        );
    }
);

SegmentClientsAddedList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func
};
