import React from 'react';

export const marketShareGlobalDonutMock = {
    hoverStyle: '',
    desc: '',
    loading: false,
    title: '',
    titleColor: '#606060',
    content: '5',
    contentType: '',
    contentColor: '#06a4e7',
    contentFractionTotal: '',
    contentAux: '',
    contentAuxType: '',
    contentAuxColor: '#06a4e7',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaAttr: [
        {
            dataKey: 'exampleAttr1',
            color: '#06a4e7',
            strokeWidth: 3
        }
    ],
    categoryAxis: 'categoryAttr',
    data: {
        data1: [
            {
                key1: 'Products Market Share',
                value: 4000,
                color: '#FD9126',
                name: 'Products Market Share',
                percentage: 0.75
            },
            {
                key1: 'Others',
                value: 1000,
                color: '#FDDCB2',
                name: 'Others',
                percentage: 0.25
            }
        ]
    },
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    attr: 'marketShareGlobalDonut',
    prefix: 'marketShareGlobalDonut',
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    },
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1'
        }
    ]
};
