import { createContext, useContext } from 'react';

const ModuleSubscriptionContext = createContext({
    moduleSubscription: undefined,
    setModuleSubscription: () => {}
});

export function ModuleSubscriptionProvider({ value, children }) {
    return (
        <ModuleSubscriptionContext.Provider value={value}>
            {children}
        </ModuleSubscriptionContext.Provider>
    );
}

export function useModuleSubscription() {
    return useContext(ModuleSubscriptionContext);
}
