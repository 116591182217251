import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamsService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { useTextInput } from '../../../hooks/useTextInput';
import {
    Modal,
    PrimaryButton,
    RadioInput,
    SecondaryButton,
    SuccessModal,
    TypeInput
} from '../../';

import { useRatioInput } from '../../../hooks/useRatioInput';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const EditTeamMemberModal = ({
    teamId,
    teamMemberId,
    onCloseModal,
    onEnd
}) => {
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const {
        value: firstName,
        setValue: firstNameSetValue,
        replaceValue: firstnameREpl
    } = useTextInput({
        name: 'firstName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: lastName,
        setValue: lastNameSetValue,
        replaceValue: lastnameREpl
    } = useTextInput({
        name: 'lastName',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: isResponsible,
        clickRatioOption: isResponsibleClickRadioOption
    } = useRatioInput({
        name: 'isResponsible',
        options: [
            {
                name: 'yes',
                label: t(`inputs:yes`),
                disabled: false,
                blocked: false
            },
            {
                name: 'no',
                label: t(`inputs:no`),
                disabled: false,
                blocked: false
            }
        ],
        selectedOption: 'yes',
        value: 'yes'
    });

    useEffect(() => {
        async function fill() {
            try {
                const dataBack = await TeamsService.getApiTeamsMembers1({
                    teamId: teamId,
                    memberId: teamMemberId
                });
                if (dataBack.employee && dataBack.employee.last_name) {
                    lastnameREpl(dataBack.employee.last_name);
                }
                if (dataBack.employee && dataBack.employee.first_name) {
                    firstnameREpl(dataBack.employee.first_name);
                }
                if (dataBack.is_leader) {
                    isResponsibleClickRadioOption(undefined, undefined, 'yes');
                }
                if (!dataBack.is_leader) {
                    isResponsibleClickRadioOption(undefined, undefined, 'no');
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [
        teamId,
        teamMemberId,
        firstnameREpl,
        isResponsibleClickRadioOption,
        lastnameREpl
    ]);

    const handleEditTeamMember = async () => {
        try {
            setLoading(true);
            await TeamsService.patchApiTeamsMembers({
                teamId: teamId,
                memberId: teamMemberId,
                requestBody: {
                    employee: {
                        first_name: firstName.value,
                        last_name: lastName.value
                    },
                    is_leader:
                        isResponsible.selectedOption === 'yes' ? true : false
                }
            });
            setIsSuccess(true);
            setLoading(false);
            onEnd();
        } catch (err) {
            setLoading(false);
            errorHandler(err);
        }
    };

    if (!isSuccess) {
        return (
            <Modal
                onToggleModal={() => onCloseModal(false)}
                isLoading={isLoading}
            >
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">
                            {t('pages:teamManagement.editTeamMember')}
                        </span>
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <TypeInput
                            onChangeInput={firstNameSetValue}
                            {...firstName}
                        />
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <TypeInput
                            onChangeInput={lastNameSetValue}
                            {...lastName}
                        />
                    </div>
                </div>
                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                    <div className="col px-0">
                        <RadioInput
                            {...isResponsible}
                            direction="horizontal"
                            onClickRatioOption={isResponsibleClickRadioOption}
                        />
                    </div>
                </div>
                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1'
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton
                            text={t('buttons:cancel')}
                            onClick={() => {
                                onCloseModal(false);
                            }}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('buttons:save')}
                            disabled={!isResponsible.selectedOption}
                            onClick={() => {
                                handleEditTeamMember();
                            }}
                        />
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:editTeamMember.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
