import _ from 'lodash';
import { useCallback, useReducer } from 'react';

const ACTIONS = {
  SET_KEYWORDS: 'setKeywords',
  UNSELECT_KEYWORD: 'unselectKeyword',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_KEYWORDS:
      return [...action.payload];
    case ACTIONS.UNSELECT_KEYWORD:{

      const idx = _.findIndex(state, (el) => {
        return el.value === action.payload;
      });
      if (idx !== -1) {
        state[idx].selected = false;
        return [...state];
      } else {
        return [...state];
      }
    }
  }
}

export function useRecommendedKeywords(defaultValue) {
  const [keywords, dispatch] = useReducer(reducer, defaultValue);

  const setKeywords = useCallback((value) => {
    dispatch({ type: 'setKeywords', payload: value });
  }, []);

  const unselectKeyword = useCallback((value) => {
    dispatch({ type: 'unselectKeyword', payload: value });
  }, []);

  return {
    keywords,
    setKeywords,
    unselectKeyword,
  };
}
