import React, { useMemo, useState } from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { CreateGoalModal } from '../../../../../components/modals/create-goal-modal/create-goal-modal';
import { PrimaryButton } from '../../../../../components';
import icoPlus from '../../../../../assets/images/ico-plus.svg';
import { SearchInput } from '../../../../../components/inputs/search-input/SearchInput';
import { FilterInput } from '../../../../../components/inputs/filter-input/filter-input';
import { useTableFilter } from '../../../../../hooks/useTableFilter';
import _ from 'lodash';

function generateMemberMockData(number) {
    const numberOfMembers = number || 10; // Adjust the number of members as needed
    const memberTypes = ['enterprise', 'organization'];
    const roles = ['admin'];
    const teams = ['Team 1', 'Team 2', 'Team 3'];

    const mockData = Array.from({ length: numberOfMembers }, (_, i) => {
        const id = i + 1;
        const typeIndex = i % memberTypes.length;
        const roleIndex = i % roles.length;
        const hasImage = i % 2 === 0; // Alternate between having an image and not

        return {
            id: id,
            image: undefined, // You can adjust this to link to actual images or use dynamic placeholders
            name: `Member ${id}`,
            email: `member${id}@example.com`,
            contact: `123456${id.toString().padStart(4, '0')}`,
            type: memberTypes[typeIndex],
            role: roles[roleIndex],
            teams: teams.slice(0, (i % teams.length) + 1), // Each member has a varying number of teams
            orgId: typeIndex === 1 ? id : undefined // Only assign orgId for organization type
        };
    });

    return mockData;
}

export const GoalFirstRow = ({ searchValue, setSearchValue, description }) => {
    const { t } = useCustomTranslation();
    const [cards, setCards] = useState(generateMemberMockData(15)); // Replace with actual data from API
    const [createGoalModal, setCreateGoalModal] = React.useState(false);

    const [filters, toggleFilterOption, clearAllFilters] = useTableFilter([]);
    const [infoToShow] = useState(['type', 'role']);
    const filterInputs = useMemo(() => {
        // for every column that has an attribute isFilter to true, go get every unique value
        return infoToShow.map((path) => {
            const values = _.uniq(cards.map((item) => _.get(item, path)));
            return {
                path,
                values
            };
        });
    }, [cards, infoToShow]);
    return (
        <>
            {createGoalModal && (
                <CreateGoalModal
                    onToggleModal={() => setCreateGoalModal(false)}
                />
            )}
            <div
                className="row mx-0 w-100 d-flex justify-content-end align-items-center"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col px-0">
                    <span className={'dashboard-sub-title'}>{description}</span>
                </div>
                <div className="col-auto px-0">
                    <PrimaryButton
                        text={t('buttons:createGoal')}
                        icon={icoPlus}
                        onClick={() => {
                            setCreateGoalModal(true);
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0"></div>
                <div className="col-auto px-0" style={{ width: '30rem' }}>
                    <SearchInput
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={t('inputs:searchMembers')}
                    />
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col-auto px-0 d-flex align-items-center">
                    <FilterInput
                        inputs={filterInputs}
                        filters={filters}
                        toggleFilterOption={toggleFilterOption}
                        clearAllFilters={clearAllFilters}
                    />
                </div>
            </div>
        </>
    );
};
