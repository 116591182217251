import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IcoActionDefault } from './assets/images/ico-action-default.svg';
import { ReactComponent as IcoActionSelected } from './assets/images/ico-action-selected.svg';
import styles from './actions-button.module.scss';
import { OptionListOld } from '../../chart-configuration/option-list-old/option-list-old';

export const ActionsButton = React.memo(
    ({
        attr,
        actions,
        onCloseActions,
        onClickActionOption,
        onClickActionIcon,
        disabled,
        position,
        onMouseEnter,
        onMouseLeave
    }) => {
        const handleOutsideClick = useCallback(
            (event) => {
                if (actions && actions.open) {
                    if (
                        actions.reference.current &&
                        !actions.reference.current.contains(event.target)
                    ) {
                        event.preventDefault();
                        event.stopPropagation();
                        onCloseActions(attr, actions.id);
                    }
                }
            },
            [actions, attr, onCloseActions]
        );

        useEffect(() => {
            document.addEventListener('click', handleOutsideClick);
            return function cleanup() {
                document.removeEventListener('click', handleOutsideClick);
            };
        }, [handleOutsideClick]);

        if (!actions || (actions && !actions.open)) {
            return (
                <div className={`${styles['actions-container']}`}>
                    <IcoActionDefault
                        className={
                            disabled
                                ? `${styles['actions-container__icon']} ${styles['actions-container__icon--normal']} ${styles['disabled']}`
                                : `${styles['actions-container__icon']} ${styles['actions-container__icon--normal']}`
                        }
                        onMouseEnter={onMouseEnter ? onMouseEnter : undefined}
                        onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
                        onClick={
                            onClickActionIcon
                                ? (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onClickActionIcon(attr, actions.id);
                                  }
                                : undefined
                        }
                    />
                </div>
            );
        } else if (actions && actions.open) {
            return (
                <div
                    className={`${styles['actions-container']}`}
                    ref={actions.reference}
                >
                    <IcoActionSelected
                        className={
                            disabled
                                ? `${styles['actions-container__icon']} ${styles['disabled']}`
                                : `${styles['actions-container__icon']}`
                        }
                        onMouseEnter={onMouseEnter ? onMouseEnter : undefined}
                        onMouseLeave={onMouseLeave ? onMouseLeave : undefined}
                        onClick={
                            onClickActionIcon
                                ? (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onClickActionIcon(attr, actions.id);
                                  }
                                : undefined
                        }
                    />
                    {actions.options && actions.options.length !== 0 && (
                        <OptionListOld
                            attr={attr}
                            label={actions.label}
                            id={actions.id}
                            type={'default'}
                            options={actions.options}
                            onClickOption={onClickActionOption}
                            position={position}
                        />
                    )}
                </div>
            );
        }
    }
);
ActionsButton.propTypes = {
    /**
     * Object containing array of options.
     * Each option is an object containing two attributes:
     * name: String to display label
     * icon: Image to display
     */
    actions: PropTypes.object.isRequired,
    /**
     * Function to handle clicking on an option
     */
    onClickActionOption: PropTypes.func.isRequired,
    /**
     * Bool to disable button
     */
    disabled: PropTypes.bool
};
