/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientSentimentInfoSchema } from '../models/ClientSentimentInfoSchema';
import type { PointOfSaleSchema } from '../models/PointOfSaleSchema';
import type { VariationInfoSchema } from '../models/VariationInfoSchema';
import type { VendorStatusLogSchema } from '../models/VendorStatusLogSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PointOfSaleService {
    /**
     * Returns list of clients with coordinates
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPos({
        clientId,
        mapInfo,
        page,
        vendorId,
    }: {
        /**
         * List of client ids.
         */
        clientId?: number,
        /**
         * append risk, location and status to clients.
         */
        mapInfo?: boolean,
        /**
         * optional pagination for tables.
         */
        page?: number,
        /**
         * vendor_id to filter clients returned.
         */
        vendorId?: number,
    }): CancelablePromise<Array<{
        active?: boolean;
        client_id?: number;
        cluster_id?: number;
        lat?: number;
        lon?: number;
        lost?: boolean;
        name?: string;
        pos_id?: number;
        risk?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos',
            query: {
                'client_id': clientId,
                'map_info': mapInfo,
                'page': page,
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns the number of active clients and monthly percentage variation
     * @returns VariationInfoSchema call successful
     * @throws ApiError
     */
    public static getApiPosActive({
        vendorId,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
    }): CancelablePromise<VariationInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/active',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns the number with all cards ;)
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosAllCards({
        vendorId,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
    }): CancelablePromise<{
        active?: VariationInfoSchema;
        at_risk?: VariationInfoSchema;
        count?: VariationInfoSchema;
        inactive?: VariationInfoSchema;
        new?: VariationInfoSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/all_cards',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns the number with all cards ;)
     * @returns VendorStatusLogSchema call successful
     * @throws ApiError
     */
    public static getApiPosAllCardsTimeseries({
        vendorId,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
    }): CancelablePromise<VendorStatusLogSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/all_cards_timeseries',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns the number of clients at risk
     * @returns VariationInfoSchema call successful
     * @throws ApiError
     */
    public static getApiPosAtRisk({
        vendorId,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
    }): CancelablePromise<VariationInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/at_risk',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns attachment as csv of clients in risk with some relevant information
     * @returns binary call successful, should start transfer
     * @throws ApiError
     */
    public static getApiPosAtRiskCsv({
        posId,
    }: {
        /**
         * List of client ids.
         */
        posId?: Array<number>,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/at_risk_csv',
            query: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns the information necessary to populate a the table on Client Overview Page.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosClientOverviewTableVendors({
        vendorId,
    }: {
        /**
         * list of vendors
         */
        vendorId?: Array<number>,
    }): CancelablePromise<Array<{
        average_order?: number;
        last_order?: string;
        name?: string;
        perc_risk?: number;
        risk?: number;
        total_orders?: number;
        total_revenue?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/client_overview/table/vendors',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns contract information for a specific client
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosContract({
        posId,
    }: {
        /**
         * Point of sale ID
         */
        posId: number,
    }): CancelablePromise<{
        duration?: number;
        expected_sale?: number;
        investment?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/contract',
            query: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns the number of active clients
     * @returns VariationInfoSchema call successful
     * @throws ApiError
     */
    public static getApiPosCount({
        vendorId,
        state,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
        /**
         * status of clients to consider (active, inactive, old).
         */
        state?: string,
    }): CancelablePromise<VariationInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/count',
            query: {
                'vendor_id': vendorId,
                'state': state,
            },
        });
    }
    /**
     * Returns a lsit of points of sale by department
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiPosDepartment({
        departmentId,
    }: {
        /**
         * Department ID
         */
        departmentId: number,
    }): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/department',
            query: {
                'department_id': departmentId,
            },
        });
    }
    /**
     * Returns the number of active clients
     * @returns VariationInfoSchema call successful
     * @throws ApiError
     */
    public static getApiPosInactive({
        vendorId,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
    }): CancelablePromise<VariationInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/inactive',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns list of locations
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosLocations(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/locations',
        });
    }
    /**
     * Returns the number of new clients in the last month
     * @returns VariationInfoSchema call successful
     * @throws ApiError
     */
    public static getApiPosNew({
        vendorId,
    }: {
        /**
         * List of vendor ids.
         */
        vendorId?: Array<number>,
    }): CancelablePromise<VariationInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/new',
            query: {
                'vendor_id': vendorId,
            },
        });
    }
    /**
     * Returns market_share and density information for a specific client
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosPosGeoInfo({
        posId,
    }: {
        /**
         * Client ID
         */
        posId: number,
    }): CancelablePromise<{
        close_comp?: number;
        close_own?: number;
        close_poi?: number;
        market_density?: number;
        market_share?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/pos_geo_info',
            query: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns a dict with client's information about sales - Total Revenue, Last Order, Average Order, Total orders.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosSalesInfo({
        posId,
    }: {
        /**
         * Client ID.
         */
        posId: number,
    }): CancelablePromise<{
        average_order?: number;
        client_since?: string;
        last_order?: string;
        total_orders?: number;
        total_revenue?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/sales_info',
            query: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns pos info
     * @returns PointOfSaleSchema call successful
     * @throws ApiError
     */
    public static getApiPos1({
        posId,
    }: {
        /**
         * id of pos
         */
        posId: number,
    }): CancelablePromise<PointOfSaleSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/{pos_id}',
            path: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns google photo of place
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosPhoto({
        posId,
    }: {
        /**
         * pos identifier.
         */
        posId: number,
    }): CancelablePromise<{
        photo_url?: string;
        pos_id?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/{pos_id}/photo',
            path: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns most recent PoS risk and series
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPosRisk({
        posId,
    }: {
        /**
         * client identifier.
         */
        posId: number,
    }): CancelablePromise<{
        pos_id?: number;
        risk?: number;
        risk_series?: Record<string, number>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/{pos_id}/risk',
            path: {
                'pos_id': posId,
            },
        });
    }
    /**
     * Returns pos sentiment info
     * @returns ClientSentimentInfoSchema call successful
     * @throws ApiError
     */
    public static getApiPosSentiment({
        posId,
    }: {
        /**
         * id of pos
         */
        posId: number,
    }): CancelablePromise<ClientSentimentInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pos/{pos_id}/sentiment',
            path: {
                'pos_id': posId,
            },
        });
    }
}
