import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TableCounter } from '../table-counter/table-counter'
import styles from './table-config-row.module.scss'
import { usePaginationInput } from '../../../hooks/usePaginationInput'
import { useTextInput } from '../../../hooks/useTextInput'
import { PaginationInput } from '../../inputs/pagination-input/pagination-input'
import { TypeInput } from '../../inputs/type-input/type-input'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const TableConfigRow = React.memo(
    ({ count, onSelectPaginationLimit, onSetSearch }) => {
        const { t } = useCustomTranslation()
        const { value: search, setValue: searchSetValue } = useTextInput({
            name: 'search',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        })

        const {
            value: paginationLimit,
            toggleDropdown: paginationLimitToggleDropdown,
            selectOption: paginationLimitSelectOption,
            keyPress: paginationLimitKeyPress,
            keyDown: paginationLimitKeyDown,
        } = usePaginationInput({
            name: 'paginationLimit',
            showOptions: true,
            options: ['5', '10', '15'],
            optionsAux: [],
            selectedOption: '10',
            selectedOptionAux: '',
            state: 'normal',
            value: '10',
            focused: -1,
            reference: useRef(),
        })

        const handleSetSearch = (e) => {
            searchSetValue(e)
            onSetSearch(e.target.value)
        }

        const handleSelectPaginationLimit = (option) => {
            paginationLimitSelectOption(option)
            onSelectPaginationLimit(option)
        }

        return (
            <div className={`${styles['table-config-row-container']}`}>
                <div className="row mx-0 w-100 h-100 d-flex justify-content-between align-items-center">
                    <div className="col px-0">
                        <div className="row mx-0 w-100">
                            <div className="col-auto px-0 pe-2">
                                <TableCounter count={count} />
                            </div>
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['table-config-row-container__pagination-label']}`}
                                >
                                    {t('components:tableConfigRow.show')}
                                </span>
                                <PaginationInput
                                    onSelectInput={handleSelectPaginationLimit}
                                    onKeyPress={paginationLimitKeyPress}
                                    onKeyDown={paginationLimitKeyDown}
                                    onToggleDropdown={
                                        paginationLimitToggleDropdown
                                    }
                                    {...paginationLimit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 px-0">
                        <TypeInput
                            onChangeInput={handleSetSearch}
                            {...search}
                        />
                    </div>
                </div>
            </div>
        )
    }
)
