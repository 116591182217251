import React from 'react'
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.css'
import { ReactComponent as IcoCheckboxSelected } from './assets/ico-checkbox-selected.svg'
import { ReactComponent as IcoCheckboxBlockedSelected } from './assets/ico-checkbox-blocked-selected.svg'
import { ReactComponent as IcoCheckboxSelectedMultiple } from './assets/ico-checkbox-selected-multiple.svg'
import styles from './checkbox-input.module.scss'

export const CheckboxInput = React.memo(({ modalAttr, attr, onClickCheckboxOption, options, selectedOptions, name }) => {
    return (
        <div className={`${styles['checkbox-input-container']}`}>
            {options.map((el, index) => {
                let iconStyle
                if (el.disabled) {
                    iconStyle = 'disabled'
                } else if (el.blocked) {
                    iconStyle = 'blocked'
                } else if (selectedOptions.includes(el.name)) {
                    iconStyle = 'selected'
                } else {
                    iconStyle = 'default'
                }
                return (
                    <div
                        key={index}
                        className={
                            options.length > 1
                                ? `row mx-0 ${styles['checkbox-input-container__option']} mb-2`
                                : `row mx-0 ${styles['checkbox-input-container__option']}`
                        }
                    >
                        <div
                            className={`${styles['checkbox-input-container__option__content']}`}
                            onClick={() => onClickCheckboxOption(modalAttr, attr, el.name, el.label, el.color, name)}
                        >
                            <div className="col-auto px-0 me-3 d-flex justify-content-center align-items-center">
                                {iconStyle === 'blocked' && <IcoCheckboxBlockedSelected />}
                                {iconStyle === 'selected' && selectedOptions.length === 1 && (
                                    <IcoCheckboxSelected className={`${styles['checkbox-input-container__option__icon-alt']}`} />
                                )}
                                {iconStyle === 'selected' && selectedOptions.length > 1 && (
                                    <IcoCheckboxSelectedMultiple className={`${styles['checkbox-input-container__option__icon-alt']}`} />
                                )}
                                {iconStyle !== 'selected' && iconStyle !== 'blocked' && (
                                    <div
                                        className={`${styles['checkbox-input-container__option__icon']} ${
                                            styles[`checkbox-input-container__option__icon--${iconStyle}`]
                                        }`}
                                    ></div>
                                )}
                            </div>
                            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                {el.color && (
                                    <div className={`${styles['checkbox-input-container__option__color']}`} style={{ borderColor: el.color }}></div>
                                )}
                                <span className={`${styles['checkbox-input-container__option__text']}`}>{el.label}</span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
})

CheckboxInput.propTypes = {
    /**
     * String to choose from state properties
     */
    attr: PropTypes.string,
    /**
     * Array of objects
     * Each object contains the following attributes:
     * name: String linked with attribute
     * label: String to display
     * disabled: Bool to disable option
     * blocked: Bool to block selected option
     */
    options: PropTypes.array.isRequired,
    /**
     * String to choose selected option from 'options' array
     */
    selectedOptions: PropTypes.array.isRequired,
    /**
     * Function to select new option
     */
    onClickCheckboxOption: PropTypes.func.isRequired,
}
