import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './scheduled-jobs.module.scss';
import { ScheduledJobsList } from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

const AdministrationScheduledJobs = React.memo(() => {
    const { t } = useCustomTranslation();
    return (
        <div className={`${styles['scheduled-jobs-container']}`}>
            <div className="col px-0 h-100">
                <div className="row w-100 dashboard-header-row d-flex justify-content-between align-items-center">
                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                        <span className="me-2">
                            {t('pages:administration.scheduled-jobs.title')}
                        </span>
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100 ${styles['scheduled-jobs-container__table-container']}`}
                    style={{ height: '45rem' }}
                >
                    <div className="col px-0">
                        <ScheduledJobsList />
                    </div>
                </div>
            </div>
        </div>
    );
});

AdministrationScheduledJobs.propTypes = {};

export default AdministrationScheduledJobs;
