import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import _ from 'lodash'

export const GoalProgressTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    const value = props.payload && props.id !== '' && props.payload.length > 0 ? _.cloneDeep(props.payload[props.id]) : null
    if (value && props.label) {
        return (
            <TooltipContainer color={value.payload['goalProgress'] - value.payload['reference'] < 0 ? '#ef9e9c' : '#6cd3c3'}>
                <TooltipHeaderRow title={t('tooltips:header.goalProgressOverview')} value={props.label} />
                <Separator />
                <TooltipContentRow
                    title={
                        value.payload['goalProgress'] - value.payload['reference'] < 0
                            ? t('tooltips:content.behindSchedule')
                            : t('tooltips:content.onSchedule')
                    }
                    value={Math.abs(value.payload['goalProgress']) + '%'}
                    color={value.payload['goalProgress'] - value.payload['reference'] < 0 ? '#ef9e9c' : '#6cd3c3'}
                />
            </TooltipContainer>
        )
    }
    return null
})
