import React from 'react'
import { SegmentComparison } from './components/segment-comparison-tooltip/segment-comparison-tooltip'
import { ClientTooltip } from './components/client-tooltip/client-tooltip'
import { CompetitorTooltip } from './components/competitor-tooltip/competitor-tooltip'
import { BillingTooltip } from './components/billing-tooltip/billing-tooltip'
import { ProductCategorySalesTooltip } from './components/product-category-sales-tooltip/product-category-sales-tooltip'
import { ProductRegionSalesTooltip } from './components/product-region-sales-tooltip/product-region-sales-tooltip'
import { LoginsHistoryTooltip } from './components/logins-history-tooltip/logins-history-tooltip'
import { SalesHistoryTooltip } from './components/sales-history-tooltip/sales-history-tooltip'
import { TemperatureTooltip } from './components/temperature-tooltip/temperature-tooltip'
import { PrecipitationTooltip } from './components/precipitation-tooltip/precipitation-tooltip'
import { HumidityTooltip } from './components/humidity-tooltip/humidity-tooltip'
import { SegmentRiskTooltip } from './components/segment-risk-tooltip/segment-risk-tooltip'
import { KeywordSentimentTooltip } from './components/keyword-sentiment-tooltip/keyword-sentiment-tooltip'
import { LostClientTooltip } from './components/lost-client-tooltip/lost-client-tooltip'
import { NameTooltip } from './components/name-tooltip /name-tooltip'
import { PriceTooltip } from './components/price-tooltip/price-tooltip'
import { ClientRiskTooltip } from './components/client-risk-tooltip/client-risk-tooltip'
import { SalesForecastTooltip } from './components/sales-forecast-tooltip/sales-forecast-tooltip'
import { SalesVsStocksTooltip } from './components/sales-vs-stocks-tooltip/sales-vs-stocks-tooltip'
import { ProductSalesTooltip } from './components/product-sales-tooltip/product-sales-tooltip'
import { ProductStockTooltip } from './components/product-stock-tooltip/product-stock-tooltip'
import { SentimentTooltip } from './components/sentiment-tooltip/sentiment-tooltip'
import { WordTooltip } from './components/word-tooltip/word-tooltip'
import { TeamSalesVsAllTooltip } from './components/team-sales-vs-all-tooltip/team-sales-vs-all-tooltip'
import { SalesByProduct } from './components/sales-by-product/sales-by-product'
import { SalesHomologueTooltip } from './components/sales-homologue-tooltip/sales-homologue-tooltip'
import { TeamSalesComparisonTooltip } from './components/team-sales-comparison-tooltip/team-sales-comparison-tooltip'
import { BubbleTooltip } from './components/bubble-tooltip/bubble-tooltip'
import { SalesByProducer } from './components/sales-by-producer/sales-by-product'
import { ProductAtc3MarketShareTooltip } from './components/product-atc3-market-share-tooltip/product-atc3-market-share-tooltip'
import { ProductAtc4MarketShareTooltip } from './components/product-atc4-market-share-tooltip/product-atc4-market-share-tooltip'
import { ProductMarketShareForecastTooltip } from './components/product-market-share-forecast-tooltip/product-market-share-forecast-tooltip'
import { RegionGeoSalesTooltip } from './components/region-geo-sales-tooltip/region-geo-sales-tooltip'
import { RegionGeoMarketShareTooltip } from './components/region-geo-market-share-tooltip/region-geo-market-share-tooltip'
import { RegionGeoMarketIndexTooltip } from './components/region-geo-market-index-tooltip/region-geo-market-index-tooltip'
import { MarketShareGlobal } from './components/market-share-global-tooltip/market-share-global-tooltip'
import { EvolByProductForecastTooltip } from './components/evol-by-product-forecast-tooltip/evol-by-product-forecast-tooltip'
import { ActivityHistoryTooltip } from './components/activity-history-tooltip/activity-history-tooltip'
import { HospitalAccountInformationTooltip } from './components/hospital-account-information-tooltip/hospital-account-information-tooltip'
import { DynamicInfoTimeseriesTooltip } from './components/dynamic-info-timeseries-tooltip/dynamic-info-timeseries-tooltip'
import { SalesForecastUnitsTooltip } from './components/sales-forecast-units-tooltip/sales-forecast-units-tooltip'
import { SalesForecastRevenueTooltip } from './components/sales-forecast-revenue-tooltip/sales-forecast-revenue-tooltip'
import { HighImpactGoalsTooltip } from './components/high-impact-goals-tooltip/high-impact-goals-tooltip'
import { GoalProgressTooltip } from './components/goal-progress-tooltip/goal-progress-tooltip'
import { GoalPerformanceOverTimeTooltip } from './components/goal-performance-over-time-tooltip/goal-performance-over-time-tooltip'
import { SubGoalProgressTooltip } from './components/sub-goal-progress-tooltip/sub-goal-progress-tooltip'
import { GoalContributionTooltip } from './components/goal-contribution-tooltip/goal-contribution-tooltip'
import { SubGoalPartitionTooltip } from './components/sub-goal-partition-tooltip/sub-goal-partition-tooltip'
import { GoalTimelineTooltip } from './components/goal-timeline-tooltip/goal-timeline-tooltip'
import { ProductMarketShareUnitsForecastTooltip } from './components/product-market-share-units-forecast-tooltip/product-market-share-units-forecast-tooltip'
import { SegmentRFMTooltip } from './components/segment-rfm-tooltip/segment-rfm-tooltip'
import { MostSoldProductsTooltip } from './components/most-sold-products-tooltip/most-sold-products-tooltip'
import { MostUsedChannelsTooltip } from './components/most-used-channels-tooltip/most-used-channels-tooltip'
import { SegmentUsersIncludedTooltip } from './components/segment-users-included-tooltip/segment-users-included-tooltip'
import { SegmentRevenueOverTimeTooltip } from './components/segment-revenue-over-time-tooltip/segment-revenue-over-time-tooltip'
import { SegmentUnitsSoldOverTimeTooltip } from './components/segment-units-sold-over-time-tooltip/segment-units-sold-over-time-tooltip'
import { MaritalStatusTooltip } from './components/marital-status-tooltip-tooltip/marital-status-tooltip-tooltip'

export const TooltipFactory = React.memo((props) => {
    switch (props.item.type) {
        case 'segmentUnitsSoldOverTime':
            return <SegmentUnitsSoldOverTimeTooltip {...props} />
        case 'segmentRevenueOverTime':
            return <SegmentRevenueOverTimeTooltip {...props} />
        case 'segmentUsersIncluded':
            return <SegmentUsersIncludedTooltip {...props} />
        case 'maritalStatus':
            return <MaritalStatusTooltip {...props} />
        case 'mostUsedChannels':
            return <MostUsedChannelsTooltip {...props} />
        case 'mostSoldProducts':
            return <MostSoldProductsTooltip {...props} />
        case 'segmentRFM':
            return <SegmentRFMTooltip {...props} />
        case 'goalTimeline':
            return <GoalTimelineTooltip {...props} />
        case 'subGoalPartition':
            return <SubGoalPartitionTooltip {...props} />
        case 'goalContribution':
            return <GoalContributionTooltip {...props} />
        case 'goalPerformanceOverTimeChart':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else break
                if (propsId == null) break
                return <GoalPerformanceOverTimeTooltip {...props} {...props.item} />
            } else break
        case 'goalProgress':
            return <GoalProgressTooltip {...props} {...props.item} />
        case 'highImpactGoals':
            return <HighImpactGoalsTooltip {...props} {...props.item} />
        case 'dynamincInfoTimeseries':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else break
                if (propsId == null) break
                return <DynamicInfoTimeseriesTooltip {...props} {...props.item} />
            } else break
        case 'evolByProductForecast':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else return null
                if (propsId == null) return null
                return <EvolByProductForecastTooltip {...props} {...props.item} />
            }
            return null
        case 'regionGeoMarketIndex':
            return <RegionGeoMarketIndexTooltip {...props} {...props.item} />
        case 'regionGeoMarketShare':
            return <RegionGeoMarketShareTooltip {...props} {...props.item} />
        case 'regionGeoSales':
            return <RegionGeoSalesTooltip {...props} {...props.item} />
        case 'productMarketShareForecast':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                (props.item.cx === props.coordinate.x || props.item.hoveredType === 'bar')
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else return null
                if (propsId == null) return null
                return <ProductMarketShareForecastTooltip {...props} {...props.item} />
            }
            return null
        case 'productMarketShareUnitsForecast':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                (props.item.cx === props.coordinate.x || props.item.hoveredType === 'bar')
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else return null
                if (propsId == null) return null
                return <ProductMarketShareUnitsForecastTooltip {...props} {...props.item} />
            }
            return null
        case 'productAtc3DonutCard':
            return <ProductAtc3MarketShareTooltip {...props} {...props.item} />
        case 'productAtc4DonutCard':
            return <ProductAtc4MarketShareTooltip {...props} {...props.item} />
        case 'marketShareGlobalDonut':
            return <MarketShareGlobal {...props} {...props.item} />
        case 'producerDonutSales':
            return <SalesByProducer {...props} {...props.item} />

        case 'teamSalesComparisonChart':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else return null
                if (propsId == null) return null
                return <TeamSalesComparisonTooltip {...props} {...props.item} />
            }
            return null
        case 'salesHomologue':
            return <SalesHomologueTooltip {...props} {...props.item} />
        case 'salesByProductCard':
            return <SalesByProduct {...props} {...props.item} />
        case 'teamSalesVsAllCard':
            return <TeamSalesVsAllTooltip {...props.item} />
        case 'subGoalProgress':
            return <SubGoalProgressTooltip {...props} />
        case 'word':
            return <WordTooltip {...props.item} />
        case 'segmentComparison':
            return <SegmentComparison {...props} {...props.item} />
        case 'bubbleGeo':
            return <BubbleTooltip {...props} {...props.item} />
        case 'clientGeo':
            return <ClientTooltip {...props} {...props.item} />
        case 'competitorGeo':
            return <CompetitorTooltip {...props} {...props.item} />
        case 'billingHistory':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else return null
                if (propsId == null) return null
                return <BillingTooltip {...props} {...props.item} />
            } else break

        case 'productCategorySales':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <ProductCategorySalesTooltip {...props} {...props.item} />
            else break
        case 'productRegionSales':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <ProductRegionSalesTooltip {...props} {...props.item} />
            else break
        case 'loginsHistory':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else break
                if (propsId == null) break
                return <LoginsHistoryTooltip {...props} {...props.item} />
            } else break
        case 'activityHistory':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else break
                if (propsId == null) break
                return <ActivityHistoryTooltip {...props} {...props.item} />
            } else break
        case 'salesHistory':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else return null
                if (propsId == null) return null
                return <SalesHistoryTooltip {...props} {...props.item} />
            } else if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx !== props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else break
                if (propsId == null) break
                else if (props.payload[propsId].name === 'avgT') {
                    return <TemperatureTooltip {...props} {...props.item} />
                } else if (props.payload[propsId].name === 'avgP') {
                    return <PrecipitationTooltip {...props} {...props.item} />
                } else if (props.payload[propsId].name === 'avgH') {
                    return <HumidityTooltip {...props} {...props.item} />
                } else break
            } else break

        case 'humidity':
            if (
                props.item.id !== '' &&
                props.payload != null &&
                props.payload[props.item.id] != null &&
                props.payload[props.item.id].dataKey === props.item.dataKey
            )
                return <HumidityTooltip {...props} {...props.item} />
            else break
        case 'segmentRisk':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <SegmentRiskTooltip {...props} {...props.item} />
            else break
        case 'keywordSentiment':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <KeywordSentimentTooltip {...props} {...props.item} />
            else break
        case 'lostClient':
            return <LostClientTooltip {...props} {...props.item} />
        case 'name':
            return <NameTooltip {...props} {...props.item} />
        case 'precipitation':
            if (
                props.item.id !== '' &&
                props.payload != null &&
                props.payload[props.item.id] != null &&
                props.payload[props.item.id].dataKey === props.item.dataKey
            )
                return <PrecipitationTooltip {...props} {...props.item} />
            else break
        case 'price':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <PriceTooltip {...props} {...props.item} />
            else break
        case 'clientRisk':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <ClientRiskTooltip {...props} {...props.item} />
            else break
        case 'salesForecastRevenue':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx === props.coordinate.x
            )
                return <SalesForecastRevenueTooltip {...props} {...props.item} />
            // else if (
            //     props.item.animationEnded &&
            //     props.item.id !== '' &&
            //     props.payload != null &&
            //     props.payload.length !== 0 &&
            //     props.item.cx !== props.coordinate.x
            // ) {
            //     let propsId;
            //     if (props.payload.length > 1) {
            //         props.payload.forEach((value, index) => {
            //             if (value.dataKey === props.item.dataKey) {
            //                 propsId = index;
            //             }
            //         });
            //     } else if (props.payload.length === 1) {
            //         if (props.payload[0].dataKey === props.item.dataKey) {
            //             propsId = 0;
            //         }
            //     } else break;
            //     if (propsId == null) break;
            //     else if (props.payload[propsId].name === 'avgT') {
            //         return <TemperatureTooltip {...props} {...props.item} />;
            //     } else if (props.payload[propsId].name === 'avgP') {
            //         return <PrecipitationTooltip {...props} {...props.item} />;
            //     } else if (props.payload[propsId].name === 'avgH') {
            //         return <HumidityTooltip {...props} {...props.item} />;
            //     } else break;
            // }
            else break
        case 'salesForecastUnits':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                (props.item.cx === props.coordinate.x || props.item.hoveredType === 'bar')
            )
                return <SalesForecastUnitsTooltip {...props} {...props.item} />
            // else if (
            //     props.item.animationEnded &&
            //     props.item.id !== '' &&
            //     props.payload != null &&
            //     props.payload.length !== 0 &&
            //     props.item.cx !== props.coordinate.x
            // ) {
            //     let propsId;
            //     if (props.payload.length > 1) {
            //         props.payload.forEach((value, index) => {
            //             if (value.dataKey === props.item.dataKey) {
            //                 propsId = index;
            //             }
            //         });
            //     } else if (props.payload.length === 1) {
            //         if (props.payload[0].dataKey === props.item.dataKey) {
            //             propsId = 0;
            //         }
            //     } else break;
            //     if (propsId == null) break;
            //     else if (props.payload[propsId].name === 'avgT') {
            //         return <TemperatureTooltip {...props} {...props.item} />;
            //     } else if (props.payload[propsId].name === 'avgP') {
            //         return <PrecipitationTooltip {...props} {...props.item} />;
            //     } else if (props.payload[propsId].name === 'avgH') {
            //         return <HumidityTooltip {...props} {...props.item} />;
            //     } else break;
            // }
            else break
        case 'salesForecast':
            if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                (props.item.cx === props.coordinate.x || props.item.hoveredType === 'bar')
            )
                return <SalesForecastTooltip {...props} {...props.item} />
            else if (
                props.item.animationEnded &&
                props.item.id !== '' &&
                props.payload != null &&
                props.payload.length !== 0 &&
                props.item.cx !== props.coordinate.x
            ) {
                let propsId
                if (props.payload.length > 1) {
                    props.payload.forEach((value, index) => {
                        if (value.dataKey === props.item.dataKey) {
                            propsId = index
                        }
                    })
                } else if (props.payload.length === 1) {
                    if (props.payload[0].dataKey === props.item.dataKey) {
                        propsId = 0
                    }
                } else break
                if (propsId == null) break
                else if (props.payload[propsId].name === 'avgT') {
                    return <TemperatureTooltip {...props} {...props.item} />
                } else if (props.payload[propsId].name === 'avgP') {
                    return <PrecipitationTooltip {...props} {...props.item} />
                } else if (props.payload[propsId].name === 'avgH') {
                    return <HumidityTooltip {...props} {...props.item} />
                } else break
            } else break
        case 'salesVsStocks':
            if (props.payload && props.payload.length !== 0) return <SalesVsStocksTooltip {...props} {...props.item} />
            else break
        case 'productSales':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <ProductSalesTooltip {...props} {...props.item} />
            else break
        case 'productStock':
            if (props.payload && props.payload.length !== 0) return <ProductStockTooltip {...props} {...props.item} />
            else break
        case 'sentimentHistory':
            if (props.item.id !== '' && props.payload && props.payload.length !== 0) return <SentimentTooltip {...props} {...props.item} />
            else break
        case 'temperature':
            if (
                props.item.id !== '' &&
                props.payload != null &&
                props.payload[props.item.id] != null &&
                props.payload[props.item.id].dataKey === props.item.dataKey
            )
                return <TemperatureTooltip {...props} {...props.item} />
            else break
        case 'hospitalAccountInformation':
            return <HospitalAccountInformationTooltip {...props} {...props.item} />
    }
    return null
})
