import { createContext, useContext } from 'react'

const HoveredComponentsAndFiltersContext = createContext({
    hoveredFilter: '',
    hoveredComponents: [],
    setHoveredFilter: () => {},
    setHoveredComponents: () => {},
})

export function HoveredComponentsAndFiltersProvider({ value, children }) {
    return (
        <HoveredComponentsAndFiltersContext.Provider value={value}>
            {children}
        </HoveredComponentsAndFiltersContext.Provider>
    )
}

export function useHoveredComponentsAndFilters() {
    return useContext(HoveredComponentsAndFiltersContext)
}
