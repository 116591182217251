import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './categorical-segment-filter-tag.module.scss'
import { FilterTag } from '../../../filter-tag/filter-tag'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const CategoricalSegmentFilterTag = React.memo(
    ({ name, value, index }) => {
        const { t } = useCustomTranslation()
        return (
            <FilterTag index={index}>
                <span
                    className={`${styles['categorical-segment-filter-tag-container__title']}`}
                >
                    {t(`layout:auxbar.filters.${name}`)}
                </span>
                <span
                    className={`${styles['categorical-segment-filter-tag-container__value']}`}
                >
                    {value}
                </span>
            </FilterTag>
        )
    }
)
