import React from 'react';

export const segmentClientsToAddListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'id', label: '', sortable: true },
        { path: 'name', label: '', sortable: true },
        { path: 'risk', label: '', sortable: true },
        { path: 'revenue', label: '', sortable: true, isNumber: true },
        { path: 'sentiment', label: '', sortable: true },
        { path: 'add', label: '', sortable: false }
    ],
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'segmentClientsToAddTable',
    disabled: true,
    loading: true,
    search: '',
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: []
        },
        view: {
            options: []
        },
        options: {
            label: '',
            options: []
        },
        reference: React.createRef()
    }
};
