export const goalContributionMock = {
    attr: 'goalContribution',
    barAttr: [
        {
            dataKey: 'contribution',
            color: '#2CD9C5'
        }
    ],
    tooltipType: 'goalContribution',
    categoryAxis: 'name',
    data: [
        {
            name: 'Team 5',
            contribution: 60
        },
        {
            name: 'Team 10',
            contribution: 55
        },
        {
            name: 'Team 3',
            contribution: 50
        },
        {
            name: 'Team 9',
            contribution: 45
        },
        {
            name: 'Team 4',
            contribution: 40
        },
        {
            name: 'Team 8',
            contribution: 35
        },
        {
            name: 'Team 2',
            contribution: 30
        },
        {
            name: 'Team 7',
            contribution: 25
        },
        {
            name: 'Team 1',
            contribution: 20
        },
        {
            name: 'Team 6',
            contribution: 10
        }
    ]
};
