export const toIsoDate = (date) => {
    const parts = date.split('/');
    const year = parts[2];
    const month = parts[1].padStart(2, '0');
    const day = parts[0].padStart(2, '0');
    const isoDate = `${year}-${month}-${day}`;
    return isoDate;
};

export const toIsoDateFromDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const isoDateTime = `${year}-${month}-${day}`;
    return isoDateTime;
};

export const toIsoDateTimeFromDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const isoDateTime = `${year}-${month}-${day}T${hours}:${minutes}:00`;
    return isoDateTime;
};

export const fromIsoDateTimeToDay = (isoDateTime) => {
    const parts = isoDateTime.split('T');
    const date = fromIsoDate(parts[0]);
    const dayOfDate = date.split('/')[0];
    return dayOfDate;
};

export const fromIsoDateTimeToMonth = (isoDateTime) => {
    const parts = isoDateTime.split('T');
    const date = fromIsoDate(parts[0]);
    const monthOfDate = date.split('/')[1];
    return monthOfDate;
};

export const fromIsoDateTimeToYear = (isoDateTime) => {
    const parts = isoDateTime.split('T');
    const date = fromIsoDate(parts[0]);
    const yearOfDate = date.split('/')[2];
    return yearOfDate;
};

export const fromIsoDateToDay = (isoDate) => {
    const date = fromIsoDate(isoDate);
    const dayOfDate = date.split('/')[0];
    return dayOfDate;
};

export const fromIsoDateToMonth = (isoDate) => {
    const date = fromIsoDate(isoDate);
    const monthOfDate = date.split('/')[1];
    return monthOfDate;
};

export const fromIsoDateToYear = (isoDate) => {
    const date = fromIsoDate(isoDate);
    const yearOfDate = date.split('/')[2];
    return yearOfDate;
};

export const fromIsoDateTimeToDate = (isoDateTime) => {
    const parts = isoDateTime.split('T');
    const date = fromIsoDate(parts[0]);
    const dateTime = `${date}`;
    return dateTime;
};

export const fromIsoDateTimeToTime = (isoDateTime) => {
    const parts = isoDateTime.split('T');
    const time = fromIsoTime(parts[1]);
    const dateTime = `${time}`;
    return dateTime;
};

export const fromIsoDateTime = (isoDateTime) => {
    const parts = isoDateTime.split('T');
    const date = fromIsoDate(parts[0]);
    const time = fromIsoTime(parts[1]);
    const dateTime = `${date} ${time}`;
    return dateTime;
};

export const fromIsoTime = (isoTime) => {
    const parts = isoTime.split(':');
    const hours = parts[0];
    const minutes = parts[1];
    const time = `${hours}:${minutes}`;
    return time;
};

export const fromIsoDate = (isoDate) => {
    const parts = isoDate.split('-');
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const date = `${day}/${month}/${year}`;
    return date;
};

export const toIsoTime = (time) => {
    const parts = time.split(':');
    const hours = parts[0];
    const minutes = parts[1];
    const isoTime = `${hours}:${minutes}:00`;
    return isoTime;
};

export const toIsoDateTime = (date, time) => {
    const isoDateTime = `${date}T${time}`;
    return isoDateTime;
};
