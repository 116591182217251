import React from 'react'

export const teamMembersListMock = {
    titleCounter: '',
    sortedData: [],
    data: [],
    columns: [
        { path: 'name', label: '', sortable: true },
        { path: 'isResponsible', label: '', sortable: true },
        { path: 'isPlatformUser', label: '', sortable: false },
        { path: 'action', label: 'ACTION', sortable: false },
    ],
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'teamMembersTable',
    paginationDisabled: false,
    disabled: true,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            options: [],
        },
        reference: React.createRef(),
    },
}
