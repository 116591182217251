import i18n, { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { getFromLS } from '../utils/localStorage'

const fallbackLng = 'en'
const availableLanguages = ['en', 'pt']

use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        lng: getFromLS('language') || 'en',
        backend: {
            /* translation file path */
            loadPath: '/assets/translations/{{ns}}/{{lng}}.json',
        },
        fallbackLng: fallbackLng,
        debug: false,
        availableLanguages: availableLanguages,
        whitelist: availableLanguages,
        detection: {
            order: ['queryString', 'cache'],
            cache: ['cookies'],
        },
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: [
            'breadcrumbs',
            'common',
            'layout',
            'legend',
            'pages',
            'components',
            'modals',
            'notifications',
            'buttons',
            'inputs',
            'errors',
            'columns',
            'settings',
            'tooltips',
            'months',
            'keywords',
            'headers',
        ],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: undefined,
            useSuspense: true,
        },
    })

export { i18n }
