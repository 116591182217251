import React, { useMemo } from 'react'
import styles from './goal-history-timeline.module.scss'
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper'
import { Spinner } from '../../loading/spinner/spinner'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { CustomTimeline } from '../../charts/custom-timeline/custom-timeline'
import Scrollable from '../../scrolling/scrollable'
import { TimeIntervalProvider, useTimeInterval } from '../../../providers/time-interval-context'
import { months } from '../../../utils/date'

const goalHistoryTimelineMock = {
    attr: 'goalHistoryTimeline',
    historyCards: {
        2023: [
            {
                month: 1,
                quarter: 1,
                semester: 1,
                activeGoals: 5,
                completedOnTrackGoals: 3,
                completedBehindScheduleGoals: 1,
                startedGoals: 2,
            },
            {
                month: 2,
                quarter: 1,
                semester: 1,
                activeGoals: 4,
                completedOnTrackGoals: 2,
                completedBehindScheduleGoals: 1,
                startedGoals: 1,
            },
            {
                month: 3,
                quarter: 1,
                semester: 1,
                activeGoals: 6,
                completedOnTrackGoals: 4,
                completedBehindScheduleGoals: 2,
                startedGoals: 2,
            },
            {
                month: 4,
                quarter: 2,
                semester: 1,
                activeGoals: 5,
                completedOnTrackGoals: 3,
                completedBehindScheduleGoals: 1,
                startedGoals: 2,
            },
            {
                month: 5,
                quarter: 2,
                semester: 1,
                activeGoals: 4,
                completedOnTrackGoals: 2,
                completedBehindScheduleGoals: 2,
                startedGoals: 1,
            },
            {
                month: 6,
                quarter: 2,
                semester: 1,
                activeGoals: 6,
                completedOnTrackGoals: 4,
                completedBehindScheduleGoals: 1,
                startedGoals: 3,
            },
            {
                month: 7,
                quarter: 3,
                semester: 2,
                activeGoals: 7,
                completedOnTrackGoals: 5,
                completedBehindScheduleGoals: 2,
                startedGoals: 4,
            },
            {
                month: 8,
                quarter: 3,
                semester: 2,
                activeGoals: 6,
                completedOnTrackGoals: 4,
                completedBehindScheduleGoals: 1,
                startedGoals: 3,
            },
            {
                month: 9,
                quarter: 3,
                semester: 2,
                activeGoals: 8,
                completedOnTrackGoals: 6,
                completedBehindScheduleGoals: 2,
                startedGoals: 4,
            },
            {
                month: 10,
                quarter: 4,
                semester: 2,
                activeGoals: 7,
                completedOnTrackGoals: 5,
                completedBehindScheduleGoals: 1,
                startedGoals: 3,
            },
            {
                month: 11,
                quarter: 4,
                semester: 2,
                activeGoals: 6,
                completedOnTrackGoals: 4,
                completedBehindScheduleGoals: 1,
                startedGoals: 2,
            },
            {
                month: 12,
                quarter: 4,
                semester: 2,
                activeGoals: 9,
                completedOnTrackGoals: 7,
                completedBehindScheduleGoals: 2,
                startedGoals: 4,
            },
        ],
        2024: [
            {
                month: 1,
                quarter: 1,
                semester: 1,
                activeGoals: 1,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 2,
                quarter: 1,
                semester: 1,
                activeGoals: 2,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 3,
                quarter: 1,
                semester: 1,
                activeGoals: 1,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 1,
                startedGoals: 0,
            },
            {
                month: 4,
                quarter: 2,
                semester: 1,
                activeGoals: 1,
                completedOnTrackGoals: 1,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 5,
                quarter: 2,
                semester: 1,
                activeGoals: 1,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 6,
                quarter: 2,
                semester: 1,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 7,
                quarter: 3,
                semester: 2,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 8,
                quarter: 3,
                semester: 2,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 9,
                quarter: 3,
                semester: 2,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 10,
                quarter: 4,
                semester: 2,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 11,
                quarter: 4,
                semester: 2,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
            {
                month: 12,
                quarter: 4,
                semester: 2,
                activeGoals: 0,
                completedOnTrackGoals: 0,
                completedBehindScheduleGoals: 0,
                startedGoals: 0,
            },
        ],
    },
    legend: [
        {
            name: 'onSchedule',
            color: '#2cd9c5',
        },
        {
            name: 'behindSchedule',
            color: '#ef9e9c',
        },
    ],
}

const Content = ({ onSelectGoal, year, yearOptions, setYear, value }) => {
    const { timeInterval } = useTimeInterval()
    // timeInterval can be month, quarter or semester
    const valueGroupedByTimeInterval = useMemo(() => {
        return value.historyCards[year].reduce((acc, card) => {
            const timeIntervalValue = card[timeInterval]
            if (!acc[timeIntervalValue]) {
                acc[timeIntervalValue] = {
                    activeGoals: card.activeGoals,
                    completedOnTrackGoals: card.completedOnTrackGoals,
                    completedBehindScheduleGoals: card.completedBehindScheduleGoals,
                }
            }
            acc[timeIntervalValue].activeGoals += card.activeGoals
            acc[timeIntervalValue].completedOnTrackGoals += card.completedOnTrackGoals
            acc[timeIntervalValue].completedBehindScheduleGoals += card.completedBehindScheduleGoals

            return acc
        }, {})
    }, [value.historyCards, year, timeInterval])

    return (
        <div className="row mx-0 w-100 h-100" style={{ padding: '0 2rem' }}>
            <div
                className="col-auto px-0 h-100"
                style={{
                    width: '15rem',
                }}
            >
                <Scrollable addRightSpacing>
                    {Object.entries(valueGroupedByTimeInterval).map((el, index) => (
                        <div className="row mx-0 w-100" key={index} style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <div className={styles['goal-history-timeline-container__card']}>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <span className={styles['goal-history-timeline-container__card__title']}>
                                                {timeInterval === 'month'
                                                    ? months[el[0]]
                                                    : timeInterval === 'quarter'
                                                    ? `Quarter ${el[0]}`
                                                    : `Semester ${el[0]}`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0 d-flex justify-content-start align-items-center">
                                            <div
                                                className={styles['goal-history-timeline-container__card__circle']}
                                                style={{
                                                    color: '#2c3c8d',
                                                }}
                                            ></div>
                                            <span className={styles['goal-history-timeline-container__card__text']}>{el[1].activeGoals} active</span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0 d-flex justify-content-start align-items-center">
                                            <div
                                                className={styles['goal-history-timeline-container__card__circle']}
                                                style={{
                                                    color: '#86d4c5',
                                                }}
                                            ></div>
                                            <span className={styles['goal-history-timeline-container__card__text']}>
                                                {el[1].completedOnTrackGoals} completed
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0 d-flex justify-content-start align-items-center">
                                            <div
                                                className={styles['goal-history-timeline-container__card__circle']}
                                                style={{
                                                    color: '#e3a29e',
                                                }}
                                            ></div>
                                            <span className={styles['goal-history-timeline-container__card__text']}>
                                                {el[1].completedBehindScheduleGoals} failed
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Scrollable>
            </div>
            <div className="col-auto px-0" style={{ width: '2rem' }}></div>
            <div
                className="col-auto px-0 h-100"
                style={{
                    width: 'calc(100% - 15rem - 2rem)',
                }}
            >
                <CustomTimeline onSelectGoal={onSelectGoal} />
            </div>
        </div>
    )
}

export const GoalHistoryTimeline = ({ onSelectGoal, isPreview, ...rest }) => {
    const { value, setLoading, setValue } = useSimpleComponent(goalHistoryTimelineMock)
    const yearOptions = useMemo(() => {
        return Object.keys(value.historyCards).map((year) => parseInt(year))
    }, [value.historyCards])
    const [year, setYear] = React.useState(2024)
    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <TimeIntervalProvider>
                <ComponentWrapper {...value} {...rest} withGeneralTimeFilter noMargin>
                    {value.loading && <Spinner />}
                    {!value.loading && (
                        <>
                            <Content year={year} yearOptions={yearOptions} setYear={setYear} value={value} onSelectGoal={onSelectGoal} />
                        </>
                    )}
                </ComponentWrapper>
            </TimeIntervalProvider>
        </div>
    )
}
