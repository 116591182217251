import React from 'react'
import { ReactComponent as IcoCheckboxSelected } from '../../assets/images/ico-checkbox-selected.svg'
import { ReactComponent as IcoCheckboxUnselected } from '../../assets/images/ico-checkbox-unselected.svg'
import styles from './select-box-table-column.module.scss'

export const SelectBoxTableColumn = React.memo(
    ({ el, column, attr, onRowSelect }) => {
        return (
            <div className={`${styles['select-box-table-column-container']}`}>
                {el[column.path] === 'selected' && (
                    <IcoCheckboxSelected
                        onClick={
                            onRowSelect
                                ? () => onRowSelect(el, attr)
                                : undefined
                        }
                        style={{ cursor: 'pointer' }}
                    />
                )}

                {el[column.path] !== 'selected' && (
                    <IcoCheckboxUnselected
                        onClick={
                            onRowSelect
                                ? () => onRowSelect(el, attr)
                                : undefined
                        }
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </div>
        )
    }
)
