import React from 'react'
import styles from './BreadCrumbs.module.scss'
import { useNavigate } from 'react-router-dom'
import icoBreadCrumbArrow from '../../../assets/images/icoBreadCrumbArrow.svg'

export const BreadCrumbs = ({ crumbs = [] }) => {
    const navigate = useNavigate()
    return (
        <div className={styles['breadcrumbs-container']}>
            {crumbs.map((crumb, index) => {
                const { url, isTitle, label } = crumb
                return (
                    <div
                        key={index}
                        className={isTitle ? styles['breadcrumbs-container__title'] : styles['breadcrumbs-container__crumb']}
                        onClick={
                            !isTitle && url
                                ? () => {
                                      navigate(url)
                                  }
                                : null
                        }
                    >
                        {label}
                        {index < crumbs.length - 1 && <img src={icoBreadCrumbArrow} alt="arrow" className={styles['breadcrumbs-container__arrow']} />}
                    </div>
                )
            })}
        </div>
    )
}
