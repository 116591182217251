import Joi from 'joi';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTextInput } from '../../../hooks/useTextInput';
import { useUniqueArray } from '../../../hooks/useUniqueArray';
import { useAuxBar } from '../../../providers/aux-bar-context';
import { useSegmentFilters } from '../../../providers/segment-filters-context';
import { PointOfSaleService, SegmentationService } from '../../../temp/test';

import styles from './new-segment.module.scss';
import { SegmentFilters } from '../segment-filters';
import { SegmentClientsToAddList } from '../segment-clients-to-add-list';
import { SegmentComparison } from '../segment-comparison';
import { SegmentClientsAddedList } from '../segment-clients-added-list';
import { SegmentClientsList } from '../segment-clients-list';

import { WarningButton } from '../../buttons/warning-button/warning-button';
import { InfoButton } from '../../buttons/info-button/info-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { getValueInString } from '../../../utils/unit';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { TypeInput } from '../../inputs/type-input/type-input';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
//import { SentimentProportions } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-proportions/sentiment-proportions';
//import { SentimentOverTime } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-over-time/sentiment-over-time';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const NewSegment = React.memo(
    ({ segmentCreationStep, onSelectSegment }) => {
        const isMounted = useIsMounted();
        const { setAvailableOptions, setSelectedAvailableOption } = useAuxBar();
        const [creationMode, setCreationMode] = useState('manual');
        const { t } = useCustomTranslation();
        const { segmentFilters, removeSegmentFilter } = useSegmentFilters();
        const [allClients, setAllClients] = useState([]);
        const {
            array: clientsAdded,
            push: clientsAddedPush,
            remove: clientsAddedRemove
        } = useUniqueArray([]);
        const {
            value: description,
            setValue: setDescriptionValue,
            setError: descriptionSetError
        } = useTextInput({
            name: 'description',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });
        const {
            value: dashboardName,
            setValue: setDashboardNameValue,
            setError: dashboardNameSetError
        } = useTextInput({
            name: 'dashboardName',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const schema = Joi.object({
            dashboardName: Joi.string().required(),
            description: Joi.string()
        });

        const handleSearchAutomatedSegments = async () => {
            try {
                const result = schema.validate(
                    {
                        dashboardName: dashboardName.value,
                        description: description.value
                    },
                    { abortEarly: false }
                );
                if (isMounted.current) {
                    dashboardNameSetError();
                    descriptionSetError();
                }
                if (result.error) {
                    result.error.details.map((el) => {
                        if (el.path[0] === 'dashboardName') {
                            if (isMounted.current)
                                dashboardNameSetError(el.type);
                        } else if (el.path[0] === 'description') {
                            if (isMounted.current) descriptionSetError(el.type);
                        }
                    });
                    return;
                }
                const pointOfSaleGroupSchema = {
                    description: description.value,
                    draft: undefined,
                    name: dashboardName.value,
                    parent_group_id: undefined,
                    type: 'ai_cluster'
                };
                const results = await SegmentationService.postApiSegmentation({
                    requestBody: pointOfSaleGroupSchema
                });
                if (clientsAdded && clientsAdded.length > 0) {
                    await SegmentationService.postApiSegmentationClients({
                        id: results.id,
                        requestBody: { ids: clientsAdded }
                    });
                } else if (segmentFilters && segmentFilters.length > 0) {
                    const previewRules = [];
                    segmentFilters.map((el) => {
                        let pointOfSaleRulesGroupRulesSchema = {
                            entity: el.entity,
                            group_func: el.grouper,
                            operator: el.operator,
                            param: el.name,
                            value: el.value
                        };
                        previewRules.push(pointOfSaleRulesGroupRulesSchema);
                        return el;
                    });
                    const newResults =
                        await SegmentationService.postApiSegmentationRulesPreview(
                            {
                                requestBody: previewRules
                            }
                        );
                    const newClientsAdded = [];
                    newResults.map((el) => {
                        newClientsAdded.push(el.client_id);
                        return el;
                    });
                    await SegmentationService.postApiSegmentationClients({
                        id: results.id,
                        requestBody: { ids: newClientsAdded }
                    });
                }
                await SegmentationService.postApiSegmentationBehaviouralSegments(
                    {
                        requestBody: {
                            clients: [],
                            parent_group_id: results.id
                        }
                    }
                );
                const pointOfSaleGroupSchema2 = {
                    description: description.value,
                    draft: false,
                    name: dashboardName.value,
                    parent_group_id: undefined,
                    type: 'ai_cluster'
                };
                await SegmentationService.putApiSegmentation({
                    id: results.id,
                    requestBody: pointOfSaleGroupSchema2
                });
                onSelectSegment(results);
            } catch (err) {
                errorHandler(err);
            }
        };

        const handleCreateSegment = async () => {
            try {
                const result = schema.validate(
                    {
                        dashboardName: dashboardName.value,
                        description: description.value
                    },
                    { abortEarly: false }
                );
                if (isMounted.current) {
                    dashboardNameSetError();
                    descriptionSetError();
                }
                if (result.error) {
                    result.error.details.map((el) => {
                        if (el.path[0] === 'dashboardName') {
                            if (isMounted.current)
                                dashboardNameSetError(el.type);
                        } else if (el.path[0] === 'description') {
                            if (isMounted.current) descriptionSetError(el.type);
                        }
                    });
                    return;
                }
                const pointOfSaleGroupSchema = {
                    description: description.value,
                    draft: undefined,
                    name: dashboardName.value,
                    parent_group_id: undefined,
                    type: undefined
                };
                let results;
                if (clientsAdded && clientsAdded.length > 0) {
                    pointOfSaleGroupSchema.type = 'pos_group';
                    results = await SegmentationService.postApiSegmentation({
                        requestBody: pointOfSaleGroupSchema
                    });
                    await SegmentationService.postApiSegmentationClients({
                        id: results.id,
                        requestBody: { ids: clientsAdded }
                    });
                } else if (segmentFilters && segmentFilters.length > 0) {
                    pointOfSaleGroupSchema.type = 'rule_group';
                    results = await SegmentationService.postApiSegmentation({
                        requestBody: pointOfSaleGroupSchema
                    });
                    const newRules = [];
                    segmentFilters.map((el) => {
                        let pointOfSaleRulesGroupRulesSchema = {
                            entity: el.entity,
                            group_func: el.grouper,
                            operator: el.operator,
                            param: el.name,
                            value: el.value
                        };
                        newRules.push(pointOfSaleRulesGroupRulesSchema);
                        return el;
                    });
                    await SegmentationService.postApiSegmentationRules({
                        id: results.id,
                        requestBody: newRules
                    });
                }
                const pointOfSaleGroupSchema2 = {
                    description: description.value,
                    draft: false,
                    name: dashboardName.value,
                    parent_group_id: undefined,
                    type: undefined
                };
                if (results) {
                    await SegmentationService.putApiSegmentation({
                        id: results.id,
                        requestBody: pointOfSaleGroupSchema2
                    });
                    onSelectSegment(results);
                }
            } catch (err) {
                errorHandler(err);
            }
        };

        const handleAddClientToSegment = useCallback(
            (id) => {
                clientsAddedPush(id);
            },
            [clientsAddedPush]
        );

        const handleRemoveClientFromSegment = useCallback(
            (index) => {
                clientsAddedRemove(index);
            },
            [clientsAddedRemove]
        );

        useEffect(() => {
            async function fill() {
                let newAllClients = [];
                const dataBack =
                    await PointOfSaleService.getApiPosClientOverviewTableVendors(
                        {
                            vendorId: []
                        }
                    );
                _.forEach(Object.entries(dataBack), (el) => {
                    newAllClients.push({
                        id: parseInt(el[0].toString().split('.')[0]),
                        name: el[1].name ? el[1].name : '-',
                        revenue: getValueInString(
                            el[1].total_revenue,
                            'values'
                        ),
                        risk: el[1].risk ? el[1].risk : '-',
                        sentiment: el[1].avg_sentiment
                            ? el[1].avg_sentiment
                            : '-'
                    });
                });
                setAllClients(newAllClients);
            }
            fill();
        }, []);

        if (segmentCreationStep === 'initial') {
            return (
                <React.Fragment>
                    <div className={`row mx-0 w-100 flex-grow-1`}>
                        <div className="col px-0 d-flex flex-column">
                            {/* <div
                                className="row mx-0 "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col-1 px-0 me-2">
                                    {creationMode === 'manual' && (
                                        <PrimaryButton
                                            text={t(
                                                'components:newSegment.manual'
                                            )}
                                            onClick={() => {
                                                setCreationMode('manual')
                                                setSelectedAvailableOption()
                                                setAvailableOptions([])
                                            }}
                                        />
                                    )}
                                    {creationMode !== 'manual' && (
                                        <SecondaryButton
                                            text={t(
                                                'components:newSegment.manual'
                                            )}
                                            onClick={() => {
                                                setCreationMode('manual')
                                                setSelectedAvailableOption()
                                                setAvailableOptions([])
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="col-1 px-0">
                                    {creationMode === 'rules' && (
                                        <PrimaryButton
                                            text={t(
                                                'components:newSegment.rules'
                                            )}
                                            onClick={() => {
                                                setCreationMode('rules')
                                                setAvailableOptions(['filters'])
                                                setSelectedAvailableOption(
                                                    'filters'
                                                )
                                            }}
                                        />
                                    )}
                                    {creationMode !== 'rules' && (
                                        <SecondaryButton
                                            text={t(
                                                'components:newSegment.rules'
                                            )}
                                            onClick={() => {
                                                setCreationMode('rules')
                                                setAvailableOptions(['filters'])
                                                setSelectedAvailableOption(
                                                    'filters'
                                                )
                                            }}
                                        />
                                    )}
                                </div>
                            </div> */}
                            {creationMode === 'rules' && (
                                <div className={`row mx-0 mb-4`}>
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <span
                                                    className={`${styles['new-segment-container__title']}`}
                                                >
                                                    FILTERS
                                                </span>
                                            </div>
                                        </div>
                                        {segmentFilters.length > 0 && (
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['new-segment-container__sub-title']}`}
                                                    >
                                                        <SegmentFilters
                                                            filters={
                                                                segmentFilters
                                                            }
                                                            onRemove={
                                                                removeSegmentFilter
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {segmentFilters.length === 0 && (
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['new-segment-container__sub-title']}`}
                                                    >
                                                        No filters added
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {creationMode === 'manual' && (
                                <React.Fragment>
                                    <div
                                        className="row w-100 mx-0 d-flex justify-content-end "
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col-auto px-0 me-3">
                                            <SecondaryButton
                                                disabled={
                                                    !dashboardName.value ||
                                                    clientsAdded.length === 0
                                                }
                                                text={t(
                                                    'buttons:searchAutomatedSegments'
                                                )}
                                                onClick={
                                                    handleSearchAutomatedSegments
                                                }
                                            />
                                        </div>
                                        <div className="col-auto px-0">
                                            <PrimaryButton
                                                disabled={
                                                    !dashboardName.value ||
                                                    clientsAdded.length === 0
                                                }
                                                text={t(
                                                    'buttons:createSegment'
                                                )}
                                                onClick={handleCreateSegment}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 flex-grow-1 "
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col-6 px-0 pe-2 d-flex flex-column">
                                            <div
                                                className={`row mx-0 w-100 mb-3 ${styles['new-segment-container__first-row-container']}`}
                                            >
                                                <div
                                                    className={`col px-0  d-flex flex-column`}
                                                >
                                                    <ContentContainer
                                                        titleText={t(
                                                            `components:newSegmentDetails.title`
                                                        )}
                                                    >
                                                        <div
                                                            className={`row mx-0 w-100 h-100`}
                                                        >
                                                            <div
                                                                className={`col d-flex flex-column ${styles['new-segment-container__last-row__table-left-container']}`}
                                                            >
                                                                <div
                                                                    className="d-flex flex-column"
                                                                    style={{
                                                                        marginBottom:
                                                                            '2rem'
                                                                    }}
                                                                >
                                                                    <TypeInput
                                                                        onChangeInput={
                                                                            setDashboardNameValue
                                                                        }
                                                                        {...dashboardName}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={`mb-3 d-flex flex-column flex-grow-1 ${styles['new-segment-container__last-row__table-left-container__description-container']}`}
                                                                >
                                                                    <TypeInput
                                                                        inputType={
                                                                            'container'
                                                                        }
                                                                        onChangeInput={
                                                                            setDescriptionValue
                                                                        }
                                                                        {...description}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ContentContainer>
                                                </div>
                                            </div>
                                            <div
                                                className={`row w-100 mx-0 ${styles['new-segment-container__last-row-container']}`}
                                            >
                                                <div className="col px-0 h-100">
                                                    <SegmentClientsToAddList
                                                        onAddClientToSegment={
                                                            handleAddClientToSegment
                                                        }
                                                        clients={allClients.filter(
                                                            (el) =>
                                                                !clientsAdded.includes(
                                                                    el.id
                                                                )
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 px-0 ps-2 d-flex flex-column">
                                            <div
                                                className={`row mx-0 mb-3 w-100 ${styles['new-segment-container__first-row-container']}`}
                                            >
                                                <div
                                                    className={`col p-0 ${styles['new-segment-container__last-row__table-left-container']}`}
                                                >
                                                    <SegmentComparison
                                                        clientsAdded={
                                                            clientsAdded
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100 h-50">
                                                <div
                                                    className={`col px-0 ${styles['new-segment-container__last-row-container']}`}
                                                >
                                                    <SegmentClientsAddedList
                                                        clients={allClients.filter(
                                                            (el) =>
                                                                clientsAdded.includes(
                                                                    el.id
                                                                )
                                                        )}
                                                        onRemoveClientFromSegment={
                                                            handleRemoveClientFromSegment
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {creationMode === 'rules' && (
                                <React.Fragment>
                                    <div className="row mx-0 flex-grow-1 ">
                                        <div className="col-6 px-0 pe-2 d-flex flex-column">
                                            <div
                                                className={`row mx-0 w-100 mb-3 ${styles['new-segment-container__first-row-container']}`}
                                            >
                                                <div
                                                    className={`col px-0  d-flex flex-column`}
                                                >
                                                    <ContentContainer
                                                        titleText={t(
                                                            `components:newSegmentDetails.title`
                                                        )}
                                                    >
                                                        <div
                                                            className={`row mx-0 w-100 h-100`}
                                                        >
                                                            <div
                                                                className={`col d-flex flex-column ${styles['new-segment-container__last-row__table-left-container']}`}
                                                            >
                                                                <div className="mb-3 d-flex flex-column">
                                                                    <TypeInput
                                                                        onChangeInput={
                                                                            setDashboardNameValue
                                                                        }
                                                                        {...dashboardName}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={`mb-3 d-flex flex-column flex-grow-1 ${styles['new-segment-container__last-row__table-left-container__description-container']}`}
                                                                >
                                                                    <TypeInput
                                                                        inputType={
                                                                            'container'
                                                                        }
                                                                        onChangeInput={
                                                                            setDescriptionValue
                                                                        }
                                                                        {...description}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ContentContainer>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 px-0 ps-2 d-flex flex-column">
                                            <div
                                                className={`row mx-0 mb-3 w-100 ${styles['new-segment-container__first-row-container']}`}
                                            >
                                                <div
                                                    className={`col p-0 ${styles['new-segment-container__last-row__table-left-container']}`}
                                                >
                                                    <SegmentComparison
                                                        segmentFilters={
                                                            segmentFilters
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="row mx-0 w-100 "
                                        style={{
                                            marginBottom: '2rem',
                                            height: '50rem'
                                        }}
                                    >
                                        <div className="col px-0">
                                            <SegmentClientsList
                                                segmentFilters={segmentFilters}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="row w-100 mx-0 d-flex justify-content-end "
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col-auto px-0 me-3">
                                            <WarningButton
                                                disabled={
                                                    !(
                                                        (dashboardName.value &&
                                                            clientsAdded.length >
                                                                0) ||
                                                        (dashboardName.value &&
                                                            segmentFilters.length >
                                                                0)
                                                    )
                                                }
                                                text={t(
                                                    'buttons:searchAutomatedSegments'
                                                )}
                                                onClick={
                                                    handleSearchAutomatedSegments
                                                }
                                            />
                                        </div>
                                        <div className="col-auto px-0">
                                            <PrimaryButton
                                                disabled={
                                                    !(
                                                        (dashboardName.value &&
                                                            clientsAdded.length >
                                                                0) ||
                                                        (dashboardName.value &&
                                                            segmentFilters.length >
                                                                0)
                                                    )
                                                }
                                                text={t(
                                                    'buttons:createSegment'
                                                )}
                                                onClick={handleCreateSegment}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else return null;
    }
);

NewSegment.propTypes = {};
