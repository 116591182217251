import React from 'react'
import styles from './add-button-table-column.module.scss'
import { AddComponentButton } from '../../../../buttons/add-component-button/add-component-button'

export const AddButtonTableColumn = React.memo(
    ({ el, addButtonText, onAdd }) => {
        return (
            <div className={`${styles['add-button-table-column-container']}`}>
                <AddComponentButton
                    id={el['id']}
                    text={addButtonText}
                    onAdd={onAdd}
                />
            </div>
        )
    }
)
