import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import _ from 'lodash'
import { useArray } from '../../hooks/useArray'
import { useNavOptions } from '../../hooks/useNavOptions'
import { useCurrentApp } from '../../providers/current-app-context'
import { useCurrentUser } from '../../providers/current-user-context'
import administrationNavMock from './administration-nav-mock.json'
import backOfficeNavMock from './back-office-nav-mock.json'
import dashboardNavMock from './dashboard-nav-mock.json'
import profileNavMock from './profile-nav-mock.json'
import { HoveredComponentsAndFiltersProvider } from '../../providers/hovered-components-and-filters-context'
import { SegmentFiltersProvider } from '../../providers/segment-filters-context'
import { useToggle } from '../../hooks/useToggle'
import { DashboardLoadProvider } from '../../providers/dashboard-load-context'
//import { TutorialFocusChoice } from '../../reusable/smart/simple/tutorial-focus-choice/tutorial-focus-choice';
import { ModalPopupProvider } from '../../providers/modal-popup-context'
import { useTutorialStep } from './useTutorialSteps'
import { ProfileService } from '../../temp/test'
import { errorHandler } from '../../utils/api'
import { useSelectInput } from '../../hooks/useSelectInput'
import { useTextInput } from '../../hooks/useTextInput'
import styles from './logged-in-routes.module.scss'
import { mockTutorialSteps } from './mockTutorialSteps'
import { Modal, PrimaryButton, SecondaryButton, SelectInput, TypeInput } from '../../components'
import { DashboardLayout } from '../../layout/dashboard-layout/dashboard-layout'
import { useCustomTranslation } from '../../hooks/useCustomTranslation'
import SetPassword from '../../pages/auth/set-password/set-password'
import platformBackground from '../../assets/images/platformBackground.png'
import { logout } from '../../utils/authService'
import Topbar from '../../layout/topbar/topbar'
import { MainScrollProvider } from '../../hooks/main-scroll-context'
import Navbar from '../../layout/navbar/navbar'
import { getFromLS, saveToLS } from '../../utils/localStorage'
import { NavBarProvider } from '../../providers/nav-bar-context'
import { ModalStatusProvider } from '../../providers/modal-status-context'

const LoggedInRoutes = React.memo(() => {
    const { t } = useCustomTranslation()
    const [modalOpen, toggleModalOpen] = useToggle(false)
    const [selectedAvailableOption, setSelectedAvailableOption] = useState('')
    const [navOpen, toggleNavbar] = useToggle(getFromLS('navOpen') === null ? true : getFromLS('navOpen'))
    const [auxOpen, toggleAuxbar] = useToggle(getFromLS('auxOpen') === null ? true : getFromLS('auxOpen'))
    const [scrollTop, setScrollTop] = useState(0)
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = useState('')
    const [currentStep, setCurrentStep] = useState(0)
    const { setCurrentUser } = useCurrentUser()
    const [isTutorialOpen, toggleIsTutorialOpen] = useToggle(false)
    const [selectedEntity, setSelectedEntity] = useState('')
    const { value: filterValue, setValue: filterValueSetValue } = useTextInput({
        name: 'filterValue',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })
    const {
        value: filterOperator,
        setOptions: filterOperatorSetOptions,
        toggleDropdown: filterOperatorToggleDropdown,
        selectOption: filterOperatorSelectOption,
        keyPress: filterOperatorKeyPress,
        keyDown: filterOperatorKeyDown,
        setValue: filterOperatorSetValue,
    } = useSelectInput({
        name: 'filterOperator',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef(),
    })

    const { value: tutorialSteps, completeTutorialStep } = useTutorialStep(mockTutorialSteps)

    const handleOpenModal = React.useCallback(
        (el, entitity, operators) => {
            toggleModal(true)
            setModalType(el)
            setSelectedEntity(entitity)
            filterOperatorSetOptions(operators, [])
        },
        [toggleModal, setModalType, filterOperatorSetOptions]
    )

    const handleCloseModal = React.useCallback(() => {
        toggleModal(false)
        setModalType('')
    }, [toggleModal, setModalType])

    const [selectedTutorialStep, setSelectedTutorialStep] = useState('')
    const [pageLoaded, togglePageLoaded] = useToggle(false)
    const [tutorialStep, setTutorialStep] = useState('roleAssigned')
    const { array: segmentFilters, push: pushSegmentFilter, remove: removeSegmentFilter } = useArray([])
    const [hoveredFilter, setHoveredFilter] = useState('')
    const [hoveredComponents, setHoveredComponents] = useState([])
    const { currentUser } = useCurrentUser()
    const { currentApp } = useCurrentApp()
    const { array: profileRoutes } = useArray([...profileNavMock])
    const [userExists, setUserExists] = useState(false)
    const { routes, actions, openDropdown } = useNavOptions(
        process.env.REACT_APP_DISABLE_TOKEN_AUTH === 'true' ||
            (currentUser && _.has(currentUser, 'roles') && currentUser.roles && currentUser.roles.includes('starkdata'))
            ? [...dashboardNavMock, ...backOfficeNavMock, ...administrationNavMock]
            : currentUser && _.has(currentUser, 'roles') && currentUser.roles && currentUser.roles.includes('admin')
            ? [...dashboardNavMock, ...backOfficeNavMock]
            : [...dashboardNavMock],
        currentApp === 'pages' ? 'extra-nav-options' : currentApp === 'back-office' ? 'extra-back-office-options' : 'extra-administration-options',
        currentApp
    )

    //------------------------------------ Callbacks ------------------------------------//
    //------------------------------------------------------------------------------------//

    /**
     * Handles the opening of the navigation dropdown
     * @param {string} option - The option to open
     * @returns {void}
     */
    const handleOpenNavDropdown = useCallback(
        (option) => {
            routes.map((el) => {
                if (el.name === 'myanalytics') {
                    el.children.map((el2) => {
                        if (el2.name === option) {
                            openDropdown(el2.name)
                        }
                        return el2
                    })
                }
                if (el.name === option) {
                    openDropdown(el.name)
                }
                return el
            })
        },
        [openDropdown, routes]
    )

    /**
     * Handles the click of an icon
     * @param {string} option - The option to open
     * @returns {void}
     */
    const handleClickIcon = useCallback(
        (option) => {
            if (!auxOpen && option) {
                toggleAuxbar()
                setSelectedAvailableOption(option)
            } else if (auxOpen && option === selectedAvailableOption) {
                toggleAuxbar()
                setSelectedAvailableOption('')
            } else if (auxOpen && option !== selectedAvailableOption) {
                setSelectedAvailableOption(option)
            }
        },
        [auxOpen, selectedAvailableOption, toggleAuxbar]
    )

    useEffect(() => {
        async function fill() {
            if (currentUser) {
                try {
                    const result = await ProfileService.getApiProfileUser({
                        uuid: currentUser.uuid,
                    })
                    setTutorialStep(result.completed_tutorial)
                    if (result.completed_tutorial > 1) {
                        _.range(1, result.completed_tutorial).map((el) => {
                            completeTutorialStep(el - 1)
                        })
                    }
                    setUserExists(true)
                } catch (err) {
                    logout()
                    errorHandler(err)
                }
            }
        }
        fill()
    }, [currentUser, completeTutorialStep, setCurrentUser])

    const renderNestedRoute = useCallback((routes, allRoutes) => {
        let allRoutesNew = allRoutes || []
        routes.map((el) => {
            if (el.children) {
                return renderNestedRoute(el.children, allRoutesNew)
            } else if (el.component) {
                allRoutesNew.push(el)
            }
        })
        return allRoutesNew
    }, [])

    const routesMemo = useMemo(() => routes, [routes])
    const profileRoutesMemo = useMemo(() => profileRoutes, [profileRoutes])
    const openDropdownMemo = useMemo(() => openDropdown, [openDropdown])
    const mainScrollProviderProps = useMemo(
        () => ({
            scrollTop: scrollTop,
            setScrollTop: setScrollTop,
        }),
        [scrollTop, setScrollTop]
    )
    const navBarProviderProps = useMemo(
        () => ({
            navOpen: navOpen,
            auxOpen: auxOpen,
            handleClickIcon: handleClickIcon,
            toggleAuxBar: toggleAuxbar,
            toggleNavBar: toggleNavbar,
            closeAllOptions: actions.closeAllOptions,
            openNavDropdown: handleOpenNavDropdown,
        }),
        [navOpen, auxOpen, handleClickIcon, toggleAuxbar, toggleNavbar, actions.closeAllOptions, handleOpenNavDropdown]
    )

    //------------------------------------------------------------------------------------//

    useEffect(() => {
        saveToLS('navOpen', navOpen)
    }, [navOpen])

    useEffect(() => {
        saveToLS('auxOpen', auxOpen)
    }, [auxOpen])

    return (
        <div className={`${styles['logged-in-routes-container']}`}>
            <MainScrollProvider value={mainScrollProviderProps}>
                <ModalStatusProvider
                    value={{
                        isOpen: modalOpen,
                        setIsOpen: toggleModalOpen,
                    }}
                >
                    <Topbar profileRoutes={profileRoutes} isModalOpen={modalOpen} />
                    <ModalPopupProvider
                        value={{
                            isOpen: isTutorialOpen,
                            toggleIsOpen: toggleIsTutorialOpen,
                            steps: tutorialSteps,
                            selectedTutorialStep: selectedTutorialStep,
                            setSelectedTutorialStep: setSelectedTutorialStep,
                            currentStep: currentStep,
                            setCurrentStep: setCurrentStep,
                            completeTutorialStep: completeTutorialStep,
                        }}
                    >
                        <DashboardLoadProvider
                            value={{
                                dashboardLoad: pageLoaded,
                                setDashboardLoad: togglePageLoaded,
                            }}
                        >
                            <SegmentFiltersProvider
                                value={{
                                    segmentFilters: segmentFilters,
                                    pushSegmentFilter: pushSegmentFilter,
                                    removeSegmentFilter: removeSegmentFilter,
                                    handleOpenModal: handleOpenModal,
                                }}
                            >
                                <HoveredComponentsAndFiltersProvider
                                    value={{
                                        hoveredFilter: hoveredFilter,
                                        hoveredComponents: hoveredComponents,
                                        setHoveredFilter: setHoveredFilter,
                                        setHoveredComponents: setHoveredComponents,
                                    }}
                                >
                                    <NavBarProvider value={navBarProviderProps}>
                                        {modal && (
                                            <Modal onToggleModal={handleCloseModal}>
                                                <div className="row mx-0 mb-4">
                                                    <div className="col px-0">
                                                        <span className="modal-title">{t('modals:addFilterRule')}</span>
                                                    </div>
                                                </div>

                                                <div
                                                    className="row mx-0 "
                                                    style={{
                                                        marginBottom: '2rem',
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <span className="modal-text">{t(`layout:auxbar.filters.${modalType}`)}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 "
                                                    style={{
                                                        marginBottom: '2rem',
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <SelectInput
                                                            onChangeInput={filterOperatorSetValue}
                                                            onSelectInput={filterOperatorSelectOption}
                                                            onKeyPress={filterOperatorKeyPress}
                                                            onKeyDown={filterOperatorKeyDown}
                                                            onToggleDropdown={filterOperatorToggleDropdown}
                                                            {...filterOperator}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 "
                                                    style={{
                                                        marginBottom: '2rem',
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <TypeInput onChangeInput={filterValueSetValue} {...filterValue} />
                                                    </div>
                                                </div>
                                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                                    <div className="col-5 px-0">
                                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                                    </div>
                                                    <div className="col-5 px-0">
                                                        <PrimaryButton
                                                            text={t('modals:save')}
                                                            onClick={() => {
                                                                pushSegmentFilter({
                                                                    type: 'numerical',
                                                                    entity: selectedEntity,
                                                                    name: modalType,
                                                                    operator: filterOperator.selectedOption,
                                                                    value: filterValue.value,
                                                                })
                                                                handleCloseModal()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal>
                                        )}
                                        <img
                                            src={platformBackground}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                        <div className="row mx-0 w-100 h-100">
                                            <div className="col-auto px-0">
                                                <Navbar routes={routesMemo} actions={actions} openDropdown={openDropdownMemo} />
                                            </div>
                                            <div className="col px-0" style={{ minWidth: 0 }}>
                                                <div className="w-100 h-100">
                                                    <Routes>
                                                        {renderNestedRoute([...routes, ...profileRoutes]).map((el, index) => {
                                                            return (
                                                                <Route
                                                                    key={index}
                                                                    exact
                                                                    path={`/${el.url}`}
                                                                    element={
                                                                        <DashboardLayout
                                                                            key={`/${el.url}`}
                                                                            routeUrl={`/${el.url}`}
                                                                            routes={routesMemo}
                                                                            profileRoutes={profileRoutesMemo}
                                                                            openDropdown={openDropdownMemo}
                                                                            selectedAvailableOption={selectedAvailableOption}
                                                                            setSelectedAvailableOption={setSelectedAvailableOption}
                                                                            {...el}
                                                                            {...actions}
                                                                        />
                                                                    }
                                                                ></Route>
                                                            )
                                                        })}
                                                        <Route path="/set-password/:uuid" element={<SetPassword />} />
                                                        <Route path="*" element={<Navigate to={'/dashboard'} replace />} />
                                                    </Routes>
                                                </div>
                                            </div>
                                        </div>
                                    </NavBarProvider>
                                </HoveredComponentsAndFiltersProvider>
                            </SegmentFiltersProvider>
                        </DashboardLoadProvider>
                    </ModalPopupProvider>
                </ModalStatusProvider>
            </MainScrollProvider>
        </div>
    )
})

export default LoggedInRoutes

LoggedInRoutes.propTypes = {}
