import _, { transform } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { generalTableMock } from './mock'
import { useTable } from '../../../hooks/useTable'
import { errorHandler } from '../../../utils/api'
import { ComponentWrapper } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { TableImprovement } from '../../tables/table-improvement/table-improvement'
import { useToggle } from '../../../hooks/useToggle'
import { GeneralEditModal } from '../../modals/general-edit-modal/general-edit-modal'
import { GeneralRemoveModal } from '../../modals/general-remove-modal/general-remove-modal'
import icoEdit from '../../../assets/images/ico-edit.svg'
import icoDelete from '../../../assets/images/icoDeleteRed.svg'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'
import { GeneralAddModal } from '../../modals/general-add-modal/general-add-modal'
import { OpenAPI } from '../../../temp/test'
import { toIsoDate } from '../../../utils/format'
import { usePreviewMode } from '../../dashboard-creation/library-component-factory/preview-mode-provider'

/**
Lists all the teams composed of employees in a table
 */

/**
Example of use:

    <GeneralTable
        isExample={false}
        title={t(
            'components:teamTable.title'
        )}
        attr={'teamTable'}
        columns={[
            {
                path: 'id',
                backendPath: 'id',
                show: false
            },
            {
                path: 'name',
                backendPath: 'name',
                show: true,
                sortable: true
            },
            {
                path: 'action',
                sortable: false
            }
        ]}
        getEndpoint={
            TeamsService.getApiTeams
        }
        getConfig={{}}
        addTitle={t(
            'pages:teamManagement.createTeam'
        )}
        addSuccessText={t(
            'modals:addTeam.success'
        )}
        addEndpoint={
            TeamsService.postApiTeams
        }
        addBodyInputs={{
            name: {
                name: 'name',
                path: 'name',
                backendPath: 'name',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            }
        }}
        deleteIds={[
            {
                path: 'id',
                backendPath: 'id'
            }
        ]}
        deleteEndpoint={
            TeamsService.deleteApiTeams1
        }
        deleteTitle={t(
            'modals:removeTeam.title'
        )}
        deleteDescription={t(
            'modals:removeTeam.description'
        )}
        deleteSuccessText={t(
            'modals:removeTeam.success'
        )}
    />

    <GeneralTable
        isExample={false}
        title={t(
            'components:teamMembersTable.title'
        )}
        attr={'teamMembersTable'}
        columns={[
            {
                path: 'id',
                backendPath: 'id',
                show: false
            },
            {
                path: 'teamId',
                value: parseInt(
                    params.get('team-id')
                ),
                show: false
            },
            {
                path: 'name',
                backendPath:
                    'employee.first_name',
                label: '',
                show: false,
                sortable: true
            },
            {
                path: 'firstName',
                label: 'First Name',
                backendPath:
                    'employee.first_name',
                show: true,
                sortable: true
            },
            {
                path: 'lastName',
                label: 'Last Name',
                backendPath:
                    'employee.last_name',
                show: true,
                sortable: true
            },
            {
                path: 'isResponsible',
                backendPath: 'is_leader',
                label: '',
                sortable: true
            },
            {
                path: 'isPlatformUser',
                backendPath: 'is_platform_user',
                label: '',
                sortable: false
            },
            {
                path: 'action',
                sortable: false
            }
        ]}
        getEndpoint={
            TeamsService.getApiTeamsMembers
        }
        getConfig={{
            teamId: parseInt(
                params.get('team-id')
            )
        }}
        updateTitle={t(
            'pages:teamManagement.editTeamMember'
        )}
        updateSuccessText={t(
            'modals:editTeamMember.success'
        )}
        updateEndpoint={
            TeamsService.patchApiTeamsMembers
        }
        updateIds={[
            {
                path: 'teamId',
                backendPath: 'teamId'
            },
            {
                path: 'id',
                backendPath: 'memberId'
            }
        ]}
        updateBodyInputs={{
            firstName: {
                name: 'firstName',
                path: 'firstName',
                backendPath:
                    'employee.first_name',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            lastName: {
                name: 'lastName',
                path: 'lastName',
                backendPath:
                    'employee.last_name',
                type: 'type',
                value: '',
                error: '',
                hidden: false,
                state: 'normal'
            },
            isResponsible: {
                type: 'radio',
                name: 'isResponsible',
                path: 'isResponsible',
                backendPath: 'is_leader',
                direction: 'horizontal',
                options: [
                    {
                        name: 'yes',
                        label: t(`inputs:yes`),
                        disabled: false,
                        blocked: false
                    },
                    {
                        name: 'no',
                        label: t(`inputs:no`),
                        disabled: false,
                        blocked: false
                    }
                ],
                selectedOption: 'yes',
                value: 'yes'
            }
        }}
        deleteIds={[
            {
                path: 'teamId',
                backendPath: 'teamId'
            },
            {
                path: 'id',
                backendPath: 'memberId'
            }
        ]}
        deleteEndpoint={
            TeamsService.deleteApiTeamsMembers
        }
        deleteTitle={t(
            'modals:removeTeamMember.title'
        )}
        deleteDescription={t(
            'modals:removeTeamMember.description'
        )}
        deleteSuccessText={t(
            'modals:removeTeamMember.success'
        )}
    />
*/

export const GeneralTable = React.memo(
    ({
        title,
        attr,
        columns,
        getEndpoint,
        getConfig,
        addTitle,
        addEndpoint,
        addSuccessText,
        addPath,
        addBodyInputs,
        addBodyStaticInputs,
        updateTitle,
        updateEndpoint,
        updateIds,
        updateBodyInputs,
        updateBodyStaticInputs,
        deleteTitle,
        deleteDescription,
        deleteEndpoint,
        deleteIds,
        onRowClick,
        mockData,
        updateSuccessText,
        deleteSuccessText,
        filterColumns,
        onClickAction,
        withSearch,
        withSelection,
        hideTitle,
        noDataText,
        withFilter,
        isPreview,
        ...rest
    }) => {
        const { t } = useCustomTranslation()
        const { isExample } = usePreviewMode()
        const [modal, toggleModal] = useToggle(false)
        const [modalType, setModalType] = useState('')
        const [selectedElement, setSelectedElement] = useState('')
        const { value: data, setLoading, setValue: setData, pageChange: dataPageChange, clickActionIcon } = useTable(generalTableMock)

        const {
            value: addInputs,
            setValue: setAddInputs,
            replaceAll: replaceAddInputs,
            selectOption: selectAddOption,
            unselectOption: unselectAddOption,
            toggleDropdown: toggleAddDropdown,
            clearSelectedOptions: clearAddSelectedOptions,
            clickRadioOption: clickAddRadioOption,
            clickCheckboxOption: clickAddCheckboxOption,
        } = useMultipleInputs({ ...addBodyInputs })

        const {
            value: inputs,
            replaceAll,
            setValue,
            selectOption,
            unselectOption,
            toggleDropdown,
            clearSelectedOptions,
            clickRadioOption,
            clickCheckboxOption,
        } = useMultipleInputs({ ...updateBodyInputs })

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(generalTableMock)
            dataClone.data.length = 0
            dataClone.currentPage = 1
            dataClone.titleText = title
            dataClone.attr = attr
            dataClone.columns = columns
            dataClone.filterColumns = filterColumns
            try {
                if (!isExample) {
                    setLoading(true)
                    let backData
                    if (getEndpoint) {
                        backData = await getEndpoint({
                            ...getConfig,
                        })
                    } else {
                        backData = mockData || []
                    }
                    if (getEndpoint) {
                        backData.map((el) => {
                            let newEl = {}
                            columns.map((column) => {
                                if (_.has(column, 'value')) {
                                    newEl[column.path] = column.value
                                } else if (_.has(el, column.backendPath)) {
                                    if (_.has(column, 'backendTransform') && column.backendTransform) {
                                        if (_.get(el, column.backendPath)) {
                                            newEl[column.path] = column.backendTransform(_.get(el, column.backendPath))
                                        } else {
                                            newEl[column.path] = ''
                                        }
                                    } else {
                                        newEl[column.path] = _.get(el, column.backendPath)
                                    }
                                } else if (column.path === 'action') {
                                    newEl[column.path] = {
                                        label: t(`columns:action`),
                                        id: el[columns.find((el) => el.path === 'id').backendPath],
                                        open: false,
                                        options: [],
                                        reference: React.createRef(),
                                    }
                                    if (updateEndpoint) {
                                        newEl[column.path].options.push({
                                            icon: icoEdit,
                                            name: 'edit',
                                            label: t(`settings:edit`),
                                            color: '#4c4c4c',
                                        })
                                    }
                                    if (deleteEndpoint) {
                                        newEl[column.path].options.push({
                                            icon: icoDelete,
                                            name: 'delete',
                                            label: t(`settings:delete`),
                                        })
                                    }
                                }
                                return column
                            })

                            dataClone.data.push(newEl)
                            return el
                        })
                    } else if (mockData) {
                        // do the same but with mock data instead of  backendPath
                        backData.map((el) => {
                            let newEl = { ...el }
                            columns.map((column) => {
                                if (column.path === 'action') {
                                    newEl[column.path] = {
                                        label: t(`columns:action`),
                                        id: el[columns.find((el) => el.path === 'id').path],
                                        open: false,
                                        options: [],
                                        reference: React.createRef(),
                                    }
                                    if (updateEndpoint) {
                                        newEl[column.path].options.push({
                                            icon: icoEdit,
                                            name: 'edit',
                                            label: t(`settings:edit`),
                                            color: '#4c4c4c',
                                        })
                                    }
                                    if (deleteEndpoint) {
                                        newEl[column.path].options.push({
                                            icon: icoDelete,
                                            name: 'delete',
                                            label: t(`settings:delete`),
                                            color: '#DB6162',
                                        })
                                    }
                                }
                                return column
                            })
                            dataClone.data.push(newEl)
                            return el
                        })
                    }
                    if (backData.length > 0) {
                        dataClone.lastUpdated = _.maxBy(backData, 'updated_at').updated_at
                    }
                    setData(dataClone)
                } else {
                    dataClone.titleText = title
                    dataClone.attr = attr
                    dataClone.columns = columns
                    dataClone.data = mockData ? mockData : []
                    setData(dataClone)
                }
            } catch (err) {
                setData(dataClone)
            }
            setLoading(false)
        }, [attr, title, setLoading, setData, isExample, getConfig, getEndpoint, columns, t, mockData, deleteEndpoint, updateEndpoint])

        useEffect(() => {
            getInformation()
        }, [getInformation])

        const handleCloseModal = () => {
            toggleModal(false)
            setModalType('')
            setSelectedElement('')
        }

        const handleSelectSettingsOption = useCallback(
            async (attr, id, option) => {
                if (onClickAction) onClickAction(attr, id, option)
                clickActionIcon(attr, id)
                toggleModal(true)
                setModalType(option)
                const selectedElement = data.data.find((el) => el.id === id) || ''
                setSelectedElement(selectedElement)
                if (option === 'edit') {
                    const newUpdateBodyInputs = { ...updateBodyInputs }
                    Object.values(newUpdateBodyInputs).map((el) => {
                        if (el.type === 'type' || el.type === 'number') {
                            el.value = selectedElement[el.path]
                        } else if (el.type === 'selectSingle') {
                            if (_.has(el, 'transformToBackend') && el.transformToBackend) {
                                el.value = selectedElement[el.path]
                                el.selectedOption = selectedElement[el.path]
                                el.selectedOptionAux = el.transformToBackend(selectedElement[el.path])
                            } else {
                                el.selectedOption = selectedElement[el.path]
                                el.selectedOptionAux = selectedElement[el.path]
                                el.value = selectedElement[el.path]
                            }
                        } else if (el.type === 'selectMultiple') {
                            el.selectedOptions = selectedElement[el.path]
                            el.selectedOptionsAux = selectedElement[el.path]
                        } else if (el.type === 'radio') {
                            el.selectedOption = selectedElement[el.path] ? 'yes' : 'no'
                            el.selectedOptionAux = selectedElement[el.path] ? 'yes' : 'no'
                        } else if (el.type === 'checkbox') {
                            el.selectedOption = selectedElement[el.path] ? 'yes' : 'no'
                            el.selectedOptionAux = selectedElement[el.path] ? 'yes' : 'no'
                        } else if (el.type === 'date') {
                            el.value = selectedElement[el.path]
                        }
                        return el
                    })
                    replaceAll({
                        ...newUpdateBodyInputs,
                    })
                }
            },
            [toggleModal, clickActionIcon, data.data, replaceAll, updateBodyInputs, onClickAction]
        )

        const handleDeleteElement = async () => {
            try {
                const deleteConfig = {}
                deleteIds.map((el) => {
                    const { value, isArray } = el
                    if (value) {
                        deleteConfig[el.backendPath] = value
                    } else {
                        if (isArray) {
                            deleteConfig[el.backendPath] = [selectedElement[el.path]]
                        } else {
                            deleteConfig[el.backendPath] = selectedElement[el.path]
                        }
                    }
                    return el
                })
                await deleteEndpoint({
                    ...deleteConfig,
                })
                await getInformation()
            } catch (err) {
                errorHandler(err)
            }
        }

        const handleEditElement = async () => {
            try {
                const updateConfig = {}
                if (updateIds)
                    updateIds.map((el) => {
                        updateConfig[el.backendPath] = selectedElement[el.path]
                        return el
                    })
                const updateBody = {}
                Object.values(inputs).map((el) => {
                    // for each el.backendPath like employee.first_name, first we split it by .

                    const path = el.backendPath.split('.')
                    // then we check if the length of the array is 1, if it is, it means that the path is not nested
                    if (path.length === 1) {
                        // so we just assign the value to the key
                        if (el.type === 'type' || el.type === 'number') {
                            updateBody[el.backendPath] = el.value
                        } else if (el.type === 'selectSingle') {
                            updateBody[el.backendPath] = el.selectedOptionAux
                        } else if (el.type === 'selectMultiple') {
                            updateBody[el.backendPath] = el.selectedOptions
                        } else if (el.type === 'radio') {
                            updateBody[el.backendPath] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'checkbox') {
                            updateBody[el.backendPath] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'date') {
                            if (el.value === '//' || el.value === '' || !_.has(el, 'value')) updateBody[el.backendPath] = undefined
                            else updateBody[el.backendPath] = toIsoDate(el.value)
                        }
                    }
                    // if the length is greater than 1, it means that the path is nested
                    else {
                        // so we create a new object with the first key of the path

                        if (!updateBody[path[0]]) {
                            updateBody[path[0]] = {}
                        }
                        if (el.type === 'type' || el.type === 'number') {
                            updateBody[path[0]][path[1]] = el.value
                        } else if (el.type === 'selectSingle') {
                            updateBody[path[0]][path[1]] = el.selectedOptionAux
                        } else if (el.type === 'selectMultiple') {
                            updateBody[path[0]][path[1]] = el.selectedOptions
                        } else if (el.type === 'radio') {
                            updateBody[path[0]][path[1]] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'checkbox') {
                            updateBody[path[0]][path[1]] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'date') {
                            if (el.value === '//' || el.value === '' || !_.has(el, 'value')) updateBody[path[0]][path[1]] = undefined
                            else updateBody[path[0]][path[1]] = toIsoDate(el.value)
                        }
                    }

                    return el
                })
                if (updateBodyStaticInputs) {
                    Object.values(updateBodyStaticInputs).map((el) => {
                        updateBody[el.backendPath] = selectedElement[el.path]
                        return el
                    })
                }
                await updateEndpoint({
                    ...updateConfig,
                    requestBody: {
                        ...updateBody,
                    },
                })
                await getInformation()
            } catch (err) {
                errorHandler(err)
            }
        }

        const handleOpenAddElementModal = () => {
            toggleModal(true)
            setModalType('add')
            const newAddBodyInputs = { ...addBodyInputs }
            Object.values(newAddBodyInputs).map((el) => {
                if (el.type === 'type') {
                    el.value = ''
                } else if (el.type === 'number') {
                    el.value = 0
                } else if (el.type === 'selectSingle') {
                    if (_.has(el, 'transformToBackend') && el.transformToBackend) {
                        el.value = el.options[0]
                        el.selectedOption = el.options[0]
                        el.selectedOptionAux = el.optionsAux[0]
                    } else {
                        el.value = el.options[0]
                        el.selectedOption = el.options[0]
                        el.selectedOptionAux = el.optionsAux[0]
                    }
                } else if (el.type === 'selectMultiple') {
                    el.selectedOptions = []
                    el.selectedOptionsAux = []
                } else if (el.type === 'radio') {
                    el.selectedOption = 'yes'
                    el.selectedOptionAux = 'yes'
                } else if (el.type === 'checkbox') {
                    el.selectedOption = 'yes'
                    el.selectedOptionAux = 'yes'
                }
                return el
            })
            replaceAddInputs({
                ...newAddBodyInputs,
            })
        }

        const handleAddElement = async () => {
            try {
                const addBody = {}
                Object.values(addInputs).map((el) => {
                    // for each el.backendPath like employee.first_name, first we split it by .

                    const path = el.backendPath.split('.')
                    // then we check if the length of the array is 1, if it is, it means that the path is not nested
                    if (path.length === 1) {
                        // so we just assign the value to the key
                        if (el.type === 'type' || el.type === 'number') {
                            addBody[el.backendPath] = el.value || undefined
                        } else if (el.type === 'selectSingle') {
                            addBody[el.backendPath] = el.selectedOptionAux
                        } else if (el.type === 'selectMultiple') {
                            addBody[el.backendPath] = el.selectedOptions
                        } else if (el.type === 'radio') {
                            addBody[el.backendPath] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'checkbox') {
                            addBody[el.backendPath] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'date') {
                            if (el.value === '//' || el.value === '' || !_.has(el, 'value')) addBody[el.backendPath] = undefined
                            else addBody[el.backendPath] = toIsoDate(el.value)
                        }
                    }
                    // if the length is greater than 1, it means that the path is nested
                    else {
                        // so we create a new object with the first key of the path

                        if (!addBody[path[0]]) {
                            addBody[path[0]] = {}
                        }
                        if (el.type === 'type' || el.type === 'number') {
                            addBody[path[0]][path[1]] = el.value
                        } else if (el.type === 'selectSingle') {
                            addBody[path[0]][path[1]] = el.selectedOptionAux
                        } else if (el.type === 'selectMultiple') {
                            addBody[path[0]][path[1]] = el.selectedOptions
                        } else if (el.type === 'radio') {
                            addBody[path[0]][path[1]] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'checkbox') {
                            addBody[path[0]][path[1]] = el.selectedOption === 'yes' ? true : false
                        } else if (el.type === 'date') {
                            if (el.value === '//' || el.value === '' || !_.has(el, 'value')) addBody[path[0]][path[1]] = undefined
                            else addBody[path[0]][path[1]] = toIsoDate(el.value)
                        }
                    }

                    return el
                })
                if (addBodyStaticInputs) {
                    await Promise.all(
                        Object.values(addBodyStaticInputs).map(async (el) => {
                            const result = await el.value()
                            addBody[el.backendPath] = await el.value()
                            return el
                        })
                    )
                }
                if (addPath) {
                    await addEndpoint({
                        [addPath.backendPath]: addPath.value,
                        requestBody: {
                            ...addBody,
                        },
                    })
                } else {
                    await addEndpoint({
                        requestBody: {
                            ...addBody,
                        },
                    })
                }
                await getInformation()
            } catch (err) {
                errorHandler(err)
            }
        }

        return (
            <>
                {modal && modalType === 'add' && addEndpoint && (
                    <GeneralAddModal
                        inputs={addInputs}
                        onChangeInput={setAddInputs}
                        onSelectInput={selectAddOption}
                        onUnselectInput={unselectAddOption}
                        onToggleDropdown={toggleAddDropdown}
                        onClearSelectedOptions={clearAddSelectedOptions}
                        onClickRatioOption={clickAddRadioOption}
                        onClickCheckboxOption={clickAddCheckboxOption}
                        title={addTitle}
                        successMessage={addSuccessText}
                        onCloseModal={handleCloseModal}
                        onAdd={handleAddElement}
                    />
                )}
                {modal && modalType === 'edit' && updateEndpoint && (
                    <GeneralEditModal
                        inputs={inputs}
                        onChangeInput={setValue}
                        onSelectInput={selectOption}
                        onUnselectInput={unselectOption}
                        onToggleDropdown={toggleDropdown}
                        onClearSelectedOptions={clearSelectedOptions}
                        onClickRatioOption={clickRadioOption}
                        onClickCheckboxOption={clickCheckboxOption}
                        title={updateTitle}
                        successMessage={updateSuccessText}
                        onCloseModal={handleCloseModal}
                        onEdit={handleEditElement}
                    />
                )}
                {modal && modalType === 'delete' && deleteEndpoint && (
                    <GeneralRemoveModal
                        title={deleteTitle}
                        description={deleteDescription}
                        successText={deleteSuccessText}
                        onDelete={handleDeleteElement}
                        onCloseModal={handleCloseModal}
                    />
                )}
                <ComponentWrapper
                    {...data}
                    onNewElementClick={addEndpoint ? handleOpenAddElementModal : undefined}
                    onNewElementText={t('buttons:add')}
                    withSearch={withSearch}
                    withSelection={withSelection}
                    withFilter={withFilter}
                    hideTitle={hideTitle}
                >
                    <TableImprovement
                        {...data}
                        {...rest}
                        onPageChange={dataPageChange}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                        onRowClick={onRowClick}
                        noDataText={noDataText}
                    />
                </ComponentWrapper>
            </>
        )
    }
)

GeneralTable.propTypes = {}
