import React from 'react';
import styles from './team-member-name-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import _ from 'lodash';

export const TeamMemberNameTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['team-member-name-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0" style={{ marginRight: '1rem' }}>
                    <img
                        src={_.has(el, 'image') && el.image && el['image']}
                        alt="Team Member"
                        className={
                            styles[
                                'team-member-name-table-column-container__avatar'
                            ]
                        }
                    />
                </div>
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    <div className="row mx-0 w-100">
                        <div className="col-auto px-0">
                            <span
                                className={
                                    styles[
                                        'team-member-name-table-column-container__text'
                                    ]
                                }
                            >
                                {el[type]}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
