import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductsService, TeamsService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useFilter } from '../../../providers/filter-context';
import {
    Modal,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
    SuccessModal
} from '../..';
import { usePub } from '../../../hooks/usePubSub';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const AssociateTeamModal = ({ onCloseModal, onEnd }) => {
    const publish = usePub();
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { filter } = useFilter();

    const {
        value: teamName,
        toggleDropdown: teamNameToggleDropdown,
        selectOption: teamNameSelectOption,
        keyPress: teamNameKeyPress,
        keyDown: teamNameKeyDown,
        setValue: teamNameSetValue,
        replaceAll: teamNameReplaceAll
    } = useSelectInput({
        name: 'teamName',
        showOptions: true,
        options: [],
        value: '',
        optionsAux: [],
        state: 'normal',
        focused: -1,
        reference: useRef()
    });

    useEffect(() => {
        async function fill() {
            const newTeamName = {
                name: 'teamName',
                showOptions: true,
                options: [],
                optionsAux: [],
                state: 'normal',
                value: '',
                focused: -1,
                reference: React.createRef()
            };
            const dataBack = await TeamsService.getApiTeams({
                params: {},
                leaderEmpNo: undefined
            });
            const productTeamsBack = await ProductsService.getApiProductsTeams({
                productId: filter.productId ? filter.productId : undefined
            });
            dataBack
                .filter((el) => {
                    const found = productTeamsBack.find(
                        (element) => element.id === el.id
                    );
                    if (!found) {
                        newTeamName.options.push(el.name);
                        newTeamName.optionsAux.push(el.id);
                    }
                    return el;
                })
                .map((el) => {
                    newTeamName.options.push(el.name);
                    newTeamName.optionsAux.push(el.id);
                    return el;
                });
            teamNameReplaceAll(newTeamName);
        }
        fill();
    }, [filter.productId, teamNameReplaceAll]);

    const handleAssociateTeam = async () => {
        try {
            setLoading(true);
            await TeamsService.patchApiTeamsProducts({
                teamId: teamName.selectedOptionAux,
                requestBody: filter.productId ? [filter.productId] : undefined
            });
            if (onEnd) {
                onEnd();
            }
            setIsSuccess(true);
            setLoading(false);
            publish('product');
        } catch (err) {
            errorHandler(err);
        }
    };

    if (!isSuccess) {
        return (
            <Modal
                onToggleModal={() => onCloseModal(false)}
                isLoading={isLoading}
            >
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">
                            {t('modals:associateTeam.title')}
                        </span>
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <SelectInput
                            onChangeInput={teamNameSetValue}
                            onSelectInput={teamNameSelectOption}
                            hideOptionsAux
                            onSelectIconInput={teamNameSelectOption}
                            onKeyPress={teamNameKeyPress}
                            onKeyDown={teamNameKeyDown}
                            onToggleDropdown={teamNameToggleDropdown}
                            {...teamName}
                        />
                    </div>
                </div>
                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1'
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton
                            text={t('buttons:cancel')}
                            onClick={() => {
                                onCloseModal(false);
                            }}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('buttons:save')}
                            disabled={!teamName.selectedOptionAux}
                            onClick={() => {
                                handleAssociateTeam();
                            }}
                        />
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:associateTeam.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
