import React, { useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import styles from './Droppable.module.scss';
import { use } from 'i18next';

export const Droppable = React.memo(({ children, id }) => {
    const { isOver, setNodeRef, rect } = useDroppable({
        id: id
    });

    const dynamicStyle = [
        styles['droppable-container'],
        isOver ? styles['hovered'] : '',
        id === 'remove' ? styles['remove'] : ''
    ];

    return (
        <div
            ref={setNodeRef}
            className={dynamicStyle.filter((style) => style !== '').join(' ')}
        >
            {children}
        </div>
    );
});
