export const unitSalesForecastMock = {
    attr: 'unitSalesForecastChart',
    tooltipType: 'salesForecastUnits-monthly',
    areaAttr: [
        {
            dataKey: 'unitsSoldForecastIntervalValue',
            color: '#8c54ff',
            dashArray: '3 3',
            strokeWidth: 2,
            hideDot: true,
            forecastGradient: true,
            fillOpacity: 0.9,
            lineType: 'monotone'
        },
        {
            dataKey: 'unitsSoldForecastValue',
            color: '#8c54ff',
            isReverseColor: true,
            strokeWidth: 2,
            lineType: 'monotone'
        },
        {
            dataKey: 'unitsSold',
            color: '#8c54ff',
            strokeWidth: 2,
            lineType: 'monotone'
        }
    ],
    categoryAxis: 'month',
    data: [
        {
            unitsSold: 2707,
            time: '2020-01-01',
            day: '01 Jan 2020',
            week: '01 Jan 2020',
            month: 'Jan 2020',
            quarter: 'Q1 2020',
            year: '2020'
        },
        {
            unitsSold: 2666,
            time: '2020-02-01',
            day: '01 Fev 2020',
            week: '01 Fev 2020',
            month: 'Feb 2020',
            quarter: 'Q1 2020',
            year: '2020'
        },
        {
            unitsSold: 2888,
            time: '2020-03-01',
            day: '01 Mar 2020',
            week: '01 Mar 2020',
            month: 'Mar 2020',
            quarter: 'Q1 2020',
            year: '2020'
        },
        {
            unitsSold: 2804,
            time: '2020-04-01',
            day: '01 Abr 2020',
            week: '01 Abr 2020',
            month: 'Apr 2020',
            quarter: 'Q2 2020',
            year: '2020'
        },
        {
            unitsSold: 2668,
            time: '2020-05-01',
            day: '01 Maio 2020',
            week: '01 Maio 2020',
            month: 'May 2020',
            quarter: 'Q2 2020',
            year: '2020'
        },
        {
            unitsSold: 2873,
            time: '2020-06-01',
            day: '01 Jun 2020',
            week: '01 Jun 2020',
            month: 'Jun 2020',
            quarter: 'Q2 2020',
            year: '2020'
        },
        {
            unitsSold: 2783,
            time: '2020-07-01',
            day: '01 Jul 2020',
            week: '01 Jul 2020',
            month: 'Jul 2020',
            quarter: 'Q3 2020',
            year: '2020'
        },
        {
            unitsSold: 2931,
            time: '2020-08-01',
            day: '01 Ago 2020',
            week: '01 Ago 2020',
            month: 'Aug 2020',
            quarter: 'Q3 2020',
            year: '2020'
        },
        {
            unitsSold: 2928,
            time: '2020-09-01',
            day: '01 Set 2020',
            week: '01 Set 2020',
            month: 'Sep 2020',
            quarter: 'Q3 2020',
            year: '2020'
        },
        {
            unitsSold: 2878,
            time: '2020-10-01',
            day: '01 Out 2020',
            week: '01 Out 2020',
            month: 'Oct 2020',
            quarter: 'Q4 2020',
            year: '2020'
        },
        {
            unitsSold: 2720,
            time: '2020-11-01',
            day: '01 Nov 2020',
            week: '01 Nov 2020',
            month: 'Nov 2020',
            quarter: 'Q4 2020',
            year: '2020'
        },
        {
            unitsSold: 3249,
            time: '2020-12-01',
            day: '01 Dez 2020',
            week: '01 Dez 2020',
            month: 'Dec 2020',
            quarter: 'Q4 2020',
            year: '2020'
        },
        {
            unitsSold: 2976,
            time: '2021-01-01',
            day: '01 Jan 2021',
            week: '01 Jan 2021',
            month: 'Jan 2021',
            quarter: 'Q1 2021',
            year: '2021'
        },
        {
            unitsSold: 2533,
            time: '2021-02-01',
            day: '01 Fev 2021',
            week: '01 Fev 2021',
            month: 'Feb 2021',
            quarter: 'Q1 2021',
            year: '2021'
        },
        {
            unitsSold: 3052,
            time: '2021-03-01',
            day: '01 Mar 2021',
            week: '01 Mar 2021',
            month: 'Mar 2021',
            quarter: 'Q1 2021',
            year: '2021'
        },
        {
            unitsSold: 2851,
            time: '2021-04-01',
            day: '01 Abr 2021',
            week: '01 Abr 2021',
            month: 'Apr 2021',
            quarter: 'Q2 2021',
            year: '2021'
        },
        {
            unitsSold: 2947,
            time: '2021-05-01',
            day: '01 Maio 2021',
            week: '01 Maio 2021',
            month: 'May 2021',
            quarter: 'Q2 2021',
            year: '2021'
        },
        {
            unitsSold: 2775,
            time: '2021-06-01',
            day: '01 Jun 2021',
            week: '01 Jun 2021',
            month: 'Jun 2021',
            quarter: 'Q2 2021',
            year: '2021'
        },
        {
            unitsSold: 2850,
            time: '2021-07-01',
            day: '01 Jul 2021',
            week: '01 Jul 2021',
            month: 'Jul 2021',
            quarter: 'Q3 2021',
            year: '2021'
        },
        {
            unitsSold: 2891,
            time: '2021-08-01',
            day: '01 Ago 2021',
            week: '01 Ago 2021',
            month: 'Aug 2021',
            quarter: 'Q3 2021',
            year: '2021'
        },
        {
            unitsSold: 2718,
            time: '2021-09-01',
            day: '01 Set 2021',
            week: '01 Set 2021',
            month: 'Sep 2021',
            quarter: 'Q3 2021',
            year: '2021'
        },
        {
            unitsSold: 3022,
            time: '2021-10-01',
            day: '01 Out 2021',
            week: '01 Out 2021',
            month: 'Oct 2021',
            quarter: 'Q4 2021',
            year: '2021'
        },
        {
            unitsSold: 3116,
            time: '2021-11-01',
            day: '01 Nov 2021',
            week: '01 Nov 2021',
            month: 'Nov 2021',
            quarter: 'Q4 2021',
            year: '2021'
        },
        {
            unitsSold: 2954,
            time: '2021-12-01',
            day: '01 Dez 2021',
            week: '01 Dez 2021',
            month: 'Dec 2021',
            quarter: 'Q4 2021',
            year: '2021'
        },
        {
            unitsSold: 3055,
            time: '2022-01-01',
            day: '01 Jan 2022',
            week: '01 Jan 2022',
            month: 'Jan 2022',
            quarter: 'Q1 2022',
            year: '2022'
        },
        {
            unitsSold: 2676,
            time: '2022-02-01',
            day: '01 Fev 2022',
            week: '01 Fev 2022',
            month: 'Feb 2022',
            quarter: 'Q1 2022',
            year: '2022'
        },
        {
            unitsSold: 2893,
            time: '2022-03-01',
            day: '01 Mar 2022',
            week: '01 Mar 2022',
            month: 'Mar 2022',
            quarter: 'Q1 2022',
            year: '2022'
        },
        {
            unitsSold: 2916,
            time: '2022-04-01',
            day: '01 Abr 2022',
            week: '01 Abr 2022',
            month: 'Apr 2022',
            quarter: 'Q2 2022',
            year: '2022'
        },
        {
            unitsSold: 2875,
            time: '2022-05-01',
            day: '01 Maio 2022',
            week: '01 Maio 2022',
            month: 'May 2022',
            quarter: 'Q2 2022',
            year: '2022'
        },
        {
            unitsSold: 2764,
            time: '2022-06-01',
            day: '01 Jun 2022',
            week: '01 Jun 2022',
            month: 'Jun 2022',
            quarter: 'Q2 2022',
            year: '2022'
        },
        {
            unitsSold: 2821,
            time: '2022-07-01',
            day: '01 Jul 2022',
            week: '01 Jul 2022',
            month: 'Jul 2022',
            quarter: 'Q3 2022',
            year: '2022'
        },
        {
            unitsSold: 2729,
            time: '2022-08-01',
            day: '01 Ago 2022',
            week: '01 Ago 2022',
            month: 'Aug 2022',
            quarter: 'Q3 2022',
            year: '2022'
        },
        {
            unitsSold: 2660,
            time: '2022-09-01',
            day: '01 Set 2022',
            week: '01 Set 2022',
            month: 'Sep 2022',
            quarter: 'Q3 2022',
            year: '2022'
        },
        {
            unitsSold: 2865,
            time: '2022-10-01',
            day: '01 Out 2022',
            week: '01 Out 2022',
            month: 'Oct 2022',
            quarter: 'Q4 2022',
            year: '2022'
        },
        {
            unitsSold: 2780,
            time: '2022-11-01',
            day: '01 Nov 2022',
            week: '01 Nov 2022',
            month: 'Nov 2022',
            quarter: 'Q4 2022',
            year: '2022'
        },
        {
            unitsSold: 2987,
            time: '2022-12-01',
            day: '01 Dez 2022',
            week: '01 Dez 2022',
            month: 'Dec 2022',
            quarter: 'Q4 2022',
            year: '2022'
        },
        {
            unitsSold: 3046,
            time: '2023-01-01',
            day: '01 Jan 2023',
            week: '01 Jan 2023',
            month: 'Jan 2023',
            quarter: 'Q1 2023',
            year: '2023'
        },
        {
            unitsSold: 2584,
            time: '2023-02-01',
            day: '01 Fev 2023',
            week: '01 Fev 2023',
            month: 'Feb 2023',
            quarter: 'Q1 2023',
            year: '2023'
        },
        {
            unitsSold: 3149,
            time: '2023-03-01',
            day: '01 Mar 2023',
            week: '01 Mar 2023',
            month: 'Mar 2023',
            quarter: 'Q1 2023',
            year: '2023'
        },
        {
            unitsSold: 2938,
            time: '2023-04-01',
            day: '01 Abr 2023',
            week: '01 Abr 2023',
            month: 'Apr 2023',
            quarter: 'Q2 2023',
            year: '2023'
        },
        {
            unitsSold: 2631,
            time: '2023-05-01',
            day: '01 Maio 2023',
            week: '01 Maio 2023',
            month: 'May 2023',
            quarter: 'Q2 2023',
            year: '2023'
        },
        {
            unitsSold: 2846,
            time: '2023-06-01',
            day: '01 Jun 2023',
            week: '01 Jun 2023',
            month: 'Jun 2023',
            quarter: 'Q2 2023',
            year: '2023'
        },
        {
            unitsSold: 2805,
            time: '2023-07-01',
            day: '01 Jul 2023',
            week: '01 Jul 2023',
            month: 'Jul 2023',
            quarter: 'Q3 2023',
            year: '2023'
        },
        {
            unitsSold: 2979,
            time: '2023-08-01',
            day: '01 Ago 2023',
            week: '01 Ago 2023',
            month: 'Aug 2023',
            quarter: 'Q3 2023',
            year: '2023'
        },
        {
            unitsSold: 2774,
            time: '2023-09-01',
            day: '01 Set 2023',
            week: '01 Set 2023',
            month: 'Sep 2023',
            quarter: 'Q3 2023',
            year: '2023'
        },
        {
            unitsSold: 3064,
            time: '2023-10-01',
            day: '01 Out 2023',
            week: '01 Out 2023',
            month: 'Oct 2023',
            quarter: 'Q4 2023',
            year: '2023'
        },
        {
            unitsSold: 2722,
            time: '2023-11-01',
            day: '01 Nov 2023',
            week: '01 Nov 2023',
            month: 'Nov 2023',
            quarter: 'Q4 2023',
            year: '2023'
        },
        {
            unitsSold: 2877,
            time: '2023-12-01',
            day: '01 Dez 2023',
            week: '01 Dez 2023',
            month: 'Dec 2023',
            quarter: 'Q4 2023',
            year: '2023'
        },
        {
            unitsSold: 3120,
            time: '2024-01-01',
            day: '01 Jan 2024',
            week: '01 Jan 2024',
            month: 'Jan 2024',
            quarter: 'Q1 2024',
            year: '2024'
        },
        {
            unitsSold: 2865,
            time: '2024-02-01',
            day: '01 Fev 2024',
            week: '01 Fev 2024',
            month: 'Feb 2024',
            quarter: 'Q1 2024',
            year: '2024'
        },
        {
            unitsSold: 2910,
            time: '2024-03-01',
            day: '01 Mar 2024',
            week: '01 Mar 2024',
            month: 'Mar 2024',
            quarter: 'Q1 2024',
            year: '2024'
        },
        {
            unitsSold: 2500,
            time: '2024-04-01',
            day: '01 Abr 2024',
            week: '01 Abr 2024',
            month: 'Apr 2024',
            quarter: 'Q2 2024',
            year: '2024',
            unitsSoldForecastIntervalValue: [2500, 2500],
            unitsSoldForecastValue: 2500,
            forecastGradient: 2500
        },
        {
            forecastGradient: 2000,
            unitsSoldForecastValue: 3000,
            unitsSoldForecastIntervalValue: [2600, 3300],
            time: '2024-05-01',
            day: '01 Maio 2024',
            week: '01 Maio 2024',
            month: 'May 2024',
            quarter: 'Q2 2024',
            year: '2024'
        },
        {
            forecastGradient: 2300,
            unitsSoldForecastValue: 3100,
            unitsSoldForecastIntervalValue: [2900, 3400],
            time: '2024-06-01',
            day: '01 Jun 2024',
            week: '01 Jun 2024',
            month: 'Jun 2024',
            quarter: 'Q2 2024',
            year: '2024'
        },
        {
            forecastGradient: 2500,
            unitsSoldForecastValue: 3000,
            unitsSoldForecastIntervalValue: [2500, 3500],
            time: '2024-07-01',
            day: '01 Jul 2024',
            week: '01 Jul 2024',
            month: 'Jul 2024',
            quarter: 'Q3 2024',
            year: '2024'
        }
    ]
};
