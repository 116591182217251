import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { getValueInString } from '../../../../../utils/unit';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const TeamSalesComparisonTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    const payloadIndex = props.payload.findIndex(
        (el) => el.dataKey === props.dataKey
    );
    if (
        props.id !== undefined &&
        props.payload &&
        props.payload[payloadIndex]
    ) {
        let teamStringEndIndex = undefined;
        let resultString = props.dataKey.toLowerCase();
        if (
            props.dataKey.toLowerCase().includes('forecast') &&
            !props.dataKey.toLowerCase().includes('min') &&
            !props.dataKey.toLowerCase().includes('max')
        ) {
            teamStringEndIndex = resultString.indexOf('forecast');
            if (teamStringEndIndex !== -1) {
                // Extract the part of the string before the substring
                resultString = resultString.slice(0, teamStringEndIndex);
            }
        }

        return (
            <TooltipContainer color={props.payload[payloadIndex].color}>
                <TooltipHeaderRow
                    title={t(`tooltips:header.${props.unitsOrValues}.normal`)}
                    value={
                        <span>
                            {
                                props.payload[payloadIndex].payload[
                                    props.categoryAxis
                                ]
                            }
                        </span>
                    }
                />
                <Separator />
                {!props.dataKey.toLowerCase().includes('forecast') &&
                    !props.dataKey.toLowerCase().includes('min') &&
                    !props.dataKey.toLowerCase().includes('max') && (
                        <TooltipContentRow
                            title={t('tooltips:content.sales')}
                            value={`${getValueInString(
                                Math.round(
                                    props.payload[payloadIndex].payload[
                                        props.dataKey
                                    ]
                                ),
                                props.unitsOrValues
                            )}`}
                            color={props.payload[payloadIndex].color}
                        />
                    )}
                {props.dataKey.toLowerCase().includes('forecast') && (
                    <React.Fragment>
                        <TooltipContentRow
                            title={t('tooltips:content.sales')}
                            value={`${getValueInString(
                                Math.round(
                                    parseFloat(
                                        props.payload[payloadIndex].payload[
                                            `${resultString}ForecastValue`
                                        ]
                                    )
                                ),
                                props.unitsOrValues
                            )}`}
                            color={props.payload[payloadIndex].color}
                        />
                        <TooltipContentRow
                            title={t('tooltips:content.max')}
                            value={`${getValueInString(
                                Math.round(
                                    parseFloat(
                                        props.payload[payloadIndex].payload[
                                            `${resultString}ForecastMaxValue`
                                        ]
                                    )
                                ),
                                props.unitsOrValues
                            )}`}
                            color={props.payload[payloadIndex].color}
                        />
                        <TooltipContentRow
                            title={t('tooltips:content.min')}
                            value={`${getValueInString(
                                Math.round(
                                    props.payload[payloadIndex].payload[
                                        `${resultString}ForecastMinValue`
                                    ]
                                ),
                                props.unitsOrValues
                            )}`}
                            color={props.payload[payloadIndex].color}
                        />
                    </React.Fragment>
                )}
            </TooltipContainer>
        );
    } else return null;
});
