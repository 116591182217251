import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { activityListMock } from './mock';
import { errorHandler } from '../../../utils/api.js';
import { ContentContainer, Loader, Spinner, Table } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays logins in a table
 */

export const ActivityList = React.memo(({ userId, isReportBuilder }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal] = useToggle(false);
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue
    } = useTable(activityListMock, modal, '');

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(activityListMock);
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            try {
                // dataClone.data.length = 0;
                // dataClone.sortedData.length = 0;
                // if (isMounted.current) setValue(dataClone);
                // if (isMounted.current) setLoading(true);
                // const loginsBack = await LoginsService.getApiBackofficeLogins({
                //     userUuid: userId
                // });
                // loginsBack.map((el, index) => {
                //     const newEl = {
                //         index: index,
                //         date: el.date ? formatDate(el.date) : '',
                //         isoDate: el.date ? el.date : '',
                //         uuid: el.user && el.user.uuid ? el.user.uuid : '',
                //         username:
                //             el.user && el.user.username ? el.user.username : ''
                //     };
                //     dataClone.data.push(newEl);
                //     return el;
                // });
                // dataClone.sortedData = _.orderBy(
                //     dataClone.data,
                //     [dataClone.sortColumn.path],
                //     [dataClone.sortColumn.order]
                // );
                // verifyComponent(dataClone);
                // if (isMounted.current) setValue(dataClone);
                // setLoading(false);
            } catch (err) {
                errorHandler(err);
            }
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
        fill();
    }, [userId, isMounted, setLoading, setValue, t]);

    return (
        <ContentContainer
            attr={data.attr}
            hidePinIcon
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onCloseSettings={toggleComponentSettings}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <Table
                    isReportBuilder={isReportBuilder}
                    actions={data.actions}
                    // onClickActionIcon={onClickActionIcon}
                    // onClickActionOption={onClickActionOption}
                    // onCloseActions={onCloseActions}
                    attr={data.attr}
                    type={data.type}
                    titleCounter={data.titleCounter}
                    titleText={t(`components:${data.attr}.title`)}
                    placeholderText={t(`components:${data.attr}.nodata`)}
                    columns={data.columns}
                    data={data.sortedData}
                    sortColumn={data.sortColumn}
                    onSort={dataSort}
                    currentPage={data.currentPage}
                    pageSize={data.pageSize}
                    onPageChange={dataPageChange}
                    onRowClick={undefined}
                    onRowSelect={undefined}
                    onSelectAll={dataSelectAll}
                    onUnselectAll={dataUnselectAll}
                    paginationDisabled={data.paginationDisabled}
                    disabled={data.disabled}
                ></Table>
            )}
        </ContentContainer>
    );
});

ActivityList.propTypes = {
    /**
     * User id. as a filter for getting data from api
     */
    userId: PropTypes.string
};
