import React from 'react'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { ComponentWrapper, DynamicBarChartContainer, Loader, Spinner } from '../../'

/**
 * Displays comparison of sales between products according to the selected category
 */

const goalProgressMock = {
    attr: 'goalProgress',
    data: [
        {
            name: 'Goal 1',
            goal: 'Goal 1',
            goalProgress: 50,
            goalRemaining: 50,
            reference: 50,
        },
        {
            name: 'Goal 2',
            goal: 'Goal 2',
            goalProgress: 20,
            goalRemaining: 80,
            reference: 70,
        },
        {
            name: 'Goal 3',
            goal: 'Goal 3',
            goalProgress: 40,
            goalRemaining: 60,
            reference: 30,
        },
        {
            name: 'Goal 4',
            goal: 'Goal 4',
            goalProgress: 2,
            goalRemaining: 98,
            reference: 20,
        },
        {
            name: 'Goal 5',
            goal: 'Goal 5',
            goalProgress: 5,
            goalRemaining: 95,
            reference: 10,
        },
        {
            name: 'Goal 6',
            goal: 'Goal 6',
            goalProgress: 10,
            goalRemaining: 90,
            reference: 30,
        },
    ],
    barStackedAttr: [
        {
            dataKey: 'goalProgress',
            colorFunction: (el) => (el['goalProgress'] - el['reference'] < 0 ? '#ef9e9c' : '#6cd3c3'),
            stackId: 'a',
            isGradient: true,
        },
        {
            dataKey: 'goalRemaining',
            color: 'rgba(240,240,240,0.3)',
            stackId: 'a',
            isGradient: true,
        },
    ],
    categoryAxis: 'goal',
    tooltipType: 'goalProgress',
    hideCursor: true,
    legend: [
        {
            name: 'onSchedule',
            color: '#2cd9c5',
        },
        {
            name: 'behindSchedule',
            color: '#ef9e9c',
        },
        {
            name: 'forecastedGoalValue',
            color: 'rgba(6, 164, 231, 1)',
        },
    ],
}

export const GoalProgress = React.memo(({ onSelectGoal, isPreview, ...rest }) => {
    const { value, setLoading, setValue } = useSimpleComponent(goalProgressMock)

    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper {...value} {...rest}>
                {value.loading && <Spinner />}
                {!value.loading && <DynamicBarChartContainer {...value} onClick={onSelectGoal} />}
            </ComponentWrapper>
        </div>
    )
})

GoalProgress.propTypes = {}
