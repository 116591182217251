import React from 'react';
import icoContract from '../../../../../assets/images/icoContract.svg';
import styles from './ContractInformation.module.scss';

export const ContractInformation = () => {
    return (
        <div className={styles['contract-information-container']}>
            <img
                src={icoContract}
                alt="contract"
                className={styles['contract-information-container__icon']}
            />
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles['contract-information-container__title']
                        }
                    >
                        Contract Details
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'contract-information-container__content-text'
                            ]
                        }
                    >
                        VAT number: 123456789
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'contract-information-container__content-text'
                            ]
                        }
                    >
                        Date Created: 01/01/2021
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'contract-information-container__content-text'
                            ]
                        }
                    >
                        Contract Code: 123456789
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'contract-information-container__content-text'
                            ]
                        }
                    >
                        Data Residency: Iceland
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'contract-information-container__content-text'
                            ]
                        }
                    >
                        Root Enterprise Admin email: example@example.com
                    </span>
                </div>
            </div>
        </div>
    );
};
