import React from 'react'

export const reportGeneralDetailsMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    loading: true,
    attr: 'reportGeneralDetails',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
