import React, { useState } from 'react';
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import styles from './goal-milestones-card.module.scss';
import { Loader } from '../../loading/Loader/Loader';
import icoSatisfactionSad from '../../../assets/images/icoSatisfactionSad.svg';
import icoSatisfactionHappy from '../../../assets/images/icoSatisfactionHappy.svg';
import { DonutChartWithCircle } from '../../charts/donut-chart-with-circle/donut-chart-with-circle';
import { NavigationMenu } from '../../navigation/navigation-menu/navigation-menu';
import Scrollable from '../../scrolling/scrollable';
import { Spinner } from '../../loading/spinner/spinner';

const goalMilestonesCardMock = {
    attr: 'goalMilestonesCard',
    upcomingMilestones: [
        {
            date: '30 May 2024',
            target: '500k',
            current: '300k',
            isNext: true
        },
        {
            date: '30 June 2024',
            target: '400k',
            current: '100k',
            isNext: false
        },
        {
            date: '30 July 2024',
            target: '500k',
            current: '300k',
            isNext: false
        }
    ],
    historyMilestones: [
        {
            date: '30 April 2024',
            target: '500k',
            reached: '300k'
        },
        {
            date: '30 March 2024',
            target: '400k',
            reached: '100k'
        },
        {
            date: '30 February 2024',
            target: '500k',
            reached: '300k'
        }
    ]
};

const MilestoneRow = ({
    date,
    target,
    current,
    reached,
    isNext,
    isHistory
}) => {
    return (
        <div
            className={styles['goal-milestones-card__milestone-row']}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
            <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <span
                        className={
                            styles['goal-milestones-card__milestone-row__date']
                        }
                    >
                        {date}
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span
                        className={
                            styles['goal-milestones-card__milestone-row__text']
                        }
                    >
                        Target:{' '}
                    </span>
                    <div style={{ width: '1rem' }}></div>
                    <span
                        className={
                            styles[
                                'goal-milestones-card__milestone-row__text'
                            ] +
                            ' ' +
                            styles[
                                'goal-milestones-card__milestone-row__text--bold'
                            ]
                        }
                    >
                        {target}
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span
                        className={
                            styles['goal-milestones-card__milestone-row__text']
                        }
                    >
                        {isHistory
                            ? 'Reached:'
                            : isNext
                            ? 'Not Started'
                            : 'Current:'}
                    </span>
                    {(isHistory || !isNext) && (
                        <>
                            <div style={{ width: '1rem' }}></div>
                            <span
                                className={
                                    styles[
                                        'goal-milestones-card__milestone-row__text'
                                    ] +
                                    ' ' +
                                    styles[
                                        'goal-milestones-card__milestone-row__text--bold'
                                    ]
                                }
                                style={
                                    isHistory
                                        ? {
                                              color:
                                                  reached === target
                                                      ? '#6ad6c5'
                                                      : '#cc6866'
                                          }
                                        : {}
                                }
                            >
                                {isHistory ? reached : current}
                            </span>
                        </>
                    )}
                </div>
                {isHistory && (
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <img
                            src={
                                current < target
                                    ? icoSatisfactionHappy
                                    : icoSatisfactionSad
                            }
                            alt="satisfaction"
                            className={
                                styles[
                                    'goal-milestones-card__milestone-row__satisfaction'
                                ]
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const GoalMilestonesCard = ({ isPreview, ...rest }) => {
    const [menuOptions, setMenuOptions] = useState(['comingUp', 'history']);
    const [selectedOption, setSelectedOption] = useState('comingUp');
    const { value, setLoading, setValue } = useSimpleComponent(
        goalMilestonesCardMock
    );
    return (
        <div
            className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <ComponentWrapper {...value} {...rest}>
                {value.loading && <Spinner />}
                {!value.loading && (
                    <div className={styles['goal-milestones-card']}>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '1rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={
                                        styles['goal-milestones-card__date']
                                    }
                                >
                                    2024
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col" style={{ padding: '0' }}>
                                <NavigationMenu
                                    isPageMenu
                                    options={menuOptions}
                                    fillSpace
                                    selectedOption={selectedOption}
                                    onChangeOption={setSelectedOption}
                                />
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 flex-grow-1"
                            style={{ minHeight: 0 }}
                        >
                            <div
                                className="col h-100"
                                style={{ padding: '0 1rem' }}
                            >
                                <Scrollable>
                                    {selectedOption === 'comingUp' &&
                                        value.upcomingMilestones.map(
                                            (item, index) => (
                                                <MilestoneRow
                                                    key={index}
                                                    date={item.date}
                                                    target={item.target}
                                                    current={item.current}
                                                    isNext={item.isNext}
                                                />
                                            )
                                        )}
                                    {selectedOption === 'history' &&
                                        value.historyMilestones.map(
                                            (item, index) => (
                                                <MilestoneRow
                                                    key={index}
                                                    date={item.date}
                                                    target={item.target}
                                                    reached={item.reached}
                                                    isHistory
                                                />
                                            )
                                        )}
                                </Scrollable>
                            </div>
                        </div>
                    </div>
                )}
            </ComponentWrapper>
        </div>
    );
};
