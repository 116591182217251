import React from 'react';

export const totalInvestmentMock = {
    hoverStyle: '',
    desc: '',
    loading: true,
    title: '',
    titleColor: '#606060',
    headline: '',
    headlineColor: '#606060',
    actions: {},
    percentage: '',
    disabled: true,
    headlineType: '',
    attr: 'totalInvestmentCard',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    }
};
