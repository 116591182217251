import React from 'react';
import styles from './AuthBackgroundWrapper.module.scss';
import starkdataLogoAuth from '../../../assets/images/starkdataLogoAuth.svg';
import starkdataBall1 from '../../../assets/images/starkdataBall1.svg';
import authBackground from '../../../assets/images/authBackground.png';
import { AuthFormWrapper } from '../../forms/AuthFormWrapper/AuthFormWrapper';

export const AuthBackgroundWrapper = ({ title, description, content }) => {
    return (
        <div className={styles.AuthBackgroundWrapper}>
            <img
                src={authBackground}
                className={styles.AuthBackgroundWrapper_background}
            ></img>
            <div className={styles.AuthBackgroundWrapper_container}>
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 d-flex align-items-center">
                        <img
                            src={starkdataLogoAuth}
                            alt="starkdata"
                            style={{ width: '100%', height: '100%' }}
                        />
                    </div>
                    <div className="col-6 px-0 d-flex align-items-center position-relative">
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center"
                            style={{ padding: '0 2rem' }}
                        >
                            <div
                                className="col-auto px-0"
                                style={{ width: '50rem' }}
                            >
                                <img
                                    src={starkdataBall1}
                                    alt="starkdataBall1"
                                    className={
                                        styles.AuthBackgroundWrapper_starkdataBall +
                                        ' ' +
                                        styles.AuthBackgroundWrapper_starkdataBall___top
                                    }
                                />
                                <img
                                    src={starkdataBall1}
                                    alt="starkdataBall1"
                                    className={
                                        styles.AuthBackgroundWrapper_starkdataBall +
                                        ' ' +
                                        styles.AuthBackgroundWrapper_starkdataBall___bottom
                                    }
                                />
                                <AuthFormWrapper>
                                    {title && (
                                        <div
                                            className="row mx-0"
                                            style={{ margin: '5rem 0' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-center">
                                                <span
                                                    className={
                                                        styles.AuthBackgroundWrapper_title
                                                    }
                                                >
                                                    {title}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {description && (
                                        <div
                                            className="row mx-0"
                                            style={{ marginBottom: '4rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-center">
                                                <span
                                                    className={
                                                        styles.AuthBackgroundWrapper_description
                                                    }
                                                >
                                                    {description}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {content}
                                </AuthFormWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
