import React from 'react';
import { ComponentWarning } from '../../dashboard-component-configuration/component-warning/component-warning';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const NoAdminWarning = () => {
    const { t } = useCustomTranslation();
    return (
        <ComponentWarning
            message={t('tooltips:content.no-org-admin-warning')}
        />
    );
};
