import { useCallback, useReducer } from 'react';

const ACTIONS = {
	ADD_OPTION: 'add',
	TOGGLE_DROPDOWN: 'dropdown',
	SELECT_OPTION: 'select',
	KEY_PRESS: 'keyPress',
	KEY_DOWN: 'keyDown',
	SET_VALUE: 'value',
	RESET: 'reset',
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.ADD_OPTION:
			return {
				...state,
				iconList: [...state.iconList, action.payload.option],
			};
		case ACTIONS.TOGGLE_DROPDOWN:
			return {
				...state,
				openDropdown: action.payload,
			};
		case ACTIONS.SELECT_OPTION:
			return {
				...state,
				selectedIcon: action.payload.option.toString(),
			};
		case ACTIONS.KEY_PRESS:
			{

				let selectedIcon = '';
				if (action.payload === 13) {
					action.payload.preventDefault();
					if (state.value && state.focused >= 0) {
						let valueReplaced = state.value.replace(
							new RegExp('\\\\', 'g'),
							'\\\\'
						);
						const regex = new RegExp(`${valueReplaced}`, 'i');
						let newIconList = state.iconList.filter((p) => {
							return p.toString().match(regex);
						});
	
						selectedIcon = newIconList[state.focused];
					} else if (state.focused >= 0) {
						selectedIcon = state.iconList[state.focused];
					}
					const scrollDiv = document.getElementById(`${state.name}-dropdown`);
					scrollDiv.scrollTop = 0;
					return {
						...state,
						focused: -1,
						value: '',
						selectedIcon,
					};
				}
			}
			break
		case ACTIONS.KEY_DOWN:
			if (action.payload.which === 38) {
				if (state.focused > 0) {
					const scrollDiv = document.getElementById(`${state.name}-dropdown`);
					const topElement = document.querySelector(
						`#${state.name}-dropdown .select-input-container__dropdown__container--focused`
					);
					scrollDiv.scrollTop = topElement.offsetTop - topElement.offsetHeight;
					return { ...state, focused: -1 };
				}
			} else if (action.payload.which === 40) {
				if (state.value && state.focused !== -1) {
					let valueReplaced = state.value.replace(
						new RegExp('\\\\', 'g'),
						'\\\\'
					);
					const regex = new RegExp(`${valueReplaced}`, 'i');
					let newIconList = state.iconList.filter((p) => {
						return p.toString().match(regex);
					});
					if (state.focused < newIconList.length - 1) {
						const scrollDiv = document.getElementById(`${state.name}-dropdown`);
						const topElement = document.querySelector(
							`#${state.name}-dropdown .select-input-container__dropdown__container--focused`
						);
						scrollDiv.scrollTop =
							topElement.offsetTop + topElement.offsetHeight;
						return {
							...state,
							focused: state.focused + 1,
						};
					}
				} else if (
					state.focused !== -1 &&
					state.focused <= state.iconList.length - 1
				) {
					const scrollDiv = document.getElementById(`${state.name}-dropdown`);
					const topElement = document.querySelector(
						`#${state.name}-dropdown .select-input-container__dropdown__container--focused`
					);
					scrollDiv.scrollTop = topElement.offsetTop + topElement.offsetHeight;
					return {
						...state,
						focused: state.focused + 1,
					};
				} else {
					return {
						...state,
						focused: 0,
					};
				}
			} else {
				return {
					...state,
				};
			}
			break
		case ACTIONS.SET_VALUE:{

			return {
				...state,
				value: action.payload,
			};
		}
		case ACTIONS.RESET:
			return {
				...action.payload,
			};
	}
}

export function useSelectIconInput(defaultValue) {
	const [value, dispatch] = useReducer(reducer, defaultValue);

	function addOption(value) {
		dispatch({ type: 'add', payload: { option: value } });
	}

	function selectOption(value) {
		dispatch({
			type: 'select',
			payload: { option: value },
		});
	}

	function keyPress(e) {
		dispatch({
			type: 'keyPress',
			payload: e,
		});
	}

	function keyDown(e) {
		dispatch({
			type: 'keyDown',
			payload: e,
		});
	}

	function toggleDropdown(v) {
		dispatch({
			type: 'dropdown',
			payload: typeof v === 'boolean' ? v : !value.openDropdown,
		});
	}

	function setValue(e) {
		dispatch({ type: 'value', payload: e.target.value });
	}

	const reset = useCallback(() => {
		dispatch({ type: 'reset', payload: defaultValue });
	}, [defaultValue]);

	return {
		value,
		addOption,
		toggleDropdown,
		selectOption,
		keyPress,
		keyDown,
		setValue,
		reset,
	};
}
