import styles from './user-statistics.module.scss';

import React, { useEffect } from 'react';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import {
    LoaderForeground,
    LoginHistory,
    LoginList,
    Spinner
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Provides information about user statistics
 */

const BackOfficeUserStatistics = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 h-100 ${styles['back-office-user-statistics-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:back-office.user-statistics.title')}
                            </div>
                        </div>
                        <div
                            className={`row mx-0 w-100 ${styles['back-office-user-statistics-container__login-chart-container']}`}
                        >
                            <div className="col px-0 h-100">
                                <LoginHistory />
                            </div>
                        </div>
                        <div
                            className={`row mx-0 w-100 ${styles['back-office-user-statistics-container__login-table-container']}`}
                        >
                            <div className="col px-0 h-100 h-100">
                                <LoginList />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default BackOfficeUserStatistics;

BackOfficeUserStatistics.propTypes = {};
