import React, { useCallback, useState } from 'react'
import styles from './OrganizationCarousel.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { RemoveCheckedOrganizationsModal } from '../../modals/remove-checked-organizations-modal/remove-organization-modal'
import { RemoveOrganizationModal } from '../../modals/remove-organization-modal/remove-organization-modal'
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg'
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg'
import { ActionsButtonNew } from '../../buttons/actions-button-new/actions-button-new'
import icoRemoveGradient from '../../../assets/images/icoRemoveGradient.svg'
import { Pagination } from '../../tables/pagination/pagination'
import FullTextPopup from '../../popups/FullTextPopup'

// Updated Carousel component

// Example usage

const AddressBlock = ({ title, content }) => (
    <div className="col px-0">
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span className={styles['organization-carousel-container__card__subTitle']}>{title}</span>
            </div>
        </div>
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <span className={styles['organization-carousel-container__card__content']}>{content}</span>
            </div>
        </div>
    </div>
)

export const OrganizationCard = ({ organization }) => {
    return (
        <div className="row mx-0 w-100">
            <div className="col px-0">
                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                    <AddressBlock title="Location" content={`${organization.city}, ${organization.country}`} />
                    <AddressBlock title="Region" content={organization.continent} />
                </div>
                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                    <AddressBlock title="Admin" content={organization.admin} />
                    <AddressBlock title="Teams" content={organization.teams} />
                </div>
                <div className="row mx-0 w-100">
                    <AddressBlock title="Revenue" content={organization.revenue} />
                </div>
            </div>
        </div>
    )
}

export const OrganizationCarousel = React.memo(({ attr, cards, getTitleText, titleIcon, selectedCard, onSelectCard, onToggleEditMode, onUpdate }) => {
    const { t } = useCustomTranslation()
    const pageSize = 3 // Number of cards to show per page
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedOrgId, setSelectedOrgId] = useState(null)
    const [checkedOrgIds, setCheckedOrgIds] = useState([]) // Array of selected organization ids
    const [deleteCheckedOrgsModal, setDeleteCheckedOrgsModal] = useState(false)
    const [deleteOrgModal, setDeleteOrgModal] = useState(false)

    // Calculate the indices of the first and last card on the current page
    const initialCardIndex = (currentPage - 1) * pageSize
    const finalCardIndex = initialCardIndex + pageSize

    // Function to update the current page
    const onPageChange = (page) => {
        setCurrentPage(page)
    }

    const handleClickOption = useCallback(
        (option, orgId) => {
            setSelectedOrgId(orgId)
            if (option === 'edit') {
                onToggleEditMode(true)
                onSelectCard(orgId)
            } else if (option === 'remove') {
                setDeleteOrgModal(true)
            }
        },
        [onToggleEditMode, onSelectCard]
    )

    const handleCheckOrg = useCallback(
        (orgId) => {
            if (checkedOrgIds.includes(orgId)) {
                setCheckedOrgIds(checkedOrgIds.filter((id) => id !== orgId))
            } else {
                setCheckedOrgIds([...checkedOrgIds, orgId])
            }
        },
        [checkedOrgIds]
    )

    const handleUncheckAll = useCallback(() => {
        setCheckedOrgIds([])
    }, [])

    const handleCheckAll = useCallback(() => {
        setCheckedOrgIds(cards.map((card) => card.id))
    }, [cards])

    return (
        <>
            {deleteCheckedOrgsModal && <RemoveCheckedOrganizationsModal onCloseModal={() => setDeleteCheckedOrgsModal(false)} onEnd={onUpdate} />}
            {selectedOrgId && deleteOrgModal && (
                <RemoveOrganizationModal
                    onCloseModal={() => {
                        setSelectedOrgId(null)
                        setDeleteOrgModal(false)
                    }}
                    onEnd={onUpdate}
                />
            )}
            <div className={`row mx-0 ${styles['organization-carousel-container']}`}>
                <div className="col px-0">
                    <div className="row mx-0" style={{ marginBottom: '2rem' }}>
                        {cards &&
                            cards.slice(initialCardIndex, finalCardIndex).map((card, index) => (
                                <div
                                    className={`col-4 h-100`}
                                    style={{
                                        paddingRight: index === pageSize - 1 ? '0px' : '0.5rem',
                                        paddingLeft: index === 0 ? '0px' : '0.5rem',
                                    }}
                                    key={initialCardIndex + index} // Unique key based on card's position in the array
                                    onClick={() => {
                                        if (selectedCard && selectedCard.id === card.id) {
                                            onSelectCard(null)
                                            onToggleEditMode(false)
                                        } else {
                                            onSelectCard(card.id)
                                        }
                                    }}
                                >
                                    <div
                                        className={
                                            selectedCard && selectedCard.id === card.id
                                                ? `
                                                            ${styles['organization-carousel-container__card']}
                                            ${styles['organization-carousel-container__card--selected']}`
                                                : styles['organization-carousel-container__card']
                                        }
                                    >
                                        <div
                                            className="row mx-0 w-100 d-flex justify-content-between"
                                            style={{
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col-auto px-0 d-flex align-items-center">
                                                <div
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                >
                                                    <img
                                                        src={checkedOrgIds.includes(card.id) ? icoCheckboxSelected : icoCheckboxEmpty}
                                                        alt="Checkbox"
                                                        className={
                                                            checkedOrgIds.includes(card.id)
                                                                ? `
                                                                    ${styles['organization-carousel-container__checkbox-icon']}
                                                                    ${styles['organization-carousel-container__checkbox-icon--selected']}`
                                                                : styles['organization-carousel-container__checkbox-icon']
                                                        }
                                                        onClick={(e) => {
                                                            handleCheckOrg(card.id)
                                                            e.stopPropagation()
                                                            e.preventDefault()
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col px-0 d-flex justify-content-start" style={{ minWidth: 0 }}>
                                                {titleIcon && (
                                                    <img
                                                        src={titleIcon}
                                                        alt="Organization"
                                                        className={styles['organization-carousel-container__card__icon']}
                                                        style={{
                                                            marginRight: '1rem',
                                                        }}
                                                    />
                                                )}
                                                {getTitleText && (
                                                    <FullTextPopup text={getTitleText(card)}>
                                                        <span className={styles['organization-carousel-container__card__title']}>
                                                            {getTitleText(card)}
                                                        </span>
                                                    </FullTextPopup>
                                                )}
                                            </div>
                                            <div className="col-auto px-0 d-flex">
                                                <ActionsButtonNew actions={card.action} onClickOption={(args) => handleClickOption(args, card.id)} />
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <OrganizationCard organization={card} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col-auto px-0 d-flex justify-content-start align-items-center" style={{ marginRight: '2rem' }}>
                            <img
                                src={checkedOrgIds.length > 0 ? icoCheckboxSelected : icoCheckboxEmpty}
                                alt="Checkbox"
                                className={
                                    checkedOrgIds.length > 0
                                        ? `
                                    ${styles['organization-carousel-container__checkbox-icon']}
                                    ${styles['organization-carousel-container__checkbox-icon--selected']}`
                                        : styles['organization-carousel-container__checkbox-icon']
                                }
                                style={{ marginRight: '1rem' }}
                                onClick={() => {
                                    if (checkedOrgIds.length > 0) {
                                        handleUncheckAll()
                                    } else handleCheckAll()
                                }}
                            />
                            <span className={`${styles['organization-carousel-container__checkbox-text']}`}>
                                {checkedOrgIds.length > 0 ? `${checkedOrgIds.length} selected` : `Select all`}
                            </span>
                        </div>
                        {checkedOrgIds.length > 0 && (
                            <div
                                className="col-auto px-0 d-flex justify-content-start align-items-center"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => setDeleteCheckedOrgsModal(true)}
                            >
                                <img src={icoRemoveGradient} alt="Remove" className={styles['organization-carousel-container__remove-icon']} />
                                <span className={`${styles['organization-carousel-container__remove-text']}`}>{t(`modals:remove`)}</span>
                            </div>
                        )}
                        <div className="col px-0 d-flex justify-content-end">
                            <Pagination itemsCount={cards.length} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
