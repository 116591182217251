import styles from './dashboard-configuration.module.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { ModelAliasService, StarkdataService } from '../../../temp/test';
import {
    LoaderForeground,
    PrimaryButton,
    Spinner,
    TerciaryButton,
    ToggleButton,
    TypeInputAlternate
} from '../../../components';
import { success } from '../../../components/notifications/notification/notification';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';
import { errorHandler } from '../../../utils/api';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { AliasList } from '../../../components/dashboard-components/alias-list/alias-list';

/**
 * Provides a page layout that allows users to manage general settings
 */

const AdministrationDashboardConfiguration = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation(false);
    const [loading, toggleLoading] = useToggle(true);

    const [sentimentPageInput, sentimentPageInputToggle] = useToggle(false);

    const [segmentPageInput, segmentPageInputToggle] = useToggle(false);

    const [teamManagementPageInput, teamManagementPageInputToggle] =
        useToggle(false);

    const { replaceValue: productReplaceValue, ...productAlias } =
        useTextInputAlternate({
            name: 'productAlias'
        });
    const [initialProductAlias, setInitialProductAlias] = useState('');
    const [initialProductLevel, setInitialProductLevel] = useState('');
    const [initialProductVersionAlias, setInitialProductVersionAlias] =
        useState('');
    const [initialTargetSegmentAlias, setInitialTargetSegmentAlias] =
        useState('');
    const [productId, setProductId] = useState(null);
    const [productVersionId, setProductVersionId] = useState(null);
    const [targetSegmentId, setTargetSegmentId] = useState(null);
    const { replaceValue: productVersionReplaceValue, ...productVersionAlias } =
        useTextInputAlternate({
            name: 'productVersionAlias'
        });

    const { replaceValue: targetSegmentReplaceValue, ...targetSegmentAlias } =
        useTextInputAlternate({
            name: 'targetSegmentAlias'
        });

    const {
        replaceValue: productAliasLevelReplaceValue,
        ...productAliasLevel
    } = useTextInputAlternate({
        name: 'productAliasLevel',
        isNumber: true
    });

    const {
        replaceValue: productVersionAliasLevelReplaceValue,
        ...productVersionAliasLevel
    } = useTextInputAlternate({
        name: 'productVersionAliasLevel'
    });

    const {
        replaceValue: targetSegmentAliasLevelReplaceValue,
        ...targetSegmentAliasLevel
    } = useTextInputAlternate({
        name: 'targetSegmentAliasLevel'
    });

    useEffect(() => {
        async function fill() {
            try {
                const result =
                    await StarkdataService.getApiBackofficeAdminModulesSubscription();
                sentimentPageInputToggle(
                    result.module_sentiment_analysis || false
                );
                segmentPageInputToggle(result.module_segmentation || false);
                teamManagementPageInputToggle(
                    result.module_team_management || false
                );
                const aliasBack = await ModelAliasService.getApiModelAlias({});
                aliasBack.forEach((alias) => {
                    if (alias.model_name === 'Product') {
                        productReplaceValue(alias.alias);
                        setProductId(alias.model_name);
                        setInitialProductAlias(alias.alias);
                    } else if (alias.model_name === 'ProductVersion') {
                        productVersionReplaceValue(alias.alias);
                        setProductVersionId(alias.model_name);
                        setInitialProductVersionAlias(alias.alias);
                    } else if (alias.model_name === 'TargetSegment') {
                        targetSegmentReplaceValue(alias.alias);
                        setTargetSegmentId(alias.model_name);
                        setInitialTargetSegmentAlias(alias.alias);
                    }
                });
                toggleLoading(false);
            } catch (e) {
                errorHandler(e);
            }
        }
        fill();
    }, [
        isMounted,
        productReplaceValue,
        productVersionReplaceValue,
        targetSegmentReplaceValue,
        toggleLoading,
        sentimentPageInputToggle,
        segmentPageInputToggle,
        teamManagementPageInputToggle
    ]);

    const handleSaveConfiguration = async () => {
        await StarkdataService.patchApiBackofficeAdminModulesSubscription({
            requestBody: {
                module_segmentation: segmentPageInput,
                module_sentiment_analysis: sentimentPageInput,
                module_team_management: teamManagementPageInput
            }
        });
        if (initialProductAlias !== productAlias.value) {
            await ModelAliasService.patchApiModelAlias({
                modelName: productId,
                requestBody: { alias: productAlias.value }
            });
        }
        if (initialProductVersionAlias !== productVersionAlias.value) {
            await ModelAliasService.patchApiModelAlias({
                modelName: productVersionId,
                requestBody: { alias: productVersionAlias.value }
            });
        }
        if (initialTargetSegmentAlias !== targetSegmentAlias.value) {
            await ModelAliasService.patchApiModelAlias({
                modelName: targetSegmentId,
                requestBody: { alias: targetSegmentAlias.value }
            });
        }
        success({ text: t('notifications:settingsSaved') });
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['dashboard-configuration-container']}`}
                >
                    <div className="col px-0 h-100 align-items-center">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <span className="title me-4">
                                    {t('pages:dashboardConfiguration.title')}
                                </span>
                            </div>
                        </div>
                        <div
                            className={`row mx-0 w-100 ${styles['dashboard-configuration-container__section']}`}
                            style={{ marginBottom: '2rem' }}
                        >
                            <div
                                className={`col p-0 h-auto d-flex flex-column justify-content-start align-items-center ${styles['dashboard-configuration-container__content-wrapper']}`}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col ${styles['dashboard-configuration-container__content']}`}
                                            >
                                                <div
                                                    className={`row mx-0 w-100 ${styles['dashboard-configuration-container__content-wrapper__title-wrapper']}`}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['dashboard-configuration-container__content-wrapper__title-wrapper__title']}`}
                                                        >
                                                            {t(
                                                                'pages:customDashboard.settings.options.general'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="col-2 px-0 d-flex justify-content-end align-items-center">
                                                        <TerciaryButton
                                                            text={t(
                                                                'buttons:save'
                                                            )}
                                                            onClick={() => {
                                                                handleSaveConfiguration();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <hr
                                                    className={`${styles['dashboard-configuration-container__content-wrapper__separator']}`}
                                                />
                                                <div
                                                    className={`row mx-0 w-100 ${styles['dashboard-configuration-container__content-wrapper__options-wrapper']}`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['dashboard-configuration-container__input-container']}`}
                                                        >
                                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                                <span
                                                                    className={`${styles['dashboard-configuration-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        'settings:sentimentDashboard'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 px-0 d-flex justify-content-end align-items-center">
                                                                <ToggleButton
                                                                    bool={
                                                                        sentimentPageInput
                                                                    }
                                                                    onClick={
                                                                        sentimentPageInputToggle
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`row mx-0 w-100 ${styles['dashboard-configuration-container__content-wrapper__options-wrapper']}`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['dashboard-configuration-container__input-container']}`}
                                                        >
                                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                                <span
                                                                    className={`${styles['dashboard-configuration-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        'settings:segmentDashboard'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 px-0 d-flex justify-content-end align-items-center">
                                                                <ToggleButton
                                                                    bool={
                                                                        segmentPageInput
                                                                    }
                                                                    onClick={
                                                                        segmentPageInputToggle
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`row mx-0 w-100 ${styles['dashboard-configuration-container__content-wrapper__options-wrapper']}`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['dashboard-configuration-container__input-container']}`}
                                                        >
                                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                                <span
                                                                    className={`${styles['dashboard-configuration-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        'settings:teamManagementDashboard'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 px-0 d-flex justify-content-end align-items-center">
                                                                <ToggleButton
                                                                    bool={
                                                                        teamManagementPageInput
                                                                    }
                                                                    onClick={
                                                                        teamManagementPageInputToggle
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`row mx-0 w-100`}>
                            <div className="col px-0">
                                <AliasList />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default AdministrationDashboardConfiguration;

AdministrationDashboardConfiguration.propTypes = {};
