import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import {
    getDayFromDateFormat,
    getMonthFromDateFormat,
    getTimeFromDateFormat,
    getYearFromDateFormat,
    isSameYearSameMonthSameDay
} from '../../../utils/date.js';
import { useIsMounted } from '../../../hooks/useIsMounted.js';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent.js';
import { useChartReady } from '../../../providers/chart-ready-context.js';
import { billingHistoryMock } from './mock.jsx';
import {
    ContentContainer,
    DynamicComposedChartContainer,
    Loader,
    Spinner
} from '../../';
import { BillingService } from '../../../temp/test';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation.js';
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush.jsx';

/**
 * Displays evolution of billing over time
 */

const Chart = withCustomBrush(DynamicComposedChartContainer);

export const BillingHistory = React.memo(({ isPreview }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const { charts } = useChartReady();
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(billingHistoryMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(billingHistoryMock);
            try {
                const timeBillingBack =
                    await BillingService.getApiBackofficeBillingSentiment({
                        minDate: undefined,
                        maxDate: undefined
                    });

                dataClone.data.length = 0;
                Object.entries(timeBillingBack).map((el) => {
                    let newEl = {
                        'revenue-billing': el[1].toFixed(1),
                        'revenueAux-billing': el[1].toFixed(1),
                        'minRevenueAux-billing': el[1].toFixed(1),
                        time: getTimeFromDateFormat(el[0]),
                        day: getDayFromDateFormat(el[0], t),
                        month: getMonthFromDateFormat(el[0], t),
                        year: getYearFromDateFormat(el[0])
                    };
                    let idx = dataClone.data.findIndex((v) => {
                        return isSameYearSameMonthSameDay(v.time, newEl.time);
                    });
                    if (idx !== -1) {
                        newEl.billingRevenue =
                            dataClone.data[idx].billingRevenue;
                        dataClone.data[idx] = newEl;
                    } else {
                        dataClone.data.push(newEl);
                    }
                    return null;
                });
                dataClone.categoryAxis = 'month';
                if (!isPreview) dataClone.dateGrouper = 'month';
                dataClone.interactionDisabled = false;
                dataClone.tooltipType = 'billingHistory-monthly';
                dataClone.loading = false;
                dataClone.ready = true;
                dataClone.areaAttr = [
                    {
                        dataKey: 'minRevenueAux-billing',
                        color: '#2cd9c5',
                        strokeWidth: 2
                    },
                    {
                        dataKey: 'revenueAux-billing',
                        color: '#166e64',
                        strokeWidth: 2
                    },
                    {
                        dataKey: 'revenue-billing',
                        color: '#2cd9c5',
                        strokeWidth: 2
                    }
                ];
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setLoading(true);
                    setValue(dataClone);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        fill();
    }, [isMounted, setLoading, setValue, t, isPreview]);

    return (
        <div
            className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
            >
                {data.loading && <Spinner />}
                {!data.loading && <Chart {...data} />}
            </ContentContainer>
        </div>
    );
});

BillingHistory.propTypes = {};
