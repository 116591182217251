import React from 'react'
import styles from './Roles.module.scss'
import { RoleOverview } from './components/role-overview/role-overview'
import { PermissionsOverview } from './components/permissions-overview/permissions-overview'

export const Roles = () => {
    return (
        <div className={`${styles['roles-container']}`}>
            <div className="row mx-0 w-100 h-100">
                <div className="col-8 px-0">
                    <RoleOverview />
                </div>
                <div
                    className="col-auto px-0"
                    style={{
                        width: '4rem',
                    }}
                ></div>
                <div className="col px-0 d-flex flex-column">
                    <PermissionsOverview />
                </div>
            </div>
        </div>
    )
}
