import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TeamsService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { useFilter } from '../../../providers/filter-context'
import icoStarkSmall from '../../../assets/images/logo-small.jpg'
import {
    DeleteButton,
    Modal,
    PrimaryButton,
    SecondaryButton,
    SuccessModal,
} from '../..'
import { usePub } from '../../../hooks/usePubSub'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const DisassociateTeamModal = ({ teamId, onCloseModal, onEnd }) => {
    const publish = usePub()
    const { t } = useCustomTranslation()
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const { filter } = useFilter()

    const handleDisassociateTeam = async () => {
        try {
            setLoading(true)
            await TeamsService.deleteApiTeamsProducts({
                teamId: teamId,
                requestBody: filter.productId ? [filter.productId] : undefined,
            })
            if (onEnd) {
                onEnd()
            }
            setLoading(false)
            setIsSuccess(true)
            publish('product')
        } catch (err) {
            errorHandler(err)
        }
    }

    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                    <div className="col px-0 d-flex justify-content-center align-items-center">
                        <img
                            src={icoStarkSmall}
                            alt="Stark"
                            style={{
                                width: '8rem',
                                height: '8rem',
                            }}
                        />
                    </div>
                </div>
                <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                    <div className="col px-0 d-flex justify-content-center align-items-center">
                        <span className="modal-subtitle">
                            {t('modals:disassociateTeam.title')}
                        </span>
                    </div>
                </div>
                <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                    <div className="col px-0 d-flex justify-content-center align-items-center">
                        <span className="modal-content">
                            {t('modals:disassociateTeam.description')}
                        </span>
                    </div>
                </div>

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1',
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton
                            text={t('modals:no')}
                            onClick={onCloseModal}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('modals:yes')}
                            onClick={handleDisassociateTeam}
                        />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return (
            <SuccessModal
                text={t('modals:disassociateTeam.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        )
    }
}
