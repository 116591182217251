import { createContext, useContext } from 'react'

const DashBoardSettingsContext = createContext({
    showEditDashboard: undefined,
    isEdit: () => {},
    isDraft: undefined,
    toggleIsEdit: () => {},
    isCustom: undefined,
})

export function DashboardSettingsProvider({ value, children }) {
    return (
        <DashBoardSettingsContext.Provider value={value}>
            {children}
        </DashBoardSettingsContext.Provider>
    )
}

export function useDashboardSettings() {
    return useContext(DashBoardSettingsContext)
}
