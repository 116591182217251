import React from 'react'

import icoDelete from '../../../assets/images/ico-delete.svg'
import icoPlus from '../../../assets/images/ico-plus.svg'

export const dynamicTablesListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'tableName', label: '', sortable: true },
        { path: 'tableType', label: '', sortable: true },
        { path: 'variables', label: '', sortable: true },
        { path: 'action', label: '', sortable: false },
    ],
    sortColumn: { path: 'entity', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'dynamicTablesTable',
    disabled: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: [
                { name: 'addDTable', label: '', icon: icoPlus },
                { name: 'removeDTable', label: '', icon: icoDelete },
            ],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [{ name: 'addDTable', label: '', icon: icoPlus }],
        },
        reference: React.createRef(),
    },
}
