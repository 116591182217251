import React from 'react';
import styles from './ProgressCircle.module.scss';

export const ProgressCircle = ({ currentDate, maxDate }) => {
    const radius = 90; // Radius of the circle
    const strokeWidth = 10; // Width of the stroke
    const normalizedRadius = radius - strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    // Calculate the difference in time between the two dates
    const maxTime = maxDate.getTime();
    const currentTime = currentDate.getTime();
    const timeDifference = maxTime - currentTime; // Milliseconds difference

    // Calculate total time from start date to max date
    const totalTime =
        maxDate.getTime() - new Date(maxDate.getFullYear(), 0, 1).getTime();

    // Calculate the percentage of time passed
    const percentage = ((totalTime - timeDifference) / totalTime) * 100;

    // Calculate the stroke dash offset
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    // Calculate remaining time in days or months
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const remainingMonths = Math.ceil(remainingDays / 30); // Approximate the number of months

    // Decide whether to show days or months
    const timeUnitString = remainingMonths > 1 ? 'months' : 'days';
    const timeLeftString =
        remainingMonths > 1 ? `${remainingMonths}` : `${remainingDays}`;
    const leftString = 'left';

    // Rotate the circle by -90 degrees to start from the top
    const rotationTransform = `rotate(-90 ${radius} ${radius})`;

    // Text positioning
    const totalLines = 3;
    const lineHeight = 20; // Adjust based on font size and desired spacing
    // Calculate the start y position so that the text group is centered
    const startY = radius - ((totalLines - 1) * lineHeight) / 2;

    return (
        <svg
            height={radius * 2}
            width={radius * 2}
            viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        >
            <defs>
                <linearGradient
                    id="textGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                >
                    <stop offset="0%" stopColor="#60C9F6BF" />
                    <stop offset="100%" stopColor="#127DAA" />
                </linearGradient>
                <linearGradient
                    id="progressGradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                >
                    <stop offset="0%" stopColor="#60C9F6BF" />
                    <stop offset="100%" stopColor="#127DAA" />
                </linearGradient>
            </defs>
            <circle
                stroke="url(#progressGradient)"
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset }}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                transform={rotationTransform}
                strokeLinecap="round"
            />
            <circle
                stroke="#1A82AE1A"
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference + ' ' + circumference}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                transform={rotationTransform}
            />
            <text
                x="50%"
                y={`${startY}px`} // Adjusted
                dominantBaseline="middle"
                textAnchor="middle"
                className={styles['progress-circle-container__text']}
                fill="url(#textGradient)"
            >
                {timeLeftString}
            </text>
            <text
                x="50%"
                y={`${startY + lineHeight}px`} // Adjusted
                dominantBaseline="middle"
                textAnchor="middle"
                className={styles['progress-circle-container__text']}
                fill="url(#textGradient)"
            >
                {timeUnitString}
            </text>
            <text
                x="50%"
                y={`${startY + lineHeight * 2}px`} // Adjusted
                dominantBaseline="middle"
                textAnchor="middle"
                className={styles['progress-circle-container__text']}
                fill="url(#textGradient)"
            >
                {leftString}
            </text>
        </svg>
    );
};

export default ProgressCircle;
