export const activityHistoryMock = {
    loading: false,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: false,
    attr: 'activityChart',
    isForecast: false,
    titleText: '',
    areaAttr: [
        {
            dataKey: 'activityCounter',
            color: '#8c54ff',
            strokeWidth: 2
        }
    ],
    categoryAxis: 'day',
    data: [
        {
            activityCounter: 15,
            time: '2024-01-12',
            day: '12 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 29,
            time: '2024-01-13',
            day: '13 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 12,
            time: '2024-01-14',
            day: '14 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 4,
            time: '2024-01-15',
            day: '15 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 8,
            time: '2024-01-16',
            day: '16 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 10,
            time: '2024-01-17',
            day: '17 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 15,
            time: '2024-01-18',
            day: '18 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 10,
            time: '2024-01-19',
            day: '19 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 12,
            time: '2024-01-20',
            day: '20 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 4,
            time: '2024-01-21',
            day: '21 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 8,
            time: '2024-01-22',
            day: '22 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 10,
            time: '2024-01-23',
            day: '23 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 15,
            time: '2024-01-24',
            day: '24 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        },
        {
            activityCounter: 13,
            time: '2024-01-25',
            day: '25 Jan 2024',
            month: 'Jan 2024',
            year: '2024'
        }
    ],
    dateGrouper: '',
    gradientOn: true,
    lineType: 'monotone',
    maxValue: 25,
    minValue: 2,
    noForecast: true,
    laguange: 'pt',
    tooltipType: 'activityHistory-daily',
    unitsOrValues: 'units',
    margin: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
    },
    interactionDisabled: false,
    ready: true
};
