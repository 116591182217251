import React from 'react'
import { getDots } from '../../../../../utils/string'
import styles from './sentiment-box-table-column.module.scss'

export const SentimentBoxTableColumn = React.memo(
    ({ el, column, sentiment }) => {
        return (
            <div
                className={`${styles['sentiment-box-table-column-container']}`}
            >
                <div
                    className={`${styles['sentiment-box-table-column-container__sentiment-box']}`}
                    style={
                        sentiment === 'positive'
                            ? { backgroundColor: '#7dd081' }
                            : sentiment === 'neutral'
                            ? { backgroundColor: '#f0bb5f' }
                            : sentiment === 'negative'
                            ? {
                                  backgroundColor: '#db6162',
                              }
                            : {}
                    }
                >
                    <span
                        className={`${styles['sentiment-box-table-column-container__content']}`}
                    >
                        {el[column.path].toString().substring(0, 100) +
                            getDots(el[column.path].toString())}
                        %
                    </span>
                </div>
            </div>
        )
    }
)
