import React from 'react';
import styles from './custom-centered-label.module.scss';

export const CustomCenteredLabel = React.memo(
    ({ x, y, width, height, value, labelType }) => {
        if (value) {
            return (
                <text
                    x={x + width / 2}
                    y={y + height / 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ pointerEvents: 'none' }}
                    className={`${styles['custom-centered-label']}`}
                >
                    {labelType === 'percentage' && value.toFixed(2) + '%'}
                    {labelType !== 'percentage' && value.toFixed(2)}
                </text>
            );
        } else return null;
    }
);
