import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ComposedChart, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Rectangle, Text, ResponsiveContainer, Line, Cell } from 'recharts'
import styles from './dynamic-composed-chart-container.module.scss'
import { CustomActiveDot, CustomDot, CustomTextTick, CustomTick, TooltipFactory } from '../..'
import useCursorTracking from '../../../hooks/useCursorTracking'
import { useCursorTrackingProvider } from '../../../providers/cursor-tracking-context'

const CircleTipRectangleBar = (props) => {
    const { fill, x, y, width, height, radius } = props
    return (
        <g>
            <rect x={x + width / 2} y={y} width={'4'} height={height} fill={fill} />
            <circle cx={x + width / 2 + 2} cy={y} r="7.5" fill={'#F7CA96'} />
        </g>
    )
}

export const DynamicComposedChartContainer = React.memo(
    ({
        startYAtZero,
        margin,
        categoryAxis,
        data,
        lineType = 'monotone',
        lineAttr,
        areaAttr,
        barAttr,
        tooltipType,
        translate,
        barFilter,
        gradient,
        unitsOrValues,
        forecastValue,
        forecastIntervalValue,
        startsAtZero,
        hideAttr,
        attr,
        startForecast,
        hasCircleTip,
    }) => {
        const [animationActive, setAnimationActive] = React.useState(true)
        const [minValue, setMinValue] = useState(0)
        const [maxValue, setMaxValue] = useState(0)
        const { position } = useCursorTrackingProvider()
        const [animationEnded] = useState(true)
        const [hoveredElement, setHoveredElement] = useState({
            id: '',
            dataKey: '',
            index: '',
        })
        const [hoveredType, setHoveredType] = useState('')
        const [hoveredColor, setHoveredColor] = useState('')
        const [cx, setCx] = useState('')
        const [cy, setCy] = useState('')
        const handleMouseOverAreaDot = (id, dataKey, cx, cy, index) => {
            setHoveredElement({
                id,
                dataKey,
                index,
            })
            setHoveredType('dot')
            setCx(cx)
            setCy(cy)
            if (areaAttr) {
                setHoveredColor(_.find(areaAttr, { dataKey }).color || _.find(lineAttr, { dataKey }).color || '')
            } else if (lineAttr) {
                setHoveredColor(_.find(lineAttr, { dataKey }).color || '')
            }
        }

        const handleMouseLeave = () => {
            setHoveredElement({
                id: '',
                dataKey: '',
                index: '',
            })
            setHoveredType('')
            setCx('')
            setCy('')
        }

        const handleMouseOverBar = (id, dataKey, color) => {
            setHoveredElement({
                id,
                dataKey,
                index: '',
            })
            setHoveredType('bar')
            setHoveredColor(color)
        }

        const CustomCursor = (props) => {
            if (
                (animationEnded || data.length === 1) &&
                props.payload &&
                hoveredElement.id !== '' &&
                hoveredType === 'dot' &&
                props.payloadIndex === hoveredElement.index &&
                ((barFilter && props.payload[hoveredElement.id + 1].dataKey === hoveredElement.dataKey) ||
                    (!barFilter && props.payload[hoveredElement.id].dataKey === hoveredElement.dataKey))
            ) {
                return (
                    <React.Fragment>
                        <Rectangle fill="url(#gradientCursorVertical)" x={props.points[0].x - 25} width={50} radius={10} height={props.height + 50} />
                        <Rectangle
                            x={cx}
                            width={1}
                            y={10}
                            height={props.height + 10}
                            stroke={
                                (barFilter && props.payload[hoveredElement.id + 1].color) || (!barFilter && props.payload[hoveredElement.id].color)
                            }
                        />
                        <line
                            x1={props.left}
                            y1={cy}
                            x2={cx}
                            y2={cy + 1}
                            stroke={
                                (barFilter && props.payload[hoveredElement.id + 1].color) || (!barFilter && props.payload[hoveredElement.id].color)
                            }
                            strokeWidth={1}
                            strokeDasharray="5 5"
                        />
                    </React.Fragment>
                )
            }
            return null
        }

        const CustomBar = (props) => {
            const { x, width, height, fill, opacity } = props
            return (
                <Rectangle
                    x={x}
                    y={props.background.height + props.background.y - height}
                    width={width}
                    height={height}
                    fill={fill}
                    opacity={opacity}
                    radius={[2, 2, 0, 0]}
                />
            )
        }

        const CustomTickExternal = (props) => {
            const { payload } = props
            if (barFilter === 'avgT') {
                return (
                    <Text {...props} className={`${styles['custom-tick']}`}>
                        {payload.value + 'ºC'}
                    </Text>
                )
            } else if (barFilter === 'avgH') {
                return (
                    <Text {...props} className={`${styles['custom-tick']}`}>
                        {payload.value + '%'}
                    </Text>
                )
            } else if (barFilter === 'avgP') {
                return (
                    <Text {...props} className={`${styles['custom-tick']}`}>
                        {payload.value + 'mm'}
                    </Text>
                )
            }
            return null
        }

        const dataParsed = useMemo(() => {
            let newMinValue = 1000000
            let newMaxValue = -1000000
            if (areaAttr && areaAttr.length !== 0 && data && data.length !== 0) {
                data.forEach((item) => {
                    areaAttr.forEach((attr) => {
                        if (item[attr.dataKey] !== undefined) {
                            //check if it is an array or string
                            if (Array.isArray(item[attr.dataKey])) {
                                item[attr.dataKey].forEach((value, index) => {
                                    item[attr.dataKey][index] = parseFloat(value)
                                    if (item[attr.dataKey][index] < newMinValue) {
                                        newMinValue = item[attr.dataKey][index]
                                    }
                                    if (item[attr.dataKey][index] > newMaxValue) {
                                        newMaxValue = item[attr.dataKey][index]
                                    }
                                })
                            } else {
                                item[attr.dataKey] = parseFloat(item[attr.dataKey])
                                if (item[attr.dataKey] < newMinValue) {
                                    newMinValue = item[attr.dataKey]
                                }
                                if (item[attr.dataKey] > newMaxValue) {
                                    newMaxValue = item[attr.dataKey]
                                }
                            }
                        }
                    })
                })
            }
            if (lineAttr && lineAttr.length !== 0 && data && data.length !== 0) {
                data.forEach((item) => {
                    lineAttr.forEach((attr) => {
                        if (item[attr.dataKey] !== undefined) {
                            //check if it is an array or string
                            if (Array.isArray(item[attr.dataKey])) {
                                item[attr.dataKey].forEach((value, index) => {
                                    item[attr.dataKey][index] = parseFloat(value)
                                    if (item[attr.dataKey][index] < newMinValue) {
                                        newMinValue = item[attr.dataKey][index]
                                    }
                                    if (item[attr.dataKey][index] > newMaxValue) {
                                        newMaxValue = item[attr.dataKey][index]
                                    }
                                })
                            } else {
                                item[attr.dataKey] = parseFloat(item[attr.dataKey])
                                if (item[attr.dataKey] < newMinValue) {
                                    newMinValue = item[attr.dataKey]
                                }
                                if (item[attr.dataKey] > newMaxValue) {
                                    newMaxValue = item[attr.dataKey]
                                }
                            }
                        }
                    })
                })
            }
            if (barAttr && barAttr.length !== 0 && data && data.length !== 0) {
                data.forEach((item) => {
                    barAttr.forEach((attr) => {
                        if (item[attr.dataKey] !== undefined) {
                            //check if it is an array or string
                            if (Array.isArray(item[attr.dataKey])) {
                                item[attr.dataKey].forEach((value, index) => {
                                    item[attr.dataKey][index] = parseFloat(value)
                                    if (item[attr.dataKey][index] < newMinValue) {
                                        newMinValue = item[attr.dataKey][index]
                                    }
                                    if (item[attr.dataKey][index] > newMaxValue) {
                                        newMaxValue = item[attr.dataKey][index]
                                    }
                                })
                            } else {
                                item[attr.dataKey] = parseFloat(item[attr.dataKey])
                                if (item[attr.dataKey] < newMinValue) {
                                    newMinValue = item[attr.dataKey]
                                }
                                if (item[attr.dataKey] > newMaxValue) {
                                    newMaxValue = item[attr.dataKey]
                                }
                            }
                        }
                    })
                })
            }
            setMinValue(startsAtZero ? 0 : newMinValue * 0.8)
            setMaxValue(newMaxValue * 1.2)
            return data
        }, [areaAttr, data, lineAttr, barAttr, startsAtZero])

        return (
            <ResponsiveContainer key={data.length} className={`${styles['dynamic-composed-chart-container']} lines-to-remove`}>
                <ComposedChart
                    data={dataParsed}
                    margin={
                        margin || {
                            top: 0,
                            bottom: 0,
                            left: 10,
                            right: barAttr ? 0 : 40,
                        }
                    }
                >
                    <defs>
                        <linearGradient id="gradientCursor" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor="#009af5" stopOpacity="0.5" />
                            <stop offset="50%" stopColor="#83a2fa" stopOpacity="0.3" />
                            <stop offset="100%" stopColor="#9edaed" stopOpacity="0" />
                        </linearGradient>
                        <linearGradient id="gradientCursorVertical" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#009af5" stopOpacity="0.3" />
                            <stop offset="50%" stopColor="#83a2fa" stopOpacity="0.1" />
                            <stop offset="100%" stopColor="#9edaed" stopOpacity="0" />
                        </linearGradient>

                        {areaAttr &&
                            areaAttr.length !== 0 &&
                            areaAttr.map((attr, index) => {
                                if (_.has(attr, 'isGradient') && attr.isGradient) {
                                    return (
                                        <linearGradient key={index} id={attr.dataKey} x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={attr.color} stopOpacity={0.8} />
                                            <stop offset="95%" stopColor={attr.color} stopOpacity={0} />
                                        </linearGradient>
                                    )
                                }
                            })}
                        {barAttr &&
                            barAttr.length !== 0 &&
                            barAttr.map((attr, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <linearGradient id={attr.dataKey} x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={attr.color} stopOpacity={0.8} />
                                            <stop offset="95%" stopColor={attr.color} stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id={attr.dataKey + 'Axis'} x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                                            <stop offset="5%" stopColor={attr.color} stopOpacity={0.8} />
                                            <stop offset="80%" stopColor={attr.color} stopOpacity={0} />
                                        </linearGradient>
                                    </React.Fragment>
                                )
                            })}
                    </defs>
                    <CartesianGrid strokeDasharray="5" vertical={false} />
                    <XAxis
                        type="category"
                        tickLine={false}
                        dataKey={categoryAxis}
                        dy={10}
                        tickSize={10}
                        height={60}
                        axisLine={false}
                        tick={<CustomTextTick />}
                        padding={{
                            left: 10,
                            right: 10,
                        }}
                    />
                    <YAxis
                        yAxisId="left"
                        type="number"
                        tickLine={false}
                        axisLine={false}
                        padding={{
                            top: 10,
                            bottom: 10,
                        }}
                        domain={[minValue, maxValue]}
                        ticks={
                            startYAtZero
                                ? [
                                      0,
                                      minValue + ((maxValue - minValue) / 5) * 4,
                                      minValue + ((maxValue - minValue) / 5) * 3,
                                      minValue + ((maxValue - minValue) / 5) * 2,
                                      minValue + ((maxValue - minValue) / 5) * 1,
                                      minValue,
                                  ]
                                : [
                                      minValue + ((maxValue - minValue) / 5) * 5,
                                      minValue + ((maxValue - minValue) / 5) * 4,
                                      minValue + ((maxValue - minValue) / 5) * 3,
                                      minValue + ((maxValue - minValue) / 5) * 2,
                                      minValue + ((maxValue - minValue) / 5) * 1,
                                      minValue,
                                  ]
                        }
                        tick={<CustomTick />}
                    />

                    {barAttr && (
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            type="number"
                            tickMargin={50}
                            domain={[minValue, maxValue]}
                            axisLine={false}
                            padding={{
                                top: 10,
                                bottom: 10,
                            }}
                            ticks={[
                                minValue + ((maxValue - minValue) / 5) * 5,
                                minValue + ((maxValue - minValue) / 5) * 4,
                                minValue + ((maxValue - minValue) / 5) * 3,
                                minValue + ((maxValue - minValue) / 5) * 2,
                                minValue + ((maxValue - minValue) / 5) * 1,
                                minValue,
                            ]}
                            tick={CustomTickExternal}
                        />
                    )}

                    {barAttr &&
                        barAttr.map((attr, index) => {
                            const { isForecast } = attr
                            return (
                                <Bar
                                    yAxisId="right"
                                    key={index + '-' + attr.dataKey}
                                    dataKey={barAttr[index].dataKey}
                                    fillOpacity={isForecast ? 0.6 : 1}
                                    fill={'url(#' + barAttr[index].dataKey + ')'}
                                    strokeDasharray={isForecast ? '5 5' : null}
                                    strokeWidth={isForecast ? 1 : 0}
                                    stroke={barAttr[index].color}
                                    onMouseOver={() => {
                                        handleMouseOverBar(index, barAttr[index].dataKey, barAttr[index].color)
                                    }}
                                    onMouseLeave={handleMouseLeave}
                                    shape={hasCircleTip && <CircleTipRectangleBar />}
                                >
                                    {data.map((entry, index) => {
                                        const isForecast = (startForecast && entry['time'] >= startForecast) || attr['isForecast']
                                        return (
                                            <Cell
                                                radius={[4, 4, 0, 0]}
                                                key={`cell-${index}`}
                                                strokeDasharray={isForecast ? '5 5' : null}
                                                strokeWidth={isForecast ? 1 : 0}
                                                stroke={attr.color}
                                            />
                                        )
                                    })}
                                </Bar>
                            )
                        })}
                    {tooltipType && (
                        <Tooltip
                            content={
                                <TooltipFactory
                                    item={{
                                        type: tooltipType.split('-')[0] || '',
                                        forecastIntervalValue: forecastIntervalValue || undefined,
                                        forecastValue: forecastValue || undefined,
                                        interval: tooltipType.split('-')[1] ? tooltipType.split('-')[1] : 'monthly',
                                        unitsOrValues: unitsOrValues || undefined,
                                        translate: translate || undefined,
                                        animationEnded: animationEnded || data.length === 1,
                                        id: hoveredElement.id,
                                        cx: cx,
                                        cy: cy,
                                        color: hoveredColor,
                                        dataKey: hoveredElement.dataKey,
                                        hoveredType: hoveredType,
                                        categoryAxis: categoryAxis,
                                        onMouseOver: handleMouseLeave,
                                    }}
                                />
                            }
                            wrapperStyle={{
                                zIndex: 1000,
                                outline: 'none',
                            }}
                            cursor={<CustomCursor id={hoveredElement.id} />}
                        />
                    )}
                    {areaAttr &&
                        areaAttr.map((attr, index) => {
                            return (
                                <Area
                                    yAxisId="left"
                                    key={index + '-' + attr.dataKey}
                                    type={_.has(attr, 'lineType') ? attr.lineType : lineType}
                                    dataKey={attr.dataKey}
                                    // stackId={stackId}
                                    stroke={_.has(attr, 'hideLine') ? 'none' : attr.color}
                                    strokeWidth={attr.strokeWidth}
                                    strokeDasharray={attr.dashArray}
                                    pointerEvents={'none'}
                                    fill={
                                        _.has(attr, 'forecastGradient') && attr.forecastGradient
                                            ? 'url(#gradientCursor)'
                                            : _.has(attr, 'isTransparent') && attr.isTransparent
                                            ? 'transparent'
                                            : _.has(attr, 'isGradient') && attr.isGradient
                                            ? `url(#${attr.dataKey})`
                                            : attr.color
                                    }
                                    fillOpacity={_.has(attr, 'fillOpacity') ? attr.fillOpacity : 0}
                                    dot={
                                        <CustomDot
                                            handleMouseOverAreaDot={handleMouseOverAreaDot}
                                            stroke={attr.color}
                                            currentIndex={index}
                                            currentDataKey={attr.dataKey}
                                            dataKey={attr.dataKey}
                                            hideDot={_.has(attr, 'hideDot') && attr.hideDot}
                                            isReverseColor={_.has(attr, 'isReverseColor') && attr.isReverseColor}
                                        />
                                    }
                                    activeDot={
                                        <CustomActiveDot
                                            animationEnded={animationEnded || data.length === 1}
                                            hoveredArea={hoveredElement}
                                            handleMouseOverAreaDot={handleMouseOverAreaDot}
                                            handleMouseLeave={handleMouseLeave}
                                            currentIndex={index}
                                            id={hoveredElement.id}
                                        />
                                    }
                                    onMouseOver={handleMouseLeave}
                                    // isAnimationActive={false}
                                    isAnimationActive={animationActive}
                                    onAnimationEnd={() => setAnimationActive(false)}
                                />
                            )
                        })}
                    {lineAttr &&
                        lineAttr.map((attr, index) => {
                            return (
                                <Line
                                    yAxisId="left"
                                    key={index + '-' + attr.dataKey}
                                    type={lineType}
                                    dataKey={attr.dataKey}
                                    // stackId={stackId}
                                    stroke={attr.color}
                                    strokeWidth={attr.strokeWidth}
                                    strokeDasharray={attr.dashArray}
                                    dot={
                                        <CustomDot
                                            handleMouseOverAreaDot={handleMouseOverAreaDot}
                                            currentIndex={index}
                                            currentDataKey={attr.dataKey}
                                            hideDot={_.has(attr, 'hideDot') && attr.hideDot}
                                            isReverseColor={_.has(attr, 'isReverseColor') && attr.isReverseColor}
                                        />
                                    }
                                    activeDot={
                                        <CustomActiveDot
                                            animationEnded={animationEnded || data.length === 1}
                                            hoveredArea={hoveredElement}
                                            handleMouseOverAreaDot={handleMouseOverAreaDot}
                                            handleMouseLeave={handleMouseLeave}
                                            currentIndex={index}
                                            id={hoveredElement.id}
                                        />
                                    }
                                    onMouseOver={handleMouseLeave}
                                    isAnimationActive={animationActive}
                                    onAnimationEnd={() => setAnimationActive(false)}
                                />
                            )
                        })}
                </ComposedChart>
            </ResponsiveContainer>
        )
    }
)

DynamicComposedChartContainer.propTypes = {}
