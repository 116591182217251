export const clientInformationMock = {
    hoverStyle: '',
    desc: '',
    noData: false,
    disabled: true,
    loading: true,
    customInfo: [],
    attr: 'clientOverviewCard',
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: 'VIEW',
            options: []
        },
        options: {
            label: 'OPTIONS',
            options: []
        }
    },
    image: 'https://lh3.googleusercontent.com/places/AAcXr8pcdeYIYU8pNKPhZelSFfO-hTyUq7EdOhk3EvOvYQTeEtPcm0h9oNIddGdsNdFyL1y8FIklrsyJ68k9wKRdRpP36dWvWxyUJ_k=s1600-w1080-h1080',
    name: "John Doe's Restaurant",
    comercialName: '-',
    subTitle: 'Av de John Doe, 1 , 9182-111',
    clientId: '-',
    vendorId: 2,
    location: 'Lisbon',
    contactName: 'John Doe',
    phone: '(351) 911726198',
    email: 'john.doe@test.net',
    open: true
};
