import React from 'react'

import icoPlus from '../../../assets/images/ico-plus.svg'

export const segmentClientsListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    filteredData: [],
    columns: [
        { path: 'id', label: '', sortable: true },
        { path: 'name', label: '', sortable: true },
        { path: 'risk', label: '', sortable: true },
        { path: 'revenue', label: '', sortable: true },
        { path: 'action', label: '', sortable: false },
    ],
    sortColumn: { path: 'name', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'segmentClientsTable',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [{ name: 'addClient', label: '', icon: icoPlus }],
        },
        reference: React.createRef(),
    },
}
