import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IcoCloseModal } from './assets/images/ico-close-modal.svg';
import styles from './step-by-step-modal.module.scss';
import { LoaderForeground, Scrollable } from '../..';

export const StepByStepModal = React.memo(
    ({ steps, currentStep, onToggleModal, children, isLoading }) => {
        const reference = React.createRef();
        const handleOutsideClick = useCallback(
            (event) => {
                if (
                    reference.current &&
                    !reference.current.contains(event.target)
                ) {
                    event.preventDefault();
                    event.stopPropagation();
                    onToggleModal();
                }
            },
            [onToggleModal, reference]
        );

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);
            return function cleanup() {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [handleOutsideClick]);

        return (
            <div className={`${styles['step-by-step-modal-container']}`}>
                <div
                    className={`${styles['step-by-step-modal-container__content']}`}
                >
                    {onToggleModal && (
                        <div
                            className={`${styles['step-by-step-modal-container__content__icon-container']}`}
                        >
                            <IcoCloseModal
                                onClick={onToggleModal}
                                style={{
                                    width: '3rem',
                                    height: '3rem'
                                }}
                            />
                        </div>
                    )}
                    <div
                        className={`${styles['step-by-step-modal-container__content__wrapper']}`}
                        ref={reference}
                    >
                        <div className="row mx-0 w-100 d-flex justify-content-center">
                            {steps &&
                                steps.map((step, index) => {
                                    const isStepSelected =
                                        currentStep >= index
                                            ? styles['selected']
                                            : '';
                                    return (
                                        <div
                                            className="col-auto px-0"
                                            key={index}
                                            style={{
                                                margin: '0 4rem',
                                                width: '18rem'
                                            }}
                                        >
                                            <div
                                                className="row mx-0 w-100 d-flex justify-content-center"
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                <div className="col-auto px-0">
                                                    <div
                                                        className={`${styles['step-by-step-modal-container__step']} ${isStepSelected}`}
                                                    >
                                                        <span
                                                            className={`${styles['step-by-step-modal-container__step__number']}`}
                                                        >
                                                            {index + 1}
                                                        </span>
                                                        {index !==
                                                            steps.length -
                                                                1 && (
                                                            <div
                                                                className={
                                                                    currentStep >
                                                                    index
                                                                        ? `${styles['step-by-step-modal-container__step__connector']} ${styles['filled']}`
                                                                        : styles[
                                                                              'step-by-step-modal-container__step__connector'
                                                                          ]
                                                                }
                                                            ></div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100 d-flex justify-content-center">
                                                <div className="col-auto px-0 d-flex justify-content-center">
                                                    <span
                                                        className={`${styles['step-by-step-modal-container__step__text']} ${isStepSelected}`}
                                                    >
                                                        {step}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0">
                                {isLoading && (
                                    <div className="row mx-0">
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <LoaderForeground
                                                maxSize={18}
                                                speed={4}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div
                                    style={{
                                        maxHeight: '71rem',
                                        height: '100%'
                                    }}
                                >
                                    <Scrollable addRightSpacing>
                                        <div
                                            className="w-100 h-100"
                                            style={{ padding: '2rem' }}
                                        >
                                            {children}
                                        </div>
                                    </Scrollable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

StepByStepModal.propTypes = {
    /**
     * Function to toggle modal
     * */
    onToggleModal: PropTypes.func
};
