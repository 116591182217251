import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLUSTER_COLORS } from '../../../constants/color';
import styles from './segment-top-risk.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const SegmentTopRisk = React.memo(({ segments }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={`${styles['segment-top-risk-container']}`}>
            <div
                className="row mx-0 d-flex justify-content-between "
                style={{ marginBottom: '2rem' }}
            >
                <div className="col-auto px-0">
                    <span
                        className={`${styles['segment-top-risk-container__title']}`}
                    >
                        {t('components:segmentTopRisk.title')}
                    </span>
                </div>
                {/* <div className="col-auto px-0">
                    <span
                        className={`${styles['segment-top-risk-container__sub-title']}`}
                    >
                        {t('components:segmentTopRisk.viewDetails')}
                    </span>
                </div> */}
            </div>
            <div className="row mx-0 flex-grow-1" style={{ minHeight: 0 }}>
                <div
                    className={`col px-0 ${styles['segment-top-risk-container__card-container']}`}
                >
                    {segments.length > 0 &&
                        segments.map((el, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${styles['segment-top-risk-container__card-container__card']} mb-3`}
                                >
                                    <div className={'mb-1'}>
                                        <span
                                            className={`${styles['segment-top-risk-container__card-container__card__sub-title']}`}
                                            style={{ color: CLUSTER_COLORS[0] }}
                                        >
                                            {t(
                                                'components:segmentTopRisk.segment'
                                            )}{' '}
                                            {el.id}
                                        </span>
                                    </div>
                                    <div className={'mb-3'}>
                                        <span
                                            className={`${styles['segment-top-risk-container__card-container__card__title']}`}
                                        >
                                            {el.name}
                                        </span>
                                    </div>
                                    <div
                                        className={`${styles['segment-top-risk-container__card-container__card__risk-container']}`}
                                    >
                                        <div
                                            className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-bar']}`}
                                            style={{
                                                backgroundColor: '#82c49d',
                                                width: `${el.risk1Percent}%`
                                            }}
                                        >
                                            <span
                                                className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-text']}`}
                                            >
                                                {el.risk1Percent
                                                    ? el.risk1Percent + '%'
                                                    : ''}
                                            </span>
                                        </div>
                                        <div
                                            className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-bar']}`}
                                            style={{
                                                backgroundColor: '#e1a43a',
                                                width: `${el.risk2Percent}%`
                                            }}
                                        >
                                            <span
                                                className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-text']}`}
                                            >
                                                {el.risk2Percent
                                                    ? el.risk2Percent + '%'
                                                    : ''}
                                            </span>
                                        </div>
                                        <div
                                            className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-bar']}`}
                                            style={{
                                                backgroundColor: '#f4841b',
                                                width: `${el.risk3Percent}%`
                                            }}
                                        >
                                            {' '}
                                            <span
                                                className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-text']}`}
                                            >
                                                {el.risk3Percent
                                                    ? el.risk3Percent + '%'
                                                    : ''}
                                            </span>
                                        </div>
                                        <div
                                            className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-bar']}`}
                                            style={{
                                                backgroundColor: '#ef9e9c',
                                                width: `${el.risk4Percent}%`
                                            }}
                                        >
                                            <span
                                                className={`${styles['segment-top-risk-container__card-container__card__risk-container__risk-text']}`}
                                            >
                                                {el.risk4Percent
                                                    ? el.risk4Percent + '%'
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    {segments.length === 0 && (
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center flex-grow-1">
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['segment-top-risk-container__no-data-title']}`}
                                >
                                    {t(
                                        'components:segmentTopRisk.noSegmentsAvailable'
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
