import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styles from './date-picker-alternative.module.scss'
import _ from 'lodash'
import icoArrow from '../../../assets/images/dropdown-arrow.svg'
import {
    fromIsoDate,
    toIsoDate,
    toIsoDateFromDate,
} from '../../../utils/format'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

const getDays = (currentDate) => {
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    const firstDayCurrentMonth = new Date(currentYear, currentMonth, 1)
    const firstDayNextMonth = new Date(currentYear, currentMonth + 1, 1)
    currentDate.setMonth(currentDate.getMonth() - 1)
    const lastDayCurrentMonth = new Date(currentYear, currentMonth + 1, 0)
    const lastDayLastMonth = new Date(currentYear, currentMonth, 0)
    const days = []
    _.range(
        lastDayLastMonth.getDate() - lastDayLastMonth.getDay(),
        lastDayLastMonth.getDate() + 1
    ).map((el) => {
        days.push({ month: 'last', day: el })
        return null
    })

    _.range(
        firstDayCurrentMonth.getDate(),
        lastDayCurrentMonth.getDate() + 1
    ).map((el) => {
        days.push({ month: 'current', day: el })
        return null
    })

    _.range(
        firstDayNextMonth.getDate(),
        firstDayNextMonth.getDate() + (7 - lastDayCurrentMonth.getDay() - 1)
    ).map((el) => {
        days.push({ month: 'next', day: el })
        return null
    })
    return days
}

const getDayFromInput = (value, initialDate) => {
    return (
        (value && value.split('/')[0] && parseInt(value.split('/')[0])) ||
        (initialDate &&
            fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[0] &&
            parseInt(
                fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[0]
            )) ||
        new Date().getDate()
    )
}

const getMonthFromInput = (value, initialDate) => {
    return (
        (value && value.split('/')[1] && parseInt(value.split('/')[1])) ||
        (initialDate &&
            fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[1] &&
            parseInt(
                fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[1]
            )) ||
        new Date().getMonth() + 1
    )
}

const getYearFromInput = (value, initialDate) => {
    return (
        (value && value.split('/')[2]) ||
        (initialDate &&
            fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[2]) ||
        new Date().getFullYear()
    )
}

function compareDates(date1, date2) {
    const year1 = date1.getFullYear()
    const month1 = date1.getMonth()
    const day1 = date1.getDate()

    const year2 = date2.getFullYear()
    const month2 = date2.getMonth()
    const day2 = date2.getDate()

    if (year1 === year2 && month1 === month2 && day1 === day2) {
        return 0 // Dates are equal
    } else if (
        year1 < year2 ||
        (year1 === year2 && month1 < month2) ||
        (year1 === year2 && month1 === month2 && day1 < day2)
    ) {
        return -1 // date1 is earlier than date2
    } else {
        return 1 // date1 is later than date2
    }
}

const monthTranslator = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
}

const monthShortTranslator = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez',
}

export const DatePicker = React.memo(
    ({ name, value, onChangeInput, initialDate, finalDate }) => {
        const { t } = useCustomTranslation()
        const [days, setDays] = useState(null)
        const [datePickerDay, setDatePickerDay] = useState(null)
        const [datePickerMonth, setDatePickerMonth] = useState(null)
        const [datePickerYear, setDatePickerYear] = useState(null)
        const handleChangeToPreviousMonth = () => {
            const newDate = new Date(
                datePickerYear,
                datePickerMonth - 1,
                datePickerDay
            )
            newDate.setMonth(newDate.getMonth() - 1)
            setDatePickerDay(newDate.getDate())
            setDatePickerMonth(newDate.getMonth() + 1)
            setDatePickerYear(newDate.getFullYear())
            setDays(getDays(newDate))
        }
        const handleChangeToNextMonth = () => {
            const newDate = new Date(
                datePickerYear,
                datePickerMonth - 1,
                datePickerDay
            )
            newDate.setMonth(newDate.getMonth() + 1)
            setDatePickerDay(newDate.getDate())
            setDatePickerMonth(newDate.getMonth() + 1)
            setDatePickerYear(newDate.getFullYear())
            setDays(getDays(newDate))
        }

        const handleSaveNewDateWithDay = (day) => {
            onChangeInput(
                day + '/' + datePickerMonth + '/' + datePickerYear,
                name,
                'date'
            )
        }

        const checkForInitialAndFinalDateAux = (el2) => {
            let initialGood = false
            let finalGood = false
            if (!initialDate) {
                initialGood = false
            } else if (
                initialDate &&
                compareDates(
                    new Date(
                        datePickerYear,
                        datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                        el2.day
                    ),
                    initialDate
                ) === -1
            ) {
                initialGood = true
            }
            if (!finalDate) {
                finalGood = false
            } else if (
                finalDate &&
                compareDates(
                    new Date(
                        datePickerYear,
                        datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                        el2.day
                    ),
                    finalDate
                ) === 1
            ) {
                finalGood = true
            }
            if (initialGood || finalGood) {
                return true
            }
            return false
        }

        const checkForInitialAndFinalDate = (el2) => {
            let initialGood = false
            let finalGood = false
            if (
                (initialDate &&
                    (compareDates(
                        new Date(
                            datePickerYear,
                            datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                            el2.day
                        ),
                        initialDate
                    ) === 1 ||
                        compareDates(
                            new Date(
                                datePickerYear,
                                datePickerMonth === 0
                                    ? 11
                                    : datePickerMonth - 1,
                                el2.day
                            ),
                            initialDate
                        ) === 0) &&
                    el2.month === 'current') ||
                (!initialDate && el2.month === 'current')
            ) {
                initialGood = true
            }
            if (
                (finalDate &&
                    (compareDates(
                        new Date(
                            datePickerYear,
                            datePickerMonth === 0 ? 11 : datePickerMonth - 1,
                            el2.day
                        ),
                        finalDate
                    ) === -1 ||
                        compareDates(
                            new Date(
                                datePickerYear,
                                datePickerMonth === 0
                                    ? 11
                                    : datePickerMonth - 1,
                                el2.day
                            ),
                            finalDate
                        ) === 0) &&
                    el2.month === 'current') ||
                (!finalDate && el2.month === 'current')
            ) {
                finalGood = true
            }
            if (initialGood && finalGood) {
                return true
            }
            return false
        }

        useEffect(() => {
            const currentDate =
                (value && new Date(toIsoDate(value))) ||
                (initialDate && new Date(initialDate)) ||
                new Date()
            setDays(getDays(currentDate))
            setDatePickerDay(getDayFromInput(value, initialDate))
            setDatePickerMonth(getMonthFromInput(value, initialDate))
            setDatePickerYear(getYearFromInput(value, initialDate))
        }, [value, initialDate])

        if (datePickerMonth && datePickerYear) {
            return (
                <div style={{ padding: '1rem' }}>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col-auto px-0">
                            <img
                                src={icoArrow}
                                alt=""
                                style={{
                                    transform: 'rotate(90deg)',
                                    cursor: 'pointer',
                                    width: '2.4rem',
                                }}
                                onClick={handleChangeToPreviousMonth}
                            />
                        </div>
                        <div className="col px-0"></div>
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['date-picker-alternative-container__title']}`}
                            >
                                {monthTranslator[datePickerMonth]}
                            </span>{' '}
                            <span
                                className={`${styles['date-picker-alternative-container__title']}`}
                            >
                                {datePickerYear}
                            </span>
                        </div>
                        <div className="col px-0"></div>
                        <div className="col-auto px-0">
                            <img
                                src={icoArrow}
                                alt=""
                                style={{
                                    transform: 'rotate(-90deg)',
                                    cursor: 'pointer',
                                    width: '2.4rem',
                                }}
                                onClick={handleChangeToNextMonth}
                            />
                        </div>
                    </div>
                    <div
                        className={`row mx-0 w-100 ${styles['date-picker-alternative-container__row-container']}`}
                    >
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:sunday')}
                            </span>
                        </div>
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:monday')}
                            </span>
                        </div>
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:tuesday')}
                            </span>
                        </div>
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:wednesday')}
                            </span>
                        </div>
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:thursday')}
                            </span>
                        </div>
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:friday')}
                            </span>
                        </div>
                        <div
                            className={`col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-title']}`}
                        >
                            <span
                                className={`${styles['date-picker-alternative-container__row-container__col-container__title']} ${styles['disabled-title']}`}
                            >
                                {t('common:saturday')}
                            </span>
                        </div>
                    </div>
                    {_.chunk(days, 7).map((el, index) => {
                        return (
                            <div
                                key={index}
                                className={`row mx-0 w-100 ${styles['date-picker-alternative-container__row-container']}`}
                                style={
                                    index === _.chunk(days, 7).length - 1
                                        ? { marginBottom: '2rem' }
                                        : {}
                                }
                            >
                                {el.map((el2, index2) => {
                                    return (
                                        <div
                                            key={index2}
                                            className={
                                                checkForInitialAndFinalDate(el2)
                                                    ? datePickerDay === el2.day
                                                        ? `col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['active']}`
                                                        : `col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']}`
                                                    : `col d-flex justify-content-center align-items-center ${styles['date-picker-alternative-container__row-container__col-container']} ${styles['disabled-text']}`
                                            }
                                            onClick={
                                                el2.month === 'current'
                                                    ? () => {
                                                          handleSaveNewDateWithDay(
                                                              el2.day
                                                          )
                                                          setDatePickerDay(
                                                              el2.day
                                                          )
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <span
                                                className={
                                                    checkForInitialAndFinalDateAux(
                                                        el2
                                                    ) || el2.month !== 'current'
                                                        ? `${styles['date-picker-alternative-container__row-container__col-container__text']} ${styles['disabled-text']}`
                                                        : `${styles['date-picker-alternative-container__row-container__col-container__text']}`
                                                }
                                            >
                                                {el2.day}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return null
        }
    }
)

export const DatePickerAlternative = React.memo(
    ({ name, value, onChangeInput, reference, initialDate, finalDate }) => {
        return (
            <div
                className={`${styles['date-picker-alternative-container']}`}
                ref={reference}
            >
                <DatePicker
                    name={name}
                    initialDate={initialDate}
                    finalDate={finalDate}
                    value={value}
                    onChangeInput={onChangeInput}
                />
            </div>
        )
    }
)
