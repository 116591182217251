import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import { hexToRGBA } from '../../../utils/color'
import _ from 'lodash'
import { TooltipWrapper } from '../../formatting/TooltipWrapper/TooltipWrapper'

const ActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, onClick, setHoveredDonutPiece, index } =
        props

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                onMouseEnter={() => {
                    setHoveredDonutPiece(index)
                }}
                onMouseLeave={() => setHoveredDonutPiece(undefined)}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 5}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
                cursor={onClick ? 'pointer' : 'default'}
                onClick={onClick}
                style={{
                    filter: `drop-shadow(0px 0px 5px ${hexToRGBA(payload.color ? payload.color : '#4c4c4c', 0.6)})`,
                    cursor: onClick ? 'pointer' : 'default',
                }}
            />
        </g>
    )
}

const CustomLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, value } = props
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 2
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text
            x={x}
            y={y}
            fontSize={12}
            fontFamily="OpenSansSemiBold"
            fill={props.isName ? props.color : '#fff'}
            textAnchor={'middle'}
            dominantBaseline="central"
            pointerEvents={'none'}
        >
            {props.isName ? props.name : value}
        </text>
    )
}

export const CustomPieChart = React.memo(({ isDonut, donutAttr, data, attr, onFilterData, isName }) => {
    const [animationActive, setAnimationActive] = React.useState(true)
    const [hoveredDonutPiece, setHoveredDonutPiece] = React.useState(undefined)
    const [selectedDonutPiece, setSelectedDonutPiece] = React.useState(undefined)
    const RADIAN = Math.PI / 180
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) + 30
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)
        return (
            <text
                x={x}
                y={y}
                fill={'#4c4c4c'}
                fontSize={'1.2rem'}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                className="label-donut"
            >
                {payload.key1}
            </text>
        )
    }

    return (
        <div className="row mx-0 w-100 h-100">
            <div className="col px-0 d-flex flex-column position-relative h-100">
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 h-100" style={{ zIndex: '1' }}>
                        <TooltipWrapper attr={attr} data={data} hoveredDonutPiece={hoveredDonutPiece} />
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart width={'100%'} height={'100%'}>
                                <defs>
                                    {_.has(data, 'data1') &&
                                        data.data1 &&
                                        data.data1.map((el, index) => {
                                            return (
                                                <linearGradient key={index} id={`gradientCursor${index}`} x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={el.color} stopOpacity={0.8} />
                                                    <stop offset="95%" stopColor={el.color} stopOpacity={0.4} />
                                                </linearGradient>
                                            )
                                        })}
                                </defs>

                                {donutAttr.map((el, index) => {
                                    return (
                                        <Pie
                                            activeIndex={selectedDonutPiece}
                                            activeShape={
                                                <ActiveShape
                                                    index={selectedDonutPiece}
                                                    setHoveredDonutPiece={setHoveredDonutPiece}
                                                    onClick={() => {
                                                        if (onFilterData) {
                                                            setSelectedDonutPiece(undefined)
                                                            onFilterData(undefined)
                                                        }
                                                    }}
                                                />
                                            }
                                            key={el.dataKey}
                                            data={data[el.dataKey]}
                                            dataKey={el.valueKey}
                                            nameKey={el.nameKey}
                                            labelLine={isDonut ? true : false}
                                            label={renderCustomizedLabel}
                                            innerRadius={isDonut ? '40%' : 0}
                                            outerRadius={isDonut ? '50%' : '90%'}
                                            paddingAngle={isDonut ? 2 : 0}
                                            cornerRadius={isDonut ? 10 : 0}
                                            blendStroke={isDonut ? false : true}
                                            isAnimationActive={animationActive}
                                            onAnimationEnd={() => setAnimationActive(false)}
                                        >
                                            {data[el.dataKey].map((entry, index2) => {
                                                return (
                                                    <Cell
                                                        key={`cell-${index2}`}
                                                        fill={`url(#gradientCursor${index2})`}
                                                        style={
                                                            selectedDonutPiece === index2
                                                                ? {
                                                                      filter: `drop-shadow(0px 0px 5px ${hexToRGBA(
                                                                          entry.color ? entry.color : '#4c4c4c',
                                                                          0.6
                                                                      )})`,
                                                                      cursor: onFilterData ? 'pointer' : 'default',
                                                                  }
                                                                : {
                                                                      cursor: onFilterData ? 'pointer' : 'default',
                                                                  }
                                                        }
                                                        onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                        onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                        onClick={() => {
                                                            if (onFilterData) {
                                                                const value =
                                                                    selectedDonutPiece !== undefined && selectedDonutPiece === index2
                                                                        ? undefined
                                                                        : index2

                                                                setSelectedDonutPiece(value)
                                                                onFilterData(value)
                                                            }
                                                        }}
                                                    />
                                                )
                                            })}
                                        </Pie>
                                    )
                                })}
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
})
