import Joi from 'joi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, useNavigate } from 'react-router-dom';
import icoStarkSmall from '../../../assets/images/logo-small.jpg';
import icoCheckCircle from '../../../assets/images/icoCheckCircle.svg';
import { errorHandler } from '../../../utils/api';
import { useTextInput } from '../../../hooks/useTextInput';
import { useToggle } from '../../../hooks/useToggle';
import { useCurrentUser } from '../../../providers/current-user-context';
import { ProfileService } from '../../../temp/test';

import styles from './forgot-password.module.scss';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { emailSchema } from '../../../schemas/fieldSchemas';
import {
    PrimaryButton,
    TypeInput,
    TypeInputAlternate
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { AuthBackgroundWrapper } from '../../../components/backgrounds/AuthBackgroundWrapper/AuthBackgroundWrapper';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';

/**
 * Dashboard allowing users to generate a new password
 */

const ForgotPassword = React.memo(() => {
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const { currentUser } = useCurrentUser();
    const [passwordSent, togglePasswordSent] = useToggle(false);
    const [emailSentTo, setEmailSentTo] = useState('');
    const email = useTextInputAlternate({
        name: 'email',
        schema: emailSchema
    });

    const handleSendNewPassword = async () => {
        try {
            if (isMounted.current) setEmailSentTo(email.value);
            await ProfileService.postApiAuthForgotPassword({
                requestBody: {
                    email: email.value,
                    redirect_url: window.location.origin + '/reset-password'
                }
            });
            if (isMounted.current) togglePasswordSent(true);
        } catch (err) {
            errorHandler(err);
            email.reset();
            setEmailSentTo('');
            togglePasswordSent(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.which === 13 && !(!email.value || email.error)) {
            handleSendNewPassword();
        }
    };

    if (currentUser)
        return (
            <Route path="*" element={<Navigate to={'/dashboard'} replace />} />
        );
    return (
        <AuthBackgroundWrapper
            title={t('pages:forgotPassword.forgot')}
            description={
                !passwordSent && emailSentTo && !email.error
                    ? t('pages:forgotPassword.sendingEmail')
                    : !passwordSent && !emailSentTo
                    ? t('pages:forgotPassword.desc')
                    : passwordSent && emailSentTo
                    ? t('pages:forgotPassword.sentEmail')
                    : undefined
            }
            content={
                <>
                    {passwordSent && emailSentTo && (
                        <React.Fragment>
                            <div
                                className="row mx-0"
                                style={{ marginBottom: '1rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <img
                                        loading="lazy"
                                        src={icoCheckCircle}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div
                                className="row mx-0 d-flex justify-content-end align-items-center"
                                style={{ marginTop: '2rem' }}
                            >
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t('pages:forgotPassword.signIn')}
                                        onClick={() => {
                                            navigate('/login');
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {!passwordSent && !emailSentTo && (
                        <React.Fragment>
                            <div
                                className="row mx-0"
                                style={{ margin: '4rem 0' }}
                            >
                                <div className="col px-0 ">
                                    <TypeInputAlternate
                                        input={email}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-center align-items-center"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <PrimaryButton
                                        disabled={!email.value || email.error}
                                        text={t(
                                            'pages:forgotPassword.sendingPassword'
                                        )}
                                        onClick={handleSendNewPassword}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-center align-items-center">
                                <div
                                    className={`col-auto px-0 ${styles['forgot-password-container__link']}`}
                                    onClick={() => {
                                        navigate('/login');
                                    }}
                                >
                                    <span>
                                        {t('pages:forgotPassword.goBack')}
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </>
            }
        ></AuthBackgroundWrapper>
    );
});

export default ForgotPassword;

ForgotPassword.propTypes = {};
