import Joi from 'joi'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import icoClose from '../../../assets/images/ico-close.svg'
import icoDelete from '../../../assets/images/ico-delete.svg'
import { dynamicTablesListMock } from './mock'

import styles from './dynamic-tables-list.module.scss'
import { useToggle } from '../../../hooks/useToggle'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useArray } from '../../../hooks/useArray'
import { useTable } from '../../../hooks/useTable'
import { useTextInput } from '../../../hooks/useTextInput'
import { useSelectInput } from '../../../hooks/useSelectInput'
import { DTablesService } from '../../../temp/test'
import { verifyComponent } from '../../../utils/component'
import { errorHandler } from '../../../utils/api'
import { ContentContainer, DeleteButton, Loader, PrimaryButton, RadioInput, SecondaryButton, SelectInput, Spinner, TypeInput } from '../..'
import { Modal } from '../../modals/modal/modal'
import { Table } from '../../tables/table/table'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'

/**
 * Displays dynamic tables in a table
 */

export const DynamicTablesList = React.memo(({ onSelectDynamicTable }) => {
    const { t } = useCustomTranslation()
    const isMounted = useIsMounted()
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = useState('')
    const [selectedTableId, setSelectedTableId] = useState('')

    const variablesMemo = useMemo(() => {
        return []
    }, [])

    const {
        array: variables,
        setArray: variablesSetValue,
        push: variablesAddOption,
        remove: variablesRemoveOption,
        reset: variablesReset,
    } = useArray(variablesMemo)

    const variableTypesMemo = useMemo(() => {
        return []
    }, [])

    const { array: variableTypes, setArray: variableTypesSetArray } = useArray(variableTypesMemo)
    const [variablesError, setVariablesError] = useState('')
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon,
    } = useTable(dynamicTablesListMock, modal, '')

    const tableInputMemo = useMemo(() => {
        return {
            name: 'tableName',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
            schema: Joi.string().regex(/^\S+$/).required().trim().messages({
                'string.pattern.base': 'custom:noWhitespace',
            }),
        }
    }, [])

    const tableTypeMemo = useMemo(() => {
        return {
            name: 'tableType',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: React.createRef(),
        }
    }, [])

    const variableInputMemo = useMemo(() => {
        return {
            name: 'variableName',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        }
    }, [])

    const variableTypeMemo = useMemo(() => {
        return {
            name: 'variableType',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: React.createRef(),
        }
    }, [])

    const { value: tableName, reset: resetTableName, setValue: setTableNameValue, setError: setTableNameError } = useTextInput(tableInputMemo)

    const {
        value: tableType,
        addOption: tableTypeAddOption,
        toggleDropdown: tableTypeToggleDropdown,
        selectOption: tableTypeSelectOption,
        keyPress: tableTypeKeyPress,
        keyDown: tableTypeKeyDown,
        setValue: tableTypeSetValue,
        reset: tableTypeReset,
        setError: tableTypeSetError,
    } = useSelectInput(tableTypeMemo)

    const {
        value: inputs,
        clickRadioOption: inputsClickRadioOption,
        setValue: inputsSetValue,
        toggleDropdown: inputsToggleDropdown,
        selectOption: inputsSelectOption,
        setOptions: inputsSetOptions,
        keyPress: inputsKeyPress,
        keyDown: inputsKeyDown,
    } = useMultipleInputs({
        isTableFormat: {
            type: 'radio',
            name: 'isTableFormat',
            direction: 'horizontal',
            options: [
                {
                    name: 'yes',
                    label: t(`inputs:yes`),
                    disabled: false,
                    blocked: false,
                },
                {
                    name: 'no',
                    label: t(`inputs:no`),
                    disabled: false,
                    blocked: false,
                },
            ],
            selectedOption: 'no',
            value: 'no',
        },
        isTimeseriesFormat: {
            type: 'radio',
            name: 'isTimeseriesFormat',
            direction: 'horizontal',
            options: [
                {
                    name: 'yes',
                    label: t(`inputs:yes`),
                    disabled: false,
                    blocked: false,
                },
                {
                    name: 'no',
                    label: t(`inputs:no`),
                    disabled: false,
                    blocked: false,
                },
            ],
            selectedOption: 'no',
            value: 'no',
        },
        timeseriesX: {
            type: 'selectSingle',
            name: 'timeseriesX',
            direction: 'horizontal',
            options: [],
            selectedOption: '',
            value: '',
            reference: React.createRef(),
        },
        timeseriesY: {
            type: 'selectSingle',
            name: 'timeseriesY',
            direction: 'horizontal',
            options: [],
            selectedOption: '',
            value: '',
            reference: React.createRef(),
        },
    })

    const {
        value: variableName,
        reset: resetVariableName,
        setValue: setVariableNameValue,
        setError: setVariableNameError,
    } = useTextInput(variableInputMemo)
    const {
        value: variableType,
        addOption: variableTypeAddOption,
        toggleDropdown: variableTypeToggleDropdown,
        selectOption: variableTypeSelectOption,
        keyPress: variableTypeKeyPress,
        keyDown: variableTypeKeyDown,
        setValue: variableTypeSetValue,
        reset: variableTypeReset,
        setError: variableTypeSetError,
    } = useSelectInput(variableTypeMemo)

    const schema = Joi.object({
        tableName: Joi.string().required(),
        tableType: Joi.string().required(),
        variables: Joi.array().items(Joi.object().required()),
    })

    const variableSchema = Joi.object({
        variableName: Joi.string().required(),
        variableType: Joi.string().required(),
    })

    const getDTables = useCallback(async () => {
        const dataClone = _.cloneDeep(dynamicTablesListMock)
        try {
            dataClone.data.length = 0
            dataClone.sortedData.length = 0
            if (isMounted.current) {
                setValue(dataClone)
                setLoading(true)
            }
            const dTableBack = await DTablesService.getApiBackofficeAdminDtables({
                tableType: [],
            })
            dTableBack.map((el) => {
                let newVariables = ''
                const variableTypes = []
                el.columns.map((el2, index2) => {
                    if (index2 !== 0) {
                        newVariables += ', '
                    }
                    newVariables += el2.variable_name.trim()
                    variableTypes.push({
                        name: el2.variable_name,
                        type: el2.variable_type,
                    })
                    return el2
                })
                variableTypesSetArray(variableTypes)
                dataClone.data.push({
                    id: el.table_id,
                    tableName: el.table_name,
                    tableType: el.table_type ? el.table_type : '',
                    variables: newVariables,
                    action: {
                        label: t(`columns:action`),
                        id: el.table_id,
                        open: false,
                        options: [
                            {
                                name: 'removeDTable',
                                label: t('settings:removeDTable'),
                                icon: icoDelete,
                            },
                        ],
                        reference: React.createRef(),
                    },
                })
                return el
            })
            dataClone.sortedData = _.orderBy(dataClone.data, [dataClone.sortColumn.path], [dataClone.sortColumn.order])
            verifyComponent(dataClone)
            if (isMounted.current) {
                setValue(dataClone)
                setLoading(false)
            }
        } catch (err) {
            errorHandler(err)
            dataClone.loading = false
            if (isMounted.current) {
                setValue(dataClone)
            }
        }
    }, [isMounted, setLoading, setValue, t, variableTypesSetArray])

    useEffect(() => {
        getDTables()
    }, [getDTables])

    const handleCreateDTable = async () => {
        try {
            const result = schema.validate(
                {
                    tableName: tableName.value,
                    tableType: tableType.selectedOption,
                    variables: variables,
                },
                { abortEarly: false }
            )
            if (isMounted.current) {
                setTableNameError()
                tableTypeSetError()
                setVariablesError()
                setVariableNameError()
                variableTypeSetError()
            }
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'tableName') {
                        if (isMounted.current) setTableNameError(el.type)
                    } else if (el.path[0] === 'tableType') {
                        if (isMounted.current) tableTypeSetError(el.type)
                    } else if (el.path[0] === 'variables') {
                        if (isMounted.current) setVariablesError(el.type)
                    }
                    return null
                })
                return
            }
            const columns = []
            variables.map((el) => {
                const dynamicTableColumns = {}
                dynamicTableColumns.variable_name = el.variableName
                dynamicTableColumns.variable_type = el.variableType
                columns.push(dynamicTableColumns)
                return el
            })
            const dynamicTableSchema = {}
            dynamicTableSchema.table_name = tableName.value
            dynamicTableSchema.table_type = tableType.selectedOptionAux
            dynamicTableSchema.columns = columns
            dynamicTableSchema.table_format = inputs.isTableFormat.selectedOption === 'yes' ? true : false
            dynamicTableSchema.timeseries_format = inputs.isTimeseriesFormat.selectedOption === 'yes' ? true : false
            if (inputs.isTimeseriesFormat.selectedOption === 'yes') {
                dynamicTableSchema.timeseries_x = inputs.timeseriesX.selectedOption
                dynamicTableSchema.timeseries_y = inputs.timeseriesY.selectedOption
            }
            await DTablesService.postApiBackofficeAdminDtables({
                requestBody: dynamicTableSchema,
            })
            if (isMounted.current) {
                toggleModal(false)
                setModalType('')
                setSelectedTableId('')
            }
            getDTables()
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleDeleteDTable = async () => {
        try {
            const index = _.findIndex(data.data, (el) => {
                return el.id === selectedTableId
            })
            if (index !== -1) {
                await DTablesService.deleteApiBackofficeAdminDtables({
                    tableName: data.data[index].tableName,
                })
                if (isMounted.current) {
                    toggleModal(false)
                    setModalType('')
                    setSelectedTableId('')
                }
                getDTables()
            }
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleRemoveVariable = (varId) => {
        const varIndex = variables.findIndex((el) => {
            return el.id === varId
        })
        if (varIndex !== -1) {
            if (isMounted.current) variablesRemoveOption(varIndex)
        }
    }

    const addVariable = (varName, varType) => {
        const result = variableSchema.validate(
            {
                variableName: variableName.value,
                variableType: variableType.selectedOption,
            },
            { abortEarly: false }
        )
        if (isMounted.current) {
            setTableNameError()
            tableTypeSetError()
            setVariablesError()
            setVariableNameError()
            variableTypeSetError()
        }
        if (result.error) {
            result.error.details.map((el) => {
                if (el.path[0] === 'variableName') {
                    if (isMounted.current) setVariableNameError(el.type)
                } else if (el.path[0] === 'variableType') {
                    if (isMounted.current) variableTypeSetError(el.type)
                }
                return null
            })
            return
        }
        if (isMounted.current) {
            variablesAddOption({
                id: variables.length,
                variableName: varName,
                variableType: varType,
            })
        }
    }

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false)
            setModalType('')
            setSelectedTableId('')
        }
    }

    useEffect(() => {
        async function fill() {
            if (modal) {
                if (modalType === 'addDTable') {
                    resetTableName()
                    tableTypeReset()
                    resetVariableName()
                    variableTypeReset()
                    variablesReset()
                    try {
                        const dTableTypesBack = await DTablesService.getApiBackofficeAdminDtablesTableTypes()
                        dTableTypesBack.map((el, index) => {
                            if (isMounted.current) tableTypeAddOption(t(`common:${el}`), el)
                            return el
                        })
                        const dTableVarTypesBack = await DTablesService.getApiBackofficeAdminDtablesVarTypes()
                        dTableVarTypesBack.map((el, index) => {
                            if (isMounted.current) variableTypeAddOption(t(`inputs:${el}`), el)
                            return el
                        })
                    } catch (err) {
                        errorHandler(err)
                    }
                }
            }
        }
        fill()
    }, [
        t,
        isMounted,
        tableTypeAddOption,
        variableTypeAddOption,
        modal,
        modalType,
        resetTableName,
        tableTypeReset,
        resetVariableName,
        variableTypeReset,
        variablesReset,
    ])

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedTableId(id)
            }
            if (isMounted.current) {
                toggleComponentSettings(false)
                toggleModal(true)
                setModalType(option)
                resetTableName()
                tableTypeReset()
                resetVariableName()
                variableTypeReset()
                variablesSetValue([])
                setVariablesError('')
            }
            if (option === 'addDTable') {
                try {
                    const dTableTypesBack = await DTablesService.getApiBackofficeAdminDtablesTableTypes()
                    dTableTypesBack.map((el, index) => {
                        if (isMounted.current) tableTypeAddOption(el, index)
                        return el
                    })
                    const dTableVarTypesBack = await DTablesService.getApiBackofficeAdminDtablesVarTypes()
                    dTableVarTypesBack.map((el) => {
                        if (isMounted.current) variableTypeAddOption(el)
                        return el
                    })
                } catch (err) {
                    errorHandler(err)
                }
            }
        },
        [
            isMounted,
            resetTableName,
            resetVariableName,
            tableTypeAddOption,
            tableTypeReset,
            toggleComponentSettings,
            toggleModal,
            variableTypeAddOption,
            variableTypeReset,
            variablesSetValue,
        ]
    )

    const handleSelectDynamicTable = (dTable) => {
        onSelectDynamicTable(dTable, variableTypes)
    }

    // useEffect that add options to timeseriesX and timeseriesY based on the variables added that are of type integer or float
    useEffect(() => {
        const timeseriesXOptions = []
        const timeseriesXOptionsAux = []
        const timeseriesYOptions = []
        const timeseriesYOptionsAux = []
        variables.map((el) => {
            if (el.variableType === 'datetime') {
                timeseriesXOptions.push(el.variableName)
                timeseriesXOptionsAux.push(el.variableName)
            } else if (el.variableType === 'int' || el.variableType === 'float') {
                timeseriesYOptions.push(el.variableName)
                timeseriesYOptionsAux.push(el.variableName)
            }
            return el
        })
        if (isMounted.current) {
            inputsSetOptions(timeseriesXOptions, timeseriesXOptionsAux, 'timeseriesX')
            inputsSetOptions(timeseriesYOptions, timeseriesYOptionsAux, 'timeseriesY')
        }
    }, [variables, inputsSetOptions, isMounted])

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <React.Fragment>
                        {modal && modalType === 'addDTable' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('modals:createDTable')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setTableNameValue} {...tableName} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <SelectInput
                                            onChangeInput={tableTypeSetValue}
                                            onSelectInput={tableTypeSelectOption}
                                            onKeyPress={tableTypeKeyPress}
                                            onKeyDown={tableTypeKeyDown}
                                            onToggleDropdown={tableTypeToggleDropdown}
                                            hideOptionsAux
                                            {...tableType}
                                        />
                                    </div>
                                </div>
                                <hr className="my-0 w-100" />
                                <div className="row mx-0 mt-3 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <span className="modal-subtitle">{t('modals:createDTableVariable')}</span>
                                    </div>
                                </div>

                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setVariableNameValue} {...variableName} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <SelectInput
                                            onChangeInput={variableTypeSetValue}
                                            onSelectInput={variableTypeSelectOption}
                                            onKeyPress={variableTypeKeyPress}
                                            onKeyDown={variableTypeKeyDown}
                                            onToggleDropdown={variableTypeToggleDropdown}
                                            hideOptionsAux
                                            {...variableType}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-3 d-flex flex-column">
                                    <div
                                        className={
                                            variablesError
                                                ? `col ${styles['dynamic-tables-list-container__variables-container']} ${styles['dynamic-tables-list-container__variables-container--error']}`
                                                : `col ${styles['dynamic-tables-list-container__variables-container']}`
                                        }
                                    >
                                        {variables.length === 0 && (
                                            <span className={`${styles['dynamic-tables-list-container__variables-container__placeholder']}`}>
                                                {t('modals:noVariables')}
                                            </span>
                                        )}
                                        {variables.length !== 0 &&
                                            variables.map((el, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`text-label`}
                                                        style={{
                                                            pointer: 'cursor',
                                                        }}
                                                        onClick={() => handleRemoveVariable(el.id)}
                                                    >
                                                        <span>
                                                            {el.variableName} - {t(`inputs:${el.variableType}`)}
                                                        </span>

                                                        <img loading="lazy" src={icoClose} alt="remove variable" />
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    {variablesError && (
                                        <div className={`${styles['dynamic-tables-list-container__variables-container__error']}`}>
                                            {t(`errors:${variablesError}`)}
                                        </div>
                                    )}
                                </div>
                                <div className="row mx-0 mb-3 d-flex justify-content-between align-items-center">
                                    <div className="col-12 px-0">
                                        <PrimaryButton
                                            text={t('modals:addVariable')}
                                            disabled={!variableName.value || !variableType.selectedOptionAux}
                                            onClick={() => addVariable(variableName.value, variableType.selectedOptionAux)}
                                        />
                                    </div>
                                </div>
                                <hr className="my-0 w-100" />
                                <div className="row mx-0 mt-3 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <span className="modal-subtitle">{t('modals:createDTableFormat')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <RadioInput onClickRatioOption={inputsClickRadioOption} {...inputs.isTableFormat} />
                                    </div>
                                </div>
                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <RadioInput onClickRatioOption={inputsClickRadioOption} {...inputs.isTimeseriesFormat} />
                                    </div>
                                </div>
                                {inputs.isTimeseriesFormat.selectedOption === 'yes' && (
                                    <>
                                        <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                            <div className="col px-0">
                                                <SelectInput
                                                    onChangeInput={inputsSetValue}
                                                    onSelectInput={inputsSelectOption}
                                                    onKeyPress={inputsKeyPress}
                                                    onKeyDown={inputsKeyDown}
                                                    onToggleDropdown={inputsToggleDropdown}
                                                    hideOptionsAux
                                                    {...inputs.timeseriesX}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                            <div className="col px-0">
                                                <SelectInput
                                                    onChangeInput={inputsSetValue}
                                                    onSelectInput={inputsSelectOption}
                                                    onKeyPress={inputsKeyPress}
                                                    onKeyDown={inputsKeyDown}
                                                    onToggleDropdown={inputsToggleDropdown}
                                                    hideOptionsAux
                                                    {...inputs.timeseriesY}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                <hr className="my-0 w-100" />
                                <div className="row mx-0 mt-3 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton
                                            text={t('modals:save')}
                                            disabled={!tableName.value || !tableType.selectedOptionAux || variables.length === 0}
                                            onClick={handleCreateDTable}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'removeDTable' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('pages:managerProfile.areyousure')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton text={t('modals:remove')} onClick={handleDeleteDTable} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        <Table
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(`components:${data.attr}.nodata`)}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={
                                !_.find(data.data, (el) => {
                                    return el.action.open
                                })
                                    ? handleSelectDynamicTable
                                    : undefined
                            }
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                            onNewElementClick={() => {
                                toggleModal(true)
                                setModalType('addDTable')
                            }}
                            onNewElementText={t('buttons:createDTable')}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </>
    )
})

DynamicTablesList.propTypes = {
    /**
     * Propagates selection of a row
     */
    onSelectDynamicTable: PropTypes.func,
}
