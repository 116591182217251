import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import styles from './mini-donut-chart.module.scss'
import { TooltipFactory } from '../..'
import useCursorTracking from '../../../hooks/useCursorTracking'

export const MiniDonutChart = React.memo(
    ({
        donutAttr,
        data,
        title,
        attr,
        subTitle,
        text,
        isHalfCircle,
        isFull,
        insideText,
        insideTitle,
        insideTitleColor,
        fillInside,
        addSpacingBetween,
    }) => {
        const { position } = useCursorTracking()
        const [animationActive, setAnimationActive] = React.useState(true)

        const [hoveredDonutPiece, setHoveredDonutPiece] = React.useState(undefined)

        const RADIAN = Math.PI / 180
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }) => {
            const radius = innerRadius + (outerRadius - innerRadius) + 30
            const x = cx + radius * Math.cos(-midAngle * RADIAN)
            const y = cy + radius * Math.sin(-midAngle * RADIAN)

            return (
                <text
                    x={x}
                    y={y}
                    fill={'#4c4c4c'}
                    fontSize={'1.2rem'}
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                    className="label-donut"
                >
                    {payload.name}
                </text>
            )
        }

        return (
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0 d-flex flex-column position-relative h-100">
                    <div className="row mx-0 w-100 h-100">
                        <div className="col px-0 h-100" style={{ zIndex: '1' }}>
                            <ResponsiveContainer width={'100%'} height={'100%'}>
                                <PieChart width={'100%'} height={'100%'}>
                                    <Tooltip
                                        cursor={false}
                                        content={
                                            <TooltipFactory
                                                item={{
                                                    type: attr,
                                                    data: data,
                                                    index: hoveredDonutPiece,
                                                }}
                                            />
                                        }
                                        wrapperStyle={{
                                            outline: 'none',
                                        }}
                                    />
                                    {donutAttr.map((el) => {
                                        return (
                                            <Pie
                                                key={el.dataKey + '2'}
                                                data={data[el.dataKey]}
                                                dataKey={el.valueKey}
                                                nameKey={el.nameKey}
                                                cx="50%"
                                                cy={isHalfCircle ? '75%' : '50%'}
                                                innerRadius={'90%'}
                                                blendStroke={!addSpacingBetween ? true : false}
                                                isAnimationActive={animationActive}
                                                onAnimationEnd={() => setAnimationActive(false)}
                                                outerRadius={'100%'}
                                                fill="#1576BF"
                                                startAngle={0}
                                                endAngle={isHalfCircle ? 180 : 360}
                                                labelLine={true}
                                                label={undefined}
                                            >
                                                {data[el.dataKey].map((entry, index2) => {
                                                    return (
                                                        <Cell
                                                            onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                            onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                            strokeWidth={2}
                                                            style={{
                                                                outline: 'none',
                                                            }}
                                                            key={`cell-${index2}`}
                                                            fill={entry['isRounded'] ? 'transparent' : entry.color}
                                                        />
                                                    )
                                                })}
                                            </Pie>
                                        )
                                    })}
                                    {donutAttr.map((el) => {
                                        return (
                                            <Pie
                                                key={el.dataKey}
                                                data={data[el.dataKey]}
                                                dataKey={el.valueKey}
                                                nameKey={el.nameKey}
                                                cx="50%"
                                                cy={isHalfCircle ? '75%' : '50%'}
                                                innerRadius={'90%'}
                                                blendStroke={!addSpacingBetween ? true : false}
                                                isAnimationActive={animationActive}
                                                onAnimationEnd={() => setAnimationActive(false)}
                                                outerRadius={'100%'}
                                                fill="#1576BF"
                                                startAngle={0}
                                                endAngle={isHalfCircle ? 180 : 360}
                                                labelLine={true}
                                                label={undefined}
                                                cornerRadius={20}
                                            >
                                                {data[el.dataKey].map((entry, index2) => {
                                                    return (
                                                        <Cell
                                                            onMouseEnter={() => setHoveredDonutPiece(index2)}
                                                            onMouseLeave={() => setHoveredDonutPiece(undefined)}
                                                            strokeWidth={2}
                                                            style={{
                                                                outline: 'none',
                                                            }}
                                                            key={`cell-${index2}`}
                                                            fill={entry['isRounded'] ? entry.color : 'transparent'}
                                                        />
                                                    )
                                                })}
                                            </Pie>
                                        )
                                    })}
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    {insideTitle && (
                        <div
                            className={`${styles['mini-donut-chart-container__inside-text']}`}
                            style={{
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            {insideTitle && (
                                <div className="row mx-0 w-100">
                                    <div className="col px-0 d-flex justify-content-center">
                                        <span
                                            className={`${styles['mini-donut-chart-container__inside-text__title']}`}
                                            style={{
                                                color: insideTitleColor,
                                            }}
                                        >
                                            {insideTitle}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }
)
