import _ from 'lodash'
import { useCallback, useReducer } from 'react'

const ACTIONS = {
    SET_ARRAY: 'setArray',
    PUSH: 'push',
    REMOVE: 'remove',
    UPDATE: 'update',
    FILTER: 'filter',
    CLEAR: 'clear',
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_ARRAY:
            return [...action.payload]
        case ACTIONS.PUSH:
            if (!_.includes(state, action.payload)) {
                return [...state, action.payload]
            } else {
                return [...state]
            }
        case ACTIONS.REMOVE:
            return [
                ...state.slice(0, action.payload),
                ...state.slice(action.payload + 1, state.length),
            ]
        case ACTIONS.UPDATE:
            return [
                ...state.slice(0, action.payload.index),
                action.payload.newElement,
                ...state.slice(action.payload.index + 1, state.length),
            ]
        case ACTIONS.FILTER:
            return state.filter(action.payload)

        case ACTIONS.CLEAR:
            return []
    }
}

export function useUniqueArray(defaultValue) {
    const [array, dispatch] = useReducer(reducer, defaultValue)

    const setArray = useCallback((value) => {
        dispatch({ type: 'setArray', payload: value })
    }, [])

    const push = useCallback((element) => {
        dispatch({ type: 'push', payload: element })
    }, [])

    const remove = useCallback((index) => {
        dispatch({ type: 'remove', payload: index })
    }, [])

    const update = useCallback((index, newElement) => {
        dispatch({
            type: 'update',
            payload: { index: index, newElement: newElement },
        })
    }, [])

    const filter = useCallback((callback) => {
        dispatch({ type: 'filter', payload: callback })
    }, [])

    const clear = useCallback((value) => {
        dispatch({ type: 'clear', payload: value })
    }, [])

    return { array, setArray, push, remove, update, filter, clear }
}
