import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorHandler } from '../utils/api'
import { PagesService } from '../temp/test'
import { useArray } from './useArray'
import { useToggle } from './useToggle'
import { useLocalStorage } from './useStorage'

export function useNavOptions(defaultValue, localStorageKey, app) {
    const navigate = useNavigate()
    const [navOptionsMemorized, setNavOptionsMemorized, clearNavOptionsMemorized] = useLocalStorage(localStorageKey, [])

    const { array: routes, setArray: originalSetRoutes } = useArray(navOptionsMemorized.length ? navOptionsMemorized : [...defaultValue])
    const setRoutes = useCallback(
        (newRoutes) => {
            originalSetRoutes(newRoutes)
            clearNavOptionsMemorized()
            setNavOptionsMemorized(newRoutes)
        },
        [originalSetRoutes, setNavOptionsMemorized, clearNavOptionsMemorized]
    )
    const [isPagesSet, toggleIsPagesSet] = useToggle(false)

    const checkRoutes = useCallback(async () => {
        try {
            const routesClone = _.cloneDeep(routes)

            const myPagesData = await PagesService.getApiPagesMyPages()
            const normalPages = [...routesClone]
            const customPagesBack = []
            const draftPagesBack = []

            const constructPageObj = (el, isDraft) => ({
                url: el.url,
                name: el.name,
                isCustom: true,
                show: true,
                isDraft,
                disabled: false,
                icon: { image: el.icon },
                isCategory: false,
                isOpen: false,
                isInCategory: '',
                availableOptions: ['search'],
                component: isDraft ? 'DraftDashboard' : 'CustomDashboard',
                folder: el.folder || undefined,
            })

            myPagesData.pages.forEach((page) => {
                if (page.draft) {
                    draftPagesBack.push(constructPageObj(page, true))
                } else if (!page.draft) {
                    customPagesBack.push(constructPageObj(page, false))
                }
            })

            const pagesToCheckForFolders = [...customPagesBack, ...draftPagesBack]

            const myAnalyticsPage = normalPages.find((page) => page.name === 'myanalytics')
            if (myAnalyticsPage) {
                myAnalyticsPage.children = []
                pagesToCheckForFolders.forEach((page) => {
                    if (page.folder) {
                        const folderId = page.folder.id
                        if (_.findIndex(myAnalyticsPage.children, (child) => child.id === page.folder.id) === -1) {
                            myAnalyticsPage.children.unshift({
                                isFolder: true,
                                name: page.folder.name,
                                id: folderId,
                                children: [page],
                            })
                        } else {
                            const folderIndex = _.findIndex(myAnalyticsPage.children, (child) => child.id === folderId)
                            if (
                                folderIndex !== -1 &&
                                _.findIndex(myAnalyticsPage.children[folderIndex].children, (child) => child.name === page.name) === -1
                            ) {
                                myAnalyticsPage.children[folderIndex].children.push(page)
                            }
                        }
                    } else {
                        if (_.findIndex(myAnalyticsPage.children, (child) => child.name === page.name) === -1) {
                            myAnalyticsPage.children.unshift(page)
                        } else {
                            const childIndex = _.findIndex(myAnalyticsPage.children, (child) => child.name === page.name)
                            if (childIndex !== -1) {
                                myAnalyticsPage.children[childIndex] = page
                            }
                        }
                    }
                })

                myAnalyticsPage.children.sort((a, b) => {
                    if (a.isFolder && !b.isFolder) {
                        return -1
                    } else if (!a.isFolder && b.isFolder) {
                        return 1
                    } else {
                        return 0
                    }
                })
            }

            setRoutes(normalPages.filter((page) => !page.folder))
            toggleIsPagesSet(true)
        } catch (err) {
            errorHandler(err)
        }
    }, [routes, setRoutes, toggleIsPagesSet])

    useEffect(() => {
        if (app === 'pages' && !isPagesSet) checkRoutes()
    }, [checkRoutes, app, isPagesSet])

    const publishRoute = useCallback(
        async (dashboardTitle, availableCharts, notAvailableCharts) => {
            let url = window.location.pathname.split('/')[1]
            const dropdownList = []
            const jsonToSend = {
                dashboardTitle: dashboardTitle,
                dropdownList: [...dropdownList],
                availableCharts: [],
                notAvailableCharts: [...notAvailableCharts],
            }
            try {
                await PagesService.putApiPages({
                    url: url,
                    requestBody: {
                        content: JSON.stringify(jsonToSend),
                        name: dashboardTitle,
                    },
                })
            } catch (err) {
                errorHandler(err)
            }
            try {
                await PagesService.postApiPagesPublish({ url: url })
            } catch (err) {
                errorHandler(err)
            }
            if (app === 'pages') checkRoutes()
        },
        [app, checkRoutes]
    )

    const updateRoute = useCallback(async (name, availableCharts, notAvailableCharts) => {
        let url = name.toLowerCase().replaceAll(' ', '-')
        const dropdownList = []
        notAvailableCharts.map((el) => {
            if (el.config.filter) {
                if (!dropdownList.includes(el.config.filter)) {
                    dropdownList.push(el.config.filter)
                }
            }
            return el
        })
        const jsonToSend = {
            dashboardTitle: name,
            dropdownList: [...dropdownList],
            availableCharts: [],
            notAvailableCharts: [...notAvailableCharts],
        }
        try {
            if (name === 'dashboard') {
                await PagesService.putApiPagesHomepage({
                    requestBody: { content: JSON.stringify(jsonToSend) },
                })
            } else {
                await PagesService.putApiPages({
                    url: url,
                    requestBody: {
                        content: JSON.stringify(jsonToSend),
                        name: undefined,
                    },
                })
            }
        } catch (err) {
            errorHandler(err)
        }
        //if (app === 'pages') checkRoutes()
    }, [])

    const addRoute = useCallback(
        async (name, icon, availableCharts, notAvailableCharts, isDraft, roles, isInsideFolder, folderExists, folderId, folderName) => {
            let url = name.toLowerCase().replaceAll(' ', '-')
            const dropdownList = []
            notAvailableCharts.map((el) => {
                if (el.config.filter) {
                    if (!dropdownList.includes(el.config.filter)) {
                        dropdownList.push(el.config.filter)
                    }
                }
                return el
            })
            const jsonToSend = {
                dashboardTitle: name,
                dashboardIcon: icon,
                dropdownList: [...dropdownList],
                availableCharts: [],
                notAvailableCharts: [...notAvailableCharts],
            }
            try {
                const postPageSchema = {
                    content: JSON.stringify(jsonToSend),
                    draft: isDraft.toString(),
                    icon: icon,
                    name: name,
                    roles: roles,
                    url: url,
                    folder:
                        isInsideFolder && folderExists
                            ? { id: folderId, name: folderName }
                            : isInsideFolder && !folderExists
                            ? { name: folderName }
                            : undefined,
                }
                await PagesService.postApiPages({
                    requestBody: postPageSchema,
                })
            } catch (err) {
                errorHandler(err)
            }

            if (app === 'pages') await checkRoutes()
            if (url) {
                if (isDraft) {
                    navigate(`/${url}`)
                } else {
                    const state = {
                        dashboardTitle: name,
                        dashboardIcon: icon,
                        availableCharts: [...availableCharts],
                        notAvailableCharts: [...notAvailableCharts],
                        isDraft,
                    }
                    navigate({ pathname: `/${url}`, state: state })
                }
            } else {
                navigate('/dashboard')
            }
        },
        [app, checkRoutes, navigate]
    )

    const removeRoute = useCallback(
        async (url) => {
            const routesClone = _.cloneDeep(routes)

            // Find if the route is a direct child
            const index = _.findIndex(routesClone, (el) => el.url === url)
            if (index !== -1) {
                routesClone.splice(index, 1)
            } else {
                // Check if the route is inside 'myanalytics'
                const myAnalyticsPage = routesClone.find((page) => page.name === 'myanalytics')
                if (myAnalyticsPage && myAnalyticsPage.children) {
                    const childIndex = _.findIndex(myAnalyticsPage.children, (child) => child.url === url)

                    if (childIndex !== -1) {
                        myAnalyticsPage.children.splice(childIndex, 1)
                    } else {
                        // Check if the route is inside folders under 'myanalytics'
                        myAnalyticsPage.children.forEach((folder) => {
                            if (folder.isFolder && folder.children) {
                                const folderChildIndex = _.findIndex(folder.children, (child) => child.url === url)
                                if (folderChildIndex !== -1) {
                                    folder.children.splice(folderChildIndex, 1)
                                    // If the folder becomes empty, we can remove it as well (optional)
                                    if (folder.children.length === 0) {
                                        const folderIndex = _.findIndex(myAnalyticsPage.children, (f) => f.id === folder.id)
                                        myAnalyticsPage.children.splice(folderIndex, 1)
                                    }
                                }
                            }
                        })
                    }
                }
            }

            setRoutes(routesClone)
            navigate('/dashboard')
        },
        [navigate, routes, setRoutes]
    )

    const openDropdown = useCallback(
        (option, value) => {
            const routesClone = _.cloneDeep(routes)
            routesClone.map((el) => {
                if (el.name === option) {
                    if (value !== undefined) el.isOpen = value
                    else {
                        if (el.isOpen) {
                            el.isOpen = false
                        } else el.isOpen = true
                    }
                }
                if (_.has(el, 'children') && el.children.length > 0) {
                    el.children.map((el2) => {
                        if (el2.name === option) {
                            if (value) el2.isOpen = value
                            else {
                                if (el2.isOpen) {
                                    el2.isOpen = false
                                } else el2.isOpen = true
                            }
                        }
                        return el2
                    })
                }
                return el
            })
            setRoutes(routesClone)
        },
        [routes, setRoutes]
    )

    // create a function to close all dropdowns recursively if they have children. to be recursive, it should be a function that calls itself
    const closeAllOptions = useCallback(
        (childrenRoutes) => {
            const routesClone = childrenRoutes || _.cloneDeep(routes)
            routesClone.map((el) => {
                el.isOpen = false
                if (_.has(el, 'children') && el.children.length > 0) {
                    closeAllOptions(el.children)
                }
                return el
            })
            if (!childrenRoutes) setRoutes(routesClone)
        },
        [routes, setRoutes]
    )

    const actions = useMemo(
        () => ({
            onPublishRoute: publishRoute,
            onUpdateRoute: updateRoute,
            onAddRoute: addRoute,
            onRemoveRoute: removeRoute,
            openDropdown,
            closeAllOptions,
        }),
        [publishRoute, updateRoute, addRoute, removeRoute, openDropdown, closeAllOptions]
    )

    return {
        routes,
        actions,
        openDropdown,
        setRoutes,
        publishRoute,
        updateRoute,
        addRoute,
        removeRoute,
        closeAllOptions,
    }
}
