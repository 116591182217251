import React from 'react';
import styles from './AuthFormWrapper.module.scss';

export const AuthFormWrapper = ({ children }) => {
    return (
        <div className={styles.AuthFormWrapper}>
            <div style={{ zIndex: 100, position: 'relative' }}>{children}</div>
            <div className={styles.AuthFormWrapper_blurredBackground}></div>
        </div>
    );
};
