import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './goal-and-sub-goal-progress.module.scss';
import { useIsMounted } from '../../../hooks/useIsMounted.js';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent.js';
import { useFilter } from '../../../providers/filter-context.js';
import { errorHandler } from '../../../utils/api.js';
import { ComponentWrapper, CustomTick, TooltipFactory } from '../../index.jsx';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation.js';
import { useTimeInterval } from '../../../providers/time-interval-context.js';
import { useSalesChannel } from '../../../providers/sales-channel-context.js';
import { goalAndSubGoalProgressMock } from './mock.jsx';
import { useCursorTrackingProvider } from '../../../providers/cursor-tracking-context';
import { CustomTooltip } from '../../chart-configuration/custom-recharts-tooltip/custom-recharts-tooltip';

/**
 * Displays the unit sales over time without forecast prediction
 */

const CustomGoalProgressBar = ({ progress }) => {
    return (
        <div
            className={`${styles['custom-bar']} ${styles['goal-bar']} d-flex justify-content-between align-items-center position-relative`}
        >
            <div
                className={`${styles['fill-bar']} d-flex justify-content-between align-items-center position-relative`}
                style={{
                    width: '0%', // Initial width for animation
                    '--progress-width': `${progress}%`,
                    background:
                        progress === 1
                            ? '#98d2c5'
                            : `linear-gradient(to right, rgba(227, 162, 158, 0)0%, rgba(227, 162, 158, 1) ${progress}%)`,
                    animation: progress
                        ? `${styles['colorUpGoal']} 0.5s forwards`
                        : 'none'
                }}
            ></div>
            {progress > 0 && progress !== 100 && (
                <span
                    className={`${styles['goal-progress']} d-flex justify-content-between align-items-center`}
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: `${progress}%`,
                        transform: 'translateX(-50%)'
                    }}
                >
                    {progress}%
                </span>
            )}
            <span
                className={`${styles['goal-progress']} d-flex justify-content-between align-items-center`}
                style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0%'
                }}
            >
                0%
            </span>
            <span
                className={`${styles['goal-progress']} d-flex justify-content-between align-items-center`}
                style={{
                    position: 'absolute',
                    top: '100%',
                    right: '0%'
                }}
            >
                100%
            </span>
        </div>
    );
};

const CustomSubGoalsProgressBar = ({ progresses }) => {
    const containerRef = useRef(null);
    const [totalProgressBars, setTotalProgressBars] = useState(0); // default value
    const { position } = useCursorTrackingProvider();
    const [isHovered, setIsHovered] = useState(undefined);

    useEffect(() => {
        const updateProgressBars = () => {
            if (containerRef.current) {
                const parentWidth = containerRef.current.clientWidth;
                const barWidth = 15; // width of each bar including margin
                const newTotalProgressBars = Math.floor(parentWidth / barWidth);
                setTotalProgressBars(newTotalProgressBars);
            }
        };

        updateProgressBars();
        window.addEventListener('resize', updateProgressBars);

        return () => {
            window.removeEventListener('resize', updateProgressBars);
        };
    }, []);

    if (progresses.length > 0) {
        return progresses.map((progress, index) => {
            const barsToFill = Math.floor(
                (progress.progress / 100) * totalProgressBars
            );
            return (
                <div
                    className="row mx-0 w-100"
                    key={index}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                        <span
                            className={`${styles['sub-goal-title']} d-flex justify-content-between align-items-center`}
                        >
                            {progress.name}
                        </span>
                    </div>
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                    <div className="col px-0" ref={containerRef}>
                        <div
                            className={`${styles['sub-goal-bar']} d-flex justify-content-between align-items-center`}
                            onMouseEnter={() => setIsHovered(progress)}
                            onMouseLeave={() => setIsHovered(undefined)}
                        >
                            {Array.from({ length: totalProgressBars }).map(
                                (_, barIndex) => {
                                    const isFilled = barIndex < barsToFill;
                                    return (
                                        <div
                                            key={barIndex}
                                            className={`${styles['sub-goal-bar-item']}`}
                                            style={{
                                                margin:
                                                    barIndex <
                                                    totalProgressBars - 1
                                                        ? '0 0.5rem 0 0'
                                                        : '0',
                                                '--bar-color': progress.color,
                                                backgroundColor: '#f2f2f2',
                                                animation: isFilled
                                                    ? `${styles['colorUp']} 0.3s forwards`
                                                    : 'none',
                                                opacity:
                                                    (barIndex / barsToFill) *
                                                    1.2,
                                                animationDelay: isFilled
                                                    ? `${barIndex * 0.02}s`
                                                    : 'none'
                                            }}
                                        ></div>
                                    );
                                }
                            )}
                        </div>
                        {isHovered && position && (
                            <TooltipFactory
                                item={{
                                    type: 'subGoalProgress',
                                    color: '#2cd9c5',
                                    ...isHovered
                                }}
                            />
                        )}
                    </div>
                </div>
            );
        });
    }
    return null;
};

export const GoalAndSubGoalProgress = React.memo(
    ({ component, isPreview, isExample, config, ...rest }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { timeInterval } = useTimeInterval();
        const { channels } = useSalesChannel();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue
        } = useSimpleComponent(goalAndSubGoalProgressMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(goalAndSubGoalProgressMock);
                try {
                    if (!isExample) {
                        setLoading(true);
                        dataClone.loading = false;
                        dataClone.categoryAxis = timeInterval;
                        setValue(dataClone);
                    } else {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            timeInterval,
            isPreview,
            channels
        ]);

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ComponentWrapper component={component} {...data} {...rest}>
                    <div className="row mx-0 w-100 h-100">
                        <div className="col px-0 d-flex flex-column">
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: '2.5rem',
                                    marginBottom: '2rem',
                                    padding: '0 2rem'
                                }}
                            >
                                <div className="col px-0">
                                    <CustomGoalProgressBar {...data} />
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <div
                                        className={`${styles['horizontal-separator']}`}
                                    ></div>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{ padding: '0 2rem' }}
                            >
                                <div className="col px-0 d-flex flex-column">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <span
                                                className={`${styles['sub-title']}`}
                                            >
                                                Sub Goals
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100 flex-grow-1">
                                        <div className="col px-0">
                                            <CustomSubGoalsProgressBar
                                                {...data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ComponentWrapper>
            </div>
        );
    }
);

GoalAndSubGoalProgress.propTypes = {};
