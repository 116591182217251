import styles from './client-ai.module.scss'

import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { formatDate, getDayFromDateFormat, getMonthFromDateFormat, getTimeFromDateFormat, getYearFromDateFormat } from '../../../utils/date'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { DTablesService, PointOfSaleService } from '../../../temp/test'
import componentConfig from '../../../configs/componentLibraryConf.json'
import { ReactComponent as IcoLocation } from './assets/images/ico-location.svg'
import { clientAiMock } from './mock'
import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component'
import { useChartReady } from '../../../providers/chart-ready-context'
import { ContentContainer, Loader, MiniAreaChartContainer, Ring, Spinner } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

/**
 * Displays ai-related information about a client
 */

export const ClientAi = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
    }) => {
        const { charts } = useChartReady()
        const { filter } = useFilter()
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()

        const { value: data, setLoading, setValue, toggleComponentSettings } = useSimpleComponent(clientAiMock)

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(clientAiMock)
                if (!isExample) {
                    if (isMounted.current) {
                        setLoading(true)
                    }
                    if (type) dataClone.attr = type
                    try {
                        let clientRiskData
                        dataClone.areaData = []
                        if (filter && filter.clientId.constructor === String && filter.clientId !== '' && config.filter.includes('clientId')) {
                            clientRiskData = await PointOfSaleService.getApiPosRisk({
                                posId: filter.clientId,
                            })
                        }
                        if (clientRiskData) {
                            dataClone.ring.risk = t('components:clientDetailsCard.risk') + clientRiskData.risk
                            dataClone.ring.percentage = (clientRiskData.risk / 4) * 100
                            dataClone.area.data = Object.entries(clientRiskData.risk_series).map((el, index) => {
                                return {
                                    quantitativeAttr: el[1],
                                    categoryAttr: index,
                                }
                            })
                            let riskValues = Object.entries(clientRiskData.risk_series).map((el) => {
                                return el[1]
                            })
                            dataClone.area.maxValue = Math.max(...riskValues)
                            dataClone.area.minValue = Math.min(...riskValues)
                            dataClone.loading = false
                            dataClone.noData = false
                        }
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                        if (isMounted.current) setValue(dataClone)
                    } catch (err) {
                        errorHandler(err)
                        dataClone.loading = false
                        if (isMounted.current) {
                            setValue(dataClone)
                        }
                    }
                    try {
                        let clientSentimentData
                        if (filter && filter.clientId.constructor === String && filter.clientId !== '' && config.filter.includes('clientId')) {
                            clientSentimentData = await PointOfSaleService.getApiPosSentiment({
                                posId: filter.clientId,
                            })
                        }
                        Object.entries(clientSentimentData.overtime).map((el) => {
                            let totalSentiment = 0
                            let sentimentDistribution = {
                                positive: 0,
                                neutral: 0,
                                negative: 0,
                            }
                            Object.entries(el[1]).map((el2) => {
                                totalSentiment += el2[1]
                                sentimentDistribution[el2[0]] = el2[1]
                            })
                            dataClone.areaData.push({
                                'revenue-positive': _.has(el[1], 'positive')
                                    ? Math.abs(((sentimentDistribution.positive / totalSentiment) * 100).toFixed(1))
                                    : 0,
                                'revenue-neutral': _.has(el[1], 'neutral')
                                    ? Math.abs(((sentimentDistribution.neutral / totalSentiment) * 100).toFixed(1))
                                    : 0,
                                'revenue-negative': _.has(el[1], 'negative')
                                    ? Math.abs(((sentimentDistribution.negative / totalSentiment) * 100).toFixed(1))
                                    : 0,
                                time: getTimeFromDateFormat(el[0]),
                                day: getDayFromDateFormat(el[0], t),
                                month: getMonthFromDateFormat(el[0], t),
                                year: getYearFromDateFormat(el[0]),
                            })
                            return el
                        })
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                        if (isMounted.current) setValue(dataClone)
                    } catch (err) {
                        //do nothing
                    }
                    try {
                        let clientSalesInfoData
                        if (filter && filter.clientId.constructor === String && filter.clientId !== '' && config.filter.includes('clientId')) {
                            clientSalesInfoData = await PointOfSaleService.getApiPosSalesInfo({
                                posId: filter.clientId,
                            })
                        }
                        if (clientSalesInfoData) {
                            dataClone.visits = '-'
                            dataClone.calls = '-'
                            dataClone.emails = '-'
                            dataClone.issues = '-'
                            dataClone.clientSince = clientSalesInfoData.client_since ? formatDate(clientSalesInfoData.client_since) : '-'
                            dataClone.totalOrders = clientSalesInfoData.total_orders ? clientSalesInfoData.total_orders : '-'
                            dataClone.totalRevenue = clientSalesInfoData.total_revenue ? '€' + clientSalesInfoData.total_revenue.toFixed(2) : '-'
                            dataClone.lastOrder = clientSalesInfoData.last_order ? formatDate(clientSalesInfoData.last_order) : '-'
                            dataClone.avgPerOrder =
                                clientSalesInfoData.total_revenue && clientSalesInfoData.total_orders
                                    ? '€' + (clientSalesInfoData.total_revenue / clientSalesInfoData.total_orders).toFixed(2)
                                    : '-'
                            dataClone.amountOrder = '-'
                            dataClone.lastPayment = '-'
                            dataClone.revenueOverdue = '-'
                            dataClone.paymentOverdue = '-'
                            dataClone.debt = '-'
                            dataClone.loading = false
                            dataClone.noData = false
                        } else {
                            dataClone.loading = false
                            dataClone.noData = true
                        }
                        if (dataClone.settings.view.options.length === 0 && dataClone.settings.options.options.length === 0) dataClone.disabled = true
                        else dataClone.disabled = false
                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setValue(dataClone)
                            setLoading(false)
                        }
                        let additionalData = []
                        if (config.additionalInfo && config.additionalInfo.length !== 0) {
                            additionalData = await DTablesService.getApiBackofficeAdminDtablesColumns({
                                columns: config.additionalInfo,
                                ids:
                                    filter && filter.clientId.constructor === String && filter.clientId !== '' && config.filter.includes('clientId')
                                        ? [filter.clientId]
                                        : [],
                            })
                        }
                        if (additionalData.length !== 0) {
                            config.additionalInfo.map((el2) => {
                                dataClone.customInfo.push(el2)
                                dataClone[el2] = additionalData[0][el2]
                                return el2
                            })
                        }
                        if (isMounted.current) setValue(dataClone)
                    } catch (err) {
                        errorHandler(err)
                        dataClone.loading = false
                        if (isMounted.current) {
                            setValue(dataClone)
                        }
                    }
                } else {
                    if (type) dataClone.attr = type
                    verifyComponent(dataClone)
                    setValue(dataClone)
                    setLoading(false)
                }
            }
            fill()
        }, [config, filter, isExample, setValue, setLoading, isMounted, t, type])

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings()
                if (option === 'pin') {
                    onPinComponent(data.attr)
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr)
                } else if (option === 'download') {
                    onDownloadComponent()
                }
            },
            [data.attr, isMounted, onDownloadComponent, onPinComponent, onUnpinComponent, toggleComponentSettings]
        )

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.noData && !data.loading && (
                        <div className={`col px-0 h-100 d-flex justify-content-center align-items-center`}>
                            <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                    <span className={`${styles['client-ai-container__no-data-container__no-data-text']}`}>{t(`common:noData`)}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {data.loading && <Spinner />}
                    {!data.loading && !data.noData && (
                        <div className={`col justify-content-center align-items-center ${styles['client-ai-container']} d-flex flex-column`}>
                            <div className={`row mx-0 w-100 h-100 ${styles['client-ai-container__graph-container']} flex-grow-1`}>
                                {(data.ring || (data.areaData && data.areaData.length > 0)) && (
                                    <div
                                        className="col-auto h-100 mx-3 d-flex justify-content-around align-items-center flex-column"
                                        style={{
                                            padding: '0 2rem',
                                        }}
                                    >
                                        {data.ring && (
                                            <div className="row mx-0 w-100 mb-4 h-50">
                                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                                    <Ring
                                                        radius={data.ring.radius}
                                                        strokeWidth={data.ring.strokeWidth}
                                                        percentage={data.ring.percentage}
                                                        type={data.ring.type}
                                                        invert={data.ring.invert}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {/* {data.areaData &&
                                            data.areaData.length > 0 && (
                                                <div className="row mx-0 w-100 h-50">
                                                    <div
                                                        className={`col h-100 px-0 ${styles['client-ai-container__graph-container__area-container']}`}
                                                    >
                                                        <MiniAreaChartContainer
                                                            translate={t}
                                                            areaAttr={
                                                                data.areaAttr
                                                            }
                                                            categoryAxis={
                                                                data.categoryAxis
                                                            }
                                                            animationDuration={
                                                                data.animationDuration
                                                            }
                                                            data={data.areaData}
                                                            dateGrouper={
                                                                data.dateGrouper
                                                            }
                                                            lineType={
                                                                data.lineType
                                                            }
                                                            timeAttr={
                                                                data.timeAttr
                                                            }
                                                            gradient={
                                                                data.gradient
                                                            }
                                                            maxValue={
                                                                data.maxValue
                                                            }
                                                            minValue={
                                                                data.minValue
                                                            }
                                                            tooltipType={
                                                                data.tooltipType
                                                            }
                                                            unitsOrValues={
                                                                'units'
                                                            }
                                                            suffix={data.suffix}
                                                            margin={data.margin}
                                                            interactionDisabled={
                                                                data.interactionDisabled
                                                            }
                                                        ></MiniAreaChartContainer>
                                                    </div>
                                                </div>
                                            )} */}
                                    </div>
                                )}
                                <div
                                    className={`col h-100 px-0 ${styles['client-ai-container__desc-container']} d-flex flex-column justify-content-between align-items-center`}
                                >
                                    <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                                        <div className={`col h-100 me-2 ${styles['client-ai-container__desc-container__card']}`}>
                                            <div className="row mx-0">
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <h1 className={`${styles['client-ai-container__desc-container__card__title']}`}>
                                                        {t(`components:${data.attr}.visits`)}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col-auto px-0 pe-2 d-flex justify-content-start align-items-center">
                                                    <IcoLocation className={`${styles['client-ai-container__desc-container__card__image']}`} />
                                                </div>
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <span className={`${styles['client-ai-container__desc-container__card__content']}`}>
                                                        {data.visits ? data.visits : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col h-100 me-2 ${styles['client-ai-container__desc-container__card']}`}>
                                            <div className="row mx-0">
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <h1 className={`${styles['client-ai-container__desc-container__card__title']}`}>
                                                        {t(`components:${data.attr}.calls`)}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col-auto px-0 pe-2 d-flex justify-content-start align-items-center">
                                                    <IcoLocation className={`${styles['client-ai-container__desc-container__card__image']}`} />
                                                </div>
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <span className={`${styles['client-ai-container__desc-container__card__content']}`}>
                                                        {data.calls ? data.calls : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col h-100 me-2 ${styles['client-ai-container__desc-container__card']}`}>
                                            <div className="row mx-0">
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <h1 className={`${styles['client-ai-container__desc-container__card__title']}`}>
                                                        {t(`components:${data.attr}.emails`)}
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="row mx-0">
                                                <div className="col-auto px-0 pe-2 d-flex justify-content-start align-items-center">
                                                    <IcoLocation className={`${styles['client-ai-container__desc-container__card__image']}`} />
                                                </div>
                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                    <span className={`${styles['client-ai-container__desc-container__card__content']}`}>
                                                        {data.emails}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.clientSince`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.clientSince ? data.clientSince : ''}
                                            </h1>
                                        </div>
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.totalOrders`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.totalOrders ? data.totalOrders : ''}
                                            </h1>
                                        </div>
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.totalRevenue`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.totalRevenue ? data.totalRevenue : ''}
                                            </h1>
                                        </div>
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.avgPerOrder`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.avgPerOrder ? data.avgPerOrder : ''}
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.lastOrder`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.lastOrder ? data.lastOrder : ''}
                                            </h1>
                                        </div>
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.paymentOverdue`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.paymentOverdue ? data.paymentOverdue : ''}
                                            </h1>
                                        </div>
                                        <div className="col px-0 mx-2 overflow-hidden h-100">
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                {t(`components:${data.attr}.revenueOverdue`)}
                                            </h1>
                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                {data.revenueOverdue ? data.revenueOverdue : ''}
                                            </h1>
                                        </div>
                                        <div className="col px-0 mx-2 overflow-hidden h-100"></div>
                                    </div>
                                    {data.customInfo.length !== 0 && (
                                        <div className="row mx-0 w-100">
                                            {data.customInfo.map((el, index) => {
                                                if (index <= componentConfig[data.attr].dTableInfoLimit - 1) {
                                                    return (
                                                        <div key={index} className="col px-0 mx-2 overflow-hidden h-100">
                                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-title']}`}>
                                                                {el}
                                                            </h1>
                                                            <h1 className={`${styles['client-ai-container__desc-container__sub-sub-content']}`}>
                                                                {data[el] ? data[el] : ''}
                                                            </h1>
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })}
                                        </div>
                                    )}
                                    <div className="row mx-0 w-100"></div>
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        )
    }
)

ClientAi.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
