import React from 'react'
import { ComponentWrapper, Spinner } from '../..'
import { TableImprovement } from '../../tables/table-improvement/table-improvement'

/**
 * Displays comparison of sales between products according to the selected category
 */

export const SegmentList = React.memo(({ data, loading, isPreview, ...rest }) => {
    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper {...rest} withEditColumns withSearch>
                {loading && <Spinner />}
                {!loading && <TableImprovement data={data} {...rest} />}
            </ComponentWrapper>
        </div>
    )
})

SegmentList.propTypes = {}
