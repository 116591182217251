export const goalAndSubGoalProgressMock = {
    attr: 'goalAndSubGoalProgress',
    progress: 30,

    progresses: [
        {
            name: 'Org 1',
            progress: 30,
            color: '#b5c7eb'
        },
        {
            name: 'Org 2',
            progress: 80,
            color: '#e6a773'
        },
        {
            name: 'Org 3',
            progress: 50,
            color: '#c2b87d'
        },
        {
            name: 'Org 4',
            progress: 30,
            color: '#d6a6de'
        }
    ]
};
