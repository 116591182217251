import _ from 'lodash'
import { useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCustomTranslation } from './useCustomTranslation'

const ACTIONS = {
    CLICK: 'click',
    HOVER: 'hover',
    LOADING: 'loading',
    TOGGLE_SETTINGS: 'toggleSettings',
    SET: 'set',
    RATIO: 'ratio',
    CHECKBOX: 'checkbox',
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET:
            return { ...action.payload }
        case ACTIONS.CLICK:
            return {
                ...state,
            }
        case ACTIONS.HOVER:
            return {
                ...state,
            }
        case ACTIONS.RESET: {
            const filteredSelectedOptions =
                state.settings.viewOptions.checkboxOptions.selectedOptions
            state.risk1Data.features = state.originalRisk1Data.features.filter(
                (el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    )
                }
            )
            state.risk2Data.features = state.originalRisk2Data.features.filter(
                (el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    )
                }
            )
            state.risk3Data.features = state.originalRisk3Data.features.filter(
                (el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    )
                }
            )
            state.risk4Data.features = state.originalRisk4Data.features.filter(
                (el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    )
                }
            )
            state.competitionData.features =
                state.originalCompetitionData.features.filter((el) => {
                    return filteredSelectedOptions.includes(
                        el.properties.defaultStatus
                    )
                })
            return {
                ...state,
            }
        }
        case ACTIONS.TOGGLE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    open:
                        typeof action.payload === 'boolean'
                            ? action.payload
                            : !state.settings.open,
                },
            }
        case ACTIONS.LOADING:
            return {
                ...state,
                loading:
                    typeof action.payload === 'boolean'
                        ? action.payload
                        : !state.loading,
            }
        case ACTIONS.RATIO: {
            let checkboxOptions = []
            if (action.payload.option === 'riskStatus') {
                checkboxOptions = [
                    {
                        name: 'risk1',
                        label: action.payload.t(`settings:risk1`),
                        color: '#6fdb7d',
                        disabled: false,
                        blocked: false,
                    },
                    {
                        name: 'risk2',
                        label: action.payload.t(`settings:risk2`),
                        color: '#dbdb6f',
                        disabled: false,
                        blocked: false,
                    },
                    {
                        name: 'risk3',
                        label: action.payload.t(`settings:risk3`),
                        color: '#ffbd77',
                        disabled: false,
                        blocked: false,
                    },
                    {
                        name: 'risk4',
                        label: action.payload.t(`settings:risk4`),
                        color: '#ff6363',
                        disabled: false,
                        blocked: false,
                    },
                ]
            } else if (action.payload.option === 'defaultStatus') {
                checkboxOptions = [
                    {
                        name: 'activeClients',
                        label: action.payload.t(`settings:activeClients`),
                        color: '#ffb200',
                        disabled: false,
                        blocked: false,
                    },
                    {
                        name: 'inactiveClients',
                        label: action.payload.t(`settings:inactiveClients`),
                        color: '#dbdb6f',
                        disabled: false,
                        blocked: false,
                    },

                    {
                        name: 'lostClients',
                        label: action.payload.t(`settings:lostClients`),
                        color: '#606060',
                        disabled: false,
                        blocked: false,
                    },
                ]
            }
            return {
                ...state,
                settings: {
                    ...state.settings,
                    viewOptions: {
                        ...state.settings.viewOptions,
                        ratioOptions: {
                            ...state.settings.viewOptions.ratioOptions,
                            selectedOption: action.payload.option,
                        },
                        checkboxOptions: {
                            ...state.settings.viewOptions.checkboxOptions,
                            options: [...checkboxOptions],
                        },
                    },
                },
            }
        }
        case ACTIONS.CHECKBOX:
            if (
                state.settings.viewOptions.checkboxOptions.selectedOptions.includes(
                    action.payload
                )
            ) {
                const index = _.findIndex(
                    state.settings.viewOptions.checkboxOptions.selectedOptions,
                    (el) => {
                        return el === action.payload
                    }
                )
                if (index !== -1) {
                    return {
                        ...state,
                        settings: {
                            ...state.settings,
                            viewOptions: {
                                ...state.settings.viewOptions,
                                checkboxOptions: {
                                    ...state.settings.viewOptions
                                        .checkboxOptions,
                                    selectedOptions: [
                                        ...state.settings.viewOptions.checkboxOptions.selectedOptions.slice(
                                            0,
                                            index
                                        ),
                                        ...state.settings.viewOptions.checkboxOptions.selectedOptions.slice(
                                            index + 1,
                                            state.settings.viewOptions
                                                .checkboxOptions.selectedOptions
                                                .length
                                        ),
                                    ],
                                },
                            },
                        },
                    }
                }
            } else {
                return {
                    ...state,
                    settings: {
                        ...state.settings,
                        viewOptions: {
                            ...state.settings.viewOptions,
                            checkboxOptions: {
                                ...state.settings.viewOptions.checkboxOptions,
                                selectedOptions: [
                                    ...state.settings.viewOptions
                                        .checkboxOptions.selectedOptions,
                                    action.payload,
                                ],
                            },
                        },
                    },
                }
            }
    }
}

export function useGeo(defaultValue) {
    const navigate = useNavigate()
    const { t } = useCustomTranslation()

    const [value, dispatch] = useReducer(reducer, defaultValue)

    const resetMap = useCallback(() => {
        dispatch({ type: 'reset' })
    }, [])

    function mouseOverMap(loc) {
        dispatch({ type: 'hover', payload: { loc: loc } })
    }

    const toggleComponentSettings = useCallback((value) => {
        dispatch({ type: 'toggleSettings', payload: value })
    }, [])

    function clickMap(loc) {
        const params = new URLSearchParams()
        if (loc.defaultStatus === 'competitors') {
            params.set('competitor-id', loc.regionId)
            navigate({
                pathname: '/prospects',
                search: params.toString(),
            })
        }
        if (loc.defaultStatus !== 'competitors') {
            params.set('client-id', loc.regionId)
            navigate({
                pathname: '/client-details',
                search: params.toString(),
            })
        }
        dispatch({
            type: 'click',
            payload: { loc: loc },
        })
    }

    const setLoading = useCallback((value) => {
        dispatch({ type: 'loading', payload: value })
    }, [])

    const setValue = useCallback((value) => {
        dispatch({ type: 'set', payload: value })
    }, [])

    function clickRatioOption(a, b, option) {
        dispatch({ type: 'ratio', payload: { option: option, t: t } })
    }

    function clickCheckboxOption(a, b, option) {
        dispatch({ type: 'checkbox', payload: option })
    }

    return {
        value,
        toggleComponentSettings,
        mouseOverMap,
        clickMap,
        resetMap,
        setLoading,
        setValue,
        clickRatioOption,
        clickCheckboxOption,
    }
}
