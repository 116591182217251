import React, { useState } from 'react';
import { errorHandler } from '../../../utils/api';
import { RemoveModal } from '../remove-modal/remove-modal';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { DTablesService } from '../../../temp/test';

export const RemoveDynamicInfoModal = ({
    dtableName,
    dtableInfoId,
    onCloseModal,
    onEnd
}) => {
    const { t } = useCustomTranslation();
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const handleDeleteDynamicInfo = async () => {
        try {
            setLoading(true);
            await DTablesService.deleteApiBackofficeAdminDtablesData({
                tableName: dtableName,
                ids: [dtableInfoId]
            });
            if (onEnd) onEnd();
            setLoading(false);
            setIsSuccess(true);
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <RemoveModal
            title={t('modals:removeDynamicInfo.title')}
            description={t('modals:removeDynamicInfo.description')}
            successMessage={t('modals:removeDynamicInfo.success')}
            isLoading={loading}
            isSuccess={isSuccess}
            onDelete={handleDeleteDynamicInfo}
            onCloseModal={onCloseModal}
        />
    );
};
