import React, { useCallback, useState } from 'react';
import styles from './CreateOrganizationModal.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';
import icoUserExample from '../../../assets/images/icoUserExample.png';
import { StepByStepModal } from '../step-by-step-modal/step-by-step-modal';
import { errorHandler } from '../../../utils/api';
import { TypeInputAlternate } from '../../inputs/type-input-alternate/type-input-alternate';
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { AddUserInput } from '../../inputs/add-user-input/AddUserInput';

export const CreateOrganizationModal = ({ onToggleModal }) => {
    const { t } = useCustomTranslation();
    const [currentStep, setCurrentStep] = React.useState(0);
    const organizationName = useTextInputAlternate({
        name: 'organizationName'
    });
    const [usersToAdd, setUsersToAdd] = useState([
        {
            name: 'User 1',
            email: 'Email 1',
            avatar: icoUserExample
        }
    ]);

    const [usersAdded, setUsersAdded] = useState([]);

    const handleAddUser = (user) => {
        setUsersAdded([...usersAdded, user]);
    };

    const handleRemoveUser = (user) => {
        setUsersAdded(usersAdded.filter((u) => u.email !== user.email));
    };

    const handleCreateOrganization = useCallback(async () => {
        try {
            // await createOrganization
            onToggleModal();
        } catch (error) {
            errorHandler(error);
        }
    }, [onToggleModal]);

    return (
        <StepByStepModal
            steps={[
                t('modals:createOrganizationModal.steps.0'),
                t('modals:createOrganizationModal.steps.1')
            ]}
            onToggleModal={onToggleModal}
            currentStep={currentStep}
        >
            {currentStep === 0 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{
                            margin: '10rem 0'
                        }}
                    >
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-organization-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(
                                                `modals:createOrganizationModal.title`
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{ marginTop: '4rem' }}
                                >
                                    <div
                                        className="col-auto px-0"
                                        style={{ width: '30rem' }}
                                    >
                                        <TypeInputAlternate
                                            input={organizationName}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <TerciaryButton
                                text={t(`buttons:cancel`)}
                                onClick={onToggleModal}
                            />
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:next`)}
                                disabled={!organizationName.value}
                                onClick={() => setCurrentStep(1)}
                            />
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 1 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{
                            margin: '2rem 0'
                        }}
                    >
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-organization-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(
                                                `modals:createOrganizationModal.title`
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{
                                        marginTop: '4rem',
                                        height: '45rem'
                                    }}
                                >
                                    <div className="col px-0">
                                        <AddUserInput
                                            usersToAddTitle={t(
                                                'modals:createOrganizationModal.usersToAdd'
                                            )}
                                            usersToAddDescription={t(
                                                'modals:createOrganizationModal.usersToAddDescription'
                                            )}
                                            usersAddedTitle={t(
                                                'modals:createOrganizationModal.usersAdded'
                                            )}
                                            onAddUser={handleAddUser}
                                            onRemoveUser={handleRemoveUser}
                                            usersToAdd={usersToAdd}
                                            usersAdded={usersAdded}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <TerciaryButton
                                text={t(`buttons:back`)}
                                onClick={() => setCurrentStep(0)}
                            />
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:next`)}
                                onClick={() => setCurrentStep(2)}
                            />
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 2 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{
                            margin: '10rem 0'
                        }}
                    >
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-organization-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(
                                                `modals:createOrganizationModal.congratulations`
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{
                                        marginTop: '2rem'
                                    }}
                                >
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-organization-modal-container__description'
                                                ]
                                            }
                                        >
                                            {t(
                                                `modals:createOrganizationModal.congratulationsDescription`
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-center">
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:done`)}
                                onClick={handleCreateOrganization}
                            />
                        </div>
                    </div>
                </div>
            )}
        </StepByStepModal>
    );
};
