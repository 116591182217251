import { createContext, useContext } from 'react';

const AuxBarContext = createContext({
    selectedAvailableOption: '',
    availableOptions: [],
    setAvailableOptions: () => {},
    setSelectedAvailableOption: () => {},
    chartFilters: [],
    setChartFilters: () => {},
    isBuilder: false,
    setIsBuilder: () => {},
    component: {},
    setComponent: () => {}
});

export function AuxBarProvider({ value, children }) {
    return (
        <AuxBarContext.Provider value={value}>
            {children}
        </AuxBarContext.Provider>
    );
}

export function useAuxBar() {
    return useContext(AuxBarContext);
}
