import styles from './job-listing.module.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { JobsService } from '../../../temp/test';
import {
    InfoButton,
    JobList,
    LoaderForeground,
    PrimaryButton,
    Spinner
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Provides information about background jobs
 */

const BackOfficeJobListing = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleSimulateJob = async () => {
        try {
            await JobsService.getApiBackofficeJobsGeoInfo();
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['back-office-job-listing-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:back-office.job-listing.title')}
                            </div>
                            <div className="col-auto px-0 d-flex justify-content-between align-items-center">
                                <PrimaryButton
                                    text={t('buttons:simulation')}
                                    onClick={() => handleSimulateJob()}
                                />
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ height: '45rem' }}
                        >
                            <div className="col px-0 h-100">
                                <JobList />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default BackOfficeJobListing;

BackOfficeJobListing.propTypes = {};
