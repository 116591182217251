import React from 'react';
import styles from './DetailsEditButton.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import icoSave from '../../../assets/images/ico-save-blue.svg';

export const DetailsEditButton = ({ onCancelEditMode, onSaveEditMode }) => {
    const { t } = useCustomTranslation();
    return (
        <div
            className="col-auto px-0 d-flex justify-content-center align-items-center"
            style={{
                cursor: 'pointer',
                position: 'relative'
            }}
        >
            <span
                className={styles['details-edit-button-container__cancel-text']}
                onClick={() => onCancelEditMode()}
            >
                {t('common:cancel')}
            </span>
            <img
                loading="lazy"
                src={icoSave}
                alt="save"
                className={styles['details-edit-button-container__save-icon']}
                style={{
                    width: '2rem',
                    height: '2rem',
                    marginRight: '0.5rem'
                }}
            />
            <span
                className={styles['details-edit-button-container__save-text']}
                onClick={() => onSaveEditMode()}
            >
                {t('common:save')}
            </span>
        </div>
    );
};
