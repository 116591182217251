/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientClusterSchema } from '../models/ClientClusterSchema';
import type { ClusterParametersSchema } from '../models/ClusterParametersSchema';
import type { PointOfSaleGroupSchema } from '../models/PointOfSaleGroupSchema';
import type { PointOfSaleRulesGroupRulesSchema } from '../models/PointOfSaleRulesGroupRulesSchema';
import type { PointOfSaleSchema } from '../models/PointOfSaleSchema';
import type { RiskDistributionSchema } from '../models/RiskDistributionSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SegmentationService {
    /**
     * Returns list of all cliets and their clusters
     * @returns ClientClusterSchema call successful
     * @throws ApiError
     */
    public static getApiClusters({
        clientId,
    }: {
        /**
         * List of client ids.
         */
        clientId?: number,
    }): CancelablePromise<Array<ClientClusterSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clusters',
            query: {
                'client_id': clientId,
            },
        });
    }
    /**
     * Returns list of all clusters
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiClustersClustersInfo({
        clusterId,
        nVariables,
    }: {
        /**
         * List of cluster ids. Default all.
         */
        clusterId?: number,
        /**
         * Number of variables to return
         */
        nVariables?: number,
    }): CancelablePromise<Array<{
        client_count?: number;
        cluster?: ClusterParametersSchema;
        cluster_percent?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clusters/clusters_info',
            query: {
                'cluster_id': clusterId,
                'n_variables': nVariables,
            },
        });
    }
    /**
     * Returns a single cluster
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiClusters1({
        id,
        nVariables,
    }: {
        /**
         * id of cluster
         */
        id: number,
        /**
         * Number of variables to return
         */
        nVariables?: number,
    }): CancelablePromise<{
        client_count?: number;
        cluster?: ClusterParametersSchema;
        cluster_percent?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clusters/{id}',
            path: {
                'id': id,
            },
            query: {
                'n_variables': nVariables,
            },
        });
    }
    /**
     * Returns all groups
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static getApiSegmentation(): CancelablePromise<Array<PointOfSaleGroupSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation',
        });
    }
    /**
     * creates group
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static postApiSegmentation({
        requestBody,
    }: {
        /**
         * Optional description in *Markdown*
         */
        requestBody: PointOfSaleGroupSchema,
    }): CancelablePromise<PointOfSaleGroupSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/segmentation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * creates automatic sub groups. if both params are specify it prioritizes parent_group clients
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static postApiSegmentationBehaviouralSegments({
        requestBody,
    }: {
        requestBody: {
            clients?: Array<number>;
            parent_group_id?: number;
        },
    }): CancelablePromise<PointOfSaleGroupSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/segmentation/behavioural-segments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * gets entities and options for rules of segment creation
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSegmentationRulesEntities(): CancelablePromise<{
        groupers?: Array<string>;
        operators?: Array<string>;
        rules_opts?: Array<{
            entity?: string;
            grouper?: boolean;
            properties?: Array<string>;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/rules/entities',
        });
    }
    /**
     * previews clients that are part of these rules
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiSegmentationRulesPreview({
        requestBody,
    }: {
        /**
         * rules to be defined
         */
        requestBody: Array<PointOfSaleRulesGroupRulesSchema>,
    }): CancelablePromise<Array<{
        active?: boolean;
        avg_sentiment?: number;
        client_id?: number;
        group_id?: number;
        lat?: number;
        lon?: number;
        lost?: boolean;
        name?: string;
        pos_id?: number;
        risk?: number;
        total_revenue?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/segmentation/rules/preview',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * gets client options with extra params
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSegmentationSegmentationClients(): CancelablePromise<Array<{
        average_order?: number;
        avg_sentiment?: number;
        id?: number;
        last_order?: string;
        name?: string;
        perc_risk?: number;
        risk?: number;
        total_orders?: number;
        total_revenue?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/segmentation_clients',
        });
    }
    /**
     * segmentation_overview for segment
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSegmentationSegmentationOverview(): CancelablePromise<{
        count?: number;
        last_segment?: PointOfSaleGroupSchema;
        prev_month_count?: number;
        segments_at_risk?: Array<{
            id?: number;
            name?: string;
            risk?: number;
            risk_distribution?: Array<RiskDistributionSchema>;
        }>;
        total_clients?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/segmentation_overview',
        });
    }
    /**
     * deletes group by id
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static deleteApiSegmentation({
        id,
    }: {
        /**
         * group id
         */
        id: number,
    }): CancelablePromise<PointOfSaleGroupSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/segmentation/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * gest group by id
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static getApiSegmentation1({
        id,
    }: {
        /**
         * group id
         */
        id: number,
    }): CancelablePromise<PointOfSaleGroupSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * updates group
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static putApiSegmentation({
        id,
        requestBody,
    }: {
        /**
         * group id
         */
        id: number,
        requestBody: PointOfSaleGroupSchema,
    }): CancelablePromise<PointOfSaleGroupSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/segmentation/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * gets children segments of parent segment
     * @returns PointOfSaleGroupSchema call successful
     * @throws ApiError
     */
    public static getApiSegmentationChildren({
        id,
    }: {
        /**
         * group id
         */
        id: number,
    }): CancelablePromise<Array<PointOfSaleGroupSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/{id}/children',
            path: {
                'id': id,
            },
        });
    }
    /**
     * deletes clients of group
     * @returns PointOfSaleSchema call successful
     * @throws ApiError
     */
    public static deleteApiSegmentationClients({
        id,
        ids,
    }: {
        /**
         * group id
         */
        id: number,
        /**
         * group id
         */
        ids: Array<number>,
    }): CancelablePromise<Array<PointOfSaleSchema>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/segmentation/{id}/clients',
            path: {
                'id': id,
            },
            query: {
                'ids': ids,
            },
        });
    }
    /**
     * gets clients of group
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSegmentationClients({
        id,
    }: {
        /**
         * group id
         */
        id: number,
    }): CancelablePromise<Array<{
        active?: boolean;
        avg_sentiment?: number;
        client_id?: number;
        group_id?: number;
        lat?: number;
        lon?: number;
        lost?: boolean;
        name?: string;
        pos_id?: number;
        risk?: number;
        total_revenue?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/{id}/clients',
            path: {
                'id': id,
            },
        });
    }
    /**
     * gets clients of group
     * @returns PointOfSaleSchema call successful
     * @throws ApiError
     */
    public static postApiSegmentationClients({
        id,
        requestBody,
    }: {
        /**
         * group id
         */
        id: number,
        /**
         * ids to add
         */
        requestBody: {
            ids?: Array<number>;
        },
    }): CancelablePromise<Array<PointOfSaleSchema>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/segmentation/{id}/clients',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes clients of group
     * @returns number call successful
     * @throws ApiError
     */
    public static getApiSegmentationMonthlySales({
        id,
        minDate,
    }: {
        /**
         * group id
         */
        id: number,
        /**
         * minimum date to filter results.
         */
        minDate: string,
    }): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/{id}/monthly_sales',
            path: {
                'id': id,
            },
            query: {
                'min_date': minDate,
            },
        });
    }
    /**
     * gets comparable radar chart params
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiSegmentationRadarChart({
        id,
    }: {
        /**
         * group id
         */
        id: number,
    }): CancelablePromise<{
        billing?: {
            avg?: number;
            max?: number;
            min?: number;
        };
        risk?: {
            avg?: number;
            max?: number;
            min?: number;
        };
        sentiment?: {
            avg?: number;
            max?: number;
            min?: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/{id}/radar-chart',
            path: {
                'id': id,
            },
        });
    }
    /**
     * gets rules of segment
     * @returns PointOfSaleRulesGroupRulesSchema call successful
     * @throws ApiError
     */
    public static getApiSegmentationRules({
        id,
    }: {
        /**
         * group id
         */
        id: number,
    }): CancelablePromise<Array<PointOfSaleRulesGroupRulesSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/segmentation/{id}/rules',
            path: {
                'id': id,
            },
        });
    }
    /**
     * creates/replaces rules for a group
     * @returns PointOfSaleRulesGroupRulesSchema call successful
     * @throws ApiError
     */
    public static postApiSegmentationRules({
        id,
        requestBody,
    }: {
        /**
         * group id
         */
        id: number,
        /**
         * rules to be defined
         */
        requestBody: Array<PointOfSaleRulesGroupRulesSchema>,
    }): CancelablePromise<Array<PointOfSaleRulesGroupRulesSchema>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/segmentation/{id}/rules',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
