/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalDefinitionsSchema } from '../models/GlobalDefinitionsSchema';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefinitionsService {
    /**
     * returns distance_comp.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsDistanceComp(): CancelablePromise<{
        result?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/distance_comp',
        });
    }
    /**
     * post distance_comp.
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsDistanceComp({
        requestBody,
    }: {
        /**
         * distance_comp in meters
         */
        requestBody: {
            distance_comp?: number;
        },
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/distance_comp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns distance_poi.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsDistancePoi(): CancelablePromise<{
        result?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/distance_poi',
        });
    }
    /**
     * post distance_poi.
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsDistancePoi({
        requestBody,
    }: {
        /**
         * distance_poi in meters
         */
        requestBody: {
            distance_poi?: number;
        },
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/distance_poi',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns log_api_calls_enabled.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsLogApiCallsEnabled(): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/log_api_calls_enabled',
        });
    }
    /**
     * post log_api_calls_enabled.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsLogApiCallsEnabled({
        requestBody,
    }: {
        /**
         * set value for logging api calls to the database
         */
        requestBody: {
            log_api_calls_enabled?: boolean;
        },
    }): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/log_api_calls_enabled',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns poi_tags.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsPoiTags(): CancelablePromise<{
        result?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/poi_tags',
        });
    }
    /**
     * post poi_tags.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsPoiTags({
        requestBody,
    }: {
        /**
         * Optional description in *Markdown*
         */
        requestBody: {
            poi_tags?: number;
        },
    }): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/poi_tags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns time_active_client.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsTimeActiveClient(): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/time_active_client',
        });
    }
    /**
     * post time_active_client.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsTimeActiveClient({
        requestBody,
    }: {
        /**
         * time_active_client in months
         */
        requestBody: {
            time_active_client?: number;
        },
    }): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/time_active_client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns time_dormed_client.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsTimeDormedClient(): CancelablePromise<{
        result?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/time_dormed_client',
        });
    }
    /**
     * post time_dormed_client.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsTimeDormedClient({
        requestBody,
    }: {
        /**
         * Optional description in *Markdown*
         */
        requestBody: {
            time_dormed_client?: number;
        },
    }): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/time_dormed_client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns time_lost_client.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsTimeLostClient(): CancelablePromise<{
        result?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/time_lost_client',
        });
    }
    /**
     * post time_lost_client.
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsTimeLostClient({
        requestBody,
    }: {
        /**
         * Optional description in *Markdown*
         */
        requestBody: {
            time_lost_client?: number;
        },
    }): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/time_lost_client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * returns 2fa enabled or not.
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeSettingsTwoFaEnabled(): CancelablePromise<{
        two_fa_enabled?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/settings/two-fa-enabled',
        });
    }
    /**
     * 2fa enabled or not
     * @returns GlobalDefinitionsSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeSettingsTwoFaEnabled({
        requestBody,
    }: {
        requestBody: {
            two_fa_enabled?: boolean;
        },
    }): CancelablePromise<GlobalDefinitionsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/settings/two-fa-enabled',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
