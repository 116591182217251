import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const EvolByProductForecastTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (
        props.dataKey &&
        props.payload &&
        props.payload.length > 0 &&
        props.id !== undefined &&
        props.payload &&
        props.payload[props.id]
    ) {
        return (
            <TooltipContainer color={props.payload[props.id].color}>
                <TooltipHeaderRow
                    title={t(`tooltips:header.evol-by-product-forecast`)}
                    value={
                        <span>
                            {props.payload[0].payload[props.categoryAxis]}
                        </span>
                    }
                />
                <Separator />
                {!props.dataKey.toLowerCase().includes('forecast') && (
                    <>
                        <TooltipContentRow
                            title={t('tooltips:content.atc3-evol')}
                            value={`${props.payload[0].payload['category-atc3']}%`}
                            color={'#FF9441'}
                        />
                        <TooltipContentRow
                            title={t('tooltips:content.atc4-evol')}
                            value={`${props.payload[0].payload['category-atc4']}%`}
                            color={'#8C54FF'}
                        />
                    </>
                )}
                {props.dataKey.toLowerCase().includes('forecast') && (
                    <>
                        <TooltipContentRow
                            title={t('tooltips:content.atc3-evol-forecast')}
                            value={`${props.payload[0].payload['category-atc3ForecastValue']}%`}
                            color={'#FF9441'}
                        />
                        <TooltipContentRow
                            title={t('tooltips:content.atc4-evol-forecast')}
                            value={`${props.payload[0].payload['category-atc4ForecastValue']}%`}
                            color={'#8C54FF'}
                        />
                    </>
                )}
            </TooltipContainer>
        )
    }
})
