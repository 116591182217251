import React from 'react';
import styles from './GoalStatus.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

const statusTextColors = {
    draft: '#25ACBE',
    pendingApproval: '#A5A50D',
    approved: '#73AD76',
    completed: '#3170FE',
    inProgress: '#E2991B',
    notStarted: '#606060',
    onTrack: '#98d2c5',
    behindSchedule: '#e3a29e'
};

const statusBackgroundColors = {
    draft: '#c3f8fe',
    pendingApproval: '#F8F8004D',
    approved: '#7DD0814D',
    completed: '#3170FE4D',
    inProgress: '#F0BB5F4D',
    notStarted: '#D6D6D64D',
    onTrack: '#98d2c54d',
    behindSchedule: '#e3a29e4d'
};

const statusBorderColors = {
    draft: '#25ACBE',
    pendingApproval: '#E1E12C',
    approved: '#7DD081',
    completed: '#3170FE',
    inProgress: '#F0BB5F',
    notStarted: '#D6D6D6',
    onTrack: '#98d2c5',
    behindSchedule: '#e3a29e'
};

export const GoalStatus = ({ text, leftAligned, children }) => {
    const { t } = useCustomTranslation();
    return (
        <div>
            <span
                className={`${styles['goal-status-text']}`}
                style={{
                    color: statusTextColors[text],
                    backgroundColor: statusBackgroundColors[text],
                    borderColor: statusBorderColors[text]
                }}
            >
                {t(`common:${text}`)}
            </span>
            {children}
        </div>
    );
};
