import Joi from 'joi';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import icoEdit from '../../../assets/images/ico-edit.svg';
import { useTranslation } from 'react-i18next';
import styles from './sentiment-history.module.scss';
import { sentimentHistoryMock } from './mock';
import { useChannels } from '../sentiment-import-data/useChannels';
import { useColumns } from '../sentiment-import-data/useColumns';
import { useRows } from '../sentiment-import-data/useRows';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useTable } from '../../../hooks/useTable';
import { useTextInput } from '../../../hooks/useTextInput';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { errorHandler } from '../../../utils/api';
import { SentimentService } from '../../../temp/test';
import { verifyComponent } from '../../../utils/component';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { DeleteButton } from '../../buttons/delete-button/delete-button';
import { Spinner } from '../../loading/spinner/spinner';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Modal } from '../../modals/modal/modal';
import { SelectInput } from '../../inputs/select-input/select-input';
import { ListWrapper } from '../../scrolling/list-wrapper/list-wrapper';
import { TypeInput } from '../../inputs/type-input/type-input';
import { FileImport } from '../file-import/file-import';
import { FileList } from '../file-list/file-list';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays logins in a table
 */

export const SentimentHistory = React.memo(({ onSelectAnalysis }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const {
        channels: addedChannels,
        push: addedChannelsPush,
        setColumn: addedChannelsSetColumn,
        remove: addedChannelsRemove
    } = useChannels([]);
    const [addedChannelId, setAddedChannelId] = useState(0);

    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(sentimentHistoryMock, modal, '');

    const {
        value: title,
        reset: titleReset,
        setValue: titleSetValue,
        setError: titleSetError,
        replaceValue: titleReplaceValue
    } = useTextInput({
        name: 'title',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        columns: columns,
        setColumns: setColumns,
        clear: clearColumns
    } = useColumns([]);
    const { rows: rows, setRows: setRows, clear: clearRows } = useRows([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const [selectedFileLoading, toggleSelectedFileLoading] = useToggle(false);

    const [selectedAnalysisId, setSelectedAnalysisId] = useState('');
    const [selectedChannelId, setSelectedChannelId] = useState('');

    const {
        value: conversationId,
        addOption: conversationIdAddOption,
        toggleDropdown: conversationIdToggleDropdown,
        selectOption: conversationIdSelectOption,
        keyPress: conversationIdKeyPress,
        keyDown: conversationIdKeyDown,
        setValue: conversationIdSetValue,
        reset: conversationIdReset
    } = useSelectInput({
        name: 'conversationId',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const {
        value: from,
        addOption: fromAddOption,
        toggleDropdown: fromToggleDropdown,
        selectOption: fromSelectOption,
        keyPress: fromKeyPress,
        keyDown: fromKeyDown,
        setValue: fromSetValue,
        reset: fromReset
    } = useSelectInput({
        name: 'from',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const {
        value: to,
        addOption: toAddOption,
        toggleDropdown: toToggleDropdown,
        selectOption: toSelectOption,
        keyPress: toKeyPress,
        keyDown: toKeyDown,
        setValue: toSetValue,
        reset: toReset
    } = useSelectInput({
        name: 'to',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const {
        value: subject,
        addOption: subjectAddOption,
        toggleDropdown: subjectToggleDropdown,
        selectOption: subjectSelectOption,
        keyPress: subjectKeyPress,
        keyDown: subjectKeyDown,
        setValue: subjectSetValue,
        reset: subjectReset
    } = useSelectInput({
        name: 'subject',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });
    const {
        value: text,
        addOption: textAddOption,
        toggleDropdown: textToggleDropdown,
        selectOption: textSelectOption,
        keyPress: textKeyPress,
        keyDown: textKeyDown,
        setValue: textSetValue,
        reset: textReset
    } = useSelectInput({
        name: 'text',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });
    const {
        value: date,
        addOption: dateAddOption,
        toggleDropdown: dateToggleDropdown,
        selectOption: dateSelectOption,
        keyPress: dateKeyPress,
        keyDown: dateKeyDown,
        setValue: dateSetValue,
        reset: dateReset
    } = useSelectInput({
        name: 'date',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });

    const schema = Joi.object({
        title: Joi.string().required()
    });

    // const handleConversationIdSetValue = (e) => {
    //     clearColumn(conversationId.name)
    //     clearRow(conversationId.name)
    //     addedChannelsSetColumn(
    //         selectedChannelId,
    //         conversationId.name,
    //         undefined
    //     )
    //     conversationIdSetValue(e)
    // }

    // const handleFromSetValue = (e) => {
    //     clearColumn(from.name)
    //     clearRow(from.name)
    //     addedChannelsSetColumn(selectedChannelId, from.name, undefined)
    //     fromSetValue(e)
    // }

    // const handleToSetValue = (e) => {
    //     clearColumn(to.name)
    //     clearRow(to.name)
    //     addedChannelsSetColumn(selectedChannelId, to.name, undefined)
    //     toSetValue(e)
    // }

    // const handleSubjectSetValue = (e) => {
    //     clearColumn(subject.name)
    //     clearRow(subject.name)
    //     addedChannelsSetColumn(selectedChannelId, subject.name, undefined)
    //     subjectSetValue(e)
    // }

    // const handleTextSetValue = (e) => {
    //     clearColumn(text.name)
    //     clearRow(text.name)
    //     addedChannelsSetColumn(selectedChannelId, text.name, undefined)
    //     textSetValue(e)
    // }
    // const handleDateSetValue = (e) => {
    //     clearColumn(date.name)
    //     clearRow(date.name)
    //     addedChannelsSetColumn(selectedChannelId, date.name, undefined)
    //     dateSetValue(e)
    // }

    const handleAddColumnTable = useCallback(() => {
        clearColumns();
        conversationIdReset();

        fromReset();
        toReset();
        textReset();
        subjectReset();
        dateReset();
    }, [
        clearColumns,
        conversationIdReset,
        fromReset,
        toReset,
        textReset,
        subjectReset,
        dateReset
    ]);

    const handleOpenModal = useCallback(() => {
        if (isMounted.current) {
            toggleModal(true);
            setModalType('csvColumnMap');
        }
    }, [toggleModal, setModalType, isMounted]);

    const handleCloseCsvColumnMapModal = () => {
        const idx = _.findIndex(addedChannels, (el) => {
            return el.id === selectedChannelId;
        });
        if (idx !== -1) {
            addedChannelsRemove(selectedChannelId);
        }
        setSelectedChannelId('');
        setModalType('editAnalysisFiles');
    };

    const handleDeleteAnalysis = async () => {
        try {
            //
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleEditAnalysisDetails = async () => {
        try {
            const result = schema.validate(
                {
                    title: title.value
                },
                { abortEarly: false }
            );
            titleSetError();
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'title') {
                        titleSetError(el.type);
                    }
                    return null;
                });
                return;
            }

            await SentimentService.patchApiNlpAnalysis({
                id: selectedAnalysisId,
                requestBody: { name: title.value }
            });
            handleCloseModal();
            getAnalysis();
        } catch (err) {
            errorHandler(err);
        }
    };
    const handleEditAnalysisFiles = async () => {
        try {
            let jointSessionId = selectedAnalysisId;
            handleCloseModal();
            setLoading(true);
            for (const el of addedChannels) {
                if (el.type === 'csv') {
                    await SentimentService.postApiNlpExtractCsv({
                        formData: {
                            conversation_id: el.csvMap.conversationId,
                            csv: el.selectedFile,
                            date: el.csvMap.date,
                            from: el.csvMap.from,
                            input_keywords: [],
                            session_id: jointSessionId,
                            subject: el.csvMap.subject,
                            text: el.csvMap.text,
                            to: el.csvMap.to
                        }
                    });
                } else if (el.type === 'mbox') {
                    await SentimentService.postApiNlpExtractMbox({
                        formData: {
                            client_id: 1,
                            from_email: 'random@gmail.com',
                            input_keywords: [],
                            mbox: el.selectedFile,
                            session_id: jointSessionId
                        }
                    });
                } else if (el.type === 'pst') {
                    await SentimentService.postApiNlpExtractMbox({
                        formData: {
                            client_id: 1,
                            from_email: 'random@gmail.com',
                            input_keywords: [],
                            mbox: el.selectedFile,
                            session_id: jointSessionId
                        }
                    });
                }
            }

            getAnalysis();
        } catch (err) {
            errorHandler(err);
        }
        //onSetResults();
    };

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false);
            setModalType('');
        }
    };

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedAnalysisId(id);
            }
            if (option === 'editAnalysisDetails') {
                if (isMounted.current) {
                    titleReset();
                    const analysis = _.find(data.data, (el) => {
                        return id === el.id;
                    });

                    titleReplaceValue(analysis.name);
                }
            }
            if (isMounted.current) {
                toggleComponentSettings(false);
                toggleModal(true);
                setModalType(option);
            }
        },
        [
            isMounted,
            titleReset,
            toggleComponentSettings,
            toggleModal,
            titleReplaceValue,
            data
        ]
    );

    const getAnalysis = useCallback(async () => {
        const dataClone = _.cloneDeep(sentimentHistoryMock);
        try {
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            if (isMounted.current) setValue(dataClone);
            if (isMounted.current) setLoading(true);
            const result = await SentimentService.getApiNlpListAnalysis();
            result.map((el) => {
                if (el.session_id) {
                    dataClone.data.push({
                        id: el.session_id,
                        name: el.name,
                        user: el.created_by,
                        messages: el.messages,
                        date: el.classification_date,
                        isoDate: el.classification_date,
                        //startDate: el.min_date,
                        //endDate: el.max_date,
                        keywords: el.keyword_count,
                        action: {
                            label: t(`columns:action`),
                            id: el.session_id,
                            open: false,
                            options: [
                                {
                                    name: 'editAnalysisDetails',
                                    label: t(`settings:editAnalysisDetails`),
                                    icon: icoEdit
                                },
                                {
                                    name: 'editAnalysisFiles',
                                    label: t(`settings:editAnalysisFiles`),
                                    icon: icoEdit
                                }
                            ],
                            reference: React.createRef()
                        }
                    });
                }
                return el;
            });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            verifyComponent(dataClone, t);
            if (isMounted.current) setValue(dataClone);
            setLoading(false);
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue, t]);

    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);

    // const conversationIdCustomSelectOption = (
    //     value,
    //     valueAux,
    //     data,
    //     columns
    // ) => {
    //     if (isMounted.current) conversationIdSelectOption(value, valueAux)
    //     addedChannelsSetColumn(selectedChannelId, 'conversationId', value)
    //     handleSelectColumn('conversationId', value, valueAux, data, columns)
    // }

    // const fromCustomSelectOption = (value, valueAux, data, columns) => {
    //     if (isMounted.current) fromSelectOption(value, valueAux)
    //     addedChannelsSetColumn(selectedChannelId, 'from', value)
    //     handleSelectColumn('from', value, valueAux, data, columns)
    // }

    // const toCustomSelectOption = (value, valueAux, data, columns) => {
    //     if (isMounted.current) toSelectOption(value, valueAux)
    //     addedChannelsSetColumn(selectedChannelId, 'to', value)
    //     handleSelectColumn('to', value, valueAux, data, columns)
    // }

    // const subjectCustomSelectOption = (value, valueAux, data, columns) => {
    //     if (isMounted.current) subjectSelectOption(value, valueAux)
    //     addedChannelsSetColumn(selectedChannelId, 'subject', value)
    //     handleSelectColumn('subject', value, valueAux, data, columns)
    // }

    // const textCustomSelectOption = (value, valueAux, data, columns) => {
    //     if (isMounted.current) textSelectOption(value, valueAux)
    //     addedChannelsSetColumn(selectedChannelId, 'text', value)
    //     handleSelectColumn('text', value, valueAux, data, columns)
    // }

    // const dateCustomSelectOption = (value, valueAux, data, columns) => {
    //     if (isMounted.current) dateSelectOption(value, valueAux)
    //     addedChannelsSetColumn(selectedChannelId, 'date', value)
    //     handleSelectColumn('date', value, valueAux, data, columns)
    // }

    // const handleSelectColumn = (name, option, optionAux, data, columnsData) => {
    //     const newColumns = columnsData ? columnsData : _.cloneDeep(columns)
    //     newColumns.map((el) => {
    //         el.mapped = false
    //         return el
    //     })
    //     if (isMounted.current) {
    //         setColumns(newColumns)
    //         clearRows()
    //     }
    //     const newRows = []
    //     let newData = data ? data : selectedFileData
    //     newData.slice(0, Math.min(newData.length - 1, 5)).map((el) => {
    //         const newEl = {}
    //         newColumns.map((el2) => {
    //             let value = null
    //             if (el2.value === name) {
    //                 value = option
    //             } else if (el2.value === 'conversationId') {
    //                 value = conversationId.selectedOption
    //             } else if (el2.value === 'from') {
    //                 value = from.selectedOption
    //             } else if (el2.value === 'to') {
    //                 value = to.selectedOption
    //             } else if (el2.value === 'subject') {
    //                 value = subject.selectedOption
    //             } else if (el2.value === 'text') {
    //                 value = text.selectedOption
    //             } else if (el2.value === 'date') {
    //                 value = date.selectedOption
    //             }
    //             newEl[el2.value] = ''
    //             Object.entries(newData[0]).map((el3) => {
    //                 if (el3[0] === value) {
    //                     el2.mapped = true
    //                     if (
    //                         typeof el[value] !== 'string' &&
    //                         selectedFile &&
    //                         selectedFile.type === 'mbox'
    //                     ) {
    //                         if (
    //                             value === 'from' ||
    //                             value === 'to' ||
    //                             value === 'reply' ||
    //                             value === 'sender' ||
    //                             value === 'deliveredTo'
    //                         ) {
    //                             if (el[value].length) {
    //                                 el[value].map((el4, index4) => {
    //                                     if (index4 === 0) {
    //                                         if (
    //                                             typeof el4.address !== 'string'
    //                                         ) {
    //                                             newEl[el2.value] +=
    //                                                 el4.address.toString()
    //                                         } else {
    //                                             newEl[el2.value] += el4.address
    //                                         }
    //                                     } else {
    //                                         if (
    //                                             typeof el4.address !== 'string'
    //                                         ) {
    //                                             newEl[el2.value] +=
    //                                                 ' ' + el4.address.toString()
    //                                         } else {
    //                                             newEl[el2.value] +=
    //                                                 ' ' + el4.address
    //                                         }
    //                                     }
    //                                     return el4
    //                                 })
    //                             } else {
    //                                 if (typeof el[value].address !== 'string') {
    //                                     newEl[el2.value] =
    //                                         el[value].address.toString()
    //                                 } else {
    //                                     newEl[el2.value] = el[value].address
    //                                 }
    //                             }
    //                         } else if (value === 'headers') {
    //                             el[value].map((el4, index4) => {
    //                                 if (index4 === 0) {
    //                                     newEl[el2.value] +=
    //                                         el4.key + ' ' + el4.value
    //                                 } else {
    //                                     newEl[el2.value] +=
    //                                         '; ' + el4.key + ' ' + el4.value
    //                                 }
    //                                 return el4
    //                             })
    //                         } else {
    //                             newEl[el2.value] = el[value].toString()
    //                         }
    //                     } else if (
    //                         typeof el[value] !== 'string' &&
    //                         selectedFile &&
    //                         selectedFile.type === 'text/csv'
    //                     ) {
    //                         newEl[el2.value] = el[value].toString()
    //                     } else {
    //                         newEl[el2.value] = el[value]
    //                     }
    //                     return false
    //                 }
    //                 return el3
    //             })
    //             return el2
    //         })
    //         newRows.push(newEl)
    //         return el
    //     })
    //     if (isMounted.current) setRows(newRows)
    // }
    const handleCsvFile = useCallback(
        (file) => {
            const newChannel = {
                id: addedChannelId,
                type: 'csv',
                selectedFile: file,
                selectedFileName: file.name,
                csvMap: {
                    conversationId: undefined,
                    from: undefined,
                    to: undefined,
                    subject: undefined,
                    text: undefined,
                    date: undefined
                },
                ready: false
            };
            if (isMounted.current) {
                setSelectedFile(file);
                toggleSelectedFileLoading(true);
                clearColumns();
                clearRows();
                conversationIdReset();
                handleAddColumnTable();
                fromReset();
                toReset();
                textReset();
                subjectReset();
                dateReset();
                setSelectedChannelId(addedChannelId);
                setAddedChannelId(addedChannelId + 1);
                handleOpenModal();
            }
            Papa.parse(file, {
                complete: async (result) => {
                    const data = result.data;
                    const headers = [];
                    Object.entries(data[0]).map((el) => {
                        headers.push(el[0]);
                        return el;
                    });
                    if (isMounted.current) {
                        clearRows();
                        const newColumns = [
                            { value: 'conversationId', mapped: false },
                            { value: 'from', mapped: false },
                            { value: 'to', mapped: false },
                            { value: 'subject', mapped: false },
                            { value: 'text', mapped: false },
                            { value: 'date', mapped: false }
                        ];
                        setColumns(newColumns);
                        headers.map((el) => {
                            conversationIdAddOption(el, el);
                            fromAddOption(el, el);
                            toAddOption(el, el);
                            subjectAddOption(el, el);
                            textAddOption(el, el);
                            dateAddOption(el, el);
                        });
                        setSelectedFileData(_.cloneDeep(data));
                        newColumns.map((el) => {
                            el.mapped = false;
                            return el;
                        });
                        if (isMounted.current) {
                            setColumns(newColumns);
                            clearRows();
                        }
                        const newRows = [];
                        let newData = data ? data : selectedFileData;
                        newData
                            .slice(0, Math.min(newData.length - 1, 5))
                            .map((el) => {
                                const newEl = {};
                                newColumns.map((el2) => {
                                    newEl[el2.value] = '';
                                    Object.entries(el).map((el3) => {
                                        if (el3[0] === el2.value) {
                                            let value = null;
                                            if (
                                                el2.value ===
                                                    'conversationId' ||
                                                el2.value === 'number' ||
                                                el2.value === 'identifier'
                                            ) {
                                                value = conversationId.name;
                                                newChannel.csvMap.conversationId =
                                                    value;
                                                conversationIdSelectOption(
                                                    value,
                                                    value
                                                );
                                            } else if (el2.value === 'from') {
                                                value = from.name;
                                                newChannel.csvMap.from = value;
                                                fromSelectOption(value, value);
                                            } else if (el2.value === 'to') {
                                                value = to.name;
                                                newChannel.csvMap.to = value;
                                                toSelectOption(value, value);
                                            } else if (
                                                el2.value === 'subject'
                                            ) {
                                                value = subject.name;
                                                newChannel.csvMap.subject =
                                                    value;
                                                subjectSelectOption(
                                                    value,
                                                    value
                                                );
                                            } else if (
                                                el2.value === 'text' ||
                                                el2.value === 'content'
                                            ) {
                                                value = text.name;
                                                newChannel.csvMap.text = value;
                                                textSelectOption(value, value);
                                            } else if (el2.value === 'date') {
                                                value = date.name;
                                                newChannel.csvMap.date = value;
                                                dateSelectOption(value, value);
                                            }
                                            el2.mapped = true;
                                            if (
                                                typeof el[value] !== 'string' &&
                                                selectedFile &&
                                                selectedFile.type === 'mbox'
                                            ) {
                                                if (
                                                    value === 'from' ||
                                                    value === 'to' ||
                                                    value === 'reply' ||
                                                    value === 'sender' ||
                                                    value === 'deliveredTo'
                                                ) {
                                                    if (el[value].length) {
                                                        el[value].map(
                                                            (el4, index4) => {
                                                                if (
                                                                    index4 === 0
                                                                ) {
                                                                    if (
                                                                        typeof el4.address !==
                                                                        'string'
                                                                    ) {
                                                                        newEl[
                                                                            el2.value
                                                                        ] +=
                                                                            el4.address.toString();
                                                                    } else {
                                                                        newEl[
                                                                            el2.value
                                                                        ] +=
                                                                            el4.address;
                                                                    }
                                                                } else {
                                                                    if (
                                                                        typeof el4.address !==
                                                                        'string'
                                                                    ) {
                                                                        newEl[
                                                                            el2.value
                                                                        ] +=
                                                                            ' ' +
                                                                            el4.address.toString();
                                                                    } else {
                                                                        newEl[
                                                                            el2.value
                                                                        ] +=
                                                                            ' ' +
                                                                            el4.address;
                                                                    }
                                                                }
                                                                return el4;
                                                            }
                                                        );
                                                    } else {
                                                        if (
                                                            typeof el[value]
                                                                .address !==
                                                            'string'
                                                        ) {
                                                            newEl[el2.value] =
                                                                el[
                                                                    value
                                                                ].address.toString();
                                                        } else {
                                                            newEl[el2.value] =
                                                                el[
                                                                    value
                                                                ].address;
                                                        }
                                                    }
                                                } else if (
                                                    value === 'headers'
                                                ) {
                                                    el[value].map(
                                                        (el4, index4) => {
                                                            if (index4 === 0) {
                                                                newEl[
                                                                    el2.value
                                                                ] +=
                                                                    el4.key +
                                                                    ' ' +
                                                                    el4.value;
                                                            } else {
                                                                newEl[
                                                                    el2.value
                                                                ] +=
                                                                    '; ' +
                                                                    el4.key +
                                                                    ' ' +
                                                                    el4.value;
                                                            }
                                                            return el4;
                                                        }
                                                    );
                                                } else {
                                                    newEl[el2.value] =
                                                        el[value].toString();
                                                }
                                            } else if (
                                                typeof el[value] !== 'string' &&
                                                selectedFile &&
                                                selectedFile.type === 'text/csv'
                                            ) {
                                                newEl[el2.value] =
                                                    el[value].toString();
                                            } else {
                                                newEl[el2.value] = el[value];
                                            }
                                            return false;
                                        }
                                        return el3;
                                    });
                                    return el2;
                                });

                                newRows.push(newEl);
                                return el;
                            });
                        if (isMounted.current) {
                            setColumns(newColumns);
                            setRows(newRows);
                        }
                        toggleSelectedFileLoading(false);
                    }
                    addedChannelsPush(newChannel);
                },
                header: true
            });
        },
        [
            addedChannelId,
            addedChannelsPush,
            clearColumns,
            clearRows,
            conversationIdReset,
            dateReset,
            fromReset,
            handleAddColumnTable,
            handleOpenModal,
            subjectReset,
            textReset,
            toReset,
            toggleSelectedFileLoading,
            conversationId.name,
            conversationIdAddOption,
            conversationIdSelectOption,
            date.name,
            dateAddOption,
            dateSelectOption,
            from.name,
            fromAddOption,
            fromSelectOption,
            isMounted,
            selectedFile,
            selectedFileData,
            setColumns,
            setRows,
            subject.name,
            subjectAddOption,
            subjectSelectOption,
            text.name,
            textAddOption,
            textSelectOption,
            to.name,
            toAddOption,
            toSelectOption
        ]
    );

    const handleCsvFileSave = () => {
        addedChannelsSetColumn(selectedChannelId, 'ready', true);
        setModalType('editAnalysisFiles');
    };

    const handleMboxFile = useCallback(
        async (file) => {
            if (isMounted.current) {
                setSelectedFile(file);
                toggleSelectedFileLoading(false);
                clearColumns();
                conversationIdReset();
                fromReset();
                toReset();
                textReset();
                subjectReset();
                dateReset();
                clearRows();
                addedChannelsPush({
                    id: addedChannelId,
                    type: 'mbox',
                    selectedFile: file,
                    selectedFileName: file.name,
                    ready: true
                });
                setAddedChannelId(addedChannelId + 1);
            }
        },
        [
            addedChannelId,
            addedChannelsPush,
            clearColumns,
            clearRows,
            conversationIdReset,
            dateReset,
            fromReset,
            isMounted,
            subjectReset,
            textReset,
            toReset,
            toggleSelectedFileLoading
        ]
    );

    const handlePstFile = useCallback(
        async (file) => {
            if (isMounted.current) {
                setSelectedFile(file);
                toggleSelectedFileLoading(false);
                clearColumns();
                conversationIdReset();
                fromReset();
                toReset();
                textReset();
                subjectReset();
                dateReset();
                clearRows();
                addedChannelsPush({
                    id: addedChannelId,
                    type: 'pst',
                    selectedFile: file,
                    selectedFileName: file.name,
                    ready: true
                });
                setAddedChannelId(addedChannelId + 1);
            }
        },
        [
            addedChannelId,
            addedChannelsPush,
            clearColumns,
            clearRows,
            conversationIdReset,
            dateReset,
            fromReset,
            isMounted,
            subjectReset,
            textReset,
            toReset,
            toggleSelectedFileLoading
        ]
    );

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <React.Fragment>
                        {modal && modalType === 'csvColumnMap' && (
                            <Modal onToggleModal={handleCloseCsvColumnMapModal}>
                                <div
                                    className="row mx-0 d-flex justify-content-start align-items-center "
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <span className="modal-title">
                                        {t('modals:addsentimentchannel')}
                                    </span>
                                </div>
                                <div
                                    className={`row mx-0 w-100 flex-grow-1 ${styles['data-column-map']}`}
                                >
                                    <div
                                        className={`col d-flex flex-column justify-content-between align-items-center px-0 ${styles['data-column-map__data-column-map-container']}`}
                                    >
                                        <div
                                            className={`row mx-0 w-100 ${styles['data-column-map__data-column-map-container__header']} h-auto`}
                                        >
                                            <div
                                                className={`col ${styles['data-column-map__data-column-map-container__title-container']}`}
                                            >
                                                <span
                                                    className={` ${styles['data-column-map__data-column-map-container__title-container__title']}`}
                                                >
                                                    {t(
                                                        'pages:sentimentAnalysis.columnsMap.title'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 flex-grow-1">
                                            <div className="col px-0 h-100 d-flex flex-column justify-content-between">
                                                <div
                                                    className={`row mx-0 w-100 h-auto ${styles['data-column-map__data-column-map-container__column-row']}`}
                                                >
                                                    <div
                                                        className={
                                                            'col-2 px-0 h-100 pe-2'
                                                        }
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                conversationIdSetValue
                                                            }
                                                            onSelectInput={
                                                                conversationIdSelectOption
                                                            }
                                                            onKeyPress={
                                                                conversationIdKeyPress
                                                            }
                                                            onKeyDown={
                                                                conversationIdKeyDown
                                                            }
                                                            onToggleDropdown={
                                                                conversationIdToggleDropdown
                                                            }
                                                            {...conversationId}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'col-2 px-0 h-100 pe-2'
                                                        }
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                fromSetValue
                                                            }
                                                            onSelectInput={
                                                                fromSelectOption
                                                            }
                                                            onKeyPress={
                                                                fromKeyPress
                                                            }
                                                            onKeyDown={
                                                                fromKeyDown
                                                            }
                                                            onToggleDropdown={
                                                                fromToggleDropdown
                                                            }
                                                            {...from}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'col-2 px-0 h-100 pe-2'
                                                        }
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                toSetValue
                                                            }
                                                            onSelectInput={
                                                                toSelectOption
                                                            }
                                                            onKeyPress={
                                                                toKeyPress
                                                            }
                                                            onKeyDown={
                                                                toKeyDown
                                                            }
                                                            onToggleDropdown={
                                                                toToggleDropdown
                                                            }
                                                            {...to}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'col-2 px-0 px-0 h-100 pe-2'
                                                        }
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                subjectSetValue
                                                            }
                                                            onSelectInput={
                                                                subjectSelectOption
                                                            }
                                                            onKeyPress={
                                                                subjectKeyPress
                                                            }
                                                            onKeyDown={
                                                                subjectKeyDown
                                                            }
                                                            onToggleDropdown={
                                                                subjectToggleDropdown
                                                            }
                                                            {...subject}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'col-2 px-0 h-100 pe-2'
                                                        }
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                textSetValue
                                                            }
                                                            onSelectInput={
                                                                textSelectOption
                                                            }
                                                            onKeyPress={
                                                                textKeyPress
                                                            }
                                                            onKeyDown={
                                                                textKeyDown
                                                            }
                                                            onToggleDropdown={
                                                                textToggleDropdown
                                                            }
                                                            {...text}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'col-2 px-0 h-100'
                                                        }
                                                    >
                                                        <SelectInput
                                                            onChangeInput={
                                                                dateSetValue
                                                            }
                                                            onSelectInput={
                                                                dateSelectOption
                                                            }
                                                            onKeyPress={
                                                                dateKeyPress
                                                            }
                                                            onKeyDown={
                                                                dateKeyDown
                                                            }
                                                            onToggleDropdown={
                                                                dateToggleDropdown
                                                            }
                                                            {...date}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100 flex-grow-1 overflow-auto"
                                                    style={{
                                                        maxHeight: '20rem'
                                                    }}
                                                >
                                                    <div
                                                        className={`col px-0 h-100 ${styles['data-column-map__data-column-map-container__data-wrapper']}`}
                                                    >
                                                        {_.findIndex(
                                                            rows,
                                                            (el) => {
                                                                return !_.every(
                                                                    el,
                                                                    (v) =>
                                                                        v === ''
                                                                );
                                                            }
                                                        ) !== -1 && (
                                                            <React.Fragment>
                                                                <ListWrapper>
                                                                    {rows.map(
                                                                        (
                                                                            el,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className={`row mx-0 w-100 ${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper']}`}
                                                                                >
                                                                                    {columns.map(
                                                                                        (
                                                                                            el2,
                                                                                            index2
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={
                                                                                                        index2
                                                                                                    }
                                                                                                    className={
                                                                                                        index2 !==
                                                                                                        columns.length -
                                                                                                            1
                                                                                                            ? `col-2 px-0 h-100 ${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper__text-wrapper']} pe-2`
                                                                                                            : `col-2 px-0 h-100 ${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper__text-wrapper']}`
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        className={`${styles['data-column-map__data-column-map-container__data-wrapper__row-wrapper__text-wrapper__text']}`}
                                                                                                    >
                                                                                                        {
                                                                                                            el[
                                                                                                                el2
                                                                                                                    .value
                                                                                                            ]
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ListWrapper>
                                                                <hr
                                                                    className="my-4"
                                                                    style={{
                                                                        color: 'rgba(0,0,0,0.1)',
                                                                        opacity: 1
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mx-0 mt-3 d-flex justify-content-between align-items-center">
                                                    <div className="col-5 px-0">
                                                        <SecondaryButton
                                                            text={t(
                                                                'modals:cancel'
                                                            )}
                                                            onClick={
                                                                handleCloseCsvColumnMapModal
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-5 px-0">
                                                        <PrimaryButton
                                                            disabled={
                                                                !conversationId.selectedOption ||
                                                                !text.selectedOption ||
                                                                !date.selectedOption
                                                            }
                                                            text={t(
                                                                'modals:save'
                                                            )}
                                                            onClick={
                                                                handleCsvFileSave
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'editAnalysisDetails' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">
                                            {t('modals:editAnalysisDetails')}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 "
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0">
                                        <TypeInput
                                            onChangeInput={titleSetValue}
                                            {...title}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 mt-3 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton
                                            text={t('modals:cancel')}
                                            onClick={handleCloseModal}
                                        />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton
                                            disabled={!title.value}
                                            text={t('modals:save')}
                                            onClick={handleEditAnalysisDetails}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'editAnalysisFiles' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">
                                            {t('modals:editAnalysisFiles')}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col px-0">
                                        <FileImport
                                            onHandleCsvFile={handleCsvFile}
                                            onHandleMboxFile={handleMboxFile}
                                            onHandlePstFile={handlePstFile}
                                        />
                                    </div>
                                    <div className="col px-0">
                                        <FileList
                                            files={addedChannels}
                                            onFileRemove={addedChannelsRemove}
                                            selectedFile={selectedFile}
                                            selectedFileLoading={
                                                selectedFileLoading
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 mt-3 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton
                                            text={t('modals:cancel')}
                                            onClick={handleCloseModal}
                                        />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton
                                            disabled={
                                                addedChannels.length === 0 ||
                                                _.find(addedChannels, {
                                                    ready: false
                                                })
                                            }
                                            text={t('modals:save')}
                                            onClick={handleEditAnalysisFiles}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'removeAnalysis' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">
                                            {t(
                                                'pages:managerProfile.areyousure'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton
                                            text={t('modals:cancel')}
                                            onClick={handleCloseModal}
                                        />
                                    </div>
                                    <div className="col-5 px-0">
                                        <DeleteButton
                                            text={t('modals:remove')}
                                            onClick={handleDeleteAnalysis}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}

                        <Table
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(
                                `components:${data.attr}.nodata`
                            )}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={
                                !_.find(data.data, (el) => {
                                    return el.action.open;
                                })
                                    ? onSelectAnalysis
                                    : undefined
                            }
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </>
    );
});

SentimentHistory.propTypes = {};
