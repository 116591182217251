import React, { useCallback } from 'react';
import { GeneralTable } from '../general-table/general-table';
import { accessListMock } from './mock';
import { ClientAccountService } from '../../../temp/test';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useFilter } from '../../../providers/filter-context';
import { fromIsoDate } from '../../../utils/format';

export const AccessList = () => {
    const { t } = useCustomTranslation();
    const { filter } = useFilter();

    const getClientAccountId = useCallback(async () => {
        try {
            const backData = await ClientAccountService.getApiClientAccount({});
            const filteredData = backData.filter((e) => {
                return (
                    e.product_id === parseInt(filter.productId) &&
                    e.client_id === parseInt(filter.hospitalId)
                );
            });
            if (filteredData.length > 0) {
                return filteredData[0].id;
            } else if (filteredData.length === 0) {
                const result =
                    await ClientAccountService.postApiClientAccountUpsert({
                        requestBody: {
                            product_id: filter.productId,
                            client_id: filter.hospitalId
                        }
                    });
                return result.id;
            }
        } catch (err) {
            console.log(err);
        }
    }, [filter]);

    return (
        <GeneralTable
            title={t(`components:${accessListMock.attr}.title`)}
            attr={accessListMock.attr}
            columns={[
                {
                    path: 'id',
                    backendPath: 'id',
                    show: false
                },
                {
                    path: 'statuss',
                    label: 'Status',
                    backendPath: 'status',
                    show: true
                },
                {
                    path: 'date',
                    backendPath: 'date',
                    backendTransform: fromIsoDate,
                    show: true
                },
                {
                    path: 'commentary',
                    backendPath: 'notes',
                    show: true
                },
                {
                    path: 'protocol',
                    backendPath: 'protocol',
                    show: true
                },
                {
                    path: 'success',
                    backendPath: 'success',
                    show: true
                },
                {
                    path: 'type',
                    backendPath: 'type',
                    show: true
                },
                {
                    path: 'action',
                    show: true
                }
            ]}
            getEndpoint={
                ClientAccountService.getApiClientAccountActionsGetByParams
            }
            getConfig={{
                clientId: filter.hospitalId,
                productId: filter.productId
            }}
            addTitle={t(`components:${accessListMock.attr}.addTitle`)}
            addSuccessText={t(
                `components:${accessListMock.attr}.addSuccessText`
            )}
            addEndpoint={ClientAccountService.postApiClientAccountActionsUpsert}
            addBodyStaticInputs={[
                {
                    value: getClientAccountId,
                    backendPath: 'client_account_id'
                }
            ]}
            addBodyInputs={{
                date: {
                    name: 'date',
                    path: 'date',
                    backendPath: 'date',
                    type: 'date',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                notes: {
                    name: 'notes',
                    path: 'notes',
                    backendPath: 'notes',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                type: {
                    name: 'type',
                    path: 'type',
                    backendPath: 'type',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                status: {
                    name: 'status',
                    path: 'statuss',
                    backendPath: 'status',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                protocol: {
                    type: 'radio',
                    name: 'protocol',
                    path: 'protocol',
                    backendPath: 'protocol',
                    direction: 'horizontal',
                    options: [
                        {
                            name: 'yes',
                            label: t(`inputs:yes`),
                            disabled: false,
                            blocked: false
                        },
                        {
                            name: 'no',
                            label: t(`inputs:no`),
                            disabled: false,
                            blocked: false
                        }
                    ],
                    selectedOption: 'yes',
                    value: 'yes'
                },
                success: {
                    type: 'radio',
                    name: 'success',
                    path: 'success',
                    backendPath: 'success',
                    direction: 'horizontal',
                    options: [
                        {
                            name: 'yes',
                            label: t(`inputs:yes`),
                            disabled: false,
                            blocked: false
                        },
                        {
                            name: 'no',
                            label: t(`inputs:no`),
                            disabled: false,
                            blocked: false
                        }
                    ],
                    selectedOption: 'yes',
                    value: 'yes'
                }
            }}
            updateTitle={t(`components:${accessListMock.attr}.updateTitle`)}
            updateSuccessText={t(
                `components:${accessListMock.attr}.updateSuccessText`
            )}
            updateEndpoint={
                ClientAccountService.postApiClientAccountActionsUpsert
            }
            updateBodyStaticInputs={[
                {
                    path: 'id',
                    backendPath: 'id'
                }
            ]}
            updateBodyInputs={{
                date: {
                    name: 'date',
                    path: 'date',
                    backendPath: 'date',
                    type: 'date',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                notes: {
                    name: 'notes',
                    path: 'commentary',
                    backendPath: 'notes',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                type: {
                    name: 'type',
                    path: 'type',
                    backendPath: 'type',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                status: {
                    name: 'status',
                    path: 'statuss',
                    backendPath: 'status',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                protocol: {
                    type: 'radio',
                    name: 'protocol',
                    path: 'protocol',
                    backendPath: 'protocol',
                    direction: 'horizontal',
                    options: [
                        {
                            name: 'yes',
                            label: t(`inputs:yes`),
                            disabled: false,
                            blocked: false
                        },
                        {
                            name: 'no',
                            label: t(`inputs:no`),
                            disabled: false,
                            blocked: false
                        }
                    ],
                    selectedOption: 'yes',
                    value: 'yes'
                },
                success: {
                    type: 'radio',
                    name: 'success',
                    path: 'success',
                    backendPath: 'success',
                    direction: 'horizontal',
                    options: [
                        {
                            name: 'yes',
                            label: t(`inputs:yes`),
                            disabled: false,
                            blocked: false
                        },
                        {
                            name: 'no',
                            label: t(`inputs:no`),
                            disabled: false,
                            blocked: false
                        }
                    ],
                    selectedOption: 'yes',
                    value: 'yes'
                }
            }}
            deleteIds={[
                {
                    path: 'id',
                    backendPath: 'id'
                }
            ]}
            deleteEndpoint={ClientAccountService.deleteApiClientAccountActions}
            deleteTitle={t(`components:${accessListMock.attr}.deleteTitle`)}
            deleteDescription={t(
                `components:${accessListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${accessListMock.attr}.deleteSuccessText`
            )}
        />
    );
};
