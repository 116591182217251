import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import { t } from 'i18next'
import _ from 'lodash'

export const handleDownloadComponent = async (ref, compType) => {
    const image = await htmlToImage.toPng(
        ref.current.getElementsByClassName('content-container__second-row')[0]
    )
    download(image, `${compType}.png`)
}

export const calculateWH = (widthPx, heightPx, colWidth, rowHeight, margin) => {
    let w = Math.ceil((widthPx - margin[0]) / (colWidth + margin[0]))
    let h = Math.ceil((heightPx - margin[1]) / (rowHeight + margin[1]))
    return [w, h]
}

export const verifyComponent = (data) => {
    if (
        _.has(data, 'settings') &&
        _.has(data.settings, 'view') &&
        _.has(data.settings, 'options') &&
        data.settings.view.options.length === 0 &&
        data.settings.options.options.length === 0
    )
        data.disabled = true
    else data.disabled = false
    translateSections(data)
    return data
}

export const translateSections = (data) => {
    // if (data.columns) {
    //     data.columns.map((el) => {
    //         if (!el.translated) {
    //             el.label = t(`columns:${el.path}`)
    //             el.translated = true
    //         }
    //         return el
    //     })
    // }
    if (data.legend) {
        data.legend.map((el) => {
            if (!el.translated) {
                el.name = t(`components:${data.attr}.legend.${el.name}`)
                el.translated = true
            }
            return el
        })
    }
    if (data.categoryLabel) {
        data.categoryLabel = t(`components:labels.${data.categoryLabel}`)
    }
    if (data.chartOptions) {
        data.chartOptions.map((el) => {
            el.label = t(`components:chartOptions.${el.name}`)
        })
    }
    if (data.settings) {
        if (data.settings.view && data.settings.view.length !== 0) {
            data.settings.view.label = t('settings:title.view')
            data.settings.view.options.map((el) => {
                if (!el.translated) {
                    el.label = t(`settings:${el.name}`)
                    el.translated = true
                }
                return el
            })
        }
        if (data.settings.options && data.settings.options.length !== 0) {
            data.settings.options.label = t('settings:title.options')
            data.settings.options.options.map((el) => {
                if (!el.translated) {
                    el.label = t(`settings:${el.name}`)
                    el.translated = true
                }
                return el
            })
        }
        if (data.settings.selected && data.settings.selected.length !== 0) {
            data.settings.selected.label = t('settings:title.selected')
            data.settings.selected.options.map((el) => {
                if (!el.translated) {
                    el.label = t(`settings:${el.name}`)
                    el.translated = true
                }
                return el
            })
        }
        if (data.settings.viewOptions) {
            if (
                data.settings.viewOptions.ratioOptions &&
                data.settings.viewOptions.ratioOptions.length !== 0
            ) {
                data.settings.viewOptions.ratioOptions.options.map((el) => {
                    if (!el.translated) {
                        el.label = t(`settings:${el.name}`)
                        el.translated = true
                    }
                    return el
                })
            }
            if (
                data.settings.viewOptions.checkboxOptions &&
                data.settings.viewOptions.checkboxOptions.length !== 0
            ) {
                data.settings.viewOptions.checkboxOptions.options.map((el) => {
                    if (!el.translated) {
                        el.label = t(`settings:${el.name}`)
                        el.translated = true
                    }
                    return el
                })
            }
        }
    }
}
