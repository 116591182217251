import React from 'react'
import { UpcomingMilestones } from '../../../../../components/dashboard-components/upcoming-milestones/upcoming-milestones'
import { GoalProgress } from '../../../../../components/dashboard-components/goal-progress/goal-progress'
import { MyGoalsPortfolio } from '../../../../../components/dashboard-components/my-goals-portfolio/my-goals-portfolio'
import { HighImpactGoals } from '../../../../../components/dashboard-components/high-impact-goals/high-impact-goals'
import { GoalHistoryTimeline } from '../../../../../components/dashboard-components/goal-history-timeline/goal-history-timeline'

export const GoalOverview = ({ onSelectGoal }) => {
    return (
        <>
            <div className="row mx-0 w-100" style={{ height: '50rem', marginBottom: '2rem' }}>
                <div className="col px-0 h-100">
                    <GoalHistoryTimeline onSelectGoal={onSelectGoal} />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ height: '40rem', marginBottom: '2rem' }}>
                <div className="col px-0">
                    <MyGoalsPortfolio onSelectGoal={onSelectGoal} />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ height: '50rem', marginBottom: '2rem' }}>
                <div
                    className="col-auto h-100 px-0"
                    style={{
                        width: 'calc(70% - 1rem)',
                    }}
                >
                    <GoalProgress onSelectGoal={onSelectGoal} />
                </div>
                <div
                    className="col-auto px-0"
                    style={{
                        width: '2rem',
                    }}
                ></div>
                <div
                    className="col-auto h-100 px-0"
                    style={{
                        width: 'calc(30% - 1rem)',
                    }}
                >
                    <HighImpactGoals />
                </div>
            </div>
            {/* <div
                className="row mx-0 w-100"
                style={{ height: '50rem', marginBottom: '2rem' }}
            >
                <div className="col px-0 h-100">
                    <UpcomingMilestones />
                </div>
            </div> */}
        </>
    )
}
