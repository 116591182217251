import React, { useMemo, useEffect, useCallback } from 'react'
import _ from 'lodash'
import styles from './map-container-new.module.scss'
import ReactMapGL, { Popup, Source, Layer, NavigationControl } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import { useToggle } from '../../../hooks/useToggle'
import { TooltipFactory } from '../..'
import useCursorTracking from '../../../hooks/useCursorTracking'

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

export const MapContainerNew = React.memo((props) => {
    const {
        dataList,
        risk1Data,
        risk2Data,
        risk3Data,
        risk4Data,
        competitionData,
        selectedData,
        minScale,
        minZoom,
        tooltipType,
        startingPosition,
        scale,
        draggingDisabled,
        hoveringDisabled,
        zoomDisabled,
        translate,
        onMouseClickMap,
        onMouseOverMap,
        reference,
        customScale,
    } = props
    const mapRef = useMemo(() => reference || React.createRef(), [reference])
    const [showPopup, toggleShowPopup] = useToggle(false)
    const [selectedEl, setSelectedEl] = React.useState({})
    const [isMapLoaded] = useToggle(true)

    let newScale = minScale || 2
    const newMinZoom = minZoom || 9
    const zoom = startingPosition ? startingPosition.zoom : { lat: 38.712458, lon: -9.140459, zoom: 10 }

    if (zoom > newMinZoom) {
        newScale = zoom - newMinZoom + 1
        newScale = scale ? newScale * scale : newScale
    } else {
        newScale = minScale
    }

    const riskLayerStyle = (color, opacity) => ({
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': color,
            'circle-opacity': opacity,
        },
    })

    const layerList = []
    const risk1LayerStyleLarge = {
        id: 'data-risk-1-style-large',
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': '#6fdb7d',
            'circle-opacity': 0.3,
        },
    }
    const risk1LayerStyleSmall = {
        id: 'data-risk-1-style-small',
        type: 'circle',
        paint: {
            'circle-radius': 0.3 * newScale,
            'circle-color': '#6fdb7d',
            'circle-opacity': 1,
        },
    }
    const risk2LayerStyleLarge = {
        id: 'data-risk-2-style-large',
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': '#dbdb6f',
            'circle-opacity': 0.3,
        },
    }
    const risk2LayerStyleSmall = {
        id: 'data-risk-2-style-small',
        type: 'circle',
        paint: {
            'circle-radius': 0.3 * newScale,
            'circle-color': '#dbdb6f',
            'circle-opacity': 1,
        },
    }
    const risk3LayerStyleLarge = {
        id: 'data-risk-3-style-large',
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': '#ffbd77',
            'circle-opacity': 0.3,
        },
    }
    const risk3LayerStyleSmall = {
        id: 'data-risk-3-style-small',
        type: 'circle',
        paint: {
            'circle-radius': 0.3 * newScale,
            'circle-color': '#ffbd77',
            'circle-opacity': 1,
        },
    }
    const risk4LayerStyleLarge = {
        id: 'data-risk-4-style-large',
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': '#ff6363',
            'circle-opacity': 0.3,
        },
    }
    const risk4LayerStyleSmall = {
        id: 'data-risk-4-style-small',
        type: 'circle',
        paint: {
            'circle-radius': 0.3 * newScale,
            'circle-color': '#ff6363',
            'circle-opacity': 1,
        },
    }
    const competitionLayerStyleLarge = {
        id: 'data-competition-style-large',
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': '#34b1eb',
            'circle-opacity': 0.3,
        },
    }
    const competitionLayerStyleSmall = {
        id: 'data-competition-style-small',
        type: 'circle',
        paint: {
            'circle-radius': 0.3 * newScale,
            'circle-color': '#34b1eb',
            'circle-opacity': 1,
        },
    }
    const selectedLayerStyleLarge = {
        id: 'data-selected-style-large',
        type: 'circle',
        paint: {
            'circle-radius': 1 * newScale,
            'circle-color': '#4c4c4c',
            'circle-opacity': 0.3,
        },
    }
    const selectedLayerStyleSmall = {
        id: 'data-selected-style-small',
        type: 'circle',
        paint: {
            'circle-radius': 0.3 * newScale,
            'circle-color': '#4c4c4c',
            'circle-opacity': 1,
        },
    }

    if (risk1Data && !_.isEmpty(risk1Data)) {
        layerList.push('data-risk-1-style-large')
        layerList.push('data-risk-1-style-small')
    }
    if (risk2Data && !_.isEmpty(risk2Data)) {
        layerList.push('data-risk-2-style-large')
        layerList.push('data-risk-2-style-small')
    }
    if (risk3Data && !_.isEmpty(risk3Data)) {
        layerList.push('data-risk-3-style-large')
        layerList.push('data-risk-3-style-small')
    }
    if (risk4Data && !_.isEmpty(risk4Data)) {
        layerList.push('data-risk-4-style-large')
        layerList.push('data-risk-4-style-small')
    }
    if (competitionData && !_.isEmpty(competitionData)) {
        layerList.push('data-competition-style-large')
        layerList.push('data-competition-style-small')
    }
    if (selectedData && !_.isEmpty(selectedData)) {
        layerList.push('data-selected-style-large')
        layerList.push('data-selected-style-small')
    }
    // const dataLayers = useMemo(
    //     () => [
    //         risk1Data,
    //         risk2Data,
    //         risk3Data,
    //         risk4Data,
    //         competitionData,
    //         selectedData
    //     ],
    //     [
    //         risk1Data,
    //         risk2Data,
    //         risk3Data,
    //         risk4Data,
    //         competitionData,
    //         selectedData
    //     ]
    // );

    // dataLayers.forEach((data, index) => {
    //     if (data && !_.isEmpty(data)) {
    //         layerList.push(`data-risk-${index + 1}-style-large`);
    //         layerList.push(`data-risk-${index + 1}-style-small`);
    //     }
    // });

    const handleLoad = useCallback(() => {
        const layers = []
        if (risk1Data && !_.isEmpty(risk1Data)) {
            layers.push('data-risk-1')
        }
        if (risk2Data && !_.isEmpty(risk2Data)) {
            layers.push('data-risk-2')
        }
        if (risk3Data && !_.isEmpty(risk3Data)) {
            layers.push('data-risk-3')
        }
        if (risk4Data && !_.isEmpty(risk4Data)) {
            layers.push('data-risk-4')
        }
        if (competitionData && !_.isEmpty(competitionData)) {
            layers.push('data-competition')
        }
        if (selectedData && !_.isEmpty(selectedData)) {
            layers.push('data-selected')
        }
        if (dataList && dataList.length !== 0) {
            dataList.map((el) => {
                layers.push(el.id)
                return el
            })
        }
        // dataLayers.forEach((data, index) => {
        //     if (data && !_.isEmpty(data)) {
        //         layers.push(`data-risk-${index + 1}`);
        //     }
        // });

        if (dataList && dataList.length !== 0) {
            dataList.forEach((el) => {
                layers.push(el.id)
            })
        }

        mapRef.current.on('click', (e) => {
            let features = mapRef.current.queryRenderedFeatures(e.point, {
                layers: layers,
            })
            if (!features.length) {
                return
            }
            let feature = features[0]
            setSelectedEl(feature.properties)
            // if (onMouseClickMap) {
            //     onMouseClickMap(
            //         feature.properties,
            //         feature.properties.regionId
            //     );
            // }
        })
        mapRef.current.on('mousemove', (e) => {
            let features = mapRef.current.queryRenderedFeatures(e.point, {
                layers: layers,
            })
            if (!features.length) {
                toggleShowPopup(false)
                setSelectedEl({})
                return
            }
            let feature = features[0]

            const regexRisk = new RegExp(`risk`, 'i')
            const regexCompetitors = new RegExp(`competition`, 'i')
            const regexSelected = new RegExp(`selected`, 'i')
            if (
                feature.layer.id.match(regexRisk) ||
                feature.layer.id.match(regexCompetitors) ||
                feature.layer.id.match(regexSelected) ||
                customScale
            ) {
                setSelectedEl(feature.properties)
                toggleShowPopup(true)
                if (onMouseOverMap) {
                    onMouseOverMap(feature.properties)
                }
            }
        })
        mapRef.current.on('mouseout', (e) => {
            toggleShowPopup(false)
            setSelectedEl({})
            return
        })
    }, [
        risk1Data,
        risk2Data,
        risk3Data,
        risk4Data,
        competitionData,
        selectedData,
        customScale,
        onMouseClickMap,
        dataList,
        onMouseOverMap,
        toggleShowPopup,
        mapRef,
    ])

    const getCursor = ({ isHovering, isDragging }) => {
        return isDragging && !draggingDisabled ? 'grabbing' : isHovering && !hoveringDisabled ? 'pointer' : 'default'
    }

    return (
        <div className={styles['map-container']} data-testid={'hospital-map'}>
            {!isMapLoaded && <div className={styles['map-container__map-placeholder']}></div>}
            <ReactMapGL
                onRender={(event) => event.target.resize()}
                dragPan={!draggingDisabled}
                style={!isMapLoaded ? { visibility: 'hidden' } : { visibility: 'visible' }}
                ref={mapRef}
                onLoad={handleLoad}
                interactiveLayerIds={[...layerList]}
                minZoom={1}
                maxZoom={18}
                attributionControl={false}
                className={styles['map-container__map']}
                mapStyle="mapbox://styles/rvelhinho/cko348qo1127u19n98oz1nble?optimize=true"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                cursor={getCursor}
                scrollZoom={false}
                doubleClickZoom={!zoomDisabled}
                initialViewState={{
                    longitude: startingPosition ? startingPosition.lon : -9.140459,
                    latitude: startingPosition ? startingPosition.lat : 38.712458,
                    zoom: startingPosition ? startingPosition.zoom : 10,
                }}
            >
                {risk1Data && !_.isEmpty(risk1Data) && (
                    <Source id="data-risk-1" type="geojson" data={risk1Data}>
                        <Layer {...risk1LayerStyleLarge} id="data-risk-1" />
                        <Layer {...risk1LayerStyleSmall} />
                    </Source>
                )}
                {risk2Data && !_.isEmpty(risk2Data) && (
                    <Source id="data-risk-2" type="geojson" data={risk2Data}>
                        <Layer {...risk2LayerStyleLarge} id="data-risk-2" />
                        <Layer {...risk2LayerStyleSmall} />
                    </Source>
                )}
                {risk3Data && !_.isEmpty(risk3Data) && (
                    <Source id="data-risk-3" type="geojson" data={risk3Data}>
                        <Layer {...risk3LayerStyleLarge} id="data-risk-3" />
                        <Layer {...risk3LayerStyleSmall} />
                    </Source>
                )}
                {risk4Data && !_.isEmpty(risk4Data) && (
                    <Source id="data-risk-4" type="geojson" data={risk4Data}>
                        <Layer {...risk4LayerStyleLarge} id="data-risk-4" />
                        <Layer {...risk4LayerStyleSmall} />
                    </Source>
                )}
                {competitionData && !_.isEmpty(competitionData) && (
                    <Source id="data-competition" type="geojson" data={competitionData}>
                        <Layer {...competitionLayerStyleLarge} id="data-competition" />
                        <Layer {...competitionLayerStyleSmall} />
                    </Source>
                )}
                {selectedData && !_.isEmpty(selectedData) && (
                    <Source id="data-selected" type="geojson" data={selectedData}>
                        <Layer {...selectedLayerStyleLarge} id="data-selected" />
                        <Layer {...selectedLayerStyleSmall} />
                    </Source>
                )}
                {dataList &&
                    dataList.length !== 0 &&
                    dataList.map((el) => {
                        return (
                            <Source key={el.id} id={el.id} type="geojson" data={el.data}>
                                <Layer {...el.styleLarge} id={el.id} />
                                <Layer {...el.styleSmall} />
                            </Source>
                        )
                    })}
                {/* {dataLayers.map((data, index) => {
                    return (
                        data &&
                        !_.isEmpty(data) && (
                            <Source
                                key={`data-risk-${index + 1}`}
                                id={`data-risk-${index + 1}`}
                                type="geojson"
                                data={data}
                            >
                                <Layer
                                    {...riskLayerStyle(
                                        [
                                            '#6fdb7d',
                                            '#dbdb6f',
                                            '#ffbd77',
                                            '#ff6363',
                                            '#34b1eb'
                                        ][index],
                                        [0.3, 1][index]
                                    )}
                                />
                            </Source>
                        )
                    );
                })} */}
                {!zoomDisabled && <NavigationControl style={{ left: '10px', top: '10px' }} showCompass={false} />}
                {showPopup && !_.isEmpty(selectedEl) && !hoveringDisabled && (
                    <Popup latitude={selectedEl.lat} longitude={selectedEl.lon} anchor="bottom" closeButton={false} maxWidth="none">
                        <TooltipFactory
                            item={{
                                type: tooltipType.split('-')[0] || '',
                                interval: tooltipType.split('-')[1] ? tooltipType.split('-')[1] : 'monthly',
                                unitsOrValues: undefined,
                                translate: translate || undefined,
                                loc: {
                                    ...selectedEl,
                                },
                                customScale: customScale,
                                color: selectedEl.innerColor,
                            }}
                        />
                    </Popup>
                )}
            </ReactMapGL>
        </div>
    )
})
