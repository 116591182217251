import styles from './dashboard-preview-card.module.scss'

import PropTypes from 'prop-types'
import React from 'react'
import clientDetailsPageImage from '../../../assets/images/client-details-image.png'
import marketingPageImage from '../../../assets/images/marketing-image.png'
import productPageImage from '../../../assets/images/product-image.png'
import salesPageImage from '../../../assets/images/sales-image.png'
import sentimentPageImage from '../../../assets/images/sentiment-image.png'
import icoChooseTemplate from '../../../assets/images/icoChooseTemplate2.svg'
import { PrimaryButton, TerciaryButton } from '../../'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import icoPreviewTemplate from '../../../assets/images/icoPreviewTemplate.svg'
import { OutlineButton } from '../../buttons/outline-button/outline-button'

/**
 * Displays a card containing a preview of each type of possible dashboard to create
 */

export const DashboardPreviewCard = React.memo(({ name, page, onChooseTemplate, onPreviewTemplate }) => {
    const { t } = useCustomTranslation()
    const [isImageHovered, setIsImageHovered] = React.useState(false)
    let bg
    if (page === 'clientsGeneralPage') {
        bg = marketingPageImage
    } else if (page === 'clientsDetailedPage') {
        bg = clientDetailsPageImage
    } else if (page === 'generalPage') {
        bg = salesPageImage
    } else if (page === 'productsPage') {
        bg = productPageImage
    } else if (page === 'marketingPage') {
        bg = marketingPageImage
    } else if (page === 'sentimentPage') {
        bg = sentimentPageImage
    } else {
        bg = salesPageImage
    }
    return (
        <React.Fragment>
            <div className={`d-flex flex-column justify-content-between align-items-center ${styles['dashboard-preview-container']}`}>
                {bg && (
                    <>
                        <div
                            className={`${styles['dashboard-preview-container__options-row__option-container__image']}`}
                            onMouseOver={() => setIsImageHovered(true)}
                            onMouseOut={() => setIsImageHovered(false)}
                        >
                            <img
                                loading="lazy"
                                src={bg}
                                alt="background"
                                className={`${styles['dashboard-preview-container__options-row__option-container__image']}`}
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    marginBottom: '1rem',
                                    transform: isImageHovered ? 'scale(1.05)' : 'scale(1)',
                                }}
                            />
                            {isImageHovered && page !== 'blankReport' && (
                                <div
                                    className={`${styles['dashboard-preview-container__options-row__option-container__image__overlay']}`}
                                    onClick={() => onPreviewTemplate(page, name)}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        <OutlineButton text="Preview" icon={icoPreviewTemplate} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {!bg && <div className={`${styles['dashboard-preview-container__options-row__option-container__image']}`} />}

                <div className={`${styles['dashboard-preview-container__options-row__text-container']}`}>
                    <span className={`${styles['dashboard-preview-container__options-row__text-container__title']}`}>
                        {t(`components:previews.${name}.title`)}
                    </span>
                    <span className={`${styles['dashboard-preview-container__options-row__text-container__sub-title']}`}>
                        {t(`components:previews.${name}.description`)}
                    </span>
                </div>
                <div className={`${styles['dashboard-preview-container__options-row__button-container']}`}>
                    <div className={`${styles['dashboard-preview-container__options-row__button-container__button']}`}>
                        <PrimaryButton
                            icon={icoChooseTemplate}
                            text={page !== 'blankReport' ? t(`components:previews.${name}.chooseTemplate`) : t(`components:previews.${name}.select`)}
                            onClick={onChooseTemplate ? () => onChooseTemplate(page, 'normal') : () => {}}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

DashboardPreviewCard.propTypes = {
    /**
     * Labels the component
     */
    name: PropTypes.string,
    /**
     * Decides what image to use
     */
    page: PropTypes.string,
    /**
     * Propagates selection of template
     */
    onChooseTemplate: PropTypes.func,
    /**
     * Propagates preview of template
     */
    onPreviewTemplate: PropTypes.func,
}
