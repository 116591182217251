import React, { useEffect } from 'react';
import { saveAs } from 'file-saver';
import ReportDocumentFactory from '../report-document-factory/report-document-factory';

export const ReportTemplate = React.memo(() => {
    // useEffect(() => {
    //     const fill = async () => {
    //         const document = await pdf(
    //             <ReportDocumentFactory type={'sales'} />
    //         ).toBlob()
    //         saveAs(document, 'asd.pdf')
    //     }
    //     fill()
    // }, [])
    return <div></div>;
});

ReportTemplate.propTypes = {};

export default ReportTemplate;
