import React from 'react';
import styles from './toggle-button.module.scss';

export const ToggleButton = React.memo(({ bool, onClick }) => {
	return (
		<div
			className={
				bool
					? `${styles['toggle-button-container']} ${styles['toggle-button-container--on']}`
					: `${styles['toggle-button-container']} ${styles['toggle-button-container--off']}`
			}
			onClick={() => onClick()}
		>
			<div
				className={
					bool
						? `${styles['toggle-button-container__circle']} ${styles['toggle-button-container__circle--on']}`
						: `${styles['toggle-button-container__circle']} ${styles['toggle-button-container__circle--off']}`
				}
			></div>
		</div>
	);
});
