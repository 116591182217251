import React, { useState } from 'react';
import styles from './segment-results.module.scss';
import { SegmentationService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { SegmentGeneralDetails } from '../segment-general-details';
import { SegmentComparison } from '../segment-comparison';
import { SegmentList } from '../segment-list';
import { SegmentRules } from '../SegmentRules/segment-rules';
import { SegmentClientsList } from '../segment-clients-list';
import { SegmentRevenueSales } from '../segment-revenue-sales';
import { SegmentGeo } from '../segment-geo';
import { SegmentRiskComparison } from '../segment-risk-comparison';
//import { SentimentProportions } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-proportions/sentiment-proportions';
//import { SentimentOverTime } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-over-time/sentiment-over-time';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SegmentResults = React.memo(
    ({ segmentId, childrenCount, onSelectSegment }) => {
        const [segmentType, setSegmentType] = useState('');
        React.useEffect(() => {
            async function fill() {
                try {
                    const results =
                        await SegmentationService.getApiSegmentation1({
                            id: segmentId
                        });
                    setSegmentType(results.type);
                } catch (err) {
                    errorHandler(err);
                }
            }
            fill();
        }, [segmentId]);

        return (
            <div
                className={`row mx-0 w-100 ${styles['segment-results-container']}`}
            >
                <div className="col px-0 h-100 d-flex flex-column align-items-center">
                    <div
                        className={`row w-100 ${styles['segment-results-container__content-row']} mb-3`}
                        style={{ height: '45rem' }}
                    >
                        <div className="col px-0 pe-3 h-100">
                            <SegmentGeneralDetails
                                segmentId={segmentId}
                                childrenCount={childrenCount}
                                onSelectSegment={onSelectSegment}
                            />
                        </div>
                        <div className="col-6 px-0 h-100">
                            <SegmentComparison segmentId={segmentId} />
                        </div>
                    </div>
                    {childrenCount > 0 && (
                        <div
                            className={`row w-100 ${styles['segment-results-container__analysis-row']} mb-3`}
                        >
                            <div className="col px-0 h-100">
                                <SegmentList
                                    parentSegmentId={segmentId}
                                    onSelectSegment={onSelectSegment}
                                />
                            </div>
                        </div>
                    )}
                    {segmentType === 'rule_group' && (
                        <div
                            className={`row w-100 ${styles['segment-results-container__analysis-row']} mb-3`}
                        >
                            <div className="col px-0 h-100">
                                <SegmentRules segmentId={segmentId} />
                            </div>
                        </div>
                    )}
                    <div
                        className={`row w-100 ${styles['segment-results-container__analysis-row']} mb-3`}
                    >
                        <div className="col px-0 h-100">
                            <SegmentClientsList segmentId={segmentId} />
                        </div>
                    </div>
                    <div
                        className={`row w-100 ${styles['segment-results-container__analysis-row']} mb-3`}
                    >
                        <div className="col px-0 h-100">
                            <SegmentRevenueSales segmentId={segmentId} />
                        </div>
                    </div>
                    <div
                        className={`row w-100 ${styles['segment-results-container__analysis-row']} mb-3`}
                    >
                        <div className="col px-0 pe-3 h-100">
                            <SegmentGeo segmentId={segmentId} />
                        </div>
                        {/* <div className="col-4 px-0 h-100">
                            <SegmentRiskComparison segmentId={segmentId} />
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
);

SegmentResults.propTypes = {};
