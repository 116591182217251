import styles from './end-consumer-information.module.scss';

import _ from 'lodash';
import React, { useEffect, useCallback, useState } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { endConsumerInformationMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { ContentContainer, Loader, Spinner } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { CustomEntryTable } from '../../tables/custom-entry-table/custom-entry-table';
import { useToggle } from '../../../hooks/useToggle';
import { ClientAccountService } from '../../../temp/test';
import { usePreviewMode } from '../../dashboard-creation/library-component-factory/preview-mode-provider';

/**
 * Displays general information about a client
 */

export const EndConsumerInformation = React.memo(
    ({
        onDownloadComponent,
        config,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { isExample } = usePreviewMode();
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [hasHeaderRow, setHasHeaderRow] = useState(false);
        const [hasHeaderColumn, setHasHeaderColumn] = useState(false);
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(endConsumerInformationMock);
        const [isEditing, toggleIsEditing] = useToggle(false);

        const [matrix, setMatrix] = useState([
            ['', ''],
            ['', '']
        ]);

        const [columnWidths, setColumnWidths] = useState(
            matrix[0].map(() => 100)
        );

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(endConsumerInformationMock);
            if (!isExample) {
                setLoading(true);
                try {
                    if (!isExample) {
                        const backData =
                            await ClientAccountService.getApiClientAccountProductKpiGetByParams(
                                {
                                    productId: filter.productId,
                                    clientId: filter.hospitalId
                                }
                            );

                        dataClone.lastUpdated = backData.updated_at;
                        if (backData.kpi_map !== null) {
                            setMatrix(JSON.parse(backData.kpi_map).json);
                            setColumnWidths(
                                JSON.parse(backData.kpi_map).columnWidths
                            );
                            dataClone.kpi_map = backData.kpi_map;
                            setHasHeaderRow(
                                JSON.parse(backData.kpi_map).hasHeaderRow
                            );
                            setHasHeaderColumn(
                                JSON.parse(backData.kpi_map).hasHeaderColumn
                            );
                        }
                        setValue(dataClone);
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                    }
                } catch (err) {
                    setMatrix([
                        ['', ''],
                        ['', '']
                    ]);
                    setColumnWidths([100, 100]);

                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            } else {
                if (type) dataClone.attr = type;
                verifyComponent(dataClone);
                setValue(dataClone);
            }
            setLoading(false);
        }, [filter, isExample, isMounted, setValue, setLoading, type]);

        useEffect(() => {
            getInformation();
        }, [getInformation]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        const getId = useCallback(async () => {
            try {
                const backData =
                    await ClientAccountService.getApiClientAccountProductKpiGetByParams(
                        {
                            productId: filter.productId,
                            clientId: filter.hospitalId
                        }
                    );
                return backData.id;
            } catch (err) {
                console.log(err);
            }
        }, [filter.hospitalId, filter.productId]);

        const getClientAccountId = useCallback(async () => {
            try {
                const backData = await ClientAccountService.getApiClientAccount(
                    {}
                );
                const filteredData = backData.filter((e) => {
                    return (
                        e.product_id === parseInt(filter.productId) &&
                        e.client_id === parseInt(filter.hospitalId)
                    );
                });
                if (filteredData.length > 0) {
                    return filteredData[0].id;
                } else if (filteredData.length === 0) {
                    const result =
                        await ClientAccountService.postApiClientAccountUpsert({
                            requestBody: {
                                product_id: filter.productId,
                                client_id: filter.hospitalId
                            }
                        });
                    return result.id;
                }
            } catch (err) {
                console.log(err);
            }
        }, [filter]);

        const handleSave = useCallback(async () => {
            const clientAccountId = await getClientAccountId();
            const id = await getId();
            try {
                await ClientAccountService.postApiClientAccountProductKpiUpsert(
                    {
                        requestBody: {
                            id: id,
                            client_account_id: clientAccountId,
                            kpi_map: JSON.stringify({
                                json: matrix,
                                columnWidths: columnWidths,
                                hasHeaderRow: hasHeaderRow,
                                hasHeaderColumn: hasHeaderColumn
                            })
                        }
                    }
                );
                toggleIsEditing();
            } catch (err) {
                errorHandler(err);
            }
        }, [
            getId,
            getClientAccountId,
            toggleIsEditing,
            matrix,
            columnWidths,
            hasHeaderRow,
            hasHeaderColumn
        ]);

        const handleCancel = useCallback(() => {
            getInformation();
            toggleIsEditing();
        }, [getInformation, toggleIsEditing]);

        return (
            <div
                className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    lastUpdated={data.lastUpdated}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    isEditing={isEditing}
                    onOpenEditMode={toggleIsEditing}
                    onSaveEditMode={handleSave}
                    onCancelEditMode={handleCancel}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`${styles['end-consumer-information-container']}`}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['end-consumer-information-container__description']}`}
                                    >
                                        {t(
                                            `components:${data.attr}.description`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{ minHeight: 0 }}
                            >
                                <div className="col px-0 h-100">
                                    <CustomEntryTable
                                        isEditing={isEditing}
                                        matrix={matrix}
                                        onSetMatrix={setMatrix}
                                        columnWidths={columnWidths}
                                        onSetColumnWidths={setColumnWidths}
                                        hasHeaderColumn={hasHeaderColumn}
                                        onSetHasHeaderColumn={
                                            setHasHeaderColumn
                                        }
                                        hasHeaderRow={hasHeaderRow}
                                        onSetHasHeaderRow={setHasHeaderRow}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

EndConsumerInformation.propTypes = {};
