import React, { useCallback, useEffect, useRef, useState } from 'react'
//import { ReactComponent as IcoError } from './assets/images/ico-error.svg'
import { ReactComponent as IcoValid } from './assets/images/ico-valid.svg'
import PropTypes from 'prop-types'

import styles from './select-input.module.scss'
import { ReactComponent as ArrowDown } from './assets/images/dropdown-arrow.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useDropdownKeyboardNavigation } from '../../../hooks/useDropdownKeyboardNavigation'
import FullTextPopup from '../../popups/FullTextPopup'
import { InputLabel } from '../input-label/input-label'
import { UserIcon } from '../../formatting/user-icon/user-icon'

export const SelectInput = React.memo(
    ({
        state,
        name,
        optionsIcon,
        options,
        optionsAux,
        selectedOption,
        selectedOptionAux,
        label,
        disabled,
        value,
        loading,
        error,
        onChangeInput,
        onToggleDropdown,
        reference,
        focused,
        openDropdown,
        inputType,
        iconList,
        selectedIcon,
        loadingText,
        hideOptionsAux,
        noOptionsText,
        hovered,
        onSelectInput,
        onSelectIconInput,
        hideLabel,
        newLabelType,
        isRequired,
    }) => {
        const { t } = useCustomTranslation()
        const [searchValue, setSearchValue] = useState('')
        const [selectInputTop, setSelectInputTop] = useState(0)
        const [selectInputLeft, setSelectInputLeft] = useState(0)
        const selectInputRef = useRef(null)

        const handleDropdownPosition = useCallback(() => {
            if (selectInputRef.current) {
                const rect = selectInputRef.current.getBoundingClientRect()
                setSelectInputTop(rect.bottom)
                setSelectInputLeft(rect.left)
            }
        }, [selectInputRef])

        useEffect(() => {
            if (openDropdown) {
                handleDropdownPosition()
            } else {
                setSelectInputLeft(undefined)
            }
        }, [openDropdown, handleDropdownPosition])

        const handleOptionClick = useCallback(
            (e, name, option, optionAux) => {
                setSearchValue(option)
                onToggleDropdown(false, name)
                onSelectInput(option, optionAux, name)
                e.preventDefault()
                e.stopPropagation()
            },
            [onSelectInput, onToggleDropdown]
        )

        // Update this handler to set searchValue and reset selected options
        const handleInputChange = useCallback(
            (e) => {
                setSearchValue(e.target.value)
                onChangeInput(e, name)
                onSelectInput('', '', name) // Reset the selected options
            },
            [onChangeInput, onSelectInput, name]
        )

        const handleOptionIconClick = useCallback(
            (e, name, iconName) => {
                onToggleDropdown(false, name)
                onSelectIconInput(iconName, name)
            },
            [onSelectIconInput, onToggleDropdown]
        )

        const handleOutsideClick = useCallback(
            (event) => {
                if (reference.current && !reference.current.contains(event.target)) {
                    onToggleDropdown(false, name)
                }
            },
            [reference, onToggleDropdown, name]
        )

        useEffect(() => {
            if (openDropdown) {
                document.addEventListener('mousedown', handleOutsideClick)
            } else {
                document.removeEventListener('mousedown', handleOutsideClick)
            }
            return function cleanup() {
                document.removeEventListener('mousedown', handleOutsideClick)
            }
        }, [handleOutsideClick, openDropdown])

        const hoveredStyle = hovered ? 'hovered' : ''
        let containerStyle
        if (disabled) {
            containerStyle = `${styles['select-input-container']} ${styles[`${inputType}`]} ${styles['disabled']}`
        } else if (openDropdown) {
            containerStyle = `${styles['select-input-container']} ${styles[`${inputType}`]} ${styles['selected']} ${styles[hoveredStyle]}`
        } else if (selectedOption !== undefined || selectedOptionAux !== undefined || value !== undefined || searchValue !== '') {
            containerStyle = `${styles['select-input-container']} ${styles[`${inputType}`]} ${styles['valued']} ${styles[hoveredStyle]}`
        } else {
            containerStyle = `${styles['select-input-container']} ${styles[`${inputType}`]} ${styles[hoveredStyle]}`
        }
        let newOptions = [...options]
        let newOptionsAux = []
        let newOptionsIcon = []
        if (optionsAux) newOptionsAux = [...optionsAux]
        if (optionsIcon) newOptionsIcon = [...optionsIcon]
        if (searchValue && searchValue !== selectedOption) {
            const valueReplaced = searchValue.replace(new RegExp('\\\\', 'g'), '\\\\')
            const regex = new RegExp(`${valueReplaced}`, 'i')
            newOptions = options.filter((p, index) => {
                if (p !== '') {
                    const result = p.toString().match(regex)
                    if (result) {
                        return result
                    } else if (newOptionsAux.length >= index + 1 && newOptionsAux[index] !== '') {
                        return newOptionsAux[index].toString().match(regex)
                    }
                } else if (newOptionsAux.length >= index + 1 && newOptionsAux[index] !== '') {
                    return newOptionsAux[index].toString().match(regex)
                }
            })
            newOptionsAux = newOptionsAux.filter((p, index) => {
                if (options.length >= index + 1 && options[index] !== '') {
                    const result = options[index].toString().match(regex)
                    if (result) {
                        return result
                    } else if (p !== '') {
                        return p.toString().match(regex)
                    }
                } else if (p !== '') {
                    return p.toString().match(regex)
                }
            })
            newOptionsIcon = newOptionsIcon.filter((p, index) => {
                if (options.length >= index + 1 && options[index] !== '') {
                    const result = options[index].toString().match(regex)
                    if (result) {
                        return result
                    } else if (p !== '') {
                        return p.toString().match(regex)
                    }
                } else if (p !== '') {
                    return p.toString().match(regex)
                }
            })
        }

        const dropdownRef = useRef(null)

        const { focusedIndex, optionRefs } = useDropdownKeyboardNavigation(
            newOptions,
            openDropdown,
            (index) => {
                const option = newOptions[index]
                const optionAux = newOptionsAux ? newOptionsAux[index] : undefined
                handleOptionClick(new Event('click'), name, option, optionAux)
            },
            dropdownRef
        )

        return (
            <React.Fragment>
                <div
                    className={containerStyle}
                    ref={reference}
                    onMouseDown={
                        !disabled
                            ? (e) => {
                                  onToggleDropdown(true, name)
                              }
                            : undefined
                    }
                >
                    {!hideLabel && (
                        <InputLabel
                            text={label}
                            name={name}
                            isError={state === 'error' || error}
                            isValued={value || inputType === 'icon'}
                            isSelected={openDropdown}
                            isRequired={isRequired}
                            type={newLabelType ? 'top' : undefined}
                        />
                    )}
                    <div className={`${styles['select-input-container__input-container']}`} ref={selectInputRef}>
                        {inputType === 'icon' && (
                            <div className={`${styles['select-input-container__input-container__icon-wrapper']}`}>
                                {selectedIcon && (
                                    <img
                                        loading="lazy"
                                        src={selectedIcon}
                                        alt=""
                                        className={`${styles['select-input-container__input-container__icon-wrapper__icon']}`}
                                    />
                                )}
                                {!selectedIcon && (
                                    <span className={`${styles['select-input-container__input-container__icon-wrapper__icon-placeholder']}`} />
                                )}
                            </div>
                        )}
                        {inputType !== 'icon' && state !== 'error' && (
                            <div
                                className={
                                    selectedOption !== '' || selectedOptionAux !== ''
                                        ? `${styles['select-input-container__value']}`
                                        : `${styles['select-input-container__value']} ${styles['placeholder']}`
                                }
                            >
                                {newOptionsIcon && newOptionsIcon.length > 0 && (selectedOption !== '' || selectedOptionAux !== '') && (
                                    <div className={'position-absolute'}>
                                        <UserIcon />
                                    </div>
                                )}
                                <input
                                    autoComplete="off"
                                    name={name}
                                    value={value || searchValue}
                                    onChange={handleInputChange}
                                    placeholder=""
                                    type="text"
                                    style={{
                                        paddingLeft:
                                            newOptionsIcon && newOptionsIcon.length > 0 && (selectedOption !== '' || selectedOptionAux !== '')
                                                ? '4rem'
                                                : '10px',
                                    }}
                                    className={
                                        value
                                            ? `${styles['select-input-container__input-container__input']}`
                                            : `${styles['select-input-container__input-container__input']} ${styles['placeholder']}`
                                    }
                                />
                            </div>
                        )}
                        {inputType !== 'icon' && state === 'error' && (
                            <div
                                className={
                                    selectedOption !== '' || selectedOptionAux !== ''
                                        ? `${styles['select-input-container__value']}`
                                        : `${styles['select-input-container__value']} ${styles['placeholder']}`
                                }
                            >
                                <input
                                    autoComplete="off"
                                    name={name}
                                    value={value}
                                    placeholder=""
                                    type="text"
                                    className={
                                        value
                                            ? `${styles['select-input-container__input-container__input']} ${styles['select-input-container__input-container__input--error']}`
                                            : `${styles['select-input-container__input-container__input']} ${styles['select-input-container__input-container__input--error']} ${styles['placeholder']}`
                                    }
                                    onChange={(e) => onChangeInput(e, name)}
                                />
                            </div>
                        )}
                        <div
                            className={`${styles['select-input-container__input-container__arrow-container']}`}
                            onMouseDown={onToggleDropdown ? () => onToggleDropdown(undefined, name) : undefined}
                        >
                            <ArrowDown
                                htmlFor={name}
                                className={
                                    state === 'error'
                                        ? `${styles['select-input-container__input-container__arrow-container__arrow']} ${styles['select-input-container__input-container__arrow-container__arrow--error']}`
                                        : `${styles['select-input-container__input-container__arrow-container__arrow']}`
                                }
                            />
                        </div>
                    </div>
                    {openDropdown && selectInputLeft && (
                        <div
                            ref={dropdownRef}
                            className={`row mx-0 ${styles['select-input-container__dropdown']}`}
                            style={{
                                marginTop: '1rem',
                                top: selectInputTop,
                                left: selectInputLeft,
                                width: selectInputRef.current ? `calc(${selectInputRef.current.offsetWidth}px + 2px)` : 'auto',
                            }}
                            id={`${name}-dropdown`}
                        >
                            <div className={`col p-0 mx-0 ${styles['select-input-container__dropdown__element']}`}>
                                {inputType !== 'icon' && (
                                    <React.Fragment>
                                        {newOptions.length === 0 && !loading && (
                                            <div
                                                className={`row mx-0`}
                                                style={{
                                                    height: '5rem',
                                                    padding: '2rem',
                                                }}
                                            >
                                                <div className={`col px-0 d-flex justify-content-center align-items-center`}>
                                                    <span
                                                        style={{
                                                            fontFamily: 'OpenSansRegular',
                                                            fontSize: '1.4rem',
                                                            color: '#9b9b9b',
                                                        }}
                                                    >
                                                        {t(`inputs:noOptions`)}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {newOptions.length === 0 && loading && (
                                            <div className={`row m-0 ${styles['select-input-container__dropdown__container-loader']}`}>
                                                <div className={`col p-0 ${styles['select-input-container__dropdown__loader-value']}`}>
                                                    {loadingText}
                                                </div>
                                            </div>
                                        )}
                                        {newOptions.length === 0 && !loading && (
                                            <div className={`row m-0 ${styles['select-input-container__dropdown__container-loader']}`}>
                                                <div className={`col p-0 ${styles['select-input-container__dropdown__loader-value']}`}>
                                                    {noOptionsText}
                                                </div>
                                            </div>
                                        )}
                                        {newOptions.length !== 0 &&
                                            !loading &&
                                            newOptions.map((option, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        ref={(el) => (optionRefs.current[index] = el)}
                                                        className={
                                                            index === focusedIndex
                                                                ? `row ${styles['select-input-container__dropdown__container']} ${styles['select-input-container__dropdown__container--focused']}`
                                                                : `row ${styles['select-input-container__dropdown__container']}`
                                                        }
                                                        onMouseDown={(e) => handleOptionClick(e, name, option, newOptionsAux[index])}
                                                    >
                                                        <div className={`w-100 p-0 ${styles['select-input-container__dropdown__value']}`}>
                                                            <FullTextPopup text={newOptions[index] ? newOptions[index] : '-'}>
                                                                {newOptionsIcon && newOptionsIcon.length > 0 && (
                                                                    <div className={'d-inline-block'}>
                                                                        <UserIcon />
                                                                    </div>
                                                                )}
                                                                <span className={`${styles['select-input-container__dropdown__value__main']}`}>
                                                                    {newOptions[index] ? newOptions[index] : '-'}
                                                                </span>
                                                            </FullTextPopup>
                                                            {!hideOptionsAux && newOptionsAux[index] !== '' && (
                                                                <span className={`${styles['select-input-container__dropdown__value__secondary']}`}>
                                                                    {newOptionsAux[index]}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </React.Fragment>
                                )}
                                {inputType === 'icon' && (
                                    <React.Fragment>
                                        {iconList.length === 0 && loading && (
                                            <div className={`row m-0 ${styles['select-input-container__dropdown__container-loader']}`}>
                                                <div className={`col p-0 ${styles['select-input-container__dropdown__loader-value']}`}>
                                                    {loadingText}
                                                </div>
                                            </div>
                                        )}
                                        {iconList.length === 0 && !loading && (
                                            <div className={`row m-0 ${styles['select-input-container__dropdown__container-loader']}`}>
                                                <div className={`col p-0 ${styles['select-input-container__dropdown__loader-value']}`}>
                                                    {noOptionsText}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mx-0 w-100" style={{ minHeight: '15rem' }}>
                                            {iconList.length !== 0 &&
                                                !loading &&
                                                iconList.map((option, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`col-6 rounded d-flex justify-content-center align-items-center p-2`}
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onMouseDown={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                handleOptionIconClick(e, name, option.name)
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src={option.icon}
                                                                className={styles['select-input-container__dropdown__value-icon']}
                                                                alt=""
                                                            />
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}
                    {state === 'valid' && <IcoValid className={styles['select-input-container__valid-icon']} />}
                    {/* {state === 'error' && (
            <IcoError className='select-input-container__error-icon' />
          )} */}
                </div>
                {error && <div className={styles['select-input-error']}>{error}</div>}
            </React.Fragment>
        )
    }
)

SelectInput.propTypes = {}
