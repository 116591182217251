import React from 'react';
import styles from './SingleSignOn.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { TypeInput } from '../../../../../components';
import { useTextInput } from '../../../../../hooks/useTextInput';

const renderInput = (label, description, value, setValue) => {
    const { inputType } = value;
    return (
        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
            <div className="col px-0">
                <div
                    className="row mx-0 w-100"
                    style={{ marginBottom: '1rem' }}
                >
                    <div className="col px-0">
                        <span
                            className={`${styles['single-sign-on-container__label']}`}
                        >
                            {label}
                        </span>
                    </div>
                </div>
                {description && (
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col px-0">
                            <span
                                className={`${styles['single-sign-on-container__description']}`}
                            >
                                {description}
                            </span>
                        </div>
                    </div>
                )}
                <div
                    className="row mx-0 w-100"
                    style={inputType === 'container' ? { height: '10rem' } : {}}
                >
                    <div className="col-6 px-0">
                        <TypeInput
                            hideLabel
                            onChangeInput={setValue}
                            inputType={inputType}
                            {...value}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SingleSignOn = () => {
    const { t } = useCustomTranslation();

    const {
        value: ssoUrl,
        setValue: ssoUrlSetValue,
        replaceValue: ssoUrlReplaceValue
    } = useTextInput({
        name: 'ssoUrl',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });
    const {
        value: entityId,
        setValue: entityIdSetValue,
        replaceValue: entityIdReplaceValue
    } = useTextInput({
        name: 'entityId',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: idpUrl,
        setValue: idpUrlSetValue,
        replaceValue: idpUrlReplaceValue
    } = useTextInput({
        name: 'idpUrl',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const {
        value: publicKey,
        setValue: publicKeySetValue,
        replaceValue: publicKeyReplaceValue
    } = useTextInput({
        name: 'publicKey',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
        inputType: 'container'
    });

    return (
        <div className={styles['single-sign-on-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['single-sign-on-container__title']}`}
                    >
                        {t(
                            'pages:authenticationAndSecurity.singleSignOn.title'
                        )}
                    </span>
                </div>
            </div>

            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['single-sign-on-container__description']}`}
                    >
                        {t(
                            'pages:authenticationAndSecurity.singleSignOn.description'
                        )}
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div
                        className={`${styles['single-sign-on-container__content']}`}
                    >
                        {renderInput(
                            'SSO URL',
                            undefined,
                            ssoUrl,
                            ssoUrlSetValue
                        )}
                        {renderInput(
                            'Entity ID',
                            undefined,
                            entityId,
                            entityIdSetValue
                        )}
                        {renderInput(
                            'IDP URL',
                            'Copy and paste the URL provided by your identity provider into this field. ',
                            idpUrl,
                            idpUrlSetValue
                        )}
                        {renderInput(
                            'Public Key',
                            'Copy and paste your key  here.',
                            publicKey,
                            publicKeySetValue
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
