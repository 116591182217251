import { useCallback, useReducer } from 'react';

const ACTIONS = {
  ADD_OPTION: 'add',
  ADD_SELECTED: 'addSelected',
  TOGGLE_DROPDOWN: 'dropdown',
  SELECT_OPTION: 'select',
  KEY_PRESS: 'keyPress',
  KEY_DOWN: 'keyDown',
  CHANGE_ERROR: 'error',
  SET_VALUE: 'value',
  RESET: 'reset',
  REPLACE_ALL: 'replace_all',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.REPLACE_ALL:
      return {
        ...action.payload,
      };
    case ACTIONS.CHANGE_ERROR:
      if (action.payload) {
        return {
          ...state,
          state: 'error',
          error: action.payload,
        };
      } else {
        return {
          ...state,
          state: 'normal',
          error: '',
        };
      }
    case ACTIONS.ADD_OPTION:
      return {
        ...state,
        options: [...state.options, action.payload.option],
        optionsAux: [...state.optionsAux, action.payload.optionAux],
      };
    case ACTIONS.ADD_SELECTED:
      return {
        ...state,
        selectedOption: action.payload.option,
        selectedOptionAux: action.payload.optionAux,
        value: action.payload.option,
      };
    case ACTIONS.TOGGLE_DROPDOWN:
      return {
        ...state,
        openDropdown: action.payload,
      };
    case ACTIONS.SELECT_OPTION:
      return {
        ...state,
        selectedOption: action.payload.option,
        selectedOptionAux: action.payload.optionAux,
        value: action.payload.option,
      };
    case ACTIONS.KEY_PRESS:{

      let selectedOption = '',
        selectedOptionAux = '';
      if (action.payload === 13) {
        action.payload.preventDefault();
        if (state.value && state.focused >= 0) {
          let valueReplaced = state.value.replace(
            new RegExp('\\\\', 'g'),
            '\\\\'
          );
          const regex = new RegExp(`${valueReplaced}`, 'i');
          let newOptions = state.options.filter((p, index) => {
            return (
              p.toString().match(regex) ||
              state.optionsAux[index].toString().match(regex)
            );
          });
          let newOptionsAux = state.optionsAux.filter((p, index) => {
            return (
              state.options[index].toString().match(regex) ||
              p.toString().match(regex)
            );
          });
          selectedOption = newOptions[state.focused];
          selectedOptionAux = newOptionsAux[state.focused];
        } else if (state.focused >= 0) {
          selectedOption = state.options[state.focused];
          selectedOptionAux = state.optionsAux[state.focused];
        }
        const scrollDiv = document.getElementById(
          `${state.name}-dropdown`
        );
        scrollDiv.scrollTop = 0;
        return {
          ...state,
          focused: -1,
          value: '',
          selectedOption,
          selectedOptionAux,
        };
      } else if (action.payload === 9) {
        return {
          ...state,
          focused: -1,
          selectedOptionAux,
        };
      }
    }
    break
    case ACTIONS.KEY_DOWN:
      {

        if (action.payload.which === 38) {
          if (state.focused > 0) {
            const scrollDiv = document.getElementById(
              `${state.name}-dropdown`
            );
            const topElement = document.querySelector(
              `#${state.name}-dropdown .select-input-container__dropdown__container--focused`
            );
            scrollDiv.scrollTop =
              topElement.offsetTop - topElement.offsetHeight;
            return { ...state, focused: -1 };
          }
        } else if (action.payload.which === 40) {
          if (state.value && state.focused !== -1) {
            let valueReplaced = state.value.replace(
              new RegExp('\\\\', 'g'),
              '\\\\'
            );
            const regex = new RegExp(`${valueReplaced}`, 'i');
            let newOptions = state.options.filter((p) => {
              return p.toString().match(regex);
            });
            if (state.focused < newOptions.length - 1) {
              const scrollDiv = document.getElementById(
                `${state.name}-dropdown`
              );
              const topElement = document.querySelector(
                `#${state.name}-dropdown .select-input-container__dropdown__container--focused`
              );
              scrollDiv.scrollTop =
                topElement.offsetTop + topElement.offsetHeight;
              return {
                ...state,
                focused: state.focused + 1,
              };
            }
          } else if (
            state.focused !== -1 &&
            state.focused <= state.options.length - 1
          ) {
            const scrollDiv = document.getElementById(
              `${state.name}-dropdown`
            );
            const topElement = document.querySelector(
              `#${state.name}-dropdown .select-input-container__dropdown__container--focused`
            );
            scrollDiv.scrollTop =
              topElement.offsetTop + topElement.offsetHeight;
            return {
              ...state,
              focused: state.focused + 1,
            };
          } else {
            return {
              ...state,
              focused: 0,
            };
          }
        } else if (action.payload.which === 9) {
          return {
            ...state,
            openDropdown: false,
          };
        } else {
          return {
            ...state,
          };
        }
      }
      break
    case ACTIONS.SET_VALUE:
      return {
        ...state,
        value: action.payload,
        selectedOption: '',
        selectedOptionAux: '',
      };
    case ACTIONS.RESET:
      return {
        ...action.payload,
      };
  }
}

export function usePaginationInput(defaultValue) {
  const [value, dispatch] = useReducer(reducer, defaultValue);

  const addOption = useCallback((value, valueAux) => {
    dispatch({
      type: 'add',
      payload: { option: value, optionAux: valueAux },
    });
  }, []);

  const addSelectedOption = useCallback((value, valueAux) => {
    dispatch({
      type: 'addSelected',
      payload: { option: value, optionAux: valueAux },
    });
  }, []);

  const selectOption = useCallback((value, valueAux) => {
    dispatch({
      type: 'select',
      payload: { option: value, optionAux: valueAux },
    });
  }, []);

  function keyPress(e) {
    dispatch({
      type: 'keyPress',
      payload: e,
    });
  }

  function keyDown(e) {
    dispatch({
      type: 'keyDown',
      payload: e,
    });
  }

  const toggleDropdown = useCallback(
    (v) => {
      dispatch({
        type: 'dropdown',
        payload: typeof v === 'boolean' ? v : !value.openDropdown,
      });
    },
    [value]
  );

  function setValue(e) {
    dispatch({ type: 'value', payload: e.target.value });
  }

  const reset = useCallback(() => {
    dispatch({ type: 'reset', payload: defaultValue });
  }, [defaultValue]);

  function setError(value) {
    dispatch({ type: 'error', payload: value });
  }

  const replaceAll = useCallback((value) => {
    dispatch({ type: 'replace_all', payload: value });
  }, []);

  return {
    value,
    addOption,
    toggleDropdown,
    selectOption,
    keyPress,
    keyDown,
    setValue,
    reset,
    setError,
    replaceAll,
    addSelectedOption,
  };
}
