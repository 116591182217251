import React from 'react'
import styles from './table-counter.module.scss'

export const TableCounter = React.memo(({ count }) => {
    return (
        <div className={`${styles['table-counter-container']}`}>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <span className={`${styles['table-counter-container__text']}`}>
                    {count}
                </span>
            </div>
        </div>
    )
})
