import { t } from 'i18next';

import { getRjwt, loginWithJwt, unauthorizedAccess } from './authService';
import { AuthService } from '../temp/test';
import { error } from '../components/notifications/notification/notification';

export const errorHandler = async (err) => {
    if (err.body && err.body.status_code === 401) {
        try {
            const data = await AuthService.postApiAuthLoginRefresh({
                requestBody: { refresh_token: getRjwt() }
            });
            loginWithJwt(data.token, data.refresh_token);
            window.location.reload();
        } catch (err) {
            error({
                statusText: undefined,
                text: t('errors:general.unauthorized'),
                date: undefined
            });
            unauthorizedAccess();
        }
    } else if (err.body && err.body.status_code === 404) {
        error({
            statusText: undefined,
            text: t('errors:general.notFound'),
            date: undefined
        });
    } else if (err.body && err.body.status_code === 400) {
        error({
            statusText: undefined,
            text: t(`errors:general.${err.body.error_code}`),
            date: undefined
        });
    } else if (err.body && err.body.status_code === 500) {
        error({
            statusText: undefined,
            text: t('errors:general.unexpected'),
            date: undefined
        });
    }
};
