import styles from './report-management.module.scss'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import RGL, { WidthProvider } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
//import { ReportsList } from '../../../reusable/smart/general/entity-centric/general/listing/reports-list/reports-list';
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useToggle } from '../../../hooks/useToggle'
import { useSelectInput } from '../../../hooks/useSelectInput'
import { useTextInput } from '../../../hooks/useTextInput'
import { errorHandler } from '../../../utils/api'
import Joi from 'joi'
import createDashboardLayout from '../../../mock/dashboards/create-dashboard-layout.json'
import { ReportService, ProfileService, TeamsService } from '../../../temp/test'
import { translateToCronString } from '../../../utils/date'
import { useFilter } from '../../../providers/filter-context'
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput'
import { useAuxBar } from '../../../providers/aux-bar-context'
import CustomDashboard from '../../general/custom-dashboard/custom-dashboard'
import componentConfig from '../../../configs/componentLibraryConf.json'

import { maxComponentCount } from '../../../constants/component'
import {
    CardCarousel,
    DashboardPreviewCard,
    EditOptions,
    InfoButton,
    LibraryComponentFactory,
    LoaderForeground,
    Modal,
    MyResizeHandle,
    PrimaryButton,
    ReportGeneralDetails,
    ReportLayout,
    ReportTeamList,
    ReportTemplate,
    ReportUserList,
    ReportsList,
    ReturnButton,
    SecondaryButton,
    SelectInput,
    SelectMultipleInput,
    Spinner,
    TypeInput,
} from '../../../components'
import { ExampleBackground } from '../../../layout/example-background/example-background'
import { error } from '../../../components/notifications/notification/notification'
import Auxbar from '../../../layout/auxbar/auxbar'
import { useHighlightedChart } from '../../../providers/highlighted-chart-context'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }

const ResponsiveGridLayout = WidthProvider(RGL)

const BackOfficeReportManagement = React.memo(
    ({
        notAvailableCharts,
        availableCharts,
        setAvailableCharts,
        setNotAvailableCharts,
        setNotAvailableChartValue,
        setNotAvailableChartSize,
        resizableGrid,
        onPageLayoutChange,
        onPageDropChart,
        onDeletePageChart,
        onOpenChartConfig,
        setFilterInputs,
        onSetLayout,
        onFillChartLayout,
    }) => {
        //const ref = useRef(null);
        const gridRef = useRef(null)
        const { highlightedChart, setHighlightedChart } = useHighlightedChart()
        const [transformScaleCustom, setTransformScaleCustom] = useState(1)
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()
        const { filter } = useFilter()
        const { setAvailableOptions, setIsBuilder } = useAuxBar()
        const [reportPageStep, setReportPageStep] = useState('initial')
        const [lastStep, setLastStep] = useState('')
        const [modal, toggleModal] = useToggle(false)
        const [modalType, setModalType] = useState('')
        const [templateSelected, setTemplateSelected] = useState('initial')
        const [loading, toggleLoading] = useToggle(true)
        const [selectedReport, setSelectedReport] = useState('')

        const {
            value: title,
            setValue: setTitleValue,
            setError: setTitleError,
            reset: titleReset,
        } = useTextInput({
            name: 'title',
            value: '',
            error: '',
            hidden: false,
            state: 'normal',
        })

        const {
            value: cronString,
            toggleDropdown: cronStringToggleDropdown,
            selectOption: cronStringSelectOption,
            keyPress: cronStringKeyPress,
            keyDown: cronStringKeyDown,
            setValue: cronStringSetValue,
            setError: cronStringSetError,
            replaceAll: cronStringReplaceAll,
        } = useSelectInput({
            name: 'cronString',
            showOptions: true,
            options: [t('settings:daily'), t('settings:weekly'), t('settings:biWeekly'), t('settings:monthly')],
            optionsAux: ['daily', 'weekly', 'biWeekly', 'monthly'],
            selectedOption: t('settings:weekly'),
            selectedOptionAux: 'weekly',
            state: 'normal',
            value: t('settings:weekly'),
            focused: -1,
            reference: useRef(),
        })

        const {
            value: teamIds,
            toggleDropdown: teamIdsToggleDropdown,
            selectOption: teamIdsSelectOption,
            unselectOption: teamIdsUnselectOption,
            keyPress: teamIdsKeyPress,
            keyDown: teamIdsKeyDown,
            setValue: teamIdsSetValue,
            setError: teamIdsSetError,
            replaceAll: teamIdsReplaceAll,
        } = useSelectMultipleInput({
            name: 'teamIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef(),
        })

        const {
            value: userIds,
            toggleDropdown: userIdsToggleDropdown,
            selectOption: userIdsSelectOption,
            unselectOption: userIdsUnselectOption,
            keyPress: userIdsKeyPress,
            keyDown: userIdsKeyDown,
            setValue: userIdsSetValue,
            setError: userIdsSetError,
            replaceAll: userIdsReplaceAll,
        } = useSelectMultipleInput({
            name: 'userIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef(),
        })

        const schema = Joi.object({
            title: Joi.string().required(),
            cronString: Joi.string().required(),
            teamIds: Joi.array().items(Joi.string()),
            userIds: Joi.array().items(Joi.string()),
        })

        useEffect(() => {
            if (isMounted.current) toggleLoading(false)
        }, [isMounted, toggleLoading])

        const handleRowClick = (report) => {
            setSelectedReport(report.id)
        }

        const [currentWidthSectionMultiplier, setCurrentWidthSectionMultiplier] = useState(1)

        useEffect(() => {
            if (reportPageStep !== 'edit') {
                setHighlightedChart('')
            }
        }, [reportPageStep, setHighlightedChart])

        const newSize = useCallback((width) => {
            if (width < 600) {
                setCurrentWidthSectionMultiplier(0.6)
            } else if (width < 800) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1200) {
                setCurrentWidthSectionMultiplier(0.7)
            } else if (width < 1400) {
                setCurrentWidthSectionMultiplier(0.8)
            } else {
                setCurrentWidthSectionMultiplier(1)
            }
            return
        }, [])

        useEffect(() => {
            // Create a ResizeObserver instance
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    // Access the new width and height
                    const { width } = entry.contentRect
                    newSize(width)
                }
            })

            resizeObserver.observe(document.documentElement)
            return () => {
                resizeObserver.disconnect()
            }
        }, [newSize])

        const handleReportClick = async (reportId) => {
            const reportDataBack = await ReportService.getApiBackofficeReporting1({
                reportId: reportId,
            })
            setSelectedReport(reportId)
            setNotAvailableCharts(JSON.parse(reportDataBack.template).charts)
            handleNavigate('front', 'edit')
        }

        const handleNewReport = async () => {
            try {
                toggleModal(true)
                setModalType('createReport')
                titleReset()
                cronStringReplaceAll({
                    name: 'cronString',
                    showOptions: true,
                    options: [t('settings:never'), t('settings:daily'), t('settings:weekly'), t('settings:biWeekly'), t('settings:monthly')],
                    optionsAux: ['never', 'daily', 'weekly', 'biWeekly', 'monthly'],
                    selectedOption: t('settings:never'),
                    selectedOptionAux: 'never',
                    state: 'normal',
                    value: t('settings:never'),
                    focused: -1,
                    reference: React.createRef(),
                })
                const newUserIds = {
                    name: 'userIds',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    state: 'normal',
                    value: '',
                    focused: -1,
                    reference: React.createRef(),
                }
                const newTeamIds = {
                    name: 'teamIds',
                    showOptions: true,
                    options: [],
                    optionsAux: [],
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    state: 'normal',
                    value: '',
                    focused: -1,
                    reference: React.createRef(),
                }
                const userBackData = await ProfileService.getApiProfileListAccounts()
                userBackData.map((el) => {
                    newUserIds.options.push(el.username)
                    newUserIds.optionsAux.push(el.uuid)
                    return el
                })
                const teamBackData = await TeamsService.getApiTeams({
                    params: {},
                    leaderEmpNo: undefined,
                })
                teamBackData.map((el) => {
                    newTeamIds.options.push(el.name)
                    newTeamIds.optionsAux.push(el.id)
                    return el
                })
                userIdsReplaceAll(newUserIds)
                teamIdsReplaceAll(newTeamIds)
            } catch (err) {
                errorHandler(err)
            }
        }

        const handleStartReport = () => {
            try {
                const result = schema.validate(
                    {
                        title: title.value,
                        cronString: cronString.selectedOption,
                        teamIds: teamIds.selectedOptions,
                        userIds: userIds.selectedOptions,
                    },
                    { abortEarly: false }
                )
                if (isMounted.current) {
                    setTitleError()
                    cronStringSetError()
                    teamIdsSetError()
                    userIdsSetError()
                }
                if (result.error) {
                    result.error.details.map((el) => {
                        if (el.path[0] === 'title') {
                            if (isMounted.current) setTitleError(el.type)
                        } else if (el.path[0] === 'cronString') {
                            if (isMounted.current) cronStringSetError(el.type)
                        } else if (el.path[0] === 'teamIds') {
                            if (isMounted.current) teamIdsSetError(el.type)
                        } else if (el.path[0] === 'userIds') {
                            if (isMounted.current) userIdsSetError(el.type)
                        }
                    })
                    return
                }
                setLastStep(reportPageStep)
                setReportPageStep('templates')
                handleCloseModal()
            } catch (err) {
                errorHandler(err)
            }
        }

        const handleCreateReport = async () => {
            try {
                const template = {
                    filters: filter,
                    charts: notAvailableCharts,
                }
                const cronValue = translateToCronString(cronString.selectedOptionAux)
                console.log('fix: created_by should be readonly')
                const reportSchema = {
                    email_after_generate: true,
                    report_type: templateSelected,
                    schedule_cron_string: cronValue,
                    tag: title.value,
                    template: JSON.stringify(template),
                }
                const result = await ReportService.postApiBackofficeReporting({
                    requestBody: reportSchema,
                })
                // if (cronValue) {
                // 	await reportApi.apiBackofficeReportingReportIdActivateSchedulingGet(
                // 		result.id
                // 	);
                // }
                await Promise.all(
                    userIds.selectedOptionsAux.map(async (el) => {
                        await ReportService.postApiBackofficeReportingUsers({
                            reportId: result.id,
                            requestBody: [el],
                        })
                        return el
                    })
                )
                await Promise.all(
                    teamIds.selectedOptionsAux.map(async (el) => {
                        await ReportService.postApiBackofficeReportingTeams({
                            reportId: result.id,
                            requestBody: [el],
                        })
                        return el
                    })
                )
                handleNavigate('back', 'initial')
            } catch (err) {
                errorHandler(err)
            }
        }

        const handleEditReport = async () => {
            try {
                const reportDataBack = await ReportService.getApiBackofficeReporting1({
                    reportId: selectedReport,
                })
                const template = {
                    filters: filter,
                    charts: notAvailableCharts,
                }
                const reportSchema = {
                    tag: reportDataBack.tag,
                    schedule_cron_string: reportDataBack.schedule_cron_string ? reportDataBack.schedule_cron_string : '',
                    report_type: reportDataBack.report_type,
                    email_after_generate: reportDataBack.email_after_generate,
                    template: JSON.stringify(template),
                }
                await ReportService.putApiBackofficeReporting({
                    reportId: selectedReport,
                    requestBody: reportSchema,
                })
                handleNavigate('back', 'initial')
            } catch (err) {
                errorHandler(err)
            }
        }

        const handleCloseModal = () => {
            if (isMounted.current) {
                toggleModal(false)
                setModalType('')
                setTemplateSelected('')
            }
        }

        const handleChooseTemplate = (name) => {
            if (isMounted.current) {
                setAvailableCharts(Object.values(componentConfig))
                onFillChartLayout(createDashboardLayout[name]['notAvailableCharts'])
            }
            handleNavigate('front', 'components')
        }
        const handlePreviewTemplate = (name) => {
            if (isMounted.current) {
                setAvailableCharts(Object.values(componentConfig))
                onFillChartLayout(createDashboardLayout[name]['notAvailableCharts'])
            }
            handleNavigate('front', 'preview')
        }

        const handleNavigate = (type, value) => {
            if (type === 'front') {
                if (value === 'preview' || value === 'previewEdit') {
                    setAvailableOptions([])
                } else if (value === 'filterConfig' || value === 'editConfig') {
                    let newDropdownList = []
                    notAvailableCharts.map((el) => {
                        if (el.config.filter) {
                            el.config.filter.map((el2) => {
                                if (!newDropdownList.includes(el2)) {
                                    newDropdownList.push(el2)
                                }
                                return el2
                            })
                        }
                        return el
                    })
                    if (newDropdownList.length > 0) {
                        setFilterInputs(newDropdownList)
                        if (newDropdownList.length > 1 || (newDropdownList.length === 1 && !newDropdownList.includes('none'))) {
                            setAvailableOptions(['search'])
                        } else {
                            setAvailableOptions([])
                        }
                    }
                } else if (value === 'components' || value === 'edit') {
                    setFilterInputs([])
                    setAvailableOptions(['store'])
                    setAvailableCharts(Object.values(componentConfig))
                } else if (value === 'templates') {
                    setAvailableOptions([])
                }
            } else if (type === 'back') {
                if (value === 'components' || value === 'edit') {
                    setAvailableOptions(['store'])
                } else if (value === 'filterConfig' || value === 'editConfig') {
                    let newDropdownList = []
                    notAvailableCharts.map((el) => {
                        if (el.config.filter) {
                            el.config.filter.map((el2) => {
                                if (!newDropdownList.includes(el2)) {
                                    newDropdownList.push(el2)
                                }
                                return el2
                            })
                        }
                        return el
                    })
                    if (newDropdownList.length > 0) {
                        setFilterInputs(newDropdownList)
                        if (newDropdownList.length > 1 || (newDropdownList.length === 1 && !newDropdownList.includes('none'))) {
                            setAvailableOptions(['search'])
                        } else {
                            setAvailableOptions([])
                        }
                    }
                } else if (value === 'templates') {
                    setAvailableOptions([])
                } else if (value === 'initial') {
                    setSelectedReport('')
                    setAvailableOptions([])
                    titleReset()
                    cronStringReplaceAll({
                        name: 'cronString',
                        showOptions: true,
                        options: [t('settings:never'), t('settings:daily'), t('settings:weekly'), t('settings:biWeekly'), t('settings:monthly')],
                        optionsAux: ['never', 'daily', 'weekly', 'biWeekly', 'monthly'],
                        selectedOption: t('settings:never'),
                        selectedOptionAux: 'never',
                        state: 'normal',
                        value: t('settings:never'),
                        focused: -1,
                        reference: React.createRef(),
                    })
                }
            }
            setReportPageStep(value)
        }

        const mapTo01 = useCallback(
            (value, min, max) => {
                let multiplier =
                    resizableGrid && resizableGrid.droppingElement && resizableGrid.droppingElement.w
                        ? 0.2 * resizableGrid.droppingElement.w + 0.15
                        : 0.3
                return (multiplier * (value - min)) / (max - min)
            },
            [resizableGrid]
        )

        const handleCheckMousePositionInsideGrid = useCallback(
            (e) => {
                if (gridRef && gridRef.current) {
                    const gridRect = gridRef.current.getBoundingClientRect()
                    if (
                        e.clientX > gridRect.left &&
                        e.clientX < gridRect.right &&
                        e.clientY > gridRect.top &&
                        e.clientY < gridRect.bottom &&
                        mapTo01(e.clientX, gridRect.right, gridRect.left)
                    ) {
                        setTransformScaleCustom(1 + mapTo01(e.clientX, gridRect.right, gridRect.left))
                    }
                }
            },
            [mapTo01]
        )

        useEffect(() => {
            if (gridRef && gridRef.current) {
                let ref = gridRef.current
                ref.addEventListener('dragover', (e) => {
                    handleCheckMousePositionInsideGrid(e)
                })
                return () => {
                    ref.removeEventListener('dragover', (e) => {
                        handleCheckMousePositionInsideGrid(e)
                    })
                }
            }
        }, [handleCheckMousePositionInsideGrid])

        useEffect(() => {
            if (reportPageStep === 'components') {
                setIsBuilder(true)
            } else {
                setIsBuilder(false)
            }
        }, [reportPageStep, setIsBuilder])

        return (
            <>
                {modal && modalType === 'createReport' && (
                    <Modal onToggleModal={handleCloseModal}>
                        <div className="row mx-0 d-flex justify-content-start align-items-center " style={{ marginBottom: '2rem' }}>
                            <span className="modal-title">{t('modals:createReport')}</span>
                        </div>
                        <div className="w-100 h-100 d-flex-justify-content-center align-items-center create-dashboard-inputs">
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <TypeInput onChangeInput={setTitleValue} {...title} />
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <SelectInput
                                        onChangeInput={cronStringSetValue}
                                        onSelectInput={cronStringSelectOption}
                                        onSelectIconInput={cronStringSelectOption}
                                        onKeyPress={cronStringKeyPress}
                                        onKeyDown={cronStringKeyDown}
                                        onToggleDropdown={cronStringToggleDropdown}
                                        {...cronString}
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <SelectMultipleInput
                                        hideOptionsAux
                                        disabled={teamIds.disabled}
                                        onChangeInput={teamIdsSetValue}
                                        onSelectMultiple={teamIdsSelectOption}
                                        onSelectedOptionClick={teamIdsUnselectOption}
                                        onKeyPress={teamIdsKeyPress}
                                        onKeyDown={teamIdsKeyDown}
                                        onToggleDropdown={teamIdsToggleDropdown}
                                        {...teamIds}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-start align-items-start flex-grow-1 modal-row ">
                                <div className="col px-0 h-100 d-flex flex-column justify-content-start align-items-start">
                                    <SelectMultipleInput
                                        hideOptionsAux
                                        disabled={userIds.disabled}
                                        onChangeInput={userIdsSetValue}
                                        onSelectMultiple={userIdsSelectOption}
                                        onSelectedOptionClick={userIdsUnselectOption}
                                        onKeyPress={userIdsKeyPress}
                                        onKeyDown={userIdsKeyDown}
                                        onToggleDropdown={userIdsToggleDropdown}
                                        {...userIds}
                                    />
                                </div>
                            </div>

                            <hr
                                className="my-4"
                                style={{
                                    color: 'rgba(0,0,0,0.1)',
                                    opacity: '1',
                                }}
                            />
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <SecondaryButton text={t('buttons:cancel')} onClick={handleCloseModal} />
                                </div>
                                <div className="col-5 px-0">
                                    <PrimaryButton text={t('buttons:save')} onClick={handleStartReport} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                {loading && <LoaderForeground />}
                {!loading && (reportPageStep === 'initial' || reportPageStep === 'edit') && (
                    <div
                        className={`row mx-0 w-100 ${styles['back-office-report-management-container']}`}
                        style={{
                            height: !selectedReport ? '100%' : 'auto',
                        }}
                    >
                        <div className="col-12 px-0 h-100">
                            <div className="row w-100 dashboard-header-row d-flex justify-content-between align-items-center">
                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                    <span className="me-2">{t('pages:report-management.title')}</span>
                                    {selectedReport && (
                                        <SecondaryButton text={t('buttons:return')} onClick={() => handleNavigate('back', 'initial')} />
                                    )}
                                </div>
                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                    {!selectedReport && <PrimaryButton text={t('buttons:newReport')} onClick={handleNewReport} />}
                                    {selectedReport && reportPageStep === 'edit' && (
                                        <React.Fragment>
                                            {/* <div className='me-2'>
												<Button
													color={'blue-outline'}
													text={t(
														'buttons:return'
													)}
													textColor={'white'}
													onClick={() => handleNavigate('back', 'initial')}
												/>
											</div> */}
                                            <PrimaryButton
                                                disabled={notAvailableCharts.length === 0}
                                                text={t('buttons:filterConfig')}
                                                onClick={() => handleNavigate('front', 'editConfig')}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                            {selectedReport && reportPageStep === 'initial' && (
                                <React.Fragment>
                                    <div className={`row mx-0 w-100 ${styles['back-office-report-management-container__row-container']}`}>
                                        <div className="col px-0">
                                            <ReportGeneralDetails reportId={selectedReport} />
                                        </div>
                                    </div>
                                    <div
                                        className={`row mx-0 w-100 ${styles['back-office-report-management-container__table-container']}`}
                                        style={{
                                            height: '40rem',
                                        }}
                                    >
                                        <div className="col px-0 h-100">
                                            <ReportTeamList />
                                        </div>
                                    </div>
                                    <div
                                        className={`row mx-0 w-100 ${styles['back-office-report-management-container__table-container']}`}
                                        style={{
                                            height: '40rem',
                                        }}
                                    >
                                        <div className="col px-0">
                                            <ReportUserList reportId={selectedReport} />
                                        </div>
                                    </div>
                                    <div className={`row mx-0 w-100`}>
                                        <div className="col px-0">
                                            <ReportLayout reportId={selectedReport} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {selectedReport && reportPageStep === 'edit' && (
                                <div className={`row mx-0 w-100 ${styles['back-office-report-management-container__report-builder-container']}`}>
                                    <div className={`col ${styles['back-office-report-management-container__layout-container__options-container']}`}>
                                        <div className="row mx-0 w-100 position-relative">
                                            <div
                                                className={`col px-0 d-flex flex-column ${styles['back-office-report-management-container__dashboard-template-container__invisible']}`}
                                            >
                                                <div className="row mx-0 w-100 h-100">
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div
                                                className={`col px-0 d-flex flex-column ${styles['back-office-report-management-container__dashboard-template-container__background']}`}
                                            >
                                                <div className="row mx-0 w-100 h-100">
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']} d-flex justify-content-center align-items-center']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                    <div
                                                        className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div
                                                className="col px-0 d-flex flex-column"
                                                style={{
                                                    minHeight: 'inherit',
                                                }}
                                            >
                                                <ResponsiveGridLayout
                                                    cols={cols}
                                                    style={{
                                                        alignItems: 'start',
                                                    }}
                                                    className={resizableGrid.gridMounted ? 'layout w-100 mx-0 animated ' : 'layout w-100 mx-0 '}
                                                    rowHeight={currentWidthSectionMultiplier * 30}
                                                    maxRows={resizableGrid.maxRows}
                                                    compactType={resizableGrid.compactType}
                                                    draggableCancel={'.resize-handle'}
                                                    verticalCompact={resizableGrid.verticalCompact}
                                                    layout={resizableGrid.layout}
                                                    onLayoutChange={
                                                        !_.isEmpty(resizableGrid.droppingElement)
                                                            ? () => {}
                                                            : (layout) => onPageLayoutChange(undefined, layout)
                                                    }
                                                    isDraggable={resizableGrid.isEditable}
                                                    isResizable={resizableGrid.isEditable}
                                                    isDroppable={true}
                                                    onDrop={(layout, layoutItem, _event) => {
                                                        if (layoutItem) {
                                                            onPageDropChart(undefined, layout, layoutItem, _event)
                                                        }
                                                        _event.preventDefault()
                                                    }}
                                                    droppingItem={
                                                        !_.isEmpty(resizableGrid.droppingElement)
                                                            ? {
                                                                  i: resizableGrid.droppingElement.i,
                                                                  w: componentConfig[resizableGrid.droppingElement.name].dataGrid.w,
                                                                  h: componentConfig[resizableGrid.droppingElement.name].dataGrid.h,
                                                                  minW: componentConfig[resizableGrid.droppingElement.name].dataGrid.minW,
                                                                  maxW: componentConfig[resizableGrid.droppingElement.name].dataGrid.maxW,
                                                                  minH: componentConfig[resizableGrid.droppingElement.name].dataGrid.minH,
                                                                  maxH: componentConfig[resizableGrid.droppingElement.name].dataGrid.maxH,
                                                              }
                                                            : undefined
                                                    }
                                                    preventCollision={false}
                                                    draggableHandle={resizableGrid.draggableHandle}
                                                    margin={resizableGrid.margin}
                                                    containerPadding={resizableGrid.containerPadding}
                                                    resizeHandle={resizableGrid.isEditable ? <MyResizeHandle /> : ''}
                                                    autoSize={resizableGrid.autoSize}
                                                    isBounded={resizableGrid.isBounded}
                                                    useCSSTransforms={false}
                                                    measureBeforeMount={false}
                                                >
                                                    {notAvailableCharts.map((el) => {
                                                        return (
                                                            <div
                                                                data-grid={el.dataGrid}
                                                                key={el.dataGrid.i}
                                                                style={
                                                                    el.dataGrid.i === highlightedChart
                                                                        ? {
                                                                              zIndex: 10,
                                                                              borderColor: '#F0BB5F',
                                                                          }
                                                                        : {}
                                                                }
                                                                className={`${styles['back-office-report-management-container__dashboard-template-container__chart-container']}`}
                                                            >
                                                                <LibraryComponentFactory
                                                                    hoveredFilter={''}
                                                                    hoveredComponents={[]}
                                                                    component={el}
                                                                    onPinComponent={() => {}}
                                                                    onUnpinComponent={() => {}}
                                                                    pinnedComponents={[]}
                                                                    onMouseEnterInfoIcon={() => {}}
                                                                    onMouseLeaveInfoIcon={() => {}}
                                                                    type={el.type}
                                                                    salesDataTimeWindow={'month'}
                                                                    isExample
                                                                />
                                                                <ExampleBackground />
                                                                <EditOptions
                                                                    type={el.dataGrid.i}
                                                                    config={el.config}
                                                                    onHighlightChart={() => {
                                                                        setHighlightedChart(el)
                                                                    }}
                                                                    onOpenChartConfig={onOpenChartConfig}
                                                                    onDeleteChart={onDeletePageChart}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </ResponsiveGridLayout>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!selectedReport && (
                                <div className="row mx-0 w-100" style={{ height: '45rem' }}>
                                    <div className="col px-0 h-100">
                                        <ReportsList onReportView={handleRowClick} onReportClick={handleReportClick} />
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <Auxbar
                                availableCharts={availableCharts}
                                notAvailableCharts={notAvailableCharts}
                                isBuilder={reportPageStep === 'edit'}
                            /> */}
                    </div>
                )}
                {!loading && reportPageStep === 'templates' && (
                    <div className={`row mx-0 w-100 ${styles['back-office-report-management-container']}`}>
                        <div className="col-12 px-0 h-100 ">
                            <div className="row w-100 dashboard-header-row">
                                <div className="col px-0 d-flex justify-content-between align-items-center">
                                    {t('pages:report-management.chooseatemplate')}
                                    <SecondaryButton text={t('buttons:return')} onClick={() => handleNavigate('back', 'initial')} />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    marginBottom: '5rem',
                                }}
                            >
                                <div className="col px-0">
                                    <div
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span className={`${styles['back-office-report-management-container__sub-title']}`}>
                                                {t('pages:report-management.subTitle')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div
                                            className="col d-flex justify-content-center align-items-center"
                                            style={{
                                                padding: '0 30rem',
                                            }}
                                        >
                                            <span className={`${styles['back-office-report-management-container__description']}`}>
                                                {t('pages:report-management.description')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                style={{
                                    marginBottom: '2rem',
                                }}
                            >
                                <div className="col-auto px-0">
                                    <SecondaryButton text={t('buttons:create-report')} onClick={() => handleChooseTemplate('blankPage')} />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                style={{
                                    marginBottom: '5rem',
                                }}
                            >
                                <div className="col-auto px-0">
                                    <span className={`${styles['back-office-report-management-container__sub-title']}`}>
                                        {t('pages:report-management.or')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 flex-grow-1">
                                <div className={`col px-0`}>
                                    <div className={`${styles['back-office-report-management-container__layout-container__options-container']}`}>
                                        <CardCarousel
                                            cards={[
                                                <DashboardPreviewCard
                                                    key={'salesReport'}
                                                    name={'salesReport'}
                                                    page={'generalPage'}
                                                    onChooseTemplate={handleChooseTemplate}
                                                    onPreviewTemplate={handlePreviewTemplate}
                                                    t={t}
                                                />,
                                                <DashboardPreviewCard
                                                    key={'clientsGeneral'}
                                                    name={'clientsGeneral'}
                                                    page={'clientsGeneralPage'}
                                                    onChooseTemplate={handleChooseTemplate}
                                                    onPreviewTemplate={handlePreviewTemplate}
                                                    t={t}
                                                />,
                                                <DashboardPreviewCard
                                                    key={'clientsDetailed'}
                                                    name={'clientsDetailed'}
                                                    page={'clientsDetailedPage'}
                                                    onChooseTemplate={handleChooseTemplate}
                                                    onPreviewTemplate={handlePreviewTemplate}
                                                    t={t}
                                                />,
                                                <DashboardPreviewCard
                                                    key={'products'}
                                                    name={'products'}
                                                    page={'productsPage'}
                                                    onChooseTemplate={handleChooseTemplate}
                                                    onPreviewTemplate={handlePreviewTemplate}
                                                    t={t}
                                                />,
                                                <DashboardPreviewCard
                                                    key={'blankReport'}
                                                    name={'blankReport'}
                                                    page={'blankReport'}
                                                    onChooseTemplate={handleChooseTemplate}
                                                    onPreviewTemplate={handlePreviewTemplate}
                                                    t={t}
                                                />,
                                            ]}
                                            onChooseTemplate={handleChooseTemplate}
                                            onPreviewTemplate={handlePreviewTemplate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Auxbar
                            availableCharts={availableCharts}
                            notAvailableCharts={notAvailableCharts}
                            isBuilder={reportPageStep === 'edit'}
                        /> */}
                    </div>
                )}
                {!loading && reportPageStep === 'components' && (
                    <div className={`row mx-0 w-100 ${styles['back-office-report-management-container']}`}>
                        <div className="col-12 px-0 h-100 ">
                            <div className="row w-100 dashboard-header-row">
                                <div className="col px-0 d-flex justify-content-between align-items-center">
                                    {t('pages:report-management.components')}
                                    <div className="d-flex">
                                        <div className="me-2">
                                            <SecondaryButton
                                                type={'return'}
                                                text={t('buttons:return')}
                                                onClick={() => handleNavigate('back', 'templates')}
                                            />
                                        </div>
                                        <PrimaryButton
                                            disabled={notAvailableCharts.length === 0}
                                            text={t('buttons:filterConfig')}
                                            onClick={() => handleNavigate('front', 'filterConfig')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 h-100">
                                <div className={`col ${styles['back-office-report-management-container__layout-container__options-container']}`}>
                                    <div
                                        className={`row mx-0 h-100 ${styles['back-office-report-management-container__dashboard-template-container']}`}
                                    >
                                        <div
                                            className={`col px-0 d-flex flex-column ${styles['back-office-report-management-container__dashboard-template-container__invisible']}`}
                                        >
                                            <div className="row mx-0 w-100 h-100">
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__invisible__column']}`}
                                                ></div>
                                            </div>
                                        </div>
                                        <div
                                            className={`col px-0 d-flex flex-column ${styles['back-office-report-management-container__dashboard-template-container__background']}`}
                                        >
                                            <div className="row mx-0 w-100 h-100">
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']} d-flex justify-content-center align-items-center']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                                <div
                                                    className={`col h-100 ${styles['back-office-report-management-container__dashboard-template-container__background__column']}`}
                                                ></div>
                                            </div>
                                        </div>
                                        <div
                                            className="col px-0 d-flex flex-column"
                                            style={{
                                                minHeight: 'inherit',
                                            }}
                                            ref={gridRef}
                                        >
                                            <ResponsiveGridLayout
                                                cols={cols}
                                                transformScale={!_.isEmpty(resizableGrid.droppingElement) ? transformScaleCustom : undefined}
                                                className={resizableGrid.gridMounted ? 'layout w-100 mx-0 animated ' : 'layout w-100 mx-0 '}
                                                rowHeight={currentWidthSectionMultiplier * 30}
                                                maxRows={resizableGrid.maxRows}
                                                compactType={resizableGrid.compactType}
                                                draggableCancel={'.resize-handle'}
                                                verticalCompact={resizableGrid.verticalCompact}
                                                layout={resizableGrid.layout}
                                                onLayoutChange={
                                                    !_.isEmpty(resizableGrid.droppingElement)
                                                        ? () => {}
                                                        : (layout) => onPageLayoutChange(undefined, layout)
                                                }
                                                isDraggable={resizableGrid.isEditable}
                                                isResizable={resizableGrid.isEditable}
                                                isDroppable={true}
                                                onDrop={(layout, layoutItem, _event) => {
                                                    if (notAvailableCharts.length > maxComponentCount) {
                                                        error(t('pages:report-management.maxComponentsError'))
                                                    } else if (layoutItem) {
                                                        onPageDropChart(undefined, layout, layoutItem, _event)
                                                    }
                                                    _event.preventDefault()
                                                }}
                                                droppingItem={
                                                    !_.isEmpty(resizableGrid.droppingElement)
                                                        ? {
                                                              i: resizableGrid.droppingElement.i,
                                                              w: componentConfig[resizableGrid.droppingElement.name].dataGrid.w,
                                                              h: componentConfig[resizableGrid.droppingElement.name].dataGrid.h,
                                                              minW: componentConfig[resizableGrid.droppingElement.name].dataGrid.minW,
                                                              maxW: componentConfig[resizableGrid.droppingElement.name].dataGrid.maxW,
                                                              minH: componentConfig[resizableGrid.droppingElement.name].dataGrid.minH,
                                                              maxH: componentConfig[resizableGrid.droppingElement.name].dataGrid.maxH,
                                                          }
                                                        : undefined
                                                }
                                                preventCollision={false}
                                                draggableHandle={resizableGrid.draggableHandle}
                                                margin={resizableGrid.margin}
                                                containerPadding={resizableGrid.containerPadding}
                                                resizeHandle={resizableGrid.isEditable ? <MyResizeHandle /> : ''}
                                                autoSize={resizableGrid.autoSize}
                                                isBounded={resizableGrid.isBounded}
                                                useCSSTransforms={false}
                                                measureBeforeMount={false}
                                            >
                                                {notAvailableCharts.map((el) => {
                                                    return (
                                                        <div
                                                            data-grid={el.dataGrid}
                                                            key={el.dataGrid.i}
                                                            style={
                                                                el.dataGrid.i === highlightedChart
                                                                    ? {
                                                                          zIndex: 10,
                                                                          borderColor: '#F0BB5F',
                                                                      }
                                                                    : {}
                                                            }
                                                            className={`${styles['back-office-report-management-container__dashboard-template-container__chart-container']}`}
                                                        >
                                                            <LibraryComponentFactory
                                                                hoveredFilter={''}
                                                                hoveredComponents={[]}
                                                                component={el}
                                                                onPinComponent={() => {}}
                                                                onUnpinComponent={() => {}}
                                                                pinnedComponents={[]}
                                                                onMouseEnterInfoIcon={() => {}}
                                                                onMouseLeaveInfoIcon={() => {}}
                                                                type={el.type}
                                                                salesDataTimeWindow={'month'}
                                                                isExample
                                                            />
                                                            <ExampleBackground />
                                                            <EditOptions
                                                                type={el.dataGrid.i}
                                                                config={el.config}
                                                                onHighlightChart={() => {
                                                                    setHighlightedChart(el)
                                                                }}
                                                                onOpenChartConfig={onOpenChartConfig}
                                                                onDeleteChart={onDeletePageChart}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </ResponsiveGridLayout>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto px-0">
                            {/* <Auxbar
                                includeStore
                                availableCharts={availableCharts}
                                notAvailableCharts={notAvailableCharts}
                                isBuilder */}
                        </div>
                    </div>
                )}
                {!loading && reportPageStep === 'filterConfig' && (
                    <CustomDashboard
                        type="filterConfig"
                        initialDashboardTitle={title.value}
                        onChangeContent={setNotAvailableChartValue}
                        onChangeSize={setNotAvailableChartSize}
                        initialNotAvailableCharts={notAvailableCharts}
                        initialAvailableCharts={[]}
                        initialDropdownList={[]}
                        resizableGrid={resizableGrid}
                        onSetLayout={onSetLayout}
                        onNavigate={handleNavigate}
                        onCreateReport={handleCreateReport}
                        pageState={reportPageStep}
                        lastState={lastStep}
                    />
                )}
                {!loading && reportPageStep === 'editConfig' && (
                    <CustomDashboard
                        type="editConfig"
                        onChangeContent={setNotAvailableChartValue}
                        onChangeSize={setNotAvailableChartSize}
                        initialDashboardTitle={title.value}
                        initialNotAvailableCharts={notAvailableCharts}
                        initialAvailableCharts={[]}
                        initialDropdownList={[]}
                        resizableGrid={resizableGrid}
                        onSetLayout={onSetLayout}
                        onNavigate={handleNavigate}
                        onCreateReport={handleCreateReport}
                        pageState={reportPageStep}
                        lastState={lastStep}
                    />
                )}
                {!loading && reportPageStep === 'preview' && (
                    <CustomDashboard
                        type="previewReport"
                        initialDashboardTitle={title.value}
                        initialNotAvailableCharts={notAvailableCharts}
                        initialAvailableCharts={[]}
                        initialDropdownList={[]}
                        resizableGrid={resizableGrid}
                        onSetLayout={onSetLayout}
                        onNavigate={handleNavigate}
                        onCreateReport={handleCreateReport}
                        pageState={reportPageStep}
                        lastState={lastStep}
                    />
                )}
                {!loading && reportPageStep === 'previewEdit' && (
                    <CustomDashboard
                        type="previewReportEdit"
                        initialDashboardTitle={title.value}
                        initialNotAvailableCharts={notAvailableCharts}
                        initialAvailableCharts={[]}
                        initialDropdownList={[]}
                        resizableGrid={resizableGrid}
                        onSetLayout={onSetLayout}
                        onNavigate={handleNavigate}
                        onCreateReport={handleEditReport}
                        pageState={reportPageStep}
                        lastState={lastStep}
                    />
                )}
            </>
        )
    }
)

BackOfficeReportManagement.propTypes = {}

export default BackOfficeReportManagement
