/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageSuccessResponseSchema } from '../models/MessageSuccessResponseSchema';
import type { UserSchema } from '../models/UserSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProfileService {
    /**
     * Logic for recovering pwd. sends email with token for user email.
     * @returns MessageSuccessResponseSchema call successful
     * @throws ApiError
     */
    public static postApiAuthForgotPassword({
        requestBody,
    }: {
        requestBody?: {
            email?: string;
            /**
             * url for user to fallback
             */
            redirect_url?: string;
        },
    }): CancelablePromise<MessageSuccessResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * forces set password with valid token. Used for forgot password and emails sent to new users
     * @returns void
     * @throws ApiError
     */
    public static postApiAuthSetPassword({
        uuid,
        token,
        requestBody,
    }: {
        /**
         * User's unique uuid.
         */
        uuid: string,
        /**
         * User's secret reset password token.
         */
        token: string,
        requestBody?: {
            password?: string;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/set-password/{uuid}',
            path: {
                'uuid': uuid,
            },
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * list all the accounts created by the client
     * @returns UserSchema call successful
     * @throws ApiError
     */
    public static getApiProfileListAccounts(): CancelablePromise<Array<UserSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/list_accounts',
        });
    }
    /**
     * Creates a new user.
     * @returns UserSchema call successful
     * @throws ApiError
     */
    public static postApiProfileUser({
        requestBody,
    }: {
        requestBody: UserSchema,
    }): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deletes user
     * @returns MessageSuccessResponseSchema call successful
     * @throws ApiError
     */
    public static deleteApiProfileUser({
        uuid,
    }: {
        /**
         * User's unique uuid.
         */
        uuid: string,
    }): CancelablePromise<MessageSuccessResponseSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/profile/user/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * gets user information
     * @returns UserSchema call successful
     * @throws ApiError
     */
    public static getApiProfileUser({
        uuid,
    }: {
        /**
         * User's unique uuid.
         */
        uuid: string,
    }): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/user/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }
    /**
     * Change password. token must be of user. only own user can change own password
     * @returns UserSchema call successful
     * @throws ApiError
     */
    public static postApiProfileUserChangePassword({
        uuid,
        requestBody,
    }: {
        /**
         * User's unique uuid.
         */
        uuid: string,
        requestBody?: {
            new_pwd?: string;
            old_pwd?: string;
        },
    }): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/user/{uuid}/change-password',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * partially edits user information. password not allowed. only starkdata or admin can change roles
     * @returns UserSchema call successful
     * @throws ApiError
     */
    public static patchApiProfileUserEdit({
        uuid,
        requestBody,
    }: {
        /**
         * User's unique uuid.
         */
        uuid: string,
        requestBody?: UserSchema,
    }): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/profile/user/{uuid}/edit',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Edits user information. password not allowed. only starkdata or admin can change roles
     * @returns UserSchema call successful
     * @throws ApiError
     */
    public static postApiProfileUserEdit({
        uuid,
        requestBody,
    }: {
        /**
         * User's unique uuid.
         */
        uuid: string,
        requestBody?: UserSchema,
    }): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/user/{uuid}/edit',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
