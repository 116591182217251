import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg'
import icoView from '../../../assets/images/ico-view.svg'
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg'

export const sentimentWordFrequencyMock = {
    words: [
        {
            text: 'told',
            value: 64,
            sentiment: 'positive',
        },
        {
            text: 'mistake',
            value: 11,
            sentiment: 'neutral',
        },
        {
            text: 'thought',
            value: 16,
            sentiment: 'negative',
        },
        {
            text: 'bad',
            value: 17,
            sentiment: 'negative',
        },
    ],
    originalWords: [
        {
            text: 'told',
            value: 64,
            sentiment: 'positive',
        },
        {
            text: 'mistake',
            value: 11,
            sentiment: 'neutral',
        },
        {
            text: 'thought',
            value: 16,
            sentiment: 'negative',
        },
        {
            text: 'bad',
            value: 17,
            sentiment: 'negative',
        },
    ],
    attr: 'sentimentWordFrequency',
    cloudOptions: {
        deterministic: true,
        enableTooltip: false,
        rotations: 2,
        rotationAngles: [-90, 0],
        fontFamily: 'sans-serif',
        fontSizes: [10, 40],
    },
    maxWords: 500,
    loading: true,
    view: {
        label: 'VIEW',
        options: [],
    },
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [
                {
                    name: 'filter',
                    icon: icoView,
                    label: 'Filter',
                    translated: false,
                },
            ],
        },
        viewOptions: {
            ratioOptions: {
                options: [],
                selectedOption: '',
            },
            checkboxOptions: {
                options: [
                    {
                        name: 'positive',
                        color: '#34b53a',
                        disabled: false,
                        translated: false,
                        icon: 'selected',
                        iconDefault: icoCheckboxEmpty,
                        iconSelected: icoCheckboxSelected,
                    },
                    {
                        name: 'neutral',
                        color: '#f5a623',
                        disabled: false,
                        translated: false,
                        icon: 'selected',
                        iconDefault: icoCheckboxEmpty,
                        iconSelected: icoCheckboxSelected,
                    },
                    {
                        name: 'negative',
                        color: '#d0021b',
                        disabled: false,
                        translated: false,
                        icon: 'selected',
                        iconDefault: icoCheckboxEmpty,
                        iconSelected: icoCheckboxSelected,
                    },
                ],
                selectedOptions: ['positive', 'neutral', 'negative'],
            },
        },
    },
}
