import _ from 'lodash'
import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const LostClientTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    return (
        <TooltipContainer color={props.color}>
            <TooltipHeaderRow
                title={t('tooltips:header.identifier')}
                value={_.has(props.loc, 'regionId') ? props.loc.regionId : '-'}
            />
            <Separator />
            <TooltipContentRow
                title={t('tooltips:content.name')}
                value={_.has(props.loc, 'name') ? props.loc.name : '-'}
            />
        </TooltipContainer>
    )
})
