import _ from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { JobsService } from '../../../temp/test';
import icoDelete from '../../../assets/images/ico-delete.svg';
import { scheduledJobsListMock } from './mock';
import { verifyComponent } from '../../../utils/component';
import {
    ContentContainer,
    DeleteButton,
    Loader,
    Modal,
    PrimaryButton,
    SecondaryButton,
    Spinner,
    Table
} from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays logins in a table
 */

export const ScheduledJobsList = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const [selectedScheduledJobId, setSelectedScheduledJobId] = useState('');
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(scheduledJobsListMock, modal, '');

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedScheduledJobId(id);
            }
            if (isMounted.current) {
                toggleComponentSettings(false);
                toggleModal(true);
                setModalType(option);
            }
        },
        [
            isMounted,
            setSelectedScheduledJobId,
            toggleComponentSettings,
            toggleModal
        ]
    );

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false);
            setModalType('');
            setSelectedScheduledJobId('');
        }
    };

    const getScheduledJobs = useCallback(async () => {
        const dataClone = _.cloneDeep(scheduledJobsListMock);
        try {
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            if (isMounted.current) setValue(dataClone);
            if (isMounted.current) setLoading(true);
            const result = await JobsService.getApiBackofficeJobsCron();
            result.map((el) => {
                dataClone.data.push({
                    id: el.id,
                    description: el.description,
                    creationTime: el.created_at,
                    isoDate: el.created_at,
                    cronString: el.cron_string,
                    meta: el.meta,
                    origin: el.origin,
                    action: {
                        label: t(`columns:action`),
                        id: el.id,
                        open: false,
                        options: [
                            {
                                name: 'removeCronJob',
                                label: t('settings:removeCronJob'),
                                icon: icoDelete
                            }
                        ],
                        reference: React.createRef()
                    }
                });
                return el;
            });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            verifyComponent(dataClone);
            if (isMounted.current) {
                setValue(dataClone);

                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue, t]);

    const handleDeleteScheduledJob = async () => {
        try {
            await JobsService.deleteApiBackofficeJobsCronCancel({
                jobId: selectedScheduledJobId
            });
            if (isMounted.current) {
                toggleModal(false);
                setModalType('');
                setSelectedScheduledJobId('');
            }
            getScheduledJobs();
        } catch (err) {
            errorHandler(err);
        }
    };

    useEffect(() => {
        getScheduledJobs();
    }, [getScheduledJobs]);

    return (
        <>
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <React.Fragment>
                        {modal && modalType === 'removeCronJob' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">
                                            {t(
                                                'pages:managerProfile.areyousure'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton
                                            text={t('modals:cancel')}
                                            onClick={handleCloseModal}
                                        />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton
                                            text={t('modals:remove')}
                                            onClick={handleDeleteScheduledJob}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        <Table
                            actions={data.actions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(
                                `components:${data.attr}.nodata`
                            )}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={undefined}
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </>
    );
});

ScheduledJobsList.propTypes = {};
