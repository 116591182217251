import React from 'react';
import { GeneralTable } from '../../../../../components/dashboard-components/general-table/general-table';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { invitationsListMock } from './mock';
import { usePlatformFormat } from '../../../../../providers/platform-format-context';

export const InvitationsList = () => {
    const { t } = useCustomTranslation();
    const { format } = usePlatformFormat();
    return (
        <GeneralTable
            title={t(`components:${invitationsListMock.attr}.title`)}
            attr={invitationsListMock.attr}
            columns={
                format === 'enterprise'
                    ? invitationsListMock.enterpriseColumns
                    : invitationsListMock.organizationColumns
            }
            mockData={invitationsListMock.data}
            withSearch
            noDataText={t(`components:${invitationsListMock.attr}.nodata`)}
            hideTitle
            withFilter
        />
    );
};
