import { createContext, useContext, useMemo, useState } from 'react';

const TimeIntervalContext = createContext({
    timeInterval: undefined,
    setTimeInterval: () => {},
    startDate: undefined,
    setStartDate: () => {},
    endDate: undefined,
    setEndDate: () => {}
});

export function TimeIntervalProvider({ timeInterval, children }) {
    const [selectedOption, setSelectedOption] = useState(
        timeInterval || 'month'
    );
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const timeIntervalValue = useMemo(() => {
        return {
            timeInterval: selectedOption,
            setTimeInterval: setSelectedOption,
            startDate,
            setStartDate,
            endDate,
            setEndDate
        };
    }, [selectedOption, startDate, endDate]);

    return (
        <TimeIntervalContext.Provider value={timeIntervalValue}>
            {children}
        </TimeIntervalContext.Provider>
    );
}

export function useTimeInterval() {
    return useContext(TimeIntervalContext);
}
