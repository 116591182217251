import React from 'react'
import { TooltipContainer } from '../../../tooltip-container/tooltip-container'
import { TooltipHeaderRow } from '../../../tooltip-header-row/tooltip-header-row'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const NameTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    return (
        <TooltipContainer color={props.color}>
            <TooltipHeaderRow
                title={t('tooltips:header.name')}
                value={props.loc.name ? props.loc.name : '-'}
            />
        </TooltipContainer>
    )
})
