/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HospitalSchema } from '../models/HospitalSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HospitalService {
    /**
     * gets all hospitals
     * @returns HospitalSchema call successful
     * @throws ApiError
     */
    public static getApiHospitals(): CancelablePromise<HospitalSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hospitals',
        });
    }
}
