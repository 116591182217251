import React from 'react';

export const goalHistoryListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'goalName', label: '', sortable: true },
        { path: 'goalStartDate', label: '', sortable: true },
        { path: 'goalEndDate', label: '', sortable: true },
        { path: 'goalTargetValue', label: '', sortable: true },
        { path: 'goalReachedValue', label: '', sortable: true },
        { path: 'goalSubGoalCount', label: '', sortable: true },
        { path: 'goalAchieved', label: '', sortable: true }
    ],
    sortColumn: { path: 'goalName', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'goalHistoryList',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: []
        },
        view: {
            options: []
        },
        options: {
            label: '',
            options: []
        },
        reference: React.createRef()
    }
};
