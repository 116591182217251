export const LOCATIONS = [
    [38.7223, -9.1393], //# Lisbon
    [41.3496, -7.611], //# Porto
    [37.0171, -7.9303], //# Faro
    [40.4115, -8.4292], //# Coimbra
    [38.5277, -8.8741], //# Setúbal
    [39.0308, -9.2983], //# Leiria
    [39.4659, -8.055], //# Santarém
    [41.4425, -8.2968], //# Braga
    [37.2046, -7.4], //# Albufeira
    [37.1377, -8.0237], //# Portimão
    [39.6668, -8.586], //# Tomar
    [41.1579, -8.6291], //# Matosinhos
    [38.7756, -9.4226], //# Cascais
    [39.2823, -7.5038], //# Évora
    [37.2076, -7.6257], //# Lagos
    [40.643, -7.6455], //# Aveiro
    [37.1028, -8.6771], //# Alvor
    [38.5538, -7.9123], //# Beja
    [41.3557, -8.74], //# Vila Nova de Gaia
    [39.0369, -8.98], //# Caldas da Rainha
    [40.6338, -8.66], //# Viseu
    [38.894, -9.0498], //# Oeiras
    [37.0755, -8.0731], //# Quarteira
    [40.2126, -8.4268], //# Figueira da Foz
    [41.6994, -8.8348], //# Viana do Castelo
    [37.1377, -8.0237], //# Alvor
    [37.6213, -8.6669], //# Aljezur
    [41.6944, -8.8303], //# Ponte de Lima
    [38.0142, -7.8634], //# Moura
    [38.697, -9.4229], //# Sintra
]
