import styles from './patient-journey.module.scss';

import _ from 'lodash';
import React, { useEffect, useCallback, useState } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { patientJourneyMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { ContentContainer, Loader, Spinner } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { RichTextInput } from '../../inputs/rich-text-input/rich-text-input';
import { ImageInput } from '../../inputs/image-input/image-input';
import { useToggle } from '../../../hooks/useToggle';
import { ClientAccountService } from '../../../temp/test';
import { usePreviewMode } from '../../dashboard-creation/library-component-factory/preview-mode-provider';

/**
 * Displays general information about a client
 */

export const PatientJourney = React.memo(
    ({
        onDownloadComponent,
        config,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { isExample } = usePreviewMode();
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const [isEditing, toggleIsEditing] = useToggle(false);
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(patientJourneyMock);

        const [notes, setNotes] = useState(undefined);
        const [initialNotes, setInitialNotes] = useState(undefined);
        const [file, setFile] = useState(undefined);
        const [changedFile, setChangedFile] = useState(undefined);

        const getClientAccountIdInitial = useCallback(async () => {
            try {
                const backData = await ClientAccountService.getApiClientAccount(
                    {}
                );
                const filteredData = backData.filter((e) => {
                    return (
                        e.product_id === parseInt(filter.productId) &&
                        e.client_id === parseInt(filter.hospitalId)
                    );
                });
                if (filteredData.length > 0) {
                    return filteredData[0].id;
                } else return undefined;
            } catch (err) {
                console.log(err);
            }
        }, [filter]);

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(patientJourneyMock);
            if (!isExample) {
                setLoading(true);
                try {
                    const backData2 =
                        await ClientAccountService.getApiClientAccountPatientJourneyGetByParams(
                            {
                                clientId: filter.hospitalId,
                                productId: filter.productId
                            }
                        );
                    dataClone.lastUpdated = backData2.updated_at;
                    setNotes(backData2.notes);
                    setInitialNotes(backData2.notes);
                    setValue(dataClone);
                    const clientAccountId2 = await getClientAccountIdInitial();
                    if (!isExample && clientAccountId2) {
                        fetch(
                            `${process.env.REACT_APP_API_URL}/api/client-account/patient-journey/${clientAccountId2}/file`,
                            {
                                method: 'GET',
                                responseType: 'blob'
                            }
                        )
                            .then((response) => {
                                if (response.status === 200) {
                                    response.blob().then((blob) => {
                                        setFile(blob);
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    setLoading(false);
                } catch (err) {
                    setNotes(undefined);
                    setInitialNotes(undefined);
                    setFile(undefined);
                    dataClone.loading = false;
                    setValue(dataClone);
                }
                setLoading(false);
            } else {
                verifyComponent(dataClone);
                setValue(dataClone);
                setLoading(false);
            }
        }, [
            filter,
            isExample,
            setValue,
            setLoading,
            getClientAccountIdInitial
        ]);

        useEffect(() => {
            getInformation();
        }, [getInformation]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        const handleOpenEditor = useCallback(() => {
            toggleIsEditing();
        }, [toggleIsEditing]);

        const getClientAccountId = useCallback(async () => {
            try {
                const backData = await ClientAccountService.getApiClientAccount(
                    {}
                );
                const filteredData = backData.filter((e) => {
                    return (
                        e.product_id === parseInt(filter.productId) &&
                        e.client_id === parseInt(filter.hospitalId)
                    );
                });
                if (filteredData.length > 0) {
                    return filteredData[0].id;
                } else if (filteredData.length === 0) {
                    const result =
                        await ClientAccountService.postApiClientAccountUpsert({
                            requestBody: {
                                product_id: filter.productId,
                                client_id: filter.hospitalId
                            }
                        });
                    return result.id;
                }
            } catch (err) {
                console.log(err);
            }
        }, [filter]);

        const getId = useCallback(async () => {
            try {
                const backData2 =
                    await ClientAccountService.getApiClientAccountPatientJourneyGetByParams(
                        {
                            clientId: filter.hospitalId,
                            productId: filter.productId
                        }
                    );
                return backData2.id;
            } catch (err) {
                console.log(err);
            }
        }, [filter.hospitalId, filter.productId]);

        const handleSave = useCallback(async () => {
            const clientAccountId = await getClientAccountId();
            const id = await getId();
            try {
                await ClientAccountService.postApiClientAccountPatientJourneyUpsert(
                    {
                        requestBody: {
                            client_account_id: clientAccountId,
                            id: id,
                            notes: notes
                        }
                    }
                );
            } catch (err) {
                console.log(err);
            }
            try {
                if (file && changedFile) {
                    await ClientAccountService.postApiClientAccountPatientJourneyFile(
                        {
                            accountPlanId: clientAccountId,
                            formData: {
                                file: file
                            }
                        }
                    );
                } else if (changedFile) {
                    await ClientAccountService.deleteApiClientAccountPatientJourneyFile(
                        {
                            accountPlanId: clientAccountId
                        }
                    );
                }
                toggleIsEditing();
            } catch (err) {
                errorHandler(err);
            }
        }, [
            getId,
            notes,
            toggleIsEditing,
            file,
            getClientAccountId,
            changedFile
        ]);

        const handleCancel = useCallback(() => {
            getInformation();
            toggleIsEditing();
        }, [getInformation, toggleIsEditing]);

        return (
            <div
                className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    lastUpdated={data.lastUpdated}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    isEditing={isEditing}
                    onOpenEditMode={handleOpenEditor}
                    onCancelEditMode={handleCancel}
                    onSaveEditMode={handleSave}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`${styles['patient-journey-container']}`}
                        >
                            <div
                                className="row mx-0 w-100"
                                style={{ height: '4rem', marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={`${styles['patient-journey-container__description']}`}
                                    >
                                        {t(
                                            `components:${data.attr}.description`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: 'calc(100% - 6rem)'
                                }}
                            >
                                <div
                                    className="col-auto px-0 h-100"
                                    style={{
                                        width: 'calc((100% - 2rem) / 2)'
                                    }}
                                >
                                    <RichTextInput
                                        isEditing={isEditing}
                                        text={notes}
                                        onSetText={(text) => {
                                            setNotes(text);
                                        }}
                                    />
                                </div>
                                <div
                                    className="col-auto px-0"
                                    style={{ width: '2rem' }}
                                ></div>
                                <div
                                    className="col-auto px-0 h-100"
                                    style={{
                                        width: 'calc((100% - 2rem) / 2)'
                                    }}
                                >
                                    <ImageInput
                                        isEditing={isEditing}
                                        image={file}
                                        onSetImage={(image) => {
                                            setChangedFile(true);
                                            setFile(image);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

PatientJourney.propTypes = {};
