import React from 'react'
import AdministrationDynamicTables from '../pages/administration/dynamic-tables/dynamic-tables'
import AdministrationDashboardConfiguration from '../pages/administration/dashboard-configuration/dashboard-configuration'
import AdministrationReportManagement from '../pages/administration/report-management/report-management'
import AdministrationScheduledJobs from '../pages/administration/scheduled-jobs/scheduled-jobs'
import AdministrationUserListing from '../pages/administration/user-listing/user-listing'
import AdministrationUserRoleManagement from '../pages/administration/user-role-management/user-role-management'
import AdministrationUserStatistics from '../pages/administration/user-statistics/user-statistics'
import ContactSupport from '../pages/auth/contact-support/contact-support'
import ForgotPassword from '../pages/auth/forgot-password/forgot-password'
import Login from '../pages/auth/login/login'
import Logout from '../pages/auth/logout/logout'
import ResetPassword from '../pages/auth/reset-password/reset-password'
import BackOfficeBilling from '../pages/back-office/billing/billing'
import ConfigAuthentication from '../pages/back-office/config-authentication/config-authentication'
import ConfigClient from '../pages/back-office/config-client/config-client'
import ConfigCompetitor from '../pages/back-office/config-competitor/config-competitor'
import ConfigPoi from '../pages/back-office/config-poi/config-poi'
import BackOfficeJobListing from '../pages/back-office/job-listing/job-listing'
import BackOfficeReportManagement from '../pages/back-office/report-management/report-management'
import BackOfficeUserActivity from '../pages/back-office/user-activity/user-activity'
import BackOfficeUserListing from '../pages/back-office/user-listing/user-listing'
import BackOfficeUserRoleManagement from '../pages/back-office/user-role-management/user-role-management'
import BackOfficeUserStatistics from '../pages/back-office/user-statistics/user-statistics'
import CreateDashboard from '../pages/general/create-dashboard/create-dashboard'
import CustomDashboard from '../pages/general/custom-dashboard/custom-dashboard'
import DraftDashboard from '../pages/general/draft-dashboard/draft-dashboard'
import EditProfile from '../pages/general/edit-profile/edit-profile'
import UserProfile from '../pages/general/user-profile/user-profile'
import MyNotifications from '../pages/general/my-notifications/my-notifications'
import MyTeams from '../pages/general/my-teams/my-teams'
import ProfileConfiguration from '../pages/general/profile-configuration/profile-configuration'
import ReportBuilder from '../pages/general/report-builder/report-builder'
import SegmentAnalysis from '../pages/general/segment-analysis/segment-analysis'
import SegmentAnalysisNew from '../pages/general/segment-analysis-new/segment-analysis-new'
import { SegmentDemographic } from '../pages/general/segment-analysis-new/sub-pages/segment-demographic/segment-demographic'
import { SegmentHandPicked } from '../pages/general/segment-analysis-new/sub-pages/segment-hand-picked/segment-hand-picked'
import { SegmentAi } from '../pages/general/segment-analysis-new/sub-pages/segment-ai/segment-ai'
import SentimentAnalysis from '../pages/general/sentiment-analysis/sentiment-analysis'
import Settings from '../pages/general/settings/settings'
import TeamManagement from '../pages/general/team-management/team-management'
import TeamManagementDetails from '../pages/general/team-management-details/team-management-details'
import ContasHospitalares from '../pages/general/contas-hospitalares/contas-hospitalares'
import SetPassword from '../pages/auth/set-password/set-password'
import { PeopleAndPermissions } from '../pages/general/people-and-permissions/PeopleAndPermissions'
import { YourOrganizations } from '../pages/general/your-organizations/YourOrganizations'
import { AuthenticationAndSecurity } from '../pages/general/authentication-and-security/AuthenticationAndSecurity'
import { Billing } from '../pages/general/billing/Billing'
import { YourContractDetails } from '../pages/general/your-contract-details/YourContractDetails'
import { YourTeams } from '../pages/general/your-teams/YourTeams'
import { YourTeamsSelection } from '../pages/general/your-teams-selection/YourTeamsSelection'
import { GoalManagement } from '../pages/general/GoalManagement/GoalManagement'
import { GoalDetails } from '../pages/general/GoalDetails/GoalDetails'
import { SubGoalOverview } from '../pages/general/GoalManagement/components/SubGoalOverview/SubGoalOverview'
import { SegmentMix } from '../pages/general/segment-analysis-new/sub-pages/segment-mix/segment-mix'
import { SegmentBehavioural } from '../pages/general/segment-analysis-new/sub-pages/segment-behavioural/segment-behavioural'

export const pageMap = {
    CustomDashboard: CustomDashboard,
    DraftDashboard: DraftDashboard,
    CreateDashboard: CreateDashboard,
    SegmentAnalysis: SegmentAnalysis,
    SegmentAnalysisNew: SegmentAnalysisNew,
    SegmentDemographic: SegmentDemographic,
    SegmentBehavioural:SegmentBehavioural,
    SegmentMix: SegmentMix,
    SegmentHandPicked: SegmentHandPicked,
    SegmentAi: SegmentAi,
    SentimentAnalysis: SentimentAnalysis,
    TeamManagement: TeamManagement,
    TeamManagementDetails: TeamManagementDetails,
    ContasHospitalares: ContasHospitalares,
    Settings: Settings,
    BackOfficeConfigClient: ConfigClient,
    BackOfficeConfigCompetitor: ConfigCompetitor,
    BackOfficeConfigAuthentication: ConfigAuthentication,
    BackOfficeConfigPoi: ConfigPoi,
    BackOfficeUserListing: BackOfficeUserListing,
    BackOfficeUserActivity: BackOfficeUserActivity,
    BackOfficeUserStatistics: BackOfficeUserStatistics,
    BackOfficeUserRoleManagement: BackOfficeUserRoleManagement,
    AdministrationDynamicTables: AdministrationDynamicTables,
    AdministrationDashboardConfiguration: AdministrationDashboardConfiguration,
    AdministrationUserListing: AdministrationUserListing,
    AdministrationUserStatistics: AdministrationUserStatistics,
    AdministrationUserRoleManagement: AdministrationUserRoleManagement,
    UserProfile: UserProfile,
    EditProfile: EditProfile,
    MyTeams: MyTeams,
    MyNotifications: MyNotifications,
    ProfileConfiguration: ProfileConfiguration,
    Login: Login,
    ContactSupport: ContactSupport,
    ForgotPassword: ForgotPassword,
    SetPassword: SetPassword,
    ResetPassword: ResetPassword,
    Logout: Logout,
    BackOfficeBilling: BackOfficeBilling,
    BackOfficeJobListing: BackOfficeJobListing,
    BackOfficeReportManagement: BackOfficeReportManagement,
    AdministrationReportManagement: AdministrationReportManagement,
    AdministrationScheduledJobs: AdministrationScheduledJobs,
    ReportBuilder: ReportBuilder,
    PeopleAndPermissions: PeopleAndPermissions,
    YourOrganizations: YourOrganizations,
    YourTeams: YourTeams,
    YourTeamsSelection: YourTeamsSelection,
    YourContractDetails: YourContractDetails,
    Billing: Billing,
    AuthenticationAndSecurity: AuthenticationAndSecurity,
    GoalManagement: GoalManagement,
    SubGoalOverview: SubGoalOverview,
    GoalDetails: GoalDetails,
}
