export const approvalRequestListMock = {
    titleCounter: '0',
    data: [
        {
            id: 1,
            name: 'Element 1',
            email: 'element1@example.com',
            type: 'Enterprise',
            date: 'March 20, 2019 23:14',
            approvalState: 'pending',
        },
        {
            id: 2,
            name: 'Element 2',
            email: 'element2@example.com',
            type: 'Organization',
            organization: 'Organization 2',
            date: 'March 20, 2019 23:14',
            approvalState: 'approved',
        },
        {
            id: 3,
            name: 'Element 3',
            email: 'element3@example.com',
            type: 'Enterprise',
            date: 'March 20, 2019 23:14',
            approvalState: 'rejected',
        },
        {
            id: 4,
            name: 'Element 4',
            email: 'element4@example.com',
            type: 'Organization',
            organization: 'Organization 4',
            date: 'March 20, 2019 23:14',
            approvalState: 'pending',
        },
        {
            id: 5,
            name: 'Element 5',
            email: 'element5@example.com',
            type: 'Enterprise',
            date: 'March 20, 2019 23:14',
            approvalState: 'pending',
        },
    ],
    enterpriseColumns: [
        { path: 'id', label: 'ID', sortable: true },
        { path: 'name', label: 'Name', sortable: true },
        { path: 'email', label: 'Email', sortable: true },
        { path: 'type', label: 'Type', sortable: true, isFilter: true },
        {
            path: 'organization',
            label: 'Organization',
            sortable: true,
        },
        { path: 'date', label: 'Date', sortable: true },
        { path: 'approvalState', label: '', sortable: false },
        { path: 'goalDetails', label: '', sortable: false },
    ],
    organizationColumns: [
        { path: 'id', label: 'ID', sortable: true },
        { path: 'name', label: 'Name', sortable: true },
        { path: 'email', label: 'Email', sortable: true },
        { path: 'date', label: 'Date', sortable: true },
        { path: 'approvalState', label: '', sortable: false },
        { path: 'goalDetails', label: '', sortable: false },
    ],
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'approvalRequestList',
    disabled: true,
    loading: true,
}
