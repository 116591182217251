import React from 'react';
import styles from './date-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const DateTableColumn = React.memo(({ el }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['date-table-column-container']}>
            <span className={`${styles['date-table-column-container__text']}`}>
                {el.date}
            </span>
        </div>
    );
});
