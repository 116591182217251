import React, { useCallback, useRef, useState } from 'react';
import styles from './MemberCard.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import icoCheckboxSelected from '../../../../../assets/images/ico-checkbox-selected.svg';
import icoCheckboxEmpty from '../../../../../assets/images/ico-checkbox-empty.svg';
import { ActionsButtonNew } from '../../../../../components/buttons/actions-button-new/actions-button-new';
import { RemoveMemberModal } from '../remove-member-modal/RemoveMemberModal';
import { useTextInput } from '../../../../../hooks/useTextInput';
import { useSelectInput } from '../../../../../hooks/useSelectInput';
import { SelectInput, TypeInput } from '../../../../../components';
import { DetailsEditButton } from '../../../../../components/buttons/details-edit-button/DetailsEditButton';
import { TransferMemberModal } from '../transfer-member-modal/transfer-member-modal';
import { success } from '../../../../../components/notifications/notification/notification';
import { ShowMemberTeamsModal } from '../../../../../components/modals/show-member-teams-modal/show-member-teams-modal';
import { EditMemberModal } from '../edit-member-modal/EditMemberModal';
import { usePlatformFormat } from '../../../../../providers/platform-format-context';
import icoSave from '../../../../../assets/images/ico-save-blue.svg';
import icoEdit from '../../../../../assets/images/ico-edit.svg';

export const MemberCard = React.memo(
    ({
        id,
        orgId,
        image,
        type,
        name,
        initials,
        email,
        role,
        teams,
        action,
        onCheck,
        onUncheck,
        checkedMembers,
        isEdit,
        onSaveEdit,
        onCancelEdit
    }) => {
        const { format } = usePlatformFormat();
        const [transferMemberModal, setTransferMemberModal] = useState(false);
        const [removeMemberModal, setRemoveMemberModal] = useState(false);
        const [showTeamsModal, setShowTeamsModal] = useState(false);
        const [editModal, setEditModal] = useState(false);
        const [editMode, setEditMode] = useState(isEdit || false);
        const { t } = useCustomTranslation();
        const handleClickOption = (option) => {
            if (option === 'transferMember') {
                setTransferMemberModal(true);
            } else if (option === 'remove') {
                setRemoveMemberModal(true);
            } else if (option === 'edit') {
                nameInputReplaceValue(name);
                emailInputReplaceValue(email);
                roleInputReset();
                setEditModal(true);
            }
        };

        const {
            value: nameInput,
            setValue: nameInputSetValue,
            replaceValue: nameInputReplaceValue
        } = useTextInput({
            name: 'nameInput',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: emailInput,
            setValue: emailInputSetValue,
            replaceValue: emailInputReplaceValue
        } = useTextInput({
            name: 'emailInput',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: contactInput,
            setValue: contactInputSetValue,
            replaceValue: contactInputReplaceValue
        } = useTextInput({
            name: 'contactInput',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: roleInput,
            addOption: roleInputAddOption,
            toggleDropdown: roleInputToggleDropdown,
            selectOption: roleInputSelectOption,
            keyPress: roleInputKeyPress,
            keyDown: roleInputKeyDown,
            setValue: roleInputSetValue,
            reset: roleInputReset,
            setError: roleInputSetError
        } = useSelectInput({
            name: 'roleInput',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const handleSaveEdit = useCallback(() => {
            success({ text: t('notifications:editedMember') });
            setEditMode(false);
        }, [t]);

        const handleEnterEdit = useCallback(() => {
            setEditMode(true);
        }, []);

        return (
            <>
                {editModal && (
                    <EditMemberModal
                        memberId={1}
                        onCloseModal={() => setEditModal(false)}
                    />
                )}
                {showTeamsModal && (
                    <ShowMemberTeamsModal
                        memberId={1}
                        onCloseModal={() => setShowTeamsModal(false)}
                    />
                )}
                {transferMemberModal && (
                    <TransferMemberModal
                        type={type}
                        orgId={orgId}
                        onCloseModal={() => setTransferMemberModal(false)}
                        onEnd={() => console.log('Member transferred')}
                    />
                )}
                {removeMemberModal && (
                    <RemoveMemberModal
                        onCloseModal={() => setRemoveMemberModal(false)}
                        onEnd={() => console.log('Member removed')}
                    />
                )}
                <div className={styles['member-card-container']}>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '1rem' }}
                    >
                        {!isEdit && (
                            <div className="col-auto px-0">
                                <img
                                    src={
                                        checkedMembers &&
                                        checkedMembers.length > 0 &&
                                        checkedMembers.includes(id)
                                            ? icoCheckboxSelected
                                            : icoCheckboxEmpty
                                    }
                                    alt="Checkbox"
                                    className={
                                        checkedMembers &&
                                        checkedMembers.length > 0
                                            ? `
                                    ${styles['member-card-container__checkbox-icon']}
                                    ${styles['member-card-container__checkbox-icon--selected']}`
                                            : styles[
                                                  'member-card-container__checkbox-icon'
                                              ]
                                    }
                                    onClick={() => {
                                        if (
                                            checkedMembers &&
                                            checkedMembers.includes(id) &&
                                            onUncheck
                                        ) {
                                            onUncheck(id);
                                        } else if (onCheck) onCheck(id);
                                    }}
                                />
                            </div>
                        )}
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            {image && (
                                <div
                                    className={
                                        role === 'admin'
                                            ? `${styles['member-card-container__image']} ${styles['member-card-container__image--admin']}`
                                            : styles[
                                                  'member-card-container__image'
                                              ]
                                    }
                                >
                                    <img src={image} alt="member" />
                                </div>
                            )}
                            {!image && (
                                <div
                                    className={
                                        role === 'admin'
                                            ? `${styles['member-card-container__image-placeholder']} ${styles['member-card-container__image-placeholder--admin']}`
                                            : styles[
                                                  'member-card-container__image-placeholder'
                                              ]
                                    }
                                >
                                    <span
                                        className={
                                            styles[
                                                'member-card-container__image-placeholder__text'
                                            ]
                                        }
                                    >
                                        {initials}
                                    </span>
                                </div>
                            )}
                        </div>
                        {isEdit && editMode && (
                            <div
                                className={
                                    styles['member-card-container__save-button']
                                }
                                onClick={handleSaveEdit}
                            >
                                <img
                                    src={icoSave}
                                    alt="Save"
                                    className={
                                        styles[
                                            'member-card-container__save-button__icon'
                                        ]
                                    }
                                />
                                <span
                                    className={
                                        styles[
                                            'member-card-container__save-button__text'
                                        ]
                                    }
                                >
                                    {t('buttons:save')}
                                </span>
                            </div>
                        )}
                        {isEdit && !editMode && (
                            <div
                                className={
                                    styles['member-card-container__edit-button']
                                }
                                onClick={handleEnterEdit}
                            >
                                <img
                                    src={icoEdit}
                                    alt="Edit"
                                    className={
                                        styles[
                                            'member-card-container__edit-button__icon'
                                        ]
                                    }
                                />
                                <span
                                    className={
                                        styles[
                                            'member-card-container__edit-button__text'
                                        ]
                                    }
                                >
                                    {t('buttons:edit')}
                                </span>
                            </div>
                        )}
                        {!isEdit && (
                            <div className="col-auto px-0">
                                <ActionsButtonNew
                                    actions={action}
                                    onClickOption={(args) =>
                                        handleClickOption(args, id)
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            {format === 'enterprise' && (
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                        <span
                                            className={
                                                styles[
                                                    'member-card-container__type'
                                                ]
                                            }
                                        >
                                            {t(`common:${type}`) || '-'}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={
                                            styles[
                                                'member-card-container__name'
                                            ]
                                        }
                                    >
                                        {editMode && (
                                            <div
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                <TypeInput
                                                    hideLabel
                                                    onChangeInput={
                                                        nameInputSetValue
                                                    }
                                                    {...nameInput}
                                                />
                                            </div>
                                        )}
                                        {!editMode && name}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={
                                            styles[
                                                'member-card-container__email'
                                            ]
                                        }
                                    >
                                        {editMode && (
                                            <div
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                <TypeInput
                                                    hideLabel
                                                    onChangeInput={
                                                        emailInputSetValue
                                                    }
                                                    {...emailInput}
                                                />
                                            </div>
                                        )}
                                        {!editMode && email}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100"
                        style={{ marginTop: '1rem' }}
                    >
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <span
                                className={
                                    styles['member-card-container__label']
                                }
                            >
                                {t('pages:peopleAndPermissions.role')}
                            </span>
                            <span
                                className={
                                    role === 'admin'
                                        ? `${styles['member-card-container__role']} ${styles['member-card-container__role--admin']}`
                                        : styles['member-card-container__role']
                                }
                            >
                                {editMode && (
                                    <SelectInput
                                        hideLabel
                                        onChangeInput={roleInputSetValue}
                                        onSelectInput={roleInputSelectOption}
                                        onKeyPress={roleInputKeyPress}
                                        onKeyDown={roleInputKeyDown}
                                        onToggleDropdown={
                                            roleInputToggleDropdown
                                        }
                                        {...roleInput}
                                    />
                                )}
                                {!editMode &&
                                    (t(`pages:peopleAndPermissions.${role}`) ||
                                        '-')}
                            </span>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{
                                width: '1px',
                                margin: '0 1rem',
                                backgroundColor: '#E5E5E5'
                            }}
                        ></div>
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <span
                                className={
                                    styles['member-card-container__label']
                                }
                            >
                                {t('pages:peopleAndPermissions.teams')}
                            </span>
                            <span
                                className={
                                    styles['member-card-container__team-label']
                                }
                                style={{
                                    cursor: !isEdit ? 'pointer' : 'default'
                                }}
                                onClick={
                                    !isEdit
                                        ? () => setShowTeamsModal(true)
                                        : undefined
                                }
                            >
                                {isEdit &&
                                    teams.map((team, index) => (
                                        <span
                                            key={index}
                                            className={'text-label'}
                                        >
                                            {team}
                                        </span>
                                    ))}
                                {!isEdit && '5'}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);
