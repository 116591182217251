import Joi from 'joi';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import icoDelete from '../../../assets/images/ico-delete.svg';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { reportUserListMock } from './mock';
import { ReportService, ProfileService } from '../../../temp/test';
import { verifyComponent } from '../../../utils/component';
import { DeleteButton } from '../../buttons/delete-button/delete-button';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { Modal } from '../../modals/modal/modal';
import { SelectInput } from '../../inputs/select-input/select-input';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays logins in a table
 */

export const ReportUserList = React.memo(({ reportId }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(reportUserListMock, modal, '');
    const {
        value: username,
        addOption: usernameAddOption,
        toggleDropdown: usernameToggleDropdown,
        selectOption: usernameSelectOption,
        keyPress: usernameKeyPress,
        keyDown: usernameKeyDown,
        setValue: usernameSetValue,
        reset: usernameReset,
        setError: usernameSetError
    } = useSelectInput({
        name: 'username',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });
    const schema = Joi.object({
        username: Joi.string().required()
    });
    const getUsers = useCallback(async () => {
        const dataClone = _.cloneDeep(reportUserListMock);
        try {
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(true);
            }
            const reportDataBack =
                await ReportService.getApiBackofficeReporting1({
                    reportId: reportId
                });
            reportDataBack.users.map((el) => {
                const newEl = {
                    id: el.uuid ? el.uuid : '',
                    empNo: el.emp_no ? el.emp_no : '',
                    name: el.name ? el.name : '',
                    username: el.username ? el.username : '',
                    email: el.email ? el.email : '',
                    department: el.department ? el.department : '',
                    phone: el.mobile_phone ? el.mobile_phone : '',
                    action: {
                        label: t(`columns:action`),
                        id: el.uuid,
                        open: false,
                        options: [
                            {
                                name: 'removeUser',
                                label: t('settings:removeUser'),
                                icon: icoDelete
                            }
                        ],
                        reference: React.createRef()
                    }
                };
                dataClone.data.push(newEl);
                return el;
            });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            verifyComponent(dataClone);
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue, t, reportId]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false);
            setModalType('');
            setSelectedUserId('');
        }
    };

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            try {
                if (id) {
                    if (isMounted.current) setSelectedUserId(id);
                }
                if (isMounted.current) {
                    toggleComponentSettings(false);
                    toggleModal(true);
                    setModalType(option);
                }
                if (option === 'addUser') {
                    if (isMounted.current) {
                        usernameReset();
                    }
                    const userBackData =
                        await ProfileService.getApiProfileListAccounts();
                    const reportDataBack =
                        await ReportService.getApiBackofficeReporting1({
                            reportId: reportId
                        });
                    userBackData.map((el) => {
                        if (
                            _.findIndex(
                                reportDataBack.users,
                                (el2) => el.uuid === el2.uuid
                            ) === -1
                        )
                            usernameAddOption(el.name, el.uuid);
                        return el;
                    });
                }
            } catch (err) {
                errorHandler(err);
            }
        },
        [
            isMounted,
            reportId,
            toggleComponentSettings,
            toggleModal,
            usernameAddOption,
            usernameReset
        ]
    );

    const handleAddUser = async () => {
        try {
            const result = schema.validate(
                {
                    username: username.selectedOption
                },
                { abortEarly: false }
            );
            if (isMounted.current) {
                usernameSetError();
            }
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'userName') {
                        if (isMounted.current) usernameSetError(el.type);
                    }
                    return null;
                });
                return;
            }
            await ReportService.postApiBackofficeReportingUsers({
                reportId: reportId,
                requestBody: [username.selectedOptionAux]
            });
            if (isMounted.current) {
                toggleModal(false);
                setLoading(false);
            }
            getUsers();
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleRemoveUser = async () => {
        try {
            const reportDataBack =
                await ReportService.getApiBackofficeReporting1({
                    reportId: reportId
                });
            const userIds = [];
            reportDataBack.users.map((el) => {
                if (el.uuid !== selectedUserId) {
                    userIds.push(el.uuid);
                }
                return;
            });
            await ReportService.putApiBackofficeReportingUsers({
                reportId: reportId,
                requestBody: userIds
            });
            if (isMounted.current) {
                toggleModal(false);
                setLoading(false);
            }
            getUsers();
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <ContentContainer
            attr={data.attr}
            hidePinIcon
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onCloseSettings={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <React.Fragment>
                    {modal && modalType === 'addUser' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('components:reportUserList.addUser')}
                                    </span>
                                </div>
                            </div>

                            <div
                                className="row mx-0 "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <SelectInput
                                        onChangeInput={usernameSetValue}
                                        onSelectInput={usernameSelectOption}
                                        onKeyPress={usernameKeyPress}
                                        onKeyDown={usernameKeyDown}
                                        onToggleDropdown={
                                            usernameToggleDropdown
                                        }
                                        {...username}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <SecondaryButton
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-5 px-0">
                                    <PrimaryButton
                                        text={t('modals:save')}
                                        onClick={handleAddUser}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    {modal && modalType === 'removeUser' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('pages:managerProfile.areyousure')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <SecondaryButton
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-5 px-0">
                                    <PrimaryButton
                                        text={t('modals:remove')}
                                        onClick={handleRemoveUser}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    <Table
                        actions={data.actions}
                        // onClickActionIcon={onClickActionIcon}
                        // onClickActionOption={onClickActionOption}
                        // onCloseActions={onCloseActions}
                        attr={data.attr}
                        type={data.type}
                        titleCounter={data.titleCounter}
                        titleText={t(`components:${data.attr}.title`)}
                        placeholderText={t(`components:${data.attr}.nodata`)}
                        columns={data.columns}
                        data={data.sortedData}
                        sortColumn={data.sortColumn}
                        onSort={dataSort}
                        currentPage={data.currentPage}
                        pageSize={data.pageSize}
                        onPageChange={dataPageChange}
                        onRowClick={undefined}
                        onRowSelect={undefined}
                        onSelectAll={dataSelectAll}
                        onUnselectAll={dataUnselectAll}
                        paginationDisabled={data.paginationDisabled}
                        disabled={data.disabled}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                    ></Table>
                </React.Fragment>
            )}
        </ContentContainer>
    );
});

ReportUserList.propTypes = {};
