import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const PriceTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.id !== '') {
        return (
            <TooltipContainer color={props.payload[props.id].fill}>
                <TooltipHeaderRow
                    title={t('tooltips:header.category')}
                    value={props.payload[props.id].name}
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.price')}
                    value={props.payload[props.id].value}
                    color={props.payload[props.id].fill}
                />
            </TooltipContainer>
        )
    } else return null
})
