import React, { useMemo, useState } from 'react'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { GoalInformationCard } from '../../../components/dashboard-components/goal-information-card/goal-information-card'
import { GoalAndSubGoalProgress } from '../../../components/dashboard-components/goal-and-sub-goal-progress/goal-and-sub-goal-progress'
import { GoalMilestonesCard } from '../../../components/dashboard-components/goal-milestones-card/goal-milestones-card'
import { GoalPerformanceOverTime } from '../../../components/dashboard-components/goal-performance-over-time'
import { SubGoalPartition } from '../../../components/dashboard-components/sub-goal-partition/sub-goal-partition'
import { NavigationMenu, SalesHomologue } from '../../../components'
import { GoalContribution } from '../../../components/dashboard-components/goal-contribution/goal-contribution'
import { GoalSummaryCard } from '../../../components/dashboard-components/goal-summary-card/goal-summary-card'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BreadCrumbs } from '../../../components/formatting/breadcrumbs/BreadCrumbs'
import { SubGoalOverview } from '../GoalManagement/components/SubGoalOverview/SubGoalOverview'
import { GoalCard } from '../GoalManagement/components/GoalCard/GoalCard'
import icoEdit from '../../../assets/images/ico-edit.svg'
import icoAddMilestones from '../../../assets/images/icoAddMilestones.svg'
import icoSubGoals from '../../../assets/images/icoSubGoals.svg'
import icoHistoricalApproval from '../../../assets/images/icoHistoricalApproval.svg'
import icoRemove from '../../../assets/images/ico-delete.svg'
import { GoalProgress } from '../../../components/dashboard-components/goal-progress/goal-progress'
import { GoalCardExtended } from '../GoalManagement/components/GoalCardExtended/GoalCardExtended'

const goalInformation = {
    id: 1,
    title: 'Goal 1',
    owner: 'Owner 1',
    status: 'behindSchedule',
    businessDimensions: ['Lisboa', 'Porto'],
    steps: [
        {
            index: 1,
            text: 'Step 1',
            isMissing: false,
        },
        {
            index: 2,
            text: 'Step 2',
            isMissing: true,
        },
    ],
    timePeriod: 'FY 2024',
    targetValue: 100,
    currentValue: 30,
    milestones: [
        {
            date: '2024-01-01',
            targetValue: 100,
            reachedTarget: false,
        },
    ],
    subGoalsCount: 4,
    action: {
        id: 1,
        open: false,
        options: [
            {
                name: 'edit',
                icon: icoEdit,
            },
            {
                name: 'addMilestones',
                icon: icoAddMilestones,
            },
            {
                name: 'subGoals',
                icon: icoSubGoals,
            },
            {
                name: 'historicalApproval',
                icon: icoHistoricalApproval,
            },
            {
                name: 'remove',
                icon: icoRemove,
                isRed: true,
            },
        ],
        reference: React.createRef(),
    },
}

export const GoalDetails = React.memo(() => {
    const { t } = useCustomTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [selectedOption, setSelectedOption] = useState(location.pathname.includes(`details`) ? 'details' : 'subGoals')
    const options = useMemo(() => {
        return [
            {
                label: 'details',
                url: `/goal-management/${params.id}/details`,
            },
            {
                label: 'subGoals',
                url: `/goal-management/${params.id}/sub-goals`,
            },
        ]
    }, [params.id])

    const customSelectOption = (url, label) => {
        navigate(url)
        setSelectedOption(label)
    }

    const menuOptions = useMemo(() => {
        return [...options.map((el) => el.label)]
    }, [options])

    const crumbsMemo = useMemo(() => {
        return [
            {
                url: '/goal-management/overview',
                label: t('pages:goalManagement.title'),
            },
            {
                url: `/goal-management/${params.id}/details`,
                label: params.id || 'Goal 1',
                isTitle: true,
            },
        ]
    }, [t, params])

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-100" style={{ padding: '0 2rem 2rem 2rem' }}>
                <div className={`row mx-0 w-100 h-100`}>
                    <div className="col-12 px-0 h-100 d-flex flex-column">
                        <div className="row w-100 dashboard-header-row ">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <BreadCrumbs crumbs={crumbsMemo} />
                            </div>
                            <span className="me-4">{params.id || t('pages:goalManagement.title')}</span>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <NavigationMenu
                                    isPageMenu
                                    options={menuOptions}
                                    selectedOption={selectedOption}
                                    onChangeOption={(option) => customSelectOption(options.find((el) => el.label === option).url, option)}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1" style={{ minHeight: 0 }}>
                            <div className="col px-0">
                                {selectedOption === 'details' && (
                                    <>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '50rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div
                                                className="col-auto h-100 px-0"
                                                style={{
                                                    width: 'calc(100%)',
                                                }}
                                            >
                                                <GoalCardExtended isParentGoal hideFlag {...goalInformation} />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '40rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div
                                                className="col-auto h-100 px-0"
                                                style={{
                                                    width: 'calc(33.3% - 1rem)',
                                                }}
                                            >
                                                <SubGoalPartition />
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    width: '2rem',
                                                }}
                                            ></div>
                                            <div
                                                className="col-auto h-100 px-0"
                                                style={{
                                                    width: 'calc(66.6% - 1rem)',
                                                }}
                                            >
                                                <GoalAndSubGoalProgress />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '40rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col h-100 px-0">
                                                <GoalPerformanceOverTime />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '40rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div
                                                className="col-auto h-100 px-0"
                                                style={{
                                                    width: 'calc(33.3% - 1rem)',
                                                }}
                                            >
                                                <GoalMilestonesCard />
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{
                                                    width: '2rem',
                                                }}
                                            ></div>
                                            <div
                                                className="col-auto h-100 px-0"
                                                style={{
                                                    width: 'calc(66.6% - 1rem)',
                                                }}
                                            >
                                                <SalesHomologue />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '35rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div
                                                className="col-auto h-100 px-0"
                                                style={{
                                                    width: 'calc(100%)',
                                                }}
                                            >
                                                <GoalContribution />
                                            </div>
                                        </div>

                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                height: '50rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="col px-0 h-100">
                                                <GoalSummaryCard />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {selectedOption === 'subGoals' && <SubGoalOverview />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
