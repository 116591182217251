import { createContext, useContext } from 'react';

const TimeSeriesManagerContext = createContext({
    data: undefined,
    xAxis: undefined,
    xAxisFormat: 'day',
    yAxis: undefined,
    onAdd: () => {},
    onRemove: () => {}
});

export function TimeSeriesManagerProvider({ value, children }) {
    return (
        <TimeSeriesManagerContext.Provider value={value}>
            {children}
        </TimeSeriesManagerContext.Provider>
    );
}

export function useTimeSeriesManager() {
    return useContext(TimeSeriesManagerContext);
}
