import React from 'react'
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg'
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg'

export const teamSalesComparisonMock = {
    loading: true,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'teamSalesComparisonChart',
    isForecast: false,
    titleText: '',
    chartOptions: [
        { name: 'avgT', label: '' },
        { name: 'avgP', label: '' },
    ],
    lineAttr: [
        {
            dataKey: 'team1ForecastIntervalValue',
            color: '#2cd9c5',
            dashArray: '3 3',
            strokeWidth: 2,
            hideDot: true,
            fillOpacity: 0.6,
        },
        {
            dataKey: 'team1ForecastValue',
            color: '#2cd9c5',
            isReverseColor: true,
            isGradient: true,
            strokeWidth: 2,
            fillOpacity: 1,
        },
        {
            dataKey: 'team1',
            color: '#2cd9c5',
            strokeWidth: 2,
            isTransparent: true,
        },
        {
            dataKey: 'team2',
            color: '#d96256',
            strokeWidth: 2,
            isTransparent: true,
        },
        {
            dataKey: 'team3',
            color: '#7fd94d',
            strokeWidth: 2,
            isTransparent: true,
        },
    ],
    categoryAxis: 'month',
    data: [],
    dateGrouper: '',
    gradient: 0.2,
    lineType: 'monotone',
    maxValue: 25,
    minValue: 2,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'teamSalesComparisonChart',
    unitsOrValues: 'units',
    margin: {
        top: 20,
        right: 40,
        left: 0,
        bottom: 0,
    },
    interactionDisabled: true,
    suffix: 'teamSalesComparisonChartAuxbar',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: [
                {
                    name: 'showWeather',
                    label: '',
                    icon: 'default',
                    iconDefault: icoCheckboxEmpty,
                    iconSelected: icoCheckboxSelected,
                },
            ],
        },
        options: {
            label: '',
            options: [
                //
            ],
        },
    },
}
