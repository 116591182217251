import icoView from '../../../assets/images/ico-view.svg'

export const clientGeoMock = {
    hoverStyle: '',
    desc: '',
    originalRisk1Data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    lat: 38.801056,
                    lon: -9.136423,
                    regionId: '1',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: false,
                    client_id: 1,
                    cluster_id: 5,
                    lost: false,
                    name: 'Pastel hotel pasteleiro esplanada café mar.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.136423, 38.801056],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.75353,
                    lon: -9.291434,
                    regionId: '2',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: true,
                    client_id: 2,
                    cluster_id: 1,
                    lost: false,
                    name: 'Central pasteleiro pastel pasteleiro pastel.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.291434, 38.75353],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.803209,
                    lon: -9.216953,
                    regionId: '5',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: false,
                    client_id: 5,
                    cluster_id: 3,
                    lost: false,
                    name: 'Hotel esplanada bar mar sol nata pasteleiro.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.216953, 38.803209],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.788453,
                    lon: -9.224793,
                    regionId: '6',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: true,
                    client_id: 6,
                    cluster_id: 3,
                    lost: false,
                    name: 'Hotel sol nata esplanada por.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.224793, 38.788453],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.760912,
                    lon: -9.280187,
                    regionId: '9',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: true,
                    client_id: 9,
                    cluster_id: 3,
                    lost: false,
                    name: 'Pastel por do café.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.280187, 38.760912],
                },
            },
        ],
    },
    originalRisk2Data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    lat: 38.739544,
                    lon: -9.301054,
                    regionId: '4',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk2',
                    riskStatusValue: 2,
                    riskStatusColor: '#dbdb6f',
                    innerColor: '#dbdb6f',
                    active: false,
                    client_id: 4,
                    cluster_id: 4,
                    lost: false,
                    name: 'Sol pasteleiro café central por pastel central pastel.',
                    risk: 2,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.301054, 38.739544],
                },
            },
        ],
    },
    originalRisk3Data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    lat: 38.788768,
                    lon: -9.231754,
                    regionId: '0',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: false,
                    client_id: 0,
                    cluster_id: 5,
                    lost: false,
                    name: 'Mar central pasteleiro central esplanada bar.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.231754, 38.788768],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.776345,
                    lon: -9.294161,
                    regionId: '3',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: true,
                    client_id: 3,
                    cluster_id: 4,
                    lost: false,
                    name: 'Nata pasteleiro pastel sol por.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.294161, 38.776345],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.721889,
                    lon: -9.294058,
                    regionId: '7',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: true,
                    client_id: 7,
                    cluster_id: 5,
                    lost: false,
                    name: 'Esplanada pastel central central.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.294058, 38.721889],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.76318,
                    lon: -9.137403,
                    regionId: '8',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: false,
                    client_id: 8,
                    cluster_id: 3,
                    lost: false,
                    name: 'Pasteleiro café sol pastel café pasteleiro nata.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.137403, 38.76318],
                },
            },
        ],
    },
    originalRisk4Data: {
        type: 'FeatureCollection',
        features: [],
    },
    originalCompetitionData: {
        type: 'FeatureCollection',
        features: [],
    },
    risk1Data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    lat: 38.801056,
                    lon: -9.136423,
                    regionId: '1',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: false,
                    client_id: 1,
                    cluster_id: 5,
                    lost: false,
                    name: 'Pastel hotel pasteleiro esplanada café mar.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.136423, 38.801056],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.75353,
                    lon: -9.291434,
                    regionId: '2',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: true,
                    client_id: 2,
                    cluster_id: 1,
                    lost: false,
                    name: 'Central pasteleiro pastel pasteleiro pastel.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.291434, 38.75353],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.803209,
                    lon: -9.216953,
                    regionId: '5',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: false,
                    client_id: 5,
                    cluster_id: 3,
                    lost: false,
                    name: 'Hotel esplanada bar mar sol nata pasteleiro.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.216953, 38.803209],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.788453,
                    lon: -9.224793,
                    regionId: '6',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: true,
                    client_id: 6,
                    cluster_id: 3,
                    lost: false,
                    name: 'Hotel sol nata esplanada por.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.224793, 38.788453],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.760912,
                    lon: -9.280187,
                    regionId: '9',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk1',
                    riskStatusValue: 1,
                    riskStatusColor: '#6fdb7d',
                    innerColor: '#6fdb7d',
                    active: true,
                    client_id: 9,
                    cluster_id: 3,
                    lost: false,
                    name: 'Pastel por do café.',
                    risk: 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.280187, 38.760912],
                },
            },
        ],
    },
    risk2Data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    lat: 38.739544,
                    lon: -9.301054,
                    regionId: '4',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk2',
                    riskStatusValue: 2,
                    riskStatusColor: '#dbdb6f',
                    innerColor: '#dbdb6f',
                    active: false,
                    client_id: 4,
                    cluster_id: 4,
                    lost: false,
                    name: 'Sol pasteleiro café central por pastel central pastel.',
                    risk: 2,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.301054, 38.739544],
                },
            },
        ],
    },
    risk3Data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    lat: 38.788768,
                    lon: -9.231754,
                    regionId: '0',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: false,
                    client_id: 0,
                    cluster_id: 5,
                    lost: false,
                    name: 'Mar central pasteleiro central esplanada bar.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.231754, 38.788768],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.776345,
                    lon: -9.294161,
                    regionId: '3',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: true,
                    client_id: 3,
                    cluster_id: 4,
                    lost: false,
                    name: 'Nata pasteleiro pastel sol por.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.294161, 38.776345],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.721889,
                    lon: -9.294058,
                    regionId: '7',
                    defaultStatus: 'activeClients',
                    defaultStatusValue: 'Cliente Ativo',
                    defaultStatusColor: '#ffb200',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: true,
                    client_id: 7,
                    cluster_id: 5,
                    lost: false,
                    name: 'Esplanada pastel central central.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.294058, 38.721889],
                },
            },
            {
                type: 'Feature',
                properties: {
                    lat: 38.76318,
                    lon: -9.137403,
                    regionId: '8',
                    defaultStatus: 'inactiveClients',
                    defaultStatusValue: 'Inactive',
                    defaultStatusColor: '#a28c6a',
                    riskStatus: 'risk3',
                    riskStatusValue: 3,
                    riskStatusColor: '#ffbd77',
                    innerColor: '#ffbd77',
                    active: false,
                    client_id: 8,
                    cluster_id: 3,
                    lost: false,
                    name: 'Pasteleiro café sol pastel café pasteleiro nata.',
                    risk: 3,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.137403, 38.76318],
                },
            },
        ],
    },
    risk4Data: {
        type: 'FeatureCollection',
        features: [],
    },
    competitionData: {
        type: 'FeatureCollection',
        features: [],
    },
    data: [],
    titleText: '',
    legend: [
        {
            name: 'risk1',
            color: '#6fdb7d',
            translated: false,
        },
        {
            name: 'risk2',
            color: '#dbdb6f',
            translated: false,
        },
        {
            name: 'risk3',
            color: '#ffbd77',
            translated: false,
        },
        {
            name: 'risk4',
            color: '#ff6363',
            translated: false,
        },
        {
            name: 'lostClients',
            color: '#606060',
            translated: false,
        },
    ],
    type: 'normal',
    tooltipType: 'clientGeo',
    attr: 'clientGeoChart',
    update: 1,
    disabled: true,
    startingPosition: {
        lat: 38.769688599999995,
        lon: -9.240822000000001,
        zoom: 12,
    },
    minScale: 2,
    scale: 2,
    minZoom: 9,
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 38.736946,
        longitude: -9.142685,
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
    },
    interactiondisabled: true,
    showPopup: false,
    selectedEl: {},
    settings: {
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        reference: {
            current: null,
        },
        options: {
            label: 'OPTIONS',
            options: [
                {
                    name: 'clientGeoChartFilter',
                    icon: icoView,
                    label: 'Filter',
                    translated: false,
                },
            ],
        },
        viewOptions: {
            ratioOptions: {
                options: [
                    {
                        name: 'defaultStatus',
                        label: 'Status',
                        disabled: false,
                        blocked: false,
                        translated: false,
                    },
                    {
                        name: 'riskStatus',
                        label: 'Risk',
                        disabled: false,
                        blocked: false,
                        translated: false,
                    },
                ],
                selectedOption: 'riskStatus',
            },
            checkboxOptions: {
                options: [
                    {
                        name: 'risk1',
                        label: 'Risk 1',
                        color: '#6fdb7d',
                        disabled: false,
                        blocked: false,
                        translated: false,
                    },
                    {
                        name: 'risk2',
                        label: 'Risk 2',
                        color: '#dbdb6f',
                        disabled: false,
                        blocked: false,
                        translated: false,
                    },
                    {
                        name: 'risk3',
                        label: 'Risk 3',
                        color: '#ffbd77',
                        disabled: false,
                        blocked: false,
                        translated: false,
                    },
                    {
                        name: 'risk4',
                        label: 'Risk 4',
                        color: '#ff6363',
                        disabled: false,
                        blocked: false,
                        translated: false,
                    },
                ],
                selectedOptions: [
                    'activeClients',
                    'inactiveClients',
                    'risk1',
                    'risk2',
                    'risk3',
                    'risk4',
                    'risk5',
                ],
            },
        },
    },
    loading: true,
    ready: true,
}
