import React from 'react';
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg';
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg';

export const revenueSalesMock = {
    loading: true,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'revenueSalesChart',
    isForecast: false,
    titleText: '',
    chartOptions: [
        { name: 'avgT', label: '' },
        { name: 'avgP', label: '' }
    ],
    areaAttr: [
        {
            dataKey: 'revenue',
            color: '#2cd9c5',
            strokeWidth: 2,
            isGradient: true,
            fillOpacity: 0.3
        }
    ],
    categoryAxis: 'month',
    data: [
        {
            revenue: '11793683.2',
            time: '2022-02-01',
            day: '01 Fev 2022',
            month: 'Feb 2022',
            year: '2022'
        },
        {
            revenue: '12872866.0',
            time: '2022-03-01',
            day: '01 Mar 2022',
            month: 'Mar 2022',
            year: '2022'
        },
        {
            revenue: '12094173.5',
            time: '2022-04-01',
            day: '01 Abr 2022',
            month: 'Apr 2022',
            year: '2022'
        },
        {
            revenue: '14628413.7',
            time: '2022-05-01',
            day: '01 Maio 2022',
            month: 'May 2022',
            year: '2022'
        },
        {
            revenue: '14136018.5',
            time: '2022-06-01',
            day: '01 Jun 2022',
            month: 'Jun 2022',
            year: '2022'
        },
        {
            revenue: '14042790.8',
            time: '2022-07-01',
            day: '01 Jul 2022',
            month: 'Jul 2022',
            year: '2022'
        },
        {
            revenue: '14356603.7',
            time: '2022-08-01',
            day: '01 Ago 2022',
            month: 'Aug 2022',
            year: '2022'
        },
        {
            revenue: '14309239.8',
            time: '2022-09-01',
            day: '01 Set 2022',
            month: 'Sep 2022',
            year: '2022'
        },
        {
            revenue: '15610575.7',
            time: '2022-10-01',
            day: '01 Out 2022',
            month: 'Oct 2022',
            year: '2022'
        },
        {
            revenue: '15897955.2',
            time: '2022-11-01',
            day: '01 Nov 2022',
            month: 'Nov 2022',
            year: '2022'
        },
        {
            revenue: '15604644.4',
            time: '2022-12-01',
            day: '01 Dez 2022',
            month: 'Dec 2022',
            year: '2022'
        },
        {
            revenue: '15611322.1',
            time: '2023-01-01',
            day: '01 Jan 2023',
            month: 'Jan 2023',
            year: '2023'
        },
        {
            revenue: '15855914.2',
            time: '2023-02-01',
            day: '01 Fev 2023',
            month: 'Feb 2023',
            year: '2023'
        },
        {
            revenue: '17770306.7',
            time: '2023-03-01',
            day: '01 Mar 2023',
            month: 'Mar 2023',
            year: '2023'
        },
        {
            revenue: '15644668.1',
            time: '2023-04-01',
            day: '01 Abr 2023',
            month: 'Apr 2023',
            year: '2023'
        },
        {
            revenue: '18061481.4',
            time: '2023-05-01',
            day: '01 Maio 2023',
            month: 'May 2023',
            year: '2023'
        },
        {
            revenue: '17484477.3',
            time: '2023-06-01',
            day: '01 Jun 2023',
            month: 'Jun 2023',
            year: '2023'
        }
    ],
    dateGrouper: '',
    gradient: 0.2,
    lineType: 'monotone',
    maxValue: 20000000,
    minValue: 0,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'salesForecast-monthly',
    unitsOrValues: 'values',
    margin: {
        top: 20,
        right: 40,
        left: 0,
        bottom: 0
    },
    interactionDisabled: true,
    suffix: 'revenueSalesChartAuxbar',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: [
                {
                    name: 'showWeather',
                    label: '',
                    icon: 'default',
                    iconDefault: icoCheckboxEmpty,
                    iconSelected: icoCheckboxSelected
                }
            ]
        },
        options: {
            label: '',
            options: [
                //
            ]
        }
    }
};
