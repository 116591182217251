import React from 'react';
import { SentimentChannelAnalysisAudioFile } from './components/sentiment-channel-analysis-audio-file';
import { SentimentChannelAnalysisEmail } from './components/sentiment-channel-analysis-email';
import { SentimentChannelAnalysisMedia } from './components/sentiment-channel-analysis-media/sentiment-channel-analysis-media';
import { SentimentChannelAnalysisTextFile } from './components/sentiment-channel-analysis-text-file';

export const SentimentChannelAnalysisFactory = React.memo(
  ({ item }) => {
    if (item.type === 'texts') {
      return <SentimentChannelAnalysisTextFile {...item} />;
    } else if (item.type === 'calls') {
      return <SentimentChannelAnalysisAudioFile {...item} />;
    } else if (item.type === 'emails') {
      return <SentimentChannelAnalysisEmail {...item} />;
    } else if (item.type === 'media') {
      return <SentimentChannelAnalysisMedia {...item} />;
    } else return null;
  }
);
