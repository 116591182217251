import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useFilter } from '../../../providers/filter-context';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { dTableInfoCardMock } from './mock';
import { DTablesService } from '../../../temp/test';
import { formatDate } from '../../../utils/date';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { ContentContainer, Loader, Spinner } from '../../';
import styles from './dtable-info-card.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays information about a custom dynamic table column
 */

export const DTableInfo = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(dTableInfoCardMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(dTableInfoCardMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        if (type) dataClone.attr = type;
                        dataClone.title = config.info;
                        let replaceData;
                        if (config.info) {
                            replaceData =
                                await DTablesService.getApiBackofficeAdminDtablesColumns1(
                                    {
                                        columnName: config.info,
                                        ids:
                                            filter &&
                                            filter.vendorId.constructor ===
                                                String &&
                                            filter.vendorId &&
                                            config.filter.includes('vendorId')
                                                ? [filter.vendorId]
                                                : filter &&
                                                  filter.clientId
                                                      .constructor === String &&
                                                  filter.clientId &&
                                                  config.filter.includes(
                                                      'clientId'
                                                  )
                                                ? [filter.clientId]
                                                : filter &&
                                                  filter.productId
                                                      .constructor === String &&
                                                  filter.productId &&
                                                  config.filter.includes(
                                                      'productId'
                                                  )
                                                ? [filter.productId]
                                                : []
                                    }
                                );
                        }
                        if (replaceData['result_type'] === 'datetime') {
                            dataClone.headline = formatDate(
                                replaceData['result_value']
                            );
                        } else {
                            dataClone.headline = replaceData['result_value']
                                ? replaceData['result_value']
                                : '-';
                        }
                        if (
                            replaceData['result_type'] === 'float' ||
                            replaceData['result_type'] === 'int'
                        ) {
                            dataClone.complementaryInfo = 'avg';
                            dataClone.isRounded = true;
                        } else if (replaceData['result_type'] === 'datetime') {
                            dataClone.complementaryInfo = 'avg';
                        } else if (replaceData['result_type'] === 'text') {
                            dataClone.complementaryInfo = 'mostFrequent';
                            dataClone.isRounded = false;
                        }
                        dataClone.percentage = false;
                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    } else {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        if (type) dataClone.attr = type;
                        dataClone.title = config.info;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            type
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={data.title}
                    complementaryInfo={
                        data.complementaryInfo
                            ? `(${t(
                                  `components:dTableInfoCard.complementaryInfo.${data.complementaryInfo}`
                              )})`
                            : undefined
                    }
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <>
                            {data.headline && (
                                <span
                                    className={`${styles['dtable-info-card-container__text']}`}
                                >
                                    {data.headline || '-'}
                                </span>
                            )}
                        </>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

DTableInfo.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
