import React, { useState } from 'react'
import styles from './TwoFactor.module.scss'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import icoCheckboxEmpty from '../../../../../assets/images/ico-checkbox-empty.svg'
import icoCheckboxSelected from '../../../../../assets/images/ico-checkbox-selected.svg'
import { ToggleButton } from '../../../../../components'
import { useToggle } from '../../../../../hooks/useToggle'

export const TwoFactor = () => {
    const { t } = useCustomTranslation()
    const [twoFactorMethods, setTwoFactorMethods] = useState([
        {
            title: 'Email',
            description: 'Receive a code via email',
        },
        {
            title: 'SMS',
            description: 'Receive a code via SMS',
        },
    ])
    const [checkedMethods, setCheckedMethods] = useState([])
    const [twoFactorAuthentication, twoFactorAuthenticationToggle] =
        useToggle(false)

    const handleCheckMethod = (method) => {
        if (checkedMethods.includes(method)) {
            setCheckedMethods(checkedMethods.filter((m) => m !== method))
        } else {
            setCheckedMethods([...checkedMethods, method])
        }
    }

    return (
        <div className={styles['two-factor-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['two-factor-container__title']}`}
                    >
                        {t('pages:authenticationAndSecurity.2FA.title')}
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['two-factor-container__description']}`}
                    >
                        {t('pages:authenticationAndSecurity.2FA.description')}
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div
                        className={`${styles['two-factor-container__content']}`}
                    >
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <div className="col px-0">
                                        <span
                                            className={`${styles['two-factor-container__option-title']}`}
                                        >
                                            Two-Factor Authentication
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <span
                                            className={`${styles['two-factor-container__option-description']}`}
                                        >
                                            Texto explicando
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-0 d-flex align-items-end">
                                <ToggleButton
                                    bool={twoFactorAuthentication}
                                    onClick={twoFactorAuthenticationToggle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <div
                        className={`${styles['two-factor-container__content']}`}
                    >
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '1rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['two-factor-container__content__title']}`}
                                >
                                    Authentication methods
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={`${styles['two-factor-container__content__sub-title']}`}
                                >
                                    Texto explicando como as pessoas receberam o
                                    convite
                                </span>
                            </div>
                        </div>
                        {twoFactorMethods.map((method, index) => {
                            return (
                                <div
                                    key={index}
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <div
                                        className="col-auto px-0"
                                        style={{ marginRight: '1rem' }}
                                    >
                                        <img
                                            src={
                                                checkedMethods.includes(
                                                    method.title
                                                )
                                                    ? icoCheckboxSelected
                                                    : icoCheckboxEmpty
                                            }
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            alt="checkbox"
                                            onClick={() =>
                                                handleCheckMethod(method.title)
                                            }
                                        />
                                    </div>
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <span
                                                    className={`${styles['two-factor-container__option-title']}`}
                                                >
                                                    {method.title}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <span
                                                    className={`${styles['two-factor-container__option-description']}`}
                                                >
                                                    {method.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
