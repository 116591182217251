import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { goalHistoryListMock } from './mock';
import _ from 'lodash';
import { useTable } from '../../../hooks/useTable';
import { Table } from '../../tables/table/table';

/**
 * Lists all the users in a table
 */

export const GoalHistoryList = React.memo(({ isExample }) => {
    const {
        value: data,
        setValue,
        pageChange,
        sort,
        selectAll,
        unselectAll,
        setLoading,
        clickActionIcon
    } = useTable(goalHistoryListMock, undefined, '');

    useEffect(() => {
        const dataClone = _.cloneDeep(goalHistoryListMock);
        if (isExample) {
            dataClone.data = [
                {
                    goalName: 'Increase Sales',
                    goalStartDate: '2024-01-01',
                    goalEndDate: '2024-06-30',
                    goalTargetValue: 50000,
                    goalReachedValue: 45000,
                    goalSubGoalCount: 3,
                    goalAchieved: false
                },
                {
                    goalName: 'Customer Satisfaction',
                    goalStartDate: '2024-02-01',
                    goalEndDate: '2024-12-31',
                    goalTargetValue: 95,
                    goalReachedValue: 92,
                    goalSubGoalCount: 2,
                    goalAchieved: false
                }
            ];
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
        } else {
            //
        }
        setValue(dataClone);
    }, [isExample, setValue]);

    return (
        <Table
            {...data}
            loading={false}
            onPageChange={pageChange}
            onSort={sort}
            onSelectAll={selectAll}
            onUnselectAll={unselectAll}
        ></Table>
    );
});

GoalHistoryList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func
};
