import React, { useCallback, useState } from 'react'
import icoGoalManagement from '../../../../../assets/images/icoGoalManagement.svg'
import icoStatusWarning from '../../../../../assets/images/icoStatusWarning.svg'
import icoHighImpactGoal from '../../../../../assets/images/icoHighImpactGoal.svg'
import icoHighImpactGoalWhite from '../../../../../assets/images/icoHighImpactGoalWhite.svg'
import styles from './GoalCard.module.scss'
import { ActionsButtonNew } from '../../../../../components/buttons/actions-button-new/actions-button-new'
import { MilestoneTimeline } from '../../../../../components/goals/MilestoneTimeline/MilestoneTimeline'
import { GoalStatus } from '../../../../../components/text/GoalStatus/GoalStatus'
import { useNavigate } from 'react-router-dom'
import { AddMilestonesModal } from '../../../../../components/modals/add-milestones-modal/add-milestones-modal'
import { RemoveGoalModal } from '../../../../../components/modals/remove-goal-modal/remove-goal-modal'
import { EditGoalModal } from '../../../../../components/modals/edit-goal-modal/edit-goal-modal'
import { EditSubGoalsModal } from '../../../../../components/modals/edit-sub-goals-modal/edit-sub-goals-modal'
import { useToggle } from '../../../../../hooks/useToggle'
import { useAuxBar } from '../../../../../providers/aux-bar-context'
import { Scrollable } from '../../../../../components'

const statusTextColors = {
    draft: '#25ACBE',
    pendingApproval: '#A5A50D',
    approved: '#73AD76',
    completed: '#3170FE',
    inProgress: '#E2991B',
    notStarted: '#606060',
    onTrack: '#98d2c5',
    behindSchedule: '#e3a29e',
}

const statusBackgroundColors = {
    draft: '#c3f8fe',
    pendingApproval: '#F8F8004D',
    approved: '#7DD0814D',
    completed: '#3170FE4D',
    inProgress: '#F0BB5F4D',
    notStarted: '#D6D6D64D',
    onTrack: '#98d2c54d',
    behindSchedule: '#e3a29e4d',
}

const statusBorderColors = {
    draft: '#25ACBE',
    pendingApproval: '#E1E12C',
    approved: '#7DD081',
    completed: '#3170FE',
    inProgress: '#F0BB5F',
    notStarted: '#D6D6D6',
    onTrack: '#98d2c5',
    behindSchedule: '#e3a29e',
}

export const GoalCard = ({
    id,
    title,
    owner,
    status,
    steps,
    timePeriod,
    targetValue,
    currentValue,
    milestones,
    action,
    hideFlag,
    hideMilestones,
    subGoalsCount,
    onSelectGoal,
    isParentGoal,
    isSubGoal,
}) => {
    const navigate = useNavigate()
    const [selectedGoalId, setSelectedGoalId] = useState(null)
    const [editGoalModal, setEditGoalModal] = useToggle(false)
    const [removeGoalModal, setRemoveGoalModal] = useToggle(false)
    const { setSelectedAvailableOption } = useAuxBar()
    const [addMilestonesModal, setAddMilestonesModal] = useToggle(false)
    const [editSubGoalsModal, setEditSubGoalsModal] = useToggle(false)
    const [historicalApprovalModal, setHistoricalApprovalModal] = useState(false)
    const [statusWarningHovered, setStatusWarningHovered] = useState(false)
    const [dates] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    const [droppedDates, setDroppedDates] = useState([
        {
            date: '8/7',
            target: 100,
        },
    ]) // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    const [isHighImpact, setIsHighImpact] = useState(false)
    const handleOpenSubGoals = useCallback(() => {
        navigate(`/goal-management/${id}/sub-goal-overview`)
    }, [id, navigate])

    const handleClickOption = useCallback(
        (option, goalid) => {
            setSelectedGoalId(goalid)
            if (option === 'edit') {
                setEditGoalModal(true)
            } else if (option === 'remove') {
                setRemoveGoalModal(true)
            } else if (option === 'addMilestones') {
                setAddMilestonesModal(true)
            } else if (option === 'historicalApproval') {
                setSelectedAvailableOption('approvalHistory')
            } else if (option === 'subGoals') {
                setEditSubGoalsModal(true)
            }
        },
        [setAddMilestonesModal, setEditGoalModal, setRemoveGoalModal, setSelectedAvailableOption, setEditSubGoalsModal]
    )
    return (
        <>
            {addMilestonesModal && (
                <AddMilestonesModal
                    onCloseModal={() => {
                        setAddMilestonesModal(false)
                    }}
                    onEnd={() => {}}
                />
            )}
            {removeGoalModal && <RemoveGoalModal onCloseModal={setRemoveGoalModal} onEnd={() => {}} />}
            {editGoalModal && <EditGoalModal onCloseModal={setEditGoalModal} onEnd={() => {}} />}
            {editSubGoalsModal && <EditSubGoalsModal onCloseModal={setEditSubGoalsModal} onEnd={() => {}} />}
            <div
                className={isHighImpact ? `${styles['goal-card-container']} ${styles['high-impact']}` : `${styles['goal-card-container']}`}
                style={{
                    boxShadow: !onSelectGoal ? 'none' : undefined,
                    cursor: onSelectGoal ? 'pointer' : 'default',
                }}
                onClick={
                    onSelectGoal
                        ? () =>
                              onSelectGoal({
                                  id,
                                  title,
                                  owner,
                                  status,
                                  steps,
                                  timePeriod,
                                  targetValue,
                                  currentValue,
                                  milestones,
                                  action,
                                  hideFlag,
                                  hideMilestones,
                              })
                        : null
                }
            >
                <Scrollable addRightSpacing>
                    {title && (
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                <img
                                    src={icoGoalManagement}
                                    alt="goal management"
                                    className={`${styles['goal-card-container__title-icon']}`}
                                    style={{ marginRight: '1rem' }}
                                />
                                <span className={`${styles['goal-card-container__title-text']}`}>{title}</span>
                            </div>
                            <div className="col px-0"></div>
                            {!hideFlag && (
                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                    <img
                                        src={isHighImpact ? icoHighImpactGoal : icoHighImpactGoalWhite}
                                        alt="high impact goal"
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            if (isHighImpact) {
                                                setIsHighImpact(false)
                                            } else setIsHighImpact(true)
                                        }}
                                    />
                                </div>
                            )}
                            {action && (
                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                    <ActionsButtonNew isAlternative actions={action} onClickOption={(args) => handleClickOption(args, id)} />
                                </div>
                            )}
                        </div>
                    )}
                    {owner && status && (
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col-auto px-0">
                                <div className="d-flex flex-column">
                                    <span className={`${styles['goal-card-container__sub-title']}`}>Owner</span>
                                    <span className={`${styles['goal-card-container__sub-text']}`}>{owner}</span>
                                </div>
                            </div>
                            <div className="col px-0"></div>
                            <div className="col-auto px-0">
                                <div className="d-flex flex-column d-flex flex-columm align-items-end">
                                    <span className={`${styles['goal-card-container__sub-title']}`} style={{ marginBottom: '1rem' }}>
                                        Status
                                    </span>
                                    <GoalStatus text={status}>
                                        {steps && steps.length > 0 && (
                                            <div className={`${styles['goal-card-container__warning-icon']}`}>
                                                <img
                                                    src={icoStatusWarning}
                                                    alt="warning"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                    onMouseOver={() => setStatusWarningHovered(true)}
                                                    onMouseOut={() => setStatusWarningHovered(false)}
                                                />
                                                {statusWarningHovered && (
                                                    <div className={`${styles['goal-card-container__warning-tooltip']}`}>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom: '1rem',
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <span className={`${styles['goal-card-container__warning-tooltip__title']}`}>
                                                                    Information is missing in the following steps to publish this goal:
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {steps.map((step, index) => (
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom: '1rem',
                                                                }}
                                                                key={index}
                                                            >
                                                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                                                    <div className={`${styles['goal-card-container__warning-tooltip__step-number']}`}>
                                                                        {step.index}
                                                                    </div>
                                                                    <span className={`${styles['goal-card-container__warning-tooltip__step-text']}`}>
                                                                        {step.text}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </GoalStatus>
                                </div>
                            </div>
                        </div>
                    )}
                    {timePeriod && (
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col-auto px-0">
                                <div className="d-flex flex-column">
                                    <span className={`${styles['goal-card-container__sub-title']}`}>Time Period</span>
                                    <span className={`${styles['goal-card-container__sub-text']}`}>{timePeriod}</span>
                                </div>
                            </div>
                            <div className="col px-0"></div>
                            {subGoalsCount && (
                                <div className="col-auto px-0">
                                    <div className="d-flex flex-column align-items-end">
                                        <span className={`${styles['goal-card-container__sub-title']}`}>Sub-Goals</span>
                                        <span
                                            className={`${styles['goal-card-container__sub-text']}`}
                                            style={{
                                                color: 'rgba(6, 164, 231, 1)',
                                            }}
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     e.stopPropagation();
                                            //     handleOpenSubGoals();
                                            // }}
                                        >
                                            {subGoalsCount}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="row mx-0 w-100 flex-grow-1">
                        <div className="col px-0 d-flex flex-column justify-content-end">
                            {!isParentGoal && !isSubGoal && (
                                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <div className="d-flex flex-column">
                                            <span className={`${styles['goal-card-container__sub-title']}`}>Target</span>
                                            {targetValue && currentValue && (
                                                <>
                                                    <div className="row mx-0 w-100 d-flex justify-content-between">
                                                        <div className="col-auto px-0">
                                                            <span className={`${styles['goal-card-container__target-text']} ${styles['bold']}`}>
                                                                {currentValue} / {targetValue}
                                                            </span>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <span className={`${styles['goal-card-container__target-text']}`}>
                                                                {Math.round((currentValue / targetValue) * 100)}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <div className={`${styles['goal-card-container__target-bar']}`}>
                                                                <div
                                                                    className={`${styles['goal-card-container__target-bar-fill']}`}
                                                                    style={{
                                                                        background: `linear-gradient(
                                                                            to right,
                                                                            #2d4691 ${(currentValue / targetValue) * 100 * 0.3}%,
                                                                            rgba(18, 137, 219, 0.67) ${(currentValue / targetValue) * 100}%,
                                                                            transparent ${(currentValue / targetValue) * 100}%
                                                                        )`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!hideMilestones && (
                                <>
                                    <div className="row mx-0 w-100" style={{ marginBottom: '3rem' }}>
                                        <div className="col px-0">
                                            <div className="d-flex flex-column">
                                                <span className={`${styles['goal-card-container__sub-title']}`}>Milestones</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col-auto px-0 d-flex justify-content-center align-items-end">
                                            <MilestoneTimeline dates={dates} droppedDates={droppedDates} minified />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Scrollable>
            </div>
        </>
    )
}
