import { createContext, useContext } from 'react'
import useCursorTracking from '../hooks/useCursorTracking'

const CursorTrackingContext = createContext({
    position: { x: 0, y: 0 },
    reversedHorizontal: false,
    reversedVertical: false,
})

export function CursorTrackingProvider({ children }) {
    const { position, reversedHorizontal, reversedVertical } = useCursorTracking()
    return (
        <CursorTrackingContext.Provider
            value={{
                position,
                reversedHorizontal,
                reversedVertical,
            }}
        >
            {children}
        </CursorTrackingContext.Provider>
    )
}

export function useCursorTrackingProvider() {
    return useContext(CursorTrackingContext)
}
