import React, { useState } from 'react'
import { TeamsService } from '../../../temp/test'
import { errorHandler } from '../../../utils/api'
import { RemoveModal } from '../remove-modal/remove-modal'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const RemoveRoleModal = ({ roleId, onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation()
    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleDeleteRole = async () => {
        try {
            setLoading(true)
            //await TeamsService.deleteApiTeams({ ids: [roleId] });
            if (onEnd) onEnd()
            setLoading(false)
            setIsSuccess(true)
        } catch (err) {
            errorHandler(err)
        }
    }

    return (
        <RemoveModal
            title={t('modals:removeRole.title')}
            description={t('modals:removeRole.description')}
            successMessage={t('modals:removeRole.success')}
            isLoading={loading}
            isSuccess={isSuccess}
            onDelete={handleDeleteRole}
            onCloseModal={onCloseModal}
        />
    )
}
