import _ from 'lodash';
import React, { useEffect } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted.js';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent.js';
import { useFilter } from '../../../providers/filter-context.js';
import { goalPerformanceOverTimeMock } from './mock.jsx';
import { errorHandler } from '../../../utils/api.js';
import {
    ComponentWrapper,
    DynamicComposedChartContainer
} from '../../index.jsx';
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush.jsx';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation.js';
import { useTimeInterval } from '../../../providers/time-interval-context.js';
import { useSalesChannel } from '../../../providers/sales-channel-context.js';

/**
 * Displays the unit sales over time without forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer);

export const GoalPerformanceOverTime = React.memo(
    ({ component, isPreview, isExample, config, ...rest }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { timeInterval } = useTimeInterval();
        const { channels } = useSalesChannel();
        const { t } = useCustomTranslation();

        const {
            value: data,
            setLoading,
            setValue
        } = useSimpleComponent(goalPerformanceOverTimeMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(goalPerformanceOverTimeMock);
                try {
                    if (!isExample) {
                        setLoading(true);
                        dataClone.loading = false;
                        setValue(dataClone);
                    } else {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            timeInterval,
            isPreview,
            channels
        ]);

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ComponentWrapper
                    withTimeFilter
                    withChannelFilter
                    isForecast
                    component={component}
                    {...data}
                    {...rest}
                >
                    <Chart {...data} />
                </ComponentWrapper>
            </div>
        );
    }
);

GoalPerformanceOverTime.propTypes = {};
