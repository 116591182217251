import React, { useCallback, useEffect, useMemo, useState } from 'react'
import icoUnreadBlue from '../../../assets/images/icoUnreadBlue.svg'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useToggle } from '../../../hooks/useToggle'
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg'
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg'
import styles from './my-notifications.module.scss'
import { useNotificationsCheckboxInput } from './useNotificationsCheckbox'
import _ from 'lodash'
import { errorHandler } from '../../../utils/api'
import { NotificationsService } from '../../../temp/test'
import { formatDateTime, getFullMonthAndYear } from '../../../utils/date'
import { useNotifications } from '../../../providers/notifications-context'
import { LoaderForeground, Modal, Spinner } from '../../../components'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import icoMark from '../../../assets/images/icoMark.svg'

export const MyNotifications = React.memo(() => {
    const { t } = useCustomTranslation()
    const isMounted = useIsMounted()
    const [loading, toggleLoading] = useToggle(true)

    const {
        value: notificationsList,
        setValue: notificationsSetValue,
        clickCheckboxOption: notificationsListClickCheckboxOption,
        clickAllCheckboxOptions: notificationsClickAllCheckboxOptions,
        markAllSelectedAsRead: notificationsMarkAllSelectedAsRead,
    } = useNotificationsCheckboxInput({
        options: [],
        selectedOptions: [],
        limit: undefined,
    })

    function formatTimestamp(isoTimestamp) {
        // Create a date object from the ISO timestamp
        const date = new Date(isoTimestamp)

        // Array of day names to get the day of the week
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

        // Get day of the week, date, hours, and minutes
        const dayOfWeek = days[date.getDay()]
        const dayOfMonth = date.getDate()
        let hours = date.getHours()
        const minutes = date.getMinutes()

        // Format hours in 12-hour format and determine AM/PM
        const ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes

        // Return the formatted string
        return `${dayOfWeek}, ${dayOfMonth} - ${hours}:${minutesStr}${ampm}`
    }

    const getNotifications2 = useCallback(async () => {
        try {
            toggleLoading(true)
            const notificationsClone = {
                options: [],
                selectedOptions: [],
                limit: undefined,
            }

            const result = await NotificationsService.getApiNotifications({})
            result.map((el) => {
                const newEl = {
                    id: el.id,
                    title: 'Notification',
                    statusText: '',
                    success: el.success,
                    text: el.message,
                    date: formatDateTime(el.timestamp),
                    month: getFullMonthAndYear(el.timestamp, t),
                    timestamp: el.timestamp,
                    read: el.read,
                }
                notificationsClone.options.push(newEl)
                return el
            })

            if (isMounted.current) notificationsSetValue(notificationsClone)
            toggleLoading(false)
        } catch (err) {
            errorHandler(err)
        }
    }, [isMounted, notificationsSetValue, toggleLoading, t])

    useEffect(() => {
        getNotifications2()
    }, [getNotifications2])

    let newNotificationOptionsList = useMemo(() => {
        const data = _.cloneDeep(notificationsList.options)
        //sort by timestamp from most recent to oldest
        data.sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp)
        })
        return data
    }, [notificationsList.options])

    const handleMarkAsRead = async (id) => {
        if (id) {
            try {
                await NotificationsService.postApiNotificationsMarkAsRead({
                    requestBody: [id],
                })
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                await NotificationsService.postApiNotificationsMarkAsRead({
                    requestBody: notificationsList.selectedOptions,
                })
            } catch (err) {
                console.log(err)
            }
        }
        getNotifications2()
    }

    const handleClickNotification = async (el) => {
        if (!el.read) {
            try {
                await NotificationsService.postApiNotificationsMarkAsRead({
                    requestBody: [el.id],
                })
                getNotifications2()
            } catch (err) {
                errorHandler(err)
            }
        }
    }

    const dataByMonth = useMemo(() => {
        const data = newNotificationOptionsList.reduce((acc, el) => {
            if (!acc[el.month]) {
                acc[el.month] = []
            }
            acc[el.month].push(el)
            return acc
        }, {})
        return data
    }, [newNotificationOptionsList])

    // order dataByMonth by most recent month

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <>
                    <div className="w-100 h-100" style={{ padding: '0 2rem 2rem 2rem' }}>
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">{t('pages:notifications.title')}</div>
                                </div>
                            </div>
                        </div>
                        {newNotificationOptionsList.length === 0 && (
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <span className={`${styles['my-notifications-container__no-notifications-text']}`}>
                                        {'No notifications found.'}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className={`row mx-0 w-100 ${styles['my-notifications-container']}`}>
                            <div className="col px-0 h-100 ">
                                {newNotificationOptionsList.length > 0 && (
                                    <div
                                        className={
                                            newNotificationOptionsList && newNotificationOptionsList.length > 0
                                                ? `row w-100 mx-0 ${styles['my-notifications-container__notifications-container']}`
                                                : `row w-100 mx-0 ${styles['my-notifications-container__notifications-container']} ${styles['hide-line']}`
                                        }
                                    >
                                        <div className="col px-0 h-inherit justify-content-center align-items-center">
                                            {
                                                <>
                                                    <div
                                                        className={`row mx-0 w-100 ${styles['my-notifications-container__notification-option']} p-0`}
                                                        style={{
                                                            height: '3rem',
                                                            marginBottom: '2rem',
                                                        }}
                                                    >
                                                        <div className={`${styles['my-notifications-container__notification-option__content']} px-0`}>
                                                            <div
                                                                onClick={() => notificationsClickAllCheckboxOptions()}
                                                                className="w-100 d-flex"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <div className="col-auto px-0 me-3 d-flex justify-content-center align-items-center">
                                                                    <img
                                                                        src={
                                                                            _.some(newNotificationOptionsList, (el2) => {
                                                                                return notificationsList.selectedOptions.includes(el2.id)
                                                                            })
                                                                                ? icoCheckboxSelected
                                                                                : icoCheckboxEmpty
                                                                        }
                                                                        alt="ico-checkbox-selected"
                                                                        className={`${styles['my-notifications-container__notification-option__icon-alt']}`}
                                                                    />
                                                                </div>
                                                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                                                    <span
                                                                        className={`${styles['my-notifications-container__notification-option__title']}`}
                                                                    >
                                                                        {'Select All'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col px-0"></div>
                                                            {notificationsList.selectedOptions.length > 0 && (
                                                                <>
                                                                    {notificationsList.options.filter((el) => {
                                                                        return !el.read && notificationsList.selectedOptions.includes(el.id)
                                                                    }).length > 0 && (
                                                                        <div className="col-auto px-0 d-flex">
                                                                            <div
                                                                                className={`${styles['my-notifications-container__notification-option__mark']}`}
                                                                                onClick={() => handleMarkAsRead()}
                                                                            >
                                                                                <img
                                                                                    src={icoMark}
                                                                                    alt="ico-mark"
                                                                                    style={{
                                                                                        width: '2rem',
                                                                                        height: '2rem',
                                                                                        marginRight: '0.5rem',
                                                                                        marginTop: '0.5rem',
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    className={`${styles['my-notifications-container__notification-option__mark-text']}`}
                                                                                >
                                                                                    {t('notifications:markAsRead')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        className="col-auto px-0"
                                                                        style={{
                                                                            width: '2rem',
                                                                        }}
                                                                    ></div>
                                                                    {notificationsList.options.filter((el) => {
                                                                        return el.read && notificationsList.selectedOptions.includes(el.id)
                                                                    }).length > 0 && (
                                                                        <div className="col-auto px-0 d-flex">
                                                                            <div
                                                                                className={`${styles['my-notifications-container__notification-option__mark']}`}
                                                                                onClick={(e) => handleMarkAsRead()}
                                                                            >
                                                                                <img
                                                                                    src={icoMark}
                                                                                    alt="ico-mark"
                                                                                    style={{
                                                                                        width: '2rem',
                                                                                        height: '2rem',
                                                                                        marginRight: '0.5rem',
                                                                                        marginTop: '0.5rem',
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    className={`${styles['my-notifications-container__notification-option__mark-text']}`}
                                                                                >
                                                                                    {t('notifications:markAsUnread')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {dataByMonth && Object.keys(dataByMonth).length > 0 && (
                                                        <>
                                                            {Object.entries(dataByMonth).map((el, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={`row w-100 mx-0 p-0`}
                                                                        // onClick={(
                                                                        //     e
                                                                        // ) => {
                                                                        //     notificationsListClickCheckboxOption(
                                                                        //         el.id
                                                                        //     );
                                                                        //     e.stopPropagation();
                                                                        // }}
                                                                    >
                                                                        <div className="col px-0">
                                                                            <div
                                                                                className="row mx-0 w-100"
                                                                                style={{
                                                                                    marginBottom: '1rem',
                                                                                }}
                                                                            >
                                                                                <div className="col px-0">
                                                                                    <span
                                                                                        className={`${styles['my-notifications-container__notification-option__month']}`}
                                                                                    >
                                                                                        {el[0]}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mx-0 w-100">
                                                                                <div className="col px-0">
                                                                                    {el[1].map((el2, index2) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={index2}
                                                                                                className={`row w-100 mx-0 ${styles['my-notifications-container__notification-option']} p-0`}
                                                                                                style={{
                                                                                                    marginBottom: '1rem',
                                                                                                }}
                                                                                            >
                                                                                                <div className="col-auto px-0">
                                                                                                    <img
                                                                                                        src={
                                                                                                            notificationsList.selectedOptions.includes(
                                                                                                                el2.id
                                                                                                            )
                                                                                                                ? icoCheckboxSelected
                                                                                                                : icoCheckboxEmpty
                                                                                                        }
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                        }}
                                                                                                        onClick={(e) => {
                                                                                                            notificationsListClickCheckboxOption(
                                                                                                                el2.id
                                                                                                            )
                                                                                                            e.stopPropagation()
                                                                                                        }}
                                                                                                        alt="ico-checkbox-empty"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-auto"
                                                                                                    style={{
                                                                                                        padding: '0 1rem',
                                                                                                    }}
                                                                                                >
                                                                                                    {!el2.read && (
                                                                                                        <img
                                                                                                            src={icoUnreadBlue}
                                                                                                            alt="ico-unread-blue"
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className="col px-0">
                                                                                                    <div className="row mx-0 w-100">
                                                                                                        <div className="col px-0">
                                                                                                            <span
                                                                                                                className={`${styles['my-notifications-container__notification-option__title']}`}
                                                                                                            >
                                                                                                                {el2.title}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row mx-0 w-100">
                                                                                                        <div className="col px-0">
                                                                                                            <span
                                                                                                                className={`${styles['my-notifications-container__notification-option__desc']}`}
                                                                                                            >
                                                                                                                {el2.text}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-auto px-0"
                                                                                                    style={{
                                                                                                        width: '2rem',
                                                                                                    }}
                                                                                                ></div>
                                                                                                <div className="col-auto px-0">
                                                                                                    <span
                                                                                                        className={`${styles['my-notifications-container__notification-option__date']}`}
                                                                                                    >
                                                                                                        {formatTimestamp(el2.timestamp)}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-auto px-0"
                                                                                                    style={{
                                                                                                        width: '2rem',
                                                                                                    }}
                                                                                                ></div>
                                                                                                <div
                                                                                                    className="col-auto px-0"
                                                                                                    onClick={() => handleClickNotification(el2)}
                                                                                                >
                                                                                                    <div
                                                                                                        className={
                                                                                                            styles[
                                                                                                                'my-notifications-container__notification-option__mark'
                                                                                                            ]
                                                                                                        }
                                                                                                        onClick={() => handleMarkAsRead(el2.id)}
                                                                                                    >
                                                                                                        <img
                                                                                                            src={icoMark}
                                                                                                            alt="ico-mark"
                                                                                                            style={{
                                                                                                                width: '2rem',
                                                                                                                height: '2rem',
                                                                                                                marginRight: '1rem',
                                                                                                                marginTop: '0.5rem',
                                                                                                            }}
                                                                                                        />
                                                                                                        {!el2.read && (
                                                                                                            <div
                                                                                                                className={`${styles['my-notifications-container__notification-option__mark-text']}`}
                                                                                                            >
                                                                                                                {t('notifications:markAsRead')}
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {el2.read && (
                                                                                                            <div
                                                                                                                className={`${styles['my-notifications-container__notification-option__mark-text']}`}
                                                                                                            >
                                                                                                                {t('notifications:markAsUnread')}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            {index !== Object.keys(dataByMonth).length - 1 && (
                                                                                <div
                                                                                    className={`row mx-0 ${styles['my-notifications-container__notification-option__separator']}`}
                                                                                ></div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
})

MyNotifications.propTypes = {}

export default MyNotifications
