import _ from 'lodash'
import React, { useCallback } from 'react'
import { ComponentWrapper, DynamicBarChartContainer } from '../../'
import { useSub } from '../../../hooks/usePubSub'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { errorHandler } from '../../../utils/api'
import { salesHomologueMock } from './mock'
import { getFilterParam } from '../../../utils/params'
import { getMonthFromDateFormat, getTimeFromDateFormat, getYearFromDateFormat } from '../../../utils/date'
import { useFilter } from '../../../providers/filter-context'
import { SalesService } from '../../../temp/test'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

const Chart = withCustomBrush(DynamicBarChartContainer)

/**
 * Displays comparison of sales between products according to the selected category
 */

export const SalesHomologue = React.memo(({ config, isExample, ...rest }) => {
    const { filter } = useFilter()
    const { t } = useCustomTranslation()
    const getInformation = useCallback(async () => {
        const dataClone = _.cloneDeep(salesHomologueMock)
        try {
            const { last_year, previous_year } = await SalesService.getApiSalesHomologue({
                productId: getFilterParam(filter, config, ['productId']),
                teamId: getFilterParam(filter, config, ['teamId']),
                unitsOrBilling: 'units',
            })
            dataClone.data = Object.entries(last_year).map((el) => ({
                month: getMonthFromDateFormat(el[0], t).split(' ')[0],
                year: getYearFromDateFormat(el[0]),
                previousYear: getYearFromDateFormat(el[0]) - 1,
                lastYearSales: el[1],
                previousYearSales: 0,
                time: getTimeFromDateFormat(el[0]),
            }))
            dataClone.legend = undefined

            Object.entries(previous_year).forEach(([date, value]) => {
                const foundMonth = dataClone.data.find((el) => el.month.split(' ')[0] === getMonthFromDateFormat(date, t).split(' ')[0])

                if (foundMonth) {
                    foundMonth.previousYearSales = value
                } else {
                    dataClone.data.push({
                        month: getMonthFromDateFormat(date, t).split(' ')[0],
                        year: getYearFromDateFormat(date),
                        previousYear: getYearFromDateFormat(date) - 1,
                        previousYearSales: value,
                        lastYearSales: 0,
                    })
                }
            })
            return dataClone
        } catch (err) {
            errorHandler(err)
            return dataClone
        }
    }, [config, filter, t])

    const { value: data, handleGetInformation } = useSimpleComponent(salesHomologueMock, getInformation, isExample)

    useSub(['team', 'product'], () => {
        handleGetInformation()
    })

    return (
        <ComponentWrapper {...data} {...rest}>
            <Chart {...data} hideLabels />
        </ComponentWrapper>
    )
})

SalesHomologue.propTypes = {}
