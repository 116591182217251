export const productMarketShareForecastMock = {
    loading: false,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: false,
    attr: 'productMarketShareUnitsForecastChart',
    isForecast: false,
    titleText: '',
    chartOptions: [
        {
            name: 'avgT',
            label: 'Temperature',
        },
        {
            name: 'avgP',
            label: 'Precipitation',
        },
    ],
    areaAttr: [
        {
            dataKey: 'unitsSoldForecastIntervalValue',
            color: '#8c54ff',
            dashArray: '3 3',
            strokeWidth: 2,
            hideDot: true,
            forecastGradient: true,
            fillOpacity: 0.9,
            lineType: 'monotone',
        },
        {
            dataKey: 'unitsSoldForecastValue',
            color: '#8c54ff',
            isReverseColor: true,
            strokeWidth: 2,
            lineType: 'monotone',
        },
        {
            dataKey: 'unitsSold',
            color: '#8c54ff',
            strokeWidth: 2,
            lineType: 'monotone',
        },
    ],
    categoryAxis: 'month',
    data: [],
    dateGrouper: 'month',
    gradient: 0.2,
    lineType: 'monotone',
    maxValue: 10000,
    minValue: 0,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'productMarketShareUnitsForecast',
    unitsOrValues: 'units',
    margin: {
        top: 20,
        right: 40,
        left: 0,
        bottom: 0,
    },
    interactionDisabled: false,
    suffix: 'productMarketShareForecastChartAuxbar',
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [
                {
                    name: 'showWeather',
                    label: 'Show Weather',
                    icon: 'default',
                    iconDefault: '/static/media/ico-checkbox-empty.23dcfc1880cefd2aab779978d71d5163.svg',
                    iconSelected: '/static/media/ico-checkbox-selected.67e79fb847afdca6479eaafcf8a9ce3f.svg',
                    translated: true,
                },
            ],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
    barAttr: [],
    ready: true,
    legend: [],
}
