import React from 'react';
import { has } from 'lodash';
import 'bootstrap/dist/css/bootstrap.css';

import styles from './option-list.module.scss';
import { useTranslation } from 'react-i18next';

export const OptionList = React.memo(({ options, onClickOption }) => {
    const { t } = useTranslation();
    const handleClick = (e, optionName) => {
        onClickOption(optionName);
        e.stopPropagation();
    };

    return (
        <div className={`${styles['option-list-container']}`}>
            {/* <div className="row m-0 mb-2">
                <div
                    className={`col p-0 d-flex justify-content-start align-items-center ${styles['option-list-container__title-container']}`}
                >
                    <span
                        className={`${styles['option-list-container__title']}`}
                    >
                        {`${t(`settings:configuration`)}`}
                    </span>
                </div>
            </div> */}
            {options.map((option, index) => (
                <div
                    key={index}
                    className={
                        option.isRed
                            ? `row mx-0 ${styles['option-list-container__option']} ${styles['option-list-container__option--red']}`
                            : `row mx-0 ${styles['option-list-container__option']}`
                    }
                    onClick={(e) => handleClick(e, option.name)}
                >
                    <div className="col-auto d-flex justify-content-between align-items-center p-0">
                        {has(option, 'icon') && (
                            <img
                                loading="lazy"
                                src={option.icon}
                                alt={`${option.name}`}
                                className={`${styles['option-list-container__option__icon']}`}
                            />
                        )}
                        <span
                            className={`${styles['option-list-container__option__text']}`}
                            style={{
                                color: has(option, 'color') && option.color
                            }}
                        >
                            {t(`settings:${option.name}`)}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
});
