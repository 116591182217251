import React, { useMemo } from 'react'
import { RadialBarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, Text, RadialBar } from 'recharts'
import { ReactComponent as IcoForecasts } from '../../../assets/images/ico-side-2.svg'
import styles from './bar-chart-radial-container.module.scss'
import { TooltipFactory } from '../..'
import { hexToRGBA } from '../../../utils/color'

const CustomTick = (props) => {
    let valueToShow = props.payload.value
    return (
        <Text {...props} className={styles['bar-chart-new-container__custom-tick']}>
            {valueToShow}
        </Text>
    )
}

export const BarChartRadialContainer = React.memo(
    ({ attr, barAttr, categoryAxis, data, onClickBar, tooltipType, barWidth, noDataLabel, unitsOrValues, translate }) => {
        if (data.length !== 0) {
            return (
                <ResponsiveContainer height={'100%'}>
                    <RadialBarChart
                        data={data}
                        innerRadius={'30%'}
                        outerRadius={'100%'}
                        startAngle={90}
                        endAngle={-90}
                        barCategoryGap={5}
                        maxBarSize={15}
                    >
                        <defs>
                            <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stopColor="#2cd9c54d" />
                                <stop offset="41%" stopColor="#2e5bff87" />
                                <stop offset="98%" stopColor="#0ea6fa" />
                            </linearGradient>
                        </defs>
                        <Tooltip
                            content={
                                <TooltipFactory
                                    item={{
                                        type: attr || '',
                                        unitsOrValues: unitsOrValues || undefined,
                                        translate: translate || undefined,
                                        id: 0,
                                    }}
                                />
                            }
                            wrapperStyle={{
                                zIndex: 1000,
                                outline: 'none',
                            }}
                            cursor={false}
                        />
                        {barAttr.map((attr, index) => {
                            return (
                                <RadialBar
                                    key={index}
                                    dataKey={attr.dataKey}
                                    fill={`url(#gradient)`}
                                    cornerRadius={10}
                                    clockWise={true}
                                    label={{ position: 'insideStart', fill: '#fff' }}
                                ></RadialBar>
                            )
                        })}
                    </RadialBarChart>
                </ResponsiveContainer>
            )
        } else {
            return (
                <div className={`${styles['bar-chart-graph-container']} ${styles['bar-chart-graph-container--no-data']}`}>
                    <IcoForecasts />
                    <span className="my-4">{noDataLabel}</span>
                </div>
            )
        }
    }
)

BarChartRadialContainer.propTypes = {}
