import styles from './team-management.module.scss'

import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMounted } from '../../../hooks/useIsMounted'
import { useToggle } from '../../../hooks/useToggle'
import TeamManagementDetails from '../team-management-details/team-management-details'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'query-string'
import { useAuxBar } from '../../../providers/aux-bar-context'
import {
    BubbleGeo,
    LoaderForeground,
    RegionGeoSales,
    Spinner,
    TeamList,
    TeamSalesComparison,
    TotalProducts,
    TotalSales,
    TotalTeamMembers,
    TotalTeams,
} from '../../../components'
import Auxbar from '../../../layout/auxbar/auxbar'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { StakeholderList } from '../../../components/dashboard-components/stakeholder-list/stakeholder-list'
import { TableDataWrapper } from '../../../components/dashboard-components/table-data-wrapper/table-data-wrapper'
import { GeneralTable } from '../../../components/dashboard-components/general-table/general-table'
import { TeamsService } from '../../../temp/test'
import { FinanceTable } from '../../../components/dashboard-components/finance-table/FinanceTable'

/**
 * Provides a page layout that allows managers to create and edit teams
 */

const TeamManagement = React.memo(() => {
    const isMounted = useIsMounted()
    const { t } = useCustomTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { setAvailableOptions, setChartFilters } = useAuxBar()
    const teamId = useMemo(() => qs.parse(location.search)['team-id'] || '', [location.search])

    useEffect(() => {
        if (teamId) setAvailableOptions(['search'])
        else setAvailableOptions([])
    }, [isMounted, setAvailableOptions, teamId])

    const handleSetTeamActive = (el) => {
        const newParams = new URLSearchParams(window.location.search)
        newParams.set('team-id', el.id)
        navigate({
            search: newParams.toString(),
        })
    }

    const columnsMemo = useMemo(
        () => [
            {
                path: 'id',
                backendPath: 'id',
                show: false,
            },
            {
                path: 'name',
                backendPath: 'name',
                show: true,
                sortable: true,
            },
            {
                path: 'action',
                sortable: false,
            },
        ],
        []
    )

    useEffect(() => {
        if (teamId) {
            setChartFilters([['teamId']])
        } else {
            setChartFilters([])
        }
    }, [setChartFilters, teamId])

    return (
        <>
            {!teamId && (
                <div className={`row mx-0 w-100 ${styles['team-management-container']}`}>
                    <div className="col-12 px-0 h-100">
                        <div className="row w-100 dashboard-header-row ">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <span className="me-4">{t(`pages:teamManagement.title`)}</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-between align-items-center" style={{ marginBottom: '2rem' }}>
                            <div className="col-auto px-0">
                                <span className={`${styles['team-management-container__sub-title']}`}>{t('pages:teamManagement.subTitle')}</span>
                            </div>
                        </div>

                        <div
                            className={`row w-100 mx-0 ${styles['manager-profile-container__section']}`}
                            style={{
                                height: '34rem',
                                marginBottom: '2rem',
                            }}
                        >
                            <div className="col-8 px-0 h-100 justify-content-center align-items-center">
                                <GeneralTable
                                    isExample={false}
                                    onRowClick={handleSetTeamActive}
                                    title={t('components:teamTable.title')}
                                    attr={'teamTable'}
                                    columns={columnsMemo}
                                    getEndpoint={TeamsService.getApiTeams}
                                    getConfig={undefined}
                                    addTitle={t('pages:teamManagement.createTeam')}
                                    addSuccessText={t('modals:addTeam.success')}
                                    addEndpoint={TeamsService.postApiTeams}
                                    addBodyInputs={{
                                        name: {
                                            name: 'name',
                                            path: 'name',
                                            backendPath: 'name',
                                            type: 'type',
                                            value: '',
                                            error: '',
                                            hidden: false,
                                            state: 'normal',
                                        },
                                    }}
                                    deleteIds={[
                                        {
                                            path: 'id',
                                            backendPath: 'id',
                                        },
                                    ]}
                                    deleteEndpoint={TeamsService.deleteApiTeams1}
                                    deleteTitle={t('modals:removeTeam.title')}
                                    deleteDescription={t('modals:removeTeam.description')}
                                    deleteSuccessText={t('modals:removeTeam.success')}
                                />
                            </div>
                            <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                            <div className="col px-0 d-flex flex-column">
                                <div className="row mx-0" style={{ height: '16rem' }}>
                                    <div className="col px-0 h-100">
                                        <TotalTeams />
                                    </div>
                                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                                    <div className="col px-0 h-100">
                                        <TotalTeamMembers />
                                    </div>
                                </div>
                                <div className="row mx-0" style={{ height: '2rem' }}>
                                    <div className="col px-0"></div>
                                </div>
                                <div className="row mx-0" style={{ height: '16rem' }}>
                                    <div className="col px-0 h-100">
                                        <TotalProducts />
                                    </div>
                                    <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                                    <div className="col px-0 h-100">
                                        <TotalSales />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{
                                height: '60rem',
                                marginBottom: '2rem',
                            }}
                        >
                            <div className="col px-0 h-100 ">
                                <TeamSalesComparison />
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{
                                height: '60rem',
                            }}
                        >
                            <div className="col px-0 h-100">
                                <RegionGeoSales />
                            </div>
                        </div>
                    </div>
                    {/* <Auxbar /> */}
                </div>
            )}
            {teamId && <TeamManagementDetails id={teamId} />}
        </>
    )
})

export default TeamManagement

TeamManagement.propTypes = {}
