import React, { useState } from 'react'
import { ReactComponent as IcoCorrespondence } from './assets/images/ico-correspondence.svg'
import icoPositive from '../../../assets/images/ico-positive.svg'
import icoNeutral from '../../../assets/images/ico-neutral.svg'
import icoNegative from '../../../assets/images/ico-negative.svg'
import styles from './sentiment-convo-list.module.scss'
import { useTranslation } from 'react-i18next'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const SentimentConvoList = React.memo(
    ({ conversations, onViewEmails, type }) => {
        const { t } = useCustomTranslation()
        const [attr] = useState('sentimentConvoList')
        return (
            <div className={`${styles['sentiment-convo-list-container']}`}>
                {conversations.map((el, index) => {
                    return (
                        <div key={index} className="row mx-0 w-100 mb-4">
                            <div
                                className={`col h-100 ${styles['sentiment-convo-list-container__item-wrapper']}`}
                                onClick={
                                    onViewEmails
                                        ? () => onViewEmails(index)
                                        : undefined
                                }
                            >
                                <div className="row mx-0 w-100 d-flex justify-content-between align-items-center">
                                    <div className="col-7 me-4 px-0 d-flex flex-column justify-content-between align-items-start">
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__item-wrapper__title']}`}
                                                >
                                                    {el.subject
                                                        ? el.subject
                                                        : '-'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 mb-2 w-100 d-flex justify-content-start align-items-center">
                                            <div className="col-auto px-0 me-2 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__item-wrapper__sub-title']}`}
                                                >
                                                    {el.date ? el.date : ''}
                                                </span>
                                            </div>
                                            <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__item-wrapper__sub-title']}`}
                                                >
                                                    {el.firstCorrespondent
                                                        ? el.firstCorrespondent
                                                        : ''}
                                                </span>
                                                {el.firstCorrespondent &&
                                                    el.secondCorrespondent && (
                                                        <IcoCorrespondence className="mx-2" />
                                                    )}
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__item-wrapper__sub-title']}`}
                                                >
                                                    {el.secondCorrespondent
                                                        ? el.secondCorrespondent
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                                            <div
                                                className={`col-auto ${
                                                    styles[
                                                        'sentiment-convo-list-container__item-wrapper__sentiment'
                                                    ]
                                                } ${
                                                    el.sentiment &&
                                                    el.sentiment.label
                                                        ? el.sentiment.label
                                                        : ''
                                                }`}
                                            >
                                                <span>
                                                    {el.sentiment &&
                                                    el.sentiment.label
                                                        ? el.sentiment.label
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {type === '1' && (
                                        <div className="col-2 px-0 h-100">
                                            <div
                                                className={`row mx-0 w-100 ${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrap']} mb-2`}
                                            >
                                                <div className="col px-0 h-100">
                                                    <div className="row mx-0 w-100 mb-1">
                                                        <div className="col px-0 h-100 d-flex justify-content-start align-items-center">
                                                            <span
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__title']}`}
                                                            >
                                                                {t(
                                                                    `components:${attr}.positivesentiment`
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="col px-0 h-100 d-flex justify-content-end align-items-center">
                                                            <span
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage']}`}
                                                            >
                                                                {el.sentiment &&
                                                                el.sentiment
                                                                    .positive
                                                                    ? el
                                                                          .sentiment
                                                                          .positive +
                                                                      '%'
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100 ${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percent']}age-bar positive">
                                                        <div className="col px-0 h-100 position-relative">
                                                            <div
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar__percentage-full']}`}
                                                                style={{
                                                                    width:
                                                                        el.sentiment &&
                                                                        el
                                                                            .sentiment
                                                                            .positive
                                                                            ? el
                                                                                  .sentiment
                                                                                  .positive +
                                                                              '%'
                                                                            : '',
                                                                }}
                                                            />
                                                            <div
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar__percentage-empty']}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100 ${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrap']}per mb-2">
                                                <div className="col px-0 h-100">
                                                    <div className="row mx-0 w-100 mb-1">
                                                        <div className="col px-0 h-100 d-flex justify-content-start align-items-center">
                                                            <span
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__title']}`}
                                                            >
                                                                {t(
                                                                    `components:${attr}.negativesentiment`
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="col px-0 h-100 d-flex justify-content-end align-items-center">
                                                            <span
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage']}`}
                                                            >
                                                                {el.sentiment &&
                                                                el.sentiment
                                                                    .negative
                                                                    ? el
                                                                          .sentiment
                                                                          .negative +
                                                                      '%'
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100 sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar negative">
                                                        <div className="col px-0 h-100 position-relative">
                                                            <div
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar__percentage-full']}`}
                                                                style={{
                                                                    width:
                                                                        el.sentiment &&
                                                                        el
                                                                            .sentiment
                                                                            .negative
                                                                            ? el
                                                                                  .sentiment
                                                                                  .negative +
                                                                              '%'
                                                                            : '',
                                                                }}
                                                            />
                                                            <div
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar__percentage-empty']}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100 sentiment-convo-list-container__item-wrapper__sentiment-wrapper">
                                                <div className="col px-0 h-100">
                                                    <div className="row mx-0 w-100 mb-1">
                                                        <div className="col px-0 h-100 d-flex justify-content-start align-items-center">
                                                            <span
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__title']}`}
                                                            >
                                                                {t(
                                                                    `components:${attr}.neutralsentiment`
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="col px-0 h-100 d-flex justify-content-end align-items-center">
                                                            <span
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage']}`}
                                                            >
                                                                {el.sentiment &&
                                                                el.sentiment
                                                                    .neutral
                                                                    ? el
                                                                          .sentiment
                                                                          .neutral +
                                                                      '%'
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100 sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar neutral">
                                                        <div className="col px-0 h-100 position-relative">
                                                            <div
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar__percentage-full']}`}
                                                                style={{
                                                                    width:
                                                                        el.sentiment &&
                                                                        el
                                                                            .sentiment
                                                                            .neutral
                                                                            ? el
                                                                                  .sentiment
                                                                                  .neutral +
                                                                              '%'
                                                                            : '',
                                                                }}
                                                            />
                                                            <div
                                                                className={`${styles['sentiment-convo-list-container__item-wrapper__sentiment-wrapper__percentage-bar__percentage-empty']}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {type === '2' && (
                                        <div className="col-auto px-3 d-flex justify-content-end align-items-center">
                                            <div className="d-flex flex-column me-3 justify-content-center align-items-center">
                                                <img
                                                    loading="lazy"
                                                    src={icoPositive}
                                                    className={`${styles['sentiment-convo-list-container__row-container__icon']} me-1`}
                                                    alt=""
                                                />
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__row-container__text']} ${styles['sentiment-convo-list-container__row-container__text--positive']}`}
                                                >
                                                    {el.positiveWordCounter}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column me-3 justify-content-center align-items-center">
                                                <img
                                                    loading="lazy"
                                                    src={icoNeutral}
                                                    className={`${styles['sentiment-convo-list-container__row-container__icon']} me-1`}
                                                    alt=""
                                                />
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__row-container__text']} ${styles['sentiment-convo-list-container__row-container__text--neutral']}`}
                                                >
                                                    {el.neutralWordCounter}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column me-3 justify-content-center align-items-center">
                                                <img
                                                    loading="lazy"
                                                    src={icoNegative}
                                                    className={`${styles['sentiment-convo-list-container__row-container__icon']} me-1`}
                                                    alt=""
                                                />
                                                <span
                                                    className={`${styles['sentiment-convo-list-container__row-container__text']} ${styles['sentiment-convo-list-container__row-container__text--negative']}`}
                                                >
                                                    {el.negativeWordCounter}
                                                </span>
                                            </div>
                                            {/* <div
                                          className={`${styles['sentiment-convo-list-container__row-container__bar']}`}
                                        >
                                          <div
                                            className={`${styles['sentiment-convo-list-container__row-container__bar__positive-bar']}`}
                                          ></div>
                                          <div
                                            className={`${styles['sentiment-convo-list-container__row-container__bar__neutral-bar']}`}
                                          ></div>
                                          <div
                                            className={`${styles['sentiment-convo-list-container__row-container__bar__negative-bar']}`}
                                          ></div>
                                        </div> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
)
