import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Label, LabelList, ResponsiveContainer, Cell, CartesianGrid, Rectangle } from 'recharts'
import styles from './dynamic-bar-chart-container.module.scss'
import _ from 'lodash'
import { CustomCenteredLabel, CustomHorizontalLabel, CustomShape, CustomTextTick, CustomTick, CustomVerticalCursor, TooltipFactory } from '../../'
import useCursorTracking from '../../../hooks/useCursorTracking'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { hexToRGBA } from '../../../utils/color'
import { useCursorTrackingProvider } from '../../../providers/cursor-tracking-context'

const CustomBarShape = (props) => {
    const { x, y, width, height, fill, radius, reference, dataKeyIndex, value, isGradient, dataKey, fillFunction, onClick } = props
    // reference should be a vertical line at specific x value knowing that reference is a percentage
    const lineX = (reference * width) / value[1]
    //if is gradient, then the rectangle should start at fff and end at fill
    const newFill = fillFunction ? fillFunction(props.payload) : fill
    const customFill = isGradient ? `url(#gradient-${props.index}-${dataKeyIndex})` : newFill
    return (
        <svg>
            {isGradient && (
                <defs>
                    <linearGradient id={`gradient-${props.index}-${dataKeyIndex}`} x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor={newFill} stopOpacity={0.3} />
                        <stop offset="100%" stopColor={newFill} stopOpacity={1} />
                    </linearGradient>
                </defs>
            )}
            <g>
                <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    cursor={onClick ? 'pointer' : 'default'}
                    fill={customFill}
                    radius={radius}
                    onClick={onClick}
                />
                {reference && dataKeyIndex === 0 && (
                    <line
                        x1={x + lineX}
                        y1={y - 5}
                        x2={x + lineX}
                        y2={y + height + 5}
                        stroke={'rgba(6, 164, 231, 1)'}
                        strokeWidth={2}
                        strokeLinecap="round"
                    />
                )}
            </g>
        </svg>
    )
}

export const DynamicBarChartContainer = React.memo(
    ({
        barStackedAttr,
        barWidth,
        categoryAxis,
        categoryLabel,
        data,
        orientation,
        tooltipType,
        quantitativeLabel,
        hideXTicks,
        hideYTicks,
        hideXTickLine,
        hideYTickLine,
        hideCursor,
        interactionDisabled,
        unitsOrValues,
        type,
        labelDisabled,
        gradient,
        margin,
        isPercentage,
        hideXValues,
        hideLabels,
        onClick,
    }) => {
        const { t } = useCustomTranslation()
        const { position } = useCursorTrackingProvider()
        const [animationActive, setAnimationActive] = React.useState(true)
        const [hoveredBarGroupId, setHoveredBarGroupId] = useState('')
        const layout = orientation || 'vertical'
        const barColors = []
        const hex = (x) => {
            x = x.toString(16)
            return x.length === 1 ? '0' + x : x
        }

        if (gradient) {
            for (let i = data.length; i > 0; i -= 1) {
                const ratio = i * (1 / data.length)
                const r = Math.ceil(
                    parseInt(barStackedAttr[0].gradientColors[0].substring(0, 2), 16) * ratio +
                        parseInt(barStackedAttr[0].gradientColors[1].substring(0, 2), 16) * (1 - ratio)
                )
                const g = Math.ceil(
                    parseInt(barStackedAttr[0].gradientColors[0].substring(2, 4), 16) * ratio +
                        parseInt(barStackedAttr[0].gradientColors[1].substring(2, 4), 16) * (1 - ratio)
                )
                const b = Math.ceil(
                    parseInt(barStackedAttr[0].gradientColors[0].substring(4, 6), 16) * ratio +
                        parseInt(barStackedAttr[0].gradientColors[1].substring(4, 6), 16) * (1 - ratio)
                )
                const newColor = hex(r) + hex(g) + hex(b)
                barColors.push('#' + newColor)
            }
        }

        if (data && data.length !== 0) {
            if (layout === 'verticalDouble' && barStackedAttr && barStackedAttr.length !== 0) {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '0.5rem',
                            overflow: 'visible',
                        }}
                        className={`${styles['dynamic-bar-chart-container']}`}
                    >
                        <ResponsiveContainer height="100%" width="50%">
                            <BarChart
                                className={
                                    interactionDisabled
                                        ? `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--vertical']} ${styles['dynamic-bar-chart-container--disabled']}`
                                        : `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--vertical']}`
                                }
                                margin={0}
                                data={data}
                                layout="vertical"
                                maxBarSize={20}
                            >
                                <Tooltip
                                    contentStyle={{ zIndex: 1000 }}
                                    content={
                                        <TooltipFactory
                                            item={{
                                                type: tooltipType.split('-')[0] || '',
                                                unitsOrValues: unitsOrValues || undefined,
                                                translate: t || undefined,
                                                id: hoveredBarGroupId,
                                            }}
                                        />
                                    }
                                    wrapperStyle={{
                                        zIndex: 1000,

                                        outline: 'none',
                                    }}
                                    cursor={hideCursor ? false : <CustomVerticalCursor />}
                                />
                                <CartesianGrid strokeDasharray="5" vertical={false} />
                                <XAxis
                                    reversed
                                    type="number"
                                    tick={hideXTicks ? false : <CustomTick />}
                                    tickLine={!hideXTickLine}
                                    hide
                                    label={
                                        quantitativeLabel ? <Label dy={20} value={quantitativeLabel} style={{ textAnchor: 'middle' }} /> : undefined
                                    }
                                />
                                <YAxis
                                    dataKey={categoryAxis || undefined}
                                    label={
                                        categoryLabel ? (
                                            <Label angle={-90} value={categoryLabel} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        ) : undefined
                                    }
                                    type="category"
                                    tickLine={!hideYTickLine}
                                    axisLine={false}
                                    tick={hideYTicks ? false : <CustomTextTick />}
                                />
                                <Bar
                                    key={0}
                                    dataKey={barStackedAttr[0].dataKey}
                                    stackId={barStackedAttr[0].stackId}
                                    radius={barStackedAttr[0].radius}
                                    barSize={barWidth || null}
                                    minPointSize={100}
                                    onMouseEnter={() => setHoveredBarGroupId(0)}
                                    onMouseLeave={() => setHoveredBarGroupId('')}
                                >
                                    {data.map((entry, index2) => {
                                        if (entry[barStackedAttr[0].dataKey]) {
                                            return (
                                                <Cell
                                                    fill={gradient ? barColors[index2] : barStackedAttr[0].color}
                                                    onMouseEnter={() => setHoveredBarGroupId(0)}
                                                    onMouseLeave={() => setHoveredBarGroupId('')}
                                                    key={index2}
                                                />
                                            )
                                        } else {
                                            return <Cell fill="transparent" key={index2} />
                                        }
                                    })}
                                    {!labelDisabled && (
                                        <LabelList
                                            dataKey={barStackedAttr[0].dataKey}
                                            content={
                                                <CustomHorizontalLabel
                                                    id={0}
                                                    unitsOrValues={unitsOrValues || 'units'}
                                                    setHoveredBarGroupId={setHoveredBarGroupId}
                                                />
                                            }
                                        />
                                    )}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer height="100%" width="50%">
                            <BarChart
                                className={
                                    interactionDisabled
                                        ? `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--vertical']} ${styles['dynamic-bar-chart-container--disabled']}`
                                        : `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--vertical']}`
                                }
                                margin={0}
                                data={data}
                                layout="vertical"
                                maxBarSize={20}
                            >
                                <Tooltip
                                    contentStyle={{
                                        zIndex: 1000,
                                        position: 'fixed',
                                    }}
                                    content={
                                        <TooltipFactory
                                            item={{
                                                type: tooltipType.split('-')[0] || '',
                                                unitsOrValues: unitsOrValues || undefined,
                                                translate: t || undefined,
                                                id: hoveredBarGroupId,
                                            }}
                                        />
                                    }
                                    wrapperStyle={{
                                        zIndex: 1000,
                                        outline: 'none',
                                    }}
                                    cursor={hideCursor ? false : <CustomVerticalCursor />}
                                />
                                <CartesianGrid strokeDasharray="5" vertical={false} />
                                <XAxis
                                    type="number"
                                    tick={false}
                                    tickLine={false}
                                    hide
                                    label={
                                        quantitativeLabel ? <Label dy={20} value={quantitativeLabel} style={{ textAnchor: 'middle' }} /> : undefined
                                    }
                                />
                                <YAxis
                                    dataKey={categoryAxis || undefined}
                                    label={
                                        categoryLabel ? (
                                            <Label angle={-90} value={categoryLabel} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        ) : undefined
                                    }
                                    type="category"
                                    tickSize={10}
                                    tickLine={false}
                                    width={0}
                                    axisLine={false}
                                    tick={hideYTicks ? false : <CustomTextTick hidden={true} />}
                                />
                                <Bar
                                    key={1}
                                    dataKey={barStackedAttr[1].dataKey}
                                    stackId={barStackedAttr[1].stackId}
                                    radius={barStackedAttr[1].radius}
                                    barSize={barWidth || null}
                                    onMouseEnter={() => setHoveredBarGroupId(0)}
                                    onMouseLeave={() => setHoveredBarGroupId('')}
                                    minPointSize={100}
                                >
                                    {data.map((entry, index2) => {
                                        if (entry[barStackedAttr[1].dataKey]) {
                                            return (
                                                <Cell
                                                    fill={gradient ? barColors[index2] : barStackedAttr[1].color}
                                                    onMouseEnter={() => setHoveredBarGroupId(1)}
                                                    onMouseLeave={() => setHoveredBarGroupId('')}
                                                    key={index2}
                                                />
                                            )
                                        } else {
                                            return <Cell fill="transparent" key={index2} />
                                        }
                                    })}
                                    {!labelDisabled && (
                                        <LabelList
                                            dataKey={barStackedAttr[1].dataKey}
                                            content={
                                                <CustomHorizontalLabel
                                                    id={1}
                                                    unitsOrValues={unitsOrValues || 'units'}
                                                    setHoveredBarGroupId={setHoveredBarGroupId}
                                                />
                                            }
                                        />
                                    )}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )
            } else if (layout === 'vertical') {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '0 2rem',
                        }}
                    >
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                            <BarChart
                                reverseStackOrder
                                className={
                                    interactionDisabled
                                        ? `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--vertical']} ${styles['dynamic-bar-chart-container--disabled']}`
                                        : `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--vertical']}`
                                }
                                data={data}
                                layout="vertical"
                                barCategoryGap={'20%'}
                            >
                                <Tooltip
                                    contentStyle={{
                                        zIndex: 1000,
                                    }}
                                    content={
                                        <TooltipFactory
                                            item={{
                                                type: tooltipType.split('-')[0] || '',
                                                unitsOrValues: unitsOrValues || undefined,
                                                translate: t || undefined,
                                                id: hoveredBarGroupId,
                                            }}
                                        />
                                    }
                                    wrapperStyle={{
                                        zIndex: 1000,
                                        outline: 'none',
                                    }}
                                    cursor={
                                        hideCursor
                                            ? false
                                            : {
                                                  fill: '#ededed',
                                                  radius: [0, 10, 10, 0],
                                              }
                                    }
                                />
                                <CartesianGrid strokeDasharray="4" horizontal={false} />
                                <XAxis
                                    type="number"
                                    tick={<CustomTick />}
                                    domain={[0, 'dataMax']}
                                    tickLine={false}
                                    label={quantitativeLabel ? <Label value={quantitativeLabel} style={{ textAnchor: 'middle' }} /> : undefined}
                                />
                                <YAxis
                                    dataKey={categoryAxis || undefined}
                                    label={
                                        categoryLabel ? (
                                            <Label angle={-90} value={categoryLabel} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        ) : undefined
                                    }
                                    type="category"
                                    tickSize={10}
                                    // tickFormatter={tickFormatter}
                                    tickLine={false}
                                    tick={hideYTicks ? false : <CustomTextTick />}
                                />
                                {barStackedAttr &&
                                    barStackedAttr.map((attr, index) => {
                                        return (
                                            <Bar
                                                style={
                                                    barStackedAttr[barStackedAttr.length - (index + 1)].dataKey.split('-')[0] === 'total'
                                                        ? {
                                                              stroke: barStackedAttr[barStackedAttr.length - (index + 1)].color,
                                                              strokeWidth: 3,
                                                              left: 0,
                                                          }
                                                        : {}
                                                }
                                                barSize={20}
                                                key={barStackedAttr.length - (index + 1)}
                                                dataKey={barStackedAttr[barStackedAttr.length - (index + 1)].dataKey.split('-')[0]}
                                                shape={
                                                    barStackedAttr[barStackedAttr.length - (index + 1)].dataKey.split('-')[0] === 'total' ? (
                                                        <CustomShape />
                                                    ) : (
                                                        <CustomBarShape
                                                            dataKey={barStackedAttr[barStackedAttr.length - (index + 1)].dataKey}
                                                            isGradient={barStackedAttr[barStackedAttr.length - (index + 1)].isGradient}
                                                            dataKeyIndex={barStackedAttr.length - (index + 1)}
                                                            fillFunction={barStackedAttr[barStackedAttr.length - (index + 1)].colorFunction}
                                                        />
                                                    )
                                                }
                                                stackId={barStackedAttr[barStackedAttr.length - (index + 1)].stackId}
                                                fill={!gradient ? barStackedAttr[barStackedAttr.length - (index + 1)].color : barColors[0]}
                                                onMouseEnter={() => setHoveredBarGroupId(barStackedAttr.length - (index + 1))}
                                                // isAnimationActive={
                                                //     (!animationEnded &&
                                                //         !animationDisabled) ||
                                                //     animationEnded
                                                // }
                                                // onMouseLeave={() =>
                                                //     setHoveredBarGroupId('')
                                                // }
                                                // animationDuration={
                                                //     1000 / barStackedAttr.length
                                                // }
                                                // animationBegin={
                                                //     animationEnded
                                                //         ? 0
                                                //         : (1000 /
                                                //               barStackedAttr.length) *
                                                //           (barStackedAttr.length -
                                                //               (index + 1))
                                                // }
                                                isAnimationActive={animationActive}
                                                onAnimationEnd={() => setAnimationActive(false)}
                                                onMouseLeave={() => setHoveredBarGroupId('')}
                                                // onAnimationEnd={
                                                //     barStackedAttr.length -
                                                //         (index + 1) ===
                                                //     barStackedAttr.length - 1
                                                //         ? () =>
                                                //               toggleAnimationEnded(
                                                //                   true
                                                //               )
                                                //         : undefined
                                                // }
                                            >
                                                {data.map((entry, index2) => {
                                                    let radius = [0, 50, 50, 0]
                                                    if (type === 'stacked') {
                                                        if (entry[barStackedAttr[barStackedAttr.length - (index + 1)].dataKey]) {
                                                            return (
                                                                <Cell
                                                                    radius={radius}
                                                                    fill={barStackedAttr[barStackedAttr.length - (index + 1)].color}
                                                                    onClick={onClick ? () => onClick(entry) : undefined}
                                                                    shapeRendering={
                                                                        <CustomBarShape
                                                                            onClick={onClick ? () => onClick(entry) : undefined}
                                                                            isGradient={
                                                                                barStackedAttr[barStackedAttr.length - (index + 1)].isGradient
                                                                            }
                                                                            fill={
                                                                                !gradient
                                                                                    ? barStackedAttr[barStackedAttr.length - (index + 1)].color ||
                                                                                      barStackedAttr[
                                                                                          barStackedAttr.length - (index + 1)
                                                                                      ].colorFunction(entry)
                                                                                    : barColors[index2]
                                                                            }
                                                                        />
                                                                    }
                                                                    key={index2}
                                                                />
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    } else {
                                                        return (
                                                            <Cell
                                                                radius={radius}
                                                                fill={
                                                                    !gradient
                                                                        ? barStackedAttr[barStackedAttr.length - (index + 1)].color ||
                                                                          barStackedAttr[barStackedAttr.length - (index + 1)].colorFunction(entry)
                                                                        : barColors[index2]
                                                                }
                                                                onClick={onClick ? () => onClick(entry) : undefined}
                                                                shapeRendering={
                                                                    <CustomBarShape
                                                                        onClick={onClick ? () => onClick(entry) : undefined}
                                                                        isGradient={barStackedAttr[barStackedAttr.length - (index + 1)].isGradient}
                                                                        fill={
                                                                            !gradient
                                                                                ? barStackedAttr[barStackedAttr.length - (index + 1)].color ||
                                                                                  barStackedAttr[barStackedAttr.length - (index + 1)].colorFunction(
                                                                                      entry
                                                                                  )
                                                                                : barColors[index2]
                                                                        }
                                                                    />
                                                                }
                                                                key={index2}
                                                            />
                                                        )
                                                    }
                                                })}
                                                {!labelDisabled && (
                                                    <LabelList
                                                        dataKey={barStackedAttr[barStackedAttr.length - (index + 1)].dataKey}
                                                        content={
                                                            barStackedAttr.length - (index + 1) !== 0 ? (
                                                                <CustomHorizontalLabel
                                                                    setHoveredBarGroupId={setHoveredBarGroupId}
                                                                    id={barStackedAttr.length - (index + 1)}
                                                                    unitsOrValues={unitsOrValues || 'units'}
                                                                />
                                                            ) : (
                                                                <CustomHorizontalLabel
                                                                    setHoveredBarGroupId={setHoveredBarGroupId}
                                                                    id={barStackedAttr.length - (index + 1)}
                                                                    unitsOrValues={unitsOrValues || 'units'}
                                                                />
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Bar>
                                        )
                                    })}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )
            } else if (layout === 'horizontal') {
                return (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '0.5rem',
                        }}
                    >
                        <ResponsiveContainer height="100%">
                            <BarChart
                                reverseStackOrder
                                className={
                                    interactionDisabled
                                        ? `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--horizontal']} ${styles['dynamic-bar-chart-container--disabled']}`
                                        : `${styles['dynamic-bar-chart-container']} ${styles['dynamic-bar-chart-container--horizontal']}`
                                }
                                margin={
                                    margin || {
                                        top: 0,
                                        right: 0,
                                        bottom: -20,
                                        left: -20,
                                    }
                                }
                                data={data}
                                layout="horizontal"
                                maxBarSize={60}
                            >
                                <defs>
                                    <linearGradient id={'gradientCursor'} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="-3.97%" stopColor="#2cd9c5" stopOpacity={0.3} />
                                        <stop offset="64%" stopColor="rgba(46, 91, 255, 0.7)" stopOpacity={0.1} />
                                    </linearGradient>
                                    <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="-3.97%" stopColor="#2cd9c5" />
                                        <stop offset="64%" stopColor="rgba(46, 91, 255, 0.7)" />
                                    </linearGradient>
                                </defs>
                                <Tooltip
                                    contentStyle={{
                                        zIndex: 1000,
                                    }}
                                    content={
                                        <TooltipFactory
                                            item={{
                                                type: tooltipType.split('-')[0] || '',
                                                unitsOrValues: unitsOrValues || undefined,
                                                translate: t || undefined,
                                                id: hoveredBarGroupId.toString() ? barStackedAttr.length - (hoveredBarGroupId + 1) : '',
                                            }}
                                        />
                                    }
                                    wrapperStyle={{
                                        zIndex: 1000,
                                        outline: 'none',
                                    }}
                                    cursor={
                                        hideCursor
                                            ? false
                                            : {
                                                  fill: 'url(#gradientCursor)',
                                                  radius: [8, 8, 0, 0],
                                              }
                                    }
                                />
                                <CartesianGrid strokeDasharray="5" vertical={false} />
                                <YAxis
                                    type="number"
                                    axisLine={false}
                                    label={
                                        quantitativeLabel ? (
                                            <Label angle={-90} value={quantitativeLabel} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        ) : undefined
                                    }
                                    dx={-10}
                                    tickLine={!hideYTickLine}
                                    tick={hideYTicks ? false : <CustomTick />}
                                />
                                <XAxis
                                    dataKey={categoryAxis || undefined}
                                    label={categoryLabel ? <Label dy={20} value={categoryLabel} style={{ textAnchor: 'middle' }} /> : undefined}
                                    height={50}
                                    type="category"
                                    tickSize={10}
                                    axisLine={false}
                                    tickLine={!hideXTickLine}
                                    tick={hideXTicks ? false : <CustomTextTick hideXValues={hideXValues} />}
                                />
                                {barStackedAttr &&
                                    barStackedAttr.map((attr, index) => {
                                        const currentAttr = barStackedAttr[barStackedAttr.length - (index + 1)]
                                        const fillColor = currentAttr.isForecastGradient ? 'url(#myGradient)' : currentAttr.color

                                        return (
                                            <Bar
                                                key={barStackedAttr.length - (index + 1)}
                                                dataKey={currentAttr.dataKey.split('-')[0]}
                                                fill={fillColor}
                                                fillOpacity={currentAttr.opacity ? currentAttr.opacity : null}
                                                style={{
                                                    filter: `drop-shadow(0px 0px 10px ${hexToRGBA('#4c4c4c', 0.3)})`,
                                                }}
                                                stackId={currentAttr.stackId}
                                                onMouseEnter={() => setHoveredBarGroupId(index)}
                                                onMouseLeave={() => setHoveredBarGroupId('')}
                                                isAnimationActive={animationActive}
                                                onAnimationEnd={() => setAnimationActive(false)}
                                                animationEasing="ease-in-out"
                                                radius={currentAttr.radius}
                                            >
                                                {!hideLabels && (
                                                    <LabelList
                                                        dataKey={currentAttr.dataKey}
                                                        content={
                                                            isPercentage ? <CustomCenteredLabel labelType="percentage" /> : <CustomCenteredLabel />
                                                        }
                                                        position="inside"
                                                    />
                                                )}
                                            </Bar>
                                        )
                                    })}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )
            }
            return null
        } else
            return (
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                        <span className="noDataText">{t('common:noData')}</span>
                    </div>
                </div>
            )
    }
)

DynamicBarChartContainer.propTypes = {
    /**
     * Array of objects
     * Each object contains the following attributes:
     * dataKey: string of attribute in data array
     * color: string of color
     * stackId: string to choose which stack the attribute belongs to
     * */
    barStackedAttr: PropTypes.array,
    /**
     * String to choose language
     */
    language: PropTypes.string,
    /**
     * String to choose which attribute is the category axis attribute
     */
    categoryAxis: PropTypes.string,
    /**
     * String to display label in category axis
     */
    categoryLabel: PropTypes.string,
    /**
     * String to display label in category axis
     */
    quantitativeLabel: PropTypes.string,
    /**
     * Bool to hide X Axis ticks
     */
    hideXTicks: PropTypes.bool,
    /**
     * Bool to hide Y Axis ticks
     */
    hideYTicks: PropTypes.bool,
    /**
     * Bool to hide cursor
     */
    hideCursor: PropTypes.bool,
    /**
     * Array of objects containing data
     */
    data: PropTypes.array,
    /**
     * String to choose layout orientation of chart
     * Can be: vertical | horizontal
     */
    orientation: PropTypes.string,
    /**
     * String to choose which tooltip to use
     */
    tooltipType: PropTypes.string,
    /**
     * String to choose format of values in number axis
     */
    unitsOrValues: PropTypes.string,
    /**
     * Boolean to allow or disable interactions
     */
    interactionDisabled: PropTypes.bool,
    /**
     * Boolean to allow or disable labels
     */
    labelDisabled: PropTypes.bool,
}
