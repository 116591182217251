import styles from './contas-hospitalares.module.scss';

import React from 'react';
import Auxbar from '../../../layout/auxbar/auxbar';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

import { EndConsumerInformation } from '../../../components/dashboard-components/end-consumer-information/end-consumer-information';
import { AccessList } from '../../../components/dashboard-components/access-list/access-list';

/**
 * Provides a page layout that allows managers to create and edit teams
 */

const ContasHospitalares = React.memo(() => {
    const { t } = useCustomTranslation();

    return (
        <div
            className={`row mx-0 w-100 ${styles['contas-hospitalares-container']}`}
        >
            <div className="col-12 px-0 h-100">
                <div className="row w-100 dashboard-header-row ">
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        <span className="me-4">
                            {t(`pages:contasHospitalares.title`)}
                        </span>
                    </div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{ height: '45rem', marginBottom: '2rem' }}
                >
                    <div className="col h-100 px-0">
                        <AccessList />
                    </div>
                </div>
            </div>
            {/* <Auxbar /> */}
        </div>
    );
});

export default ContasHospitalares;

ContasHospitalares.propTypes = {};
