import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { usePub } from '../../../../../hooks/usePubSub';
import { useFilter } from '../../../../../providers/filter-context';
import { useSelectInput } from '../../../../../hooks/useSelectInput';
import { errorHandler } from '../../../../../utils/api';
import {
    Modal,
    PrimaryButton,
    RadioInput,
    SelectInput,
    SuccessModal,
    TerciaryButton
} from '../../../../../components';
import { useRatioInput } from '../../../../../hooks/useRatioInput';
import styles from './transfer-member-modal.module.scss';
import { ModalInputLabel } from '../../../../../components/text/modal-input-label/ModalInputLabel';
import { useNavigate } from 'react-router-dom';
import { usePlatformFormat } from '../../../../../providers/platform-format-context';

export const TransferMemberModal = ({ onCloseModal, onEnd, type, orgId }) => {
    const { format } = usePlatformFormat();
    const publish = usePub();
    const navigate = useNavigate();
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [transferOrgWarning, setTransferOrgWarning] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const {
        value: organization,
        toggleDropdown: organizationToggleDropdown,
        selectOption: organizationSelectOption,
        keyPress: organizationKeyPress,
        keyDown: organizationKeyDown,
        setValue: organizationSetValue
    } = useSelectInput({
        name: 'organization',
        showOptions: true,
        options: ['123'],
        value: '',
        optionsAux: ['123'],
        state: 'normal',
        focused: -1,
        reference: useRef()
    });

    const {
        value: profileType,
        clickRatioOption: profileTypeClickRadioOption
    } = useRatioInput({
        name: 'profileType',
        options: [
            {
                name: 'enterprise',
                label: t(`inputs:enterprise`),
                disabled: false,
                blocked: false
            },
            {
                name: 'organization',
                label: t(`inputs:organization`),
                disabled: false,
                blocked: false
            }
        ],
        selectedOption: type ? type : ''
    });

    const {
        value: orgTeamsInput,
        toggleDropdown: orgTeamsInputToggleDropdown,
        selectOption: orgTeamsInputSelectOption,
        keyPress: orgTeamsInputKeyPress,
        keyDown: orgTeamsInputKeyDown,
        setValue: orgTeamsInputSetValue
    } = useSelectInput({
        name: 'orgTeamsInput',
        showOptions: true,
        options: [],
        value: '',
        optionsAux: [],
        state: 'normal',
        focused: -1,
        reference: useRef()
    });

    const handleTransferMember = useCallback(async () => {
        try {
            setLoading(true);
            // await TeamsService.patchApiTeamsProducts({
            //     teamId: memberRole.selectedOptionAux,
            //     requestBody: filter.productId ? [filter.productId] : undefined
            // });
            if (onEnd) {
                onEnd();
            }
            setIsSuccess(true);
            setLoading(false);
            publish('product');
        } catch (err) {
            errorHandler(err);
        }
    }, [onEnd, publish]);

    const handleCreateOrganization = useCallback(() => {
        navigate('/organizations', {
            state: { from: 'inviteMember' }
        });
    }, [navigate]);

    useEffect(() => {
        if (organization.selectedOptionAux !== orgId) {
            setTransferOrgWarning(true);
        } else {
            setTransferOrgWarning(false);
        }
    }, [orgId, organization.selectedOptionAux]);

    const renderEnterprise = useCallback(() => {
        return (
            <>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">
                            {t('modals:transferMembers.title')}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-sub-title">
                            {t('modals:transferMembers.subTitleEnterprise')}
                        </span>
                    </div>
                </div>
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <RadioInput
                            {...profileType}
                            direction="horizontal"
                            onClickRatioOption={profileTypeClickRadioOption}
                        />
                    </div>
                </div>
                {profileType.selectedOption === 'organization' &&
                    organization.options.length === 0 && (
                        <>
                            <div
                                className={`row mx-0 w-100`}
                                style={{ margin: '5rem 0' }}
                            >
                                <div className="col px-0 d-flex justify-content-end align-items-center">
                                    <span
                                        className={
                                            styles[
                                                'transfer-member-modal-container__informational-text'
                                            ]
                                        }
                                    >
                                        {t(
                                            'modals:transferMembers.noOrganization'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <hr
                                className="my-4"
                                style={{
                                    color: 'rgba(0,0,0,0.1)',
                                    opacity: '1'
                                }}
                            />
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <TerciaryButton
                                        text={t('buttons:cancel')}
                                        onClick={() => {
                                            onCloseModal(false);
                                        }}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t('buttons:createOrganization')}
                                        onClick={() => {
                                            handleCreateOrganization();
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                {profileType.selectedOption === 'organization' &&
                    organization.options.length > 0 && (
                        <>
                            <>
                                <ModalInputLabel
                                    text={
                                        'Choose which organization the members belong to'
                                    }
                                />
                                <div
                                    className={`row mx-0 w-100`}
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0 d-flex justify-content-end align-items-center">
                                        <SelectInput
                                            hideLabel
                                            onChangeInput={organizationSetValue}
                                            onSelectInput={
                                                organizationSelectOption
                                            }
                                            hideOptionsAux
                                            onSelectIconInput={
                                                organizationSelectOption
                                            }
                                            onKeyPress={organizationKeyPress}
                                            onKeyDown={organizationKeyDown}
                                            onToggleDropdown={
                                                organizationToggleDropdown
                                            }
                                            {...organization}
                                        />
                                    </div>
                                </div>
                                {transferOrgWarning && (
                                    <div
                                        className="row mx-0 w-100"
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0">
                                            <span
                                                className={
                                                    styles[
                                                        'transfer-member-modal-container__warning-text'
                                                    ]
                                                }
                                            >
                                                Transferring this member to
                                                another organization will remove
                                                all current teams.
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {organization.selectedOption && (
                                    <>
                                        <ModalInputLabel
                                            text={'Add the member to the team'}
                                            optionalText={'(optional)'}
                                        />
                                        <div
                                            className={`row mx-0 w-100`}
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                                <SelectInput
                                                    hideLabel
                                                    onChangeInput={
                                                        orgTeamsInputSetValue
                                                    }
                                                    onSelectInput={
                                                        orgTeamsInputSelectOption
                                                    }
                                                    hideOptionsAux
                                                    onSelectIconInput={
                                                        orgTeamsInputSelectOption
                                                    }
                                                    onKeyPress={
                                                        orgTeamsInputKeyPress
                                                    }
                                                    onKeyDown={
                                                        orgTeamsInputKeyDown
                                                    }
                                                    onToggleDropdown={
                                                        orgTeamsInputToggleDropdown
                                                    }
                                                    {...orgTeamsInput}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <hr
                                    className="my-4"
                                    style={{
                                        color: 'rgba(0,0,0,0.1)',
                                        opacity: '1'
                                    }}
                                />
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <TerciaryButton
                                            text={t('buttons:cancel')}
                                            onClick={() => {
                                                onCloseModal(false);
                                            }}
                                        />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton
                                            text={t('buttons:transferMember')}
                                            onClick={() => {
                                                handleTransferMember();
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        </>
                    )}
            </>
        );
    }, [
        organizationSelectOption,
        organizationSetValue,
        organizationToggleDropdown,
        orgTeamsInput,
        orgTeamsInputSelectOption,
        orgTeamsInputSetValue,
        orgTeamsInputToggleDropdown,
        profileType,
        profileTypeClickRadioOption,
        transferOrgWarning,
        onCloseModal,
        handleCreateOrganization,
        handleTransferMember,
        t,
        orgTeamsInputKeyDown,
        organization,
        organizationKeyDown,
        organizationKeyPress,
        orgTeamsInputKeyPress
    ]);

    const renderOrganization = useCallback(() => {
        return (
            <>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">
                            {t('modals:transferMembers.title')}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-sub-title">
                            {t('modals:transferMembers.subTitleOrganization')}
                        </span>
                    </div>
                </div>

                <ModalInputLabel
                    text={'Add the member to the team'}
                    optionalText={'(optional)'}
                />
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <SelectInput
                            hideLabel
                            onChangeInput={orgTeamsInputSetValue}
                            onSelectInput={orgTeamsInputSelectOption}
                            hideOptionsAux
                            onSelectIconInput={orgTeamsInputSelectOption}
                            onKeyPress={orgTeamsInputKeyPress}
                            onKeyDown={orgTeamsInputKeyDown}
                            onToggleDropdown={orgTeamsInputToggleDropdown}
                            {...orgTeamsInput}
                        />
                    </div>
                </div>

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1'
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <TerciaryButton
                            text={t('buttons:cancel')}
                            onClick={() => {
                                onCloseModal(false);
                            }}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('buttons:transferMember')}
                            onClick={() => {
                                handleTransferMember();
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }, [
        orgTeamsInput,
        orgTeamsInputSelectOption,
        orgTeamsInputSetValue,
        orgTeamsInputToggleDropdown,
        onCloseModal,
        handleTransferMember,
        t,
        orgTeamsInputKeyDown,
        orgTeamsInputKeyPress
    ]);

    if (!isSuccess) {
        return (
            <Modal
                onToggleModal={() => onCloseModal(false)}
                isLoading={isLoading}
            >
                <div style={{ width: '40rem', height: '100%' }}>
                    {format === 'enterprise' && renderEnterprise()}
                    {format === 'organization' && renderOrganization()}
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:transferMembers.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
