import React from 'react';
import { getValueInString } from '../../../../../utils/unit';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import _ from 'lodash';

export const SalesForecastTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    if (props.dataKey !== '') {
        const value = _.find(props.payload, {
            dataKey: props.dataKey
        });
        return (
            <TooltipContainer color={value.color}>
                {props.dataKey !== props.forecastValue && (
                    <TooltipHeaderRow
                        title={t(
                            `tooltips:header.${props.unitsOrValues}.normal`
                        )}
                        value={<span>{value.payload[props.categoryAxis]}</span>}
                    />
                )}
                {props.dataKey === props.forecastValue && (
                    <TooltipHeaderRow
                        title={t(
                            `tooltips:header.${props.unitsOrValues}.forecast`
                        )}
                        value={<span>{value.payload[props.categoryAxis]}</span>}
                    />
                )}
                <Separator />
                {props.dataKey !== props.forecastValue && (
                    <TooltipContentRow
                        title={t('tooltips:content.sales')}
                        value={`${getValueInString(
                            Math.round(value.payload[props.dataKey]),
                            props.unitsOrValues
                        )}`}
                        color={value.color}
                    />
                )}
                {props.dataKey === props.forecastValue && (
                    <React.Fragment>
                        <TooltipContentRow
                            title={t('tooltips:content.sales')}
                            value={`${getValueInString(
                                Math.round(
                                    parseFloat(
                                        value.payload[props.forecastValue]
                                    )
                                ),
                                props.unitsOrValues
                            )}`}
                            color={value.color}
                        />
                        <TooltipContentRow
                            title={t('tooltips:content.max')}
                            value={`${getValueInString(
                                Math.round(
                                    parseFloat(
                                        value.payload[
                                            props.forecastIntervalValue
                                        ][1]
                                    )
                                ),
                                props.unitsOrValues
                            )}`}
                            color={value.color}
                        />
                        <TooltipContentRow
                            title={t('tooltips:content.min')}
                            value={`${getValueInString(
                                Math.round(
                                    value.payload[
                                        props.forecastIntervalValue
                                    ][0]
                                ),
                                props.unitsOrValues
                            )}`}
                            color={value.color}
                        />
                    </React.Fragment>
                )}
            </TooltipContainer>
        );
    } else return null;
});
