import React from 'react'
import styles from './remove-button-table-column.module.scss'
import { RemoveButton } from '../../../..'

export const RemoveButtonTableColumn = React.memo(
    ({ index, removeButtonText, onRemove }) => {
        return (
            <div
                className={`${styles['remove-button-table-column-container']}`}
            >
                <RemoveButton
                    index={index}
                    text={removeButtonText}
                    onRemove={onRemove}
                />
            </div>
        )
    }
)
