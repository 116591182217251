import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './YourOrganizations.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { PrimaryButton } from '../../../components';
import icoPlus from '../../../assets/images/ico-plus.svg';
import { SearchInput } from '../../../components/inputs/search-input/SearchInput';
import icoOrganization from '../../../assets/images/icoOrganization.svg';
import icoEdit from '../../../assets/images/ico-edit.svg';
import icoDelete from '../../../assets/images/ico-delete.svg';
import { useToggle } from '../../../hooks/useToggle';
import { useLocation } from 'react-router-dom';
import { CreateOrganizationModal } from '../../../components/modals/create-organization-modal/CreateOrganizationModal';
import { OrganizationCarousel } from '../../../components/organization/organization-carousel/OrganizationCarousel';
import { OrganizationDetails } from '../../../components/organization/organization-details/OrganizationDetails';
import { formatCurrency } from '../../../utils/math';

function generateMockData() {
    const countries = ['North America', 'Spain', 'Germany', 'Portugal', 'UK'];
    const cities = {
        'North America': ['New York', 'Los Angeles', 'Chicago', 'Houston'],
        Spain: ['Madrid', 'Barcelona', 'Valencia', 'Seville'],
        Germany: ['Berlin', 'Hamburg', 'Munich', 'Cologne'],
        Portugal: ['Lisbon', 'Porto', 'Faro', 'Coimbra'],
        UK: ['London', 'Manchester', 'Birmingham', 'Glasgow']
    };
    const admins = [
        'john.smith@outlook.com',
        'alice.brown@outlook.com',
        'robert.davis@outlook.com',
        'emma.wilson@outlook.com',
        'michael.johnson@outlook.com'
    ];
    const continent = {
        'North America': 'America',
        Spain: 'Europe',
        Germany: 'Europe',
        Portugal: 'Europe',
        UK: 'Europe'
    };
    // Base information for the organizations
    const baseName = 'Blue Corporation';
    const type = 'Subsidiary';
    const industry = 'Technology & Research';
    const status = 'Active';
    const keyContactName = 'John Smith';
    const keyContactPosition = 'Director';

    const teamList = {
        'North America': [
            {
                id: 1,
                teamName: 'Sales Chicago',
                teamManager: 'alice.brown@outlook.com',
                teamMembers: 10,
                revenue: '€ 100,000',
                customers: 100
            },
            {
                id: 2,
                teamName: 'Sales New York',
                teamManager: 'john.smith@outlook.com',
                teamMembers: 8,
                revenue: '€ 120,000',
                customers: 80
            }
        ],
        Spain: [
            {
                id: 3,
                teamName: 'Ventas Madrid',
                teamManager: 'carlos.garcia@outlook.com',
                teamMembers: 12,
                revenue: '€ 110,000',
                customers: 95
            },
            {
                id: 4,
                teamName: 'Ventas Barcelona',
                teamManager: 'laura.martinez@outlook.com',
                teamMembers: 15,
                revenue: '€ 130,000',
                customers: 120
            }
        ],
        Germany: [
            {
                id: 5,
                teamName: 'Vertrieb Berlin',
                teamManager: 'hans.muller@outlook.com',
                teamMembers: 14,
                revenue: '€ 140,000',
                customers: 110
            },
            {
                id: 6,
                teamName: 'Vertrieb Hamburg',
                teamManager: 'julia.schmidt@outlook.com',
                teamMembers: 10,
                revenue: '€ 125,000',
                customers: 85
            }
        ],
        Portugal: [
            {
                id: 7,
                teamName: 'Vendas Lisboa',
                teamManager: 'ana.silva@outlook.com',
                teamMembers: 11,
                revenue: '€ 115,000',
                customers: 90
            },
            {
                id: 8,
                teamName: 'Vendas Porto',
                teamManager: 'pedro.sousa@outlook.com',
                teamMembers: 13,
                revenue: '€ 135,000',
                customers: 105
            }
        ],
        UK: [
            {
                id: 9,
                teamName: 'Sales London',
                teamManager: 'emma.wilson@outlook.com',
                teamMembers: 9,
                revenue: '€ 105,000',
                customers: 100
            },
            {
                id: 10,
                teamName: 'Sales Manchester',
                teamManager: 'michael.johnson@outlook.com',
                teamMembers: 12,
                revenue: '€ 125,000',
                customers: 95
            }
        ]
    };

    // Generate organization cards
    const organizations = countries.map((country, i) => {
        const id = i + 1;
        return {
            id: id,
            organizationName: `${baseName} ${country}`,
            type: type,
            continent: continent[country],
            country: country,
            admin: admins[i],
            revenue: `${formatCurrency(
                Math.floor(Math.random() * 1000000) + 100000,
                'EUR'
            )}`, // Random revenue from 100,000 to 1,100,000
            teams: Math.floor(Math.random() * 10) + 1, // Random number of teams from 1 to 10
            city: cities[country][Math.floor(Math.random() * 4)], // Random city from the country
            numberOfEmployees: Math.floor(Math.random() * 1000) + 100, // Random number of employees from 100 to 1100
            keyContact: `${keyContactName}, ${keyContactPosition}`,
            industry: industry,
            status: status,
            keyPerformanceIndicators: {
                revenueGrowth: `${Math.floor(Math.random() * 20) + 1}% YOY`, // Random growth between 1% and 20%
                customerSatisfaction: `${Math.floor(Math.random() * 30) + 70}%` // Random satisfaction between 70% and 99%
            },
            teamList: teamList[country],
            recentActivity: `Launched new product in Q${
                Math.floor(Math.random() * 4) + 1
            } 2024`,
            name: `Organization ${id}`,
            members: id * 10,
            roles: id * 5,
            permissions: id * 20,
            pendingInvitations: id * 2,
            action: {
                id: id,
                open: false,
                options: [
                    {
                        name: 'edit',
                        icon: icoEdit
                    },
                    {
                        name: 'remove',
                        icon: icoDelete,
                        isRed: true
                    }
                ],
                reference: React.createRef()
            }
        };
    });

    return organizations;
}

const mockData = generateMockData();

export const YourOrganizations = () => {
    const { t } = useCustomTranslation();
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');
    const [organizationCards, setOrganizationCards] = useState(mockData);
    const [selectedCard, setSelectedCard] = useState(null);
    const [checkedOrganizationCards, setCheckedOrganizationCards] = useState(
        []
    );

    const [createOrganizationModal, setCreateOrganizationModal] =
        useState(false);
    const [editMode, toggleEditMode] = useToggle(false);

    const handleUpdate = useCallback(() => {
        setOrganizationCards(mockData);
    }, []);

    useEffect(() => {
        if (location.state?.from && location.state?.from === 'inviteMember') {
            setCreateOrganizationModal(true);
        }
    }, [location.state]);

    const handleSelectCard = useCallback(
        (orgId) => {
            if (orgId) {
                const selected = organizationCards.find(
                    (card) => card.id === orgId
                );
                setSelectedCard(selected);
            } else {
                setSelectedCard(null);
            }
        },
        [organizationCards]
    );

    const filteredData = useMemo(() => {
        return organizationCards.filter((card) => {
            return (
                [
                    'id',
                    'organizationName',
                    'type',
                    'continent',
                    'country',
                    'city',
                    'industry',
                    'status'
                ].filter((info) => {
                    return (
                        card[info]
                            .toString()
                            .toLowerCase()
                            .indexOf(searchValue.toLowerCase()) > -1
                    );
                }).length > 0
            );
        });
        // .filter((card) => {
        //     return filters.reduce((acc, filter) => {
        //         return (
        //             acc && filter.value.includes(_.get(card, filter.path))
        //         );
        //     }, true);
        // });
    }, [organizationCards, searchValue]);

    return (
        <>
            {createOrganizationModal && (
                <CreateOrganizationModal
                    onToggleModal={() => setCreateOrganizationModal(false)}
                />
            )}
            <div className={styles['your-organizations-container']}>
                <div className="row w-100 mx-0 dashboard-header-row">
                    <div className="col px-0 d-flex justify-content-between align-items-center">
                        {t('pages:yourOrganizations.title')}
                    </div>
                </div>
                <div
                    className="row mx-0 w-100 d-flex justify-content-between align-items-center"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col-auto px-0" style={{ width: '40rem' }}>
                        <SearchInput
                            value={searchValue}
                            placeholder={t('inputs:searchOrganization')}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                    <div className="col-auto px-0">
                        <PrimaryButton
                            text={t('buttons:createOrganization')}
                            icon={icoPlus}
                            onClick={() => setCreateOrganizationModal(true)}
                        />
                    </div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0">
                        <OrganizationCarousel
                            selectedCard={selectedCard}
                            onSelectCard={handleSelectCard}
                            cards={filteredData}
                            titleIcon={icoOrganization}
                            getTitleText={(card) => {
                                return card.organizationName;
                            }}
                            onToggleEditMode={toggleEditMode}
                            onUpdate={handleUpdate}
                        ></OrganizationCarousel>
                    </div>
                </div>
                {selectedCard && (
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <OrganizationDetails
                                {...selectedCard}
                                isEdit={editMode && selectedCard ? true : false}
                                onCancelEditMode={toggleEditMode}
                                onSaveEditMode={toggleEditMode}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
