import React, { useCallback } from 'react'
import { ComponentWrapper, Loader, Spinner } from '../..'
import { TableImprovement } from '../../tables/table-improvement/table-improvement'
import { useTable } from '../../../hooks/useTable'
import { useToggle } from '../../../hooks/useToggle'

/**
 * Displays comparison of sales between products according to the selected category
 */

const segmentSelectedCustomersMock = {
    attr: 'segmentSelectedCustomers',
    type: 'type-1',
    data: [
        {
            id: 1,
            name: 'John Doe',
            age: 25,
            gender: 'Male',
            education: 'High School',
            maritalStatus: 'Single',
            country: 'North America',
        },
    ],
    columns: [
        {
            path: 'name',
            label: '',
            sortable: true,
        },
        {
            path: 'age',
            label: '',
            sortable: true,
        },
        {
            path: 'gender',
            label: '',
            sortable: true,
        },
        {
            path: 'education',
            label: '',
            sortable: true,
        },
        {
            path: 'maritalStatus',
            label: '',
            sortable: true,
        },
        {
            path: 'country',
            label: '',
            sortable: true,
        },
    ],
}

export const SegmentSelectedCustomers = React.memo(({ onSelectGoal, isPreview, ...rest }) => {
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = React.useState('')
    const { value, setLoading, setValue: setData, pageChange: dataPageChange, clickActionIcon } = useTable(segmentSelectedCustomersMock)

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            clickActionIcon(attr, id)
            toggleModal(true)
            setModalType(option)
        },
        [toggleModal, clickActionIcon]
    )

    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper {...value} {...rest}>
                {value.loading && <Spinner />}
                {!value.loading && (
                    <TableImprovement
                        {...value}
                        {...rest}
                        onPageChange={dataPageChange}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                        onRowClick={onSelectGoal ? onSelectGoal : undefined}
                    />
                )}
            </ComponentWrapper>
        </div>
    )
})

SegmentSelectedCustomers.propTypes = {}
