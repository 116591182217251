export const clientAiMock = {
    hoverStyle: '',
    desc: '',
    customInfo: [],
    area: {
        areaAttr: [
            {
                dataKey: 'quantitativeAttr',
                strokeWidth: 3
            }
        ],
        categoryAxis: 'categoryAttr',
        data: [
            {
                quantitativeAttr: 1,
                categoryAttr: 0
            },
            {
                quantitativeAttr: 1,
                categoryAttr: 1
            },
            {
                quantitativeAttr: 3,
                categoryAttr: 2
            }
        ],
        chartType: 'risk',
        lineType: 'monotone',
        maxValue: 3,
        minValue: 1
    },
    attr: 'clientDetailsCard',
    disabled: true,
    titleText: '',
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: 'VIEW',
            options: []
        },
        options: {
            label: 'OPTIONS',
            options: []
        }
    },
    ring: {
        radius: 40,
        strokeWidth: 5,
        percentage: 75,
        risk: 'components:clientDetailsCard.risk3',
        type: 'risk',
        invert: true
    },
    noData: false,
    loading: true,
    visits: '-',
    calls: '-',
    emails: '-',
    issues: '-',
    clientSince: '17 Jan 2019',
    totalOrders: 23,
    totalRevenue: '€786.00',
    lastOrder: '11 Nov 2021',
    avgPerOrder: '€32.70',
    amountOrder: '-',
    lastPayment: '-',
    revenueOverdue: '-',
    paymentOverdue: '-',
    debt: '-',

    margin: {
        top: 0,
        right: 20,
        left: -20,
        bottom: -40
    },
    areaAttr: [
        {
            dataKey: 'revenue-positive',
            color: '#7dd081',
            strokeWidth: 2
        },
        {
            dataKey: 'revenue-neutral',
            color: '#f0bb5f',
            strokeWidth: 2
        },
        {
            dataKey: 'revenue-negative',
            color: '#db6162',
            strokeWidth: 2
        }
    ],
    categoryAxis: 'month',
    animationDuration: 500,
    areaData: [
        {
            time: '2022-10-01',
            month: 'Jan',
            'revenue-positive': 70,
            'revenue-neutral': 60,
            'revenue-negative': 50
        },
        {
            time: '2022-10-02',
            month: 'Fev',
            'revenue-positive': 90,
            'revenue-neutral': 40,
            'revenue-negative': 20
        },
        {
            time: '2022-10-03',
            month: 'Mar',
            'revenue-positive': 80,
            'revenue-neutral': 50,
            'revenue-negative': 40
        }
    ],
    dateGrouper: 'month',
    gradient: 0.6,
    lineType: 'monotone',
    maxValue: 80,
    minValue: 0,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'sentimentHistory',
    unitsOrValues: 'values',
    interactiondisabled: true,
    suffix: 'sentimentOverTimeAuxbar'
};
