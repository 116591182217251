import React from 'react'
import { has } from 'lodash'
import { getDots } from '../../../utils/string'
import { ActionButtonTableColumn } from './components/action-button-table-column/action-button-table-column'
import { AddButtonTableColumn } from './components/add-button-table-column/add-button-table-column'
import { AssignToTableColumn } from './components/assign-to-table-column/assign-to-table-column'
import { MemberIdTableColumn } from './components/member-id-table-column/member-id-table-column'
import { PriorityTableColumn } from './components/priority-table-column/priority-table-column'
import { RemoveButtonTableColumn } from './components/remove-button-table-column/remove-button-table-column'
import { RiskDistributionTableColumn } from './components/risk-distribution-table-column/risk-distribution-table-column'
import { RiskTableColumn } from './components/risk-table-column/risk-table-column'
import { SatisfactionTableColumn } from './components/satisfaction-table-column/satisfaction-table-column'
import { SelectBoxTableColumn } from './components/select-box-table-column/select-box-table-column'
import { SentimentBoxTableColumn } from './components/sentiment-box-table-column/sentiment-box-table-column'
import { SentimentChannelTableColumn } from './components/sentiment-channel-table-column/sentiment-channel-table-column'
import { StatusTableColumn } from './components/status-table-column/status-table-column'
import { TrendTableColumn } from './components/trend-table-column/trend-table-column'
import yesLabel from '../../../assets/images/yesLabel.svg'
import noLabel from '../../../assets/images/noLabel.svg'
import styles from './table-column-factory.module.scss'
import { ResendButtonTableColumn } from './components/resend-button-table-column/resend-button-table-column'
import { InvoiceStatusTableColumn } from './components/invoice-status-table-column/invoice-status-table-column'
import { InvoiceDownloadTableColumn } from './components/invoice-download-table-column/invoice-download-table-column'
import { DateTableColumn } from './components/date-table-column/date-table-column'
import { ApprovalStateTableColumn } from './components/approval-state-table-column/approval-state-table-column'
import { GoalDetailsTableColumn } from './components/goal-details-table-column/goal-details-table-column'
import { GoalValueTableColumn } from './components/goal-value-table-column/goal-value-table-column'
import { GoalDifferenceTableColumn } from './components/goal-difference-table-column/goal-difference-table-column'
import { GoalTimeLeftTableColumn } from './components/goal-time-left-table-column/goal-time-left-table-column'
import { GoalExpectedTableColumn } from './components/goal-expected-value-table-column/goal-expected-table-column'
import { TeamMemberNameTableColumn } from './components/team-member-name-table-column/team-member-name-table-column'
import { TeamMemberCustomersTableColumn } from './components/team-member-customers-table-column/team-member-customers-table-column'
import { TeamMemberRevenueTableColumn } from './components/team-member-revenue-table-column/team-member-revenue-table-column'
import { GoalTargetValueTableColumn } from './components/goal-target-value-table-column/goal-target-value-table-column'
import { GoalReachedValueTableColumn } from './components/goal-reached-value-table-column/goal-reached-value-table-column'
import { GoalAchievedTableColumn } from './components/goal-achieved-table-column/goal-achieved-table-column'
import { RoleAssignedMemberTableColumn } from './components/role-assigned-member-table-column/role-assigned-member-table-column'

export const TableColumnFactory = React.memo((props) => {
    switch (props.item.type) {
        case 'roleAssignedMember':
            return <RoleAssignedMemberTableColumn {...props} {...props.item} />
        case 'goalAchieved':
            return <GoalAchievedTableColumn {...props} {...props.item} />
        case 'goalReachedValue':
            return <GoalReachedValueTableColumn {...props} {...props.item} />
        case 'goalTargetValue':
            return <GoalTargetValueTableColumn {...props} {...props.item} />
        case 'teamMemberCustomers':
            return <TeamMemberCustomersTableColumn {...props} {...props.item} />
        case 'teamMemberRevenue':
            return <TeamMemberRevenueTableColumn {...props} {...props.item} />
        case 'teamMemberName':
            return <TeamMemberNameTableColumn {...props} {...props.item} />
        case 'goalDifference':
            return <GoalDifferenceTableColumn {...props} {...props.item} />
        case 'goalCurrentValue':
            return <GoalValueTableColumn {...props} {...props.item} />
        case 'goalExpectedValue':
            return <GoalExpectedTableColumn {...props} {...props.item} />
        case 'goalTimeLeft':
            return <GoalTimeLeftTableColumn {...props} {...props.item} />
        case 'goalDetails':
            return <GoalDetailsTableColumn {...props} {...props.item} />
        case 'approvalState':
            return <ApprovalStateTableColumn {...props} {...props.item} />
        case 'date':
            return <DateTableColumn {...props} {...props.item} />
        case 'invoiceStatus':
            return <InvoiceStatusTableColumn {...props} {...props.item} />
        case 'invoiceDownload':
            return <InvoiceDownloadTableColumn {...props} {...props.item} />
        case 'resend':
            return <ResendButtonTableColumn {...props} {...props.item} />
        case 'action':
            return <ActionButtonTableColumn {...props} {...props.item} />
        case 'add':
            return <AddButtonTableColumn {...props} {...props.item} />
        case 'remove':
            return <RemoveButtonTableColumn {...props} {...props.item} />
        case 'risk':
            return <RiskTableColumn {...props} {...props.item} />
        case 'riskDistribution':
            return <RiskDistributionTableColumn {...props} {...props.item} />
        case 'satisfaction':
            return <SatisfactionTableColumn {...props} {...props.item} />
        case 'priority':
            return <PriorityTableColumn {...props} {...props.item} />
        case 'status':
            return <StatusTableColumn {...props} {...props.item} />
        case 'assignTo':
            return <AssignToTableColumn {...props} {...props.item} />
        case 'memberId':
            return <MemberIdTableColumn {...props} {...props.item} />
        case 'selectBox':
            return <SelectBoxTableColumn {...props} {...props.item} />
        case 'sentimentPositive':
            return <SentimentBoxTableColumn {...props} {...props.item} sentiment={'positive'} />
        case 'sentimentNeutral':
            return <SentimentBoxTableColumn {...props} {...props.item} sentiment={'neutral'} />
        case 'sentimentNegative':
            return <SentimentBoxTableColumn {...props} {...props.item} sentiment={'negative'} />
        case 'sentimentChannel':
            return <SentimentChannelTableColumn {...props} {...props.item} />
        case 'trend':
            return <TrendTableColumn {...props} {...props.item} />
        default:
            // Check if the value is a boolean and render the appropriate label
            const booleanValue = has(props.item.el, props.item.column.path) ? props.item.el[props.item.column.path] : null
            if (typeof booleanValue === 'boolean') {
                return (
                    <div className="w-100 d-flex justify-content-start align-items-center">
                        <img
                            src={booleanValue ? yesLabel : noLabel}
                            alt={booleanValue ? 'Yes' : 'No'}
                            style={{
                                width: '5rem',
                            }}
                        />
                    </div>
                )
            } else {
                return (
                    <span className={`${styles['table-column-factory-container__content']}`}>
                        {(has(props.item.el, props.item.column.path) &&
                            props.item.el[props.item.column.path] &&
                            props.item.el[props.item.column.path].toString().substring(0, 100) +
                                getDots(props.item.el[props.item.column.path].toString())) ||
                            '-'}
                    </span>
                )
            }
    }
})
