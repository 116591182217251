export const goalPerformanceOverTimeMock = {
    attr: 'goalPerformanceOverTimeChart',
    tooltipType: 'goalPerformanceOverTimeChart',
    areaAttr: [
        {
            dataKey: 'goalProgression',
            color: '#2cd9c5',
            strokeWidth: 2,
            isGradient: true,
            fillOpacity: 0.5
        },
        {
            dataKey: 'goalPrediction',
            color: '#4ba3e0',
            strokeWidth: 2,
            isGradient: true,
            fillOpacity: 0.5
        }
    ],
    categoryAxis: 'month',
    legend: [
        {
            name: 'goalProgression',
            color: '#2cd9c5'
        },
        {
            name: 'goalPrediction',
            color: '#4ba3e0'
        }
    ],
    data: [
        {
            goalProgression: 70.59,
            goalPrediction: 64.74,
            time: '2022-02-01',
            day: '01 Fev 2022',
            month: 'Feb 2022',
            year: '2022'
        },
        {
            goalProgression: 76.99,
            goalPrediction: 70.24,
            time: '2022-03-01',
            day: '01 Mar 2022',
            month: 'Mar 2022',
            year: '2022'
        },
        {
            goalProgression: 72.3,
            goalPrediction: 65.55,
            time: '2022-04-01',
            day: '01 Abr 2022',
            month: 'Apr 2022',
            year: '2022'
        },
        {
            goalProgression: 87.42,
            goalPrediction: 80.67,
            time: '2022-05-01',
            day: '01 Maio 2022',
            month: 'May 2022',
            year: '2022'
        },
        {
            goalProgression: 84.44,
            goalPrediction: 77.69,
            time: '2022-06-01',
            day: '01 Jun 2022',
            month: 'Jun 2022',
            year: '2022'
        },
        {
            goalProgression: 83.88,
            goalPrediction: 77.13,
            time: '2022-07-01',
            day: '01 Jul 2022',
            month: 'Jul 2022',
            year: '2022'
        },
        {
            goalProgression: 85.72,
            goalPrediction: 78.97,
            time: '2022-08-01',
            day: '01 Ago 2022',
            month: 'Aug 2022',
            year: '2022'
        },
        {
            goalProgression: 85.44,
            goalPrediction: 78.69,
            time: '2022-09-01',
            day: '01 Set 2022',
            month: 'Sep 2022',
            year: '2022'
        },
        {
            goalProgression: 93.12,
            goalPrediction: 86.37,
            time: '2022-10-01',
            day: '01 Out 2022',
            month: 'Oct 2022',
            year: '2022'
        },
        {
            goalProgression: 94.9,
            goalPrediction: 88.15,
            time: '2022-11-01',
            day: '01 Nov 2022',
            month: 'Nov 2022',
            year: '2022'
        },
        {
            goalProgression: 92.93,
            goalPrediction: 86.18,
            time: '2022-12-01',
            day: '01 Dez 2022',
            month: 'Dec 2022',
            year: '2022'
        },
        {
            goalProgression: 92.97,
            goalPrediction: 86.22,
            time: '2023-01-01',
            day: '01 Jan 2023',
            month: 'Jan 2023',
            year: '2023'
        },
        {
            goalProgression: 94.6,
            goalPrediction: 87.85,
            time: '2023-02-01',
            day: '01 Fev 2023',
            month: 'Feb 2023',
            year: '2023'
        },
        {
            goalProgression: 105.95,
            goalPrediction: 99.2,
            time: '2023-03-01',
            day: '01 Mar 2023',
            month: 'Mar 2023',
            year: '2023'
        },
        {
            goalProgression: 93.14,
            goalPrediction: 86.39,
            time: '2023-04-01',
            day: '01 Abr 2023',
            month: 'Apr 2023',
            year: '2023'
        },
        {
            goalProgression: 107.53,
            goalPrediction: 100.78,
            time: '2023-05-01',
            day: '01 Maio 2023',
            month: 'May 2023',
            year: '2023'
        },
        {
            goalProgression: 104.11,
            goalPrediction: 97.36,
            time: '2023-06-01',
            day: '01 Jun 2023',
            month: 'Jun 2023',
            year: '2023'
        }
    ]
};
