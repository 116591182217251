import React from 'react'
import styles from './role-assigned-member-table-column.module.scss'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const RoleAssignedMemberTableColumn = React.memo(({ el, type }) => {
    return (
        <div className={styles['role-assigned-member-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <img src={el[type][1]} />
                    <span className={styles['role-assigned-member-table-column-container__text']}>{el[type][0]}</span>
                </div>
            </div>
        </div>
    )
})
