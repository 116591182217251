import React from 'react'
import styles from './goal-achieved-table-column.module.scss'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const GoalAchievedTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation()
    return (
        <div className={styles['goal-achieved-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    <div className="row mx-0 w-100">
                        <div className="col-auto px-0" style={{ marginRight: '1rem' }}>
                            <div
                                className={
                                    el[type] === true
                                        ? styles['goal-achieved-table-column-container__statusBar'] + ' ' + styles['achieved']
                                        : styles['goal-achieved-table-column-container__statusBar'] + ' ' + styles['notAchieved']
                                }
                            ></div>
                        </div>
                        <div className="col-auto px-0">
                            <span className={styles['goal-achieved-table-column-container__text']}>
                                {el[type] ? t('columns:achieved') : t('columns:notAchieved')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
