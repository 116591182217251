import React, { useCallback, useEffect, useState } from 'react'
import styles from './TimeseriesDataManagerPopup.module.scss'
import Scrollable from '../../scrolling/scrollable'
import { TableInput } from '../../inputs/table-input/TableInput'
import { AddNewTimeseriesEntryModal } from '../../modals/add-new-timeseries-entry-modal/add-new-timeseries-entry-modal'
import { RemoveEntryModal } from '../../modals/remove-entry-modal/remove-entry-modal'

export const TimeseriesDataManagerPopup = ({ componentRef, onClosePopup, type = 'dynamicInfo' }) => {
    const [addNewEntry, setAddNewEntry] = useState(false)
    const [removeEntryModal, setRemoveEntryModal] = useState(false)
    const [idsToRemove, setIdsToRemove] = useState([])

    const handleOutsideClick = useCallback(
        (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target) && !addNewEntry) {
                event.preventDefault()
                event.stopPropagation()
                onClosePopup()
            }
        },
        [onClosePopup, componentRef, addNewEntry]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [handleOutsideClick])

    return (
        <>
            {addNewEntry && <AddNewTimeseriesEntryModal onCloseModal={() => setAddNewEntry(false)} />}
            {removeEntryModal && <RemoveEntryModal onCloseModal={() => setRemoveEntryModal(false)} ids={idsToRemove} />}
            <div className={`${styles['timeseries-data-manager-popup-container']}`}>
                <div className="row mx-0 w-100" style={{ height: '30rem' }}>
                    <div className="col px-0 h-100">
                        <TableInput
                            title={type === 'dynamicInfo' ? 'Dynamic Info' : 'User Forecasts'}
                            onAdd={() => setAddNewEntry(true)}
                            onRemove={(element) => {
                                setIdsToRemove(element.id)
                                setRemoveEntryModal(true)
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
