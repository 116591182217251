import React, { useEffect, useState } from 'react';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { TeamsService } from '../../../temp/test';
import styles from './my-teams.module.scss';
import {
    LoaderForeground,
    ManagerTeamListing,
    Spinner,
    TeamMembersList
} from '../../../components';

export const MyTeams = React.memo(() => {
    const isMounted = useIsMounted();
    const [loading, toggleLoading] = useToggle(true);
    const [selectedTeamId, setSelectedTeamId] = useState();
    const [selectedTeamName, setSelectedTeamName] = useState('');
    const [trigger, toggleTrigger] = useToggle(false);

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleSelectTeam = async (teamId) => {
        if (isMounted.current) setSelectedTeamId(teamId);
        try {
            const teamBackData = await TeamsService.getApiTeams({
                params: { id: [teamId] }
            });
            if (isMounted.current) setSelectedTeamName(teamBackData[0].name);
        } catch (err) {
            errorHandler(err);
        }
    };
    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['my-teams-container']}`}
                >
                    <div className="col px-0 h-100 ">
                        <div
                            className={`row w-100 mx-0 mb-3 ${styles['my-teams-container__first-row']}`}
                        >
                            <div className="col-5 px-0 pe-4 h-inherit justify-content-center align-items-center">
                                <ManagerTeamListing
                                    selectedTeamId={selectedTeamId}
                                    onSelectTeam={handleSelectTeam}
                                    trigger={trigger}
                                    toggleTrigger={toggleTrigger}
                                />
                            </div>
                            <div className="col px-0 h-inherit justify-content-center align-items-center">
                                <TeamMembersList
                                    selectedTeamId={selectedTeamId}
                                    selectedTeamName={selectedTeamName}
                                    trigger={trigger}
                                    toggleTrigger={toggleTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

MyTeams.propTypes = {};

export default MyTeams;
