import React from 'react';

export const salesDonutMock = {
    hoverStyle: '',
    desc: '',
    loading: true,
    title: '',
    titleColor: '#606060',
    content: '5',
    contentType: '',
    contentColor: '#06a4e7',
    contentFractionTotal: '',
    contentAux: '',
    contentAuxType: '',
    contentAuxColor: '#06a4e7',
    contentAuxFractionTotal: '',
    interval: '1M',
    actions: {},
    disabled: true,
    percentage: '',
    areaAttr: [
        {
            dataKey: 'exampleAttr1',
            color: '#06a4e7',
            strokeWidth: 3
        }
    ],
    categoryAxis: 'categoryAttr',
    data: [
        { exampleAttr1: 10, categoryAttr: 'yo1' },
        { exampleAttr1: 15, categoryAttr: 'yo2' },
        { exampleAttr1: 5, categoryAttr: 'yo2.1' },
        { exampleAttr1: 30, categoryAttr: 'yo2.2' },
        { exampleAttr1: 5, categoryAttr: 'yo3' }
    ],
    lineType: 'monotone',
    maxValue: 30,
    minValue: 5,
    attr: 'salesDonutCard',
    prefix: 'salesDonutCard',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: []
        },
        options: {
            label: '',
            options: []
        }
    }
};
