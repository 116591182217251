import { useTranslation } from 'react-i18next';
import { getFromLS } from '../utils/localStorage';
import { useCallback } from 'react';

export function useCustomTranslation(checkAliases = true) {
    const { t: i18nTranslation } = useTranslation();

    const t = useCallback(
        (...args) => {
            const result = i18nTranslation(...args);
            let newResult = result;
            if (checkAliases) {
                const aliases = getFromLS('aliases');
                // Define regular expressions using an object or switch statement
                if (aliases && aliases.length > 0) {
                    aliases.forEach((alias) => {
                        const escapedModelName = alias.model_name.replace(
                            /[.*+?^${}()|[\]\\]/g,
                            '\\$&'
                        );
                        const regex = new RegExp(escapedModelName, 'g');
                        if (alias.model_name) {
                            newResult = newResult.replace(regex, alias.alias);
                        }
                    });
                }
            }
            return newResult;
        },
        [i18nTranslation, checkAliases]
    );

    return { t };
}
