import React, { useState } from 'react'
import { Modal } from '../modal/modal'
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button'
import { PrimaryButton } from '../../buttons/primary-button/primary-button'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { DragElementsInputs } from '../../inputs/drag-elements-input/drag-elements-input'

export const EditTableColumnsModal = React.memo(({ onCloseModal }) => {
    const { t } = useCustomTranslation()
    const [elementsToAdd, setElementsToAdd] = useState([
        {
            id: 1,
            text: 'revenue',
        },
        {
            id: 2,
            text: 'unitsSold',
        },
        {
            id: 3,

            text: 'recency',
        },
        {
            id: 4,
            text: 'frequency',
        },
        {
            id: 5,
            text: 'monetary',
        },
    ])

    const [elementsAdded, setElementsAdded] = useState([
        {
            id: 6,
            text: 'age',
        },
        {
            id: 7,
            text: 'gender',
        },
        { id: 8, text: 'education' },
        { id: 9, text: 'maritalStatus' },
        {
            id: 10,
            text: 'country',
        },
    ])

    const handleAddUser = (user) => {
        setElementsAdded([...elementsAdded, user])
    }

    const handleRemoveUser = (user) => {
        setElementsAdded(elementsAdded.filter((u) => u.email !== user.email))
    }

    return (
        <Modal
            onToggleModal={onCloseModal}
            noScrollingElements={
                <div className="row mx-0 w-100 d-flex justify-content-end">
                    <div className="col-auto px-0" style={{ marginRight: '2rem' }}>
                        <TerciaryButton text={t(`buttons:cancel`)} onClick={onCloseModal} />
                    </div>
                    <div className="col-auto px-0">
                        <PrimaryButton text={t(`buttons:save`)} onClick={() => {}} />
                    </div>
                </div>
            }
        >
            <div className="h-100 d-flex flex-column justify-content-center align-items-center" style={{ width: '80rem' }}>
                <div className="row mx-0 w-100 flex-grow-1" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0">
                        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <div className="row mx-0 w-100 d-flex justify-content-center">
                                <div className="col-auto px-0">
                                    <span className={'modal-title'}>{t(`modals:editTableColumns.title`)}</span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100 d-flex justify-content-center"
                                style={{
                                    marginTop: '4rem',
                                    height: '45rem',
                                }}
                            >
                                <div className="col px-0">
                                    <DragElementsInputs
                                        elementsLimit={5}
                                        elementsToAddTitle={t('modals:editTableColumns.title1')}
                                        elementsToAddDescription={t('modals:editTableColumns.description1')}
                                        elementsAddedTitle={t('modals:editTableColumns.title2')}
                                        elementsAddedDescription={t('modals:editTableColumns.description2')}
                                        onSetElementsToAdd={setElementsToAdd}
                                        onSetElementsAdded={setElementsAdded}
                                        elementsToAdd={elementsToAdd}
                                        elementsAdded={elementsAdded}
                                        warningText={t('modals:editTableColumns.warning')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
})
