import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styles from './date-picker-new.module.scss'
import _ from 'lodash'
import icoArrow from '../../../assets/images/dropdown-arrow.svg'
import { fromIsoDate, toIsoDate, toIsoDateFromDate } from '../../../utils/format'

const getDays = (currentDate) => {
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    const firstDayCurrentMonth = new Date(currentYear, currentMonth, 1)
    const firstDayNextMonth = new Date(currentYear, currentMonth + 1, 1)
    currentDate.setMonth(currentDate.getMonth() - 1)
    const lastDayCurrentMonth = new Date(currentYear, currentMonth + 1, 0)
    const lastDayLastMonth = new Date(currentYear, currentMonth, 0)
    const days = []
    _.range(lastDayLastMonth.getDate() - lastDayLastMonth.getDay(), lastDayLastMonth.getDate() + 1).map((el) => {
        days.push({ month: 'last', day: el })
        return null
    })

    _.range(firstDayCurrentMonth.getDate(), lastDayCurrentMonth.getDate() + 1).map((el) => {
        days.push({ month: 'current', day: el })
        return null
    })

    _.range(firstDayNextMonth.getDate(), firstDayNextMonth.getDate() + (7 - lastDayCurrentMonth.getDay() - 1)).map((el) => {
        days.push({ month: 'next', day: el })
        return null
    })
    return days
}

const getDayFromInput = (value, initialDate) => {
    return (
        (value && value.split('/')[0] && parseInt(value.split('/')[0])) ||
        (initialDate &&
            fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[0] &&
            parseInt(fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[0])) ||
        new Date().getDate()
    )
}

const getMonthFromInput = (value, initialDate) => {
    return (
        (value && value.split('/')[1] && parseInt(value.split('/')[1])) ||
        (initialDate &&
            fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[1] &&
            parseInt(fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[1])) ||
        new Date().getMonth() + 1
    )
}

const getYearFromInput = (value, initialDate) => {
    return (value && value.split('/')[2]) || (initialDate && fromIsoDate(toIsoDateFromDate(initialDate)).split('/')[2]) || new Date().getFullYear()
}

function compareDates(date1, date2) {
    const year1 = date1.getFullYear()
    const month1 = date1.getMonth()
    const day1 = date1.getDate()

    const year2 = date2.getFullYear()
    const month2 = date2.getMonth()
    const day2 = date2.getDate()

    if (year1 === year2 && month1 === month2 && day1 === day2) {
        return 0 // Dates are equal
    } else if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
        return -1 // date1 is earlier than date2
    } else {
        return 1 // date1 is later than date2
    }
}

const monthTranslator = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
}

const monthShortTranslator = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez',
}

export const DatePickerDays = React.memo(({ name, value, onChangeInput, initialDate, finalDate }) => {
    const [days, setDays] = useState(null)
    const [datePickerDay, setDatePickerDay] = useState(null)
    const [datePickerMonth, setDatePickerMonth] = useState(null)
    const [datePickerYear, setDatePickerYear] = useState(null)
    const handleChangeToPreviousMonth = () => {
        const newDate = new Date(datePickerYear, datePickerMonth - 1, datePickerDay)
        newDate.setMonth(newDate.getMonth() - 1)
        setDatePickerDay(newDate.getDate())
        setDatePickerMonth(newDate.getMonth() + 1)
        setDatePickerYear(newDate.getFullYear())
        setDays(getDays(newDate))
    }
    const handleChangeToNextMonth = () => {
        const newDate = new Date(datePickerYear, datePickerMonth - 1, datePickerDay)
        newDate.setMonth(newDate.getMonth() + 1)
        setDatePickerDay(newDate.getDate())
        setDatePickerMonth(newDate.getMonth() + 1)
        setDatePickerYear(newDate.getFullYear())
        setDays(getDays(newDate))
    }

    const handleSaveNewDateWithDay = (day) => {
        onChangeInput(day + '/' + datePickerMonth + '/' + datePickerYear, name, 'date')
    }

    const checkForInitialAndFinalDateAux = (el2) => {
        let initialGood = false
        let finalGood = false
        if (!initialDate) {
            initialGood = false
        } else if (
            initialDate &&
            compareDates(new Date(datePickerYear, datePickerMonth === 0 ? 11 : datePickerMonth - 1, el2.day), initialDate) === -1
        ) {
            initialGood = true
        }
        if (!finalDate) {
            finalGood = false
        } else if (finalDate && compareDates(new Date(datePickerYear, datePickerMonth === 0 ? 11 : datePickerMonth - 1, el2.day), finalDate) === 1) {
            finalGood = true
        }
        if (initialGood || finalGood) {
            return true
        }
        return false
    }

    const checkForInitialAndFinalDate = (el2) => {
        let initialGood = false
        let finalGood = false
        if (
            (initialDate &&
                (compareDates(new Date(datePickerYear, datePickerMonth === 0 ? 11 : datePickerMonth - 1, el2.day), initialDate) === 1 ||
                    compareDates(new Date(datePickerYear, datePickerMonth === 0 ? 11 : datePickerMonth - 1, el2.day), initialDate) === 0) &&
                el2.month === 'current') ||
            (!initialDate && el2.month === 'current')
        ) {
            initialGood = true
        }
        if (
            (finalDate &&
                (compareDates(new Date(datePickerYear, datePickerMonth === 0 ? 11 : datePickerMonth - 1, el2.day), finalDate) === -1 ||
                    compareDates(new Date(datePickerYear, datePickerMonth === 0 ? 11 : datePickerMonth - 1, el2.day), finalDate) === 0) &&
                el2.month === 'current') ||
            (!finalDate && el2.month === 'current')
        ) {
            finalGood = true
        }
        if (initialGood && finalGood) {
            return true
        }
        return false
    }

    useEffect(() => {
        const currentDate = (value && new Date(toIsoDate(value))) || (initialDate && new Date(initialDate)) || new Date()
        setDays(getDays(currentDate))
        setDatePickerDay(getDayFromInput(value, initialDate))
        setDatePickerMonth(getMonthFromInput(value, initialDate))
        setDatePickerYear(getYearFromInput(value, initialDate))
    }, [value, initialDate])

    if (datePickerMonth && datePickerYear) {
        return (
            <div style={{ padding: '1rem' }}>
                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                    <div className="col-auto px-0">
                        <img
                            src={icoArrow}
                            alt=""
                            style={{
                                transform: 'rotate(90deg)',
                                cursor: 'pointer',
                                width: '2.4rem',
                            }}
                            onClick={handleChangeToPreviousMonth}
                        />
                    </div>
                    <div className="col px-0"></div>
                    <div className="col-auto px-0">
                        <span className={`${styles['date-picker-new-container__title']}`}>{monthTranslator[datePickerMonth]}</span>{' '}
                        <span className={`${styles['date-picker-new-container__title']}`}>{datePickerYear}</span>
                    </div>
                    <div className="col px-0"></div>
                    <div className="col-auto px-0">
                        <img
                            src={icoArrow}
                            alt=""
                            style={{
                                transform: 'rotate(-90deg)',
                                cursor: 'pointer',
                                width: '2.4rem',
                            }}
                            onClick={handleChangeToNextMonth}
                        />
                    </div>
                </div>
                <hr></hr>
                <div className={`row mx-0 w-100 ${styles['date-picker-new-container__row-container']}`}>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            D
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            S
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            T
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            Q
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            Q
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            S
                        </span>
                    </div>
                    <div
                        className={`col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-title']}`}
                    >
                        <span className={`${styles['date-picker-new-container__row-container__col-container__title']} ${styles['disabled-title']}`}>
                            S
                        </span>
                    </div>
                </div>
                {_.chunk(days, 7).map((el, index) => {
                    return (
                        <div
                            key={index}
                            className={`row mx-0 w-100 ${styles['date-picker-new-container__row-container']}`}
                            style={index === _.chunk(days, 7).length - 1 ? { marginBottom: '2rem' } : {}}
                        >
                            {el.map((el2, index2) => {
                                return (
                                    <div
                                        key={index2}
                                        className={
                                            checkForInitialAndFinalDate(el2)
                                                ? datePickerDay === el2.day
                                                    ? `col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['active']}`
                                                    : `col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']}`
                                                : `col d-flex justify-content-center align-items-center ${styles['date-picker-new-container__row-container__col-container']} ${styles['disabled-text']}`
                                        }
                                        onClick={
                                            el2.month === 'current'
                                                ? () => {
                                                      handleSaveNewDateWithDay(el2.day)
                                                      setDatePickerDay(el2.day)
                                                  }
                                                : undefined
                                        }
                                    >
                                        <span
                                            className={
                                                checkForInitialAndFinalDateAux(el2) || el2.month !== 'current'
                                                    ? `${styles['date-picker-new-container__row-container__col-container__text']} ${styles['disabled-text']}`
                                                    : `${styles['date-picker-new-container__row-container__col-container__text']}`
                                            }
                                        >
                                            {el2.day}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return null
    }
})

const DatePickerMonths = React.memo(({ name, value, onChangeInput, initialDate }) => {
    const [datePickerMonth, setDatePickerMonth] = useState(null)
    const [datePickerYear, setDatePickerYear] = useState(null)

    const handleChangeToPreviousYear = () => {
        setDatePickerYear((prevYear) => prevYear - 1)
    }

    const handleChangeToNextYear = () => {
        setDatePickerYear((prevYear) => prevYear + 1)
    }

    const handleSaveNewDateWithMonth = (month) => {
        setDatePickerMonth(month)
        onChangeInput(month + ' ' + datePickerYear, name, 'date')
    }

    useEffect(() => {
        const currentDate = value ? new Date(value.split(' ')[1], value.split(' ')[0] - 1, 1) : new Date()
        setDatePickerMonth(currentDate.getMonth() + 1) // JavaScript months are 0-indexed
        setDatePickerYear(currentDate.getFullYear())
    }, [initialDate, value])

    return (
        <div style={{ padding: '1rem' }}>
            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col-auto px-0">
                    <img
                        src={icoArrow}
                        alt=""
                        style={{
                            transform: 'rotate(90deg)',
                            cursor: 'pointer',
                            width: '2.4rem',
                        }}
                        onClick={handleChangeToPreviousYear}
                    />
                </div>
                <div className="col px-0"></div>
                <div className="col-auto px-0">
                    <span className={`${styles['date-picker-new-container__title']}`}>{datePickerYear}</span>
                </div>
                <div className="col px-0"></div>
                <div className="col-auto px-0">
                    <img
                        src={icoArrow}
                        alt=""
                        style={{
                            transform: 'rotate(-90deg)',
                            cursor: 'pointer',
                            width: '2.4rem',
                        }}
                        onClick={handleChangeToNextYear}
                    />
                </div>
            </div>
            <hr></hr>
            <div className="row mx-0 w-100">
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                    <div key={month} className="col-3 d-flex justify-content-center align-items-center" style={{ padding: '0.5rem' }}>
                        <div
                            className={
                                datePickerMonth === month
                                    ? `${styles['date-picker-new-container__row-container__col-container']} ${styles['month']} ${styles['active']}`
                                    : `${styles['date-picker-new-container__row-container__col-container']} ${styles['month']}`
                            }
                            onClick={() => handleSaveNewDateWithMonth(month)}
                        >
                            {monthShortTranslator[month]}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
})

const DatePickerQuarters = React.memo(({ name, value, onChangeInput, initialDate, finalDate }) => {
    const [datePickerQuarter, setDatePickerQuarter] = useState(null)
    const [datePickerYear, setDatePickerYear] = useState(null)

    const handleChangeToPreviousYear = () => {
        setDatePickerYear((prevYear) => prevYear - 1)
    }

    const handleChangeToNextYear = () => {
        setDatePickerYear((prevYear) => prevYear + 1)
    }

    const handleSaveNewDateWithQuarter = (quarter) => {
        setDatePickerQuarter(quarter)
        onChangeInput(quarter + ' ' + datePickerYear, name, 'date')
    }

    useEffect(() => {
        // knowing that value is something like "1 2021"
        const currentDate = value ? new Date(value.split(' ')[1], (value.split(' ')[0] - 1) * 3, 1) : new Date()
        // Determine the quarter from the current date
        const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1
        setDatePickerQuarter(currentQuarter)
        setDatePickerYear(currentDate.getFullYear())
    }, [initialDate, value])

    if (datePickerQuarter && datePickerYear) {
        return (
            <div style={{ padding: '1rem' }}>
                <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                    <div className="col-auto px-0">
                        <img
                            src={icoArrow}
                            alt=""
                            style={{
                                transform: 'rotate(90deg)',
                                cursor: 'pointer',
                                width: '2.4rem',
                            }}
                            onClick={handleChangeToPreviousYear}
                        />
                    </div>
                    <div className="col px-0"></div>
                    <div className="col-auto px-0">
                        <span className={`${styles['date-picker-new-container__title']}`}>{datePickerYear}</span>
                    </div>
                    <div className="col px-0"></div>
                    <div className="col-auto px-0">
                        <img
                            src={icoArrow}
                            alt=""
                            style={{
                                transform: 'rotate(-90deg)',
                                cursor: 'pointer',
                                width: '2.4rem',
                            }}
                            onClick={handleChangeToNextYear}
                        />
                    </div>
                </div>
                <hr></hr>
                <div className="row mx-0 w-100">
                    {['Q1', 'Q2', 'Q3', 'Q4'].map((quarter, index) => (
                        <div key={index} className="col-3 d-flex justify-content-center align-items-center" style={{ padding: '0.5rem' }}>
                            <div
                                className={
                                    datePickerQuarter === index + 1
                                        ? `${styles['date-picker-new-container__row-container__col-container']} ${styles['quarter']} ${styles['active']}`
                                        : `${styles['date-picker-new-container__row-container__col-container']} ${styles['quarter']}`
                                }
                                onClick={() => handleSaveNewDateWithQuarter(index + 1)}
                            >
                                {quarter}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    } else {
        return null
    }
})

const DatePickerYears = React.memo(({ name, value, onChangeInput, initialYear, finalYear }) => {
    const [selectedYear, setSelectedYear] = useState(null)

    const handleSaveNewDateWithYear = (year) => {
        onChangeInput(year.toString(), name, 'date')
        setSelectedYear(year)
    }

    useEffect(() => {
        const currentYear = value ? parseInt(value, 10) : new Date().getFullYear()
        setSelectedYear(currentYear)
    }, [value])

    const generateYearRange = (start, end) => {
        const years = []
        for (let year = start; year <= end; year++) {
            years.push(year)
        }
        return years
    }

    const years = generateYearRange(initialYear, finalYear)

    return (
        <div style={{ padding: '1rem' }}>
            <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                <div className="col-auto px-0">
                    <span className="date-picker-title">Year</span>
                </div>
            </div>
            <hr></hr>
            <div className="row mx-0 w-100">
                {years.map((year) => (
                    <div key={year} className="col-3 d-flex justify-content-center align-items-center" style={{ padding: '1rem' }}>
                        <div
                            className={
                                selectedYear === year
                                    ? `${styles['date-picker-new-container__row-container__col-container']} ${styles['year']} ${styles['active']}`
                                    : `${styles['date-picker-new-container__row-container__col-container']} ${styles['year']}`
                            }
                            onClick={() => handleSaveNewDateWithYear(year)}
                        >
                            {year}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
})

export const DatePickerNew = React.memo(
    ({ name, value, onChangeInput, reference, parentRef, onCloseTimePicker, initialDate, finalDate, pickerType }) => {
        const [parentTop, setParentTop] = useState(null)
        const [parentLeft, setParentLeft] = useState(null)

        useEffect(() => {
            if (parentRef.current) {
                const parentRect = parentRef.current.getBoundingClientRect()
                setParentTop(parentRect.top)
                setParentLeft(parentRect.left)
            }
        }, [parentRef])

        const handleOutsideClick = useCallback(
            (event) => {
                if (parentRef.current && !parentRef.current.contains(event.target)) {
                    onCloseTimePicker()
                    event.stopPropagation()
                    event.preventDefault()
                }
            },
            [onCloseTimePicker, parentRef]
        )

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick)
            return function cleanup() {
                document.removeEventListener('mousedown', handleOutsideClick)
            }
        }, [handleOutsideClick])

        if (parentTop && parentLeft) {
            return (
                <div
                    className={`${styles['date-picker-new-container']}`}
                    ref={reference}
                    style={{
                        top: `calc(${parentTop}px + 4.5rem + 1rem)`,
                        right: `calc(${parentLeft}px + 1rem)`,
                        left: 'unset',
                        bottom: undefined,
                        zIndex: 1000000,
                    }}
                >
                    {pickerType === 'day' && (
                        <DatePickerDays name={name} initialDate={initialDate} finalDate={finalDate} value={value} onChangeInput={onChangeInput} />
                    )}
                    {pickerType === 'month' && (
                        <DatePickerMonths name={name} initialDate={initialDate} finalDate={finalDate} value={value} onChangeInput={onChangeInput} />
                    )}
                    {pickerType === 'quarter' && (
                        <DatePickerQuarters name={name} initialDate={initialDate} finalDate={finalDate} value={value} onChangeInput={onChangeInput} />
                    )}
                    {pickerType === 'year' && (
                        <DatePickerYears name={name} initialYear={2020} finalYear={2025} value={value} onChangeInput={onChangeInput} />
                    )}
                </div>
            )
        } else {
            return null
        }
    }
)
