import React, { useEffect, useMemo } from 'react';
import styles from './auxbar-approval-history.module.scss';
import icoClose from '../../../assets/images/icoApprovalHistory.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import Scrollable from '../../scrolling/scrollable';
import { useHistoryGoal } from '../../../providers/history-goal-context';
import { useAuxBar } from '../../../providers/aux-bar-context';

function generateRandomEvents(startDate) {
    const events = [
        {
            id: 1,
            label: 'Submitted ',
            state: 'pending',
            owner: 'John Doe',
            date: startDate
        }
    ];

    let currentDate = new Date(startDate);
    const endDate = new Date();
    let idCounter = 2;
    let lastLabel = 'Submitted'; // Track the last label to prevent repetition

    while (currentDate < endDate) {
        const daysToAdd = Math.floor(Math.random() * 30) + 1; // Generate a random number of days to add (1 to 30 days)
        currentDate.setDate(currentDate.getDate() + daysToAdd);

        if (currentDate >= endDate) {
            break;
        }

        let eventLabel;
        if (lastLabel === 'Rejected' || lastLabel === 'Submitted') {
            eventLabel = 'Resubmitted';
        } else {
            eventLabel = 'Rejected';
        }

        const formattedDate = currentDate.toISOString().split('T')[0];
        events.push({
            id: idCounter,
            label: eventLabel,
            state: eventLabel === 'Rejected' ? 'rejected' : 'pending',
            owner: 'John Doe',
            date: formattedDate
        });
        lastLabel = eventLabel;
        idCounter++;
    }

    // Decide randomly whether the last event is "Approved" or "Waiting"
    const finalEventLabel = Math.random() > 0.5 ? 'Approved' : 'Waiting';
    events.push({
        id: idCounter,
        state: finalEventLabel === 'Approved' ? 'approved' : 'waiting',
        label: finalEventLabel,
        owner: 'John Doe',
        date: endDate.toISOString().split('T')[0]
    });

    return events;
}

// Example usage:
const events = generateRandomEvents('2024-01-01');

export const AuxbarApprovalHistory = React.memo(({ goalId }) => {
    const { t } = useCustomTranslation();
    const { setSelectedAvailableOption } = useAuxBar();

    const eventColor = useMemo(() => {
        return {
            approved: '#27AE60',
            rejected: '#E74C3C',
            pending: '#F39C12',
            waiting: '#E6E6E6'
        };
    }, []);

    const eventsReversed = useMemo(() => {
        return events.slice().reverse();
    }, []);

    return (
        <div className={`${styles['auxbar-approval-history-container']}`}>
            <img
                src={icoClose}
                alt="Close"
                className={`${styles['auxbar-approval-history-container__close-icon']}`}
                onClick={() => {
                    setSelectedAvailableOption('');
                }}
            />
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0 d-flex flex-column h-100">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <span
                                className={`${styles['auxbar-approval-history-container__title']}`}
                            >
                                {t('common:approvalHistory')}
                            </span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{ minHeight: 0 }}
                    >
                        <div className="col px-0 h-100">
                            <Scrollable>
                                {eventsReversed &&
                                    eventsReversed.length > 0 &&
                                    eventsReversed.map((event, index) => (
                                        <div
                                            className="row mx-0 w-100 position-relative"
                                            key={index}
                                            style={{
                                                padding: '0 1rem',
                                                marginBottom: '2rem',
                                                height: '8rem'
                                            }}
                                        >
                                            <div className="col-auto px-0 d-flex justify-content-start align-items-start">
                                                <div
                                                    className={`${styles['auxbar-approval-history-container__event-circle']}`}
                                                    style={{
                                                        backgroundColor:
                                                            eventColor[
                                                                event.state
                                                            ]
                                                    }}
                                                >
                                                    {index !==
                                                        eventsReversed.length -
                                                            1 && (
                                                        <div
                                                            className={`${styles['auxbar-approval-history-container__event-line']}`}
                                                        ></div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{ width: '1rem' }}
                                            ></div>
                                            <div className="col px-0">
                                                <span
                                                    className={`${styles['auxbar-approval-history-container__event-label']} d-block`}
                                                    style={{
                                                        color: eventColor[
                                                            event.state
                                                        ]
                                                    }}
                                                >
                                                    {t(event.label)}
                                                </span>
                                                {event.state !== 'waiting' && (
                                                    <>
                                                        <span
                                                            className={`${styles['auxbar-approval-history-container__event-owner']} d-block`}
                                                        >
                                                            {event.owner}
                                                        </span>
                                                        <span
                                                            className={`${styles['auxbar-approval-history-container__event-date']}`}
                                                        >
                                                            Date: {event.date}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </Scrollable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

AuxbarApprovalHistory.propTypes = {};
