import styles from './settings.module.scss';

import Joi from 'joi';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { i18n } from '../../../configs/i18nextConf';
import icoSave from '../../../assets/images/ico-save-blue.svg';
import { saveToLS } from '../../../utils/localStorage';
import { success } from '../../../components/notifications/notification/notification';
import {
    LoaderForeground,
    PrimaryButton,
    SelectInput,
    Spinner,
    TerciaryButton
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Provides a page layout that allows users to manage general settings
 */

const Settings = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);
    const {
        value: language,
        toggleDropdown: languageToggleDropdown,
        selectOption: selectLanguageOption,
        keyPress: languageKeyPress,
        keyDown: languageKeyDown,
        setValue: setLanguageValue,
        setError: setLanguageError
    } = useSelectInput({
        name: 'language',
        showOptions: true,
        options:
            i18n && i18n.options && i18n.options.availableLanguages
                ? i18n.options.availableLanguages
                : [],
        optionsAux: [],
        selectedOption: i18n.language ? i18n.language : '',
        selectedOptionAux: '',
        state: 'normal',
        error: '',
        value: i18n.language ? i18n.language : '',
        focused: -1,
        openDropdown: false,
        reference: useRef()
    });

    const schema = Joi.object({
        language: Joi.string().required()
    });

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    const handleSaveSettings = () => {
        const result = schema.validate(
            {
                language: language.selectedOption
            },
            { abortEarly: false }
        );
        if (isMounted.current) {
            setLanguageError();
        }
        if (result.error) {
            result.error.details.map((el) => {
                if (el.path[0] === 'language') {
                    if (isMounted.current)
                        setLanguageError(t(`errors:${el.type}`));
                }
            });
            return;
        }
        saveToLS('language', language.selectedOption);
        i18n.changeLanguage(language.selectedOption);
        success({ text: t('notifications:settingsSaved') });
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['settings-page-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <span className="title me-4">
                                    {t('pages:customDashboard.settings.title')}
                                </span>
                            </div>
                        </div>
                        <div
                            className={`row mx-0 w-100 ${styles['settings-page-container__section']}`}
                        >
                            <div
                                className={`col p-0 h-auto d-flex flex-column justify-content-start align-items-center ${styles['settings-page-container__content-wrapper']}`}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col ${styles['settings-page-container__content']}`}
                                            >
                                                <div
                                                    className={`row mx-0 w-100 ${styles['settings-page-container__content-wrapper__title-wrapper']}`}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['settings-page-container__content-wrapper__title-wrapper__title']}`}
                                                        >
                                                            {t(
                                                                'pages:customDashboard.settings.options.general'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="col-auto px-0 d-flex justify-content-end align-items-center"
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() =>
                                                            handleSaveSettings()
                                                        }
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            src={icoSave}
                                                            alt="save"
                                                            className={
                                                                styles[
                                                                    'settings-page-container__save-icon'
                                                                ]
                                                            }
                                                            style={{
                                                                width: '2rem',
                                                                height: '2rem',
                                                                marginRight:
                                                                    '0.5rem'
                                                            }}
                                                        />
                                                        <span
                                                            className={
                                                                styles[
                                                                    'settings-page-container__save-text'
                                                                ]
                                                            }
                                                        >
                                                            {t('common:save')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <hr
                                                    className={`${styles['settings-page-container__content-wrapper__separator']}`}
                                                />
                                                <div
                                                    className={`row mx-0 w-100 ${styles['settings-page-container__content-wrapper__options-wrapper']} flex-grow-1`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['settings-page-container__input-container']}`}
                                                        >
                                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                                <span
                                                                    className={`${styles['settings-page-container__input-container__input-title']}`}
                                                                >
                                                                    {t(
                                                                        'pages:customDashboard.settings.options.language'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 px-0">
                                                                <SelectInput
                                                                    onChangeInput={
                                                                        setLanguageValue
                                                                    }
                                                                    onSelectInput={
                                                                        selectLanguageOption
                                                                    }
                                                                    onKeyPress={
                                                                        languageKeyPress
                                                                    }
                                                                    onKeyDown={
                                                                        languageKeyDown
                                                                    }
                                                                    onToggleDropdown={
                                                                        languageToggleDropdown
                                                                    }
                                                                    {...language}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default Settings;

Settings.propTypes = {};
