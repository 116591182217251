import React from 'react';
import styles from './BillingCycle.module.scss';
import { ProgressCircle } from '../progress-circle/ProgressCircle';

export const BillingCycle = () => {
    const currentDate = new Date(); // For example, today's date
    // create maxDate as the end of the month
    const maxDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 3,
        0
    );

    return (
        <div className={styles['billing-cycle-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <ProgressCircle
                        currentDate={currentDate}
                        maxDate={maxDate}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={
                            styles['billing-cycle-container__content-text']
                        }
                    >
                        Your plan
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span className={styles['billing-cycle-container__title']}>
                        Pro Annual
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span
                        className={
                            styles['billing-cycle-container__content-text']
                        }
                    >
                        Renews on November 2025
                    </span>
                </div>
            </div>
        </div>
    );
};
