import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { evolByProductForecastMock } from './mock';
import PropTypes from 'prop-types';
import { useChartReady } from '../../../providers/chart-ready-context';
import { ComponentWrapper, DynamicComposedChartContainer } from '../..';
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush';
import { ProductsService } from '../../../temp/test';
import {
    getDayFromDateFormat,
    getMonthFromDateFormat,
    getTimeFromDateFormat,
    getYearFromDateFormat,
    isSameYearSameMonth
} from '../../../utils/date';
import { errorHandler } from '../../../utils/api';
import { getFilterParam } from '../../../utils/params';
import { Promise } from 'q';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useTimeInterval } from '../../../providers/time-interval-context';
import { useSalesChannel } from '../../../providers/sales-channel-context';
/**
 * Displays the evolution of revenue sales over time with forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer);

export const EvolByProductForecast = React.memo(
    ({ component, isPreview, isExample, config, ...rest }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { channels } = useSalesChannel();
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue
        } = useSimpleComponent(evolByProductForecastMock);

        const getInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(evolByProductForecastMock);
            try {
                if (!isExample) {
                    if (isMounted.current) {
                        setLoading(true);
                    }
                    dataClone.loading = true;
                    dataClone.legend = [];
                    dataClone.areaAttr = [];
                    dataClone.data.length = 0;
                    const forbiddenCategories = ['V&I', 'OBU', 'R&I', 'CVRM'];
                    const productResult = await ProductsService.getApiProducts1(
                        {
                            id: getFilterParam(filter, config, [
                                'productId',
                                'productIds'
                            ])
                        }
                    );
                    await Promise.all(
                        productResult.categories.map(
                            async (category, categoryIndex) => {
                                if (
                                    !forbiddenCategories.includes(category.code)
                                ) {
                                    const categoryResult =
                                        await ProductsService.getApiProductsEvolMonth(
                                            {
                                                productId:
                                                    getFilterParam(
                                                        filter,
                                                        config,
                                                        [
                                                            'productId',
                                                            'productIds'
                                                        ]
                                                    ).length > 0
                                                        ? getFilterParam(
                                                              filter,
                                                              config,
                                                              [
                                                                  'productId',
                                                                  'productIds'
                                                              ]
                                                          )[0]
                                                        : undefined,

                                                productCategoryId: category.id,
                                                channels: JSON.parse(channels)
                                            }
                                        );
                                    const categoryName =
                                        category.code.length === 4
                                            ? 'atc3'
                                            : 'atc4';
                                    Object.entries(
                                        categoryResult.timeseries
                                    ).map((el) => {
                                        let newEl = {
                                            [`category-${categoryName}`]:
                                                el[1].toFixed(1),
                                            [`category-${categoryName}ForecastValue`]:
                                                el[1].toFixed(1),
                                            [`category-${categoryName}ForecastMinValue`]:
                                                el[1].toFixed(1),
                                            [`category-${categoryName}ForecastMaxValue`]:
                                                el[1].toFixed(1),

                                            time: getTimeFromDateFormat(el[0]),
                                            day: getDayFromDateFormat(el[0], t),
                                            month: getMonthFromDateFormat(
                                                el[0],
                                                t
                                            ),
                                            year: getYearFromDateFormat(el[0])
                                        };
                                        let idx = dataClone.data.findIndex(
                                            (v) => {
                                                return isSameYearSameMonth(
                                                    v.time,
                                                    newEl.time
                                                );
                                            }
                                        );
                                        if (idx !== -1) {
                                            dataClone.data[idx][
                                                `category-${categoryName}`
                                            ] =
                                                newEl[
                                                    `category-${categoryName}`
                                                ];
                                            dataClone.data[idx][
                                                `category-${categoryName}ForecastValue`
                                            ] =
                                                newEl[
                                                    `category-${categoryName}ForecastValue`
                                                ];
                                            dataClone.data[idx][
                                                `category-${categoryName}ForecastMinValue`
                                            ] =
                                                newEl[
                                                    `category-${categoryName}ForecastMinValue`
                                                ];
                                            dataClone.data[idx][
                                                `category-${categoryName}ForecastMaxValue`
                                            ] =
                                                newEl[
                                                    `category-${categoryName}ForecastMaxValue`
                                                ];
                                        } else {
                                            dataClone.data.push(newEl);
                                        }
                                        return null;
                                    });
                                    categoryResult.forecast.forecast_trace_y.map(
                                        (el, index) => {
                                            let newEl = {
                                                [`category-${categoryName}`]:
                                                    categoryResult.forecast,
                                                [`category-${categoryName}ForecastValue`]:
                                                    categoryResult.forecast.forecast_trace_y[
                                                        index
                                                    ].toFixed(1),
                                                [`category-${categoryName}ForecastMinValue`]:
                                                    categoryResult.forecast.min_trace_y[
                                                        index
                                                    ].toFixed(1),
                                                [`category-${categoryName}ForecastMaxValue`]:
                                                    categoryResult.forecast.max_trace_y[
                                                        index
                                                    ].toFixed(1),
                                                time: getTimeFromDateFormat(
                                                    categoryResult.forecast
                                                        .forecast_x[index]
                                                ),
                                                day: getDayFromDateFormat(
                                                    categoryResult.forecast
                                                        .forecast_x[index],
                                                    t
                                                ),
                                                month: getMonthFromDateFormat(
                                                    categoryResult.forecast
                                                        .forecast_x[index],
                                                    t
                                                ),
                                                year: getYearFromDateFormat(
                                                    categoryResult.forecast
                                                        .forecast_x[index]
                                                )
                                            };
                                            let idx = dataClone.data.findIndex(
                                                (v) => {
                                                    return isSameYearSameMonth(
                                                        v.time,
                                                        newEl.time
                                                    );
                                                }
                                            );
                                            if (idx !== -1) {
                                                dataClone.data[idx][
                                                    `category-${categoryName}ForecastValue`
                                                ] =
                                                    newEl[
                                                        `category-${categoryName}ForecastValue`
                                                    ];
                                                dataClone.data[idx][
                                                    `category-${categoryName}ForecastMaxValue`
                                                ] =
                                                    newEl[
                                                        `category-${categoryName}ForecastMaxValue`
                                                    ];
                                                dataClone.data[idx][
                                                    `category-${categoryName}ForecastMinValue`
                                                ] =
                                                    newEl[
                                                        `category-${categoryName}ForecastMinValue`
                                                    ];
                                            } else {
                                                dataClone.data.push(newEl);
                                            }
                                            return null;
                                        }
                                    );

                                    dataClone.areaAttr.push({
                                        dataKey: `category-${categoryName}ForecastValue`,
                                        color:
                                            categoryName === 'atc3'
                                                ? '#FF9441'
                                                : '#8C54FF',
                                        isReverseColor: true,
                                        dashArray: '3 3',
                                        teamId: categoryName,
                                        isTransparent: true,
                                        isGradient: true,
                                        strokeWidth: 2,
                                        fillOpacity: 1
                                    });
                                    dataClone.areaAttr.push({
                                        dataKey: `category-${categoryName}`,
                                        color:
                                            categoryName === 'atc3'
                                                ? '#FF9441'
                                                : '#8C54FF',
                                        strokeWidth: 2,
                                        isTransparent: true
                                    });
                                    dataClone.legend.push({
                                        id: `category-${categoryName}`,
                                        name: `category-${categoryName}-evol`,
                                        label: category.name,
                                        translated: false,
                                        color:
                                            categoryName === 'atc3'
                                                ? '#FF9441'
                                                : '#8C54FF'
                                    });
                                }
                            }
                        )
                    );

                    dataClone.categoryAxis = 'month';
                    if (!isPreview) dataClone.dateGrouper = 'month';
                    dataClone.interactionDisabled = false;
                    dataClone.tooltipType = 'evolByProductForecast';
                    dataClone.loading = false;
                    dataClone.ready = true;
                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;
                    if (isMounted.current) {
                        setLoading(false);
                        setValue(dataClone);
                    }
                } else {
                    dataClone.data = [
                        {
                            'category-atc4': '98.4',
                            'category-atc4ForecastValue': '98.4',
                            'category-atc4ForecastMinValue': '98.4',
                            'category-atc4ForecastMaxValue': '98.4',
                            time: '2021-12-01',
                            day: '01 Dez 2021',
                            month: 'Dec 2021',
                            year: '2021',
                            'category-atc3': '98.8',
                            'category-atc3ForecastValue': '98.8',
                            'category-atc3ForecastMinValue': '98.8',
                            'category-atc3ForecastMaxValue': '98.8'
                        },
                        {
                            'category-atc4': '103.7',
                            'category-atc4ForecastValue': '103.7',
                            'category-atc4ForecastMinValue': '103.7',
                            'category-atc4ForecastMaxValue': '103.7',
                            time: '2022-01-01',
                            day: '01 Jan 2022',
                            month: 'Jan 2022',
                            year: '2022',
                            'category-atc3': '103.0',
                            'category-atc3ForecastValue': '103.0',
                            'category-atc3ForecastMinValue': '103.0',
                            'category-atc3ForecastMaxValue': '103.0'
                        },
                        {
                            'category-atc4': '96.9',
                            'category-atc4ForecastValue': '96.9',
                            'category-atc4ForecastMinValue': '96.9',
                            'category-atc4ForecastMaxValue': '96.9',
                            time: '2022-02-01',
                            day: '01 Fev 2022',
                            month: 'Feb 2022',
                            year: '2022',
                            'category-atc3': '97.7',
                            'category-atc3ForecastValue': '97.7',
                            'category-atc3ForecastMinValue': '97.7',
                            'category-atc3ForecastMaxValue': '97.7'
                        },
                        {
                            'category-atc4': '100.0',
                            'category-atc4ForecastValue': '100.0',
                            'category-atc4ForecastMinValue': '100.0',
                            'category-atc4ForecastMaxValue': '100.0',
                            time: '2022-03-01',
                            day: '01 Mar 2022',
                            month: 'Mar 2022',
                            year: '2022',
                            'category-atc3': '99.0',
                            'category-atc3ForecastValue': '99.0',
                            'category-atc3ForecastMinValue': '99.0',
                            'category-atc3ForecastMaxValue': '99.0'
                        },
                        {
                            'category-atc4': '96.7',
                            'category-atc4ForecastValue': '96.7',
                            'category-atc4ForecastMinValue': '96.7',
                            'category-atc4ForecastMaxValue': '96.7',
                            time: '2022-04-01',
                            day: '01 Abr 2022',
                            month: 'Apr 2022',
                            year: '2022',
                            'category-atc3': '97.7',
                            'category-atc3ForecastValue': '97.7',
                            'category-atc3ForecastMinValue': '97.7',
                            'category-atc3ForecastMaxValue': '97.7'
                        },
                        {
                            'category-atc4': '105.2',
                            'category-atc4ForecastValue': '105.2',
                            'category-atc4ForecastMinValue': '105.2',
                            'category-atc4ForecastMaxValue': '105.2',
                            time: '2022-05-01',
                            day: '01 Maio 2022',
                            month: 'May 2022',
                            year: '2022',
                            'category-atc3': '103.3',
                            'category-atc3ForecastValue': '103.3',
                            'category-atc3ForecastMinValue': '103.3',
                            'category-atc3ForecastMaxValue': '103.3'
                        },
                        {
                            'category-atc4': '99.9',
                            'category-atc4ForecastValue': '99.9',
                            'category-atc4ForecastMinValue': '99.9',
                            'category-atc4ForecastMaxValue': '99.9',
                            time: '2022-06-01',
                            day: '01 Jun 2022',
                            month: 'Jun 2022',
                            year: '2022',
                            'category-atc3': '100.3',
                            'category-atc3ForecastValue': '100.3',
                            'category-atc3ForecastMinValue': '100.3',
                            'category-atc3ForecastMaxValue': '100.3'
                        },
                        {
                            'category-atc4': '99.0',
                            'category-atc4ForecastValue': '99.0',
                            'category-atc4ForecastMinValue': '99.0',
                            'category-atc4ForecastMaxValue': '99.0',
                            time: '2022-07-01',
                            day: '01 Jul 2022',
                            month: 'Jul 2022',
                            year: '2022',
                            'category-atc3': '97.1',
                            'category-atc3ForecastValue': '97.1',
                            'category-atc3ForecastMinValue': '97.1',
                            'category-atc3ForecastMaxValue': '97.1'
                        },
                        {
                            'category-atc4': '109.8',
                            'category-atc4ForecastValue': '109.8',
                            'category-atc4ForecastMinValue': '109.8',
                            'category-atc4ForecastMaxValue': '109.8',
                            time: '2022-08-01',
                            day: '01 Ago 2022',
                            month: 'Aug 2022',
                            year: '2022',
                            'category-atc3': '111.1',
                            'category-atc3ForecastValue': '111.1',
                            'category-atc3ForecastMinValue': '111.1',
                            'category-atc3ForecastMaxValue': '111.1'
                        },
                        {
                            'category-atc4': '96.8',
                            'category-atc4ForecastValue': '96.8',
                            'category-atc4ForecastMinValue': '96.8',
                            'category-atc4ForecastMaxValue': '96.8',
                            time: '2022-09-01',
                            day: '01 Set 2022',
                            month: 'Sep 2022',
                            year: '2022',
                            'category-atc3': '96.1',
                            'category-atc3ForecastValue': '96.1',
                            'category-atc3ForecastMinValue': '96.1',
                            'category-atc3ForecastMaxValue': '96.1'
                        },
                        {
                            'category-atc4': '96.0',
                            'category-atc4ForecastValue': '96.0',
                            'category-atc4ForecastMinValue': '96.0',
                            'category-atc4ForecastMaxValue': '96.0',
                            time: '2022-10-01',
                            day: '01 Out 2022',
                            month: 'Oct 2022',
                            year: '2022',
                            'category-atc3': '95.7',
                            'category-atc3ForecastValue': '95.7',
                            'category-atc3ForecastMinValue': '95.7',
                            'category-atc3ForecastMaxValue': '95.7'
                        },
                        {
                            'category-atc4': '98.6',
                            'category-atc4ForecastValue': '98.6',
                            'category-atc4ForecastMinValue': '98.6',
                            'category-atc4ForecastMaxValue': '98.6',
                            time: '2022-11-01',
                            day: '01 Nov 2022',
                            month: 'Nov 2022',
                            year: '2022',
                            'category-atc3': '98.6',
                            'category-atc3ForecastValue': '98.6',
                            'category-atc3ForecastMinValue': '98.6',
                            'category-atc3ForecastMaxValue': '98.6'
                        },
                        {
                            'category-atc4': '106.9',
                            'category-atc4ForecastValue': '106.9',
                            'category-atc4ForecastMinValue': '106.9',
                            'category-atc4ForecastMaxValue': '106.9',
                            time: '2022-12-01',
                            day: '01 Dez 2022',
                            month: 'Dec 2022',
                            year: '2022',
                            'category-atc3': '109.5',
                            'category-atc3ForecastValue': '109.5',
                            'category-atc3ForecastMinValue': '109.5',
                            'category-atc3ForecastMaxValue': '109.5'
                        },
                        {
                            'category-atc4': '98.6',
                            'category-atc4ForecastValue': '98.6',
                            'category-atc4ForecastMinValue': '98.6',
                            'category-atc4ForecastMaxValue': '98.6',
                            time: '2023-01-01',
                            day: '01 Jan 2023',
                            month: 'Jan 2023',
                            year: '2023',
                            'category-atc3': '96.0',
                            'category-atc3ForecastValue': '96.0',
                            'category-atc3ForecastMinValue': '96.0',
                            'category-atc3ForecastMaxValue': '96.0'
                        },
                        {
                            'category-atc4': '98.6',
                            'category-atc4ForecastValue': '98.6',
                            'category-atc4ForecastMinValue': '98.6',
                            'category-atc4ForecastMaxValue': '98.6',
                            time: '2023-02-01',
                            day: '01 Fev 2023',
                            month: 'Feb 2023',
                            year: '2023',
                            'category-atc3': '100.2',
                            'category-atc3ForecastValue': '100.2',
                            'category-atc3ForecastMinValue': '100.2',
                            'category-atc3ForecastMaxValue': '100.2'
                        },
                        {
                            'category-atc4': '96.0',
                            'category-atc4ForecastValue': '96.0',
                            'category-atc4ForecastMinValue': '96.0',
                            'category-atc4ForecastMaxValue': '96.0',
                            time: '2023-03-01',
                            day: '01 Mar 2023',
                            month: 'Mar 2023',
                            year: '2023',
                            'category-atc3': '94.9',
                            'category-atc3ForecastValue': '94.9',
                            'category-atc3ForecastMinValue': '94.9',
                            'category-atc3ForecastMaxValue': '94.9'
                        },
                        {
                            'category-atc4': '104.2',
                            'category-atc4ForecastValue': '104.2',
                            'category-atc4ForecastMinValue': '104.2',
                            'category-atc4ForecastMaxValue': '104.2',
                            time: '2023-04-01',
                            day: '01 Abr 2023',
                            month: 'Apr 2023',
                            year: '2023',
                            'category-atc3': '106.5',
                            'category-atc3ForecastValue': '106.5',
                            'category-atc3ForecastMinValue': '106.5',
                            'category-atc3ForecastMaxValue': '106.5'
                        },
                        {
                            'category-atc4': '98.1',
                            'category-atc4ForecastValue': '98.1',
                            'category-atc4ForecastMinValue': '98.1',
                            'category-atc4ForecastMaxValue': '98.1',
                            time: '2023-05-01',
                            day: '01 Maio 2023',
                            month: 'May 2023',
                            year: '2023',
                            'category-atc3': '97.7',
                            'category-atc3ForecastValue': '97.7',
                            'category-atc3ForecastMinValue': '97.7',
                            'category-atc3ForecastMaxValue': '97.7'
                        },
                        {
                            'category-atc4': '102.6',
                            'category-atc4ForecastValue': '102.6',
                            'category-atc4ForecastMinValue': '102.6',
                            'category-atc4ForecastMaxValue': '102.6',
                            time: '2023-06-01',
                            day: '01 Jun 2023',
                            month: 'Jun 2023',
                            year: '2023',
                            'category-atc3': '103.0',
                            'category-atc3ForecastValue': '103.0',
                            'category-atc3ForecastMinValue': '103.0',
                            'category-atc3ForecastMaxValue': '103.0'
                        },
                        {
                            'category-atc4': '98.4',
                            'category-atc4ForecastValue': '98.4',
                            'category-atc4ForecastMinValue': '98.4',
                            'category-atc4ForecastMaxValue': '98.4',
                            time: '2023-07-01',
                            day: '01 Jul 2023',
                            month: 'Jul 2023',
                            year: '2023',
                            'category-atc3': '95.6',
                            'category-atc3ForecastValue': '95.6',
                            'category-atc3ForecastMinValue': '95.6',
                            'category-atc3ForecastMaxValue': '95.6'
                        },
                        {
                            'category-atc4': '101.4',
                            'category-atc4ForecastValue': '101.4',
                            'category-atc4ForecastMinValue': '101.4',
                            'category-atc4ForecastMaxValue': '101.4',
                            time: '2023-08-01',
                            day: '01 Ago 2023',
                            month: 'Aug 2023',
                            year: '2023',
                            'category-atc3': '103.8',
                            'category-atc3ForecastValue': '103.8',
                            'category-atc3ForecastMinValue': '103.8',
                            'category-atc3ForecastMaxValue': '103.8'
                        },
                        {
                            'category-atc4': '95.0',
                            'category-atc4ForecastValue': '95.0',
                            'category-atc4ForecastMinValue': '95.0',
                            'category-atc4ForecastMaxValue': '95.0',
                            time: '2023-09-01',
                            day: '01 Set 2023',
                            month: 'Sep 2023',
                            year: '2023',
                            'category-atc3': '93.8',
                            'category-atc3ForecastValue': '93.8',
                            'category-atc3ForecastMinValue': '93.8',
                            'category-atc3ForecastMaxValue': '93.8'
                        },
                        {
                            'category-atc4': '102.2',
                            'category-atc4ForecastValue': '102.2',
                            'category-atc4ForecastMinValue': '102.2',
                            'category-atc4ForecastMaxValue': '102.2',
                            time: '2023-10-01',
                            day: '01 Out 2023',
                            month: 'Oct 2023',
                            year: '2023',
                            'category-atc3': '101.7',
                            'category-atc3ForecastValue': '101.7',
                            'category-atc3ForecastMinValue': '101.7',
                            'category-atc3ForecastMaxValue': '101.7'
                        }
                    ];
                    dataClone.lineAttr = [
                        {
                            dataKey: 'category-atc4ForecastValue',
                            color: '#8C54FF',
                            isReverseColor: true,
                            dashArray: '3 3',
                            teamId: 'atc4',
                            isGradient: true,
                            isTransparent: true,
                            strokeWidth: 2,
                            fillOpacity: 1
                        },
                        {
                            dataKey: 'category-atc4',
                            color: '#8C54FF',
                            strokeWidth: 2,
                            isTransparent: true
                        },
                        {
                            dataKey: 'category-atc3ForecastValue',
                            color: '#FF9441',
                            isReverseColor: true,
                            dashArray: '3 3',
                            teamId: 'atc3',
                            isGradient: true,
                            isTransparent: true,
                            strokeWidth: 2,
                            fillOpacity: 1
                        },
                        {
                            dataKey: 'category-atc3',
                            color: '#FF9441',
                            strokeWidth: 2,
                            isTransparent: true
                        }
                    ];
                    dataClone.legend = [
                        {
                            id: 'category-atc4',
                            name: 'category-atc4-evol',
                            translated: false,
                            color: '#8C54FF'
                        },
                        {
                            id: 'category-atc3',
                            name: 'category-atc3-evol',
                            translated: false,
                            color: '#FF9441'
                        }
                    ];

                    setValue(dataClone);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [
            channels,
            isExample,
            isPreview,
            setLoading,
            setValue,
            isMounted,
            t,
            config,
            filter
        ]);

        useEffect(() => {
            getInformation();
        }, [getInformation]);

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ComponentWrapper
                    withChannelFilter
                    component={component}
                    {...data}
                    {...rest}
                >
                    <Chart
                        {...data}
                        forecastValue="unitsSoldForecastValue"
                        forecastIntervalValue="unitsSoldForecastIntervalValue"
                    />
                </ComponentWrapper>
            </div>
        );
    }
);

EvolByProductForecast.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Time interval to aggregate data from api
     */
    salesDataTimeWindow: PropTypes.string,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
