import React from 'react';
import styles from './date-input-alternative.module.scss';

const InputDefault = ({ value }) => {
    return (
        <div
            className={
                styles['date-input-alternative-container__input-container']
            }
        >
            <div
                className="col-auto px-0 d-flex justify-content-center align-items-center"
                style={{ opacity: !value ? 0.4 : 1 }}
            >
                <span>{value ? value.split('/')[0] : 'dd'}</span>
            </div>
            <div className="col-auto px-0" style={{ width: '0.5rem' }}></div>
            <div
                className="col-auto px-0 d-flex justify-content-center align-items-center"
                style={{ opacity: !value ? 0.4 : 1 }}
            >
                <span>
                    {value
                        ? monthShortTranslator[parseInt(value.split('/')[1])]
                        : 'mm'}
                    ,
                </span>
            </div>
            <div className="col-auto px-0" style={{ width: '0.5rem' }}></div>
            <div
                className="col-auto px-0 d-flex justify-content-start align-items-center"
                style={{ opacity: !value ? 0.4 : 1 }}
            >
                <span>{value ? value.split('/')[2] : 'aaaa'}</span>
            </div>
        </div>
    );
};

const monthShortTranslator = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez'
};

export const DateInputAlternative = React.memo(
    ({ label, description, format, ...rest }) => {
        return (
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0">
                    <div
                        className={`${styles['date-input-alternative-container']}`}
                    >
                        <div className="w-100 h-100">
                            <div className="col px-0">
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                        {label && (
                                            <span
                                                className={`${styles['date-input-alternative-container__label']}`}
                                            >
                                                {label}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        {description && (
                                            <span className="sub-title">
                                                {description}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100"
                                    style={{
                                        minWidth: '18rem'
                                    }}
                                >
                                    <InputDefault {...rest} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
