import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import icoActionDefault from './assets/images/ico-action-default.svg';
import icoActionSelected from './assets/images/ico-action-selected.svg';
import icoMore from '../../../assets/images/icoMore.svg';
import styles from './actions-button-new.module.scss';
import { useToggle } from '../../../hooks/useToggle';
import { OptionList } from '../../chart-configuration/option-list/option-list';

export const ActionsButtonNew = React.memo(
    ({ attr, actions, onClickOption, disabled, position, isAlternative }) => {
        const [isOpen, toggleIsOpen] = useToggle(false);
        const handleOutsideClick = useCallback(
            (event) => {
                if (actions && isOpen) {
                    if (
                        actions.reference.current &&
                        !actions.reference.current.contains(event.target)
                    ) {
                        event.preventDefault();
                        event.stopPropagation();
                        toggleIsOpen();
                    }
                }
            },
            [actions, isOpen, toggleIsOpen]
        );

        useEffect(() => {
            document.addEventListener('click', handleOutsideClick);
            return function cleanup() {
                document.removeEventListener('click', handleOutsideClick);
            };
        }, [handleOutsideClick]);

        if (!actions || (actions && !isOpen)) {
            return (
                <div className={`${styles['actions-button-new-container']}`}>
                    <img
                        src={isAlternative ? icoMore : icoActionDefault}
                        className={
                            disabled
                                ? `${styles['actions-button-new-container__icon']} ${styles['actions-button-new-container__icon--normal']} ${styles['disabled']}`
                                : `${styles['actions-button-new-container__icon']} ${styles['actions-button-new-container__icon--normal']}`
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleIsOpen();
                        }}
                    />
                </div>
            );
        } else if (actions && isOpen) {
            return (
                <div
                    className={`${styles['actions-button-new-container']}`}
                    ref={actions.reference}
                >
                    <img
                        src={isAlternative ? icoMore : icoActionSelected}
                        className={
                            disabled
                                ? `${styles['actions-button-new-container__icon']} ${styles['disabled']}`
                                : `${styles['actions-button-new-container__icon']}`
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleIsOpen();
                        }}
                    />
                    {actions.options && actions.options.length !== 0 && (
                        <OptionList
                            attr={attr}
                            label={actions.label}
                            id={actions.id}
                            type={'default'}
                            options={actions.options}
                            onClickOption={(args) => {
                                onClickOption(args);
                                toggleIsOpen();
                            }}
                            position={position}
                        />
                    )}
                </div>
            );
        }
    }
);
ActionsButtonNew.propTypes = {
    /**
     * Object containing array of options.
     * Each option is an object containing two attributes:
     * name: String to display label
     * icon: Image to display
     */
    actions: PropTypes.object.isRequired,
    /**
     * Function to handle clicking on an option
     */
    onClickOption: PropTypes.func.isRequired,
    /**
     * Bool to disable button
     */
    disabled: PropTypes.bool
};
