import { useCallback, useEffect } from 'react'
import ee from 'event-emitter'

const emitter = new ee()

export const useSub = (events, callback) => {
    if (!Array.isArray(events)) {
        events = [events]
    }
    const unsubscribe = useCallback(() => {
        events.forEach((event) => {
            emitter.off(event, callback)
        })
    }, [callback, events])

    useEffect(() => {
        events.forEach((event) => {
            emitter.on(event, callback)
        })
        return unsubscribe
    }, [callback, events, unsubscribe])

    return unsubscribe
}

export const usePub = () => {
    return (events, data) => {
        if (!Array.isArray(events)) {
            events = [events]
        }
        events.forEach((event) => {
            emitter.emit(event, data)
        })
    }
}
