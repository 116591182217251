import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const SubGoalPartitionTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();
    if (
        props.item.data &&
        props.item.data.data1 &&
        props.item.data.data1.length > 0 &&
        props.item.index !== undefined
    ) {
        return (
            <TooltipContainer
                color={props.item.data.data1[props.item.index].color}
            >
                <TooltipHeaderRow
                    title={t(`tooltips:header.sub-goal-partition`)}
                />
                <Separator />
                <TooltipContentRow
                    title={props.item.data.data1[props.item.index].key1}
                    value={props.item.data.data1[props.item.index].value + '%'}
                    color={props.item.data.data1[props.item.index].color}
                />
            </TooltipContainer>
        );
    }
    return null;
});
