import React from 'react';
import styles from './custom-horizontal-label.module.scss';
import { getValueInString } from '../../../utils/unit';

export const CustomHorizontalLabel = React.memo(
    ({
        x,
        y,
        width,
        height,
        value,
        unitsOrValues,
        setHoveredBarGroupId,
        id
    }) => {
        if (value && unitsOrValues && width > 20) {
            return (
                <g
                    className={`${styles['custom-horizontal-label-wrapper__label-font-wrapper']}`}
                >
                    <text
                        x={x + width - 20}
                        y={y + height / 2 + 1}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className={`${styles['custom-horizontal-label-wrapper__label-font-wrapper__label-font']} ${styles['reversed']}`}
                        onMouseEnter={() => setHoveredBarGroupId(id)}
                    >
                        {getValueInString(parseFloat(value), unitsOrValues)}
                    </text>
                </g>
            );
        } else {
            return null;
        }
    }
);
