import React, { useMemo } from 'react';
import { Modal } from '../..';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import styles from './show-member-teams-modal.module.scss';

const mockData = [
    {
        id: 1,
        name: 'John Doe',
        teams: ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5']
    }
];

export const ShowMemberTeamsModal = ({ memberId, onCloseModal }) => {
    const { t } = useCustomTranslation();
    const memberName = useMemo(() => {
        const member = mockData.find((el) => el.id === memberId);
        return member ? member.name : '';
    }, [memberId]);

    const memberTeams = useMemo(() => {
        const member = mockData.find((el) => el.id === memberId);
        return member ? member.teams : [];
    }, [memberId]);

    return (
        <Modal onToggleModal={onCloseModal}>
            <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <span
                        className={
                            styles['show-member-teams-modal-container__title']
                        }
                    >
                        {memberName}
                    </span>
                </div>
            </div>
            <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <span
                        className={
                            styles[
                                'show-member-teams-modal-container__sub-title'
                            ]
                        }
                    >
                        Teams
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ height: '20rem' }}>
                <div className="col px-0">
                    {memberTeams.map((team, index) => (
                        <div key={index} className={`text-label`}>
                            {team}
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};
