import React from 'react'
import { Text } from 'recharts'
import { getRoundedValue } from '../../../utils/math'
import styles from './custom-tick.module.scss'

export const CustomTick = React.memo((props) => {
    let valueToShow = props.payload.value
    valueToShow = getRoundedValue(valueToShow, props.unitsOrValues)
    return (
        <Text {...props} className={`${styles['custom-tick']}`}>
            {valueToShow.toString().length < 10
                ? valueToShow.toString()
                : valueToShow.toString().slice(0, 9) + '...'}
        </Text>
    )
})
