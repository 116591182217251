import styles from './team-members-list.module.scss';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { teamMembersListMock } from './mock';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useTable } from '../../../hooks/useTable';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { TeamsService } from '../../../temp/test';
import { useLocation } from 'react-router-dom';
import { RemoveTeamMemberModal } from '../../modals/remove-team-member-modal/remove-team-member-modal';
import { AddTeamMemberModal } from '../../modals/add-team-member-modal/add-team-member-modal';
import { EditTeamMemberModal } from '../../modals/edit-team-member-modal/edit-team-member-modal';
import { useSub } from '../../../hooks/usePubSub';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Lists all the members from a selected team of employees
 */

export const TeamMembersList = React.memo(() => {
    const location = useLocation();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const [selectedTeamMemberId, setSelectedTeamMemberId] = useState('');
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(teamMembersListMock, modal, '');

    const handleCloseModal = () => {
        toggleModal();
        setSelectedTeamMemberId('');
    };

    const getTeamMembers = useCallback(async () => {
        const dataClone = _.cloneDeep(teamMembersListMock);
        try {
            if (isMounted.current) setLoading(true);
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            dataClone.currentPage = 1;
            if (isMounted.current) setValue(dataClone);
            const params = new URLSearchParams(location.search);
            const teamsBackData = await TeamsService.getApiTeamsMembers({
                teamId: parseInt(params.get('team-id'))
            });
            teamsBackData.map((el) => {
                if (el.employee) {
                    const newEl = {
                        id: el.id,
                        name: el.employee.last_name
                            ? el.employee.first_name +
                              ' ' +
                              el.employee.last_name
                            : el.employee.first_name,
                        isResponsible: el.is_leader,
                        isPlatformUser: el.is_platform_user,
                        action: {
                            label: t(`columns:action`),
                            id: el.id,
                            open: false,
                            options: [
                                {
                                    name: 'editTeamMember',
                                    label: t(`settings:edit`),
                                    color: '#4c4c4c'
                                },
                                {
                                    name: 'removeTeamMember',
                                    label: t(`settings:remove`),
                                    color: '#DB6162'
                                }
                            ],
                            reference: React.createRef()
                        }
                    };
                    dataClone.data.push(newEl);
                }
                return el;
            });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            verifyComponent(dataClone, t);
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue, t, location.search]);

    useEffect(() => {
        getTeamMembers();
    }, [getTeamMembers]);

    useSub('team', () => {
        getTeamMembers();
    });

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                if (isMounted.current) setSelectedTeamMemberId(id);
            }
            if (isMounted.current) {
                toggleComponentSettings(false);
                toggleModal(true);
                setModalType(option);
            }
        },
        [isMounted, toggleComponentSettings, toggleModal]
    );

    const handleAddTeamMemberOpenModal = async () => {
        if (isMounted.current) {
            toggleModal(true);
            setModalType('addMember');
        }
    };

    return (
        <>
            {modal && modalType === 'addMember' && (
                <AddTeamMemberModal
                    onCloseModal={handleCloseModal}
                    onEnd={() => getTeamMembers()}
                />
            )}
            {modal && modalType === 'editTeamMember' && (
                <EditTeamMemberModal
                    teamId={new URLSearchParams(location.search).get('team-id')}
                    teamMemberId={selectedTeamMemberId}
                    onCloseModal={handleCloseModal}
                    onEnd={() => getTeamMembers()}
                />
            )}
            {modal && modalType === 'removeTeamMember' && (
                <RemoveTeamMemberModal
                    teamId={new URLSearchParams(location.search).get('team-id')}
                    teamMemberId={selectedTeamMemberId}
                    onCloseModal={handleCloseModal}
                    onEnd={() => getTeamMembers()}
                />
            )}
            <div className={`${styles['team-members-list-container']}`}>
                <ContentContainer
                    hidePinIcon
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <React.Fragment>
                            <Table
                                actions={data.actions}
                                attr={data.attr}
                                type={data.type}
                                titleCounter={data.sortedData.length}
                                titleText={t(`components:${data.attr}.title`)}
                                placeholderText={t(
                                    `components:${data.attr}.nodata`
                                )}
                                columns={data.columns}
                                data={data.sortedData}
                                sortColumn={data.sortColumn}
                                onSort={dataSort}
                                currentPage={data.currentPage}
                                pageSize={data.pageSize}
                                onPageChange={dataPageChange}
                                onRowClick={undefined}
                                onRowSelect={undefined}
                                onSelectAll={dataSelectAll}
                                onUnselectAll={dataUnselectAll}
                                paginationDisabled={data.paginationDisabled}
                                onClickActionIcon={clickActionIcon}
                                onCloseActions={clickActionIcon}
                                onClickActionOption={handleSelectSettingsOption}
                                disabled={data.disabled}
                                onNewElementClick={handleAddTeamMemberOpenModal}
                                onNewElementText={t('buttons:addTeamMember')}
                            ></Table>
                        </React.Fragment>
                    )}
                </ContentContainer>
            </div>
        </>
    );
});

TeamMembersList.propTypes = {
    /**
     * Selected team id. as a filter for getting data from api
     */
    selectedTeamId: PropTypes.number,
    /**
     * Trigger to propagate changes to parent component
     */
    toggleTrigger: PropTypes.func
};
