/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegionDefaultSchema } from '../models/RegionDefaultSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RegionsService {
    /**
     * list all Region types
     * @returns RegionDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiRegions({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, name, parent_id, level, lat, lon, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'name' | 'parent_id' | 'level' | 'lat' | 'lon' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<RegionDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/regions',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * creates Region
     * @returns RegionDefaultSchema resource created
     * @throws ApiError
     */
    public static postApiRegions({
        requestBody,
    }: {
        requestBody: RegionDefaultSchema,
    }): CancelablePromise<RegionDefaultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/regions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns a market share and market index for all regions
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiRegionsIndexesAll({
        level,
    }: {
        /**
         * level
         */
        level: number,
    }): CancelablePromise<{
        market_index?: number;
        market_share?: number;
        region?: RegionDefaultSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/regions/indexes-all',
            query: {
                'level': level,
            },
        });
    }
    /**
     * deletes Region
     * @returns void
     * @throws ApiError
     */
    public static deleteApiRegions({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/regions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * get one Region by id
     * @returns RegionDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiRegions1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<RegionDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/regions/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * partially updates Region by id
     * @returns RegionDefaultSchema call successful
     * @throws ApiError
     */
    public static patchApiRegions({
        id,
        requestBody,
    }: {
        /**
         * id
         */
        id: number,
        requestBody: RegionDefaultSchema,
    }): CancelablePromise<RegionDefaultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/regions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Returns a market share and market index for a region
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiRegionsIndexes({
        regionId,
    }: {
        /**
         * Region id
         */
        regionId: number,
    }): CancelablePromise<{
        market_index?: number;
        market_share?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/regions/{region_id}/indexes',
            path: {
                'region_id': regionId,
            },
        });
    }
}
