import { errorHandler } from './api';

export const getFromLS = (page) => {
    if (global.localStorage) {
        try {
            const item =
                global.localStorage.getItem(page) !== undefined
                    ? JSON.parse(global.localStorage.getItem(page))
                    : null;
            // const now = new Date();
            // if (now.getTime() > item.expiry) {
            // 	localStorage.removeItem(page);
            // 	return null;
            // } else
            return item;
        } catch (err) {
            errorHandler(err);
        }
    }
};

export const removeFromLS = (name) => {
    global.localStorage.removeItem(name);
};

export const saveToLS = (page, value) => {
    //const now = new Date()
    // const item = {
    // 	value: value,
    // 	expiry: now.getTime() + ttl,
    // };
    if (global.localStorage) {
        global.localStorage.setItem(page, JSON.stringify(value));
    }
};
