import { useCallback, useState } from 'react'
import styles from './role-card.module.scss'
import { EditRoleModal } from '../../../../../../../../../components/modals/edit-role-modal/edit-role-modal'
import { RemoveRoleModal } from '../../../../../../../../../components/modals/remove-role-modal/remove-role-modal'
import { ActionsButtonNew } from '../../../../../../../../../components/buttons/actions-button-new/actions-button-new'
import arrowDown from '../../../../../../../../../assets/images/dropdown-arrow.svg'
import icoDashboardManagement from '../../../../../../../../../assets/images/icoDashboardManagement.svg'
import { PermissionTree } from '../../../permissions-tree/permissions-tree'
import { AssignedToList } from './components/assigned-to-list/assigned-to-list'

const rolePermissions = [
    {
        category: 'Dashboard Management',
        image: icoDashboardManagement,
        permissions: [
            {
                title: 'View Dashboard',
            },
            {
                title: 'Edit Dashboard',
            },
            {
                title: 'Delete Dashboard',
            },
        ],
    },
]

export const RoleCard = ({ id, name, description, assignedMembers, action }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedRoleId, setSelectedRoleId] = useState(null)
    const [removeRoleModal, setRemoveRoleModal] = useState(false)
    const [editRoleModal, setEditRoleModal] = useState(false)

    const handleClickOption = useCallback(
        (option, roleId) => {
            setSelectedRoleId(roleId)
            if (option === 'edit') {
                setEditRoleModal(true)
            } else if (option === 'remove') {
                setRemoveRoleModal(true)
            }
        },
        [setEditRoleModal, setRemoveRoleModal, setSelectedRoleId]
    )
    return (
        <>
            {editRoleModal && <EditRoleModal onCloseModal={() => setEditRoleModal(false)}></EditRoleModal>}
            {removeRoleModal && <RemoveRoleModal onCloseModal={() => setRemoveRoleModal(false)}></RemoveRoleModal>}
            <div className={`${styles['role-card']}`}>
                <div
                    className="row mx-0 w-100"
                    style={{
                        position: 'relative',
                        borderRadius: isOpen ? '2rem 2rem 0 0' : '2rem',
                        padding: '2rem',
                        background: '#fff',
                    }}
                >
                    <div
                        className="col-auto px-0"
                        style={{
                            width: 'calc(100% - 7.5rem)',
                        }}
                    >
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span className={`${styles['role-card__title']}`}>{name}</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span className={`${styles['role-card__description']}`}>{description}</span>
                            </div>
                        </div>
                        <div className={styles['role-card__members-container']}>
                            <div className="d-flex">
                                {assignedMembers.slice(0, 3).map((member, index) => (
                                    <img key={index} src={member.image} className={styles['role-card__members-container__avatar']} />
                                ))}
                                {assignedMembers.length > 3 && (
                                    <div className={styles['role-card__members-container__avatar-plus']}>
                                        <span className={styles['role-card__members-container__avatar-plus__text']}>
                                            +{assignedMembers.length - 3}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {action && (
                        <div
                            className="col-auto px-0 d-flex justify-content-center align-items-center"
                            style={{
                                width: '5rem',
                            }}
                        >
                            <ActionsButtonNew isAlternative actions={action} onClickOption={(args) => handleClickOption(args, id)} />
                        </div>
                    )}
                    <div
                        className="col-auto px-0 d-flex justify-content-center align-items-center"
                        style={{
                            width: '2.5rem',
                        }}
                    >
                        <img src={arrowDown} className={styles['role-card__arrow-down']} alt="arrow-down" onClick={() => setIsOpen(!isOpen)} />
                    </div>
                </div>
                {isOpen && (
                    <div
                        className="row mx-0 w-100"
                        style={{
                            height: '40rem',
                            padding: '2rem',
                            borderRadius: '0 0 2rem 2rem',
                            background: 'rgba(255,255,255,0.5)',
                        }}
                    >
                        <div className="col-auto px-0">
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <span className={`${styles['role-card__sub-title']}`}>Permissions</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    {rolePermissions &&
                                        rolePermissions.length > 0 &&
                                        rolePermissions.map((permission) => {
                                            return <PermissionTree key={permission.category} {...permission} shouldContainRoles={false} />
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="col px-0 h-100 d-flex flex-column" style={{ minWidth: 0 }}>
                            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                                <div className="col px-0">
                                    <span className={`${styles['role-card__sub-title']}`}>Assigned to</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 flex-grow-1" style={{ minHeight: 0 }}>
                                <div className="col px-0 h-100">
                                    <AssignedToList />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
