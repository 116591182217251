import React from 'react'

export const managerTeamListMock = {
    teams: [],
    attr: 'teamList',
    selected: false,
    settings: {
        view: {
            label: '',
            options: [
                {
                    name: 'Option',
                    icon: 'default',
                    iconDefault: undefined,
                    icoSelected: undefined,
                },
            ],
        },
        options: {
            label: '',
            options: [],
        },
        reference: React.createRef(),
    },
    disabled: true,
    loading: true,
    placeholderText: '',
}
