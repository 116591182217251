import React, { useMemo, useRef, useState } from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { usePub } from '../../../../../hooks/usePubSub';
import { useFilter } from '../../../../../providers/filter-context';
import { useSelectInput } from '../../../../../hooks/useSelectInput';
import { errorHandler } from '../../../../../utils/api';
import {
    Modal,
    PrimaryButton,
    RadioInput,
    SelectInput,
    SuccessModal,
    TagMultipleInput,
    TerciaryButton
} from '../../../../../components';
import { useRatioInput } from '../../../../../hooks/useRatioInput';
import { useMultipleInputs } from '../../../../../hooks/useMultipleInputs';
import DynamicTypeInput from '../../../../../components/inputs/dynamic-type-input/DynamicTypeInput';
import styles from './invite-member-modal.module.scss';
import { ModalInputLabel } from '../../../../../components/text/modal-input-label/ModalInputLabel';
import { useTagMultipleInput } from '../../../../../hooks/useTagMultipleInput';
import { useNavigate } from 'react-router-dom';
import { usePlatformFormat } from '../../../../../providers/platform-format-context';

export const InviteMemberModal = ({ onCloseModal, onEnd, type, orgId }) => {
    const publish = usePub();
    const { format } = usePlatformFormat();
    const navigate = useNavigate();
    const { t } = useCustomTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { filter } = useFilter();

    const {
        value: memberRole,
        toggleDropdown: memberRoleToggleDropdown,
        selectOption: memberRoleSelectOption,
        keyPress: memberRoleKeyPress,
        keyDown: memberRoleKeyDown,
        setValue: memberRoleSetValue,
        replaceAll: memberRoleReplaceAll
    } = useSelectInput({
        name: 'memberRole',
        showOptions: true,
        options: ['role 1', 'role 2'],
        value: '',
        optionsAux: ['role 1', 'role 2'],
        state: 'normal',
        focused: -1,
        reference: useRef()
    });

    const {
        value: organization,
        toggleDropdown: organizationToggleDropdown,
        selectOption: organizationSelectOption,
        keyPress: organizationKeyPress,
        keyDown: organizationKeyDown,
        setValue: organizationSetValue,
        replaceAll: organizationReplaceAll
    } = useSelectInput({
        name: 'organization',
        showOptions: true,
        options: [],
        value: '',
        optionsAux: [],
        state: 'normal',
        focused: -1,
        reference: useRef()
    });

    const {
        value: profileType,
        clickRatioOption: profileTypeClickRadioOption
    } = useRatioInput({
        name: 'profileType',
        options: [
            {
                name: 'enterprise',
                label: t(`inputs:enterprise`),
                disabled: false,
                blocked: false
            },
            {
                name: 'organization',
                label: t(`inputs:organization`),
                disabled: false,
                blocked: false
            }
        ]
    });

    const {
        value: inputs,
        setValueNew: inputsSetValue,
        replaceAll: inputsReplaceAll
    } = useMultipleInputs({
        membersAddress: {
            name: 'membersAddress',
            path: 'membersAddress',
            label: t('inputs:membersAddress'),
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        }
    });

    // useEffect(() => {
    //     async function fill() {
    //         const newmemberRole = {
    //             name: 'memberRole',
    //             showOptions: true,
    //             options: [],
    //             optionsAux: [],
    //             state: 'normal',
    //             value: '',
    //             focused: -1,
    //             reference: React.createRef()
    //         };
    //         const dataBack = await TeamsService.getApiTeams({
    //             params: {},
    //             leaderEmpNo: undefined
    //         });
    //         const productTeamsBack = await ProductsService.getApiProductsTeams({
    //             productId: filter.productId ? filter.productId : undefined
    //         });
    //         dataBack
    //             .filter((el) => {
    //                 const found = productTeamsBack.find(
    //                     (element) => element.id === el.id
    //                 );
    //                 if (!found) {
    //                     newmemberRole.options.push(el.name);
    //                     newmemberRole.optionsAux.push(el.id);
    //                 }
    //                 return el;
    //             })
    //             .map((el) => {
    //                 newmemberRole.options.push(el.name);
    //                 newmemberRole.optionsAux.push(el.id);
    //                 return el;
    //             });
    //         memberRoleReplaceAll(newmemberRole);
    //     }
    //     fill();
    // }, [filter.productId, memberRoleReplaceAll]);

    const handleInviteMember = async () => {
        try {
            setLoading(true);
            // await TeamsService.patchApiTeamsProducts({
            //     teamId: memberRole.selectedOptionAux,
            //     requestBody: filter.productId ? [filter.productId] : undefined
            // });
            if (onEnd) {
                onEnd();
            }
            setIsSuccess(true);
            setLoading(false);
            publish('product');
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleCreateOrganization = () => {
        navigate('/organizations', {
            state: { from: 'inviteMember' }
        });
    };

    const renderEnterpriseInputs = () => {
        return (
            <>
                {!type && !orgId && (
                    <div
                        className={`row mx-0 w-100`}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0 d-flex justify-content-end align-items-center">
                            <RadioInput
                                {...profileType}
                                direction="horizontal"
                                onClickRatioOption={profileTypeClickRadioOption}
                            />
                        </div>
                    </div>
                )}
                {!type &&
                    !orgId &&
                    profileType.selectedOption === 'organization' &&
                    organization.options.length === 0 && (
                        <>
                            <div
                                className={`row mx-0 w-100`}
                                style={{ margin: '5rem 0' }}
                            >
                                <div className="col px-0 d-flex justify-content-end align-items-center">
                                    <span
                                        className={
                                            styles[
                                                'invite-member-modal-container__informational-text'
                                            ]
                                        }
                                    >
                                        {t(
                                            'modals:inviteMembers.noOrganization'
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="row mx-0 d-flex justify-content-center align-items-center">
                                <div className="col-auto px-0">
                                    <PrimaryButton
                                        text={t('buttons:createOrganization')}
                                        onClick={() => {
                                            handleCreateOrganization();
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                {(profileType.selectedOption || type) && (
                    <>
                        {!type &&
                            !orgId &&
                            profileType.selectedOption === 'organization' &&
                            organization.options.length > 0 && (
                                <>
                                    <ModalInputLabel
                                        text={
                                            'Choose which organization the members belong to'
                                        }
                                    />
                                    <div
                                        className={`row mx-0 w-100`}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <div className="col px-0 d-flex justify-content-end align-items-center">
                                            <SelectInput
                                                hideLabel
                                                onChangeInput={
                                                    organizationSetValue
                                                }
                                                onSelectInput={
                                                    organizationSelectOption
                                                }
                                                hideOptionsAux
                                                onSelectIconInput={
                                                    organizationSelectOption
                                                }
                                                onKeyPress={
                                                    organizationKeyPress
                                                }
                                                onKeyDown={organizationKeyDown}
                                                onToggleDropdown={
                                                    organizationToggleDropdown
                                                }
                                                {...organization}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        {((profileType.selectedOption === 'organization' &&
                            organization.options.length > 0) ||
                            profileType.selectedOption === 'enterprise' ||
                            type) && (
                            <>
                                <ModalInputLabel text={'Address of emails'} />
                                <div
                                    className={`row mx-0 w-100`}
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0 d-flex justify-content-end align-items-center">
                                        <DynamicTypeInput
                                            onChangeInput={inputsSetValue}
                                            {...inputs['membersAddress']}
                                            elementText={t('inputs:members')}
                                        />
                                    </div>
                                </div>

                                <ModalInputLabel
                                    text={
                                        'Specify the type of role that these members will have access to'
                                    }
                                />
                                <div
                                    className={`row mx-0 w-100`}
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col px-0 d-flex justify-content-end align-items-center">
                                        <SelectInput
                                            hideLabel
                                            onChangeInput={memberRoleSetValue}
                                            onSelectInput={
                                                memberRoleSelectOption
                                            }
                                            hideOptionsAux
                                            onSelectIconInput={
                                                memberRoleSelectOption
                                            }
                                            onKeyPress={memberRoleKeyPress}
                                            onKeyDown={memberRoleKeyDown}
                                            onToggleDropdown={
                                                memberRoleToggleDropdown
                                            }
                                            {...memberRole}
                                        />
                                    </div>
                                </div>
                                <hr
                                    className="my-4"
                                    style={{
                                        color: 'rgba(0,0,0,0.1)',
                                        opacity: '1'
                                    }}
                                />
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <TerciaryButton
                                            text={t('buttons:cancel')}
                                            onClick={() => {
                                                onCloseModal(false);
                                            }}
                                        />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton
                                            text={t('buttons:sendInvitation')}
                                            disabled={
                                                (profileType.selectedOption ===
                                                    'organization' &&
                                                    !organization.selectedOptionAux) ||
                                                !inputs.membersAddress.value ||
                                                !memberRole.selectedOptionAux
                                            }
                                            onClick={() => {
                                                handleInviteMember();
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    const renderOrganizationInputs = () => {
        return (
            <>
                <ModalInputLabel text={'Address of emails'} />
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <DynamicTypeInput
                            onChangeInput={inputsSetValue}
                            {...inputs['membersAddress']}
                            elementText={t('inputs:members')}
                        />
                    </div>
                </div>

                <ModalInputLabel
                    text={
                        'Specify the type of role that these members will have access to'
                    }
                />
                <div
                    className={`row mx-0 w-100`}
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0 d-flex justify-content-end align-items-center">
                        <SelectInput
                            hideLabel
                            onChangeInput={memberRoleSetValue}
                            onSelectInput={memberRoleSelectOption}
                            hideOptionsAux
                            onSelectIconInput={memberRoleSelectOption}
                            onKeyPress={memberRoleKeyPress}
                            onKeyDown={memberRoleKeyDown}
                            onToggleDropdown={memberRoleToggleDropdown}
                            {...memberRole}
                        />
                    </div>
                </div>
                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1'
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <TerciaryButton
                            text={t('buttons:cancel')}
                            onClick={() => {
                                onCloseModal(false);
                            }}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('buttons:sendInvitation')}
                            disabled={
                                (profileType.selectedOption ===
                                    'organization' &&
                                    !organization.selectedOptionAux) ||
                                !inputs.membersAddress.value ||
                                !memberRole.selectedOptionAux
                            }
                            onClick={() => {
                                handleInviteMember();
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    if (!isSuccess) {
        return (
            <Modal
                onToggleModal={() => onCloseModal(false)}
                isLoading={isLoading}
            >
                <div style={{ width: '40rem', height: '100%' }}>
                    <div className="row mx-0 mb-4">
                        <div className="col px-0">
                            <span className="modal-title">
                                {t('modals:inviteMembers.title')}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 mb-4">
                        <div className="col px-0">
                            <span className="modal-sub-title">
                                {t('modals:inviteMembers.subTitle')}
                            </span>
                        </div>
                    </div>
                    {format === 'enterprise' && renderEnterpriseInputs()}
                    {format === 'organization' && renderOrganizationInputs()}
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:inviteMembers.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
