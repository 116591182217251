import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { SalesService } from '../../../temp/test';
import { productSalesMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component.js';
import { useChartReady } from '../../../providers/chart-ready-context';
import {
    ContentContainer,
    DynamicBarChartContainer,
    Loader,
    Spinner
} from '../../';
import { getFilterParam } from '../../../utils/params';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays comparison of sales between products
 */

export const ProductSales = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { charts } = useChartReady();
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { i18n } = useTranslation();
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(productSalesMock);

        const getProductSalesInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(productSalesMock);
            try {
                if (!isExample) {
                    if (isMounted.current) {
                        setLoading(true);
                    }
                    if (type) dataClone.attr = type;
                    const dataBack = await SalesService.getApiSalesDistribution(
                        {
                            clientId: getFilterParam(filter, config, [
                                'clientId',
                                'clientIds'
                            ]),
                            vendorId: getFilterParam(filter, config, [
                                'vendorId',
                                'vendorIds'
                            ])
                        }
                    );
                    dataClone.data = dataBack.map((el) => {
                        return {
                            productName: el.product_name,
                            productId: el.id,
                            productSales: el.value
                        };
                    });
                    dataClone.barStackedAttr = [
                        {
                            dataKey: 'productSales',
                            gradientColors: ['199788', '26b5a4'],
                            stackId: 'a',
                            minPointSize: 70,
                            radius: [0, 100, 100, 0]
                        }
                    ];
                    dataClone.categoryAxis = 'productName';
                    dataClone.categoryLabel = '';
                    dataClone.unitsOrValues = 'values';
                    dataClone.loading = false;
                    dataClone.ready = true;

                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } else {
                    if (type) dataClone.attr = type;
                    verifyComponent(dataClone);
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [config, filter, isExample, isMounted, setLoading, setValue, type]);

        useEffect(() => {
            getProductSalesInformation();
        }, [getProductSalesInformation]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <DynamicBarChartContainer
                            translate={t}
                            barStackedAttr={data.barStackedAttr}
                            categoryAxis={data.categoryAxis}
                            categoryLabel={data.categoryLabel}
                            orientation={data.orientation}
                            data={data.data}
                            margin={data.margin}
                            tooltipType={data.tooltipType}
                            unitsOrValues={data.unitsOrValues}
                            barWidth={data.barWidth}
                            gradient
                        />
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ProductSales.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
