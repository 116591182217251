import React from 'react'
import styles from './risk-distribution-table.module.scss'
export const RiskDistributionTable = React.memo(
    ({ risk1Percent, risk2Percent, risk3Percent, risk4Percent }) => {
        return (
            <div className={`${styles['risk-distribution-table-container']}`}>
                <div
                    className={`${styles['risk-distribution-table-container__risk-bar']}`}
                    style={{ backgroundColor: '#7dd081' }}
                >
                    <span
                        className={`${styles['risk-distribution-table-container__risk-text']}`}
                    >
                        {risk1Percent}%
                    </span>
                </div>
                <div
                    className={`${styles['risk-distribution-table-container__risk-bar']}`}
                    style={{ backgroundColor: '#e1a43a' }}
                >
                    <span
                        className={`${styles['risk-distribution-table-container__risk-text']}`}
                    >
                        {risk2Percent}%
                    </span>
                </div>
                <div
                    className={`${styles['risk-distribution-table-container__risk-bar']}`}
                    style={{ backgroundColor: '#f4841b' }}
                >
                    {' '}
                    <span
                        className={`${styles['risk-distribution-table-container__risk-text']}`}
                    >
                        {risk3Percent}%
                    </span>
                </div>
                <div
                    className={`${styles['risk-distribution-table-container__risk-bar']}`}
                    style={{ backgroundColor: '#db6162' }}
                >
                    <span
                        className={`${styles['risk-distribution-table-container__risk-text']}`}
                    >
                        {risk4Percent}%
                    </span>
                </div>
            </div>
        )
    }
)
