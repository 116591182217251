import React, { useState } from 'react';
import styles from './PendingInvitations.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { PrimaryButton } from '../../../../../components';
import { InviteMemberModal } from '../invite-member-modal/invite-member-modal';
import { PendingInvitationsList } from '../pending-invitations-list/PendingInvitationsList';
import { usePlatformFormat } from '../../../../../providers/platform-format-context';

export const PendingInvitations = () => {
    const { t } = useCustomTranslation();
    const [inviteMemberModal, setInviteMemberModal] = useState(false);
    return (
        <>
            {inviteMemberModal && (
                <InviteMemberModal
                    onCloseModal={() => setInviteMemberModal(false)}
                />
            )}
            <div className={styles['pending-invitations-container']}>
                <div
                    className="row mx-0 w-100"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0">
                        <span className={'dashboard-sub-title'}>
                            {t(
                                'pages:peopleAndPermissions.pendingInvitationsSubTitle'
                            )}
                        </span>
                    </div>
                </div>
                <div className="row mx-0 w-100 h-100">
                    <div className="col px-0">
                        <PendingInvitationsList />
                    </div>
                    <div
                        className="col-auto px-0"
                        style={{ width: '2rem' }}
                    ></div>
                    <div className="col-auto px-0 d-flex flex-column justify-content-center">
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                            <div className="col-auto px-0">
                                <span
                                    className={
                                        styles[
                                            'pending-invitations-container__big-text'
                                        ]
                                    }
                                >
                                    100
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                            style={{
                                marginBottom: '2rem'
                            }}
                        >
                            <div className="col-auto px-0">
                                <span
                                    className={
                                        styles[
                                            'pending-invitations-container__medium-text'
                                        ]
                                    }
                                >
                                    Invitations Pending
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                            <div className="col-auto px-0">
                                <PrimaryButton
                                    text={t('buttons:inviteMembers')}
                                    onClick={() => setInviteMemberModal(true)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
