import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IcoCloseModal } from './assets/images/ico-close-modal.svg';
import styles from './tabs-modal.module.scss';
import { LoaderForeground, NavigationMenu, Scrollable } from '../..';

export const TabsModal = React.memo(
    ({ tabs, currentTab, onChangeTab, onToggleModal, children, isLoading }) => {
        const reference = React.createRef();
        const handleOutsideClick = useCallback(
            (event) => {
                if (
                    reference.current &&
                    !reference.current.contains(event.target)
                ) {
                    event.preventDefault();
                    event.stopPropagation();
                    onToggleModal();
                }
            },
            [onToggleModal, reference]
        );

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick);
            return function cleanup() {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [handleOutsideClick]);

        return (
            <div className={`${styles['tabs-modal-container']}`}>
                <div className={`${styles['tabs-modal-container__content']}`}>
                    {onToggleModal && (
                        <div
                            className={`${styles['tabs-modal-container__content__icon-container']}`}
                        >
                            <IcoCloseModal
                                onClick={onToggleModal}
                                style={{
                                    width: '3rem',
                                    height: '3rem'
                                }}
                            />
                        </div>
                    )}
                    <div
                        className={`${styles['tabs-modal-container__content__wrapper']}`}
                        ref={reference}
                    >
                        <div className="row mx-0 w-100 d-flex justify-content-center">
                            <div className="col-auto px-0">
                                <NavigationMenu
                                    isAlternativeColor
                                    options={tabs}
                                    selectedOption={currentTab}
                                    onChangeOption={onChangeTab}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0">
                                {isLoading && (
                                    <div className="row mx-0">
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <LoaderForeground
                                                maxSize={18}
                                                speed={4}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div
                                    style={{
                                        maxHeight: '71rem',
                                        height: '100%'
                                    }}
                                >
                                    <Scrollable addRightSpacing>
                                        <div
                                            className="w-100 h-100"
                                            style={{ padding: '2rem' }}
                                        >
                                            {children}
                                        </div>
                                    </Scrollable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

TabsModal.propTypes = {
    /**
     * Function to toggle modal
     * */
    onToggleModal: PropTypes.func
};
