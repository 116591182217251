import React from 'react'
import imagePlaceholder from '../../../assets/images/user-img.png'

export const userInformationMock = {
    image: imagePlaceholder,
    name: 'John Doe',
    deparment: 'Sales Department',
    title: 'Manager',
    zoneCode: '896-1683',
    location: 'Lisbon',
    phone: '9618239126',
    email: 'john.doe@email.com',
    settings: {
        view: {
            options: [],
        },
        options: {
            options: [],
        },
        reference: React.createRef(),
    },
    disabled: true,
    loading: true,
}
