import styles from './product-information.module.scss';

import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { DTablesService, ProductsService } from '../../../temp/test';
import componentConfig from '../../../configs/componentLibraryConf.json';

import { productInformationMock } from './mock';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { useToggle } from '../../../hooks/useToggle';
import {
    ContentContainer,
    Loader,
    Scrollable,
    SelectMultipleInput,
    Spinner,
    TagMultipleInput,
    TagsContainer,
    TypeInput
} from '../../';
import { useTextInput } from '../../../hooks/useTextInput';
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput';
import { useTagMultipleInput } from '../../../hooks/useTagMultipleInput';
import { success } from '../../notifications/notification/notification';
import { useSub } from '../../../hooks/usePubSub';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays general information about a product
 */

export const ProductInformation = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [isEditing, toggleIsEditing] = useToggle(false);

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(productInformationMock);

        const {
            value: name,
            setValue: nameSetValue,
            replaceValue: nameReplaceValue
        } = useTextInput({
            name: 'name',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: categories,
            toggleDropdown: categoriesToggleDropdown,
            selectOption: categoriesSelectOption,
            unselectOption: categoriesUnselectOption,
            keyPress: categoriesKeyPress,
            keyDown: categoriesKeyDown,
            setValue: categoriesSetValue,
            replaceAll: categoriesReplaceAll
        } = useSelectMultipleInput({
            name: 'categories',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: targetSegments,
            toggleDropdown: targetSegmentsToggleDropdown,
            selectOption: targetSegmentsSelectOption,
            unselectOption: targetSegmentsUnselectOption,
            keyPress: targetSegmentsKeyPress,
            keyDown: targetSegmentsKeyDown,
            setValue: targetSegmentsSetValue,
            replaceAll: targetSegmentsReplaceAll,
            replaceSelectedOptions: targetSegmentsReplaceSelectedOptions
        } = useSelectMultipleInput({
            name: 'targetSegments',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: price,
            setValue: priceSetValue,
            replaceValue: priceReplaceValue
        } = useTextInput({
            name: 'price',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: externalId,
            setValue: externalIdSetValue,
            replaceValue: externalIdReplaceValue
        } = useTextInput({
            name: 'externalId',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: producer,
            setValue: producerSetValue,

            replaceValue: producerReplaceValue
        } = useTextInput({
            name: 'producer',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: description,
            setValue: descriptionSetValue,
            replaceValue: descriptionReplaceValue
        } = useTextInput({
            name: 'description',
            value: '',
            error: '',
            hidden: false,
            state: 'normal'
        });

        const {
            value: presentations,
            toggleDropdown: presentationsToggleDropdown,
            selectOption: presentationsSelectOption,
            unselectOption: presentationsUnselectOption,
            keyPress: presentationsKeyPress,
            keyDown: presentationsKeyDown,
            setValue: presentationsSetValue,
            addKeyword: presentationsAddKeyword,
            reset: presentationsReset
        } = useTagMultipleInput({
            name: 'presentations',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const getTeamInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(productInformationMock);
            try {
                if (!isExample) {
                    if (isMounted.current) {
                        setLoading(true);
                    }
                    if (type) dataClone.attr = type;
                    let dataBack;
                    if (
                        filter &&
                        filter.productId.constructor === String &&
                        filter.productId &&
                        config.filter.includes('productId')
                    ) {
                        dataBack = await ProductsService.getApiProducts1({
                            id: filter.productId
                        });
                    }
                    if (dataBack) {
                        dataClone.data.name = dataBack.title
                            ? dataBack.title
                            : '';
                        dataClone.data.description = dataBack.description
                            ? dataBack.description
                            : '';
                        dataClone.data.format = dataBack.format
                            ? dataBack.format
                            : '';
                        dataClone.data.productId = dataBack.id
                            ? dataBack.id
                            : '';
                        dataClone.data.segment = dataBack.segment
                            ? dataBack.segment
                            : '';
                        dataClone.data.presentations = dataBack.versions
                            ? dataBack.versions.map((el) => {
                                  return { name: el.version, id: el.id };
                              })
                            : [];
                        dataClone.data.targetSegments = dataBack.target_segments
                            ? dataBack.target_segments.map((el) => {
                                  return {
                                      name: el.name,
                                      id: el.id
                                  };
                              })
                            : [];
                        dataClone.data.categories = dataBack.categories
                            ? dataBack.categories.map((el) => {
                                  return {
                                      name: el.description,
                                      id: el.id
                                  };
                              })
                            : [];
                        dataClone.data.price = dataBack.price
                            ? '€' + dataBack.price
                            : '';
                        const associatedTeamsBack =
                            await ProductsService.getApiProductsTeams({
                                productId: dataBack.id
                            });
                        dataClone.data.associatedTeams = associatedTeamsBack
                            ? associatedTeamsBack.map((el) => {
                                  return {
                                      name: el.name,
                                      id: el.id
                                  };
                              })
                            : [];
                        dataClone.data.externalId = dataBack.ext_id
                            ? dataBack.ext_id
                            : '';
                        dataClone.data.producer = dataBack.producer
                            ? dataBack.producer === 'self'
                                ? 'My Products'
                                : dataBack.producer
                            : '';
                        dataClone.data.image = dataBack.image_url;
                        dataClone.loading = false;
                        dataClone.noData = false;
                    } else {
                        dataClone.noData = true;
                        dataClone.loading = false;
                    }

                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                    let additionalData = [];
                    if (
                        config.additionalInfo &&
                        config.additionalInfo.length !== 0
                    ) {
                        additionalData =
                            await DTablesService.getApiBackofficeAdminDtablesColumns(
                                {
                                    columns: config.additionalInfo,
                                    ids:
                                        filter &&
                                        filter.productId.constructor ===
                                            String &&
                                        filter.productId !== '' &&
                                        config.filter.includes('productId')
                                            ? [filter.productId]
                                            : []
                                }
                            );
                    }
                    if (additionalData.length !== 0) {
                        config.additionalInfo.map((el2) => {
                            dataClone.customInfo.push(el2);
                            dataClone[el2] = additionalData[0][el2];
                            return el2;
                        });
                    }
                    if (isMounted.current) setValue(dataClone);
                } else {
                    if (type) dataClone.attr = type;
                    verifyComponent(dataClone);
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [config, filter, isExample, isMounted, setLoading, setValue, type]);

        useEffect(() => {
            if (filter) {
                getTeamInformation();
            }
        }, [getTeamInformation, filter]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        const handleOpenEditMode = useCallback(async () => {
            if (isMounted.current) {
                toggleIsEditing(true);
                nameReplaceValue(data.data.name);
                const categoryDataBack =
                    await ProductsService.getApiProductsCategories({
                        productId: data.data.productId
                    });
                const newCategories = {
                    name: 'categories',
                    showOptions: true,
                    state: 'normal',
                    focused: -1,
                    reference: React.createRef(),
                    options: categoryDataBack
                        .filter((el) => {
                            return !_.some(data.data.categories, { id: el.id });
                        })
                        .map((el) => {
                            return el.code;
                        }),
                    optionsAux: categoryDataBack
                        .filter((el) => {
                            return !_.some(data.data.categories, { id: el.id });
                        })
                        .map((el) => {
                            return el.id;
                        }),
                    selectedOptions: [
                        data.data.categories.map((el) => {
                            return el.name;
                        })
                    ],
                    selectedOptionsAux: [
                        data.data.categories.map((el) => {
                            return el.id;
                        })
                    ]
                };
                categoriesReplaceAll(newCategories);
                const targetSegmentsDataBack =
                    await ProductsService.getApiProductsTargetSegments({});
                const newTargetSegments = {
                    name: 'targetSegments',
                    showOptions: true,
                    state: 'normal',
                    focused: -1,
                    reference: React.createRef(),
                    options: targetSegmentsDataBack
                        .filter((el) => {
                            return !_.some(data.data.targetSegments, {
                                id: el.id
                            });
                        })
                        .map((el) => {
                            return el.name;
                        }),
                    optionsAux: targetSegmentsDataBack
                        .filter((el) => {
                            return !_.some(data.data.targetSegments, {
                                id: el.id
                            });
                        })
                        .map((el) => {
                            return el.id;
                        }),
                    selectedOptions: [
                        data.data.targetSegments.map((el) => {
                            return el.name;
                        })
                    ],
                    selectedOptionsAux: [
                        data.data.targetSegments.map((el) => {
                            return el.id;
                        })
                    ]
                };
                targetSegmentsReplaceAll(newTargetSegments);

                await ProductsService.getApiProductsCategories({
                    productId: data.data.productId
                });
                targetSegmentsReplaceSelectedOptions(
                    data.data.targetSegments.map((el) => el.name),
                    data.data.targetSegments.map((el) => el.id)
                );
                priceReplaceValue(data.data.price);
                externalIdReplaceValue(data.data.externalId);
                producerReplaceValue(data.data.producer);
                presentationsReset();
                descriptionReplaceValue(data.data.description);
                data.data.presentations.map((el) => {
                    presentationsAddKeyword(el.name);
                });
            }
        }, [
            presentationsReset,
            presentationsAddKeyword,
            categoriesReplaceAll,
            data.data.productId,
            targetSegmentsReplaceAll,
            data.data.categories,
            data.data.description,
            data.data.externalId,
            data.data.name,
            data.data.presentations,
            data.data.price,
            data.data.producer,
            data.data.targetSegments,
            descriptionReplaceValue,
            externalIdReplaceValue,
            isMounted,
            nameReplaceValue,
            priceReplaceValue,
            producerReplaceValue,
            targetSegmentsReplaceSelectedOptions,
            toggleIsEditing
        ]);

        const handleEdit = useCallback(async () => {
            try {
                await ProductsService.patchApiProducts({
                    id: data.data.productId,
                    requestBody: {
                        title: name.value,
                        categories: categories.selectedOptionsAux.map((el) => {
                            return {
                                id: parseInt(el)
                            };
                        }),
                        target_segments: targetSegments.selectedOptionsAux.map(
                            (el) => {
                                return {
                                    id: parseInt(el)
                                };
                            }
                        ),
                        price: parseInt(price.value),
                        ext_id: externalId.value,
                        producer: producer.value,
                        description: description.value,
                        versions: presentations.selectedOptions.map((el) => {
                            return {
                                version: el
                            };
                        })
                    }
                });
                success({ text: t('notifications:productSaved') });
                getTeamInformation();
                toggleIsEditing(false);
            } catch (err) {
                errorHandler(err);
                if (isMounted.current) setLoading(false);
            }
        }, [
            presentations.selectedOptions,
            toggleIsEditing,
            getTeamInformation,
            t,
            categories.selectedOptionsAux,
            data,
            description.value,
            externalId.value,
            isMounted,
            name.value,
            price.value,
            producer.value,
            setLoading,
            targetSegments.selectedOptionsAux
        ]);

        const handlePresentationsUnselectOption = useCallback(
            (value, valueAux) => {
                presentationsUnselectOption(value, valueAux);
            },
            [presentationsUnselectOption]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                    onOpenEditMode={
                        !data.noData && !isPreview && handleOpenEditMode
                    }
                    isEditing={isEditing}
                    onSaveEditMode={handleEdit}
                    onCancelEditMode={toggleIsEditing}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`${styles['product-information-card-container']}`}
                        >
                            <Scrollable>
                                <>
                                    <div
                                        className="row mx-0"
                                        style={{
                                            height:
                                                !data.noData && !data.loading
                                                    ? undefined
                                                    : '100%'
                                        }}
                                    >
                                        {!data.noData && (
                                            <div
                                                className={`col-2 px-0 h-inherit`}
                                            >
                                                <img
                                                    loading="lazy"
                                                    src={data.data.image}
                                                    alt="client"
                                                    style={{
                                                        border: '1px solid #e6e6e6',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain',
                                                        borderRadius: '8px'
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className="col-auto px-0"
                                            style={{ width: '2rem' }}
                                        ></div>

                                        {_.has(data, 'noData') &&
                                            data.noData && (
                                                <div
                                                    className={`col px-0 h-100 d-flex justify-content-center align-items-center`}
                                                >
                                                    <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                                        <div className="col-auto px-0 h-100 d-flex justify-content-center align-items-center">
                                                            <span
                                                                className={`${styles['no-data-text']}`}
                                                            >
                                                                {t(
                                                                    `common:noData`
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {!data.noData && !data.loading && (
                                            <div className="col px-0">
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.name`
                                                            )}
                                                        </h1>
                                                        {!isEditing && (
                                                            <h1
                                                                className={`${styles['product-information-card-container__content-big']}`}
                                                                style={{
                                                                    height: '4.5rem',
                                                                    display:
                                                                        'block'
                                                                }}
                                                            >
                                                                {data.data.name
                                                                    ? data.data
                                                                          .name
                                                                    : ''}
                                                            </h1>
                                                        )}
                                                        {isEditing && (
                                                            <TypeInput
                                                                hideLabel
                                                                onChangeInput={
                                                                    nameSetValue
                                                                }
                                                                {...name}
                                                            />
                                                        )}
                                                        <h1
                                                            className={`${styles['product-information-card-container__content']}`}
                                                        ></h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row mx-0 w-100"
                                                    style={{
                                                        marginBottom: '2rem'
                                                    }}
                                                >
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.category`
                                                            )}
                                                        </h1>

                                                        {!isEditing && (
                                                            <TagsContainer
                                                                items={
                                                                    data.data
                                                                        .categories
                                                                }
                                                            />
                                                        )}
                                                        {isEditing && (
                                                            <SelectMultipleInput
                                                                hideOptionsAux
                                                                hideLabel
                                                                disabled={
                                                                    categories.disabled
                                                                }
                                                                onChangeInput={
                                                                    categoriesSetValue
                                                                }
                                                                onSelectMultiple={
                                                                    categoriesSelectOption
                                                                }
                                                                onSelectedOptionClick={
                                                                    categoriesUnselectOption
                                                                }
                                                                onKeyPress={
                                                                    categoriesKeyPress
                                                                }
                                                                onKeyDown={
                                                                    categoriesKeyDown
                                                                }
                                                                onToggleDropdown={
                                                                    categoriesToggleDropdown
                                                                }
                                                                {...categories}
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    ></div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.targetSegment`
                                                            )}
                                                        </h1>

                                                        {!isEditing && (
                                                            <TagsContainer
                                                                items={
                                                                    data.data
                                                                        .targetSegments
                                                                }
                                                            />
                                                        )}
                                                        {isEditing && (
                                                            <SelectMultipleInput
                                                                hideOptionsAux
                                                                hideLabel
                                                                disabled={
                                                                    targetSegments.disabled
                                                                }
                                                                onChangeInput={
                                                                    targetSegmentsSetValue
                                                                }
                                                                onSelectMultiple={
                                                                    targetSegmentsSelectOption
                                                                }
                                                                onSelectedOptionClick={
                                                                    targetSegmentsUnselectOption
                                                                }
                                                                onKeyPress={
                                                                    targetSegmentsKeyPress
                                                                }
                                                                onKeyDown={
                                                                    targetSegmentsKeyDown
                                                                }
                                                                onToggleDropdown={
                                                                    targetSegmentsToggleDropdown
                                                                }
                                                                {...targetSegments}
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    ></div>
                                                    <div className="col px-0 h-100">
                                                        <div className="col px-0">
                                                            <h1
                                                                className={`${styles['product-information-card-container__title']}`}
                                                                style={{
                                                                    width: '120px'
                                                                }}
                                                            >
                                                                {t(
                                                                    `components:${data.attr}.price`
                                                                )}
                                                            </h1>
                                                            <h1
                                                                className={`${styles['product-information-card-container__name']}`}
                                                            >
                                                                {!isEditing && (
                                                                    <div
                                                                        style={{
                                                                            height: '4.5rem',
                                                                            display:
                                                                                'block'
                                                                        }}
                                                                    >
                                                                        {data
                                                                            .data
                                                                            .price
                                                                            ? data
                                                                                  .data
                                                                                  .price
                                                                            : '-'}
                                                                    </div>
                                                                )}
                                                                {isEditing && (
                                                                    <TypeInput
                                                                        hideLabel
                                                                        onChangeInput={
                                                                            priceSetValue
                                                                        }
                                                                        {...price}
                                                                    />
                                                                )}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.externalId`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['product-information-card-container__name']}`}
                                                        >
                                                            {!isEditing && (
                                                                <div
                                                                    style={{
                                                                        height: '4.5rem',
                                                                        display:
                                                                            'block'
                                                                    }}
                                                                >
                                                                    {data.data
                                                                        .externalId
                                                                        ? data
                                                                              .data
                                                                              .externalId
                                                                        : '-'}
                                                                </div>
                                                            )}
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        externalIdSetValue
                                                                    }
                                                                    {...externalId}
                                                                />
                                                            )}
                                                        </h1>
                                                    </div>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    ></div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.producer`
                                                            )}
                                                        </h1>
                                                        <h1
                                                            className={`${styles['product-information-card-container__name']}`}
                                                        >
                                                            {!isEditing && (
                                                                <div
                                                                    style={{
                                                                        height: '4.5rem',
                                                                        display:
                                                                            'block'
                                                                    }}
                                                                >
                                                                    {data.data
                                                                        .producer
                                                                        ? data
                                                                              .data
                                                                              .producer
                                                                        : '-'}
                                                                </div>
                                                            )}
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        producerSetValue
                                                                    }
                                                                    {...producer}
                                                                />
                                                            )}
                                                        </h1>
                                                    </div>
                                                    <div
                                                        className="col-auto px-0"
                                                        style={{
                                                            width: '2rem'
                                                        }}
                                                    ></div>
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.associatedTeams`
                                                            )}
                                                        </h1>
                                                        <TagsContainer
                                                            items={
                                                                data.data
                                                                    .associatedTeams
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {data.customInfo.length !==
                                                    0 && (
                                                    <div
                                                        className="row mx-0 w-100 "
                                                        style={{
                                                            marginBottom: '2rem'
                                                        }}
                                                    >
                                                        {data.customInfo.map(
                                                            (el, index) => {
                                                                if (
                                                                    index <=
                                                                    componentConfig[
                                                                        data
                                                                            .attr
                                                                    ]
                                                                        .dTableInfoLimit -
                                                                        1
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="col px-0 h-100"
                                                                        >
                                                                            <h1
                                                                                className={`${styles['competitor-information-container__side-details-container__sub-title']}`}
                                                                            >
                                                                                {
                                                                                    el
                                                                                }
                                                                            </h1>
                                                                            <h1
                                                                                className={`${styles['competitor-information-container__side-details-container__subcontent']}`}
                                                                            >
                                                                                {data[
                                                                                    el
                                                                                ]
                                                                                    ? data[
                                                                                          el
                                                                                      ]
                                                                                    : ''}
                                                                            </h1>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {!data.noData && !data.loading && (
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginTop: '2rem',
                                                marginBottom: '2rem',
                                                height: undefined
                                            }}
                                        >
                                            <div className="col-4 px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.description`
                                                            )}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={`${styles['product-information-card-container__name']}`}
                                                        >
                                                            {!isEditing && (
                                                                <div
                                                                    style={{
                                                                        height: '4.5rem',
                                                                        display:
                                                                            'block',
                                                                        wordBreak:
                                                                            'break-word'
                                                                    }}
                                                                >
                                                                    {data.data
                                                                        .description
                                                                        ? data
                                                                              .data
                                                                              .description
                                                                        : '-'}
                                                                </div>
                                                            )}
                                                            {isEditing && (
                                                                <TypeInput
                                                                    hideLabel
                                                                    onChangeInput={
                                                                        descriptionSetValue
                                                                    }
                                                                    {...description}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-auto px-0"
                                                style={{ width: '2rem' }}
                                            ></div>
                                            <div className="col px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <h1
                                                            className={`${styles['product-information-card-container__title']}`}
                                                        >
                                                            {t(
                                                                `components:${data.attr}.productPresentation`
                                                            )}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        {!isEditing && (
                                                            <TagsContainer
                                                                items={
                                                                    data.data
                                                                        .presentations
                                                                }
                                                            />
                                                        )}
                                                        {isEditing && (
                                                            <TagMultipleInput
                                                                hideLabel
                                                                hideOptionsAux
                                                                onChangeInput={
                                                                    presentationsSetValue
                                                                }
                                                                onSelectMultiple={
                                                                    presentationsSelectOption
                                                                }
                                                                onSelectedOptionClick={
                                                                    handlePresentationsUnselectOption
                                                                }
                                                                onKeyPress={
                                                                    presentationsKeyPress
                                                                }
                                                                onKeyDown={
                                                                    presentationsKeyDown
                                                                }
                                                                onToggleDropdown={
                                                                    presentationsToggleDropdown
                                                                }
                                                                {...presentations}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            </Scrollable>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ProductInformation.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
