import React, { useMemo } from 'react';
import styles from './input-label.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const InputLabel = ({
    name,
    text,
    isError,
    isValued,
    isSelected,
    type = 'normal',
    isRequired,
    isOptional,
    inputType
}) => {
    const { t } = useCustomTranslation();
    const valueToUse = useMemo(
        () => text || t(`inputs:${name}`),
        [name, text, t]
    );
    const labelStyle = [
        styles['input-label'],
        isError && styles['error'],
        isValued && styles['valued'],
        isSelected && styles['selected'],
        type === 'top' && styles['top']
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <div className={labelStyle}>
            {valueToUse}
            {isRequired && <span className={styles['required-icon']}>*</span>}
            {isOptional && (
                <span className={styles['optional-text']}>
                    ({t('common:optional')})
                </span>
            )}
        </div>
    );
};
