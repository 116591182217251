import React from 'react'
import styles from './create-ai-segment-modal.module.scss'

import { Modal } from '../modal/modal'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { PrimaryButton } from '../../buttons/primary-button/primary-button'
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button'
import LoadingBar from '../../loading/LoadingBar/LoadingBar'

export const CreateAISegmentModal = ({ onCloseModal, onBack, onSetAIResult }) => {
    const { t } = useCustomTranslation()
    const [isGenerating, setIsGenerating] = React.useState(false)
    const [data, setData] = React.useState(undefined)

    const handleGenerate = () => {
        setIsGenerating(true)
        setTimeout(() => {
            onSetAIResult([1, 1, 1, 1, 1])
        }, 3000)
    }

    return (
        <Modal title={t('modals:createAISegmentModal.title')} onToggleModal={onCloseModal}>
            {isGenerating && <RenderGeneration />}
            {data && <RenderData onBack={onBack} handleGenerate={handleGenerate} />}
            {!isGenerating && !data && <RenderContent onBack={onBack} handleGenerate={handleGenerate} />}
            <div style={{ width: '70rem' }}></div>
        </Modal>
    )
}

const RenderGeneration = () => {
    const { t } = useCustomTranslation()
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['create-ai-segment-modal-container__description']}>{t('modals:createAISegmentModal.description')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['create-ai-segment-modal-container__sub-title']}>{t('modals:createAISegmentModal.algorithmFocus')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <LoadingBar />
                </div>
            </div>
        </>
    )
}

const RenderData = ({ onBack, handleGenerate }) => {
    const { t } = useCustomTranslation()
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['create-ai-segment-modal-container__description']}>{t('modals:createAISegmentModal.description')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['create-ai-segment-modal-container__sub-title']}>{t('modals:createAISegmentModal.algorithmFocus')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                <div className="col-auto px-0">
                    <SecondaryButton text={t('buttons:back')} onClick={onBack} />
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col-auto px-0">
                    <PrimaryButton text={t('buttons:generate')} onClick={handleGenerate} />
                </div>
            </div>
        </>
    )
}

const RenderContent = ({ onBack, handleGenerate }) => {
    const { t } = useCustomTranslation()
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['create-ai-segment-modal-container__description']}>{t('modals:createAISegmentModal.description')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['create-ai-segment-modal-container__sub-title']}>{t('modals:createAISegmentModal.algorithmFocus')}</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <div className={styles['create-ai-segment-modal-container__box']}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <span className={styles['create-ai-segment-modal-container__box__title']}>
                                    {t('modals:createAISegmentModal.loyaltyAndRetention.title')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span className={styles['create-ai-segment-modal-container__box__text']}>
                                    {t('modals:createAISegmentModal.loyaltyAndRetention.description')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <div className={styles['create-ai-segment-modal-container__box']}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <span className={styles['create-ai-segment-modal-container__box__title']}>
                                    {t('modals:createAISegmentModal.buyingPattern.title')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span className={styles['create-ai-segment-modal-container__box__text']}>
                                    {t('modals:createAISegmentModal.buyingPattern.description')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <div className={styles['create-ai-segment-modal-container__box']}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                            <div className="col px-0">
                                <span className={styles['create-ai-segment-modal-container__box__title']}>
                                    {t('modals:createAISegmentModal.unknownFactors.title')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span className={styles['create-ai-segment-modal-container__box__text']}>
                                    {t('modals:createAISegmentModal.unknownFactors.description')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100 d-flex justify-content-end align-items-center">
                <div className="col-auto px-0">
                    <SecondaryButton text={t('buttons:back')} onClick={onBack} />
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col-auto px-0">
                    <PrimaryButton text={t('buttons:generate')} onClick={handleGenerate} />
                </div>
            </div>
        </>
    )
}
