import React from 'react'
import icoPlus from '../../../assets/images/ico-plus.svg'

export const tokenListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'name', label: '', sortable: true },
        { path: 'createdBy', label: '', sortable: true },
        { path: 'expirationDate', label: '', sortable: true },
        { path: 'action', label: '', sortable: false },
    ],
    sortColumn: { path: 'id', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'tokenList',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [{ name: 'addToken', label: '', icon: icoPlus }],
        },
        reference: React.createRef(),
    },
}
