import { createContext, useContext, useMemo } from 'react';
import { useFilterHook } from '../hooks/useFilterHook';

const FilterContext = createContext({
    filter: undefined,
    setFilter: () => {},
    setCompetitorId: () => {}
});

export function FilterProvider({ children }) {
    const {
        value: filter,
        setValue,
        setCompetitorId
    } = useFilterHook({
        clientId: '',
        vendorId: '',
        userId: '',
        competitorId: '',
        productId: '',
        clientIds: [],
        vendorIds: [],
        productIds: [],
        teamId: '',
        teamIds: [],
        regionId: '',
        regionIds: [],
        hospitalId: '',
        hospitalIds: []
    });

    const valueMemo = useMemo(() => {
        return {
            filter: filter,
            setFilter: setValue,
            setCompetitorId: setCompetitorId
        };
    }, [filter, setValue, setCompetitorId]);

    return (
        <FilterContext.Provider value={valueMemo}>
            {children}
        </FilterContext.Provider>
    );
}

export function useFilter() {
    return useContext(FilterContext);
}
