import React from 'react';
import icoGoalManagement from '../../../../../assets/images/icoGoalManagement.svg';

export const GoalNoItems = () => {
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <img
                        src={icoGoalManagement}
                        alt="Goal Management"
                        className="mx-auto"
                        style={{
                            width: '16rem',
                            height: '16rem',
                            filter: 'brightness(0) saturate(100%) invert(78%) sepia(0%) saturate(1344%) hue-rotate(227deg) brightness(71%) contrast(85%)'
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0 d-flex justify-content-center align-items-center">
                    <span
                        style={{
                            fontFamily: 'OpenSansRegular',
                            fontSize: '1.6rem',
                            color: '#969696'
                        }}
                    >
                        No goals found
                    </span>
                </div>
            </div>
        </>
    );
};
