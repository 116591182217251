import PropTypes from 'prop-types';
import React from 'react';
import { GoalHistoryList } from '../../../../../components/dashboard-components/goal-history-list';

/**
 * Lists all the users in a table
 */

export const GoalHistory = React.memo(() => {
    return <GoalHistoryList isExample />;
});

GoalHistory.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectUser: PropTypes.func
};
