// src/LoadingBar.js
import React from 'react';
import styles from './LoadingBar.module.scss';

const LoadingBar = () => {
    return (
        <div className={styles.loadingBar}>
            <div className={styles.loadingBarFill}></div>
        </div>
    );
};

export default LoadingBar;
