export function fromBinary(encoded) {
    const binary = atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return bytes;
}

import { toIsoDate } from './format';

export const translateMonth = (date) => {
    const month = date.split('-')[1];
    switch (month) {
        case '01':
            return 'Jan';
        case '02':
            return 'Fev';
        case '03':
            return 'Mar';
        case '04':
            return 'Abr';
        case '05':
            return 'Mai';
        case '06':
            return 'Jun';
        case '07':
            return 'Jul';
        case '08':
            return 'Ago';
        case '09':
            return 'Set';
        case '10':
            return 'Out';
        case '11':
            return 'Nov';
        case '12':
            return 'Dez';
        default:
            return 'Jan';
    }
};

export const getMonthName = (month) => {
    switch (month) {
        case 1:
            return 'Janeiro';
        case 2:
            return 'Fevereiro';
        case 3:
            return 'Março';
        case 4:
            return 'Abril';
        case 5:
            return 'Maio';
        case 6:
            return 'Junho';
        case 7:
            return 'Julho';
        case 8:
            return 'Agosto';
        case 9:
            return 'Setembro';
        case 10:
            return 'Outubro';
        case 11:
            return 'Novembro';
        case 12:
            return 'Dezembro';
        default:
            return '';
    }
};

export const getAgeFromBirthDate = (dateString) => {
    var birthday = new Date(toIsoDate(dateString));
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970) || 0;
};

export const transformDate = (date) => {
    const dateArray = date.split('-');
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

// function that gets the hours and minutes froma  format like "2023-04-25T12:41:35.861566"
export const getHoursAndMinutes = (date) => {
    const dateArray = date.split('T');
    const time = dateArray[1].split(':');
    return `${time[0]}:${time[1]}`;
};

// do the same but get the date
export const getDateFromDateTime = (date) => {
    const dateArray = date.split('T');
    return transformDate(dateArray[0]);
};

export const DateObjectToString = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    //fill with 0 if month is less than 10
    const monthString = month < 10 ? `0${month}` : `${month}`;
    const day = date.getDate();
    //fill with 0 if day is less than 10
    const dayString = day < 10 ? `0${day}` : `${day}`;
    return `${dayString}/${monthString}/${year}`;
};

export const getYearFromString = (date) => {
    const dateArray = date.split('/');
    return dateArray[2];
};

export const DateObjectToDateTimeString = (date) => {
    const hours = date.getHours();
    //fill with 0 if hours is less than 10
    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutes = date.getMinutes();
    //fill with 0 if minutes is less than 10
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursString}:${minutesString}`;
};
