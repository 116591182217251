import { useReducer } from 'react';

// Define action types for clarity and to avoid typos
const actionTypes = {
    ADD_FILTER: 'ADD_FILTER',
    REMOVE_FILTER_IF_EMPTY: 'REMOVE_FILTER_IF_EMPTY',
    CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS'
};

// The reducer function to handle state changes based on actions
const filtersReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.ADD_FILTER:
            // Check if the filter path already exists
            const index = state.findIndex(
                (filter) => filter.path === action.payload.path
            );

            // If it exists, update the filter's value; otherwise, add a new filter
            if (index > -1) {
                // Update existing filter's value only if the new values are not empty,
                // otherwise, it will be handled by the REMOVE_FILTER_IF_EMPTY action
                if (action.payload.value.length > 0) {
                    const newState = [...state];
                    newState[index].value = action.payload.value;
                    return newState;
                }
                return state;
            } else {
                return [...state, action.payload];
            }

        case actionTypes.REMOVE_FILTER_IF_EMPTY:
            // Remove the filter if the value list is empty
            const newState = state.filter(
                (filter) => filter.path !== action.payload.path
            );
            return [...newState];

        case actionTypes.CLEAR_ALL_FILTERS:
            return [];

        default:
            return state;
    }
};

// Custom hook
export const useTableFilter = (initialFilters) => {
    const [filters, dispatch] = useReducer(filtersReducer, initialFilters);

    const addOrUpdateFilter = (path, values) => {
        dispatch({
            type:
                values.length > 0
                    ? actionTypes.ADD_FILTER
                    : actionTypes.REMOVE_FILTER_IF_EMPTY,
            payload: { path, value: values }
        });
    };

    const toggleFilterOption = (path, option) => {
        // Find the filter by path
        const existingFilter = filters.find((f) => f.path === path);
        let updatedValues = [];

        if (existingFilter) {
            // Toggle the option in the filter's values

            if (existingFilter.value.includes(option)) {
                // Remove the option if it exists
                updatedValues = existingFilter.value.filter(
                    (v) => v !== option
                );
            } else {
                // Add the option if it doesn't exist
                updatedValues = [...existingFilter.value, option];
            }
        } else {
            // Add new filter with the option if it doesn't exist
            updatedValues = [option];
        }

        // Update the filter with new values or remove it if empty
        addOrUpdateFilter(path, updatedValues);
    };

    const clearAllFilters = () => {
        dispatch({ type: 'CLEAR_ALL_FILTERS' });
    };

    return [filters, toggleFilterOption, clearAllFilters];
};
