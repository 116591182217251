import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './DynamicTypeInput.module.scss'; // Assuming you've saved the SCSS with this name
import icoClearSearch from '../../../assets/images/icoClearSearch.svg';
import { useToggle } from '../../../hooks/useToggle';
import icoClose from '../../../assets/images/ico-close.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

const DynamicTypeInput = ({
    label,
    hidden,
    disabled,
    edit,
    name,
    value, // Changed from 'value' to 'values' to represent an array of values
    error,
    onChangeInput,
    onClickHiddenIcon,
    password,
    inputType,
    hovered,
    hideLabel,
    elementText
}) => {
    const { t } = useCustomTranslation();
    const [inputValues, setInputValues] = useState([...value, ''] || ['', '']); // Initialize with the passed values or a single empty string
    const [tagMode, toggleTagMode] = useToggle(false);
    const [tagInput, setTagInput] = useState('');

    useEffect(() => {
        if (inputValues[inputValues.length - 1] !== '' && !tagMode) {
            setInputValues([...inputValues, '']);
        }
    }, [inputValues, tagMode]);

    const handleInputChange = (e, index) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = e.target.value;
        setInputValues(newInputValues);
        if (onChangeInput) {
            onChangeInput({ value: newInputValues, name });
        }
    };

    const handleRemoveInput = (index) => {
        setInputValues(inputValues.filter((_, i) => i !== index));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (tagInput) {
                setInputValues([...inputValues, tagInput]);
                setTagInput('');
            }
        }
    };

    const handleRemoveTag = (index) => {
        setInputValues(inputValues.filter((_, i) => i !== index));
    };

    return (
        <div
            className={`${styles['dynamic-type-input-container']} ${
                styles[inputType] || ''
            } ${hovered ? styles.hovered : ''} ${
                disabled ? styles.disabled : ''
            }`}
        >
            {tagMode && (
                <div
                    className={
                        styles[
                            'dynamic-type-input-container__tags-input-container'
                        ]
                    }
                >
                    {inputValues.map((tag, index) => {
                        if (tag === '') {
                            return;
                        }
                        return (
                            <span
                                key={index}
                                className={
                                    styles[
                                        'dynamic-type-input-container__tags-input-container__tag'
                                    ]
                                }
                                onClick={() => handleRemoveTag(index)}
                            >
                                {tag}
                                <img
                                    src={icoClose}
                                    alt="Close"
                                    className={
                                        styles[
                                            'dynamic-type-input-container__tags-input-container__close-icon'
                                        ]
                                    }
                                />
                            </span>
                        );
                    })}
                    <textarea
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type and press enter..."
                        className={styles.textarea}
                        disabled={disabled}
                    />
                </div>
            )}
            {!tagMode &&
                inputValues.map((value, index) => (
                    <div
                        className="position-relative"
                        key={index}
                        style={{
                            marginBottom: '2rem'
                        }}
                    >
                        <input
                            autoComplete="false"
                            name={`${name}-${index}`}
                            value={value}
                            id={`${name}-${index}`}
                            placeholder=""
                            type={hidden ? 'password' : 'text'}
                            disabled={disabled}
                            className={`${styles['form-control']} ${
                                styles['dynamic-type-input-container__input']
                            } ${
                                error
                                    ? styles[
                                          'dynamic-type-input-container__input--error'
                                      ]
                                    : ''
                            }`}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                        {inputValues.length >= 3 &&
                            index !== inputValues.length - 1 && (
                                <img
                                    src={icoClearSearch}
                                    alt="Clear"
                                    className={
                                        styles[
                                            'dynamic-type-input-container__clear-icon'
                                        ]
                                    }
                                    onClick={() => handleRemoveInput(index)}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                    </div>
                ))}
            {edit && (
                <span
                    className={
                        styles['dynamic-type-input-container__edit-icon']
                    }
                />
            )}
            {password && (
                <img
                    src={
                        hidden
                            ? 'path/to/IcoEyeHidden.svg'
                            : 'path/to/IcoEye.svg'
                    }
                    className={
                        styles['dynamic-type-input-container__password-icon']
                    }
                    onClick={() => onClickHiddenIcon(name)}
                />
            )}
            {error && <div className={styles['type-input-error']}>{error}</div>}
            <span
                className={`${styles['dynamic-type-input-container__link']}`}
                onClick={() => {
                    toggleTagMode();
                }}
            >
                {tagMode
                    ? t('inputs:dynamicTypeInput.addOne1') +
                      elementText +
                      t('inputs:dynamicTypeInput.addOne2')
                    : t('inputs:dynamicTypeInput.addMany1') +
                      elementText +
                      t('inputs:dynamicTypeInput.addMany2')}
            </span>
        </div>
    );
};

DynamicTypeInput.propTypes = {
    disabled: PropTypes.bool,
    edit: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    onClickHiddenIcon: PropTypes.func,
    onChangeInput: PropTypes.func.isRequired,
    password: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    inputType: PropTypes.string,
    hovered: PropTypes.bool,
    hideLabel: PropTypes.bool
};

export default DynamicTypeInput;
