import React, { useCallback, useEffect, useRef } from 'react';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useFilter } from '../../../providers/filter-context';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useSelectMultipleInput } from '../../../hooks/useSelectMultipleInput';
import {
    DefaultService,
    HospitalService,
    PointOfSaleService,
    ProductsService,
    ProfileService,
    ProspectsService,
    RegionsService,
    TeamsService
} from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { useLocation } from 'react-router-dom';
import { SelectInput, SelectMultipleInput } from '../../';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const AuxbarSearchInformation = React.memo(
    ({ filterInputs, hoveredFilter }) => {
        const [isLoaded, setIsLoaded] = React.useState(false);
        const { t } = useCustomTranslation();
        const isMounted = useIsMounted();
        const location = useLocation();
        const { setFilter } = useFilter();
        const {
            value: teamId,
            toggleDropdown: teamIdToggleDropdown,
            selectOption: teamIdSelectOption,
            keyPress: teamIdKeyPress,
            keyDown: teamIdKeyDown,
            setValue: teamIdSetValue,
            replaceAll: teamIdReplaceAll
        } = useSelectInput({
            name: 'teamId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: userId,
            toggleDropdown: userIdToggleDropdown,
            selectOption: userIdSelectOption,
            keyPress: userIdKeyPress,
            keyDown: userIdKeyDown,
            setValue: userIdSetValue,
            replaceAll: userIdReplaceAll
        } = useSelectInput({
            name: 'userId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: clientId,
            toggleDropdown: clientIdToggleDropdown,
            selectOption: clientIdSelectOption,
            keyPress: clientIdKeyPress,
            keyDown: clientIdKeyDown,
            setValue: clientIdSetValue,
            replaceAll: clientIdReplaceAll
        } = useSelectInput({
            name: 'clientId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: clientIds,
            toggleDropdown: clientIdsToggleDropdown,
            selectOption: clientIdsSelectOption,
            unselectOption: clientIdsUnselectOption,
            keyPress: clientIdsKeyPress,
            keyDown: clientIdsKeyDown,
            setValue: clientIdsSetValue,
            replaceAll: clientIdsReplaceAll
        } = useSelectMultipleInput({
            name: 'clientIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: vendorId,
            toggleDropdown: vendorIdToggleDropdown,
            selectOption: vendorIdSelectOption,
            keyPress: vendorIdKeyPress,
            keyDown: vendorIdKeyDown,
            setValue: vendorIdSetValue,
            replaceAll: vendorIdReplaceAll
        } = useSelectInput({
            name: 'vendorId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: vendorIds,
            toggleDropdown: vendorIdsToggleDropdown,
            selectOption: vendorIdsSelectOption,
            unselectOption: vendorIdsUnselectOption,
            keyPress: vendorIdsKeyPress,
            keyDown: vendorIdsKeyDown,
            setValue: vendorIdsSetValue,
            replaceAll: vendorIdsReplaceAll
        } = useSelectMultipleInput({
            name: 'vendorIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: hospitalId,
            toggleDropdown: hospitalIdToggleDropdown,
            selectOption: hospitalIdSelectOption,
            keyPress: hospitalIdKeyPress,
            keyDown: hospitalIdKeyDown,
            setValue: hospitalIdSetValue,
            replaceAll: hospitalIdReplaceAll
        } = useSelectInput({
            name: 'hospitalId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: hospitalIds,
            toggleDropdown: hospitalIdsToggleDropdown,
            selectOption: hospitalIdsSelectOption,
            unselectOption: hospitalIdsUnselectOption,
            keyPress: hospitalIdsKeyPress,
            keyDown: hospitalIdsKeyDown,
            setValue: hospitalIdsSetValue,
            replaceAll: hospitalIdsReplaceAll
        } = useSelectMultipleInput({
            name: 'hospitalIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: regionId,
            toggleDropdown: regionIdToggleDropdown,
            selectOption: regionIdSelectOption,
            keyPress: regionIdKeyPress,
            keyDown: regionIdKeyDown,
            setValue: regionIdSetValue,
            replaceAll: regionIdReplaceAll
        } = useSelectInput({
            name: 'regionId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: regionIds,
            toggleDropdown: regionIdsToggleDropdown,
            selectOption: regionIdsSelectOption,
            unselectOption: regionIdsUnselectOption,
            keyPress: regionIdsKeyPress,
            keyDown: regionIdsKeyDown,
            setValue: regionIdsSetValue,
            replaceAll: regionIdsReplaceAll
        } = useSelectMultipleInput({
            name: 'regionIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: productId,
            toggleDropdown: productIdToggleDropdown,
            selectOption: productIdSelectOption,
            keyPress: productIdKeyPress,
            keyDown: productIdKeyDown,
            setValue: productIdSetValue,
            replaceAll: productIdReplaceAll
        } = useSelectInput({
            name: 'productId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: productIds,
            toggleDropdown: productIdsToggleDropdown,
            selectOption: productIdsSelectOption,
            unselectOption: productIdsUnselectOption,
            keyPress: productIdsKeyPress,
            keyDown: productIdsKeyDown,
            setValue: productIdsSetValue,
            replaceAll: productIdsReplaceAll
        } = useSelectMultipleInput({
            name: 'productIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        const {
            value: teamIds,
            toggleDropdown: teamIdsToggleDropdown,
            selectOption: teamIdsSelectOption,
            unselectOption: teamIdsUnselectOption,
            keyPress: teamIdsKeyPress,
            keyDown: teamIdsKeyDown,
            setValue: teamIdsSetValue
        } = useSelectMultipleInput({
            name: 'teamIds',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOptions: [],
            selectedOptionsAux: [],
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });
        const {
            value: competitorId,
            toggleDropdown: competitorIdToggleDropdown,
            selectOption: competitorIdSelectOption,
            keyPress: competitorIdKeyPress,
            keyDown: competitorIdKeyDown,
            setValue: competitorIdSetValue,
            replaceAll: competitorIdReplaceAll
        } = useSelectInput({
            name: 'competitorId',
            showOptions: true,
            options: [],
            optionsAux: [],
            selectedOption: '',
            selectedOptionAux: '',
            state: 'normal',
            value: '',
            focused: -1,
            reference: useRef()
        });

        useEffect(() => {
            setIsLoaded(false);
        }, [location.pathname]);

        const handleFillFilters = useCallback(() => {
            const newFilters = {
                clientId: clientId.selectedOptionAux,
                vendorId: vendorId.selectedOptionAux,
                userId: userId.selectedOptionAux,
                competitorId: competitorId.selectedOptionAux,
                productId: productId.selectedOptionAux,
                clientIds: clientIds.selectedOptionsAux,
                vendorIds: vendorIds.selectedOptionsAux,
                hospitalId: hospitalId.selectedOptionAux,
                hospitalIds: hospitalIds.selectedOptionsAux,
                productIds: productIds.selectedOptionsAux,
                teamId: teamId.selectedOptionAux,
                teamIds: teamIds.selectedOptionsAux,
                regionId: regionId.selectedOptionAux,
                regionIds: regionIds.selectedOptionsAux
            };
            setFilter(newFilters);
        }, [
            teamIds.selectedOptionsAux,
            teamId.selectedOptionAux,
            clientId.selectedOptionAux,
            vendorId.selectedOptionAux,
            userId.selectedOptionAux,
            competitorId.selectedOptionAux,
            productId.selectedOptionAux,
            clientIds.selectedOptionsAux,
            vendorIds.selectedOptionsAux,
            productIds.selectedOptionsAux,
            regionId.selectedOptionAux,
            regionIds.selectedOptionsAux,
            hospitalId.selectedOptionAux,
            hospitalIds.selectedOptionsAux,
            setFilter
        ]);

        const checkFilterInputs = useCallback(async () => {
            const newFilters = {
                clientId: '',
                vendorId: '',
                userId: '',
                competitorId: '',
                productId: '',
                clientIds: [],
                vendorIds: [],
                productIds: [],
                teamId: '',
                teamIds: [],
                regionId: '',
                regionIds: [],
                hospitalId: '',
                hospitalIds: []
            };

            try {
                if (filterInputs && filterInputs.includes('teamId')) {
                    const teamBackData = await TeamsService.getApiTeams({});
                    const newFilter = {
                        name: 'teamId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    teamBackData.map((el) => {
                        newFilter.options.push(el.name);
                        newFilter.optionsAux.push(el.id);
                        return el;
                    });
                    let initialTeamId = '';
                    let selectedTeamId = window.location.state
                        ? window.location.state.teamId
                        : null;
                    const previousTeamId = qs.parse(window.location.search);
                    if (
                        typeof selectedTeamId === 'number' ||
                        (typeof selectedTeamId === 'string' &&
                            selectedTeamId !== '')
                    ) {
                        initialTeamId = selectedTeamId;
                    } else if (
                        typeof previousTeamId['team-id'] === 'string' &&
                        previousTeamId['team-id'] !== 'null'
                    ) {
                        initialTeamId = previousTeamId['team-id'];
                    }
                    if (initialTeamId !== '') {
                        const foundEl = teamBackData.filter((el) => {
                            return (
                                el.id.toString() === initialTeamId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.value = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.selectedOptionAux = foundEl[0].id.toString();
                        if (isMounted.current) {
                            newFilters.teamId = foundEl[0].id.toString();
                        }
                    }
                    teamIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('userId')) {
                    const userBackData =
                        await ProfileService.getApiProfileListAccounts();
                    const newFilter = {
                        name: 'userId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    userBackData.map((el) => {
                        if (
                            _.some(el.roles, (el2) => el2.name === 'user') &&
                            !_.some(el.roles, (el2) => el2.name === 'admin') &&
                            !_.some(el.roles, (el2) => el2.name === 'starkdata')
                        ) {
                            newFilter.options.push(el.username);
                            newFilter.optionsAux.push(el.uuid.toString());
                        }
                        return el;
                    });
                    let initialUserId = '';

                    let selectedUserId = window.location.state
                        ? window.location.state.userId
                        : null;
                    const previousUserId = qs.parse(window.location.search);
                    if (
                        typeof selectedUserId === 'number' ||
                        (typeof selectedUserId === 'string' &&
                            selectedUserId !== '')
                    ) {
                        initialUserId = selectedUserId;
                    } else if (
                        typeof previousUserId['user-id'] === 'string' &&
                        previousUserId['user-id'] !== 'null'
                    ) {
                        initialUserId = previousUserId['user-id'];
                    }
                    if (initialUserId !== '') {
                        const foundEl = userBackData.filter((el) => {
                            return (
                                el.uuid.toString() === initialUserId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].username
                            ? foundEl[0].username
                            : '';
                        newFilter.value = foundEl[0].username
                            ? foundEl[0].username
                            : '';
                        newFilter.selectedOptionAux =
                            foundEl[0].uuid.toString();
                        if (isMounted.current) {
                            newFilters.userId = foundEl[0].uuid.toString();
                        }
                    }
                    userIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('vendorId')) {
                    const vendorIdData = await DefaultService.getApiVendors();
                    const newFilter = {
                        name: 'vendorId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    vendorIdData.map((el) => {
                        newFilter.options.push(el.name ? el.name : '');
                        newFilter.optionsAux.push(el.emp_no.toString());
                        return el;
                    });
                    let initialVendorId = '';

                    let selectedVendorId = window.location.state
                        ? window.location.state.vendorId
                        : null;
                    const previousVendorId = qs.parse(window.location.search);
                    if (
                        typeof selectedVendorId === 'number' ||
                        (typeof selectedVendorId === 'string' &&
                            selectedVendorId !== '')
                    ) {
                        initialVendorId = selectedVendorId;
                    } else if (
                        typeof previousVendorId['vendor-id'] === 'string' &&
                        previousVendorId['vendor-id'] !== 'null'
                    ) {
                        initialVendorId = previousVendorId['vendor-id'];
                    }
                    if (initialVendorId !== '') {
                        const foundEl = vendorIdData.filter((el) => {
                            return (
                                el.emp_no.toString() ===
                                initialVendorId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.value = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.selectedOptionAux =
                            foundEl[0].emp_no.toString();
                        if (isMounted.current) {
                            newFilters.vendorId = foundEl[0].emp_no.toString();
                        }
                    }
                    vendorIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('vendorIds')) {
                    const vendorIdData = await DefaultService.getApiVendors();
                    const newFilter = {
                        name: 'vendorIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    vendorIdData.map((el) => {
                        newFilter.options.push(el.name);
                        newFilter.optionsAux.push(el.emp_no.toString());
                        return el;
                    });
                    let initialVendorIds = [];
                    const previousVendorIdList = qs.parse(
                        window.location.search
                    );
                    if (
                        previousVendorIdList['vendor-ids'] !== '' &&
                        previousVendorIdList['vendor-ids']
                    ) {
                        if (
                            typeof previousVendorIdList['vendor-ids'] !==
                            'object'
                        ) {
                            initialVendorIds.push(
                                parseInt(
                                    _.cloneDeep(
                                        previousVendorIdList['vendor-ids']
                                    )
                                )
                            );
                        } else {
                            _.forEach(
                                previousVendorIdList['vendor-ids'],
                                (v) => {
                                    initialVendorIds.push(parseInt(v));
                                }
                            );
                        }
                    }
                    if (initialVendorIds.length > 0) {
                        initialVendorIds.map((el) => {
                            const vendorObject = vendorIdData.filter((el2) => {
                                return el.toString() === el2.emp_no.toString();
                            });
                            newFilter.selectedOptions.push(
                                vendorObject[0].name ? vendorObject[0].name : ''
                            );
                            newFilter.selectedOptionsAux.push(
                                vendorObject[0].emp_no.toString()
                            );
                            const optionIndex = _.findIndex(
                                newFilter.optionsAux,
                                (el3) => {
                                    return (
                                        el3 ===
                                        vendorObject[0].emp_no.toString()
                                    );
                                }
                            );
                            if (optionIndex !== -1) {
                                newFilter.options.splice(optionIndex, 1);
                                newFilter.optionsAux.splice(optionIndex, 1);
                            }
                            return el;
                        });
                        const vendorIdsList = initialVendorIds.map((el) => {
                            return el.toString();
                        });
                        newFilters.vendorIds = vendorIdsList;
                    }
                    vendorIdsReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('hospitalId')) {
                    const hospitalIdData =
                        await HospitalService.getApiHospitals();
                    const newFilter = {
                        name: 'hospitalId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    hospitalIdData.map((el) => {
                        newFilter.options.push(el.name ? el.name : '');
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialHospitalId = '';

                    let selectedHospitalId = window.location.state
                        ? window.location.state.hospitalId
                        : null;
                    const previousHospitalId = qs.parse(window.location.search);
                    if (
                        typeof selectedHospitalId === 'number' ||
                        (typeof selectedHospitalId === 'string' &&
                            selectedHospitalId !== '')
                    ) {
                        initialHospitalId = selectedHospitalId;
                    } else if (
                        typeof previousHospitalId['hospital-id'] === 'string' &&
                        previousHospitalId['hospital-id'] !== 'null'
                    ) {
                        initialHospitalId = previousHospitalId['hospital-id'];
                    }
                    if (initialHospitalId !== '') {
                        const foundEl = hospitalIdData.filter((el) => {
                            return (
                                el.id.toString() ===
                                initialHospitalId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.value = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.selectedOptionAux = foundEl[0].id.toString();
                        if (isMounted.current) {
                            newFilters.hospitalId = foundEl[0].id.toString();
                        }
                    }
                    hospitalIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('hospitalIds')) {
                    const hospitalIdData =
                        await HospitalService.getApiHospitals();
                    const newFilter = {
                        name: 'hospitalIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    hospitalIdData.map((el) => {
                        newFilter.options.push(el.name);
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialHospitalIds = [];
                    const previousHospitalIdList = qs.parse(
                        window.location.search
                    );
                    if (
                        previousHospitalIdList['hospital-ids'] !== '' &&
                        previousHospitalIdList['hospital-ids']
                    ) {
                        if (
                            typeof previousHospitalIdList['hospital-ids'] !==
                            'object'
                        ) {
                            initialHospitalIds.push(
                                parseInt(
                                    _.cloneDeep(
                                        previousHospitalIdList['hospital-ids']
                                    )
                                )
                            );
                        } else {
                            _.forEach(
                                previousHospitalIdList['hospital-ids'],
                                (v) => {
                                    initialHospitalIds.push(parseInt(v));
                                }
                            );
                        }
                    }
                    if (initialHospitalIds.length > 0) {
                        initialHospitalIds.map((el) => {
                            const hospitalObject = hospitalIdData.filter(
                                (el2) => {
                                    return el.toString() === el2.id.toString();
                                }
                            );
                            newFilter.selectedOptions.push(
                                hospitalObject[0].name
                                    ? hospitalObject[0].name
                                    : ''
                            );
                            newFilter.selectedOptionsAux.push(
                                hospitalObject[0].id.toString()
                            );
                            const optionIndex = _.findIndex(
                                newFilter.optionsAux,
                                (el3) => {
                                    return (
                                        el3 === hospitalObject[0].id.toString()
                                    );
                                }
                            );
                            if (optionIndex !== -1) {
                                newFilter.options.splice(optionIndex, 1);
                                newFilter.optionsAux.splice(optionIndex, 1);
                            }
                            return el;
                        });
                        const hospitalIdsList = initialHospitalIds.map((el) => {
                            return el.toString();
                        });
                        newFilters.hospitalIds = hospitalIdsList;
                    }
                    hospitalIdsReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('regionId')) {
                    const regionIdBack = await RegionsService.getApiRegions({
                        params: [
                            {
                                level: 1
                            }
                        ]
                    });
                    const newFilter = {
                        name: 'regionId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    regionIdBack.map((el) => {
                        newFilter.options.push(el.name ? el.name : '');
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialRegionId = '';

                    let selectedRegionId = window.location.state
                        ? window.location.state.regionId
                        : null;
                    const previousRegionId = qs.parse(window.location.search);
                    if (
                        typeof selectedRegionId === 'number' ||
                        (typeof selectedRegionId === 'string' &&
                            selectedRegionId !== '')
                    ) {
                        initialRegionId = selectedRegionId;
                    } else if (
                        typeof previousRegionId['region-id'] === 'string' &&
                        previousRegionId['region-id'] !== 'null'
                    ) {
                        initialRegionId = previousRegionId['region-id'];
                    }
                    if (initialRegionId !== '') {
                        const foundEl = regionIdBack.filter((el) => {
                            return (
                                el.id.toString() === initialRegionId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.value = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.selectedOptionAux = foundEl[0].id.toString();
                        if (isMounted.current) {
                            newFilters.regionId = foundEl[0].id.toString();
                        }
                    }
                    regionIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('regionIds')) {
                    const regionIdBack = await RegionsService.getApiRegions({
                        params: [
                            {
                                level: 1
                            }
                        ]
                    });
                    const newFilter = {
                        name: 'regionIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    regionIdBack.map((el) => {
                        newFilter.options.push(el.name);
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialRegionIds = [];
                    const previousRegionIdList = qs.parse(
                        window.location.search
                    );
                    if (
                        previousRegionIdList['region-ids'] !== '' &&
                        previousRegionIdList['region-ids']
                    ) {
                        if (
                            typeof previousRegionIdList['region-ids'] !==
                            'object'
                        ) {
                            initialRegionIds.push(
                                parseInt(
                                    _.cloneDeep(
                                        previousRegionIdList['region-ids']
                                    )
                                )
                            );
                        } else {
                            _.forEach(
                                previousRegionIdList['region-ids'],
                                (v) => {
                                    initialRegionIds.push(parseInt(v));
                                }
                            );
                        }
                    }
                    if (initialRegionIds.length > 0) {
                        initialRegionIds.map((el) => {
                            const regionObject = regionIdBack.filter((el2) => {
                                return el.toString() === el2.id.toString();
                            });
                            newFilter.selectedOptions.push(
                                regionObject[0].name ? regionObject[0].name : ''
                            );
                            newFilter.selectedOptionsAux.push(
                                regionObject[0].id.toString()
                            );
                            const optionIndex = _.findIndex(
                                newFilter.optionsAux,
                                (el3) => {
                                    return (
                                        el3 === regionObject[0].id.toString()
                                    );
                                }
                            );
                            if (optionIndex !== -1) {
                                newFilter.options.splice(optionIndex, 1);
                                newFilter.optionsAux.splice(optionIndex, 1);
                            }
                            return el;
                        });
                        const regionIdsList = initialRegionIds.map((el) => {
                            return el.toString();
                        });
                        newFilters.regionIds = regionIdsList;
                    }
                    regionIdsReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('clientId')) {
                    const clientIdData = await PointOfSaleService.getApiPos({
                        clientId: [],
                        mapInfo: 'true',
                        page: undefined,
                        vendorId: []
                    });
                    const newFilter = {
                        name: 'clientId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    clientIdData.map((el) => {
                        newFilter.options.push(el.name ? el.name : '');
                        newFilter.optionsAux.push(el.client_id.toString());
                        return el;
                    });
                    let initialClientId = '';
                    let selectedClientId = window.location.state
                        ? window.location.state.clientId
                        : null;
                    const previousClientId = qs.parse(window.location.search);
                    if (
                        typeof selectedClientId === 'number' ||
                        (typeof selectedClientId === 'string' &&
                            selectedClientId !== '')
                    ) {
                        initialClientId = selectedClientId;
                    } else if (
                        typeof previousClientId['client-id'] === 'string' &&
                        previousClientId['client-id'] !== 'null'
                    ) {
                        initialClientId = previousClientId['client-id'];
                    }
                    if (initialClientId !== '') {
                        const foundEl = clientIdData.filter((el) => {
                            return (
                                el.client_id.toString() ===
                                initialClientId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.value = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.selectedOptionAux =
                            foundEl[0].client_id.toString();
                        if (isMounted.current) {
                            newFilters.clientId =
                                foundEl[0].client_id.toString();
                        }
                    }
                    clientIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('clientIds')) {
                    const clientIdData = await PointOfSaleService.getApiPos({
                        clientId: [],
                        mapInfo: 'true',
                        page: undefined,
                        vendorId: []
                    });
                    const newFilter = {
                        name: 'clientIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    clientIdData.map((el) => {
                        newFilter.options.push(el.name);
                        newFilter.optionsAux.push(el.client_id.toString());
                        return el;
                    });
                    let initialClientIds = [];
                    const previousClientIdIdList = qs.parse(
                        window.location.search
                    );
                    if (
                        previousClientIdIdList['client-ids'] !== '' &&
                        previousClientIdIdList['client-ids']
                    ) {
                        if (
                            typeof previousClientIdIdList['client-ids'] !==
                            'object'
                        ) {
                            initialClientIds.push(
                                parseInt(
                                    _.cloneDeep(
                                        previousClientIdIdList['client-ids']
                                    )
                                )
                            );
                        } else {
                            _.forEach(
                                previousClientIdIdList['client-ids'],
                                (v) => {
                                    initialClientIds.push(parseInt(v));
                                }
                            );
                        }
                    }
                    if (initialClientIds.length > 0) {
                        initialClientIds.map((el) => {
                            const clientObject = clientIdData.filter((el2) => {
                                return (
                                    el.toString() === el2.client_id.toString()
                                );
                            });

                            newFilter.selectedOptions.push(
                                clientObject[0].name ? clientObject[0].name : ''
                            );
                            newFilter.selectedOptionsAux.push(
                                clientObject[0].client_id.toString()
                            );

                            const optionIndex = _.findIndex(
                                newFilter.optionsAux,
                                (el3) => {
                                    return (
                                        el3 ===
                                        clientObject[0].client_id.toString()
                                    );
                                }
                            );
                            if (optionIndex !== -1) {
                                newFilter.options.splice(optionIndex, 1);
                                newFilter.optionsAux.splice(optionIndex, 1);
                            }
                            return el;
                        });
                        const clientIdsList = initialClientIds.map((el) => {
                            return el.toString();
                        });
                        newFilters.clientIds = clientIdsList;
                    }
                    clientIdsReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('competitorId')) {
                    const prospectGeoDataBack =
                        await ProspectsService.getApiCompetitors();
                    const newFilter = {
                        name: 'competitorId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    prospectGeoDataBack.map((el) => {
                        newFilter.options.push(el.name ? el.name : '');
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialCompetitorId = '';

                    let selectedProspectId = window.location.state
                        ? window.location.state.prospectId
                        : null;
                    const previousProspectId = qs.parse(window.location.search);
                    if (
                        typeof selectedProspectId === 'number' ||
                        (typeof selectedProspectId === 'string' &&
                            selectedProspectId !== '')
                    ) {
                        initialCompetitorId = selectedProspectId;
                    } else if (
                        typeof previousProspectId['competitor-id'] ===
                            'string' &&
                        previousProspectId['competitor-id'] !== 'null'
                    ) {
                        initialCompetitorId =
                            previousProspectId['competitor-id'];
                    }
                    if (initialCompetitorId !== '') {
                        const foundEl = prospectGeoDataBack.filter((el) => {
                            return (
                                el.id.toString() ===
                                initialCompetitorId.toString()
                            );
                        });
                        newFilter.selectedOption = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.value = foundEl[0].name
                            ? foundEl[0].name
                            : '';
                        newFilter.selectedOptionAux = foundEl[0].id.toString();
                        if (isMounted.current) {
                            newFilters.competitorId = foundEl[0].id.toString();
                        }
                    }
                    competitorIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('productId')) {
                    const productBackData =
                        await ProductsService.getApiProducts({});
                    const newFilter = {
                        name: 'productId',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOption: '',
                        selectedOptionAux: '',
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    productBackData.map((el) => {
                        newFilter.options.push(el.title);
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialProductId = '';

                    let selectedProductId = window.location.state
                        ? window.location.state.productId
                        : null;
                    const previousProductId = qs.parse(window.location.search);
                    if (
                        typeof selectedProductId === 'number' ||
                        (typeof selectedProductId === 'string' &&
                            selectedProductId !== '')
                    ) {
                        initialProductId = selectedProductId;
                    } else if (
                        typeof previousProductId['product-id'] === 'string' &&
                        previousProductId['product-id'] !== 'null'
                    ) {
                        initialProductId = previousProductId['product-id'];
                    }
                    if (initialProductId !== '') {
                        const foundEl = productBackData.filter((el) => {
                            return (
                                el.id.toString() === initialProductId.toString()
                            );
                        });
                        if (isMounted.current) {
                            newFilter.selectedOption = foundEl[0].title
                                ? foundEl[0].title
                                : '';
                            newFilter.value = foundEl[0].title
                                ? foundEl[0].title
                                : '';
                            newFilter.selectedOptionAux =
                                foundEl[0].id.toString();

                            newFilters.productId = foundEl[0].id.toString();
                        }
                    }

                    productIdReplaceAll(newFilter);
                }
                if (filterInputs && filterInputs.includes('productIds')) {
                    const productBackData =
                        await ProductsService.getApiProducts({
                            productId: undefined,
                            page: undefined,
                            itemsPerPage: undefined
                        });
                    const newFilter = {
                        name: 'productIds',
                        showOptions: true,
                        options: [],
                        optionsAux: [],
                        selectedOptions: [],
                        selectedOptionsAux: [],
                        state: 'normal',
                        value: '',
                        focused: -1,
                        reference: React.createRef()
                    };
                    productBackData.map((el) => {
                        newFilter.options.push(el.title);
                        newFilter.optionsAux.push(el.id.toString());
                        return el;
                    });
                    let initialProductIds = [];
                    const previousProductIdList = qs.parse(
                        window.location.search
                    );
                    if (
                        previousProductIdList['product-ids'] !== '' &&
                        previousProductIdList['product-ids']
                    ) {
                        if (
                            typeof previousProductIdList['product-ids'] !==
                            'object'
                        ) {
                            initialProductIds.push(
                                parseInt(
                                    _.cloneDeep(
                                        previousProductIdList['product-ids']
                                    )
                                )
                            );
                        } else {
                            _.forEach(
                                previousProductIdList['product-ids'],
                                (v) => {
                                    initialProductIds.push(parseInt(v));
                                }
                            );
                        }
                    }
                    if (initialProductIds.length > 0) {
                        initialProductIds.map((el) => {
                            const productObject = productBackData.filter(
                                (el2) => {
                                    return el.toString() === el2.id.toString();
                                }
                            );
                            newFilter.selectedOptions.push(
                                productObject[0].name
                                    ? productObject[0].name
                                    : ''
                            );
                            newFilter.selectedOptionsAux.push(
                                productObject[0].id.toString()
                            );
                            const optionIndex = _.findIndex(
                                newFilter.optionsAux,
                                (el3) => {
                                    return (
                                        el3 === productObject[0].id.toString()
                                    );
                                }
                            );
                            if (optionIndex !== -1) {
                                newFilter.options.splice(optionIndex, 1);
                                newFilter.optionsAux.splice(optionIndex, 1);
                            }
                            return el;
                        });
                        const productIdsList = initialProductIds.map((el) => {
                            return el.toString();
                        });
                        newFilters.productIds = productIdsList;
                    }
                    productIdsReplaceAll(newFilter);
                }
                // setFilter(newFilters)
            } catch (err) {
                errorHandler(err);
            }
        }, [
            // setFilter,
            filterInputs,
            hospitalIdReplaceAll,
            hospitalIdsReplaceAll,
            clientIdReplaceAll,
            clientIdsReplaceAll,
            teamIdReplaceAll,
            isMounted,
            competitorIdReplaceAll,
            productIdReplaceAll,
            productIdsReplaceAll,
            userIdReplaceAll,
            vendorIdsReplaceAll,
            vendorIdReplaceAll,
            regionIdReplaceAll,
            regionIdsReplaceAll
        ]);

        useEffect(() => {
            checkFilterInputs();
        }, [checkFilterInputs]);

        return (
            <React.Fragment>
                <div className="row w-100 m-0 mb-4">
                    <div className="col category__font p-0">
                        {t('layout:auxbar.search.title')}
                    </div>
                </div>
                {filterInputs && filterInputs.includes('clientId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === clientId.name}
                                onChangeInput={clientIdSetValue}
                                onSelectInput={clientIdSelectOption}
                                onKeyPress={clientIdKeyPress}
                                onKeyDown={clientIdKeyDown}
                                onToggleDropdown={clientIdToggleDropdown}
                                {...clientId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('clientIds') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectMultipleInput
                                hovered={hoveredFilter === clientIds.name}
                                hideOptionsAux
                                disabled={clientIds.disabled}
                                onChangeInput={clientIdsSetValue}
                                onSelectMultiple={clientIdsSelectOption}
                                onSelectedOptionClick={clientIdsUnselectOption}
                                onKeyPress={clientIdsKeyPress}
                                onKeyDown={clientIdsKeyDown}
                                onToggleDropdown={clientIdsToggleDropdown}
                                {...clientIds}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('teamId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === teamId.name}
                                onChangeInput={teamIdSetValue}
                                onSelectInput={teamIdSelectOption}
                                onKeyPress={teamIdKeyPress}
                                onKeyDown={teamIdKeyDown}
                                onToggleDropdown={teamIdToggleDropdown}
                                {...teamId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('userId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === userId.name}
                                onChangeInput={userIdSetValue}
                                onSelectInput={userIdSelectOption}
                                onKeyPress={userIdKeyPress}
                                onKeyDown={userIdKeyDown}
                                onToggleDropdown={userIdToggleDropdown}
                                {...userId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('regionId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === regionId.name}
                                onChangeInput={regionIdSetValue}
                                onSelectInput={regionIdSelectOption}
                                onKeyPress={regionIdKeyPress}
                                onKeyDown={regionIdKeyDown}
                                onToggleDropdown={regionIdToggleDropdown}
                                {...regionId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('regionIds') && (
                    <div
                        className={'row mx-0 h-auto'}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectMultipleInput
                                hovered={hoveredFilter === regionIds.name}
                                disabled={regionIds.disabled}
                                onChangeInput={regionIdsSetValue}
                                onSelectMultiple={regionIdsSelectOption}
                                onSelectedOptionClick={regionIdsUnselectOption}
                                onKeyPress={regionIdsKeyPress}
                                onKeyDown={regionIdsKeyDown}
                                onToggleDropdown={regionIdsToggleDropdown}
                                {...regionIds}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('vendorId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === vendorId.name}
                                onChangeInput={vendorIdSetValue}
                                onSelectInput={vendorIdSelectOption}
                                onKeyPress={vendorIdKeyPress}
                                onKeyDown={vendorIdKeyDown}
                                onToggleDropdown={vendorIdToggleDropdown}
                                {...vendorId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('vendorIds') && (
                    <div
                        className={'row mx-0 h-auto'}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectMultipleInput
                                hovered={hoveredFilter === vendorIds.name}
                                disabled={vendorIds.disabled}
                                onChangeInput={vendorIdsSetValue}
                                onSelectMultiple={vendorIdsSelectOption}
                                onSelectedOptionClick={vendorIdsUnselectOption}
                                onKeyPress={vendorIdsKeyPress}
                                onKeyDown={vendorIdsKeyDown}
                                onToggleDropdown={vendorIdsToggleDropdown}
                                {...vendorIds}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('hospitalId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === hospitalId.name}
                                onChangeInput={hospitalIdSetValue}
                                onSelectInput={hospitalIdSelectOption}
                                onKeyPress={hospitalIdKeyPress}
                                onKeyDown={hospitalIdKeyDown}
                                onToggleDropdown={hospitalIdToggleDropdown}
                                {...hospitalId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('hospitalIds') && (
                    <div
                        className={'row mx-0 h-auto'}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectMultipleInput
                                hovered={hoveredFilter === hospitalIds.name}
                                disabled={hospitalIds.disabled}
                                onChangeInput={hospitalIdsSetValue}
                                onSelectMultiple={hospitalIdsSelectOption}
                                onSelectedOptionClick={
                                    hospitalIdsUnselectOption
                                }
                                onKeyPress={hospitalIdsKeyPress}
                                onKeyDown={hospitalIdsKeyDown}
                                onToggleDropdown={hospitalIdsToggleDropdown}
                                {...hospitalIds}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('competitorId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === competitorId.name}
                                onChangeInput={competitorIdSetValue}
                                onSelectInput={competitorIdSelectOption}
                                onKeyPress={competitorIdKeyPress}
                                onKeyDown={competitorIdKeyDown}
                                onToggleDropdown={competitorIdToggleDropdown}
                                {...competitorId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('productId') && (
                    <div
                        className="row mx-0 h-auto"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectInput
                                hovered={hoveredFilter === productId.name}
                                onChangeInput={productIdSetValue}
                                onSelectInput={productIdSelectOption}
                                onKeyPress={productIdKeyPress}
                                onKeyDown={productIdKeyDown}
                                onToggleDropdown={productIdToggleDropdown}
                                {...productId}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('productIds') && (
                    <div
                        className={'row mx-0 h-auto'}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectMultipleInput
                                hovered={hoveredFilter === productIds.name}
                                disabled={productIds.disabled}
                                onChangeInput={productIdsSetValue}
                                onSelectMultiple={productIdsSelectOption}
                                onSelectedOptionClick={productIdsUnselectOption}
                                onKeyPress={productIdsKeyPress}
                                onKeyDown={productIdsKeyDown}
                                onToggleDropdown={productIdsToggleDropdown}
                                {...productIds}
                            />
                        </div>
                    </div>
                )}
                {filterInputs && filterInputs.includes('teamIds') && (
                    <div
                        className={'row mx-0 h-auto'}
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <SelectMultipleInput
                                hovered={hoveredFilter === teamIds.name}
                                disabled={teamIds.disabled}
                                onChangeInput={teamIdsSetValue}
                                onSelectMultiple={teamIdsSelectOption}
                                onSelectedOptionClick={teamIdsUnselectOption}
                                onKeyPress={teamIdsKeyPress}
                                onKeyDown={teamIdsKeyDown}
                                onToggleDropdown={teamIdsToggleDropdown}
                                {...teamIds}
                            />
                        </div>
                    </div>
                )}
                <div className="row mx-0 h-auto">
                    <PrimaryButton
                        disabled={_.every(filterInputs, (el) => {
                            return (
                                (el === 'clientId' &&
                                    clientId.selectedOption === '') ||
                                (el === 'vendorId' &&
                                    vendorId.selectedOption === '') ||
                                (el === 'productId' &&
                                    productId.selectedOption === '') ||
                                (el === 'competitorId' &&
                                    competitorId.selectedOption === '') ||
                                (el === 'userId' &&
                                    userId.selectedOption === '') ||
                                (el === 'clientIds' &&
                                    clientIds.selectedOptions.length === 0) ||
                                (el === 'vendorIds' &&
                                    vendorIds.selectedOptions.length === 0) ||
                                (el === 'hospitalId' &&
                                    hospitalId.selectedOption === '') ||
                                (el === 'hospitalIds' &&
                                    hospitalIds.selectedOptions.length === 0) ||
                                (el === 'productIds' &&
                                    productIds.selectedOptions.length === 0) ||
                                (el === 'teamId' &&
                                    teamId.selectedOption === '') ||
                                (el === 'teamIds' &&
                                    teamIds.selectedOptions.length === 0) ||
                                el === 'none' ||
                                (el === 'regionId' &&
                                    regionId.selectedOption === '') ||
                                (el === 'regionIds' &&
                                    regionIds.selectedOptions.length === 0)
                            );
                        })}
                        type={'info'}
                        text={t(`buttons:search`)}
                        onClick={() => handleFillFilters()}
                    />
                </div>
            </React.Fragment>
        );
    }
);

AuxbarSearchInformation.propTypes = {};
