import { useCallback, useState } from 'react';
import _ from 'lodash';
import { ParentSize } from '@visx/responsive';
import styles from './word-cloud.module.scss';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import { TooltipFactory } from '../../chart-configuration/tooltip-factory/tooltip-factory';

const colors = ['#143059', '#2F6B9A', '#82a6c2'];

const lyrics = `I hear the drums echoing tonight
But she hears only whispers of some quiet conversation
She's coming in, 12:30 flight
The moonlit wings reflect the stars that guide me towards salvation
I stopped an old man along the way
Hoping to find some old forgotten words or ancient melodies
He turned to me as if to say, Hurry boy, it's waiting there for you
It's gonna take a lot to drag me away from you
There's nothing that a hundred men or more could ever do`;

const words = wordFreq(lyrics);
function wordFreq(text) {
    const words = text.replace(/\./g, '').split(/\s/);
    const freqMap = {};

    for (const w of words) {
        if (!freqMap[w]) freqMap[w] = 0;
        freqMap[w] += 1;
    }
    return Object.keys(freqMap).map((word) => ({
        text: word,
        value: freqMap[word]
    }));
}

function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
}

const fixedValueGenerator = () => 0.5;

export const WordCLoud = ({ data }) => {
    const [spiralType, setSpiralType] = useState('archimedean');
    const [withRotation, setWithRotation] = useState(false);
    const [hoveredWord, setHoveredWord] = useState({});
    const fontScale = scaleLog({
        domain: [
            Math.min(...data.map((w) => w.value)),
            Math.max(...data.map((w) => w.value))
        ],
        range: [10, 50]
    });
    const fontSizeSetter = (datum) => fontScale(datum.value);
    const onWordMouseOver = useCallback((word, e) => {
        setHoveredWord({
            top: e.clientY + 10,
            left: e.clientX + 10,
            word: {
                value: word.text,
                rankingPosition: word.ranking,
                rankingCount: word.totalCount,
                inputCount: word.value,
                sentiment: word.sentiment
            }
        });
    }, []);

    const onWordMouseOut = useCallback(() => {
        setHoveredWord({});
    }, []);
    return (
        <div className={styles['word-cloud-container']}>
            <ParentSize>
                {(parent) => (
                    <Wordcloud
                        words={data.slice(0, 100)}
                        width={parent.width}
                        height={parent.height}
                        fontSize={fontSizeSetter}
                        padding={2}
                        font={'OpenSansSemiBold'}
                        spiral={spiralType}
                        rotate={withRotation ? getRotationDegree : 0}
                        random={fixedValueGenerator}
                    >
                        {(cloudWords) =>
                            cloudWords.map((w, i) => {
                                return (
                                    <Text
                                        key={i}
                                        fill={
                                            w.sentiment === 'positive'
                                                ? '#7dd081'
                                                : w.sentiment === 'negative'
                                                ? '#db6162'
                                                : '#f0bb5f'
                                        }
                                        textAnchor={'middle'}
                                        transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                        fontSize={w.size}
                                        fontFamily={w.font}
                                        onMouseOver={(e) =>
                                            onWordMouseOver(w, e)
                                        }
                                        onMouseOut={onWordMouseOut}
                                    >
                                        {w.text}
                                    </Text>
                                );
                            })
                        }
                    </Wordcloud>
                )}
            </ParentSize>
            {!_.isEmpty(hoveredWord) && (
                <TooltipFactory
                    item={{
                        type: 'word',
                        top: hoveredWord.top,
                        left: hoveredWord.left,
                        word: hoveredWord.word,
                        value: hoveredWord.value,
                        sentiment: hoveredWord.sentiment
                    }}
                />
            )}
        </div>
    );
};
