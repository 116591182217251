export const tacticalPlanListMock = {
    titleCounter: '0',
    data: [],
    columns: [
        { path: 'name', label: '', sortable: true },
        { path: 'action', label: '', sortable: false }
    ],
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'tacticalPlanList',
    disabled: true,
    loading: true
};
