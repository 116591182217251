import React, { useCallback, useEffect, useRef, useState } from 'react';
//import { ReactComponent as IcoError } from './assets/images/ico-error.svg'
import { ReactComponent as IcoValid } from './assets/images/ico-valid.svg';
import PropTypes from 'prop-types';

import styles from './select-label-input.module.scss';
import { ReactComponent as ArrowDown } from './assets/images/dropdown-arrow.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useDropdownKeyboardNavigation } from '../../../hooks/useDropdownKeyboardNavigation';
import FullTextPopup from '../../popups/FullTextPopup';
import { InputLabel } from '../input-label/input-label';
import { UserIcon } from '../../formatting/user-icon/user-icon';

export const SelectLabelInput = React.memo(
    ({
        state,
        name,
        selectedOption,
        selectedOptionAux,
        label,
        disabled,
        value,
        error,
        onChangeInput,
        reference,
        openDropdown,
        inputType,
        hovered,
        onSelectInput,
        newLabelType,
        isRequired,
        options,
        optionsAux
    }) => {
        const handleOptionClick = useCallback(
            (e, name, option, optionAux) => {
                onSelectInput(option, optionAux, name);
                e.preventDefault();
                e.stopPropagation();
            },
            [onSelectInput]
        );

        const hoveredStyle = hovered ? 'hovered' : '';
        let containerStyle;
        if (disabled) {
            containerStyle = `${styles['select-label-input-container']} ${
                styles[`${inputType}`]
            } ${styles['disabled']}`;
        } else if (openDropdown) {
            containerStyle = `${styles['select-label-input-container']} ${
                styles[`${inputType}`]
            } ${styles['selected']} ${styles[hoveredStyle]}`;
        } else if (
            selectedOption !== undefined ||
            selectedOptionAux !== undefined ||
            value !== undefined
        ) {
            containerStyle = `${styles['select-label-input-container']} ${
                styles[`${inputType}`]
            } ${styles['valued']} ${styles[hoveredStyle]}`;
        } else {
            containerStyle = `${styles['select-label-input-container']} ${
                styles[`${inputType}`]
            } ${styles[hoveredStyle]}`;
        }

        return (
            <React.Fragment>
                <div className={containerStyle} ref={reference}>
                    <InputLabel
                        text={label}
                        name={name}
                        isError={state === 'error' || error}
                        isValued={value || inputType === 'icon'}
                        isSelected={openDropdown}
                        isRequired={isRequired}
                        type={newLabelType ? 'top' : undefined}
                    />
                    <div
                        className={`${styles['select-label-input-container__input-container']}`}
                    ></div>
                </div>
                {options &&
                    options.length > 0 &&
                    optionsAux &&
                    optionsAux.length > 0 &&
                    options.map((option, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    selectedOptionAux === optionsAux[index]
                                        ? `${styles['select-label-input-container__option']} ${styles['selected']}`
                                        : `${styles['select-label-input-container__option']}`
                                }
                                onClick={(e) =>
                                    handleOptionClick(
                                        e,
                                        name,
                                        option,
                                        optionsAux[index]
                                    )
                                }
                            >
                                <div
                                    className={
                                        styles[
                                            'select-label-input-container__option__text'
                                        ]
                                    }
                                >
                                    {option}
                                </div>
                            </div>
                        );
                    })}
                {error && (
                    <div className={styles['select-input-error']}>{error}</div>
                )}
            </React.Fragment>
        );
    }
);

SelectLabelInput.propTypes = {};
