import React, { useCallback, useMemo, useState } from 'react'
import styles from './YourTeams.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { PrimaryButton } from '../../../components'
import { SearchInput } from '../../../components/inputs/search-input/SearchInput'
import icoCreateTeam from '../../../assets/images/icoCreateTeam.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { TeamSmallCard } from '../../../components/teams/team-small-card/TeamSmallCard'
import { CreateTeamModal } from '../../../components/modals/create-team-modal/CreateTeamModal'
import { ManageTeamMembersModal } from '../../../components/modals/manage-team-members-modal/ManageTeamMembersModal'
import { RemoveOrgTeamModal } from '../../../components/modals/remove-org-team-modal/remove-org-team-modal'
import icoUser1 from '../../../assets/images/user1.svg'
import icoUser2 from '../../../assets/images/user2.svg'
import icoUser3 from '../../../assets/images/user3.svg'
import icoUser4 from '../../../assets/images/user4.svg'
import icoUser5 from '../../../assets/images/user5.svg'
import icoUser6 from '../../../assets/images/user6.svg'
import icoUser7 from '../../../assets/images/user7.svg'
import icoUser8 from '../../../assets/images/user8.svg'
import icoUser9 from '../../../assets/images/user9.svg'
import icoUser10 from '../../../assets/images/user10.svg'

function generateMockData() {
    function generateMembers() {
        const imagesUsed = []
        const members = []
        Array.from({ length: 5 }, (_, memberIndex) => {
            let newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
                Math.floor(Math.random() * 10)
            ]
            if (imagesUsed.length !== 0) {
                do {
                    newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
                        Math.floor(Math.random() * 10)
                    ]
                } while (imagesUsed.includes(newImage))
            }
            imagesUsed.push(newImage)
            const firstName = 'John'
            const lastName = 'Doe'
            members.push({
                id: memberIndex + 1,
                teamMemberName: `${firstName} ${lastName}`,
                teamMemberCustomers: Math.floor(Math.random() * 100 + 10),
                teamMemberCustomersLast: Math.floor(Math.random() * 100 + 10),
                teamMemberRevenue: `${(Math.random() * 10000 + 100).toFixed(0)}`,
                teamMemberRevenueLast: `${(Math.random() * 11000 + 100).toFixed(0)}`,
                image: newImage,
            })
        })
        return members
    }

    return [
        {
            id: 1,
            teamName: 'Sales Chicago',
            teamManager: 'alice.brown@outlook.com',
            teamMembers: 10,
            revenue: '€ 100,000',
            customers: 100,
            members: generateMembers(),
            corporation: 'Blue Corporation North America',
        },
        {
            id: 2,
            teamName: 'Sales New York',
            teamManager: 'john.smith@outlook.com',
            teamMembers: 8,
            revenue: '€ 120,000',
            customers: 80,
            members: generateMembers(),
            corporation: 'Blue Corporation North America',
        },
        {
            id: 3,
            teamName: 'Ventas Madrid',
            teamManager: 'carlos.garcia@outlook.com',
            teamMembers: 12,
            revenue: '€ 110,000',
            customers: 95,
            members: generateMembers(),
            corporation: 'Blue Corporation Spain',
        },
        {
            id: 4,
            teamName: 'Ventas Barcelona',
            teamManager: 'laura.martinez@outlook.com',
            teamMembers: 15,
            revenue: '€ 130,000',
            customers: 120,
            members: generateMembers(),
            corporation: 'Blue Corporation Spain',
        },
        {
            id: 5,
            teamName: 'Vertrieb Berlin',
            teamManager: 'hans.muller@outlook.com',
            teamMembers: 14,
            revenue: '€ 140,000',
            customers: 110,
            members: generateMembers(),
            corporation: 'Blue Corporation Germany',
        },
        {
            id: 6,
            teamName: 'Vertrieb Hamburg',
            teamManager: 'julia.schmidt@outlook.com',
            teamMembers: 10,
            revenue: '€ 125,000',
            customers: 85,
            members: generateMembers(),
            corporation: 'Blue Corporation Germany',
        },
        {
            id: 7,
            teamName: 'Vendas Lisboa',
            teamManager: 'ana.silva@outlook.com',
            teamMembers: 11,
            revenue: '€ 115,000',
            customers: 90,
            members: generateMembers(),
            corporation: 'Blue Corporation Portugal',
        },
        {
            id: 8,
            teamName: 'Vendas Porto',
            teamManager: 'pedro.sousa@outlook.com',
            teamMembers: 13,
            revenue: '€ 135,000',
            customers: 105,
            members: generateMembers(),
            corporation: 'Blue Corporation Portugal',
        },
        {
            id: 9,
            teamName: 'Sales London',
            teamManager: 'emma.wilson@outlook.com',
            teamMembers: 9,
            revenue: '€ 105,000',
            customers: 100,
            members: generateMembers(),
            corporation: 'Blue Corporation UK',
        },
        {
            id: 10,
            teamName: 'Sales Manchester',
            teamManager: 'michael.johnson@outlook.com',
            teamMembers: 12,
            revenue: '€ 125,000',
            customers: 95,
            members: generateMembers(),
            corporation: 'Blue Corporation UK',
        },
    ]
}

const mockData = generateMockData()

export const YourTeams = () => {
    const { t } = useCustomTranslation()
    const [searchValue, setSearchValue] = useState('')
    const [selectedTeamId, setSelectedTeamId] = useState(null)
    const [deleteTeamModal, setDeleteTeamModal] = useState(false)
    const [manageMembersModal, setManageMembersModal] = useState(false)
    const location = useLocation()
    const [createTeamModal, setCreateTeamModal] = useState(false)
    const navigate = useNavigate()

    const handleClickOption = useCallback(
        (option, teamId) => {
            setSelectedTeamId(teamId)
            if (option === 'edit') {
                navigate(`${location.pathname}/${teamId}`, {
                    state: { isEdit: true },
                })
            } else if (option === 'remove') {
                setDeleteTeamModal(true)
            } else if (option === 'manageMembers') {
                setManageMembersModal(true)
            }
        },
        [location.pathname, navigate]
    )

    const filteredData = useMemo(() => {
        return mockData.filter((card) => {
            return (
                ['id', 'teamName', 'teamManager'].filter((info) => {
                    return card[info].toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                }).length > 0
            )
        })
        // .filter((card) => {
        //     return filters.reduce((acc, filter) => {
        //         return (
        //             acc && filter.value.includes(_.get(card, filter.path))
        //         );
        //     }, true);
        // });
    }, [searchValue])


    return (
        <>
            {createTeamModal && <CreateTeamModal onToggleModal={() => setCreateTeamModal(false)} />}
            {selectedTeamId && manageMembersModal && (
                <ManageTeamMembersModal
                    onToggleModal={() => {
                        setManageMembersModal(false)
                        setSelectedTeamId(null)
                    }}
                />
            )}
            {selectedTeamId && deleteTeamModal && (
                <RemoveOrgTeamModal
                    teamId={selectedTeamId}
                    onCloseModal={() => {
                        setSelectedTeamId(null)
                        setDeleteTeamModal(false)
                    }}
                />
            )}
            <div className={styles['your-teams-container']}>
                <div className="row w-100 mx-0 dashboard-header-row">
                    <div className="col px-0 d-flex justify-content-between align-items-center">{t('pages:yourTeams.title')}</div>
                </div>
                <div
                    className="row mx-0 w-100"
                    style={{
                        marginBottom: '2rem',
                    }}
                >
                    <div className="col px-0">
                        <span className={styles['your-teams-container__sub-title']}>{t('pages:yourTeams.sub-title')}</span>
                    </div>
                </div>

                <div className="row mx-0 w-100 d-flex justify-content-between align-items-center" style={{ marginBottom: '2rem' }}>
                    <div className="col-auto px-0" style={{ width: '40rem' }}>
                        <SearchInput value={searchValue} placeholder={t('inputs:searchTeam')} onChange={(e) => setSearchValue(e.target.value)} />
                    </div>
                    <div className="col-auto px-0">
                        <PrimaryButton text={t('buttons:createTeam')} icon={icoCreateTeam} onClick={() => setCreateTeamModal(true)} />
                    </div>
                </div>
                <div
                    className="row"
                    style={{
                        width: 'calc(100% + 2rem)',
                        margin: '0 -1rem',
                    }}
                >
                    {filteredData.map((team, index) => (
                        <>
                            <div key={index} className="col-6 col-md-6 col-lg-4 col-xl-3" style={{ padding: '1rem' }}>
                                <TeamSmallCard
                                    team={team}
                                    onSelectCard={() => {
                                        navigate(`${location.pathname}/${team.id}`, {
                                            state: { teamSelected: team },
                                        })
                                    }}
                                    onClickOption={(args, teamId) => handleClickOption(args, teamId)}
                                />
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}
