
import _ from 'lodash';
import {  useReducer } from 'react';

const ACTIONS = {
	TOGGLE_ACTIVE_DOT: 'toggleActiveDot',
    TOGGLE_INACTIVE_DOT: 'toggleInactiveDot'
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.TOGGLE_ACTIVE_DOT:
                const activeIdx = _.findIndex(state[action.payload.dataKeyIndex].dots,(el) => {
                    return el.index === action.payload.value
                })
                if (activeIdx !== -1){
                    state[action.payload.dataKeyIndex].dots[activeIdx].active = true
                }
            return [...state]
            case ACTIONS.TOGGLE_INACTIVE_DOT:
                    const inactiveIdx = _.findIndex(state[action.payload.dataKeyIndex].dots,(el) => {
                        return el.index === action.payload.value
                    })
                    if (inactiveIdx !== -1){
                        state[action.payload.dataKeyIndex].dots[inactiveIdx].active = false
                    }
                return [...state]
	}
}

export function useDotHoveredList(defaultValue) {
	const [list, dispatch] = useReducer(reducer, defaultValue);

	function toggleActiveDot(dataKeyIndex,value) {
		dispatch({ type: 'toggleActiveDot', payload:{dataKeyIndex, value} });
	}

    function toggleInactiveDot(dataKeyIndex, value) {
		dispatch({ type: 'toggleInactiveDot', payload:{dataKeyIndex, value} });
	}

	return { list,toggleActiveDot,toggleInactiveDot};
}
