import React, { useCallback } from 'react'
import { ComponentWrapper, Loader, Spinner } from '../..'
import { TableImprovement } from '../../tables/table-improvement/table-improvement'
import { useTable } from '../../../hooks/useTable'
import { useToggle } from '../../../hooks/useToggle'

/**
 * Displays comparison of sales between products according to the selected category
 */

const myGoalsPortfolioMock = {
    attr: 'myGoalsPortfolio',
    type: 'type-1',
    data: [
        {
            id: 1,
            name: 'Goal 1',
            targetValue: 50,
            targetVariable: 20,
            goalTimeLeft: '2024-12-31',
            goalCurrentValue: [17000, 26],
            goalExpectedValue: [18000, 36],
            goalDifference: 10,
        },
    ],
    columns: [
        {
            path: 'name',
            label: '',
            sortable: true,
        },
        {
            path: 'targetVariable',
            label: '',
            sortable: true,
        },
        {
            path: 'goalTimeLeft',
            label: '',
            sortable: true,
        },
        {
            path: 'targetValue',
            label: '',
            sortable: true,
        },
        {
            path: 'goalCurrentValue',
            label: '',
            sortable: true,
        },
        {
            path: 'goalExpectedValue',
            label: '',
            sortable: true,
        },
        {
            path: 'goalDifference',
            label: '',
            sortable: true,
        },
    ],
}

export const MyGoalsPortfolio = React.memo(({ onSelectGoal, isPreview, ...rest }) => {
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = React.useState('')
    const { value, setLoading, setValue: setData, pageChange: dataPageChange, clickActionIcon } = useTable(myGoalsPortfolioMock)

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            clickActionIcon(attr, id)
            toggleModal(true)
            setModalType(option)
        },
        [toggleModal, clickActionIcon]
    )

    return (
        <div className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
            <ComponentWrapper {...value} {...rest}>
                {value.loading && <Spinner />}
                {!value.loading && (
                    <TableImprovement
                        {...value}
                        {...rest}
                        onPageChange={dataPageChange}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                        onRowClick={onSelectGoal ? onSelectGoal : undefined}
                    />
                )}
            </ComponentWrapper>
        </div>
    )
})

MyGoalsPortfolio.propTypes = {}
