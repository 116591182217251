import React from 'react'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { ComponentWarning } from '../component-warning/component-warning'

export const ReportOverflowWarning = () => {
    const { t } = useCustomTranslation()
    return (
        <ComponentWarning
            message={t('tooltips:content.report-overflow-warning')}
        />
    )
}
