import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SegmentRFMTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.payload && props.payload.length > 0) {
        return (
            <TooltipContainer color={'#B7BBE5'}>
                <TooltipHeaderRow title={t('tooltips:header.rfmScore')} />
                <Separator />
                {props.label === 'Recency' && (
                    <TooltipContentRow title={t(`tooltips:content.${props.label}`)} value={props.payload[1].payload.left} />
                )}
                {props.label === 'Frequency' && (
                    <TooltipContentRow title={t(`tooltips:content.${props.label}`)} value={props.payload[1].payload.bottom} />
                )}
                {props.label === 'Monetary' && (
                    <TooltipContentRow title={t(`tooltips:content.${props.label}`)} value={props.payload[1].payload.bottom} />
                )}
            </TooltipContainer>
        )
    }
})
