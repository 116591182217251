import React from 'react';
import { SegmentList } from '../segment-list';
//import { SentimentProportions } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-proportions/sentiment-proportions';
//import { SentimentOverTime } from '../../reusable/smart/general/indicator-centric/sentiment/sentiment-over-time/sentiment-over-time';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const AllSegments = React.memo(({ onSelectSegment }) => {
    return (
        <div className={`row mx-0 w-100`} style={{ height: '45rem' }}>
            <div className="col px-0">
                <SegmentList onSelectSegment={onSelectSegment} />
            </div>
        </div>
    );
});

AllSegments.propTypes = {};
