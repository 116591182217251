import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { formatDate } from '../../../utils/date';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import { SegmentationService } from '../../../temp/test';
import { segmentGeneralDetailsMock } from './mock';
import styles from './segment-general-details.module.scss';
import { ReturnButton } from '../../buttons/return-button/return-button';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';

export const SegmentGeneralDetails = React.memo(
    ({ segmentId, childrenCount, onSelectSegment }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(segmentGeneralDetailsMock);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(segmentGeneralDetailsMock);
                try {
                    if (isMounted.current) {
                        setLoading(true);
                    }
                    const results =
                        await SegmentationService.getApiSegmentation1({
                            id: segmentId
                        });
                    dataClone.segmentId = results.id;
                    dataClone.segmentName = results.name;
                    dataClone.createdBy = results.created_by;
                    dataClone.createdAt = formatDate(results.created_at);
                    dataClone.automated = results.automated.toString();
                    dataClone.description = results.description;
                    dataClone.parentSegmentId = results.parent_group_id;
                    dataClone.risk1Percent = _.find(
                        results.risk_distribution,
                        (el2) => el2.risk === 1
                    )
                        ? (
                              (_.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      if (el2.risk === 1) {
                                          return sum + el2.count;
                                      } else return sum;
                                  },
                                  0
                              ) *
                                  100) /
                              _.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      return sum + el2.count;
                                  },
                                  0
                              )
                          ).toFixed(0)
                        : 0;
                    dataClone.risk2Percent = _.find(
                        results.risk_distribution,
                        (el2) => el2.risk === 2
                    )
                        ? (
                              (_.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      if (el2.risk === 2) {
                                          return sum + el2.count;
                                      } else return sum;
                                  },
                                  0
                              ) *
                                  100) /
                              _.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      return sum + el2.count;
                                  },
                                  0
                              )
                          ).toFixed(0)
                        : 0;
                    dataClone.risk3Percent = _.find(
                        results.risk_distribution,
                        (el2) => el2.risk === 3
                    )
                        ? (
                              (_.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      if (el2.risk === 3) {
                                          return sum + el2.count;
                                      } else return sum;
                                  },
                                  0
                              ) *
                                  100) /
                              _.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      return sum + el2.count;
                                  },
                                  0
                              )
                          ).toFixed(0)
                        : 0;
                    dataClone.risk4Percent = _.find(
                        results.risk_distribution,
                        (el2) => el2.risk === 4
                    )
                        ? (
                              (_.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      if (el2.risk === 4) {
                                          return sum + el2.count;
                                      } else return sum;
                                  },
                                  0
                              ) *
                                  100) /
                              _.reduce(
                                  results.risk_distribution,
                                  function (sum, el2) {
                                      return sum + el2.count;
                                  },
                                  0
                              )
                          ).toFixed(0)
                        : 0;
                    if (
                        dataClone.settings.view.options.length === 0 &&
                        dataClone.settings.options.options.length === 0
                    )
                        dataClone.disabled = true;
                    else dataClone.disabled = false;

                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [filter, isMounted, setLoading, setValue, t, segmentId]);

        const handleSelectSettingsOption = useCallback(() => {
            if (isMounted.current) toggleComponentSettings();
        }, [isMounted, toggleComponentSettings]);

        return (
            <ContentContainer
                attr={data.attr}
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
                onClickSettingsView={handleSelectSettingsOption}
                onCLickSettintsSelected={handleSelectSettingsOption}
                onCloseSettings={toggleComponentSettings}
                // // hovered={hovered}
                // onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                // onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                // hideInfoIcon={true}
                // hidePinIcon={isExample || isPreview || isReportExample}
                // hideSettingsIcon={isExample || isPreview || isReportExample}
                // pinned={pinned}
                // onPinComponent={onPinComponent}
                // onUnpinComponent={onUnpinComponent}
                // interval={'1M'}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <div
                        className={`row mx-0 ${styles['segment-general-details-container']}`}
                    >
                        <div className="col px-0 d-flex flex-column justify-content-between">
                            <div
                                className={`row mx-0 ${styles['segment-general-details-container__details-container']} mb-3`}
                                style={{
                                    backgroundImage: `url(${creationOptionBackground})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <div className="col px-0">
                                    <div className="row mx-0 h-100">
                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__sub-title']}`}
                                                    >
                                                        {t(
                                                            `components:${data.attr}.segmentId`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__text']}`}
                                                    >
                                                        {data.segmentId}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__sub-title']}`}
                                                    >
                                                        {t(
                                                            `components:${data.attr}.createdAt`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__text']}`}
                                                    >
                                                        {data.createdAt}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__sub-title']}`}
                                                    >
                                                        {t(
                                                            `components:${data.attr}.createdBy`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__text']}`}
                                                    >
                                                        {data.createdBy}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__sub-title']}`}
                                                    >
                                                        {t(
                                                            `components:${data.attr}.automated`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__text']}`}
                                                    >
                                                        {data.automated}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`row mx-0 ${styles['segment-general-details-container__details-container']}`}
                                style={{
                                    backgroundImage: `url(${creationOptionBackground})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    marginBottom: '1rem'
                                }}
                            >
                                <div className="col px-0">
                                    <div className="row mx-0 h-100">
                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-start">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__sub-title']}`}
                                                    >
                                                        {t(
                                                            `components:${data.attr}.segmentName`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__text']}`}
                                                    >
                                                        {data.segmentName}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto px-0">
                                    {data.parentSegmentId && (
                                        <div className="col-auto px-0 pe-2 d-flex flex-column justify-content-center">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    {parseInt(childrenCount) ===
                                                        0 && (
                                                        <SecondaryButton
                                                            text={t(
                                                                'buttons:gotoparentsegment'
                                                            )}
                                                            onClick={() => {
                                                                onSelectSegment(
                                                                    {
                                                                        id: data.parentSegmentId
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`row mx-0 ${styles['segment-general-details-container__details-container']} flex-grow-1`}
                                style={{
                                    backgroundImage: `url(${creationOptionBackground})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <div className="col px-0">
                                    <div className="row mx-0 h-100">
                                        <div className="col px-0 pe-2 d-flex flex-column justify-content-between">
                                            <div className="row w-100 mx-0">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__sub-title']}`}
                                                    >
                                                        {t(
                                                            `components:${data.attr}.description`
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row w-100 mx-0 flex-grow-1">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['segment-general-details-container__details-container__text']}`}
                                                    >
                                                        {data.description}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles['segment-general-details-container__risk-card']} mb-3`}
                            >
                                <div
                                    className={`${styles['segment-general-details-container__risk-card__risk-container']}`}
                                >
                                    <div
                                        className={`${styles['segment-general-details-container__risk-card__risk-container__risk-bar']}`}
                                        style={{
                                            backgroundColor: '#7dd081',
                                            borderRadius: '1.5rem 0 0 1.5rem'
                                        }}
                                    >
                                        <span
                                            className={`${styles['segment-general-details-container__risk-card__risk-container__risk-text']}`}
                                        >
                                            {data.risk1Percent}%
                                        </span>
                                    </div>
                                    <div
                                        className={`${styles['segment-general-details-container__risk-card__risk-container__risk-bar']}`}
                                        style={{
                                            backgroundColor: '#e1a43a'
                                        }}
                                    >
                                        <span
                                            className={`${styles['segment-general-details-container__risk-card__risk-container__risk-text']}`}
                                        >
                                            {data.risk2Percent}%
                                        </span>
                                    </div>
                                    <div
                                        className={`${styles['segment-general-details-container__risk-card__risk-container__risk-bar']}`}
                                        style={{
                                            backgroundColor: '#f4841b'
                                        }}
                                    >
                                        {' '}
                                        <span
                                            className={`${styles['segment-general-details-container__risk-card__risk-container__risk-text']}`}
                                        >
                                            {data.risk3Percent}%
                                        </span>
                                    </div>
                                    <div
                                        className={`${styles['segment-general-details-container__risk-card__risk-container__risk-bar']}`}
                                        style={{
                                            backgroundColor: '#db6162',
                                            borderRadius: '0 1.5rem 1.5rem 0'
                                        }}
                                    >
                                        <span
                                            className={`${styles['segment-general-details-container__risk-card__risk-container__risk-text']}`}
                                        >
                                            {data.risk4Percent}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ContentContainer>
        );
    }
);
