import React, { useState } from 'react';
import IcoEye from '../../../assets/images/ico-hidden.svg';
import IcoEyeHidden from '../../../assets/images/icon-eye-hidden.svg';
import { ReactComponent as IcoEdit } from './assets/images/ico-edit.svg';
import { ReactComponent as IcoValid } from './assets/images/ico-valid.svg';
import styles from './type-input-alternate.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const TypeInputAlternate = React.memo(
    ({ input, onKeyPress, disabled, hideLabel, inputType = '' }) => {
        const { t } = useCustomTranslation();
        const [selected, toggleSelected] = useState(false);
        const [showPassword, toggleShowPassword] = useState(false);
        const containerStyle = getContainerStyle(
            disabled,
            selected,
            input,
            inputType
        );
        const inputStyles = getInputStyles(input, inputType);
        const labelStyles = getLabelStyles(inputType, input.error);

        return (
            <React.Fragment>
                <div
                    className={containerStyle}
                    onFocus={() => toggleSelected(true)}
                    onBlur={() => toggleSelected(false)}
                >
                    {!hideLabel && (
                        <label htmlFor={input.name} className={labelStyles}>
                            {input.label || t(`inputs:${input.name}`)}
                        </label>
                    )}
                    {inputType === 'container'
                        ? renderTextArea(input, inputStyles, onKeyPress)
                        : renderInput(input, inputStyles, onKeyPress)}
                    {input.password &&
                        renderPasswordIcon(
                            input,
                            showPassword,
                            toggleShowPassword
                        )}
                    {input.edit && (
                        <IcoEdit className={getEditIconStyle(input)} />
                    )}
                    {input.state === 'valid' && (
                        <IcoValid
                            className={
                                styles[
                                    'type-input-alternate-container__valid-icon'
                                ]
                            }
                        />
                    )}
                    {input.error && (
                        <div className={styles['type-input-error']}>
                            {t(`errors:${input.error}`)}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
);

TypeInputAlternate.propTypes = {};

// Helper functions
function getContainerStyle(disabled, selected, input, inputType) {
    const baseStyle = `${styles['type-input-alternate-container']}`;
    const disabledStyle = disabled ? styles['disabled'] : '';
    const selectedStyle = selected ? styles['selected'] : '';
    const valuedStyle = input.value !== '' ? styles['valued'] : '';
    return `${baseStyle} ${disabledStyle} ${selectedStyle} ${valuedStyle} ${styles[inputType]}`;
}

function getInputStyles(input, inputType) {
    const baseStyle = `${styles['form-control']} ${styles['type-input-alternate-container__input']}`;
    const errorStyle = input.error
        ? styles['type-input-alternate-container__input--error']
        : '';
    const textAreaStyle = inputType === 'container' ? styles['textarea'] : '';
    const placeholderStyle = !input.value ? styles['placeholder'] : '';
    return `${baseStyle} ${errorStyle} ${textAreaStyle} ${placeholderStyle}`;
}

function getLabelStyles(inputType, hasError) {
    const baseStyle = styles['type-input-alternate-container__label'];
    const textAreaStyle = inputType === 'container' ? styles['textarea'] : '';
    const errorStyle = hasError
        ? styles['type-input-alternate-container__label--error']
        : '';
    return `${baseStyle} ${textAreaStyle} ${errorStyle}`;
}

function renderTextArea(input, inputStyles, onKeyPress) {
    return (
        <textarea
            autoComplete="false"
            name={input.name}
            value={input.value}
            id={input.name}
            type={input.hidden ? 'password' : 'text'}
            className={inputStyles}
            style={{ resize: 'none' }}
            onChange={input.setValue}
            onKeyDown={onKeyPress}
        />
    );
}

function renderInput(input, inputStyles, onKeyPress) {
    return (
        <input
            autoComplete="false"
            name={input.name}
            value={input.value}
            id={input.name}
            type={input.hidden ? 'password' : 'text'}
            className={inputStyles}
            onChange={input.setValue}
            onKeyDown={onKeyPress}
        />
    );
}

function renderPasswordIcon(input, showPassword, toggleShowPassword) {
    const icon = showPassword ? IcoEye : IcoEyeHidden;
    const iconStyle = {
        right: '1rem',
        width: '2.4rem',
        height: '2.4rem'
    };
    return (
        <img
            loading="lazy"
            src={icon}
            style={iconStyle}
            className={getPasswordIconStyle(input)}
            onClick={() => {
                toggleShowPassword(!showPassword);
                input.handleClickHiddenIcon(input.name);
            }}
        />
    );
}

function getEditIconStyle(input) {
    const baseStyle = styles['type-input-alternate-container__edit-icon'];
    const errorStyle =
        input.state === 'error'
            ? styles['type-input-alternate-container__edit-icon--error']
            : '';
    return `${baseStyle} ${errorStyle}`;
}

function getPasswordIconStyle(input) {
    const baseStyle = styles['type-input-alternate-container__password-icon'];
    const errorStyle =
        input.state === 'error'
            ? styles['type-input-alternate-container__password-icon--error']
            : '';
    return `${baseStyle} ${errorStyle}`;
}
