import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './scroll.module.scss'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useModalPopup } from '../../../providers/modal-popup-context'

// This function takes a component...
export const Scroll = React.memo(({ noPadding, children }) => {
    const isMounted = useIsMounted()
    const [listShadow, setListShadow] = useState('')
    const { steps, selectedTutorialStep, currentStep } = useModalPopup()
    const listRef = useRef()

    useEffect(() => {
        async function fill() {
            const top =
                listRef.current.scrollTop === 0 &&
                listRef.current.scrollHeight !==
                    listRef.current.clientHeight - 1
            const bottom =
                listRef.current.scrollHeight - listRef.current.scrollTop ===
                    listRef.current.clientHeight &&
                listRef.current.scrollHeight !==
                    listRef.current.clientHeight - 1
            const middle =
                listRef.current.scrollTop !== 0 &&
                listRef.current.scrollHeight - listRef.current.scrollTop !==
                    listRef.current.clientHeight - 1
            if (top) {
                if (isMounted.current) {
                    setListShadow('up')
                }
            } else if (bottom) {
                if (isMounted.current) {
                    setListShadow('down')
                }
            } else if (middle) {
                if (isMounted.current) {
                    setListShadow('middle')
                }
            } else {
                if (isMounted.current) {
                    setListShadow('default')
                }
            }
        }
        fill()
    }, [isMounted])

    const handleScroll = useCallback(
        (e) => {
            const top =
                e.target.scrollTop === 0 &&
                e.target.scrollHeight !== e.target.clientHeight
            const bottom =
                e.target.scrollHeight - e.target.scrollTop ===
                    e.target.clientHeight &&
                e.target.scrollHeight !== e.target.clientHeight
            const middle =
                e.target.scrollTop !== 0 &&
                e.target.scrollHeight - e.target.scrollTop !==
                    e.target.clientHeight
            if (top) {
                if (isMounted.current) {
                    setListShadow('up')
                }
            } else if (bottom) {
                if (isMounted.current) {
                    setListShadow('down')
                }
            } else if (middle) {
                if (isMounted.current) {
                    setListShadow('middle')
                }
            } else {
                if (isMounted.current) {
                    setListShadow('default')
                }
            }
        },
        [isMounted]
    )

    return (
        <>
            <div
                onScroll={handleScroll}
                className={
                    noPadding
                        ? `col p-0 ${styles['scroll-container__container-wrapper-container']}`
                        : `col ${styles['scroll-container__container-wrapper-container']}`
                }
                ref={listRef}
                style={
                    steps &&
                    selectedTutorialStep !== '' &&
                    steps[selectedTutorialStep]['steps'][currentStep][
                        'popupDetails'
                    ] &&
                    (steps[selectedTutorialStep]['steps'][currentStep][
                        'popupDetails'
                    ]['type'] === 'component' ||
                        steps[selectedTutorialStep]['steps'][currentStep][
                            'popupDetails'
                        ]['type'] === 'dashboardPreviewCard')
                        ? { zIndex: 10, overflow: 'visible' }
                        : {}
                }
            >
                {children}
            </div>
            <div
                className={`scroll-container__list-shadow scroll-container__list-shadow--${listShadow} `}
            ></div>
        </>
    )
})

Scroll.propTypes = {
    /**
     * Child nodes to be included in the render-props element
     */
    children: PropTypes.node,
}
