/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModulesSubscriptionSchema } from '../models/ModulesSubscriptionSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StarkdataService {
    /**
     * Returns the modules subscription. Open to all users
     * @returns ModulesSubscriptionSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeAdminModulesSubscription(): CancelablePromise<ModulesSubscriptionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/admin/modules-subscription',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Updates the modules subscription, starkdata only
     * @returns ModulesSubscriptionSchema call successful
     * @throws ApiError
     */
    public static patchApiBackofficeAdminModulesSubscription({
        requestBody,
    }: {
        requestBody: ModulesSubscriptionSchema,
    }): CancelablePromise<ModulesSubscriptionSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/backoffice/admin/modules-subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
