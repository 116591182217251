import React, { useState } from 'react';
import styles from './goal-summary-modal.module.scss';
import icoGoalManagement from '../../../assets/images/icoGoalManagement.svg';
import icoVisibility from '../../../assets/images/icoVisibility.svg';
import { Modal } from '../modal/modal';
import { MilestoneTimeline } from '../../goals/MilestoneTimeline/MilestoneTimeline';
import { PendingApprovalStateTableColumn } from '../../tables/table-column-factory/components/approval-state-table-column/approval-state-table-column';
import icoReject from '../../../assets/images/icoReject.svg';
import icoApprove from '../../../assets/images/icoApprove.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { GoalStatus } from '../../text/GoalStatus/GoalStatus';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button';

const SummarySection = ({ title, direction = 'vertical', informations }) => {
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles[
                                'goal-summary-modal-container__summary-subtitle'
                            ]
                        }
                    >
                        {title}
                    </span>
                </div>
            </div>
            {direction === 'vertical' && (
                <>
                    {informations.map((information, index) => {
                        const { label, icon, value, isUser } = information;
                        return (
                            <div
                                key={index}
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0 d-flex align-items-start">
                                    <span
                                        className={`
                                 ${styles['goal-summary-modal-container__summary-label']}
                                 ${styles['bold']}
                                 `}
                                    >
                                        {label}
                                        {label && ':'}
                                    </span>
                                    <span style={{ width: '0.5rem' }}></span>
                                    {icon && (
                                        <img
                                            src={icon}
                                            alt="Icon"
                                            className={`
                                             ${styles['goal-summary-modal-container__summary-label']}
                                             ${styles['icon']}
                                             `}
                                        />
                                    )}
                                    {isUser && (
                                        <div
                                            className={`${styles['goal-summary-modal-container__user-image']}`}
                                        >
                                            <span
                                                className={`${styles['goal-summary-modal-container__user-image__text']}`}
                                            >
                                                PF
                                            </span>
                                        </div>
                                    )}
                                    {
                                        // if value is an array, we display it as a list

                                        Array.isArray(value) ? (
                                            value.length !== 0 &&
                                            value.map((el, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`text-label`}
                                                    >
                                                        <span>{el}</span>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <span
                                                className={`
                                     ${styles['goal-summary-modal-container__summary-label']}
                                     `}
                                            >
                                                {value}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {direction === 'horizontal' && (
                <div className="row mx-0 w-100">
                    {informations.map((information, index) => {
                        const { label, value } = information;
                        return (
                            <div
                                key={index}
                                className="col px-0"
                                style={{ marginRight: '2rem' }}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <span
                                            className={`
                                         ${styles['goal-summary-modal-container__summary-label']}
                                         ${styles['bold']}
                                         `}
                                        >
                                            {label}:
                                        </span>{' '}
                                        <span
                                            className={`
                                         ${styles['goal-summary-modal-container__summary-label']}
                                         `}
                                        >
                                            {value}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export const GoalSummaryModal = ({
    onCloseModal,
    setRejectApprovalModal,
    setApproveApprovalModal,
    isCreatingGoal,
    goBackText,
    onSaveText,
    goBackHandler,
    onSaveHandler
}) => {
    const [dates] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]); // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    const [droppedDates, setDroppedDates] = useState([
        {
            date: '8/7',
            target: 100
        }
    ]); // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12

    const [approvalState, setApprovalState] = useState('rejected');

    const { t } = useCustomTranslation();

    return (
        <Modal
            onToggleModal={onCloseModal}
            noScrollingElements={
                isCreatingGoal && (
                    <div
                        className="row mx-0 w-100 d-flex justify-content-end"
                        style={{
                            marginTop: '2rem'
                        }}
                    >
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <TerciaryButton
                                text={goBackText}
                                onClick={
                                    goBackHandler ? goBackHandler : undefined
                                }
                            />
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={onSaveText}
                                onClick={
                                    onSaveHandler ? onSaveHandler : undefined
                                }
                            />
                        </div>
                    </div>
                )
            }
        >
            <div
                className="row mx-0 w-100 position-relative"
                style={{ marginBottom: '2rem' }}
            >
                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                    <img
                        src={icoGoalManagement}
                        alt="Goal Management"
                        className={
                            styles['goal-summary-modal-container__summary-icon']
                        }
                    />
                    <span
                        className={
                            styles[
                                'goal-summary-modal-container__summary-title'
                            ]
                        }
                    >
                        Here is the summary of your goal
                    </span>
                </div>
                {setRejectApprovalModal && setApproveApprovalModal && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 'auto'
                        }}
                    >
                        <PendingApprovalStateTableColumn
                            setApproveApprovalModal={setApproveApprovalModal}
                            setRejectApprovalModal={setRejectApprovalModal}
                        />
                    </div>
                )}
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <SummarySection
                        title="General informations"
                        informations={[
                            {
                                label: 'Goal Title',
                                value: 'Build my network'
                            },
                            {
                                label: 'Description',
                                value: 'Lorem ipsum dolor sit amet, consectetur consectetur consectetur consectetur'
                            },
                            {
                                label: 'Goal Owner',
                                isUser: true,
                                value: 'Wade Warren'
                            },
                            {
                                label: 'Goal Type',
                                value: 'Enterprise Goal'
                            },
                            {
                                label: 'Visibility',
                                value: 'Public',
                                icon: icoVisibility
                            }
                        ]}
                    />
                </div>
                <div className="col px-0">
                    <div
                        className="row mx-0 w-100 justify-content-end"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div className="col-auto px-0">
                            <span
                                className={`${styles['goal-summary-modal-container__summary-label']}
                                ${styles['bold']}
                                `}
                            >
                                Goal Status
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 justify-content-end">
                        <div className="col-auto px-0">
                            <GoalStatus text="draft" />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <SummarySection
                title="Time Period"
                direction="horizontal"
                informations={[
                    {
                        label: 'Period',
                        value: 'FY 2024'
                    },
                    {
                        label: 'Start Date',
                        value: '01/01/2024'
                    },
                    {
                        label: 'End Date',
                        value: '31/12/2024'
                    }
                ]}
            />
            <hr />
            <SummarySection
                title="Target"
                informations={[
                    {
                        label: 'Business Indicator',
                        value: 'Units Sold'
                    },
                    {
                        label: 'Target Value',
                        value: '€1.000.000'
                    },
                    {
                        label: 'Business Dimension',
                        value: ['Revenue']
                    },
                    {
                        label: 'Reminder',
                        value: 'Weekly'
                    }
                ]}
            />
            <hr />
            <div className="row mx-0 w-100">
                <div
                    className="col-auto px-0"
                    style={{
                        width: '40rem'
                    }}
                >
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '4rem' }}
                    >
                        <div className="col px-0">
                            <span
                                className={
                                    styles[
                                        'goal-summary-modal-container__summary-subtitle'
                                    ]
                                }
                            >
                                {'Milestones'}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <MilestoneTimeline
                                dates={dates}
                                droppedDates={droppedDates}
                                minified
                            />
                        </div>
                    </div>
                </div>
                <div className="col px-0" style={{ width: '4rem' }}></div>
                <div className="col-auto px-0">
                    <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0">
                            <span
                                className={
                                    styles[
                                        'goal-summary-modal-container__summary-subtitle'
                                    ]
                                }
                            >
                                {'Approvals'}
                            </span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col-auto px-0 d-flex justify-content-center align-items-start">
                            <div
                                className={
                                    styles[
                                        'goal-summary-modal-container__image-placeholder'
                                    ]
                                }
                            >
                                <span
                                    className={
                                        styles[
                                            'goal-summary-modal-container__image-placeholder__text'
                                        ]
                                    }
                                >
                                    PF
                                </span>
                            </div>
                        </div>
                        <div
                            className="col-auto px-0"
                            style={{ width: '1rem' }}
                        ></div>
                        <div className="col px-0">
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                    <span
                                        className={
                                            styles[
                                                'goal-summary-modal-container__approval-name'
                                            ]
                                        }
                                    >
                                        Wade Warren
                                    </span>
                                    {approvalState === 'approved' && (
                                        <img
                                            src={icoApprove}
                                            alt="Approve"
                                            className={
                                                styles[
                                                    'goal-summary-modal-container__approval-icon'
                                                ]
                                            }
                                        />
                                    )}
                                    {approvalState === 'rejected' && (
                                        <img
                                            src={icoReject}
                                            alt="Reject"
                                            className={
                                                styles[
                                                    'goal-summary-modal-container__approval-icon'
                                                ]
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0 d-flex justify-content-start align-items-center">
                                    <span
                                        className={
                                            styles[
                                                'goal-summary-modal-container__approval-state'
                                            ]
                                        }
                                        style={{
                                            color:
                                                (approvalState === 'pending' &&
                                                    '#b6b6b6') ||
                                                (approvalState === 'approved' &&
                                                    '#7DD081') ||
                                                (approvalState === 'rejected' &&
                                                    '#DB6162')
                                        }}
                                    >
                                        {approvalState === 'pending' &&
                                            t('common:pending')}
                                        {approvalState === 'approved' &&
                                            t('common:approved')}
                                        {approvalState === 'rejected' &&
                                            t('common:rejected')}
                                    </span>
                                </div>
                            </div>
                            {approvalState === 'approved' ||
                                (approvalState === 'rejected' && (
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <span
                                                className={
                                                    styles[
                                                        'goal-summary-modal-container__approval-date'
                                                    ]
                                                }
                                            >
                                                20 Feb 2024
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* {inputs.approvalRequest.selectedOptionAux === 'yes' && (
                <>
                    <hr />
                    <SummarySection
                        title="Approvals"
                        informations={[
                            {
                                value: 'Wade Warren',
                                isUser: true
                            }
                        ]}
                    />
                </>
            )}
            <div className="row mx-0 w-100 d-flex justify-content-end">
                <div className="col-auto px-0" style={{ marginRight: '2rem' }}>
                    <TerciaryButton
                        text={t(`buttons:back`)}
                        onClick={toggleSummary}
                    />
                </div>
                <div className="col-auto px-0">
                    <PrimaryButton
                        text={
                            inputs.approvalRequest.selectedOptionAux === 'yes'
                                ? t(`buttons:submitForApproval`)
                                : t(`buttons:publishGoal`)
                        }
                        onClick={handleCreateGoal}
                    />
                </div>
            </div> */}
        </Modal>
    );
};
