import React, { useState } from 'react';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { errorHandler } from '../../../../../utils/api';
import { RemoveModal } from '../../../../../components';

export const RemoveCheckedMembersModal = ({ onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation();
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const handleDelete = async () => {
        try {
            setLoading(true);
            // await TeamsService.deleteApiTeams({ ids: [teamId] });
            if (onEnd) onEnd();
            setLoading(false);
            setIsSuccess(true);
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <RemoveModal
            title={t('modals:removeCheckedMembers.title')}
            description={t('modals:removeCheckedMembers.description')}
            successMessage={t('modals:removeCheckedMembers.success')}
            isLoading={loading}
            isSuccess={isSuccess}
            onDelete={handleDelete}
            onCloseModal={onCloseModal}
        />
    );
};
