import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import _ from 'lodash';

export const SegmentComparison = React.memo((props) => {
    const { t } = useCustomTranslation();
    if (!_.isEmpty(props['tooltipActive'])) {
        return (
            <TooltipContainer color={props['tooltipActive']['color']}>
                <TooltipHeaderRow
                    title={t('tooltips:header.segment')}
                    value={props['tooltipActive']['segment']}
                />
                <Separator />
                <TooltipContentRow
                    title={t(
                        `tooltips:content.${props['tooltipActive']['subject']}`
                    )}
                    value={
                        (
                            props['tooltipActive']['value'] *
                            (props['tooltipActive']['max'] -
                                props['tooltipActive']['min'] +
                                props['tooltipActive']['min'])
                        ).toFixed(2) || '-'
                    }
                />
                <TooltipContentRow
                    title={t('tooltips:content.max')}
                    value={props['tooltipActive']['fullMark'].toFixed(2) || '-'}
                />
            </TooltipContainer>
        );
    } else return null;
});
