import React from 'react';
import { Modal, PrimaryButton, TerciaryButton } from '../../';
import icoBin from '../../../assets/images/icoBin.svg';
import { SuccessModal } from '../success-modal/success-modal';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { InputFactory } from '../../inputs/input-factory/input-factory';

export const RemoveModal = ({
    title,
    titleColor,
    description,
    successMessage,
    onDelete,
    onCloseModal,
    isLoading,
    isSuccess,
    icon,
    inputs,
    onChangeInput
}) => {
    const { t } = useCustomTranslation();
    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                {icon && (
                    <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <img
                                src={icon}
                                style={{
                                    width: '8rem',
                                    height: '8rem'
                                }}
                            />
                        </div>
                    </div>
                )}
                {!icon && (
                    <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <img
                                src={icoBin}
                                alt="Stark"
                                style={{
                                    width: '8rem',
                                    height: '8rem'
                                }}
                            />
                        </div>
                    </div>
                )}
                {title && (
                    <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span
                                className="modal-subtitle"
                                style={{
                                    color: titleColor
                                }}
                            >
                                {title}
                            </span>
                        </div>
                    </div>
                )}
                {description && (
                    <div className="row mx-0" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0 d-flex justify-content-center align-items-center">
                            <span className="modal-content">{description}</span>
                        </div>
                    </div>
                )}
                {inputs &&
                    inputs.length > 0 &&
                    inputs.map((input, index) => (
                        <div className="row mx-0" key={index}>
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <InputFactory
                                    {...input}
                                    hideLabel
                                    onChangeInput={onChangeInput}
                                />
                            </div>
                        </div>
                    ))}

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1'
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <TerciaryButton
                            text={t('modals:cancel')}
                            onClick={onCloseModal}
                        />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton
                            text={t('modals:yes')}
                            onClick={onDelete}
                        />
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal text={successMessage} onCloseModal={onCloseModal} />
        );
    }
};
