import React, { useEffect, useMemo, useState } from 'react'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useToggle } from '../../../../../hooks/useToggle'
import { LoaderForeground, Spinner } from '../../../../../components'
import styles from './SubGoalOverview.module.scss'
import { MiniDonutChart } from '../../../../../components/charts/mini-donut-chart/mini-donut-chart'
import icoToggleSubGoals from '../../../../../assets/images/icoToggleSubGoals.svg'
import { useSubGoalData } from './useSubGoalData'
import icoTopperArrow from '../../../../../assets/images/icoTopperArrow.svg'
import icoSubGoalCup from '../../../../../assets/images/icoSubGoalCup.svg'
import { GoalCard } from '../GoalCard/GoalCard'
import { BreadCrumbs } from '../../../../../components/formatting/breadcrumbs/BreadCrumbs'

// create a function that generates random mock data. The sub-goals should be recursive

const addDotsToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// Utility function to generate a random title
const randomTitle = () => {
    const titles = [
        'Project Alpha',
        'Beta Launch',
        'Market Expansion',
        'New Product Line',
        'Cost Reduction',
        'Technology Upgrade',
    ]
    return titles[Math.floor(Math.random() * titles.length)]
}

// Utility function to generate a random owner name
const randomOwner = () => {
    const owners = [
        'Department A',
        'Department B',
        'Organization X',
        'Organization Y',
        'Unit Z',
        'Global Team',
    ]
    return owners[Math.floor(Math.random() * owners.length)]
}

// Utility function to generate a random time period
const randomTimePeriod = () => {
    const years = ['2019', '2020', '2021', '2022', '2023']
    return years[Math.floor(Math.random() * years.length)]
}

const countEverySubGoal = (goal) => {
    let count = 0
    goal.subGoals.forEach((sg) => {
        if (sg.subGoalsOpen) {
            count += 1 + countEverySubGoal(sg)
        } else {
            count += 1
        }
    })

    return count
}

const generateSubGoals = (depth = 0) => {
    if (depth > 1) {
        return []
    }
    const subGoals = []
    for (let i = 0; i < Math.floor(Math.random() * 5 + 2); i++) {
        subGoals.push({
            id: depth * 100 + i + 1,
            title: randomTitle(),
            owner: randomOwner(),
            timePeriod: randomTimePeriod(),
            targetValue: addDotsToNumber(
                Math.round(Math.random() * 100000 + 10000)
            ),
            currentValue: addDotsToNumber(Math.round(Math.random() * 10000)),
            subGoals: generateSubGoals(depth + 1),
            subGoalsOpen: false,
        })
    }
    return subGoals
}

const generateMockData = () => {
    return {
        id: 1,
        title: 'Goal 1',
        owner: 'Organization 3',
        timePeriod: '2021',
        targetValue: addDotsToNumber(
            Math.round(Math.random() * 100000 + 10000)
        ),
        currentValue: addDotsToNumber(Math.round(Math.random() * 10000)),
        subGoals: generateSubGoals(),
        subGoalsOpen: true,
    }
}

const mockData = generateMockData()

const SubGoalTree = React.memo(
    ({
        subGoals,
        subGoalsOpen,
        updateSubGoalData,
        subGoalDepth = 0,
        id,
        isSubGoal,
        goalIndex,
    }) => {
        const offset = useMemo(() => {
            return subGoalDepth === 0 ? 25 : 25
        }, [subGoalDepth])
        const cardVerticalLine = useMemo(() => {
            let count = 0
            if (subGoalsOpen) {
                count = countEverySubGoal({
                    subGoals: subGoals.slice(0, subGoals.length - 1),
                })
            }
            return count * 25 + offset + 0.1 + 1
        }, [subGoals, subGoalsOpen, offset])
        const lineVerticalLine = useMemo(() => {
            let count = 0
            if (subGoalsOpen) {
                count = countEverySubGoal({
                    subGoals: subGoals.slice(0, subGoals.length - 1),
                })
            }
            if (subGoalDepth === 0) {
                return count * 25 + 15 + 0.1
            }
            return count * 25 + 19 + 0.1
        }, [subGoals, subGoalsOpen, subGoalDepth])
        return (
            <>
                {subGoals.length > 0 && !subGoalsOpen && (
                    <>
                        <svg
                            width="100%"
                            height={cardVerticalLine + 'rem'}
                            style={{
                                position: 'absolute',
                                top: 'calc(100%)',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            <defs>
                                <linearGradient
                                    id="myGradient"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2={'5rem'}
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset="1.19%" stopColor="#0EA6FA" />
                                    <stop
                                        offset="57.71%"
                                        stopColor="#2E5BFF"
                                        stopOpacity="0.53"
                                    />
                                    <stop
                                        offset="98.12%"
                                        stopColor="#2CD9C5"
                                        stopOpacity="0.3"
                                    />
                                </linearGradient>
                            </defs>
                            <line
                                x1="50%"
                                y1="0"
                                x2="50%"
                                y2={'5rem'}
                                stroke="url(#myGradient)"
                                strokeWidth="2"
                            />
                        </svg>
                        <img
                            src={icoToggleSubGoals}
                            alt="Toggle Sub-Goals"
                            style={{
                                position: 'absolute',
                                top: 'calc(100% + 5rem - 3px)',
                                left: '50%',
                                transform: 'translateX(-50%) rotate(180deg)',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                updateSubGoalData(id, 'subGoalsOpen', true)
                            }}
                        />
                    </>
                )}
                {subGoals.length > 0 && subGoalsOpen && (
                    <>
                        <svg
                            width="100%"
                            height={cardVerticalLine + 'rem'}
                            style={{
                                position: 'absolute',
                                top: 'calc(100%)',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            <defs>
                                <linearGradient
                                    id="myGradient"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2={lineVerticalLine + 'rem'}
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset="1.19%" stopColor="#0EA6FA" />
                                    <stop
                                        offset="57.71%"
                                        stopColor="#2E5BFF"
                                        stopOpacity="0.53"
                                    />
                                    <stop
                                        offset="98.12%"
                                        stopColor="#2CD9C5"
                                        stopOpacity="0.3"
                                    />
                                </linearGradient>
                            </defs>
                            <line
                                x1="50%"
                                y1="0"
                                x2="50%"
                                y2={lineVerticalLine + 'rem'}
                                stroke="url(#myGradient)"
                                strokeWidth="2"
                            />
                            {subGoals.map((sg, index) => {
                                let count = 0
                                if (index !== 0) {
                                    subGoals.forEach((subGoal, i) => {
                                        if (i < index && subGoal.subGoalsOpen) {
                                            count += countEverySubGoal(subGoal)
                                        }
                                    })
                                }
                                const y =
                                    subGoalDepth === 0
                                        ? index * 25 + 15 + count * 25
                                        : index * 25 + 19 + count * 25
                                return (
                                    <line
                                        key={index}
                                        x1="50%"
                                        y1={`${y}rem`}
                                        x2="75%"
                                        y2={`${y}rem`}
                                        stroke="url(#myGradient)"
                                        strokeWidth="2"
                                    />
                                )
                            })}
                        </svg>
                        {subGoals.map((sg, index) => {
                            let count = 0
                            if (index !== 0) {
                                subGoals.forEach((subGoal, i) => {
                                    if (i < index && subGoal.subGoalsOpen) {
                                        count += countEverySubGoal(subGoal)
                                    }
                                })
                            }
                            const top =
                                subGoalDepth === 0
                                    ? index * 25 + 34 + count * 25
                                    : index * 25 + 25 + count * 25

                            return (
                                <div
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        top: `${top}rem`,
                                        left: '75%',
                                    }}
                                >
                                    <SubGoalCard
                                        key={index}
                                        {...sg}
                                        isSubGoal
                                        subGoalIndex={index}
                                        updateSubGoalData={updateSubGoalData}
                                        subGoalDepth={subGoalDepth + 1}
                                        previousGoalIndex={goalIndex + 1}
                                    />
                                </div>
                            )
                        })}
                        {isSubGoal && (
                            <img
                                src={icoToggleSubGoals}
                                alt="Toggle Sub-Goals"
                                style={{
                                    position: 'absolute',
                                    top: 'calc(100% + 5rem)',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    updateSubGoalData(id, 'subGoalsOpen', false)
                                }}
                            />
                        )}
                    </>
                )}
            </>
        )
    }
)

const MainGoalCard = React.memo(
    ({
        id,
        title,
        owner,
        status,
        timePeriod,
        targetValue,
        currentValue,
        subGoals,
        subGoalsOpen,
        updateSubGoalData,
        subGoalDepth = 0,
        isSubGoal,
    }) => {
        return (
            <div
                className={
                    styles['sub-goal-overview-container__main-goal-card']
                }
            >
                <GoalCard
                    id={id}
                    title={title}
                    owner={owner}
                    hideMilestones
                    hideFlag
                    status={status}
                    timePeriod={timePeriod}
                    targetValue={targetValue}
                    currentValue={currentValue}
                />
                <SubGoalTree
                    subGoals={subGoals}
                    subGoalsOpen={subGoalsOpen}
                    updateSubGoalData={updateSubGoalData}
                    subGoalDepth={subGoalDepth}
                    id={id}
                    isSubGoal={isSubGoal}
                />
            </div>
        )
    }
)

const SubGoalCard = React.memo(
    ({
        id,
        title,
        owner,
        timePeriod,
        targetValue,
        currentValue,
        subGoals,
        isSubGoal,
        subGoalIndex,
        previousGoalIndex,
        subGoalsOpen,
        updateSubGoalData,
        subGoalDepth = 0,
    }) => {
        return (
            <div
                className={styles['sub-goal-overview-container__sub-goal-card']}
            >
                {isSubGoal && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 'calc(100% + 1rem)',
                            left: '0%',
                        }}
                    >
                        <span
                            className={
                                styles[
                                    'sub-goal-overview-container__sub-goal-card__sub-goal-index'
                                ]
                            }
                        >
                            Sub-Goal {previousGoalIndex || subGoalIndex + 1}
                            {subGoalDepth > 1 && `.${subGoalIndex + 1}`}
                        </span>
                    </div>
                )}
                <div className="row mx-0 h-100">
                    <div className="col px-0 h-100">
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '0.5rem' }}
                        >
                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                <img
                                    src={icoSubGoalCup}
                                    alt="Sub-Goal Cup"
                                    style={{
                                        width: '2.4rem',
                                        marginRight: '0.5rem',
                                    }}
                                />
                                <span
                                    className={
                                        styles[
                                            'sub-goal-overview-container__sub-goal-card__title'
                                        ]
                                    }
                                >
                                    {title}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '0.5rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={
                                        styles[
                                            'sub-goal-overview-container__sub-goal-card__scope'
                                        ]
                                    }
                                >
                                    {owner}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '0.5rem' }}
                        >
                            <div className="col px-0">
                                <span
                                    className={
                                        styles[
                                            'sub-goal-overview-container__sub-goal-card__currentValue'
                                        ]
                                    }
                                >
                                    €{currentValue}
                                    {' / '}
                                </span>
                                <span
                                    className={
                                        styles[
                                            'sub-goal-overview-container__sub-goal-card__targetValue'
                                        ]
                                    }
                                >
                                    €{targetValue}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-auto px-0 h-100 d-flex"
                        style={{ width: '10rem' }}
                    >
                        <MiniDonutChart
                            insideTitle="50%"
                            insideTitleColor="rgba(6, 164, 231, 0.7)"
                            data={{
                                data1: [
                                    {
                                        key1: 'Product',
                                        value: 17357051.54,
                                        color: '#69b6e2',
                                        name: 'Product',
                                        percentage: 0.62272463213962,
                                        isRounded: true,
                                    },
                                    {
                                        key1: 'Others',
                                        value: 3248928.99,
                                        color: '#E6E6E6',
                                        name: 'Others',
                                        percentage: 0.36770418756979956,
                                    },
                                ],
                            }}
                            donutAttr={[
                                {
                                    dataKey: 'data1',
                                    valueKey: 'value',
                                    nameKey: 'key1',
                                },
                            ]}
                        />
                    </div>
                </div>
                <SubGoalTree
                    subGoals={subGoals}
                    subGoalsOpen={subGoalsOpen}
                    updateSubGoalData={updateSubGoalData}
                    subGoalDepth={subGoalDepth}
                    id={id}
                    isSubGoal={isSubGoal}
                    goalIndex={subGoalIndex}
                />
            </div>
        )
    }
)

export const SubGoalOverview = () => {
    const { t } = useCustomTranslation()
    const { value, updateSubGoalData } = useSubGoalData(mockData)
    const [isNotOnTop, toggleIsNotOnTop] = useToggle(false)
    const [loading, toggleLoading] = useToggle(true)
    const contentRef = React.useRef(null)
    useEffect(() => {
        async function fillData() {
            toggleLoading(false)
        }
        fillData()
    }, [toggleLoading])

    useEffect(() => {
        // Add event listener to scroll isNotOnTop for contentRef
        const handleScroll = () => {
            if (contentRef.current) {
                if (contentRef.current.scrollTop > 100) {
                    toggleIsNotOnTop(true)
                } else {
                    toggleIsNotOnTop(false)
                }
            }
        }
        if (contentRef.current) {
            contentRef.current.addEventListener('scroll', handleScroll)
        }
        return () => {
            if (contentRef.current)
                contentRef.current.removeEventListener('scroll', handleScroll)
        }
    }, [toggleIsNotOnTop])

    const handleReturnToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    const [showWithAnimation, setShowWithAnimation] = useState(false)

    useEffect(() => {
        // This will not run on initial mount but only on updates
        if (isNotOnTop) {
            setShowWithAnimation(true) // starts fadeIn
        } else {
            setShowWithAnimation(false) // starts fadeOut
        }
    }, [isNotOnTop]) // Depend on isNotOnTop state

    return (
        <>
            <>
                <div className={styles['sub-goal-overview-container']}>
                    <div
                        className={
                            showWithAnimation
                                ? `${styles['fadeIn']} ${styles['sub-goal-overview-container__topper']}`
                                : `${styles['fadeOut']} ${styles['sub-goal-overview-container__topper']}`
                        }
                        onClick={handleReturnToTop}
                    >
                        <img
                            src={icoTopperArrow}
                            alt="Topper Arrow"
                            style={{
                                position: 'relative',
                                height: '2rem',
                            }}
                        />
                        <span
                            className={
                                styles[
                                    'sub-goal-overview-container__topper-text'
                                ]
                            }
                        >
                            TOP
                        </span>
                    </div>
                    <div className={`row mx-0 w-100 h-100`}>
                        <div className="col-12 px-0 h-100 d-flex flex-column">
                            {/* <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <span className={'dashboard-sub-title'}>
                                        {t(`pages:subGoalOverview.subTitle`)}
                                    </span>
                                </div>
                            </div> */}
                            <div
                                className="row mx-0 w-100 flex-grow-1"
                                style={{ minHeight: 0 }}
                            >
                                <div className="col px-0">
                                    <div
                                        className="w-100 h-100"
                                        ref={contentRef}
                                        style={{
                                            overflow: 'auto',
                                            paddingTop: '0rem',
                                            paddingBottom: '10rem',
                                        }}
                                    >
                                        <MainGoalCard
                                            {...value}
                                            subGoalsOpen
                                            updateSubGoalData={
                                                updateSubGoalData
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}
