import React from 'react'

export const TagsContainer = ({ items }) => {
    return (
        <div>
            <>
                {items && items.length !== 0
                    ? items.map((el, index) => {
                          return (
                              <div key={index} className="text-label">
                                  {el.name}
                              </div>
                          )
                      })
                    : '-'}
            </>
        </div>
    )
}
