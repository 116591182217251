import React, { useMemo } from 'react'
import styles from './tooltip-container.module.scss'
import { useCursorTrackingProvider } from '../../../providers/cursor-tracking-context'

export const TooltipContainer = React.memo(({ color, children }) => {
    const { reversedHorizontal, reversedVertical } = useCursorTrackingProvider()

    const reverseStyle = useMemo(() => {
        if (reversedHorizontal && !reversedVertical) {
            // Triangle at the top right
            return {
                position: 'absolute',
                top: 0,
                right: 0,
                width: 0,
                height: 0,
                transform: 'rotate(-90deg)',
                borderLeft: `1rem solid transparent`,
                borderBottom: `1rem solid ${color}`,
                borderRight: '0 solid transparent',
                borderTop: '0 solid transparent',
            }
        } else if (!reversedHorizontal && !reversedVertical) {
            // Triangle at the top left
            return {
                position: 'absolute',
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                transform: 'rotate(90deg)',
                borderRight: `1rem solid transparent`,
                borderBottom: `1rem solid ${color}`,
                borderLeft: '0 solid transparent',
                borderTop: '0 solid transparent',
            }
        } else if (reversedHorizontal && reversedVertical) {
            // Triangle at the bottom right
            return {
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: 0,
                height: 0,
                transform: 'rotate(90deg)',
                borderTop: `1rem solid ${color}`,
                borderLeft: '1rem solid transparent',
                borderBottom: '0 solid transparent',
                borderRight: '0 solid transparent',
            }
        } else {
            // Triangle at the bottom left
            return {
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: 0,
                height: 0,
                transform: 'rotate(-90deg)',
                borderTop: `1rem solid ${color}`,
                borderRight: '1rem solid transparent',
                borderBottom: '0 solid transparent',
                borderLeft: '0 solid transparent',
            }
        }
    }, [reversedHorizontal, reversedVertical, color])

    const tooltipStyle = useMemo(() => {
        let borderRadius
        // Adjust borderRadius based on the arrow's position
        if (reversedHorizontal && !reversedVertical) {
            // Top right
            borderRadius = '1rem 0 1rem 1rem'
        } else if (!reversedHorizontal && !reversedVertical) {
            // Top left
            borderRadius = '0 1rem 1rem 1rem'
        } else if (reversedHorizontal && reversedVertical) {
            // Bottom right
            borderRadius = '1rem 1rem 0 1rem'
        } else {
            // Bottom left
            borderRadius = '1rem 1rem 1rem 0'
        }

        return {
            borderColor: color,
            // transform: `translateX(${reversedHorizontal ? 'calc(-100% - 2rem)' : '0'}) translateY(${
            //     reversedVertical ? 'calc(-100% - 2rem)' : '2rem'
            // })`,
            borderRadius: '1rem',
        }
    }, [reversedHorizontal, reversedVertical, color])

    return (
        <div className={`${styles['tooltip-container']}`} style={tooltipStyle}>
            {/* <div style={reverseStyle}></div> */}
            {children}
        </div>
    )
})
