import React from 'react'
import styles from './segment-filters.module.scss'
import { SegmentFilterTagFactory } from '../segment-filter-tag-factory/segment-filter-tag-factory'

export const SegmentFilters = React.memo(({ filters }) => {
    return (
        <div className={`${styles['segment-filters-container']}`}>
            {filters.map((el, index) => {
                return (
                    <div key={index}>
                        <SegmentFilterTagFactory
                            key={index}
                            item={{ ...el, type: el.type, index: index }}
                        ></SegmentFilterTagFactory>
                    </div>
                )
            })}
        </div>
    )
})
