import { useCallback, useReducer } from 'react'
import { has, findIndex } from 'lodash'
import { weakPasswordOptions } from '../schemas/fieldSchemas'
import { i18n } from '../configs/i18nextConf'

const ACTIONS = {
    ADD_OPTION: 'add',
    ADD_SELECTED: 'addSelected',
    TOGGLE_DROPDOWN: 'dropdown',
    SELECT_OPTION: 'select',
    KEY_PRESS: 'keyPress',
    KEY_DOWN: 'keyDown',
    CHANGE_ERROR: 'error',
    SET_VALUE: 'value',
    RESET: 'reset',
    REPLACE: 'replace',
    REPLACE_ALL: 'replace_all',
    UNSELECT_OPTION: 'unselect',
    SET_OPTIONS: 'setOptions',
    CLEAR_SELECTED_OPTIONS: 'clearSelectedOptions',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    ADD_INPUT: 'addInput',
    REMOVE_INPUT: 'removeInput',
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.ADD_INPUT:
            return {
                ...state,
                [action.payload.name]: {
                    name: action.payload.name,
                    type: action.payload.type,
                    schema: action.payload.schema,
                    options: action.payload.options,
                    optionsAux: action.payload.optionsAux,
                    selectedOption: '',
                    selectedOptionAux: '',
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    value: '',
                    openDropdown: false,
                    reference: action.payload.reference,
                },
            }
        case ACTIONS.REMOVE_INPUT:
            const { [action.payload.name]: _, ...newState } = state
            return newState
        case ACTIONS.CLEAR_SELECTED_OPTIONS:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    selectedOption: '',
                    selectedOptionAux: '',
                    selectedOptions: [],
                    selectedOptionsAux: [],
                    value: '',
                },
            }
        case ACTIONS.REPLACE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    selectedOptions: [...action.payload.value],
                    selectedOptionsAux: [...action.payload.value],
                },
            }
        case ACTIONS.UNSELECT_OPTION:
            if (state[action.payload.name].type === 'selectSingle' || state[action.payload.name].type === 'selectLabel') {
                {
                    let selectedOptionIndex = findIndex(state[action.payload.name].selectedOptionsAux, (el) => {
                        return el === action.payload.optionAux
                    })

                    if (selectedOptionIndex !== -1) {
                        return {
                            ...state,
                            [action.payload.name]: {
                                ...state[action.payload.name],
                                options: [action.payload.option, ...state[action.payload.name].options],
                                optionsAux: [action.payload.optionAux, ...state[action.payload.name].optionsAux],
                                selectedOptions: [
                                    ...state[action.payload.name].selectedOptions.slice(0, selectedOptionIndex),
                                    ...state[action.payload.name].selectedOptions.slice(
                                        selectedOptionIndex + 1,
                                        state[action.payload.name].selectedOptions.length
                                    ),
                                ],
                                selectedOptionsAux: [
                                    ...state[action.payload.name].selectedOptionsAux.slice(0, selectedOptionIndex),
                                    ...state[action.payload.name].selectedOptionsAux.slice(
                                        selectedOptionIndex + 1,
                                        state[action.payload.name].selectedOptionsAux.length
                                    ),
                                ],
                                value: '',
                            },
                        }
                    }
                }
            } else if (state[action.payload.name].type === 'selectMultiple') {
                {
                    let selectedOptionIndex = findIndex(state[action.payload.name].selectedOptionsAux, (el) => {
                        return el === action.payload.optionAux
                    })

                    if (selectedOptionIndex !== -1) {
                        return {
                            ...state,
                            [action.payload.name]: {
                                ...state[action.payload.name],
                                options: [action.payload.option, ...state[action.payload.name].options],
                                optionsAux: [action.payload.optionAux, ...state[action.payload.name].optionsAux],
                                selectedOptions: [
                                    ...state[action.payload.name].selectedOptions.slice(0, selectedOptionIndex),
                                    ...state[action.payload.name].selectedOptions.slice(
                                        selectedOptionIndex + 1,
                                        state[action.payload.name].selectedOptions.length
                                    ),
                                ],
                                selectedOptionsAux: [
                                    ...state[action.payload.name].selectedOptionsAux.slice(0, selectedOptionIndex),
                                    ...state[action.payload.name].selectedOptionsAux.slice(
                                        selectedOptionIndex + 1,
                                        state[action.payload.name].selectedOptionsAux.length
                                    ),
                                ],
                                value: '',
                            },
                        }
                    }
                }
            }
        case ACTIONS.SET_OPTIONS:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    options: [...action.payload.options],
                    optionsAux: [...action.payload.optionsAux],
                },
            }
        case ACTIONS.REPLACE_ALL:
            return { ...action.payload.value }

        case ACTIONS.CHANGE_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        state: 'error',
                        error: action.payload.value,
                    },
                }
            } else {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        state: 'normal',
                        error: '',
                    },
                }
            }
        case ACTIONS.ADD_OPTION:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    options: [...state[action.payload.name].options, action.payload.option],
                    optionsAux: [...state[action.payload.name].optionsAux, action.payload.optionAux],
                },
            }
        case ACTIONS.ADD_SELECTED:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    selectedOption: action.payload.option,
                    selectedOptionAux: action.payload.optionAux,
                    value: action.payload.option,
                },
            }
        case ACTIONS.TOGGLE_DROPDOWN:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    openDropdown: action.payload.value,
                },
            }
        case ACTIONS.SELECT_OPTION:
            if (state[action.payload.name].type === 'selectSingle' || state[action.payload.name].type === 'selectLabel') {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        selectedOption: action.payload.option,
                        selectedOptionAux: action.payload.optionAux,
                        value: action.payload.option,
                    },
                }
            } else if (state[action.payload.name].type === 'selectMultiple') {
                let selectOptionIndex = findIndex(state[action.payload.name].optionsAux, (el) => {
                    return el === action.payload.optionAux
                })
                if (selectOptionIndex !== -1) {
                    return {
                        ...state,
                        [action.payload.name]: {
                            ...state[action.payload.name],
                            options: [
                                ...state[action.payload.name].options.slice(0, selectOptionIndex),
                                ...state[action.payload.name].options.slice(selectOptionIndex + 1, state[action.payload.name].options.length),
                            ],
                            optionsAux: [
                                ...state[action.payload.name].optionsAux.slice(0, selectOptionIndex),
                                ...state[action.payload.name].optionsAux.slice(selectOptionIndex + 1, state[action.payload.name].optionsAux.length),
                            ],
                            selectedOptions: [...state[action.payload.name].selectedOptions, action.payload.option],
                            selectedOptionsAux: [...state[action.payload.name].selectedOptionsAux, action.payload.optionAux],
                            value: '',
                        },
                    }
                } else {
                    return {
                        ...state,
                        [action.payload.name]: {
                            ...state[action.payload.name],
                            selectedOptions: [...state[action.payload.name].selectedOptions, action.payload.option],
                            selectedOptionsAux: [...state[action.payload.name].selectedOptionsAux, action.payload.optionAux],
                            value: '',
                        },
                    }
                }
            }

        case ACTIONS.KEY_PRESS: {
            let selectedOption = '',
                selectedOptionAux = ''
            if (action.payload.value === 13) {
                action.payload.value.preventDefault()
                if (state[action.payload.name].value && state[action.payload.name].focused >= 0) {
                    let valueReplaced = state[action.payload.name].value.replace(new RegExp('\\\\', 'g'), '\\\\')
                    const regex = new RegExp(`${valueReplaced}`, 'i')
                    let newOptions = state[action.payload.name].options.filter((p, index) => {
                        return p.toString().match(regex) || state[action.payload.name].optionsAux[index].toString().match(regex)
                    })
                    let newOptionsAux = state[action.payload.name].optionsAux.filter((p, index) => {
                        return state[action.payload.name].options[index].toString().match(regex) || p.toString().match(regex)
                    })
                    selectedOption = newOptions[state[action.payload.name].focused]
                    selectedOptionAux = newOptionsAux[state[action.payload.name].focused]
                } else if (state[action.payload.name].focused >= 0) {
                    selectedOption = state[action.payload.name].options[state[action.payload.name].focused]
                    selectedOptionAux = state[action.payload.name].optionsAux[state[action.payload.name].focused]
                }
                const scrollDiv = document.getElementById(`${state[action.payload.name].name}-dropdown`)
                scrollDiv.scrollTop = 0
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        focused: -1,
                        value: '',
                        selectedOption,
                        selectedOptionAux,
                    },
                }
            } else if (action.payload.value === 9) {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        focused: -1,
                        selectedOptionAux,
                    },
                }
            } else {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        focused: -1,
                        selectedOptionAux,
                    },
                }
            }
        }

        case ACTIONS.KEY_DOWN:
            {
                if (action.payload.value.which === 38) {
                    if (state[action.payload.name].focused > 0) {
                        const scrollDiv = document.getElementById(`${state[action.payload.name].name}-dropdown`)
                        const topElement = document.querySelector(
                            `#${state[action.payload.name].name}-dropdown .select-input-container__dropdown__container--focused`
                        )
                        scrollDiv.scrollTop = topElement.offsetTop - topElement.offsetHeight
                        return { ...state, focused: -1 }
                    }
                } else if (action.payload.value.which === 40) {
                    if (state[action.payload.name].value && state[action.payload.name].focused !== -1) {
                        let valueReplaced = state[action.payload.name].value.replace(new RegExp('\\\\', 'g'), '\\\\')
                        const regex = new RegExp(`${valueReplaced}`, 'i')
                        let newOptions = state[action.payload.name].options.filter((p) => {
                            return p.toString().match(regex)
                        })
                        if (state[action.payload.name].focused < newOptions.length - 1) {
                            const scrollDiv = document.getElementById(`${state[action.payload.name].name}-dropdown`)
                            const topElement = document.querySelector(
                                `#${state[action.payload.name].name}-dropdown .select-input-container__dropdown__container--focused`
                            )
                            scrollDiv.scrollTop = topElement.offsetTop + topElement.offsetHeight
                            return {
                                ...state,
                                focused: state[action.payload.name].focused + 1,
                            }
                        }
                    } else if (
                        state[action.payload.name].focused !== -1 &&
                        state[action.payload.name].focused <= state[action.payload.name].options.length - 1
                    ) {
                        const scrollDiv = document.getElementById(`${state[action.payload.name].name}-dropdown`)
                        const topElement = document.querySelector(
                            `#${state[action.payload.name].name}-dropdown .select-input-container__dropdown__container--focused`
                        )
                        scrollDiv.scrollTop = topElement.offsetTop + topElement.offsetHeight
                        return {
                            ...state,
                            focused: state[action.payload.name].focused + 1,
                        }
                    } else {
                        return {
                            [action.payload.name]: {
                                ...state[action.payload.name],
                                ...state,
                                focused: 0,
                            },
                        }
                    }
                } else if (action.payload.value.which === 9) {
                    return {
                        ...state,
                        [action.payload.name]: {
                            ...state[action.payload.name],
                            openDropdown: false,
                        },
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            }
            break
        case ACTIONS.SET_VALUE: {
            if (state[action.payload.name].type === 'type' || state[action.payload.name].type === 'number') {
                let newState = 'normal'
                let error = undefined
                if (state[action.payload.name].type === 'number' && isNaN(Number(action.payload.value))) {
                    newState = 'error'
                    error = 'provide.number' // Set custom error message for number validation
                    return {
                        ...state,
                        [action.payload.name]: {
                            ...state[action.payload.name],
                            state: newState,
                            error,
                        },
                    }
                } else if (has(state, 'schema') && state[action.payload.name].schema) {
                    const result = state[action.payload.name].schema.validate(action.payload.value, {
                        abortEarly: false,
                    })
                    if (result.error) {
                        let resultError = ''
                        let errorConfig = {}
                        if (result.error.details[0].type === 'passwordComplexity.tooShort') {
                            resultError = `${result.error.details[0].type}.${weakPasswordOptions.min}`
                        } else if (result.error.details[0].type === 'passwordComplexity.tooLong') {
                            resultError = `${result.error.details[0].type}.${weakPasswordOptions.max}`
                        } else if (result.error.details[0].type === 'passwordComplexity.lowercase') {
                            resultError = `${result.error.details[0].type}.${weakPasswordOptions.lowerCase}`
                        } else if (result.error.details[0].type === 'passwordComplexity.uppercase') {
                            resultError = `${result.error.details[0].type}.${weakPasswordOptions.upperCase}`
                        } else if (result.error.details[0].type === 'string.min') {
                            resultError = `${result.error.details[0].type}`
                            errorConfig = {
                                limit: result.error.details[0].context.limit,
                            }
                        } else if (result.error.details[0].type === 'string.max') {
                            resultError = `${result.error.details[0].type}`
                            errorConfig = {
                                limit: result.error.details[0].context.limit,
                            }
                        } else if (result.error.details[0].type === 'number.min') {
                            resultError = `${result.error.details[0].type}`
                            errorConfig = {
                                limit: result.error.details[0].context.limit,
                            }
                        } else if (result.error.details[0].type === 'number.max') {
                            resultError = `${result.error.details[0].type}`
                            errorConfig = {
                                limit: result.error.details[0].context.limit,
                            }
                        } else if (result.error.details[0].type === 'number.greater') {
                            resultError = `${result.error.details[0].type}`
                            errorConfig = {
                                limit: result.error.details[0].context.limit,
                            }
                        } else {
                            resultError = result.error.details[0].type
                        }
                        newState = 'error'
                        error = i18n.t(resultError, errorConfig)
                    }
                }
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        value: action.payload.value,
                        state: newState,
                        error,
                    },
                }
            } else if (state[action.payload.name].type === 'selectSingle' || state[action.payload.name].type === 'selectLabel') {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        value: action.payload.value,
                        selectedOption: '',
                        selectedOptionAux: '',
                    },
                }
            } else {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        value: action.payload.value,
                    },
                }
            }
        }
        case ACTIONS.RESET:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    ...action.payload.value,
                },
            }
        case ACTIONS.RADIO:
            if (state[action.payload.name].type === 'radio') {
                return {
                    ...state,
                    [action.payload.name]: {
                        ...state[action.payload.name],
                        selectedOption: action.payload.option,
                        selectedOptionAux: action.payload.option,
                    },
                }
            }

        case ACTIONS.CHECKBOX:
            if (state[action.payload.name].type === 'checkbox') {
                // Checking if the option is already selected
                const selectedOptions = state[action.payload.name].selectedOptions || []
                const selectedOptionsAux = state[action.payload.name].selectedOptionsAux || []
                if (state[action.payload.name].selectedOptions.includes(action.payload.option)) {
                    // If it is, remove the option from the selectedOptions array
                    return {
                        ...state,
                        [action.payload.name]: {
                            ...state[action.payload.name],
                            selectedOptions: selectedOptions.filter((opt) => opt !== action.payload.option),
                            selectedOptionsAux: selectedOptionsAux.filter((opt) => opt !== action.payload.option), // Assuming similar auxiliary functionality as in RADIO
                        },
                    }
                } else {
                    // If the option is not already selected, add it to the selectedOptions array
                    return {
                        ...state,
                        [action.payload.name]: {
                            ...state[action.payload.name],
                            selectedOptions: [...selectedOptions, action.payload.option],
                            selectedOptionsAux: [...selectedOptionsAux, action.payload.option], // Assuming similar auxiliary functionality as in RADIO
                        },
                    }
                }
            }
            break
    }
}

export function useMultipleInputs(defaultValue) {
    const [value, dispatch] = useReducer(reducer, defaultValue)
    const addOption = useCallback((value, valueAux, name) => {
        dispatch({
            type: 'add',
            payload: { option: value, optionAux: valueAux, name: name },
        })
    }, [])

    const setOptions = useCallback((values, valuesAux, name) => {
        dispatch({
            type: 'setOptions',
            payload: { options: values, optionsAux: valuesAux, name: name },
        })
    }, [])

    const addSelectedOption = useCallback((value, valueAux, name) => {
        dispatch({
            type: 'addSelected',
            payload: { option: value, optionAux: valueAux, name: name },
        })
    }, [])

    const selectOption = useCallback((value, valueAux, name) => {
        dispatch({
            type: 'select',
            payload: { option: value, optionAux: valueAux, name: name },
        })
    }, [])

    const keyPress = useCallback((e, name) => {
        dispatch({
            type: 'keyPress',
            payload: { value: e, name: name },
        })
    }, [])

    const keyDown = useCallback((e, name) => {
        dispatch({
            type: 'keyDown',
            payload: { value: e, name: name },
        })
    }, [])

    const toggleDropdown = useCallback(
        (v, name) => {
            dispatch({
                type: 'dropdown',
                payload:
                    typeof v === 'boolean'
                        ? { value: v, name: name }
                        : {
                              value: !value[name].openDropdown,
                              name: name,
                          },
            })
        },
        [value]
    )

    const setValueNew = useCallback(({ value, name }) => {
        dispatch({
            type: 'value',
            payload: { value: value, name: name },
        })
    }, [])

    const setValue = useCallback((e, name, date) => {
        dispatch({
            type: 'value',
            payload: { value: date ? e : e.target.value, name: name },
        })
    }, [])

    const reset = useCallback(
        (name) => {
            dispatch({
                type: 'reset',
                payload: { defaultValue: defaultValue, name: name },
            })
        },
        [defaultValue]
    )

    const setError = useCallback((value, name) => {
        dispatch({ type: 'error', payload: { value: value, name: name } })
    }, [])

    const replaceAll = useCallback((value, name) => {
        dispatch({
            type: 'replace_all',
            payload: { value: value, name: name },
        })
    }, [])

    function unselectOption(value, valueAux, name) {
        dispatch({
            type: 'unselect',
            payload: { option: value, optionAux: valueAux, name: name },
        })
    }

    const replaceSelectedOptions = useCallback((value, name) => {
        dispatch({ type: 'replace', payload: { value: value, name: name } })
    }, [])

    const replaceValue = useCallback((value, name) => {
        dispatch({ type: 'value', payload: { value: value, name: name } })
    }, [])

    const clearSelectedOptions = useCallback((name) => {
        dispatch({
            type: 'clearSelectedOptions',
            payload: { name: name },
        })
    }, [])

    const clickRadioOption = useCallback((a, b, option, name) => {
        dispatch({ type: 'radio', payload: { name: name, option: option } })
    }, [])

    function clickCheckboxOption(a, b, option, name) {
        dispatch({ type: 'checkbox', payload: { name: name, option: option } })
    }

    const addInput = useCallback((name, type, schema, options, optionsAux, reference) => {
        dispatch({
            type: 'addInput',
            payload: {
                name,
                type,
                schema,
                options,
                optionsAux,
                reference,
            },
        })
    }, [])

    const removeInput = useCallback((name) => {
        dispatch({ type: 'removeInput', payload: { name } })
    }, [])

    return {
        value,
        addOption,
        setOptions,
        unselectOption,
        replaceValue,
        replaceSelectedOptions,
        toggleDropdown,
        selectOption,
        keyPress,
        keyDown,
        setValue,
        setValueNew,
        reset,
        setError,
        replaceAll,
        addSelectedOption,
        clearSelectedOptions,
        clickRadioOption,
        clickCheckboxOption,
        addInput,
        removeInput,
    }
}
