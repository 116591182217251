import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './LoaderArc.module.scss'
import { useToggle } from '../../../hooks/useToggle'

export const LoaderArc = React.memo(({ colors, maxSize, delay, speed }) => {
    const [isRenderActive, toggleIsRenderActive] = useToggle(false)
    const [animationPlayState, setAnimationPlayState] = useState('running')
    const intervalRef = useRef(null)
    const timeoutRefs = useRef([])

    const reload = useCallback(() => {
        timeoutRefs.current.forEach((ref) => clearTimeout(ref))
        timeoutRefs.current = []

        const startAnimation = () => {
            toggleIsRenderActive(true)
            intervalRef.current = setInterval(() => {
                toggleIsRenderActive(false)
                timeoutRefs.current.push(
                    setTimeout(() => {
                        toggleIsRenderActive(true)
                    }, 300 / speed)
                )
            }, 6400 / speed)
        }

        timeoutRefs.current.push(
            setTimeout(() => {
                startAnimation()
            }, delay || 0)
        )
    }, [toggleIsRenderActive, speed, delay])

    useEffect(() => {
        reload()
    }, [reload])

    useEffect(() => {
        if (animationPlayState === 'paused') {
            intervalRef.current && clearInterval(intervalRef.current)
            timeoutRefs.current.forEach((ref) => clearTimeout(ref))
            intervalRef.current = null
            timeoutRefs.current = []
        } else {
            setTimeout(() => {
                if (
                    !intervalRef.current &&
                    timeoutRefs.current.every((ref) => !ref)
                ) {
                    reload()
                }
            }, 300)
        }
    }, [animationPlayState, reload])

    useEffect(() => {
        document.addEventListener('visibilitychange', function () {
            if (document.visibilityState === 'hidden') {
                toggleIsRenderActive(false)
                setAnimationPlayState('paused')
            } else {
                setAnimationPlayState('running')
            }
        })
    })

    const renderCircle = (size, top, left, gradientColors, animationDelay) => (
        <div
            key={animationDelay}
            className={`${styles['loader-arc-container__circle']}`}
            style={{
                width: `calc(${size} * ${maxSize}px)`,
                height: `calc(${size} * ${maxSize}px)`,
                top: `calc(${maxSize}px * ${top})`,
                left: `calc(${maxSize}px * ${left})`,
                background: `linear-gradient(${gradientColors[0]}, ${gradientColors[1]})`,
                animation: `${
                    styles['scaleIn']
                } calc(0.3s / ${speed}) calc(${animationDelay}s / ${speed}), ${
                    styles['scaleOut']
                } calc(0.3s / ${speed}) calc(${
                    animationDelay + 4 - size
                }s / ${speed})`,
                animationFillMode: 'forwards',
            }}
        ></div>
    )

    return (
        <div className={`${styles['loader-arc-container']}`}>
            {isRenderActive && (
                <>
                    {[
                        [0.21, 0, 0],
                        [0.32, 8 / 18, 2 / 18],
                        [0.55, 18 / 18, 7 / 18],
                        [0.83, 26 / 18, 19 / 18],
                        [1, 29 / 18, 39 / 18],
                        [0.83, 26 / 18, 60 / 18],
                        [0.55, 18 / 18, 77 / 18],
                        [0.32, 8 / 18, 87 / 18],
                        [0.21, 0, 91.9 / 18],
                    ].map(([size, top, left], index) =>
                        renderCircle(
                            size,
                            top,
                            left,
                            colors[index],
                            0.3 * (9 - index)
                        )
                    )}
                </>
            )}
        </div>
    )
})
