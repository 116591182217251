import React from 'react';
import styles from './goal-value-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { formatCurrency } from '../../../../../utils/math';

export const GoalValueTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['goal-value-table-column-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '0.5rem' }}>
                <div className="col px-0">
                    <span
                        className={`${styles['goal-value-table-column-container__label']}`}
                        style={{
                            color: '#ef9e9c'
                        }}
                    >
                        {formatCurrency(el[type][0], 'EUR')}
                    </span>{' '}
                    <span
                        className={`${styles['goal-value-table-column-container__text']}`}
                        style={{
                            color: '#ef9e9c'
                        }}
                    >
                        ({`${el[type][1]}`}
                        {'%'})
                    </span>
                </div>
            </div>
            <div
                className="row mx-0 w-100"
                style={{ marginBottom: '0.5rem', height: '1rem' }}
            >
                <div className="col px-0 position-relative">
                    <div
                        className={`${styles['goal-value-table-column-container__bar']}`}
                    >
                        <div
                            className={`${styles['goal-value-table-column-container__bar__fill']}`}
                            style={{
                                width: `${el[type][1]}%`,
                                backgroundColor: '#ef9e9c'
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
});
