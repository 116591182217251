import React from 'react'
import styles from './permissions-overview.module.scss'
import icoDashboardManagement from '../../../../../../../assets/images/icoDashboardManagement.svg'
import { PermissionTree } from '../permissions-tree/permissions-tree'

const permissions = [
    {
        category: 'Dashboard Management',
        image: icoDashboardManagement,
        permissions: [
            {
                title: 'View Dashboard',
                rolesEnabled: ['Administrator', 'Viewer'],
                rolesDisabled: [],
            },
            {
                title: 'Edit Dashboard',
                rolesEnabled: ['Administrator'],
                rolesDisabled: ['Viewer'],
            },
            {
                title: 'Delete Dashboard',
                rolesEnabled: ['Administrator'],
                rolesDisabled: ['Viewer'],
            },
        ],
    },
]

export const PermissionsOverview = () => {
    return (
        <>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span className="content-title">Permissions Overview</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className="content-description">
                        Explore all permissions available and quickly discover which permissions each role has
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100 flex-grow-1" style={{ minHeight: 0 }}>
                <div className="col px-0 h-100">
                    <div className={`${styles['permissions-container']}`}>
                        {permissions &&
                            permissions.length > 0 &&
                            permissions.map((permission) => {
                                return <PermissionTree key={permission.category} {...permission} />
                            })}
                    </div>
                </div>
            </div>
        </>
    )
}
