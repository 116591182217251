import React from 'react'

export const productSalesMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    titleText: '',
    legend: [
        {
            name: 'revenue',
            color: '#20998b',
            translated: false,
        },
    ],
    type: 'Map',
    barStackedAttr: [
        {
            dataKey: 'productSales',
            gradientColors: ['199788', '26b5a4'],
            stackId: 'a',
            minPointSize: 70,
            radius: [0, 100, 100, 0],
        },
    ],
    barWidth: 20,
    categoryAxis: 'productName',
    data: [
        {
            productName: "Cut Russet Potatoes Steam N' Mash",
            productId: 8,
            productSales: 14658,
        },
        {
            productName: 'Overnight Diapers Size 6',
            productId: 15,
            productSales: 14046,
        },
        {
            productName: 'Saline Nasal Mist',
            productId: 13,
            productSales: 13275,
        },
        {
            productName: 'Green Chile Anytime Sauce',
            productId: 5,
            productSales: 12805,
        },
        {
            productName: 'Sparkling Orange Juice & Prickly Pear Beverage',
            productId: 10,
            productSales: 12333,
        },
        {
            productName: 'Pure Coconut Water With Orange',
            productId: 7,
            productSales: 12149,
        },
        {
            productName: 'Chocolate Fudge Layer Cake',
            productId: 12,
            productSales: 12035,
        },
        {
            productName: 'Fresh Scent Dishwasher Cleaner',
            productId: 14,
            productSales: 11906,
        },
        {
            productName:
                'Smart Ones Classic Favorites Mini Rigatoni With Vodka Cream Sauce',
            productId: 4,
            productSales: 11817,
        },
        {
            productName: 'Peach Mango Juice',
            productId: 11,
            productSales: 11698,
        },
    ],
    orientation: 'vertical',
    tooltipType: 'productSales',
    unitsOrValues: 'values',
    loading: true,
    categoryLabel: '',
    margin: {
        top: 5,
        left: 0,
        right: 20,
        bottom: 5,
    },
    attr: 'productSalesChart',
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
