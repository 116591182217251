import React, { useEffect } from 'react';
import styles from './rich-text-input.module.scss';
import './rich-text-input.scss';
import ReactQuill from 'react-quill';
import Scrollable from '../../scrolling/scrollable';
import MagicUrl from 'quill-magic-url';

ReactQuill.Quill.register('modules/magicUrl', MagicUrl);

const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' }
];

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
];

const StyleButton = (props) => {
    const onToggle = (e) => {
        e.preventDefault();
        props.onToggle(props.style);
    };

    let className = 'RichEditor-styleButton';
    if (props.active) {
        className += ' RichEditor-activeButton';
    }

    return (
        <span className={className} onMouseDown={onToggle}>
            {props.label}
        </span>
    );
};

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </div>
    );
};

const BlockTypeControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </div>
    );
};

export const RichTextInput = ({ isEditing, text, onSetText }) => {
    const setButtonTitle = () => {
        const toolbarElements = document.querySelectorAll(
            '.ql-toolbar button[class*="ql-"], .ql-toolbar span.ql-picker.ql-color-picker'
        );

        toolbarElements.forEach((element) => {
            if (element.tagName.toLowerCase() === 'button') {
                // Handles buttons
                const { className } = element;
                let title = className.split('ql-')[1].split(' ')[0]; // Split by 'ql-' and take the first part
                title = title.charAt(0).toUpperCase() + title.slice(1);
                const value = element.getAttribute('value');
                element.setAttribute(
                    'title',
                    `${title}${value ? `: ${value}` : ''}`
                );
            } else if (
                element.tagName.toLowerCase() === 'span' &&
                element.className.includes('ql-picker')
            ) {
                // Handles spans (color pickers)
                // get the first class in the list of classes of the span
                let title = element.className.split(' ')[0];
                // remove its ql- prefix
                title = title.split('ql-')[1];
                title = title.charAt(0).toUpperCase() + title.slice(1);
                element.setAttribute('title', `${title} picker`);
            }
        });
    };

    React.useEffect(() => {
        setButtonTitle();
    }, []);

    return (
        <div
            className={styles['rich-text-input-container']}
            style={!isEditing ? { border: 'none', padding: '0' } : {}}
        >
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0 h-100">
                    {isEditing && (
                        <ReactQuill
                            modules={{
                                magicUrl: true
                            }}
                            className={
                                !isEditing
                                    ? 'rich-text-input-container__reading-editor'
                                    : ''
                            }
                            style={{ height: '100%' }}
                            value={text}
                            onChange={onSetText}
                        />
                    )}
                    {!isEditing && (
                        <Scrollable addRightSpacing>
                            <ReactQuill
                                readOnly
                                className={
                                    !isEditing
                                        ? 'rich-text-input-container__reading-editor'
                                        : ''
                                }
                                value={text}
                                onChange={onSetText}
                            />
                        </Scrollable>
                    )}
                </div>
            </div>
        </div>
    );
};
