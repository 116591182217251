import React from 'react'
import styles from './risk-distribution-table-column.module.scss'
import { RiskDistributionTable } from '../../../risk-distribution-table/risk-distribution-table'

export const RiskDistributionTableColumn = React.memo(({ el, column }) => {
    return (
        <div
            className={`${styles['risk-distribution-table-column-container']}`}
        >
            <RiskDistributionTable
                risk1Percent={el[column.path]['risk1Percent']}
                risk2Percent={el[column.path]['risk2Percent']}
                risk3Percent={el[column.path]['risk3Percent']}
                risk4Percent={el[column.path]['risk4Percent']}
            />
        </div>
    )
})
