import React, { useEffect, useState } from 'react';
import styles from './upcoming-milestones.module.scss';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper';
import { Spinner } from '../../loading/spinner/spinner';
import icoSatisfactionSad from '../../../assets/images/icoSatisfactionSad.svg';
import icoSatisfactionHappy from '../../../assets/images/icoSatisfactionHappy.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import _ from 'lodash';
import { formatDate } from '../../../utils/date';
import Scrollable from '../../scrolling/scrollable';
import { Loader } from '../../loading/Loader/Loader';

const upcomingMilestonesMock = {
    attr: 'upcomingMilestones',
    upcomingMilestones: [
        {
            id: 1,
            date: '2021-10-10',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'overdue'
        },
        {
            id: 2,
            date: '2021-09-20',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 3,
            date: '2021-09-30',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 4,
            date: '2021-10-05',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 5,
            date: '2021-10-15',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 6,
            date: '2021-10-20',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        }
    ],
    laterOnMilestones: [
        {
            id: 1,
            date: '2021-10-10',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'overdue'
        },
        {
            id: 2,
            date: '2021-09-20',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 3,
            date: '2021-09-30',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 4,
            date: '2021-10-05',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 5,
            date: '2021-10-15',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        },
        {
            id: 6,
            date: '2021-10-20',
            name: 'Goal name',
            targetValue: '100',
            targetVariable: 'Revenue',
            goalTotal: '1000',
            status: 'onTrack'
        }
    ],
    nextMilestone: {
        days: 10,
        id: 1,
        name: 'Goal name'
    }
};

export const UpcomingMilestones = ({ isPreview, ...rest }) => {
    const { t } = useCustomTranslation();
    const { value, setLoading, setValue } = useSimpleComponent(
        upcomingMilestonesMock
    );

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div
            className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <ComponentWrapper {...value} {...rest}>
                {value.loading && <Spinner />}
                {!value.loading && (
                    <div className={`${styles.upcomingMilestonesContainer}`}>
                        <div className="row mx-0 w-100 h-100">
                            <div className="col-auto px-0 h-100">
                                <div
                                    className={`${styles.upcomingMilestonesContainer__nextMilestoneCard}`}
                                >
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0 d-flex justify-content-center align-items-end">
                                            <span
                                                className={
                                                    styles.upcomingMilestonesContainer__bigText
                                                }
                                            >
                                                10
                                            </span>
                                            <span
                                                className={
                                                    styles.upcomingMilestonesContainer__mediumText
                                                }
                                                style={{
                                                    lineHeight: '12.8rem'
                                                }}
                                            >
                                                days
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0 d-flex justify-content-center align-items-center">
                                            <span
                                                className={
                                                    styles.upcomingMilestonesContainer__mediumText
                                                }
                                            >
                                                Goal name
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-auto px-0 h-100"
                                style={{ width: '2rem' }}
                            ></div>
                            <div className="col px-0 h-100">
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col px-0 h-100 d-flex flex-column">
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={
                                                        styles.upcomingMilestonesContainer__title
                                                    }
                                                >
                                                    {t(
                                                        'components:upcomingMilestones.comingUp'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 flex-grow-1"
                                            style={{
                                                minHeight: 0
                                            }}
                                        >
                                            <div className="col px-0 h-100">
                                                <Scrollable addRightSpacing>
                                                    {_.chunk(
                                                        value.upcomingMilestones,
                                                        windowWidth > 1600
                                                            ? 2
                                                            : 1
                                                    ).map(
                                                        (milestones, index) => (
                                                            <div
                                                                className="row mx-0 w-100"
                                                                style={{
                                                                    marginBottom:
                                                                        '2rem',
                                                                    paddingTop:
                                                                        '2rem'
                                                                }}
                                                                key={index}
                                                            >
                                                                {milestones.map(
                                                                    (
                                                                        milestone,
                                                                        index2
                                                                    ) => (
                                                                        <div
                                                                            className="col px-0"
                                                                            key={
                                                                                index2
                                                                            }
                                                                        >
                                                                            <div className="row mx-0 w-100">
                                                                                {index2 !==
                                                                                    0 && (
                                                                                    <div
                                                                                        className="col-auto px-0"
                                                                                        style={{
                                                                                            width: '1rem'
                                                                                        }}
                                                                                    ></div>
                                                                                )}
                                                                                <div className="col px-0">
                                                                                    <div
                                                                                        className={
                                                                                            styles.upcomingMilestonesContainer__upcomingMilestoneCard +
                                                                                            ' ' +
                                                                                            (value
                                                                                                .nextMilestone
                                                                                                .id ===
                                                                                            milestone.id
                                                                                                ? styles.nextMilestone
                                                                                                : '')
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                styles.upcomingMilestonesContainer__status +
                                                                                                ' ' +
                                                                                                (milestone.status ===
                                                                                                'overdue'
                                                                                                    ? styles.behindSchedule
                                                                                                    : styles.onTrack)
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                className={
                                                                                                    styles.upcomingMilestonesContainer__statusText
                                                                                                }
                                                                                            >
                                                                                                {t(
                                                                                                    `components:upcomingMilestones.${milestone.status}`
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="row mx-0 w-100"
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '1rem'
                                                                                            }}
                                                                                        >
                                                                                            <div className="col px-0">
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.upcomingMilestonesContainer__cardTitle
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        milestone.name
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="row mx-0 w-100"
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '0.5rem'
                                                                                            }}
                                                                                        >
                                                                                            <div className="col px-0">
                                                                                                <div
                                                                                                    className="row mx-0 w-100"
                                                                                                    style={{
                                                                                                        marginBottom:
                                                                                                            '0.5rem'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            Target:
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardText
                                                                                                            }
                                                                                                        >
                                                                                                            €100
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row mx-0 w-100">
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            Current:
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardText
                                                                                                            }
                                                                                                            style={{
                                                                                                                color:
                                                                                                                    milestone.status ===
                                                                                                                    'overdue'
                                                                                                                        ? 'rgba(253, 153, 153, 1)'
                                                                                                                        : 'rgba(44, 217, 197, 1)'
                                                                                                            }}
                                                                                                        >
                                                                                                            €50
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            (20%)
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-auto px-0">
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: '4rem',
                                                                                                        height: '4rem'
                                                                                                    }}
                                                                                                    src={
                                                                                                        milestone.status ===
                                                                                                        'overdue'
                                                                                                            ? icoSatisfactionSad
                                                                                                            : icoSatisfactionHappy
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="row mx-0 w-100"
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '0.5rem'
                                                                                            }}
                                                                                        >
                                                                                            <div className="col px-0">
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.upcomingMilestonesContainer__cardLabel
                                                                                                    }
                                                                                                >
                                                                                                    Reach
                                                                                                    Projection:
                                                                                                </span>{' '}
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.upcomingMilestonesContainer__cardText
                                                                                                    }
                                                                                                    style={{
                                                                                                        color:
                                                                                                            milestone.status ===
                                                                                                            'overdue'
                                                                                                                ? 'rgba(253, 153, 153, 1)'
                                                                                                                : 'rgba(44, 217, 197, 1)'
                                                                                                    }}
                                                                                                >
                                                                                                    €50
                                                                                                </span>{' '}
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.upcomingMilestonesContainer__cardLabel
                                                                                                    }
                                                                                                >
                                                                                                    (20%)
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mx-0 w-100">
                                                                                            <div className="col px-0">
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.upcomingMilestonesContainer__cardDate
                                                                                                    }
                                                                                                >
                                                                                                    {formatDate(
                                                                                                        new Date(
                                                                                                            milestone.date
                                                                                                        ).toISOString()
                                                                                                    )}{' '}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {index2 ===
                                                                                    0 && (
                                                                                    <div
                                                                                        className="col-auto px-0"
                                                                                        style={{
                                                                                            width: '1rem'
                                                                                        }}
                                                                                    ></div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </Scrollable>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto px-0 h-100">
                                        <div
                                            className={`${styles.upcomingMilestonesContainer__verticalSeparator}`}
                                        ></div>
                                    </div>
                                    <div className="col px-0 h-100 d-flex flex-column">
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginBottom: '2rem'
                                            }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <span
                                                    className={
                                                        styles.upcomingMilestonesContainer__title
                                                    }
                                                >
                                                    {t(
                                                        'components:upcomingMilestones.nextMilestones'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100 flex-grow-1"
                                            style={{ minHeight: 0 }}
                                        >
                                            <div className="col px-0 h-100">
                                                <Scrollable addRightSpacing>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            {_.chunk(
                                                                value.laterOnMilestones,
                                                                windowWidth >
                                                                    1600
                                                                    ? 2
                                                                    : 1
                                                            ).map(
                                                                (
                                                                    milestones,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        className="row mx-0 w-100"
                                                                        style={{
                                                                            marginBottom:
                                                                                '2rem',
                                                                            paddingTop:
                                                                                '2rem'
                                                                        }}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {milestones.map(
                                                                            (
                                                                                milestone,
                                                                                index2
                                                                            ) => (
                                                                                <div
                                                                                    className="col px-0"
                                                                                    key={
                                                                                        index2
                                                                                    }
                                                                                >
                                                                                    <div className="row mx-0 w-100">
                                                                                        {index2 !==
                                                                                            0 && (
                                                                                            <div
                                                                                                className="col-auto px-0"
                                                                                                style={{
                                                                                                    width: '1rem'
                                                                                                }}
                                                                                            ></div>
                                                                                        )}
                                                                                        <div className="col px-0">
                                                                                            <div
                                                                                                className={`${styles.upcomingMilestonesContainer__laterOnMilestoneCard}`}
                                                                                            >
                                                                                                <div
                                                                                                    className="row mx-0 w-100"
                                                                                                    style={{
                                                                                                        marginBottom:
                                                                                                            '1rem'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardTitle
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                milestone.name
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row mx-0 w-100"
                                                                                                    style={{
                                                                                                        marginBottom:
                                                                                                            '0.5rem'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            Target:
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardText
                                                                                                            }
                                                                                                        >
                                                                                                            €100
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row mx-0 w-100"
                                                                                                    style={{
                                                                                                        marginBottom:
                                                                                                            '0.5rem'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            Reached:
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardText
                                                                                                            }
                                                                                                        >
                                                                                                            €50
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            (20%)
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row mx-0 w-100"
                                                                                                    style={{
                                                                                                        visibility:
                                                                                                            'hidden',
                                                                                                        marginBottom:
                                                                                                            '0.5rem'
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            Reached:
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardText
                                                                                                            }
                                                                                                        >
                                                                                                            €50
                                                                                                        </span>{' '}
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardLabel
                                                                                                            }
                                                                                                        >
                                                                                                            (20%)
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row mx-0 w-100">
                                                                                                    <div className="col px-0">
                                                                                                        <span
                                                                                                            className={
                                                                                                                styles.upcomingMilestonesContainer__cardDate
                                                                                                            }
                                                                                                            style={{
                                                                                                                color: 'rgba(44, 60, 141, 0.4)'
                                                                                                            }}
                                                                                                        >
                                                                                                            {formatDate(
                                                                                                                new Date(
                                                                                                                    milestone.date
                                                                                                                ).toISOString()
                                                                                                            )}{' '}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {index2 ===
                                                                                            0 && (
                                                                                            <div
                                                                                                className="col-auto px-0"
                                                                                                style={{
                                                                                                    width: '1rem'
                                                                                                }}
                                                                                            ></div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </Scrollable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ComponentWrapper>
        </div>
    );
};
