import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'

import { getRoundedValue } from '../../../../../utils/math'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useCursorTrackingProvider } from '../../../../../providers/cursor-tracking-context'
import { getValueInString } from '../../../../../utils/unit'

export const RegionGeoSalesTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    const { position } = useCursorTrackingProvider()
    return (
        <div
            style={{
                transition: 'all 0.3s ease-in-out',
                position: 'fixed',
                left: position.x,
                top: position.y,
            }}
        >
            <TooltipContainer color={props.item.hoveredPiece.color}>
                <TooltipHeaderRow title={props.item.hoveredPiece.name} />
                <Separator />
                <TooltipContentRow title={t('tooltips:content.unitsSold')} value={getValueInString(props.item.hoveredPiece.value, 'units') || 0} />
            </TooltipContainer>
        </div>
    )
})
