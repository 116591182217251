import React from 'react'
import icoEmptyList from '../../../assets/images/iconEmptyList.svg'
import styles from './empty-list.module.scss'
import { PrimaryButton } from '../../buttons/primary-button/primary-button'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const EmptyList = ({ text, onClick }) => {
    const { t } = useCustomTranslation()
    return (
        <div className={styles['empty-list-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem', marginTop: '10rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <img src={icoEmptyList} className={styles['empty-list-container__icon']} />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '4rem' }}>
                <div className="col px-0 d-flex justify-content-center">
                    <span className={styles['empty-list-container__text']}>{text}</span>
                </div>
            </div>
            <div className="row mx-0 w-100 d-flex justify-content-center">
                <div className="col-auto px-0">
                    <PrimaryButton text={t('buttons:createNewSegment')} onClick={onClick} />
                </div>
            </div>
        </div>
    )
}
