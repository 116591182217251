import { useCallback, useReducer } from 'react';

const ACTIONS = {
  SET_ROWS: 'setRows',
  CLEAR: 'clear',
  CLEAR_ROW: 'clearRow',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_ROWS:
      return [...action.payload];
    case ACTIONS.CLEAR_ROW:
      state.map((el) => {
        Object.entries(el).map((el2) => {
          if (el2[0] === action.payload) {
            el[action.payload] = '';
          }
        });
      });
      return [...state];
    case ACTIONS.CLEAR:
      return [...action.payload];
  }
}

export function useRows(defaultValue) {
  const [rows, dispatch] = useReducer(reducer, defaultValue);

  const setRows = useCallback((value) => {
    dispatch({ type: 'setRows', payload: value });
  }, []);

  const clearRow = useCallback((name) => {
    dispatch({ type: 'clearRow', payload: name });
  }, []);

  const clear = useCallback(() => {
    dispatch({ type: 'clear', payload: defaultValue });
  }, [defaultValue]);

  return {
    rows,
    setRows,
    clear,
    clearRow,
  };
}
