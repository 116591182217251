import React from 'react'
import { GeneralTable } from '../general-table/general-table'
import { aliasListMock } from './mock'
import { ModelAliasService } from '../../../temp/test'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const AliasList = React.memo(() => {
    const { t } = useCustomTranslation()

    return (
        <GeneralTable
            title={t(`components:${aliasListMock.attr}.title`)}
            attr={aliasListMock.attr}
            columns={[
                {
                    path: 'id',
                    backendPath: 'id',
                    show: false,
                },
                {
                    path: 'modelName',
                    backendPath: 'model_name',
                    show: true,
                },
                {
                    path: 'alias',
                    backendPath: 'alias',
                    show: true,
                },
                {
                    path: 'level',
                    backendPath: 'level',
                    show: true,
                },
                {
                    path: 'action',
                    backendPath: 'action',
                    show: true,
                },
            ]}
            getEndpoint={ModelAliasService.getApiModelAlias}
            addTitle={t(`components:${aliasListMock.attr}.addTitle`)}
            addSuccessText={t(`components:${aliasListMock.attr}.addSuccessText`)}
            addEndpoint={ModelAliasService.postApiModelAlias}
            addBodyInputs={{
                modelName: {
                    type: 'selectSingle',
                    path: 'modelName',
                    name: 'modelName',
                    backendPath: 'model_name',
                    showOptions: true,
                    options: ['Product', 'ProductVersion', 'TargetSegment', 'Region'],
                    optionsAux: ['Product', 'ProductVersion', 'TargetSegment', 'Region'],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    hideOptionsAux: true,
                    reference: React.createRef(),
                },
                alias: {
                    name: 'alias',
                    path: 'alias',
                    backendPath: 'alias',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal',
                },
                level: {
                    name: 'level',
                    path: 'level',
                    backendPath: 'level',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal',
                },
            }}
            updateTitle={t(`components:${aliasListMock.attr}.updateTitle`)}
            updateSuccessText={t(`components:${aliasListMock.attr}.updateSuccessText`)}
            updateEndpoint={ModelAliasService.patchApiModelAlias}
            updateIds={[
                {
                    path: 'modelName',
                    backendPath: 'modelName',
                },
                {
                    path: 'level',
                    backendPath: 'level',
                },
            ]}
            updateBodyInputs={{
                alias: {
                    name: 'alias',
                    path: 'alias',
                    backendPath: 'alias',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal',
                },
            }}
            deleteIds={[
                {
                    path: 'id',
                    backendPath: 'id',
                },
            ]}
            deleteEndpoint={ModelAliasService.deleteApiModelAlias}
            deleteTitle={t(`components:${aliasListMock.attr}.deleteTitle`)}
            deleteDescription={t(`components:${aliasListMock.attr}.deleteDescription`)}
            deleteSuccessText={t(`components:${aliasListMock.attr}.deleteSuccessText`)}
        />
    )
})
