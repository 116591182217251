import React, { useCallback } from 'react';
import { GeneralTable } from '../general-table/general-table';
import { stakeholderListMock } from './mock';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useFilter } from '../../../providers/filter-context';
import { ClientAccountService } from '../../../temp/test';

export const StakeholderList = () => {
    const { t } = useCustomTranslation();
    const [clientAccountId, setClientAccountId] = React.useState(undefined);
    const { filter } = useFilter();

    const getClientAccountId = useCallback(async () => {
        try {
            const backData = await ClientAccountService.getApiClientAccount({});
            const filteredData = backData.filter((e) => {
                return (
                    e.product_id === parseInt(filter.productId) &&
                    e.client_id === parseInt(filter.hospitalId)
                );
            });
            if (filteredData.length > 0) {
                return filteredData[0].id;
            } else if (filteredData.length === 0) {
                const result =
                    await ClientAccountService.postApiClientAccountUpsert({
                        requestBody: {
                            product_id: filter.productId,
                            client_id: filter.hospitalId
                        }
                    });
                return result.id;
            }
        } catch (err) {
            console.log(err);
        }
    }, [filter]);

    return (
        <GeneralTable
            title={t(`components:${stakeholderListMock.attr}.title`)}
            attr={stakeholderListMock.attr}
            columns={[
                {
                    path: 'id',
                    backendPath: 'id',
                    show: false
                },
                {
                    path: 'mdmId',
                    backendPath: 'mdm_id',
                    show: true
                },
                {
                    path: 'name',
                    backendPath: 'name',
                    show: true
                },
                {
                    path: 'spec',
                    backendPath: 'spec',
                    show: true
                },
                {
                    path: 'why',
                    backendPath: 'why',
                    show: true
                },
                {
                    path: 'opportunity',
                    backendPath: 'opportunity',
                    show: true
                },
                {
                    path: 'speaker',
                    backendPath: 'is_speaker',
                    show: true
                },
                {
                    path: 'action',
                    show: true
                }
            ]}
            getEndpoint={
                ClientAccountService.getApiClientAccountStakeholdersGetByParams
            }
            getConfig={{
                clientId: filter.hospitalId,
                productId: filter.productId
            }}
            addTitle={t(`components:${stakeholderListMock.attr}.addTitle`)}
            addSuccessText={t(
                `components:${stakeholderListMock.attr}.addSuccessText`
            )}
            addEndpoint={
                ClientAccountService.postApiClientAccountStakeholdersUpsert
            }
            addBodyStaticInputs={[
                {
                    value: getClientAccountId,
                    backendPath: 'client_account_id'
                }
            ]}
            addBodyInputs={{
                mdmId: {
                    name: 'mdmId',
                    path: 'mdmId',
                    backendPath: 'mdm_id',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                name: {
                    name: 'name',
                    path: 'name',
                    backendPath: 'name',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                spec: {
                    name: 'spec',
                    path: 'spec',
                    backendPath: 'spec',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                why: {
                    name: 'why',
                    path: 'why',
                    backendPath: 'why',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                opportunity: {
                    name: 'opportunity',
                    path: 'opportunity',
                    backendPath: 'opportunity',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                speaker: {
                    type: 'radio',
                    name: 'speaker',
                    path: 'speaker',
                    backendPath: 'is_speaker',
                    direction: 'horizontal',
                    options: [
                        {
                            name: 'yes',
                            label: t(`inputs:yes`),
                            disabled: false,
                            blocked: false
                        },
                        {
                            name: 'no',
                            label: t(`inputs:no`),
                            disabled: false,
                            blocked: false
                        }
                    ],
                    selectedOption: 'yes',
                    value: 'yes'
                }
            }}
            updateTitle={t(
                `components:${stakeholderListMock.attr}.updateTitle`
            )}
            updateSuccessText={t(
                `components:${stakeholderListMock.attr}.updateSuccessText`
            )}
            updateEndpoint={
                ClientAccountService.postApiClientAccountStakeholdersUpsert
            }
            updateBodyStaticInputs={[
                {
                    path: 'id',
                    backendPath: 'id'
                }
            ]}
            updateBodyInputs={{
                mdmId: {
                    name: 'mdmId',
                    path: 'mdmId',
                    backendPath: 'mdm_id',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                name: {
                    name: 'name',
                    path: 'name',
                    backendPath: 'name',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                spec: {
                    name: 'spec',
                    path: 'spec',
                    backendPath: 'spec',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                why: {
                    name: 'why',
                    path: 'why',
                    backendPath: 'why',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                opportunity: {
                    name: 'opportunity',
                    path: 'opportunity',
                    backendPath: 'opportunity',
                    type: 'type',
                    value: '',
                    error: '',
                    hidden: false,
                    state: 'normal'
                },
                speaker: {
                    type: 'radio',
                    name: 'speaker',
                    path: 'speaker',
                    backendPath: 'is_speaker',
                    direction: 'horizontal',
                    options: [
                        {
                            name: 'yes',
                            label: t(`inputs:yes`),
                            disabled: false,
                            blocked: false
                        },
                        {
                            name: 'no',
                            label: t(`inputs:no`),
                            disabled: false,
                            blocked: false
                        }
                    ],
                    selectedOption: 'yes',
                    value: 'yes'
                }
            }}
            deleteIds={[
                {
                    path: 'id',
                    backendPath: 'id'
                }
            ]}
            deleteEndpoint={
                ClientAccountService.deleteApiClientAccountStakeholders
            }
            deleteTitle={t(
                `components:${stakeholderListMock.attr}.deleteTitle`
            )}
            deleteDescription={t(
                `components:${stakeholderListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${stakeholderListMock.attr}.deleteSuccessText`
            )}
        />
    );
};
