import React from 'react'
import { getDots } from '../../../../../utils/string'
import { ReactComponent as IcoDown } from '../../assets/images/ico-down.svg'
import { ReactComponent as IcoUp } from '../../assets/images/ico-up.svg'
import styles from './trend-table-column.module.scss'

export const TrendTableColumn = React.memo(({ el, column }) => {
    return (
        <div className={`${styles['trend-table-column-container']}`}>
            {el[column.path] > 0 && (
                <span
                    className={`${styles['trend-table-column-container__content']}`}
                    style={{
                        color: '#34b53a',
                    }}
                >
                    +{' '}
                </span>
            )}
            {el[column.path] < 0 && (
                <span
                    className={`${styles['trend-table-column-container__content']}`}
                    style={{
                        color: '#df0000',
                    }}
                >
                    -{' '}
                </span>
            )}
            <span
                className={`${styles['trend-table-column-container__content']}`}
                style={
                    el[column.path] > 0
                        ? {
                              color: '#34b53a',
                          }
                        : el[column.path] < 0
                        ? {
                              color: '#df0000',
                          }
                        : {}
                }
            >
                {Math.abs(el[column.path]).toString().substring(0, 100) +
                    getDots(el[column.path].toString())}
                %
            </span>
            {el[column.path] > 0 && (
                <IcoUp
                    className={`${styles['trend-table-column-container__sentiment-arrow']}`}
                />
            )}
            {el[column.path] < 0 && (
                <IcoDown
                    className={`${styles['trend-table-column-container__sentiment-arrow']}`}
                />
            )}
        </div>
    )
})
