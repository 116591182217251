import React from 'react';
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow
} from '../../../..';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const GoalTimelineTooltip = React.memo((props) => {
    const { t } = useCustomTranslation();

    return (
        <TooltipContainer color={'#adcceb'}>
            <TooltipHeaderRow title={props.item.name} />
            <Separator />
            <TooltipContentRow
                title={'Start Date'}
                value={props.item.startDate}
            />
            <TooltipContentRow title={'End Date'} value={props.item.endDate} />
        </TooltipContainer>
    );
});
