import React from 'react'

export const teamInformationMock = {
    responsibleMembers: [],
    teamName: '',
    attr: 'teamInformationCard',
    regions: [],
    settings: {
        view: {
            options: [],
        },
        options: {
            options: [],
        },
        reference: React.createRef(),
    },
    disabled: true,
    loading: true,
}
