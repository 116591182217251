import React from 'react'
import styles from './table-type-2-text.module.scss'

export const TableType2Text = React.memo(({ column }) => {
    return (
        <span
            className={`${styles['table-type-2-container-text']}`}
            style={{
                display: 'block',
                marginBottom: '1em',
            }}
        >
            {column.path.toUpperCase()}
        </span>
    )
})
