import React from 'react'
import PropTypes from 'prop-types'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useDashboardSettings } from '../../../../../providers/dashboard-settings-context'
import { ActionBar } from '../../../../actions/action-bar/action-bar'
import icoEdit from '../../../../../assets/images/ico-edit.svg'
import { useLocation } from 'react-router-dom'

/**
 * Header present in published dashboards
 */

export const DefaultHeader = React.memo(({ dashboardTitle }) => {
    const { t } = useCustomTranslation()
    const location = useLocation()
    const { showEditDashboard, toggleIsEdit } = useDashboardSettings()
    return (
        <div className="row w-100 dashboard-header-row d-flex justify-content-between">
            <div className="col px-0 d-flex justify-content-between align-items-center">
                <span className="me-4 reveal">
                    {(dashboardTitle === 'dashboard' ||
                        dashboardTitle === 'globaldashboard' ||
                        dashboardTitle === 'clientoverview' ||
                        dashboardTitle === 'clientdetails' ||
                        dashboardTitle === 'productoverview' ||
                        dashboardTitle === 'prospects') &&
                        t(`pages:customDashboard.${dashboardTitle}`)}
                    {dashboardTitle !== 'dashboard' &&
                        dashboardTitle !== 'globaldashboard' &&
                        dashboardTitle !== 'clientoverview' &&
                        dashboardTitle !== 'clientdetails' &&
                        dashboardTitle !== 'prospects' &&
                        dashboardTitle !== 'productoverview' &&
                        dashboardTitle}
                </span>
            </div>
            {(showEditDashboard || location.pathname === '/dashboard') && (
                <div className="col-auto px-0 reveal">
                    <ActionBar
                        actions={[
                            {
                                text: t('layout:topbar.editpage'),
                                icon: icoEdit,
                                onClick: () => {
                                    toggleIsEdit()
                                },
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    )
})

DefaultHeader.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
}
