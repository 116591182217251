import { useCallback, useMemo, useReducer } from 'react';
import { weakPasswordOptions } from '../schemas/fieldSchemas';
import _, { isNumber } from 'lodash';
import { i18n } from '../configs/i18nextConf';

const ACTIONS = {
    CHANGE_VALUE: 'value',
    REPLACE_VALUE: 'replace',
    CHANGE_ERROR: 'error',
    RESET: 'reset',
    CLICK_HIDDEN_ICON: 'clickHiddenIcon'
};

// Utility function to check if the string is numeric
const isNumeric = (str) => {
    return (
        typeof str === 'string' &&
        (str.trim() === '' || (!isNaN(str) && !isNaN(parseFloat(str))))
    );
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.CLICK_HIDDEN_ICON:
            return {
                ...state,
                hidden: !state.hidden
            };
        case ACTIONS.CHANGE_VALUE:
        case ACTIONS.REPLACE_VALUE:
            const { newState, newStrength, error } = validateField(
                state,
                action.payload
            );
            if (state.isNumber) {
                if (isNumeric(action.payload)) {
                    return {
                        ...state,
                        value: action.payload,
                        state: newState,
                        error: error,
                        strength: newStrength
                    };
                } else
                    return {
                        ...state,
                        error: ''
                    };
            } else {
                return {
                    ...state,
                    strength: newStrength,
                    value: action.payload,
                    state: newState,
                    error: error
                };
            }
        case ACTIONS.CHANGE_ERROR:
            return {
                ...state,
                state: action.payload ? 'error' : 'normal',
                error: action.payload || ''
            };
        case ACTIONS.RESET:
            return { ...action.payload };
        default:
            return state;
    }
}

function validateField(state, value) {
    let newState = 'normal';
    let error = undefined;
    let newStrength = undefined;

    if (_.has(state, 'schema') && state.schema) {
        const result = state.schema.validate(value, { abortEarly: false });
        if (result.error) {
            const errorDetails = result.error.details[0];
            const errorType = errorDetails.type;
            const errorConfig =
                errorType.includes('string') || errorType.includes('number')
                    ? { limit: errorDetails.context.limit }
                    : {};
            const resultError = getErrorKey(errorType);
            error = i18n.t(resultError, errorConfig);
            newState = 'error';
        }
    }

    if (_.has(state, 'strengthRules') && state.strengthRules && value) {
        Object.entries(state.strengthRules).forEach(([key, rules]) => {
            rules.forEach((rule) => {
                const result = rule.validate(value);
                if (!result.error) {
                    newStrength = key;
                }
            });
        });
    }

    return { newState, error, newStrength };
}

function getErrorKey(errorType) {
    const passwordErrorKeys = {
        'passwordComplexity.tooShort': `passwordComplexity.tooShort.${weakPasswordOptions.min}`,
        'passwordComplexity.tooLong': `passwordComplexity.tooLong.${weakPasswordOptions.max}`,
        'passwordComplexity.lowercase': `passwordComplexity.lowercase.${weakPasswordOptions.lowerCase}`,
        'passwordComplexity.uppercase': `passwordComplexity.uppercase.${weakPasswordOptions.upperCase}`
    };

    return passwordErrorKeys[errorType] || errorType;
}

export function useTextInputAlternate({
    name,
    schema = undefined,
    isPassword = false,
    isNumber = false,
    ...rest
}) {
    const defaultValue = useMemo(() => {
        return {
            name: name,
            value: '',
            error: '',
            state: 'normal',
            password: isPassword ? true : false,
            hidden: isPassword ? true : false,
            isNumber: isNumber ? true : false,
            schema: schema,
            ...rest
        };
    }, [name, isPassword, schema, isNumber, rest]);
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const setValue = useCallback((e) => {
        dispatch({ type: ACTIONS.CHANGE_VALUE, payload: e.target.value });
    }, []);

    const replaceValue = useCallback((newValue) => {
        dispatch({ type: ACTIONS.REPLACE_VALUE, payload: newValue });
    }, []);

    const setError = useCallback((error) => {
        dispatch({ type: ACTIONS.CHANGE_ERROR, payload: error });
    }, []);

    const reset = useCallback(() => {
        dispatch({ type: ACTIONS.RESET, payload: defaultValue });
    }, [defaultValue]);

    const handleClickHiddenIcon = useCallback(() => {
        dispatch({ type: ACTIONS.CLICK_HIDDEN_ICON, payload: defaultValue });
    }, [defaultValue]);

    const validate = useCallback(() => {
        return validateField(state, state.value, dispatch);
    }, [state]);

    return {
        ...state,
        setValue,
        setError,
        replaceValue,
        reset,
        handleClickHiddenIcon,
        validate
    };
}
