/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackgroundJobSchema } from '../models/BackgroundJobSchema';
import type { RqSchedulerJobSchema } from '../models/RqSchedulerJobSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class JobsService {
    /**
     * return list of all jobs (current and past) for all users
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobs(): CancelablePromise<Array<BackgroundJobSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs',
        });
    }
    /**
     * Check for shortages
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsCheckForShortages(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/check_for_shortages',
        });
    }
    /**
     * return list of all jobs current cron jobs
     * @returns RqSchedulerJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsCron(): CancelablePromise<Array<RqSchedulerJobSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/cron',
        });
    }
    /**
     * Cancels cron jobs
     * @returns any call successful
     * @throws ApiError
     */
    public static deleteApiBackofficeJobsCronCancelAll(): CancelablePromise<{
        status?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/jobs/cron/cancel/all',
        });
    }
    /**
     * Cancels cron jobs
     * @returns any call successful
     * @throws ApiError
     */
    public static deleteApiBackofficeJobsCronCancel({
        jobId,
    }: {
        /**
         * job_id.
         */
        jobId: string,
    }): CancelablePromise<{
        status?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/jobs/cron/cancel/{job_id}',
            path: {
                'job_id': jobId,
            },
        });
    }
    /**
     * return list of all jobs current cron jobs
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsCronUpdateWeather(): CancelablePromise<{
        status?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/cron/update_weather',
        });
    }
    /**
     * get failed jovs
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsFailedJobs(): CancelablePromise<Array<{
        exc_info?: string;
        job_id?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/failed_jobs',
        });
    }
    /**
     * Fills empty addresses on competitors table
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsFillAddresses(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/fill_addresses',
        });
    }
    /**
     * Fills frequently bought together table
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsFillFrequentlyBoughtTogether(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/fill_frequently_bought_together',
        });
    }
    /**
     * Fills recommendations
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsFillRecommendations(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/fill_recommendations',
        });
    }
    /**
     * creates process to calc geo info and update db
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsGeoInfo(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/geo_info',
        });
    }
    /**
     * return list of active jobs for current user
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsMyActiveJobs(): CancelablePromise<Array<BackgroundJobSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/my-active-jobs',
        });
    }
    /**
     * products_monthly_forecast
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsProductsMonthlyForecast(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/products_monthly_forecast',
        });
    }
    /**
     * creates process test current data on created churn model
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsUpdateClientRisk(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/update_client_risk',
        });
    }
    /**
     * Updates competitors and points of interest
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsUpdateCompetitors(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/update_competitors',
        });
    }
    /**
     * Manually updates weather
     * @returns BackgroundJobSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeJobsUpdateWeather(): CancelablePromise<BackgroundJobSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/jobs/update_weather',
        });
    }
}
