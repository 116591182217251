import React from 'react'

export const prospectGeoMock = {
    hoverStyle: '',
    desc: '',
    originalRisk1Data: {
        type: 'FeatureCollection',
        features: [],
    },
    originalRisk2Data: {
        type: 'FeatureCollection',
        features: [],
    },
    originalRisk3Data: {
        type: 'FeatureCollection',
        features: [],
    },
    originalRisk4Data: {
        type: 'FeatureCollection',
        features: [],
    },
    originalCompetitionData: {
        type: 'FeatureCollection',
        features: [],
    },
    risk1Data: {
        type: 'FeatureCollection',
        features: [],
    },
    risk2Data: {
        type: 'FeatureCollection',
        features: [],
    },
    risk3Data: {
        type: 'FeatureCollection',
        features: [],
    },
    risk4Data: {
        type: 'FeatureCollection',
        features: [],
    },
    competitionData: {
        type: 'FeatureCollection',
        features: [],
    },
    selectedData: {
        type: 'FeatureCollection',
        features: [],
    },
    titleText: '',
    legend: [
        {
            name: 'prospects',
            color: '#34b1eb',
            translated: false,
        },
    ],
    type: 'normal',
    tooltipType: 'competitorGeo',
    attr: 'prospectGeoChart',
    update: 1,
    disabled: true,
    startingPosition: {
        lat: 38.712458,
        lon: -9.140459,
        zoom: 14,
    },
    minScale: 2,
    scale: 2,
    minZoom: 9,
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 38.736946,
        longitude: -9.142685,
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
    },
    interactiondisabled: true,
    showPopup: false,
    selectedEl: {},
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: '',
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
        viewOptions: {
            ratioOptions: {
                options: [],
                selectedOption: '',
            },
            checkboxOptions: {
                options: [],
                selectedOptions: [],
            },
        },
    },
    loading: true,
    noData: true,
    flyEnabled: true,
}
