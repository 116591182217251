import styles from './user-role-management.module.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { LoaderForeground, RoleList, Spinner } from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Dashboard containing information about user roles
 */

const AdministrationUserRoleManagement = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    return (
        <>
            {loading && <LoaderForeground />}

            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['administration-role-management-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t(
                                    'pages:back-office.user-role-management.title'
                                )}
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ height: '45rem' }}
                        >
                            <div className="col px-0 h-100">
                                <RoleList />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default AdministrationUserRoleManagement;

AdministrationUserRoleManagement.propTypes = {};
