import React, { useCallback } from 'react';
import { ComponentWrapper } from '../../dashboard-component-configuration/component-wrapper/component-wrapper';
import { CustomPieChart } from '../../charts/custom-pie-chart/custom-pie-chart';
import { useToggle } from '../../../hooks/useToggle';
import { Loader } from '../../loading/Loader/Loader';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { Spinner } from '../../loading/spinner/spinner';

const subGoalPartitionMock = {
    attr: 'subGoalPartition',
    type: 'type-1',
    donutAttr: [
        {
            dataKey: 'data1',
            valueKey: 'value',
            nameKey: 'key1'
        }
    ],
    donutData: {
        data1: [
            {
                value: 7,
                key1: 'Organization 1',
                color: '#b5c7eb'
            },
            {
                value: 9,
                key1: 'Organization 2',
                color: '#e6a773'
            },
            {
                value: 5,
                key1: 'Organization 3',
                color: '#c2b87d'
            },
            {
                value: 3,
                key1: 'Organization 4',
                color: '#d6a6de'
            }
        ]
    }
};

export const SubGoalPartition = ({ isPreview, ...rest }) => {
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = React.useState('');
    const {
        value,
        setLoading,
        setValue: setData
    } = useSimpleComponent(subGoalPartitionMock);

    return (
        <div
            className={isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'}
            style={{ zIndex: 'inherit' }}
        >
            <ComponentWrapper {...value} {...rest}>
                {value.loading && <Spinner />}
                {!value.loading && (
                    <>
                        <div className="row mx-0 w-100 position-relative h-100">
                            <CustomPieChart
                                {...value}
                                data={value.donutData}
                                isName
                                isDonut
                            />
                        </div>
                    </>
                )}
            </ComponentWrapper>
        </div>
    );
};
