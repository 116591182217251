import React from 'react';
import styles from './segment-counter.module.scss';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const SegmentCounter = React.memo(
    ({ totalCount, lastMonthCount, lastCreatedDate }) => {
        const { t } = useCustomTranslation();
        return (
            <div className={`${styles['segment-counter-container']}`}>
                <div className="row mx-0 w-100 flex-grow-1">
                    <div className="col px-0">
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-start">
                                <span
                                    className={`${styles['segment-counter-container__sub-title']}`}
                                >
                                    {t('components:segmentCounterCard.title')}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-start">
                                <span
                                    className={`${styles['segment-counter-container__title']}`}
                                >
                                    {totalCount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 w-100 flex-grow-1">
                    <div className="col px-0">
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-start">
                                <span
                                    className={`${styles['segment-counter-container__sub-title']}`}
                                >
                                    {t(
                                        'components:segmentCounterCard.lastMonth'
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div className="col px-0 d-flex justify-content-start">
                                <span
                                    className={`${styles['segment-counter-container__title']}`}
                                >
                                    {lastMonthCount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='row mx-0 w-100 flex-grow-1'>
						<div className='col px-0 d-flex justify-content-center'>
							<span
								className={`${styles['segment-counter-container__sub-sub-title']}`}
							>
								19 LAST MONTH
							</span>
						</div>
					</div> */}
                <div className="row mx-0 w-100">
                    <div className="col px-0 d-flex justify-content-start">
                        <div>
                            <span
                                className={`${styles['segment-counter-container__content-aux']}`}
                            >
                                {t(
                                    'components:segmentCounterCard.lastSegmentCreated'
                                )}
                            </span>
                            <span> </span>
                            <span
                                className={`${styles['segment-counter-container__content']}`}
                            >
                                {lastCreatedDate ? lastCreatedDate : '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
