import styles from './config-authentication.module.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { DefinitionsService } from '../../../temp/test';
import {
    LoaderForeground,
    PrimaryButton,
    Spinner,
    TerciaryButton,
    ToggleButton
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { success } from '../../../components/notifications/notification/notification';

/**
 * Provides information about competitor configuration
 */

const ConfigAuthentication = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();

    const [loading, toggleLoading] = useToggle(true);
    const [twoFactorInput, toggleTwoFactorInput] = useToggle(false);

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    useEffect(() => {
        async function fill() {
            try {
                toggleLoading(true);
                const twoFactorResult =
                    await DefinitionsService.getApiBackofficeSettingsTwoFaEnabled();
                if (isMounted.current) {
                    toggleTwoFactorInput(twoFactorResult.two_fa_enabled);
                    toggleLoading(false);
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [toggleTwoFactorInput, isMounted, toggleLoading]);

    const handleSaveDefinitions = async () => {
        try {
            await DefinitionsService.postApiBackofficeSettingsTwoFaEnabled({
                requestBody: { two_fa_enabled: twoFactorInput }
            });
            success({ text: t('notifications:saved') });
        } catch (err) {
            errorHandler(err);
        }
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['back-office-config-authentication-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                {t('pages:administration.back-office.title')}
                            </div>
                        </div>
                        <div className="row mx-0 w-100">
                            <div
                                className={`col p-0 h-auto d-flex flex-column justify-content-start align-items-center ${styles['back-office-config-authentication-container__content-wrapper']}`}
                            >
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100">
                                            <div
                                                className={`col ${styles['back-office-config-authentication-container__content']}`}
                                            >
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-authentication-container__content-wrapper__title-container']}`}
                                                >
                                                    <div className="col px-0 d-flex justify-content-start align-items-center">
                                                        <span
                                                            className={`${styles['back-office-config-authentication-container__content-wrapper__title-container__title']}`}
                                                        >
                                                            {t(
                                                                'pages:administration.back-office.sections.authentication'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="col-2 px-0 d-flex justify-content-end align-items-center">
                                                        <TerciaryButton
                                                            text={t(
                                                                'buttons:save'
                                                            )}
                                                            onClick={
                                                                handleSaveDefinitions
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <hr
                                                    className={`${styles['back-office-config-authentication-container__separator']}`}
                                                />
                                                <div
                                                    className={`row mx-0 w-100 ${styles['back-office-config-authentication-container__definitions-container']} flex-grow-1`}
                                                >
                                                    <div className="col px-0">
                                                        <div
                                                            className={`row mx-0 w-100 ${styles['back-office-config-authentication-container__input-container']}`}
                                                        >
                                                            <div className="col-8 px-0">
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['back-office-config-authentication-container__input-container__input-title']}`}
                                                                        >
                                                                            {t(
                                                                                `inputs:twoFactorEnabled.title`
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-0 w-100">
                                                                    <div className="col px-0">
                                                                        <span
                                                                            className={`${styles['back-office-config-authentication-container__input-container__input-desc']}`}
                                                                        >
                                                                            {t(
                                                                                `inputs:twoFactorEnabled.text`
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col px-0 d-flex justify-content-end align-items-center">
                                                                <ToggleButton
                                                                    bool={
                                                                        twoFactorInput
                                                                    }
                                                                    onClick={
                                                                        toggleTwoFactorInput
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default ConfigAuthentication;

ConfigAuthentication.propTypes = {};
