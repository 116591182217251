import React from 'react'
import styles from './create-segment-options-modal.module.scss'

import icoAiSegments from '../../../assets/images/icoAIColor.svg'
import icoDemographicSegments from '../../../assets/images/icoDemographicSegments.svg'
import icoBehaviouralSegments from '../../../assets/images/icoBehaviouralSegments.svg'
import icoMixSegments from '../../../assets/images/icoMixSegments.svg'
import icoHandPickSegments from '../../../assets/images/icoHandPickSegments.svg'
import { Modal } from '../modal/modal'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

const cards = [
    {
        value: 'demographic',
        icon: icoDemographicSegments,
    },
    {
        value: 'behavioural',
        icon: icoBehaviouralSegments,
    },
    {
        value: 'mix',
        icon: icoMixSegments,
    },
    {
        value: 'handPick',
        icon: icoHandPickSegments,
    },
    {
        value: 'ai',
        icon: icoAiSegments,
    },
]

const renderCard = (title, description, icon, onClick, index) => {
    return (
        <div
            className={`${styles['create-segment-options-modal-container__card']}`}
            onClick={onClick}
            key={title}
            style={{ animation: `fadeIn 0.3s forwards ${index * 0.3}s`, opacity: 0 }}
        >
            <div className="row mx-0 w-100">
                <div className="col-auto px-0 d-flex justify-content align-items-center">
                    <img src={icon} className={styles['create-segment-options-modal-container__card__icon']} />
                </div>
                <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                <div className="col px-0" style={{ minWidth: 0 }}>
                    <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                        <div className="col px-0">
                            <span className={styles['create-segment-options-modal-container__card__title']}>{title}</span>
                        </div>
                    </div>
                    <div className="row mx-0 w-100">
                        <div className="col px-0">
                            <span className={styles['create-segment-options-modal-container__card__description']}>{description}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CreateSegmentOptionsModal = ({ onCloseModal, onClickOption }) => {
    const { t } = useCustomTranslation()
    return (
        <Modal title={'Create Segment'} onToggleModal={onCloseModal}>
            <div style={{ width: '60rem' }}>
                {cards &&
                    cards.map((card, index) =>
                        renderCard(
                            t('pages:segmentAnalysis.createSegment.' + card.value + '.title'),
                            t('pages:segmentAnalysis.createSegment.' + card.value + '.description'),
                            card.icon,
                            () => onClickOption(card.value),
                            index
                        )
                    )}
            </div>
        </Modal>
    )
}
