import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import icoDelete from '../../../assets/images/ico-delete.svg';
import { errorHandler } from '../../../utils/api';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { segmentListMock } from './mock';
import { verifyComponent } from '../../../utils/component.js';
import { SegmentationService } from '../../../temp/test';
import { formatDate } from '../../../utils/date';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { Modal } from '../../modals/modal/modal';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
//import { formatDate } from '../../../helpers/date';

/**
 * Lists all the users in a table
 */

export const SegmentList = React.memo(
    ({ onSelectSegment, segmentId, parentSegmentId }) => {
        const isMounted = useIsMounted();
        const [modal, toggleModal] = useToggle(false);
        const [modalType, setModalType] = useState('');
        const [selectedSegmentId, setSelectedSegmentId] = useState('');
        const { t } = useCustomTranslation();
        const {
            value: data,
            toggleComponentSettings,
            pageChange: dataPageChange,
            sort: dataSort,
            selectAll: dataSelectAll,
            unselectAll: dataUnselectAll,
            setLoading,
            setValue,
            clickActionIcon
        } = useTable(segmentListMock, modal, '');

        const getSegments = useCallback(async () => {
            const dataClone = _.cloneDeep(segmentListMock);
            try {
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(true);
                }
                dataClone.data.length = 0;
                dataClone.sortedData.length = 0;
                const results = await SegmentationService.getApiSegmentation();
                results.map((el) => {
                    if (
                        parseInt(parentSegmentId) ===
                            parseInt(el.parent_group_id) ||
                        (segmentId &&
                            segmentId.toString() === el.id.toString()) ||
                        (!segmentId && !parentSegmentId)
                    )
                        dataClone.data.push({
                            id: el.id,
                            name: el.name,
                            createdBy: el.created_by,
                            createdAt: formatDate(el.created_at),
                            isoDate: el.created_at,
                            parentSegment: el.parent_group_id
                                ? el.parent_group_id
                                : '-',
                            automated: el.automated,
                            action: {
                                label: t(`columns:action`),
                                id: el.id,
                                open: false,
                                options: [
                                    {
                                        name: 'removeSegment',
                                        label: t('settings:removeSegment'),
                                        icon: icoDelete
                                    }
                                ],
                                reference: React.createRef()
                            }
                        });
                    return el;
                });
                dataClone.sortedData = _.orderBy(
                    dataClone.data,
                    [dataClone.sortColumn.path],
                    [dataClone.sortColumn.order]
                );
                verifyComponent(dataClone);
                if (isMounted.current) {
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [segmentId, isMounted, setLoading, setValue, parentSegmentId, t]);

        useEffect(() => {
            getSegments();
        }, [getSegments]);

        const handleCloseModal = () => {
            if (isMounted.current) {
                toggleModal(false);
                setModalType('');
                setSelectedSegmentId('');
            }
        };

        const handleSelectSettingsOption = useCallback(
            async (attr, id, option) => {
                if (id) {
                    if (isMounted.current) setSelectedSegmentId(id);
                }
                if (isMounted.current) {
                    toggleComponentSettings(false);
                    toggleModal(true);
                    setModalType(option);
                }
            },
            [isMounted, toggleComponentSettings, toggleModal]
        );

        const handleDeleteSegment = async () => {
            try {
                await SegmentationService.deleteApiSegmentation({
                    id: selectedSegmentId
                });
                onSelectSegment(undefined);
                handleCloseModal();
                getSegments();
            } catch (err) {
                errorHandler(err);
            }
        };

        return (
            <>
                <ContentContainer
                    hidePinIcon
                    attr={data.attr}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <React.Fragment>
                            {modal && modalType === 'removeSegment' && (
                                <Modal onToggleModal={handleCloseModal}>
                                    <div className="row mx-0 mb-4">
                                        <div className="col px-0">
                                            <span className="modal-title">
                                                {t(
                                                    'pages:managerProfile.areyousure'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 d-flex justify-content-between align-items-center">
                                        <div className="col-5 px-0">
                                            <SecondaryButton
                                                text={t('modals:cancel')}
                                                onClick={handleCloseModal}
                                            />
                                        </div>
                                        <div className="col-5 px-0">
                                            <PrimaryButton
                                                text={t('modals:remove')}
                                                onClick={handleDeleteSegment}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            )}
                            <Table
                                actions={data.actions}
                                // onClickActionIcon={onClickActionIcon}
                                onClickActionOption={handleSelectSettingsOption}
                                // onCloseActions={onCloseActions}
                                attr={data.attr}
                                type={data.type}
                                titleCounter={data.titleCounter}
                                titleText={t(`components:${data.attr}.title`)}
                                placeholderText={t(
                                    `components:${data.attr}.nodata`
                                )}
                                columns={data.columns}
                                data={data.sortedData}
                                sortColumn={data.sortColumn}
                                onSort={dataSort}
                                currentPage={data.currentPage}
                                pageSize={data.pageSize}
                                onPageChange={dataPageChange}
                                onRowClick={
                                    !_.find(data.data, (el) => {
                                        return el.action.open;
                                    })
                                        ? onSelectSegment
                                        : undefined
                                }
                                onRowSelect={undefined}
                                onSelectAll={dataSelectAll}
                                onUnselectAll={dataUnselectAll}
                                paginationDisabled={data.paginationDisabled}
                                disabled={data.disabled}
                                onClickActionIcon={clickActionIcon}
                                onCloseActions={clickActionIcon}
                            ></Table>
                        </React.Fragment>
                    )}
                </ContentContainer>
            </>
        );
    }
);

SegmentList.propTypes = {
    /**
     * Propagates selection of a user
     */
    onSelectSegment: PropTypes.func
};
