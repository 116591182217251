import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getClientColorByRisk } from '../../../utils/color';
import { useGeo } from '../../../hooks/useGeo';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useFilter } from '../../../providers/filter-context';
import { PointOfSaleService } from '../../../temp/test';
import { clientGeoMock } from './mock';
import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import {
    CheckboxInput,
    ContentContainer,
    Loader,
    MapContainerNew,
    Modal,
    PrimaryButton,
    RadioInput,
    SecondaryButton,
    Spinner
} from '../../';
import { getFilterParam } from '../../../utils/params';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 *
 * Displays the geographical location of all clients
 *
 */

export const ClientGeo = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isPreview,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const { i18n } = useTranslation();
        const [modal, toggleModal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            mouseOverMap,
            clickMap,
            setLoading,
            setValue,
            clickRatioOption,
            clickCheckboxOption
        } = useGeo(clientGeoMock, modal);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(clientGeoMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true);
                        }
                        if (type) dataClone.attr = type;
                        let riskColors = [
                            '#6fdb7d',
                            '#dbdb6f',
                            '#ffbd77',
                            '#ff6363'
                        ];
                        const dataBack = await PointOfSaleService.getApiPos({
                            clientId: getFilterParam(filter, config, [
                                'clientId',
                                'clientIds'
                            ]),
                            mapInfo: 'true',
                            page: undefined,
                            vendorId: getFilterParam(filter, config, [
                                'vendorId',
                                'vendorIds'
                            ])
                        });
                        let avgLat = 0;
                        let avgLon = 0;
                        let count = 0;
                        dataClone.originalRisk1Data.features.length = 0;
                        dataClone.risk1Data.features.length = 0;
                        dataClone.originalRisk2Data.features.length = 0;
                        dataClone.risk2Data.features.length = 0;
                        dataClone.originalRisk3Data.features.length = 0;
                        dataClone.risk3Data.features.length = 0;
                        dataClone.originalRisk4Data.features.length = 0;
                        dataClone.risk4Data.features.length = 0;
                        dataClone.originalCompetitionData.features.length = 0;
                        dataClone.competitionData.features.length = 0;
                        dataBack.map((el) => {
                            if (el.lon && el.lat && el.risk) {
                                let initialDefaultStatus = 'inactiveClients';
                                let initialDefaultStatusValue = 'Inactive';
                                let initialDefaultStatusColor = '#a28c6a';
                                let innerColor = getClientColorByRisk(
                                    riskColors,
                                    el.risk
                                );
                                if (el.lost) {
                                    initialDefaultStatus = 'lostClients';
                                    initialDefaultStatusValue =
                                        'Cliente Perdido';
                                    initialDefaultStatusColor = '#606060';
                                    innerColor = '#606060';
                                } else if (el.active) {
                                    initialDefaultStatus = 'activeClients';
                                    initialDefaultStatusValue = 'Cliente Ativo';
                                    initialDefaultStatusColor = '#ffb200';
                                }
                                const newEl = {
                                    lat: el.lat,
                                    lon: el.lon,
                                    regionId: el.client_id.toString(),
                                    defaultStatus: initialDefaultStatus,
                                    defaultStatusValue:
                                        initialDefaultStatusValue,
                                    defaultStatusColor:
                                        initialDefaultStatusColor,
                                    riskStatus: 'risk' + el.risk,
                                    riskStatusValue: el.risk,
                                    riskStatusColor: getClientColorByRisk(
                                        riskColors,
                                        el.risk
                                    ),
                                    innerColor: innerColor,
                                    ...el
                                };
                                if (el.risk === 1) {
                                    dataClone.originalRisk1Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    dataClone.risk1Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    avgLat += el.lat;
                                    avgLon += el.lon;
                                    count++;
                                } else if (el.risk === 2) {
                                    dataClone.originalRisk2Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    dataClone.risk2Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    avgLat += el.lat;
                                    avgLon += el.lon;
                                    count++;
                                } else if (el.risk === 3) {
                                    dataClone.originalRisk3Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    dataClone.risk3Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    avgLat += el.lat;
                                    avgLon += el.lon;
                                    count++;
                                } else if (el.risk === 4) {
                                    dataClone.originalRisk4Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    dataClone.risk4Data.features.push({
                                        type: 'Feature',
                                        properties: {
                                            ...newEl
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [el.lon, el.lat]
                                        }
                                    });
                                    avgLat += el.lat;
                                    avgLon += el.lon;
                                    count++;
                                }
                            }
                            return el;
                        });
                        if (avgLat !== 0 && avgLon !== 0) {
                            dataClone.startingPosition.lat = avgLat / count;
                            dataClone.startingPosition.lon = avgLon / count;
                        } else {
                            dataClone.startingPosition.lat = 38.736946;
                            dataClone.startingPosition.lon = -9.142685;
                        }
                        dataClone.loading = false;
                        dataClone.ready = true;
                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setLoading,
            setValue,
            t,
            type
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'clientGeoChartFilter') {
                    if (isMounted.current) toggleModal(true);
                }
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings,
                toggleModal
            ]
        );

        const handleCloseModal = () => {
            if (isMounted.current) toggleModal(false);
        };

        const handleSaveModal = () => {
            const dataClone = _.cloneDeep(data);
            const filteredSelectedOptions =
                dataClone.settings.viewOptions.checkboxOptions.selectedOptions;
            dataClone.risk1Data.features =
                dataClone.originalRisk1Data.features.filter((el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    );
                });
            dataClone.risk2Data.features =
                dataClone.originalRisk2Data.features.filter((el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    );
                });
            dataClone.risk3Data.features =
                dataClone.originalRisk3Data.features.filter((el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    );
                });
            dataClone.risk4Data.features =
                dataClone.originalRisk4Data.features.filter((el) => {
                    return (
                        filteredSelectedOptions.includes(
                            el.properties.defaultStatus
                        ) &&
                        filteredSelectedOptions.includes(
                            el.properties.riskStatus
                        )
                    );
                });
            dataClone.competitionData.features =
                dataClone.originalCompetitionData.features.filter((el) => {
                    return filteredSelectedOptions.includes(
                        el.properties.defaultStatus
                    );
                });
            if (isMounted.current) {
                setValue(dataClone);
                toggleModal(false);
            }
        };

        return (
            <>
                <div
                    className={
                        isPreview ? 'w-100 h-100 pe-none' : 'w-100 h-100'
                    }
                >
                    <ContentContainer
                        attr={data.attr}
                        titleText={t(`components:${data.attr}.title`)}
                        disabled={data.disabled}
                        isForecast={data.isForecast}
                        legend={data.legend}
                        settings={data.settings}
                        onClickSettingsIcon={toggleComponentSettings}
                        onClickSettingsOptions={handleSelectSettingsOption}
                        onClickSettingsView={handleSelectSettingsOption}
                        onCLickSettintsSelected={handleSelectSettingsOption}
                        onCloseSettings={toggleComponentSettings}
                        // hovered={hovered}
                        onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                        onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                        pinned={pinned}
                        onPinComponent={onPinComponent}
                        onUnpinComponent={onUnpinComponent}
                        hideInfoIcon={true}
                        hidePinIcon={isExample || isPreview || isReportExample}
                        hideSettingsIcon={isExample}
                    >
                        {data.loading && <Spinner />}
                        {!data.loading && (
                            <React.Fragment>
                                {modal && (
                                    <Modal onToggleModal={handleCloseModal}>
                                        <div
                                            className="row mx-0 "
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <span className="modal-title">
                                                    {t(
                                                        'pages:customDashboard.filterRatios'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 "
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <RadioInput
                                                    options={
                                                        data.settings
                                                            .viewOptions
                                                            .ratioOptions
                                                            .options
                                                    }
                                                    selectedOption={
                                                        data.settings
                                                            .viewOptions
                                                            .ratioOptions
                                                            .selectedOption
                                                    }
                                                    onClickRatioOption={
                                                        clickRatioOption
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 "
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <span className="modal-title">
                                                    {t(
                                                        'pages:customDashboard.filterCheckboxes'
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            className="row mx-0 "
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0">
                                                <CheckboxInput
                                                    options={
                                                        data.settings
                                                            .viewOptions
                                                            .checkboxOptions
                                                            .options
                                                    }
                                                    selectedOptions={
                                                        data.settings
                                                            .viewOptions
                                                            .checkboxOptions
                                                            .selectedOptions
                                                    }
                                                    onClickCheckboxOption={
                                                        clickCheckboxOption
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <hr
                                            className="my-4"
                                            style={{
                                                color: 'rgba(0,0,0,0.1)',
                                                opacity: 1
                                            }}
                                        />
                                        <div className="row mx-0 d-flex justify-content-between align-items-center">
                                            <div className="col-5 px-0">
                                                <SecondaryButton
                                                    text={t('settings:cancel')}
                                                    onClick={handleCloseModal}
                                                />
                                            </div>
                                            <div className="col-5 px-0">
                                                <PrimaryButton
                                                    text={t('settings:save')}
                                                    onClick={handleSaveModal}
                                                />
                                            </div>
                                        </div>
                                    </Modal>
                                )}
                                <MapContainerNew
                                    translate={t}
                                    risk1Data={data.risk1Data}
                                    risk2Data={data.risk2Data}
                                    risk3Data={data.risk3Data}
                                    risk4Data={data.risk4Data}
                                    competitionData={data.competitionData}
                                    selectedData={data.selectedData}
                                    startingPosition={data.startingPosition}
                                    tooltipType={data.tooltipType}
                                    interactionDisabled={
                                        data.interactionDisabled
                                    }
                                    onMouseOverMap={mouseOverMap}
                                    onMouseClickMap={clickMap}
                                    selectedEl={data.selectedEl}
                                    showPopup={data.showPopup}
                                    scale={data.scale}
                                    minScale={data.minScale}
                                    minZoom={data.minZoom}
                                    flyEnabled={data.flyEnabled}
                                />
                            </React.Fragment>
                        )}
                    </ContentContainer>
                </div>
            </>
        );
    }
);

ClientGeo.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
