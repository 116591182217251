import React from 'react'
import { getValueInString } from '../../../../../utils/unit'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SalesVsStocksTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    return (
        <TooltipContainer color={'#2c3c8d'}>
            <TooltipHeaderRow
                title={t('tooltips:header.product')}
                value={props.payload[0].payload.productName}
            />
            <Separator />
            <TooltipContentRow
                title={t('tooltips:content.stock')}
                value={getValueInString(
                    parseFloat(props.payload[0].payload.productStock),
                    props.unitsOrValues
                )}
                color={props.payload[0].fill}
            />
            <TooltipContentRow
                title={t('tooltips:content.sales')}
                value={getValueInString(
                    parseFloat(props.payload[0].payload.productSales),
                    props.unitsOrValues
                )}
                color={props.payload[1].fill}
            />
        </TooltipContainer>
    )
})
