import React, { useCallback, useState } from 'react';
import styles from './CreateTeamModal.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';
import { errorHandler } from '../../../utils/api';
import { StepByStepModal } from '../step-by-step-modal/step-by-step-modal';
import icoUserExample from '../../../assets/images/icoUserExample.png';
import { TypeInputAlternate } from '../../inputs/type-input-alternate/type-input-alternate';
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { AddUserInput } from '../../inputs/add-user-input/AddUserInput';
import { ToggleUserInput } from '../../inputs/toggle-user-input/ToggleUserInput';
import icoDone from '../../../assets/images/icoDone.svg';

export const CreateTeamModal = ({ onToggleModal }) => {
    const { t } = useCustomTranslation();
    const [currentStep, setCurrentStep] = React.useState(0);
    const teamName = useTextInputAlternate({
        name: 'teamName'
    });
    const [usersToAdd, setUsersToAdd] = useState([
        {
            name: 'User 1',
            email: 'Email 1',
            avatar: icoUserExample
        }
    ]);

    const [usersAdded, setUsersAdded] = useState([]);

    const [usersToToggle, setUsersToToggle] = useState([
        {
            name: 'User 1',
            email: 'Email 1',
            avatar: icoUserExample,
            added: false
        },
        {
            name: 'User 2',
            email: 'Email 2',
            avatar: icoUserExample,

            added: false
        },
        {
            name: 'User 3',
            email: 'Email 3',
            avatar: icoUserExample,

            added: false
        }
    ]);

    const handleAddUser = (user) => {
        setUsersAdded([...usersAdded, user]);
    };

    const handleRemoveUser = (user) => {
        setUsersAdded(usersAdded.filter((u) => u.email !== user.email));
    };

    const handleToggleUser = (user) => {
        setUsersToToggle(
            usersToToggle.map((u) => {
                if (u.email === user.email) {
                    return {
                        ...u,
                        added: !u.added
                    };
                }
                return u;
            })
        );
    };

    const handleCreateOrganization = useCallback(async () => {
        try {
            // await createOrganization
            onToggleModal();
        } catch (error) {
            errorHandler(error);
        }
    }, [onToggleModal]);

    return (
        <StepByStepModal
            steps={[
                t('modals:createTeamModal.steps.0'),
                t('modals:createTeamModal.steps.1'),
                t('modals:createTeamModal.steps.1-5'),
                t('modals:createTeamModal.steps.2')
            ]}
            onToggleModal={onToggleModal}
            currentStep={currentStep}
        >
            {currentStep === 0 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{
                            margin: '10rem 0'
                        }}
                    >
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-team-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(`modals:createTeamModal.title`)}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{ marginTop: '4rem' }}
                                >
                                    <div
                                        className="col-auto px-0"
                                        style={{ width: '30rem' }}
                                    >
                                        <TypeInputAlternate input={teamName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <TerciaryButton
                                text={t(`buttons:cancel`)}
                                onClick={onToggleModal}
                            />
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:next`)}
                                disabled={!teamName.value}
                                onClick={() => setCurrentStep(1)}
                            />
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 1 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{
                            margin: '2rem 0'
                        }}
                    >
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-team-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(`modals:createTeamModal.title`)}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{
                                        marginTop: '4rem',
                                        height: '45rem'
                                    }}
                                >
                                    <div className="col px-0">
                                        <AddUserInput
                                            usersToAddTitle={t(
                                                'modals:createTeamModal.usersToAdd'
                                            )}
                                            usersToAddDescription={t(
                                                'modals:createTeamModal.usersToAddDescription'
                                            )}
                                            usersAddedTitle={t(
                                                'modals:createTeamModal.usersAdded'
                                            )}
                                            onAddUser={handleAddUser}
                                            onRemoveUser={handleRemoveUser}
                                            usersToAdd={usersToAdd}
                                            usersAdded={usersAdded}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <TerciaryButton
                                text={t(`buttons:back`)}
                                onClick={() => setCurrentStep(0)}
                            />
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:next`)}
                                onClick={() => setCurrentStep(2)}
                            />
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 2 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div
                        className="row mx-0 w-100 flex-grow-1"
                        style={{
                            margin: '2rem 0'
                        }}
                    >
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-team-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(`modals:createTeamModal.title`)}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{
                                        marginTop: '4rem',
                                        height: '45rem'
                                    }}
                                >
                                    <div className="col px-0">
                                        <ToggleUserInput
                                            title={t(
                                                'modals:createTeamModal.usersToToggle'
                                            )}
                                            optionalTitle={t(
                                                'modals:createTeamModal.optionalTitle'
                                            )}
                                            subTitle={t(
                                                'modals:createTeamModal.usersAdded'
                                            )}
                                            onToggleUser={handleToggleUser}
                                            usersToToggle={usersToToggle}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '2rem' }}
                        >
                            <TerciaryButton
                                text={t(`buttons:back`)}
                                onClick={() => setCurrentStep(1)}
                            />
                        </div>
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:next`)}
                                onClick={() => setCurrentStep(3)}
                            />
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 3 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="row mx-0 w-100 flex-grow-1">
                        <div className="col px-0">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col-auto px-0">
                                        <img
                                            src={icoDone}
                                            alt="done"
                                            style={{
                                                width: '8rem',
                                                height: '8rem'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 d-flex justify-content-center">
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-team-modal-container__title'
                                                ]
                                            }
                                        >
                                            {t(
                                                `modals:createTeamModal.congratulations`
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-center"
                                    style={{
                                        marginTop: '2rem',
                                        marginBottom: '2rem'
                                    }}
                                >
                                    <div className="col-auto px-0">
                                        <span
                                            className={
                                                styles[
                                                    'create-team-modal-container__description'
                                                ]
                                            }
                                        >
                                            {t(
                                                `modals:createTeamModal.congratulationsDescription`
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-center">
                        <div className="col-auto px-0">
                            <PrimaryButton
                                text={t(`buttons:done`)}
                                onClick={handleCreateOrganization}
                            />
                        </div>
                    </div>
                </div>
            )}
        </StepByStepModal>
    );
};
