import React, { useState } from 'react'
import icoEdit from '../../../../../../../assets/images/ico-edit.svg'
import icoRemove from '../../../../../../../assets/images/ico-delete.svg'
import { getProfileImages } from '../../../../../../../utils/params'
import { PrimaryButton } from '../../../../../../../components'
import icoPlus from '../../../../../../../assets/images/ico-plus.svg'
import { RoleCard } from './components/role-card/role-card'
import { CreateRoleModal } from '../../../../../../../components/modals/create-role-modal/create-role-modal'

const emails = [
    'john.smith@outlook.com',
    'alice.brown@outlook.com',
    'robert.davis@outlook.com',
    'emma.wilson@outlook.com',
    'michael.johnson@outlook.com',
]

const images = getProfileImages(5)

export const RoleOverview = () => {
    const [createRoleModal, setCreateRoleModal] = useState(false)
    const [roles, setRoles] = useState([
        {
            id: 1,
            name: 'Administrator',
            description:
                'Manage user accounts, permissions, and settings. Ensure system security, monitor performance, and provide support. Handle updates and backups.',
            assignedMembers: [
                {
                    id: 1,
                    name: 'John Doe',
                    email: emails[0],
                    image: images[0],
                },
                {
                    id: 2,
                    name: 'Jane Doe',
                    email: emails[1],
                    image: images[1],
                },
            ],
            action: {
                id: 1,
                open: false,
                options: [
                    {
                        name: 'edit',
                        icon: icoEdit,
                    },
                    {
                        name: 'remove',
                        icon: icoRemove,
                        isRed: true,
                    },
                ],
                reference: React.createRef(),
            },
        },
        {
            id: 2,
            name: 'Viewer',
            description: 'Discover insights and explore different views in your data',
            assignedMembers: [
                {
                    id: 3,
                    name: 'John Doe',
                    email: emails[2],
                    image: images[2],
                },
                {
                    id: 4,
                    name: 'Jane Doe',
                    email: emails[3],
                    image: images[3],
                },
            ],
            action: {
                id: 1,
                open: false,
                options: [
                    {
                        name: 'edit',
                        icon: icoEdit,
                    },
                    {
                        name: 'remove',
                        icon: icoRemove,
                        isRed: true,
                    },
                ],
                reference: React.createRef(),
            },
        },
    ])
    return (
        <>
            {createRoleModal && <CreateRoleModal onCloseModal={() => setCreateRoleModal(false)}></CreateRoleModal>}
            <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                <div className="col px-0">
                    <span className="content-title">Role Overview</span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className="content-description">
                        Manage and assign roles here. Each role offers a specific set of permissions and responsibilities, ensuring a personalized and
                        secure experience for all users.
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col-auto px-0">
                    <PrimaryButton icon={icoPlus} text="Create Role" onClick={() => setCreateRoleModal(true)} />
                </div>
            </div>
            {roles && roles.length > 0 && (
                <div className="row mx-0 w-100">
                    <div className="col px-0">
                        {roles.map((role) => {
                            return <RoleCard key={role.id} {...role} />
                        })}
                    </div>
                </div>
            )}
        </>
    )
}
