/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SaleChannelDefaultSchema } from '../models/SaleChannelDefaultSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SaleChannelsService {
    /**
     * list all SaleChannel types
     * @returns SaleChannelDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiSaleChannels({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, name, description, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<SaleChannelDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sale-channels',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * get one SaleChannel by id
     * @returns SaleChannelDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiSaleChannels1({
        id,
    }: {
        /**
         * id
         */
        id: number,
    }): CancelablePromise<SaleChannelDefaultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sale-channels/{id}',
            path: {
                'id': id,
            },
        });
    }
}
