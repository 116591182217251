import styles from './reset-password.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Navigate,
    Route,
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import icoStarkSmall from '../../../assets/images/logo-small.jpg';
import icoRuleInfo from '../../../assets/images/icoRuleInfo.svg';
import icoRuleInfoHovered from '../../../assets/images/icoRuleInfoHovered.svg';
import icoCheckCircle from '../../../assets/images/icoCheckCircle.svg';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { usePasswordInput } from '../../../hooks/usePasswordInput';
import { useToggle } from '../../../hooks/useToggle';
import { useCurrentUser } from '../../../providers/current-user-context';
import {
    mediumPasswordSchema,
    mediumPasswordSchema2,
    strongPasswordSchema,
    weakPasswordSchema
} from '../../../schemas/fieldSchemas';
import { joiPasswordErrorHandler } from '../../../utils/error';
import { ProfileService } from '../../../temp/test';
import { errorHandler } from '../../../utils/api';
import {
    PrimaryButton,
    TypeInput,
    TypeInputAlternate
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { AuthBackgroundWrapper } from '../../../components/backgrounds/AuthBackgroundWrapper/AuthBackgroundWrapper';
import { useTextInputAlternate } from '../../../hooks/useTextInputAlternate';

/**
 * Dashboard allowing users to set a new password
 */

const ResetPassword = React.memo(() => {
    const isMounted = useIsMounted();
    const { currentUser } = useCurrentUser();
    const { t } = useCustomTranslation();
    const navigate = useNavigate();
    const { uuid } = useParams();
    const location = useLocation();
    const password = useTextInputAlternate({
        name: 'password',
        schema: weakPasswordSchema,
        isPassword: true,
        strength: '',
        strengthRules: {
            fraco: [weakPasswordSchema],
            médio: [mediumPasswordSchema, mediumPasswordSchema2],
            forte: [strongPasswordSchema]
        }
    });

    const [isInfoHovered, toggleIsInfoHovered] = useToggle(false);
    const [passwordReset, togglePasswordReset] = useToggle(false);

    // const handleClickSignIn = () => {
    // 	history.push('login');
    // };

    const handleResetPassword = async () => {
        try {
            await ProfileService.postApiAuthSetPassword({
                uuid: uuid,
                token: new URLSearchParams(location.search).get('token'),
                requestBody: {
                    password: password.value
                }
            });
            if (isMounted.current) togglePasswordReset(true);
            return;
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleKeyPress = (e) => {
        if (
            e.which === 13 &&
            !(
                password.strength !== 'fraco' &&
                password.strength !== 'médio' &&
                password.strength !== 'forte'
            )
        ) {
            if (isMounted.current) handleResetPassword();
        }
    };

    if (currentUser)
        return (
            <Route path="*" element={<Navigate to={'/dashboard'} replace />} />
        );

    return (
        <AuthBackgroundWrapper
            title={t('pages:resetPassword.resetPassword')}
            description={
                !passwordReset ? t('pages:resetPassword.desc2') : undefined
            }
            content={
                <>
                    {!passwordReset && (
                        <React.Fragment>
                            <div
                                className="row mx-0"
                                style={{ margin: '10rem 0' }}
                            >
                                <div className="col px-0">
                                    <div
                                        className="row mx-0"
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        <div className="col px-0">
                                            <TypeInputAlternate
                                                input={password}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <div
                                                className={`${styles['reset-password-container__strength-meter']}`}
                                            >
                                                <div
                                                    className={
                                                        password.strength ===
                                                        'forte'
                                                            ? `${styles['reset-password-container__strength-meter__bar']} ${styles['strong']}`
                                                            : password.strength ===
                                                              'médio'
                                                            ? `${styles['reset-password-container__strength-meter__bar']} ${styles['medium']}`
                                                            : password.strength ===
                                                              'fraco'
                                                            ? `${styles['reset-password-container__strength-meter__bar']} ${styles['weak']}`
                                                            : `${styles['reset-password-container__strength-meter__bar']}`
                                                    }
                                                ></div>
                                                <div
                                                    className={`${styles['reset-password-container__strength-meter__bar-space']}`}
                                                ></div>
                                                <div
                                                    className={
                                                        password.strength ===
                                                        'forte'
                                                            ? `${styles['reset-password-container__strength-meter__bar']} ${styles['strong']}`
                                                            : password.strength ===
                                                              'médio'
                                                            ? `${styles['reset-password-container__strength-meter__bar']} ${styles['medium']}`
                                                            : `${styles['reset-password-container__strength-meter__bar']}`
                                                    }
                                                ></div>
                                                <div
                                                    className={`${styles['reset-password-container__strength-meter__bar-space']}`}
                                                ></div>
                                                <div
                                                    className={
                                                        password.strength ===
                                                        'forte'
                                                            ? `${styles['reset-password-container__strength-meter__bar']} ${styles['strong']}`
                                                            : `${styles['reset-password-container__strength-meter__bar']}`
                                                    }
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    {password.strength && (
                                        <div
                                            className="row mx-0 w-100"
                                            style={{
                                                marginTop: '0.7rem'
                                            }}
                                        >
                                            <div className="col-auto px-0 d-flex justify-content-center align-items-center position-relative">
                                                <img
                                                    loading="lazy"
                                                    src={
                                                        isInfoHovered
                                                            ? icoRuleInfoHovered
                                                            : icoRuleInfo
                                                    }
                                                    alt=""
                                                    className={`${styles['reset-password-container__strength-info-icon']}`}
                                                    onMouseEnter={() =>
                                                        toggleIsInfoHovered(
                                                            true
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        toggleIsInfoHovered(
                                                            false
                                                        )
                                                    }
                                                ></img>
                                                <div
                                                    className={`${styles['reset-password-container__strength-info-message']}`}
                                                    style={
                                                        isInfoHovered
                                                            ? {
                                                                  visibility:
                                                                      'visible'
                                                              }
                                                            : {
                                                                  visibility:
                                                                      'hidden'
                                                              }
                                                    }
                                                >
                                                    <span>
                                                        {t(
                                                            'pages:resetPassword.strengthInfo'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <span
                                                    className={
                                                        password.strength ===
                                                        'forte'
                                                            ? `${styles['reset-password-container__strength-info-text']} ${styles['strong']}`
                                                            : password.strength ===
                                                              'médio'
                                                            ? `${styles['reset-password-container__strength-info-text']} ${styles['medium']}`
                                                            : password.strength ===
                                                              'fraco'
                                                            ? `${styles['reset-password-container__strength-info-text']} ${styles['weak']}`
                                                            : `${styles['reset-password-container__strength-info-text']}`
                                                    }
                                                >
                                                    {t(
                                                        'pages:resetPassword.strength'
                                                    )}
                                                    :{' '}
                                                    {t(
                                                        `pages:resetPassword.${password.strength}`
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-end align-items-center"
                                style={{ marginTop: '2rem' }}
                            >
                                <div className="col px-0">
                                    <PrimaryButton
                                        disabled={
                                            password.strength !== 'fraco' &&
                                            password.strength !== 'médio' &&
                                            password.strength !== 'forte'
                                        }
                                        text={t('pages:resetPassword.confirm')}
                                        onClick={handleResetPassword}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {passwordReset && (
                        <React.Fragment>
                            <div
                                className="row mx-0"
                                style={{ marginBottom: '1rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <img
                                        loading="lazy"
                                        src={icoCheckCircle}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                className="row mx-0"
                                style={{ marginBottom: '1rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['reset-password-container__desc-success']}`}
                                    >
                                        {t(
                                            'pages:resetPassword.resetSuccessful'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0"
                                style={{ marginBottom: '10rem' }}
                            >
                                <div className="col px-0 d-flex justify-content-center align-items-center">
                                    <span
                                        className={`${styles['reset-password-container__desc2']}`}
                                    >
                                        {t('pages:resetPassword.redirecting')}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row mx-0 d-flex justify-content-end align-items-center"
                                style={{ marginTop: '2rem' }}
                            >
                                <div className="col px-0">
                                    <PrimaryButton
                                        text={t(
                                            'pages:resetPassword.backToSignIn'
                                        )}
                                        onClick={() => {
                                            navigate('/login');
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </>
            }
        />
    );
});

export default ResetPassword;

ResetPassword.propTypes = {};
