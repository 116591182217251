import React, { useState } from 'react'
import styles from './radar-chart-new-container.module.scss'
import { Dot, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Text, Tooltip } from 'recharts'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { TooltipFactory } from '../../chart-configuration/tooltip-factory/tooltip-factory'

const dataMock = [
    {
        subject: 'Recency',
        bottom: 0,
        left: 80,
        right: 80,
        fullMark: 150,
    },
    {
        subject: 'Frequency',
        bottom: 40,
        left: 0,
        right: 40,
        fullMark: 150,
    },
    {
        subject: 'Monetary',
        bottom: 120,
        left: 120,
        right: 0,
        fullMark: 150,
    },
]

const tickMinsAndMaxsMock = {
    Recency: { min: 0, max: 150 },
    Frequency: { min: 0, max: 150 },
    Monetary: { min: 0, max: 150 },
}

const CustomTick = (props) => {
    const { t } = useCustomTranslation()
    let valueToShow = props.payload.value
    const min = props.tickMinsAndMaxs[props.payload.value].min
    const max = props.tickMinsAndMaxs[props.payload.value].max
    return (
        <>
            <Text {...props} x={props.x} y={props.y - 15} className={styles['radar-chart-new-container__custom-tick']}>
                {valueToShow}
            </Text>
            <Text {...props} className={styles['radar-chart-new-container__custom-tick-aux']}>
                {`${t('common:min')} ${min}`}
            </Text>
            <Text {...props} x={props.x} y={props.y - 30} className={styles['radar-chart-new-container__custom-tick-aux']}>
                {`${t('common:max')} ${max}`}
            </Text>
        </>
    )
}

export const RadarChartNewContainer = React.memo(({ attr = '', tickMinsAndMaxs = tickMinsAndMaxsMock, data = dataMock }) => {
    return (
        <div className={styles['radar-chart-new-container']}>
            <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
                    <Tooltip
                        contentStyle={{ zIndex: 1000 }}
                        content={
                            <TooltipFactory
                                item={{
                                    type: attr,
                                }}
                            />
                        }
                        wrapperStyle={{
                            zIndex: 1000,
                            outline: 'none',
                        }}
                    />
                    <defs>
                        <filter id="glow">
                            <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
                            <feMerge>
                                <feMergeNode in="coloredBlur" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <linearGradient id="gradientRadarBottom" x1="0" y1="0" x2="0" y2="100%">
                            <stop offset="0%" stopColor="#2cd9c54d" />
                            <stop offset="41%" stopColor="#2e5bff87" />
                            <stop offset="98%" stopColor="#0ea6fa" />
                        </linearGradient>
                        <linearGradient id="gradientRadarLeft" x1="1" y1="50%" x2="0" y2="0">
                            <stop offset="0%" stopColor="#2cd9c54d" />
                            <stop offset="41%" stopColor="#2e5bff87" />
                            <stop offset="98%" stopColor="#0ea6fa" />
                        </linearGradient>
                        <linearGradient id="gradientRadarRight" x1="0" y1="50%" x2="1" y2="0%">
                            <stop offset="0%" stopColor="#2cd9c54d" />
                            <stop offset="41%" stopColor="#2e5bff87" />
                            <stop offset="98%" stopColor="#0ea6fa" />
                        </linearGradient>
                    </defs>
                    <Radar name="bottom" dataKey="bottom" fill="url(#gradientRadarBottom)" activeDot={false}></Radar>
                    <Radar name="left" dataKey="left" fill="url(#gradientRadarLeft)" activeDot={false}></Radar>
                    <Radar name="right" dataKey="right" fill="url(#gradientRadarRight)" activeDot={false}></Radar>
                    <PolarGrid stroke="#B7BBE5" filter="url(#glow)" />
                    <PolarAngleAxis dataKey="subject" tick={<CustomTick tickMinsAndMaxs={tickMinsAndMaxs} />} />
                </RadarChart>
            </ResponsiveContainer>
        </div>
    )
})
