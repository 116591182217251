import styles from './segment-analysis.module.scss';
import qs from 'query-string';

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useArray } from '../../../hooks/useArray';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useAuxBar } from '../../../providers/aux-bar-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorHandler } from '../../../utils/api';
import { SegmentationService } from '../../../temp/test';
import {
    AllSegments,
    LoaderForeground,
    NavigationMenu,
    NewSegment,
    SegmentOverview,
    SegmentResults
} from '../../../components';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Provides a page layout that allows users to analyze segments
 */

const SegmentAnalysis = React.memo(() => {
    const { setAvailableOptions, setSelectedAvailableOption } = useAuxBar();
    const [childrenCount, setChildrenCount] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [resultsLoading] = useToggle(false);
    const [selectedSegmentId, setSelectedSegmentId] = useState(
        qs.parse(location.search)['segment-id'] || ''
    );
    const [segmentCreationStep, setSegmentCreationStep] = useState('initial');
    const { array: subNavs } = useArray([
        'overview',
        'newSegment',
        'allSegments',
        'results'
    ]);
    const [currentSubNav, setCurrentSubNav] = useState('overview');

    useEffect(() => {
        async function fill() {
            if (selectedSegmentId) {
                try {
                    const results =
                        await SegmentationService.getApiSegmentation1({
                            id: selectedSegmentId
                        });

                    setChildrenCount(results.children_count);
                } catch (err) {
                    errorHandler(err);
                }
            }
        }
        fill();
    }, [selectedSegmentId]);

    const handleChangeSubNav = useCallback(
        (option) => {
            setAvailableOptions([]);
            setSelectedAvailableOption('');
            if (isMounted.current) {
                setCurrentSubNav(option);
                setSegmentCreationStep('initial');
            }
        },
        [
            setAvailableOptions,
            setSelectedAvailableOption,
            setCurrentSubNav,
            setSegmentCreationStep,
            isMounted
        ]
    );

    const handleSelectSegment = useCallback(
        (segment) => {
            if (segment) {
                const params = new URLSearchParams();
                params.append('segment-id', segment.id);
                navigate({
                    pathname: location.pathname,
                    search: params.toString()
                });
                handleChangeSubNav('results');
                setSelectedSegmentId(segment.id);
            } else {
                let params = new URLSearchParams(location.search).get(
                    'segment-id'
                );
                if (parseInt(params) === parseInt(selectedSegmentId)) {
                    navigate({
                        pathname: location.pathname
                    });
                }
                setSelectedSegmentId('');
            }
        },
        [
            handleChangeSubNav,
            navigate,
            location.pathname,
            location.search,
            selectedSegmentId
        ]
    );

    return (
        <>
            <div
                className={`row mx-0 w-100 ${styles['segment-analysis-container']}`}
            >
                <div className="col px-0 d-flex flex-column">
                    <div className="row w-100 dashboard-header-row">
                        <div className="col px-0 d-flex justify-content-between align-items-center">
                            <span>{t(`pages:segmentAnalysis.title`)}</span>
                        </div>
                    </div>
                    <div
                        className="row mx-0 w-100 "
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <div className={`row mx-0 w-100 h-100`}>
                                <div className="col px-0">
                                    <NavigationMenu
                                        isPageMenu
                                        options={subNavs}
                                        selectedOption={currentSubNav}
                                        onChangeOption={handleChangeSubNav}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {currentSubNav === 'overview' && (
                        <SegmentOverview
                            onSelectSegment={handleSelectSegment}
                        />
                    )}
                    {currentSubNav === 'newSegment' && (
                        <NewSegment
                            segmentCreationStep={segmentCreationStep}
                            onSetSegmentCreationStep={setSegmentCreationStep}
                            onSelectSegment={handleSelectSegment}
                        />
                    )}
                    {currentSubNav === 'allSegments' && (
                        <AllSegments onSelectSegment={handleSelectSegment} />
                    )}
                    {currentSubNav === 'results' && resultsLoading && (
                        <div
                            className={`row mx-0 w-100 flex-grow-1 d-flex justify-content-center align-items-center ${styles['segment-analysis-container__results-wrapper']}`}
                        >
                            <div className="col px-0 h-100 d-flex justify-content-center align-items-center">
                                <LoaderForeground />
                            </div>
                        </div>
                    )}
                    {currentSubNav === 'results' && !resultsLoading && (
                        <div
                            className={`row mx-0 w-100 flex-grow-1 ${styles['segment-analysis-container__results-wrapper']}`}
                        >
                            {selectedSegmentId && (
                                <div
                                    className={`col px-0 d-flex justify-content-center align-items-start`}
                                >
                                    <SegmentResults
                                        segmentId={selectedSegmentId}
                                        childrenCount={childrenCount}
                                        onSelectSegment={handleSelectSegment}
                                    />
                                </div>
                            )}
                            {!selectedSegmentId && (
                                <div
                                    className={`col px-0 d-flex justify-content-center align-items-center`}
                                >
                                    <span
                                        className={`${styles['segment-analysis-container__results-wrapper__no-data']}`}
                                    >
                                        {t(`components:segmentResults.nodata`)}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
});

export default SegmentAnalysis;

SegmentAnalysis.propTypes = {};
