export const regionGeoMarketShareMock = {
    hoverStyle: '',
    desc: '',
    total: 147,
    revenue: 30000,
    percentage: 47,
    percentageGrowth: 0.6,
    tooltipType: 'regionGeoMarketShare',
    dataList: [],
    titleText: '',
    attr: 'regionGeoMarketShare',
    loading: false,
    legend: [
        {
            name: '0 - 26%',
            color: '#006BA6',
            translated: true,
        },
        {
            name: '26 - 35%',
            color: '#0496FF',
            translated: true,
        },
        {
            name: '35 - 43%',
            color: '#FFBC42',
            translated: true,
        },
        {
            name: '43 - 52%',
            color: '#D81159',
            translated: true,
        },
        {
            name: '52 - 60%',
            color: '#8F2D56',
            translated: true,
        },
    ],
    type: 'normal',
    update: 1,
    disabled: true,
    startingPosition: {
        lat: 38.769688599999995,
        lon: -9.240822000000001,
        zoom: 7,
    },
    language: 'pt',
    minScale: 2,
    scale: 2,
    minZoom: 9,
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 38.736946,
        longitude: -9.142685,
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
    },
    interactiondisabled: true,
    showPopup: false,
    selectedEl: {},
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
        viewOptions: {
            ratioOptions: {
                options: [],
                selectedOption: '',
            },
            checkboxOptions: {
                options: [],
                selectedOptions: [],
            },
        },
    },
    ready: true,
    data: {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    color: '#E1B758',
                    name: 'AC001 S Miguel, S Maria, Terceira, Graciosa, S Jorge',
                    market_share: 42.0693,
                    lat: 38.7216415,
                    lon: -27.2205771,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-27.2205771, 38.7216415],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BC1C58',
                    name: 'AC002 Faial,Pico,Flores,Corvo',
                    market_share: 54.9913,
                    lat: 38.5912552,
                    lon: -28.6964752,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-28.6964752, 38.5912552],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E64F51',
                    name: 'AV001 Espinho',
                    market_share: 48.551,
                    lat: 41.0071914,
                    lon: -8.641033199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.641033199999999, 41.0071914],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A246',
                    name: 'AV002 Feira 1 (Feira, Souto)',
                    market_share: 44.3998,
                    lat: 39.5757716,
                    lon: -8.2374798,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2374798, 39.5757716],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#73A7AB',
                    name: 'AV003 Feira 3 (Argoncilhe, Fiaes, Lobao)',
                    market_share: 38.3095,
                    lat: 41.0191312,
                    lon: -8.544635999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.544635999999999, 41.0191312],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F38749',
                    name: 'AV004 Castelo de Paiva',
                    market_share: 45.7561,
                    lat: 41.0409444,
                    lon: -8.2714736,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2714736, 41.0409444],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#4FA1C7',
                    name: 'AV005 Oliveira de Azemeis 1(Santiago de Riba-Ul, Sao Martinho da Gandara, Vila de Cucujaes)',
                    market_share: 37.0722,
                    lat: 40.8527028,
                    lon: -8.489734499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.489734499999999, 40.8527028],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#93AC93',
                    name: 'AV006 S Joao da Madeira',
                    market_share: 39.4111,
                    lat: 40.90052379999999,
                    lon: -8.490671599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.490671599999999, 40.90052379999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0390F3',
                    name: 'AV007 Ovar 1(Arada, Cortegaca, Esmoriz, Maceda)',
                    market_share: 33.3837,
                    lat: 40.945082,
                    lon: -8.622563,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.622563, 40.945082],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0493F9',
                    name: 'AV008 Ovar 2 (Ovar)',
                    market_share: 33.9608,
                    lat: 40.8596399,
                    lon: -8.6253313,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6253313, 40.8596399],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FEB843',
                    name: 'AV009 Murtosa, Estarreja',
                    market_share: 43.2814,
                    lat: 40.7528385,
                    lon: -8.5709856,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5709856, 40.7528385],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#60A4BA',
                    name: 'AV010 Vale de Cambra, Sever do Vouga, OAzemeis 2(Cesar, Nog Cravo, Carregosa, Pindelo, S Roque)',
                    market_share: 37.6392,
                    lat: 40.83985699999999,
                    lon: -8.481836999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.481836999999999, 40.83985699999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A6AE85',
                    name: 'AV011 Agueda 1 (Agueda)',
                    market_share: 40.0388,
                    lat: 40.5754246,
                    lon: -8.446436799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.446436799999999, 40.5754246],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BEB273',
                    name: 'AV012 Agueda 2, Obairro (Aguada de Cima, Macinhata do Vouga, Oliveira do Bairro)',
                    market_share: 40.8684,
                    lat: 40.577358,
                    lon: -8.448443,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.448443, 40.577358],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E0B759',
                    name: 'AV013 Aveiro 1 (Vera Cruz, Gloria)',
                    market_share: 42.0286,
                    lat: 40.6408386,
                    lon: -8.648259399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.648259399999999, 40.6408386],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D5B662',
                    name: 'AV014 Ilhavo (Gafanha, Salvador)',
                    market_share: 41.6406,
                    lat: 40.6363737,
                    lon: -8.7004614,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7004614, 40.6363737],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#58A3C0',
                    name: 'AV015 Vagos (Vagos, Calvao)',
                    market_share: 37.3784,
                    lat: 40.4836591,
                    lon: -8.7095789,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7095789, 40.4836591],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EF784B',
                    name: 'AV016 Anadia 1 (Tamengo, Avelas de Caminho, Avelas de Cima, Paredes do Bairro)',
                    market_share: 46.5223,
                    lat: 40.4836974,
                    lon: -8.4170685,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4170685, 40.4836974],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C21958',
                    name: 'AV017 Anadia 2 (Arcos)',
                    market_share: 54.2614,
                    lat: 40.44783959999999,
                    lon: -8.4409028,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4409028, 40.44783959999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CFB566',
                    name: 'AV018 Mealhada (Mealhada, Barcouco)',
                    market_share: 41.4363,
                    lat: 40.3807921,
                    lon: -8.449581400000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.449581400000001, 40.3807921],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038AE6',
                    name: 'AV019 Aveiro 2(Oliverinha, Aradas, Santa Joana, Sao Bernardo)',
                    market_share: 32.0885,
                    lat: 40.6188083,
                    lon: -8.624348,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.624348, 40.6188083],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D41259',
                    name: 'AV020 Feira 2 (Lourosa, P Brandao, SM Lamas, Nogueira da Regedoura, Sao Paio de Oleiros, Mozelos)',
                    market_share: 52.1231,
                    lat: 40.9780231,
                    lon: -8.5725509,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5725509, 40.9780231],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DD2556',
                    name: 'AV021 Feira 4(Escapaes, Rio Meao, Sao Joao de Ver)',
                    market_share: 50.6628,
                    lat: 40.9557367,
                    lon: -8.588585799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.588585799999999, 40.9557367],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C61858',
                    name: 'AV022 Arouca',
                    market_share: 53.7577,
                    lat: 40.9312766,
                    lon: -8.237704899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.237704899999999, 40.9312766],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F8BB47',
                    name: 'AV023 Oliveira de Azemeis 3(O Azemeis, P Bemposta, Loureiro, Ossela)',
                    market_share: 42.8594,
                    lat: 40.8505259,
                    lon: -8.434573199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.434573199999999, 40.8505259],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E1B759',
                    name: 'AV024 Albergaria-a-Velha',
                    market_share: 42.0505,
                    lat: 40.6894236,
                    lon: -8.4796655,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4796655, 40.6894236],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0491F4',
                    name: 'AV025 Ovar 3(Valega, Sao Vicente de Pereira Jusa, Sao Joao)',
                    market_share: 33.4507,
                    lat: 40.8656705,
                    lon: -8.5364829,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5364829, 40.8656705],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038FF1',
                    name: 'AV026 Aveiro 3(Cacia, Esgueira, Eixo)',
                    market_share: 33.142,
                    lat: 40.6405055,
                    lon: -8.6537539,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6537539, 40.6405055],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E34053',
                    name: 'BG001 Macedo Cavaleiros, Miranda do Douro, Vimioso, Vinhais',
                    market_share: 49.3106,
                    lat: 41.5844451,
                    lon: -6.529121099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-6.529121099999999, 41.5844451],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F07A4B',
                    name: 'BG002 Braganca',
                    market_share: 46.4056,
                    lat: 41.8061131,
                    lon: -6.756737999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-6.756737999999999, 41.8061131],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EC694D',
                    name: 'BG003 Mirandela',
                    market_share: 47.2603,
                    lat: 41.4853545,
                    lon: -7.180871100000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.180871100000001, 41.4853545],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DC2357',
                    name: 'BG004 Carrazeda Ansiaes, Vila Flor, Alfandega da Fe',
                    market_share: 50.7633,
                    lat: 41.344363,
                    lon: -6.9601807,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-6.9601807, 41.344363],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BC1C58',
                    name: 'BG005 Mogadouro, Freixo de Espada Cinta, Torre de Moncorvo',
                    market_share: 54.9302,
                    lat: 41.1856059,
                    lon: -7.0498442,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.0498442, 41.1856059],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FAA845',
                    name: 'BJ001 Alvito, Cuba, Vidigueira, Moura01(Santo Agostinho, Sao Joao Baptista)',
                    market_share: 44.1022,
                    lat: 38.2118842,
                    lon: -7.8005203,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.8005203, 38.2118842],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DE2A56',
                    name: 'BJ002 Serpa',
                    market_share: 50.4066,
                    lat: 37.9469642,
                    lon: -7.602181900000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.602181900000001, 37.9469642],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D01459',
                    name: 'BJ003 Beja (Beringel, Salvador, Santa Maria da Feira)',
                    market_share: 52.5884,
                    lat: 38.018056,
                    lon: -7.859999999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.859999999999999, 38.018056],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#ADB080',
                    name: 'BJ004 Ferreira do Alentejo',
                    market_share: 40.292,
                    lat: 38.0593873,
                    lon: -8.114071299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.114071299999999, 38.0593873],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#99AD8F',
                    name: 'BJ005 Odemira (Vila Nova de Milfontes, Colos), Ourique, Castro Verde',
                    market_share: 39.6098,
                    lat: 37.7239951,
                    lon: -8.7817132,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7817132, 37.7239951],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E2B858',
                    name: 'BJ006  Moura 2, Barrancos',
                    market_share: 42.0992,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#64A4B7',
                    name: 'BJ007  Almodovar, Mertola',
                    market_share: 37.78,
                    lat: 37.6415148,
                    lon: -7.6606665,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6606665, 37.6415148],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D61259',
                    name: 'BJ008 Aljustrel',
                    market_share: 51.8532,
                    lat: 37.8760582,
                    lon: -8.1649833,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1649833, 37.8760582],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0493F9',
                    name: 'BR001 Esposende (Esposende, Marinhas »)',
                    market_share: 33.9683,
                    lat: 41.5323363,
                    lon: -8.783007099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.783007099999999, 41.5323363],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#55A2C2',
                    name: 'BR002 Barcelos 1 (Alheira, Arcozelo, Barcelinhos, Macieira de Rates »)',
                    market_share: 37.2801,
                    lat: 41.5410747,
                    lon: -8.6124835,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6124835, 41.5410747],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0386DF',
                    name: 'BR003 Barcelos 2 (Vila Cova, Barqueiros, Vila Seca, Carapecos, Perelhal)',
                    market_share: 31.4163,
                    lat: 41.5318724,
                    lon: -8.6939738,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6939738, 41.5318724],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FEB743',
                    name: 'BR004 Amares, Braga 1 (Sao Victor, Lamacaes), Vila Verde 1 (Vila Verde »)',
                    market_share: 43.3553,
                    lat: 41.5457315,
                    lon: -8.3950816,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.3950816, 41.5457315],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F69447',
                    name: 'BR005 Vila Verde 2 (Cervaes, Prado)',
                    market_share: 45.118,
                    lat: 41.5986139,
                    lon: -8.5226706,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5226706, 41.5986139],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E34153',
                    name: 'BR006 Povoa de Lanhoso, Terras de Bouro, Vieira do Minho',
                    market_share: 49.2635,
                    lat: 41.7167409,
                    lon: -8.306244399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.306244399999999, 41.7167409],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FFBA42',
                    name: 'BR007 Braga 2 (Celeiros, Maximinos, Real »)',
                    market_share: 43.1823,
                    lat: 41.5117597,
                    lon: -8.4508236,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4508236, 41.5117597],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#55A2C2',
                    name: 'BR008 Braga 3 (Se, Mire de Tibaes, Palmeira, Sao Pedro Merelim,Sao Jose de Sao Lazaro, Sao Vicente)',
                    market_share: 37.2843,
                    lat: 41.5653733,
                    lon: -8.4778465,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4778465, 41.5653733],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#289BE4',
                    name: 'BR009 Famalicao 1(Santa Maria Oliveira, Bairro, Delaes, Riba de Ave)',
                    market_share: 35.7335,
                    lat: 41.40588959999999,
                    lon: -8.3967334,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.3967334, 41.40588959999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#54A2C2',
                    name: 'BR010 Famalicao 2 (Joane,Nine),Guimaraes1(Azurem,Brito,Caldelas,SPaio,STorcato,OCastelo,Serzedo »)',
                    market_share: 37.2605,
                    lat: 41.4515701,
                    lon: -8.2861917,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2861917, 41.4515701],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#1599F2',
                    name: 'BR011 Guimaraes 2 (Creixomil, Moreira de Conegos), Vizela (Vizela)',
                    market_share: 35.0924,
                    lat: 41.4377038,
                    lon: -8.302363999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.302363999999999, 41.4377038],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C5B36D',
                    name: 'BR012 Fafe',
                    market_share: 41.116,
                    lat: 41.4508217,
                    lon: -8.1728619,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1728619, 41.4508217],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0897FC',
                    name: 'BR013 Barcelos 3 (Barcelos)',
                    market_share: 34.6352,
                    lat: 41.5335052,
                    lon: -8.619289199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.619289199999999, 41.5335052],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44452',
                    name: 'BR014 Braga 4 (Sao Jose de Sao Lazaro, Sao Joao do Souto)',
                    market_share: 49.1149,
                    lat: 41.5441376,
                    lon: -8.4211639,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4211639, 41.5441376],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F38A49',
                    name: 'BR015 Famalicao 3(Ribeir,Fradel,Lousad,Calend,Caval,Gondif,Louro,Antas,Gav,VNFamal,Land,Verm,Ruiva)',
                    market_share: 45.621,
                    lat: 41.4116818,
                    lon: -8.5224051,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5224051, 41.4116818],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0389E4',
                    name: 'BR016 Cabeceiras de Basto',
                    market_share: 31.9015,
                    lat: 41.5116939,
                    lon: -7.9979753,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9979753, 41.5116939],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EE704C',
                    name: 'BR017 Celorico de Basto',
                    market_share: 46.919,
                    lat: 41.3889737,
                    lon: -7.999703599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.999703599999999, 41.3889737],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0492F7',
                    name: 'CB001 Covilha 1 (Casegas, Sao Martinho, Sao Pedro »)',
                    market_share: 33.7063,
                    lat: 40.177914,
                    lon: -7.6985738,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6985738, 40.177914],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027FCE',
                    name: 'CB002 Covilha 2 (Ferro, Teixoso, Tortosendo, Vila do Carvalho), Belmonte',
                    market_share: 29.8306,
                    lat: 40.315611,
                    lon: -7.4976536,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4976536, 40.315611],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#97AC90',
                    name: 'CB003 CBranco 1 (Alcains, Lardosa, Castelo Branco - N »), VVRodao  (Cebolais de Cima »)',
                    market_share: 39.5463,
                    lat: 39.9163551,
                    lon: -7.4489531,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4489531, 39.9163551],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FDB443',
                    name: 'CB004 CBranco 2 (Castelo Branco - S), Idanha-a-Nova, Penamacor',
                    market_share: 43.479,
                    lat: 39.924752,
                    lon: -7.2415895,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.2415895, 39.924752],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F89C46',
                    name: 'CB005 Oleiros, Proenca-a-Nova, Serta, Vila de Rei',
                    market_share: 44.6761,
                    lat: 39.7492692,
                    lon: -7.924331400000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.924331400000001, 39.7492692],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0491F5',
                    name: 'CB006 Fundao',
                    market_share: 33.5778,
                    lat: 40.137963,
                    lon: -7.501077299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.501077299999999, 40.137963],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E85650',
                    name: 'CO001 Cantanhede 1 (Sepins, Vilamar, Murtede, Febres, Covoes)',
                    market_share: 48.2176,
                    lat: 40.3880011,
                    lon: -8.500711599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.500711599999999, 40.3880011],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F6BB49',
                    name: 'CO002 Cantanhede 2 (Anca, Cadima, Tocha, Cantanhede)',
                    market_share: 42.7933,
                    lat: 40.3257161,
                    lon: -8.6407177,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6407177, 40.3257161],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F48D48',
                    name: 'CO003 Penacova',
                    market_share: 45.4338,
                    lat: 40.2704883,
                    lon: -8.28054,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.28054, 40.2704883],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EC6A4D',
                    name: 'CO004 Tabua, Arganil, Oliveira do Hospital',
                    market_share: 47.2211,
                    lat: 40.218499,
                    lon: -8.0537909,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.0537909, 40.218499],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E03354',
                    name: 'CO006 Coimbra 1 (Eiras, Santa Cruz, Santo Antonio dos Olivais-N)',
                    market_share: 49.9413,
                    lat: 40.2172968,
                    lon: -8.4023333,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4023333, 40.2172968],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F17E4A',
                    name: 'CO007 Coimbra 2 (Ceira, Santo Antonio dos Olivais - S)',
                    market_share: 46.2086,
                    lat: 40.2172968,
                    lon: -8.4023333,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4023333, 40.2172968],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FAA545',
                    name: 'CO008 Coimbra 3 (Santa Clara, Se Nova »)',
                    market_share: 44.2241,
                    lat: 40.2069771,
                    lon: -8.415379399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.415379399999999, 40.2069771],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DD2956',
                    name: 'CO009 Coimbra 4 (São Martinho do Bispo, Trouxemil »)',
                    market_share: 50.4596,
                    lat: 40.2107568,
                    lon: -8.4564966,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4564966, 40.2107568],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F2834A',
                    name: 'CO010 Coimbra 5 (Eiras »)',
                    market_share: 45.9257,
                    lat: 41.1337843,
                    lon: -8.4223704,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4223704, 41.1337843],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B71E58',
                    name: 'CO011 Coimbra 6 (Santa Cruz, Sao Bartolomeu, Se Nova)',
                    market_share: 55.5603,
                    lat: 40.21481259999999,
                    lon: -8.4342267,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4342267, 40.21481259999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#7CA8A5',
                    name: 'CO012 Mont Velho (Montemor-o-Velho, Arazede »)',
                    market_share: 38.6036,
                    lat: 40.2817719,
                    lon: -8.6522166,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6522166, 40.2817719],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#6FA6AF',
                    name: 'CO013 FFoz 1 (Alhadas, Alqueidao, Buarcos »)',
                    market_share: 38.1505,
                    lat: 40.1682124,
                    lon: -8.8816732,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8816732, 40.1682124],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#95AC92',
                    name: 'CO014 FFoz 2 (Marinha das Ondas, Sao Juliao (Figueira da Foz) »)',
                    market_share: 39.4488,
                    lat: 40.15084359999999,
                    lon: -8.8555736,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8555736, 40.15084359999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#AAAF82',
                    name: 'CO015 Soure (Soure, Vila da Rainha »)',
                    market_share: 40.2002,
                    lat: 40.0629162,
                    lon: -8.7270068,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7270068, 40.0629162],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44652',
                    name: 'CO016 Condeixa-a-Nova',
                    market_share: 48.9946,
                    lat: 40.1141535,
                    lon: -8.498116399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.498116399999999, 40.1141535],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44752',
                    name: 'CO017 Miranda do Corvo, Penela, Lousa, VN de Poiares',
                    market_share: 48.951,
                    lat: 40.2107667,
                    lon: -8.25907,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.25907, 40.2107667],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E13754',
                    name: 'CO018 Pampilhosa da Serra, Gois',
                    market_share: 49.773,
                    lat: 40.0460146,
                    lon: -7.951996099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.951996099999999, 40.0460146],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D21359',
                    name: 'CO019 Mira',
                    market_share: 52.4198,
                    lat: 40.4287977,
                    lon: -8.738126099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.738126099999999, 40.4287977],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B8B177',
                    name: 'EV001 Mora, Arraiolos',
                    market_share: 40.6672,
                    lat: 38.723479,
                    lon: -7.9863851,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9863851, 38.723479],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#ECB950',
                    name: 'EV002 Estremoz, Borba, Vila Vicosa, Alandroal, Redondo',
                    market_share: 42.4471,
                    lat: 38.7021331,
                    lon: -7.4036488,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4036488, 38.7021331],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F38749',
                    name: 'EV003 Evora 1 (Santo Antao, Sao Mamede, Evora (Se e Sao Pedro), Senhora da Saude »)',
                    market_share: 45.767,
                    lat: 38.6351836,
                    lon: -7.855835700000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.855835700000001, 38.6351836],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#9EAD8B',
                    name: 'EV004 Montemor-o-Novo, Vendas Novas',
                    market_share: 39.7656,
                    lat: 38.64708,
                    lon: -8.2178641,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2178641, 38.64708],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#81A9A1',
                    name: 'EV005 Viana do Alentejo, Portel, Reng Monsaraz, Mourao',
                    market_share: 38.7759,
                    lat: 38.4518533,
                    lon: -7.3840729,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.3840729, 38.4518533],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#AEB07F',
                    name: 'EV006 Evora 2 (Malagueira, Santo Antao, Sao Mamede,Se e Sao Pedro, Santo Antao)',
                    market_share: 40.3292,
                    lat: 38.5702579,
                    lon: -7.9221795,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9221795, 38.5702579],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44652',
                    name: 'FR001 Aljezur, Monchique',
                    market_share: 48.9887,
                    lat: 37.3123601,
                    lon: -8.6713298,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6713298, 37.3123601],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D31359',
                    name: 'FR002 Lagos, Vila do Bispo',
                    market_share: 52.2428,
                    lat: 37.0829204,
                    lon: -8.9121095,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9121095, 37.0829204],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#1899F0',
                    name: 'FR003 Portimao (Portimao, Alvor, Mexilhoeira Grande)',
                    market_share: 35.2037,
                    lat: 37.130505,
                    lon: -8.5935063,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5935063, 37.130505],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#289BE4',
                    name: 'FR004 Lagoa, Silves (Silves,Armacao Pera,Alcantarilha,Algoz,S Bartolomeu Messines,S Marcos Serra,»)',
                    market_share: 35.724,
                    lat: 37.3592972,
                    lon: -8.376360199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.376360199999999, 37.3592972],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#52A2C5',
                    name: 'FR005 Loule',
                    market_share: 37.1605,
                    lat: 37.1379187,
                    lon: -8.020216,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.020216, 37.1379187],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#5CA3BC',
                    name: 'FR006 Albufeira',
                    market_share: 37.5323,
                    lat: 37.0890719,
                    lon: -8.2478796,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2478796, 37.0890719],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#8CAB99',
                    name: 'FR007 Faro 1(Conceicao, Montenegro, Sao Pedro, Se)',
                    market_share: 39.1429,
                    lat: 37.0193548,
                    lon: -7.9304397,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9304397, 37.0193548],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DFB75A',
                    name: 'FR008 Olhao',
                    market_share: 41.9965,
                    lat: 37.0260416,
                    lon: -7.8423509,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.8423509, 37.0260416],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FAA745',
                    name: 'FR009 Faro 2(Estoi,S Bras Alportel,Se),Tavira 1(Sta Luzia,Conceicao,Luz,Santa Catarina Fonte Bispo)',
                    market_share: 44.122,
                    lat: 37.1335906,
                    lon: -7.643001899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.643001899999999, 37.1335906],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EEB94F',
                    name: 'FR010 VR St Antonio, Alcoutim, Castro Marim',
                    market_share: 42.4908,
                    lat: 37.2179422,
                    lon: -7.4423339,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4423339, 37.2179422],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BDB274',
                    name: 'FR011 Faro 3(Sao Pedro, Se)',
                    market_share: 40.8304,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F07A4B',
                    name: 'FR012 Tavira 2(Santa Maria Tavira, Santiago Tavira)',
                    market_share: 46.4142,
                    lat: 37.1248493,
                    lon: -7.652046399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.652046399999999, 37.1248493],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F38749',
                    name: 'GR001 Meda, Trancoso, VN Foz Coa',
                    market_share: 45.7579,
                    lat: 41.0822466,
                    lon: -7.1403598,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.1403598, 41.0822466],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EB654E',
                    name: 'GR002 Figueira Castelo Rodrigo, Pinhel',
                    market_share: 47.4687,
                    lat: 40.7732736,
                    lon: -7.0685935,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.0685935, 40.7732736],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F4BA4A',
                    name: 'GR003 Aguiar da Beira, Fornos de Algodres, Celorico da Beira',
                    market_share: 42.7164,
                    lat: 40.6335005,
                    lon: -7.3927217,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.3927217, 40.6335005],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#6FA6AE',
                    name: 'GR004 Guarda (Goncalo, Sao Vicente, Trinta, Se)',
                    market_share: 38.1779,
                    lat: 40.5408397,
                    lon: -7.2560056,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.2560056, 40.5408397],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DF3155',
                    name: 'GR005 Gouveia, Manteigas',
                    market_share: 50.0659,
                    lat: 40.4022206,
                    lon: -7.538339300000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.538339300000001, 40.4022206],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DA1A58',
                    name: 'GR006 Seia (Seia, Vide, Loriga, Sao Romao, Torrozelo)',
                    market_share: 51.2117,
                    lat: 40.4148619,
                    lon: -7.707845000000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.707845000000001, 40.4148619],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C7B36C',
                    name: 'GR007 Sabugal, Almeida',
                    market_share: 41.1686,
                    lat: 40.3516608,
                    lon: -7.089524099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.089524099999999, 40.3516608],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D91558',
                    name: 'LR001 Pombal 1 (Guia, Almagreira, Pombal »)',
                    market_share: 51.4559,
                    lat: 39.9918999,
                    lon: -8.6620705,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6620705, 39.9918999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B12057',
                    name: 'LR002 Pombal 2 (Albergaria dos Doze, Carnide »)',
                    market_share: 56.2849,
                    lat: 39.7991856,
                    lon: -8.5820059,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5820059, 39.7991856],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EE734C',
                    name: 'LR003 Ansiao, Alvaiazere, Figueiro dos Vinhos, Castanheira Pera, Pedrogao Grande)',
                    market_share: 46.7341,
                    lat: 39.917835,
                    lon: -8.1458099,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1458099, 39.917835],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CB1658',
                    name: 'LR004 Leiria 1 (Monte Real, Sta Catarina da Serra, Barreira, Memoria »)',
                    market_share: 53.2049,
                    lat: 39.8412644,
                    lon: -8.8748765,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8748765, 39.8412644],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A12657',
                    name: 'LR005 Leiria 2 (Marrazes)',
                    market_share: 58.1547,
                    lat: 39.763454,
                    lon: -8.805053899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.805053899999999, 39.763454],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#9D2757',
                    name: 'LR006 Leiria 3 (Leiria, Maceira)',
                    market_share: 58.5435,
                    lat: 39.6823024,
                    lon: -8.8786256,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8786256, 39.6823024],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D71159',
                    name: 'LR007 Leiria 4 (Leiria, Barosa, Amor)',
                    market_share: 51.7281,
                    lat: 39.74953310000001,
                    lon: -8.807682999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.807682999999999, 39.74953310000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44452',
                    name: 'LR008 Marinha Grande',
                    market_share: 49.0908,
                    lat: 39.7503802,
                    lon: -8.931805899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.931805899999999, 39.7503802],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#8F2D56',
                    name: 'LR009 Batalha, Porto de Mos 1(Mira de Aire, Alqueidao da Serra)',
                    market_share: 60.2446,
                    lat: 39.5411755,
                    lon: -8.715083100000001,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.715083100000001, 39.5411755],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C11A58',
                    name: 'LR010 Nazare',
                    market_share: 54.3401,
                    lat: 39.6012147,
                    lon: -9.0700991,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0700991, 39.6012147],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#AE2157',
                    name: 'LR011 Alcobaca 1 (Aljubarrota (Prazeres), Barrio, Pataias, Alcobaca)',
                    market_share: 56.5443,
                    lat: 39.5489981,
                    lon: -8.9767266,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9767266, 39.5489981],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F89F46',
                    name: 'LR012 Alcobaca 2 (Benedita, Turquel, Alfeizerao, S Martinho Porto, Vimeiro)',
                    market_share: 44.5403,
                    lat: 39.1782666,
                    lon: -9.3169929,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3169929, 39.1782666],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DCB75C',
                    name: 'LR013 Caldas Rainha (Vidais, A dos Francos, Foz do Arelho, Sto Onofre, N Sra Populo »)',
                    market_share: 41.8886,
                    lat: 39.4030187,
                    lon: -9.152151,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.152151, 39.4030187],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FDBC43',
                    name: 'LR015 Obidos, Bombarral',
                    market_share: 43.0251,
                    lat: 39.2680991,
                    lon: -9.1585113,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1585113, 39.2680991],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#84A99E',
                    name: 'LR016 Peniche',
                    market_share: 38.9004,
                    lat: 39.3603291,
                    lon: -9.387622,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.387622, 39.3603291],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DF3055',
                    name: 'LR017 Porto de Mos 2(Calvaria de Cima, Juncal, Sao Pedro, Serro Ventoso)',
                    market_share: 50.0923,
                    lat: 39.6018396,
                    lon: -8.900817199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.900817199999999, 39.6018396],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A146',
                    name: 'LX001 Lourinha',
                    market_share: 44.4315,
                    lat: 39.2414853,
                    lon: -9.3129511,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3129511, 39.2414853],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CBB469',
                    name: 'LX002 Cadaval, Azambuja',
                    market_share: 41.3009,
                    lat: 39.2433402,
                    lon: -9.1027805,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1027805, 39.2433402],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#58A3C0',
                    name: 'LX003 Alenquer (Abrigada, Aldeia Galega da Merceana, Carregado »)',
                    market_share: 37.367,
                    lat: 39.0820899,
                    lon: -9.1125693,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1125693, 39.0820899],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E75150',
                    name: 'LX004 T Vedras 1 (Silveira, A dos Cunhados, Ponte do Rol, Santa Maria e Sao Miguel)',
                    market_share: 48.4564,
                    lat: 39.0979879,
                    lon: -9.3173746,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3173746, 39.0979879],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E0B759',
                    name: 'LX005 T Vedras 2 (Sao Pedro e Sao Tiago, Campelos, Maxial »)',
                    market_share: 42.0231,
                    lat: 39.0936028,
                    lon: -9.2572667,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2572667, 39.0936028],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#AAAF82',
                    name: 'LX006 Mafra 1(Igreja Nova, Mafra, Sobral da Abelheira, Encarnacao, »), Ericeira',
                    market_share: 40.1682,
                    lat: 38.9664933,
                    lon: -9.4176169,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.4176169, 38.9664933],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C6B36D',
                    name: 'LX007 VF Xira 1 (Castanheira do Ribatejo, VF Xira, Alhandra)',
                    market_share: 41.1529,
                    lat: 38.9273718,
                    lon: -9.009137,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.009137, 38.9273718],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D5B661',
                    name: 'LX008 VF Xira 2 (Alverca do Ribatejo, Povoa de Sta Iria, Vialonga)',
                    market_share: 41.6654,
                    lat: 38.8615606,
                    lon: -9.0633125,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0633125, 38.8615606],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0390F2',
                    name: 'LX009 Loures 1 (Portela, Moscavide)',
                    market_share: 33.2357,
                    lat: 38.7819922,
                    lon: -9.1082647,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1082647, 38.7819922],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F0794B',
                    name: 'LX010 Loures 2 (Prior Velho, Sacavem)',
                    market_share: 46.4326,
                    lat: 38.7957214,
                    lon: -9.1151801,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1151801, 38.7957214],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EA5F4F',
                    name: 'LX011 Loures 3 (Camarate, Santo Antonio de Cavaleiros, Santa Iria de Azoia »)',
                    market_share: 47.7622,
                    lat: 38.8137476,
                    lon: -9.1640362,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1640362, 38.8137476],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FFBA42',
                    name: 'LX012 Loures 4 (Lousa, Bucelas, Fanhoes, Sto Antonio do Tojal, Loures)',
                    market_share: 43.183,
                    lat: 38.8615627,
                    lon: -9.1506447,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1506447, 38.8615627],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F38849',
                    name: 'LX013 Odivelas 1 (Pontinha)',
                    market_share: 45.685,
                    lat: 38.7779317,
                    lon: -9.2092897,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2092897, 38.7779317],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EF764B',
                    name: 'LX014 Odivelas 2 (Olival Basto, Povoa de Santo Adriao, Odivelas)',
                    market_share: 46.6153,
                    lat: 38.7912926,
                    lon: -9.1633678,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1633678, 38.7912926],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F48A49',
                    name: 'LX015 Amadora 1 (Alfragide, Buraca, Damaia, Reboleira, Venteira, Falagueira)',
                    market_share: 45.5858,
                    lat: 38.7471779,
                    lon: -9.2197802,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2197802, 38.7471779],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F3BA4B',
                    name: 'LX016 Amadora 2 (Brandoa)',
                    market_share: 42.6746,
                    lat: 38.7683384,
                    lon: -9.2173458,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2173458, 38.7683384],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C7B36C',
                    name: 'LX017 Amadora 3 (Venteira, Mina)',
                    market_share: 41.1667,
                    lat: 38.758785,
                    lon: -9.2437861,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2437861, 38.758785],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F48E48',
                    name: 'LX018 Sintra 1 (Belas, Casal de Cambra,  Monte Abraao)',
                    market_share: 45.4127,
                    lat: 38.8026373,
                    lon: -9.2278328,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2278328, 38.8026373],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F0BA4E',
                    name: 'LX019 Sintra 2 (Colares,SJLampas,Terrug,AlmBispo,Montelav,PPinheiro,SPPenafer,SMiguel,AlgMMartins»)',
                    market_share: 42.5628,
                    lat: 38.8031844,
                    lon: -9.449783,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.449783, 38.8031844],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#61A4B9',
                    name: 'LX020 Sintra 3 (Queluz)',
                    market_share: 37.7056,
                    lat: 38.7573981,
                    lon: -9.2587453,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2587453, 38.7573981],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F07A4B',
                    name: 'LX021 Sintra 4 (Rio de Mouro, Cacem, Sao Marcos, Agualva, Mira-Sintra, Massama)',
                    market_share: 46.3888,
                    lat: 38.784473,
                    lon: -9.321642,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.321642, 38.784473],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FDB143',
                    name: 'LX022 Cascais 1 (Alcabideche, Cascais, Estoril)',
                    market_share: 43.6217,
                    lat: 38.7133152,
                    lon: -9.3936558,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3936558, 38.7133152],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F28549',
                    name: 'LX023 Cascais 2 (Sao Domingos de Rana)',
                    market_share: 45.8553,
                    lat: 38.7123876,
                    lon: -9.3389678,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3389678, 38.7123876],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44652',
                    name: 'LX024 Cascais 3 (Carcavelos)',
                    market_share: 49.0261,
                    lat: 38.6879164,
                    lon: -9.3378114,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3378114, 38.6879164],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F59148',
                    name: 'LX025 Oeiras 1 (Oeiras-W e Sao Juliao Barra)',
                    market_share: 45.2247,
                    lat: 38.6920034,
                    lon: -9.3186852,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3186852, 38.6920034],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#8DAB98',
                    name: 'LX026 Oeiras 2 (Barcarena, Paco de Arcos, Porto Salvo)',
                    market_share: 39.1872,
                    lat: 38.696797,
                    lon: -9.2915896,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2915896, 38.696797],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F69447',
                    name: 'LX027 Oeiras 3 (Carnaxide, Queijas)',
                    market_share: 45.0849,
                    lat: 38.7238227,
                    lon: -9.23699,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.23699, 38.7238227],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0387DF',
                    name: 'LX028 Oeiras 4 (Linda a Velha)',
                    market_share: 31.4534,
                    lat: 38.7149733,
                    lon: -9.2416491,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2416491, 38.7149733],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EFBA4E',
                    name: 'LX029 Lisboa 1 (Sta Maria de Belem, S Francisco de Xavier)',
                    market_share: 42.5539,
                    lat: 38.6991685,
                    lon: -9.2203687,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2203687, 38.6991685],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D0B565',
                    name: 'LX030 Lisboa 2 (Ajuda, Alcantara, Sta Maria de Belem)',
                    market_share: 41.4861,
                    lat: 38.703621,
                    lon: -9.1956117,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1956117, 38.703621],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44752',
                    name: 'LX031 Lisboa 3 (Alcantara-N)',
                    market_share: 48.9424,
                    lat: 38.7059957,
                    lon: -9.1827535,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1827535, 38.7059957],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F2BA4B',
                    name: 'LX032 Lisboa 4 (Lapa, Prazeres, Santos-o-Velho, Sao Paulo, Prazeres, Lapa)',
                    market_share: 42.6603,
                    lat: 38.709909,
                    lon: -9.1610726,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1610726, 38.709909],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#3E9FD3',
                    name: 'LX033 Lisboa 5 (Sta Isabel, Coracao de Jesus, S Mamede, S Sebastiao da Pedreira)',
                    market_share: 36.4851,
                    lat: 38.7244377,
                    lon: -9.146999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.146999, 38.7244377],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F0784B',
                    name: 'LX034 Lisboa 6 (Merces, Sao Mamede, Coracao de Jesus »)',
                    market_share: 46.4857,
                    lat: 38.7188082,
                    lon: -9.152534,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.152534, 38.7188082],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DDB75C',
                    name: 'LX035 Lisboa 7 (Anjos, Pena)',
                    market_share: 41.9265,
                    lat: 38.7263148,
                    lon: -9.1349164,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1349164, 38.7263148],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0176BE',
                    name: 'LX036 Lisboa 8 (Sao Jorge de Arroios, Penha de Franca, Sao Joao)',
                    market_share: 28.2042,
                    lat: 38.7259582,
                    lon: -9.1276295,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1276295, 38.7259582],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D2B564',
                    name: 'LX037 Lisboa 9 (Madalena, Sao Nicolau, Sta Justa)',
                    market_share: 41.5506,
                    lat: 38.7222524,
                    lon: -9.1393366,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1393366, 38.7222524],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E75250',
                    name: 'LX038 Lisboa 10 (Sta Justa, Socorro, Santiago, Sto Estevao, S Miguel »)',
                    market_share: 48.4235,
                    lat: 38.7124614,
                    lon: -9.1379767,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1379767, 38.7124614],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B3B07B',
                    name: 'LX039 Lisboa 11 (Campolide, Campolide, Sao Domingos de Benfica)',
                    market_share: 40.4842,
                    lat: 38.7258789,
                    lon: -9.1627085,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1627085, 38.7258789],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EF754C',
                    name: 'LX040 Lisboa 12 (Sao Sebastiao da Pedreia, Sao Jorge de Arroios)',
                    market_share: 46.6448,
                    lat: 38.7363912,
                    lon: -9.1451223,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1451223, 38.7363912],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#79A8A7',
                    name: 'LX041 Lisboa 13 (SSPedr-NE)',
                    market_share: 38.5141,
                    lat: 38.7142511,
                    lon: -9.1359734,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1359734, 38.7142511],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2C9CE1',
                    name: 'LX042 Lisboa 14 (Nossa Senhora de Fatima, S Joao de Deus, S Jorge de Arroios)',
                    market_share: 35.883,
                    lat: 39.6172063,
                    lon: -8.6521422,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6521422, 39.6172063],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A7AF84',
                    name: 'LX043 Lisboa 15 (Sao Joao de Deus, Alvalade)',
                    market_share: 40.094,
                    lat: 38.745489,
                    lon: -9.1345755,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1345755, 38.745489],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#6BA6B1',
                    name: 'LX044 Lisboa 16 (Campo Grande, Lumiar, Ameixoeira, Charneca)',
                    market_share: 38.0442,
                    lat: 38.7584429,
                    lon: -9.1583507,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1583507, 38.7584429],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E13854',
                    name: 'LX045 Lisboa 17 (Sao Joao de Brito, Campo Grande)',
                    market_share: 49.6898,
                    lat: 38.7541381,
                    lon: -9.1532229,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1532229, 38.7541381],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2F9DDF',
                    name: 'LX046 Lisboa 18 (Benfica, Sao Domingues de Benfica, Carnide)',
                    market_share: 35.9825,
                    lat: 38.7641133,
                    lon: -9.1871364,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1871364, 38.7641133],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#8EAB97',
                    name: 'LX047 Lisboa 19 (Carnide, Lumiar)',
                    market_share: 39.237,
                    lat: 38.7641133,
                    lon: -9.1871364,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1871364, 38.7641133],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E23C53',
                    name: 'LX048 Lisboa 20 (Benfica)',
                    market_share: 49.5185,
                    lat: 38.7510519,
                    lon: -9.2026693,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2026693, 38.7510519],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FDB343',
                    name: 'LX049 Lisboa 21 (Sao Joao, Sao Jorge de Arroios, Alto do Pina)',
                    market_share: 43.5277,
                    lat: 38.741539,
                    lon: -9.1276295,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1276295, 38.741539],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D8B660',
                    name: 'LX050 Lisboa 22 (Marvila, Beato)',
                    market_share: 41.7493,
                    lat: 38.7324804,
                    lon: -9.1097188,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1097188, 38.7324804],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D8B660',
                    name: 'LX051 Lisboa 23 (Marvila, Olivais-SE)',
                    market_share: 41.7492,
                    lat: 38.7513851,
                    lon: -9.1179465,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1179465, 38.7513851],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F2814A',
                    name: 'LX052 Lisboa 24 (Sta Maria dos Olivais)',
                    market_share: 46.0368,
                    lat: 38.7712833,
                    lon: -9.129012800000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.129012800000002, 38.7712833],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027FCF',
                    name: 'LX053 T Vedras 3 (Freiria, Ventosa, Turcifal, Sao Pedro da Cadeira)',
                    market_share: 29.9194,
                    lat: 39.02567090000001,
                    lon: -9.3173746,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3173746, 39.02567090000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C5B36E',
                    name: 'LX054 Mafra 2(Malveira, Vila Franca do Rosario, Gradil), Sobral Monte Agraco, Arruda dos Vinhos',
                    market_share: 41.1129,
                    lat: 38.9835516,
                    lon: -9.0774591,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0774591, 38.9835516],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E34053',
                    name: 'LX055 Odivelas 3 (Ramada, Canecas)',
                    market_share: 49.2929,
                    lat: 38.8161817,
                    lon: -9.2175988,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2175988, 38.8161817],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E03255',
                    name: 'LX056 Amadora 4 (Sao Bras, Falagueira)',
                    market_share: 49.9961,
                    lat: 38.7813644,
                    lon: -9.2314492,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2314492, 38.7813644],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F48D48',
                    name: 'LX057 Cascais 4 (Parede)',
                    market_share: 45.4567,
                    lat: 38.69291399999999,
                    lon: -9.3553319,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3553319, 38.69291399999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#1F9AEB',
                    name: 'LX058 Oeiras 5 (Oeiras-E e Sao Juliao Barra)',
                    market_share: 35.4178,
                    lat: 38.6919934,
                    lon: -9.3118418,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.3118418, 38.6919934],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#94AC92',
                    name: 'LX059 Oeiras 6 (Alges, Cruz Quebrada)',
                    market_share: 39.4427,
                    lat: 38.7022246,
                    lon: -9.2296032,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2296032, 38.7022246],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EA5F4E',
                    name: 'LX061 Lisboa 25 (Santo Condestavel)',
                    market_share: 47.7364,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FDBC43',
                    name: 'LX063 Lisboa 27(Sao Paulo, Merces, St Catatina, Sacramento, Encarnacao, Martires)',
                    market_share: 43.0229,
                    lat: 38.7222524,
                    lon: -9.1393366,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1393366, 38.7222524],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B6B179',
                    name: 'LX064 Lisboa 28 (Sao Domingos de Benfica)',
                    market_share: 40.5815,
                    lat: 38.7500101,
                    lon: -9.1685614,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1685614, 38.7500101],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FFBA42',
                    name: 'LX065 Lisboa 28 (Nossa Senhora de Fatima, Alvalade)',
                    market_share: 43.1767,
                    lat: 38.7477561,
                    lon: -9.139388900000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.139388900000002, 38.7477561],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A345',
                    name: 'LX066 Lisboa 29 (Olivais-S, W)',
                    market_share: 44.3247,
                    lat: 38.7712833,
                    lon: -9.129012800000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.129012800000002, 38.7712833],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038BE7',
                    name: 'MD001 Madeira, Porto Santo',
                    market_share: 32.2297,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F79A47',
                    name: 'PG001 Nisa',
                    market_share: 44.7794,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#9EAD8B',
                    name: 'PG002 Portalegre (Alagoa, Fortios, S Lourenco, Alegrete, Se)',
                    market_share: 39.7584,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B7B178',
                    name: 'PG003 Ponte de Sor, Avis, Sousel, Alter Chao',
                    market_share: 40.6234,
                    lat: 39.28602840000001,
                    lon: -7.9701846,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9701846, 39.28602840000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FAA645',
                    name: 'PG004 Fronteira, Monforte, Arronches, Campo Maior, Elvas',
                    market_share: 44.1846,
                    lat: 39.0207079,
                    lon: -7.0642042,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.0642042, 39.0207079],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CF1459',
                    name: 'PG005 Gaviao, Castelo de Vide, Marvao, Crato',
                    market_share: 52.7182,
                    lat: null,
                    lon: null,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [null, null],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A046',
                    name: 'PO001 P Varzim 1(Balazar, Estela, Rates, A Ver-o-Mar, Amorim, Agucadoura)',
                    market_share: 44.5051,
                    lat: 41.3828815,
                    lon: -8.759989299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.759989299999999, 41.3828815],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A7AF85',
                    name: 'PO002 Vila do Conde 1 (Macieira da Maia, Junqueira, Azurara, Vila do Conde)',
                    market_share: 40.0656,
                    lat: 41.347023,
                    lon: -8.7370252,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7370252, 41.347023],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2C9CE1',
                    name: 'PO003 Vila do Conde 2(Guilhabreu, Labruge, Mindelo »)',
                    market_share: 35.8845,
                    lat: 41.2781543,
                    lon: -8.718171,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.718171, 41.2781543],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#9BAD8D',
                    name: 'PO004 Sto Tirso 1 (Areias, Santo Tirso)',
                    market_share: 39.6869,
                    lat: 41.3633525,
                    lon: -8.4760011,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4760011, 41.3633525],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#006BA6',
                    name: 'PO005 Sto Tirso 2 (S Tome de Negrelos, Vilarinho, S Martinho Campos »)',
                    market_share: 25.9321,
                    lat: 41.348127,
                    lon: -8.4003255,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4003255, 41.348127],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44552',
                    name: 'PO006 Sto Tirso 3 (Agrela »), P Ferreira (Pacos de Ferriea, Freamunde »)',
                    market_share: 49.0522,
                    lat: 41.2865608,
                    lon: -8.338962,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.338962, 41.2865608],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EE704C',
                    name: 'PO007 Lousada (Aveleda, Lustosa »), Felgueiras 1 (Idaes, Regilde »)',
                    market_share: 46.8933,
                    lat: 41.2774793,
                    lon: -8.2837376,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2837376, 41.2774793],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E23D53',
                    name: 'PO008 Felgueiras 2 (Margaride Sta Eulalia, Lixa, Moure, Santao »)',
                    market_share: 49.4557,
                    lat: 41.3654043,
                    lon: -8.198755499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.198755499999999, 41.3654043],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F79A47',
                    name: 'PO009 Baiao',
                    market_share: 44.7969,
                    lat: 41.16177150000001,
                    lon: -8.035514,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.035514, 41.16177150000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D6B661',
                    name: 'PO010 Amarante',
                    market_share: 41.6899,
                    lat: 41.26963569999999,
                    lon: -8.0826488,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.0826488, 41.26963569999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E64F51',
                    name: 'PO011 MCanaveses, Penafiel',
                    market_share: 48.5399,
                    lat: 41.2050452,
                    lon: -8.2875175,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2875175, 41.2050452],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D5B661',
                    name: 'PO012 Paredes (Madalena, Baltar, Lordelo, Rebordosa »)',
                    market_share: 41.6603,
                    lat: 41.2053076,
                    lon: -8.3304861,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.3304861, 41.2053076],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F89F46',
                    name: 'PO013 Valongo 1(Campo, Sobrado, Valongo, Alfena)',
                    market_share: 44.536,
                    lat: 41.1937533,
                    lon: -8.5017966,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5017966, 41.1937533],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F58F48',
                    name: 'PO014 Maia 1 (Aguas Santas, Pedroucos)',
                    market_share: 45.3582,
                    lat: 41.1888,
                    lon: -8.5874796,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5874796, 41.1888],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F69547',
                    name: 'PO015 Maia 2 (Avioso (Santa Maria), Maia)',
                    market_share: 45.0469,
                    lat: 41.267274,
                    lon: -8.6062677,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6062677, 41.267274],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DA1B58',
                    name: 'PO016 Valongo 2(Ermesinde)',
                    market_share: 51.1526,
                    lat: 41.219536,
                    lon: -8.5522002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5522002, 41.219536],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FCAE44',
                    name: 'PO017 Maia 4 (Moreira, Vila Nova da Telha)',
                    market_share: 43.7847,
                    lat: 41.2531913,
                    lon: -8.668614,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.668614, 41.2531913],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F79A47',
                    name: 'PO018 Matosinhos 2 (Matosinhos)',
                    market_share: 44.7967,
                    lat: 41.1844362,
                    lon: -8.696277499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.696277499999999, 41.1844362],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E23E53',
                    name: 'PO019 Matosinhos 3 (Custoias, Guifoes, Sta Cruz do Bispo)',
                    market_share: 49.4169,
                    lat: 41.1971825,
                    lon: -8.6682332,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6682332, 41.1971825],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DB2057',
                    name: 'PO020 Matosinhos 4 (Senhora da Hora, Leca do Balio, S Mamede de Infesta)',
                    market_share: 50.9016,
                    lat: 41.183583,
                    lon: -8.6518083,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6518083, 41.183583],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D0B565',
                    name: 'PO021 Porto 1 (Nevogilde, Foz do Douro, Lordelo do Ouro, Aldoar)',
                    market_share: 41.4871,
                    lat: 41.1517951,
                    lon: -8.674636099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.674636099999999, 41.1517951],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2C9CE1',
                    name: 'PO022 Porto 2 (Foz do Douro, Lordelo do Ouro, Massarelos)',
                    market_share: 35.8875,
                    lat: 41.1517951,
                    lon: -8.674636099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.674636099999999, 41.1517951],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#91AB95',
                    name: 'PO023 Porto 3 (Cedofeita, Ramalde)',
                    market_share: 39.3405,
                    lat: 41.1490117,
                    lon: -8.6163972,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6163972, 41.1490117],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0492F6',
                    name: 'PO024 Porto 4 (Ramalde)',
                    market_share: 33.6516,
                    lat: 41.1663721,
                    lon: -8.6498773,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6498773, 41.1663721],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EA5E4F',
                    name: 'PO025 Porto 5 (Paranhos, Ramalde)',
                    market_share: 47.8144,
                    lat: 41.1663721,
                    lon: -8.6498773,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6498773, 41.1663721],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#43A0CF',
                    name: 'PO026 Porto 6 (Cedofeita, Paranhos)',
                    market_share: 36.6741,
                    lat: 41.14894719999999,
                    lon: -8.6162177,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6162177, 41.14894719999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CCB468',
                    name: 'PO027 Porto 7 (Bonfim, Paranhos)',
                    market_share: 41.3564,
                    lat: 41.1753841,
                    lon: -8.607517,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.607517, 41.1753841],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F07B4B',
                    name: 'PO028 Porto 8 (Bonfim, Campanha)',
                    market_share: 46.3429,
                    lat: 41.1540226,
                    lon: -8.5919779,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5919779, 41.1540226],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B7B178',
                    name: 'PO029 Porto 9 (Bonfim, Cedofeita, Sto Ildefonso)',
                    market_share: 40.6214,
                    lat: 41.1464714,
                    lon: -8.604028,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.604028, 41.1464714],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DDB75B',
                    name: 'PO030 Porto 10 (Bonfim, Sto Ildefonso)',
                    market_share: 41.9331,
                    lat: 41.1579438,
                    lon: -8.629105299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.629105299999999, 41.1579438],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DCB75C',
                    name: 'PO031 Porto 11 (Sto Ildefonso, Se)',
                    market_share: 41.8984,
                    lat: 41.1433171,
                    lon: -8.609144100000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.609144100000002, 41.1433171],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44552',
                    name: 'PO032 Porto 12 (Sao Nicolau, Vitoria, Se, Cedofeita, Miragaia)',
                    market_share: 49.0701,
                    lat: 41.14069500000001,
                    lon: -8.6148768,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6148768, 41.14069500000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F59248',
                    name: 'PO033 Gondomar 1 (Valbom, Sao Cosme), VN Gaia 1 (Oliveira do Douro)',
                    market_share: 45.207,
                    lat: 41.12982299999999,
                    lon: -8.5619497,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5619497, 41.12982299999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EB654E',
                    name: 'PO034 Gondomar 2 (Rio Tinto, Baguim do Monte)',
                    market_share: 47.4756,
                    lat: 41.1918929,
                    lon: -8.541168400000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.541168400000002, 41.1918929],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DB1C58',
                    name: 'PO035 Gondomar 3 (Fanzeres, S Pedro da Cova, Foz do Sousa »)',
                    market_share: 51.1105,
                    lat: 41.1521455,
                    lon: -8.510187499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.510187499999999, 41.1521455],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#45A0CE',
                    name: 'PO036 VN Gaia 2 (Canidelo, Santa Marinha, Sao Pedro da Afurada, Canidelo)',
                    market_share: 36.7362,
                    lat: 41.1233052,
                    lon: -8.6461724,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6461724, 41.1233052],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#1F9AEB',
                    name: 'PO037 VN Gaia 3 (Madalena, Vilar do Paraiso)',
                    market_share: 35.4377,
                    lat: 41.0891434,
                    lon: -8.612393899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.612393899999999, 41.0891434],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F0BA4D',
                    name: 'PO038 VN Gaia 4 (Valadares, Vilar do Paraiso, Arcozelo, Gulpilhares)',
                    market_share: 42.5757,
                    lat: 41.0517695,
                    lon: -8.6435589,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6435589, 41.0517695],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C8B46B',
                    name: 'PO039 VN Gaia 5 (Serzedo, S Felix da Marinha)',
                    market_share: 41.2252,
                    lat: 41.0498582,
                    lon: -8.6150022,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6150022, 41.0498582],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C1B370',
                    name: 'PO040 VN Gaia 6 (Crestuma, Lever, Grijo, Perosinho, Seixezelo »)',
                    market_share: 40.9847,
                    lat: 41.0728337,
                    lon: -8.4866271,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4866271, 41.0728337],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#83A99F',
                    name: 'PO041 VN Gaia 7 (Avintes, Vilar de Andorinho, Pedroso, Mafamude, Sta Marinha)',
                    market_share: 38.8558,
                    lat: 41.1252976,
                    lon: -8.601839499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.601839499999999, 41.1252976],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#66A5B5',
                    name: 'PO042 P Varzim 2 (Povoa de Varzim)',
                    market_share: 37.8564,
                    lat: 41.3828815,
                    lon: -8.759989299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.759989299999999, 41.3828815],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B5B17A',
                    name: 'PO043 Maia 3 (Gueifaes, Vermoim)',
                    market_share: 40.5427,
                    lat: 41.4229179,
                    lon: -8.4485846,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4485846, 41.4229179],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FEB643',
                    name: 'PO044 Trofa (Muro, Sao Mamede Coronado, Sao Romao Coronado, Alvarelhos, Bougado)',
                    market_share: 43.405,
                    lat: 41.282184,
                    lon: -8.56904,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.56904, 41.282184],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B31F57',
                    name: 'PO045 Maia 5 (Sao Pedro Fins, Silva Escura, Milheiros, Nogueira)',
                    market_share: 55.9672,
                    lat: 41.2571491,
                    lon: -8.581362799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.581362799999999, 41.2571491],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E75250',
                    name: 'PO046 Matosinhos 1 (Leca da Palmeira, Lavra, Perafita)',
                    market_share: 48.3914,
                    lat: 41.2014865,
                    lon: -8.6962733,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6962733, 41.2014865],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DF2F55',
                    name: 'PO047 Porto13 (Campanha)',
                    market_share: 50.1484,
                    lat: 41.16136789999999,
                    lon: -8.5763932,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5763932, 41.16136789999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9BB46',
                    name: 'PO048 VN Gaia 8 (Mafamude)',
                    market_share: 42.896,
                    lat: 41.1252976,
                    lon: -8.601839499999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.601839499999999, 41.1252976],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D01459',
                    name: 'SR001 Ourem (Fatima, Alburitel, Nossa Sra da Piedade, Caxarias, Urq »)',
                    market_share: 52.6192,
                    lat: 39.642683,
                    lon: -8.5226706,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5226706, 39.642683],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#9B2956',
                    name: 'SR002 Tomar, Ferreira do Zezere',
                    market_share: 58.8784,
                    lat: 39.6932144,
                    lon: -8.2905257,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2905257, 39.6932144],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E33F53',
                    name: 'SR003 Torres Novas (Sta Maria, Santiago, S Pedro, Olaia, Paco, Riachos »)',
                    market_share: 49.3465,
                    lat: 39.4790062,
                    lon: -8.540103,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.540103, 39.4790062],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D41359',
                    name: 'SR004 Entroncamento, Vila Nova da Barquinha, Golega',
                    market_share: 52.1531,
                    lat: 39.4611239,
                    lon: -8.478759,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.478759, 39.4611239],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DC2357',
                    name: 'SR005 Macao, Sardoal',
                    market_share: 50.767,
                    lat: 39.5375181,
                    lon: -8.1612773,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1612773, 39.5375181],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D8B65F',
                    name: 'SR006 Santarem 1 (Sao Salvador, Sao Nicolau)',
                    market_share: 41.7669,
                    lat: 39.2848804,
                    lon: -8.704075,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.704075, 39.2848804],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FCAE44',
                    name: 'SR007 Alcanena',
                    market_share: 43.7983,
                    lat: 39.4595628,
                    lon: -8.666868899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.666868899999999, 39.4595628],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BAB176',
                    name: 'SR008 Santarem 2 (Achete, Alcanhoes, Pernes, Vale de Figueira), Alpiarca',
                    market_share: 40.7141,
                    lat: 39.2929167,
                    lon: -8.665559199999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.665559199999999, 39.2929167],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44752',
                    name: 'SR009 Cartaxo 1 (Ereira, Pontevel, Valada, Vale da Pedra, Vila Cha de Ourique)',
                    market_share: 48.9762,
                    lat: 39.1178806,
                    lon: -8.7976937,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7976937, 39.1178806],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BB1C58',
                    name: 'SR010 Salvaterra de Magos',
                    market_share: 55.0573,
                    lat: 39.0272019,
                    lon: -8.794182,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.794182, 39.0272019],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C4B36E',
                    name: 'SR011 Constancia, Coruche, Chamusca',
                    market_share: 41.0713,
                    lat: 39.1687671,
                    lon: -8.2757081,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2757081, 39.1687671],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C11A58',
                    name: 'SR012 Abrantes',
                    market_share: 54.4215,
                    lat: 39.4630563,
                    lon: -8.1995808,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.1995808, 39.4630563],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FDBC44',
                    name: 'SR013 Santarem 3 (Alcanede,AmBaixo,Tremes,Abitureiras,Almoster,P Isenta,Vale Sant,Varzea),Rio Maior',
                    market_share: 43.018,
                    lat: 39.3148294,
                    lon: -8.7821951,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7821951, 39.3148294],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EC6A4D',
                    name: 'SR014 Santarem 4 (Marvila, Sao Nicolau, S Salvador)',
                    market_share: 47.214,
                    lat: 39.2848804,
                    lon: -8.704075,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.704075, 39.2848804],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FCBC44',
                    name: 'SR015 Alpiarca, Alameirim',
                    market_share: 42.983,
                    lat: 39.2119734,
                    lon: -8.6254394,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6254394, 39.2119734],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E1B759',
                    name: 'SR016 Cartaxo 2 (Cartaxo)',
                    market_share: 42.0491,
                    lat: 39.16181660000001,
                    lon: -8.7888254,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7888254, 39.16181660000001],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F89D46',
                    name: 'SR017 Benavente',
                    market_share: 44.6364,
                    lat: 38.9817395,
                    lon: -8.8095549,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8095549, 38.9817395],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FAA745',
                    name: 'ST001 Almada 1 (Costa da Caparica, Trafaria)',
                    market_share: 44.1251,
                    lat: 38.670973,
                    lon: -9.2323084,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.2323084, 38.670973],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A9AF83',
                    name: 'ST002 Almada 2 (Charneca da Caparica, Sobreda)',
                    market_share: 40.1493,
                    lat: 38.6279559,
                    lon: -9.1973177,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1973177, 38.6279559],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FCAF44',
                    name: 'ST003 Almada 3 (Feijo, Laranjeiro)',
                    market_share: 43.7476,
                    lat: 38.65467,
                    lon: -9.1634774,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1634774, 38.65467],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F69647',
                    name: 'ST004 Almada 4 (Cova da Piedade)',
                    market_share: 45.019,
                    lat: 38.6703794,
                    lon: -9.1595477,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1595477, 38.6703794],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F07B4B',
                    name: 'ST005 Almada 5 (Almada, Cacilhas, Pragal)',
                    market_share: 46.3273,
                    lat: 38.6861358,
                    lon: -9.1492754,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1492754, 38.6861358],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F38949',
                    name: 'ST006 Seixal 1 (Arrentela, Fernao Ferro)',
                    market_share: 45.6382,
                    lat: 38.626648,
                    lon: -9.1038399,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1038399, 38.626648],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F69647',
                    name: 'ST007 Seixal 2 (Seixal, Aldeia de Paio Pires)',
                    market_share: 44.9825,
                    lat: 38.6410676,
                    lon: -9.0975,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0975, 38.6410676],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#ED6D4D',
                    name: 'ST008 Seixal 3 (Corroios, Amora-N)',
                    market_share: 47.0537,
                    lat: 38.6275503,
                    lon: -9.1456487,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1456487, 38.6275503],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#ED6F4C',
                    name: 'ST009 Seixal 4 (Amora -S)',
                    market_share: 46.9592,
                    lat: 38.6201484,
                    lon: -9.1345466,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1345466, 38.6201484],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A245',
                    name: 'ST010 Barreiro 1 (Barreiro, Lavradio)',
                    market_share: 44.372,
                    lat: 38.6691492,
                    lon: -9.0527681,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0527681, 38.6691492],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F07C4B',
                    name: 'ST011 Barreiro 2 (Coina, Sto Andre, Sto Antonio da Charneca)',
                    market_share: 46.3008,
                    lat: 38.6254803,
                    lon: -9.0324825,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0324825, 38.6254803],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DE2A56',
                    name: 'ST012 Barreiro 3 (Alto do Seixalinho, Verderena)',
                    market_share: 50.409,
                    lat: 38.6544481,
                    lon: -9.0714266,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0714266, 38.6544481],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#B3B07B',
                    name: 'ST013 Moita 1 (Vale da Amoreira, Baixa da Banheira)',
                    market_share: 40.482,
                    lat: 38.6462216,
                    lon: -9.0382454,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0382454, 38.6462216],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FEB743',
                    name: 'ST014 Montijo 1 (Afonsoeiro, Atalaia, Sarilhos Grandes, Canha, Sto Isidro de Pegoes)',
                    market_share: 43.3246,
                    lat: 38.6793102,
                    lon: -8.967957799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.967957799999999, 38.6793102],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A246',
                    name: 'ST015 Montijo 2 (Montijo)',
                    market_share: 44.4063,
                    lat: 38.7055652,
                    lon: -8.974118299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.974118299999999, 38.7055652],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FBBB45',
                    name: 'ST016 Alcochette',
                    market_share: 42.9513,
                    lat: 38.7561726,
                    lon: -8.9598931,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9598931, 38.7561726],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FFBA42',
                    name: 'ST017 Palmela 2 (Palmela, Quinta do Anjo)',
                    market_share: 43.1816,
                    lat: 38.5611235,
                    lon: -8.9423976,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.9423976, 38.5611235],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0494FA',
                    name: 'ST018 Setubal 1 (Santa Maria da Graca, Sao Juliao)',
                    market_share: 34.0665,
                    lat: 38.5244132,
                    lon: -8.888171,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.888171, 38.5244132],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F69747',
                    name: 'ST019 Setubal 2 (Sao Sebastiao, Santa Maria da Graca)',
                    market_share: 44.9609,
                    lat: 38.5167627,
                    lon: -8.8315681,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.8315681, 38.5167627],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D71159',
                    name: 'ST020 Alcacer do Sal, Grandola',
                    market_share: 51.7863,
                    lat: 38.3725756,
                    lon: -8.5110695,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5110695, 38.3725756],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#78A8A8',
                    name: 'ST021 Santiago Cacem 2 (Santiago do Cacem, Sto Andre), Sines',
                    market_share: 38.4742,
                    lat: 38.0551238,
                    lon: -8.7861353,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7861353, 38.0551238],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#DDB75B',
                    name: 'ST022 Barreiro 4 (Alto do Seixalinho)',
                    market_share: 41.9326,
                    lat: 38.66001809999999,
                    lon: -9.0615356,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0615356, 38.66001809999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#429FD0',
                    name: 'ST023 Moita 2 (Alhos Vedros, Moita)',
                    market_share: 36.6382,
                    lat: 38.6407724,
                    lon: -9.0058867,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0058867, 38.6407724],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#EE734C',
                    name: 'ST024 Palmela 1 (Pinhal Novo, Poceirao, Marateca)',
                    market_share: 46.7585,
                    lat: 38.6318721,
                    lon: -8.915093299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.915093299999999, 38.6318721],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E44552',
                    name: 'ST025 Sesimbra',
                    market_share: 49.0729,
                    lat: 38.44421699999999,
                    lon: -9.1014903,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.1014903, 38.44421699999999],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FFBC42',
                    name: 'ST026 Setubal 3 (Sao Lourenco, Sao Simao, Nossa Senhora da Anunciada)',
                    market_share: 43.0757,
                    lat: 38.5203456,
                    lon: -9.0190036,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.0190036, 38.5203456],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0492F6',
                    name: 'ST027 Setubal 4 (Sado, Sao Sebastiao)',
                    market_share: 33.6771,
                    lat: 38.5045895,
                    lon: -8.808715099999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.808715099999999, 38.5045895],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#69A5B3',
                    name: 'ST028 Santiago Cacem 1 (Alvalade, Cercal, Ermidas-Sado, Abela)',
                    market_share: 37.9469,
                    lat: 38.7477561,
                    lon: -9.139388900000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-9.139388900000002, 38.7477561],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#81A9A1',
                    name: 'VC001 Valenca, Vila Nova Cerveira',
                    market_share: 38.7699,
                    lat: 41.9383326,
                    lon: -8.744077299999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.744077299999999, 41.9383326],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CEB567',
                    name: 'VC002 Moncao',
                    market_share: 41.4216,
                    lat: 42.0771855,
                    lon: -8.4819441,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4819441, 42.0771855],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FAA845',
                    name: 'VC003 Arcos de Valdevez, Melgaco, Ponte da Barca',
                    market_share: 44.1122,
                    lat: 41.8055334,
                    lon: -8.417051599999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.417051599999999, 41.8055334],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#ECB950',
                    name: 'VC004 Paredes de Coura, Ponte de Lima',
                    market_share: 42.4387,
                    lat: 41.7647333,
                    lon: -8.5827407,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.5827407, 41.7647333],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#319DDD',
                    name: 'VC005 Viana do Castelo 1 (Afife,Carreco,Lanheses,Meadela,Portuzelo,Monserrate,Santa Maria Maior)',
                    market_share: 36.0627,
                    lat: 41.7021226,
                    lon: -8.822494100000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.822494100000002, 41.7021226],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#038CEB',
                    name: 'VC006 Caminha',
                    market_share: 32.6039,
                    lat: 41.8732082,
                    lon: -8.837845399999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.837845399999999, 41.8732082],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#027CCA',
                    name: 'VC007 Viana do Castelo 2 (Barroselas, Balugaes »)',
                    market_share: 29.3622,
                    lat: 41.6427053,
                    lon: -8.6432965,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.6432965, 41.6427053],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#84A99F',
                    name: 'VC008 Viana do Castelo 3 (Vila Franca, Castelo do Neiva, Vila Nova de Anha, Darque, Neiva)',
                    market_share: 38.8698,
                    lat: 41.6634866,
                    lon: -8.7938678,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.7938678, 41.6634866],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#D91858',
                    name: 'VR001 Boticas, Montalegre, Mondim Basto, Ribeira de Pena',
                    market_share: 51.3371,
                    lat: 41.6984124,
                    lon: -7.6710889,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6710889, 41.6984124],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E75250',
                    name: 'VR002 Chaves, Vila Pouca de Aguiar',
                    market_share: 48.4293,
                    lat: 41.5002387,
                    lon: -7.6432318,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.6432318, 41.5002387],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F79847',
                    name: 'VR003 Alijo, Murca, Sabrosa',
                    market_share: 44.8895,
                    lat: 41.4072842,
                    lon: -7.453387899999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.453387899999999, 41.4072842],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#E85650',
                    name: 'VR004 Valpacos',
                    market_share: 48.2195,
                    lat: 41.6069681,
                    lon: -7.3104483,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.3104483, 41.6069681],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#F9A146',
                    name: 'VR005 Peso da Regua, SM Penaguiao, Mesao Frio',
                    market_share: 44.4349,
                    lat: 41.1598648,
                    lon: -7.889922799999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.889922799999999, 41.1598648],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2F9DDE',
                    name: 'VR006 Vila Real 1 (Mateus, Nossa Senhora da Conceicao, Sao Pedro, Campea, Lordelo)',
                    market_share: 35.9913,
                    lat: 41.2379674,
                    lon: -8.4239055,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.4239055, 41.2379674],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#BAB176',
                    name: 'VR007 Vila Real 2 (Sao Diniz, Sao Pedro, Nossa Senhora da Conceicao)',
                    market_share: 40.7176,
                    lat: 41.2988174,
                    lon: -7.7439337,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.7439337, 41.2988174],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FCB144',
                    name: 'VS001 Cinfaes (Cinfaes, Fornelos »), Resende',
                    market_share: 43.6626,
                    lat: 41.0717872,
                    lon: -8.0902881,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.0902881, 41.0717872],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A4AE86',
                    name: 'VS002 Lamego,  SJ Pesq, Tabuaco, Armamar',
                    market_share: 39.9817,
                    lat: 41.1162713,
                    lon: -7.5674579,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.5674579, 41.1162713],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#FBAC44',
                    name: 'VS003 Tondela 1 (Canas, Lajeosa, Besteiros, »)',
                    market_share: 43.8863,
                    lat: 40.540379,
                    lon: -7.979455,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.979455, 40.540379],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#CAB46A',
                    name: 'VS004 Penedono,Sernance,Moim Beira,Tarouca,Satao,VN Paiva, Castro Daire,SP Sul (Varzea»),Oli Frades',
                    market_share: 41.2853,
                    lat: 40.8998417,
                    lon: -7.4900188,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.4900188, 40.8998417],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#A02657',
                    name: 'VS005 Vouzela, Viseu 1 ( S Salvador, Torredeita, Lordosa, Abraveses, Bodiosa)',
                    market_share: 58.2032,
                    lat: 40.6791759,
                    lon: -7.9224147,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9224147, 40.6791759],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#2A9CE2',
                    name: 'VS006 Tondela 2 (Tondela)',
                    market_share: 35.8119,
                    lat: 40.5168388,
                    lon: -8.080047100000002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.080047100000002, 40.5168388],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#C3B36F',
                    name: 'VS007 Viseu 2 (Santa Maria, SJose, Cepoes, SJ Lourosa, Silgueiros)',
                    market_share: 41.0273,
                    lat: 40.5578422,
                    lon: -7.9583908,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9583908, 40.5578422],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0495FE',
                    name: 'VS008 Mangualde, Penalva do Castelo',
                    market_share: 34.3986,
                    lat: 40.6862295,
                    lon: -7.633112999999999,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.633112999999999, 40.6862295],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#319DDD',
                    name: 'VS009 Nelas',
                    market_share: 36.0519,
                    lat: 40.537787,
                    lon: -7.8498679,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.8498679, 40.537787],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#0897FC',
                    name: 'VS010 Carregal do Sal',
                    market_share: 34.638,
                    lat: 40.4347784,
                    lon: -7.9980002,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9980002, 40.4347784],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#409FD2',
                    name: 'VS011 Viseu 3 (Coracao de Jesus, Sao Jose)',
                    market_share: 36.5489,
                    lat: 40.67024,
                    lon: -7.9075423,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-7.9075423, 40.67024],
                },
            },
            {
                type: 'Feature',
                properties: {
                    color: '#84A99F',
                    name: 'VS012 S Comba Dao, Mortagua',
                    market_share: 38.8795,
                    lat: 40.3971448,
                    lon: -8.2309134,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [-8.2309134, 40.3971448],
                },
            },
        ],
    },
    layersProp: [
        {
            id: 'point-inner',
            type: 'circle',
            source: 'earthquakes',
            paint: {
                'circle-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'market_share'],
                    25.9321,
                    '#006BA6',
                    34.510225,
                    '#0496FF',
                    43.08835,
                    '#FFBC42',
                    51.666475,
                    '#D81159',
                    60.2446,
                    '#8F2D56',
                ],
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 1, 60.2446, 4],
                    12,
                    ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 2, 60.2446, 5],
                    22,
                    ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 3, 60.2446, 6],
                ],
                'circle-opacity': ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 0.3, 60.2446, 1],
                'circle-stroke-color': '#fff',
            },
        },
        {
            id: 'point-outer',
            type: 'circle',
            source: 'earthquakes',
            paint: {
                'circle-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'market_share'],
                    25.9321,
                    '#006BA6',
                    34.510225,
                    '#0496FF',
                    43.08835,
                    '#FFBC42',
                    51.666475,
                    '#D81159',
                    60.2446,
                    '#8F2D56',
                ],
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 4, 60.2446, 15],
                    12,
                    ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 5, 60.2446, 25],
                    22,
                    ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 6, 60.2446, 40],
                ],
                'circle-opacity': ['interpolate', ['linear'], ['get', 'market_share'], 25.9321, 0, 60.2446, 0.3],
            },
        },
    ],
}
