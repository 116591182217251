import React from 'react';
import styles from './user-icon.module.scss';

export const UserIcon = () => {
    return (
        <div className={`${styles['user-icon-container']}`}>
            <span className={`${styles['user-icon-container__text']}`}>PF</span>
        </div>
    );
};
