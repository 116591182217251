import React from 'react'
import { getValueInString } from '../../../../../utils/unit'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const ProductCategorySalesTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    return (
        <TooltipContainer color={props.payload[0].fill}>
            <TooltipHeaderRow
                title={t('tooltips:header.category')}
                value={props.payload[0].payload.categoryName}
            />
            <Separator />
            <TooltipContentRow
                title={t('tooltips:content.sales')}
                value={getValueInString(
                    parseFloat(props.payload[0].payload.categorySales),
                    props.unitsOrValues
                )}
                color={props.payload[0].fill}
            />
        </TooltipContainer>
    )
})
