import { createContext, useContext } from 'react';

const ReportBuilderContext = createContext({
    isReportBuilder: undefined
});

export function ReportBuilderProvider({ value, children }) {
    return (
        <ReportBuilderContext.Provider value={value}>
            {children}
        </ReportBuilderContext.Provider>
    );
}

export function useReportBuilder() {
    return useContext(ReportBuilderContext);
}
