import { createContext, useContext } from 'react';

const NotificationsContext = createContext({
    notifications: undefined,
    setNotifications: () => {},
    removeNotification: () => {},
    getNotifications: () => {}
});

export function NotificationsProvider({ value, children }) {
    return (
        <NotificationsContext.Provider value={value}>
            {children}
        </NotificationsContext.Provider>
    );
}

export function useNotifications() {
    return useContext(NotificationsContext);
}
