import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useSimpleComponent } from '../../../hooks/useSimpleComponent'
import { useFilter } from '../../../providers/filter-context'
import { teamSalesComparisonMock } from './mock'
import PropTypes from 'prop-types'
import { errorHandler } from '../../../utils/api.js'
import { useToggle } from '../../../hooks/useToggle.js'
import { verifyComponent } from '../../../utils/component.js'
import { SalesService } from '../../../temp/test'
import { getDayFromDateFormat, getMonthFromDateFormat, getTimeFromDateFormat, getYearFromDateFormat, isSameYearSameMonth } from '../../../utils/date'
import { TEAM_COMPARISON_COLORS } from '../../../constants/color'
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container'
import { withCustomBrush } from '../../../hocs/with-custom-brush/with-custom-brush'
import { DynamicComposedChartContainer } from '../../charts/dynamic-composed-chart-container/dynamic-composed-chart-container'
import { Spinner } from '../../loading/spinner/spinner'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useSalesChannel } from '../../../providers/sales-channel-context'
import { useTimeInterval } from '../../../providers/time-interval-context'
import { Loader } from '../../loading/Loader/Loader'

/**
 * Displays the evolution of revenue sales over time without forecast prediction
 */

const Chart = withCustomBrush(DynamicComposedChartContainer)

export const TeamSalesComparison = React.memo(
    ({
        config,
        salesDataTimeWindow,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
    }) => {
        const { filter } = useFilter()
        const isMounted = useIsMounted()
        const { t } = useCustomTranslation()
        const { timeInterval } = useTimeInterval()
        const { channels } = useSalesChannel()
        const [weather] = useToggle(false)
        const [selectedWeather, setSelectedWeather] = useState('avgT')
        const { value: data, setLoading, setValue, toggleComponentSettings, handleClickLegend } = useSimpleComponent(teamSalesComparisonMock)

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(teamSalesComparisonMock)
                try {
                    if (!isExample) {
                        if (isMounted.current) {
                            setLoading(true)
                        }
                        if (type) dataClone.attr = type
                        const time = new Date('2022-01-01')
                        // time.setMonth(time.getMonth() - 6)
                        // time.setDate(1)
                        const timeISO = time.toISOString().split('T')[0]
                        dataClone.loading = true
                        const result = await SalesService.getApiSalesMonthPerTeam({
                            minDate: timeISO,
                            teamId: undefined,
                            sumBy: 'units',
                        })

                        const newResult = result.filter((el) => {
                            return el.forecast && el.timeseries && Object.entries(el.timeseries).length > 0
                        })
                        newResult.map((team) => {
                            Object.entries(team.timeseries).map((el) => {
                                let newEl = {
                                    [`team${team.team_id}`]: el[1].toFixed(1),
                                    [`team${team.team_id}ForecastValue`]: el[1].toFixed(1),
                                    [`team${team.team_id}ForecastMinValue`]: el[1].toFixed(1),
                                    [`team${team.team_id}ForecastMaxValue`]: el[1].toFixed(1),

                                    time: getTimeFromDateFormat(el[0]),
                                    day: getDayFromDateFormat(el[0], t),
                                    month: getMonthFromDateFormat(el[0], t),
                                    year: getYearFromDateFormat(el[0]),
                                }
                                let idx = dataClone.data.findIndex((v) => {
                                    return isSameYearSameMonth(v.time, newEl.time)
                                })
                                if (idx !== -1) {
                                    dataClone.data[idx][`team${team.team_id}`] = newEl[`team${team.team_id}`]
                                    dataClone.data[idx][`team${team.team_id}ForecastValue`] = newEl[`team${team.team_id}ForecastValue`]
                                    dataClone.data[idx][`team${team.team_id}ForecastMinValue`] = newEl[`team${team.team_id}ForecastMinValue`]
                                    dataClone.data[idx][`team${team.team_id}ForecastMaxValue`] = newEl[`team${team.team_id}ForecastMaxValue`]
                                } else {
                                    dataClone.data.push(newEl)
                                }
                                return null
                            })
                            team.forecast.forecast_trace_y.map((el, index) => {
                                let newEl = {
                                    [`team${team.team_id}ForecastValue`]: team.forecast.forecast_trace_y[index].toFixed(1),
                                    [`team${team.team_id}ForecastMinValue`]: team.forecast.min_trace_y[index].toFixed(1),
                                    [`team${team.team_id}ForecastMaxValue`]: team.forecast.max_trace_y[index].toFixed(1),
                                    time: getTimeFromDateFormat(team.forecast.forecast_x[index]),
                                    day: getDayFromDateFormat(team.forecast.forecast_x[index], t),
                                    month: getMonthFromDateFormat(team.forecast.forecast_x[index], t),
                                    year: getYearFromDateFormat(team.forecast.forecast_x[index]),
                                }
                                let idx = dataClone.data.findIndex((v) => {
                                    return isSameYearSameMonth(v.time, newEl.time)
                                })
                                if (idx !== -1) {
                                    dataClone.data[idx][[`team${team.team_id}`]] = undefined
                                    dataClone.data[idx][`team${team.team_id}ForecastValue`] = newEl[`team${team.team_id}ForecastValue`]
                                    dataClone.data[idx][`team${team.team_id}ForecastMaxValue`] = newEl[`team${team.team_id}ForecastMaxValue`]
                                    dataClone.data[idx][`team${team.team_id}ForecastMinValue`] = newEl[`team${team.team_id}ForecastMinValue`]
                                } else {
                                    dataClone.data.push(newEl)
                                }
                                return null
                            })
                        })
                        dataClone.lineAttr.length = 0
                        newResult.map((team, teamIndex) => {
                            dataClone.lineAttr.push({
                                dataKey: `team${team.team_id}ForecastValue`,
                                color: TEAM_COMPARISON_COLORS[teamIndex % 10],
                                isReverseColor: true,
                                dashArray: '3 3',
                                teamId: team.team_id,
                                isGradient: true,
                                isTransparent: true,
                                strokeWidth: 2,
                                fillOpacity: 1,
                            })
                            dataClone.lineAttr.push({
                                dataKey: `team${team.team_id}`,
                                teamId: team.team_id,
                                color: TEAM_COMPARISON_COLORS[teamIndex % 10],
                                strokeWidth: 2,
                                isTransparent: true,
                            })
                        })
                        dataClone.categoryAxis = 'month'
                        if (!isPreview) dataClone.dateGrouper = 'month'
                        dataClone.interactionDisabled = false
                        dataClone.tooltipType = 'teamSalesComparisonChart'
                        dataClone.loading = false
                        dataClone.ready = true
                        if (dataClone.settings.view.options.length === 0 && dataClone.settings.options.options.length === 0) dataClone.disabled = true
                        else dataClone.disabled = false
                        dataClone.legend = newResult.map((team, teamIndex) => {
                            return {
                                id: team.team_id,
                                name: team.team,
                                label: team.team_name,
                                color: TEAM_COMPARISON_COLORS[teamIndex % 10],
                                translated: true,
                            }
                        })

                        verifyComponent(dataClone)
                        if (isMounted.current) {
                            setLoading(false)
                            setValue(dataClone)
                        }
                    } else {
                        if (type) dataClone.attr = type
                        verifyComponent(dataClone)
                        setValue(dataClone)
                        setLoading(false)
                    }
                } catch (err) {
                    errorHandler(err)
                    dataClone.loading = false
                    if (isMounted.current) {
                        setValue(dataClone)
                    }
                }
            }
            fill()
        }, [config, filter, salesDataTimeWindow, isExample, isMounted, setLoading, setValue, t, type, isPreview, selectedWeather, weather])

        const filteredAttr = useMemo(() => {
            let lineAttr = []
            if (data.legend && data.legend.length > 0) {
                let activeLegends = data.legend.filter((el) => _.has(el, 'active') && el.active)
                if (activeLegends.length === 0) {
                    return data.lineAttr
                } else {
                    activeLegends.map((el) => {
                        data.lineAttr.map((el2) => {
                            if (el2.dataKey.startsWith(`team${el.id}`)) {
                                lineAttr.push(el2)
                            }
                        })
                    })
                }
            }
            return lineAttr
        }, [data])

        return (
            <div className={isPreview ? 'w-100 h-100' : 'w-100 h-100'} style={{ zIndex: 'inherit' }}>
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    showChartOptions={weather}
                    chartOptions={data.chartOptions}
                    selectedChartOption={selectedWeather}
                    onClickChartOption={setSelectedWeather}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    onClickLegend={handleClickLegend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <Chart
                            translate={t}
                            lineAttr={filteredAttr}
                            barAttr={data.barAttr}
                            margin={data.margin}
                            categoryAxis={data.categoryAxis}
                            data={data.data}
                            dateGrouper={data.dateGrouper}
                            timeAttr={data.timeAttr}
                            lineType={data.lineType}
                            showExternalOptions={data.showExternalOption}
                            externalOptions={data.externalOptions}
                            selectedExternalOption={data.selectedExternalOptions}
                            gradient={data.gradient}
                            tooltipType={data.tooltipType}
                            unitsOrValues={data.unitsOrValues}
                            barFilter={data.barFilter}
                            brushStroke={'#2cd9c5'}
                        />
                    )}
                </ContentContainer>
            </div>
        )
    }
)

TeamSalesComparison.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Time interval to aggregate data from api
     */
    salesDataTimeWindow: PropTypes.string,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
