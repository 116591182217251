import React, { useEffect } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import styles from './user-profile.module.scss';
import {
    LoaderForeground,
    Spinner,
    UserInformation
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Provides a page layout that allows managers to view their profile details
 */

const UserProfile = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);

    useEffect(() => {
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading]);

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['user-profile-container']}`}
                >
                    <div className="col px-0 h-100">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        {t('pages:managerProfile.title')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`row w-100 mx-0 mb-3`}>
                            <div className="col-12 col-md-6 pe-3 px-0 h-100 justify-content-center align-items-center">
                                <UserInformation />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default UserProfile;

UserProfile.propTypes = {};
