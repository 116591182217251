import _ from 'lodash'
import { useCallback, useReducer } from 'react'

const ACTIONS = {
    ADD_OPTION: 'add',
    TOGGLE_DROPDOWN: 'dropdown',
    CHANGE_ERROR: 'error',
    SELECT_OPTION: 'select',
    UNSELECT_OPTION: 'unselect',
    KEY_PRESS: 'keyPress',
    KEY_DOWN: 'keyDown',
    SET_VALUE: 'value',
    RESET: 'reset',
    REPLACE: 'replace',
    REPLACE_ALL: 'replace_all',
    ADD_KEYWORD: 'add_keyword',
    REMOVE_KEYWORD: 'remove_keyword',
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.REMOVE_KEYWORD: {
            const removeIdx = _.findIndex(state.selectedOptions, (el) => {
                return el === action.payload
            })
            if (removeIdx !== -1) {
                return {
                    ...state,
                    error: '',
                    state: 'normal',
                    selectedOptions: [
                        ...state.selectedOptions.slice(0, removeIdx),
                        ...state.selectedOptions.slice(
                            removeIdx + 1,
                            state.selectedOptions.length
                        ),
                    ],
                    selectedOptionsAux: [
                        ...state.selectedOptionsAux.slice(0, removeIdx),
                        ...state.selectedOptionsAux.slice(
                            removeIdx + 1,
                            state.selectedOptionsAux.length
                        ),
                    ],
                }
            } else {
                return {
                    ...state,
                }
            }
        }
        case ACTIONS.ADD_KEYWORD:
            return {
                ...state,
                error: '',
                state: 'normal',
                selectedOptions: [...state.selectedOptions, action.payload],
                selectedOptionsAux: [
                    ...state.selectedOptionsAux,
                    action.payload,
                ],
            }
        case ACTIONS.REPLACE_ALL:
            return {
                ...action.payload,
            }
        case ACTIONS.CHANGE_ERROR:
            if (action.payload) {
                return {
                    ...state,
                    state: 'error',
                    error: action.payload,
                }
            } else {
                return {
                    ...state,
                    state: 'normal',
                    error: '',
                }
            }
        case ACTIONS.ADD_OPTION:
            return {
                ...state,
                options: [...state.options, action.payload.option],
                optionsAux: [...state.optionsAux, action.payload.optionAux],
            }
        case ACTIONS.TOGGLE_DROPDOWN:
            return {
                ...state,
                openDropdown: action.payload,
            }
        case ACTIONS.SELECT_OPTION: {
            let selectOptionIndex = _.findIndex(state.optionsAux, (el) => {
                return el === action.payload.optionAux
            })
            if (selectOptionIndex !== -1) {
                return {
                    ...state,
                    options: [
                        ...state.options.slice(0, selectOptionIndex),
                        ...state.options.slice(
                            selectOptionIndex + 1,
                            state.options.length
                        ),
                    ],
                    optionsAux: [
                        ...state.optionsAux.slice(0, selectOptionIndex),
                        ...state.optionsAux.slice(
                            selectOptionIndex + 1,
                            state.optionsAux.length
                        ),
                    ],
                    selectedOptions: [
                        ...state.selectedOptions,
                        action.payload.option,
                    ],
                    selectedOptionsAux: [
                        ...state.selectedOptionsAux,
                        action.payload.optionAux,
                    ],
                    value: '',
                }
            } else {
                return {
                    ...state,
                    selectedOptions: [
                        ...state.selectedOptions,
                        action.payload.option,
                    ],
                    selectedOptionsAux: [
                        ...state.selectedOptionsAux,
                        action.payload.optionAux,
                    ],
                    value: '',
                }
            }
        }
        case ACTIONS.UNSELECT_OPTION: {
            let selectedOptionIndex = _.findIndex(
                state.selectedOptionsAux,
                (el) => {
                    return el === action.payload.optionAux
                }
            )

            if (selectedOptionIndex !== -1) {
                return {
                    ...state,
                    options: [action.payload.option, ...state.options],
                    optionsAux: [action.payload.optionAux, ...state.optionsAux],
                    selectedOptions: [
                        ...state.selectedOptions.slice(0, selectedOptionIndex),
                        ...state.selectedOptions.slice(
                            selectedOptionIndex + 1,
                            state.selectedOptions.length
                        ),
                    ],
                    selectedOptionsAux: [
                        ...state.selectedOptionsAux.slice(
                            0,
                            selectedOptionIndex
                        ),
                        ...state.selectedOptionsAux.slice(
                            selectedOptionIndex + 1,
                            state.selectedOptionsAux.length
                        ),
                    ],
                    value: '',
                }
            }
            break
        }
        case ACTIONS.KEY_PRESS: {
            let options = _.cloneDeep(state.options),
                optionsAux = _.cloneDeep(state.optionsAux),
                selectedOptions = _.cloneDeep(state.selectedOptions),
                selectedOptionsAux = _.cloneDeep(state.selectedOptionsAux)
            const { which, currentTarget } = action.payload.event
            if (which === 13) {
                if (action.payload.value) {
                    if (selectedOptions.includes(action.payload.value)) {
                        return {
                            ...state,
                            error: 'duplicateKeyword',
                            state: 'error',
                        }
                    } else {
                        selectedOptions.push(action.payload.value)
                        selectedOptionsAux.push(action.payload.value)
                    }
                }

                if (currentTarget) currentTarget.blur()
                return {
                    ...state,
                    error: '',
                    state: 'normal',
                    options,
                    optionsAux,
                    selectedOptions,
                    selectedOptionsAux,
                    value: '',
                }
            } else {
                return { ...state }
            }
        }
        case ACTIONS.KEY_DOWN:
            return {
                ...state,
                focused: 0,
            }

        case ACTIONS.SET_VALUE:
            return {
                ...state,
                value: action.payload,
            }
        case ACTIONS.RESET:
            return {
                ...action.payload,
            }
        case ACTIONS.REPLACE:
            return {
                ...state,
                selectedOptions: [...action.payload],
                selectedOptionsAux: [...action.payload],
            }
        default:
            return { ...state }
    }
}

export function useTagMultipleInput(defaultValue) {
    const [value, dispatch] = useReducer(reducer, defaultValue)

    const addOption = useCallback((value, valueAux) => {
        dispatch({
            type: 'add',
            payload: { option: value, optionAux: valueAux },
        })
    }, [])

    function selectOption(value, valueAux) {
        dispatch({
            type: 'select',
            payload: { option: value, optionAux: valueAux },
        })
    }

    function unselectOption(value, valueAux) {
        dispatch({
            type: 'unselect',
            payload: { option: value, optionAux: valueAux },
        })
    }

    function keyPress(e) {
        dispatch({
            type: 'keyPress',
            payload: { event: e, value: value.value },
        })
    }

    function keyDown(e) {
        dispatch({
            type: 'keyDown',
            payload: e,
        })
    }

    const toggleDropdown = useCallback(
        (v) => {
            dispatch({
                type: 'dropdown',
                payload: typeof v === 'boolean' ? v : !value.openDropdown,
            })
        },
        [value]
    )

    const setValue = useCallback((e) => {
        dispatch({ type: 'value', payload: e.target.value })
    }, [])

    const reset = useCallback(() => {
        dispatch({ type: 'reset', payload: defaultValue })
    }, [defaultValue])

    const replaceSelectedOptions = useCallback((value) => {
        dispatch({ type: 'replace', payload: value })
    }, [])
    function setError(value) {
        dispatch({ type: 'error', payload: value })
    }

    const replaceAll = useCallback((value) => {
        dispatch({ type: 'replace_all', payload: value })
    }, [])

    const addKeyword = useCallback((value) => {
        dispatch({ type: 'add_keyword', payload: value })
    }, [])

    const removeKeyword = useCallback((value) => {
        dispatch({ type: 'remove_keyword', payload: value })
    }, [])

    return {
        value,
        addOption,
        toggleDropdown,
        selectOption,
        unselectOption,
        keyPress,
        keyDown,
        setValue,
        reset,
        replaceSelectedOptions,
        setError,
        replaceAll,
        addKeyword,
        removeKeyword,
    }
}
