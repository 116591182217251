import React from 'react'
import { GlobalModalPopupOriginal } from '../global-modal-popup-original/global-modal-popup-original'

export const GlobalModalPopupManagerTeamListing = React.memo(
    ({
        toggleIsOpen,
        steps,
        selectedTutorialStep,
        currentStep,
        setCurrentStep,
    }) => {
        return (
            <GlobalModalPopupOriginal
                visible={
                    steps &&
                    selectedTutorialStep !== '' &&
                    steps[selectedTutorialStep]['steps'][currentStep][
                        'popupDetails'
                    ] &&
                    steps[selectedTutorialStep]['steps'][currentStep][
                        'popupDetails'
                    ]['type'] === 'component' &&
                    steps[selectedTutorialStep]['steps'][currentStep][
                        'popupDetails'
                    ].name === 'managerTeamListing'
                }
                direction={'up'}
                steps={steps}
                toggleIsOpen={toggleIsOpen}
                selectedTutorialStep={selectedTutorialStep}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            />
        )
    }
)
