import React, { useMemo, useRef, useState } from 'react'
import styles from './date-input-new.module.scss'
import _ from 'lodash'
import icoCalendar from '../../../assets/images/icoCalendar.svg'
import { useToggle } from '../../../hooks/useToggle'
import { useLocation } from 'react-router-dom'
import { useMainScroll } from '../../../hooks/main-scroll-context'
import { DatePickerNew } from '../date-picker-new/date-picker-new'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

const InputDay = ({ id, value, isAge, onChangeInput, name, pickerType, initialDate, finalDate, indexList }) => {
    const inputDay = useRef(null)
    const inputMonth = useRef(null)
    const inputYear = useRef(null)
    const [isFocused, setIsFocused] = useState(false)
    const location = useLocation()
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
    const realValue = useMemo(() => searchParams.get('data-acompanhamento'), [searchParams])

    const { mainScrollRef } = useMainScroll()
    const datePickerParentRef = useRef(null)
    const datePickerRef = useRef(null)
    const [datePickerPosition, setDatePickerPosition] = useState('bottom')
    const [datePickerHorizontalPosition, setDatePickerHorizontalPosition] = useState('bottom')

    const [datePickerIsOpen, toggleDatePickerIsOpen] = useToggle(false)

    const handleOpenDatePicker = () => {
        toggleDatePickerIsOpen(datePickerIsOpen === true ? false : true)
    }
    return (
        <div className={isFocused ? 'input-box selected' : value && value.length > 0 && value !== '//' ? 'input-box valued' : 'input-box'}>
            <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                <input
                    type={'text'}
                    ref={inputDay}
                    onFocus={() => {
                        inputDay.current.select()
                        setIsFocused(true)
                    }}
                    onBlur={() => setIsFocused(false)}
                    className={`input-box hidden ${styles['date-input-new-container__text']}`}
                    style={{
                        textAlign: 'center',
                    }}
                    size={2}
                    placeholder="dd"
                    value={
                        id === 'dataDaRecolhaAcompanhamento'
                            ? realValue && realValue.split('/')[0] && realValue.split('/')[0].length < 2
                                ? realValue && '0'.repeat(2 - realValue.split('/')[0].length) + realValue.split('/')[0]
                                : (realValue && realValue.split('/')[0]) || ''
                            : value && value.split('/')[0] && value.split('/')[0].length < 2
                            ? value && '0'.repeat(2 - value.split('/')[0].length) + value.split('/')[0]
                            : (value && value.split('/')[0]) || ''
                    }
                    onChange={(e) => {
                        let finalValue = e.target.value
                        if (finalValue.length > 2) {
                            if (value) {
                                onChangeInput(finalValue.slice(1) + '/' + value.split('/')[1] + '/' + value.split('/')[2], name, 'date')
                            } else {
                                onChangeInput(finalValue.slice(1) + '/' + '/', name, 'date')
                            }
                        } else {
                            if (value) {
                                onChangeInput(finalValue + '/' + value.split('/')[1] + '/' + value.split('/')[2], name, 'date')
                            } else {
                                onChangeInput(finalValue + '//', name, 'date')
                            }
                        }
                        if (finalValue.length > 2) {
                            inputMonth.current.focus()
                        }
                    }}
                />
            </div>
            <div
                className="col-auto px-1 d-flex justify-content-center align-items-center"
                style={{
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
            >
                /
            </div>
            <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                <input
                    type={'text'}
                    ref={inputMonth}
                    onFocus={() => {
                        inputMonth.current.select()
                        setIsFocused(true)
                    }}
                    onBlur={() => setIsFocused(false)}
                    placeholder="mm"
                    size={3}
                    className={`input-box hidden ${styles['date-input-new-container__text']}`}
                    style={{
                        textAlign: 'center',
                    }}
                    value={
                        id === 'dataDaRecolhaAcompanhamento'
                            ? realValue && realValue.split('/')[1] && realValue.split('/')[1].length < 2
                                ? realValue && '0'.repeat(2 - realValue.split('/')[1].length) + realValue.split('/')[1]
                                : (realValue && realValue.split('/')[1]) || ''
                            : value && value.split('/')[1] && value.split('/')[1].length < 2
                            ? value && '0'.repeat(2 - value.split('/')[1].length) + value.split('/')[1]
                            : (value && value.split('/')[1]) || ''
                    }
                    onChange={(e) => {
                        let finalValue = e.target.value
                        if (finalValue.length > 2) {
                            if (value) {
                                onChangeInput(value.split('/')[0] + '/' + finalValue.slice(1) + '/' + value.split('/')[2], name, 'date')
                            } else {
                                onChangeInput('/' + finalValue.slice(1) + '/', name, 'date')
                            }
                        } else {
                            if (value) {
                                onChangeInput(value.split('/')[0] + '/' + finalValue + '/' + value.split('/')[2], name, 'date')
                            } else {
                                onChangeInput('/' + finalValue + '/', name, 'date')
                            }
                        }
                        if (finalValue.length > 2) {
                            inputYear.current.focus()
                        }
                    }}
                />
            </div>
            <div
                className="col-auto px-1 d-flex justify-content-center align-items-center"
                style={{
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
            >
                /
            </div>
            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                <input
                    type={'text'}
                    ref={inputYear}
                    onFocus={() => {
                        inputYear.current.select()
                        setIsFocused(true)
                    }}
                    onBlur={() => setIsFocused(false)}
                    placeholder="aaaa"
                    size={4}
                    className={`input-box hidden ${styles['date-input-new-container__text']}`}
                    style={{
                        textAlign: 'center',
                    }}
                    value={
                        id === 'dataDaRecolhaAcompanhamento'
                            ? realValue && realValue.split('/')[2] && realValue.split('/')[2].length < 4
                                ? realValue && '0'.repeat(4 - realValue.split('/')[2].length) + realValue.split('/')[2]
                                : (realValue && realValue.split('/')[2]) || ''
                            : value && value.split('/')[2] && value.split('/')[2].length < 4
                            ? value && '0'.repeat(4 - value.split('/')[2].length) + value.split('/')[2]
                            : value && value.split('/')[2]
                    }
                    onChange={(e) => {
                        let finalValue = e.target.value
                        if (finalValue.length > 4) {
                            if (value) {
                                onChangeInput(value.split('/')[0] + '/' + value.split('/')[1] + '/' + finalValue.slice(1), name, 'date')
                            } else {
                                onChangeInput('//' + finalValue.slice(1), name, 'date')
                            }
                        } else {
                            if (value) {
                                onChangeInput(value.split('/')[0] + '/' + value.split('/')[1] + '/' + finalValue, name, 'date')
                            } else {
                                onChangeInput('//' + finalValue, name, 'date')
                            }
                        }
                    }}
                />
            </div>
            <div className="col px-0 d-flex justify-content-end align-items-center" ref={datePickerParentRef}>
                <img
                    src={icoCalendar}
                    alt=""
                    className={`input-icon ${styles['date-input-new-container__input-icon']}`}
                    onClick={handleOpenDatePicker}
                />
                {datePickerIsOpen && (
                    <DatePickerNew
                        pickerType={pickerType}
                        parentRef={datePickerParentRef}
                        initialDate={initialDate}
                        finalDate={finalDate ? finalDate : isAge ? new Date() : undefined}
                        reference={datePickerRef}
                        name={name}
                        position={datePickerPosition}
                        value={value}
                        indexList={indexList}
                        onChangeInput={onChangeInput}
                        datePickerHorizontalPosition={datePickerHorizontalPosition}
                        onCloseTimePicker={() => {
                            toggleDatePickerIsOpen(false)
                            setIsFocused(false)
                        }}
                    />
                )}
            </div>
        </div>
    )
}

const quarterTranslator = {
    1: '1º Trimestre',
    2: '2º Trimestre',
    3: '3º Trimestre',
    4: '4º Trimestre',
}

const monthShortTranslator = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez',
}

const InputDefault = ({ id, value, isAge, onChangeInput, name, pickerType, initialDate, finalDate, indexList }) => {
    const { t } = useCustomTranslation()
    const [isFocused, setIsFocused] = useState(false)
    const [datePickerIsOpen, toggleDatePickerIsOpen] = useToggle(false)
    const datePickerRef = useRef(null)
    const datePickerParentRef = useRef(null)
    const handleOpenDatePicker = () => {
        toggleDatePickerIsOpen(datePickerIsOpen === true ? false : true)
    }

    const valueTranslated = useMemo(() => {
        if (value) {
            if (pickerType === 'month') {
                return monthShortTranslator[value.split(' ')[0]] + ' ' + value.split(' ')[1]
            } else if (pickerType === 'year') {
                return value.split(' ')[0]
            } else if (pickerType === 'quarter') {
                return quarterTranslator[value.split(' ')[0]] + ' ' + value.split(' ')[1]
            }
        }
        return value
    }, [pickerType, value])

    return (
        <div className={isFocused ? 'input-box selected' : 'input-box'} ref={datePickerParentRef} onClick={handleOpenDatePicker}>
            <div
                className="row mx-0 w-100"
                style={{
                    pointerEvents: 'all',
                    cursor: 'pointer',
                }}
            >
                <div className="col-auto px-0">
                    <input
                        type={'text'}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        className={`${styles['date-input-new-container__default-input']}`}
                        value={valueTranslated}
                        placeholder={t(`inputs:${pickerType}`)}
                        style={{ cursor: 'default' }}
                    />
                </div>
                <div className="col px-0"></div>
                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                    <img src={icoCalendar} alt="" className={`input-icon ${styles['date-input-new-container__input-icon']}`} />
                    {datePickerIsOpen && (
                        <DatePickerNew
                            pickerType={pickerType}
                            parentRef={datePickerParentRef}
                            initialDate={initialDate}
                            finalDate={finalDate ? finalDate : isAge ? new Date() : undefined}
                            reference={datePickerRef}
                            name={name}
                            value={value}
                            indexList={indexList}
                            onChangeInput={onChangeInput}
                            onCloseTimePicker={() => {
                                toggleDatePickerIsOpen(false)
                                setIsFocused(false)
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export const DateInputNew = React.memo(({ label, description, format, ...rest }) => {
    const { name } = rest
    const { t } = useCustomTranslation()
    const pickerType = useMemo(() => {
        return format || 'day'
    }, [format])

    return (
        <div className="row mx-0 w-100 h-100">
            <div className="col px-0">
                <div className={`${styles['date-input-new-container']}`}>
                    <div className="w-100 h-100">
                        <div className="col px-0">
                            <div
                                className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                style={{
                                    marginBottom: '1rem',
                                }}
                            >
                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                    {(label || name) && (
                                        <span className={`${styles['date-input-new-container__label']}`}>{label || t(`inputs:${name}`)}</span>
                                    )}
                                </div>
                            </div>

                            <div className="row mx-0 w-100">
                                <div className="col px-0">{description && <span className="sub-title">{description}</span>}</div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    minWidth: '18rem',
                                }}
                            >
                                {pickerType === 'day' && <InputDay {...rest} pickerType={pickerType} />}
                                {pickerType !== 'day' && <InputDefault {...rest} pickerType={pickerType} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
