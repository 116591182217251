import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SentimentConvoDetails } from '../../../sentiment-convo-details/sentiment-convo-details'
import styles from './sentiment-channel-analysis-audio-file.module.scss'
import { SentimentConvoList } from '../../../sentiment-convo-list'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SentimentChannelAnalysisAudioFile = React.memo(({ calls }) => {
    const { t } = useCustomTranslation()
    const [selectedConversation, setSelectedConversation] = useState('')
    return (
        <div
            className={`${styles['sentiment-channel-analysis-audio-file-container']}`}
        >
            {selectedConversation !== '' && calls && (
                <React.Fragment>
                    <div
                        className="row mx-0 w-100 d-flex justify-content-between align-items-center "
                        style={{ marginBottom: '2rem' }}
                    >
                        <div className="col-4 px-0">
                            <span
                                className={`${styles['sentiment-channel-analysis-audio-file-container__sub-sub-title']}`}
                            >
                                {'DATA EXTRACTION'}
                            </span>
                        </div>
                        <div className="col ms-4 px-0">
                            <span
                                className={`${styles['sentiment-channel-analysis-audio-file-container__sub-sub-title']}`}
                            >
                                {'SENTECE ANALYSIS'}
                            </span>
                        </div>
                    </div>
                </React.Fragment>
            )}
            {selectedConversation === '' && calls && (
                <React.Fragment>
                    {calls.length === 0 && (
                        <div className="row mx-0 w-100 h-100">
                            <div className="col px-0 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['sentiment-channel-analysis-audio-file-container__no-data-text']}`}
                                >
                                    {t(
                                        'components:sentiment-channel-analysis-file-audio.nodata'
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                    {calls.length !== 0 && (
                        <div className="row mx-0 mb-2">
                            <div className="col-auto px-0">
                                <span
                                    className={`${styles['sentiment-channel-analysis-audio-file-container__count']} me-3`}
                                >
                                    {calls.length}
                                </span>
                                <span
                                    className={`${styles['sentiment-channel-analysis-audio-file-container__title']}`}
                                >
                                    {t(
                                        `components:sentiment-channel-analysis-file-audio.results`
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
            <div className="row mx-0 w-100 d-flex justify-content-between align-items-center flex-grow-1">
                <div className="col px-0 h-100">
                    {selectedConversation === '' && calls && (
                        <SentimentConvoList
                            conversations={calls}
                            onViewcalls={setSelectedConversation}
                            type={'1'}
                        />
                    )}
                    {selectedConversation !== '' && calls && (
                        <SentimentConvoDetails
                            conversation={calls[selectedConversation]}
                            onReturnToConversations={() =>
                                setSelectedConversation('')
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    )
})
