import React from 'react'
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.css'
import { ReactComponent as IcoCheckboxSelected } from './assets/ico-checkbox-selected.svg'
import { ReactComponent as IcoCheckboxBlockedSelected } from './assets/ico-checkbox-blocked-selected.svg'
import { ReactComponent as IcoCheckboxSelectedMultiple } from './assets/ico-checkbox-selected-multiple.svg'
import styles from './checkbox-input-new.module.scss'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'

export const CheckboxInputNew = React.memo(({ icon, modalAttr, label, hideLabel, attr, onClickCheckboxOption, options, selectedOptions, name }) => {
    const { t } = useCustomTranslation()
    return (
        <div className={`${styles['checkbox-input-new-container']}`}>
            {(label || name) && !hideLabel && (
                <div className="d-flex justify-content-start">
                    <label htmlFor={name} className={`${styles['checkbox-input-new-container__label']}`} style={{ marginBottom: '1rem' }}>
                        {label || t(`inputs:${name}`)}
                    </label>
                    {icon && <img src={icon} alt="icon" className={`${styles['checkbox-input-new-container__icon']}`} />}
                </div>
            )}
            {options.map((el, index) => {
                let iconStyle
                if (el.disabled) {
                    iconStyle = 'disabled'
                } else if (el.blocked) {
                    iconStyle = 'blocked'
                } else if ((el.name && selectedOptions.includes(el.name)) || selectedOptions.includes(el.label)) {
                    iconStyle = 'selected'
                } else {
                    iconStyle = 'default'
                }
                return (
                    <div
                        key={index}
                        className={
                            options.length > 1
                                ? `row mx-0 ${styles['checkbox-input-new-container__option']}`
                                : `row mx-0 ${styles['checkbox-input-new-container__option']}`
                        }
                    >
                        <div
                            className={`${styles['checkbox-input-new-container__option__content']}`}
                            onClick={() => onClickCheckboxOption(modalAttr, attr, el.name || el.label, name)}
                        >
                            <div className="col-auto px-0 me-3 d-flex justify-content-center align-items-center">
                                {RenderCheckboxBox({ iconStyle, selectedOptions })}
                            </div>
                            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                {el.color && (
                                    <div
                                        className={`${styles['checkbox-input-new-container__option__color']}`}
                                        style={{ borderColor: el.color }}
                                    ></div>
                                )}
                                <span className={`${styles['checkbox-input-new-container__option__text']}`}>
                                    {el.label || t(`common:${el.name}`)}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
})

export const RenderCheckboxBox = ({ iconStyle = 'default', selectedOptions = [] }) => {
    return (
        <>
            {iconStyle === 'blocked' && <IcoCheckboxBlockedSelected />}
            {iconStyle === 'selected' && selectedOptions.length <= 1 && (
                <IcoCheckboxSelected className={`${styles['checkbox-input-new-container__option__icon-alt']}`} />
            )}
            {iconStyle === 'selected' && selectedOptions.length > 1 && (
                <IcoCheckboxSelectedMultiple className={`${styles['checkbox-input-new-container__option__icon-alt']}`} />
            )}
            {iconStyle !== 'selected' && iconStyle !== 'blocked' && (
                <div
                    className={`${styles['checkbox-input-new-container__option__icon']} ${
                        styles[`checkbox-input-new-container__option__icon--${iconStyle}`]
                    }`}
                ></div>
            )}
        </>
    )
}

CheckboxInputNew.propTypes = {
    /**
     * String to choose from state properties
     */
    attr: PropTypes.string,
    /**
     * Array of objects
     * Each object contains the following attributes:
     * name: String linked with attribute
     * label: String to display
     * disabled: Bool to disable option
     * blocked: Bool to block selected option
     */
    options: PropTypes.array.isRequired,
    /**
     * String to choose selected option from 'options' array
     */
    selectedOptions: PropTypes.array.isRequired,
    /**
     * Function to select new option
     */
    onClickCheckboxOption: PropTypes.func.isRequired,
}
