import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../button/button'

export const SecondaryButton = React.memo((props) => {
    return <Button color={'secondary'} {...props} />
})

SecondaryButton.propTypes = {
    /**
     * Text to display in button
     */
    text: PropTypes.string.isRequired,
    /**
     * Disables button if set to true
     */
    disabled: PropTypes.bool,
    /**
     * Function to fire when clicking button
     */
    onClick: PropTypes.func,
}
