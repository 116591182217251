import React from 'react';
import styles from './team-member-customers-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import icoUpTrend from '../../../../../assets/images/icoUpTrend.svg';
import icoDownTrend from '../../../../../assets/images/icoDownTrend.svg';
import _ from 'lodash';
import { formatCurrency } from '../../../../../utils/math';

export const TeamMemberCustomersTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation();
    return (
        <div className={styles['team-member-customers-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    <div className="row mx-0 w-100">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '1rem' }}
                        >
                            <span
                                className={
                                    el['teamMemberCustomers'] &&
                                    el['teamMemberCustomersLast']
                                        ? el['teamMemberCustomers'] >
                                          el['teamMemberCustomersLast']
                                            ? styles[
                                                  'team-member-customers-table-column-container__text'
                                              ] +
                                              ' ' +
                                              styles['up']
                                            : el['teamMemberCustomers'] <
                                              el['teamMemberCustomersLast']
                                            ? styles[
                                                  'team-member-customers-table-column-container__text'
                                              ] +
                                              ' ' +
                                              styles['down']
                                            : styles[
                                                  'team-member-customers-table-column-container__text'
                                              ]
                                        : styles[
                                              'team-member-customers-table-column-container__text'
                                          ]
                                }
                            >
                                {el[type] ? el[type] : ''}
                            </span>
                        </div>
                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                            {el['teamMemberCustomers'] &&
                            el['teamMemberCustomersLast'] ? (
                                el['teamMemberCustomers'] >
                                el['teamMemberCustomersLast'] ? (
                                    <img
                                        src={icoUpTrend}
                                        alt="Up trend"
                                        className={
                                            styles[
                                                'team-member-customers-table-column-container__icon'
                                            ] +
                                            ' ' +
                                            styles['up']
                                        }
                                    />
                                ) : el['teamMemberCustomers'] <
                                  el['teamMemberCustomersLast'] ? (
                                    <img
                                        src={icoDownTrend}
                                        alt="Up trend"
                                        className={
                                            styles[
                                                'team-member-customers-table-column-container__icon'
                                            ] +
                                            ' ' +
                                            styles['down']
                                        }
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}{' '}
                            {el['teamMemberCustomers'] &&
                                el['teamMemberCustomersLast'] &&
                                (el['teamMemberCustomers'] >
                                    el['teamMemberCustomersLast'] ||
                                    el['teamMemberCustomers'] <
                                        el['teamMemberCustomersLast']) && (
                                    <>
                                        <span
                                            className={
                                                el['teamMemberCustomers'] &&
                                                el['teamMemberCustomersLast']
                                                    ? el[
                                                          'teamMemberCustomers'
                                                      ] >
                                                      el[
                                                          'teamMemberCustomersLast'
                                                      ]
                                                        ? styles[
                                                              'team-member-customers-table-column-container__text-secondary'
                                                          ] +
                                                          ' ' +
                                                          styles['up']
                                                        : el[
                                                              'teamMemberCustomers'
                                                          ] <
                                                          el[
                                                              'teamMemberCustomersLast'
                                                          ]
                                                        ? styles[
                                                              'team-member-customers-table-column-container__text-secondary'
                                                          ] +
                                                          ' ' +
                                                          styles['down']
                                                        : styles[
                                                              'team-member-customers-table-column-container__text-secondary'
                                                          ]
                                                    : styles[
                                                          'team-member-customers-table-column-container__text-secondary'
                                                      ]
                                            }
                                            style={{ marginRight: '0.5rem' }}
                                        >
                                            {Math.round(
                                                (el['teamMemberCustomers'] /
                                                    el[
                                                        'teamMemberCustomersLast'
                                                    ]) *
                                                    100
                                            )}
                                            %
                                        </span>
                                        <span
                                            className={
                                                styles[
                                                    'team-member-customers-table-column-container__text-secondary'
                                                ]
                                            }
                                        >
                                            vs. last month
                                        </span>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
