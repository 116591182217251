import React from 'react'
import icoMoveComponent from '../../../assets/images/ico-move-component.svg'
import styles from './drag-handle.module.scss'

export const DragHandle = React.memo(() => {
    return (
        <div className={`drag-handle-ref`}>
            <div className={`${styles['drag-handle']}`}>
                <img
                    loading="lazy"
                    src={icoMoveComponent}
                    alt="drag element with cursor"
                    className={`${styles['drag-handle__icon']}`}
                />
            </div>
        </div>
    )
})
