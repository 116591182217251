import React from 'react'
import _ from 'lodash'
import styles from './AddUserInput.module.scss'
import icoCorrect from '../../../assets/images/icoCorrect.svg'
import icoClose from '../../../assets/images/ico-close.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { SearchInputAlternative } from '../search-input-alternative/SearchInputAlternative'

export const AddUserInput = ({ usersToAddTitle, usersToAddDescription, usersAddedTitle, usersToAdd, usersAdded, onAddUser, onRemoveUser }) => {
    const { t } = useCustomTranslation()
    const [searchValue, setSearchValue] = React.useState('')
    return (
        <div className={styles['add-user-input-container']}>
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0 d-flex flex-column">
                    <div className={styles['add-user-input-container__left-list']}>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <span className={styles['add-user-input-container__left-list__title']}>{usersToAddTitle}</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <span className={styles['add-user-input-container__left-list__sub-title']}>{usersToAddDescription}</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 d-flex justify-content-end" style={{ marginBottom: '2rem' }}>
                            <div className="col-7 px-0">
                                <SearchInputAlternative
                                    value={searchValue}
                                    placeholder={t('inputs:searchUser')}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0 h-100">
                                {usersToAdd.map((user, index) => {
                                    const { isTeamLeader } = user
                                    return (
                                        <div key={index} className={styles['add-user-input-container__left-list__user']}>
                                            <div className="row mx-0 w-100">
                                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                    <img
                                                        src={user.avatar}
                                                        alt={user.name}
                                                        className={styles['add-user-input-container__left-list__user__avatar']}
                                                    />
                                                </div>
                                                <div className="col px-0">
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span className={styles['add-user-input-container__left-list__user__name']}>
                                                                {user.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 w-100">
                                                        <div className="col px-0">
                                                            <span className={styles['add-user-input-container__left-list__user__email']}>
                                                                {user.email}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {isTeamLeader && (
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span className={styles['add-user-input-container__left-list__user__team-leader']}>
                                                                    {t(`common:teamLeader`)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                    {_.find(usersAdded, (u) => u.email === user.email) ? (
                                                        <img
                                                            src={icoCorrect}
                                                            alt={t('buttons:add')}
                                                            className={styles['add-user-input-container__left-list__user__button__icon']}
                                                        />
                                                    ) : (
                                                        <span
                                                            className={styles['add-user-input-container__left-list__user__button']}
                                                            onClick={() => onAddUser(user)}
                                                        >
                                                            {t('buttons:add')}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto px-0 position-relative">
                    <div className={styles['add-user-input-container__separator']}></div>
                </div>
                <div className="col px-0">
                    <div className={styles['add-user-input-container__right-list']}>
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div className={styles['add-user-input-container__right-list__title-container']}>
                                    <span className={styles['add-user-input-container__right-list__title-container__text-bold']}>
                                        {usersAdded.length}
                                    </span>{' '}
                                    <span className={styles['add-user-input-container__right-list__title-container__text']}>{usersAddedTitle}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0 h-100">
                                <div className={styles['add-user-input-container__right-list__content-container']}>
                                    {usersAdded.map((user, index) => {
                                        const { isTeamLeader } = user
                                        return (
                                            <div key={index} className={styles['add-user-input-container__right-list__user']}>
                                                <div className="row mx-0 w-100">
                                                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                        <img
                                                            src={user.avatar}
                                                            alt={user.name}
                                                            className={styles['add-user-input-container__right-list__user__avatar']}
                                                        />
                                                    </div>
                                                    <div className="col px-0">
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span className={styles['add-user-input-container__right-list__user__name']}>
                                                                    {user.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span className={styles['add-user-input-container__right-list__user__email']}>
                                                                    {user.email}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {isTeamLeader && (
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={styles['add-user-input-container__left-list__user__team-leader']}
                                                                    >
                                                                        {t(`common:teamLeader`)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                        <img
                                                            src={icoClose}
                                                            alt={t('buttons:remove')}
                                                            className={styles['add-user-input-container__right-list__user__button__icon']}
                                                            onClick={() => onRemoveUser(user)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
