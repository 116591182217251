import styles from './team-management-details.module.scss'

import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../../components/loading/spinner/spinner'
import { useToggle } from '../../../hooks/useToggle'
import { useAuxBar } from '../../../providers/aux-bar-context'
import { useIsMounted } from '../../../hooks/useIsMounted'
import {
    LoaderForeground,
    ProductsAssociatedList,
    ProductsDonut,
    RevenueSalesForecast,
    SalesHomologue,
    TeamInformation,
    TeamMembersList,
    TeamSalesVsAll,
    UnitSalesForecast,
} from '../../../components'
import Auxbar from '../../../layout/auxbar/auxbar'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useFilterInputs } from '../../../providers/filter-inputs-context'
import { useFilter } from '../../../providers/filter-context'
import { TeamsService } from '../../../temp/test'
import { useLocation } from 'react-router-dom'

/**
 * Provides a page layout that allows managers to create and edit teams
 */

const TeamManagementDetails = React.memo(({ id }) => {
    const isMounted = useIsMounted()
    const { t } = useCustomTranslation()
    const { filter } = useFilter()
    const [loading, toggleLoading] = useToggle(true)
    const [teamName, setTeamName] = React.useState('')
    const { setAvailableOptions } = useAuxBar()
    useEffect(() => {
        if (isMounted.current) {
            toggleLoading(false)
            setAvailableOptions(['search'])
        }
    }, [toggleLoading, isMounted, setAvailableOptions])

    useEffect(() => {
        async function fill() {
            if (filter.teamId || id) {
                const team = await TeamsService.getApiTeams1({
                    id: filter.teamId || id,
                })
                setTeamName(team.name + ' ' + 'team details')
            }
        }
        fill()
    }, [filter, id])

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <>
                    <div className={`row mx-0 w-100 ${styles['team-management-details-container']} p-0`}>
                        <div className="col-12 h-100" style={{ padding: '0 2rem 2rem 2rem' }}>
                            <div className="row w-100 dashboard-header-row ">
                                <div className="col px-0 d-flex justify-content-between align-items-center">
                                    <span className="me-4">{t(`pages:teamManagement.title`)}</span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100 d-flex justify-content-between align-items-center" style={{ marginBottom: '2rem' }}>
                                <div className="col-auto px-0">
                                    <span className={`${styles['team-management-details-container__sub-title']}`}>{teamName}</span>
                                </div>
                            </div>
                            <div
                                className={`row w-100 mx-0 ${styles['manager-profile-container__section']}`}
                                style={{
                                    height: '35rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <div
                                    className="col h-100"
                                    style={{
                                        minWidth: 0,
                                        padding: '0',
                                    }}
                                >
                                    <TeamInformation />
                                </div>
                                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                                <div className="col px-0 h-100" style={{ minWidth: 0 }}>
                                    <TeamMembersList />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: '40rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <div
                                    className="col h-100"
                                    style={{
                                        padding: '0',
                                        width: 'calc((100% - 2rem)/2)',
                                    }}
                                >
                                    <div className="row mx-0 w-100 h-100">
                                        <div className="col px-0 h-100">
                                            <TeamSalesVsAll />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                                <div
                                    className="col-auto h-100"
                                    style={{
                                        padding: '0',
                                        width: 'calc((100% - 2rem)/2)',
                                    }}
                                >
                                    <div className="row mx-0 w-100 h-100">
                                        <div className="col px-0 h-100">
                                            <ProductsDonut />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: '40rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <div className="col px-0 h-100">
                                    <ProductsAssociatedList />
                                </div>
                            </div>
                            <div
                                className="row mx-0 w-100"
                                style={{
                                    height: '50rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <div className="col px-0 h-100">
                                    <UnitSalesForecast />
                                </div>
                            </div>
                            <div className="row mx-0 w-100" style={{ height: '50rem' }}>
                                <div className="col px-0 h-100">
                                    <SalesHomologue />
                                </div>
                            </div>
                        </div>
                        {/* <Auxbar customFilterInputs={customFilterInputsMemo} /> */}
                    </div>
                </>
            )}
        </>
    )
})

export default TeamManagementDetails

TeamManagementDetails.propTypes = {}
