import React, { useState, useRef, useEffect } from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'
import styles from './list-wrapper.module.scss'

export const ListWrapper = React.memo(({ children }) => {
    const isMounted = useIsMounted()
    const listRef = useRef(false)
    const [listShadow, setListShadow] = useState('')

    useEffect(() => {
        const top =
            listRef.current.scrollTop === 0 &&
            listRef.current.scrollHeight !== listRef.current.clientHeight
        const bottom =
            listRef.current.scrollHeight - listRef.current.scrollTop ===
                listRef.current.clientHeight &&
            listRef.current.scrollHeight !== listRef.current.clientHeight
        const middle =
            listRef.current.scrollTop !== 0 &&
            listRef.current.scrollHeight - listRef.current.scrollTop !==
                listRef.current.clientHeight
        if (top) {
            if (isMounted.current) {
                setListShadow('up')
            }
        } else if (bottom) {
            if (isMounted.current) {
                setListShadow('down')
            }
        } else if (middle) {
            if (isMounted.current) {
                setListShadow('middle')
            }
        } else {
            if (isMounted.current) {
                setListShadow('default')
            }
        }
    }, [isMounted])

    const handleScroll = (e) => {
        const top =
            e.target.scrollTop === 0 &&
            e.target.scrollHeight !== e.target.clientHeight
        const bottom =
            e.target.scrollHeight - e.target.scrollTop ===
                e.target.clientHeight &&
            e.target.scrollHeight !== e.target.clientHeight
        const middle =
            e.target.scrollTop !== 0 &&
            e.target.scrollHeight - e.target.scrollTop !== e.target.clientHeight
        if (top) {
            if (isMounted.current) {
                setListShadow('up')
            }
        } else if (bottom) {
            if (isMounted.current) {
                setListShadow('down')
            }
        } else if (middle) {
            if (isMounted.current) {
                setListShadow('middle')
            }
        } else {
            if (isMounted.current) {
                setListShadow('default')
            }
        }
    }
    return (
        <div className="w-100 h-100 position-relative px-0">
            <div
                onScroll={handleScroll}
                className={`col px-0 ${styles['list-wrapper-container']} h-100`}
                ref={listRef}
            >
                {children}
            </div>
            <div
                className={`${styles['list-wrapper-container__list-shadow']} ${
                    styles[`list-wrapper-container__list-shadow--${listShadow}`]
                }`}
            />
        </div>
    )
})
