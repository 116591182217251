import styles from './auxbar-store-information.module.scss'

import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useTextInput } from '../../../hooks/useTextInput'
import { useArray } from '../../../hooks/useArray'
import arrowDown from '../../../assets/images/dropdown-arrow.svg'
import dragImgMap from '../../../constants/dragImgMap'
import componentConfig from '../../../configs/componentLibraryConf.json'
import { PrimaryButton } from '../../buttons/primary-button/primary-button'
import { useDragComponent } from '../../../providers/drag-component-context'
import { ListWrapper, TypeInput } from '../..'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { errorHandler } from '../../../utils/api'
import { DTablesService } from '../../../temp/test'

export const AuxbarStoreInformation = React.memo(({ availableCharts }) => {
    const { t } = useCustomTranslation()
    const isMounted = useIsMounted()
    // const [storeSelected, setStoreSelected] = useState('assets')
    const [searchedAssets] = useState('')
    const { handleDragComponentStart, handleDragDynamicInfoComponentStart } = useDragComponent()

    const { value: assetsSearch, setValue: setAssetsSearchValue } = useTextInput({
        name: 'assetsSearch',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })
    const [assetsSearchResult, setAssetsSearchResult] = useState('')

    const [dynamicInfoComponents, setDynamicInfoComponents] = useState([])

    const { array: storeCategories, setArray: setStoreCategories } = useArray([
        // {
        //     name: 'text',
        //     open: false,
        // },
        {
            name: 'client',
            open: false,
        },
        {
            name: 'competitor',
            open: false,
        },
        {
            name: 'product',
            open: false,
        },
        {
            name: 'sales',
            open: false,
        },
        {
            name: 'geo',
            open: false,
        },
        {
            name: 'finance',
            open: false,
        },
        {
            name: 'region',
            open: false,
        },
        {
            name: 'hospital',
            open: false,
        },
    ])

    const handleSearchAssets = () => {
        setAssetsSearchResult(assetsSearch.value)
    }

    useEffect(() => {
        async function fill() {
            try {
                const dynamicComponents = []
                const result = await DTablesService.getApiBackofficeAdminDtables({
                    tableType: [],
                })
                result.map((el) => {
                    const filter = []
                    const category = []
                    if (el.table_type === 'product_id') {
                        filter.push('productId')
                        category.push('product')
                    }
                    if (el.table_format) {
                        const newComponent = {
                            type: 'dynamicInfoTable',
                            name: el.table_name,
                            isDynamicComponent: true,
                            dataGrid: {
                                minW: 6,
                                maxW: 12,
                                minH: 6,
                                maxH: 12,
                                w: 6,
                                h: 8,
                            },
                            configList: {
                                additionalInfo: [],
                                info: [],
                                time: [],
                                filter: [...filter],
                            },
                            category: [...category],
                        }
                        dynamicComponents.push(newComponent)
                    }
                    if (el.timeseries_format) {
                        const newComponent = {
                            type: 'dynamicInfoTimeseries',
                            name: el.table_name,
                            isDynamicComponent: true,
                            dataGrid: {
                                minW: 6,
                                maxW: 12,
                                minH: 6,
                                maxH: 12,
                                w: 6,
                                h: 8,
                            },
                            configList: {
                                additionalInfo: [],
                                info: [],
                                time: [],
                                filter: [...filter],
                            },
                            category: [...category],
                        }
                        dynamicComponents.push(newComponent)
                    }
                    return el
                })
                setDynamicInfoComponents(dynamicComponents)
            } catch (err) {
                errorHandler(err)
            }
        }
        fill()
    }, [])

    const handleOpenStore = (name) => {
        const storeCategoriesClone = _.cloneDeep(storeCategories)
        storeCategoriesClone.map((el) => {
            if (el.name === name) {
                el.open = !el.open
            }
        })
        if (isMounted.current) setStoreCategories(storeCategoriesClone)
    }

    const allCharts = useMemo(() => {
        return availableCharts.concat(dynamicInfoComponents)
    }, [availableCharts, dynamicInfoComponents])

    return (
        <React.Fragment>
            <div className={`row ${styles['auxbar-store-information__search']}`} style={{ marginTop: '1rem' }}>
                <div className="col px-0">
                    <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                        <div className="col px-0">
                            <TypeInput onChangeInput={setAssetsSearchValue} {...assetsSearch} />
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-end align-items-center mb-4">
                        <div className="col-auto mt-2 px-0">
                            <PrimaryButton text={t(`buttons:search`)} onClick={handleSearchAssets} />
                        </div>
                    </div>
                    <div className="row mx-0 w-100 d-flex justify-content-start align-items-center">
                        <div className="col-auto px-0">
                            {searchedAssets !== '' && (
                                <span className={`${styles['auxbar-store-information__search']}`}>
                                    {t('layout:auxbar.assets.resultfor')}{' '}
                                    <span className={`${styles['auxbar-store-information__search']}`}>{searchedAssets}</span>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row w-100 m-0 ${styles['auxbar-store-information__store']} flex-grow-1`}>
                <ListWrapper>
                    <div className="col px-0">
                        {Object.entries(storeCategories).map((attr, index) => {
                            const regex = new RegExp(`${assetsSearchResult}`, 'i')
                            if (
                                assetsSearchResult === '' ||
                                (allCharts &&
                                    allCharts.length > 0 &&
                                    _.some(allCharts, (el) => {
                                        return (
                                            _.has(componentConfig, el.type.split('-')[0]) &&
                                            componentConfig[el.type.split('-')[0]].category.includes(attr[1].name) &&
                                            t(`components:${el.type}.title`).match(regex)
                                        )
                                    }))
                            ) {
                                return (
                                    <React.Fragment key={index}>
                                        <div
                                            className={
                                                attr[1].open
                                                    ? `row mx-0 w-100 ${styles['auxbar-store-information__store__menu']} ${styles['open']}`
                                                    : `row mx-0 w-100 ${styles['auxbar-store-information__store__menu']}`
                                            }
                                            onClick={() => handleOpenStore(attr[1].name)}
                                        >
                                            <div className="col px-0 ">
                                                <span className={`${styles['auxbar-store-information__store__menu__text']}`}>
                                                    {t(`layout:auxbar.categories.${attr[1].name}`)}
                                                </span>
                                            </div>
                                            <div className="col-auto px-0">
                                                <img
                                                    loading="lazy"
                                                    src={arrowDown}
                                                    alt="store open and close action"
                                                    className={
                                                        attr[1].open
                                                            ? `${styles['auxbar-store-information__store__menu__icon']} ${styles['open']}`
                                                            : `${styles['auxbar-store-information__store__menu__icon']}`
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`row mx-0 ${styles['auxbar-store-information__store__menu__component-row']}`}
                                            style={{
                                                marginBottom: '1rem',
                                            }}
                                        >
                                            {attr[1].open &&
                                                _.find(allCharts, (el) => {
                                                    return el.category.includes(attr[1].name)
                                                }) === undefined && (
                                                    <div className="col px-0 d-flex justify-content-center align-items-center">
                                                        <span
                                                            className={`${styles['auxbar-store-information__store__menu__text']}`}
                                                            style={{
                                                                padding: '1rem',

                                                                border: '1px solid #e6e6e6',
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                borderRadius: '0 0 8px 8px',
                                                            }}
                                                        >
                                                            {t('layout:auxbar.store.noresults')}
                                                        </span>
                                                    </div>
                                                )}
                                            {attr[1].open &&
                                                allCharts &&
                                                allCharts.length > 0 &&
                                                allCharts.map((el, index) => {
                                                    const { isDynamicComponent } = el
                                                    if (el.category.includes(attr[1].name)) {
                                                        if (t(`components:${el.type}.title`).match(regex)) {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`col-6 px-0 ${styles['auxbar-store-information__store__menu__component-row__component']} d-flex flex-column justify-content-center align-items-center`}
                                                                    onDragStart={(e) => {
                                                                        if (isDynamicComponent) handleDragDynamicInfoComponentStart(e, el)
                                                                        else handleDragComponentStart(e, el.type)
                                                                    }}
                                                                >
                                                                    <span
                                                                        className={`${styles['auxbar-store-information__store__menu__component-row__component__title']}`}
                                                                    >
                                                                        {isDynamicComponent && el.name}
                                                                        {!isDynamicComponent && t(`components:${el.type}.title`)}
                                                                    </span>
                                                                    <img
                                                                        loading="lazy"
                                                                        src={dragImgMap[el.type]}
                                                                        alt="store component"
                                                                        className={`${styles['auxbar-store-information__store__menu__component-row__component__icon']}`}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                })}
                                        </div>
                                    </React.Fragment>
                                )
                            } else return null
                        })}
                    </div>
                </ListWrapper>
            </div>
        </React.Fragment>
    )
})

AuxbarStoreInformation.propTypes = {}
