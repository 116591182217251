import React from 'react'
import { dateSort } from '../../../constants/sort'

export const loginListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [
        { path: 'index', label: '', sortable: true },
        {
            path: 'date',
            label: '',
            sortable: true,
            customSort: (props) => dateSort(props, 'isoDate'),
        },
        { path: 'uuid', label: '', sortable: true },
        { path: 'username', label: '', sortable: true },
    ],
    sortColumn: { path: 'date', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'loginTable',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
        reference: React.createRef(),
    },
}
