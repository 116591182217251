import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api.js';
import { useArray } from '../../../hooks/useArray.js';
import { useIsMounted } from '../../../hooks/useIsMounted.js';
import { useTextInput } from '../../../hooks/useTextInput.js';
import { useToggle } from '../../../hooks/useToggle.js';
import qs from 'query-string';

import styles from './sentiment-analysis.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
    Loader,
    LoaderForeground,
    NavigationMenu,
    SentimentDemo,
    SentimentHistory,
    SentimentImportData,
    SentimentMedia,
    SentimentResults,
    Spinner
} from '../../../components/index.jsx';
import { SentimentService } from '../../../temp/test';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation.js';

/**
 * Provides a page layout that allows users to analyze sentiment around data
 */

const SentimentAnalysis = React.memo(() => {
    const isMounted = useIsMounted();
    const { i18n } = useTranslation();
    const { t } = useCustomTranslation();
    const [loading, toggleLoading] = useToggle(true);
    const { array: subNavs } = useArray([
        'demo',
        'newAnalysis',
        'media',
        'history',
        'results',
        'api'
    ]);
    const [currentSubNav, setCurrentSubNav] = useState('demo');
    const [sentimentPositive, setSentimentPositive] = useState(0);
    const [sentimentNegative, setSentimentNegative] = useState(0);
    const [sentimentNeutral, setSentimentNeutral] = useState(0);
    const [sentimentConfidence] = useState(0);
    const [sentimentLoading, toggleSentimentLoading] = useToggle(false);
    const [resultsLoading, toggleResultsLoading] = useToggle(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedAnalysisId, setSelectedAnalysisId] = useState('');
    const {
        array: keywords,
        push: pushKeywords,
        clear: clearKeywords
    } = useArray([]);
    const {
        value: document,
        setValue: setDocumentValue,
        setError: setDocumentError
    } = useTextInput({
        name: 'document',
        value: '',
        error: '',
        hidden: false,
        state: 'normal'
    });

    const schema = Joi.object({
        document: Joi.string().required()
    });

    useEffect(() => {
        const search = qs.parse(location.search);
        if (_.has(search, 'analysis-id')) {
            setSelectedAnalysisId(search['analysis-id']);
        }
        if (isMounted.current) toggleLoading(false);
    }, [isMounted, toggleLoading, location, setSelectedAnalysisId]);

    const handleChangeSubNav = (option) => {
        if (isMounted.current) setCurrentSubNav(option);
    };

    const handleClassifyText = async () => {
        if (isMounted.current) {
            clearKeywords();
            setSentimentPositive(0);
            setSentimentNegative(0);
            setSentimentNeutral(0);
        }
        try {
            const result = schema.validate(
                {
                    document: document.value
                },
                { abortEarly: false }
            );
            if (isMounted.current) setDocumentError();
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'document') {
                        if (isMounted.current) setDocumentError(el.type);
                    }
                });
                return;
            }
            if (isMounted.current) toggleSentimentLoading(true);
            const postSentiment = {};
            postSentiment.document = document.value;
            postSentiment.input_keywords = [];
            const sentimentData = await SentimentService.postApiNlpDemo({
                requestBody: { document: document.value, input_keywords: [] }
            });
            if (isMounted.current) {
                setSentimentPositive(
                    sentimentData.label === 'positive'
                        ? Math.abs(sentimentData.score.toFixed(2) * 100)
                        : 0
                );
                setSentimentNeutral(
                    sentimentData.label === 'neutral'
                        ? Math.abs(sentimentData.score.toFixed(2) * 100)
                        : 0
                );
                setSentimentNegative(
                    sentimentData.label === 'negative'
                        ? Math.abs(sentimentData.score.toFixed(2) * 100)
                        : 0
                );
            }
            if (sentimentData.keywords && sentimentData.keywords.length !== 0) {
                sentimentData.keywords.map((el) => {
                    if (isMounted.current)
                        pushKeywords({
                            value: el.text,
                            sentiment: el.sentiment.label
                        });
                    return el;
                });
            }
            if (isMounted.current) toggleSentimentLoading(false);
        } catch (err) {
            errorHandler(err);
            if (isMounted.current) toggleSentimentLoading(false);
        }
    };

    const handleSetResults = (results) => {
        const params = new URLSearchParams();
        params.append('analysis-id', results.sessionId);
        navigate({
            pathname: location.pathname,
            search: params.toString(),
            replace: true
        });
        setSelectedAnalysisId(results.sessionId);
        handleChangeSubNav('results');
        toggleResultsLoading(false);
    };

    const handleSelectAnalysis = (analysis) => {
        toggleResultsLoading(true);
        const params = new URLSearchParams();
        params.append('analysis-id', analysis.id);
        navigate({
            pathname: location.pathname,
            search: params.toString(),
            replace: true
        });
        setSelectedAnalysisId(analysis.id);
        handleChangeSubNav('results');
        toggleResultsLoading(false);
    };

    const handleNavigateToResults = () => {
        toggleResultsLoading(true);
        handleChangeSubNav('results');
    };

    return (
        <>
            {loading && <LoaderForeground />}
            {!loading && (
                <div
                    className={`row mx-0 w-100 ${styles['sentiment-analysis-container']}`}
                >
                    <div className="col px-0 d-flex flex-column">
                        <div className="row w-100 dashboard-header-row">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <span>
                                    {t(`pages:sentimentAnalysis.title`)}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0 d-flex flex-column justify-content-center align-items-center">
                                <div className={`row mx-0 w-100 h-100`}>
                                    <div className="col px-0">
                                        <NavigationMenu
                                            isPageMenu
                                            options={subNavs}
                                            selectedOption={currentSubNav}
                                            onChangeOption={handleChangeSubNav}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {currentSubNav === 'demo' && (
                            <SentimentDemo
                                testYourTextTitle={t(
                                    `pages:sentimentAnalysis.demo.testYourText`
                                )}
                                classifyTextLabel={t(
                                    `pages:sentimentAnalysis.demo.classifyText`
                                )}
                                resultsTitle={t(
                                    `pages:sentimentAnalysis.demo.results.title`
                                )}
                                sentimentLabel={t(
                                    `pages:sentimentAnalysis.demo.results.sentiment`
                                )}
                                confidenceLabel={t(
                                    `pages:sentimentAnalysis.demo.results.confidence`
                                )}
                                positiveTitle={t(
                                    `pages:sentimentAnalysis.demo.results.positive`
                                )}
                                negativeTitle={t(
                                    `pages:sentimentAnalysis.demo.results.negative`
                                )}
                                neutralTitle={t(
                                    `pages:sentimentAnalysis.demo.results.neutral`
                                )}
                                keywordsTitle={t(
                                    `pages:sentimentAnalysis.demo.results.keywords`
                                )}
                                document={document}
                                t={t}
                                sentimentPositive={sentimentPositive}
                                sentimentNegative={sentimentNegative}
                                sentimentNeutral={sentimentNeutral}
                                sentimentConfidence={sentimentConfidence}
                                loading={sentimentLoading}
                                keywords={keywords}
                                onClassifyText={handleClassifyText}
                                onChange={setDocumentValue}
                            />
                        )}
                        {currentSubNav === 'newAnalysis' && (
                            <div
                                className={`row mx-0 w-100 ${styles['sentiment-analysis-container__data-wrapper']}`}
                            >
                                <div className="col px-0">
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            <SentimentImportData
                                                onSetResults={handleSetResults}
                                                onNavigateToResults={
                                                    handleNavigateToResults
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentSubNav === 'history' && (
                            <SentimentHistory
                                onSelectAnalysis={handleSelectAnalysis}
                            />
                        )}
                        {currentSubNav === 'results' && resultsLoading && (
                            <div className="row mx-0 w-100 flex-grow-1">
                                <div className="col px-0 h-100">
                                    <Spinner />
                                </div>
                            </div>
                        )}
                        {currentSubNav === 'results' && !resultsLoading && (
                            <div
                                className={`row mx-0 w-100 flex-grow-1 ${styles['sentiment-analysis-container__results-wrapper']}`}
                            >
                                {selectedAnalysisId && (
                                    <div className="col px-0 d-flex justify-content-center align-items-start">
                                        <SentimentResults
                                            analysisId={selectedAnalysisId}
                                        />
                                    </div>
                                )}
                                {!selectedAnalysisId && (
                                    <div className="col px-0 d-flex justify-content-center align-items-center">
                                        <span
                                            className={`${styles['sentiment-analysis-container__results-wrapper__no-data']}`}
                                        >
                                            {t(
                                                `components:sentimentResults.nodata`
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        {currentSubNav === 'media' && (
                            <SentimentMedia
                                onSetResults={handleSetResults}
                                onNavigateToResults={handleNavigateToResults}
                            />
                        )}
                        {currentSubNav === 'api' && (
                            <div
                                className={`row mx-0 w-100 ${styles['sentiment-analysis-container__api-wrapper']}`}
                            ></div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
});

export default SentimentAnalysis;

SentimentAnalysis.propTypes = {};
