import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDashboardSettings } from '../../../../../providers/dashboard-settings-context';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { ActionBar } from '../../../../actions/action-bar/action-bar';
import icoArrowBackAction from '../../../../../assets/images/icoArrowBackAction.svg';
import icoSaveCurrentDashboard from '../../../../../assets/images/icoSaveCurrentDashboard.svg';

/**
 * Header present in published dashboards being edited which allows for updating the layout
 */

export const EditHeader = React.memo(
    ({
        dashboardTitle,
        availableCharts,
        notAvailableCharts,
        onNavigate,
        onUpdateRoute
    }) => {
        const { t } = useCustomTranslation();
        const { toggleIsEdit } = useDashboardSettings();
        return (
            <div className="row w-100 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <span className="header-title me-4">
                        {(dashboardTitle === 'dashboard' ||
                            dashboardTitle === 'clientoverview' ||
                            dashboardTitle === 'clientdetails' ||
                            dashboardTitle === 'productoverview' ||
                            dashboardTitle === 'prospects') &&
                            t(`pages:customDashboard.${dashboardTitle}`)}
                        {dashboardTitle !== 'dashboard' &&
                            dashboardTitle !== 'clientoverview' &&
                            dashboardTitle !== 'clientdetails' &&
                            dashboardTitle !== 'prospects' &&
                            dashboardTitle !== 'productoverview' &&
                            dashboardTitle}
                    </span>
                </div>
                <div className="col-auto px-0">
                    <ActionBar
                        actions={[
                            {
                                text: t('buttons:cancel'),
                                icon: icoArrowBackAction,
                                onClick: () => {
                                    onNavigate(
                                        undefined,
                                        undefined,
                                        'back',
                                        'components'
                                    );
                                }
                            },
                            {
                                text: t('buttons:save'),
                                icon: icoSaveCurrentDashboard,
                                onClick: () => {
                                    onUpdateRoute(
                                        dashboardTitle,
                                        availableCharts,
                                        notAvailableCharts
                                    );
                                    toggleIsEdit(false);
                                }
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
);

EditHeader.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
    /**
     * Contains the list of library charts to populate the store in case the user updates the page layout
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Contains the list of charts when updating the layout
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Function allowing the user to 'go back'
     */
    onNavigate: PropTypes.func,
    /**
     * Fuction allowing the user to update the published dashboard
     */
    onUpdateRoute: PropTypes.func
};
