import Joi from 'joi';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSelectInput } from '../../../hooks/useSelectInput';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { reportTeamListMock } from './mock';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { DeleteButton } from '../../buttons/delete-button/delete-button';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { Modal } from '../../modals/modal/modal';
import { SelectInput } from '../../inputs/select-input/select-input';
import { Table } from '../../tables/table/table';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays logins in a table
 */

export const ReportTeamList = React.memo(() => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [modal, toggleModal] = useToggle(false);
    const [modalType, setModalType] = useState('');
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon
    } = useTable(reportTeamListMock, modal, '');
    const {
        value: teamName,
        toggleDropdown: teamNameToggleDropdown,
        selectOption: teamNameSelectOption,
        keyPress: teamNameKeyPress,
        keyDown: teamNameKeyDown,
        setValue: teamNameSetValue,
        setError: teamNameSetError
    } = useSelectInput({
        name: 'teamName',
        showOptions: true,
        options: [],
        optionsAux: [],
        selectedOption: '',
        selectedOptionAux: '',
        state: 'normal',
        value: '',
        focused: -1,
        reference: useRef()
    });
    const schema = Joi.object({
        teamName: Joi.string().required()
    });
    const getTeams = useCallback(async () => {
        const dataClone = _.cloneDeep(reportTeamListMock);
        try {
            if (isMounted.current) setLoading(true);
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            dataClone.currentPage = 1;
            if (isMounted.current) setValue(dataClone);
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            verifyComponent(dataClone);
            if (isMounted.current) {
                setValue(dataClone);
                setLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, setLoading, setValue]);

    useEffect(() => {
        getTeams();
    }, [getTeams]);

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false);
            setModalType('');
        }
    };

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (isMounted.current) {
                toggleComponentSettings(false);
                toggleModal(true);
                setModalType(option);
            }
        },
        [isMounted, toggleComponentSettings, toggleModal]
    );

    const handleAddTeam = async () => {
        try {
            const result = schema.validate(
                {
                    teamName: teamName.selectedOption
                },
                { abortEarly: false }
            );
            if (isMounted.current) {
                teamNameSetError();
            }
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'teamName') {
                        if (isMounted.current) teamNameSetError(el.type);
                    }
                    return null;
                });
                return;
            }
            if (isMounted.current) {
                toggleModal(false);
                setLoading(false);
            }
            getTeams();
        } catch (err) {
            errorHandler(err);
        }
    };

    const handleRemoveTeam = async () => {};

    return (
        <ContentContainer
            attr={data.attr}
            hidePinIcon
            disabled={data.disabled}
            isForecast={data.isForecast}
            legend={data.legend}
            settings={data.settings}
            onClickSettingsIcon={toggleComponentSettings}
            onClickSettingsOptions={handleSelectSettingsOption}
            onCloseSettings={toggleComponentSettings}
        >
            {data.loading && <Spinner />}
            {!data.loading && (
                <React.Fragment>
                    {modal && modalType === 'addTeam' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('components:reportTeamList.addTeam')}
                                    </span>
                                </div>
                            </div>

                            <div
                                className="row mx-0 "
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <SelectInput
                                        onChangeInput={teamNameSetValue}
                                        onSelectInput={teamNameSelectOption}
                                        onKeyPress={teamNameKeyPress}
                                        onKeyDown={teamNameKeyDown}
                                        onToggleDropdown={
                                            teamNameToggleDropdown
                                        }
                                        {...teamName}
                                    />
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <SecondaryButton
                                        type={'cancel'}
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-5 px-0">
                                    <PrimaryButton
                                        text={t('modals:save')}
                                        onClick={handleAddTeam}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    {modal && modalType === 'removeTeam' && (
                        <Modal onToggleModal={handleCloseModal}>
                            <div className="row mx-0 mb-4">
                                <div className="col px-0">
                                    <span className="modal-title">
                                        {t('pages:managerProfile.areyousure')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 d-flex justify-content-between align-items-center">
                                <div className="col-5 px-0">
                                    <SecondaryButton
                                        text={t('modals:cancel')}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                                <div className="col-5 px-0">
                                    <PrimaryButton
                                        text={t('modals:remove')}
                                        onClick={handleRemoveTeam}
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                    <Table
                        actions={data.actions}
                        // onClickActionIcon={onClickActionIcon}
                        // onClickActionOption={onClickActionOption}
                        // onCloseActions={onCloseActions}
                        attr={data.attr}
                        type={data.type}
                        titleCounter={data.titleCounter}
                        titleText={t(`components:${data.attr}.title`)}
                        placeholderText={t(`components:${data.attr}.nodata`)}
                        columns={data.columns}
                        data={data.sortedData}
                        sortColumn={data.sortColumn}
                        onSort={dataSort}
                        currentPage={data.currentPage}
                        pageSize={data.pageSize}
                        onPageChange={dataPageChange}
                        onRowClick={undefined}
                        onRowSelect={undefined}
                        onSelectAll={dataSelectAll}
                        onUnselectAll={dataUnselectAll}
                        paginationDisabled={data.paginationDisabled}
                        disabled={data.disabled}
                        onClickActionIcon={clickActionIcon}
                        onCloseActions={clickActionIcon}
                        onClickActionOption={handleSelectSettingsOption}
                    ></Table>
                </React.Fragment>
            )}
        </ContentContainer>
    );
});

ReportTeamList.propTypes = {};
