import styles from './topbar.module.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import icoGoBack from '../../assets/images/ico-go-back.svg'
import icoAdministration from '../../assets/images/icoAdministration.svg'
import icoBackOffice from '../../assets/images/icoBackOffice2.svg'
import arrowDown from '../../assets/images/dropdown-arrow.svg'
import icoNotification from '../../assets/images/ico-notification.svg'
import { iconMap } from '../../constants/iconMap'
import { useIsMounted } from '../../hooks/useIsMounted'
import { useToggle } from '../../hooks/useToggle'
import { useCurrentApp } from '../../providers/current-app-context'
import { useCurrentUser } from '../../providers/current-user-context'
import { useNavBar } from '../../providers/nav-bar-context'
import { useNotifications } from '../../providers/notifications-context'
import _, { set } from 'lodash'
import { errorHandler } from '../../utils/api'
import { NotificationsService } from '../../temp/test'
import { useModalPopup } from '../../providers/modal-popup-context'
import { FullTextPopup, Modal, Notification, NotificationList, TutorialHub, TutorialSteps } from '../../components'
import { useCustomTranslation } from '../../hooks/useCustomTranslation'
import { usePlatformFormat } from '../../providers/platform-format-context'
import { useMainScroll } from '../../hooks/main-scroll-context'

/**
 * Top bar containing details about the current user and notifications
 */

const Topbar = React.memo(({ profileRoutes, isModalOpen }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { scrollTop } = useMainScroll()
    const isMounted = useIsMounted()
    const { currentUser } = useCurrentUser()
    const { format: platformFormat } = usePlatformFormat()
    const { currentApp, setCurrentApp } = useCurrentApp()
    const userDropdownReference = React.createRef()
    const userDropdownButtonReference = React.createRef()
    const notificationDropdownReference = React.createRef()
    const notificationDropdownButtonReference = React.createRef()
    const [showBlurredBackground, setShowBlurredBackground] = useState(false)
    const { navOpen } = useNavBar()
    const { t } = useCustomTranslation()
    const { notifications, removeNotification, getNotifications } = useNotifications()

    const [notificationsUnread, toggleNotificationsUnread] = useToggle(false)
    const [userDropdown, toggleUserDropdown] = useToggle(false)
    const [notificationsDropdown, toggleNotificationsDropdown] = useToggle(false)
    const [selectedNotification, setSelectedNotification] = useState('')

    //const userDropdownReference = useRef();

    const style = navOpen ? 'open' : 'closed'

    useEffect(() => {
        // Example logic for determining the current app based on the path
        const path = location.pathname
        if (path.startsWith('/administration')) {
            setCurrentApp('administration')
        } else if (path.startsWith('/back-office')) {
            setCurrentApp('back-office')
        } else {
            setCurrentApp('pages')
        }
    }, [location, setCurrentApp])

    useEffect(() => {
        let unreadStatus = false
        notifications.map((el) => {
            if (!el.read) {
                unreadStatus = true
            }
            return el
        })
        toggleNotificationsUnread(unreadStatus)
    }, [notifications, toggleNotificationsUnread])

    const handleCloseDropdowns = useCallback(() => {
        if (isMounted.current) {
            toggleUserDropdown(false)
            toggleNotificationsDropdown(false)
        }
    }, [isMounted, toggleUserDropdown, toggleNotificationsDropdown])

    const handleOutsideUserClick = useCallback(
        (event) => {
            if (
                userDropdownReference &&
                userDropdownReference.current &&
                !userDropdownReference.current.contains(event.target) &&
                userDropdownButtonReference &&
                userDropdownButtonReference.current &&
                !userDropdownButtonReference.current.contains(event.target)
            ) {
                handleCloseDropdowns()
            }
        },
        [handleCloseDropdowns, userDropdownReference, userDropdownButtonReference]
    )

    const handleOutsideNotificationClick = useCallback(
        (event) => {
            if (
                notificationDropdownReference &&
                notificationDropdownReference.current &&
                !notificationDropdownReference.current.contains(event.target) &&
                notificationDropdownButtonReference &&
                notificationDropdownButtonReference.current &&
                !notificationDropdownButtonReference.current.contains(event.target)
            ) {
                handleCloseDropdowns()
            }
        },
        [handleCloseDropdowns, notificationDropdownReference, notificationDropdownButtonReference]
    )

    useEffect(() => {
        if (userDropdown) {
            document.addEventListener('mousedown', handleOutsideUserClick)
        } else {
            document.removeEventListener('mousedown', handleOutsideUserClick)
        }
        if (notificationsDropdown) {
            document.addEventListener('mousedown', handleOutsideNotificationClick)
        } else {
            document.removeEventListener('mousedown', handleOutsideNotificationClick)
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideUserClick)
            document.removeEventListener('mousedown', handleOutsideNotificationClick)
        }
    }, [userDropdown, handleOutsideUserClick, notificationsDropdown, handleOutsideNotificationClick])

    const handleClickDropdownIcon = () => {
        if (userDropdown) {
            if (isMounted.current) toggleUserDropdown(false)

            //.removeEventListener('click', handleOutsideClick);
        } else {
            if (isMounted.current) {
                toggleUserDropdown(true)
                toggleNotificationsDropdown(false)
            }
            //document.addEventListener('click', handleOutsideClick);
        }
    }

    const handleClickDropdownOption = () => {
        handleCloseDropdowns(false)
    }

    const handleToggleNotificationList = () => {
        if (notificationsDropdown) {
            if (isMounted.current) {
                toggleNotificationsDropdown(false)
            }
            //document.removeEventListener('click', handleOutsideClick);
        } else {
            if (isMounted.current) {
                toggleNotificationsDropdown(true)
                toggleUserDropdown(false)
            }
            //document.addEventListener('click', handleOutsideClick);
        }
    }

    const handleClickNotification = async (el) => {
        setSelectedNotification(el)
        if (!el.read) {
            try {
                await NotificationsService.postApiNotificationsMarkAsRead({
                    requestBody: [el.id],
                })
                getNotifications()
            } catch (err) {
                errorHandler(err)
            }
        }
    }

    useEffect(() => {
        if (scrollTop > 0) {
            setShowBlurredBackground(true)
        } else {
            setShowBlurredBackground(false)
        }
    }, [scrollTop])

    return (
        <React.Fragment>
            <div
                className={`${styles['topbar']} w-100 ${styles[style]}`}
                style={{
                    zIndex: isModalOpen ? 90 : 10001,
                    pointerEvents: isModalOpen ? 'none' : 'auto',
                }}
            >
                <div
                    className={`${styles['topbar__blurred-background']} w-100`}
                    style={{
                        opacity: showBlurredBackground ? 1 : 0,
                    }}
                ></div>
                <div className={`${styles['topbar__icon-container']} p-0`}>
                    <div className={`${styles['icon-container']}`}>
                        <a href="https://www.starkdata.ai/" target="_blank" rel="noreferrer">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/blue_logo.svg'}
                                alt="Starkdata logo"
                                style={{
                                    height: '3.5rem',
                                    position: 'relative',
                                    top: '0.1rem',
                                    left: '3rem',
                                }}
                            />
                        </a>
                    </div>
                    {/* <img
                        loading="lazy"
                        src={icoToggle}
                        alt="sidebar toggler"
                        className={`${styles['topbar__toggler']}`}
                        onClick={toggleNavBar}
                    /> */}
                </div>
                <div className={`${styles['topbar__user-container']}`}>
                    {/* {(showEditDashboard ||
                        location.pathname === '/dashboard') && (
                        <div
                            className={
                                isEdit
                                    ? `${styles['nav-link']} ${styles['topbar__edit-page-container']} ${styles['selected']}`
                                    : steps && selectedTutorialStep !== ''
                                    ? `${styles['nav-link']} ${styles['topbar__edit-page-container']}`
                                    : `${styles['nav-link']} ${styles['topbar__edit-page-container']} ${styles['hoverable']}`
                            }
                            onClick={() => toggleIsEdit(true)}
                        >
                            {steps && selectedTutorialStep !== '' && (
                                <GlobalModalPopupTopbar
                                    steps={steps}
                                    toggleIsOpen={toggleIsOpen}
                                    selectedTutorialStep={selectedTutorialStep}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                />
                            )}
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                <span
                                    className={`${styles['topbar__edit-page-container__image-container']}`}
                                >
                                    <img
                                        loading="lazy"
                                        src={icoEdit}
                                        alt="edit page"
                                    />
                                </span>
                                <span
                                    className={`${styles['topbar__edit-page-container__text']}`}
                                >
                                    {t('layout:topbar.editpage')}
                                </span>
                            </div>
                        </div>
                    )} */}
                    {(currentApp === 'administration' || currentApp === 'back-office') && (
                        <div
                            className={`${styles['topbar__go-back-container']} ${styles['topbar__selectable']}`}
                            onClick={() => {
                                navigate('/dashboard', { replace: false })
                            }}
                        >
                            <img loading="lazy" src={icoGoBack} alt="" className={`${styles['topbar__go-back-container__image']}`} />
                            <span className={`${styles['topbar__go-back-container__text']}`}>{t('layout:topbar.goBack')}</span>
                        </div>
                    )}
                    {currentUser && currentUser.roles && currentUser.roles.length > 0 && currentUser.roles.includes('starkdata') && (
                        <div
                            className={
                                currentApp === 'administration'
                                    ? `${styles['topbar__icon-administration-container']} ${styles['topbar__selectable']} ${styles['selected']}`
                                    : `${styles['topbar__icon-administration-container']} ${styles['topbar__selectable']}`
                            }
                            onClick={
                                currentApp !== 'administration'
                                    ? () => {
                                          navigate('/administration/user-management/listing', { replace: false })
                                      }
                                    : undefined
                            }
                        >
                            <FullTextPopup text={t('layout:topbar.administration')}>
                                <img
                                    loading="lazy"
                                    src={icoAdministration}
                                    alt="administration"
                                    className={`${styles['topbar__icon-administration-container__icon']}`}
                                />
                            </FullTextPopup>
                        </div>
                    )}
                    {currentUser && currentUser.roles && currentUser.roles.length > 0 && currentUser.roles.includes('admin') && (
                        <div
                            className={
                                currentApp === 'back-office'
                                    ? `${styles['topbar__icon-back-office-container']} ${styles['topbar__selectable']} ${styles['selected']}`
                                    : `${styles['topbar__icon-back-office-container']} ${styles['topbar__selectable']}`
                            }
                            onClick={
                                currentApp !== 'back-office'
                                    ? () => {
                                          setCurrentApp('back-office')
                                          navigate('/back-office/config/client', {
                                              replace: false,
                                          })
                                      }
                                    : undefined
                            }
                        >
                            <FullTextPopup text={t('layout:topbar.backOffice')}>
                                <img
                                    loading="lazy"
                                    src={icoBackOffice}
                                    alt="back-office"
                                    className={`${styles['topbar__icon-back-office-container__icon']}`}
                                />
                            </FullTextPopup>
                        </div>
                    )}
                    {currentApp === 'pages' && (
                        <>
                            {/* <div
                                onClick={() => handleToggleIsOpen(!isOpen)}
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '1rem',
                                }}
                                className={`${styles['topbar__icon-tutorial-container']}`}
                            >
                                <FullTextPopup
                                    text={t('layout:topbar.tutorial')}
                                >
                                    <RingWrapper
                                        radius={8}
                                        strokeWidth={2}
                                        percentage={
                                            ((_.filter(
                                                steps,
                                                (el) => el.completed === true
                                            ).length || 0) /
                                                steps.length) *
                                            100
                                        }
                                        color={'#2c3c8d'}
                                        invert={false}
                                    >
                                        <img
                                            loading="lazy"
                                            src={icoBook}
                                            alt="open tutorial"
                                            className={
                                                isOpen
                                                    ? `${styles['topbar__icon-tutorial']} ${styles['selected']}`
                                                    : `${styles['topbar__icon-tutorial']}`
                                            }
                                        />
                                    </RingWrapper>
                                </FullTextPopup>
                            </div> */}
                            {/* <div className="h-100 position-relative d-flex justify-content-center align-items-center">
                                <FullTextPopup
                                    text={t('layout:topbar.downloadCsv')}
                                >
                                    <img
                                        loading="lazy"
                                        src={icoDownload}
                                        alt="download csv"
                                        className={
                                            onClickDownload
                                                ? `${styles['topbar__icon-download']}`
                                                : `${styles['topbar__icon-download']} ${styles['topbar__icon-download--disabled']}`
                                        }
                                        style={{ marginRight: '1rem' }}
                                        onClick={() => onClickDownload()}
                                    />
                                </FullTextPopup>
                            </div> */}
                            <div className="h-100 position-relative d-flex flex-column justify-content-center">
                                <div>
                                    <FullTextPopup text={t('layout:topbar.notifications')}>
                                        <div
                                            className={
                                                notificationsDropdown
                                                    ? `${styles['topbar__icon-notification']} ${styles['topbar__selectable']} ${styles['selected']}`
                                                    : `${styles['topbar__icon-notification']} ${styles['topbar__selectable']}`
                                            }
                                            ref={notificationDropdownButtonReference}
                                            onClick={() => handleToggleNotificationList()}
                                        >
                                            <img src={icoNotification} loading="lazy" alt="notification dropdown" />
                                        </div>
                                    </FullTextPopup>
                                    {notificationsUnread && <div className={`${styles['topbar__notification-unread']}`}></div>}
                                </div>
                            </div>
                            <div className="h-100 position-relative d-flex flex-column justify-content-center">
                                <FullTextPopup text={t('layout:topbar.profile.nav')}>
                                    <div
                                        className={
                                            userDropdown
                                                ? `${styles['topbar__user-container__profile']} ${styles['topbar__selectable']}  position-relative d-flex justify-content-center align-items-center ${styles['selected']}`
                                                : `${styles['topbar__user-container__profile']}  ${styles['topbar__selectable']} position-relative d-flex justify-content-center align-items-center`
                                        }
                                        ref={userDropdownButtonReference}
                                        onClick={() => handleClickDropdownIcon()}
                                    >
                                        <div className={`${styles['topbar__user-container__profile__image']}`}>
                                            <span className={`${styles['topbar__user-container__profile__image__text']}`}>PF</span>
                                        </div>
                                        <span className={`${styles['topbar__user-container__profile__name']}`}>
                                            {currentUser ? currentUser.username : ''}{' '}
                                        </span>
                                        <div>
                                            <div className={`${styles['topbar__user-container__profile__dropdown-icon-container']}`}>
                                                <img
                                                    loading="lazy"
                                                    src={arrowDown}
                                                    alt="user dropdown"
                                                    className={
                                                        userDropdown
                                                            ? `${styles['topbar__user-container__profile__dropdown-icon-container__icon']} ${styles['topbar__user-container__profile__dropdown-icon-container__icon--up']}`
                                                            : `${styles['topbar__user-container__profile__dropdown-icon-container__icon']}`
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FullTextPopup>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {selectedNotification && (
                <Modal onToggleModal={() => setSelectedNotification('')}>
                    <div className={`${styles['topbar__selected-notification-container']}`}>
                        <div
                            className={`row mx-0 w-100 ${styles['topbar__selected-notification-container__header-container']}`}
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col-auto px-0 d-flex justify-content-end align-items-center">
                                <span className={`row mx-0 w-100 ${styles['topbar__selected-notification-container__header-container__date']}`}>
                                    {selectedNotification.date}
                                </span>
                            </div>
                        </div>
                        <div className={`row mx-0 w-100 ${styles['topbar__selected-notification-container__header-container']}`}>
                            <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                <span className={`row mx-0 w-100 ${styles['topbar__selected-notification-container__header-container__title']}`}>
                                    {selectedNotification.text}
                                </span>
                            </div>
                        </div>
                        {/* <div className='row mx-0 w-100'>
							<div className='col px-0'>
								<span
									className={`row mx-0 w-100 ${styles['topbar__selected-notification-container__text']}`}
								>
									{selectedNotification.text}
								</span>
							</div>
						</div> */}
                    </div>
                </Modal>
            )}
            <div
                className={`${styles['topbar__user-container__notification-dropdown-menu']}`}
                ref={notificationDropdownReference}
                style={
                    notificationsDropdown
                        ? { opacity: 1, transform: 'translate(0,0)' }
                        : {
                              opacity: 0,
                              pointerEvents: 'none',
                              transform: 'translate(0,-1rem)',
                          }
                }
            >
                <NotificationList
                    onRemoveNotification={removeNotification}
                    onHandleClick={handleClickNotification}
                    title={t('notifications:title')}
                    notificationList={notifications}
                    viewText={t('notifications:viewmore')}
                    readText={t('notifications:read')}
                    unreadText={t('notifications:unread')}
                    onViewMore={() => {
                        navigate('/profile/notifications')
                        handleClickDropdownOption()
                    }}
                    placeholder={t('notifications:placeholder')}
                    successLabel={t('notifications:success')}
                    failedLabel={t('notifications:failed')}
                />
            </div>
            <div
                className={`${styles['topbar__user-container__profile__user-dropdown-menu']}`}
                ref={userDropdownReference}
                style={
                    userDropdown
                        ? { opacity: 1, transform: 'translate(0,0)' }
                        : {
                              opacity: 0,
                              pointerEvents: 'none',
                              transform: 'translate(0,-1rem)',
                          }
                }
            >
                <div className="d-flex flex-column w-100">
                    <div className="row mx-0 w-100" style={{ marginBottom: '1rem' }}>
                        <div className={`col p-0 ${styles['topbar__user-container__profile__user-dropdown-menu__title']}`}>
                            <span>{t('layout:topbar.profile.title')}</span>
                        </div>
                    </div>
                    {profileRoutes.map((el, index) => {
                        if (_.has(el, 'show') && el.show) {
                            const { format } = el
                            if (format === platformFormat || !format) {
                                return (
                                    <NavLink
                                        key={index}
                                        className={({ isActive }) => {
                                            let activeStyle = isActive ? `${styles['is-active']}` : ''
                                            return (
                                                `${styles['nav-link']} row mx-0 ${styles['topbar__user-container__profile__user-dropdown-menu__option-container']}` +
                                                ' ' +
                                                activeStyle
                                            )
                                        }}
                                        style={
                                            el.disabled
                                                ? {
                                                      pointerEvents: 'none',
                                                      opacity: 0.3,
                                                  }
                                                : {}
                                        }
                                        to={`/${el.url}`}
                                        onClick={() => handleClickDropdownOption()}
                                    >
                                        <div
                                            className={`col-auto p-0 ${styles['topbar__user-container__profile__user-dropdown-menu__option-container__option']}`}
                                        >
                                            <img
                                                loading="lazy"
                                                src={iconMap[el.icon.image]}
                                                alt="nav link"
                                                style={{
                                                    width: '2.4rem',
                                                    marginRight: '1rem',
                                                }}
                                            />
                                            <span
                                                className={`${styles['topbar__user-container__profile__user-dropdown-menu__option-container__option__text']}`}
                                            >
                                                {el.name !== 'yourContractDetails' && t(`layout:topbar.profile.${el.name}`)}
                                            </span>
                                        </div>
                                    </NavLink>
                                )
                            }
                        } else return null
                    })}
                </div>
            </div>
            <div className="topbar-notification">
                <Notification
                    successLabel={t('notifications:success')}
                    failedLabel={t('notifications:failed')}
                    onStartNotification={() => {}}
                    onFinishNotification={() => {}}
                />
            </div>
        </React.Fragment>
    )
})

export default Topbar

Topbar.propTypes = {
    /**
     * Function that provides functionality to the download icon
     */
    onClickDownload: PropTypes.func,
    /**
     * Set of routes to populate the profile section
     */
    profileRoutes: PropTypes.arrayOf(PropTypes.object),
}
