import _ from 'lodash';
import { useReducer } from 'react';

const findSubGoalRecursively = (subGoals, id) => {
    for (let i = 0; i < subGoals.length; i++) {
        if (subGoals[i].id === id) {
            return subGoals[i];
        } else if (subGoals[i].subGoals) {
            const found = findSubGoalRecursively(subGoals[i].subGoals, id);
            if (found) {
                return found;
            }
        }
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_SUBGOAL_DATA':
            const newState = _.cloneDeep(state);
            const subGoal = findSubGoalRecursively(
                newState.subGoals,
                action.id
            );
            subGoal[action.attr] = action.value;
            return { ...newState };
        default:
            return state;
    }
};

export const useSubGoalData = (defaultData) => {
    const [value, dispatch] = useReducer(reducer, defaultData);

    const updateSubGoalData = (id, attr, value) => {
        dispatch({ type: 'UPDATE_SUBGOAL_DATA', id, attr, value });
    };

    return { value, updateSubGoalData };
};
