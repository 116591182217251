import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
    ActiveClients,
    AssociatedTeams,
    BubbleGeo,
    ClientAi,
    ClientGeo,
    ClientInformation,
    ClientList,
    ClientsAtRisk,
    CompetitorInformation,
    DTableInfo,
    EvolByProductForecast,
    ExtendedGlobalGeo,
    ExtendedRiskDistribution,
    GlobalGeo,
    Homologue,
    InactiveClients,
    MarketDensity,
    MeanRisk,
    NewClients,
    ProducerDonutSales,
    ProductAtc3Donut,
    ProductAtc4Donut,
    ProductFrequentlyBoughtTogether,
    ProductInformation,
    ProductList,
    ProductMarketShareForecast,
    ProductSales,
    ProductSalesByCategory,
    ProductSalesByRegion,
    ProductStock,
    ProductStocksVsSales,
    ProductsDonut,
    ProspectGeo,
    RegionGeoSales,
    RegionInformation,
    ReportEditor,
    RevenueSales,
    RevenueSalesForecast,
    RiskDistribution,
    SalesGeo,
    SalesHomologue,
    TotalClients,
    TotalInvestment,
    UnitSales,
    UnitSalesForecast,
} from '../../'
import { RegionGeoMarketIndex } from '../../dashboard-components/region-geo-market-index/region-geo-market-index'
import { RegionGeoMarketShare } from '../../dashboard-components/region-geo-market-share/region-geo-market-share'
import { MarketShareGlobalDonut } from '../../dashboard-components/market-share-global-donut'
import { ReportBuilderProvider } from './report-builder-provider'
import { HospitalAccountInformation } from '../../dashboard-components/hospital-account-information/hospital-account-information'
import { PatientJourney } from '../../dashboard-components/patient-journey/patient-journey'
import { SwotAnalysis } from '../../dashboard-components/swot-analysis/swot-analysis'
import { AccessList } from '../../dashboard-components/access-list/access-list'
import { DmuList } from '../../dashboard-components/dmu-list/dmu-list'
import { StakeholderList } from '../../dashboard-components/stakeholder-list/stakeholder-list'
import { TargetCustomerList } from '../../dashboard-components/target-customer-list/target-customer-list'
import { TacticalPlanList } from '../../dashboard-components/tactical-plan-list/tactical-plan-list'
import { EndConsumerInformation } from '../../dashboard-components/end-consumer-information/end-consumer-information'
import { PreviewModeProvider } from './preview-mode-provider'
import { RevenueSalesForecastComparison } from '../../dashboard-components/revenue-sales-forecast-comparison'
import { UnitSalesForecastComparison } from '../../dashboard-components/unit-sales-forecast-comparison'
import { DynamicInfoTable } from '../../dashboard-components/dynamic-info-table/DynamicInfoTable'
import { DynamicInfoTimeseries } from '../../dashboard-components/dynamic-info-timeseries/DynamicInfoTimeseries'
import { FinanceTable } from '../../dashboard-components/finance-table/FinanceTable'
import { TimeIntervalProvider } from '../../../providers/time-interval-context'
import { SalesChannelProvider } from '../../../providers/sales-channel-context'
import { UserForecastFilterProvider } from '../../../providers/user-forecast-filter-context'
import componentConfig from '../../../configs/componentLibraryConf.json'
import { ProductMarketShareUnitsForecast } from '../../dashboard-components/product-market-share-units-forecast/product-market-share-units-forecast'

function areEqual(prevProps, nextProps) {
    return (
        prevProps.type === nextProps.type &&
        prevProps.component === nextProps.component &&
        prevProps.hoveredComponents === nextProps.hoveredComponents &&
        prevProps.pinnedComponents === nextProps.pinnedComponents
    )
}

export const LibraryComponentFactory = React.memo(({ component, hoveredComponents, pinnedComponents, hoveredFilter, type, ...rest }) => {
    const { isExample } = rest
    const libraryProps = {
        hovered: component.config.filter.includes(hoveredFilter) && hoveredComponents.includes(component.type),
        component: component,
        config: component.config,
        pinned: pinnedComponents.includes(component.type),
        isEdit: type === 'draft' || type === 'edit',
        isFilterConfig: type === 'filterConfig',
        isReportBuilder: type === 'previewReport' || type === 'previewReportEdit',
        isReal: type !== 'preview' && type !== 'previewReport' && type !== 'filterConfig' && type !== 'draft' && type !== 'edit',
        isPreview: type === 'preview' || type === 'previewReport' || type === 'filterConfig' || type === 'draft' || type === 'edit',
        type: component.type.split('-')[0],
        identifier: component.dataGrid.i,
        ...rest,
    }
    const componentType = useMemo(() => {
        return component.type.split('-')[0]
    }, [component.type])

    const reportBuilderValue = useMemo(() => {
        return {
            isReportBuilder: libraryProps.isReportBuilder,
        }
    }, [libraryProps.isReportBuilder])

    const componentMap = {
        totalClientsCard: TotalClients,
        activeClientsCard: ActiveClients,
        inactiveClientsCard: InactiveClients,
        newClientsCard: NewClients,
        clientsAtRiskCard: ClientsAtRisk,
        geoChart: GlobalGeo,
        extendedGeoChart: ExtendedGlobalGeo,
        clientGeoChart: ClientGeo,
        prospectGeoChart: ProspectGeo,
        salesGeoChart: SalesGeo,
        clientRiskChart: RiskDistribution,
        extendedClientRiskChart: ExtendedRiskDistribution,
        unitSalesChart: UnitSales,
        unitSalesForecastChart: UnitSalesForecast,
        unitSalesForecastComparisonChart: UnitSalesForecastComparison,
        revenueSalesChart: RevenueSales,
        revenueSalesForecastChart: RevenueSalesForecast,
        revenueSalesForecastComparisonChart: RevenueSalesForecastComparison,
        totalInvestmentCard: TotalInvestment,
        meanRiskCard: MeanRisk,
        homologueCard: Homologue,
        marketDensityCard: MarketDensity,
        dTableInfoCard: DTableInfo,
        productSalesChart: ProductSales,
        productStockChart: ProductStock,
        productStockVsSalesChart: ProductStocksVsSales,
        productSalesByRegionChart: ProductSalesByRegion,
        salesHomologueChart: SalesHomologue,
        productSalesByCategoryChart: ProductSalesByCategory,
        productDetailsCard: ProductInformation,
        productFrequentlyBoughtTogether: ProductFrequentlyBoughtTogether,
        clientTable: ClientList,
        clientOverviewCard: ClientInformation,
        clientDetailsCard: ClientAi,
        competitorDetailsCard: CompetitorInformation,
        associatedTeamsTable: AssociatedTeams,
        bubbleGeoChart: BubbleGeo,
        productAtc3DonutCard: ProductAtc3Donut,
        productAtc4DonutCard: ProductAtc4Donut,
        productMarketShareForecastChart: ProductMarketShareForecast,
        productMarketShareUnitsForecastChart: ProductMarketShareUnitsForecast,
        regionGeoSales: RegionGeoSales,
        regionGeoMarketIndex: RegionGeoMarketIndex,
        regionGeoMarketShare: RegionGeoMarketShare,
        regionInformation: RegionInformation,
        productList: ProductList,
        salesByProductCard: ProductsDonut,
        producerDonutSales: ProducerDonutSales,
        marketShareGlobalDonut: MarketShareGlobalDonut,
        evolByProductForecastChart: EvolByProductForecast,
        hospitalAccountInformation: HospitalAccountInformation,
        patientJourney: PatientJourney,
        swotAnalysis: SwotAnalysis,
        accessList: AccessList,
        dmuList: DmuList,
        stakeholderList: StakeholderList,
        targetCustomerList: TargetCustomerList,
        tacticalPlanList: TacticalPlanList,
        endConsumerInformation: EndConsumerInformation,
        dynamicInfoTable: DynamicInfoTable,
        dynamicInfoTimeseries: DynamicInfoTimeseries,
        financeTableCard: FinanceTable,
    }
    if (!Object.keys(componentMap).includes(componentType)) {
        return null
    } else if (componentType === 'textBox') {
        return (
            <ReportBuilderProvider
                value={{
                    isReportBuilder: libraryProps.isReportBuilder,
                }}
            >
                <ReportEditor {...libraryProps} content={_.has(component, 'content') ? component.content : undefined} />
            </ReportBuilderProvider>
        )
    } else {
        const Component = componentMap[componentType]
        return (
            <ReportBuilderProvider
                value={{
                    isReportBuilder: libraryProps.isReportBuilder,
                }}
            >
                <PreviewModeProvider value={{ isExample: isExample }}>
                    <div className="w-100 h-100" style={isExample ? { pointerEvents: 'none' } : {}}>
                        <TimeIntervalProvider>
                            <SalesChannelProvider>
                                <UserForecastFilterProvider>
                                    <Component {...libraryProps} />
                                </UserForecastFilterProvider>
                            </SalesChannelProvider>
                        </TimeIntervalProvider>
                    </div>
                </PreviewModeProvider>
            </ReportBuilderProvider>
        )
    }
}, areEqual)

LibraryComponentFactory.propTypes = {
    /**
     * To let each component known if they are being hovered
     */
    hoveredFilter: PropTypes.string,
    /**
     * To let each component known if they are being hovered
     */
    hoveredComponents: PropTypes.arrayOf(PropTypes.string),
    /**
     * Decides what react element to render
     */
    component: PropTypes.object,
    /**
     * Lets the api call know what timeframe to use to get the data
     */
    salesDataTimeWindow: PropTypes.string,
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * To let each component know if they are pinned
     */
    pinnedComponents: PropTypes.arrayOf(PropTypes.string),
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Component's Type
     */
    type: PropTypes.string,
}
