import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useModalPopup } from '../../../../../providers/modal-popup-context'
import { PrimaryButton, ReturnButton, TerciaryButton } from '../../../../'
import { success } from '../../../../notifications/notification/notification'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { ActionBar } from '../../../../actions/action-bar/action-bar'
import icoArrowBackAction from '../../../../../assets/images/icoArrowBackAction.svg'
import icoSaveCurrentDashboard from '../../../../../assets/images/icoSaveCurrentDashboard.svg'
import icoPublishCurrentDashboard from '../../../../../assets/images/icoPublishCurrentDashboard.svg'

/**
 * Header present in draft dashboards which allows for updating the layout or publishing the dashboard
 */

export const DraftHeader = React.memo(
    ({
        dashboardTitle,
        availableCharts,
        notAvailableCharts,
        onNavigate,
        onUpdateRoute,
        onPublishRoute,
    }) => {
        const { t } = useCustomTranslation()
        const { steps, selectedTutorialStep, currentStep, setCurrentStep } =
            useModalPopup()
        return (
            <div className="row w-100 dashboard-header-row">
                <div className="col px-0 d-flex justify-content-start align-items-center">
                    <span className="header-title me-4">
                        {(dashboardTitle === 'dashboard' ||
                            dashboardTitle === 'clientoverview' ||
                            dashboardTitle === 'clientdetails' ||
                            dashboardTitle === 'productoverview' ||
                            dashboardTitle === 'prospects') &&
                            t(`pages:customDashboard.${dashboardTitle}`)}
                        {dashboardTitle !== 'dashboard' &&
                            dashboardTitle !== 'clientoverview' &&
                            dashboardTitle !== 'clientdetails' &&
                            dashboardTitle !== 'prospects' &&
                            dashboardTitle !== 'productoverview' &&
                            dashboardTitle}
                    </span>
                </div>
                <div className="col-auto px-0">
                    <ActionBar
                        actions={[
                            {
                                text: t('buttons:cancel'),
                                icon: icoArrowBackAction,
                                onClick: () => {
                                    onNavigate(
                                        undefined,
                                        undefined,
                                        'back',
                                        'components'
                                    )
                                },
                            },
                            {
                                text: t('buttons:saveasdraft'),
                                icon: icoSaveCurrentDashboard,
                                onClick: async () => {
                                    await onUpdateRoute(
                                        dashboardTitle,
                                        availableCharts,
                                        notAvailableCharts
                                    )
                                    success({
                                        statusText: undefined,
                                        text: `${t(
                                            'notifications:savednewdraft'
                                        )}`,
                                        date: undefined,
                                    })
                                },
                            },
                            {
                                text: t('buttons:publish'),
                                icon: icoPublishCurrentDashboard,
                                onClick: () => {
                                    if (
                                        steps &&
                                        selectedTutorialStep !== '' &&
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ]['waitUntilTrigger'] &&
                                        steps[selectedTutorialStep]['steps'][
                                            currentStep
                                        ]['triggerPoint'] === 'createDashboard'
                                    ) {
                                        setCurrentStep(currentStep + 1)
                                    }
                                    onPublishRoute(
                                        dashboardTitle,
                                        availableCharts,
                                        notAvailableCharts
                                    )
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
)

DraftHeader.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
    /**
     * Contains the list of library charts to populate a store in case the user goes 'back'
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Contains the list of charts when updating the layout or publishing the dashboard
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Function allowing the user to 'go back'
     */
    onNavigate: PropTypes.func,
    /**
     * Fuction allowing the user to update the draft dashboard
     */
    onUpdateRoute: PropTypes.func,
    /**
     * Function allowing the user to publish the draft dashboard
     */
    onPublishRoute: PropTypes.func,
}
