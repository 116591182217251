import React, { useState } from 'react'
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button'
import { Modal, PrimaryButton } from '../..'
import { SuccessModal } from '../success-modal/success-modal'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { errorHandler } from '../../../utils/api'
import { useMultipleInputs } from '../../../hooks/useMultipleInputs'
import { InputFactory } from '../../inputs/input-factory/input-factory'
import _ from 'lodash'
import { useTimeSeriesManager } from '../../../providers/time-series-manager-context'

export const AddNewTimeseriesEntryModal = ({ onCloseModal, onEnd }) => {
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const { xAxis, xAxisFormat, yAxis, onAdd } = useTimeSeriesManager()
    const { t } = useCustomTranslation()
    const {
        value: inputs,
        clickRadioOption: inputsClickRadioOption,
        setValue: inputsSetValue,
        toggleDropdown: inputsToggleDropdown,
        selectOption: inputsSelectOption,
        setOptions: inputsSetOptions,
        keyPress: inputsKeyPress,
        keyDown: inputsKeyDown,
    } = useMultipleInputs({
        [xAxis]: {
            name: xAxis,
            type: 'datenew',
            format: xAxisFormat || 'day',
            value: '',
        },
        [yAxis]: {
            name: yAxis,
            type: 'type',
            value: '',
        },
    })

    const handleAddElement = async () => {
        try {
            setLoading(true)
            await onAdd(inputs)
            if (onEnd) {
                await onEnd(inputs)
            }
            setLoading(false)
            setIsSuccess(true)
        } catch (err) {
            errorHandler(err)
        }
    }

    if (!isSuccess) {
        return (
            <Modal onToggleModal={onCloseModal} isLoading={isLoading}>
                <div className="row mx-0 mb-4">
                    <div className="col px-0">
                        <span className="modal-title">{t('modals:add-new-forecast-modal.title')}</span>
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col px-0">
                        {inputs &&
                            !_.isEmpty(inputs) &&
                            Object.values(inputs) &&
                            Object.values(inputs).map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="row mx-0 w-100"
                                        style={{
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <div className="col px-0 d-flex justify-content-end align-items-center">
                                            <InputFactory
                                                {...el}
                                                clickRadioOption={inputsClickRadioOption}
                                                onChangeInput={inputsSetValue}
                                                onToggleDropdown={inputsToggleDropdown}
                                                onSelectInput={inputsSelectOption}
                                                setOptions={inputsSetOptions}
                                                keyPress={inputsKeyPress}
                                                keyDown={inputsKeyDown}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>

                <hr
                    className="my-4"
                    style={{
                        color: 'rgba(0,0,0,0.1)',
                        opacity: '1',
                    }}
                />
                <div className="row mx-0 d-flex justify-content-between align-items-center">
                    <div className="col-5 px-0">
                        <SecondaryButton text={t('modals:cancel')} onClick={onCloseModal} />
                    </div>
                    <div className="col-5 px-0">
                        <PrimaryButton text={t('modals:save')} disabled={!inputs[xAxis].value || !inputs[yAxis].value} onClick={handleAddElement} />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return <SuccessModal text={t('modals:addDynamicInfo.success')} onCloseModal={onCloseModal} />
    }
}
