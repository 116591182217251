import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import React, { useMemo } from 'react'

export const Draggable = React.memo(({ children, hoverText, id, showOver, element, style = {}, preventDrag, className, ...rest }) => {
    const { attributes, listeners, setNodeRef, transform, over, isDragging } = useDraggable({
        id,
    })

    const [isOver, setIsOver] = React.useState(false)
    const styleFinal = {
        ...style,
        zIndex: isDragging ? 1000 : 1,
        transform: CSS.Translate.toString(transform),
    }

    const FinalElement = useMemo(() => {
        return element || 'div'
    }, [element])

    const dragListeners = useMemo(() => {
        if (preventDrag) {
            return {}
        }
        return listeners
    }, [listeners, preventDrag])

    const dragAttributes = useMemo(() => {
        if (preventDrag) {
            return {}
        }
        return attributes
    }, [attributes, preventDrag])

    return (
        <FinalElement
            {...dragAttributes}
            {...dragListeners}
            ref={setNodeRef}
            className={className + (isDragging ? ' is-dragging' : '')}
            onMouseEnter={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
            {...rest}
            style={styleFinal}
        >
            {isOver && hoverText && isDragging && showOver && (
                <span
                    style={{
                        position: 'absolute',
                        top: '-4rem',
                        left: '50%',
                        fontFamily: 'OpenSansRegular',
                        fontSize: '1.4rem',
                        whiteSpace: 'nowrap',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: '0.5rem',
                        borderRadius: '0.5rem',
                        boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.1)',
                        zIndex: 1,
                    }}
                >
                    {hoverText}
                </span>
            )}
            {children}
        </FinalElement>
    )
})
