import React from 'react';
import PropTypes from 'prop-types';
import styles from './ring.module.scss';

export const Ring = React.memo(
    ({ radius, strokeWidth, percentage, invert, type, fontSize }) => {
        const colorWheel = ['#6fdb7d', '#dbdb6f', '#ffbd77', '#ff6363'];
        const getRiskColors = (percentage) => {
            const riskLevel = Math.round((percentage * 4) / 100);
            return {
                riskString: `Risk ${riskLevel}`,
                color: colorWheel[riskLevel - 1]
            };
        };

        const { riskString, color } =
            type === 'risk' ? getRiskColors(percentage) : {};

        const transform =
            type === 'leaderboard' || (type === 'risk' && !invert)
                ? `translate(${strokeWidth / 2}px ${strokeWidth / 2}px)`
                : `scale(1, -1) translate(${strokeWidth / 2}px -${
                      radius * 2 + strokeWidth - strokeWidth / 2
                  }px)`;

        return (
            <div className={styles['ring-container']}>
                <svg
                    width={`${radius * 2 + strokeWidth}px`}
                    height={`${radius * 2 + strokeWidth}px`}
                    style={
                        type === 'leaderboard'
                            ? { transform: 'rotate(45deg)' }
                            : {}
                    }
                >
                    <circle
                        cx={`${radius + strokeWidth / 2}px`}
                        cy={`${radius + strokeWidth / 2}px`}
                        r={`${radius}px`}
                        strokeWidth={`${strokeWidth}px`}
                        transform={transform}
                    />
                    <circle
                        cx={`${radius + strokeWidth / 2}px`}
                        cy={`${radius + strokeWidth / 2}px`}
                        r={`${radius}px`}
                        stroke={color}
                        strokeWidth={`${strokeWidth}px`}
                        strokeDasharray={`${
                            (2 * Math.PI * radius * percentage) / 100
                        }px ${
                            (2 * Math.PI * (100 - percentage) * radius) / 100
                        }px`}
                        transform={transform}
                    />
                </svg>
                {type === 'risk' && (
                    <span
                        className={styles['ring-container__text']}
                        style={fontSize ? { fontSize, color } : { color }}
                    >
                        {riskString}
                    </span>
                )}
                {type === 'leaderboard' && (
                    <span
                        className={styles['ring-container__text']}
                        style={fontSize ? { fontSize } : {}}
                    >
                        {percentage}
                    </span>
                )}
            </div>
        );
    }
);

Ring.propTypes = {
    radius: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    invert: PropTypes.bool,
    type: PropTypes.string.isRequired,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
