import React from 'react'
import { assignedToListMock } from './mock'
import { useCustomTranslation } from '../../../../../../../../../../../hooks/useCustomTranslation'
import { GeneralTable } from '../../../../../../../../../../../components/dashboard-components/general-table/general-table'

export const AssignedToList = () => {
    const { t } = useCustomTranslation()
    return (
        <GeneralTable
            title={t(`components:${assignedToListMock.attr}.title`)}
            attr={assignedToListMock.attr}
            columns={assignedToListMock.columns}
            mockData={assignedToListMock.data}
            withSearch
            noDataText={t(`components:${assignedToListMock.attr}.nodata`)}
            hideTitle
            withFilter
        />
    )
}
