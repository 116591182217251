export function generateRandomData(startDate, endDate) {
    const randomRoles = ['Starkdata', 'Admin', 'User'];
    const randomActions = ['Created', 'Updated', 'Deleted'];
    const randomEntities = ['Product', 'Sales', 'Region', 'Team', 'Segment'];
    const numValues = [15, 29, 12, 4, 8, 10, 15, 10, 12, 4, 8, 10, 15, 13];

    const randomData = [];

    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    let dateIndex = 0;
    while (currentDate <= endDateObj) {
        const dateStr = currentDate.toISOString().split('T')[0];

        for (let i = 0; i < numValues[dateIndex]; i++) {
            const data = {
                uuid: generateRandomUUID(),
                username: generateRandomUsername(),
                role: getRandomItemFromArray(randomRoles),
                date: dateStr,
                isoDate: currentDate.toISOString(),
                userAction: getRandomItemFromArray(randomActions),
                entity: getRandomItemFromArray(randomEntities)
            };

            randomData.push(data);
        }

        dateIndex++;
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return randomData;
}

// Function to generate a random UUID
function generateRandomUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

// Function to generate a random username
function generateRandomUsername() {
    const firstNames = [
        'Luis',
        'Beatriz',
        'Miguel',
        'Sofia',
        'João',
        'Maria',
        'Rui',
        'Ana',
        'Pedro',
        'Carolina'
    ];
    const lastNames = [
        'Silva',
        'Santos',
        'Fernandes',
        'Pereira',
        'Martins',
        'Gomes',
        'Rodrigues',
        'Almeida'
    ];

    const randomFirstName = getRandomItemFromArray(firstNames);
    const randomLastName = getRandomItemFromArray(lastNames);

    return `${randomFirstName} ${randomLastName}`;
}

// Function to get a random item from an array
function getRandomItemFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
}
