import React, { useRef } from 'react'
import styles from './technical-support-modal.module.scss'
import { PrimaryButton } from '../../buttons/primary-button/primary-button'
import modalSuccessIcon from '../../../assets/images/modalSuccessIcon.svg'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import icoCloseModalSecondary from '../../../assets/images/icoCloseModalSecondary.svg'
import { useTextInput } from '../../../hooks/useTextInput'
import { TypeInput } from '../../inputs/type-input/type-input'
import { SelectInput } from '../../inputs/select-input/select-input'
import { useSelectInput } from '../../../hooks/useSelectInput'
import { TerciaryButton } from '../../buttons/terciary-button/terciary-button'
import { ProfileService, SupportService } from '../../../temp/test'
import { success } from '../../notifications/notification/notification'
import { errorHandler } from '../../../utils/api'
import { useCurrentUser } from '../../../providers/current-user-context'

export const TechnicalSupportModal = ({ onCloseModal }) => {
    const { t } = useCustomTranslation()
    const { currentUser } = useCurrentUser()
    const {
        value: category,
        toggleDropdown: categoryToggleDropdown,
        selectOption: categorySelectOption,
        keyPress: categoryKeyPress,
        keyDown: categoryKeyDown,
        setValue: categorySetValue,
    } = useSelectInput({
        name: 'category',
        showOptions: true,
        options: [t('common:technicalIssues'), t('common:productInquiries'), t('common:feedback'), t('common:other')],
        optionsAux: [],
        selectedOption: t('common:technicalIssues'),
        selectedOptionAux: '',
        state: 'normal',
        value: t('common:technicalIssues'),
        focused: -1,
        reference: useRef(),
    })
    const { value: details, setValue: detailsSetValue } = useTextInput({
        name: 'details',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const handleSaveModal = async () => {
        try {
            const result = await ProfileService.getApiProfileUser({
                uuid: currentUser.uuid,
            })
            await SupportService.postApiSupport({
                requestBody: {
                    email: result.email,
                    message: details.value,
                    name: result.name,
                    reason: category.selectedOption,
                },
            })
            success({
                text: t('notifications:supportTicketCreated'),
            })
        } catch (err) {
            errorHandler(err)
        }
    }
    return (
        <div className={`${styles['technical-support-modal']}`}>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div className={`${styles['technical-support-modal__first-row']}`}>
                        <span className={`${styles['technical-support-modal__first-row__title']}`}>{t('modals:technicalSupport.title')}</span>
                        <img
                            src={icoCloseModalSecondary}
                            alt="close modal"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onCloseModal()
                            }}
                            className={`${styles['technical-support-modal__first-row__close-modal']}`}
                        />
                    </div>
                </div>
            </div>
            <div className="row mx-0 w-100">
                <div className="col px-0">
                    <div className={`${styles['technical-support-modal__body']}`}>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <span className={`${styles['technical-support-modal__body__text']}`}>{t('modals:technicalSupport.body')}</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <SelectInput
                                    onChangeInput={categorySetValue}
                                    onSelectInput={categorySelectOption}
                                    onKeyPress={categoryKeyPress}
                                    onKeyDown={categoryKeyDown}
                                    onToggleDropdown={categoryToggleDropdown}
                                    {...category}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ height: '20rem' }}>
                            <div className="col px-0">
                                <TypeInput inputType={'container'} onChangeInput={detailsSetValue} {...details} />
                            </div>
                        </div>
                        <hr
                            className="my-4"
                            style={{
                                color: 'rgba(0,0,0,0.1)',
                                opacity: 1,
                            }}
                        />
                        <div className="row mx-0 d-flex justify-content-between align-items-center">
                            <div className="col-5 px-0">
                                <TerciaryButton text={t('settings:cancel')} onClick={onCloseModal} />
                            </div>
                            <div className="col-5 px-0">
                                <PrimaryButton text={t('buttons:send')} onClick={handleSaveModal} disabled={category.selectedOption === ''} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
