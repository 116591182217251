import icoBack from '../../../../../assets/images/ico-sentiment-analysis.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import icoPositive from '../../../../../assets/images/ico-positive.svg';
import icoNeutral from '../../../../../assets/images/ico-neutral.svg';
import icoNegative from '../../../../../assets/images/ico-negative.svg';
import { SentimentConvoDetails } from '../../../sentiment-convo-details/sentiment-convo-details';

import styles from './sentiment-channel-analysis-media.module.scss';
import { ReturnButton } from '../../../../buttons/return-button/return-button';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';
import { SecondaryButton } from '../../../../buttons/secondary-button/secondary-button';

export const SentimentChannelAnalysisMedia = React.memo(({ media }) => {
    const { t } = useCustomTranslation();
    const [selectedRow, setSelectedRow] = useState('');
    return (
        <>
            <div
                className={`${styles['sentiment-channel-analysis-media-container']}`}
            >
                {selectedRow !== '' && (
                    <React.Fragment>
                        <div
                            className="row mx-0 "
                            style={{ marginBottom: '2rem' }}
                        >
                            <SecondaryButton
                                text={t('buttons:return')}
                                icon={{ index: 0, image: icoBack }}
                                onClick={() => setSelectedRow('')}
                            />
                        </div>
                        <div className="row mx-0 flex-grow-1">
                            <SentimentConvoDetails el={media[selectedRow]} />
                        </div>
                    </React.Fragment>
                )}
                {selectedRow === '' && (
                    <React.Fragment>
                        {media.length === 0 && (
                            <React.Fragment>
                                <div className="row mx-0 w-100 h-100">
                                    <div className="col px-0 d-flex justify-content-center align-items-center">
                                        <span
                                            className={`${styles['sentiment-channel-analysis-media-container__no-data-text']}`}
                                        >
                                            {t(
                                                'components:sentiment-channel-analysis-media.nodata'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        {media.length !== 0 && (
                            <React.Fragment>
                                <div className="row mx-0 mb-2">
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-channel-analysis-media-container__count']} me-3`}
                                        >
                                            {media.length}
                                        </span>
                                        <span
                                            className={`${styles['sentiment-channel-analysis-media-container__title']}`}
                                        >
                                            {t(
                                                `components:sentiment-channel-analysis-media.media`
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0"></div>
                                </div>
                                <div className="row mx-0 h-100 overflow-auto">
                                    <div className="col px-0">
                                        {media.length !== 0 &&
                                            media.map((el, index) => {
                                                return (
                                                    <div
                                                        key={el.id}
                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container']}`}
                                                        onClick={() =>
                                                            setSelectedRow(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <div className="row mx-0 w-100 h-100">
                                                            <div className="col-9 px-0">
                                                                <div className="row mx-0 h-100">
                                                                    {/* <div className="col-auto px-3 d-flex justify-content-center align-items-center">
                                    <img
                                      src={icoStarkSmall}
                                      alt="starkdata"
                                      className={`${styles['sentiment-channel-analysis-media-container__row-container__image']}`}
                                    />
                                  </div> */}
                                                                    <div className="col px-0 d-flex flex-column justify-content-center">
                                                                        <div className="row mx-0">
                                                                            <div className="col px-0">
                                                                                <span
                                                                                    className={`${styles['sentiment-channel-analysis-media-container__row-container__title']}`}
                                                                                >
                                                                                    {
                                                                                        el.subject
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mx-0">
                                                                            <div className="col px-0">
                                                                                <span
                                                                                    className={`${styles['sentiment-channel-analysis-media-container__row-container__text']}`}
                                                                                >
                                                                                    {
                                                                                        el.text
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col px-3 d-flex justify-content-end align-items-center">
                                                                <div className="d-flex flex-column justify-content-center align-items-center me-3">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoPositive
                                                                        }
                                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container__icon']}`}
                                                                        alt=""
                                                                    />
                                                                    <span
                                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container__text']} ${styles['sentiment-channel-analysis-media-container__row-container__text--positive']}`}
                                                                    >
                                                                        {
                                                                            el.positiveWordCounter
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex flex-column justify-content-center align-items-center me-3">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoNeutral
                                                                        }
                                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container__icon']}`}
                                                                        alt=""
                                                                    />
                                                                    <span
                                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container__text']} ${styles['sentiment-channel-analysis-media-container__row-container__text--neutral']}`}
                                                                    >
                                                                        {
                                                                            el.neutralWordCounter
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                                    <img
                                                                        loading="lazy"
                                                                        src={
                                                                            icoNegative
                                                                        }
                                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container__icon']}`}
                                                                        alt=""
                                                                    />
                                                                    <span
                                                                        className={`${styles['sentiment-channel-analysis-media-container__row-container__text']} ${styles['sentiment-channel-analysis-media-container__row-container__text--negative']}`}
                                                                    >
                                                                        {
                                                                            el.negativeWordCounter
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </>
    );
});
