/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginResponseSchema } from '../models/LoginResponseSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * Returns a message indicating whether or not the `/login` endpoint is currently rate-limited.
     * @returns any Not limited
     * @throws ApiError
     */
    public static getApiAuthCheckLoginRateLimit(): CancelablePromise<{
        /**
         * The message indicating that the rate limit has not been reached.
         */
        message?: string;
        /**
         * The HTTP status code of the response.
         */
        status_code?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/check-login-rate-limit',
            errors: {
                429: `too many attempts`,
            },
        });
    }
    /**
     * return authenticated token for user
     * @returns LoginResponseSchema call successful
     * @throws ApiError
     */
    public static postApiAuthLogin({
        requestBody,
    }: {
        requestBody: {
            /**
             * User pwd
             */
            password?: string;
            /**
             * User's username or email
             */
            username?: string;
        },
    }): CancelablePromise<LoginResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `should auth with 2fa`,
                400: `unable to log in`,
                429: `too many attempts`,
            },
        });
    }
    /**
     * send MFA challenge
     * @returns any call successful
     * @throws ApiError
     */
    public static postApiAuthLoginChallenge({
        requestBody,
    }: {
        requestBody: {
            method?: string;
            /**
             * User pwd
             */
            password?: string;
            /**
             * User's username or email
             */
            username?: string;
        },
    }): CancelablePromise<{
        /**
         * two-factor code destination
         */
        sentTo?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login/challenge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * refreshes access token with refresh_token
     * @returns LoginResponseSchema call successful
     * @throws ApiError
     */
    public static postApiAuthLoginRefresh({
        requestBody,
    }: {
        requestBody: {
            refresh_token?: string;
        },
    }): CancelablePromise<LoginResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * return authenticated token for user
     * @returns LoginResponseSchema call successful
     * @throws ApiError
     */
    public static postApiAuthLoginVerify({
        requestBody,
    }: {
        requestBody: {
            code?: string;
            /**
             * User pwd
             */
            password?: string;
            /**
             * User's username or email
             */
            username?: string;
        },
    }): CancelablePromise<LoginResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login/verify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
