import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useModalPopup } from '../../../../../providers/modal-popup-context'
import { PrimaryButton, ReturnButton, TerciaryButton } from '../../../../'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { ActionBar } from '../../../../actions/action-bar/action-bar'
import icoArrowBackAction from '../../../../../assets/images/icoArrowBackAction.svg'
import icoSaveCurrentDashboard from '../../../../../assets/images/icoSaveCurrentDashboard.svg'
import icoPublishCurrentDashboard from '../../../../../assets/images/icoPublishCurrentDashboard.svg'

export const PreviewHeaderFinal = React.memo(
    ({
        dashboardTitle,
        selectedOption,
        notAvailableCharts,
        availableCharts,
        onCreatePage,
        onNavigate,
    }) => {
        const { t } = useCustomTranslation()
        const { steps, selectedTutorialStep, currentStep, setCurrentStep } =
            useModalPopup()
        return (
            <div className="row w-100 dashboard-header-row d-flex justify-content-end">
                <div className="col-auto px-0">
                    <ActionBar
                        actions={[
                            {
                                text: t('buttons:cancel'),
                                icon: icoArrowBackAction,
                                onClick: () => {
                                    onNavigate(
                                        'preview',
                                        selectedOption,
                                        'back',
                                        'components'
                                    )
                                },
                            },
                            {
                                text: t('buttons:saveasdraft'),
                                icon: icoSaveCurrentDashboard,
                                onClick: () => {
                                    onCreatePage(
                                        availableCharts,
                                        notAvailableCharts,
                                        true
                                    )
                                },
                            },
                            {
                                text: t('buttons:publish'),
                                icon: icoPublishCurrentDashboard,
                                onClick: () => {
                                    onCreatePage(
                                        availableCharts,
                                        notAvailableCharts,
                                        false
                                    )
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
)

PreviewHeaderFinal.propTypes = {
    /**
     * Labels the header title
     */
    dashboardTitle: PropTypes.string,
    /**
     * Allows the user to return to the correct template if on the workflow of creating a new dashboard
     */
    selectedOption: PropTypes.string,
    /**
     * Contains the list of library charts to populate the store in case the user updates the page layout
     */
    availableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Contains the list of charts when updating the layout
     */
    notAvailableCharts: PropTypes.arrayOf(PropTypes.object),
    /**
     * Function allowing the user to 'go back'
     */
    onNavigate: PropTypes.func,
    /**
     * Fuction allowing the user to either save a new layout of a published page or creating a new page
     */
    onCreatePage: PropTypes.func,
}
