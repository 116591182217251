export const getClientColorByRisk = (riskColors, risk) => {
    return riskColors[(risk - 1) % riskColors.length];
};

export const getStatusColor = (status) => {
    if (status === 'Inactive') {
        return '#34b53a';
    } else if (status === 'In Progress') {
        return '#f5a623';
    } else if (status === 'Completed') {
        return '#d0021b';
    }
};

export const getPriorityColor = (priority) => {
    if (priority === 'low') {
        return '#34b53a';
    } else if (priority === 'medium') {
        return '#f5a623';
    } else if (priority === 'high') {
        return '#d0021b';
    }
};

const sentimentColors = {
    positive: '#34b53a',
    neutral: '#f5a623',
    negative: '#d0021b'
};
export const getSentimentColor = (sentiment) => {
    return sentimentColors[sentiment];
};

export function hexToRGBA(hex, alpha = 1) {
    // Remove the hash at the beginning if it's there
    hex = hex.replace(/^#/, '');

    // Parse the hex color
    let r, g, b;
    if (hex.length === 3) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
        g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
        b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    } else if (hex.length === 8) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
        alpha = parseInt(hex.slice(6, 8), 16) / 255;
    }

    // Return the RGBA color string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
