import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './segment-overview-card.module.scss';
import { useFilter } from '../../../providers/filter-context';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useArray } from '../../../hooks/useArray';
import { SegmentationService } from '../../../temp/test';
import { formatDate } from '../../../utils/date';
import { errorHandler } from '../../../utils/api';
import { SegmentCounter } from '../segment-counter';
import { SegmentDescriptor } from '../segment-descriptor';
import { SegmentTopRisk } from '../segment-top-risk';
import { Spinner } from '../../loading/spinner/spinner';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SegmentOverviewCard = React.memo(() => {
    const { filter } = useFilter();
    const isMounted = useIsMounted();
    const [loading, toggleLoading] = useToggle(true);
    const [totalCount, setTotalCount] = useState(0);
    const [lastMonthCount, setLastMonthCount] = useState(0);
    const [lastCreatedDate, setLastCreatedDate] = useState('');
    const [lastCreated, setLastCreated] = useState({
        id: '',
        name: '',
        createdAt: '',
        segmentClientCount: 0,
        platformClientCount: 0,
        segmentClientPercentage: ''
    });
    const { array: segments, setArray: setSegments } = useArray([1, 2, 3]);
    const { t } = useCustomTranslation();
    useEffect(() => {
        async function fill() {
            try {
                if (isMounted.current) {
                    toggleLoading(true);
                }
                const results =
                    await SegmentationService.getApiSegmentationSegmentationOverview();
                setTotalCount(results.count);
                setLastMonthCount(results.prev_month_count);
                if (_.has(results.last_segment, 'created_at')) {
                    setLastCreatedDate(
                        formatDate(results.last_segment.created_at)
                    );
                }
                const lastCreatedClone = {
                    id: '',
                    name: '',
                    segmentClientCount: 0,
                    platformClientCount: 0,
                    segmentClientPercentage: ''
                };
                let segmentClients = 0;
                if (_.has(results.last_segment, 'risk_distribution')) {
                    segmentClients = _.reduce(
                        results.last_segment.risk_distribution,
                        function (sum, el2) {
                            return sum + el2.count;
                        },
                        0
                    );
                }
                if (_.has(results.last_segment, 'id')) {
                    lastCreatedClone.id = results.last_segment.id;
                }
                if (_.has(results.last_segment, 'name')) {
                    lastCreatedClone.name = results.last_segment.name;
                }
                lastCreatedClone.segmentClientCount = segmentClients;
                lastCreatedClone.platformClientCount = results.total_clients;
                lastCreatedClone.segmentClientPercentage = (
                    (segmentClients * 100) /
                    results.total_clients
                ).toFixed(2);
                if (_.has(results.last_segment, 'created_at')) {
                    lastCreatedClone.createdAt = formatDate(
                        results.last_segment.created_at
                    );
                    setLastCreated(lastCreatedClone);
                }
                const segmentsClone = [];
                results.segments_at_risk.map((el) => {
                    segmentsClone.push({
                        id: el.id,
                        name: el.name,
                        risk1Percent: _.find(
                            el.risk_distribution,
                            (el2) => el2.risk === 1
                        )
                            ? (
                                  (_.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          if (el2.risk === 1) {
                                              return sum + el2.count;
                                          } else return sum;
                                      },
                                      0
                                  ) *
                                      100) /
                                  _.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          return sum + el2.count;
                                      },
                                      0
                                  )
                              ).toFixed(0)
                            : 0,
                        risk2Percent: _.find(
                            el.risk_distribution,
                            (el2) => el2.risk === 2
                        )
                            ? (
                                  (_.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          if (el2.risk === 2) {
                                              return sum + el2.count;
                                          } else return sum;
                                      },
                                      0
                                  ) *
                                      100) /
                                  _.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          return sum + el2.count;
                                      },
                                      0
                                  )
                              ).toFixed(0)
                            : 0,
                        risk3Percent: _.find(
                            el.risk_distribution,
                            (el2) => el2.risk === 3
                        )
                            ? (
                                  (_.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          if (el2.risk === 3) {
                                              return sum + el2.count;
                                          } else return sum;
                                      },
                                      0
                                  ) *
                                      100) /
                                  _.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          return sum + el2.count;
                                      },
                                      0
                                  )
                              ).toFixed(0)
                            : 0,
                        risk4Percent: _.find(
                            el.risk_distribution,
                            (el2) => el2.risk === 4
                        )
                            ? (
                                  (_.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          if (el2.risk === 4) {
                                              return sum + el2.count;
                                          } else return sum;
                                      },
                                      0
                                  ) *
                                      100) /
                                  _.reduce(
                                      el.risk_distribution,
                                      function (sum, el2) {
                                          return sum + el2.count;
                                      },
                                      0
                                  )
                              ).toFixed(0)
                            : 0
                    });
                    return el;
                });
                setSegments(segmentsClone);
                if (isMounted.current) {
                    toggleLoading(false);
                }
            } catch (err) {
                errorHandler(err);
            }
        }
        fill();
    }, [filter, isMounted, toggleLoading, t, setSegments]);
    return (
        <div
            className={`
            ${styles['segment-overview-card-container']}
        `}
        >
            <div
                className={`${styles['segment-overview-card-container__first-row']}`}
            >
                {loading && <Spinner />}
                {!loading && (
                    <div className="col px-0 h-100">
                        <div className="row mx-0 w-100 h-100">
                            <div
                                className={`col-3 pe-3 ${styles['segment-overview-card-container__first-row__counter-container']}`}
                            >
                                <SegmentCounter
                                    totalCount={totalCount}
                                    lastMonthCount={lastMonthCount}
                                    lastCreatedDate={lastCreatedDate}
                                />
                            </div>
                            <div
                                className={`col-4 pe-3 ${styles['segment-overview-card-container__first-row__descriptor-container']}`}
                            >
                                <SegmentDescriptor lastCreated={lastCreated} />
                            </div>
                            <div
                                className={`col-5 ${styles['segment-overview-card-container__first-row__top-risk-container']}`}
                            >
                                <SegmentTopRisk segments={segments} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

SegmentOverviewCard.propTypes = {};
