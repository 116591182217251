import React from 'react'
import { Tooltip } from 'recharts'
import { useCursorTrackingProvider } from '../../../providers/cursor-tracking-context'
import { TooltipFactory } from '../../chart-configuration/tooltip-factory/tooltip-factory'

export const TooltipWrapper = ({ attr, hoveredDonutPiece, ...rest }) => {
    const { position, reversedHorizontal, reversedVertical } = useCursorTrackingProvider()
    return (
        <div
            style={{
                position: 'fixed',
                outline: 'none',
                left: reversedHorizontal ? position.x - 20 : position.x + 20,
                top: reversedVertical ? position.y - 20 : position.y + 20,
                transition: 'all 0.3s ease-in-out',
                zIndex: 9999,
                pointerEvents: 'none',
            }}
        >
            <TooltipFactory
                item={{
                    type: attr,
                    index: hoveredDonutPiece,
                    ...rest,
                }}
            />
        </div>
    )
}
