import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { getSentimentColor } from '../../../utils/color';
import { verifyComponent } from '../../../utils/component';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { SentimentService } from '../../../temp/test';
import { sentimentKeywordAnalysisMock } from './mock';
import styles from './sentiment-keyword-analysis.module.scss';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

import { Scroll } from '../../scrolling/scroll/scroll';
import Scrollable from '../../scrolling/scrollable';
import { Loader } from '../../loading/Loader/Loader';

export const SentimentKeywordAnalysis = React.memo(({ analysisId }) => {
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const {
        value: data,
        setValue,
        toggleComponentSettings
    } = useSimpleComponent(sentimentKeywordAnalysisMock);

    useEffect(() => {
        async function fill() {
            const dataClone = _.cloneDeep(sentimentKeywordAnalysisMock);
            try {
                const result =
                    await SentimentService.getApiNlpAnalysisSegmentation({
                        sessionId: analysisId,
                        minDate: undefined,
                        maxDate: undefined
                    });
                const sentiments = ['positive', 'neutral', 'negative'];
                sentiments.map((el) => {
                    if (result[el] && result[el].length > 0) {
                        result[el].map((el2) => {
                            const newEl = [
                                {
                                    type: 'general',
                                    infoType: 'text',
                                    value: el2.keyword,
                                    valueColor: getSentimentColor(el),
                                    backgroundColor: '#f7f7f7'
                                },
                                {
                                    type: 'general',
                                    infoType: 'percentageNormal',
                                    value: Math.abs(el2.score * 100).toFixed(0),
                                    valueColor: getSentimentColor(el),
                                    suffixColor: getSentimentColor(el),
                                    backgroundColor: '#f7f7f7'
                                },
                                {
                                    type: 'general',
                                    infoType: 'fraction',
                                    value: el2.customers,
                                    valueColor: getSentimentColor(el),
                                    suffixColor: '#969696',
                                    fractionTotal: el2.total_customers,
                                    backgroundColor: '#f7f7f7'
                                },
                                {
                                    type: 'general',
                                    infoType: 'fraction',
                                    value: el2.messages,
                                    valueColor: getSentimentColor(el),
                                    suffixColor: '#969696',
                                    fractionTotal: el2.total_messages,
                                    backgroundColor: '#f7f7f7'
                                },

                                {
                                    type: 'channel',
                                    dataa: [
                                        {
                                            label: t(
                                                `components:${data.attr}.texts`
                                            ),
                                            value:
                                                el2.channel === 'text_sentiment'
                                                    ? el2.count
                                                    : 0,
                                            color: '#00be3c'
                                        },
                                        {
                                            label: t(
                                                `components:${data.attr}.emails`
                                            ),
                                            value:
                                                el2.channel ===
                                                'email_sentiment'
                                                    ? el2.count
                                                    : 0,
                                            color: '#00acee'
                                        },
                                        {
                                            label: t(
                                                `components:${data.attr}.calls`
                                            ),
                                            value:
                                                el2.channel === 'call_sentiment'
                                                    ? el2.count
                                                    : 0,
                                            color: '#009083'
                                        }
                                    ],
                                    fractionTotal: 34000
                                }
                            ];
                            dataClone.keywords.push(newEl);
                            return el;
                        });
                    }
                });
                dataClone.loading = false;
                verifyComponent(dataClone);
                setValue(dataClone);
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }
        if (analysisId) fill();
    }, [analysisId, data.attr, setValue, t, isMounted]);

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={undefined}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <div
                        className={`${styles['sentiment-keyword-analysis-container']}`}
                    >
                        <div
                            className="row mx-0"
                            style={{ marginBottom: '1rem' }}
                        >
                            <div className="col px-0">
                                <div
                                    className={`${styles['sentiment-keyword-analysis-container__header']}`}
                                >
                                    <span
                                        className={`${styles['sentiment-keyword-analysis-container__header__text']}`}
                                    >
                                        {t(`components:${data.attr}.keyword`)}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="col-auto px-0"
                                style={{ width: '1rem' }}
                            ></div>
                            <div className="col px-0">
                                <div
                                    className={`${styles['sentiment-keyword-analysis-container__header']}`}
                                >
                                    <span
                                        className={`${styles['sentiment-keyword-analysis-container__header__text']}`}
                                    >
                                        {t(
                                            `components:${data.attr}.confidence`
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="col-auto px-0"
                                style={{ width: '1rem' }}
                            ></div>
                            <div className="col px-0">
                                <div
                                    className={`${styles['sentiment-keyword-analysis-container__header']}`}
                                >
                                    <span
                                        className={`${styles['sentiment-keyword-analysis-container__header__text']}`}
                                    >
                                        {t(`components:${data.attr}.customers`)}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="col-auto px-0"
                                style={{ width: '1rem' }}
                            ></div>
                            <div className="col px-0">
                                <div
                                    className={`${styles['sentiment-keyword-analysis-container__header']}`}
                                >
                                    <span
                                        className={`${styles['sentiment-keyword-analysis-container__header__text']}`}
                                    >
                                        {t(`components:${data.attr}.messages`)}
                                    </span>
                                </div>
                            </div>

                            <div
                                className="col-auto px-0"
                                style={{ width: '1rem' }}
                            ></div>
                            <div className="col px-0">
                                <div
                                    className={`${styles['sentiment-keyword-analysis-container__header']}`}
                                >
                                    <span
                                        className={`${styles['sentiment-keyword-analysis-container__header__text']}`}
                                    >
                                        {t(`components:${data.attr}.channels`)}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            className="row mx-0 w-100"
                            style={{ height: '40rem' }}
                        >
                            <div className="col px-0 h-100">
                                <Scrollable>
                                    {data.keywords.map((el, index) => {
                                        return (
                                            <div
                                                className="row mx-0 w-100"
                                                key={index}
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                <div
                                                    className={`col px-0 ${styles['sentiment-keyword-analysis-container__content']}`}
                                                    style={{
                                                        backgroundImage: `url(${creationOptionBackground})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center'
                                                    }}
                                                >
                                                    <span
                                                        className={`${styles['sentiment-keyword-analysis-container__content__text']}`}
                                                        style={{
                                                            color: el[0]
                                                                .valueColor
                                                        }}
                                                    >
                                                        {el[0].value}
                                                    </span>
                                                </div>
                                                <div
                                                    className="col-auto px-0"
                                                    style={{
                                                        width: '1rem'
                                                    }}
                                                ></div>
                                                <div
                                                    className={`col px-0 ${styles['sentiment-keyword-analysis-container__content']}`}
                                                    style={{
                                                        backgroundImage: `url(${creationOptionBackground})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center'
                                                    }}
                                                >
                                                    <span
                                                        className={`${styles['sentiment-keyword-analysis-container__content__text']}`}
                                                        style={{
                                                            color: el[1]
                                                                .valueColor
                                                        }}
                                                    >
                                                        {el[1].value}%
                                                    </span>
                                                </div>
                                                <div
                                                    className="col-auto px-0"
                                                    style={{
                                                        width: '1rem'
                                                    }}
                                                ></div>
                                                <div
                                                    className={`col px-0 ${styles['sentiment-keyword-analysis-container__content']}`}
                                                    style={{
                                                        backgroundImage: `url(${creationOptionBackground})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center'
                                                    }}
                                                >
                                                    <span
                                                        className={`${styles['sentiment-keyword-analysis-container__content__text']}`}
                                                        style={{
                                                            color: el[1]
                                                                .valueColor
                                                        }}
                                                    >
                                                        {el[2].value}/
                                                        {el[2].fractionTotal}
                                                    </span>
                                                </div>
                                                <div
                                                    className="col-auto px-0"
                                                    style={{
                                                        width: '1rem'
                                                    }}
                                                ></div>
                                                <div
                                                    className={`col px-0 ${styles['sentiment-keyword-analysis-container__content']}`}
                                                    style={{
                                                        backgroundImage: `url(${creationOptionBackground})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center'
                                                    }}
                                                >
                                                    <span
                                                        className={`${styles['sentiment-keyword-analysis-container__content__text']}`}
                                                        style={{
                                                            color: el[1]
                                                                .valueColor
                                                        }}
                                                    >
                                                        {el[3].value}/
                                                        {el[3].fractionTotal}
                                                    </span>
                                                </div>
                                                <div
                                                    className="col-auto px-0"
                                                    style={{
                                                        width: '1rem'
                                                    }}
                                                ></div>
                                                <div
                                                    className={`col px-0 ${styles['sentiment-keyword-analysis-container__content']} d-flex flex-column`}
                                                    style={{
                                                        backgroundImage: `url(${creationOptionBackground})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center'
                                                    }}
                                                >
                                                    {el[4].dataa.map(
                                                        (el2, index2) => {
                                                            return (
                                                                <div
                                                                    key={index2}
                                                                    className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            backgroundColor:
                                                                                el2.color
                                                                        }}
                                                                        className={`col-auto me-2 px-0 ${styles['sentiment-keyword-analysis-container__circle']} `}
                                                                    ></div>
                                                                    <div
                                                                        className="col-auto px-0 me-3 d-flex align-items-center"
                                                                        style={{
                                                                            width: '2rem'
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={`${styles['sentiment-keyword-analysis-container__content__text']}`}
                                                                        >
                                                                            {
                                                                                el2.value
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className="col-auto px-0 d-flex align-items-center"
                                                                        style={{
                                                                            width: '5rem'
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={`${styles['sentiment-keyword-analysis-container__content__text']}`}
                                                                        >
                                                                            {
                                                                                el2.label
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Scrollable>
                            </div>
                        </div>
                        {/* <CardGrouper
                                headers={[
                                    {
                                        type: 'text',
                                        value: t(
                                            `components:${data.attr}.keyword`
                                        ),
                                        size: '1.4rem',
                                        weight: '600',
                                        color: '#4c4c4c',
                                    },
                                    {
                                        type: 'text',
                                        value: t(
                                            `components:${data.attr}.confidence`
                                        ),
                                        size: '1.4rem',
                                        weight: '600',
                                        color: '#4c4c4c',
                                    },
                                    {
                                        type: 'text',
                                        value: t(
                                            `components:${data.attr}.customers`
                                        ),
                                        size: '1.4rem',
                                        weight: '600',
                                        color: '#4c4c4c',
                                    },
                                    {
                                        type: 'text',
                                        value: t(
                                            `components:${data.attr}.messages`
                                        ),
                                        size: '1.4rem',
                                        weight: '600',
                                        color: '#4c4c4c',
                                    },
                                    {
                                        type: 'text',
                                        expand: 'channel',
                                        value: t(
                                            `components:${data.attr}.channels`
                                        ),
                                        size: '1.4rem',
                                        weight: '600',
                                        color: '#4c4c4c',
                                    },
                                ]}
                                cards={data.keywords}
                            ></CardGrouper> */}
                    </div>
                )}
            </ContentContainer>
        </>
    );
});
