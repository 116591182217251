export const regionInformationMock = {
    hoverStyle: '',
    desc: '',
    noData: false,
    loading: false,
    disabled: true,
    customInfo: [],
    attr: 'regionInformation',
    data: {},
    settings: {
        reference: {
            current: null,
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
