import React, { useMemo, useRef, useState } from 'react';
import styles from './date-input.module.scss';
import _ from 'lodash';
import icoCalendar from '../../../assets/images/icoCalendar.svg';
import { useToggle } from '../../../hooks/useToggle';
import { useLocation } from 'react-router-dom';
import { useMainScroll } from '../../../hooks/main-scroll-context';
import { DatePicker } from '../date-picker/date-picker';

export const DateInput = React.memo(
    ({
        name,
        label,
        description,
        value,
        onChangeInput,
        indexList,
        isStudy,
        isAge,
        id,
        initialDate,
        finalDate
    }) => {
        const location = useLocation();
        const searchParams = useMemo(
            () => new URLSearchParams(location.search),
            [location.search]
        );
        const realValue = useMemo(
            () => searchParams.get('data-acompanhamento'),
            [searchParams]
        );

        const [isFocused, setIsFocused] = useState(false);
        const { mainScrollRef } = useMainScroll();
        const datePickerParentRef = useRef(null);
        const datePickerRef = useRef(null);
        const inputDay = useRef(null);
        const inputMonth = useRef(null);
        const inputYear = useRef(null);
        const [datePickerPosition, setDatePickerPosition] = useState('bottom');
        const [datePickerHorizontalPosition, setDatePickerHorizontalPosition] =
            useState('bottom');
        const [datePickerIsOpen, toggleDatePickerIsOpen] = useToggle(false);

        const handleOpenDatePicker = () => {
            if (
                datePickerParentRef.current &&
                datePickerParentRef.current.getBoundingClientRect().bottom +
                    mainScrollRef.current.scrollTop +
                    454 >
                    mainScrollRef.current.scrollHeight
            ) {
                setDatePickerPosition('top');
            } else {
                setDatePickerPosition('bottom');
            }
            if (
                datePickerParentRef.current &&
                datePickerParentRef.current.getBoundingClientRect().bottom +
                    mainScrollRef.current.scrollLeft +
                    310 >
                    mainScrollRef.current.scrollWidth
            ) {
                setDatePickerHorizontalPosition('left');
            } else {
                setDatePickerHorizontalPosition('right');
            }
            if (isFocused) {
                setIsFocused(false);
            } else {
                setIsFocused(true);
            }
            toggleDatePickerIsOpen(datePickerIsOpen === true ? false : true);
        };

        const inputBoxStyle = useMemo(() => {
            return isFocused
                ? 'col-12 input-box selected'
                : value &&
                  (value.split('/')[0] ||
                      value.split('/')[1] ||
                      value.split('/')[2])
                ? 'col-12 input-box value'
                : 'col-12 input-box';
        }, [isFocused, value]);

        return (
            <div className="row mx-0 w-100 h-100">
                <div className="col px-0">
                    <div className={`${styles['date-input-container']}`}>
                        <div className="w-100 h-100">
                            <div className="col px-0">
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                    style={{
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                        {label && (
                                            <span
                                                className={`${styles['date-input-container__label']}`}
                                            >
                                                {label}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="row mx-0 w-100">
                                    <div className="col px-0">
                                        {description && (
                                            <span className="sub-title">
                                                {description}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="row mx-0 w-100"
                                    style={{
                                        minWidth: '18rem'
                                    }}
                                >
                                    <div className={inputBoxStyle}>
                                        <div className="row mx-0 w-100">
                                            <>
                                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                    <input
                                                        type={'text'}
                                                        ref={inputDay}
                                                        onFocus={() => {
                                                            inputDay.current.select();
                                                            setIsFocused(true);
                                                        }}
                                                        onBlur={() =>
                                                            setIsFocused(false)
                                                        }
                                                        className={`input-box hidden ${styles['date-input-container__text']}`}
                                                        style={{
                                                            textAlign: 'center',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                        size={2}
                                                        placeholder="dd"
                                                        value={
                                                            id ===
                                                            'dataDaRecolhaAcompanhamento'
                                                                ? realValue &&
                                                                  realValue.split(
                                                                      '/'
                                                                  )[0] &&
                                                                  realValue.split(
                                                                      '/'
                                                                  )[0].length <
                                                                      2
                                                                    ? realValue &&
                                                                      '0'.repeat(
                                                                          2 -
                                                                              realValue.split(
                                                                                  '/'
                                                                              )[0]
                                                                                  .length
                                                                      ) +
                                                                          realValue.split(
                                                                              '/'
                                                                          )[0]
                                                                    : (realValue &&
                                                                          realValue.split(
                                                                              '/'
                                                                          )[0]) ||
                                                                      ''
                                                                : value &&
                                                                  value.split(
                                                                      '/'
                                                                  )[0] &&
                                                                  value.split(
                                                                      '/'
                                                                  )[0].length <
                                                                      2
                                                                ? value &&
                                                                  '0'.repeat(
                                                                      2 -
                                                                          value.split(
                                                                              '/'
                                                                          )[0]
                                                                              .length
                                                                  ) +
                                                                      value.split(
                                                                          '/'
                                                                      )[0]
                                                                : (value &&
                                                                      value.split(
                                                                          '/'
                                                                      )[0]) ||
                                                                  ''
                                                        }
                                                        onChange={(e) => {
                                                            let finalValue =
                                                                e.target.value;
                                                            if (
                                                                finalValue.length >
                                                                2
                                                            ) {
                                                                if (value) {
                                                                    onChangeInput(
                                                                        finalValue.slice(
                                                                            1
                                                                        ) +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[1] +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[2],
                                                                        name,
                                                                        'date'
                                                                    );
                                                                } else {
                                                                    onChangeInput(
                                                                        finalValue.slice(
                                                                            1
                                                                        ) +
                                                                            '/' +
                                                                            '/',
                                                                        name,
                                                                        'date'
                                                                    );
                                                                }
                                                            } else {
                                                                if (value) {
                                                                    onChangeInput(
                                                                        finalValue +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[1] +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[2],
                                                                        name,
                                                                        'date'
                                                                    );
                                                                } else {
                                                                    onChangeInput(
                                                                        finalValue +
                                                                            '//',
                                                                        name,
                                                                        'date'
                                                                    );
                                                                }
                                                            }
                                                            if (
                                                                finalValue.length >
                                                                2
                                                            ) {
                                                                inputMonth.current.focus();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="col-auto px-1 d-flex justify-content-center align-items-center"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        userSelect: 'none'
                                                    }}
                                                >
                                                    /
                                                </div>
                                                <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                    <input
                                                        type={'text'}
                                                        ref={inputMonth}
                                                        onFocus={() => {
                                                            inputMonth.current.select();
                                                            setIsFocused(true);
                                                        }}
                                                        onBlur={() =>
                                                            setIsFocused(false)
                                                        }
                                                        placeholder="mm"
                                                        size={3}
                                                        className={`input-box hidden ${styles['date-input-container__text']}`}
                                                        style={{
                                                            textAlign: 'center',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                        value={
                                                            id ===
                                                            'dataDaRecolhaAcompanhamento'
                                                                ? realValue &&
                                                                  realValue.split(
                                                                      '/'
                                                                  )[1] &&
                                                                  realValue.split(
                                                                      '/'
                                                                  )[1].length <
                                                                      2
                                                                    ? realValue &&
                                                                      '0'.repeat(
                                                                          2 -
                                                                              realValue.split(
                                                                                  '/'
                                                                              )[1]
                                                                                  .length
                                                                      ) +
                                                                          realValue.split(
                                                                              '/'
                                                                          )[1]
                                                                    : (realValue &&
                                                                          realValue.split(
                                                                              '/'
                                                                          )[1]) ||
                                                                      ''
                                                                : value &&
                                                                  value.split(
                                                                      '/'
                                                                  )[1] &&
                                                                  value.split(
                                                                      '/'
                                                                  )[1].length <
                                                                      2
                                                                ? value &&
                                                                  '0'.repeat(
                                                                      2 -
                                                                          value.split(
                                                                              '/'
                                                                          )[1]
                                                                              .length
                                                                  ) +
                                                                      value.split(
                                                                          '/'
                                                                      )[1]
                                                                : (value &&
                                                                      value.split(
                                                                          '/'
                                                                      )[1]) ||
                                                                  ''
                                                        }
                                                        onChange={(e) => {
                                                            let finalValue =
                                                                e.target.value;
                                                            if (
                                                                finalValue.length >
                                                                2
                                                            ) {
                                                                if (value) {
                                                                    onChangeInput(
                                                                        value.split(
                                                                            '/'
                                                                        )[0] +
                                                                            '/' +
                                                                            finalValue.slice(
                                                                                1
                                                                            ) +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[2],
                                                                        name,
                                                                        'date'
                                                                    );
                                                                } else {
                                                                    onChangeInput(
                                                                        '/' +
                                                                            finalValue.slice(
                                                                                1
                                                                            ) +
                                                                            '/',
                                                                        name,
                                                                        'date'
                                                                    );
                                                                }
                                                            } else {
                                                                if (value) {
                                                                    onChangeInput(
                                                                        value.split(
                                                                            '/'
                                                                        )[0] +
                                                                            '/' +
                                                                            finalValue +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[2],
                                                                        name,
                                                                        'date'
                                                                    );
                                                                } else {
                                                                    onChangeInput(
                                                                        '/' +
                                                                            finalValue +
                                                                            '/',
                                                                        name,
                                                                        'date'
                                                                    );
                                                                }
                                                            }
                                                            if (
                                                                finalValue.length >
                                                                2
                                                            ) {
                                                                inputYear.current.focus();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="col-auto px-1 d-flex justify-content-center align-items-center"
                                                    style={{
                                                        pointerEvents: 'none',
                                                        userSelect: 'none'
                                                    }}
                                                >
                                                    /
                                                </div>
                                                <div className="col-auto px-0 d-flex justify-content-start align-items-center">
                                                    <input
                                                        type={'text'}
                                                        ref={inputYear}
                                                        onFocus={() => {
                                                            inputYear.current.select();
                                                            setIsFocused(true);
                                                        }}
                                                        onBlur={() =>
                                                            setIsFocused(false)
                                                        }
                                                        placeholder="aaaa"
                                                        size={4}
                                                        className={`input-box hidden ${styles['date-input-container__text']}`}
                                                        style={{
                                                            textAlign: 'center',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                        value={
                                                            id ===
                                                            'dataDaRecolhaAcompanhamento'
                                                                ? realValue &&
                                                                  realValue.split(
                                                                      '/'
                                                                  )[2] &&
                                                                  realValue.split(
                                                                      '/'
                                                                  )[2].length <
                                                                      4
                                                                    ? realValue &&
                                                                      '0'.repeat(
                                                                          4 -
                                                                              realValue.split(
                                                                                  '/'
                                                                              )[2]
                                                                                  .length
                                                                      ) +
                                                                          realValue.split(
                                                                              '/'
                                                                          )[2]
                                                                    : (realValue &&
                                                                          realValue.split(
                                                                              '/'
                                                                          )[2]) ||
                                                                      ''
                                                                : value &&
                                                                  value.split(
                                                                      '/'
                                                                  )[2] &&
                                                                  value.split(
                                                                      '/'
                                                                  )[2].length <
                                                                      4
                                                                ? value &&
                                                                  '0'.repeat(
                                                                      4 -
                                                                          value.split(
                                                                              '/'
                                                                          )[2]
                                                                              .length
                                                                  ) +
                                                                      value.split(
                                                                          '/'
                                                                      )[2]
                                                                : value &&
                                                                  value.split(
                                                                      '/'
                                                                  )[2]
                                                        }
                                                        onChange={(e) => {
                                                            let finalValue =
                                                                e.target.value;
                                                            if (
                                                                finalValue.length >
                                                                4
                                                            ) {
                                                                if (value) {
                                                                    onChangeInput(
                                                                        value.split(
                                                                            '/'
                                                                        )[0] +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[1] +
                                                                            '/' +
                                                                            finalValue.slice(
                                                                                1
                                                                            ),
                                                                        name,
                                                                        'date'
                                                                    );
                                                                } else {
                                                                    onChangeInput(
                                                                        '//' +
                                                                            finalValue.slice(
                                                                                1
                                                                            ),
                                                                        name,
                                                                        'date'
                                                                    );
                                                                }
                                                            } else {
                                                                if (value) {
                                                                    onChangeInput(
                                                                        value.split(
                                                                            '/'
                                                                        )[0] +
                                                                            '/' +
                                                                            value.split(
                                                                                '/'
                                                                            )[1] +
                                                                            '/' +
                                                                            finalValue,
                                                                        name,
                                                                        'date'
                                                                    );
                                                                } else {
                                                                    onChangeInput(
                                                                        '//' +
                                                                            finalValue,
                                                                        name,
                                                                        'date'
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="col px-0 d-flex justify-content-end align-items-center"
                                                    ref={datePickerParentRef}
                                                >
                                                    <img
                                                        src={icoCalendar}
                                                        alt=""
                                                        className={`input-icon ${styles['date-input-container__input-icon']}`}
                                                        onClick={
                                                            handleOpenDatePicker
                                                        }
                                                    />
                                                    {datePickerIsOpen &&
                                                        !isStudy && (
                                                            <DatePicker
                                                                parentRef={
                                                                    datePickerParentRef
                                                                }
                                                                initialDate={
                                                                    initialDate
                                                                }
                                                                finalDate={
                                                                    finalDate
                                                                        ? finalDate
                                                                        : isAge
                                                                        ? new Date()
                                                                        : undefined
                                                                }
                                                                reference={
                                                                    datePickerRef
                                                                }
                                                                name={name}
                                                                position={
                                                                    datePickerPosition
                                                                }
                                                                value={value}
                                                                indexList={
                                                                    indexList
                                                                }
                                                                onChangeInput={
                                                                    onChangeInput
                                                                }
                                                                datePickerHorizontalPosition={
                                                                    datePickerHorizontalPosition
                                                                }
                                                                onCloseTimePicker={() => {
                                                                    toggleDatePickerIsOpen(
                                                                        false
                                                                    );
                                                                    setIsFocused(
                                                                        false
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
