import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { getValueInString } from '../../../../../utils/unit'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const ProductMarketShareForecastTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.dataKey && props.payload && props.payload.length > 0 && props.id !== undefined && props.payload && props.payload[props.id]) {
        return (
            <>
                {!props.dataKey.toLowerCase().includes('forecast') && props.payload[0].payload['revenue'] && (
                    <>
                        <TooltipContainer color={props.color}>
                            <TooltipHeaderRow
                                title={t(`tooltips:header.market-share`)}
                                value={<span>{props.payload[0].payload[props.categoryAxis]}</span>}
                            />
                            <Separator />
                            {props.payload[0].payload['revenue'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.sales')}
                                    value={`${getValueInString(Math.round(props.payload[0].payload['revenue']), props.unitsOrValues)}`}
                                    color={'#2cd9c5'}
                                />
                            )}
                            {props.payload[0].payload['category-atc3'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.atc3-market-share')}
                                    value={
                                        props.payload[0].payload['revenue']
                                            ? `
                            ${getValueInString(
                                (Math.round(parseInt(props.payload[0].payload['revenue'])) /
                                    Math.round(parseInt(props.payload[0].payload['category-atc3']))) *
                                    100,
                                'percentage'
                            )}
                            (of ${getValueInString(Math.round(props.payload[0].payload['category-atc3']), props.unitsOrValues)})`
                                            : getValueInString(Math.round(props.payload[0].payload['category-atc3']), props.unitsOrValues)
                                    }
                                    color={'#FF9441'}
                                />
                            )}
                            {props.payload[0].payload['category-atc4'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.atc4-market-share')}
                                    value={
                                        props.payload[0].payload['revenue']
                                            ? `
                                ${getValueInString(
                                    (Math.round(props.payload[0].payload['revenue']) / Math.round(props.payload[0].payload['category-atc4'])) * 100,
                                    'percentage'
                                )}
                                (of ${getValueInString(Math.round(props.payload[0].payload['category-atc4']), props.unitsOrValues)})`
                                            : getValueInString(Math.round(props.payload[0].payload['category-atc4']), props.unitsOrValues)
                                    }
                                    color={'#8C54FF'}
                                />
                            )}
                        </TooltipContainer>
                    </>
                )}
                {props.dataKey.toLowerCase().includes('forecast') && props.payload[0].payload['revenueForecastValue'] && (
                    <>
                        <TooltipContainer color={props.color}>
                            <TooltipHeaderRow
                                title={t(`tooltips:header.market-share`)}
                                value={<span>{props.payload[0].payload[props.categoryAxis]}</span>}
                            />
                            <Separator />
                            <TooltipContentRow
                                title={t('tooltips:content.sales-forecast')}
                                value={`${getValueInString(Math.round(props.payload[0].payload['revenueForecastValue']), props.unitsOrValues)}`}
                                color={'#2cd9c5'}
                            />
                            {props.payload[0].payload['category-atc3ForecastValue'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.atc3-market-share-forecast')}
                                    value={`
                            ${getValueInString(
                                (Math.round(props.payload[0].payload['revenueForecastValue']) /
                                    Math.round(props.payload[0].payload['category-atc3ForecastValue'])) *
                                    100,
                                'percentage'
                            )}
                            (of ${getValueInString(Math.round(props.payload[0].payload['category-atc3ForecastValue']), props.unitsOrValues)})`}
                                    color={'#FF9441'}
                                />
                            )}
                            {props.payload[0].payload['category-atc4ForecastValue'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.atc4-market-share-forecast')}
                                    value={`
                            ${getValueInString(
                                (Math.round(props.payload[0].payload['revenueForecastValue']) /
                                    Math.round(props.payload[0].payload['category-atc4ForecastValue'])) *
                                    100,
                                'percentage'
                            )}
                            (of ${getValueInString(Math.round(props.payload[0].payload['category-atc4ForecastValue']), props.unitsOrValues)})`}
                                    color={'#8C54FF'}
                                />
                            )}
                        </TooltipContainer>
                    </>
                )}
                {props.dataKey.toLowerCase().includes('forecast') && !props.payload[0].payload['revenueForecastValue'] && (
                    <>
                        <TooltipContainer color={props.color}>
                            <TooltipHeaderRow
                                title={t(`tooltips:header.market-share`)}
                                value={<span>{props.payload[0].payload[props.categoryAxis]}</span>}
                            />
                            <Separator />
                            {props.payload[0].payload['category-atc3ForecastValue'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.atc3-market-share-forecast')}
                                    value={`${getValueInString(
                                        Math.round(props.payload[0].payload['category-atc3ForecastValue']),
                                        props.unitsOrValues
                                    )}`}
                                    color={'#FF9441'}
                                />
                            )}
                            {props.payload[0].payload['category-atc4ForecastValue'] && (
                                <TooltipContentRow
                                    title={t('tooltips:content.atc4-market-share-forecast')}
                                    value={`${getValueInString(
                                        Math.round(props.payload[0].payload['category-atc4ForecastValue']),
                                        props.unitsOrValues
                                    )}`}
                                    color={'#8C54FF'}
                                />
                            )}
                        </TooltipContainer>
                    </>
                )}
            </>
        )
    }
})
