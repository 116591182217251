import React, { useMemo, useState } from 'react';
import styles from './edit-sub-goals-modal.module.scss';
import { Modal, SelectInput, SuccessModal, TypeInput } from '../..';
import icoGoalManagement from '../../../assets/images/icoGoalManagement.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { useMultipleInputs } from '../../../hooks/useMultipleInputs';
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg';
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg';
import icoSave from '../../../assets/images/ico-save-blue.svg';
import icoSubGoalCup from '../../../assets/images/icoSubGoalCup.svg';
import { useArray } from '../../../hooks/useArray';
import icoEdit from '../../../assets/images/ico-edit.svg';
import icoSubGoalArrow from '../../../assets/images/icoSubGoalArrow.svg';

const goalInformationMock = {
    title: 'Build my network',
    owner: 'Wade Warren',
    timePeriod: 'FY24 (1 Jan, 2024 - 31 Dec, 2024) ',
    targetValue: '$ 50.000,00',
    businessDimensions: [
        {
            type: 'Product',
            values: ['Product 1', 'Product 2']
        }
    ]
};

export const EditSubGoalsModal = ({ onCloseModal, onEnd }) => {
    const { t } = useCustomTranslation();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isConfigurationSaved, setIsConfigurationSaved] = useState(false);
    const {
        array: elements,
        setArray: setElements,
        updateSingle: updateElement,
        updateAll: updateAllElements
    } = useArray([
        {
            id: 1,
            title: 'Sub Goal 1',
            type: 'Teams',
            target: 100,
            isSelected: false
        },
        {
            id: 2,
            title: 'Sub Goal 2',
            target: 100,
            isSelected: false,
            type: 'Teams'
        },
        {
            id: 3,
            title: 'Sub Goal 3',
            target: 100,
            isSelected: false,
            type: 'Teams'
        },
        {
            id: 4,
            title: 'Sub Goal 4',
            target: 100,
            isSelected: false,
            type: 'Teams'
        },
        {
            id: 5,
            title: 'Sub Goal 5',
            target: 100,
            isSelected: false,
            type: 'Teams'
        }
    ]);

    const {
        value: inputs,
        clickRadioOption: inputsClickRadioOption,
        setValue: inputsSetValue,
        toggleDropdown: inputsToggleDropdown,
        selectOption: inputsSelectOption,
        setOptions: inputsSetOptions,
        keyPress: inputsKeyPress,
        keyDown: inputsKeyDown
    } = useMultipleInputs({
        subGoalsBy: {
            type: 'selectSingle',
            name: 'subGoalsBy',
            options: ['Teams', 'Products'],
            selectedOption: '',
            value: '',
            reference: React.createRef()
        }
    });

    const allElementsSelected = useMemo(() => {
        return elements.every((element) => element.isSelected);
    }, [elements]);

    const isMoreThanOneSelected = useMemo(() => {
        return elements.filter((element) => element.isSelected).length > 1;
    }, [elements]);

    const handleSelectAll = () => {
        updateAllElements('isSelected', true);
    };

    const handleDeselectAll = () => {
        updateAllElements('isSelected', false);
    };

    if (!isSuccess) {
        return (
            <Modal onToggleModal={() => onCloseModal(false)}>
                <div
                    className="row mx-0 w-100"
                    style={{ marginBottom: '2rem' }}
                >
                    <div className="col px-0">
                        <div className={styles.editSubGoalsModalContainer}>
                            <div
                                className="row mx-0 w-100"
                                style={{ marginBottom: '2rem' }}
                            >
                                <div className="col px-0">
                                    <span
                                        className={
                                            styles.editSubGoalsModalContainer__title
                                        }
                                    >
                                        {t('modals:editSubGoals.title')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 w-100">
                                <div className="col px-0">
                                    <div
                                        className={
                                            styles.editSubGoalsModalContainer__mainGoalCard
                                        }
                                    >
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col px-0 d-flex justify-content-start align-items-center">
                                                <img
                                                    src={icoGoalManagement}
                                                    alt="Goal Management"
                                                    className={
                                                        styles.editSubGoalsModalContainer__mainGoalCard__icon
                                                    }
                                                    style={{
                                                        marginRight: '1rem'
                                                    }}
                                                />
                                                <span
                                                    className={
                                                        styles.editSubGoalsModalContainer__mainGoalCard__title
                                                    }
                                                >
                                                    {goalInformationMock.title}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="row mx-0 w-100"
                                            style={{ marginBottom: '2rem' }}
                                        >
                                            <div className="col-auto px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelTitle
                                                            }
                                                        >
                                                            {t(
                                                                'modals:editSubGoals.owner'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelValue
                                                            }
                                                        >
                                                            {
                                                                goalInformationMock.owner
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0"></div>
                                            <div className="col-auto px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelTitle
                                                            }
                                                        >
                                                            {t(
                                                                'modals:editSubGoals.timePeriod'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelValue
                                                            }
                                                        >
                                                            {
                                                                goalInformationMock.timePeriod
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0"></div>
                                            <div className="col-auto px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelTitle
                                                            }
                                                        >
                                                            {t(
                                                                'modals:editSubGoals.targetValue'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelValue
                                                            }
                                                        >
                                                            {
                                                                goalInformationMock.targetValue
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <div className="col px-0">
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        <span
                                                            className={
                                                                styles.editSubGoalsModalContainer__mainGoalCard__labelTitle
                                                            }
                                                        >
                                                            {t(
                                                                'modals:editSubGoals.businessDimensions'
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 w-100">
                                                    <div className="col px-0">
                                                        {goalInformationMock.businessDimensions.map(
                                                            (
                                                                businessDimension,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="row mx-0 w-100"
                                                                        style={{
                                                                            pointer:
                                                                                'cursor'
                                                                        }}
                                                                    >
                                                                        <div className="col px-0">
                                                                            <span
                                                                                className={
                                                                                    styles.editSubGoalsModalContainer__mainGoalCard__labelValue
                                                                                }
                                                                                style={{
                                                                                    marginRight:
                                                                                        businessDimension
                                                                                            .values
                                                                                            .length >
                                                                                        0
                                                                                            ? '1rem'
                                                                                            : '0'
                                                                                }}
                                                                            >
                                                                                {
                                                                                    businessDimension.type
                                                                                }
                                                                                {businessDimension
                                                                                    .values
                                                                                    .length >
                                                                                0
                                                                                    ? ':'
                                                                                    : ''}
                                                                            </span>
                                                                            {businessDimension
                                                                                .values
                                                                                .length >
                                                                                0 && (
                                                                                <>
                                                                                    {businessDimension.values.map(
                                                                                        (
                                                                                            value,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <span
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                    className={`text-label`}
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }
                                                                                                </span>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isConfigurationSaved && (
                                <>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                        style={{ height: '10rem' }}
                                    >
                                        <div className="col-auto px-0">
                                            <SelectInput
                                                onChangeInput={inputsSetValue}
                                                onSelectInput={
                                                    inputsSelectOption
                                                }
                                                onKeyPress={inputsKeyPress}
                                                onKeyDown={inputsKeyDown}
                                                onToggleDropdown={
                                                    inputsToggleDropdown
                                                }
                                                hideOptionsAux
                                                newLabelType
                                                {...inputs.subGoalsBy}
                                            />
                                        </div>
                                    </div>
                                    {inputs &&
                                        inputs.subGoalsBy.value === 'Teams' && (
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginTop: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <div
                                                        className={
                                                            styles.editSubGoalsModalContainer__subGoalsByElements
                                                        }
                                                    >
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div className="col-auto px-0">
                                                                <span
                                                                    className={
                                                                        styles.editSubGoalsModalContainer__subGoalsByElements__warningText
                                                                    }
                                                                >
                                                                    Remember
                                                                    that the
                                                                    target of
                                                                    sub-goals
                                                                    cannot
                                                                    exceed the
                                                                    value of the
                                                                    main goal.
                                                                </span>
                                                            </div>
                                                            <div className="col px-0"></div>
                                                            <div className="col-auto px-0 d-flex justify-content-end align-items-center">
                                                                <img
                                                                    src={
                                                                        icoSave
                                                                    }
                                                                    alt="Save"
                                                                    className={
                                                                        styles.editSubGoalsModalContainer__subGoalsByElements__saveIcon
                                                                    }
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() =>
                                                                        setIsConfigurationSaved(
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    '2rem'
                                                            }}
                                                        >
                                                            <div
                                                                className="col px-0 d-flex justify-content-start align-items-center"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={
                                                                    isMoreThanOneSelected
                                                                        ? handleDeselectAll
                                                                        : handleSelectAll
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        isMoreThanOneSelected
                                                                            ? icoCheckboxSelected
                                                                            : icoCheckboxEmpty
                                                                    }
                                                                    alt="Checkbox Selected"
                                                                    className={
                                                                        styles.editSubGoalsModalContainer__subGoalsByElements__checkbox
                                                                    }
                                                                    style={{
                                                                        marginRight:
                                                                            '1rem'
                                                                    }}
                                                                />
                                                                <span
                                                                    className={
                                                                        styles.editSubGoalsModalContainer__subGoalsByElements__selectionTitle
                                                                    }
                                                                >
                                                                    {isMoreThanOneSelected
                                                                        ? t(
                                                                              'modals:editSubGoals.desselectAll'
                                                                          )
                                                                        : t(
                                                                              'modals:editSubGoals.selectAll'
                                                                          )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                {elements.map(
                                                                    (
                                                                        element,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="row mx-0 w-100"
                                                                                style={{
                                                                                    marginBottom:
                                                                                        index ===
                                                                                        elements.length -
                                                                                            1
                                                                                            ? '0'
                                                                                            : '2rem'
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="col-auto px-0 d-flex justify-content-start align-items-center"
                                                                                    style={{
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        updateElement(
                                                                                            index,
                                                                                            'isSelected',
                                                                                            !element.isSelected
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            element.isSelected
                                                                                                ? icoCheckboxSelected
                                                                                                : icoCheckboxEmpty
                                                                                        }
                                                                                        alt="Checkbox Selected"
                                                                                        className={
                                                                                            styles.editSubGoalsModalContainer__subGoalsByElements__checkbox
                                                                                        }
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '1rem'
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            updateElement(
                                                                                                index,
                                                                                                'isSelected',
                                                                                                !element.isSelected
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <span
                                                                                        className={
                                                                                            styles.editSubGoalsModalContainer__subGoalsByElements__elementLabel
                                                                                        }
                                                                                    >
                                                                                        {element.type +
                                                                                            ' ' +
                                                                                            element.id}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col px-0"></div>
                                                                                <div
                                                                                    className="col-auto px-0"
                                                                                    style={{
                                                                                        width: '15rem'
                                                                                    }}
                                                                                >
                                                                                    <TypeInput
                                                                                        value={
                                                                                            element.target
                                                                                        }
                                                                                        onChangeInput={(
                                                                                            e
                                                                                        ) =>
                                                                                            updateElement(
                                                                                                index,
                                                                                                'target',
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                        label={t(
                                                                                            'inputs:target'
                                                                                        )}
                                                                                        type="number"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="col-auto px-0"
                                                                                    style={{
                                                                                        width: '2rem'
                                                                                    }}
                                                                                ></div>
                                                                                <div
                                                                                    className="col-auto px-0"
                                                                                    style={{
                                                                                        width: '15rem'
                                                                                    }}
                                                                                >
                                                                                    <TypeInput
                                                                                        value={
                                                                                            element.title
                                                                                        }
                                                                                        onChangeInput={(
                                                                                            e
                                                                                        ) =>
                                                                                            updateElement(
                                                                                                index,
                                                                                                'title',
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                        label={t(
                                                                                            'inputs:title'
                                                                                        )}
                                                                                        type="number"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </>
                            )}
                            {isConfigurationSaved && (
                                <div
                                    className="w-100 d-flex flex-column justify-content-start align-items-end position-relative"
                                    style={{ paddingLeft: '40px' }}
                                >
                                    <svg
                                        width="6rem"
                                        height={`calc(100px * ${
                                            elements.filter(
                                                (el) => el.isSelected
                                            ).length
                                        } + 8rem - 2rem)`}
                                        style={{
                                            position: 'absolute',
                                            left: '0',
                                            top: '0'
                                        }}
                                    >
                                        <line
                                            x1="15"
                                            y1="0"
                                            x2="15"
                                            y2={
                                                100 *
                                                    elements.filter(
                                                        (el) => el.isSelected
                                                    ).length -
                                                10 +
                                                'px'
                                            }
                                            stroke="#606060"
                                            strokeWidth="1"
                                        />
                                        {elements
                                            .filter((el) => el.isSelected)
                                            .map((el, index) => {
                                                const y1 =
                                                    100 * (index + 1) - 10; // Starting y-coordinate
                                                const y2 =
                                                    100 * (index + 1) + 10; // Ending y-coordinate

                                                // Convert rem to pixels assuming 1rem = 16px
                                                const x1 = 15; // Equivalent to 2rem
                                                const x2 = 30; // Equivalent to 4rem

                                                // Calculate control points for the curve
                                                const cx = (x1 + x2) / 2; // Midpoint x-coordinate for the control point
                                                const cy = y2; // Control point y, adjusted to curve upwards, in pixels

                                                const imageWidth = 14; // Image width in pixels
                                                const imageHeight = 14; // Image height in pixels

                                                return (
                                                    <svg
                                                        key={index}
                                                        width="100%"
                                                        height="100%"
                                                    >
                                                        <path
                                                            key={index}
                                                            d={`M ${x1} ${y1} Q ${cx} ${cy}, ${x2} ${y2}`} // Coordinates in pixels
                                                            stroke="#606060"
                                                            strokeWidth="1"
                                                            fill="none" // Ensures the path does not close into a shape
                                                        />
                                                        <image
                                                            href={
                                                                icoSubGoalArrow
                                                            } // Use href for modern browsers
                                                            x={x2 - 2} // Position the image slightly to the right of the path end
                                                            y={
                                                                y2 -
                                                                imageHeight / 2
                                                            } // Vertically center the image around the path end
                                                            width={imageWidth}
                                                            height={imageHeight}
                                                        />
                                                    </svg>
                                                );
                                            })}
                                    </svg>
                                    <div
                                        className="row mx-0 w-100 d-flex justify-content-start align-items-center"
                                        style={{
                                            height: '8rem'
                                        }}
                                    >
                                        <div className="col-auto px-0">
                                            <span
                                                className={
                                                    styles.editSubGoalsModalContainer__savedConfigurationText
                                                }
                                            >
                                                {t(
                                                    'modals:editSubGoals.savedConfiguration'
                                                )}
                                            </span>
                                        </div>
                                        <div className="col px-0"></div>
                                        <div
                                            className="col-auto px-0 d-flex justify-content-start align-items-center"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                setIsConfigurationSaved(false)
                                            }
                                        >
                                            <img
                                                src={icoEdit}
                                                alt="Edit"
                                                className={
                                                    styles.editSubGoalsModalContainer__editIcon
                                                }
                                                style={{
                                                    marginRight: '0.5rem'
                                                }}
                                            />
                                            <span
                                                className={
                                                    styles.editSubGoalsModalContainer__editText
                                                }
                                            >
                                                {t('modals:editSubGoals.edit')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <div className="col px-0">
                                            {elements
                                                .filter((el) => el.isSelected)
                                                .map((el, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="row mx-0 w-100"
                                                            style={{
                                                                marginBottom:
                                                                    index ===
                                                                    elements.filter(
                                                                        (el) =>
                                                                            el.isSelected
                                                                    ).length -
                                                                        1
                                                                        ? '0'
                                                                        : '20px'
                                                            }}
                                                        >
                                                            <div className="col px-0">
                                                                <div
                                                                    className={
                                                                        styles.editSubGoalsModalContainer__savedConfigurationCard
                                                                    }
                                                                >
                                                                    <div className="row mx-0 w-100 d-flex justify-content-center align-items-center">
                                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                                            <img
                                                                                src={
                                                                                    icoSubGoalCup
                                                                                }
                                                                                alt="Sub Goal"
                                                                                className={
                                                                                    styles.editSubGoalsModalContainer__savedConfigurationCard__icon
                                                                                }
                                                                                style={{
                                                                                    marginRight:
                                                                                        '1rem'
                                                                                }}
                                                                            />
                                                                            <span
                                                                                className={
                                                                                    styles.editSubGoalsModalContainer__savedConfigurationCard__title
                                                                                }
                                                                            >
                                                                                {
                                                                                    el.title
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="col px-0"></div>
                                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                                            <span
                                                                                className={
                                                                                    styles.editSubGoalsModalContainer__savedConfigurationCard__id
                                                                                }
                                                                            >
                                                                                {el.type +
                                                                                    ' ' +
                                                                                    el.id}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col px-0"></div>
                                                                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                                            <span
                                                                                className={
                                                                                    styles.editSubGoalsModalContainer__savedConfigurationCard__target
                                                                                }
                                                                            >
                                                                                {
                                                                                    el.target
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <SuccessModal
                text={t('modals:editSubGoals.success')}
                onCloseModal={() => onCloseModal(false)}
            />
        );
    }
};
