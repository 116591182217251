import { createContext, useContext } from 'react';

const DashboardLoadContext = createContext({
	dashboardLoad: undefined,
	setDashboardLoad: () => {},
});

export function DashboardLoadProvider({ value, children }) {
	return (
		<DashboardLoadContext.Provider value={value}>
			{children}
		</DashboardLoadContext.Provider>
	);
}

export function useDashboardLoad() {
	return useContext(DashboardLoadContext);
}
