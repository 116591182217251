import React from 'react'
import { Rectangle } from 'recharts'

export const CustomVerticalCursor = React.memo(({ x, y, width, height }) => {
    return (
        <Rectangle
            x={x - 70}
            y={y}
            width={width + 100}
            radius={10}
            height={height}
        />
    )
})
