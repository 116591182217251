import React from 'react';
import styles from './invoice-download-table-column.module.scss';
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation';

export const InvoiceDownloadTableColumn = React.memo(({ el }) => {
    const { t } = useCustomTranslation();
    const handleDownloadInvoice = () => {
        console.log('Download invoice');
    };

    return (
        <div className={styles['invoice-download-table-column-container']}>
            <span
                className={
                    styles['invoice-download-table-column-container__text']
                }
                onClick={handleDownloadInvoice}
            >
                {t('buttons:download')}
            </span>
        </div>
    );
});
