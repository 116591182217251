import React from 'react'
import { ReactComponent as IcoLeftArrow } from '../../../assets/images/icon-left-arrow.svg'
import styles from './left-double-arrow-button.module.scss'

export const LeftDoubleArrowButton = ({ disabled }) => {
    return (
        <div
            className={`${styles['left-double-arrow-container']}`}
            style={
                disabled
                    ? { cursor: 'none', pointerEvents: 'none', opacity: 0.2 }
                    : {}
            }
        >
            <IcoLeftArrow
                className={`${styles['left-double-arrow-container__icon']}`}
            />
            <IcoLeftArrow
                className={`${styles['left-double-arrow-container__icon']}`}
            />
        </div>
    )
}
