/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionCategoryDefaultSchema } from '../models/PermissionCategoryDefaultSchema';
import type { RoleSchema } from '../models/RoleSchema';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RolesService {
    /**
     * return all available roles. if not starkdata user only returns other roles
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeRoles(): CancelablePromise<Array<RoleSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/roles',
        });
    }
    /**
     * creates new role
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static postApiBackofficeRoles({
        requestBody,
    }: {
        requestBody: RoleSchema,
    }): CancelablePromise<RoleSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/backoffice/roles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * list all PermissionCategory types
     * @returns PermissionCategoryDefaultSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeRolesPermissionCategories({
        page,
        itemsPerPage,
        params,
        sortBy,
        sortDir,
    }: {
        /**
         * page number
         */
        page?: number,
        /**
         * number of items per page
         */
        itemsPerPage?: number,
        /**
         * key format: PARAM__OP. if Only Param is provided, OP is 'eq' by default. OP can be inversed by inserting '~' before OP, i.e. PARAM__~OP.
         *
         * Param list: id, name, description, created_at, updated_at, created_by, updated_by.
         *
         * You can append operators: in, eq, not, gte, lte, gt, lt, like, isnull
         */
        params?: Record<string, string>,
        /**
         * Sort the results by the given field.
         */
        sortBy?: 'id' | 'name' | 'description' | 'created_at' | 'updated_at' | 'created_by' | 'updated_by',
        /**
         * Sort the results by the given direction. asc | desc
         */
        sortDir?: 'asc' | 'desc',
    }): CancelablePromise<Array<PermissionCategoryDefaultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/roles/permission-categories',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'params': params,
                'sort_by': sortBy,
                'sort_dir': sortDir,
            },
        });
    }
    /**
     * get one Role by name
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeRoles1({
        name,
    }: {
        /**
         * name
         */
        name: string,
    }): CancelablePromise<RoleSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/roles/{name}',
            path: {
                'name': name,
            },
        });
    }
    /**
     * deletes role
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static deleteApiBackofficeRoles({
        roleName,
    }: {
        /**
         * role name.
         */
        roleName: string,
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/backoffice/roles/{role_name}',
            path: {
                'role_name': roleName,
            },
        });
    }
    /**
     * partially updates role
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static patchApiBackofficeRoles({
        roleName,
        requestBody,
    }: {
        /**
         * role name.
         */
        roleName: string,
        requestBody: RoleSchema,
    }): CancelablePromise<RoleSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/backoffice/roles/{role_name}',
            path: {
                'role_name': roleName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * return all roles for user
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static getApiBackofficeUsersRoles({
        userUuid,
    }: {
        /**
         * user id.
         */
        userUuid: string,
    }): CancelablePromise<Array<RoleSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/backoffice/users/{user_uuid}/roles',
            path: {
                'user_uuid': userUuid,
            },
        });
    }
    /**
     * updates roles for user
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static putApiBackofficeUsersRoles({
        userUuid,
        requestBody,
    }: {
        /**
         * user id.
         */
        userUuid: string,
        requestBody: Array<string>,
    }): CancelablePromise<RoleSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/backoffice/users/{user_uuid}/roles',
            path: {
                'user_uuid': userUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * gets roles for current user (token based)
     * @returns RoleSchema call successful
     * @throws ApiError
     */
    public static getApiUtilsMyRoles(): CancelablePromise<Array<RoleSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/utils/my-roles',
        });
    }
}
