import React from 'react'

export const selectedDynamicTablesListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [],
    columns: [{ path: 'action', label: '', sortable: false }],
    sortColumn: { path: '', order: 'asc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'dynamicTablesSelectedTable',
    disabled: true,
    loading: true,
    settings: {
        open: false,
        selected: {
            label: '',
            total: 0,
            options: [],
        },
        view: {
            options: [],
        },
        options: {
            label: '',
            options: [],
        },
        reference: React.createRef(),
    },
}
