import React from 'react';
import styles from './goal-reached-value-table-column.module.scss';
import { formatCurrency } from '../../../../../utils/math';

export const GoalReachedValueTableColumn = React.memo(({ el, type }) => {
    return (
        <div className={styles['goal-reached-value-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    <div className="row mx-0 w-100">
                        <div
                            className="col-auto px-0"
                            style={{ marginRight: '1rem' }}
                        >
                            <span
                                className={
                                    parseInt(el['goalTargetValue']) > el[type]
                                        ? styles[
                                              'goal-reached-value-table-column-container__text'
                                          ] +
                                          ' ' +
                                          styles['down']
                                        : parseInt(el['goalTargetValue']) <
                                          el[type]
                                        ? styles[
                                              'goal-reached-value-table-column-container__text'
                                          ] +
                                          ' ' +
                                          styles['up']
                                        : styles[
                                              'goal-reached-value-table-column-container__text'
                                          ]
                                }
                            >
                                {el[type]
                                    ? formatCurrency(el[type], 'EUR')
                                    : ''}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
