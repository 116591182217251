import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../utils/date';
import { getValueInString } from '../../../utils/unit';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTable } from '../../../hooks/useTable';
import { useToggle } from '../../../hooks/useToggle';
import { useFilter } from '../../../providers/filter-context';
import { DTablesService, PointOfSaleService } from '../../../temp/test';
import { clientListMock } from './mock';
import { errorHandler } from '../../../utils/api.js';
import { verifyComponent } from '../../../utils/component.js';
import { useChartReady } from '../../../providers/chart-ready-context';
import { ContentContainer, Spinner, Table } from '../../';
import { getFilterParam } from '../../../utils/params';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * List all clients in a table
 */

export const ClientList = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type,
        isReportBuilder,
        ...rest
    }) => {
        const { setChartReady } = useChartReady();
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { t } = useCustomTranslation();
        const [modal] = useToggle(false);
        const {
            value: data,
            toggleComponentSettings,
            pageChange: dataPageChange,
            sort: dataSort,
            selectAll: dataSelectAll,
            unselectAll: dataUnselectAll,
            setLoading,
            setValue
        } = useTable(clientListMock, modal);

        useEffect(() => {
            async function fill() {
                const dataClone = _.cloneDeep(clientListMock);
                try {
                    if (!isExample) {
                        if (isMounted.current) setLoading(true);
                        if (type) dataClone.attr = type;
                        dataClone.data = [];
                        dataClone.sortedData = [];
                        dataClone.columns = [
                            {
                                path: 'clientId',
                                label: 'IDENTIFIER',
                                sortable: true,
                                translated: false
                            },
                            {
                                path: 'clientName',
                                label: 'NAME',
                                sortable: true,
                                translated: false
                            },
                            {
                                path: 'avgOrders',
                                label: 'AVERAGE ORDERS',
                                sortable: true,
                                translated: false
                            },
                            {
                                path: 'lastOrder',
                                label: 'LAST ORDER',
                                sortable: true,
                                translated: false
                            },
                            {
                                path: 'revenue',
                                label: 'REVENUE',
                                sortable: true,
                                translated: false
                            },
                            {
                                path: 'risk',
                                label: 'RISK',
                                sortable: true,
                                translated: false
                            },
                            {
                                path: 'totalOrders',
                                label: 'TOTAL ORDERS',
                                sortable: true,
                                translated: false
                            }
                        ];
                        const dataBack =
                            await PointOfSaleService.getApiPosClientOverviewTableVendors(
                                {
                                    vendorId: getFilterParam(filter, config, [
                                        'vendorId',
                                        'vendorIds'
                                    ])
                                }
                            );
                        _.forEach(Object.entries(dataBack), (el) => {
                            dataClone.data.push({
                                clientId: parseInt(
                                    el[0].toString().split('.')[0]
                                ),
                                clientName: el[1].name ? el[1].name : '-',
                                lastOrder: el[1].last_order
                                    ? formatDate(el[1].last_order)
                                    : '-',
                                revenue: getValueInString(
                                    el[1].total_revenue,
                                    'values'
                                ),
                                risk: el[1].risk ? el[1].risk : '-',
                                avgOrders: el[1].average_order
                                    ? el[1].average_order.toFixed(2)
                                    : '-',
                                totalOrders:
                                    el[1].total_orders !== null
                                        ? el[1].total_orders
                                        : '-'
                            });
                        });
                        dataClone.sortedData = _.sortBy(
                            dataClone.data,
                            dataClone.sortColumn.path,
                            dataClone.sortColumn.order
                        );
                        dataClone.titleCounter = dataClone.sortedData.length;
                        dataClone.loading = false;
                        dataClone.disabled = true;

                        if (
                            dataClone.settings.view.options.length === 0 &&
                            dataClone.settings.options.options.length === 0
                        )
                            dataClone.disabled = true;
                        else dataClone.disabled = false;
                        verifyComponent(dataClone);
                        if (isMounted.current) {
                            setValue(dataClone);
                            setLoading(false);
                        }
                        let filterIds = [];
                        let additionalData = [];
                        if (
                            config.additionalInfo &&
                            config.additionalInfo.length !== 0
                        ) {
                            _.forEach(Object.entries(dataBack), (el) => {
                                filterIds.push(el[0]);
                                return el;
                            });
                            config.additionalInfo.map((el) => {
                                dataClone.columns.push({
                                    path: el,
                                    label: el,
                                    sortable: true,
                                    translated: false
                                });
                                return el;
                            });
                            additionalData =
                                await DTablesService.getApiBackofficeAdminDtablesColumns(
                                    {
                                        columns: config.additionalInfo,
                                        ids: filterIds
                                    }
                                );
                        }
                        _.forEach(Object.entries(dataBack), (el) => {
                            if (additionalData.length !== 0) {
                                const index = _.findIndex(
                                    dataClone.data,
                                    (el3) => {
                                        return (
                                            el3.clientId ===
                                            parseInt(
                                                el[0].toString().split('.')[0]
                                            )
                                        );
                                    }
                                );
                                if (index !== -1) {
                                    config.additionalInfo.map((el2) => {
                                        dataClone.data[index][el2] =
                                            additionalData[index][el2];
                                        return el2;
                                    });
                                }
                            }
                        });
                        dataClone.sortedData = _.sortBy(
                            dataClone.data,
                            dataClone.sortColumn.path,
                            dataClone.sortColumn.order
                        );
                        if (isMounted.current) setValue(dataClone);
                    } else {
                        if (type) dataClone.attr = type;
                        verifyComponent(dataClone);
                        setValue(dataClone);
                        setLoading(false);
                    }
                    if (setChartReady) setChartReady(dataClone.attr);
                } catch (err) {
                    errorHandler(err);
                    dataClone.loading = false;
                    if (isMounted.current) {
                        setValue(dataClone);
                    }
                }
            }
            fill();
        }, [
            config,
            filter,
            isExample,
            isMounted,
            setValue,
            setLoading,
            t,
            type,
            setChartReady
        ]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    {...rest}
                    attr={data.attr}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <Table
                            isReportBuilder={isReportBuilder}
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            placeholderText={t(
                                `components:${data.attr}.nodata`
                            )}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={undefined}
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                        ></Table>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

ClientList.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
