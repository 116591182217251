import { createContext, useContext } from 'react'

const PageDetailsContext = createContext({
    isHome: undefined,
})

export function PageDetailsProvider({ value, children }) {
    return (
        <PageDetailsContext.Provider value={value}>
            {children}
        </PageDetailsContext.Provider>
    )
}

export function usePageDetails() {
    return useContext(PageDetailsContext)
}
