import _ from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';

import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useToggle } from '../../../hooks/useToggle';
import { SentimentService } from '../../../temp/test';
import { sentimentWordFrequencyMock } from './mock';
import { SecondaryButton } from '../../buttons/secondary-button/secondary-button';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { ContentContainer } from '../../dashboard-component-configuration/content-container/content-container';
import { CheckboxInput } from '../../inputs/checkbox-input/checkbox-input';
import { TooltipFactory } from '../../chart-configuration/tooltip-factory/tooltip-factory';
import { WordCLoud } from '../../charts/word-cloud/word-cloud';
import { Spinner } from '../../loading/spinner/spinner';
import { Modal } from '../../modals/modal/modal';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { Loader } from '../../loading/Loader/Loader';

/**
 * Displays logins in a table
 */

export const SentimentWordFrequency = React.memo(({ analysisId }) => {
    const [modal, toggleModal] = useToggle(false);
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const [hoveredWord] = useState({});
    const {
        value: data,
        setLoading,
        setValue,
        toggleComponentSettings,
        clickCheckboxOption
    } = useSimpleComponent(sentimentWordFrequencyMock);

    const getWords = useCallback(async () => {
        const dataClone = _.cloneDeep(sentimentWordFrequencyMock);
        try {
            dataClone.words.length = 0;
            if (isMounted.current) setLoading(true);
            const results = await SentimentService.getApiNlpAnalysisWordcloud({
                id: analysisId,
                includeNeutral: undefined
            });
            Object.entries(results.keyword_distribution).map((el, index) => {
                Object.entries(el[1]).map((el2) => {
                    if (el2[1]) {
                        dataClone.words.push({
                            text: el[0],
                            value: el2[1],
                            sentiment: el2[0]
                        });
                    }
                    return el2;
                });
                return el;
            });
            // for every word, rank it according to its value
            dataClone.words.map((el, index) => {
                const ranking = dataClone.words.filter(
                    (el2) => el2.value > el.value
                ).length;
                el.ranking = ranking + 1;
                el.totalCount = dataClone.words.length;
                return el;
            });
            dataClone.originalWords = _.cloneDeep(dataClone.words);
            if (isMounted.current) setValue(dataClone);
            setLoading(false);
        } catch (err) {
            errorHandler(err);
            dataClone.loading = false;
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
        verifyComponent(dataClone, t);
    }, [isMounted, setLoading, setValue, t, analysisId]);

    useEffect(() => {
        getWords();
    }, [getWords]);

    const handleSelectSettingsOption = useCallback(
        (attr, id, option) => {
            if (option === 'filter') {
                if (isMounted.current) {
                    toggleModal(true);
                }
            }
            if (isMounted.current) toggleComponentSettings();
        },
        [isMounted, toggleComponentSettings, toggleModal]
    );

    const handleCloseModal = () => {
        if (isMounted.current) toggleModal(false);
    };

    const handleSaveModal = () => {
        const dataClone = _.cloneDeep(data);
        const filteredSelectedOptions =
            dataClone.settings.viewOptions.checkboxOptions.selectedOptions;
        dataClone.words = dataClone.originalWords.filter((el) => {
            return filteredSelectedOptions.includes(el.sentiment);
        });
        if (isMounted.current) {
            setValue(dataClone);
            toggleModal(false);
        }
    };

    return (
        <>
            <ContentContainer
                attr={data.attr}
                hidePinIcon
                titleText={t(`components:${data.attr}.title`)}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
                onClickSettingsView={handleSelectSettingsOption}
                onCLickSettintsSelected={handleSelectSettingsOption}
                onCloseSettings={toggleComponentSettings}
            >
                {modal && (
                    <Modal onToggleModal={handleCloseModal}>
                        <div
                            className="row mx-0 "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <span className="modal-title">
                                    {t(
                                        'pages:customDashboard.filterCheckboxes'
                                    )}
                                </span>
                            </div>
                        </div>

                        <div
                            className="row mx-0 "
                            style={{ marginBottom: '2rem' }}
                        >
                            <div className="col px-0">
                                <CheckboxInput
                                    options={
                                        data.settings.viewOptions
                                            .checkboxOptions.options
                                    }
                                    selectedOptions={
                                        data.settings.viewOptions
                                            .checkboxOptions.selectedOptions
                                    }
                                    onClickCheckboxOption={clickCheckboxOption}
                                />
                            </div>
                        </div>
                        <hr
                            className="my-4"
                            style={{ color: 'rgba(0,0,0,0.1)', opacity: '1' }}
                        />
                        <div className="row mx-0 d-flex justify-content-between align-items-center">
                            <div className="col-5 px-0">
                                <SecondaryButton
                                    text={t('settings:cancel')}
                                    onClick={handleCloseModal}
                                />
                            </div>
                            <div className="col-5 px-0">
                                <PrimaryButton
                                    text={t('settings:save')}
                                    onClick={handleSaveModal}
                                />
                            </div>
                        </div>
                    </Modal>
                )}
                {data.loading && <Spinner />}
                {!data.loading && <WordCLoud data={data.words} />}
                {!_.isEmpty(hoveredWord) && (
                    <TooltipFactory
                        item={{
                            type: 'word',
                            top: hoveredWord.top,
                            left: hoveredWord.left,
                            word: hoveredWord.word,
                            value: hoveredWord.value,
                            sentiment: hoveredWord.sentiment
                        }}
                    />
                )}
            </ContentContainer>
        </>
    );
});

SentimentWordFrequency.propTypes = {};
