import React from 'react';
import _ from 'lodash';
import styles from './ToggleUserInput.module.scss';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import icoCheckboxEmpty from '../../../assets/images/ico-checkbox-empty.svg';
import icoCheckboxSelected from '../../../assets/images/ico-checkbox-selected.svg';

export const ToggleUserInput = ({
    title,
    optionalTitle,
    subTitle,
    usersToToggle,
    onToggleUser
}) => {
    const { t } = useCustomTranslation();
    const [searchValue, setSearchValue] = React.useState('');
    return (
        <div className={styles['toggle-user-input-container']}>
            <div
                className="row mx-0 w-100 d-flex justify-content-center align-items-center"
                style={{ marginBottom: '1rem' }}
            >
                <div className="col-auto px-0">
                    <span
                        className={styles['toggle-user-input-container__title']}
                    >
                        {title}
                    </span>
                    {optionalTitle && (
                        <span
                            className={
                                styles[
                                    'toggle-user-input-container__optional-title'
                                ]
                            }
                        >
                            {' '}
                            ({optionalTitle})
                        </span>
                    )}
                </div>
            </div>
            <div className="row mx-0 w-100 h-100 d-flex justify-content-center align-items-center">
                <div
                    className="col-auto px-0 h-100"
                    style={{
                        width: '50rem'
                    }}
                >
                    <div
                        className={
                            styles['toggle-user-input-container__right-list']
                        }
                    >
                        <div className="row mx-0 w-100">
                            <div className="col px-0">
                                <div
                                    className={
                                        styles[
                                            'toggle-user-input-container__right-list__title-container'
                                        ]
                                    }
                                >
                                    <span
                                        className={
                                            styles[
                                                'toggle-user-input-container__right-list__title-container__text-bold'
                                            ]
                                        }
                                    >
                                        {usersToToggle.length}
                                    </span>{' '}
                                    <span
                                        className={
                                            styles[
                                                'toggle-user-input-container__right-list__title-container__text'
                                            ]
                                        }
                                    >
                                        {subTitle}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div className="col px-0 h-100">
                                <div
                                    className={
                                        styles[
                                            'toggle-user-input-container__right-list__content-container'
                                        ]
                                    }
                                >
                                    {usersToToggle.map((user, index) => {
                                        const { isTeamLeader } = user;
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    styles[
                                                        'toggle-user-input-container__right-list__user'
                                                    ]
                                                }
                                            >
                                                <div className="row mx-0 w-100">
                                                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                                                        <img
                                                            src={user.avatar}
                                                            alt={user.name}
                                                            className={
                                                                styles[
                                                                    'toggle-user-input-container__right-list__user__avatar'
                                                                ]
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col px-0">
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'toggle-user-input-container__right-list__user__name'
                                                                        ]
                                                                    }
                                                                >
                                                                    {user.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mx-0 w-100">
                                                            <div className="col px-0">
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'toggle-user-input-container__right-list__user__email'
                                                                        ]
                                                                    }
                                                                >
                                                                    {user.email}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {isTeamLeader && (
                                                            <div className="row mx-0 w-100">
                                                                <div className="col px-0">
                                                                    <span
                                                                        className={
                                                                            styles[
                                                                                'toggle-user-input-container__left-list__user__team-leader'
                                                                            ]
                                                                        }
                                                                    >
                                                                        {t(
                                                                            `common:teamLeader`
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col px-0 d-flex justify-content-end align-items-center">
                                                        <img
                                                            src={
                                                                user.added
                                                                    ? icoCheckboxSelected
                                                                    : icoCheckboxEmpty
                                                            }
                                                            alt="Toggle user"
                                                            className={
                                                                styles[
                                                                    'toggle-user-input-container__right-list__user__toggle'
                                                                ]
                                                            }
                                                            onClick={() =>
                                                                onToggleUser(
                                                                    user
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
