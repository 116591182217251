let env = process.env;
const config = {
    deviceType: 'web',
    service: {
        BASE_URL: env.REACT_APP_API_URL
    },
    platformFormat: env.REACT_APP_PLATFORM_FORMAT || 'enterprise'
};

export default config;
