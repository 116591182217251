import Joi from 'joi'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import icoDelete from '../../../assets/images/ico-delete.svg'
import { errorHandler } from '../../../utils/api'
import { verifyComponent } from '../../../utils/component.js'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useTable } from '../../../hooks/useTable'
import { useTextInput } from '../../../hooks/useTextInput'
import { useToggle } from '../../../hooks/useToggle'
import { RolesService } from '../../../temp/test'
import { roleListMock } from './mock'
import { ContentContainer, DeleteButton, Loader, PrimaryButton, ReturnButton, SecondaryButton, Spinner, TypeInput } from '../../'
import { Modal } from '../../modals/modal/modal'
import { Table } from '../../tables/table/table'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { success } from '../../notifications/notification/notification'

export const RoleList = React.memo(() => {
    const isMounted = useIsMounted()
    const { t } = useCustomTranslation()
    const [modal, toggleModal] = useToggle(false)
    const [modalType, setModalType] = useState('')
    const [selectedRoleName, setSelectedRoleName] = useState('')
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setLoading,
        setValue,
        clickActionIcon,
    } = useTable(roleListMock, modal, '')
    const {
        value: name,
        reset: resetName,
        setValue: setNameValue,
        setError: setNameError,
    } = useTextInput({
        name: 'name',
        value: '',
        error: '',
        hidden: false,
        state: 'normal',
    })

    const schema = Joi.object({
        name: Joi.string().required(),
    })

    const getRoles = useCallback(async () => {
        const dataClone = _.cloneDeep(roleListMock)
        try {
            dataClone.data.length = 0
            dataClone.sortedData.length = 0
            setValue(dataClone)
            if (isMounted.current) setLoading(true)
            const roleBackData = await RolesService.getApiBackofficeRoles()
            roleBackData.map((el, index) => {
                const newEl = {
                    id: index,
                    name: el.name ? el.name : '',
                    action: {
                        label: t(`columns:action`),
                        id: index,
                        open: false,
                        options: [
                            {
                                name: 'removeRole',
                                label: t('settings:removeRole'),
                                icon: icoDelete,
                            },
                        ],
                        reference: React.createRef(),
                    },
                }
                dataClone.data.push(newEl)
                return el
            })
            dataClone.sortedData = _.orderBy(dataClone.data, [dataClone.sortColumn.path], [dataClone.sortColumn.order])
            verifyComponent(dataClone)
            if (isMounted.current) {
                setValue(dataClone)
                setLoading(false)
            }
        } catch (err) {
            errorHandler(err)
            dataClone.loading = false
            if (isMounted.current) {
                setValue(dataClone)
            }
        }
    }, [isMounted, setLoading, setValue, t])

    useEffect(() => {
        getRoles()
    }, [getRoles])

    const handleCloseModal = () => {
        if (isMounted.current) {
            toggleModal(false)
            setModalType('')
            setSelectedRoleName('')
        }
    }

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (id) {
                data.data.map((el) => {
                    if (el.id === id) {
                        if (isMounted.current) setSelectedRoleName(el.name)
                    }
                    return el
                })
            }
            if (isMounted.current) {
                toggleComponentSettings(false)
                toggleModal(true)
                resetName()
                setModalType(option)
            }
        },
        [isMounted, resetName, toggleComponentSettings, toggleModal, data]
    )

    const handleCreateRole = async () => {
        try {
            const result = schema.validate(
                {
                    name: name.value,
                },
                { abortEarly: false }
            )
            if (isMounted.current) setNameError()
            if (result.error) {
                result.error.details.map((el) => {
                    if (el.path[0] === 'name') {
                        if (isMounted.current) setNameError(el.type)
                    }
                    return null
                })
                return
            }
            await RolesService.postApiBackofficeRoles({
                requestBody: { name: name.value },
            })
            if (isMounted.current) {
                toggleModal(false)
                setLoading(false)
            }
            getRoles()
            success({ text: t('notifications:roleCreated') })
        } catch (err) {
            errorHandler(err)
        }
    }

    const handleDeleteRole = async () => {
        try {
            await RolesService.deleteApiBackofficeRoles({
                roleName: selectedRoleName,
            })
            if (isMounted.current) {
                toggleModal(false)
                setLoading(false)
            }
            getRoles()
            success({ text: t('notifications:roleDeleted') })
        } catch (err) {
            errorHandler(err)
        }
    }

    return (
        <>
            <ContentContainer
                hidePinIcon
                attr={data.attr}
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
                onClickSettingsOptions={handleSelectSettingsOption}
            >
                {data.loading && <Spinner />}
                {!data.loading && (
                    <React.Fragment>
                        {modal && modalType === 'addRole' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('modals:createrole')}</span>
                                    </div>
                                </div>

                                <div className="row mx-0 " style={{ marginBottom: '2rem' }}>
                                    <div className="col px-0">
                                        <TypeInput onChangeInput={setNameValue} {...name} />
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton text={t('modals:save')} onClick={handleCreateRole} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        {modal && modalType === 'removeRole' && (
                            <Modal onToggleModal={handleCloseModal}>
                                <div className="row mx-0 mb-4">
                                    <div className="col px-0">
                                        <span className="modal-title">{t('pages:managerProfile.areyousure')}</span>
                                    </div>
                                </div>
                                <div className="row mx-0 d-flex justify-content-between align-items-center">
                                    <div className="col-5 px-0">
                                        <SecondaryButton text={t('modals:cancel')} onClick={handleCloseModal} />
                                    </div>
                                    <div className="col-5 px-0">
                                        <PrimaryButton text={t('modals:remove')} onClick={handleDeleteRole} />
                                    </div>
                                </div>
                            </Modal>
                        )}
                        <Table
                            actions={data.actions}
                            // onClickActionIcon={onClickActionIcon}
                            // onClickActionOption={onClickActionOption}
                            // onCloseActions={onCloseActions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.titleCounter}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(`components:${data.attr}.nodata`)}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={undefined}
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            disabled={data.disabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                            onNewElementClick={() => {
                                handleSelectSettingsOption(data.attr, undefined, 'addRole')
                            }}
                            onNewElementText={t('buttons:add')}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </>
    )
})

RoleList.propTypes = {}
