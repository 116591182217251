import React, { useEffect, useMemo, useState } from 'react'
import { LoaderForeground, NavigationMenu } from '../../../components'
import { useToggle } from '../../../hooks/useToggle'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { GoalOverview } from './components/GoalOverview/GoalOverview'
import { Goals } from './components/Goals/Goals'
import { GoalApprovals } from './components/GoalApprovals/GoalApprovals'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GoalHistory } from './components/GoalHistory/GoalHistory'

export const GoalManagement = () => {
    const { t } = useCustomTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const [selectedOption, setSelectedOption] = useState(
        location.pathname.includes('/goal-management/overview')
            ? t(`components:navigation-menu.goalOverview.title`)
            : location.pathname.includes('/goal-management/goal-list')
            ? t(`components:navigation-menu.goals.title`)
            : location.pathname.includes('/goal-management/goal-history')
            ? t(`components:navigation-menu.goalHistory.title`)
            : location.pathname.includes('/goal-management/goal-approvals')
            ? t(`components:navigation-menu.goalApprovals.title`)
            : params.id || null
    )
    const options = useMemo(() => {
        return [
            {
                label: t(`components:navigation-menu.goalOverview.title`),
                url: '/goal-management/overview',
            },
            {
                label: t(`components:navigation-menu.goals.title`),
                url: '/goal-management/goal-list',
            },
            {
                label: t(`components:navigation-menu.goalHistory.title`),
                url: '/goal-management/goal-history',
            },
            {
                label: t(`components:navigation-menu.goalApprovals.title`),
                url: '/goal-management/goal-approvals',
            },
        ]
    }, [t])

    const menuOptions = useMemo(() => {
        return [...options.map((el) => el.label)]
    }, [options])
    const [loading, toggleLoading] = useToggle(true)
    useEffect(() => {
        async function fillData() {
            toggleLoading(false)
        }
        fillData()
    }, [toggleLoading])

    const handleSelectGoal = (goal) => {
        customSelectOption(`/goal-management/${goal.title || goal.name}/details`, goal.title || goal.name)
    }

    const customSelectOption = (url, label) => {
        navigate(url)
        setSelectedOption(label)
    }

    return (
        <>
            <div className="w-100 h-100" style={{ padding: '0 2rem 2rem 2rem' }}>
                <div className={`row mx-0 w-100 h-100`}>
                    <div className="col-12 px-0 h-100 d-flex flex-column">
                        <div className="row w-100 dashboard-header-row ">
                            <div className="col px-0 d-flex justify-content-between align-items-center">
                                <span className="me-4">{t(`pages:goalManagement.title`)}</span>
                            </div>
                        </div>
                        <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                            <div className="col px-0">
                                <NavigationMenu
                                    options={menuOptions}
                                    isPageMenu
                                    selectedOption={selectedOption}
                                    onChangeOption={(option) => customSelectOption(options.find((el) => el.label === option).url, option)}
                                    translated
                                />
                            </div>
                        </div>
                        {!loading && (
                            <div className="row mx-0 w-100 flex-grow-1" style={{ minHeight: 0 }}>
                                <div className="col px-0">
                                    {selectedOption === t(`components:navigation-menu.goalOverview.title`) && (
                                        <GoalOverview onSelectGoal={handleSelectGoal} />
                                    )}
                                    {selectedOption === t(`components:navigation-menu.goals.title`) && <Goals onSelectGoal={handleSelectGoal} />}
                                    {selectedOption === t(`components:navigation-menu.goalHistory.title`) && (
                                        <GoalHistory onSelectGoal={handleSelectGoal} />
                                    )}
                                    {selectedOption === t(`components:navigation-menu.goalApprovals.title`) && <GoalApprovals />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
