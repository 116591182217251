import { createContext, useContext } from 'react'

const DeleteContext = createContext({
    handleDelete: undefined,
})

export function DeleteProvider({ value, children }) {
    return <DeleteContext.Provider value={value}>{children}</DeleteContext.Provider>
}

export function useDelete() {
    return useContext(DeleteContext)
}
