import { createContext, useContext } from 'react';

const CurrentUserContext = createContext({
    currentUser: undefined,
    setCurrentUser: () => {}
});

export function CurrentUserProvider({ value, children }) {
    return (
        <CurrentUserContext.Provider value={value}>
            {children}
        </CurrentUserContext.Provider>
    );
}

export function useCurrentUser() {
    return useContext(CurrentUserContext);
}
