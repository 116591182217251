import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ACTIONS = {
    SET_FILTERS: 'setFilters',
    SET_COMPETITOR_ID: 'setCompetitorId',
    RESET: 'reset'
};

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_FILTERS:
            return { ...action.payload };

        case ACTIONS.SET_TEAM_ID:
            return {
                ...state,
                teamId: action.payload
            };
        case ACTIONS.SET_CLIENT_ID:
            return {
                ...state,
                clientId: action.payload
            };
        case ACTIONS.SET_CLIENT_IDS:
            return {
                ...state,
                clientIds: action.payload
            };
        case ACTIONS.SET_COMPETITOR_ID:
            return {
                ...state,
                competitorId: action.payload
            };
        case ACTIONS.SET_VENDOR_ID:
            return {
                ...state,
                vendorId: action.payload
            };
        case ACTIONS.SET_VENDOR_IDS:
            return {
                ...state,
                vendorIds: action.payload
            };
        case ACTIONS.SET_PRODUCT_ID:
            return {
                ...state,
                productId: action.payload
            };
        case ACTIONS.SET_PRODUCT_IDS:
            return {
                ...state,
                productIds: action.payload
            };
        case ACTIONS.RESET:
            return { ...action.payload };
        case ACTIONS.SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            };
    }
}

export function useFilterHook(defaultValue) {
    const navigate = useNavigate();
    const location = useLocation();
    const [value, dispatch] = useReducer(reducer, defaultValue);
    const initial = useRef(true);

    useEffect(() => {
        initial.current = false;
        const params = new URLSearchParams(window.location.search);
        const teamId = params.get('team-id');
        const teamIds = params.getAll('team-ids');
        const clientId = params.get('client-id');
        const clientIds = params.getAll('client-ids');
        const vendorId = params.get('vendor-id');
        const vendorIds = params.getAll('vendor-ids');
        const hospitalId = params.get('hospital-id');
        const hospitalIds = params.getAll('hospital-ids');
        const productId = params.get('product-id');
        const productIds = params.getAll('product-ids');
        const competitorId = params.get('competitor-id');
        const regionId = params.get('region-id');
        const regionIds = params.getAll('region-ids');
        const userId = params.get('user-id');
        const filters = {
            teamId: teamId || '',
            teamIds: teamIds || [],
            clientId: clientId || '',
            clientIds: clientIds || [],
            vendorId: vendorId || '',
            vendorIds: vendorIds || [],
            hospitalId: hospitalId || '',
            hospitalIds: hospitalIds || [],
            productId: productId || '',
            productIds: productIds || [],
            competitorId: competitorId || '',
            userId: userId || '',
            regionId: regionId || '',
            regionIds: regionIds || []
        };
        dispatch({ type: 'setFilters', payload: filters });
    }, [location.search]);

    const setCompetitorId = useCallback(
        (value) => {
            const params = new URLSearchParams(window.location.search);
            if (value !== '') {
                params.set('competitor-id', value);
            }
            navigate({
                pathname: window.location.pathname,
                search: params.toString(),
                replace: true
            });
            dispatch({ type: 'setCompetitorId', payload: value });
        },
        [navigate]
    );

    const setValue = useCallback(
        (filters) => {
            const params = new URLSearchParams(window.location.search);
            params.delete('team-id');
            params.delete('team-ids');
            params.delete('client-id');
            params.delete('client-ids');
            params.delete('vendor-id');
            params.delete('vendor-ids');
            params.delete('hospital-id');
            params.delete('hospital-ids');
            params.delete('product-id');
            params.delete('product-ids');
            params.delete('competitor-id');
            params.delete('user-id');
            params.delete('region-id');
            params.delete('region-ids');
            if (filters.teamId !== '') {
                params.set('team-id', filters.teamId);
            }
            if (filters.teamIds.length > 0) {
                filters.teamIds.map((el) => {
                    params.append('team-ids', el);
                });
            }
            if (filters.clientId !== '') {
                params.set('client-id', filters.clientId);
            }
            if (filters.clientIds.length > 0) {
                filters.clientIds.map((el) => {
                    params.append('client-ids', el);
                });
            }
            if (filters.vendorId !== '') {
                params.set('vendor-id', filters.vendorId);
            }
            if (filters.vendorIds.length > 0) {
                filters.vendorIds.map((el) => {
                    params.append('vendor-ids', el);
                });
            }
            if (filters.hospitalId !== '') {
                params.set('hospital-id', filters.hospitalId);
            }
            if (filters.hospitalIds.length > 0) {
                filters.hospitalIds.map((el) => {
                    params.append('hospital-ids', el);
                });
            }
            if (filters.productId !== '') {
                params.set('product-id', filters.productId);
            }
            if (filters.productIds.length > 0) {
                filters.productIds.map((el) => {
                    params.append('product-ids', el);
                });
            }
            if (filters.competitorId !== '') {
                params.set('competitor-id', filters.competitorId);
            }
            if (filters.userId !== '') {
                params.set('user-id', filters.userId);
            }
            if (filters.regionId !== '') {
                params.set('region-id', filters.regionId);
            }
            if (filters.regionIds.length > 0) {
                filters.regionIds.map((el) => {
                    params.append('region-ids', el);
                });
            }
            if (!initial.current) {
                navigate({
                    pathname: window.location.pathname,
                    search: params.toString(),
                    replace: true
                });
            }
        },
        [navigate]
    );

    return {
        value,
        setValue,
        setCompetitorId
    };
}
