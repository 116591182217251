import React from 'react';
import styles from './PaymentMethod.module.scss';
import imageVisa from '../../../../../assets/images/imageVisa.png';

export const PaymentMethod = () => {
    return (
        <div className={styles['payment-method-container']}>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span className={styles['payment-method-container__title']}>
                        Payment Method
                    </span>
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles['payment-method-container__sub-title']
                        }
                    >
                        Card information
                    </span>
                    <span
                        className={
                            styles['payment-method-container__content-text']
                        }
                    >
                        Visa ** **** 6598
                    </span>
                </div>
                <div className="col-auto px-0" style={{ width: '2rem' }}></div>
                <div className="col px-0">
                    <img
                        src={imageVisa}
                        alt="Visa"
                        className={styles['payment-method-container__image']}
                    />
                </div>
            </div>
            <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                <div className="col px-0">
                    <span
                        className={
                            styles['payment-method-container__sub-title']
                        }
                    >
                        Name on card
                    </span>
                    <span
                        className={
                            styles['payment-method-container__content-text']
                        }
                    >
                        The Walt Disney Company
                    </span>
                </div>
            </div>
        </div>
    );
};
