import React, { useCallback } from 'react'
import { GeneralTable } from '../general-table/general-table'
import { tacticalPlanListMock } from './mock'
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import { useFilter } from '../../../providers/filter-context'
import { ClientAccountService } from '../../../temp/test'
import { fromIsoDate } from '../../../utils/format'

export const TacticalPlanList = () => {
    const { t } = useCustomTranslation()
    const [clientAccountId, setClientAccountId] = React.useState(undefined)
    const { filter } = useFilter()

    const getClientAccountId = useCallback(async () => {
        try {
            const backData = await ClientAccountService.getApiClientAccount({})
            const filteredData = backData.filter((e) => {
                return (
                    e.product_id === parseInt(filter.productId) &&
                    e.client_id === parseInt(filter.hospitalId)
                )
            })
            if (filteredData.length > 0) {
                return filteredData[0].id
            } else if (filteredData.length === 0) {
                const result =
                    await ClientAccountService.postApiClientAccountUpsert({
                        requestBody: {
                            product_id: filter.productId,
                            client_id: filter.hospitalId,
                        },
                    })
                return result.id
            }
        } catch (err) {
            console.log(err)
        }
    }, [filter])

    const translateStatusFromBackend = (value) => {
        switch (value) {
            case 'PLANNED':
                return 'Planeado'
            case 'LATE':
                return 'Atrasado'
            case 'EXECUTED':
                return 'Executado'
            default:
                return ''
        }
    }

    const translateEvaluationFromBackend = (value) => {
        switch (value) {
            case 'POSITIVE':
                return 'Positiva'
            case 'NEGATIVE':
                return 'Negativa'
            case 'NEUTRAL':
                return 'Neutra'
            case 'REPEAT':
                return 'Repetir'
            default:
                return ''
        }
    }

    const translateStatusToBackend = (value) => {
        switch (value) {
            case 'Planeado':
                return 'PLANNED'
            case 'Atrasado':
                return 'LATE'
            case 'Executado':
                return 'EXECUTED'
            default:
                return ''
        }
    }

    const translateEvaluationToBackend = (value) => {
        switch (value) {
            case 'Positiva':
                return 'POSITIVE'
            case 'Negativa':
                return 'NEGATIVE'
            case 'Neutra':
                return 'NEUTRAL'
            case 'Repetir':
                return 'REPEAT'
            default:
                return ''
        }
    }

    return (
        <GeneralTable
            title={t(`components:${tacticalPlanListMock.attr}.title`)}
            attr={tacticalPlanListMock.attr}
            columns={[
                {
                    path: 'id',
                    backendPath: 'id',
                    show: false,
                },
                {
                    path: 'objective',
                    label: 'Objective',
                    backendPath: 'goals',
                    show: true,
                },
                {
                    path: 'actionName',
                    label: 'Nome da Ação',
                    backendPath: 'action_name',
                    show: true,
                },
                {
                    path: 'type',
                    label: 'Tipo',
                    backendPath: 'type',
                    show: true,
                },
                {
                    path: 'description',
                    backendPath: 'description',
                    show: true,
                },
                {
                    path: 'owner',
                    label: 'Owner',
                    backendPath: 'owner',
                    show: true,
                },
                {
                    path: 'date',
                    backendPath: 'date',
                    backendTransform: fromIsoDate,
                    show: true,
                },
                {
                    path: 'dmu',
                    label: 'DMU',
                    backendPath: 'dmu',
                    show: true,
                },
                {
                    path: 'followUp',
                    label: 'Follow Up',
                    backendPath: 'follow_up',
                    show: true,
                },
                {
                    path: 'budget',
                    label: 'Budget',
                    backendPath: 'budget',
                    show: true,
                },
                {
                    path: 'leading',
                    label: 'Leading',
                    backendPath: 'leading',
                    show: true,
                },
                {
                    path: 'target',
                    label: 'Target',
                    backendPath: 'target',
                    show: true,
                },
                {
                    path: 'statuss',
                    label: 'Status',
                    backendPath: 'status',
                    backendTransform: (value) => {
                        return translateStatusFromBackend(value)
                    },
                    show: true,
                },
                {
                    path: 'evaluation',
                    label: 'Avaliação',
                    backendPath: 'evaluation',
                    backendTransform: (value) => {
                        return translateEvaluationFromBackend(value)
                    },
                    show: true,
                },
                {
                    path: 'action',
                    show: true,
                },
            ]}
            getEndpoint={
                ClientAccountService.getApiClientAccountTacticalPlanGetByParams
            }
            getConfig={{
                clientId: filter.hospitalId,
                productId: filter.productId,
            }}
            addTitle={t(`components:${tacticalPlanListMock.attr}.addTitle`)}
            addSuccessText={t(
                `components:${tacticalPlanListMock.attr}.addSuccessText`
            )}
            addEndpoint={
                ClientAccountService.postApiClientAccountTacticalPlanUpsert
            }
            addBodyStaticInputs={[
                {
                    value: getClientAccountId,
                    backendPath: 'client_account_id',
                },
            ]}
            addBodyInputs={{
                objective: {
                    name: 'objective',
                    path: 'objective',
                    backendPath: 'goals',
                    type: 'type',
                    show: true,
                },
                actionName: {
                    name: 'actionName',
                    path: 'actionName',
                    backendPath: 'action_name',
                    type: 'type',
                    show: true,
                },
                type: {
                    name: 'type',
                    path: 'type',
                    backendPath: 'type',
                    type: 'type',
                    show: true,
                },
                description: {
                    name: 'description',
                    path: 'description',
                    backendPath: 'description',
                    type: 'type',
                    show: true,
                },
                owner: {
                    name: 'owner',
                    path: 'owner',
                    backendPath: 'owner',
                    type: 'type',
                    show: true,
                },
                date: {
                    name: 'date',
                    path: 'date',
                    backendPath: 'date',
                    type: 'date',
                    show: true,
                },
                dmu: {
                    name: 'dmu',
                    path: 'dmu',
                    backendPath: 'dmu',
                    type: 'type',
                    show: true,
                },
                followUp: {
                    name: 'followUp',
                    path: 'followUp',
                    backendPath: 'follow_up',
                    type: 'type',
                    show: true,
                },
                budget: {
                    name: 'budget',
                    path: 'budget',
                    backendPath: 'budget',
                    type: 'number',
                    show: true,
                },
                leading: {
                    name: 'leading',
                    path: 'leading',
                    backendPath: 'leading',
                    type: 'type',
                    show: true,
                },
                target: {
                    name: 'target',
                    path: 'target',
                    backendPath: 'target',
                    type: 'type',
                    show: true,
                },
                status: {
                    type: 'selectSingle',
                    path: 'statuss',
                    backendPath: 'status',
                    name: 'status',
                    showOptions: true,
                    options: [
                        translateStatusFromBackend('PLANNED'),
                        translateStatusFromBackend('LATE'),
                        translateStatusFromBackend('EXECUTED'),
                    ],
                    transformToBackend: (value) => {
                        return translateStatusToBackend(value)
                    },
                    optionsAux: ['PLANNED', 'LATE', 'EXECUTED'],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    hideOptionsAux: true,
                    reference: React.createRef(),
                },
                evaluation: {
                    type: 'selectSingle',
                    path: 'evaluation',
                    backendPath: 'evaluation',
                    name: 'evaluation',
                    showOptions: true,
                    options: [
                        translateEvaluationFromBackend('POSITIVE'),
                        translateEvaluationFromBackend('NEGATIVE'),
                        translateEvaluationFromBackend('NEUTRAL'),
                        translateEvaluationFromBackend('REPEAT'),
                    ],
                    transformToBackend: (value) => {
                        return translateEvaluationToBackend(value)
                    },
                    optionsAux: ['POSITIVE', 'NEGATIVE', 'NEUTRAL', 'REPEAT'],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    hideOptionsAux: true,
                    reference: React.createRef(),
                },
            }}
            updateTitle={t(
                `components:${tacticalPlanListMock.attr}.updateTitle`
            )}
            updateSuccessText={t(
                `components:${tacticalPlanListMock.attr}.updateSuccessText`
            )}
            updateEndpoint={
                ClientAccountService.postApiClientAccountTacticalPlanUpsert
            }
            updateBodyStaticInputs={[
                {
                    path: 'id',
                    backendPath: 'id',
                },
            ]}
            updateBodyInputs={{
                objective: {
                    name: 'objective',
                    path: 'objective',
                    backendPath: 'goals',
                    type: 'type',
                    show: true,
                },
                actionName: {
                    name: 'actionName',
                    path: 'actionName',
                    backendPath: 'action_name',
                    type: 'type',
                    show: true,
                },
                type: {
                    name: 'type',
                    path: 'type',
                    backendPath: 'type',
                    type: 'type',
                    show: true,
                },
                description: {
                    name: 'description',
                    path: 'description',
                    backendPath: 'description',
                    type: 'type',
                    show: true,
                },
                owner: {
                    name: 'owner',
                    path: 'owner',
                    backendPath: 'owner',
                    type: 'type',
                    show: true,
                },
                date: {
                    name: 'date',
                    path: 'date',
                    backendPath: 'date',
                    type: 'date',
                    show: true,
                },
                dmu: {
                    name: 'dmu',
                    path: 'dmu',
                    backendPath: 'dmu',
                    type: 'type',
                    show: true,
                },
                followUp: {
                    name: 'followUp',
                    path: 'followUp',
                    backendPath: 'follow_up',
                    type: 'type',
                    show: true,
                },
                budget: {
                    name: 'budget',
                    path: 'budget',
                    backendPath: 'budget',
                    type: 'number',
                    show: true,
                },
                leading: {
                    name: 'leading',
                    path: 'leading',
                    backendPath: 'leading',
                    type: 'type',
                    show: true,
                },
                target: {
                    name: 'target',
                    path: 'target',
                    backendPath: 'target',
                    type: 'type',
                    show: true,
                },
                status: {
                    type: 'selectSingle',
                    path: 'statuss',
                    backendPath: 'status',
                    name: 'status',
                    showOptions: true,
                    transformToBackend: (value) => {
                        return translateStatusToBackend(value)
                    },
                    options: [
                        translateStatusFromBackend('PLANNED'),
                        translateStatusFromBackend('LATE'),
                        translateStatusFromBackend('EXECUTED'),
                    ],
                    optionsAux: ['PLANNED', 'LATE', 'EXECUTED'],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    hideOptionsAux: true,
                    reference: React.createRef(),
                },
                evaluation: {
                    type: 'selectSingle',
                    path: 'evaluation',
                    backendPath: 'evaluation',
                    name: 'evaluation',
                    showOptions: true,
                    transformToBackend: (value) => {
                        return translateEvaluationToBackend(value)
                    },
                    options: [
                        translateEvaluationFromBackend('POSITIVE'),
                        translateEvaluationFromBackend('NEGATIVE'),
                        translateEvaluationFromBackend('NEUTRAL'),
                        translateEvaluationFromBackend('REPEAT'),
                    ],
                    optionsAux: ['POSITIVE', 'NEGATIVE', 'NEUTRAL', 'REPEAT'],
                    selectedOption: '',
                    selectedOptionAux: '',
                    state: 'normal',
                    value: '',
                    focused: -1,
                    hideOptionsAux: true,
                    reference: React.createRef(),
                },
            }}
            deleteIds={[
                {
                    path: 'id',
                    backendPath: 'id',
                },
            ]}
            deleteEndpoint={
                ClientAccountService.deleteApiClientAccountTacticalPlan
            }
            deleteTitle={t(
                `components:${tacticalPlanListMock.attr}.deleteTitle`
            )}
            deleteDescription={t(
                `components:${tacticalPlanListMock.attr}.deleteDescription`
            )}
            deleteSuccessText={t(
                `components:${tacticalPlanListMock.attr}.deleteSuccessText`
            )}
        />
    )
}
