import React from 'react'

export const sentimentGeneralDetailsMock = {
    hoverStyle: '',
    disabled: true,
    desc: '',
    ready: true,
    attr: 'sentimentGeneralDetails',
    data: {
        createdBy: '-',
        dateOfCreation: '-',
        dataInterval: '-',
        emails: '-',
        texts: '-',
        calls: '-',
        media: '-',
        beganAt: '-',
        endedAt: '-',
    },
    loading: true,
    settings: {
        reference: React.createRef(),
        open: false,
        view: {
            label: 'VIEW',
            options: [],
        },
        options: {
            label: 'OPTIONS',
            options: [],
        },
    },
}
