const isValidOptionAndIsOpen = (selectedOption, options) => {
    return options && options.length > 0 && options.includes(selectedOption);
};

export const getContainerWidth = (
    toggleActive,
    auxBarActive,
    availableOptions,
    selectedAvailableOption,
    highlightedChart
) => {
    const isOptionValidAndIsOpen = isValidOptionAndIsOpen(
        selectedAvailableOption,
        availableOptions
    );
    // if there are no options on the right bar, then the right bar is not visible, the right bar is always invisible
    if (
        (!availableOptions || availableOptions.length === 0) &&
        !selectedAvailableOption.startsWith('componentFilter') &&
        selectedAvailableOption !== 'approvalHistory'
    ) {
        if (toggleActive) return 'collapsed--no-aux';
        return 'collapsed--nav-no-aux';
    } else if (
        (availableOptions.length === 1 && availableOptions[0] === 'none') ||
        (availableOptions.length === 1 && availableOptions[0] === '')
    ) {
        if (toggleActive) return 'collapsed--no-aux-2';
        return 'collapsed--nav-no-aux-2';
    }
    // if there are options on the right bar but the options are not valid or if the right bar is closed, the right bar is always closed
    if (
        !isOptionValidAndIsOpen &&
        !highlightedChart &&
        !selectedAvailableOption.startsWith('componentFilter') &&
        selectedAvailableOption !== 'approvalHistory'
    ) {
        // if the left bar is open
        if (toggleActive) return 'collapsed--aux';
        // if the left bar is closed
        else return 'collapsed';
    }
    // if there are options on the right bar and the option selected is valid. this option assumes the right bar is always open
    else {
        // if both bars are open
        if (auxBarActive && toggleActive) return '';
        // if the left bar is open and the right bar is closed
        else if (auxBarActive && !toggleActive) return 'collapsed--nav';
    }
};

export const getNavWidth = (toggleActive) => {
    return toggleActive === false ? 'collapsed' : '';
};
