export const billingHistoryMock = {
    loading: true,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: true,
    attr: 'billingChart',
    isForecast: false,
    titleText: '',
    areaAttr: [],
    categoryAxis: 'month',
    data: [],
    dateGrouper: '',
    maxValue: 25,
    minValue: 2,
    noForecast: true,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'yo',
    unitsOrValues: 'units',
    margin: {
        top: 20,
        right: 40,
        left: 0,
        bottom: 10
    },
    interactionDisabled: true
};
