import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import styles from './Members.module.scss'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { PrimaryButton } from '../../../../../components'
import icoPlus from '../../../../../assets/images/ico-plus.svg'
import icoCheckboxEmpty from '../../../../../assets/images/ico-checkbox-empty.svg'
import icoCheckboxSelected from '../../../../../assets/images/ico-checkbox-selected.svg'
import { SearchInput } from '../../../../../components/inputs/search-input/SearchInput'
import { FilterInput } from '../../../../../components/inputs/filter-input/filter-input'
import { MemberCard } from '../member-card/MemberCard'
import { useTableFilter } from '../../../../../hooks/useTableFilter'
import memberCardExample from '../../../../../assets/images/memberCardExample.png'
import icoEdit from '../../../../../assets/images/ico-edit.svg'
import icoTransferMember from '../../../../../assets/images/icoTransferMember.svg'
import icoDelete from '../../../../../assets/images/ico-delete.svg'
import { InviteMemberModal } from '../invite-member-modal/invite-member-modal'
import icoRemoveGradient from '../../../../../assets/images/icoRemoveGradient.svg'
import { RemoveCheckedMembersModal } from '../remove-checked-members-modal/remove-checked-members-modal'
import { usePlatformFormat } from '../../../../../providers/platform-format-context'
import icoUser1 from '../../../../../assets/images/user1.svg'
import icoUser2 from '../../../../../assets/images/user2.svg'
import icoUser3 from '../../../../../assets/images/user3.svg'
import icoUser4 from '../../../../../assets/images/user4.svg'
import icoUser5 from '../../../../../assets/images/user5.svg'
import icoUser6 from '../../../../../assets/images/user6.svg'
import icoUser7 from '../../../../../assets/images/user7.svg'
import icoUser8 from '../../../../../assets/images/user8.svg'
import icoUser9 from '../../../../../assets/images/user9.svg'
import icoUser10 from '../../../../../assets/images/user10.svg'

function generateMemberMockData(number) {
    const numberOfMembers = number || 10 // Default to 10 members if no number is provided
    const memberTypes = ['enterprise', 'organization']
    const roles = ['admin', 'member']
    const teams = ['Team 1', 'Team 2', 'Team 3']
    const imagesUsed = []
    const mockData = Array.from({ length: numberOfMembers }, (_, i) => {
        const id = i + 1
        const typeIndex = i % memberTypes.length
        const roleIndex = i % roles.length
        const hasImage = Math.random() > 0.5

        let newImage = [icoUser1, icoUser2, icoUser3, icoUser4, icoUser5, icoUser6, icoUser7, icoUser8, icoUser9, icoUser10][
            Math.floor(Math.random() * 10)
        ]
        imagesUsed.push(newImage)
        const firstName = 'John'
        const lastName = 'Doe'
        return {
            id: id,
            image: hasImage ? newImage : undefined,
            initials: !hasImage ? firstName[0] + lastName[0] : undefined,
            name: `${firstName} ${lastName}`,
            email: 'john.doe@outlook.com',
            type: memberTypes[typeIndex],
            role: roles[roleIndex],
            teams: teams.slice(0, (i % teams.length) + 1), // Each member has a varying number of teams
            orgId: typeIndex === 1 ? id : undefined, // Only assign orgId for organization type
            action: {
                id: id,
                open: false,
                options: [
                    { name: 'edit', icon: icoEdit },
                    { name: 'transferMember', icon: icoTransferMember },
                    { name: 'remove', icon: icoDelete, isRed: true },
                ],
                reference: React.createRef(), // Assuming this is used in a React component for managing UI state
            },
        }
    })

    return mockData
}

export const Members = React.memo(() => {
    const { t } = useCustomTranslation()
    const { format } = usePlatformFormat()
    const [inviteMemberModal, setInviteMemberModal] = useState(false)
    const [deleteCheckedMembersModal, setDeleteCheckedMembersModal] = useState(false)
    const [cards, setCards] = useState(generateMemberMockData(15)) // Replace with actual data from API
    const [checkedMembers, setCheckedMembers] = useState([])
    const [searchValue, setSearchValue] = useState('') // Replace with actual search value
    const [infoToSearch, setInfoToSearch] = useState(['name', 'email']) // Replace with actual info to search
    const [infoToShow, setInfoToShow] = useState(format === 'enterprise' ? ['type', 'role'] : ['role'])

    const handleCheckMember = useCallback(
        (memberId) => {
            setCheckedMembers([...checkedMembers, memberId])
        },
        [checkedMembers]
    )

    const handleUncheckMember = useCallback(
        (memberId) => {
            setCheckedMembers(checkedMembers.filter((id) => id !== memberId))
        },
        [checkedMembers]
    )

    const [filters, toggleFilterOption, clearAllFilters] = useTableFilter([])
    const filterInputs = useMemo(() => {
        // for every column that has an attribute isFilter to true, go get every unique value
        return infoToShow.map((path) => {
            const values = _.uniq(cards.map((item) => _.get(item, path)))
            return {
                path,
                values,
            }
        })
    }, [cards, infoToShow])

    const filteredData = useMemo(() => {
        return cards
            .filter((card) => {
                return (
                    infoToSearch.filter((info) => {
                        return card[info].toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                    }).length > 0
                )
            })
            .filter((card) => {
                return filters.reduce((acc, filter) => {
                    return acc && filter.value.includes(_.get(card, filter.path))
                }, true)
            })
    }, [cards, filters, searchValue, infoToSearch])

    const handleUncheckAll = useCallback(() => {
        setCheckedMembers([])
    }, [])

    const handleCheckAll = useCallback(() => {
        setCheckedMembers(filteredData.map((card) => card.id))
    }, [filteredData])

    return (
        <>
            {deleteCheckedMembersModal && <RemoveCheckedMembersModal onCloseModal={() => setDeleteCheckedMembersModal(false)} />}
            {inviteMemberModal && <InviteMemberModal onCloseModal={() => setInviteMemberModal(false)} />}
            <div className={styles['members-container']}>
                <div className="row mx-0 w-100 d-flex justify-content-end align-items-center" style={{ marginBottom: '2rem' }}>
                    <div className="col px-0">
                        <span className={styles['members-container__description']}>
                            {format === 'enterprise' && t('pages:peopleAndPermissions.members.descriptionEnterprise')}
                            {format === 'organization' && t('pages:peopleAndPermissions.members.descriptionOrganization')}
                        </span>
                    </div>
                    <div className="col-auto px-0">
                        <PrimaryButton
                            text={t('buttons:inviteMembers')}
                            icon={icoPlus}
                            onClick={() => {
                                setInviteMemberModal(true)
                            }}
                        />
                    </div>
                </div>
                <div className="row mx-0 w-100" style={{ marginBottom: '2rem' }}>
                    <div className="col-auto px-0 d-flex align-items-center" style={{ marginRight: '1rem' }}>
                        <img
                            src={checkedMembers.length > 0 ? icoCheckboxSelected : icoCheckboxEmpty}
                            alt="Checkbox"
                            className={
                                checkedMembers.length > 0
                                    ? `
                                    ${styles['members-container__checkbox-icon']}
                                    ${styles['members-container__checkbox-icon--selected']}`
                                    : styles['members-container__checkbox-icon']
                            }
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                                if (checkedMembers.length > 0) {
                                    handleUncheckAll()
                                } else handleCheckAll()
                            }}
                        />
                        <span className={`${styles['members-container__checkbox-text']}`}>
                            {checkedMembers.length > 0 ? `${checkedMembers.length} selected` : `Select all`}
                        </span>
                    </div>
                    <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                        {checkedMembers.length > 0 && (
                            <div
                                className="col-auto px-0 d-flex justify-content-start align-items-center"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setDeleteCheckedMembersModal(true)
                                }}
                            >
                                <img src={icoRemoveGradient} alt="Remove" className={styles['members-container__remove-icon']} />
                                <span className={`${styles['members-container__remove-text']}`}>{t(`modals:remove`)}</span>
                            </div>
                        )}
                    </div>
                    <div className="col px-0"></div>
                    <div className="col-auto px-0" style={{ width: '30rem' }}>
                        <SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={t('inputs:searchMembers')} />
                    </div>
                    <div className="col-auto px-0" style={{ width: '1rem' }}></div>
                    <div className="col-auto px-0 d-flex align-items-center">
                        <FilterInput
                            inputs={filterInputs}
                            filters={filters}
                            toggleFilterOption={toggleFilterOption}
                            clearAllFilters={clearAllFilters}
                        />
                    </div>
                </div>
                <div
                    className="row"
                    style={{
                        width: 'calc(100% + 2rem)',
                        margin: '0 -1rem',
                    }}
                >
                    {filteredData &&
                        filteredData.map((card, index) => {
                            return (
                                <div
                                    className="col-6 col-md-6 col-xl-6 col-xxl-3" // Use col-md-6 to create a 2 by 2 grid layout
                                    key={card.id}
                                    style={{
                                        padding: '0 1rem',
                                        marginBottom: '2rem',
                                    }}
                                >
                                    <MemberCard
                                        {...card}
                                        onCheck={handleCheckMember}
                                        onUncheck={handleUncheckMember}
                                        checkedMembers={checkedMembers}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
        </>
    )
})
