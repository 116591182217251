import styles from './region-information.module.scss';

import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useSimpleComponent } from '../../../hooks/useSimpleComponent';
import { useFilter } from '../../../providers/filter-context';
import componentConfig from '../../../configs/componentLibraryConf.json';

import { regionInformationMock } from './mock';
import { useToggle } from '../../../hooks/useToggle';
import {
    ContentContainer,
    Loader,
    MarkerMap,
    Spinner,
    TagsContainer
} from '../..';
import { errorHandler } from '../../../utils/api';
import { verifyComponent } from '../../../utils/component';
import { RegionsService } from '../../../temp/test';
import { getFilterParam } from '../../../utils/params';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Displays general information about a product
 */

export const RegionInformation = React.memo(
    ({
        onDownloadComponent,
        config,
        isExample,
        isReportExample,
        pinned,
        onPinComponent,
        onUnpinComponent,
        onMouseEnterInfoIcon,
        onMouseLeaveInfoIcon,
        isPreview,
        type
    }) => {
        const { filter } = useFilter();
        const isMounted = useIsMounted();
        const { i18n } = useTranslation();
        const { t } = useCustomTranslation();
        const [isEditing, toggleIsEditing] = useToggle(false);

        const {
            value: data,
            setLoading,
            setValue,
            toggleComponentSettings
        } = useSimpleComponent(regionInformationMock);

        const getTeamInformation = useCallback(async () => {
            const dataClone = _.cloneDeep(regionInformationMock);
            try {
                if (!isExample) {
                    if (isMounted.current) {
                        setLoading(true);
                    }
                    if (type) dataClone.attr = type;

                    const dataBack = await RegionsService.getApiRegions1({
                        id: getFilterParam(filter, config, [
                            'regionId',
                            'regionIds'
                        ])
                    });
                    const dataBackMarket =
                        await RegionsService.getApiRegionsIndexes({
                            regionId:
                                getFilterParam(filter, config, [
                                    'regionId',
                                    'regionIds'
                                ]).length > 0
                                    ? getFilterParam(filter, config, [
                                          'regionId',
                                          'regionIds'
                                      ])[0]
                                    : undefined
                        });
                    dataClone.marketIndex = dataBackMarket.market_index;
                    dataClone.marketShare = dataBackMarket.market_share;
                    dataClone.region = dataBack.parent
                        ? dataBack.parent.name
                        : undefined;
                    dataClone.name = dataBack.name ?? '-';
                    verifyComponent(dataClone);
                    if (isMounted.current) {
                        setValue(dataClone);
                        setLoading(false);
                    }
                    dataClone.dataList = [
                        {
                            id: 'small',
                            data: {
                                type: 'FeatureCollection',
                                features: [
                                    {
                                        type: 'Feature',
                                        properties: {
                                            name: 'VR001 Boticas, Montalegre, Mondim Basto, Ribeira de Pena',
                                            lat: dataBack.lat,
                                            lon: dataBack.lon
                                        },
                                        geometry: {
                                            type: 'Point',
                                            coordinates: [
                                                dataBack.lon,
                                                dataBack.lat
                                            ]
                                        }
                                    }
                                ]
                            },
                            styleSmall: {
                                id: 'small-style-small',
                                type: 'circle',
                                innerRadius: 0.4,
                                paint: {
                                    'circle-radius': 20,
                                    'circle-color': '#6CC69A',
                                    'circle-opacity': 0.6
                                }
                            },
                            styleLarge: {
                                id: 'small-style-large',
                                type: 'circle',
                                outerRadius: 1.2,
                                paint: {
                                    'circle-radius': 20,
                                    'circle-color': '#6CC69A',
                                    'circle-opacity': 0.1
                                }
                            }
                        }
                    ];
                    dataClone.startingPosition = {
                        lat: dataBack.lat,
                        lon: dataBack.lon,
                        zoom: 7
                    };

                    if (isMounted.current) setValue(dataClone);
                } else {
                    if (type) dataClone.attr = type;
                    verifyComponent(dataClone);
                    setValue(dataClone);
                    setLoading(false);
                }
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        }, [config, filter, isExample, isMounted, setLoading, setValue, type]);

        useEffect(() => {
            getTeamInformation();
        }, [getTeamInformation]);

        const handleSelectSettingsOption = useCallback(
            (attr, id, option) => {
                if (isMounted.current) toggleComponentSettings();
                if (option === 'pin') {
                    onPinComponent(data.attr);
                } else if (option === 'unpin') {
                    onUnpinComponent(data.attr);
                } else if (option === 'download') {
                    onDownloadComponent();
                }
            },
            [
                data.attr,
                isMounted,
                onDownloadComponent,
                onPinComponent,
                onUnpinComponent,
                toggleComponentSettings
            ]
        );

        return (
            <div
                className={isPreview ? 'w-100 h-100' : 'w-100 h-100'}
                style={{ zIndex: 'inherit' }}
            >
                <ContentContainer
                    attr={data.attr}
                    titleText={t(`components:${data.attr}.title`)}
                    disabled={data.disabled}
                    isForecast={data.isForecast}
                    legend={data.legend}
                    settings={data.settings}
                    onClickSettingsIcon={toggleComponentSettings}
                    onClickSettingsOptions={handleSelectSettingsOption}
                    onClickSettingsView={handleSelectSettingsOption}
                    onCLickSettintsSelected={handleSelectSettingsOption}
                    onCloseSettings={toggleComponentSettings}
                    // hovered={hovered}
                    onMouseEnterInfoIcon={onMouseEnterInfoIcon}
                    onMouseLeaveInfoIcon={onMouseLeaveInfoIcon}
                    hideInfoIcon={true}
                    hidePinIcon={isExample || isPreview || isReportExample}
                    hideSettingsIcon={isExample || isPreview || isReportExample}
                    pinned={pinned}
                    onPinComponent={onPinComponent}
                    onUnpinComponent={onUnpinComponent}
                >
                    {data.loading && <Spinner />}
                    {!data.loading && (
                        <div
                            className={`${styles['region-information-card-container']}`}
                        >
                            <div className="row mx-0 w-100 h-100">
                                <div
                                    className="col-8 px-0 h-100"
                                    style={{
                                        borderRadius: '0.8rem',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <MarkerMap
                                        translate={t}
                                        dataList={data.dataList}
                                        startingPosition={data.startingPosition}
                                        tooltipType={undefined}
                                        interactionDisabled={
                                            data.interactionDisabled
                                        }
                                        selectedEl={data.selectedEl}
                                        showPopup={data.showPopup}
                                        scale={data.scale}
                                        minScale={data.minScale}
                                        minZoom={data.minZoom}
                                        flyEnabled={data.flyEnabled}
                                        customScale={10}
                                    />
                                </div>
                                <div
                                    className="col-auto px-0"
                                    style={{ width: '2rem' }}
                                ></div>
                                <div className="col-3 px-0 h-100">
                                    <div className="row-mx-0 w-100">
                                        <div className="col px-0">
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__label']}`}
                                                    >
                                                        Name
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__title']}`}
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                            maxWidth: '100%',
                                                            display:
                                                                'inline-block'
                                                        }}
                                                    >
                                                        {_.has(data, 'name') &&
                                                            data.name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__label']}`}
                                                    >
                                                        Region
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__text']}`}
                                                    >
                                                        {(_.has(
                                                            data,
                                                            'region'
                                                        ) &&
                                                            data.region) ||
                                                            '-'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__label']}`}
                                                    >
                                                        Market Share
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__text']}`}
                                                    >
                                                        {_.has(
                                                            data,
                                                            'marketShare'
                                                        ) && data.marketShare
                                                            ? data.marketShare +
                                                              '%'
                                                            : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mx-0 w-100">
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__label']}`}
                                                    >
                                                        Market Index
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="row mx-0 w-100"
                                                style={{ marginBottom: '2rem' }}
                                            >
                                                <div className="col px-0">
                                                    <span
                                                        className={`${styles['region-information-card-container__text']}`}
                                                    >
                                                        {_.has(
                                                            data,
                                                            'marketIndex'
                                                        ) && data.marketIndex
                                                            ? data.marketIndex +
                                                              '%'
                                                            : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ContentContainer>
            </div>
        );
    }
);

RegionInformation.propTypes = {
    /**
     * Download component as an image
     */
    onDownloadComponent: PropTypes.func,
    /**
     * Configuration defined for object
     */
    config: PropTypes.object,
    /**
     * Is the component an example?
     */
    isExample: PropTypes.bool,
    /**
     * Is the component pinned to homepage?
     */
    pinned: PropTypes.bool,
    /**
     * Pins component to homepage
     */
    onPinComponent: PropTypes.func,
    /**
     * Unpins component from homepage
     */
    onUnpinComponent: PropTypes.func,
    /**
     * Is the component being hovered?
     */
    hovered: PropTypes.bool,
    /**
     * When mouse enters the info icon
     */
    onMouseEnterInfoIcon: PropTypes.func,
    /**
     * When mouse leaves the info icon
     */
    onMouseLeaveInfoIcon: PropTypes.func,
    /**
     * Is the component in preview mode?
     */
    isPreview: PropTypes.bool,
    /**
     * Component's Type
     */
    type: PropTypes.string
};
