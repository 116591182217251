import React, { useState } from 'react';
import { TableConfigRow } from '../../components';

export const WithTableHeader = React.memo(({ count, children }) => {
    const [search, setSearch] = useState('');
    const [paginationLimit, setPaginationLimit] = useState(10);
    return (
        <div className="w-100 h-100 d-flex flex-column">
            <div
                className="row mx-0 w-100"
                style={{ zIndex: 1000, padding: '2rem', paddingBottom: '0' }}
            >
                <div className="col px-0">
                    <TableConfigRow
                        count={count}
                        onSelectPaginationLimit={setPaginationLimit}
                        onSetSearch={setSearch}
                    />
                </div>
            </div>
            <div
                className="row mx-0 w-100 flex-grow-1"
                style={{ minHeight: 0 }}
            >
                <div className="col px-0 h-100">
                    {React.cloneElement(children, {
                        pageSize: paginationLimit,
                        search: search
                    })}
                </div>
            </div>
        </div>
    );
});
