import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const TemperatureTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.id !== '') {
        return (
            <TooltipContainer color={props.color}>
                <TooltipHeaderRow
                    title={t('tooltips:header.temperature')}
                    value={props.payload[props.id].payload.month}
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.avg')}
                    value={
                        parseFloat(
                            props.payload[props.id].payload.avgT
                        ).toFixed(2) + 'ºC'
                    }
                    color={props.color}
                />
            </TooltipContainer>
        )
    }
})
