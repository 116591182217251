import React from 'react'
import {
    Separator,
    TooltipContainer,
    TooltipContentRow,
    TooltipHeaderRow,
} from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SentimentTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.id !== '') {
        return (
            <TooltipContainer color={props.payload[props.id].stroke}>
                <TooltipHeaderRow
                    title={t('tooltips:header.sentiment')}
                    value={t(
                        `tooltips:header.${
                            props.payload[props.id].name.split('-')[1]
                        }`
                    )}
                    color={props.payload[props.id].stroke}
                />
                <Separator />
                <TooltipContentRow
                    title={t('tooltips:content.distribution')}
                    value={`${props.payload[props.id].value}%`}
                    color={props.payload[props.id].stroke}
                />
            </TooltipContainer>
        )
    }
})
