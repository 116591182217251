import React, { useCallback, useMemo, useState } from 'react'
import styles from './GoalList.module.scss'
import { EditGoalModal } from '../../../../../components/modals/edit-goal-modal/edit-goal-modal'
import { GoalFirstRow } from '../GoalFirstRow/GoalFirstRow'
import { GoalNoItems } from '../GoalNoItems/GoalNoItems'
import { GoalCard } from '../GoalCard/GoalCard'
import icoEdit from '../../../../../assets/images/ico-edit.svg'
import icoRemove from '../../../../../assets/images/ico-delete.svg'
import icoAddMilestones from '../../../../../assets/images/icoAddMilestones.svg'
import icoSubGoals from '../../../../../assets/images/icoSubGoals.svg'
import icoHistoricalApproval from '../../../../../assets/images/icoHistoricalApproval.svg'
import { RemoveGoalModal } from '../../../../../components/modals/remove-goal-modal/remove-goal-modal'
import { AddMilestonesModal } from '../../../../../components/modals/add-milestones-modal/add-milestones-modal'
import { useToggle } from '../../../../../hooks/useToggle'
import { useAuxBar } from '../../../../../providers/aux-bar-context'
import { useNavigate } from 'react-router-dom'
import { EditSubGoalsModal } from '../../../../../components/modals/edit-sub-goals-modal/edit-sub-goals-modal'

export const GoalList = ({ onSelectGoal }) => {
    const [searchValue, setSearchValue] = useState('') // Replace with actual search value
    const [goals, setGoals] = useState([
        {
            id: 1,
            title: 'Goal 1',
            owner: 'Owner 1',
            status: 'draft',
            steps: [
                {
                    index: 1,
                    text: 'Step 1',
                    isMissing: false,
                },
                {
                    index: 2,
                    text: 'Step 2',
                    isMissing: true,
                },
            ],
            timePeriod: 'FY 2024',
            targetValue: 100,
            currentValue: 50,
            milestones: [
                {
                    date: '2024-01-01',
                    targetValue: 100,
                    reachedTarget: false,
                },
            ],
            subGoalsCount: 2,
            action: {
                id: 1,
                open: false,
                options: [
                    {
                        name: 'edit',
                        icon: icoEdit,
                    },
                    {
                        name: 'addMilestones',
                        icon: icoAddMilestones,
                    },
                    {
                        name: 'subGoals',
                        icon: icoSubGoals,
                    },
                    {
                        name: 'historicalApproval',
                        icon: icoHistoricalApproval,
                    },
                    {
                        name: 'remove',
                        icon: icoRemove,
                        isRed: true,
                    },
                ],
                reference: React.createRef(),
            },
        },
    ])

    const filteredData = useMemo(() => {
        return goals.filter((card) => {
            return (
                ['title', 'owner', 'status', 'timePeriod', 'targetValue', 'currentValue', 'subGoalsCount'].filter((info) => {
                    return card[info].toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                }).length > 0
            )
        })
        // .filter((card) => {
        //     return filters.reduce((acc, filter) => {
        //         return (
        //             acc && filter.value.includes(_.get(card, filter.path))
        //         );
        //     }, true);
        // });
    }, [goals, searchValue])

    return (
        <>
            <div className="d-flex flex-column w-100 h-100">
                <GoalFirstRow
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    description={
                        'Manage and track your goals here. You can add, edit, and delete goals. You can also add milestones to your goals and track your progress.'
                    }
                />
                {filteredData.length === 0 && (
                    <div className="w-100 d-flex flex-column justify-content-center align-items-center flex-grow-1">
                        <GoalNoItems />
                    </div>
                )}
                {filteredData &&
                    filteredData.length > 0 &&
                    filteredData.map((goal, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    width: '46rem',
                                    height: '40rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <GoalCard {...goal} onSelectGoal={onSelectGoal} />
                            </div>
                        )
                    })}
            </div>
        </>
    )
}
