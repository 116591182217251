import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { getRoundedValue } from '../../../../../utils/math'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'

export const SalesByProduct = React.memo((props) => {
    const { t } = useCustomTranslation()
    if (props.data.data1 && props.data.data1.length > 0 && props.index !== undefined) {
        return (
            <TooltipContainer color={props.data.data1[props.index].color}>
                <TooltipHeaderRow
                    title={t(`tooltips:header.sales-by-product`)}
                    value={props.data.data1[props.index].name}
                    color={props.data.data1[props.index].color}
                />
                <Separator />
                <TooltipContentRow
                    title={t(`tooltips:content.unitsSold`)}
                    value={getRoundedValue(props.data.data1[props.index].value)}
                    color={props.data.data1[props.index].color}
                />
                <TooltipContentRow
                    title={t(`tooltips:content.percentage`)}
                    value={Math.round(props.data.data1[props.index].percentage.toFixed(2) * 100) + '%'}
                    color={props.data.data1[props.index].color}
                />
            </TooltipContainer>
        )
    } else return null
})
