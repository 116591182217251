import React from 'react'
import styles from './team-member-revenue-table-column.module.scss'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import icoUpTrend from '../../../../../assets/images/icoUpTrend.svg'
import icoDownTrend from '../../../../../assets/images/icoDownTrend.svg'
import _ from 'lodash'
import { formatCurrency } from '../../../../../utils/math'

export const TeamMemberRevenueTableColumn = React.memo(({ el, type }) => {
    const { t } = useCustomTranslation()
    return (
        <div className={styles['team-member-revenue-table-column-container']}>
            <div className="row mx-0 w-100">
                <div className="col-auto px-0 d-flex flex-column justify-content-center">
                    <div className="row mx-0 w-100">
                        <div className="col-auto px-0" style={{ marginRight: '1rem' }}>
                            <span
                                className={
                                    el['teamMemberRevenue'] && el['teamMemberRevenueLast']
                                        ? el['teamMemberRevenue'] > el['teamMemberRevenueLast']
                                            ? styles['team-member-revenue-table-column-container__text'] + ' ' + styles['up']
                                            : el['teamMemberRevenue'] < el['teamMemberRevenueLast']
                                            ? styles['team-member-revenue-table-column-container__text'] + ' ' + styles['down']
                                            : styles['team-member-revenue-table-column-container__text']
                                        : styles['team-member-revenue-table-column-container__text']
                                }
                            >
                                {el[type] ? formatCurrency(el[type], 'EUR') : ''}
                            </span>
                        </div>
                        <div className="col-auto px-0 d-flex justify-content-center align-items-center">
                            {el['teamMemberRevenue'] && el['teamMemberRevenueLast'] ? (
                                el['teamMemberRevenue'] > el['teamMemberRevenueLast'] ? (
                                    <img
                                        src={icoUpTrend}
                                        alt="Up trend"
                                        className={styles['team-member-revenue-table-column-container__icon'] + ' ' + styles['up']}
                                    />
                                ) : el['teamMemberRevenue'] < el['teamMemberRevenueLast'] ? (
                                    <img
                                        src={icoDownTrend}
                                        alt="Up trend"
                                        className={styles['team-member-revenue-table-column-container__icon'] + ' ' + styles['down']}
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}{' '}
                            {el['teamMemberRevenue'] &&
                                el['teamMemberRevenueLast'] &&
                                (el['teamMemberRevenue'] > el['teamMemberRevenueLast'] || el['teamMemberRevenue'] < el['teamMemberRevenueLast']) && (
                                    <>
                                        <span
                                            className={
                                                el['teamMemberRevenue'] && el['teamMemberRevenueLast']
                                                    ? el['teamMemberRevenue'] > el['teamMemberRevenueLast']
                                                        ? styles['team-member-revenue-table-column-container__text-secondary'] + ' ' + styles['up']
                                                        : el['teamMemberRevenue'] < el['teamMemberRevenueLast']
                                                        ? styles['team-member-revenue-table-column-container__text-secondary'] + ' ' + styles['down']
                                                        : styles['team-member-revenue-table-column-container__text-secondary']
                                                    : styles['team-member-revenue-table-column-container__text-secondary']
                                            }
                                            style={{ marginRight: '0.5rem' }}
                                        >
                                            {Math.round((el['teamMemberRevenue'] / el['teamMemberRevenueLast']) * 100)}%
                                        </span>{' '}
                                        <span className={styles['team-member-revenue-table-column-container__text-secondary']}>vs. last month</span>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
