export const evolByProductForecastMock = {
    loading: false,
    infoEnabled: true,
    infoText: 'yo',
    hoverStyle: '',
    desc: '',
    disabled: false,
    attr: 'evolByProductForecastChart',
    isForecast: false,
    titleText: '',
    chartOptions: [
        {
            name: 'avgT',
            label: 'Temperature'
        },
        {
            name: 'avgP',
            label: 'Precipitation'
        }
    ],
    lineAttr: [
        // {
        //     dataKey: 'revenueForecastValue',
        //     teamId: 10,
        //     color: '#2cd9c5',
        //     strokeWidth: 2,
        //     isTransparent: true,
        //     isReverseColor: true,
        // },
        // {
        //     dataKey: 'revenue',
        //     color: '#2cd9c5',
        //     dashArray: '3 3',
        //     teamId: 10,
        //     isGradient: true,
        //     isTransparent: true,
        //     strokeWidth: 2,
        //     fillOpacity: 1,
        // },
        // {
        //     dataKey: 'atc3ForecastValue',
        //     teamId: 11,
        //     color: '#FF8900',
        //     strokeWidth: 2,
        //     isTransparent: true,
        //     isReverseColor: true,
        // },
        // {
        //     dataKey: 'atc3',
        //     color: '#FF8900',
        //     dashArray: '3 3',
        //     teamId: 11,
        //     isGradient: true,
        //     isTransparent: true,
        //     strokeWidth: 2,
        //     fillOpacity: 1,
        // },
        // {
        //     dataKey: 'atc4ForecastValue',
        //     teamId: 12,
        //     color: '#8C54FF',
        //     strokeWidth: 2,
        //     isTransparent: true,
        //     isReverseColor: true,
        // },
        // {
        //     dataKey: 'atc4',
        //     color: '#8C54FF',
        //     dashArray: '3 3',
        //     teamId: 12,
        //     isGradient: true,
        //     isTransparent: true,
        //     strokeWidth: 2,
        //     fillOpacity: 1,
        // },
    ],
    categoryAxis: 'month',
    data: [
        // {
        //     time: '2022-01-01',
        //     day: '01 Jan 2022',
        //     month: 'Jan 2022',
        //     year: '2022',
        //     revenue: '752042',
        //     revenueForecastValue: '752042',
        //     revenueForecastMinValue: '724123',
        //     revenueForecastMaxValue: '781729',
        //     atc3: '1306346',
        //     atc3ForecastValue: '1306346',
        //     atc3ForecastMinValue: '1411025',
        //     atc3ForecastMaxValue: '1311274',
        //     atc4: '1341933',
        //     atc4ForecastValue: '1341933',
        //     atc4ForecastMinValue: '1546843',
        //     atc4ForecastMaxValue: '1387266',
        // },
        // {
        //     time: '2022-02-01',
        //     day: '01 Fev 2022',
        //     month: 'Feb 2022',
        //     year: '2022',
        //     revenue: '580387',
        //     revenueForecastValue: '580387',
        //     revenueForecastMinValue: '586148',
        //     revenueForecastMaxValue: '844999',
        //     atc3: '1271292',
        //     atc3ForecastValue: '1271292',
        //     atc3ForecastMinValue: '1427530',
        //     atc3ForecastMaxValue: '1429132',
        //     atc4: '1344936',
        //     atc4ForecastValue: '1344936',
        //     atc4ForecastMinValue: '1609114',
        //     atc4ForecastMaxValue: '1575935',
        // },
        // {
        //     time: '2022-03-01',
        //     day: '01 Mar 2022',
        //     month: 'Mar 2022',
        //     year: '2022',
        //     revenue: '533962',
        //     revenueForecastValue: '533962',
        //     revenueForecastMinValue: '507155',
        //     revenueForecastMaxValue: '884394',
        //     atc3: '1262012',
        //     atc3ForecastValue: '1262012',
        //     atc3ForecastMinValue: '1332645',
        //     atc3ForecastMaxValue: '1486661',
        //     atc4: '1701581',
        //     atc4ForecastValue: '1701581',
        //     atc4ForecastMinValue: '1764531',
        //     atc4ForecastMaxValue: '1327844',
        // },
        // {
        //     time: '2022-04-01',
        //     day: '01 Abr 2022',
        //     month: 'Apr 2022',
        //     year: '2022',
        //     revenue: '656451',
        //     revenueForecastValue: '656451',
        //     revenueForecastMinValue: '722972',
        //     revenueForecastMaxValue: '676239',
        //     atc3: '1399687',
        //     atc3ForecastValue: '1399687',
        //     atc3ForecastMinValue: '1470430',
        //     atc3ForecastMaxValue: '1493055',
        //     atc4: '1496140',
        //     atc4ForecastValue: '1496140',
        //     atc4ForecastMinValue: '1625255',
        //     atc4ForecastMaxValue: '1539453',
        // },
        // {
        //     time: '2022-05-01',
        //     day: '01 Maio 2022',
        //     month: 'May 2022',
        //     year: '2022',
        //     revenue: '889578',
        //     revenueForecastValue: '889578',
        //     revenueForecastMinValue: '782502',
        //     revenueForecastMaxValue: '618178',
        //     atc3: '1365859',
        //     atc3ForecastValue: '1365859',
        //     atc3ForecastMinValue: '1342349',
        //     atc3ForecastMaxValue: '1459611',
        //     atc4: '1578234',
        //     atc4ForecastValue: '1578234',
        //     atc4ForecastMinValue: '1569644',
        //     atc4ForecastMaxValue: '1626999',
        // },
        // {
        //     time: '2022-06-01',
        //     day: '01 Jun 2022',
        //     month: 'Jun 2022',
        //     year: '2022',
        //     revenue: '784617',
        //     revenueForecastValue: '784617',
        //     revenueForecastMinValue: '625624',
        //     revenueForecastMaxValue: '819825',
        //     atc3: '1216244',
        //     atc3ForecastValue: '1216244',
        //     atc3ForecastMinValue: '1303613',
        //     atc3ForecastMaxValue: '1345300',
        //     atc4: '1352741',
        //     atc4ForecastValue: '1352741',
        //     atc4ForecastMinValue: '1625363',
        //     atc4ForecastMaxValue: '1709620',
        // },
        // {
        //     time: '2022-07-01',
        //     day: '01 Jul 2022',
        //     month: 'Jul 2022',
        //     year: '2022',
        //     revenue: '866431',
        //     revenueForecastValue: '866431',
        //     revenueForecastMinValue: '527860',
        //     revenueForecastMaxValue: '782024',
        //     atc3: '1442867',
        //     atc3ForecastValue: '1442867',
        //     atc3ForecastMinValue: '1246690',
        //     atc3ForecastMaxValue: '1238871',
        //     atc4: '1310062',
        //     atc4ForecastValue: '1310062',
        //     atc4ForecastMinValue: '1354437',
        //     atc4ForecastMaxValue: '1550448',
        // },
        // {
        //     time: '2022-08-01',
        //     day: '01 Ago 2022',
        //     month: 'Aug 2022',
        //     year: '2022',
        //     revenue: '511369',
        //     revenueForecastValue: '511369',
        //     revenueForecastMinValue: '652162',
        //     revenueForecastMaxValue: '515671',
        //     atc3: '1311914',
        //     atc3ForecastValue: '1311914',
        //     atc3ForecastMinValue: '1486695',
        //     atc3ForecastMaxValue: '1243721',
        //     atc4: '1445025',
        //     atc4ForecastValue: '1445025',
        //     atc4ForecastMinValue: '1335432',
        //     atc4ForecastMaxValue: '1503894',
        // },
        // {
        //     time: '2022-09-01',
        //     day: '01 Set 2022',
        //     month: 'Sep 2022',
        //     year: '2022',
        //     revenue: '536850',
        //     revenueForecastValue: '536850',
        //     revenueForecastMinValue: '697743',
        //     revenueForecastMaxValue: '888553',
        //     atc3: '1376797',
        //     atc3ForecastValue: '1376797',
        //     atc3ForecastMinValue: '1479931',
        //     atc3ForecastMaxValue: '1431903',
        //     atc4: '1463895',
        //     atc4ForecastValue: '1463895',
        //     atc4ForecastMinValue: '1430983',
        //     atc4ForecastMaxValue: '1461468',
        // },
        // {
        //     time: '2022-10-01',
        //     day: '01 Out 2022',
        //     month: 'Oct 2022',
        //     year: '2022',
        //     revenue: '593287',
        //     revenueForecastValue: '593287',
        //     revenueForecastMinValue: '529812',
        //     revenueForecastMaxValue: '816568',
        //     atc3: '1417157',
        //     atc3ForecastValue: '1417157',
        //     atc3ForecastMinValue: '1332692',
        //     atc3ForecastMaxValue: '1361032',
        //     atc4: '1366289',
        //     atc4ForecastValue: '1366289',
        //     atc4ForecastMinValue: '1401412',
        //     atc4ForecastMaxValue: '1749527',
        // },
        // {
        //     time: '2022-11-01',
        //     day: '01 Nov 2022',
        //     month: 'Nov 2022',
        //     year: '2022',
        //     revenue: '784865',
        //     revenueForecastValue: '784865',
        //     revenueForecastMinValue: '700405',
        //     revenueForecastMaxValue: '773700',
        //     atc3: '1344664',
        //     atc3ForecastValue: '1344664',
        //     atc3ForecastMinValue: '1316616',
        //     atc3ForecastMaxValue: '1309605',
        //     atc4: '1325946',
        //     atc4ForecastValue: '1325946',
        //     atc4ForecastMinValue: '1622637',
        //     atc4ForecastMaxValue: '1435239',
        // },
        // {
        //     time: '2022-12-01',
        //     day: '01 Dez 2022',
        //     month: 'Dec 2022',
        //     year: '2022',
        //     revenue: '667712',
        //     revenueForecastValue: '667712',
        //     revenueForecastMinValue: '861196',
        //     revenueForecastMaxValue: '857683',
        //     atc3: '1213325',
        //     atc3ForecastValue: '1213325',
        //     atc3ForecastMinValue: '1237929',
        //     atc3ForecastMaxValue: '1324660',
        //     atc4: '1547689',
        //     atc4ForecastValue: '1547689',
        //     atc4ForecastMinValue: '1751973',
        //     atc4ForecastMaxValue: '1411365',
        // },
        // {
        //     time: '2023-01-01',
        //     day: '01 Jan 2023',
        //     month: 'Jan 2023',
        //     year: '2023',
        //     revenue: '603926',
        //     revenueForecastValue: '603926',
        //     revenueForecastMinValue: '553220',
        //     revenueForecastMaxValue: '710878',
        //     atc3: '1468713',
        //     atc3ForecastValue: '1468713',
        //     atc3ForecastMinValue: '1289992',
        //     atc3ForecastMaxValue: '1383525',
        //     atc4: '1624281',
        //     atc4ForecastValue: '1624281',
        //     atc4ForecastMinValue: '1595072',
        //     atc4ForecastMaxValue: '1566631',
        // },
        // {
        //     time: '2023-02-01',
        //     day: '01 Fev 2023',
        //     month: 'Feb 2023',
        //     year: '2023',
        //     revenue: '842098',
        //     revenueForecastValue: '842098',
        //     revenueForecastMinValue: '840207',
        //     revenueForecastMaxValue: '768037',
        //     atc3: '1256017',
        //     atc3ForecastValue: '1256017',
        //     atc3ForecastMinValue: '1272692',
        //     atc3ForecastMaxValue: '1255405',
        //     atc4: '1544700',
        //     atc4ForecastValue: '1544700',
        //     atc4ForecastMinValue: '1528562',
        //     atc4ForecastMaxValue: '1387260',
        // },
        // {
        //     time: '2023-03-01',
        //     day: '01 Mar 2023',
        //     month: 'Mar 2023',
        //     year: '2023',
        //     revenue: '698905',
        //     revenueForecastValue: '698905',
        //     revenueForecastMinValue: '861849',
        //     revenueForecastMaxValue: '787842',
        //     atc3: '1354890',
        //     atc3ForecastValue: '1354890',
        //     atc3ForecastMinValue: '1350912',
        //     atc3ForecastMaxValue: '1447064',
        //     atc4: '1366764',
        //     atc4ForecastValue: '1366764',
        //     atc4ForecastMinValue: '1631422',
        //     atc4ForecastMaxValue: '1436460',
        // },
        // {
        //     time: '2023-04-01',
        //     day: '01 Abr 2023',
        //     month: 'Apr 2023',
        //     year: '2023',
        //     revenue: '696576',
        //     revenueForecastValue: '696576',
        //     revenueForecastMinValue: '567635',
        //     revenueForecastMaxValue: '653052',
        //     atc3: '1285487',
        //     atc3ForecastValue: '1285487',
        //     atc3ForecastMinValue: '1308880',
        //     atc3ForecastMaxValue: '1432948',
        //     atc4: '1464052',
        //     atc4ForecastValue: '1464052',
        //     atc4ForecastMinValue: '1407480',
        //     atc4ForecastMaxValue: '1408785',
        // },
        // {
        //     time: '2023-05-01',
        //     day: '01 Maio 2023',
        //     month: 'May 2023',
        //     year: '2023',
        //     revenue: '717731',
        //     revenueForecastValue: '717731',
        //     revenueForecastMinValue: '794070',
        //     revenueForecastMaxValue: '711634',
        //     atc3: '1246782',
        //     atc3ForecastValue: '1246782',
        //     atc3ForecastMinValue: '1474377',
        //     atc3ForecastMaxValue: '1452759',
        //     atc4: '1541549',
        //     atc4ForecastValue: '1541549',
        //     atc4ForecastMinValue: '1477510',
        //     atc4ForecastMaxValue: '1654217',
        // },
        // {
        //     time: '2023-06-01',
        //     day: '01 Jun 2023',
        //     month: 'Jun 2023',
        //     year: '2023',
        //     revenue: undefined,
        //     revenueForecastValue: '827680',
        //     revenueForecastMinValue: '540314',
        //     revenueForecastMaxValue: '876983',
        //     atc3: undefined,
        //     atc3ForecastValue: '1423055',
        //     atc3ForecastMinValue: '1317314',
        //     atc4: undefined,
        //     atc4ForecastValue: '1423055',
        //     atc4ForecastMinValue: '1317314',
        // },
    ],
    dateGrouper: 'month',
    gradient: 0.2,
    lineType: 'monotone',
    maxValue: 10000,
    minValue: 0,
    noForecast: false,
    timeAttr: 'month',
    laguange: 'pt',
    tooltipType: 'evolByProductForecastChart',
    unitsOrValues: 'values',
    margin: {
        top: 20,
        right: 40,
        left: 0,
        bottom: 0
    },
    interactionDisabled: false,
    suffix: 'evolByProductForecastChartAuxbar',
    settings: {
        reference: {
            current: null
        },
        open: false,
        view: {
            label: 'VIEW',
            options: [
                {
                    name: 'showWeather',
                    label: 'Show Weather',
                    icon: 'default',
                    iconDefault:
                        '/static/media/ico-checkbox-empty.23dcfc1880cefd2aab779978d71d5163.svg',
                    iconSelected:
                        '/static/media/ico-checkbox-selected.67e79fb847afdca6479eaafcf8a9ce3f.svg',
                    translated: true
                }
            ]
        },
        options: {
            label: 'OPTIONS',
            options: []
        }
    },
    ready: true,
    legend: []
};
