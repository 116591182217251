import React from 'react';
import { dateSort } from '../../../constants/sort';
import { generateRandomData } from '../../../utils/mock';

// Example usage:
const startDate = new Date('2023-01-12');
const endDate = new Date('2023-01-25');

const randomData = generateRandomData(startDate, endDate);

export const activityListMock = {
    titleCounter: '0',
    sortedData: [],
    data: [...randomData],
    columns: [
        { path: 'uuid', label: '', sortable: true },
        { path: 'username', label: '', sortable: true },
        {
            path: 'role',
            label: '',
            sortable: true
        },
        {
            path: 'date',
            label: '',
            sortable: true,
            customSort: (props) => dateSort(props, 'isoDate')
        },
        { path: 'userAction', label: '', sortable: true },
        { path: 'entity', label: '', sortable: true }
    ],
    sortColumn: { path: 'date', order: 'desc' },
    currentPage: 1,
    pageSize: 10,
    type: 'type-1',
    attr: 'activityTable',
    disabled: true,
    loading: false,
    settings: {
        open: false,
        selected: {
            total: 0,
            options: []
        },
        view: {
            options: []
        },
        options: {
            label: '',
            options: []
        },
        reference: React.createRef()
    }
};
