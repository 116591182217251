import React from 'react'
import { Separator, TooltipContainer, TooltipContentRow, TooltipHeaderRow } from '../../../..'
import { useCustomTranslation } from '../../../../../hooks/useCustomTranslation'
import { useCursorTrackingProvider } from '../../../../../providers/cursor-tracking-context'

export const ClientTooltip = React.memo((props) => {
    const { t } = useCustomTranslation()
    return (
        <div>
            <TooltipContainer color={props.color}>
                <TooltipHeaderRow title={t('tooltips:header.identifier')} value={props.loc.regionId} />
                <Separator />
                <TooltipContentRow title={t('tooltips:content.name')} value={props.loc.name || '-'} />
                <TooltipContentRow
                    title={t('tooltips:content.defaultStatus')}
                    value={props.loc.defaultStatusValue || '-'}
                    color={props.loc.defaultStatusColor}
                />
                <TooltipContentRow
                    title={t('tooltips:content.riskStatus')}
                    value={props.loc.riskStatusValue || '--'}
                    color={props.loc.riskStatusColor}
                />
            </TooltipContainer>
        </div>
    )
})
