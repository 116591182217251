import React from 'react';
import LoaderArc from '../LoaderArc';
import styles from './Loader.module.scss';

export const Loader = React.memo(({ inverted, maxSize, speed }) => {
    const arcs = [
        {
            rotate: 90,
            translateX: -55,
            translateY: 33,
            colors: [
                ['#2065b0', '#2168b3'],
                ['#1f5ca8', '#2168b3'],
                ['#244f9d', '#2156a4'],
                ['#2a4091', '#254c9b'],
                ['#2c3b8c', '#264a99'],
                ['#2a4091', '#254d9c'],
                ['#244e9d', '#2155a3'],
                ['#1f5ca8', '#1e5fac'],
                ['#2065b0', '#2168b3']
            ],
            delay: 5200 / speed
        },
        {
            rotate: 180,
            translateX: -39,
            translateY: 56,
            colors: [
                ['#3093d7', '#3196da'],
                ['#2f8fd4', '#3094d8'],
                ['#2b86cc', '#2e8ed4'],
                ['#2675be', '#2a82c9'],
                ['#1e5fac', '#236fb9'],
                ['#244e9d', '#2059a6'],
                ['#294191', '#264998'],
                ['#2b3a8c', '#2a4090'],
                ['#2d388a', '#2b3c8d']
            ],
            delay: 0
        },
        {
            rotate: 270,
            translateX: -16,
            translateY: 39,
            colors: [
                ['#297ec6', '#2a81c8'],
                ['#2b86cd', '#2d8ad0'],
                ['#2f90d5', '#3298dd'],
                ['#3298dd', '#36a4e6'],
                ['#339ce0', '#38abed'],
                ['#3299dd', '#36a5e7'],
                ['#3092d7', '#3197db'],
                ['#2b86cd', '#2d8ad0'],
                ['#297ec6', '#2a81c8']
            ],
            delay: 1740 / speed
        },
        {
            rotate: 360,
            translateX: -28,
            translateY: 20,
            colors: [
                ['#24509f', '#2253a1'],
                ['#2352a0', '#2157a5'],
                ['#2158a5', '#1e5fac'],
                ['#2064af', '#2471bb'],
                ['#2778c0', '#2c87cd'],
                ['#2d8cd2', '#3298dd'],
                ['#339ce0', '#37a6e8'],
                ['#37a6e9', '#38abeb'],
                ['#38abeb', '#39adef']
            ],
            delay: 3470 / speed
        }
    ];

    return (
        <div className={`${styles['loader-container']}`}>
            {arcs.map((arc, index) => (
                <div
                    key={index}
                    className={`${styles['loader-container__arc']}`}
                    style={{
                        transform: `rotate(${arc.rotate}deg) translate(${arc.translateX}px, ${arc.translateY}px)`
                    }}
                >
                    <LoaderArc
                        speed={speed}
                        colors={arc.colors}
                        inverted={inverted}
                        maxSize={maxSize}
                        delay={arc.delay}
                        waitFor={0 / speed}
                    />
                </div>
            ))}
        </div>
    );
});
