/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HomePageSchema } from '../models/HomePageSchema';
import type { PagesSchema } from '../models/PagesSchema';
import type { StatusResponseSchema } from '../models/StatusResponseSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PagesService {
    /**
     * gets all available pages with content and url
     * @returns PagesSchema call successful
     * @throws ApiError
     */
    public static getApiPages(): CancelablePromise<Array<PagesSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pages',
        });
    }
    /**
     * Creates new Page,
     * @returns PagesSchema call successful
     * @throws ApiError
     */
    public static postApiPages({
        requestBody,
    }: {
        requestBody: PagesSchema,
    }): CancelablePromise<PagesSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * current user is allowed to create pages
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPagesCanCreate(): CancelablePromise<{
        allowed?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pages/can-create',
        });
    }
    /**
     * updates homepage
     * @returns HomePageSchema call successful
     * @throws ApiError
     */
    public static putApiPagesHomepage({
        requestBody,
    }: {
        requestBody: {
            content?: string;
        },
    }): CancelablePromise<HomePageSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/pages/homepage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * gets available pages for current user (token based)
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPagesMyPages(): CancelablePromise<{
        homepage?: HomePageSchema;
        pages?: Array<PagesSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pages/my-pages',
        });
    }
    /**
     * gets all available pages unique names and corresponding icons
     * @returns any call successful
     * @throws ApiError
     */
    public static getApiPagesNames(): CancelablePromise<Array<{
        icon?: string;
        name?: string;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pages/names',
        });
    }
    /**
     * gets components for new page, according to que natural language query. based on the starkbot/components_data/components.txt
     * @returns string call successful
     * @throws ApiError
     */
    public static postApiPagesNlGeneratePage({
        requestBody,
    }: {
        requestBody: {
            query?: string;
        },
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pages/nl-generate-page',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deletes page by unique url
     * @returns StatusResponseSchema call successful
     * @throws ApiError
     */
    public static deleteApiPages({
        url,
    }: {
        /**
         * unique url of page
         */
        url: string,
    }): CancelablePromise<StatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/pages/{url}',
            path: {
                'url': url,
            },
        });
    }
    /**
     * gets Page by unique url
     * @returns PagesSchema call successful
     * @throws ApiError
     */
    public static getApiPages1({
        url,
    }: {
        /**
         * unique url of page
         */
        url: string,
    }): CancelablePromise<PagesSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pages/{url}',
            path: {
                'url': url,
            },
        });
    }
    /**
     * updates page, except draft status
     * @returns PagesSchema call successful
     * @throws ApiError
     */
    public static putApiPages({
        url,
        requestBody,
    }: {
        /**
         * url to link content and name
         */
        url: string,
        requestBody: {
            content?: string;
            name?: string;
        },
    }): CancelablePromise<PagesSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/pages/{url}',
            path: {
                'url': url,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * chages draft status to false
     * @returns PagesSchema call successful
     * @throws ApiError
     */
    public static postApiPagesPublish({
        url,
    }: {
        /**
         * url to link content and name
         */
        url: string,
    }): CancelablePromise<PagesSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pages/{url}/publish',
            path: {
                'url': url,
            },
        });
    }
}
