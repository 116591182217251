import React from 'react'
import PropTypes from 'prop-types'
import styles from './button.module.scss'

export const Button = React.memo(
    ({ color, text, disabled, icon, onClick, id, variableHeight }) => {
        let justifyContentStyle = 'justify-content-center'
        if (icon) {
            justifyContentStyle = 'justify-space-between'
        }
        return (
            <div
                id={id ? id : undefined}
                className={
                    !disabled
                        ? `${styles['button']} ${
                              styles[`button--${color}`]
                          } ${justifyContentStyle}`
                        : `${styles['button']} ${styles[`button--${color}`]} ${
                              styles['disabled']
                          } ${justifyContentStyle}`
                }
                style={
                    variableHeight
                        ? {
                              height: '100%',
                              minHeight: '3rem',
                          }
                        : {}
                }
                onClick={onClick ? () => onClick() : undefined}
            >
                {icon && (
                    <img src={icon} className={`${styles['button__icon']}`} />
                )}
                {text && (
                    <span className={`${styles['button__text']}`}>{text}</span>
                )}
            </div>
        )
    }
)

Button.propTypes = {
    /**
     * Color of button. Should be either 'grey' | 'yellow' | 'green' | 'blue' | 'blue-outline'
     * */
    color: PropTypes.string.isRequired,
    /**
     * Text to display in button
     */
    text: PropTypes.string.isRequired,
    /**
     * Disables button if set to true
     */
    disabled: PropTypes.bool,

    /**
     * Function to fire when clicking button
     */
    onClick: PropTypes.func,
}
