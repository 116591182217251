import React from 'react';
import styles from './action-bar.module.scss';

export const ActionBar = ({ actions }) => {
    return (
        <div className={styles.ActionBar}>
            <div className="d-flex w-100 h-100">
                {actions.map((action, index) => {
                    return (
                        <div
                            key={index}
                            className={styles.ActionBar_action}
                            onClick={action.onClick}
                            style={{
                                marginRight:
                                    index !== actions.length - 1 ? '3rem' : '0',
                                cursor: action.onClick ? 'pointer' : 'default'
                            }}
                        >
                            <img
                                src={action.icon}
                                className={styles.ActionBar_actionIcon}
                            />
                            <span className={styles.ActionBar_actionText}>
                                {action.text}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
