import styles from './sentiment-demo.module.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../../buttons/primary-button/primary-button';
import { TypeInput } from '../../inputs/type-input/type-input';
import { Spinner } from '../../loading/spinner/spinner';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';
import { LoaderForeground } from '../../loading/LoaderForeground/LoaderForeground';
import creationOptionBackground from '../../../assets/images/creationOptionBackground.png';

/**
 * Displays the sentiment confidence, values for positive, neutral, and negative sentiment,
 * and the keywords for a custom input text
 */

export const SentimentDemo = React.memo(
    ({
        document,
        loading,
        sentimentPositive,
        sentimentNegative,
        sentimentNeutral,
        keywords,
        onChange,
        onClassifyText
    }) => {
        const { t } = useCustomTranslation();
        const [attr] = useState('sentimentDemo');

        return (
            <div
                className={`row mx-0 w-100 ${styles['sentiment-demo-container']}`}
            >
                <div
                    className={`col-12 col-md-5 me-0 me-md-2 mb-2 mb-md-0 ${styles['sentiment-demo-container__document-wrapper']} d-flex flex-column`}
                >
                    {/* <div
                        className="row mx-0 w-100"
                        style={{ marginBottom: '2rem' }}
                    >
                        <div
                            className={`col px-0 ${styles['sentiment-demo-container__document-wrapper__title-container']}`}
                        >
                            <span
                                className={`${styles['sentiment-demo-container__document-wrapper__title-container__title']}`}
                            >
                                {t(`components:${attr}.testyourtext`)}
                            </span>
                        </div>
                    </div> */}
                    <div
                        className="row mx-0 w-100 flex-grow-1 "
                        style={{ marginBottom: '4rem' }}
                    >
                        <div
                            className={`col ${styles['sentiment-demo-container__document-wrapper__content']}`}
                        >
                            <TypeInput
                                inputType={'container'}
                                newLabelType
                                onChangeInput={onChange}
                                {...document}
                            />
                        </div>
                    </div>
                    <div className="row mx-0 d-flex justify-content-end align-items-center">
                        <div
                            className={`col-auto ${styles['sentiment-demo-container__document-wrapper__button-container']}`}
                        >
                            <PrimaryButton
                                disabled={loading}
                                text={t(`components:${attr}.classifytext`)}
                                onClick={
                                    onClassifyText ? onClassifyText : undefined
                                }
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`col-12 col-md ms-0 ms-md-2 ${styles['sentiment-demo-container__results-wrapper']}`}
                >
                    {loading && (
                        <div
                            className={`row mx-0 w-100 ${styles['sentiment-demo-container__results-wrapper__loading-container']}`}
                        >
                            <LoaderForeground />
                        </div>
                    )}
                    <React.Fragment>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div
                                className={`col px-0 ${styles['sentiment-demo-container__results-wrapper__title-container']}`}
                            >
                                <span
                                    className={`col px-0 ${styles['sentiment-demo-container__results-wrapper__title-container__title']}`}
                                >
                                    {t(`components:${attr}.results`)}
                                </span>
                            </div>
                        </div>
                        <div
                            className="row mx-0 w-100"
                            style={{ marginBottom: '2rem' }}
                        >
                            <div
                                className={`col ${styles['sentiment-demo-container__results-wrapper__content']}`}
                            >
                                <div
                                    className="row mx-0 w-100 d-flex justify-content-between align-items-center"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']}`}
                                        >
                                            {t(`components:${attr}.sentiment`)}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']}`}
                                        >
                                            {t(`components:${attr}.confidence`)}{' '}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 mb-2 d-flex justify-content-between align-items-center sentiment-demo-container__results-wrapper__sentiment-wrapper">
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['positive']}`}
                                        >
                                            {t(`components:${attr}.positive`)}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['positive']}`}
                                        >
                                            {sentimentPositive} %
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 mb-2 d-flex justify-content-between align-items-center sentiment-demo-container__results-wrapper__sentiment-wrapper">
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['neutral']}`}
                                        >
                                            {t(`components:${attr}.neutral`)}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['neutral']}`}
                                        >
                                            {sentimentNeutral} %
                                        </span>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 mb-2 d-flex justify-content-between align-items-center sentiment-demo-container__results-wrapper__sentiment-wrapper">
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['negative']}`}
                                        >
                                            {t(`components:${attr}.negative`)}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['negative']}`}
                                        >
                                            {sentimentNegative} %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 w-100 flex-grow-1">
                            <div
                                className={`col px-0 ${styles['sentiment-demo-container__results-wrapper__keywords-container']}`}
                            >
                                <div
                                    className="row mx-0 w-100"
                                    style={{ marginBottom: '2rem' }}
                                >
                                    <div className="col-auto px-0">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']} ${styles['count']}`}
                                        >
                                            {keywords.length
                                                ? keywords.length
                                                : '0'}
                                        </span>
                                    </div>
                                    <div className="col-auto px-0 ms-2">
                                        <span
                                            className={`${styles['sentiment-demo-container__results-wrapper__sub-title']}`}
                                        >
                                            {t(`components:${attr}.keywords`)}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className={`row mx-0 w-100 flex-grow-1 ${styles['sentiment-demo-container__results-wrapper__keywords-wrapper']}`}
                                >
                                    <div className="col px-0">
                                        <div className="row mx-0 w-100 h-auto">
                                            {keywords.map((el, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`col-auto h-auto ${
                                                            styles[
                                                                'sentiment-demo-container__results-wrapper__keywords-wrapper__item'
                                                            ]
                                                        } ${
                                                            styles[el.sentiment]
                                                        }`}
                                                    >
                                                        <span>{el.value}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        );
    }
);

SentimentDemo.propTypes = {
    /**
     * Contains the input functionality
     */
    document: PropTypes.object,
    /**
     * Holds the loading status
     */
    loading: PropTypes.bool,
    /**
     * Labels the positive sentiment
     */
    sentimentPositive: PropTypes.number,
    /**
     * Labels the negative sentiment
     */
    sentimentNegative: PropTypes.number,
    /**
     * Labels the neutral sentiment
     */
    sentimentNeutral: PropTypes.number,
    /**
     * Contains all keywords and their respective sentiment
     */
    keywords: PropTypes.arrayOf(PropTypes.object),
    /**
     * Propagates changes to document input
     */
    onChange: PropTypes.func,
    /**
     * Propagates request for classification of text in document object input
     */
    onClassifyText: PropTypes.func,
    /**
     * Labels the component with the text area
     */
    testYourTextTitle: PropTypes.string,
    /**
     * Labels the button that allows for classifying the text
     */
    classifyTextLabel: PropTypes.string,
    /**
     * Labels the results component
     */
    resultsTitle: PropTypes.string,
    /**
     * Labels overall sentiment
     */
    sentimentLabel: PropTypes.string,
    /**
     * Labels confidence of sentiment
     */
    confidenceLabel: PropTypes.string,
    /**
     * Labels positive sentiment
     */
    positiveTitle: PropTypes.string,
    /**
     * Labels neutral sentiment
     */
    neutralTitle: PropTypes.string,
    /**
     * Labels negative sentiment
     */
    negativeTitle: PropTypes.string,
    /**
     * Labels component holding keywords of results
     */
    keywordsTitle: PropTypes.string
};
