import React, {
    useState,
    useRef,
    useCallback,
    useMemo,
    useEffect
} from 'react';
import styles from './custom-brush.module.scss';
import { useTranslation } from 'react-i18next';
import {
    getDayFromDateFormat,
    getMonthFromDateFormat
} from '../../../utils/date';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

export const CustomBrush = React.memo(
    ({
        timeInterval,
        initialMinDate,
        initialMaxDate,
        currentMinDate,
        currentMaxDate,
        onSetCurrentMinDate,
        onSetCurrentMaxDate
    }) => {
        const { t } = useCustomTranslation();
        const [activeBarWidth, setActiveBarWidth] = useState(0);
        const [currentActiveBar, setCurrentActiveBar] = useState(null);
        const dateToNumber = (date) => {
            return Math.floor(new Date(date).getTime() / (1000 * 3600 * 24));
        };
        const [containerWidth, setContainerWidth] = useState(0);
        useEffect(() => {
            const brushContainer = brushRef.current;
            if (brushContainer) {
                const resizeObserver = new ResizeObserver((entries) => {
                    for (let entry of entries) {
                        setContainerWidth(entry.contentRect.width);
                    }
                });
                resizeObserver.observe(brushContainer);
                return () => resizeObserver.disconnect();
            }
        }, []);

        const updateActiveBarWidth = useCallback(() => {
            if (activeBrushRef.current) {
                setActiveBarWidth(
                    activeBrushRef.current.getBoundingClientRect().width
                );
            }
        }, []);

        useEffect(() => {
            updateActiveBarWidth();
        }, [updateActiveBarWidth]);
        const renderLabelsInARow = useCallback(
            ({ initialMinDate, initialMaxDate }) => {
                const calculatePercentage = (
                    date,
                    initialMinDate,
                    initialMaxDate
                ) => {
                    const numDate = dateToNumber(date);
                    const initialMinNum = dateToNumber(initialMinDate);
                    const initialMaxNum = dateToNumber(initialMaxDate);
                    return (
                        ((numDate - initialMinNum) /
                            (initialMaxNum - initialMinNum)) *
                        100
                    );
                };

                const renderLabels = () => {
                    const labels = [];
                    const initialMinNum = dateToNumber(initialMinDate);
                    const initialMaxNum = dateToNumber(initialMaxDate);

                    // Calculate the total number of months between initialMinDate and initialMaxDate
                    let total = (initialMaxNum - initialMinNum) / 90;

                    const labelWidth = 100; // This is an example width for each label. Adjust as needed.
                    const maxLabels = Math.floor(containerWidth / labelWidth);
                    const skipInterval = Math.ceil(total / maxLabels);

                    // Calculate the step value for each iteration, considering every second month
                    const step = (initialMaxNum - initialMinNum) / total;

                    for (let i = 1; i <= total; i += skipInterval) {
                        const currentDate = numberToDate(
                            Math.round(initialMinNum + i * step)
                        );

                        const percentage = calculatePercentage(
                            currentDate,
                            initialMinDate,
                            initialMaxDate
                        );

                        const dayLabel = new Intl.DateTimeFormat('en', {
                            day: '2-digit'
                        }).format(new Date(currentDate));

                        const monthLabel = new Intl.DateTimeFormat('en', {
                            month: 'short'
                        }).format(new Date(currentDate));
                        const yearLabel = new Intl.DateTimeFormat('en', {
                            year: 'numeric'
                        }).format(new Date(currentDate));
                        labels.push(
                            <div
                                key={i}
                                className={
                                    styles[
                                        'custom-brush-container__labels__label'
                                    ]
                                }
                                style={{
                                    left: `${percentage}%`,
                                    transform: `translateX(-100%)`
                                }}
                            >
                                {timeInterval === 'day' && (
                                    <>
                                        <span>{dayLabel}</span>
                                        <span> </span>
                                    </>
                                )}
                                <span>{monthLabel}</span>
                                <span> </span>
                                <span>{yearLabel}</span>
                            </div>
                        );
                    }
                    return labels;
                };

                return (
                    <div
                        className={
                            styles[`${'custom-brush-container__labels'}`]
                        }
                    >
                        {renderLabels()}
                    </div>
                );
            },
            [timeInterval, containerWidth]
        );
        const dragOffset = useRef(0);

        const initialMinNum = dateToNumber(initialMinDate);
        const initialMaxNum = dateToNumber(initialMaxDate);

        const dragSide = useRef(null); // 'left' for minDate, 'right' for maxDate

        const numberToDate = (value) => {
            return new Date(value * 1000 * 3600 * 24)
                .toISOString()
                .split('T')[0];
        };

        const brushRef = useRef(null);
        const activeBrushRef = useRef(null);
        const isDragging = useRef(false);
        const dragType = useRef(null); // 'left', 'right' for stretching, 'move' for moving

        const updateDate = (percentage) => {
            // const range =
            //     dateToNumber(currentMaxDate) - dateToNumber(currentMinDate)
            if (dragSide.current === 'left') {
                let newMinNum = Math.round(
                    (initialMaxNum - initialMinNum) * (percentage / 100) +
                        initialMinNum
                );
                // Clamping the newMinNum to not exceed the current maxDate
                newMinNum = Math.min(
                    Math.max(newMinNum, dateToNumber(initialMinDate)),
                    dateToNumber(initialMaxDate)
                );
                onSetCurrentMinDate(numberToDate(newMinNum));
            } else if (dragSide.current === 'right') {
                let newMaxNum = Math.round(
                    (initialMaxNum - initialMinNum) * (percentage / 100) +
                        initialMinNum
                );
                // Clamping the newMaxNum to not go below the current minDate
                newMaxNum = Math.max(
                    Math.min(newMaxNum, dateToNumber(initialMaxDate)),
                    dateToNumber(initialMinDate)
                );
                onSetCurrentMaxDate(numberToDate(newMaxNum));
            }
        };

        const updateDateForMove = (percentage) => {
            const range =
                dateToNumber(currentMaxDate) - dateToNumber(currentMinDate);
            const totalRange = initialMaxNum - initialMinNum;
            let moveDistance =
                (totalRange * percentage) / 100 - range * dragOffset.current;

            let newMinNum = initialMinNum + moveDistance;
            let newMaxNum = newMinNum + range;

            // Clamping values within initial range
            if (newMinNum < initialMinNum) {
                newMinNum = initialMinNum;
                newMaxNum = newMinNum + range;
            }
            if (newMaxNum > initialMaxNum) {
                newMaxNum = initialMaxNum;
                newMinNum = newMaxNum - range;
            }

            onSetCurrentMinDate(numberToDate(newMinNum));
            onSetCurrentMaxDate(numberToDate(newMaxNum));
        };

        const handleMouseMove = (event) => {
            if (!isDragging.current || !brushRef.current) return;

            const { left, width } = brushRef.current.getBoundingClientRect();
            const x = event.clientX - left;
            const percentage = (x / width) * 100;

            if (dragType.current === 'move') {
                updateDateForMove(percentage);
            } else {
                // Ensure handles do not overlap
                if (dragSide.current === 'left') {
                    const maxPercentage = calculatePercentage(currentMaxDate);
                    if (
                        percentage < maxPercentage &&
                        maxPercentage - percentage > 10
                    ) {
                        updateDate(percentage);
                    }
                } else if (dragSide.current === 'right') {
                    const minPercentage = calculatePercentage(currentMinDate);
                    if (
                        percentage > minPercentage &&
                        percentage - minPercentage > 10
                    ) {
                        updateDate(percentage);
                    }
                }
            }
            updateActiveBarWidth();
        };

        const handleMouseDown = (event, side) => {
            event.preventDefault();
            isDragging.current = true;
            dragSide.current = side;
            dragType.current = 'stretch';
            setCurrentActiveBar(side);
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        };

        const handleMouseUp = () => {
            isDragging.current = false;
            setCurrentActiveBar(null);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        const handleBarMouseDown = (event) => {
            if (isDragging.current) return;
            event.preventDefault();
            isDragging.current = true;
            dragType.current = 'move';

            const barRect = activeBrushRef.current.getBoundingClientRect();
            dragOffset.current = (event.clientX - barRect.left) / barRect.width; // Offset as a percentage of the bar width

            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        };

        const calculatePercentage = (date) => {
            const numDate = dateToNumber(date);
            return (
                ((numDate - initialMinNum) / (initialMaxNum - initialMinNum)) *
                100
            );
        };

        const activeBarStyle = {
            left: `${calculatePercentage(currentMinDate)}%`,
            right: `${100 - calculatePercentage(currentMaxDate)}%`
        };

        const leftHandleStyle = {
            left: `${1 + calculatePercentage(currentMinDate)}%`
        };

        const rightHandleStyle = {
            right: `${100 + 1 - calculatePercentage(currentMaxDate)}%`
        };

        const shouldShowText = useMemo(() => {
            return activeBarWidth > 200 || activeBarWidth === 0;
        }, [activeBarWidth]);

        return (
            <div
                className={`${styles['custom-brush-container']}`}
                ref={brushRef}
            >
                {renderLabelsInARow({
                    initialMinDate: initialMinDate,
                    initialMaxDate: initialMaxDate
                })}
                <div
                    className={`${styles['custom-brush-container__active-bar']}`}
                    style={activeBarStyle}
                    ref={activeBrushRef}
                    onMouseDown={handleBarMouseDown}
                ></div>
                {currentActiveBar === 'left' && (
                    <div
                        className={
                            styles[
                                'custom-brush-container__active-bar__left-dot'
                            ]
                        }
                        style={leftHandleStyle}
                    ></div>
                )}
                {currentActiveBar === 'right' && (
                    <div
                        className={
                            styles[
                                'custom-brush-container__active-bar__right-dot'
                            ]
                        }
                        style={rightHandleStyle}
                    ></div>
                )}
                <div
                    className={
                        currentActiveBar === 'left'
                            ? `${styles['custom-brush-container__active-bar__left-handle']} ${styles['active']}`
                            : `${styles['custom-brush-container__active-bar__left-handle']}`
                    }
                    style={leftHandleStyle}
                    onMouseDown={(e) => handleMouseDown(e, 'left')}
                >
                    {shouldShowText && (
                        <span
                            className={`${styles['custom-brush-container__active-bar__text']}`}
                            style={leftHandleStyle}
                        >
                            {timeInterval === 'day' &&
                                getDayFromDateFormat(currentMinDate, t)}
                            {timeInterval !== 'day' &&
                                getMonthFromDateFormat(currentMinDate, t)}
                        </span>
                    )}
                </div>
                <div
                    className={
                        currentActiveBar === 'right'
                            ? `${styles['custom-brush-container__active-bar__right-handle']} ${styles['active']}`
                            : `${styles['custom-brush-container__active-bar__right-handle']}`
                    }
                    style={rightHandleStyle}
                    onMouseDown={(e) => handleMouseDown(e, 'right')}
                >
                    {shouldShowText && (
                        <span
                            className={`${styles['custom-brush-container__active-bar__text']}`}
                            style={rightHandleStyle}
                        >
                            {timeInterval === 'day' &&
                                getDayFromDateFormat(currentMaxDate, t)}
                            {timeInterval !== 'day' &&
                                getMonthFromDateFormat(currentMaxDate, t)}
                        </span>
                    )}
                </div>
            </div>
        );
    }
);
