import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import config from './configs/apiConfig'
import { errorHandler } from './utils/api'
import { useArray } from './hooks/useArray'
import { useLocalStorage } from './hooks/useStorage'
import { CurrentAppProvider } from './providers/current-app-context'
import { CurrentUserProvider } from './providers/current-user-context'
import { NotificationsProvider } from './providers/notifications-context'
import { ThemeProvider } from './providers/theme-context'
import { getCurrentUser, getJwt } from './utils/authService'
import { ModelAliasService, NotificationsService, OpenAPI } from './temp/test'
import styles from './App.module.scss'
import _ from 'lodash'
import { formatDateTime } from './utils/date'
import { ErrorFallback } from './layout/error-fallback'
import LoggedOutRoutes from './routes/logged-out-routes/logged-out-routes'
import LoggedInRoutes from './routes/logged-in-routes/logged-in-routes'
import { notification } from './components/notifications/notification/notification'
import { saveToLS } from './utils/localStorage'
import { useCustomTranslation } from './hooks/useCustomTranslation'
import { CursorTrackingProvider } from './providers/cursor-tracking-context'
import { PlatformFormatProvider } from './providers/platform-format-context'

let url = window.location
let params = new URLSearchParams(url.search)
let token = params.has('token') ? params.get('token') : ''
let serviceUrl = config.service.BASE_URL

OpenAPI.BASE = serviceUrl
OpenAPI.TOKEN = token ? token : getJwt()
OpenAPI.RESPONSE_TYPE = 'json'

let apiClient = OpenAPI

export default function App() {
    const { t } = useCustomTranslation()
    const [theme, setTheme] = useLocalStorage('theme', 'light')
    const [currentUser, setCurrentUser] = useState(getCurrentUser())

    const currentUserMemoized = useMemo(() => {
        return {
            currentUser: currentUser,
            setCurrentUser: setCurrentUser,
        }
    }, [currentUser])
    const { array: notifications, setArray: setNotifications } = useArray([])

    const getAliases = useCallback(async () => {
        if (currentUser) {
            try {
                const aliasesBack = await ModelAliasService.getApiModelAlias({})
                saveToLS('aliases', aliasesBack)
            } catch (err) {
                errorHandler(err)
            }
        }
    }, [currentUser])

    const getNotifications = useCallback(async () => {
        if (currentUser) {
            try {
                const notificationsClone = []
                const notificationsBack = await NotificationsService.getApiNotifications({})
                notificationsBack.map((el) => {
                    const newEl = {
                        id: el.id,
                        statusText: '',
                        success: el.success,
                        text: el.message,
                        date: formatDateTime(el.timestamp),
                        read: el.read,
                    }
                    notificationsClone.push(newEl)
                    return el
                })
                setNotifications(notificationsClone)
            } catch (err) {
                errorHandler(err)
            }
        }
    }, [setNotifications, currentUser])

    useEffect(() => {
        getNotifications()
        getAliases()
    }, [getNotifications, getAliases])

    // useEffect(() => {
    //     apiClient.defaultHeaders = {}
    //     apiClient.basePath = 'http://localhost:5000'
    //     if (currentUser && !eventSource) {
    //         eventSource = new EventSourcePolyfill(
    //             `${config.service.BASE_URL}/api/push_notifications?channel=user.${currentUser.uuid}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${getJwt()}`,
    //                 },
    //             }
    //         )
    //         eventSource.onmessage = async (e) => {
    //             notification({
    //                 statusText: e.type,
    //                 success: JSON.parse(e.data).success,
    //                 text: JSON.parse(e.data).message,
    //                 date: JSON.parse(e.data).timestamp.split('T')[0],
    //             })
    //             getNotifications()
    //         }
    //         eventSource.onerror = (e) => {}
    //     }
    // }, [])

    const handleRemoveNotification = async (id) => {
        try {
            await NotificationsService.getApiNotificationsRead({ id: id })
        } catch (err) {
            errorHandler(err)
        }
    }

    const notificationsMemoized = useMemo(() => {
        return {
            notifications: notifications,
            setNotifications: setNotifications,
            removeNotification: handleRemoveNotification,
        }
    }, [notifications, setNotifications])

    const handleFinishLogin = async () => {
        OpenAPI.TOKEN = getJwt()
        setCurrentUser(getCurrentUser())
        const unreadNotifications = _.filter(notifications, (el) => !el.read)
        if (unreadNotifications.length > 0) {
            notification({
                statusText: undefined,
                text:
                    unreadNotifications.length > 1
                        ? `${t('notifications:youhavereceived')} ${unreadNotifications.length} ${t('notifications:notifications')}`
                        : `${t('notifications:youhavereceived')} ${unreadNotifications.length} ${t('notifications:notification')}`,
                date: undefined,
            })
        }
    }

    const isUserAuthenticated = useMemo(() => {
        return currentUser || process.env.REACT_APP_DISABLE_TOKEN_AUTH === 'true'
    }, [currentUser])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <main className={`container-fluid p-0 ${styles['app-container']} initial-height`}>
                <CurrentUserProvider value={currentUserMemoized}>
                    {!isUserAuthenticated && (
                        <>
                            <LoggedOutRoutes onFinishLogin={handleFinishLogin} />
                        </>
                    )}
                    {isUserAuthenticated && (
                        <CurrentAppProvider>
                            <PlatformFormatProvider>
                                <ThemeProvider value={{ theme: theme, setTheme: setTheme }}>
                                    <CursorTrackingProvider>
                                        <NotificationsProvider value={notificationsMemoized}>
                                            <LoggedInRoutes />
                                        </NotificationsProvider>
                                    </CursorTrackingProvider>
                                </ThemeProvider>
                            </PlatformFormatProvider>
                        </CurrentAppProvider>
                    )}
                </CurrentUserProvider>
            </main>
        </ErrorBoundary>
    )
}

export { apiClient }
