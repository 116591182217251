import { createContext, useContext } from 'react';

const SelectionContext = createContext({
    selected: undefined,
    setSelected: () => {}
});

export function SelectionProvider({ value, children }) {
    return (
        <SelectionContext.Provider value={value}>
            {children}
        </SelectionContext.Provider>
    );
}

export function useSelection() {
    return useContext(SelectionContext);
}
