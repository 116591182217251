import styles from './products-associated-list.module.scss';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { productsAssociatedListMock } from './mock';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useToggle } from '../../../hooks/useToggle';
import { useTable } from '../../../hooks/useTable';
import { ContentContainer, Loader, Spinner, Table } from '../../';
import { verifyComponent } from '../../../utils/component';
import { errorHandler } from '../../../utils/api';
import { TeamsService } from '../../../temp/test';
import { useLocation } from 'react-router-dom';
import { usePub } from '../../../hooks/usePubSub';
import icoAssociate from '../../../assets/images/icoAssociate.svg';
import icoDisassociate from '../../../assets/images/icoDisassociate.svg';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation';

/**
 * Lists all the members from a selected team of employees
 */

export const ProductsAssociatedList = React.memo(() => {
    const publish = usePub();
    const isMounted = useIsMounted();
    const { t } = useCustomTranslation();
    const location = useLocation();
    const [modal, toggleModal] = useToggle(false);
    const [loading, toggleLoading] = useToggle(false);
    const {
        value: data,
        toggleComponentSettings,
        pageChange: dataPageChange,
        sort: dataSort,
        selectAll: dataSelectAll,
        unselectAll: dataUnselectAll,
        setValue,
        clickActionIcon
    } = useTable(productsAssociatedListMock, modal, '');

    const handleCloseModal = useCallback(() => {
        toggleModal();
    }, [toggleModal]);

    const getProducts = useCallback(async () => {
        const dataClone = _.cloneDeep(productsAssociatedListMock);
        try {
            toggleLoading(true);
            dataClone.data.length = 0;
            dataClone.sortedData.length = 0;
            dataClone.currentPage = 1;
            if (isMounted.current) setValue(dataClone);
            const params = new URLSearchParams(location.search);
            const productsBackData = await TeamsService.getApiTeamsProducts({
                teamId: params.get('team-id')
            });
            productsBackData.map((el) => {
                const newEl = {
                    name: el.product.title ? el.product.title : '',
                    price: el.product.price ? el.product.price : '',
                    description: el.product.description
                        ? el.product.description
                        : '',
                    associated: el.associated ? el.associated : false,
                    id: el.product.id,
                    action: {
                        label: t(`columns:action`),
                        id: el.product.id,
                        open: false,
                        options: [
                            {
                                name: el.associated
                                    ? 'disassociate'
                                    : 'associate',
                                icon: el.associated
                                    ? icoDisassociate
                                    : icoAssociate,
                                label: el.associated
                                    ? t(`settings:disassociate`)
                                    : t(`settings:associate`)
                            }
                        ],
                        reference: React.createRef()
                    }
                };
                dataClone.data.push(newEl);
                return el;
            });
            dataClone.sortedData = _.orderBy(
                dataClone.data,
                [dataClone.sortColumn.path],
                [dataClone.sortColumn.order]
            );
            if (isMounted.current) {
                setValue(dataClone);
                toggleLoading(false);
            }
        } catch (err) {
            errorHandler(err);
            if (isMounted.current) {
                setValue(dataClone);
            }
        }
    }, [isMounted, toggleLoading, location.search, setValue, t]);

    useEffect(() => {
        getProducts();
    }, [getProducts]);

    const handleAssociateProduct = useCallback(
        async (id) => {
            const dataClone = _.cloneDeep(productsAssociatedListMock);
            try {
                const params = new URLSearchParams(location.search);
                await TeamsService.patchApiTeamsProducts({
                    teamId: params.get('team-id'),
                    requestBody: [id]
                });
                handleCloseModal();
                getProducts();
                publish('team');
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        },
        [
            isMounted,
            setValue,
            getProducts,
            handleCloseModal,
            location.search,
            publish
        ]
    );

    const handleDisassociateProduct = useCallback(
        async (id) => {
            const dataClone = _.cloneDeep(productsAssociatedListMock);
            try {
                const params = new URLSearchParams(location.search);
                await TeamsService.deleteApiTeamsProducts({
                    teamId: params.get('team-id'),
                    requestBody: [id]
                });
                handleCloseModal();
                getProducts();
                publish('team');
            } catch (err) {
                errorHandler(err);
                dataClone.loading = false;
                if (isMounted.current) {
                    setValue(dataClone);
                }
            }
        },
        [
            isMounted,

            setValue,
            getProducts,
            handleCloseModal,
            publish,
            location.search
        ]
    );

    const handleSelectSettingsOption = useCallback(
        async (attr, id, option) => {
            if (isMounted.current) {
                toggleComponentSettings(false);
                toggleModal(true);
                if (option === 'associate') {
                    handleAssociateProduct(id);
                } else if (option === 'disassociate') {
                    handleDisassociateProduct(id);
                }
            }
        },
        [
            isMounted,
            toggleComponentSettings,
            toggleModal,
            handleAssociateProduct,
            handleDisassociateProduct
        ]
    );

    return (
        <div className={`${styles['products-associated-list-container']}`}>
            <ContentContainer
                hidePinIcon
                disabled={data.disabled}
                isForecast={data.isForecast}
                legend={data.legend}
                settings={data.settings}
                onClickSettingsIcon={toggleComponentSettings}
                onCloseSettings={toggleComponentSettings}
            >
                {loading && <Spinner />}
                {!loading && (
                    <React.Fragment>
                        <Table
                            actions={data.actions}
                            attr={data.attr}
                            type={data.type}
                            titleCounter={data.sortedData.length}
                            titleText={t(`components:${data.attr}.title`)}
                            placeholderText={t(
                                `components:${data.attr}.nodata`
                            )}
                            columns={data.columns}
                            data={data.sortedData}
                            sortColumn={data.sortColumn}
                            onSort={dataSort}
                            currentPage={data.currentPage}
                            pageSize={data.pageSize}
                            onPageChange={dataPageChange}
                            onRowClick={undefined}
                            onRowSelect={undefined}
                            onSelectAll={dataSelectAll}
                            onUnselectAll={dataUnselectAll}
                            paginationDisabled={data.paginationDisabled}
                            onClickActionIcon={clickActionIcon}
                            onCloseActions={clickActionIcon}
                            onClickActionOption={handleSelectSettingsOption}
                            disabled={data.disabled}
                        ></Table>
                    </React.Fragment>
                )}
            </ContentContainer>
        </div>
    );
});

ProductsAssociatedList.propTypes = {
    /**
     * Selected team id. as a filter for getting data from api
     */
    selectedTeamId: PropTypes.number,
    /**
     * Trigger to propagate changes to parent component
     */
    toggleTrigger: PropTypes.func
};
